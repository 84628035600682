import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ViewChild } from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslatorService } from '../../../services/translator_service';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import _ from 'lodash';

import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { PaginationComponent } from '../../pagination/pagination.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-refills-table',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, MatDividerModule, MatCardModule, MatTableModule,
     MatSortModule, PaginationComponent,RouterModule],
  templateUrl: './refills-table.component.html',
  animations: [
    trigger('detailExpand', [ state('collapsed, void', style({ height: '0px' })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')), transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')) ])
	],
  styleUrl: './refills-table.component.scss'
})
export class RefillsTableComponent {
  expandedElement: any;
  isMobile: Boolean;
  translationsObj;
  translate;
	currentLang;
  spinnerActive:boolean = true;
  groups:any[]=[];
  groupsWithFillings:any;
  dataSource = new MatTableDataSource<any>();
  fillingsDataSource = new MatTableDataSource<any>();
  tempFilter = '';
  startIndexTableData = 0;
  endIndexTableData = 9;
  displayedColumns = ['description', 'address','lotSize','totalRefills', 'avgRefillAmount','avgDailyUsageRate','arrow'];
  displayedFillingsColumns = ['driverName', 'refillAmount','refillTime','fertilizerType'];
	@ViewChild(MatSort,{static: false}) sort: MatSort;
	usageType = 1;
  filteredData:Array<object> = [];

  constructor(private translator: TranslatorService,
		private responsiveService: ResponsiveService,
		private apiQuery:ApiQuery) { 
    this.translate = this.translator;
    this.translator.currentLangEmitter$
    .subscribe(async value=>{						
      this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
      this.currentLang = value;
    })
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.spinnerActive = true;	

    this.apiQuery.refillsStats$.subscribe((data:any) => {                  
			this.spinnerActive = true;		
      if(data.length==0) return;    
      this.groups = [];           
      this.spinnerActive = false;      
      _.each(data, (site) => {
        if(site.SiteRefillsData != undefined){
          _.each(site.SiteRefillsData, (item) => {  
            item.site_name = site.site_name;     
            this.groups.push(item);
          });    
        }
      });

      this.groupsWithFillings = this.getArrayOfCollections(this.groups);   
      this.dataSource = new MatTableDataSource<any>(this.groupsWithFillings);
      this.dataSource.sort = this.sort;
      this.sortData({ direction: 'desc', active: 'bin_name' });
		}); 

    this.apiQuery.user$.subscribe(user => {
      if (!user) {
        return;
      }				
      if(user["usage_type_id"] != 1){
        this.usageType = user["usage_type_id"];
      }
    });
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
  }

  getArrayOfCollections(group:any){    
    let res = _.each(_.groupBy(group, "bin_id"), (item) => {   
      let refillAmount = 0
      let refillAmountPercent = 0;
      item["avg_refill_amount"] = 0;
      _.each((item), (refill) => {    
        item["bin_desc"] = refill["bin_description"];
        item["bin_address"] = refill["bin_address"]
        item["lot_size"] = refill["bin_lot_size"];        
        item["total_refills"] = refill["RefillsData"].length;
        item["bin_id"] = refill["bin_id"];
        item["max_capacity_in_liters"] = refill["max_capacity_in_liters"];        
        item["red_status_value"] = refill["red_status_value"];        
        item["yellow_status_value"] = refill["yellow_status_value"];        
        item["green_status_value"] = refill["green_status_value"];        

        _.each((refill["RefillsData"]), (refillData) => { 
          refillData["max_capacity_in_liters"] = refill["max_capacity_in_liters"];
          refillData["red_status_value"] = refill["red_status_value"];
          refillData["yellow_status_value"] = refill["yellow_status_value"];
          refillData["green_status_value"] = refill["green_status_value"];
          refillAmount += refillData.bin_refill_amount_in_liters;
          refillAmountPercent += refillData.bin_fill_level_after_refill_in_percent;
        });
        item["bin_avg_fill_rate_in_liters"] = refill["bin_avg_fill_rate_in_liters"];
        item["RefillsData"] = refill["RefillsData"];
      });   
      item["avg_refill_amount"] = refillAmount;
    });
    
    const binsFiltered = _.map(res, b => {
      return {
        bin_desc: b["bin_desc"],
        bin_address: b["bin_address"],
        lot_size:b["lot_size"],
        total_refills: b["total_refills"],
        avg_refill_amount: b["avg_refill_amount"],
        bin_avg_fill_rate_in_liters: b["bin_avg_fill_rate_in_liters"],
        red_status_value : b["red_status_value"],
        yellow_status_value : b["yellow_status_value"],
        green_status_value : b["green_status_value"],  
        bin_id : b["bin_id"],
        RefillsData : b["RefillsData"]
      };
    });        
    return binsFiltered;
  }

  applyFilter = (filterValue: any) => {
    filterValue = filterValue.value;
    filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempFilter = filterValue;
		this.dataSource.filter = filterValue;	
  }
  
  getTableData = () => {	
    const tableData = [];        
		const tempSearch = new MatTableDataSource<any>(this.groupsWithFillings);

    const filterPredicate = (data, filter) => {
      try {
          const dataStr = data.bin_desc + data.bin_address + data.lot_size + data.total_refills
          + data.avg_refill_amount + data.bin_avg_fill_rate_in_liters + 
          data.RefillsData.reduce((acc,value) => {
            return acc + Object.values(value).reduce((acc1 : any,value1:any) => {
              return acc1 + value1;
            },"");
          },"");        
          return dataStr.indexOf(filter) != -1; 
        } catch (error) {
          return true;
        }
    }
    tempSearch.filteredData = tempSearch.filteredData.filter(data => filterPredicate(data,this.tempFilter));
    this.filteredData = tempSearch.filteredData;
		for (let index = this.startIndexTableData; index <= this.endIndexTableData; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
    this.dataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		            
		return this.dataSource;
  }

  sortData(sort: Sort) {    
    const data = this.groupsWithFillings.slice();    
		if (!sort.active || sort.direction === '') {
			this.groupsWithFillings = data;
			return;
		}
		this.groupsWithFillings = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
  }
  
  compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  getTableFillings(element){
    if(element != undefined){
      const tableData = [];
      const tempSearch = new MatTableDataSource<any>(element.RefillsData);
      for (let index = 0; index <= element.RefillsData.length; index++) {
        if (tempSearch.filteredData[index]) {
          tableData.push(tempSearch.filteredData[index]);
        }
      }
      this.fillingsDataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);      
      return this.fillingsDataSource;
    }
   }

  onPageChange = (dataTableIndexes: any) => {
		this.startIndexTableData = dataTableIndexes.startIndex;
		this.endIndexTableData = dataTableIndexes.endIndex;
  }

}

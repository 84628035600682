import { Component, Input } from '@angular/core';
import { Chart, ChartModule } from 'angular-highcharts';
import _ from 'lodash';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslatorService } from '../../../services/translator_service';
import { ApiService } from '../../../web-services/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { ApiQuery } from '../../../web-services/api/api.query';

import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-bin-collection-pie',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, MatDividerModule, MatCardModule, ChartModule],
  templateUrl: './bin-collection-pie.component.html',
  styleUrl: './bin-collection-pie.component.scss'
})
export class BinCollectionPieComponent {
  spinnerActive:boolean = true;
	isMobile: Boolean;
	donutChart: any;
	translationsObj;
	currentLang;
	binId;
	thresholdRange: [number, number, number, number,number,number];
	routeParamsSub;
	binInfo;
	@Input()
	set binLiveInfo_Bin(bin_info:any) {
		if(bin_info != null){
			this.binInfo = bin_info;
			this.spinnerActive = true;					
			if (this.binInfo == null || parseInt(this.binId) != this.binInfo.bin_id) {
				return;
			}
			this.thresholdRange = [
				0,
				parseFloat((this.binInfo.Bin_thresholds.green_status_fill_level_in_percent/2).toFixed(0)),
				this.binInfo.Bin_thresholds.green_status_fill_level_in_percent,
				parseFloat(((this.binInfo.Bin_thresholds.yellow_status_fill_level_in_percent + this.binInfo.Bin_thresholds.green_status_fill_level_in_percent)/2).toFixed(0)),
				this.binInfo.Bin_thresholds.yellow_status_fill_level_in_percent,
				this.binInfo.Bin_thresholds.red_status_fill_level_in_percent,
			];			
			this.updateChart();
		}
	}

  	constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,private apiQuery:ApiQuery,
		private route: ActivatedRoute,private apiService: ApiService) {
    }

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.spinnerActive = true;
		this.routeParamsSub = this.route
		.queryParams
		.subscribe(params => {
			this.binId = params['binId'] || null;	
			setTimeout(() => {
				this.apiService.getBinCollection(this.binId);
			}, 0);			
		});
	}

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

	ngAfterViewChecked(){
		var methods = ['debug','error','exception','info','time', 'timeEnd','warn'];
		for(var i=0;i<methods.length;i++){
			console[methods[i]] = function(){};
		}
	}

	updateChart(){
		this.spinnerActive = true;
		this.apiQuery.binCollectionData$.subscribe((data:any) => {
			this.spinnerActive = true;					
			if(data.length==0) return;			
			let total = 0;
			let firstPart = 0;
			let secondPart = 0;
			let thirdPart = 0;
			let forthPart = 0;
			let fifthPart = 0;
			if (!_.isEmpty(data[0])){
				_.each(data, (item) => {
					if(item.bin_fill_level_before_collection > this.thresholdRange[0] && item.bin_fill_level_before_collection <= this.thresholdRange[1]){
						firstPart ++;
					}else if(item.bin_fill_level_before_collection > this.thresholdRange[1] && item.bin_fill_level_before_collection <= this.thresholdRange[2]){
						secondPart ++;
					}else if(item.bin_fill_level_before_collection > this.thresholdRange[2] && item.bin_fill_level_before_collection <= this.thresholdRange[3]){
						thirdPart ++;
					}else if(item.bin_fill_level_before_collection > this.thresholdRange[3] && item.bin_fill_level_before_collection <= this.thresholdRange[4]){
						forthPart ++;
					}else if(item.bin_fill_level_before_collection > this.thresholdRange[4] && item.bin_fill_level_before_collection <= this.thresholdRange[5]){
						fifthPart ++;
					}
				});
				total = data.length;
			}
			this.initDonut(total,firstPart,secondPart,thirdPart,forthPart,fifthPart);
		}); 	
	}

	initDonut(total,firstPart,secondPart,thirdPart,forthPart,fifthPart) {
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
			this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			this.currentLang = value;
			const donut = new Chart({
				chart: {
				marginLeft:50,
					marginRight:50,
					marginBottom:100,
					marginTop:-10,
					plotBackgroundColor: null,
					plotBorderWidth: 0,
					plotShadow: false
				},
				legend: {
					align: this.currentLang != 'iw' ? 'left' : 'right',
					layout: 'vertical',	
					itemMarginBottom:5,	
					itemStyle: {
						fontFamily:'Open Sans',
						fontSize:'14px',
						color:'#3A4743',
						fontWeight: 'normal'				
					},	
				},
				title:{
					text: '<strong>' + total +'<br>' + this.translationsObj.WIDGETS.COLLECTIONS + '</strong>',
					align: 'center',
					verticalAlign: 'middle',
					y: -40,
					style:{
						fontFamily:'Open Sans, sans-serif',
						fontSize:'14px',
						fontWeight: '600'				
					}
				},
				tooltip: {	
					borderWidth:0,
					backgroundColor:null,
					useHTML: true,	
					formatter: function(){
						return `<div style='flex-direction: row;align-items: center;padding: 4px 8px;width: max-content;height: max-content;top: 85px;background: #F5F6FA;box-shadow: 0px 2px 8px rgba(93, 120, 112, 0.2);border-radius: 3px;display: flex;'><img src='assets/images/dashboard/bin.svg' alt=''/><div><div style='position: static;width: max-content;height: max-content;font-family: Open Sans;font-style: normal;font-weight: normal;font-size: 14px;line-height: 19px;text-transform: capitalize;color: #3A4743;flex: none;order: 0;flex-grow: 0;margin-left: 8px;margin-right: 8px;'>${this.point.name}</div><div style='position: static;width: max-content;height: max-content;font-family: Open Sans;font-style: normal;font-weight: 600;font-size: 14px;line-height: 19px;text-transform: capitalize;color: #3A4743;flex: none;order: 1;flex-grow: 0;margin-left: 8px;margin-right: 8px;'>${this.point.y} (${parseInt(this.point.percentage.toString())}%)</div></div></div>`;										
					  }
				},
				credits: {
				  enabled: false
				},
				plotOptions: {
				  pie: {
					allowPointSelect: true,
					cursor: "pointer",
					dataLabels: {
					  enabled: false,
					  distance: -50,
					},
					center: ["50%", "50%"],
					size: "90%",
					showInLegend: true
				  }
				},
				series: [
				  {
					name: "",
					point: {
						events: {
							legendItemClick: function() {
								return false;
							}
						}
					},
					data: [
					  {
						name: this.thresholdRange[0].toString()+"%"+"-"+this.thresholdRange[1].toString()+"%",
						color: '#A7E8C4',
						y: firstPart
					  },
					  {
						name: (this.thresholdRange[1]+1).toString()+"%"+"-"+this.thresholdRange[2].toString()+"%",
						color: '#28B365',
						y: secondPart
					  },
					  {
						name: (this.thresholdRange[2]+1).toString()+"%"+"-"+this.thresholdRange[3].toString()+"%",
						color: '#FFD654',
						y: thirdPart
					  },
					  {
						name: (this.thresholdRange[3]+1).toString()+"%"+"-"+this.thresholdRange[4].toString()+"%",
						color: '#FAB300',
						y: forthPart
					  },
					  {
						name: (this.thresholdRange[4]+1).toString()+"%"+"-"+this.thresholdRange[5].toString()+"%",
						color: '#FF4E3E',
						y: fifthPart
					  }
					],
					type: "pie",
					innerSize: "50%"
				  }
				]
			  });
			  this.donutChart = donut;
			  this.spinnerActive = false;
		})
	  }
}

@if (!isMobile) {
  <div>
    <div class="inventory-page" rtlDiv>
      <div class="inventory-header" rtlDiv>
        {{'SIDEBAR.TICKET_MANAGEMENT' | translate }}
      </div>
      <mat-divider class="top-divider"></mat-divider>
    </div>
    <div class="ticket-view">
      <div class='datepicker-wrapper' rtlDiv>
        <div class='selected-dates' (click)='showHideDatePicker()'>
          <img [ngClass]="{'transform-arrow' : currentLang == 'iw'}" src="assets/images/dashboard/Previous.svg" alt="Previous" rtlDiv>
          <div class="{{ getSavedStatusId() }} dates-range datepicker-rtl" rtlDiv>
            {{selectedDates.from}} - {{selectedDates.to}}
          </div>
          <div class="{{ getSavedStatusIdDefault() }} dates-range datepicker-rtl" rtlDiv>
            {{ 'TICKET_MANAGEMENT.SELECT_DATES' | translate }}
          </div>
          <img [ngClass]="{'transform-arrow' : currentLang == 'iw'}" src="assets/images/dashboard/Next.svg" alt="Next" rtlDiv>
        </div>
        <div class="datepicker-frame" rtlDiv>
          <app-datepicker-range [hidden]='!showDatePicker' (selectDates)='onSelectDates($event)'></app-datepicker-range>
        </div>
      </div>
      <mat-tab-group mat-stretch-tabs="false" rtlDiv animationDuration="0ms" (selectedIndexChange)="myTabSelectedIndexChange($event)">
        <mat-tab label="{{'TICKET_MANAGEMENT.CHECK_OR_REPLACEMENT_TAB' | translate}}">
          <mat-card>
            <div class='spinner-container' [hidden]="!checkOrReplacementSpinnerActive">
              <div class="spinner"></div>
            </div>
            <mat-card-content [hidden]="checkOrReplacementSpinnerActive">
              <div class="tab-width">
                <div class='search-input-container' rtlDiv>
                  <div class="search-container">
                    <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                    <input id="checkOrReplacementFilter" class='search-input' type="text" (input)="applyCheckOrReplacementFilter($event.target['value'])" placeholder="{{'OTHER.SEARCH' | translate }}" name="">
                  </div>
                  <div class='table-export-container' rtlDiv>
                    <div class='table-export-btns-wrapper' (click)="exportToCsv('0')" rtlDiv>
                      <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                      <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                    </div>
                    @if (user_role_id == 5 || user_role_id == 2 || user_role_id == 4) {
                      <div class="manage-bins-btn-container" (click)="openAddTicket()" rtlDiv>
                        <a class="manage-bins-btn" rtlDiv>
                          <span>
                            <img class="arow_manage" src="assets/images/dashboard/Plus.svg" rtlDiv/>
                          </span>
                          <span class="btn-text">{{ 'TICKET_MANAGEMENT.ADD_EVENT' | translate }}</span>
                        </a>
                      </div>
                    }
                  </div>
                </div>
                <table class="table" mat-table [dataSource]="getCheckOrReplacementData()" matSort matSortStart="asc"
                  matSortActive="status" matSortDisableClear (matSortChange)="sortCheckOrReplacementTicketsData($event)" matSortDirection="desc">
                  <ng-container matColumnDef="ticket_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="ticket_id" class="th-title">
                    {{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="td-value"> {{element ? element.ticket_id : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ticket_status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="status" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.STATUS' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span [ngClass]="{'pc-opened-event' : element.status == 1, 'pc-closed-event' : element.status != 1}">{{element ? element.status_txt : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="event_cause">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="ticket_event_cause_id" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      @if (currentLang != 'iw') {
                        <span rtlDiv> {{element ? element.eventCauseEn : ''}}</span>
                      }
                      @if (currentLang == 'iw') {
                        <span rtlDiv> {{element ? element.eventCauseHe : ''}}</span>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="priority">
                    <th [hidden]="user_role_id == 3 || user_role_id == 4" mat-header-cell *matHeaderCellDef mat-sort-header="priority_id" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.PRIORITY' | translate }} </th>
                    <td [hidden]="user_role_id == 3 || user_role_id == 4" mat-cell *matCellDef="let element" class="td-value padding-value">
                      <div class="dflex-center" rtlDiv>
                        <span [ngClass]="{'low-priority-dot':element.priority_id == 1,'medium-priority-dot':element.priority_id == 2,'high-priority-dot':element.priority_id == 3}"></span>
                        @if (currentLang != 'iw') {
                          <span class="td-value"> {{ element ? element.priorityEn : ''}}</span>
                        }
                        @if (currentLang == 'iw') {
                          <span class="td-value"> {{element ? element.priorityHe : ''}}</span>
                        }
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="site_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.SITE' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv> {{element ? element.site_name : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      @if (element.bin_id == 0 || element.bin_id == '') {
                        <span> {{element ? element.bin_name : ''}}</span>
                      }
                      @if (element.bin_id != 0 && element.bin_id != '') {
                        <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'ticketManagement',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="td-value"> {{element ? element.bin_name : ''}}</a>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv> {{element ? element.bin_address : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="comment">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="comment" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv> {{element ? element.comment : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="reporter">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reporter" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.REPORTER' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv> {{element ? element.reporter : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="created">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="created_DateTime_value" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv>
                        {{currentLang == 'iw' && element ? element.created_he : element.created}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="arrival_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="expected_arrival_date" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv>
                        {{currentLang == 'iw' && element ? element.expected_arrival_date_he : element.expected_arrival_date}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="closed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="closed_DateTime_value" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.CLOSED' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span>
                        {{currentLang == 'iw' && element ? element.closed_he : element.closed}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="manage">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element" class="manage-bins">
                      <div class="menu-Cmp" rtlDiv>
                        <div class="dropdown-toggle" data-bs-toggle="dropdown">
                          <img alt="" src="../../../assets/images/dashboard/threedots.svg">
                        </div>
                        <div class="dropdown-menu list-items" rtlDiv>
                          @if (user_role_id == 1 || user_role_id == 3 || user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) {
                            <div class="menuItem" (click)="viewAction(element,element.status,0)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.VIEW' | translate }}</span>
                            </div>
                          }
                          @if (user_role_id == 5 || user_role_id == 2 || deleteOwnEvent(element)) {
                            <div class="menuItem" (click)="deleteAction(element,element.status)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.DELETE' | translate }}</span>
                            </div>
                          }
                          @if ((user_role_id == 5 || user_role_id == 6) && element.status == 1) {
                            <div class="menuItem" (click)="resolveAction(element,element.status,0)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.CLOSE' | translate }}</span>
                            </div>
                          }
                          @if ((user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) && element.status == 1) {
                            <div class="menuItem" (click)="editAction(element,element.status,0)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.EDIT' | translate }}</span>
                            </div>
                          }
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedCheckOrReplacementColumns"></tr>
                  <tr class="ticket-row" mat-row *matRowDef="let row; columns: displayedCheckOrReplacementColumns;" [ngClass]="{'highlight-row': row.ticket_id == markerSelected['ticket_id']}"></tr>
                </table>
                @if (checkOrReplacementTicketsTableLength > 0) {
                  <div>
                    <app-pagination [chosenBins]="filteredCheckOrReplacementData" (pageChange)='onCheckOrReplacementPageChange($event)' [filesPerPage]="10"></app-pagination>
                  </div>
                }
              </div>
              <app-ticket-management-map (markerSelected)="handleMarkerSelected($event)" [selectedTab]="selectedTab" [filteredCheckOrReplacementValue]="tempCheckOrReplacementFilter" [filteredInstallationValue]="tempInstallationFilter" [filteredCancellationValue]="tempCancellationFilter"></app-ticket-management-map>
            </mat-card-content>
          </mat-card>
        </mat-tab>
        <mat-tab label="{{'TICKET_MANAGEMENT.INSTALLATION_TAB' | translate}}">
          <mat-card>
            <div class='spinner-container' [hidden]="!installationSpinnerActive">
              <div class="spinner"></div>
            </div>
            <mat-card-content [hidden]="installationSpinnerActive">
              <div class="tab-width">
                <div class='search-input-container' rtlDiv>
                  <div class="search-container">
                    <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                    <input id="installationFilter" class='search-input' type="text" (input)="applyInstallationFilter($event.target['value'])" placeholder="{{'OTHER.SEARCH' | translate }}" name="">
                  </div>
                  <div class='table-export-container' rtlDiv>
                    <div class='table-export-btns-wrapper' (click)="exportToCsv('1')" rtlDiv>
                      <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                      <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                    </div>
                    @if (user_role_id == 5 || user_role_id == 2 || user_role_id == 4) {
                      <div class="manage-bins-btn-container" (click)="openAddTicket()" rtlDiv>
                        <a class="manage-bins-btn" rtlDiv>
                          <span>
                            <img class="arow_manage" src="assets/images/dashboard/Plus.svg" rtlDiv/>
                          </span>
                          <span class="btn-text">{{ 'TICKET_MANAGEMENT.ADD_EVENT' | translate }}</span>
                        </a>
                      </div>
                    }
                  </div>
                </div>
                <table class="table" mat-table [dataSource]="getInstallationData()" matSort matSortStart="asc"
                  matSortActive="status" matSortDisableClear (matSortChange)="sortInstallationTicketsData($event)" matSortDirection="desc">
                  <ng-container matColumnDef="ticket_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="ticket_id" class="th-title">
                    {{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value">
                      <span> {{element ? element.ticket_id : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ticket_status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="status" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.STATUS' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span [ngClass]="{'pc-opened-event' : element.status == 1, 'pc-closed-event' : element.status != 1}">{{element ? element.status_txt : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="event_cause">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="ticket_event_cause_id" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      @if (currentLang != 'iw') {
                        <span rtlDiv> {{element ? element.eventCauseEn : ''}}</span>
                      }
                      @if (currentLang == 'iw') {
                        <span rtlDiv> {{element ? element.eventCauseHe : ''}}</span>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="priority">
                    <th [hidden]="user_role_id == 3 || user_role_id == 4" mat-header-cell *matHeaderCellDef mat-sort-header="priority_id" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.PRIORITY' | translate }} </th>
                    <td [hidden]="user_role_id == 3 || user_role_id == 4" mat-cell *matCellDef="let element" class="td-value padding-value">
                      <div class="dflex-center" rtlDiv>
                        <span [ngClass]="{'low-priority-dot':element.priority_id == 1,'medium-priority-dot':element.priority_id == 2,'high-priority-dot':element.priority_id == 3}"></span>
                        @if (currentLang != 'iw') {
                          <span> {{element ? element.priorityEn : ''}}</span>
                        }
                        @if (currentLang == 'iw') {
                          <span> {{element ? element.priorityHe : ''}}</span>
                        }
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="site_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.SITE' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv> {{element ? element.site_name : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      @if (element.bin_id == 0 || element.bin_id == '') {
                        <span> {{element ? element.bin_name : ''}}</span>
                      }
                      @if (element.bin_id != 0 && element.bin_id != '') {
                        <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'ticketManagement',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="td-value"> {{element ? element.bin_name : ''}}</a>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv> {{element ? element.bin_address : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="comment">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="comment" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv> {{element ? element.comment : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="reporter">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reporter" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.REPORTER' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv> {{element ? (element.first_name == null || element.last_name == null ? '' : element.reporter) : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="created">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="created_DateTime_value" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv>
                        {{currentLang == 'iw' && element ? element.created_he : element.created}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="arrival_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="expected_arrival_date" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv>
                        {{currentLang == 'iw' && element ? element.expected_arrival_date_he : element.expected_arrival_date}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="closed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="closed_DateTime_value" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.CLOSED' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span>
                        {{currentLang == 'iw' && element ? element.closed_he : element.closed}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="manage">
                    <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="manage"> </th>
                    <td mat-cell *matCellDef="let element" class="manage-bins">
                      <div class="menu-Cmp" rtlDiv>
                        <div class="dropdown-toggle" data-bs-toggle="dropdown">
                          <img alt="" src="../../../assets/images/dashboard/threedots.svg">
                        </div>
                        <div class="dropdown-menu list-items" rtlDiv>
                          @if (user_role_id == 1 || user_role_id == 3 || user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) {
                            <div class="menuItem" (click)="viewAction(element,element.status,1)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.VIEW' | translate }}</span>
                            </div>
                          }
                          @if (user_role_id == 5 || user_role_id == 2 || deleteOwnEvent(element)) {
                            <div class="menuItem" (click)="deleteAction(element,element.status)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.DELETE' | translate }}</span>
                            </div>
                          }
                          @if ((user_role_id == 5 || user_role_id == 6) && element.status == 1) {
                            <div class="menuItem" (click)="resolveAction(element,element.status,1)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.CLOSE' | translate }}</span>
                            </div>
                          }
                          @if ((user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) && element.status == 1) {
                            <div class="menuItem" (click)="editAction(element,element.status,1)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.EDIT' | translate }}</span>
                            </div>
                          }
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedInstallationColumns"></tr>
                  <tr class="ticket-row" mat-row *matRowDef="let row; columns: displayedInstallationColumns;" [ngClass]="{'highlight-row': row.ticket_id == markerSelected['ticket_id']}"></tr>
                </table>
                @if (installationTicketsTableLength > 0) {
                  <div>
                    <app-pagination [chosenBins]="filteredInstallationData" (pageChange)='onInstallationPageChange($event)' [filesPerPage]="10"></app-pagination>
                  </div>
                }
              </div>
              <app-ticket-management-map (markerSelected)="handleMarkerSelected($event)" [selectedTab]="selectedTab" [filteredCheckOrReplacementValue]="tempCheckOrReplacementFilter" [filteredInstallationValue]="tempInstallationFilter" [filteredCancellationValue]="tempCancellationFilter"></app-ticket-management-map>
            </mat-card-content>
          </mat-card>
        </mat-tab>
        <mat-tab label="{{'TICKET_MANAGEMENT.CANCELLATION_TAB' | translate}}">
          <mat-card>
            <div class='spinner-container' [hidden]="!cancellationSpinnerActive">
              <div class="spinner"></div>
            </div>
            <mat-card-content [hidden]="cancellationSpinnerActive">
              <div class="tab-width">
                <div class='search-input-container' rtlDiv>
                  <div class="search-container">
                    <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                    <input id="cancellationFilter" class='search-input' type="text" (input)="applyCancellationFilter($event.target['value'])" placeholder="{{'OTHER.SEARCH' | translate }}" name="">
                  </div>
                  <div class='table-export-container' rtlDiv>
                    <div class='table-export-btns-wrapper' (click)="exportToCsv('2')" rtlDiv>
                      <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                      <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                    </div>
                    @if (user_role_id == 5 || user_role_id == 2 || user_role_id == 4) {
                      <div class="manage-bins-btn-container" (click)="openAddTicket()" rtlDiv>
                        <a class="manage-bins-btn" rtlDiv>
                          <span>
                            <img class="arow_manage" src="assets/images/dashboard/Plus.svg" rtlDiv/>
                          </span>
                          <span class="btn-text">{{ 'TICKET_MANAGEMENT.ADD_EVENT' | translate }}</span>
                        </a>
                      </div>
                    }
                  </div>
                </div>
                <table class="table" mat-table [dataSource]="getCancellationData()" matSort matSortStart="asc"
                  matSortActive="status" matSortDisableClear (matSortChange)="sortCancellationTicketsData($event)" matSortDirection="desc">
                  <ng-container matColumnDef="ticket_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="ticket_id" class="th-title">
                    {{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value">
                      <span> {{element ? element.ticket_id : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ticket_status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="status" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.STATUS' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span [ngClass]="{'pc-opened-event' : element.status == 1, 'pc-closed-event' : element.status != 1}">{{element ? element.status_txt : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="event_cause">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="ticket_event_cause_id" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      @if (currentLang != 'iw') {
                        <span rtlDiv> {{element ? element.eventCauseEn : ''}}</span>
                      }
                      @if (currentLang == 'iw') {
                        <span rtlDiv> {{element ? element.eventCauseHe : ''}}</span>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="priority">
                    <th [hidden]="user_role_id == 3 || user_role_id == 4" mat-header-cell *matHeaderCellDef mat-sort-header="priority_id" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.PRIORITY' | translate }} </th>
                    <td [hidden]="user_role_id == 3 || user_role_id == 4" mat-cell *matCellDef="let element" class="td-value padding-value">
                      <div class="dflex-center" rtlDiv>
                        <span [ngClass]="{'low-priority-dot':element.priority_id == 1,'medium-priority-dot':element.priority_id == 2,'high-priority-dot':element.priority_id == 3}"></span>
                        @if (currentLang != 'iw') {
                          <span> {{element ? element.priorityEn : ''}}</span>
                        }
                        @if (currentLang == 'iw') {
                          <span> {{element ? element.priorityHe : ''}}</span>
                        }
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="site_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.SITE' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv> {{element ? element.site_name : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      @if (element.bin_id == 0 || element.bin_id == '') {
                        <span> {{element ? element.bin_name : ''}}</span>
                      }
                      @if (element.bin_id != 0 && element.bin_id != '') {
                        <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'ticketManagement',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="td-value"> {{element ? element.bin_name : ''}}</a>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv> {{element ? element.bin_address : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="comment">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="comment" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv> {{element ? element.comment : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="reporter">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reporter" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.REPORTER' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv> {{element ? (element.first_name == null || element.last_name == null ? '' : element.reporter) : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="created">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="created_DateTime_value" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv>
                        {{currentLang == 'iw' && element ? element.created_he : element.created}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="arrival_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="expected_arrival_date" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span rtlDiv>
                        {{currentLang == 'iw' && element ? element.expected_arrival_date_he : element.expected_arrival_date}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="closed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="closed_DateTime_value" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.CLOSED' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      <span>
                        {{currentLang == 'iw' && element ? element.closed_he : element.closed}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="removedReason">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="removedReason" class="th-title padding-value">
                    {{ 'TICKET_MANAGEMENT.REMOVED_REASON' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="td-value padding-value">
                      @if (currentLang != 'iw') {
                        <span> {{element ? element.reasonOfReplacementEn : ''}}</span>
                      }
                      @if (currentLang == 'iw') {
                        <span> {{element ? element.reasonOfReplacementHe : ''}}</span>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="manage">
                    <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="manage"> </th>
                    <td mat-cell *matCellDef="let element" class="manage-bins">
                      <div class="menu-Cmp" rtlDiv>
                        <div class="dropdown-toggle" data-bs-toggle="dropdown">
                          <img alt="" src="../../../assets/images/dashboard/threedots.svg">
                        </div>
                        <div class="dropdown-menu list-items" rtlDiv>
                          @if (user_role_id == 1 || user_role_id == 3 || user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) {
                            <div class="menuItem" (click)="viewAction(element,element.status,2)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.VIEW' | translate }}</span>
                            </div>
                          }
                          @if (user_role_id == 5 || user_role_id == 2 || deleteOwnEvent(element)) {
                            <div class="menuItem" (click)="deleteAction(element,element.status)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.DELETE' | translate }}</span>
                            </div>
                          }
                          @if ((user_role_id == 5 || user_role_id == 6) && element.status == 1) {
                            <div class="menuItem" (click)="resolveAction(element,element.status,2)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.CLOSE' | translate }}</span>
                            </div>
                          }
                          @if ((user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) && element.status == 1) {
                            <div class="menuItem" (click)="editAction(element,element.status,2)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.EDIT' | translate }}</span>
                            </div>
                          }
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedCancellationColumns"></tr>
                  <tr class="ticket-row" mat-row *matRowDef="let row; columns: displayedCancellationColumns;" [ngClass]="{'highlight-row': row.ticket_id == markerSelected['ticket_id']}"></tr>
                </table>
                @if (cancellationTicketsTableLength > 0) {
                  <div>
                    <app-pagination [chosenBins]="filteredCancellationData" (pageChange)='onCancellationPageChange($event)' [filesPerPage]="10"></app-pagination>
                  </div>
                }
              </div>
              <app-ticket-management-map (markerSelected)="handleMarkerSelected($event)" [selectedTab]="selectedTab" [filteredCheckOrReplacementValue]="tempCheckOrReplacementFilter" [filteredInstallationValue]="tempInstallationFilter" [filteredCancellationValue]="tempCancellationFilter"></app-ticket-management-map>
            </mat-card-content>
          </mat-card>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
}

@if (isMobile) {
  <div>
    <div class="mobile-top-container">
      <div class="mobile-page-header">
        <div>{{'SIDEBAR.TICKET_MANAGEMENT' | translate }}</div>
      </div>
      <div class='mobile-datepicker-container' rtlDiv>
        <div class='selected-dates' (click)='showHideDatePicker()'>
          <img class="mobile-date-arrow" [ngClass]="{'transform-arrow' : currentLang == 'iw'}" src="assets/images/dashboard/Previous.svg" alt="Previous" rtlDiv>
          <div class="{{ getSavedStatusId() }} dates-range datepicker-rtl" rtlDiv>
            {{selectedDates.from}} - {{selectedDates.to}}
          </div>
          <div class="{{ getSavedStatusIdDefault() }} dates-range datepicker-rtl" rtlDiv>
            {{ 'TICKET_MANAGEMENT.SELECT_DATES' | translate }}
          </div>
          <img class="mobile-date-arrow" [ngClass]="{'transform-arrow' : currentLang == 'iw'}" src="assets/images/dashboard/Next.svg" alt="Next" rtlDiv>
        </div>
        <div class="datepicker-rtl" rtlDiv>
          <app-datepicker-range [hidden]='!showDatePicker' (selectDates)='onSelectDates($event)'></app-datepicker-range>
        </div>
      </div>
    </div>
    <div class="mobile-group-tabs">
      <mat-tab-group mat-stretch-tabs="false" rtlDiv animationDuration="0ms" (selectedIndexChange)="myTabSelectedIndexChange($event)">
        <mat-tab label="{{'TICKET_MANAGEMENT.CHECK_OR_REPLACEMENT_TAB' | translate}}">
          <ng-template matTabContent>
            <div class="card mobile-card">
              <div class="card-body">
                <div class="mobile-search-excel">
                  <div class='mobile-search-container'>
                    <img class='mobile-search-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                    <input id="mobile-checkOrReplacement-filter" class='mobile-input' type="text" (input)="applyCheckOrReplacementFilter($event.target['value'])" placeholder="{{'OTHER.SEARCH' | translate}}" rtlDiv>
                  </div>
                  <div class="display-icons">
                    @if (mapCheckOrReplacementExpanded) {
                      <div class="padding-img">
                        <img (click)="expandCheckOrReplacementMap()" src="assets/images/sidemenu/expand-map.svg" alt="" rtlDiv />
                      </div>
                    }
                    @if (!mapCheckOrReplacementExpanded) {
                      <div class="padding-img">
                        <img (click)="expandCheckOrReplacementMap()" src="assets/images/sidemenu/not-expand-map.svg" alt="" rtlDiv />
                      </div>
                    }
                    <div class="padding-img dropdown">
                      <div class="dropdown-toggle" data-bs-toggle="dropdown">
                        <img src="assets/images/sidemenu/menu-dots.svg" alt="" rtlDiv />
                      </div>
                      <div class="dropdown-menu mobile-dropdown-list" rtlDiv>
                        <div class="mobile-menuItem">
                          <div class="menu-row" (click)="exportToCsv('0')" rtlDiv>
                            <img src="assets/images/sidemenu/mobile-excel.svg" alt="" rtlDiv />
                            <span class="mobile-item-txt">{{ 'OTHER.EXCEL' | translate }}</span>
                          </div>
                          <div class="menu-row" (click)="openAddTicket()" rtlDiv>
                            <img src="assets/images/sidemenu/mobile-add.svg" alt="" rtlDiv />
                            <span class="mobile-item-txt">{{ 'TICKET_MANAGEMENT.ADD_EVENT' | translate }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="full-width" [hidden]="!checkOrReplacementSpinnerActive">
                  <div class="spinner-border mobile-spinner">
                    <div class="spinner"></div>
                  </div>
                </div>
                @if (!checkOrReplacementSpinnerActive && mapCheckOrReplacementExpanded) {
                  <div class="mobile-map-frame">
                    <app-ticket-management-map (markerSelected)="handleMarkerSelected($event)" [selectedTab]="selectedTab" [filteredCheckOrReplacementValue]="tempCheckOrReplacementFilter" [filteredInstallationValue]="tempInstallationFilter" [filteredCancellationValue]="tempCancellationFilter"></app-ticket-management-map>
                  </div>
                }
                <div id="checkOrReplacementTable" [hidden]="checkOrReplacementSpinnerActive">
                  <table class="mobile-table" mat-table [dataSource]="getCheckOrReplacementData()" matSort matSortStart="asc"
                    matSortActive="status" multiTemplateDataRows matSortDisableClear (matSortChange)="sortCheckOrReplacementTicketsData($event)" matSortDirection="desc">
                    <ng-container matColumnDef="ticket_status">
                      <th [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketStatusChecked" mat-header-cell *matHeaderCellDef mat-sort-header="status" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.STATUS' | translate }} </th>
                      <td [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketStatusChecked" class="mobile-td-title" mat-cell *matCellDef="let element">
                        <span [ngClass]="{'opened-event' : element.status == 1, 'closed-event' : element.status != 1}">{{element ? element.status_txt : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="event_cause">
                      <th [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketCauseChecked" mat-header-cell *matHeaderCellDef mat-sort-header="ticket_event_cause_id" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }} </th>
                      <td [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketCauseChecked" class="mobile-td-title" mat-cell *matCellDef="let element">
                        @if (currentLang != 'iw') {
                          <span class="mobile-td"> {{element ? element.eventCauseEn : ''}}</span>
                        }
                        @if (currentLang == 'iw') {
                          <span class="mobile-td"> {{element ? element.eventCauseHe : ''}}</span>
                        }
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="arrival_date">
                      <th [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isArrivalDateChecked" mat-header-cell *matHeaderCellDef mat-sort-header="expected_arrival_date" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }} </th>
                      <td [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isArrivalDateChecked" class="mobile-td-title" mat-cell *matCellDef="let element">
                        <span class="mobile-td">
                          {{currentLang == 'iw' && element ? element.expected_arrival_date_he : element.expected_arrival_date}}
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="ticket_id">
                      <th [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketIdChecked" mat-header-cell *matHeaderCellDef mat-sort-header="ticket_id" class="mobile-th-title">{{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }}</th>
                      <td [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketIdChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td"> {{element ? element.ticket_id : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="priority">
                      <th [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketPriorityChecked" mat-header-cell *matHeaderCellDef mat-sort-header="priority_id" class="mobile-th-title">{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }} </th>
                      <td [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketPriorityChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <div class="dflex-center" rtlDiv>
                          <span [ngClass]="{'low-priority-dot':element.priority_id == 1,'medium-priority-dot':element.priority_id == 2,'high-priority-dot':element.priority_id == 3}"></span>
                          @if (currentLang != 'iw') {
                            <span class="mobile-td"> {{ element ? element.priorityEn : ''}}</span>
                          }
                          @if (currentLang == 'iw') {
                            <span class="mobile-td"> {{element ? element.priorityHe : ''}}</span>
                          }
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="site_name">
                      <th [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketSiteChecked" mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.SITE' | translate }} </th>
                      <td [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketSiteChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td" rtlDiv> {{element ? element.site_name : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_name">
                      <th [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isBinNameChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }} </th>
                      <td [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isBinNameChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        @if (element.bin_id == 0 || element.bin_id == '') {
                          <span class="mobile-td"> {{element ? element.bin_name : ''}}</span>
                        }
                        @if (element.bin_id != 0 && element.bin_id != '') {
                          <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'ticketManagement',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="mobile-td"> {{element ? element.bin_name : ''}}</a>
                        }
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_address">
                      <th [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketAddressChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }} </th>
                      <td [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketAddressChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td cut-txt" rtlDiv> {{element ? element.bin_address : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="comment">
                      <th [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isDescChecked" mat-header-cell *matHeaderCellDef mat-sort-header="comment" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }} </th>
                      <td [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isDescChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td cut-txt" rtlDiv> {{element ? element.comment : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="reporter">
                      <th [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isReporterChecked" mat-header-cell *matHeaderCellDef mat-sort-header="reporter" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.REPORTER' | translate }} </th>
                      <td [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isReporterChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td" rtlDiv> {{element ? element.reporter : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                      <th [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketCreatedChecked" mat-header-cell *matHeaderCellDef mat-sort-header="created_DateTime_value" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.CREATED' | translate }} </th>
                      <td [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketCreatedChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td" rtlDiv>
                          {{currentLang == 'iw' && element ? element.created_he : element.created}}
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="closed">
                      <th [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketClosedChecked" mat-header-cell *matHeaderCellDef mat-sort-header="closed_DateTime_value" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.CLOSED' | translate }} </th>
                      <td [hidden]="!ticketManagementCheckOrReplacementSelectedValues.isTicketClosedChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td">
                          {{currentLang == 'iw' && element ? element.closed_he : element.closed}}
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="arrowDown">
                      <th class="mobile-th-title" rtlDiv mat-header-cell *matHeaderCellDef>
                        <img (click)="openCheckOrReplacementColumnManaging()" rtlDiv src="assets/images/dashboard/addCol.svg" alt=""/>       
                      </th>
                      <td class="mobile-td-title" mat-cell *matCellDef="let element">
                        <div rtlDiv class="mobile-status-value" (click)="handleExpandedElm(element)">
                          @if (expandedElementTable['ticket_id'] != element['ticket_id']) {
                            <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                          }
                          @if (expandedElementTable['ticket_id'] == element['ticket_id']) {
                            <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                          }
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="expandedDetail">
                      <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="checkOrReplacementColumnsToDisplay.length" rtlDiv>
                        <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element['ticket_id'] == expandedElementTable['ticket_id'] ? 'expanded' : 'collapsed'">
                          <div class="mobile-element-diagram" rtlDiv>
                            <div class="mobile-item-details">
                              @if(!ticketManagementCheckOrReplacementSelectedValues.isTicketIdChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }}</div>
                                  <div class="mobile-details-value"> {{element ? element.ticket_id : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementCheckOrReplacementSelectedValues.isTicketCauseChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</div>
                                  <div class="mobile-details-value"> {{currentLang == 'iw' && element ? element.eventCauseHe : element.eventCauseEn}} </div>
                                </div>
                              }
                              @if(!ticketManagementCheckOrReplacementSelectedValues.isTicketPriorityChecked){
                                @if (user_role_id != 3 && user_role_id != 4) {
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</div>
                                    <div class="mobile-details-value">
                                      <span [ngClass]="{'low-priority-dot':element.priority_id == 1,'medium-priority-dot':element.priority_id == 2,'high-priority-dot':element.priority_id == 3}"></span>
                                      @if (currentLang != 'iw') {
                                        <span> {{ element ? element.priorityEn : ''}}</span>
                                      }
                                      @if (currentLang == 'iw') {
                                        <span> {{element ? element.priorityHe : ''}}</span>
                                      }
                                    </div>
                                  </div>
                                }
                              }
                              @if(!ticketManagementCheckOrReplacementSelectedValues.isTicketSiteChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.SITE' | translate }}</div>
                                  <div class="mobile-details-value"> {{element ? element.site_name : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementCheckOrReplacementSelectedValues.isBinNameChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</div>
                                  <div class="mobile-details-value">
                                    @if (element.bin_id == 0 || element.bin_id == '') {
                                      <span> {{element ? element.bin_name : ''}}</span>
                                    }
                                    @if (element.bin_id != 0 && element.bin_id != '') {
                                      <a class="binName" [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'ticketManagement',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}"> {{element ? element.bin_name : ''}}</a>
                                    }
                                  </div>
                                </div>
                              }
                              @if(!ticketManagementCheckOrReplacementSelectedValues.isTicketAddressChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</div>
                                  <div class="mobile-details-value"> {{element ? element.bin_address : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementCheckOrReplacementSelectedValues.isDescChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.DESCRIPTION' | translate}}</div>
                                  <div [ngClass]="{'mobile-details-value-rtl':currentLang != 'iw','mobile-details-value-ltr':currentLang == 'iw'}"> {{element ? element.comment : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementCheckOrReplacementSelectedValues.isReporterChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.REPORTER' | translate}}</div>
                                  <div class="mobile-details-value"> {{element ? element.reporter : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementCheckOrReplacementSelectedValues.isTicketCreatedChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.CREATED' | translate}}</div>
                                  <div class="mobile-details-value"> {{currentLang == 'iw' && element ? element.created_he : element.created}} </div>
                                </div>
                              }
                              @if(!ticketManagementCheckOrReplacementSelectedValues.isArrivalDateChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate}}</div>
                                  <div class="mobile-details-value"> {{currentLang == 'iw' && element ? element.expected_arrival_date_he : element.expected_arrival_date}} </div>
                                </div>
                              }
                              @if(!ticketManagementCheckOrReplacementSelectedValues.isTicketClosedChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.CLOSED' | translate}}</div>
                                  <div class="mobile-details-value">{{currentLang == 'iw' && element ? element.closed_he : element.closed}}</div>
                                </div>
                              }
                              @if (element.status != 1) {
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.DESCRIPTION' | translate}}</div>
                                  <div [ngClass]="{'mobile-details-value-rtl':currentLang != 'iw','mobile-details-value-ltr':currentLang == 'iw'}"> {{element ? element.resolvedDescription : ''}} </div>
                                </div>
                              }
                            </div>
                            <div class="dFlex padding-border">
                              <div class="mobile-details-value title-width">{{'SITE_MANAGMENT.ACTIONS' | translate}}:</div>
                              <div class="actions-container" rtlDiv>
                                @if ((user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) && element.status == 1) {
                                  <div rtlDiv class="action-frame" (click)="editAction(element,element.status,0)">
                                    <img src="assets/images/sidemenu/edit-pen.svg" alt=""/>
                                    <span class="action-txt">{{ 'TICKET_MANAGEMENT.EDIT' | translate }}</span>
                                  </div>
                                }
                                @if ((user_role_id == 5 || user_role_id == 6) && element.status == 1) {
                                  <div rtlDiv class="action-frame" (click)="resolveAction(element,element.status,0)">
                                    <img src="assets/images/sidemenu/correct-symbol.svg" alt=""/>
                                    <span class="action-txt">{{ 'TICKET_MANAGEMENT.CLOSE' | translate }}</span>
                                  </div>
                                }
                                @if (user_role_id == 5 || user_role_id == 2 || deleteOwnEvent(element)) {
                                  <div rtlDiv class="delete-action-frame" (click)="deleteAction(element,element.status)">
                                    <img src="assets/images/sidemenu/red-bin.svg" alt=""/>
                                    <span class="action-txt">{{ 'TICKET_MANAGEMENT.DELETE' | translate }}</span>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="checkOrReplacementColumnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let element; columns: checkOrReplacementColumnsToDisplay;"
                      (click)="expandedElement = expandedElement === element ? null : element">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                  </table>
                  @if (checkOrReplacementTicketsTableLength > 0) {
                    <div>
                      <app-pagination [chosenBins]="filteredCheckOrReplacementData" (pageChange)='onCheckOrReplacementPageChange($event)' [filesPerPage]="10"></app-pagination>
                    </div>
                  }
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'TICKET_MANAGEMENT.INSTALLATION_TAB' | translate}}">
          <ng-template matTabContent>
            <div class="card mobile-card">
              <div class="card-body">
                <div class="mobile-search-excel">
                  <div class='mobile-search-container'>
                    <img class='mobile-search-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                    <input id="mobile-installation-filter" class='mobile-input' type="text" (input)="applyInstallationFilter($event.target['value'])" placeholder="{{'OTHER.SEARCH' | translate}}" rtlDiv>
                  </div>
                  <div class="display-icons">
                    @if (mapInstallationExpanded) {
                      <div class="padding-img">
                        <img (click)="expandInstallationMap()" src="assets/images/sidemenu/expand-map.svg" alt="" rtlDiv />
                      </div>
                    }
                    @if (!mapInstallationExpanded) {
                      <div class="padding-img">
                        <img (click)="expandInstallationMap()" src="assets/images/sidemenu/not-expand-map.svg" alt="" rtlDiv />
                      </div>
                    }
                    <div class="padding-img dropdown">
                      <div class="dropdown-toggle" data-bs-toggle="dropdown">
                        <img src="assets/images/sidemenu/menu-dots.svg" alt="" rtlDiv />
                      </div>
                      <div class="dropdown-menu mobile-dropdown-list" rtlDiv>
                        <div class="mobile-menuItem">
                          <div class="menu-row" (click)="exportToCsv('1')" rtlDiv>
                            <img src="assets/images/sidemenu/mobile-excel.svg" alt="" rtlDiv />
                            <span class="mobile-item-txt">{{ 'OTHER.EXCEL' | translate }}</span>
                          </div>
                          <div class="menu-row" (click)="openAddTicket()" rtlDiv>
                            <img src="assets/images/sidemenu/mobile-add.svg" alt="" rtlDiv />
                            <span class="mobile-item-txt">{{ 'TICKET_MANAGEMENT.ADD_EVENT' | translate }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="full-width" [hidden]="!installationSpinnerActive">
                  <div class="spinner-border mobile-spinner">
                    <div class="spinner"></div>
                  </div>
                </div>
                @if (!installationSpinnerActive && mapInstallationExpanded) {
                  <div class="mobile-map-frame">
                    <app-ticket-management-map (markerSelected)="handleMarkerSelected($event)" [selectedTab]="selectedTab" [filteredCheckOrReplacementValue]="tempCheckOrReplacementFilter" [filteredInstallationValue]="tempInstallationFilter" [filteredCancellationValue]="tempCancellationFilter"></app-ticket-management-map>
                  </div>
                }
                <div id="installationTable" [hidden]="installationSpinnerActive">
                  <table class="mobile-table" mat-table [dataSource]="getInstallationData()" matSort matSortStart="asc"
                    matSortActive="status" multiTemplateDataRows matSortDisableClear (matSortChange)="sortInstallationTicketsData($event)" matSortDirection="desc">
                    <ng-container matColumnDef="ticket_status">
                      <th [hidden]="!ticketManagementInstallationSelectedValues.isTicketStatusChecked" mat-header-cell *matHeaderCellDef mat-sort-header="status" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.STATUS' | translate }} </th>
                      <td [hidden]="!ticketManagementInstallationSelectedValues.isTicketStatusChecked" class="mobile-td-title" mat-cell *matCellDef="let element">
                        <span [ngClass]="{'opened-event' : element.status == 1, 'closed-event' : element.status != 1}">{{element ? element.status_txt : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="event_cause">
                      <th [hidden]="!ticketManagementInstallationSelectedValues.isTicketCauseChecked" mat-header-cell *matHeaderCellDef mat-sort-header="ticket_event_cause_id" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }} </th>
                      <td [hidden]="!ticketManagementInstallationSelectedValues.isTicketCauseChecked" class="mobile-td-title" mat-cell *matCellDef="let element">
                        @if (currentLang != 'iw') {
                          <span class="mobile-td"> {{element ? element.eventCauseEn : ''}}</span>
                        }
                        @if (currentLang == 'iw') {
                          <span class="mobile-td"> {{element ? element.eventCauseHe : ''}}</span>
                        }
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="arrival_date">
                      <th [hidden]="!ticketManagementInstallationSelectedValues.isArrivalDateChecked" mat-header-cell *matHeaderCellDef mat-sort-header="expected_arrival_date" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }} </th>
                      <td [hidden]="!ticketManagementInstallationSelectedValues.isArrivalDateChecked" class="mobile-td-title" mat-cell *matCellDef="let element">
                        <span class="mobile-td">
                          {{currentLang == 'iw' && element ? element.expected_arrival_date_he : element.expected_arrival_date}}
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="ticket_id">
                      <th [hidden]="!ticketManagementInstallationSelectedValues.isTicketIdChecked" mat-header-cell *matHeaderCellDef mat-sort-header="ticket_id" class="mobile-th-title">{{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }}</th>
                      <td [hidden]="!ticketManagementInstallationSelectedValues.isTicketIdChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td"> {{element ? element.ticket_id : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="priority">
                      <th [hidden]="!ticketManagementInstallationSelectedValues.isTicketPriorityChecked" mat-header-cell *matHeaderCellDef mat-sort-header="priority_id" class="mobile-th-title">{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }} </th>
                      <td [hidden]="!ticketManagementInstallationSelectedValues.isTicketPriorityChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <div class="dflex-center" rtlDiv>
                          <span [ngClass]="{'low-priority-dot':element.priority_id == 1,'medium-priority-dot':element.priority_id == 2,'high-priority-dot':element.priority_id == 3}"></span>
                          @if (currentLang != 'iw') {
                            <span class="mobile-td"> {{ element ? element.priorityEn : ''}}</span>
                          }
                          @if (currentLang == 'iw') {
                            <span class="mobile-td"> {{element ? element.priorityHe : ''}}</span>
                          }
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="site_name">
                      <th [hidden]="!ticketManagementInstallationSelectedValues.isTicketSiteChecked" mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.SITE' | translate }} </th>
                      <td [hidden]="!ticketManagementInstallationSelectedValues.isTicketSiteChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td" rtlDiv> {{element ? element.site_name : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_name">
                      <th [hidden]="!ticketManagementInstallationSelectedValues.isBinNameChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }} </th>
                      <td [hidden]="!ticketManagementInstallationSelectedValues.isBinNameChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        @if (element.bin_id == 0 || element.bin_id == '') {
                          <span class="mobile-td"> {{element ? element.bin_name : ''}}</span>
                        }
                        @if (element.bin_id != 0 && element.bin_id != '') {
                          <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'ticketManagement',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="mobile-td"> {{element ? element.bin_name : ''}}</a>
                        }
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_address">
                      <th [hidden]="!ticketManagementInstallationSelectedValues.isTicketAddressChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }} </th>
                      <td [hidden]="!ticketManagementInstallationSelectedValues.isTicketAddressChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td cut-txt" rtlDiv> {{element ? element.bin_address : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="comment">
                      <th [hidden]="!ticketManagementInstallationSelectedValues.isDescChecked" mat-header-cell *matHeaderCellDef mat-sort-header="comment" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }} </th>
                      <td [hidden]="!ticketManagementInstallationSelectedValues.isDescChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td cut-txt" rtlDiv> {{element ? element.comment : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="reporter">
                      <th [hidden]="!ticketManagementInstallationSelectedValues.isReporterChecked" mat-header-cell *matHeaderCellDef mat-sort-header="reporter" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.REPORTER' | translate }} </th>
                      <td [hidden]="!ticketManagementInstallationSelectedValues.isReporterChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td" rtlDiv> {{element ? element.reporter : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                      <th [hidden]="!ticketManagementInstallationSelectedValues.isTicketCreatedChecked" mat-header-cell *matHeaderCellDef mat-sort-header="created_DateTime_value" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.CREATED' | translate }} </th>
                      <td [hidden]="!ticketManagementInstallationSelectedValues.isTicketCreatedChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td" rtlDiv>
                          {{currentLang == 'iw' && element ? element.created_he : element.created}}
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="closed">
                      <th [hidden]="!ticketManagementInstallationSelectedValues.isTicketClosedChecked" mat-header-cell *matHeaderCellDef mat-sort-header="closed_DateTime_value" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.CLOSED' | translate }} </th>
                      <td [hidden]="!ticketManagementInstallationSelectedValues.isTicketClosedChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td">
                          {{currentLang == 'iw' && element ? element.closed_he : element.closed}}
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="arrowDown">
                      <th class="mobile-th-title" rtlDiv mat-header-cell *matHeaderCellDef>
                        <img (click)="openInstallationColumnManaging()" rtlDiv src="assets/images/dashboard/addCol.svg" alt=""/>       
                      </th>
                      <td class="mobile-td-title" mat-cell *matCellDef="let element">
                        <div rtlDiv class="mobile-status-value" (click)="handleExpandedElm(element)">
                          @if (expandedElementTable['ticket_id'] != element['ticket_id']) {
                            <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                          }
                          @if (expandedElementTable['ticket_id'] == element['ticket_id']) {
                            <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                          }
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="expandedDetail">
                      <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="installationColumnsToDisplay.length" rtlDiv>
                        <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element['ticket_id'] == expandedElementTable['ticket_id'] ? 'expanded' : 'collapsed'">
                          <div class="mobile-element-diagram" rtlDiv>
                            <div class="mobile-item-details">
                              @if(!ticketManagementInstallationSelectedValues.isTicketIdChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }}</div>
                                  <div class="mobile-details-value"> {{element ? element.ticket_id : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementInstallationSelectedValues.isTicketCauseChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</div>
                                  <div class="mobile-details-value"> {{currentLang == 'iw' && element ? element.eventCauseHe : element.eventCauseEn}} </div>
                                </div>
                              }
                              @if(!ticketManagementInstallationSelectedValues.isTicketPriorityChecked){
                                @if (user_role_id != 3 && user_role_id != 4) {
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</div>
                                    <div class="mobile-details-value">
                                      <span [ngClass]="{'low-priority-dot':element.priority_id == 1,'medium-priority-dot':element.priority_id == 2,'high-priority-dot':element.priority_id == 3}"></span>
                                      @if (currentLang != 'iw') {
                                        <span> {{ element ? element.priorityEn : ''}}</span>
                                      }
                                      @if (currentLang == 'iw') {
                                        <span> {{element ? element.priorityHe : ''}}</span>
                                      }
                                    </div>
                                  </div>
                                }
                              }
                              @if(!ticketManagementInstallationSelectedValues.isTicketSiteChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.SITE' | translate }}</div>
                                  <div class="mobile-details-value"> {{element ? element.site_name : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementInstallationSelectedValues.isBinNameChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</div>
                                  <div class="mobile-details-value">
                                    @if (element.bin_id == 0 || element.bin_id == '') {
                                      <span> {{element ? element.bin_name : ''}}</span>
                                    }
                                    @if (element.bin_id != 0 && element.bin_id != '') {
                                      <a class="binName" [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'ticketManagement',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}"> {{element ? element.bin_name : ''}}</a>
                                    }
                                  </div>
                                </div>
                              }
                              @if(!ticketManagementInstallationSelectedValues.isTicketAddressChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</div>
                                  <div class="mobile-details-value"> {{element ? element.bin_address : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementInstallationSelectedValues.isDescChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.DESCRIPTION' | translate}}</div>
                                  <div [ngClass]="{'mobile-details-value-rtl':currentLang != 'iw','mobile-details-value-ltr':currentLang == 'iw'}"> {{element ? element.comment : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementInstallationSelectedValues.isReporterChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.REPORTER' | translate}}</div>
                                  <div class="mobile-details-value"> {{element ? (element.first_name == null || element.last_name == null ? '' : element.reporter) : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementInstallationSelectedValues.isTicketCreatedChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.CREATED' | translate}}</div>
                                  <div class="mobile-details-value"> {{currentLang == 'iw' && element ? element.created_he : element.created}} </div>
                                </div>
                              }
                              @if(!ticketManagementInstallationSelectedValues.isArrivalDateChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate}}</div>
                                  <div class="mobile-details-value"> {{currentLang == 'iw' && element ? element.expected_arrival_date_he : element.expected_arrival_date}} </div>
                                </div>
                              }
                              @if(!ticketManagementInstallationSelectedValues.isTicketClosedChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.CLOSED' | translate}}</div>
                                  <div class="mobile-details-value">{{currentLang == 'iw' && element ? element.closed_he : element.closed}}</div>
                                </div>
                              }
                              @if (element.status != 1) {
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.DESCRIPTION' | translate}}</div>
                                  <div [ngClass]="{'mobile-details-value-rtl':currentLang != 'iw','mobile-details-value-ltr':currentLang == 'iw'}"> {{element ? element.resolvedDescription : ''}} </div>
                                </div>
                              }
                            </div>
                            <div class="dFlex padding-border">
                              <div class="mobile-details-value title-width">{{'SITE_MANAGMENT.ACTIONS' | translate}}:</div>
                              <div class="actions-container" rtlDiv>
                                @if ((user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) && element.status == 1) {
                                  <div rtlDiv class="action-frame" (click)="editAction(element,element.status,1)">
                                    <img src="assets/images/sidemenu/edit-pen.svg" alt=""/>
                                    <span class="action-txt">{{ 'TICKET_MANAGEMENT.EDIT' | translate }}</span>
                                  </div>
                                }
                                @if ((user_role_id == 5 || user_role_id == 6) && element.status == 1) {
                                  <div rtlDiv class="action-frame" (click)="resolveAction(element,element.status,1)">
                                    <img src="assets/images/sidemenu/correct-symbol.svg" alt=""/>
                                    <span class="action-txt">{{ 'TICKET_MANAGEMENT.CLOSE' | translate }}</span>
                                  </div>
                                }
                                @if (user_role_id == 5 || user_role_id == 2 || deleteOwnEvent(element)) {
                                  <div rtlDiv class="delete-action-frame" (click)="deleteAction(element,element.status)">
                                    <img src="assets/images/sidemenu/red-bin.svg" alt=""/>
                                    <span class="action-txt">{{ 'TICKET_MANAGEMENT.DELETE' | translate }}</span>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="installationColumnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let element; columns: installationColumnsToDisplay;"
                      (click)="expandedElement = expandedElement === element ? null : element">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                  </table>
                  @if (installationTicketsTableLength > 0) {
                    <div>
                      <app-pagination [chosenBins]="filteredInstallationData" (pageChange)='onInstallationPageChange($event)' [filesPerPage]="10"></app-pagination>
                    </div>
                  }
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="{{'TICKET_MANAGEMENT.CANCELLATION_TAB' | translate}}">
          <ng-template matTabContent>
            <div class="card mobile-card">
              <div class="card-body">
                <div class="mobile-search-excel">
                  <div class='mobile-search-container'>
                    <img class='mobile-search-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                    <input id="mobile-cancellation-filter" class='mobile-input' type="text" (input)="applyCancellationFilter($event.target['value'])" placeholder="{{'OTHER.SEARCH' | translate}}" rtlDiv>
                  </div>
                  <div class="display-icons">
                    @if (mapCancellationExpanded) {
                      <div class="padding-img">
                        <img (click)="expandCancellationMap()" src="assets/images/sidemenu/expand-map.svg" alt="" rtlDiv />
                      </div>
                    }
                    @if (!mapCancellationExpanded) {
                      <div class="padding-img">
                        <img (click)="expandCancellationMap()" src="assets/images/sidemenu/not-expand-map.svg" alt="" rtlDiv />
                      </div>
                    }
                    <div class="padding-img dropdown">
                      <div class="dropdown-toggle" data-bs-toggle="dropdown">
                        <img src="assets/images/sidemenu/menu-dots.svg" alt="" rtlDiv />
                      </div>
                      <div class="dropdown-menu mobile-dropdown-list" rtlDiv>
                        <div class="mobile-menuItem">
                          <div class="menu-row" (click)="exportToCsv('2')" rtlDiv>
                            <img src="assets/images/sidemenu/mobile-excel.svg" alt="" rtlDiv />
                            <span class="mobile-item-txt">{{ 'OTHER.EXCEL' | translate }}</span>
                          </div>
                          <div class="menu-row" (click)="openAddTicket()" rtlDiv>
                            <img src="assets/images/sidemenu/mobile-add.svg" alt="" rtlDiv />
                            <span class="mobile-item-txt">{{ 'TICKET_MANAGEMENT.ADD_EVENT' | translate }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="full-width" [hidden]="!cancellationSpinnerActive">
                  <div class="spinner-border mobile-spinner">
                    <div class="spinner"></div>
                  </div>
                </div>
                @if (!cancellationSpinnerActive && mapCancellationExpanded) {
                  <div class="mobile-map-frame">
                    <app-ticket-management-map (markerSelected)="handleMarkerSelected($event)" [selectedTab]="selectedTab" [filteredCheckOrReplacementValue]="tempCheckOrReplacementFilter" [filteredInstallationValue]="tempInstallationFilter" [filteredCancellationValue]="tempCancellationFilter"></app-ticket-management-map>
                  </div>
                }
                <div id="cancellationTable" [hidden]="cancellationSpinnerActive">
                  <table class="mobile-table" mat-table [dataSource]="getCancellationData()" matSort matSortStart="asc"
                    matSortActive="status" multiTemplateDataRows matSortDisableClear (matSortChange)="sortCancellationTicketsData($event)" matSortDirection="desc">
                    <ng-container matColumnDef="ticket_status">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isTicketStatusChecked" mat-header-cell *matHeaderCellDef mat-sort-header="status" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.STATUS' | translate }} </th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isTicketStatusChecked" class="mobile-td-title" mat-cell *matCellDef="let element">
                        <span [ngClass]="{'opened-event' : element.status == 1, 'closed-event' : element.status != 1}">{{element ? element.status_txt : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="event_cause">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isTicketCauseChecked" mat-header-cell *matHeaderCellDef mat-sort-header="ticket_event_cause_id" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }} </th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isTicketCauseChecked" class="mobile-td-title" mat-cell *matCellDef="let element">
                        @if (currentLang != 'iw') {
                          <span class="mobile-td"> {{element ? element.eventCauseEn : ''}}</span>
                        }
                        @if (currentLang == 'iw') {
                          <span class="mobile-td"> {{element ? element.eventCauseHe : ''}}</span>
                        }
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="arrival_date">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isArrivalDateChecked" mat-header-cell *matHeaderCellDef mat-sort-header="expected_arrival_date" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }} </th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isArrivalDateChecked" class="mobile-td-title" mat-cell *matCellDef="let element">
                        <span class="mobile-td">
                          {{currentLang == 'iw' && element ? element.expected_arrival_date_he : element.expected_arrival_date}}
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="ticket_id">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isTicketIdChecked" mat-header-cell *matHeaderCellDef mat-sort-header="ticket_id" class="mobile-th-title">{{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }}</th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isTicketIdChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td"> {{element ? element.ticket_id : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="priority">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isTicketPriorityChecked" mat-header-cell *matHeaderCellDef mat-sort-header="priority_id" class="mobile-th-title">{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }} </th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isTicketPriorityChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <div class="dflex-center" rtlDiv>
                          <span [ngClass]="{'low-priority-dot':element.priority_id == 1,'medium-priority-dot':element.priority_id == 2,'high-priority-dot':element.priority_id == 3}"></span>
                          @if (currentLang != 'iw') {
                            <span class="mobile-td"> {{ element ? element.priorityEn : ''}}</span>
                          }
                          @if (currentLang == 'iw') {
                            <span class="mobile-td"> {{element ? element.priorityHe : ''}}</span>
                          }
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="site_name">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isTicketSiteChecked" mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.SITE' | translate }} </th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isTicketSiteChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td" rtlDiv> {{element ? element.site_name : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_name">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isBinNameChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }} </th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isBinNameChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        @if (element.bin_id == 0 || element.bin_id == '') {
                          <span class="mobile-td"> {{element ? element.bin_name : ''}}</span>
                        }
                        @if (element.bin_id != 0 && element.bin_id != '') {
                          <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'ticketManagement',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="mobile-td"> {{element ? element.bin_name : ''}}</a>
                        }
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_address">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isTicketAddressChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }} </th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isTicketAddressChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td cut-txt" rtlDiv> {{element ? element.bin_address : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="comment">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isDescChecked" mat-header-cell *matHeaderCellDef mat-sort-header="comment" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }} </th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isDescChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td cut-txt" rtlDiv> {{element ? element.comment : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="reporter">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isReporterChecked" mat-header-cell *matHeaderCellDef mat-sort-header="reporter" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.REPORTER' | translate }} </th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isReporterChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td" rtlDiv> {{element ? element.reporter : ''}}</span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isTicketCreatedChecked" mat-header-cell *matHeaderCellDef mat-sort-header="created_DateTime_value" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.CREATED' | translate }} </th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isTicketCreatedChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td" rtlDiv>
                          {{currentLang == 'iw' && element ? element.created_he : element.created}}
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="closed">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isTicketClosedChecked" mat-header-cell *matHeaderCellDef mat-sort-header="closed_DateTime_value" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.CLOSED' | translate }} </th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isTicketClosedChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        <span class="mobile-td">
                          {{currentLang == 'iw' && element ? element.closed_he : element.closed}}
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="removedReason">
                      <th [hidden]="!ticketManagementCancellationSelectedValues.isRemovedReasonChecked" mat-header-cell *matHeaderCellDef mat-sort-header="removedReason" class="mobile-th-title">
                      {{ 'TICKET_MANAGEMENT.REMOVED_REASON' | translate }} </th>
                      <td [hidden]="!ticketManagementCancellationSelectedValues.isRemovedReasonChecked" mat-cell *matCellDef="let element" class="mobile-td-title">
                        @if (currentLang != 'iw') {
                          <span class="mobile-td"> {{element ? element.reasonOfReplacementEn : ''}}</span>
                        }
                        @if (currentLang == 'iw') {
                          <span class="mobile-td"> {{element ? element.reasonOfReplacementHe : ''}}</span>
                        }
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="arrowDown">
                      <th class="mobile-th-title" rtlDiv mat-header-cell *matHeaderCellDef>
                        <img (click)="openCancellationColumnManaging()" rtlDiv src="assets/images/dashboard/addCol.svg" alt=""/>       
                      </th>
                      <td class=" " mat-cell *matCellDef="let element">
                        <div rtlDiv class="mobile-status-value" (click)="handleExpandedElm(element)">
                          @if (expandedElementTable['ticket_id'] != element['ticket_id']) {
                            <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                          }
                          @if (expandedElementTable['ticket_id'] == element['ticket_id']) {
                            <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                          }
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="expandedDetail">
                      <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="cancellationColumnsToDisplay.length" rtlDiv>
                        <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element['ticket_id'] == expandedElementTable['ticket_id'] ? 'expanded' : 'collapsed'">
                          <div class="mobile-element-diagram" rtlDiv>
                            <div class="mobile-item-details">
                              @if(!ticketManagementCancellationSelectedValues.isTicketIdChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }}</div>
                                  <div class="mobile-details-value"> {{element ? element.ticket_id : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementCancellationSelectedValues.isTicketCauseChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</div>
                                  <div class="mobile-details-value"> {{currentLang == 'iw' && element ? element.eventCauseHe : element.eventCauseEn}} </div>
                                </div>
                              }
                              @if(!ticketManagementCancellationSelectedValues.isTicketPriorityChecked){
                                @if (user_role_id != 3 && user_role_id != 4) {
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</div>
                                    <div class="mobile-details-value">
                                      <span [ngClass]="{'low-priority-dot':element.priority_id == 1,'medium-priority-dot':element.priority_id == 2,'high-priority-dot':element.priority_id == 3}"></span>
                                      @if (currentLang != 'iw') {
                                        <span> {{ element ? element.priorityEn : ''}}</span>
                                      }
                                      @if (currentLang == 'iw') {
                                        <span> {{element ? element.priorityHe : ''}}</span>
                                      }
                                    </div>
                                  </div>
                                }
                              }
                              @if(!ticketManagementCancellationSelectedValues.isTicketSiteChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.SITE' | translate }}</div>
                                  <div class="mobile-details-value"> {{element ? element.site_name : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementCancellationSelectedValues.isBinNameChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</div>
                                  <div class="mobile-details-value">
                                    @if (element.bin_id == 0 || element.bin_id == '') {
                                      <span> {{element ? element.bin_name : ''}}</span>
                                    }
                                    @if (element.bin_id != 0 && element.bin_id != '') {
                                      <a class="binName" [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'ticketManagement',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}"> {{element ? element.bin_name : ''}}</a>
                                    }
                                  </div>
                                </div>
                              }
                              @if(!ticketManagementCancellationSelectedValues.isTicketAddressChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</div>
                                  <div class="mobile-details-value"> {{element ? element.bin_address : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementCancellationSelectedValues.isDescChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.DESCRIPTION' | translate}}</div>
                                  <div [ngClass]="{'mobile-details-value-rtl':currentLang != 'iw','mobile-details-value-ltr':currentLang == 'iw'}"> {{element ? element.comment : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementCancellationSelectedValues.isReporterChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.REPORTER' | translate}}</div>
                                  <div class="mobile-details-value"> {{element ? (element.first_name == null || element.last_name == null ? '' : element.reporter) : ''}} </div>
                                </div>
                              }
                              @if(!ticketManagementCancellationSelectedValues.isTicketCreatedChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.CREATED' | translate}}</div>
                                  <div class="mobile-details-value"> {{currentLang == 'iw' && element ? element.created_he : element.created}} </div>
                                </div>
                              }
                              @if(!ticketManagementCancellationSelectedValues.isArrivalDateChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate}}</div>
                                  <div class="mobile-details-value"> {{currentLang == 'iw' && element ? element.expected_arrival_date_he : element.expected_arrival_date}} </div>
                                </div>
                              }
                              @if(!ticketManagementCancellationSelectedValues.isTicketClosedChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.CLOSED' | translate}}</div>
                                  <div class="mobile-details-value">{{currentLang == 'iw' && element ? element.closed_he : element.closed}}</div>
                                </div>
                              }
                              @if(!ticketManagementCancellationSelectedValues.isRemovedReasonChecked){
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.REMOVED_REASON' | translate}}</div>
                                  <div class="mobile-details-value">{{currentLang == 'iw' && element ? element.reasonOfReplacementHe : element.reasonOfReplacementEn}}</div>
                                </div>
                              }
                              @if (element.status != 1) {
                                <div class="dFlex">
                                  <div class="mobile-details-title">{{'TICKET_MANAGEMENT.DESCRIPTION' | translate}}</div>
                                  <div [ngClass]="{'mobile-details-value-rtl':currentLang != 'iw','mobile-details-value-ltr':currentLang == 'iw'}"> {{element ? element.resolvedDescription : ''}} </div>
                                </div>
                              }
                            </div>
                            <div class="dFlex padding-border">
                              <div class="mobile-details-value title-width">{{'SITE_MANAGMENT.ACTIONS' | translate}}:</div>
                              <div class="actions-container" rtlDiv>
                                @if ((user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) && element.status == 1) {
                                  <div rtlDiv class="action-frame" (click)="editAction(element,element.status,2)">
                                    <img src="assets/images/sidemenu/edit-pen.svg" alt=""/>
                                    <span class="action-txt">{{ 'TICKET_MANAGEMENT.EDIT' | translate }}</span>
                                  </div>
                                }
                                @if ((user_role_id == 5 || user_role_id == 6) && element.status == 1) {
                                  <div rtlDiv class="action-frame" (click)="resolveAction(element,element.status,2)">
                                    <img src="assets/images/sidemenu/correct-symbol.svg" alt=""/>
                                    <span class="action-txt">{{ 'TICKET_MANAGEMENT.CLOSE' | translate }}</span>
                                  </div>
                                }
                                @if (user_role_id == 5 || user_role_id == 2 || deleteOwnEvent(element)) {
                                  <div rtlDiv class="delete-action-frame" (click)="deleteAction(element,element.status)">
                                    <img src="assets/images/sidemenu/red-bin.svg" alt=""/>
                                    <span class="action-txt">{{ 'TICKET_MANAGEMENT.DELETE' | translate }}</span>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="cancellationColumnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let element; columns: cancellationColumnsToDisplay;"
                      (click)="expandedElement = expandedElement === element ? null : element">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                  </table>
                  @if (cancellationTicketsTableLength > 0) {
                    <div>
                      <app-pagination [chosenBins]="filteredCancellationData" (pageChange)='onCancellationPageChange($event)' [filesPerPage]="10"></app-pagination>
                    </div>
                  }
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
}
import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { TranslatorService } from '../../services/translator_service';
import { ResponsiveService } from '../../services/responsive.service';
import { ApiService } from '../../web-services/api/api.service';
import { ApiQuery } from '../../web-services/api/api.query';
import _ from 'lodash';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { MatCardModule } from '@angular/material/card';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-alerts-history',
  standalone: true,
  imports: [MatDividerModule,CommonModule,TranslateModule,ScrollingModule,MatCardModule,RTLDivDirectiveDirective,RouterModule],
  templateUrl: './alerts-history.component.html',
  styleUrl: './alerts-history.component.scss'
})
export class AlertsHistoryComponent {
  isMobile: boolean = false;
	translationsObj : any;
	currentLang : any;
  translate;
  translateSub : any;
  allAlerts:any[] = [];
  @Output() showCMP = new EventEmitter<boolean>(true);
  spinnerActive:boolean = true;
	usageType = 1;
	showDatePicker: Boolean = false;
	selectedDates: any = {
		date: '',
		dateformat: ''
	};
  minDate = '';
  maxDate = '';
	dateLbl:any = '';

  constructor(private translator: TranslatorService, 
		private responsiveService: ResponsiveService,
    private apiService:ApiService,
		private apiQuery:ApiQuery) {
      this.translate = this.translator;
      this.translator.currentLangEmitter$
      .subscribe(async value=>{					 
        this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
        this.currentLang = value;
      })
	}

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.spinnerActive = true;

    this.apiQuery.user$.subscribe((user:any) => {
			if (!user) return;

			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
    });

    this.apiQuery.userAlertsByType$.subscribe((data:any) => {   
      this.spinnerActive = true;
      if(data == null || data.length == 0){return;} 
      this.spinnerActive = false;
      this.allAlerts = [];
      _.each(data, (item) => {
        _.each(item.user_alerts_data, (ua) => {
          this.allAlerts.push(ua);
        });
      });
      let allAretsGrouped: any[]=[]; 
      let res = _.each(_.groupBy(this.allAlerts, "alert_id"), (item) => {  
        let group : any= {
          alert_data : [],
          alert_name : '',
          alert_name_hebrew : '',
          alert_id : 0
        };
        _.each((item), (collection: any) => {  
          if(collection["alert_data"] != null){
            _.each((collection["alert_data"]), (alert) => {
              group["alert_data"].push(alert);
            });
            group["alert_name"] = collection["alert_name"];
            group["alert_name_hebrew"] = collection["alert_name_hebrew"];
            group["alert_id"] = collection["alert_id"];
          }
        }); 
        if(group.alert_id != 0){
          allAretsGrouped.push(group);
        } 
      });
      this.allAlerts = allAretsGrouped;
		}); 
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
  }
  
  validateDateIw(param: any){
    return moment(param).format('MMM DD, HH:mm');
  }

  validateDateEn(param: any){
    return moment(param).format('MMM DD, hh:mm A');
  }
  
  closeCMP(){
		this.showCMP.emit(false);
  }

}

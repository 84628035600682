@if (!isMobile) {
  <div>
    <div>
      <div class="top-container">
        <div class="install-new-bin-header">{{ 'SITE_MANAGMENT.PRE_INSTALLATION_MAP' | translate }} {{displayedName}}</div>
        <mat-divider class="top-divider"></mat-divider>
      </div>
    </div>
    <div class="padded install-new-bin-page">
      <mat-vertical-stepper [linear]="isLinear" #stepper>
        <div>
          <mat-step [stepControl]="firstFormGroup" [completed]="false">
            <form [formGroup]="firstFormGroup" #formDirective="ngForm">
              <mat-form-field [ngClass]="{'hide' : displayedName != ''}">
                <input matInput placeholder="{{ 'SITE_MANAGMENT.BIN_NAME' | translate }}" formControlName="binName" required>
                @if (isFirstFormGroupSubmitted && f1.binName.errors) {
                  <div class="invalid-red-feedback">
                    @if (f1.binName.errors.required) {
                      <div>*{{ 'SITE_MANAGMENT.NAME_REQ' | translate }}*</div>
                    }
                  </div>
                }
                @if (binExists) {
                  <div class="invalid-red-feedback">{{ 'SITE_MANAGMENT.INVALID_BIN_NAME' | translate }}</div>
                }
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="{{ 'SITE_MANAGMENT.BIN_DESCRIPTION' | translate }}" formControlName="binDescription">
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'SITE_MANAGMENT.SELECT_TYPE' | translate }}</mat-label>
                <mat-select formControlName="binType">
                  @for (type of binTypesToDisplay; track type) {
                    <mat-option  [value]="type">
                      <ng-container>
                        <div rtlDiv>
                          <b [ngClass]="{'left':binTypesToDisplay.length > 0 && currentLang != 'iw','right':binTypesToDisplay.length > 0 && currentLang == 'iw'}">{{ type.bin_type_name }}</b>
                        </div>
                      </ng-container>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'SITE_MANAGMENT.SELECT_ASSOCIATED_BIN' | translate }}</mat-label>
                <mat-select formControlName="binClusterId">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="binFilterCtrl" [placeholderLabel]="translationsObj ? translationsObj.SITE_MANAGMENT.SEARCH_BIN : ''" [noEntriesFoundLabel]="'no matching bin found'"></ngx-mat-select-search>
                  </mat-option>
                  @for (clusters of clustersToDisplay; track clusters) {
                    @for (cluster of clusters.binsArr[0]; track cluster) {
                      <!-- [disabled]="clusters.count >= 5" -->
                      @if (clusters.bin_id !== 0) {
                        <mat-option [value]="cluster">
                          <!-- *ngIf="clusters.count < 5; else disabledCluster" -->
                          <ng-container>
                            <div rtlDiv>
                              <b>{{ 'SITE_MANAGMENT.BIN_NAME' | translate }}: </b>{{cluster.bin_name}}, <b>{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}: </b>{{cluster.bin_address}}
                            </div>
                          </ng-container>
                          <!-- <ng-template #disabledCluster>
                          <div style="color: gray" rtlDiv>
                            <b>{{ 'SITE_MANAGMENT.BIN_NAME' | translate }}: </b>{{cluster.bin_name}}, <b>{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}: </b>{{cluster.bin_address}} - Fully Occupied
                          </div>
                        </ng-template> -->
                      </mat-option>
                    } @else {
                      <mat-option [value]="cluster">{{ 'SITE_MANAGMENT.NEW_CLUSTER' | translate }}</mat-option>
                    }
                  }
                }
              </mat-select>
            </mat-form-field>
          </form>
          <form [formGroup]="secondFormGroup" (ngSubmit)="onSecondFormGroupSubmit()">
            <mat-form-field>
              <input matInput placeholder="{{ 'SITE_MANAGMENT.ADDRESS' | translate }}" formControlName="binAddress" required>
              @if (isSecondFormGroupSubmitted && f2.binAddress.errors) {
                <div class="invalid-red-feedback">
                  @if (f2.binAddress.errors.required) {
                    <div>*{{ 'SITE_MANAGMENT.ADDRESS_REQ' | translate }}*</div>
                  }
                </div>
              }
            </mat-form-field>
            <app-map (latLong)="recieveLatLong($event)" (messageEvent)="recieveLocationMsg($event)" (address)="recieveAddress($event)" [showFields]="false"></app-map>
            <div>
              <div [ngClass]="{'hide':!spinnerExist,'showSpinner':spinnerExist}" class="spinner-border mobile-spinner" role="status">
                <span class="sr-only"></span>
              </div>
              <div id="update">
                <button mat-button matStepperNext [disabled]="firstFormGroup.invalid || secondFormGroup.invalid || spinnerExist" class="mat-stepper-next" (click)="preInstallationMapping()">{{ 'SITE_MANAGMENT.UPDATE' | translate }}</button>
              </div>
            </div>
          </form>
        </mat-step>
      </div>
    </mat-vertical-stepper>
  </div>
</div>
}



@if (isMobile) {
  <div>
    <div class="mobile-top-container">
      <div class="mobile-management-header">
        <span class="mobile-left-title">{{ 'SITE_MANAGMENT.PRE_INSTALLATION_MAP' | translate }} {{displayedName}}</span>
      </div>
      <div class="mobile-separate">
        <mat-divider class="mobile-line"></mat-divider>
      </div>
    </div>
    <div class="install-new-bin-page mobile-new-bin">
      <mat-vertical-stepper [linear]="isLinear" #stepper>
        <div>
          <mat-step [stepControl]="firstFormGroup" [completed]="false">
            <form [formGroup]="firstFormGroup" #formDirective="ngForm">
              <mat-form-field [ngClass]="{'hide' : displayedName != ''}">
                <input matInput placeholder="{{ 'SITE_MANAGMENT.BIN_NAME' | translate }}" formControlName="binName" required>
                @if (isFirstFormGroupSubmitted && f1.binName.errors) {
                  <div class="invalid-red-feedback">
                    @if (f1.binName.errors.required) {
                      <div>*{{ 'SITE_MANAGMENT.NAME_REQ' | translate }}*</div>
                    }
                  </div>
                }
                @if (binExists) {
                  <div class="invalid-red-feedback">{{ 'SITE_MANAGMENT.INVALID_BIN_NAME' | translate }}</div>
                }
              </mat-form-field>
              <mat-form-field>
                <input matInput placeholder="{{ 'SITE_MANAGMENT.BIN_DESCRIPTION' | translate }}" formControlName="binDescription">
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'SITE_MANAGMENT.SELECT_TYPE' | translate }}</mat-label>
                <mat-select formControlName="binType">
                  @for (type of binTypesToDisplay; track type) {
                    <mat-option  [value]="type">
                      <ng-container>
                        <div rtlDiv>
                          <b [ngClass]="{'left':binTypesToDisplay.length > 0 && currentLang != 'iw','right':binTypesToDisplay.length > 0 && currentLang == 'iw'}">{{ type.bin_type_name }}</b>
                        </div>
                      </ng-container>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'SITE_MANAGMENT.SELECT_ASSOCIATED_BIN' | translate }}</mat-label>
                <mat-select formControlName="binClusterId">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="binFilterCtrl" [placeholderLabel]="translationsObj ? translationsObj.SITE_MANAGMENT.SEARCH_BIN : ''" [noEntriesFoundLabel]="'no matching bin found'"></ngx-mat-select-search>
                  </mat-option>
                  @for (clusters of clustersToDisplay; track clusters) {
                    @for (cluster of clusters.binsArr[0]; track cluster) {
                      <!-- [disabled]="clusters.count >= 5" -->
                      @if (clusters.bin_id !== 0) {
                        <mat-option [value]="cluster">
                          <!-- *ngIf="clusters.count < 5; else disabledCluster" -->
                          <ng-container>
                            <div rtlDiv>
                              <b>{{ 'SITE_MANAGMENT.BIN_NAME' | translate }}: </b>{{cluster.bin_name}}, <b>{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}: </b>{{cluster.bin_address}}
                            </div>
                          </ng-container>
                          <!-- <ng-template #disabledCluster>
                          <div style="color: gray" rtlDiv>
                            <b>{{ 'SITE_MANAGMENT.BIN_NAME' | translate }}: </b>{{cluster.bin_name}}, <b>{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}: </b>{{cluster.bin_address}} - Fully Occupied
                          </div>
                        </ng-template> -->
                      </mat-option>
                    } @else {
                      <mat-option [value]="cluster">{{ 'SITE_MANAGMENT.NEW_CLUSTER' | translate }}</mat-option>
                    }
                  }
                }
              </mat-select>
            </mat-form-field>
          </form>
          <form [formGroup]="secondFormGroup" (ngSubmit)="onSecondFormGroupSubmit()">
            <mat-form-field>
              <input matInput placeholder="{{ 'SITE_MANAGMENT.ADDRESS' | translate }}" formControlName="binAddress" required>
              @if (isSecondFormGroupSubmitted && f2.binAddress.errors) {
                <div class="invalid-red-feedback">
                  @if (f2.binAddress.errors.required) {
                    <div>*{{ 'SITE_MANAGMENT.ADDRESS_REQ' | translate }}*</div>
                  }
                </div>
              }
            </mat-form-field>
            <app-map (latLong)="recieveLatLong($event)" (messageEvent)="recieveLocationMsg($event)" (address)="recieveAddress($event)"></app-map>
            <div>
              <div [ngClass]="{'hide':!spinnerExist,'showSpinner':spinnerExist}" class="spinner-border mobile-spinner" role="status">
                <span class="sr-only"></span>
              </div>
              <div id="update">
                <button mat-button matStepperNext [disabled]="secondFormGroup.invalid || spinnerExist" class="mat-stepper-next" (click)="preInstallationMapping()">{{ 'SITE_MANAGMENT.UPDATE' | translate }}</button>
              </div>
            </div>
          </form>
        </mat-step>
      </div>
    </mat-vertical-stepper>
  </div>
</div>
}






import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, QueryList, ViewChild, ViewChildren, ViewRef } from '@angular/core';
import { API } from '../../types.api';
import { combineLatest, Subject } from 'rxjs';
import { TranslatorService } from '../../services/translator_service';
import { UtilService } from '../../services/util.service';
import { ResponsiveService } from '../../services/responsive.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiQuery } from '../../web-services/api/api.query';
import _ from 'lodash';
import moment from 'moment';
import { ManageBinsModalComponent } from '../inventory/manage-bins-modal/manage-bins-modal.component';
import { AddClusterModalComponent } from '../inventory/add-cluster-modal/add-cluster-modal.component';
import { GrahamScan } from './manage-panel-modal/graham-scan';
import { takeUntil } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ManagePanelModalComponent } from './manage-panel-modal/manage-panel-modal.component';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { GoogleMap, GoogleMapsModule,MapInfoWindow,MapMarker,MapMarkerClusterer } from '@angular/google-maps';

type ClusterIconInfo = {
  text: string; // Now ensuring text is always a string
  index: number;
  title: string; // Ensuring title is always included
};
type Point = [number, number];
const RADIUS = 54;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
@Component({
  host: {
		'(document:click)': 'handleClick($event)',
	},
  selector: 'app-map-live-view',
  standalone: true,
  imports: [TranslateModule,MatDividerModule,MatCardModule,CommonModule,MatCheckboxModule,MapMarker,
    ManagePanelModalComponent,RTLDivDirectiveDirective,GoogleMapsModule,MapMarkerClusterer,GoogleMap,
	MapInfoWindow],
  templateUrl: './map-live-view.component.html',
  styleUrl: './map-live-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapLiveViewComponent {
	@ViewChildren(MapInfoWindow) infoWindows!: QueryList<MapInfoWindow>;
  mapView: GoogleMap;
  @ViewChild(GoogleMap, { static: false }) set map(m: GoogleMap) {
    if (m) {
      this.mapView = m;
    }
  }
  infoWindow: any;
	infoHtml:any;
	isMobile: Boolean;
	@Output() showCMP = new EventEmitter<boolean>(true);
	bins: any;
	backUpBins : API.Bin[];
	showClusters: boolean = true;
	isDisabled:boolean = false;
	translate;
	spinnerActive:boolean = true;
	sortedBins = [];
	markersArray = [];
	markersArrayDic = {};
	clustersArrDic = {};
	binsHashClustersArr = [];
	binsHash: {};
	binsHashUsingSystem: {};
	usageType = 1;
	private readonly destroy$ = new Subject();
	capacityDisplayType: number;
	types = [];
	translationsObj;
	currentLang;
	items = [];
	binsIncluded:Array<any> = [];
	sitesCosenLength:number = 0;
	moreThanMax:boolean = false;
	mapOptions = {
    minimumClusterSize: 5,
		styles: [{
			height: 53,
			url: "../../assets/images/bins/all/cluster_icon.png",
			width: 52
		},
		{
			height: 53,
			url: "",
			width: 52
		}],
		calculator: (markers) : ClusterIconInfo => {
		  let count = 0;
		  let count1 = 0;
		  let count2 = 0;
		  let count3 = 0;
		  let count4 = 0;

		  for (let i = 0; i < markers.length; i++) {
			if(markers[i].visible){
				if(markers[i].icon.toString().includes("Cluster1")){
					count1++;				  
				}else if(markers[i].icon.toString().includes("Cluster2")){
					count2++;
				}else if(markers[i].icon.toString().includes("Cluster3")){
					count3++;
				}else if(markers[i].icon.toString().includes("Cluster4")){
					count4++;
				}else{
					count++;
				}
			}
		  }
		  if(count1*2 + count2*3 + count3*4 + count4*5 + count > 0){
			return {
				text: (count1*2 + count2*3 + count3*4 + count4*5 + count).toString(),
				index:1,
        		title: (count1*2 + count2*3 + count3*4 + count4*5 + count).toString()
			  };
		  }else{
			return {
				text: "",
				index:2,
        		title: ""
			  }; 
		  }
		}
	  };
	  user_role_id = 0;
	  preventManage: boolean = false;
	  polygon: any;
	  neighborhoodsPolygon: Array<any> = [];
	  FullClustersPolygon: Array<any> = [];
	  SemiClustersPolygon: Array<any> = [];
	  controlButton;
	drawingManager: google.maps.drawing.DrawingManager;
	managerOptions;
	mapOptionsPc: google.maps.MapOptions = {
	streetViewControl: true,
	disableDefaultUI:false,
	mapTypeControl : false,
	fullscreenControl : true,
	fullscreenControlOptions : {
		position: google.maps.ControlPosition.TOP_LEFT
	},
	zoomControl : false
	};

	mobileMapOptions: google.maps.MapOptions = {
		streetViewControl: true,
		mapTypeControl : false,
		clickableIcons : false,
		disableDefaultUI:false,  
		fullscreenControl : true,
		fullscreenControlOptions : {
			position: google.maps.ControlPosition.TOP_LEFT
		},
		zoomControl : false
	};

	constructor(private translator: TranslatorService,
		private sanitizer: DomSanitizer,
		private dialog: MatDialog,
		private responsiveService: ResponsiveService,
		private cd: ChangeDetectorRef,
		private router: Router,
		private apiQuery:ApiQuery,private utilService: UtilService) {
			this.translator.currentLangEmitter$
			.subscribe(async value=>{	
				if(this.controlButton && this.controlButton.src.includes("chevron")){
					document.getElementById('managePanelClicked').style.visibility = 'hidden';
					this.controlButton.src = 'assets/images/dashboard/mapSettings.svg';
				}					
				this.translationsObj = await this.translator.getTranslation(value).toPromise();							 
				this.currentLang = value;	
			})
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.spinnerActive = true;		

		this.managerOptions = {
			drawingMode: null,  // Default mode is set to null
			drawingControl: false,
			drawingControlOptions: {
			  drawingModes: [
				google.maps.drawing.OverlayType.POLYGON,
			  ],
			},
			polygonOptions: {
			  draggable: true,
			  editable: true,
			},
		};

		this.drawingManager = new google.maps.drawing.DrawingManager(this.managerOptions);
	}

	mapClick() {	
		this.infoWindows.forEach((infoWindow: MapInfoWindow ) => {
			infoWindow.close();
		});
	  }

	  openPolygonInfoWindow(event?:any){
		this.mapClick();
		if(this.infoWindow){
			this.infoWindow.close();
		}
		this.infoWindow = new google.maps.InfoWindow();
		let collectedBins = [];
		let avgFillLevel = 0;
		let collectionCoverage = 0;

		this.binsIncluded = [];
		let paths = this.getPaths();
		_.each(this.markersArray, marker => {
			if(marker.visible){
				if(marker.item){
					_.each(marker.item, item => {
						if(this.isWithinCoordinates([item.Bin_location.bin_latitude,item.Bin_location.bin_longitude],paths)){
							this.binsIncluded.push(item);
						}
					})
				}else{
					if(this.isWithinCoordinates([marker.lat,marker.lng],paths)){
						this.binsIncluded.push(marker);
					}
				}
			}
		});
		_.each(this.binsIncluded, (bin) => {
			if(moment(moment(bin.last_collection_time).format('YYYY-MM-DD')).isBetween(moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))){
				collectedBins.push(bin);
			}
			if(bin.fill_level_percent){
				avgFillLevel += bin.fill_level_percent;
			}else{
				if(bin.Bin_live_Data){
					avgFillLevel += bin.Bin_live_Data.fill_level_percent;
				}
			}
		});

		avgFillLevel = Number(Number(avgFillLevel / this.binsIncluded.length).toFixed(0));
		collectionCoverage = Number(Number((collectedBins.length / this.binsIncluded.length) * 100).toFixed(0));
		const progress = avgFillLevel / 100;
		const dashoffset = CIRCUMFERENCE * (1 - progress);
		this.polygon["avgFillLevel"] = isNaN(avgFillLevel) ? 0 : avgFillLevel;
		this.polygon["collectionCoverage"] = isNaN(collectionCoverage) ? 0 : collectionCoverage;

		let contentString = `
		<div>
			<div style="padding-top: 10px;">
				<div style="display: flex;gap: 12px;align-items: center;">
					<img src="assets/images/dashboard/greenBin.svg" alt=""/>
					<div style="display: flex;gap: 4px;align-items: center;">
						<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600; font-size: 14px;color:#2A3338;align-items: center;display: flex">${this.polygon["collectionCoverage"]}%</span>
						<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 400; font-size: 12px;color:#2A3338;">${this.translationsObj.TITLES.COLLECTED_BINS}</span>
					</div>
				</div>
				<div style="display: flex;gap: 12px;padding-top: 20px;align-items: center;">
					<div class="circle">
						<svg style="transform: rotate(-90deg)" width="24" height="24" viewBox="0 0 120 120">
							<circle style="fill: none;stroke: #CDD2D4" class="progress__meter" cx="60" cy="60" r="55" stroke-width="10" />
							<circle style="stroke-dashoffset:${dashoffset};stroke-dasharray:${CIRCUMFERENCE};fill: none;stroke: #28B365;stroke-linecap: round" class="progress__value averageCapacity" cx="60" cy="60" r="55" stroke-width="10" />
						</svg>
					</div>
					<div style="display: flex;gap: 4px;align-items: center;">
						<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600; font-size: 14px;color:#2A3338;">${this.polygon["avgFillLevel"]}%</span>
						<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 400; font-size: 12px;color:#2A3338;">${this.translationsObj.LIVE_INFO.AVG_RATE}</span>
					</div>
				</div>
				<div style="display: flex;gap: 16px;align-items: center;justify-content: center;padding-top: 15px;">        
					${this.user_role_id != 3 ? `<button id="create-cluster" style="display: flex;justify-content: center;align-items: center;padding: 8px 12px;background: rgb(40, 179, 101);border-radius: 30px;border: none;font-family: Open Sans, sans-serif;font-style: normal;font-weight: 400;font-size: 12px;color: white;outline: none;gap: 4px">
					<span>${this.translationsObj.MANAGE_BIN.CREATE_CLUSTER}</span>
					<img src="assets/images/dashboard/edit-whitePen.png" alt="">
					</button>	
					<button id="manage-bins" style="display: flex;justify-content: center;align-items: center;padding: 8px 12px;background: white;border-radius: 30px;border: 1px solid #5D7870;font-family: Open Sans, sans-serif;font-style: normal;font-weight: 400;font-size: 12px;color: #5D7870;outline: none;gap: 4px">
						<span>${this.translationsObj.MANAGE_BIN.MANAGE_BINS}</span>
						<img src="assets/images/dashboard/edit-grayPen.png" alt="">
					</button>` : ``}
					<button id="delete-polygon" style="display: flex;justify-content: center;align-items: center;padding: 8px 12px;background: rgb(239, 131, 120);border-radius: 30px;border: none;font-family: Open Sans, sans-serif;font-style: normal;font-weight: 400;font-size: 12px;color: white;outline: none;gap: 4px">
						<span>${this.translationsObj.SITE_MANAGMENT.REMOVE}</span>
					</button>
				</div>
			</div>
		</div>`;
		this.infoWindow.setContent(contentString);
		if(this.binsIncluded.length > 0 && !event){
			let maxLat = this.binsIncluded[0].lat;
			let minLat = this.binsIncluded[0].lat;
			let maxLng = this.binsIncluded[0].lng;
			let minLng = this.binsIncluded[0].lng;
	
			_.each(this.binsIncluded, (bin) => {
				if(bin.lat > maxLat){
					maxLat = bin.lat;
				}	
				if(bin.lat < minLat){
					minLat = bin.lat;
				}	
				if(bin.lng > maxLng){
					maxLng = bin.lng;
				}	
				if(bin.lng < minLng){
					minLng = bin.lng;
				}			
			});
	
			let centerLat =  minLat + ((maxLat - minLat) / 2);
			let centerLng = minLng + ((maxLng - minLng) / 2);
			this.infoWindow.setPosition({lat: centerLat, lng: centerLng});
		}else if(event){
			this.infoWindow.setPosition(event.latLng);
		}
		this.infoWindow.open(this.mapView.googleMap);
	  }

	  private isWithinCoordinates = (point: Point, polygon: Array<Point>) => {		
		const [lat, lng] = point;		
		var isInside = false;
		for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
		  const iLat = polygon[i][0];
		  const iLng = polygon[i][1];
	  
		  const jLat = polygon[j][0];
		  const jLng = polygon[j][1];
	  
		  const isIntersecting =
			iLng > lng != jLng > lng &&
			lat < ((jLat - iLat) * (lng - iLng)) / (jLng - iLng) + iLat;
	  
		  if (isIntersecting) isInside = !isInside;
		}
	  
		return isInside;
	  };

	  manageBins(){
      if(this.binsIncluded.length > 1){
        this.openManageModalForMulti(this.binsIncluded);
      }
	  }

	  deletePolygon(){
		this.mapClick();
		if (this.polygon) {
			this.polygon.setMap(null);
		}
		if(this.infoWindow){
			this.infoWindow.close();
		}
	  }

	  public openManageModalForMulti(bins){		
		this.dialog.open(ManageBinsModalComponent, { panelClass: 'bulkBins-container' , data: {chosenBins : bins} });
	}
	
	  getPaths() {		
		if (this.polygon) {
		  const vertices = this.polygon.getPaths().getArray()[0];
		  let paths = [];
		  vertices.getArray().forEach(function (xy, i) {
			paths.push([xy.lat(),xy.lng()]);
		  });		  
		  return paths;
		}
		return [];
	  }
	
	  addPolygonChangeEvent(polygon) {
		//this.controlButton.src = 'assets/images/dashboard/mapSettings.svg';
		var me = polygon,
		  isBeingDragged = false,
		  triggerCoordinatesChanged = function () {
			// Broadcast normalized event
			google.maps.event.trigger(me, 'coordinates_changed');
		  };
		  google.maps.event.addListener(me, 'click', (event) => {	
			this.openPolygonInfoWindow(event);
		});
	
		// If  the overlay is being dragged, set_at gets called repeatedly,
		// so either we can debounce that or igore while dragging,
		// ignoring is more efficient
		google.maps.event.addListener(me, 'dragstart', function () {			
		  isBeingDragged = true;
		});
	
		// If the overlay is dragged
		google.maps.event.addListener(me, 'dragend', function () {
		  triggerCoordinatesChanged();
		  isBeingDragged = false;
		});
	
		// Or vertices are added to any of the possible paths, or deleted
		var paths = me.getPaths();
		paths.forEach(function (path, i) {
		  google.maps.event.addListener(path, 'insert_at', function () {
			triggerCoordinatesChanged();
		  });
		  google.maps.event.addListener(path, 'set_at', function () {
			if (!isBeingDragged) {
			  triggerCoordinatesChanged();
			}
		  });
		  google.maps.event.addListener(path, 'remove_at', function () {
			triggerCoordinatesChanged();
		  });
		});
	  }

	handleClick(event) { 
		let manageBins = document.getElementById('manage-bins');
		let createCluster = document.getElementById('create-cluster');
		let deletePolygon = document.getElementById('delete-polygon');
		if(manageBins && manageBins.contains(event.target) && !this.preventManage){
			this.manageBins();
		}else if(createCluster && createCluster.contains(event.target)){
			this.dialog.open(AddClusterModalComponent, { panelClass: 'bulkBins-container', data: {chosenBins : this.binsIncluded} });	
		}else if(deletePolygon && deletePolygon.contains(event.target)){
			this.deletePolygon();
		}	
	}

	openMarkerInfoWindow(marker: MapMarker, markerIndex: number) {
		this.infoWindows.forEach((infoWindow: MapInfoWindow, index: number ) => {
		  if (index === markerIndex) {
			infoWindow.open(marker);
		  } else {
			infoWindow.close();
		  }
		});
	  }

	clickedMarker(bin) {			
		let content = '';								
		if(!bin.cluster){
			let wazeLink = '';
			if(this.isMobile){
				wazeLink+= `<a style="order:2" class="tooltip-waze" href="https://waze.com/ul?ll=${bin.lat},${bin.lng}"><img class="wazeIcon" src="assets/images/dashboard/waze.svg" alt=""></a>`;
			}	
			content = `<div class="iw-content" style="padding-left:unset;padding-right:unset;">
			<div class="tooltip-id">
			<div class="tooltip-value">
			<a href="/binLiveInfoPage?pageName=dashboard&binId=${bin.bin_id}" class="binNameHref-truckMap tooltip-value bin-name">${bin.bin_name}</a>
			<span> | </span>
			<span>${bin.bin_id}</span>
			</div>
			<div class="tooltip-id border-bottom">
			${wazeLink}
			<div class="tooltip-address" title="${bin.bin_address}">${bin.bin_address}</div>
			<div class="tooltip-address" title="${bin.bin_neighbourhood == null ? '' : bin.bin_neighbourhood}">${bin.bin_neighbourhood == null ? '' : bin.bin_neighbourhood}</div>
			</div>
			</div>
			</div>
			<div class="tooltip-id" style="padding-bottom: 9px;">
			<div class="tooltip-text">${this.usageType == 1 ? this.translationsObj.MAP_MARKER.VOLUME : this.translationsObj.MAP_MARKER.VOLUME_D}:</div>
			</div>          
			<div class="tooltip-marker" style="${this.getTooltipStyle(bin.fill_level_percent)}">
			${this.usageType == 1 ? bin.fill_level_percent : bin.bin_max_capacity_in_liters * bin.fill_level_percent / 100}${this.capacityDisplayType !=0 ? 'L' : '%'}
			<div class="tooltip-arrow-marker"></div>
			</div>
			
			<div class="progress_bar" id="progressbar" style="background: ${this.getProgressBarStyleWrapper(bin.color_status_name)};border-radius: 10px;margin-bottom: 5px;display:flex;position: relative;">
			<div class="progress_bar_internal" style="width: ${this.getProgressBarWidthInternal(bin.fill_level_percent)};
			height: 14px;background: ${this.getProgressBarStyleInternal(bin.color_status_name)};border-radius: 10px; line-height: 15px;">
			</div>
			</div>
	
			<div class="last">
			<div class="tooltip-id">
			<div class="tooltip-text-collection">${this.usageType == 1 ? this.translationsObj.LIST_COLUMNS.LAST_COLLECTION : this.translationsObj.LIST_COLUMNS.LAST_REFILL}:&nbsp;</div>		
			<div class="tooltip-text-collection">${(this.currentLang == 'iw' && bin.last_collection_time!='' && bin.last_collection_time!=undefined ? this.utilService.convertDateHebrew(bin.last_collection_time) : this.utilService.convertDateEnglish(bin.last_collection_time))}</div>
			<div class="tooltip-text-collection">${(bin.last_collection_time=='' || bin.last_collection_time==undefined) && this.usageType == 1 ? this.translationsObj.TITLES.LAST_COL : ((bin.last_collection_time=='' || bin.last_collection_time==undefined) && this.usageType != 1 ? this.translationsObj.TITLES.LAST_REFILL : '')}</div>
			</div>
			</div>`;
		}else{						
			let binTooltips = '';
			let wazeLink = '';
			for(let i=0; i < bin.item.length; i++){
				let capacityVolume = bin.item[i].Bin_live_Data.fill_level_percent;
				let capacityDataSymbol = '%';
				if (this.capacityDisplayType != 0) {
					capacityDataSymbol = 'L';
					capacityVolume = bin.item[i].Bin_thresholds.bin_max_capacity_in_liters * capacityVolume / 100;
				}
				if(this.isMobile){
					wazeLink += `<a class="tooltip-waze" href="https://waze.com/ul?ll=${bin.lat},${bin.lng}"><img class="wazeIcon" src="assets/images/dashboard/waze.svg" alt=""></a>`;
				}

				binTooltips += `
				<div class="carousel-item ${i == 0 ? "active" : ""}">
				<div class="iw-content" style="padding-left:unset;padding-right:unset;">
				<div class="tooltip-id">
				<div class="tooltip-value">
				<a class="binNameHref-truckMap tooltip-value bin-name" href="/binLiveInfoPage?pageName=workplans&binId=${bin.item[i].bin_id}" id="clickableItem">${bin.item[i].bin_name}</a>
				<span> | </span>
				<span>${bin.item[i].bin_id}</span>
				</div>
				<div class="tooltip-id border-bottom">
				${wazeLink}
				<div class="tooltip-address">${bin.item[i].Bin_location.bin_address}</div>
				<div class="tooltip-address">${bin.item[i].Bin_location.bin_neighbourhood == null ? "" : bin.item[i].Bin_location.bin_neighbourhood}</div>
				</div>
				</div>
				</div>
				<div class="tooltip-id" style="padding-bottom: 9px;">
				<div class="tooltip-text">${this.translationsObj.MAP_MARKER.VOLUME}:
				</div>
				</div>
				<div class="tooltip-marker" style="${this.getTooltipStyle(bin.item[i].Bin_live_Data.fill_level_percent)}">
				${this.usageType == 1 ? bin.item[i].Bin_live_Data.fill_level_percent : bin.item[i].Bin_thresholds.bin_max_capacity_in_liters * bin.item[i].Bin_live_Data.fill_level_percent / 100}${this.capacityDisplayType !=0 ? 'L' : '%'}
				<div class="tooltip-arrow-marker"></div>
				</div>    
				<div id="progressbar" class="progress_bar" style="background: ${this.getProgressBarStyleWrapper(bin.item[i].Bin_live_Data.color_status_name)};border-radius: 10px;margin-bottom: 5px;display:flex;position: relative;">
				<div class="progress_bar_internal" style="background: ${this.getProgressBarStyleInternal(bin.item[i].Bin_live_Data.color_status_name)};width: ${this.getProgressBarWidthInternal(bin.item[i].Bin_live_Data.fill_level_percent)};height: 14px;border-radius: 10px; line-height: 15px;">
				</div>
				</div>
				<div class="last">
				<div class="tooltip-id">
				<div class="tooltip-text-collection">
				${this.usageType == 1 ? this.translationsObj.LIST_COLUMNS.LAST_COLLECTION : ''}:
				</div>
				<div class="tooltip-text-collection">${this.usageType == 1 ? (this.currentLang == 'iw' && bin.item[i].Bin_live_Data.last_collection_time!='' && bin.item[i].Bin_live_Data.last_collection_time!=undefined ? this.utilService.convertDateHebrew(bin.item[i].Bin_live_Data.last_collection_time) : this.utilService.convertDateEnglish(bin.item[i].Bin_live_Data.last_collection_time)) : ''}</div>
				<div class="tooltip-text-collection">${(bin.item[i].Bin_live_Data.last_collection_time=='' || bin.item[i].Bin_live_Data.last_collection_time==undefined) && this.usageType == 1 ? this.translationsObj.TITLES.LAST_COL : ''}</div>				
				</div>
				</div>
				</div>
				`;
			}
			content = `<button class="carousel-control" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" class="prevNextArrow">
			<img src="assets/images/dashboard/pagination_arrow.png" alt="" class="${this.currentLang != 'iw' ? "rotation" : ""}">
			</button>
			<span style="position:relative;bottom:3px;padding: 0px 8px;">|</span>
			<button class="carousel-control" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
			<img src="assets/images/dashboard/pagination_arrow.png" alt="" class="${this.currentLang == 'iw' ? "rotation" : ""}">
			</button>
			</div>
			<div id="carouselExampleControls" style="top:9px" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
			<div class="carousel-inner">			
				${binTooltips}
			</div>`;
		}
		//data-bs-ride="carousel"
		//this.infoHtml = this.sanitizer.bypassSecurityTrustHtml(content);
		let node;
		node = document.createElement("div");
		node.id = "infoWindow-popup";
		node.className = 'infoWindow-class';
		node.innerHTML = content;

		if((this.sitesCosenLength > 1) || (this.sitesCosenLength == 1 && this.moreThanMax)){
			setTimeout(() => {					
				this.checkIfInfoWindowExist();
				document.getElementById("binInfoWindow_multiSites").append(node);
			}, 100);
		}else if(this.sitesCosenLength == 1 && !this.moreThanMax){
			setTimeout(() => {
				this.checkIfInfoWindowExist();
				document.getElementById("binInfoWindow_singleSite").append(node);
			}, 100);
		}	
	}

	checkIfInfoWindowExist(){
		if(document.getElementById('infoWindow-popup')){
			document.getElementById('infoWindow-popup').remove();
		}
	}
	
	private mapBinsByCoordinates(bins: API.Bin[]) {
		const binsHash = {};
		_.each(bins, b => {
			if (binsHash[b["Bin_location"]["bin_latitude"]+","+b["Bin_location"]["bin_longitude"]] !== undefined) {
				binsHash[b["Bin_location"]["bin_latitude"]+","+b["Bin_location"]["bin_longitude"]].push(b);
			} else {
				binsHash[b["Bin_location"]["bin_latitude"]+","+b["Bin_location"]["bin_longitude"]] = [b];
			}
		});
		return binsHash;
	}

	isNumber(val): boolean { return typeof val === 'number'; }

	sortBinsAccordingToCoordinates(bins: API.Bin[]) {
		for(let i=0; i<bins.length; i++){
			for(let j=0; j<bins.length; j++){
				if(bins[i].bin_id != bins[j].bin_id && bins[i]["site_id"] == bins[j]["site_id"]){					
					if(this.distance(bins[j].Bin_location.bin_latitude, bins[j].Bin_location.bin_longitude, bins[i].Bin_location.bin_latitude, bins[i].Bin_location.bin_longitude, "M") <= 10){
						bins[i]["nearbyBin"] = true;
						bins[j]["nearbyBin"] = true;
					}else{
						if(bins[i]["nearbyBin"] != true && bins[j]["nearbyBin"] != true){
							bins[i]["nearbyBin"] = false;
							bins[j]["nearbyBin"] = false;
						}
					}
				}
			}
		}
		return bins;
	}

	private mapBinsByClusterUsingSystem(bins: API.Bin[]) {
		const binsHashUsingSystem = {};
		_.each(bins, b => {
			if (binsHashUsingSystem[b.cluster_id+b["site_id"]] !== undefined) {
				binsHashUsingSystem[b.cluster_id+b["site_id"]].push(b);
			} else {
				binsHashUsingSystem[b.cluster_id+b["site_id"]] = [b];
			}
		});
		return binsHashUsingSystem;
	}

	private clusterToIcon(cluster){				
		let icon = '';		
		let redCluster = _.find(cluster, function (item) {
			return (item.Bin_live_Data.color_status_name == "RED")
		});	
	
		let yellowCluster = _.find(cluster, function (item) {
			return (item.Bin_live_Data.color_status_name == "YELLOW")
		});	

		let greenCluster = _.find(cluster, function (item) {
			return (item.Bin_live_Data.color_status_name == "GREEN")
		});	
		
		let nonActiveCluster = _.find(cluster, function (item) {
			return (item.bin_collection_mode == 1)
		});	
		if(nonActiveCluster != null){			
			if(cluster.length == 2){
				icon = `../../assets/images/bins/all/nonActiveCluster1.png`;
			}else if(cluster.length == 3){
				icon = `../../assets/images/bins/all/nonActiveCluster2.png`;
			}else if(cluster.length == 4){
				icon = `../../assets/images/bins/all/nonActiveCluster3.png`;
			}else{				
				icon = `../../assets/images/bins/all/nonActiveCluster4.png`;
			}			
			return icon;
		}else if(nonActiveCluster == null && redCluster != null){
			if(cluster.length == 2){
				icon = `../../assets/images/bins/all/redCluster1.png`;
			}else if(cluster.length == 3){
				icon = `../../assets/images/bins/all/redCluster2.png`;
			}else if(cluster.length == 4){
				icon = `../../assets/images/bins/all/redCluster3.png`;
			}else{
				icon = `../../assets/images/bins/all/redCluster4.png`;
			}			
			return icon;
		}else if(nonActiveCluster == null && redCluster == null && yellowCluster != null){
			if(cluster.length == 2){
				icon = `../../assets/images/bins/all/yellowCluster1.png`;
			}else if(cluster.length == 3){
				icon = `../../assets/images/bins/all/yellowCluster2.png`;
			}else if(cluster.length == 4){
				icon = `../../assets/images/bins/all/yellowCluster3.png`;
			}else{
				icon = `../../assets/images/bins/all/yellowCluster4.png`;
			}			
			return icon;
		}else if(nonActiveCluster == null && redCluster == null && yellowCluster == null && greenCluster != null){
			if(cluster.length == 2){
				icon = `../../assets/images/bins/all/greenCluster1.png`;
			}else if(cluster.length == 3){
				icon = `../../assets/images/bins/all/greenCluster2.png`;
			}else if(cluster.length == 4){
				icon = `../../assets/images/bins/all/greenCluster3.png`;
			}else{
				icon = `../../assets/images/bins/all/greenCluster4.png`;
			}			
			return icon;
		}
	}

	private labelOption(bin,icon){				
		let label = {
			text: bin.length.toString(),
			className: "marker-label",
			color: this.convertColotToInt(icon),
			fontSize: "16px",
			fontWeight: "bold"
		}
		return label;
	}

	private convertColotToInt = (icon) => {
		if(icon.toString().includes('red')){
			return "#E01B09";
		}else if(icon.toString().includes('yellow')){
			return "#FAB300";
		}else if(icon.toString().includes('green')){
			return "#245B3E";
		}else{
			return "#68747A";
		}
	}

	private distance(lat1, lon1, lat2, lon2, unit) {
        var radlat1 = Math.PI * lat1/180
        var radlat2 = Math.PI * lat2/180
        var radlon1 = Math.PI * lon1/180
        var radlon2 = Math.PI * lon2/180
        var theta = lon1-lon2
        var radtheta = Math.PI * theta/180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="M") { dist = dist * 1609.34 }
        return dist
	}

	private setMarkersByStatus(bins: API.Bin[]): void {		
		this.sortedBins = this.sortBinsAccordingToCoordinates(bins);	
		this.binsHash = this.mapBinsByCoordinates(this.sortedBins);		
		this.binsHashUsingSystem = this.mapBinsByClusterUsingSystem(this.sortedBins);
		_.forOwn(this.binsHash, (value :any, key) => {			
			if(value.length > 1){
				let isPartOfCluster = false;
				const icon = this.clusterToIcon(value);	
				const checkIfCluster = 	this.mapBinsByClusterUsingSystem(value);	
				_.forOwn(checkIfCluster, (value :any, key) => {
					if(value.length > 1){
						isPartOfCluster = true;
					}					
				})
				const label = this.labelOption(value,icon);								
				const marker = {
					label:label,
					lat: Number(value[0].Bin_location.bin_latitude),
					lng: Number(value[0].Bin_location.bin_longitude),					
					icon: icon,
					category : this.convertMarkerToColor(icon),
					visible: true,
					cluster : true,
					item:value,
					draggable: false,
					site_id: value[0].site_id,
					partOfCluster : isPartOfCluster,
					nearbyBin : value[0].nearbyBin
				};

				if(!this.clustersArrDic[value[0].Bin_location.bin_latitude+","+value[0].Bin_location.bin_longitude]){			
					this.clustersArrDic[value[0].Bin_location.bin_latitude+","+value[0].Bin_location.bin_longitude] = true;
					this.markersArray.push(marker);
				}else{
					this.markersArray.forEach(m=>{
						value.forEach(bin=>{
							if(m.lat == Number(bin.Bin_location.bin_latitude) && m.lng == Number(bin.Bin_location.bin_longitude)){
								m.visible = true;
							}
						})					
					  })
				}                
			}else{
				value.forEach((element, index, array) => {
					const b = element;						
					const icon = this.BinToIcon(b.Bin_live_Data.color_status_id,b["bin_collection_mode"]);
					
					const marker = {
						label:'',
						icon: icon,
						bin_name:b.bin_name,
						bin_id:b.bin_id,
						lat: Number(b.Bin_location.bin_latitude),
						lng: Number(b.Bin_location.bin_longitude),				
						category : this.BinToType(b.Bin_live_Data.color_status_id,b["bin_collection_mode"]),
						fill_level_percent : element.Bin_live_Data.fill_level_percent,
						visible: true,
						site_id: b.site_id,
						cluster : false,
						bin_address : b.Bin_location.bin_address,
						bin_neighbourhood : b.Bin_location.bin_neighbourhood,
						last_collection_time : b.Bin_live_Data.last_collection_time,
						color_status_name : b.Bin_live_Data.color_status_name,
						bin_max_capacity_in_liters : b.Bin_thresholds.bin_max_capacity_in_liters,	
						draggable: false,
						partOfCluster : this.isPartOfCluster(this.binsHashUsingSystem,element),
						nearbyBin : b.nearbyBin		
					};

					if(!this.markersArrayDic[marker.bin_id]){
						this.markersArrayDic[marker.bin_id] = true;
						this.markersArray.push(marker);
					}else{
						this.markersArray.forEach(bin=>{
							if(bin.bin_id == element.bin_id){
								bin.visible = true;
							}
						})
					}					
				});
			}
		});	
		this.binsHashClustersArr = [];		
		this.sitesCosenLength = 0;			
		_.each(this.markersArray, b => {
			if (this.binsHashClustersArr[b["site_id"]] != undefined) {
				this.binsHashClustersArr[b["site_id"]].push(b);
			} else {
				this.binsHashClustersArr[b["site_id"]] = [b];
			}
		});	
		_.forOwn(this.binsHashClustersArr, (value: any, key) => {
			if(value !== undefined && value[0].visible && value[0].visible){
				this.sitesCosenLength++;
			}
		})	

		let not_same_linked_sites_id = true;
		if(JSON.parse(sessionStorage.getItem("chosenSites"))){
			let sitesToCompare = [];
			let isChildsSites = false;
			_.each(JSON.parse(sessionStorage.getItem("chosenSites")), site => {
				if(site != 0){
					sitesToCompare.push(site);
				}
			});	
			isChildsSites = sitesToCompare.some(site => site.linked_sites_id != 0);
			if(sitesToCompare.length == 1){
				not_same_linked_sites_id = false;
			}else{
				if(isChildsSites){
					not_same_linked_sites_id = sitesToCompare.some(site => site.linked_sites_id != sitesToCompare[0].linked_sites_id);
				}
			}
		}

		if(sessionStorage.getItem("chosenSites") && JSON.parse(sessionStorage.getItem("chosenSites")).length == 1){
			if(this.binsHashClustersArr[JSON.parse(sessionStorage.getItem("chosenSites"))[0].site_id].length > 500){
				this.moreThanMax = true;
			}else{
				this.moreThanMax = false;
			}
		}

		if(!not_same_linked_sites_id){
			if(!document.getElementById('settings-id') && !this.isMobile){
				const centerControlDiv = document.createElement('div');
				centerControlDiv.id = 'settings-id';
				centerControlDiv.style.margin = '14px 24px';
				centerControlDiv.style.width = '32px';
				centerControlDiv.style.height = '32px';
				centerControlDiv.style.background = 'white';
				centerControlDiv.style.textAlign = 'center';
				const centerControl = this.createCenterControl();
				centerControlDiv.appendChild(centerControl);
				this.mapView.googleMap.controls[google.maps.ControlPosition.TOP_RIGHT].push(centerControlDiv);
			}
			if(document.getElementById("settings-id")){
				document.getElementById("settings-id").style.display = 'block';			
			}

			this.managerOptions.drawingControl = true;
			this.managerOptions.drawingMode = null;
			this.managerOptions.drawingControlOptions = {
				position: google.maps.ControlPosition.TOP_CENTER,
				drawingModes: [
				  google.maps.drawing.OverlayType.POLYGON
				]
			};
			this.drawingManager = new google.maps.drawing.DrawingManager(this.managerOptions);
			this.drawingManager.setMap(this.mapView.googleMap);

			google.maps.event.addListener(
				this.drawingManager,
				'overlaycomplete',
				(event) => {
				  // Polygon drawn
				  if (event.type === google.maps.drawing.OverlayType.POLYGON) {
					//this is the coordinate, you can assign it to a variable or pass into another function.
					if (this.polygon) {
					  this.polygon.setMap(null);
					}
					this.polygon = event.overlay;	
					this.addPolygonChangeEvent(this.polygon);
					this.openPolygonInfoWindow();
				  }
				}
			  );
		}else{
			if(document.getElementById("settings-id")){
				document.getElementById("settings-id").style.display = 'none';			
			}
			this.drawingManager.setMap(null);
		}
	}

	isPartOfCluster(clusters,elm){
		if(clusters[elm.cluster_id+elm["site_id"]].length > 1){
			return true;
		}else{
			return false;
		}
	}
	  
	private BinToType(status,mode): string {
		let icon = '';
		if(mode == "1"){
			icon = "Non Active";
		}else{
			if(status == "0"){
				icon = 'Green Bins';
			}else if(status == "1"){
				icon = 'Yellow Bins';
			}else if(status == "2"){
				icon = 'Red Bins';
			}
		}
		return icon;
	}

	private BinToIcon(status,mode) {
		let icon = '';
		if(mode == "1"){
			icon = `../../assets/images/bins/all/nonActive.svg`;
			return icon;
		}else{
			if(this.usageType == 1){
				if(status == 0){
					icon = `../../assets/images/bins/all/greenNotOrdered.png`;
				}else if(status == 1){
					icon = `../../assets/images/bins/all/yellowNotOrdered.png`;
				}else if(status == 2){
					icon = `../../assets/images/bins/all/redNotOrdered.png`;
				}
				return icon;
			}else{
				if(status == 0){
					icon = `../../assets/images/bins/all/greenNotOrdered2.png`;
				}else if(status == 1){
					icon = `../../assets/images/bins/all/yellowNotOrdered2.png`;
				}else if(status == 2){
					icon = `../../assets/images/bins/all/redNotOrdered2.png`;
				}
				return icon;
			}
		}
	}

	getProgressBarStyleWrapper = (binInfo: any) => {		
		if (!binInfo) { return ''; }
		if (binInfo == "GREEN") {
			return 'rgba(52, 208, 141, 0.15)';
		} else if (binInfo == "YELLOW") {
			return 'rgba(226, 224, 85, 0.15)';
		}
		return 'rgba(215, 89, 76, 0.15)';
  }

  getProgressBarStyleInternal = (binInfo: any) => {
	if (!binInfo) { return ''; }
	if (binInfo == "GREEN") {
		return '#34D08D';
	} else if (binInfo == "YELLOW") {
		return '#E2E055';
	}
	return '#D7594C';
  }

  getProgressBarWidthInternal = (binInfo: any) => {
	if (!binInfo) { return ''; }
	return `${binInfo}%`;
  }
  
  

	private convertMarkerToColor(icon){
		if(icon.toLowerCase().includes("red")){
			return "Red Bins";
		}else if(icon.toLowerCase().includes("yellow")){
			return "Yellow Bins";
		}else if(icon.toLowerCase().includes("green")){
			return "Green Bins";
		}else{
			return "Non Active";
		}
	}

	private resetMarkers() {
		this.markersArray = [];	
		this.markersArrayDic = {};
		this.clustersArrDic = {};	
		_.each(this.binsHashClustersArr, (clusters) => {
			_.each(clusters, (marker) => {
				marker.visible = false;
			});
		});
		this.cd.detectChanges();
	}

	closeCMP(){
		this.showCMP.emit(false);
	}

	filterClusters(){	
		_.each(this.markersArray, (marker) => {			
			if ((marker["partOfCluster"] && marker["cluster"]) || marker["nearbyBin"]) {				
				 marker["visible"] = true;							
			}else{
				marker["visible"] = false;				
			}
		});

	}

	filterClustersAndColors(){	
		if(!this.showClusters){	
			this.fliterMarkers();
		}else if(this.showClusters){
			this.filterClusters();
		}
		let prevZoom = this.mapView.googleMap.getZoom();
		this.mapView.googleMap.setZoom(-12);
		this.mapView.googleMap.setZoom(prevZoom);
	}

	fliterMarkers() {				
		let categories = ['Red Bins', 'Green Bins', 'Yellow Bins', 'Non Active'];		
		categories = this.types;		
		_.each(this.markersArray, (marker) => {			
			if (categories.includes(marker["category"])) {
				marker.visible = true;
			} else {
				marker.visible = false;
			}
		});
	}

	handleResetClicked = (reset) => {
		this.mapClick();
		_.each(this.neighborhoodsPolygon, (polygon) => {
			polygon.setMap(null);
		});

		_.each(this.FullClustersPolygon, (polygon) => {
			polygon.setMap(null);	
		});

		_.each(this.SemiClustersPolygon, (polygon) => {
			polygon.setMap(null);
		});
	}

	handleClusterClicked = (cluster) => {
		if(cluster){
			this.showClustersbtn();
		}else{
			this.hideClustersbtn();
		}
	}

	handleNeighborhoodsSelected = (neighborhood) => {
		let coordinates = [];
		let polygonBounds = [];
		let collectedBins = [];
		let avgFillLevel = 0;
		let collectionCoverage = 0;
		if(neighborhood.selected){
			const filteredBins = _.filter(this.backUpBins, (bin) => {
				return neighborhood.value.nbrh_name == bin.Bin_location["bin_neighbourhood"];
			});
	
			_.each(filteredBins, (bin) => {
				if(moment(moment(bin.Bin_live_Data.last_collection_time).format('YYYY-MM-DD')).isBetween(moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))){
					collectedBins.push(bin);
				}
				avgFillLevel += bin.Bin_live_Data["fill_level_percent"];
				coordinates.push([bin["Bin_location"]["bin_latitude"],bin["Bin_location"]["bin_longitude"]]);
			});

			avgFillLevel = Number(Number(avgFillLevel / filteredBins.length).toFixed(0));
			collectionCoverage = Number(Number((collectedBins.length / filteredBins.length) * 100).toFixed(0));
			const progress = avgFillLevel / 100;
			const dashoffset = CIRCUMFERENCE * (1 - progress);	
			if(filteredBins.length > 2){
				const grahamScan = new GrahamScan();
				grahamScan.setPoints(coordinates);
				const hull = grahamScan.getHull();

				_.each(hull, (boundary) => {
					polygonBounds.push({lat : boundary[0],lng : boundary[1]});
				});
			}else{
				_.each(coordinates, (boundary) => {
					polygonBounds.push({lat : boundary[0],lng : boundary[1]});
				});
			}
	
			const neighborhoodsPolygon = new google.maps.Polygon({
				paths: polygonBounds,
				strokeColor: "#8750FC",
				strokeWeight: 2,
				fillColor: "#8750fc",
			});
			neighborhoodsPolygon["id"] = neighborhood.value.nbrh_name;
			neighborhoodsPolygon["binsIncluded"] = neighborhood.value.num_of_bins_in_nbrh;
			neighborhoodsPolygon["avgFillLevel"] = avgFillLevel;
			neighborhoodsPolygon["collectionCoverage"] = collectionCoverage;
			neighborhoodsPolygon.setMap(this.mapView.googleMap);
			this.neighborhoodsPolygon.push(neighborhoodsPolygon);

			var bounds = new google.maps.LatLngBounds();
			neighborhoodsPolygon.getPath().forEach(function(path, index) {
				bounds.extend(path);
			});
			this.mapView.googleMap.fitBounds(bounds);
			neighborhoodsPolygon.addListener("click", (event) => {
				this.mapClick();
				if(this.infoWindow){
					this.infoWindow.close();
				}
				this.infoWindow = new google.maps.InfoWindow();
				let contentString = `
				<div>
					<div style="font-family: Open Sans, sans-serif;display: flex;gap: 4px;font-style: normal;color: #2A3338;border-bottom: 1px solid #CDD2D4;padding-bottom: 10px;">
						<span style="font-weight: 700;">${neighborhoodsPolygon["id"]}</span>
						<span> | </span>
						<span style="font-weight: 600;">${this.translationsObj.TITLES.TOTAL_BINS}</span>
						<span style="font-weight: 600;">(${neighborhoodsPolygon["binsIncluded"]})</span>
					</div>
					<div style="padding-top: 10px;">
						<div style="display: flex;gap: 12px;align-items: center;">
							<img src="assets/images/dashboard/greenBin.svg" alt=""/>
							<div style="display: flex;gap: 4px;">
								<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600; font-size: 14px;color:#2A3338;align-items: center;display: flex">${neighborhoodsPolygon["collectionCoverage"]}%</span>
								<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 400; font-size: 12px;color:#2A3338;">${this.translationsObj.TITLES.COLLECTED_BINS}</span>
							</div>
						</div>
						<div style="display: flex;gap: 12px;padding-top: 20px;align-items: center;">
							<div class="circle">
								<svg style="transform: rotate(-90deg)" width="24" height="24" viewBox="0 0 120 120">
									<circle style="fill: none;stroke: #CDD2D4" class="progress__meter" cx="60" cy="60" r="55" stroke-width="10" />
									<circle style="stroke-dashoffset:${dashoffset};stroke-dasharray:${CIRCUMFERENCE};fill: none;stroke: #28B365;stroke-linecap: round" class="progress__value averageCapacity" cx="60" cy="60" r="55" stroke-width="10" />
								</svg>
							</div>
							<div style="display: flex;gap: 4px;">
								<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600; font-size: 14px;color:#2A3338;">${neighborhoodsPolygon["avgFillLevel"]}%</span>
								<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 400; font-size: 12px;color:#2A3338;">${this.translationsObj.LIVE_INFO.AVG_RATE}</span>
							</div>
						</div>
					</div>
				</div>`;
				this.infoWindow.setContent(contentString);
				this.infoWindow.setPosition(event.latLng);
				this.infoWindow.open(this.mapView.googleMap);
			});
		}else{
			_.each(this.neighborhoodsPolygon, (polygon) => {
				if(polygon["id"] == neighborhood.value.nbrh_name){
					polygon.setMap(null);
				}
			});
		}
	}

	handleFullClusterSelection = (fullCluster) => {
		let coordinates = [];
		let polygonBounds = [];
		let collectedBins = [];
		let avgFillLevel = 0;
		let collectionCoverage = 0;
		if(fullCluster.selected){
			const filteredBins = _.filter(this.backUpBins, (bin) => {
				return fullCluster.value.cluster_id == bin.cluster_id;
			});
			_.each(filteredBins, (bin) => {
				if(moment(moment(bin.Bin_live_Data.last_collection_time).format('YYYY-MM-DD')).isBetween(moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))){
					collectedBins.push(bin);
				}
				avgFillLevel += bin.Bin_live_Data["fill_level_percent"];
				coordinates.push([bin["Bin_location"]["bin_latitude"],bin["Bin_location"]["bin_longitude"]]);
			});

			avgFillLevel = Number(Number(avgFillLevel / filteredBins.length).toFixed(0));
			collectionCoverage = Number(Number((collectedBins.length / filteredBins.length) * 100).toFixed(0));

			const progress = avgFillLevel / 100;
			const dashoffset = CIRCUMFERENCE * (1 - progress);	

			if(filteredBins.length > 2){
				const grahamScan = new GrahamScan();
				grahamScan.setPoints(coordinates);
				const hull = grahamScan.getHull();

				_.each(hull, (boundary) => {
					polygonBounds.push({lat : boundary[0],lng : boundary[1]});
				});
			}else{
				_.each(coordinates, (boundary) => {
					polygonBounds.push({lat : boundary[0],lng : boundary[1]});
				});
			}
	
			const FullClusterPolygon = new google.maps.Polygon({
				paths: polygonBounds,
				strokeColor: "#64A5F2",
				strokeWeight: 2,
				fillColor: "#006eef",
			});
			FullClusterPolygon["id"] = fullCluster.value.cluster_id;
			FullClusterPolygon["binsIncluded"] = fullCluster.value.num_of_bins_in_cluster;
			FullClusterPolygon["avgFillLevel"] = avgFillLevel;
			FullClusterPolygon["collectionCoverage"] = collectionCoverage;
			FullClusterPolygon.setMap(this.mapView.googleMap);
			this.FullClustersPolygon.push(FullClusterPolygon);

			var bounds = new google.maps.LatLngBounds();
			FullClusterPolygon.getPath().forEach(function(path, index) {
				bounds.extend(path);
			});
			this.mapView.googleMap.fitBounds(bounds);
			FullClusterPolygon.addListener("click", (event) => {
				this.mapClick();
				if(this.infoWindow){
					this.infoWindow.close();
				}
				this.infoWindow = new google.maps.InfoWindow();
				let contentString = `
				<div>
					<div style="font-family: Open Sans, sans-serif;display: flex;gap: 4px;font-style: normal;color: #2A3338;border-bottom: 1px solid #CDD2D4;padding-bottom: 10px;">
						<span style="font-weight: 700;">${this.translationsObj.SITE_MANAGMENT.CLUSTER} ${FullClusterPolygon["id"]}</span>
						<span> | </span>
						<span style="font-weight: 600;">${this.translationsObj.TITLES.TOTAL_BINS}</span>
						<span style="font-weight: 600;">(${FullClusterPolygon["binsIncluded"]})</span>
					</div>
					<div style="padding-top: 10px;">
						<div style="display: flex;gap: 12px;align-items: center;">
							<img src="assets/images/dashboard/greenBin.svg" alt=""/>
							<div style="display: flex;gap: 4px;">
								<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600; font-size: 14px;color:#2A3338;align-items: center;display: flex">${FullClusterPolygon["collectionCoverage"]}%</span>
								<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 400; font-size: 12px;color:#2A3338;">${this.translationsObj.TITLES.COLLECTED_BINS}</span>
							</div>
						</div>
						<div style="display: flex;gap: 12px;padding-top: 20px;align-items: center;">
							<div class="circle">
								<svg style="transform: rotate(-90deg)" width="24" height="24" viewBox="0 0 120 120">
									<circle style="fill: none;stroke: #CDD2D4" class="progress__meter" cx="60" cy="60" r="55" stroke-width="10" />
									<circle style="stroke-dashoffset:${dashoffset};stroke-dasharray:${CIRCUMFERENCE};fill: none;stroke: #28B365;stroke-linecap: round" class="progress__value averageCapacity" cx="60" cy="60" r="55" stroke-width="10" />
								</svg>
							</div>
							<div style="display: flex;gap: 4px;">
								<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600; font-size: 14px;color:#2A3338;">${FullClusterPolygon["avgFillLevel"]}%</span>
								<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 400; font-size: 12px;color:#2A3338;">${this.translationsObj.LIVE_INFO.AVG_RATE}</span>
							</div>
						</div>
					</div>
				</div>`;
				this.infoWindow.setContent(contentString);
				this.infoWindow.setPosition(event.latLng);
				this.infoWindow.open(this.mapView.googleMap);
			});
		}else{
			_.each(this.FullClustersPolygon, (polygon) => {
				if(polygon["id"] == fullCluster.value.cluster_id){
					polygon.setMap(null);
				}
			});
		}
	}

	handleSemiClusterSelection = (semiCluster) => {
		let coordinates = [];
		let polygonBounds = [];
		let collectedBins = [];
		let avgFillLevel = 0;
		let collectionCoverage = 0;
		if(semiCluster.selected){
			const filteredBins = _.filter(this.backUpBins, (bin) => {
				return semiCluster.value.cluster_id == bin.cluster_id;
			});
			_.each(filteredBins, (bin) => {
				if(moment(moment(bin.Bin_live_Data.last_collection_time).format('YYYY-MM-DD')).isBetween(moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))){
					collectedBins.push(bin);
				}
				avgFillLevel += bin.Bin_live_Data["fill_level_percent"];
				coordinates.push([bin["Bin_location"]["bin_latitude"],bin["Bin_location"]["bin_longitude"]]);
			});

			avgFillLevel = Number(Number(avgFillLevel / filteredBins.length).toFixed(0));
			collectionCoverage = Number(Number((collectedBins.length / filteredBins.length) * 100).toFixed(0));
			const progress = avgFillLevel / 100;
			const dashoffset = CIRCUMFERENCE * (1 - progress);	

			if(filteredBins.length > 2){
				const grahamScan = new GrahamScan();
				grahamScan.setPoints(coordinates);
				const hull = grahamScan.getHull();

				_.each(hull, (boundary) => {
					polygonBounds.push({lat : boundary[0],lng : boundary[1]});
				});
			}else{
				_.each(coordinates, (boundary) => {
					polygonBounds.push({lat : boundary[0],lng : boundary[1]});
				});
			}
	
			const SemiClusterPolygon = new google.maps.Polygon({
				paths: polygonBounds,
				strokeColor: "#006EEF",
				strokeWeight: 2,
				fillColor: "#006eef",
			});
			SemiClusterPolygon["id"] = semiCluster.value.cluster_id;
			SemiClusterPolygon["binsIncluded"] = semiCluster.value.num_of_bins_in_cluster;
			SemiClusterPolygon["avgFillLevel"] = avgFillLevel;
			SemiClusterPolygon["collectionCoverage"] = collectionCoverage;
			SemiClusterPolygon.setMap(this.mapView.googleMap);
			this.SemiClustersPolygon.push(SemiClusterPolygon);

			var bounds = new google.maps.LatLngBounds();
			SemiClusterPolygon.getPath().forEach(function(path, index) {
				bounds.extend(path);
			});
			this.mapView.googleMap.fitBounds(bounds);
			SemiClusterPolygon.addListener("click", (event) => {
				this.mapClick();
				if(this.infoWindow){
					this.infoWindow.close();
				}
				this.infoWindow = new google.maps.InfoWindow();
				let contentString = `
				<div>
					<div style="font-family: Open Sans, sans-serif;display: flex;gap: 4px;font-style: normal;color: #2A3338;border-bottom: 1px solid #CDD2D4;padding-bottom: 10px;">
						<span style="font-weight: 700;">${SemiClusterPolygon["id"]}</span>
						<span> | </span>
						<span style="font-weight: 600;">${this.translationsObj.TITLES.TOTAL_BINS}</span>
						<span style="font-weight: 600;">(${SemiClusterPolygon["binsIncluded"]})</span>
					</div>
					<div style="padding-top: 10px;">
						<div style="display: flex;gap: 12px;align-items: center;">
							<img src="assets/images/dashboard/greenBin.svg" alt=""/>
							<div style="display: flex;gap: 4px;">
								<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600; font-size: 14px;color:#2A3338;align-items: center;display: flex">${SemiClusterPolygon["collectionCoverage"]}%</span>
								<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 400; font-size: 12px;color:#2A3338;">${this.translationsObj.TITLES.COLLECTED_BINS}</span>
							</div>
						</div>
						<div style="display: flex;gap: 12px;padding-top: 20px;align-items: center;">
							<div class="circle">
								<svg style="transform: rotate(-90deg)" width="24" height="24" viewBox="0 0 120 120">
									<circle style="fill: none;stroke: #CDD2D4" class="progress__meter" cx="60" cy="60" r="55" stroke-width="10" />
									<circle style="stroke-dashoffset:${dashoffset};stroke-dasharray:${CIRCUMFERENCE};fill: none;stroke: #28B365;stroke-linecap: round" class="progress__value averageCapacity" cx="60" cy="60" r="55" stroke-width="10" />
								</svg>
							</div>
							<div style="display: flex;gap: 4px;">
								<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600; font-size: 14px;color:#2A3338;">${SemiClusterPolygon["avgFillLevel"]}%</span>
								<span style="font-family: Open Sans, sans-serif;font-style: normal;font-weight: 400; font-size: 12px;color:#2A3338;">${this.translationsObj.LIVE_INFO.AVG_RATE}</span>
							</div>
						</div>
					</div>
				</div>`;
				this.infoWindow.setContent(contentString);
				this.infoWindow.setPosition(event.latLng);
				this.infoWindow.open(this.mapView.googleMap);
			});
		}else{
			_.each(this.SemiClustersPolygon, (polygon) => {
				if(polygon["id"] == semiCluster.value.cluster_id){
					polygon.setMap(null);
				}
			});
		}
	}

	public showClustersbtn = () => {
		if(this.isDisabled){return;}
		this.showClusters = true;	
		this.filterClustersAndColors();					
	}

	public hideClustersbtn = () => {
		if(this.isDisabled){return;}
		this.showClusters = false;	
		this.filterClustersAndColors();					
	}

	infoWindowClose(infoWindow) {
	}

	getTooltipStyle = (binInfo: any) => {		
		if(this.currentLang == 'iw'){
			return `margin-right: ${binInfo-7}%`;
		}else{
			return `margin-left: ${binInfo-7}%`;
		}
	  }

	applyFilter = (filterValue) => {
    filterValue = filterValue.value;
		let filteredArr = [];
		if(this.backUpBins != undefined && this.backUpBins.length > 0){
			filterValue = filterValue.trim(); 
			if (filterValue === "") {
				const location = {
					lat: Number(this.backUpBins[0].Bin_location.bin_latitude.toString()),
					lng: Number(this.backUpBins[0].Bin_location.bin_longitude.toString())
				};
	
				this.loadAPIWrapperInternal(this.mapView.googleMap, location,this.backUpBins);
			}
			else{
				_.each(this.backUpBins, (item) => {										
					if((item["bin_id"].toString().includes(filterValue)) || (item["bin_name"].toString().includes(filterValue)) || ((item["Bin_location"]["bin_neighbourhood"] != undefined) && (item["Bin_location"]["bin_neighbourhood"].toString().includes(filterValue))) || (item["Bin_location"]["bin_address"].toString().includes(filterValue))){
						filteredArr.push(item);	
					}			
				});

				this.bins = [];
				this.bins = filteredArr;				

				if(this.bins != undefined && this.bins.length > 0){
					const location = {
						lat: Number(this.bins[0].Bin_location.bin_latitude),
						lng: Number(this.bins[0].Bin_location.bin_longitude)
					};					
					this.loadAPIWrapperInternal(this.mapView.googleMap, location,this.bins);
				}else{
					this.loadAPIWrapperInternal(this.mapView.googleMap, null,this.backUpBins);
				}
			}

		}
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

	loadAPIWrapper(map: any) {
		this.addYourLocationButton(map);				
		setTimeout(() => {
			combineLatest(
				this.apiQuery.user$,
				this.apiQuery.filteredBasicBins$
			  )
			  .pipe(takeUntil(this.destroy$))
			  .subscribe(([user,bins])=>{				  			
				this.spinnerActive = true;
				if (!user) return;
				if(!bins || bins.length == 0){return;}
				if(this.polygon){
					this.polygon.setMap(null);
				}
				this.spinnerActive = false;
				this.bins = bins;			
				this.backUpBins = this.bins;
				this.types = [];
				this.items = [
					{ displayName: 'WIDGETS.RED_BINS', name: 'Red Bins', selected: false, color:"#EF8378"},
					{ displayName: 'WIDGETS.YELLOW_BINS', name: 'Yellow Bins', selected: false, color:"#E6E67E" },
					{ displayName: 'WIDGETS.GREEN_BINS', name: 'Green Bins', selected: false, color:"#66D699" },
					{ displayName: 'WIDGETS.NON_ACTIVE', name: 'Non Active', selected: false, color:"#9DA9AD" }
					];
				this.selectUnselectItem(this.items[0]);
				this.selectUnselectItem(this.items[1]);
				this.selectUnselectItem(this.items[2]);	
				this.selectUnselectItem(this.items[3]);	
				this.capacityDisplayType = user["user_default_capacity_type_id"];
				if(user["usage_type_id"] != 1){
					this.usageType = user["usage_type_id"];
				}
				if(user["user_role_id == 3"]){
					this.preventManage = true;
				}
				this.user_role_id = user["user_role_id"];
				this.resetMarkers();
				this.setMarkersByStatus(this.backUpBins);
				this.filterClustersAndColors();	
				this.loadAPIWrapperInternal(map, null,this.backUpBins);
	
				if(document.getElementById('managePanelClicked') && 
				document.getElementById('managePanelClicked').style.visibility == 'visible'){
					document.getElementById('managePanelClicked').style.visibility = 'hidden';
					this.controlButton.src = 'assets/images/dashboard/mapSettings.svg';
				}
				try {
					this.cd.detectChanges();
				}catch (error) {

				}
			  })
		}, 1200);
	}

	createCenterControl() {
		this.controlButton = document.createElement('img');	  
		this.controlButton.style.background = 'none padding-box rgb(255, 255, 255)';
		this.controlButton.style.position = 'relative';
		this.controlButton.style.cursor = 'pointer';
		this.controlButton.style.borderBottomRightRadius = '2px';
		this.controlButton.style.borderTopRightRadius = '2px';
		this.controlButton.style.width = '16px';
		this.controlButton.style.height = '100%';
		this.controlButton.src = 'assets/images/dashboard/mapSettings.svg';

		this.controlButton.addEventListener('click', () => {
			if(this.controlButton.src.includes("chevron")){
				document.getElementById('managePanelClicked').style.visibility = 'hidden';
				this.controlButton.src = 'assets/images/dashboard/mapSettings.svg';
			}else{
				document.getElementById('managePanelClicked').style.visibility = 'visible';
				this.controlButton.src = 'assets/images/dashboard/chevron.svg';
			}
		});
	  
		return this.controlButton;
	  }

	ngOnDestroy(){
		this.destroy$.next(true);
		this.destroy$.complete();
		if (!this.cd['destroyed'] || !(this.cd as ViewRef).destroyed) {			
			this.cd.detectChanges();
		}
	}

	addYourLocationButton(map) {
		let controlDiv = document.createElement('div');
		let firstChild = document.createElement('button');
		firstChild.type = 'button';
		firstChild.style.backgroundColor = '#fff';
		firstChild.style.border = 'none';
		firstChild.style.outline = 'none';
		firstChild.style.width = '40px';
		firstChild.style.height = '40px';
		firstChild.style.borderRadius = '2px';
		firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
		firstChild.style.cursor = 'pointer';
		firstChild.style.marginRight = '10px';
		firstChild.style.padding = '0px';
		firstChild.title = 'Your Location';
		controlDiv.appendChild(firstChild);
		
		let secondChild = document.createElement('div');
		secondChild.style.margin = 'calc(50% - 18px / 2)';
		secondChild.style.width = '18px';
		secondChild.style.height = '18px';
		secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
		secondChild.style.backgroundSize = '180px 18px';
		secondChild.style.backgroundPosition = '0px 0px';
		secondChild.style.backgroundRepeat = 'no-repeat';
		secondChild.id = 'center-mapview';
		firstChild.appendChild(secondChild);
		
		google.maps.event.addListener(map, 'dragend', function() {
			document.getElementById('center-mapview').style.backgroundPosition = '0px 0px';
		});

		firstChild.addEventListener('click', function() {
			var imgX = '0';
			var animationInterval = setInterval(function(){
				if(imgX == '-18') imgX = '0';
				else imgX = '-18';
				document.getElementById('center-mapview').style.backgroundPosition = imgX+'px 0px';
			}, 500);
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition( pos => {        
				map.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude});
				map.setZoom(15);
				clearInterval(animationInterval);
				document.getElementById('center-mapview').style.backgroundPosition = '-144px 0px';
				}, error => {
				},{maximumAge:10000, timeout:5000, enableHighAccuracy: true});
			}else{
				clearInterval(animationInterval);
				document.getElementById('center-mapview').style.backgroundPosition = '0px 0px';
			}
		});
		map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
	}

	selectUnselectItem(item) {
		this.mapClick();
		item.selected = !item.selected;
		let items = [];
		items = this.items.filter(item => item.selected).map((obj) => {return obj.name});
		this.types = items;
		if(items.length != 4){
			this.hideClustersbtn();
			this.isDisabled = true;
		}else{
			this.isDisabled = false;
		}
		this.filterClustersAndColors();	
	}

	private loadAPIWrapperInternal(map: any, customLocation: any,bins) {
		if (!map) return;
		this.mapView.googleMap.setZoom(-12);
		const userPosition = {
			lat: Number("32.085300"),
			lng: Number("34.781768")
		};
		if (customLocation) {
			userPosition.lat = Number(customLocation.lat);
			userPosition.lng = Number(customLocation.lng);
		}
		if (bins!=undefined && bins.length > 0) {
			const bounds = new google.maps.LatLngBounds();
			bins.map(bin => {
				if(bin.Bin_location.bin_latitude != 0 && bin.Bin_location.bin_longitude != 0 && 
					bin.Bin_location.bin_latitude != null && bin.Bin_location.bin_longitude != null && 
					bin.Bin_location.bin_latitude != '' && bin.Bin_location.bin_longitude != ''){	
						bounds.extend({ lat: Number(bin.Bin_location.bin_latitude), lng: Number(bin.Bin_location.bin_longitude) });				
				}	
			});
			map.fitBounds(bounds);
		} else {
			map.panTo(userPosition);
			map.googleMap.setZoom(12);
		}
	}

}

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { trigger, state, style, stagger, animate, transition, keyframes } from '@angular/animations';
import { API } from '../../../types.api';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { E_Error_Tracer_Sensors_Zone, E_Error_Tracer_Zones } from '../../../types';
import { Observable, BehaviorSubject, ReplaySubject, combineLatest, Subject} from 'rxjs';
import _ from 'lodash';
import moment from 'moment';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { InstallNewBinDialogComponent } from '../install-new-bin/install-new-bin-dialog/install-new-bin-dialog.component';
import { ActivationTimeOutDialogComponent } from '../install-new-bin/activation-time-out-dialog/activation-time-out-dialog.component';
import { CancelInstallationDialogComponent } from '../install-new-bin/cancel-installation-dialog/cancel-installation-dialog.component';
import { ActivationValidationDialogComponent } from '../install-new-bin/activation-validation-dialog/activation-validation-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../web-services/api/api.service';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ApiStore } from '../../../web-services/api/api.store';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslatorService } from '../../../services/translator_service';
import { MatDialog } from '@angular/material/dialog';
import { DataSource } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxQrReaderComponent } from '../ngx-qr-reader/ngx-qr-reader.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatIconModule } from '@angular/material/icon';
import {CdkTableModule} from '@angular/cdk/table';
import { GoogleMapsModule } from '@angular/google-maps';
import { Loader } from '@googlemaps/js-api-loader';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";

const finalReportData: IFinalReport[] = [];

const FIRST_ACTIVATION_TIMER_DURATION: number = 420;
const SECOND_ACTIVATION_TIMER_DURATION: number = 420;
const ACTIVATION_TRANSMISSION_TIMER_DURATION: number = 1000;
const PHYSICAL_TRANSMISSION_TIMER_DURATION: number = 1200;

const MAX_ALLOWED_BINS_IN_CLUSTER: number = 5;

interface Coordinates {
  address: string;
  latitude: number;
  longitude: number;
}
@Component({
  selector: 'app-new-installation-wizard',
  standalone: true,
  imports: [TranslateModule,MatDividerModule,MatStepperModule,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,NgxQrReaderComponent,MatSelectModule,NgxMatSelectSearchModule,MatExpansionModule,
    MatProgressBarModule,MatTableModule,MatSortModule,NgbModule,MatInputModule,RTLDivDirectiveDirective,
    MatIconModule,CdkTableModule,GoogleMapsModule,NgxGpAutocompleteModule],
  templateUrl: './new-installation-wizard.component.html',
  providers: [
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyB0UcoR0JNhlJkETb9LkDYfnjoiTW77GTc',
        libraries: ['places']
      })
    }
  ],
  animations: [
    trigger('fingerDivState', [
      state('normal', style({
        transform: 'translateY(50px)',
        opacity: 0
      }),
      ),
      state('done', style({
        transform: 'translateY(0)',
        opacity: 1
      })),
      transition('*=>done', animate('1s 4s'))
    ]),
    trigger('detailExpand', [
		  state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
		  state('expanded', style({height: '*'})),
		  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
    trigger('sensorDivState', [
      state('normal', style({
        transform: 'scale(1)',
      }),
      ),
      state('done', style({
        transform: 'scale(1)'
      })),
      transition('*<=>*', animate('1s 2s',
      keyframes([
        style({transform: 'translate3d(-2px, -2px, -8px)', offset: 0.1}),
        style({transform: 'translate3d(2px, 2px, 8px)', offset: 0.2}),
        style({transform: 'translate3d(-4px, -4px, 0)', offset: 0.3}),
        style({transform: 'translate3d(4px, 4px, 0)', offset: 0.4}),
        style({transform: 'translate3d(-4px, -4px, 0)', offset: 0.5}),
        style({transform: 'translate3d(4px, 4px, 0)', offset: 0.6}),
        style({transform: 'translate3d(-4px, -4px, 0)', offset: 0.7}),
        style({transform: 'translate3d(2px, 2px, 0)', offset: 0.8}),
        style({transform: 'translate3d(-2px, -2px, 0)', offset: 0.9}),
      ])
      ))
    ])
  ],
  styleUrl: './new-installation-wizard.component.scss'
})
export class NewInstallationWizardComponent {
  expandedElement: any;

  @Input() isFastInstallation: boolean;
  @Input() fastInstallationBinId: number;

  coordinates: Coordinates;
  associatedBinId: number;
  binSelectedCluster: BinCluster;
  binClusterId: number;
  installationPhase: API.InstallationPhase;
  timeOutObj: API.ITimOutObj;
  binCreationDate: any;
  isLinear = true;
  fingerDivState = 'normal';
  sensorDivState = 'normal';
  firstFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  isFirstFormGroupSubmitted: boolean = false;
  isThirdFormGroupSubmitted: boolean = false;
  isInstallationCancled: boolean = false;
  timeLeft: number = 5;
  interval;
  showTimer: boolean = false;
  firstActivationTimerTimeLeft: number = FIRST_ACTIVATION_TIMER_DURATION; // 7 min = 420 sec
  secondActivationTimerTimeLeft: number = SECOND_ACTIVATION_TIMER_DURATION; // 7 min = 420 sec
  waitForTransChangeTimerTimeLeft: number = ACTIVATION_TRANSMISSION_TIMER_DURATION;
  waitForPhysicalInstallationTimerTimeLeft: number = PHYSICAL_TRANSMISSION_TIMER_DURATION; // 11 min = 220 sec * 3 sec each interval
  maxAllowedBinsInCluster: number = MAX_ALLOWED_BINS_IN_CLUSTER;
  firstActivationTimerInterval;
  secondActivationTimerInterval;
  waitForTransChangeTimerInterval;
  waitForInstallationCompleteInterval;
  waitForPhysicalInstallationTimerInterval;
  waitForInstallComplete;
  activationTimeOutAttempt: number = 0;
  origTransId: number = 0;
  currentTransId: number = 0;
  isActive: boolean = false;
  isPhysicalInstalled: boolean = false;
  isTranssmitedFromInsideTheBin: boolean = false;
  isErrorToDisplay: boolean = false;
  isDisplayProgressBar: boolean = false;
  isInstallationComplete: boolean = false;
  isIgnoreTrans: boolean = true;
  binLastTransIdPhysicalCounter: number = 0;
  lstClusters: object;
  binTypesToDisplay:any =[];
  clustersToDisplay: any = [];
  assignedBinId: number = 0;
  dialogRef;
  cancelInstallationDialogRef;
  qrElement;
  finalReportColumns: string[] = ['time', 'capacity', 'temperature', 'humidity', 'signalQuality', 'battery', 'version'];
  columnsToDisplay = ['time', 'capacity'];
  activationErrorsToDisplay: string[] = [];
  dataSource;
  err: E_Error_Tracer_Sensors_Zone = E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_ACC_COM;
  binPostObj:object={
    site_id: 0,
    device_id: 1,
    bin_name: 'a',
    bin_neighborhood: 'a',
    bin_description: 'a',
    cluster_id: 1,
    bin_address: 'a',
    bin_lat: "0.00000000",
    bin_lon: "0.00000000",
    bin_time_zone_offset: 1,
    bin_time_zone_next_dst_change: 1,
    bin_religion_id: 0,
    bin_creation_date: 0
  };
  binInstallObj: object = { };
  binInserted:string = '';
  public binFilterCtrl: FormControl = new FormControl();
  public filteredBins: ReplaySubject<object> = new ReplaySubject<object>(1);

  isNewBinAssigned: boolean = false;
  @ViewChild('stepper',{static: false}) stepper: MatStepper;
  @ViewChild('formDirective',{static: false}) private formDirective: NgForm;
  isMobile: Boolean;
  spinnerExist : boolean = false;
  sitesListData: any[];
  siteName:string = "";
  currentLang: string;
  translateSub;
  translationsObj;
  neighborhoods;
  installationAccessories;
  message:string;
  public show:boolean = false;
  dataToRegister = {
  };
  routeParamsSub;
  siteId: any;
  binName: any;
  binType:number;
  binNeighborhood: any;
  binInsAcc:any;
  binInfoObject: DataBin;
  binGmtTimeZoneOffset: number;
  isTicketId:boolean = false;
  currentDeviceState: number = 0;

  @ViewChild('qrFormField', {static: false, read: ElementRef }) qrFormField: ElementRef;
  binsSite = [];
  preMappingBins = [];
  binExists:boolean = false;
  binNameInserted;
  file_store: File[];
  accuracyPos;
  latPos: number = 0.00000000;
  lonPos: number = 0.00000000;
  spinnerActive:boolean = true;
  chosenAddress: any;
  private geoCoder;
  zoom: number;
  latitude: number = 0.00000000;
  longitude: number = 0.00000000;
  map;
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  markerIcon: string = '../../../../assets/images/bins/all/greenNotOrdered.svg';
	usageType = 1;
  locationPanelOpenState:boolean = false;
	private readonly destroy$ = new Subject();
  center: google.maps.LatLngLiteral = {lat: 0, lng: 0};
  mapOptionsPc: google.maps.MapOptions = {
    streetViewControl: false
  };

  receiveMessage($event) {
    this.message = $event;
    this.show = false;
    this.firstFormGroup.controls.deviceId.setValue(this.message);  
  }

  onClickQr() {
    this.show = !this.show;
  }

  onDeviceIdChanges(): void {
      this.firstFormGroup.controls.deviceId.valueChanges.subscribe(val => {
        if (!val) return;

        this.apiService.getDeviceIdState(val);
        this.subscribeDeviceIdChange();
      });
    
  }

  onBinNameChanges(): void {
    this.firstFormGroup.controls.binName.valueChanges.subscribe(val => {    
      if(!val){return;}
      if(val.includes(" ")){
        this.firstFormGroup.controls.binName.setValue(val.replace(/\s/g, ""));
      }
      this.apiQuery.filteredBasicBins$.subscribe(bins => {	
        this.apiQuery.preMappingInfo$.subscribe(preMappingInfo => {
          this.binNameInserted = val;
          if(bins.length == 0 || !bins){return;}      
          if(this.siteId == 28 || this.siteId == 44 || this.siteId == 58){
            this.binsSite = bins.filter(bin => bin["site_id"] == 28 || bin["site_id"] == 44 || bin["site_id"] == 58);     
          }else if(this.siteId == 62 || this.siteId == 63 || this.siteId == 69){            
            this.binsSite = bins.filter(bin => bin["site_id"] == 62 || bin["site_id"] == 63 || bin["site_id"] == 69);     
          }else{
            this.binsSite = bins.filter(bin => bin["site_id"] == this.siteId);   
            this.preMappingBins = preMappingInfo.filter(bin => bin["site_id"] == this.siteId);   
          }
          const binsFiltered = this.binsSite.filter(bin => bin.bin_name.toLowerCase() == val.toLowerCase());           
          const binsMappingFiltered = this.preMappingBins.filter(bin => bin.bin_letter.toLowerCase()+""+bin.bin_number == val.toLowerCase());           

          if(val == this.binName && !this.isTicketId){
            if(binsFiltered.length > 0){
              this.binExists = true;     
              this.firstFormGroup.controls.binName.setErrors({});   
            }else{
              this.binExists = false;         
              this.firstFormGroup.controls.binName.valid;
              this.firstFormGroup.controls.binName.setErrors(null);   
            }
          }else if(val.length < 4 || val.length > 4){
            this.binExists = false;         
            this.firstFormGroup.controls.binName.setErrors({});                
          }         
          else if(binsFiltered.length > 0 || binsMappingFiltered.length > 0){
            this.binExists = true;     
            this.firstFormGroup.controls.binName.setErrors({});        
          }else{        
            this.binExists = false;         
            this.firstFormGroup.controls.binName.valid;
            this.firstFormGroup.controls.binName.setErrors(null);        
          } 
        });       
      });
    });
  }

  subscribeDeviceIdChange() {
    this.apiQuery.deviceIdStatus$.subscribe((deviceIdState) => {
      if (deviceIdState === null) return;

      this.currentDeviceState = deviceIdState;
      if (this.qrElement) {
        if (this.currentDeviceState === 0) {
            this.firstFormGroup.controls.deviceId.clearValidators();
            this.firstFormGroup.controls.deviceId.setErrors(null);
            this.firstFormGroup.updateValueAndValidity();    
        }
        else{
          this.qrElement.style.display = 'block';
          this.firstFormGroup.controls.deviceId.clearValidators();
          this.firstFormGroup.controls.deviceId.setErrors({deviceIdState: deviceIdState});
          this.firstFormGroup.updateValueAndValidity();
        }
      }
    });
  }

  subscribeBinFilterCtrlChange() {
    this.binFilterCtrl.valueChanges.subscribe(val => {
      if (val !== "") {
        this.filterBins();
      }
      else {
        this.clustersToDisplay = this.lstClusters;
      }
    });
  }

  handleFileInputChange(l): void {
    if (l.length) {
      const f = l[0];
      this.file_store = l;          
      this.firstFormGroup.controls.binImage.patchValue(`${f.name}`);
    } else {
      this.firstFormGroup.controls.binImage.patchValue('');
    }

    this.dataToRegister["accuracy"] = this.accuracyPos;
    this.dataToRegister["tablet_lat"] = this.latPos;
    this.dataToRegister["tablet_lon"] = this.lonPos;
  }

  protected filterBins() {
    if (!this.lstClusters) {
      return;
    }
    let search = this.binFilterCtrl.value;
    this.searchBin(search);

  }
  
  public searchBin = (filterValue: string) => {
    let backupList = this.lstClusters;
    const filter = filterValue.trim().toLowerCase();
		if (filter === "") {
      this.clustersToDisplay = _.cloneDeep(this.lstClusters);
		}
		else {
      this.clustersToDisplay = [];
			_.each(backupList, (item: any) => {
        const str = (item.bin_id + item.bin_name + item.bin_address).toLowerCase();
				if (_.includes(str, filter)) {
					this.clustersToDisplay.push(item);
				}
      });
		}
  }

  onSwapFormChanges(): void {
    this.subscribeDeviceIdChange();
  }

  onBinSelectionChangeSubscribe() {
    this.firstFormGroup.controls.binClusterId.valueChanges.subscribe(val => {
      this.binSelectedCluster = <BinCluster>val;
    }); 
  }



  onFirstFormGroupSubmit() {

    if (this.firstFormGroup.invalid) return;

    this.binCreationDate = moment().format('YYYY-MM-DD HH:mm:ss');
    this.installationPhase = API.InstallationPhase.PENDING_REVIEW;
    this.isFirstFormGroupSubmitted = true;

    if (this.binSelectedCluster.cluster_id == -1) {
      this.apiService.getNextClusterIdInDb();

      this.apiQuery.nextClusterIdInDb$.subscribe((nextClusterIdInDb) => {
        if (!nextClusterIdInDb) return;

        this.apiService.putNewBinClusterId(this.binSelectedCluster.bin_id, nextClusterIdInDb);

        this.binClusterId = nextClusterIdInDb;

        this.updatebinInstallObj();
        this.lookForNewTranssmistion();
        
      });

    }
    else {
      this.binClusterId = this.binSelectedCluster.cluster_id;
      
      this.updatebinInstallObj();
      this.lookForNewTranssmistion();
    }
    this.openDialog(false);
  }

  updatebinInstallObj() {
    this.binPostObj["site_id"] = this.siteId;
    this.binPostObj["device_id"] = this.firstFormGroup.controls.deviceId.value;
    this.binPostObj["bin_name"] = this.firstFormGroup.controls.binName.value;
    this.binPostObj["bin_description"] = this.firstFormGroup.controls.binDescription.value;
    this.binPostObj["bin_neighborhood"] = this.firstFormGroup.controls.binNeighborhood.value;
    this.binPostObj["installation_accessory_id"] = this.firstFormGroup.controls.binInsAcc.value;
    this.binPostObj["cluster_id"] = this.binClusterId;
    this.binPostObj["bin_creation_date"] = this.binCreationDate;
    this.binPostObj["bin_address"] = this.firstFormGroup.controls.binAddress.value + "__" + this.firstFormGroup.controls.binNeighborhood.value;

    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.SITE_NAME] = this.siteName;
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.DEVICE_ID] = this.firstFormGroup.controls.deviceId.value;
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_NAME] = this.firstFormGroup.controls.binName.value;
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.ADDRESS] = this.firstFormGroup.controls.binAddress.value;
    this.binInstallObj[this.translationsObj.LIST_COLUMNS.NEIGHBORHOOD] = this.firstFormGroup.controls.binNeighborhood.value;  
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_DESCRIPTION] = this.firstFormGroup.controls.binDescription.value;  
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.CLUSTER] = this.firstFormGroup.controls.binClusterId.value.bin_name == '' ? this.translationsObj.TITLES.NEW : this.firstFormGroup.controls.binClusterId.value.bin_name;
    this.binInstallObj[this.translationsObj.LIST_COLUMNS.BIN_TYPE] = this.firstFormGroup.controls.binType.value.bin_type_name == '' ? this.translationsObj.TITLES.NEW : this.firstFormGroup.controls.binType.value.bin_type_name;    
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_MAX_CAP_CM] = this.firstFormGroup.controls.binType.value.bin_max_capacity;
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_MAX_CAP_L] = this.firstFormGroup.controls.binType.value.bin_max_capacity_in_liters;    
  }


  fingerDivStateStart(event) {
  }

  fingerDivStateEnd(event) {
    if (event.fromState != 'void') {
      if (event.toState == 'done') {
        this.showTimer = true;
        this.startTimer();
      }
      else {
        this.toggleAnimation();
      }
    }
  }

  toggleAnimation() {
    this.fingerDivState == 'normal' ? this.fingerDivState = 'done' : this.fingerDivState = 'normal';
    this.sensorDivState == 'normal' ? this.sensorDivState = 'done' : this.sensorDivState = 'normal';
  }

  resetAnimation() {
    if (this.fingerDivState != 'normal') { 
      this.fingerDivState == 'normal';
    }
    if (this.sensorDivState != 'normal') {
      this.sensorDivState = 'normal';
    }
  }

  onCountDownStart() {
  }

  onCountDownFinished() {
  }

  waitForPhysicalInstallationTransmission() {
    this.installationPhase = API.InstallationPhase.PENDING_PHYSICAL_INSTALLATION;
    this.isDisplayProgressBar = true;

    this.isIgnoreTrans = false;

    this.waitForPhysicalInstallationTimerInterval = setInterval(() => {
      if(this.waitForPhysicalInstallationTimerTimeLeft > 0) {
        this.waitForPhysicalInstallationTimerTimeLeft--;
      } else {
        this.pauseWaitForPhysicalInstallationTranssmissionTimer(true);
      }
    },1000)
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.showTimer = false;
        this.pauseTimer();
        this.timeLeft = 5;
        setTimeout(() => {
          if(!this.isDisplayProgressBar) this.isDisplayProgressBar = true;
          this.toggleAnimation();
        }, 0);
      }
    },1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  startFirstActivationTimer() {
    this.firstActivationTimerInterval = setInterval(() => {
      if(this.firstActivationTimerTimeLeft > 0) {
        this.firstActivationTimerTimeLeft--;
      } else {
        this.pauseFirstActivationTimer(true);
        this.firstActivationTimerTimeLeft = 5;
        setTimeout(() => {
          this.startSecondActivationTimer();
        }, 0);
      }
    },1000)
  }

  startSecondActivationTimer() {
    this.secondActivationTimerInterval = setInterval(() => {
      if(this.secondActivationTimerTimeLeft > 0) {
        this.secondActivationTimerTimeLeft--;
      } else {
        this.pauseSecondActivationTimer(true);
        this.firstActivationTimerTimeLeft = 5;
        setTimeout(() => {
        }, 0);
      }
    },1000)
  }

  pauseFirstActivationTimer(isOpenDialog: boolean) {
    clearInterval(this.firstActivationTimerInterval);
    this.firstActivationTimerTimeLeft = FIRST_ACTIVATION_TIMER_DURATION;
    this.activationTimeOutAttempt++;
    if (isOpenDialog) this.openActivationTimeOutDialog();
  }

  pauseSecondActivationTimer(isOpenDialog: boolean) {
    clearInterval(this.secondActivationTimerInterval);
    this.secondActivationTimerTimeLeft = SECOND_ACTIVATION_TIMER_DURATION;
    this.activationTimeOutAttempt++;
    if (isOpenDialog) this.openActivationTimeOutDialog();
  }

  goBack(){
    this.stepper.ngAfterViewInit();
    this.stepper.previous();
  }

  resetStepper() {
    this.stepper.reset();
  }

  resetForm(form: FormGroup) {

  form.reset();

  Object.keys(form.controls).forEach(key => {
    form.get(key).markAsUntouched();
    form.get(key).markAsPristine();
    form.get(key).setErrors(null);
  });

    form.markAsUntouched();
    form.markAsPristine();
  }


  handleNewBinTransmisstion(binLastTransId) {
    let isRealNewTrans = false;

    if (binLastTransId > this.currentTransId) {
      this.origTransId = this.currentTransId;
      this.currentTransId = binLastTransId;
      isRealNewTrans = true;
    }

    if ((!this.isIgnoreTrans) && (isRealNewTrans)) {
      switch(this.installationPhase) {
        case API.InstallationPhase.PENDING:
          break;
  
        case API.InstallationPhase.PENDING_REVIEW:
          break;
  
        case API.InstallationPhase.REVIEW_APPROVED:
          break;
  
        case API.InstallationPhase.PENDING_SWAP_ACTIVATION:
          this.installationPhase = API.InstallationPhase.PENDING_ACTIVATION;
          this.clearFinalReport();
          break;
  
        case API.InstallationPhase.PENDING_ACTIVATION:
          this.handlePendingActivation();
          break;

        case API.InstallationPhase.NEW_PENDING_ACTIVATION:
          this.handlePendingActivation();
          break;
  
        case API.InstallationPhase.ACTIVATED:
          this.clearFinalReport();
          break;
  
        case API.InstallationPhase.PENDING_PHYSICAL_INSTALLATION:
          this.clearFinalReport();
          this.handlePendingPhysicalTrans();
          break;
  
        case API.InstallationPhase.PHYSICALLY_INSTALLED:
          this.handleUpdateReportTrans();
          break;
  
        case API.InstallationPhase.INSTALLATION_COMPLETE:
          break;
  
        default:
      }
    }
  }

  handlePendingActivation() {
    if (this.origTransId !== this.currentTransId) {
      this.isIgnoreTrans = true;
      this.installationPhase = API.InstallationPhase.ACTIVATED;
      this.isActive = true;
      this.isDisplayProgressBar = false;
      this.pauseWaitForNewBinTranssmissionTimer();
      this.resetAnimation();
      this.stepper.next();
      this.pauseFirstActivationTimer(false);
      this.pauseSecondActivationTimer(false);
      this.getTransErrors();
    }
  }

  waitForNewBinTranssmission() {
    this.setInstallationStatusActive(true);
    this.installationPhase = API.InstallationPhase.NEW_PENDING_ACTIVATION; 

    this.waitForTransChangeTimerInterval = setInterval(() => {
      if(this.waitForTransChangeTimerTimeLeft > 0) {
        this.waitForTransChangeTimerTimeLeft--;
        if (this.waitForTransChangeTimerTimeLeft === (ACTIVATION_TRANSMISSION_TIMER_DURATION - 2)) {
          this.isIgnoreTrans = false;
        }
      } else {
        this.pauseWaitForNewBinTranssmissionTimer();
        setTimeout(() => {
        }, 0);
      }
    },3000)
  }

  lookForNewTranssmistion() {
    this.waitForInstallationCompleteInterval = setInterval(() => {
      if(this.installationPhase !== API.InstallationPhase.INSTALLATION_COMPLETE) {
        this.apiService.getBinLastTransId(this.assignedBinId);
      } else {
        this.pauseWaitForInstallationCompleteTimer();
        setTimeout(() => {
        }, 0);
      }
    },3000)
  }

  getTransErrors() {
    this.activationErrorsToDisplay = [];
    this.isErrorToDisplay = false;

    this.apiService.getTransErrorTrace(this.assignedBinId, this.currentTransId);

    this.apiQuery.transErrorTrace$.subscribe((transErrorTrace) => {
      if (transErrorTrace === null) return;

      if (transErrorTrace != 0) {
        for (let key in transErrorTrace) {
          let errorZone: E_Error_Tracer_Zones = <E_Error_Tracer_Zones>transErrorTrace[key].err_row_index;
          let errorCode: E_Error_Tracer_Sensors_Zone = <E_Error_Tracer_Sensors_Zone>transErrorTrace[key].err_column_index;
          let errorValue = transErrorTrace[key].err_value;

          if (errorZone == E_Error_Tracer_Zones.E_ERROR_TRACER_ZONE_SENSORS) {
            if (errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_ACC_COM || errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_ACC_NO_RESPONSE) {
              if (this.activationErrorsToDisplay.length > 0) {
                if (_.includes(this.activationErrorsToDisplay, this.translationsObj.SITE_MANAGMENT.ACC_ERROR)) {
                  return;
                }
              }

              if (errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_ACC_NO_RESPONSE && errorValue <= 1) {
                return;
              }

              this.isErrorToDisplay = true;
              this.activationErrorsToDisplay.push(this.translationsObj.SITE_MANAGMENT.ACC_ERROR);
            }
            else if (errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_TEMP_COM) {
              if (this.activationErrorsToDisplay.length > 0) {
                if (_.includes(this.activationErrorsToDisplay, this.translationsObj.SITE_MANAGMENT.TH_ERROR)) {
                  return;
                }
              }

              if (errorValue == 1) {
                this.isErrorToDisplay = true;
                this.activationErrorsToDisplay.push(this.translationsObj.SITE_MANAGMENT.TH_ERROR);
              }
            }
            else if (errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_COM || errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_MEASURE || errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_NO_RES || errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_DISTANCE_CALC_SOURCE_PGA || errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_EEPROM_RESET) {
              if (this.activationErrorsToDisplay.length > 0) {
                if (_.includes(this.activationErrorsToDisplay, this.translationsObj.SITE_MANAGMENT.ULTRA_ERROR)) {
                  return;
                }
              }

              if (errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_MEASURE && this.installationPhase < API.InstallationPhase.PHYSICALLY_INSTALLED) {
                return;
              }

              if (errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_EEPROM_RESET && errorValue <= 1) {
                return;
              }

              this.isErrorToDisplay = true;
              this.activationErrorsToDisplay.push(this.translationsObj.SITE_MANAGMENT.ULTRA_ERROR);
            }
          }
        }
      }
    });
  }

  onInstallationReviewClickBack() {
    this.installationPhase = API.InstallationPhase.ACTIVATED;
    this.isTranssmitedFromInsideTheBin = false;
    this.pauseWaitForPhysicalInstallationTranssmissionTimer(false);
    this.clearFinalReport();
  }

  clearFinalReport() {
    finalReportData.forEach(function (element, index, array) {
      finalReportData.pop();
    });

    this.dataSource = finalReportData;
    this.spinnerExist = true;
  }

  handlePendingPhysicalTrans() {
    if ((this.origTransId !== this.currentTransId) && (this.origTransId != 0)) {
      this.installationPhase = API.InstallationPhase.PHYSICALLY_INSTALLED;
      this.pauseWaitForNewBinTranssmissionTimer();
      this.isTranssmitedFromInsideTheBin = true;
      this.updateFinalReportData();
      this.apiService.getTransErrorTrace(this.assignedBinId, this.currentTransId);
      this.pauseWaitForPhysicalInstallationTranssmissionTimer(false);
    }
  }

  handleUpdateReportTrans() {
    if (this.origTransId !== this.currentTransId) {
      this.isActive = true, this.resetAnimation();
      this.stepper.next();
      this.pauseFirstActivationTimer(false);
      this.pauseSecondActivationTimer(false);
      this.apiService.getTransErrorTrace(this.assignedBinId, this.currentTransId);
      this.isDisplayProgressBar = false;
      this.handleNewTransmisstion();
    }
  }

  pauseWaitForNewBinTranssmissionTimer() {
    clearInterval(this.waitForTransChangeTimerInterval);
    this.waitForTransChangeTimerTimeLeft = ACTIVATION_TRANSMISSION_TIMER_DURATION;
    
  }

  pauseWaitForInstallationCompleteTimer() {
    clearInterval(this.waitForInstallationCompleteInterval);
  }

  pauseWaitForPhysicalInstallationTranssmissionTimer(isOpenDialog: boolean) {
    clearInterval(this.waitForPhysicalInstallationTimerInterval);
    this.waitForPhysicalInstallationTimerTimeLeft = PHYSICAL_TRANSMISSION_TIMER_DURATION;
    if (isOpenDialog) this.openActivationTimeOutDialog();
  }

  updateFinalReportData() {
    this.handleNewTransmisstion();

    this.waitForTransChangeTimerInterval = setInterval(() => {
      if(this.waitForTransChangeTimerTimeLeft > 0) {
        this.waitForTransChangeTimerTimeLeft--;
      } else {
        this.pauseWaitForNewBinTranssmissionTimer();
        setTimeout(() => {
          // Reset timer for addtional 5 min before canceling this installtion
        }, 0);
      }
    },3000)
}

  handleNewTransmisstion() {
    this.apiService.getBinInfo(this.assignedBinId);
    
    this.apiQuery.binInfo$.subscribe((binInfo) => {
      if (!binInfo) return;
      this.binInfoObject = <DataBin>binInfo;
      this.binGmtTimeZoneOffset = this.binInfoObject.Bin_location.bin_gmt_time_zone;


      if ((this.installationPhase === API.InstallationPhase.PHYSICALLY_INSTALLED) && (this.binInfoObject.bin_id == this.assignedBinId) && (this.binInfoObject.Bin_live_Data.Transmission_data.last_transmission_time !== null) && (this.binInfoObject.Bin_live_Data.Transmission_data.last_transmission_id === this.currentTransId)) {

        let newRow: IFinalReport = {
          time: this.binInfoObject.Bin_live_Data.fill_level_time,
          capacity: this.binInfoObject.Bin_live_Data.fill_level_unfiltered,
          capacityPrecent: this.binInfoObject.Bin_live_Data.fill_level_percent_unfiltered,
          temperature: this.binInfoObject.Bin_live_Data.temperature,
          humidity: this.binInfoObject.Bin_live_Data.humidity,
          signalQuality: this.binInfoObject.Bin_live_Data.Transmission_data.signal_quality,
          battery: this.binInfoObject.Bin_live_Data.battery_level,
          version: this.binInfoObject.software_version
        }

        finalReportData.push(newRow);
        this.dataSource = new ExampleDataSource();
        this.spinnerExist = true;
      }
    }); 
  }

  completeInstallation() {
    this.isIgnoreTrans = true;
    this.setInstallationStatusActive(false);
    this.installationPhase = API.InstallationPhase.INSTALLATION_COMPLETE;
    this.apiQuery.installationStatusResponse$.subscribe((installationStatusResponse) => {
      if (!installationStatusResponse) return;
      if (installationStatusResponse != 0) {
        this.openDialog(true);
      }
    })
  }

  cancelInstallation() {
    this.isInstallationCancled = true;
    this.isTranssmitedFromInsideTheBin = false;
    this.apiService.performBinReset(this.assignedBinId);
    this.apiQuery.binResetResponse$.subscribe((binResetResponse) => {
      if (!binResetResponse) return;
      this.installationPhase = API.InstallationPhase.PENDING;
    });

    let request = {
      "bin_id" : this.assignedBinId,
      "start_time" : moment().startOf('day').subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
      "end_time" : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      "time_zone" : this.binGmtTimeZoneOffset == null ? 2 : this.binGmtTimeZoneOffset,
      "is_ignore_time_limits" : 1
    }
    

    if(this.assignedBinId != 0){
      this.apiService.cleanBinTrans(request).subscribe((clearBinTransResonse:any) => {            
        if (!clearBinTransResonse) return;  
        if(!this.isTicketId){
          this.goToPreMapping();
        }else{
          this.goToTicketManagement();
        }
      });
    }else{
      if(!this.isTicketId){
        this.goToPreMapping();
      }else{
        this.goToTicketManagement();
      }
    }
  }

  initPage(isOnDestroy: boolean) {
    this.pauseWaitForNewBinTranssmissionTimer();
    this.pauseFirstActivationTimer(false);
    this.pauseSecondActivationTimer(false);
    this.pauseTimer();
    this.initParams();
    this.clearFinalReport();
    this.isTranssmitedFromInsideTheBin = false;
  }

  initParams() {
    this.resetStepper();
    this.activationTimeOutAttempt = 0;
    this.isNewBinAssigned = false;
    this.isFirstFormGroupSubmitted = false;
    this.isThirdFormGroupSubmitted = false;
    this.isInstallationCancled = false;
    this.timeLeft = 5;
    this.showTimer = false;
    this.firstActivationTimerTimeLeft = FIRST_ACTIVATION_TIMER_DURATION; // 5 min = 300 sec
    this.secondActivationTimerTimeLeft = SECOND_ACTIVATION_TIMER_DURATION; // 5 min = 300 sec
    this.waitForTransChangeTimerTimeLeft = ACTIVATION_TRANSMISSION_TIMER_DURATION;
    this.waitForPhysicalInstallationTimerTimeLeft = PHYSICAL_TRANSMISSION_TIMER_DURATION // 11 min = 220 sec * 3 sec each interval
    this.activationTimeOutAttempt = 0;
    this.origTransId = 0;
    this.currentTransId = 0;
    this.isActive = false;
    this.isPhysicalInstalled = false;
    this.isTranssmitedFromInsideTheBin = false;
    this.isErrorToDisplay = false;
    this.isDisplayProgressBar = false;
    this.isInstallationComplete = false;
    this.isIgnoreTrans = true;
    this.associatedBinId = null;
    this.clustersToDisplay = [];
    this.binTypesToDisplay = [];

    this.initForms();
  }

  initForms() {
      this.firstFormGroup.controls.deviceId.setValidators([Validators.required, Validators.minLength(10), Validators.min(0)]);
      this.resetForm(this.firstFormGroup);
      this.firstFormGroup.controls.binClusterId.setValue(this.clustersToDisplay[0]);
      
      this.apiService.getSiteClustersList(this.siteId);
  }
  
  setInstallationStatusActive(isActiveFast: boolean) {
    if (isActiveFast) {
      this.apiService.setInstallationStatus(this.assignedBinId, 0);
    }
    else {
      this.apiService.setInstallationStatus(this.assignedBinId, 1);
    }
  }
  
  openDialog(isInstallationCompleted: boolean): void {
    this.binPostObj["bin_max_capacity"] = this.firstFormGroup.controls.binType.value.bin_max_capacity;
    this.binPostObj["bin_min_capacity"] = this.firstFormGroup.controls.binType.value.bin_min_capacity;
    this.binPostObj["bin_types_sites_id"] = this.firstFormGroup.controls.binType.value.site_bin_type_id;
    this.binPostObj["bin_width"] = this.firstFormGroup.controls.binType.value.bin_width;
    this.binPostObj["bin_height"] = this.firstFormGroup.controls.binType.value.bin_hight;
    this.binPostObj["bin_max_capacity_liters"] = this.firstFormGroup.controls.binType.value.bin_max_capacity_in_liters;
    if(this.isTicketId){
      this.binPostObj["ticket_id"] = this.binName;
    }

    if(this.firstFormGroup.getRawValue()['googleBinLocation']!=''){
      const lonLat = this.firstFormGroup.getRawValue()['googleBinLocation'].split(",");
      if(isNaN(lonLat[0]) || isNaN(lonLat[1])){
        this.binPostObj["bin_lat"] = this.latitude;
        this.binPostObj["bin_lon"] = this.longitude;
      }else{
        this.binPostObj["bin_lat"] = this.firstFormGroup.getRawValue()['googleBinLocation'].split(",")[0].trim();
        this.binPostObj["bin_lon"] = this.firstFormGroup.getRawValue()['googleBinLocation'].split(",")[1].trim();
      }
    }else{
      this.binPostObj["bin_lat"] = 0.00000000;
      this.binPostObj["bin_lon"] = 0.00000000;
    }

    let dataObj = this.binInstallObj;
    let outSideClosedAllowed: boolean = false;

    if (isInstallationCompleted) outSideClosedAllowed = true;

    let dialogData: DialogData = {
      data: dataObj,
      isFastInstallation: this.isFastInstallation,
      isInstallationCompleted: isInstallationCompleted,
      binId: this.assignedBinId
    };
    const dialogRef = this.dialog.open(InstallNewBinDialogComponent, {
      data: dialogData,
      width: '600px',
      disableClose: outSideClosedAllowed
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {        
        this.installationPhase = API.InstallationPhase.REVIEW_APPROVED;        
        this.insertNewBin(this.binPostObj);      
        this.startFirstActivationTimer();
      }
      else {
        if (!isInstallationCompleted) {
          this.apiService.getSiteClustersList(this.siteId);
          this.goBack();
        }else{
          let request = {
            "bin_id" : this.assignedBinId,
            "start_time" : moment().startOf('day').subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
            "end_time" : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            "time_zone" : this.binGmtTimeZoneOffset ? this.binGmtTimeZoneOffset : 2,
            "is_ignore_time_limits" : 1
          }
  
          this.apiService.cleanBinTrans(request).subscribe((clearBinTransResonse:any) => {                    
            if (!clearBinTransResonse) return;
            this.isInstallationComplete = true;
            if(!this.isTicketId){
              if (!this.isFastInstallation) {
                this.goToPreMapping();
              }
              else {
                this.goToPendingInstallationBinsReport();
              }
            }else{
              this.goToTicketManagement();
            }
          });
        }
      }
    });
  }

  async insertNewBin(obj){
    this.binInserted = '';
    let response;
    response = await this.apiService.insertNewbin(obj);
    if(response){
      this.binInserted = response.InsertResponseMsg;
      this.spinnerActive = false;
      this.isNewBinAssigned = true;
      this.assignedBinId = Number(response.new_assigned_bin_id);
      this.toggleAnimation();
      this.waitForNewBinTranssmission();
      if(this.binName != this.binPostObj["bin_name"] && !this.isTicketId){
        let request = {
          old_bin_name : this.binName,
          new_bin_name : this.binPostObj["bin_name"],
          site_id : this.siteId
        }                  
        this.apiService.overwritePreMappingBin(request).subscribe((v:any) => {                                                           
        });
      }
      this.apiService.uploadPictureToAmazonBucket(this.file_store,this.assignedBinId,1).subscribe((v:any) => {                       
        if(v.upload_url.length > 0){
          this.dataToRegister["bin_id"] = this.assignedBinId;
          this.dataToRegister["picture_url"] = v.upload_url;
          this.dataToRegister["picture_url_time"] = moment().format('YYYY-MM-DD HH:mm:ss');             
          this.dataToRegister["item_type_id"] = 1;                      
          this.apiService.registerPicture(this.dataToRegister).subscribe((v:any) => {                      		                      
          });   
        }
      });
    }
  }

  openActivationTimeOutDialog(): void {
    if (this.dialogRef != undefined) {
      this.dialogRef.close();
    }

    let timeOutObj: API.ITimOutObj = {
      activationTimeOutAttempt: this.activationTimeOutAttempt,
      installationPhase: this.installationPhase
    }

    this.dialogRef = this.dialog.open(ActivationTimeOutDialogComponent, {
      data: timeOutObj,
      width: '600px',
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if(this.installationPhase != API.InstallationPhase.NEW_PENDING_ACTIVATION){
        if (result === false) {
          this.openCancelInstallationDialog();
        }
        else if (result === true) {
        }
        else if (result !== undefined) {
          this.cancelInstallation();
        }
      }else{
        if (result === false) {          
          this.openCancelInstallationDialog();
        }
        else{
          const request = {   
            "new_device_id": result,
            "bin_id":this.assignedBinId
            }
            this.apiService.SwapDevices(request).subscribe((v:any) => {                 
           });
        }
      }
    });
  }

  openCancelInstallationDialog(): void {
    if (this.cancelInstallationDialogRef != null) {
      this.cancelInstallationDialogRef.close();
    } 
      this.cancelInstallationDialogRef = this.dialog.open(CancelInstallationDialogComponent, { 
        width: '600px',
        disableClose: true
      });

    this.cancelInstallationDialogRef.afterClosed().subscribe(result => {
      if (result !== true) {
        this.cancelInstallation();
      }
    });
  }

  // convenience getter for easy access to form fields
  get f1() { 
    return this.firstFormGroup.controls; 
  }

  constructor(private _formBuilder: FormBuilder,private apiQuery:ApiQuery,
      private router: Router, private route: ActivatedRoute,private apiService:ApiService,
      private dialog: MatDialog,private responsiveService: ResponsiveService,private modalService: NgbModal,
      private translator: TranslatorService,private apiStore:ApiStore) {
      this.currentLang = this.translator.getTranslationLanguage();
      this.translateSub = this.translator.getTranslation(this.currentLang).subscribe(translations => {
        this.translationsObj = translations;
      });
      this.coordinates = {} as Coordinates;
  }

  unloadNotification($event: any): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.spinnerActive = true;

    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
		});

    if (navigator.geolocation) {
      const id = navigator.geolocation.watchPosition( pos => {
              
        this.accuracyPos = pos.coords.accuracy;
        this.latPos = pos.coords.latitude;
        this.lonPos = pos.coords.longitude;
        }, error => {
      },{maximumAge:300000, timeout:5000, enableHighAccuracy: true}
      );
    } 

    this.installationPhase = API.InstallationPhase.PENDING;
    this.firstFormGroup = this._formBuilder.group({
      binName: new FormControl('', { validators: [Validators.required] }),
      deviceId: new FormControl('', { validators: [Validators.required, Validators.minLength(10), Validators.min(0)]}),
      binAddress: new FormControl('', { validators: [Validators.required] }),
      googleBinLocation: new FormControl(''),
      binType: new FormControl('', Validators.required),
      binNeighborhood: new FormControl(''),
      binInsAcc: new FormControl(''),
      binDescription: new FormControl(''),
      binClusterId: new FormControl('', Validators.required),
      binImage: new FormControl('', { validators: [Validators.required] })
    });

    this.thirdFormGroup = this._formBuilder.group({
      binAddress: new FormControl('')
    });

      this.firstFormGroup.controls.binClusterId.setValue("0"); // 0 means new cluster ID (default)

      this.firstFormGroup.statusChanges.subscribe((result) => {
        if (this.qrElement) {
          if (this.firstFormGroup.controls.deviceId.pristine || (!this.firstFormGroup.controls.deviceId.invalid)) {
              this.qrElement.style.display = 'flex';
          }
          else {
            this.qrElement.style.display = 'block';
          }
        }
      });

    this.onDeviceIdChanges();
    this.onBinNameChanges();

    this.apiQuery.binLastTransId$.subscribe((binLastTransId) => {
      if (binLastTransId === null) return;
      
      if (this.assignedBinId > 0) {
        this.handleNewBinTransmisstion(binLastTransId);
      }
    });

    this.subscribeBinFilterCtrlChange();
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
  }

  goToPreMapping() {
    this.apiStore.update({ allFilteredBins: [] });                    
    this.apiStore.update({ filteredBins: [] });
    this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
    this.apiStore.update({ allFilteredBasicBins: [] });                    
    this.apiStore.update({ filteredBasicBins: [] }); 
    this.apiService.getfilteredBasicBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
    this.router.navigate([`/preMapping/${this.siteId}`]);
  }
  
  goToPendingInstallationBinsReport() {
    //this.router.navigate([`/pendingInstallationBins/${this.siteId}`]);
  }

  goToTicketManagement(){
    let ticketManagementRequest = {};   
		if(sessionStorage.getItem("ticketManagementMinDate") == '' || sessionStorage.getItem("ticketManagementMaxDate") == '' || 
		sessionStorage.getItem("ticketManagementMinDate") == null || sessionStorage.getItem("ticketManagementMaxDate") == null){
			ticketManagementRequest = {
				status_id : -1
			  }
		}else{
			ticketManagementRequest = {
				start_time : sessionStorage.getItem("ticketManagementMinDate"),
				end_time : sessionStorage.getItem("ticketManagementMaxDate")
			  }
		}				

		this.apiStore.update({ allCRTicketsList: [] });
		this.apiStore.update({ cRTicketsList: [] });
		this.apiService.getCRTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allInsTicketsList: [] });
		this.apiStore.update({ insTicketsList: [] });
		this.apiService.getInsTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allRmTicketsList: [] });
		this.apiStore.update({ rmTicketsList: [] });
		this.apiService.getRmTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
    this.router.navigate([`/ticketManagement`]);
  }

  ngAfterViewInit() {
      setTimeout(() => {
        if (this.currentLang == 'iw') {
          if(document.getElementById('onFirst')!=null){
            document.getElementById('onFirst').style.direction = 'ltr';
          }
          if(document.getElementById('complete') != null){
            document.getElementById('complete').style.cssFloat = 'left';
          }
          if(document.getElementById('physicalNext') != null){
            document.getElementById('physicalNext').style.cssFloat = 'left';
          }
          if(document.getElementById('backRep') != null){
            document.getElementById('backRep').style.cssFloat = 'none';
          }
        }
        else{
          if(document.getElementById('onFirst')!=null){
            document.getElementById('onFirst').style.direction = 'rtl';
          }
          if(document.getElementById('complete') != null){
            document.getElementById('complete').style.cssFloat = 'right';
          }
          if(document.getElementById('physicalNext') != null){
            document.getElementById('physicalNext').style.cssFloat = 'right';
          }
          if(document.getElementById('backRep') != null){
            document.getElementById('backRep').style.cssFloat = 'right';
          }
        }
        if (this.qrFormField.nativeElement.querySelector('.mat-form-field-infix')) {
          this.qrElement = this.qrFormField.nativeElement.querySelector('.mat-form-field-infix');
          this.qrElement.style.display = 'flex';
        }       

        this.routeParamsSub = this.route
        .queryParams
        .subscribe(params => {
          this.siteId = this.route.snapshot.paramMap.get("id");
          this.binName = this.route.snapshot.paramMap.get("binName");
          if(!isNaN(this.binName)){  
            this.isTicketId = true;
            combineLatest(
              this.apiQuery.cRTicketsList$,
              this.apiQuery.insTicketsList$,
              this.apiQuery.rmTicketsList$,
              )
              .pipe(takeUntil(this.destroy$))
              .subscribe(([cRTicketsList,insTicketsList,rmTicketsList])=>{	
                let allTickets = [];
                if(cRTicketsList.length == 0 && insTicketsList.length == 0 && rmTicketsList.length == 0){return;}

                _.each(cRTicketsList, (item) => {					
                  if(item["TicketsList"]){
                    if(item["TicketsList"]["open"]!=null){			
                      _.each(item["TicketsList"]["open"], (ticket) => {	
                        allTickets.push(ticket);                      	
                      });		
                    }	
                  }		
                });
        
                _.each(insTicketsList, (item) => {					
                  if(item["TicketsList"]){
                    if(item["TicketsList"]["open"]!=null){			
                      _.each(item["TicketsList"]["open"], (ticket) => {	
                        allTickets.push(ticket);                      
                      });		
                    }								
                  }		
                });
        
                _.each(rmTicketsList, (item) => {					
                  if(item["TicketsList"]){
                    if(item["TicketsList"]["open"]!=null){			
                      _.each(item["TicketsList"]["open"], (ticket) => {	
                        allTickets.push(ticket);                      	
                      });		
                    }								
                  }		
                });
                const relevantTicket = _.filter(allTickets, (ticket) => {
                  return ticket.ticket_id == this.binName;
                });
                if(relevantTicket.length > 0){
                  this.firstFormGroup.controls.binAddress.setValue(
                    _.groupBy(relevantTicket[0].ticket_id_info, "status_id")["1"] ? _.groupBy(relevantTicket[0].ticket_id_info, "status_id")["1"][0].installed_bin_address : ''
                  );  
                  this.firstFormGroup.controls.binDescription.setValue(
                    _.groupBy(relevantTicket[0].ticket_id_info, "status_id")["1"] ? _.groupBy(relevantTicket[0].ticket_id_info, "status_id")["1"][0].comment : ''
                  );   
                  this.firstFormGroup.controls.binNeighborhood.setValue(
                    _.groupBy(relevantTicket[0].ticket_id_info, "status_id")["1"] ? _.groupBy(relevantTicket[0].ticket_id_info, "status_id")["1"][0].installed_bin_neighborhood : ''
                  );         
                  this.apiService.getNextFreeStickers(this.siteId, 1).subscribe((response:any) => {
                    if(response.stickers_array){
                      this.firstFormGroup.controls.binName.setValue(response.stickers_array[0]);
                    }
                  });
                  let lat = _.groupBy(relevantTicket[0].ticket_id_info, "status_id")["1"] ? _.groupBy(relevantTicket[0].ticket_id_info, "status_id")["1"][0].installed_lat : 0.00000000;
                  let lon = _.groupBy(relevantTicket[0].ticket_id_info, "status_id")["1"] ? _.groupBy(relevantTicket[0].ticket_id_info, "status_id")["1"][0].installed_lon : 0.00000000;  
                  if(lat != 0.00000000 && lon != 0.00000000){
                    let location = Number(lat).toFixed(8)+','+Number(lon).toFixed(8);
                    this.firstFormGroup.controls.googleBinLocation.setValue(location);  
                  }
                }
              });          
          }else{
            this.isTicketId = false;
            this.firstFormGroup.controls.binName.setValue(this.binName);
          }
              
          this.apiQuery.sitesListData$.subscribe((sitesList) => {
            if (!sitesList) return;
              this.sitesListData = sitesList;
              _.each(this.sitesListData, (item: any) => {
                if (item.site_id == this.siteId) {
                  this.siteName = item.site_name;            
                }
              });
      
            });

          this.apiService.getPendingPreMappingInfoBySiteId(this.siteId);
    
          this.apiQuery.preMappingInfo$.subscribe(preMappingInfo => {

            if (preMappingInfo.length > 0) {
                let chosenObj = [];
              _.each(preMappingInfo, (item: any) => {         
                if ((item.bin_letter + item.bin_number).trim()  == this.route.snapshot.paramMap.get("binName")) {
                  chosenObj.push(item); 
                  return false;
                }
              });                            
              if(chosenObj.length == 1){
                _.each(chosenObj, (item: any) => { 
                  this.firstFormGroup.controls.binDescription.setValue(item.comments);           
                  this.firstFormGroup.controls.binAddress.setValue(item.bin_address == null ? '' : item.bin_address);   
                  this.apiService.getPreMappingBinsNeighborhoodList({"site_id":this.siteId}).subscribe((v:any) => {	            
                    let ngh = [];
                    _.each(v.NBRHoodList, (site) => {
                      _.each(site.site_NBRH_list, (neighborhood) => {
                        ngh.push(neighborhood.nbrh_name);
                      });
                    });
                    this.neighborhoods = ngh;     
                    this.firstFormGroup.controls.binNeighborhood.setValue(item.bin_neigborhood == null ? '' : item.bin_neigborhood);
                  });
                })
              }else if(chosenObj.length == 0){
                this.apiService.getPreMappingBinsNeighborhoodList({"site_id":this.siteId}).subscribe((v:any) => {	            
                  let ngh = [];                    
                  _.each(v.NBRHoodList, (site) => {
                    _.each(site.site_NBRH_list, (neighborhood) => {
                      ngh.push(neighborhood.nbrh_name);
                    });
                  });
                  this.neighborhoods = ngh;     
                });
              }
            }
          });

          this.apiQuery.installationAccessoryTypes$.subscribe((v:any) => {	       
            if(v.length == 0 || v == null) {return;}    
            this.installationAccessories = v;   
            this.firstFormGroup.controls.binInsAcc.setValue(2);       
        }); 

          this.apiService.GetSiteBinTypes({"site_id":this.siteId});
          this.apiQuery.binTypes$.subscribe((v:any) => {	       
            if(v.length == 0 || v.siteBinsTypeInfoObj == null) {return;}    
            this.binTypesToDisplay = v.siteBinsTypeInfoObj;          
            this.firstFormGroup.controls.binType.setValue(this.binTypesToDisplay[0]);       
        }); 

          this.apiService.getSiteClustersList(this.siteId);

          this.apiQuery.siteClustersList$.subscribe((siteClustersList) => {
            let clusterBinsArr = [];
            if (!siteClustersList) return;
            if(siteClustersList.ClusterBySiteInfoObj != null){
              _.each(siteClustersList.ClusterBySiteInfoObj[0].clusters_info, (item: any) => {
                _.each(item.cluster_bins, (bin: any) => {
                  bin.numberOfBinsInCluster = item.num_of_bins_in_cluster;
                  bin.bin_address = item.cluster_address;
                  bin.cluster_id = item.cluster_id;
                  clusterBinsArr.push(bin);
                });
              });
            }
            siteClustersList = clusterBinsArr;
            this.clustersToDisplay = [];
            this.lstClusters = siteClustersList;
            this.clustersToDisplay = siteClustersList;
            let clusterForDefaultDisplay = this.clustersToDisplay[0];

            if (this.fastInstallationBinId !== undefined) {
              let that = this;
              let updatedClustersToDisplay = [];
              let fastInstallationBinIdIndexInClustersToDisplay = _.findIndex(that.clustersToDisplay, function(o: any) { return o.bin_id == that.fastInstallationBinId; });
              updatedClustersToDisplay[0] = this.clustersToDisplay[fastInstallationBinIdIndexInClustersToDisplay];
              this.clustersToDisplay = updatedClustersToDisplay;
              clusterForDefaultDisplay = this.clustersToDisplay[0];
            }
            
            let newClusterItem: BinCluster = {
              bin_id: 0,
              bin_name: '',
              bin_address: '',
              cluster_id: 0
            };

            this.clustersToDisplay.unshift(newClusterItem);

            this.firstFormGroup.controls.binClusterId.setValue(newClusterItem);

            this.binSelectedCluster = newClusterItem;

            this.onBinSelectionChangeSubscribe();
          });
        });

      }, 500);   
    }

  ngOnDestroy() {
    this.initPage(true);
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  markerDragEnd($event: any) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    const lonLat = Number(this.latitude).toFixed(8) + "," + Number(this.longitude).toFixed(8);
    this.firstFormGroup.controls["googleBinLocation"].setValue(lonLat);   
    this.getAddress(this.latitude, this.longitude); 
  }

  loadAPIWrapper(map: any) {
    this.map = map;
    this.addYourLocationButton(this.map);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition( pos => {       
        const lon = this.firstFormGroup.getRawValue()['googleBinLocation'] != '' ? Number(this.firstFormGroup.getRawValue()['googleBinLocation'].split(",")[1].trim()) : Number(pos.coords.longitude);
        const lat = this.firstFormGroup.getRawValue()['googleBinLocation'] != '' ? Number(this.firstFormGroup.getRawValue()['googleBinLocation'].split(",")[0].trim()) : Number(pos.coords.latitude);
        this.setCurrentLocation(lon,lat);
      }, error => {
      },{maximumAge:300000, timeout:5000, enableHighAccuracy: true});
    }
  }

   addYourLocationButton(map) {
		let controlDiv = document.createElement('div');
		let firstChild = document.createElement('button');
		firstChild.type = 'button';
		firstChild.style.backgroundColor = '#fff';
		firstChild.style.border = 'none';
		firstChild.style.outline = 'none';
		firstChild.style.width = '40px';
		firstChild.style.height = '40px';
		firstChild.style.borderRadius = '2px';
		firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
		firstChild.style.cursor = 'pointer';
		firstChild.style.marginRight = '10px';
		firstChild.style.padding = '0px';
		firstChild.title = 'Your Location';
		controlDiv.appendChild(firstChild);
		
		let secondChild = document.createElement('div');
		secondChild.style.margin = 'calc(50% - 18px / 2)';
		secondChild.style.width = '18px';
		secondChild.style.height = '18px';
		secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
		secondChild.style.backgroundSize = '180px 18px';
		secondChild.style.backgroundPosition = '0px 0px';
		secondChild.style.backgroundRepeat = 'no-repeat';
		secondChild.id = 'center-managebin';
		firstChild.appendChild(secondChild);
		
		google.maps.event.addListener(map, 'dragend', function() {
      if(document.getElementById('center-managebin')){
        document.getElementById('center-managebin').style.backgroundPosition = '0px 0px';
      }
		});
    let lon = Number(this.lonPos);
    let lat = Number(this.latPos);
		firstChild.addEventListener('click', () => {
			var imgX = '0';
			var animationInterval = setInterval(function(){
				if(imgX == '-18') imgX = '0';
				else imgX = '-18';
        if(document.getElementById('center-managebin')){
          document.getElementById('center-managebin').style.backgroundPosition = imgX+'px 0px';
        }
			}, 500);
			if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition( pos => {   
          map.setCenter({lat: Number(pos.coords.latitude), lng: Number(pos.coords.longitude)});
          this.latitude = pos.coords.latitude;
          this.longitude = pos.coords.longitude;
          const lonLat = this.latitude.toFixed(8) + "," + this.longitude.toFixed(8);
          this.firstFormGroup.controls["googleBinLocation"].setValue(lonLat);
          this.getAddress(this.latitude, this.longitude); 
          map.setZoom(15);
          clearInterval(animationInterval);
          if(document.getElementById('center-managebin')){
            document.getElementById('center-managebin').style.backgroundPosition = '-144px 0px';
          }
        }, error => {
        },{maximumAge:10000, timeout:5000, enableHighAccuracy: true});
			}else{
				clearInterval(animationInterval);
        if(document.getElementById('center-managebin')){
          document.getElementById('center-managebin').style.backgroundPosition = '0px 0px';
        }
			}
		});
		map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
	}

  setCurrentLocation(lon,lat) {
    this.latitude = Number(lat);
    this.longitude = Number(lon);
    //const lonLat = Number(this.latitude).toFixed(8) + "," + Number(this.longitude).toFixed(8);
    //this.firstFormGroup.controls["googleBinLocation"].setValue(lonLat);    
    this.zoom = 8;
    this.getAddress(this.latitude, this.longitude);
  }
  
  handleAddressChange(place: google.maps.places.PlaceResult) {
    this.chosenAddress = place;
    this.changeLoc();
  }
  
  changeLoc(){
    if(this.firstFormGroup.getRawValue()['googleBinLocation'].length == 0){
      return;
    }
    const lonLat = this.firstFormGroup.getRawValue()['googleBinLocation'].split(",");
    if(isNaN(lonLat[0]) || isNaN(lonLat[1])){
      if(this.chosenAddress.geometry){
        this.latitude = Number(this.chosenAddress.geometry.location.lat().toFixed(8));
        this.longitude = Number(this.chosenAddress.geometry.location.lng().toFixed(8));
      }
    }else{
      this.latitude = Number(lonLat[0]);
      this.longitude = Number(lonLat[1]);
    }
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder = new google.maps.Geocoder;
    this.geoCoder.geocode({ 'location': { lat: Number(latitude), lng: Number(longitude) } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
        }
      }
    });
    this.center = {lat: latitude, lng: longitude};
  }

  refresh(): void {
    window.location.reload();
  }
}


export class ExampleDataSource extends DataSource<IFinalReport> {
  /** Stream of data that is provided to the table. */
  data = new BehaviorSubject<IFinalReport[]>(finalReportData);

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<IFinalReport[]> {
    return this.data;
  }

  disconnect() {
  }
}

export interface IFinalReport {
  time: string;
  capacity: number;
  capacityPrecent: number;
  temperature: number;
  humidity: number;
  signalQuality: number;
  battery: number;
  version: string;
}

export interface IGetBinsInfo {
  http_ref:     string;
  resource:     string;
  api_version:  number;
  count:        number;
  dataBins:     DataBin[];
  generated_on: Date;
}

export interface DataBin {
  bin_id:           number;
  sim_id:           string;
  bin_name:         string;
  bin_description:  string;
  cluster_id:       string;
  Bin_location:     BinLocation;
  bin_type_id:      number;
  is_active:        number;
  Bin_dimensions:   BinDimensions;
  Active_features:  ActiveFeatures;
  Bin_thresholds:   { [key: string]: number };
  Bin_live_Data:    BinLiveData;
  Bin_Restrictions: BinRestrictions;
  software_version: string;
}

export interface ActiveFeatures {
  is_fire_alarm_on:    number;
  is_washing_alarm_on: number;
  is_bin_upgrade_on:   number;
}

export interface BinRestrictions {
  restriction_1_start_time: string;
  restriction_1_duration:   number;
  restriction_2_start_time: string;
  restriction_2_duration:   number;
  restriction_3_start_time: string;
  restriction_3_duration:   number;
}

export interface BinDimensions {
  bin_height: number;
  bin_width:  number;
}

export interface BinLiveData {
  fill_level:           number;
  fill_level_unfiltered: number;
  fill_level_percent:   number;
  fill_level_percent_unfiltered:   number;
  fill_level_time:      string;
  color_status_id:      number;
  color_status_name:    string;
  temperature:          number;
  humidity:             number;
  last_collection_time: Date;
  battery_level:        number;
  Transmission_data:    TransmissionData;
}

export interface TransmissionData {
  last_transmission_id:       number;
  signal_quality:             number;
  last_transmission_time:     Date;
  expected_transmission_time: Date;
}

export interface BinLocation {
  bin_latitude:  number;
  bin_longitude: number;
  bin_address:   string;
  bin_gmt_time_zone: number;
}

export interface BinCluster {
  bin_id: number;
  bin_name: string;
  cluster_id: number;
  bin_address: string;
}


export interface DialogData {
  binId: number;
  isInstallationCompleted: boolean;
  isFastInstallation: boolean;
  data: object;
}



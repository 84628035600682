@if (!isMobile) {
  <div>
    <div class="inventory-view">
      <mat-card>
        <mat-card-header>
          <mat-card-title class="title">{{ 'TITLES.ALL_COLLECTIONS' | translate }} {{datesDiff}}</mat-card-title>
        </mat-card-header>
        <mat-divider class="top-divider"></mat-divider>
        <div class='spinner-container' [hidden]="!spinnerActive">
          <div class="spinner"></div>
        </div>
        <mat-card-content [hidden]="spinnerActive">
          <div class="flexFilter">
            <div class='search-input-container' rtlDiv>
              <div class="search-frame">
                <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                <input class='search-input' type="text" (input)="applyFilter($event.target)" placeholder="{{
                  'OTHER.SEARCH' | translate }}" name="">
              </div>
            </div>
              @if (filterTableAccordigToStatus.text != '') {
                <div class="filteredValue">
                  <svg height="8" width="8">
                    <ellipse cx="4" cy="4" rx="4" ry="4" [ngStyle]="{fill: filterTableAccordigToStatus.value}"/>
                  </svg>
                  <span>{{filterTableAccordigToStatus.text}}</span>
                  <img (click)="removeFilter()" class="removeFilter" src="assets/images/reports/removeFilter.svg" alt=""/>
                </div>
              }
            </div>
            <mat-divider class="top-divider"></mat-divider>
            <table class="table" mat-table #table [dataSource]="getTableData()" matSort matSortStart="asc"
              matSortActive="bin_name" matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc" rtlDiv>
              <ng-container matColumnDef="name">
                <th [hidden]="!binNameColAll" mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="th-title">
                {{ 'SITE_MANAGMENT.BIN_NAME' | translate }} </th>
                <td [ngClass]="{'cursor-auto' : user_role_id == 8}" [hidden]="!binNameColAll" mat-cell *matCellDef="let element" class="td-value">
                  <div class="flex-th-value">
                    @if(user_role_id != 8){
                      <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'collections',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="cut-txt binNameHref"> {{element ? element.bin_name : ''}}</a>
                    }@else{
                      <span title="{{element ? element.bin_name : ''}}" class="cut-txt binNameHref">{{element ? element.bin_name : ''}}</span>
                    }
                    <button (click)="changeSubmitClickedStatus(element)" class="tooltip-btn" type="button">
                      @if (element.number_of_bins_in_cluster > 1 && element.chosenCluster) {
                        <img src="assets/images/binInfoPage/clusterIndication.svg" alt="" />
                      }
                    </button>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="siteName">
                <th [hidden]="!siteNameColAll" mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="th-title space">
                {{ 'TRUCK_MAP.SITE' | translate }} </th>
                <td [hidden]="!siteNameColAll" mat-cell *matCellDef="let element" class="td-value space"> 
                  <span> {{element ? element.site_name : ''}}</span> 
                </td>
              </ng-container>
              <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="th-title space">
                {{ 'LIST_COLUMNS.ADDRESS' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="td-value space"> 
                  <span title="{{element ? element.bin_address : ''}}" class="cut-txt"> {{element ? element.bin_address : ''}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="neighborhood">
                <th [hidden]="!neighborhoodAll" mat-header-cell *matHeaderCellDef mat-sort-header="neighborhood" class="th-title space">
                {{'LIST_COLUMNS.NEIGHBORHOOD' | translate}}</th>
                <td [hidden]="!neighborhoodAll" mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.neighborhood: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="type">
                <th [hidden]="!binTypeColAll" mat-header-cell *matHeaderCellDef mat-sort-header="bin_type" class="th-title space">
                  {{ 'LIST_COLUMNS.BIN_TYPE' | translate }}
                </th>
                <td [hidden]="!binTypeColAll" mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.bin_type: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="clearing">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_collection_time" class="th-title space"> {{ 'LIVE_INFO.LIST_COLLECTION_TIME' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="td-value space"> 
                  {{currentLang == 'iw' && element ? element.bin_collection_time_he : element.bin_collection_time}}
                </td>
              </ng-container>
              <ng-container matColumnDef="volume">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_fill_level_before_collection" class="th-title space"> {{ 'LIST_COLUMNS.Capacity_Pick_Up' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="td-value space">
                  <div class="progress-flex">
                    <div class="table-status-progress-bar-wrapper progress">
                      <div class="table-status-progress-line progress-bar" [ngStyle]="getProgressBarStyleInternalCollections(element)"></div>
                    </div>
                    <div class='table-status-progress-bar-title' rtlDiv>{{element ? element.bin_fill_level_before_collection: ''}}{{capacityDisplaySymbol}}</div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="cause">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="collection_cause_name" class="th-title space"> {{ 'TITLES.COLLECTION_CAUSE' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="td-value space">
                  @if (currentLang != 'iw') {
                    <span>{{element ? element.collection_cause_name: ''}}</span>
                  }
                  @if (currentLang == 'iw') {
                    <span>{{element ? element.collection_cause_name_hebrew: ''}}</span>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="rate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_avg_fill_rate" class="th-title space"> {{ 'LIVE_INFO.AVG_RATE' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="td-value space">
                  {{element ? (element.bin_avg_fill_rate| number:'1.0-2'): ''}}{{capacityDisplaySymbol}}
                </td>
              </ng-container>
              <ng-container matColumnDef="driver">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="collected_driver_first_name" class="th-title space"> {{ 'TITLES.DRIVER_NAME' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="td-value space">
                  @if (currentLang == 'iw') {
                    <span class="cut-txt"> {{element !=undefined && element.collected_driver_first_name != undefined && element.collected_driver_last_name != undefined ? element.collected_driver_first_name + ' ' + element.collected_driver_last_name : 'ללא'}}</span>
                  }
                  @if (currentLang != 'iw') {
                    <span class="cut-txt"> {{element !=undefined && element.collected_driver_first_name != undefined && element.collected_driver_last_name != undefined ? element.collected_driver_first_name + ' ' + element.collected_driver_last_name : 'NA'}}</span>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="license_plate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="assigned_truck_license_plate" class="th-title">
                  {{ 'TITLES.LICENSE_PLATE' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="td-value">
                  {{element ? element.assigned_truck_license_plate: ''}}
                </td>
              </ng-container>
              <ng-container matColumnDef="plus">
                <th mat-header-cell *matHeaderCellDef rtlDiv>
                  <div class="add-dynamic-items" [matMenuTriggerFor]="menu">
                    <img rtlDiv src="assets/images/dashboard/addCol.svg" alt=""/>
                  </div>
                  <mat-menu #menu="matMenu" rtlDiv>
                    <div class="dynamic-item" mat-menu-item rtlDiv>
                      <label class="container-extra" rtlDiv>
                        {{ 'TRUCK_MAP.SITE' | translate }}
                        <input type="checkbox" [checked]="siteNameColAll" [(ngModel)]="isSiteNameChecked" (change)="toggleEditableSiteAll(isSiteNameChecked)" rtlDiv>
                        <span class="checkmark" rtlDiv></span>
                      </label>
                    </div>
                    <div class="dynamic-item" mat-menu-item rtlDiv>
                      <label class="container-extra" rtlDiv>
                        {{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}
                        <input type="checkbox" [checked]="neighborhoodAll" [(ngModel)]="isNeighborhoodChecked" (change)="toggleEditableAll(isNeighborhoodChecked)" rtlDiv>
                        <span class="checkmark" rtlDiv></span>
                      </label>
                    </div>
                    <div class="dynamic-item" mat-menu-item rtlDiv>
                      <label class="container-extra" rtlDiv>
                        {{ 'LIST_COLUMNS.BIN_TYPE' | translate }}
                        <input type="checkbox" [checked]="binTypeColAll" [(ngModel)]="isBinTypeColChecked" (change)="toggleEditableTypeAll(isBinTypeColChecked)" rtlDiv>
                        <span class="checkmark" rtlDiv></span>
                      </label>
                    </div>
                    @if (usageType != 1) {
                      <div class="dynamic-item" mat-menu-item rtlDiv>
                        <label class="container-extra" rtlDiv>
                          {{ 'LIST_COLUMNS.NAME' | translate }}
                          <input type="checkbox" [checked]="binNameColAll" [(ngModel)]="isBinNameColChecked" (change)="toggleEditableNameAll(isBinNameColChecked)" rtlDiv>
                          <span class="checkmark" rtlDiv></span>
                        </label>
                      </div>
                    }
                  </mat-menu>
                </th>
                <td mat-cell *matCellDef="let element"></td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div [hidden]="filteredData.length == 0">
              <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="10"></app-pagination>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  }

  @if (isMobile) {
    <div>
      <div class="mobile-group-tabs">
        <div class="card-header mobile-header">
          <div class="mobile-flex">
            <span class="mobile-title">  {{ 'TITLES.ALL_COLLECTIONS' | translate }} {{datesDiff}}</span>
          </div>
        </div>
        <div class="card mobile-card">
          <div class="card-body">
            <div class='mobile-search-input-container' rtlDiv>
              <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
              <input class='search-input' type="text" (keyup)="applyFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
                id="mobile-collection-table" rtlDiv>
              </div>
              <div class="spinner-border mobile-spinner" [hidden]="!spinnerActive">
                <div class="spinner"></div>
              </div>
              <table rtlDiv class="mobile-table" #table mat-table [dataSource]="getTableData()" multiTemplateDataRows
                matSort matSortStart="asc" matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc"
                matSortActive="bin_name" [ngClass]="{'show':!spinnerActive,'hide':spinnerActive}">
                <ng-container matColumnDef="name">
                  <th [hidden]="!collectionsSelectedValues.isBinNameChecked" class="mobile-th" mat-sort-header="bin_name" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.NAME' | translate }} </th>
                  <td [hidden]="!collectionsSelectedValues.isBinNameChecked" class="mobile-status-td" mat-cell *matCellDef="let element"> 
                    <div class="flex-th-value">
                      <span (click)="goToBinLivePage(element)" title="{{element ? element.bin_name : ''}}" class="cut-txt"> {{element ? element.bin_name : ''}}</span> 
                      @if (element.number_of_bins_in_cluster > 1 && element.chosenCluster) {
                        <button (click)="changeSubmitClickedStatus(element)" class="tooltip-btn" type="button">
                          <img src="assets/images/binInfoPage/clusterIndication.svg" alt="" />
                        </button>
                      }
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="siteName">
                  <th [hidden]="!collectionsSelectedValues.isSiteNameChecked" mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="mobile-th">{{ 'TRUCK_MAP.SITE' | translate }}</th>
                  <td [hidden]="!collectionsSelectedValues.isSiteNameChecked" mat-cell *matCellDef="let element" class="mobile-status-td"> 
                    <span> {{element ? element.site_name : ''}}</span> 
                  </td>
                </ng-container>
                <ng-container matColumnDef="address">
                  <th [hidden]="!collectionsSelectedValues.isAddressChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="mobile-th">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</th>
                  <td [hidden]="!collectionsSelectedValues.isAddressChecked" mat-cell *matCellDef="let element" class="mobile-status-td"> 
                    <span title="{{element ? element.bin_address : ''}}" class="cut-txt"> {{element ? element.bin_address : ''}}</span> 
                  </td>
                </ng-container>
                <ng-container matColumnDef="neighborhood">
                  <th [hidden]="!collectionsSelectedValues.isNeighborhoodChecked" mat-header-cell *matHeaderCellDef mat-sort-header="neighborhood" class="mobile-th">{{'LIST_COLUMNS.NEIGHBORHOOD' | translate}}</th>
                  <td [hidden]="!collectionsSelectedValues.isNeighborhoodChecked" mat-cell *matCellDef="let element" class="mobile-status-td"> {{element ? element.neighborhood: ''}} </td>
                </ng-container>
                <ng-container matColumnDef="type">
                  <th [hidden]="!collectionsSelectedValues.isBinTypeColChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_type" class="mobile-th">{{ 'LIST_COLUMNS.BIN_TYPE' | translate }}</th>
                  <td [hidden]="!collectionsSelectedValues.isBinTypeColChecked" mat-cell *matCellDef="let element" class="mobile-status-td"> {{element ? element.bin_type: ''}} </td>
                </ng-container>
                <ng-container matColumnDef="clearing">
                  <th [hidden]="!collectionsSelectedValues.isBinCollectionTime" mat-header-cell *matHeaderCellDef mat-sort-header="bin_collection_time" class="mobile-th"> {{ 'LIVE_INFO.LIST_COLLECTION_TIME' | translate }} </th>
                  <td [hidden]="!collectionsSelectedValues.isBinCollectionTime" mat-cell *matCellDef="let element" class="mobile-status-td"> 
                    {{currentLang == 'iw' && element ? element.bin_collection_time_he : element.bin_collection_time}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="volume">
                  <th [hidden]="!collectionsSelectedValues.isBinFillLevelBeforeCollection" class="mobile-th" mat-sort-header="bin_fill_level_before_collection" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.Capacity_Pick_Up' | translate }}</th>
                  <td [hidden]="!collectionsSelectedValues.isBinFillLevelBeforeCollection" class="mobile-status-td" mat-cell *matCellDef="let element">
                    <span> {{element.bin_fill_level_before_collection}}%</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="cause">
                  <th [hidden]="!collectionsSelectedValues.isCollectionCauseNameChecked" mat-header-cell *matHeaderCellDef mat-sort-header="collection_cause_name" class="mobile-th">{{ 'TITLES.COLLECTION_CAUSE' | translate }} </th>
                  <td [hidden]="!collectionsSelectedValues.isCollectionCauseNameChecked" mat-cell *matCellDef="let element" class="mobile-status-td">
                    @if (currentLang != 'iw') {
                      <span>{{element ? element.collection_cause_name: ''}}</span>
                    }
                    @if (currentLang == 'iw') {
                      <span>{{element ? element.collection_cause_name_hebrew: ''}}</span>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="rate">
                  <th [hidden]="!collectionsSelectedValues.isBinAvgFillRateChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_avg_fill_rate" class="mobile-th"> {{ 'LIVE_INFO.AVG_RATE' | translate }} </th>
                  <td [hidden]="!collectionsSelectedValues.isBinAvgFillRateChecked" mat-cell *matCellDef="let element" class="mobile-status-td">
                    {{element ? (element.bin_avg_fill_rate| number:'1.0-2'): ''}}{{capacityDisplaySymbol}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="driver">
                  <th [hidden]="!collectionsSelectedValues.isDriverName" mat-header-cell *matHeaderCellDef mat-sort-header="collected_driver_first_name" class="mobile-th"> {{ 'TITLES.DRIVER_NAME' | translate }} </th>
                  <td [hidden]="!collectionsSelectedValues.isDriverName" mat-cell *matCellDef="let element" class="mobile-status-td">
                    @if (currentLang == 'iw') {
                      <span class="cut-txt"> {{element !=undefined && element.collected_driver_first_name != undefined && element.collected_driver_last_name != undefined ? element.collected_driver_first_name + ' ' + element.collected_driver_last_name : 'ללא'}}</span>
                    }
                    @if (currentLang != 'iw') {
                      <span class="cut-txt"> {{element !=undefined && element.collected_driver_first_name != undefined && element.collected_driver_last_name != undefined ? element.collected_driver_first_name + ' ' + element.collected_driver_last_name : 'NA'}}</span>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="license_plate">
                  <th [hidden]="!collectionsSelectedValues.isLicensePlate" mat-header-cell *matHeaderCellDef mat-sort-header="assigned_truck_license_plate" class="mobile-th">{{ 'TITLES.LICENSE_PLATE' | translate }}</th>
                  <td [hidden]="!collectionsSelectedValues.isLicensePlate" mat-cell *matCellDef="let element" class="mobile-status-td">
                    {{element ? element.assigned_truck_license_plate: ''}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="arrowDown">
                  <th class="mobile-th" rtlDiv mat-header-cell *matHeaderCellDef>
                    <img (click)="openColumnManaging()" rtlDiv src="assets/images/dashboard/addCol.svg" alt=""/>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div rtlDiv class="mobile-status-value">
                      @if (expandedElement !== element) {
                        <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                      }
                      @if (expandedElement === element) {
                        <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                      }
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" rtlDiv>
                    <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                      <div class="mobile-element-diagram" rtlDiv>
                        @if(!collectionsSelectedValues.isSiteNameChecked){
                          <div class="dFlex">
                            <div class="mobile-description-details-title">{{ 'TRUCK_MAP.SITE' | translate }}</div>
                            <div class="mobile-description-details-value"> {{element.site_name}} </div>
                          </div>
                        }
                        @if(!collectionsSelectedValues.isAddressChecked){
                          <div class="dFlex">
                            <div class="mobile-address-details-title">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</div>
                            <div class="mobile-address-details-value">
                              <span>{{element.bin_address}}</span>
                            </div>
                          </div>
                        }
                        @if(!collectionsSelectedValues.isNeighborhoodChecked){
                          @if (element && (element.neighborhood != null && element.neighborhood != '')) {
                            <div class="dFlex">
                              <div class="mobile-description-details-title">{{'LIST_COLUMNS.NEIGHBORHOOD' | translate}}</div>
                              <div class="mobile-description-details-value"> {{element ? element.neighborhood: ''}} </div>
                            </div>
                          }
                        }
                        @if(!collectionsSelectedValues.isBinTypeColChecked){
                          <div class="dFlex">
                            <div class="mobile-description-details-title">{{'LIST_COLUMNS.BIN_TYPE' | translate}}</div>
                            <div class="mobile-description-details-value"> {{element ? element.bin_type: ''}} </div>
                          </div>
                        }
                        @if(!collectionsSelectedValues.isBinFillLevelBeforeCollection){
                          <div class="dFlex">
                            <div class="mobile-description-details-title">{{'LIST_COLUMNS.Capacity_Pick_Up' | translate}}</div>
                            <div class="mobile-description-details-value"> {{element.bin_fill_level_before_collection}}% </div>
                          </div>
                        }
                        @if(!collectionsSelectedValues.isBinCollectionTime){
                          <div class="dFlex">
                            <div class="mobile-description-details-title">{{'LIVE_INFO.LIST_COLLECTION_TIME' | translate}}</div>
                            <div class="mobile-description-details-value"> {{element ? parseDateFormat(element.bin_collection_time) : ''}} </div>
                          </div>
                        }
                        @if(!collectionsSelectedValues.isCollectionCauseNameChecked){
                          <div class="dFlex">
                            <div class="mobile-description-details-title">{{'TITLES.COLLECTION_CAUSE' | translate}}</div>
                            <div class="mobile-description-details-value"> {{ currentLang == 'iw' ? element.collection_cause_name_hebrew : element.collection_cause_name}} </div>
                          </div>
                        }
                        @if(!collectionsSelectedValues.isBinAvgFillRateChecked){
                          <div class="dFlex">
                            <div class="mobile-description-details-title">{{'LIVE_INFO.AVG_RATE' | translate}}</div>
                            <div class="mobile-description-details-value"> {{element ? (element.bin_avg_fill_rate| number:'1.0-2'): ''}}{{capacityDisplaySymbol}} </div>
                          </div>
                        }
                        @if(!collectionsSelectedValues.isDriverName){
                          <div class="dFlex">
                            <div class="mobile-acre-details-title">{{'TITLES.DRIVER_NAME' | translate}}</div>
                            <div class="mobile-acre-details-value"> {{element.collected_driver_first_name != null ? element.collected_driver_first_name + " " + element.collected_driver_last_name : " "}} </div>
                          </div>
                        }
                        @if(!collectionsSelectedValues.isLicensePlate){
                          <div class="dFlex">
                            <div class="mobile-description-details-title">{{'TITLES.LICENSE_PLATE' | translate}}</div>
                            <div class="mobile-description-details-value"> {{ element ? element.assigned_truck_license_plate : ''}} </div>
                          </div>
                        }
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                  (click)="expandedElement = expandedElement === element ? null : element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
              </table>
              <div [hidden]="filteredData.length == 0">
                <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="10"></app-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
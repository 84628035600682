import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { UtilService } from '../../../services/util.service';
import { TranslatorService } from '../../../services/translator_service';
import { ApiService } from '../../../web-services/api/api.service';

import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';

@Component({
  selector: 'app-site-config',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, MatDividerModule, MatGridListModule, RouterModule],
  templateUrl: './site-config.component.html',
  styleUrl: './site-config.component.scss'
})
export class SiteConfigComponent {
  siteObject: Object;
  siteId: any;
  routeParamsSub;
	isMobile: Boolean;

  constructor( private translator: TranslatorService, private utilService: UtilService, private router: Router,
    private apiService:ApiService,private apiQuery:ApiQuery,
    private responsiveService: ResponsiveService,
		private route: ActivatedRoute ) { }

  ngOnInit() {
    
    this.onResize();
    this.responsiveService.checkWidth();
    this.routeParamsSub = this.route
			.queryParams
			.subscribe(params => {
        this.siteId = this.route.snapshot.paramMap.get("id");
        this.apiService.getSiteInfo(this.siteId);
      });

      this.apiQuery.siteInfo$.subscribe((siteInfo) => {
        if ((Object.keys(siteInfo).length === 0) || (!siteInfo)) return;
        this.siteObject = siteInfo;
      });
      
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}
}

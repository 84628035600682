import { Component } from '@angular/core';
import moment from 'moment';
import { ApiService } from '../../../web-services/api/api.service';
import { UtilService } from '../../../services/util.service';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ResponsiveService } from '../../../services/responsive.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { BinLiveStatusComponent } from '../../bin-live-status/bin-live-status.component';
import { BinEventsComponent } from '../bin-events/bin-events.component';
import { BinCapacityComponent } from '../bin-capacity/bin-capacity.component';
import { BinCollectionHistoryComponent } from '../bin-collection-history/bin-collection-history.component';
import { BinCollectionPieComponent } from '../bin-collection-pie/bin-collection-pie.component';
import { BinInfoComponent } from '../bin-info/bin-info.component';
import { BinTicketsListComponent } from '../bin-tickets-list/bin-tickets-list.component';
import { BinLocationDescriptionComponent } from '../bin-location-description/bin-location-description.component';

@Component({
  selector: 'app-bin-live-info-page',
  standalone: true,
  imports: [CommonModule,TranslateModule,RTLDivDirectiveDirective,MatDividerModule,BinLiveStatusComponent,BinEventsComponent,
    BinCapacityComponent,BinCollectionHistoryComponent,BinCollectionPieComponent,BinInfoComponent,
	BinTicketsListComponent,BinLocationDescriptionComponent],
  templateUrl: './bin-live-info-page.component.html',
  styleUrl: './bin-live-info-page.component.scss'
})
export class BinLiveInfoPageComponent {
  isMobile: Boolean;
	landscape:boolean=true;
	showLocation:boolean;
	showEvents:boolean;
	translate;
	routeParamsSub;
	bin: any = null;
	binId;
	last_time_updated;
	date1;
	date2;
	sub;
	lastPage; 
	lastPageTitle; 
	currentLang;
	translationsObj;
	binMaxCapacityInLitters;
	
	capacityDisplayType: number = 0;
	capacitySymbol: string = ' %';

	usageType = 1;

	constructor(private translator: TranslatorService, private utilService: UtilService, private router: Router,
		private apiQuery:ApiQuery,
		private responsiveService: ResponsiveService,private apiService:ApiService,
		private route: ActivatedRoute) {
			this.translate = this.translator;
			this.translator.currentLangEmitter$
			.subscribe(async value=>{						
			  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			  this.currentLang = value;
			  this.lastPageTitle = this.determineLastPage(); 
			})
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.routeParamsSub = this.route
			.queryParams
			.subscribe(params => {
				this.lastPage = params['pageName'] || 'dashboard';
				if (this.translationsObj) {
					this.lastPageTitle = this.determineLastPage();
				}
				this.binId = params['binId'] || null;				
				setTimeout(() => {
					this.apiService.getBinInfo(this.binId);
				}, 0);
			});

		this.apiQuery.binInfo$.subscribe((info) => {
			if (info == null || parseInt(this.binId) != info.bin_id) {
				return;
			}

			this.bin = info;
		});
		this.apiQuery.user$.subscribe(user => {
			if (!user) return;
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
			this.capacityDisplayType = user["user_default_capacity_type_id"];
			if (this.capacityDisplayType != 0) this.capacitySymbol = ' L';
		});
	}

	rangeBtnEventHander($event: any) {
		this.landscape = $event;
	  }

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	ngOnDestroy() {
		this.routeParamsSub.unsubscribe();
	}
	isRtl() {
		return this.currentLang === 'iw';
	}
	determineLastPage() {		
		switch (this.lastPage) {
			case 'dashboard':
				return this.translationsObj.SIDEBAR.DASHBOARD;
			case 'workplans':
				return this.translationsObj.SIDEBAR.WORKPLANS;
			case 'inventory':
				return this.translationsObj.SIDEBAR.INVENTORY;
			case 'collections':
				return this.translationsObj.SIDEBAR.COLLECTIONS;
			case 'reports':
				return this.translationsObj.SIDEBAR.REPORTS;
			default: return this.translationsObj.SIDEBAR.DASHBOARD;
		}
	}

	showLocationEventHandler($event: any) {
		this.showLocation = $event;		
	  }

	  showEventHandler($event: any) {
		this.showEvents = $event;		
	  }

	navigateTo = (navigateTo: string) => {
		this.router.navigate(['/' + navigateTo]);
	}

	calcDiff = (date: string) => {
		if (this.translationsObj != undefined) {
			let diff_in_string = '';
			const original_date = moment(date);
			const date_time_now = moment();
			const diff_in_object: any = this.utilService.preciseDiffBetweenDates(original_date, date_time_now, true);
			if (diff_in_object.months > 0) {
				diff_in_string = diff_in_string + diff_in_object.months + ' ';
				if (diff_in_object.months === 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MONTH + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MONTHS + ' ';
				}
			}
			if (diff_in_object.days > 0) {
				diff_in_string = diff_in_string + diff_in_object.days + ' ';
				if (diff_in_object.days === 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.DAY + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.DAYS + ' ';
				}
			}
			if (diff_in_object.hours > 0) {
				if (diff_in_object.days > 0) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.AND + ' ' + diff_in_object.hours + ' ';
				} else {
					diff_in_string += diff_in_object.hours + ' ';
				}
				if (diff_in_object.hours === 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.HOUR + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.HOURS + ' ';
				}
			}
			
			if (diff_in_object.minutes > 0) {
				if (diff_in_object.hours > 0) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.AND + ' ' + diff_in_object.minutes + ' ';
				} else {
					diff_in_string += diff_in_object.minutes + ' ';
				}
				if (diff_in_object.minutes === 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MINUTE + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MINUTES + ' ';
				}
			}
			else {
				diff_in_string += this.translationsObj.COLLECTION_REPORT.MINUTE + ' ';
			}

			if (this.currentLang !== 'iw') {
				diff_in_string += this.translationsObj.COLLECTION_REPORT.AGO;
			}
			else {
				diff_in_string = this.translationsObj.COLLECTION_REPORT.AGO + ' ' + diff_in_string;
			}
			
			return diff_in_string;
		}
		
	}

}

@if(!isMobile){
    <div>
        <form class="sitesOpt" [formGroup]="searchUserForm" rtlDiv>
          <mat-select panelClass="myPanelClass" (openedChange)="openedChange($event)" placeholder="{{formValueForUser}}" #selectSites formControlName="userType" multiple rtlDiv>
            <mat-select-trigger rtlDiv>
              {{formValueForUser}}
            </mat-select-trigger>
            <div class='search-input-sites' rtlDiv>
              <img rtlDiv class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />      
              <input id="filter-input" class="filter-input" placeholder="{{'OTHER.SEARCH' | translate}}" type="text" (keydown)="$event.stopPropagation()" (input)="applyFilter($event.target)" rtlDiv>
            </div> 
            <div class="sites-menu-list" rtlDiv>        
              <mat-option class="site-option" #allSelected (click)="toggleAllSelection()" [value]="0" rtlDiv>{{ 'TITLES.ALL_SIES' | translate }} ({{sitesListFilter.length}})</mat-option>
              @for(group of sitesHierarchy;track $index){
                <mat-optgroup>
                    @if(group.names.length > 1 && group.display){
                        <div class="group-site-option">
                            <mat-checkbox [checked]="group.selected" (change)="toggleSelection($event, group)" 
                              (click)="$event.stopPropagation()">
                              <span class="group-label"> {{group.letter}} </span>
                            </mat-checkbox>
                        </div> 
                    }   
                    @for(site of group.names;track $index){
                      <mat-option [ngClass]="{'sub-option':group.names.length > 1,'hide': !group.display}" class="site-option" rtlDiv [value]="site" (click)="tosslePerOne(site,allSelected.viewValue)">
                        {{site.site_name}}
                      </mat-option>
                    }    
                </mat-optgroup>
              }
            </div>
            <div class="apply-option" (click)="applySitesChosenBtn()"><button [ngClass]="{'noClick':searchUserForm.getRawValue()['userType'] && searchUserForm.getRawValue()['userType'].length == 0}" class="apply-btn"><span class="apply-txt">{{ 'TITLES.APPLY' | translate }}</span></button></div>            
          </mat-select>
        </form>
      </div>
}

@if(isMobile){
    <div>
        <form class="mobile-sitesOpt" [formGroup]="searchUserForm" rtlDiv>
          <mat-select panelClass="myPanelClass-mobile" (openedChange)="openedChange($event)" placeholder="{{formValueForUser}}" #selectSites formControlName="userType" multiple rtlDiv>
            <mat-select-trigger rtlDiv>
              {{formValueForUser}}
            </mat-select-trigger>
            <div>
              <div class='search-input-sites' rtlDiv>
                <input id="filter-input" class="filter-input" placeholder="{{'OTHER.SEARCH' | translate}}" type="text" (keydown)="$event.stopPropagation()" (input)="applyFilter($event.target)" rtlDiv>
                <img rtlDiv class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />      
              </div> 
              <div class="mobile-sites-menu-list">
                <mat-option class="site-option" #allSelected (click)="toggleAllSelection()" [value]="0" rtlDiv>{{ 'TITLES.ALL_SIES' | translate }} ({{sitesListFilter.length}})</mat-option>
                @for(group of sitesHierarchy;track $index){
                  <mat-optgroup>
                    @if(group.names.length > 1 && group.display){
                      <div class="group-site-option" rtlDiv>
                          <mat-checkbox [checked]="group.selected" (change)="toggleSelection($event, group)" 
                            (click)="$event.stopPropagation()">
                            <span class="group-label"> {{group.letter}} </span>
                          </mat-checkbox>
                      </div>  
                    }
                    @for(site of group.names;track $index){
                      <mat-option [ngClass]="{'sub-option':group.names.length > 1,'hide': !group.display}" class="site-option" rtlDiv [value]="site" (click)="tosslePerOne(site,allSelected.viewValue)">
                        {{site.site_name}}
                      </mat-option>
                    }
                  </mat-optgroup>
                }
              </div>
              <div class="apply-option" (click)="applySitesChosenBtn()"><button class="apply-btn"><span class="apply-txt">{{ 'TITLES.APPLY' | translate }}</span></button></div>  
            </div>          
          </mat-select>
        </form>
      </div>
}

  

import { Component, Inject, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiQuery } from '../../../web-services/api/api.query';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiStore } from '../../../web-services/api/api.store';
import { TranslatorService } from '../../../services/translator_service';
import moment from 'moment';
import _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { GoogleMapsModule } from '@angular/google-maps';
import { Loader } from '@googlemaps/js-api-loader';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";

interface Coordinates {
  address: string;
  latitude: number;
  longitude: number;
}
@Component({
  selector: 'app-manage-site-modal',
  standalone: true,
  imports: [TranslateModule,RTLDivDirectiveDirective,CommonModule,FormsModule,ReactiveFormsModule,
    MatExpansionModule,NgxMaterialTimepickerModule,MatTabsModule,GoogleMapsModule,NgxGpAutocompleteModule
  ],
  providers: [
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyB0UcoR0JNhlJkETb9LkDYfnjoiTW77GTc',
        libraries: ['places']
      })
    }
  ],
  templateUrl: './manage-site-modal.component.html',
  styleUrl: './manage-site-modal.component.scss'
})
export class ManageSiteModalComponent {
  userLon;
  userLat;
  coordinates: Coordinates;
  ElForm: FormGroup;
  dynamicForm: FormGroup;
  selectedTab:number = 0;
  seedData:Array<object> = [];
  showDefaultIconsInStartPointMap:boolean = false;
  showDefaultIconsInFinishPointMap:boolean = false;
  showDefaultIconsInUnloadingPointMap:boolean = false;
  mapForStartPoint:any;
  mapForFinishPoint:any;
  mapForUnloadingPoint:any;
  currentLang;
	translationsObj;
  showVolume:boolean = true;
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  workPlanDistrebutionMethods:Array<object> = [];
  customerWorkPlanCollectionschedulePolicy:Array<object> = [];
  workPlanOpenState: boolean = false;
  advancedSettingsOpenState: boolean = false;
  alertsOpenState: boolean = false;
  mapOptionsPc: google.maps.MapOptions = {
    streetViewControl: false,    
    disableDefaultUI:false,  
    fullscreenControl : false,    
    zoomControl : false
  };
  staticTheme = {
    container: {
        // bodyBackgroundColor: '#424242',
        // buttonColor: '#fff'
    },
    dial: {
         dialBackgroundColor: '#28B365',
    },
    clockFace: {
        // clockFaceBackgroundColor: '#555',
        clockHandColor: '#28B365',
        // clockFaceTimeInactiveColor: '#fff'
    }
  };

  points : Array<object> = [
    {
      label : 'start',
      latitude : 0.00000000,
      longitude : 0.00000000
    },
  
    {
      label : 'finish',
      latitude : 0.00000000,
      longitude : 0.00000000
    },
  
    {
      label : 'dump',
      latitude : 0.00000000,
      longitude : 0.00000000
    }
  ]
  private geoCoder;
	markersArrayStartPoint = [];
	markersArrayFinishPoint = [];
	markersArrayDumpPoint = [];
  chosenStartAddress: any;
  chosenFinishAddress: any;
  chosenDumpAddress: any;

  constructor(private translator: TranslatorService,private apiQuery:ApiQuery,
    public fb: FormBuilder,private apiService:ApiService,private apiStore:ApiStore,private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any,private dialogRef: MatDialogRef<ManageSiteModalComponent>) {
    this.dialogRef.disableClose = true;

    this.translator.currentLangEmitter$
    .subscribe(async value=>{						
      this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
      this.currentLang = value;	
    })
    this.coordinates = {} as Coordinates;

    this.ElForm = this.fb.group({
      siteName: '',
      WorkPlanDistrebutionMethods:'',
      customerWorkPlanCollectionschedulePolicy:'',
      capacity:'',
      daysGapForNoCollections:'',
      minAverageVolume:'',
      maxNumberDaysForecast:'',
      useSiteMaxAllowedCapacity:'',
      startLonLat: '',
      finishLonLat: '',
      dumpLonLat: '' 
    });

    this.dynamicForm = this.fb.group({
      shifts: this.fb.array([]),
    });
   }

  ngOnInit() {
    _.each(this.data.chosenSites.shifts, b => {
      b.start_time = moment(b["start_time"],"HH:mm:ss").format("HH:mm");
      b.duration = Math.abs(b.duration);
    });
    this.seedData = this.data.chosenSites.shifts;
    this.seedFiltersFormArray();
    this.ElForm.controls["siteName"].setValue(this.data.chosenSites.site_name);    
    this.ElForm.controls["WorkPlanDistrebutionMethods"].setValue(this.data.chosenSites.preferred_bin_distribution_method);       
    this.ElForm.controls["capacity"].setValue(this.data.chosenSites.site_max_allowed_capacity+"%");    
    this.ElForm.controls["daysGapForNoCollections"].setValue(this.data.chosenSites.days_gap_for_no_collection_alert);    
    this.ElForm.controls["minAverageVolume"].setValue(this.data.chosenSites.avg_capacity_in_percent_future_work_plan_alert+"%");    
    this.ElForm.controls["maxNumberDaysForecast"].setValue(this.data.chosenSites.site_days_gap_for_future_work_plan);    
    this.ElForm.controls["customerWorkPlanCollectionschedulePolicy"].setValue(this.data.chosenSites.customer_work_plan_collection_schedule_policy_id);   
    if(this.data.chosenSites.is_use_site_max_allowed_capacity == "true"){
      this.ElForm.controls["useSiteMaxAllowedCapacity"].setValue(1);  
      this.showVolume = true; 
    }else{
      this.ElForm.controls["useSiteMaxAllowedCapacity"].setValue(0);   
      this.showVolume = false;
    }

    this.points[0]["latitude"] = parseFloat(this.data.chosenSites.nav_start_point_lat).toFixed(8);
    this.points[0]["longitude"] = parseFloat(this.data.chosenSites.nav_start_point_lon).toFixed(8);
    this.points[1]["latitude"] = parseFloat(this.data.chosenSites.nav_finish_point_lat).toFixed(8);
    this.points[1]["longitude"] = parseFloat(this.data.chosenSites.nav_finish_point_lon).toFixed(8);
    this.points[2]["latitude"] = parseFloat(this.data.chosenSites.nav_waste_dump_point_lat).toFixed(8);
    this.points[2]["longitude"] = parseFloat(this.data.chosenSites.nav_waste_dump_point_lon).toFixed(8);

    this.apiQuery.workPlanDistrebutionMethods$.subscribe((workPlanDistrebutionMethods) => {
      if(!workPlanDistrebutionMethods){return;}
      this.workPlanDistrebutionMethods = workPlanDistrebutionMethods;
    });

    this.apiQuery.customerWorkPlanCollectionschedulePolicy$.subscribe((customerWorkPlanCollectionschedulePolicy) => {
      if(!customerWorkPlanCollectionschedulePolicy){return;}
      this.customerWorkPlanCollectionschedulePolicy = customerWorkPlanCollectionschedulePolicy;
    });
    
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.userLon = position.coords.longitude;
        this.userLat = position.coords.latitude;
      });
    }

    this.ElForm.controls["startLonLat"].setValue("");   
    this.ElForm.controls["finishLonLat"].setValue("");   
    this.ElForm.controls["dumpLonLat"].setValue(""); 
  }

  seedFiltersFormArray() {
    this.seedData.forEach((seedDatum) => {
      const formGroup = this.createFilterGroup();
      formGroup.patchValue(seedDatum);
      this.filtersFormArray.push(formGroup);
    });
  }

  createFilterGroup() {
    return this.fb.group({
      name: [],
      start_time:[],
      duration: []
    });
  }

  addFilterToFiltersFormArray() {
    this.filtersFormArray.push(this.createFilterGroup());
  }

  removeFilterFromFiltersFormArray(index) {
    this.filtersFormArray.removeAt(index);
  }

  get filtersFormArray() {
    return <FormArray>this.dynamicForm.get('shifts');
  }

  getShiftsNames(){
    let shiftsNames = '';
    _.each(this.dynamicForm.value.shifts, (filter,index) => {
      if(filter.name){
        if(Number(index) == this.dynamicForm.value.shifts.length-1){
          shiftsNames += filter.name;
        }else{
          shiftsNames += filter.name + ',';
        }
      }
    });
    if(this.filtersFormArray.controls.length == 0){
      if(this.translationsObj){
        return this.translationsObj.MANAGE_SITE.NO_SHIFTS;
      }
    }
    return shiftsNames;
  }

  applyFilter(){
    this.resetMarkersFromMaps()

    this.changeStartPointLoc();
    this.changeFinishPointLoc();
    this.changeDumpPointLoc();

    this.loadAPIWrapperForStartPoint(this.mapForStartPoint);
    this.loadAPIWrapperForFinishPoint(this.mapForFinishPoint);
    this.loadAPIWrapperForUnloadingPoint(this.mapForUnloadingPoint);
  }

  handleStartAddressChange(place: google.maps.places.PlaceResult) {
    this.chosenStartAddress = place;
    this.applyFilter();
  }

  handleFinishAddressChange(place: google.maps.places.PlaceResult) {
    this.chosenFinishAddress = place;
    this.applyFilter();
  }

  handleDumpAddressChange(place: google.maps.places.PlaceResult) {
    this.chosenDumpAddress = place;
    this.applyFilter();
  }

  changeStartPointLoc(){
    if(this.ElForm.getRawValue()['startLonLat'].length == 0){
      return;
    }
    const lonLat = this.ElForm.getRawValue()['startLonLat'].split(",");
    if(isNaN(lonLat[0]) || isNaN(lonLat[1])){
      if(this.chosenStartAddress.geometry){
        this.points[0]["latitude"] = Number(this.chosenStartAddress.geometry.location.lat().toFixed(8));
        this.points[0]["longitude"] = Number(this.chosenStartAddress.geometry.location.lng().toFixed(8));
      }
    }else{
      this.points[0]["latitude"] = Number(parseFloat(lonLat[0]).toFixed(8));
      this.points[0]["longitude"] = Number(parseFloat(lonLat[1]).toFixed(8));
    }
  }

  changeFinishPointLoc(){
    if(this.ElForm.getRawValue()['finishLonLat'].length == 0){
      return;
    }
    const lonLat = this.ElForm.getRawValue()['finishLonLat'].split(",");
    if(isNaN(lonLat[0]) || isNaN(lonLat[1])){
      if(this.chosenFinishAddress.geometry){
        this.points[1]["latitude"] = Number(this.chosenFinishAddress.geometry.location.lat().toFixed(8));
        this.points[1]["longitude"] = Number(this.chosenFinishAddress.geometry.location.lng().toFixed(8));
      }
    }else{
      this.points[1]["latitude"] = Number(parseFloat(lonLat[0]).toFixed(8));
      this.points[1]["longitude"] = Number(parseFloat(lonLat[1]).toFixed(8));
    }
  }

  changeDumpPointLoc(){
    if(this.ElForm.getRawValue()['dumpLonLat'].length == 0){
      return;
    }
    const lonLat = this.ElForm.getRawValue()['dumpLonLat'].split(",");
    if(isNaN(lonLat[0]) || isNaN(lonLat[1])){
      if(this.chosenDumpAddress.geometry){
        this.points[2]["latitude"] = Number(this.chosenDumpAddress.geometry.location.lat().toFixed(8));
        this.points[2]["longitude"] = Number(this.chosenDumpAddress.geometry.location.lng().toFixed(8));
      }
    }else{
      this.points[2]["latitude"] = Number(parseFloat(lonLat[0]).toFixed(8));
      this.points[2]["longitude"] = Number(parseFloat(lonLat[1]).toFixed(8));
    }
  }

  mapBinsByCoordinates(bins) {
		const binsHash = {};
		_.each(bins, b => {
			if (binsHash[b.latitude+","+b.longitude] !== undefined) {
				binsHash[b.latitude+","+b.longitude].push(b);
			} else {
				binsHash[b.latitude+","+b.longitude] = [b];
			}
		});
    return binsHash;
	}

  mapRelevantBinsByCoordinates(bins,label) {
		const binsHash = {};
		_.each(bins, b => {
      if(b.label == label){
        if (binsHash[b.latitude+","+b.longitude] !== undefined) {
          binsHash[b.latitude+","+b.longitude].push(b);
        } else {
          binsHash[b.latitude+","+b.longitude] = [b];
        }
      }
		});
    return binsHash;
	}

  loadAPIWrapperForStartPoint(map: any){
    this.mapForStartPoint = map;
    this.setStartCurrentLocation(this.points[0]["longitude"],this.points[0]["latitude"]);
    let binsHash;
    if(!this.showDefaultIconsInStartPointMap){
      binsHash = this.mapBinsByCoordinates(this.points);	
    }else{
      binsHash = this.mapRelevantBinsByCoordinates(this.points,'start');	
    }
		_.forOwn(binsHash, (value :any, key) => {	
			if(value.length > 1){
        let icon = '';
        if(value.length == 2){
          if(this.ifStartFinish(value)){
            icon = '../../../../assets/images/inventory/start_finish_combined.svg';
          }else if(this.ifDumpFinish(value)){
            icon = '../../../../assets/images/inventory/dump_finish_combined_not_selected.svg';
          }else if(this.ifStartDump(value)){
            icon = '../../../../assets/images/inventory/start_dump_combined.svg';
          }
        }else{
          icon = '../../../../assets/images/inventory/start_finish_dump_combined.svg';
        }   				
        const marker = {
          icon: icon,
          lat: Number(value[0].latitude),
          lng: Number(value[0].longitude),				
          visible: true,
          draggable: !this.showDefaultIconsInStartPointMap ? false : true
        };	
        this.markersArrayStartPoint.push(marker);
			}else{
				value.forEach((element, index, array) => {
					const b = element;						
					const icon = this.startPointToIcon(b.label);
					
					const marker = {
						icon: icon,
						lat: Number(b.latitude),
						lng: Number(b.longitude),				
						visible: true,
            draggable: !this.showDefaultIconsInStartPointMap ? false : true
					};	
          this.markersArrayStartPoint.push(marker);
				});
			}
		});	
    this.boundMapForStartPoint(this.mapForStartPoint,this.markersArrayStartPoint);
  }

  startPointToIcon(label){
    if(label == 'start'){
      return '../../../../assets/images/inventory/start-point-yes-active.svg';
    }else if(label == 'finish'){
      return '../../../../assets/images/inventory/finish-point-no-active.svg';
    }else{
      return '../../../../assets/images/inventory/unloading-point-no-active.svg';
    }
  }

  loadAPIWrapperForFinishPoint(map: any){
    this.mapForFinishPoint = map;
    this.setFinishCurrentLocation(this.points[1]["longitude"],this.points[1]["latitude"]);
    let binsHash;
    if(!this.showDefaultIconsInFinishPointMap){
      binsHash = this.mapBinsByCoordinates(this.points);		
    }else{
      binsHash = this.mapRelevantBinsByCoordinates(this.points,'finish');	
    }
		_.forOwn(binsHash, (value :any, key) => {	
			if(value.length > 1){
        let icon = '';
        if(value.length == 2){
          if(this.ifStartFinish(value)){
            icon = '../../../../assets/images/inventory/finish_start_combined.svg';
          }else if(this.ifDumpFinish(value)){
            icon = '../../../../assets/images/inventory/finish_dump_combined.svg';
          }else if(this.ifStartDump(value)){
            icon = '../../../../assets/images/inventory/start_dump_combined_not_selected.svg';
          }
        }else{
          icon = '../../../../assets/images/inventory/finish_start_dump_combined.svg';
        }   				
        const marker = {
          icon: icon,
          lat: Number(value[0].latitude),
          lng: Number(value[0].longitude),				
          visible: true,
          draggable: !this.showDefaultIconsInFinishPointMap ? false : true
        };	
        this.markersArrayFinishPoint.push(marker);
			}else{
				value.forEach((element, index, array) => {
					const b = element;						
					const icon = this.finishPointToIcon(b.label);
					
					const marker = {
						icon: icon,
						lat: Number(b.latitude),
						lng: Number(b.longitude),				
						visible: true,
            draggable: !this.showDefaultIconsInFinishPointMap ? false : true
					};	
          this.markersArrayFinishPoint.push(marker);
				});
			}
		});	
    this.boundMapForFinishPoint(this.mapForFinishPoint,this.markersArrayFinishPoint);
  }

  finishPointToIcon(label){
    if(label == 'start'){
      return '../../../../assets/images/inventory/start-point-no-active.svg';
    }else if(label == 'finish'){
      return '../../../../assets/images/inventory/finish-point-yes-active.svg';
    }else{
      return '../../../../assets/images/inventory/unloading-point-no-active.svg';
    }
  }

  loadAPIWrapperForUnloadingPoint(map: any){
    this.mapForUnloadingPoint = map;
    this.setDumpCurrentLocation(this.points[2]["longitude"],this.points[2]["latitude"]);
    let binsHash;
    if(!this.showDefaultIconsInUnloadingPointMap){
      binsHash = this.mapBinsByCoordinates(this.points);		
    }else{
      binsHash = this.mapRelevantBinsByCoordinates(this.points,'dump');	
    }
		_.forOwn(binsHash, (value :any, key) => {	
			if(value.length > 1){
        let icon = '';
        if(value.length == 2){
          if(this.ifStartFinish(value)){
            icon = '../../../../assets/images/inventory/start_finish_combined_not_selected.svg';
          }else if(this.ifDumpFinish(value)){
            icon = '../../../../assets/images/inventory/dump_finish_combined.svg';
          }else if(this.ifStartDump(value)){
            icon = '../../../../assets/images/inventory/dump_start_combined.svg';
          }
        }else{
          icon = '../../../../assets/images/inventory/dump_start_finish_combined.svg';
        }   				
        const marker = {
          icon: icon,
          lat: Number(value[0].latitude),
          lng: Number(value[0].longitude),				
          visible: true,
          draggable: !this.showDefaultIconsInUnloadingPointMap ? false : true
        };	
        this.markersArrayDumpPoint.push(marker);
			}else{
				value.forEach((element, index, array) => {
					const b = element;						
					const icon = this.dumpPointToIcon(b.label);
					
					const marker = {
						icon: icon,
						lat: Number(b.latitude),
						lng: Number(b.longitude),				
						visible: true,
            draggable: !this.showDefaultIconsInUnloadingPointMap ? false : true
					};	
          this.markersArrayDumpPoint.push(marker);
				});
			}
		});	
    this.boundMapForDumpPoint(this.mapForUnloadingPoint,this.markersArrayDumpPoint);
  }

  ifStartFinish(arr){
    return arr[0].label == "start" && arr[1].label == "finish";
  }

  ifStartDump(arr){
    return arr[0].label == "start" && arr[1].label == "dump";
  }

  ifDumpFinish(arr){
    return arr[0].label == "finish" && arr[1].label == "dump";
  }

  dumpPointToIcon(label){
    if(label == 'start'){
      return '../../../../assets/images/inventory/start-point-no-active.svg';
    }else if(label == 'finish'){
      return '../../../../assets/images/inventory/finish-point-no-active.svg';
    }else{
      return '../../../../assets/images/inventory/unloading-point-yes-active.svg';
    }
  }

  setStartCurrentLocation(lon,lat) {
    this.points[0]["latitude"] = Number(parseFloat(lat).toFixed(8));
    this.points[0]["longitude"] = Number(parseFloat(lon).toFixed(8));
    const lonLat = Number(this.points[0]["latitude"]).toFixed(8) + "," + Number(this.points[0]["longitude"]).toFixed(8);
    this.ElForm.controls["startLonLat"].setValue(lonLat);    
  }

  setFinishCurrentLocation(lon,lat) {
    this.points[1]["latitude"] = Number(parseFloat(lat).toFixed(8));
    this.points[1]["longitude"] = Number(parseFloat(lon).toFixed(8));
    const lonLat = Number(this.points[1]["latitude"]).toFixed(8) + "," + Number(this.points[1]["longitude"]).toFixed(8);
    this.ElForm.controls["finishLonLat"].setValue(lonLat);    
  }


  setDumpCurrentLocation(lon,lat) {
    this.points[2]["latitude"] = Number(parseFloat(lat).toFixed(8));
    this.points[2]["longitude"] = Number(parseFloat(lon).toFixed(8));
    const lonLat = Number(this.points[2]["latitude"]).toFixed(8) + "," + Number(this.points[2]["longitude"]).toFixed(8);
    this.ElForm.controls["dumpLonLat"].setValue(lonLat);    
  }

  markerStartPointDragEnd($event: any) {
    this.points[0]["latitude"] = $event.latLng.lat();
    this.points[0]["longitude"] = $event.latLng.lng();
    const lonLat = this.points[0]["latitude"].toFixed(8) + "," + this.points[0]["longitude"].toFixed(8);
    this.ElForm.controls["startLonLat"].setValue(lonLat); 
    this.applyFilter();
  }

  markerFinishPointDragEnd($event: any) {
    this.points[1]["latitude"] = $event.latLng.lat();
    this.points[1]["longitude"] = $event.latLng.lng();
    const lonLat = this.points[1]["latitude"].toFixed(8) + "," + this.points[1]["longitude"].toFixed(8);
    this.ElForm.controls["finishLonLat"].setValue(lonLat); 
    this.applyFilter();
  }

  markerDumpPointDragEnd($event: any) {
    this.points[2]["latitude"] = $event.latLng.lat();
    this.points[2]["longitude"] = $event.latLng.lng();
    const lonLat = this.points[2]["latitude"].toFixed(8) + "," + this.points[2]["longitude"].toFixed(8);
    this.ElForm.controls["dumpLonLat"].setValue(lonLat); 
    this.applyFilter();
  }

  setVolumeType(){
    if(this.ElForm.getRawValue()['useSiteMaxAllowedCapacity'] == 1){
      this.showVolume = true;
    }else{
      this.showVolume = false;
    }   
  }

  tabChanged(index){
    this.applyFilter();
  }

  resetMarkersFromMaps(){
		_.each(this.markersArrayStartPoint, (b) => {
			b["visible"] = false;
		});
    _.each(this.markersArrayFinishPoint, (b) => {
			b["visible"] = false;
		});
    _.each(this.markersArrayDumpPoint, (b) => {
			b["visible"] = false;
		});
  }

  toggleDefaultIconsInStartPointMap(){
    this.showDefaultIconsInStartPointMap = !this.showDefaultIconsInStartPointMap;
    _.each(this.markersArrayStartPoint, (b) => {
			b["visible"] = false;
		});
    this.loadAPIWrapperForStartPoint(this.mapForStartPoint);
  }

  toggleDefaultIconsInFinishPointMap(){
    this.showDefaultIconsInFinishPointMap = !this.showDefaultIconsInFinishPointMap;
    _.each(this.markersArrayFinishPoint, (b) => {
			b["visible"] = false;
		});
    this.loadAPIWrapperForFinishPoint(this.mapForFinishPoint);
  }

  toggleDefaultIconsInUnloadingPointMap(){
    this.showDefaultIconsInUnloadingPointMap = !this.showDefaultIconsInUnloadingPointMap;
    _.each(this.markersArrayDumpPoint, (b) => {
			b["visible"] = false;
		});
    this.loadAPIWrapperForUnloadingPoint(this.mapForUnloadingPoint);
  }

  boundMapForStartPoint(map: any,bins) {
		if (!map) return;
		const userPosition = {
			lat: Number("32.085300"),
			lng: Number("34.781768")
		};
		if (bins.length > 0) {
			const bounds = new google.maps.LatLngBounds();
			bins.map(bin => {
				if(bin.lat != 0 && bin.lng != 0 && 
					bin.lat != null && bin.lng != null && 
					bin.lat != '' && bin.lng != '' && bin.visible){	
						bounds.extend({ lat: Number(bin.lat), lng: Number(bin.lng) });				
				}	
			});
			map.fitBounds(bounds);
		} else {
			map.panTo(userPosition);
		}
	}

  boundMapForFinishPoint(map: any,bins) {
		if (!map) return;
		const userPosition = {
			lat: Number("32.085300"),
			lng: Number("34.781768")
		};
		if (bins.length > 0) {
			const bounds = new google.maps.LatLngBounds();
			bins.map(bin => {
				if(bin.lat != 0 && bin.lng != 0 && 
					bin.lat != null && bin.lng != null && 
					bin.lat != '' && bin.lng != '' && bin.visible){	
						bounds.extend({ lat: Number(bin.lat), lng: Number(bin.lng) });				
				}	
			});
			map.fitBounds(bounds);
		} else {
			map.panTo(userPosition);
		}
	}

  boundMapForDumpPoint(map: any,bins) {
		if (!map) return;
		const userPosition = {
			lat: Number("32.085300"),
			lng: Number("34.781768")
		};
		if (bins.length > 0) {
			const bounds = new google.maps.LatLngBounds();
			bins.map(bin => {
				if(bin.lat != 0 && bin.lng != 0 && 
					bin.lat != null && bin.lng != null && 
					bin.lat != '' && bin.lng != '' && bin.visible){	
						bounds.extend({ lat: Number(bin.lat), lng: Number(bin.lng) });				
				}	
			});
			map.fitBounds(bounds);
		} else {
			map.panTo(userPosition);
		}
	}

  public closeModal = () => {
    this.dialogRef.close();
  }

  public saveWorkplan = () => {
    const request = {   
    "site_id_list": [this.data.chosenSites.site_id],
    "site_name":this.ElForm.getRawValue()['siteName'],
    "avg_capacity_in_percent_future_work_plan_alert":this.ElForm.getRawValue()['minAverageVolume'].replace("%",""),
    "customer_work_plan_collection_schedule_policy_id":this.ElForm.getRawValue()['customerWorkPlanCollectionschedulePolicy'],
    "days_gap_for_no_collection_alert":this.ElForm.getRawValue()['daysGapForNoCollections'],
    "is_use_site_max_allowed_capacity":this.ElForm.getRawValue()['useSiteMaxAllowedCapacity'],
    "site_max_allowed_capacity":this.ElForm.getRawValue()['useSiteMaxAllowedCapacity'] == 1 ? this.ElForm.getRawValue()['capacity'].replace("%","") : 0,
    "preferred_bin_distribution_method":this.ElForm.getRawValue()['WorkPlanDistrebutionMethods'],
    "site_days_gap_for_future_work_plan":this.ElForm.getRawValue()['maxNumberDaysForecast'],
    "nav_start_point_lat":this.points[0]["latitude"],
    "nav_start_point_lon":this.points[0]["longitude"],
    "nav_finish_point_lat":this.points[1]["latitude"],
    "nav_finish_point_lon":this.points[1]["longitude"],
    "nav_waste_dump_point_lat":this.points[2]["latitude"],
    "nav_waste_dump_point_lon":this.points[2]["longitude"],
    }
    let shifts = [];
    _.each(this.dynamicForm.value.shifts, (shift) => {
      shifts.push({
        start_time: moment(shift.start_time,"HH:mm").format("HH:mm:ss"),
        duration: shift.duration,
        name: shift.name
      });
    });
    request['shifts'] = shifts;
    this.apiService.setSiteParams(request).subscribe((v:any) => {   
      this.apiStore.update({ allSitesListData: [] }); 
      this.apiService.getSitesList(JSON.parse(sessionStorage.getItem("chosenSites")));
      this.closeModal();  
    });
  }

}

@if(!isMobile){
    <div>
        @if(!clickedItem){
            <a routerLink={{link}} routerLinkActive="active" #rla="routerLinkActive">
                <div class="sidebar-item-container" rtlDiv [ngClass]="{'active': rla.isActive,'notActive':!rla.isActive}">
                  <div class="icon-caption" rtlDiv>
                    @if(rla.isActive){
                        <img class="routerlink-img" src={{iconChosen}} alt="" />
                    }
                    @if(!rla.isActive){
                        <img class="routerlink-img" src={{icon}} alt="" />
                    }
                    <div class="label"><span>{{label}}</span></div>
                  </div>
                </div>
              </a>
        }
        @if(clickedItem){
            <div class="isNotActivate" (click)="onReqToLink(link)">
                <div class="sidebar-item-container" rtlDiv>
                  <div class="icon-caption" rtlDiv>
                    <div class="routerlink">
                      <img class="routerlink-img" src={{icon}} alt="" />
                    </div>
                    <div class="label"><span>{{label}}</span></div>
                  </div>
                </div>
            </div>
        }
      </div>
}
  
@if(isMobile){
    <div>
        <a routerLink={{link}} routerLinkActive="active" #rla="routerLinkActive">
          <div rtlDiv class="sidebar-item-container-mobile" rtlDiv [ngClass]="{'active': rla.isActive,'notActive':!rla.isActive}">
            <div class="icon-caption" rtlDiv>
                @if(rla.isActive){
                    <img class="mobile-logo" src={{iconChosen}} alt="" />
                    <span class="mobile-label-active">{{label}}</span>
                }
                @if(!rla.isActive){
                  <img class="mobile-logo" src={{icon}} alt="" />
                  <span class="mobile-label">{{label}}</span>
                }
            </div>
          </div>
        </a>
        @if(clickedItem){
            <div class="isNotActivate" (click)="onReqToLink(link)">
                <div rtlDiv class="sidebar-item-container-mobile">
                  <div class="icon-caption" rtlDiv>
                    @if(!rla.isActive){
                        <img class="mobile-logo" src={{icon}} alt="" />
                    }
                    <span class="mobile-label">{{label}}</span>  
                  </div>
                </div>
            </div>
        }
      </div>
}
  
  
export interface User {
	user_id: number;
	first_name: string;
	last_name: string;
	user_phone_number: string;
	user_email_address: string;
	user_role_name: string;
	user_role_id: number,
	user_default_capacity_type_id: number;
	user_default_lang_id: number;
}

interface BinCollectionData {
	bin_collection_time: string;
	bin_fill_level_before_collection: number;
	collection_status: BinCollectionType;
	is_washed: number;
}

export interface BinsCollectionsData {
	bin_ID: number;
	bin_collections: BinCollectionData[];
}

export enum BinCollectionType {
	'COLLECTED_TOO_LATE',
	'COLLECTED_TOO_SOON',
	'COLLECTED_ON_TIME'
}

export enum E_Error_Tracer_Zones {
	E_ERROR_TRACER_ZONE_SENSORS,
	E_ERROR_TRACER_ZONE_MODEM,
	E_ERROR_TRACER_ZONE_OTHER
  }
  
  export enum E_Error_Tracer_Sensors_Zone {
	E_ERROR_TRACER_ZONE_SENSORS_US_COM,
	E_ERROR_TRACER_ZONE_SENSORS_US_MEASURE,
	E_ERROR_TRACER_ZONE_SENSORS_ACC_COM,
	E_ERROR_TRACER_ZONE_SENSORS_ACC_FALSE_DETECTION,
	E_ERROR_TRACER_ZONE_SENSORS_TEMP_COM,
	E_ERROR_TRACER_ZONE_SENSORS_US_DISTANCE_CALC_SOURCE_PGA,
	E_ERROR_TRACER_ZONE_SENSORS_US_DISTANCE_CALC_SOURCE_INTERNAL,
	E_ERROR_TRACER_ZONE_SENSORS_US_NO_RES,
	E_ERROR_TRACER_ZONE_SENSORS_US_EEPROM_RESET,
	E_ERROR_TRACER_ZONE_SENSORS_ACC_NO_RESPONSE

  }

@if(!isMobile){
  <div class="bin-status">
    <mat-card>
      <mat-card-header class="container-header">
        <mat-card-title class="title">
          {{ 'WIDGETS.WORKPLAN_PROGRESS' | translate }}
          @if(!spinnerActive && notIncluded.length > 0){
            <div class="collections-label">
              &nbsp;&nbsp;
              <img class="alert-collections" src="../assets/images/workplans-page/alert.svg" alt="alert">
              <span class="collected_txt">{{notIncluded.length}} {{ 'WORKPLAN.NOT_INCLUDED_PLANS' | translate }}</span>
            </div> 
          }
        </mat-card-title>
        <div class="close-btn" rtlDiv (click)="closeCMP()">
          <img class="close-img" src="../../../assets/images/dashboard/closeX.svg">
        </div>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <mat-card-content> 
        <div class="content-container">
          <div class='spinner-container' [hidden]="!spinnerActive">
            <div class="spinner"></div>
          </div>
          <div class="content-frame" [hidden]="spinnerActive">
            <div class="box left-box" rtlDiv>
              <div class="circle-content" rtlDiv>
                <div class="content-number">{{workPlanProgress}}%</div>
                <div class="content-text">{{ 'WIDGETS.COMPLETED' | translate }}</div>
              </div>
              <div class="circle" rtlDiv>
                <circle-progress
                  [percent]="workPlanProgress"
                  [maxPercent]="100"
                  [toFixed]="0"
                  [showTitle]="false"
                  [showUnits]="false"
                  [showSubtitle]="false"
                  [showInnerStroke]="true"
                  [animation]="false"
                  [outerStrokeGradient]="false"
                  [radius]="75"
                  [outerStrokeColor]="'#3B4743'"
                  [innerStrokeWidth]="12"
                  [outerStrokeWidth]="12"      
                  [innerStrokeColor]="'#98B9AF'"
                  [space]="-12">
                </circle-progress>
              </div>      
            </div>
            <div class="box right-box bin-status">
              <div class="totalInfo">
                <app-number-with-description [number]="collectedBins" [total]="totalCollectedBins" [description]="'WORKPLAN.BINS_COLLECTED' | translate"></app-number-with-description>
                <mat-divider class="top-divider separate"></mat-divider>
                <app-number-with-description [number]="activeTrucks" [total]="totalActiveTrucks" [description]="'WIDGETS.ACTIVE_TRUCKS' | translate"></app-number-with-description>
              </div>
              <a routerLink="/workplans" class="view-details-btn">{{ 'WIDGETS.VIEW_DETAILS' | translate }}</a>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if(isMobile){
  <div class="mobile-bin-status">
    <mat-card class="card mobile-status">
      <div class="card-header mobile-header">
        <div class="flex">
          <span class="mobile-title"> {{ 'WIDGETS.WORKPLAN_PROGRESS' | translate }} </span>
        </div>
        <div class="mobile-close-btn" rtlDiv (click)="closeCMP()">
          <img class="mobile-close-img" src="../../../assets/images/dashboard/closeX.svg">
        </div>
      </div>
      <div [hidden]="!spinnerActive" class="spinner-border mobile-spinner" role="status">
        <span class="sr-only"></span>
      </div>
      <mat-card-content [hidden]="spinnerActive">
        @if(notIncluded.length > 0){
          <div class="mobile-collections-label">
            <img class="alert-collections" src="../assets/images/workplans-page/alert.svg" alt="alert">
            <span class="collected_txt">{{notIncluded.length}} {{ 'WORKPLAN.NOT_INCLUDED_PLANS' | translate }}</span>
          </div> 
        }
        <div class="mobile-content-frame">
          <div class="box">
            <div class="circle" rtlDiv>
              <div class="circle-content" rtlDiv>
                <div class="content-number">{{workPlanProgress}}%</div>
                <div class="content-text">{{ 'WIDGETS.COMPLETED' | translate }}</div>
              </div>
              <circle-progress
              [percent]="workPlanProgress"
              [maxPercent]="100"
              [toFixed]="0"
              [showTitle]="false"
              [showUnits]="false"
              [showSubtitle]="false"
              [showInnerStroke]="true"
              [animation]="false"
              [outerStrokeGradient]="false"
              [radius]="75"
              [outerStrokeColor]="'#3B4743'"
              [innerStrokeWidth]="12"
              [outerStrokeWidth]="12"      
              [innerStrokeColor]="'#98B9AF'"
              [space]="-12">
            </circle-progress>
            </div>
          </div>
          <div class="box right-box bin-status">
            <div class="mobile-totalInfo">
              <app-number-with-description [number]="collectedBins" [total]="totalCollectedBins" [description]="'WORKPLAN.BINS_COLLECTED' | translate"></app-number-with-description>
              <mat-divider class="top-divider separate"></mat-divider>
              <app-number-with-description [number]="activeTrucks" [total]="totalActiveTrucks" [description]="'WIDGETS.ACTIVE_TRUCKS' | translate"></app-number-with-description>
            </div>
            <div class="goWorkplanBtn">
              <a routerLink="/workplans" class="view-details-btn">{{ 'WIDGETS.VIEW_DETAILS' | translate }}</a>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

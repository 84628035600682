import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {timer as observableTimer,  Subscription ,  Observable } from 'rxjs';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-success-modal',
  standalone: true,
  imports: [TranslateModule,RTLDivDirectiveDirective,FormsModule,CommonModule],
  templateUrl: './success-modal.component.html',
  styleUrl: './success-modal.component.scss'
})
export class SuccessModalComponent {
  @Output() showHideSuccessModal = new EventEmitter<any>();
  @Input() message:any;

  showResetApproval: boolean = false;
  public showloader: boolean = false;
  private subscription: Subscription = Subscription.EMPTY;
  private timer: Observable<any> = new Observable();
  constructor() { }

  ngOnInit() {
    this.setTimer();
  }
  public closeModal = () => {
    if (this.showResetApproval) {
      this.showResetApproval = false;
      return;
    }
    this.showHideSuccessModal.emit(false);
  }
  public ngOnDestroy() {
    if (this.subscription && this.subscription instanceof Subscription) {
      this.subscription.unsubscribe();
    }
  }
  public setTimer() {
    // set showloader to true to show loading div on view

    this.timer = observableTimer(2000); // 1000 millisecond means 1 seconds
    this.subscription = this.timer.subscribe(() => {
      this.closeModal();
    });
  }
}

import { Component, ViewChild } from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import _ from 'lodash';
import { ApiStore } from '../../../web-services/api/api.store';
import { ApiService } from '../../../web-services/api/api.service';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslatorService } from '../../../services/translator_service';
import { Papa } from 'ngx-papaparse';
import { ApiQuery } from '../../../web-services/api/api.query';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PaginationComponent } from '../../pagination/pagination.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-unallocated-bins',
  standalone: true,
  imports: [CommonModule,TranslateModule,RTLDivDirectiveDirective,MatDividerModule,MatCardModule,
	MatTableModule,MatSortModule,MatCheckboxModule,PaginationComponent,RouterModule],
  templateUrl: './unallocated-bins.component.html',
  styleUrl: './unallocated-bins.component.scss'
})
export class UnallocatedBinsComponent {
  currentLang;
	translationsObj;
  	isMobile: Boolean;
  	translate;
	usageType = 1;
	displayedColumns = ['select', 'name','address', 'id','status','size','lastFill','lastUpdate','emptyRate','fillingAmount','manage'];
	binsData:Array<any> = [];
	spinnerActive:boolean = true;
	dataSource = new MatTableDataSource<any>();
	dataTableLength = 0;
	@ViewChild(MatSort,{static: false}) sort: MatSort;
	startIndexTableData = 0;
	endIndexTableData = 9;
	tempFilter = '';
	selection = new SelectionModel<Element>(true, []);
	filteredData:Array<object> = [];

  constructor(private translator: TranslatorService, private papa: Papa, 
		private responsiveService: ResponsiveService,private apiQuery:ApiQuery,
		private apiService:ApiService,private apiStore:ApiStore) {
		this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
	}


  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
  
	this.apiQuery.user$.subscribe(user => {
		if (!user) {
			return;
		}				
		if(user["usage_type_id"] != 1){
			this.usageType = user["usage_type_id"];
		}
		
		this.apiQuery.filteredBins$.subscribe(bins => {
			let binsFiltered = [];
			let unAllocated_Bins = [];
			this.spinnerActive = true;		
			if(bins.length == 0) return;	  
			this.spinnerActive = false;		
			
			unAllocated_Bins = bins.filter(function (b) {
				if(b["fill_requests_status"]){
					return b["Bin_Restrictions"]["collection_mode"] == 2 &&
					b["Bin_live_Data"].color_status_id != 0 && 
				   ((b["fill_requests_status"]["fill_requests_status_is_requst_sent"] != "1" &&
				   (b["fill_requests_status"]["fill_requests_status_is_requst_executed"] == "0" || 
				   b["fill_requests_status"]["fill_requests_status_is_requst_executed"] == null)) ||
				   (b["fill_requests_status"]["fill_requests_status_is_requst_sent"] == "1" &&	  
				   (b["fill_requests_status"]["fill_requests_status_is_requst_executed"] == "1" || 
				   b["fill_requests_status"]["fill_requests_status_is_requst_executed"] == null)))
				}
			});
	  
			  binsFiltered = _.map(unAllocated_Bins, b => {																					
				  return {
					  bin_name: b["bin_name"],
					  address: b["Bin_location"].bin_address,
					  bin_id: b["bin_id"],
					  current_status: Number(b["Bin_live_Data"].fill_level_percent * b["Bin_thresholds"].bin_max_capacity_in_liters / 100),
					  size:b["plot_size"] != 0 ? b["plot_size"] : '',
					  last_fill: b["Bin_live_Data"].last_fill_time,
					  last_update: b["Bin_live_Data"].Transmission_data.last_transmission_time,
					  empty_rate:b["Bin_live_Data"].average_daily_fill_rate_in_liters,
					  Filling_amount: Number(b["Bin_thresholds"].bin_max_capacity_in_liters - (b["Bin_live_Data"].fill_level_percent * b["Bin_thresholds"].bin_max_capacity_in_liters / 100)),
					  bin_color : b["Bin_live_Data"]["color_status_name"],
					  status: b["Bin_live_Data"].fill_level_percent
				  };	
			  });
			
			this.binsData = binsFiltered;							  
			this.dataTableLength = this.binsData.length;
			this.dataSource = new MatTableDataSource<any>(binsFiltered);
		
			this.dataSource.sort = this.sort;
			this.sortData({ direction: 'desc', active: 'bin_name' });
		  });
	});
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		this.isMobile = isMobile;
		});
	}

	allocateBin(bin){
		let request = {};    
		request["bin_id"] = bin.bin_id;
		request["refill_status"] = "1";
		this.apiService.SetBinFillStatus(request).subscribe((v:any) => {  			
			if(v.insert_status){
				this.apiStore.update({ allFilteredBins: [] });                    
				this.apiStore.update({ filteredBins: [] });
				this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
			}	
		 }); 
	}

	getTableData = () => {
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.binsData);
		tempSearch.filter = this.tempFilter;
		this.filteredData = tempSearch.filteredData;
		for (let index = this.startIndexTableData; index <= this.endIndexTableData; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
		this.dataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
		this.dataSource["AllData"] = new MatTableDataSource<any>(this.binsData);
		return this.dataSource;
	}


	sortData(sort: Sort) {
		let greenArr = [];
		let yellowArr = [];
		let redArr = [];
		let allArr = [];
		const data = this.binsData.slice();
		if (!sort.active || sort.direction === '') {
			this.binsData = data;
			return;
		}
		this.binsData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});

		if(sort.active == 'current_status' && this.usageType == 2){
			_.each(this.binsData, (item) => {
				if(item.bin_color == "RED"){
					redArr.push(item);
				}else if(item.bin_color == "YELLOW"){
					yellowArr.push(item);
				}else{
					greenArr.push(item);
				}
			});
				
			redArr.sort(this.compareColors);
			yellowArr.sort(this.compareColors);
			greenArr.sort(this.compareColors);

			if(sort.direction === 'asc'){
				allArr = greenArr.concat(yellowArr,redArr);
			}else{
				allArr = redArr.concat(yellowArr,greenArr);
			} 

			this.binsData = allArr;
		}
	}

	compareColors(a,b) {
		if ( a.current_status < b.current_status ){
		  return -1;
		}
		if ( a.current_status > b.current_status ){
		  return 1;
		}
		return 0;
	  }

	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	masterToggle = () => {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource["AllData"].filteredData.map(row =>
				this.selection.select(row))
	}

	isAllSelected = (): boolean => {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource["AllData"].filteredData.length;
		return numSelected === numRows;
	}

	onPageChange = (dataTableIndexes: any) => {
		this.startIndexTableData = dataTableIndexes.startIndex;
		this.endIndexTableData = dataTableIndexes.endIndex;
	}

	applyFilter = (filterValue: any) => {
		filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempFilter = filterValue;		
		this.dataSource.filter = filterValue;				
	}

	public exportToCsv = () => {
		let dataToExport = null;
		let csvData;
		if (this.selection.selected.length > 0) {
			dataToExport = _.cloneDeep(this.selection.selected);
		} else {
			dataToExport = _.cloneDeep(this.binsData);
		}

		const flatData = dataToExport.reduce((b, a) => {
			b.push(_.values(a));
			return b;
		}, [])

		csvData = this.papa.unparse({
			fields: [this.translationsObj.LIST_COLUMNS.NAME,
				this.translationsObj.LIST_COLUMNS.ADDRESS,
				this.translationsObj.LIST_COLUMNS.ID,
				this.translationsObj.LIST_COLUMNS.STATUS,
				this.translationsObj.LIST_COLUMNS.QUARTER_ACRE,
				this.translationsObj.FILLABLE_BINS.LAST_FILL,
				this.translationsObj.LIST_COLUMNS.LAST_UPDATE,
				this.translationsObj.LIVE_INFO.EMPTY_RATE,
				this.translationsObj.FILLABLE_BINS.FILLING_AMOUNT
					], data: flatData
		});
		
		this.papa.parse(csvData, {
			complete: (results, file) => {
				const csv = `\uFEFF${this.papa.unparse(results.data)}`;
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
				const csvURL = window.URL.createObjectURL(blob);
				const tempLink = document.createElement('a');
				tempLink.href = csvURL;
				tempLink.setAttribute('download', 'unallocated bins.csv');
				tempLink.click();
			}
		});
	}

	getProgressBarStyleWrapper = (binInfo: any) => {
		if (binInfo.bin_color == "GREEN") {
			return { 'background-color': 'rgba(52, 208, 141, 0.15)' };
		} else if (binInfo.bin_color == "YELLOW") {
			return { 'background-color': 'rgba(226, 224, 85, 0.15)' };
		}
		return { 'background-color': 'rgba(215, 89, 76, 0.15)' };
	}

	getProgressBarStyleInternal = (binInfo: any) => {		
		if(!this.isMobile){
			if (binInfo.bin_color == "GREEN") {
				return { 'background-color': '#34D08D', 'width': binInfo.status + '%' };
			} else if (binInfo.bin_color == "YELLOW") {
				return { 'background-color': '#E2E055', 'width': binInfo.status + '%' };
			}
			return { 'background-color': '#D7594C', 'width': binInfo.status + '%' };
		}else{
			if (binInfo.bin_color == "GREEN") {
				return { 'color': '#34D08D'};
			} else if (binInfo.bin_color == "YELLOW") {
				return { 'color': '#E2E055' };
			}
			return { 'color': '#D7594C' };
		}
	}
}

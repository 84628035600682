@if (!isMobile) {
  <div>
    <div class="driver-list-page">
      @if (vehicles.length > 1) {
        <div class="back-arrow">
          <img class="arrow-back" (click)="goToDriverPlans()" rtlDiv src="assets/images/workplans-page/arrow-back.svg" alt="arrow-back">
          <span class="back-lbl">{{ 'SITE_MANAGMENT.BACK' | translate }}</span>
        </div>
      }
      <div class="header" rtlDiv>
        <div class="title">{{'DRIVER_PLAN.BINS_LEFT' | translate}}: {{binsNotCollected}}</div>
        <div class="plan-info-container">
          <div class="site-truck-info">
            <div class="plan-info">
              <img rtlDiv src="assets/images/workplans-page/site-name.svg" alt="site-name">
              <span>{{chosenPlan ? chosenPlan.site_name : ''}}</span>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="plan-info">
              <img rtlDiv src="assets/images/workplans-page/truck-name.svg" alt="truck-name">
              <span>{{chosenPlan ? chosenPlan.tablet_id : ''}}</span>
            </div>
          </div>
          <div class="sub-title">{{'DRIVER_PLAN.LAST_UPDATE' | translate}}:
            {{(currentLang == 'iw' ? utilService.convertDateHebrew(lastUpdatedTime) : utilService.convertDateEnglish(lastUpdatedTime))}}
          </div>
        </div>
      </div>
      <div class="bins-list">
        <div>
          <div class="outside-table-border">
            <cdk-virtual-scroll-viewport tvsItemSize="52" headerHeight="56" style="height: 100%;">
              <table class="table" mat-table #table multiTemplateDataRows [dataSource]="getTableData()" matSort matSortStart="asc" matSortActive="bin_order"
                matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="asc">
                <ng-container matColumnDef="bin_collection_order_index">
                  <th class="th-title" mat-header-cell *matHeaderCellDef># </th>
                  <td class="td-value" mat-cell *matCellDef="let element" [ngClass]="{'no-border':!element.isLast && element.num_of_bins_in_cluster > 1,'align-content':element.isMiddle && element.num_of_bins_in_cluster % 2 == 0}">
                    @if(element.isMiddle || element.num_of_bins_in_cluster == 1){
                      {{element ? element.bin_collection_order_index: ''}}
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="cluster_size">
                  <th class="th-title" mat-header-cell *matHeaderCellDef>{{'DRIVER_PLAN.CLUSTER_SIZE' | translate}}</th>
                  <td class="td-value" mat-cell *matCellDef="let element" [ngClass]="{'no-border':!element.isLast && element.num_of_bins_in_cluster > 1,'align-content':element.isMiddle && element.num_of_bins_in_cluster % 2 == 0}">
                    @if(element.isMiddle || element.num_of_bins_in_cluster == 1){
                      {{element ? element.num_of_bins_in_cluster : ''}}
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="bin_name">
                  <th class="th-title" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.NAME' | translate }} </th>
                  <td mat-cell *matCellDef="let element" [ngClass]="{'no-border':!element.isLast && element.num_of_bins_in_cluster > 1}">
                    <div class="flexOnly">
                      <span class="td-value"> {{element ? element.bin_name : ''}}</span>
                      <img src="{{element ? getImg(element) : ''}}" alt="" />
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="neighborhood">
                  <th class="th-title" mat-header-cell *matHeaderCellDef>{{'LIST_COLUMNS.NEIGHBORHOOD' | translate}}</th>
                  <td class="td-value" mat-cell *matCellDef="let element" [ngClass]="{'no-border':!element.isLast && element.num_of_bins_in_cluster > 1}">
                    <div>
                      <span class="td-value"> {{element ? element['Bin_location']['bin_neighbourhood'] : ''}}</span>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="address">
                  <th class="th-title" mat-header-cell *matHeaderCellDef> {{ 'LIST_COLUMNS.ADDRESS' | translate }}</th>
                  <td class="td-value" mat-cell *matCellDef="let element" [ngClass]="{'no-border':!element.isLast && element.num_of_bins_in_cluster > 1}">
                    <div class="address-val">
                      <a class="wazeFrame" href='https://waze.com/ul?ll={{element.Bin_location.bin_latitude}},{{element.Bin_location.bin_longitude}}'><img class="mobile-wazeIcon" src="assets/images/dashboard/mobile-waze.svg" alt=""></a>
                      <span class="td-value"> {{element ? element['Bin_location']['bin_address'] : ''}}</span>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="status">
                  <th class="th-title" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                  <td mat-cell *matCellDef="let element" [ngClass]="{'no-border':!element.isLast && element.num_of_bins_in_cluster > 1}">
                    <div>
                      <div class="dFlex">
                        <div class="table-status-progress-bar-wrapper" [ngStyle]="getProgressBarStyleWrapper(element)">
                          <div class="table-status-progress-line" [ngStyle]="getProgressBarStyleInternal(element)"></div>
                        </div>
                        <div class='table-status-progress-bar-title'>
                          {{capacityDisplayType !=0 ? element['Last_updated_fill_level_sample']['fill_level_in_litters']: element['Last_updated_fill_level_sample']['fill_level_in_precent']}}
                          <span>{{capacitySymbol}}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if (isMobile) {
  <div>
    @if (vehicles.length > 1) {
      <div class="mobile-back-arrow">
        <img class="arrow-back" (click)="goToDriverPlans()" rtlDiv src="assets/images/workplans-page/arrow-back.svg" alt="arrow-back">
        <span class="mobile-back-lbl">{{ 'SITE_MANAGMENT.BACK' | translate }}</span>
      </div>
    }
    <div class="mobile-driver-list-page">
      <div class="mobile-header" rtlDiv>
        <div class="mobile-title">{{'DRIVER_PLAN.BINS_LEFT' | translate}}: {{binsNotCollected}}</div>
        <div class="mobile-sub-title">{{'DRIVER_PLAN.LAST_UPDATE' | translate}}:
          {{(currentLang == 'iw' ? utilService.convertDateHebrew(lastUpdatedTime) : utilService.convertDateEnglish(lastUpdatedTime))}}
        </div>
        <div class="mobile-plan-info">
          <div class="plan-info">
            <img rtlDiv src="assets/images/workplans-page/site-name.svg" alt="site-name">
            <span>{{chosenPlan ? chosenPlan.site_name : ''}}</span>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div class="plan-info">
            <img rtlDiv src="assets/images/workplans-page/truck-name.svg" alt="truck-name">
            <span>{{chosenPlan ? chosenPlan.tablet_id : ''}}</span>
          </div>
        </div>
      </div>
      <div class="mobile-outside-table-border">
        <cdk-virtual-scroll-viewport tvsItemSize="52" headerHeight="56" class="cdk-table">
          <div class="mat-table-wrapper">
            <table class="table" mat-table #table multiTemplateDataRows [dataSource]="getTableData()" matSort matSortStart="asc" matSortActive="bin_order"
            matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="asc">
              <ng-container matColumnDef="bin_collection_order_index">
                <th class="mobile-th-title" mat-header-cell *matHeaderCellDef># </th>
                <td class="mobile-td-value" mat-cell *matCellDef="let element" [ngClass]="{'no-border':!element.isLast && element.num_of_bins_in_cluster > 1,'align-content':element.isLast && element.num_of_bins_in_cluster % 2 == 0}">
                  @if(element.isMiddle || element.num_of_bins_in_cluster == 1){
                    {{element ? element.bin_collection_order_index: ''}}
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="cluster_size">
                <th class="mobile-th-title" mat-header-cell *matHeaderCellDef>{{'DRIVER_PLAN.CLUSTER_SIZE' | translate}}</th>
                <td class="mobile-td-value" mat-cell *matCellDef="let element" [ngClass]="{'no-border':!element.isLast && element.num_of_bins_in_cluster > 1,'align-content':element.isLast && element.num_of_bins_in_cluster % 2 == 0}">
                  @if(element.isMiddle || element.num_of_bins_in_cluster == 1){
                    {{element ? element.num_of_bins_in_cluster : ''}}
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="bin_name">
                <th class="mobile-th-title" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.NAME' | translate }} </th>
                <td class="mobile-td-value" mat-cell *matCellDef="let element" [ngClass]="{'no-border':!element.isLast && element.num_of_bins_in_cluster > 1}">
                  <div class="mobile-flexOnly">
                    <span> {{element ? element.bin_name : ''}}</span>
                    <img src="{{element ? getImg(element) : ''}}" alt="" />
                  </div>                    
                </td>
              </ng-container>
              <ng-container matColumnDef="neighborhood">
                <th class="mobile-th-title" mat-header-cell *matHeaderCellDef>{{'LIST_COLUMNS.NEIGHBORHOOD' | translate}}</th>
                <td class="mobile-td-value" mat-cell *matCellDef="let element" [ngClass]="{'no-border':!element.isLast && element.num_of_bins_in_cluster > 1}">
                    <div>
                      <span> {{element ? element['Bin_location']['bin_neighbourhood'] : ''}}</span>
                    </div>                    
                </td>
              </ng-container>
              <ng-container matColumnDef="address">
                <th class="mobile-th-title" mat-header-cell *matHeaderCellDef> {{ 'LIST_COLUMNS.ADDRESS' | translate }}</th>
                <td class="mobile-td-value" mat-cell *matCellDef="let element" [ngClass]="{'no-border':!element.isLast && element.num_of_bins_in_cluster > 1}">
                    <div class="address-val">
                      <a class="wazeFrame" href='https://waze.com/ul?ll={{element.Bin_location.bin_latitude}},{{element.Bin_location.bin_longitude}}'><img class="mobile-wazeIcon" src="assets/images/dashboard/mobile-waze.svg" alt=""></a>
                      <span> {{element ? element['Bin_location']['bin_address'] : ''}}</span>
                    </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th class="mobile-th-title" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                <td class="mobile-td-value" mat-cell *matCellDef="let element" [ngClass]="{'no-border':!element.isLast && element.num_of_bins_in_cluster > 1}">
                  <div>
                    <div class="dFlex">
                      <div class='table-status-progress-bar-title'>
                        {{capacityDisplayType !=0 ? element['Last_updated_fill_level_sample']['fill_level_in_litters']: element['Last_updated_fill_level_sample']['fill_level_in_precent']}}
                        <span>{{capacitySymbol}}</span>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsMobile"></tr>
            </table>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
}
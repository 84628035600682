@if (!isMobile) {
  <div>
    @if (!isSwap) {
      <div>
        <div class="top-container">
          <div class="install-new-bin-header">{{ 'TITLES.INSTALL_NEW_BIN' | translate }}</div>
          <mat-divider class="top-divider"></mat-divider>
        </div>
      </div>
    } @else {
      <div class="top-container">
        @if (!isFastInstallation) {
          <div>
            <div class="install-new-bin-header">{{ 'TITLES.SWAP_DEVICE' | translate }}</div>
          </div>
        } @else {
          <div class="install-new-bin-header">{{ 'TITLES.FAST_INSTALLATION' | translate }}</div>
        }
        <mat-divider class="top-divider"></mat-divider>
      </div>
    }
    <div class="padded install-new-bin-page">
      <mat-horizontal-stepper [linear]="isLinear" #stepper>
        @if (!isSwap) {
          <div>
            <mat-step [stepControl]="firstFormGroup" [completed]="false">
              <form [formGroup]="firstFormGroup" #formDirective="ngForm">
                <ng-template matStepLabel>
                  <span class="lang_dir">{{ 'SITE_MANAGMENT.BASIC_BIN_INFO_INSTALL' | translate }}</span>
                </ng-template>
                <mat-form-field #qrFormField>
                  <input type="text" pattern="[0-9]*" #deviceId matInput placeholder="{{ 'SITE_MANAGMENT.DEVICE_ID' | translate }}" formControlName="deviceId" class="form-control" required>
                  <button mat-button type="button" class="qr-icon" (click)="onClickQr()"><img src="../../assets/images/siteManagment/qr-code-scan.png" class="qr-image"></button>
                  @if (isFirstFormGroupSubmitted && f1.deviceId.errors) {
                    <div class="invalid-red-feedback">
                      @if (f1.deviceId.errors.required) {
                        <div>*{{ 'SITE_MANAGMENT.DEVICE_ID_REQ' | translate }}*</div>
                      }
                    </div>
                  }
                  <div class="invalid-red-feedback">
                    @switch (currentDeviceState) {
                      @case (1) {
                        <div>{{ 'SITE_MANAGMENT.ASSIGNED_ID' | translate }}</div>
                      }
                      @case (2) {
                        <div>{{ 'SITE_MANAGMENT.NOT_REGISTERED' | translate }}</div>
                      }
                      @case (3) {
                        <div>{{ 'SITE_MANAGMENT.INVALID_DEVICE_ID' | translate }}</div>
                      }
                      @default {
                        <div></div>
                      }
                    }
                  </div>
                  @if (show) {
                    <app-ngx-qr-reader (messageEvent)="receiveMessage($event)"></app-ngx-qr-reader>
                  }
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="{{ 'SITE_MANAGMENT.BIN_NAME' | translate }}" formControlName="binName" required>
                  @if (isFirstFormGroupSubmitted && f1.binName.errors) {
                    <div class="invalid-red-feedback">
                      @if (f1.binName.errors.required) {
                        <div>*{{ 'SITE_MANAGMENT.NAME_REQ' | translate }}*</div>
                      }
                    </div>
                  }
                </mat-form-field>
                <mat-form-field>
                  <input matInput placeholder="{{ 'SITE_MANAGMENT.BIN_DESCRIPTION' | translate }}" formControlName="binDescription">
                </mat-form-field>
                <mat-form-field>
                  <input type="number" matInput placeholder="{{ 'SITE_MANAGMENT.BIN_MAX_CAP' | translate }}" formControlName="binMaxCapacity" required>
                  @if (isFirstFormGroupSubmitted && f1.binMaxCapacity.errors) {
                    <div class="invalid-red-feedback">
                      @if (f1.binMaxCapacity.errors.required) {
                        <div>*{{ 'SITE_MANAGMENT.MAX_REQ' | translate }}*</div>
                      }
                    </div>
                  }
                </mat-form-field>
                <mat-form-field>
                  <input type="number" matInput placeholder="{{ 'SITE_MANAGMENT.BIN_MIN_CAP' | translate }}" formControlName="binMinCapacity" required>
                  @if (isFirstFormGroupSubmitted && f1.binMinCapacity.errors) {
                    <div class="invalid-red-feedback">
                      @if (f1.binMinCapacity.errors.required) {
                        <div>*{{ 'SITE_MANAGMENT.MIN_REQ' | translate }}*</div>
                      }
                    </div>
                  }
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{ 'SITE_MANAGMENT.SELECT_MATERIAL_TYPE' | translate }}</mat-label>
                  <mat-select formControlName="wasteTypeListCtrl" (change)="onSubmit($event)">
                    @for (wasteType of lstWasteTypes; track wasteType) {
                      <mat-option [value]="wasteType.waste_type_id">
                        <div>
                          {{wasteType.waste_type_name}}
                        </div>
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{ 'SITE_MANAGMENT.SELECT_ASSOCIATED_BIN' | translate }}</mat-label>
                  <mat-select formControlName="binClusterId">
                    <mat-option class="auto-filter" rtlDiv>
                      <ngx-mat-select-search rtlDiv class="select-filter" [formControl]="binFilterCtrl" [placeholderLabel]="translationsObj ? translationsObj.SITE_MANAGMENT.SEARCH_BIN_INSTALL_SWAP : ''" [noEntriesFoundLabel]="'no matching bin found'"></ngx-mat-select-search>
                    </mat-option>
                    @for (cluster of clustersToDisplay; track cluster) {
                      @if (cluster.bin_id !== 0) {
                        <mat-option [value]="cluster" [disabled]="cluster.numberOfBinsInCluster >= 5">
                          @if (cluster.numberOfBinsInCluster < 5) {
                            <div class="option-direction" rtlDiv>
                              <b>{{ 'SITE_MANAGMENT.BIN_NI' | translate }}: </b>{{cluster.bin_name}} ({{cluster.bin_id}}), <b>{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}: </b>{{cluster.bin_address}}
                            </div>
                          } @else {
                            <div style="color: gray" rtlDiv>
                              <b>{{ 'SITE_MANAGMENT.BIN_NI' | translate }}: </b>{{cluster.bin_name}} ({{cluster.bin_id}}), <b>{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}: </b>{{cluster.bin_address}} - Fully Occupied
                            </div>
                          }
                        </mat-option>
                      } @else {
                        <mat-option [value]="cluster">{{ 'SITE_MANAGMENT.NEW_CLUSTER' | translate }}</mat-option>
                      }
                    }
                  </mat-select>
                </mat-form-field>
                @if (isShowAdvanced) {
                  <div>
                    <div><br><b>{{ 'SITE_MANAGMENT.ADVANCED_SET' | translate }}</b></div>
                    <mat-form-field>
                      <input type="number" matInput placeholder="{{ 'SITE_MANAGMENT.BIN_WIDTH' | translate }}" formControlName="binWidthCtrl">
                    </mat-form-field>
                    <mat-form-field>
                      <input type="number" matInput placeholder="{{ 'SITE_MANAGMENT.BIN_HEIGHT' | translate }}" formControlName="binHeighthCtrl">
                    </mat-form-field>
                    <mat-form-field>
                      <input type="number" matInput placeholder="{{ 'SITE_MANAGMENT.BIN_MAX_CAP_L' | translate }}" formControlName="binMaxLitersCapacityCtrl">
                    </mat-form-field>
                  </div>
                }
                <div>
                  @if (!isShowAdvanced) {
                    <div>
                      <button mat-button color="primary" (click)="onAdvancedOptionClicked()">{{ 'SITE_MANAGMENT.SHOW_ADVANCED_SETTINGS' | translate }}</button>
                    </div>
                  } @else {
                    <button mat-button color="primary" (click)="onHideAdvancedOptionClicked()">{{ 'SITE_MANAGMENT.HIDE_ADVANCED_SET' | translate }}</button>
                  }
                  <div id="onFirst">
                    <button mat-button matStepperNext class="mat-stepper-next" [disabled]="firstFormGroup.invalid || isInitForms" (click)="onFirstFormGroupSubmit()">{{ 'SITE_MANAGMENT.NEXT' | translate }}</button>
                  </div>
                </div>
              </form>
            </mat-step>
          </div>
        } @else {
          <mat-step [stepControl]="swapFormGroup" [completed]="false">
            <form [formGroup]="swapFormGroup" #swapFormDir="ngForm" (ngSubmit)="onSwapFormGroupSubmit()">
              <ng-template matStepLabel>
                @if (!isFastInstallation) {
                  <div>
                    <span class="lang_dir">{{ 'SITE_MANAGMENT.SWAP_INFO' | translate }}</span>
                  </div>
                } @else {
                  <span class="lang_dir">{{ 'SITE_MANAGMENT.FAST_INSTALLATION_INFO' | translate }}</span>
                }
              </ng-template>
              <mat-form-field #qrFormField rtlDiv>
                  <div>{{ 'SITE_MANAGMENT.NEW_DEVICE_ID' | translate }}*</div>
                  <div class="qr-frame">
                    <input type="text" #deviceId matInput formControlName="newDeviceId" required>
                    <button mat-button class="qr-icon" (click)="onClickQr()"><img src="../../assets/images/siteManagment/qr-code-scan.png" class="qr-image"></button>
                  </div>     
                @if (isSwapFormGroupSubmitted && fSwap.newDeviceId.errors) {
                  <div class="invalid-red-feedback">
                    @if (fSwap.newDeviceId.errors.required) {
                      <div>*{{ 'SITE_MANAGMENT.DEVICE_ID_REQ' | translate }}*</div>
                    }
                  </div>
                }
                <div class="invalid-red-feedback">
                  @switch (currentDeviceState) {
                    @case (1) {
                      <div>{{ 'SITE_MANAGMENT.ASSIGNED_ID' | translate }}</div>
                    }
                    @case (2) {
                      <div>{{ 'SITE_MANAGMENT.NOT_REGISTERED' | translate }}</div>
                    }
                    @case (3) {
                      <div>{{ 'SITE_MANAGMENT.INVALID_DEVICE_ID' | translate }}</div>
                    }
                    @default {
                      <div></div>
                    }
                  }
                </div>
                @if (show) {
                  <app-ngx-qr-reader (messageEvent)="receiveMessage($event)"></app-ngx-qr-reader>
                }
              </mat-form-field>
              <mat-form-field>
                <div>{{ 'SITE_MANAGMENT.SELECT_ASSOCIATED_BIN' | translate }}</div>
                <mat-select disableOptionCentering formControlName="oldBinId">
                  <mat-option class="auto-filter list-item" rtlDiv>
                    <ngx-mat-select-search rtlDiv class="select-filter" [formControl]="binFilterCtrl" [placeholderLabel]="translationsObj ? translationsObj.SITE_MANAGMENT.SEARCH_BIN_INSTALL_SWAP : ''" [noEntriesFoundLabel]="'no matching bin found'"></ngx-mat-select-search>
                  </mat-option>
                  @for (cluster of clustersToDisplay; track cluster) {
                    @if (cluster.bin_id !== 0) {
                      <mat-option [value]="cluster" class="list-item">
                        <div class="option-direction" rtlDiv>
                          <b>{{ 'SITE_MANAGMENT.BIN_NI' | translate }}: </b>{{cluster.bin_name}} ({{cluster.bin_id}}), <b>{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}: </b>{{cluster.bin_address}}
                        </div>
                      </mat-option>
                    } @else {
                      <mat-option rtlDiv class="list-item" [value]="cluster">{{ 'SITE_MANAGMENT.NEW_CLUSTER' | translate }}</mat-option>
                    }
                  }
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <div>{{ 'SITE_MANAGMENT.INS_ACC' | translate }}</div>
                <mat-select disableOptionCentering formControlName="binInsAcc">
                  @for (binInsAcc of installationAccessories; track binInsAcc) {
                    <mat-option [value]="binInsAcc.installation_accessory_types_id" class="list-item" rtlDiv>
                      <b>{{ currentLang != 'iw' ? binInsAcc.installation_accessory_types_name : binInsAcc.installation_accessory_types_name_hebrew }}</b>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <div id="swapFormstep">
                <button mat-button matStepperNext [disabled]="swapFormGroup.invalid" class="mat-stepper-next">{{ 'SITE_MANAGMENT.NEXT' | translate }}</button>
              </div>
            </form>
          </mat-step>
        }
        @if (!isSwap) {
          <mat-step [stepControl]="secondFormGroup" [completed]="false">
            <form [formGroup]="secondFormGroup" (ngSubmit)="onSecondFormGroupSubmit()">
              <ng-template matStepLabel>
                <span class="lang_dir">{{ 'SITE_MANAGMENT.BIN_LOCATION' | translate }}</span>
              </ng-template>
              <mat-form-field>
                <input matInput placeholder="{{ 'SITE_MANAGMENT.ADDRESS' | translate }}" formControlName="binAddress" required>
                @if (isSecondFormGroupSubmitted && f2.binAddress.errors) {
                  <div class="invalid-red-feedback">
                    @if (f2.binAddress.errors.required) {
                      <div>*{{ 'SITE_MANAGMENT.ADDRESS_REQ' | translate }}*</div>
                    }
                  </div>
                }
              </mat-form-field>
              <!-- <app-map (messageEvent)="recieveLocationMsg($event)" (address)="recieveAddress($event)" [showFields]="true"></app-map> -->
              <div id="secondForm">
                <button id="secondFormBack" mat-button matStepperPrevious>{{ 'SITE_MANAGMENT.BACK' | translate }}</button>
                <button mat-button matStepperNext [disabled]="secondFormGroup.invalid" class="mat-stepper-next">{{ 'SITE_MANAGMENT.NEXT' | translate }}</button>
              </div>
            </form>
          </mat-step>
        }
        <mat-step [stepControl]="thirdFormGroup" [completed]="false">
          <form [formGroup]="thirdFormGroup">
            <ng-template matStepLabel>
              <span class="lang_dir">{{ 'SITE_MANAGMENT.DEVICE_ACTIVATION' | translate }}</span>
            </ng-template>
            @if (isNewBinAssigned) {
              <div>
                @if (assignedBinId > 0) {
                  <div><p class="text-center green">{{ 'SITE_MANAGMENT.BIN_ID' | translate }}: {{assignedBinId}} {{ 'SITE_MANAGMENT.ASSIGN' | translate }}</p></div>
                } @else {
                  <p class="text-center red">Error while trying to create new bin (see console for more info)!</p>
                }
              </div>
            }
            @if (isDisplayProgressBar) {
              <div><mat-progress-bar mode="indeterminate" color="ThemePalette"></mat-progress-bar></div>
            }
            <p class="text-center">{{ 'SITE_MANAGMENT.DEVICE_ACTIVATION_FINGER' | translate }}</p>
            <p [@sensorDivState]="sensorDivState"><img src="../../assets/images/siteManagment/sensor.png" style="position: relative; top:10px; margin-right: calc(50% - 132px/2); height:100px;width: 132px;margin-left: calc(50% - 132px/2);"></p>
            <p [@fingerDivState]="fingerDivState" (@fingerDivState.start)="fingerDivStateStart($event)" (@fingerDivState.done)="fingerDivStateEnd($event)"><img src="../../assets/images/siteManagment/finger.png" style="position: relative; left: calc(50% - 40px/2 - 18px);; bottom: 40px; height:80px;right:calc(50% - 40px/2 + 18px);width: 40px;"></p>
            <div [hidden]="!showTimer" class="timer">{{ timeLeft }}</div>
            <div>
              <button mat-button (click)="openCancelInstallationDialog()">{{ 'SITE_MANAGMENT.CANCEL' | translate }}</button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>
            <span class="lang_dir">{{ 'SITE_MANAGMENT.PHISICAL_INS' | translate }}</span>
          </ng-template>
          @if (!isErrorToDisplay) {
            <div>
              <p class="text-center">{{ 'SITE_MANAGMENT.SUCCESSFULLY_ACT' | translate }}</p>
              <p class="text-center">{{ 'SITE_MANAGMENT.INSTALLED_NEXT' | translate }}</p>
              <p class="text-center early-complete-text"> {{ 'SITE_MANAGMENT.OR_COMPLETE_INSTALLATION' | translate }} </p>
            </div>
          } @else {
            <p class="text-center red">{{ 'SITE_MANAGMENT.DEVICE_ERRORS' | translate }} </p>
            <ul>
              @for (element of activationErrorsToDisplay; track element) {
                <li>- {{element}}</li>
              }
            </ul>
            <p class="text-center">{{ 'SITE_MANAGMENT.PLACE_DEV' | translate }}</p>
            <p class="text-center">{{ 'SITE_MANAGMENT.OTHER_CANCEL' | translate }}</p>
          }
          <div class="buttons-wrapper">
            <button mat-button (click)="openCancelInstallationDialog()">{{ 'SITE_MANAGMENT.CANCEL' | translate }}</button>
            <div class="complete-next-wrapper">
              <button mat-button id="physicalNext" matStepperNext class="mat-stepper-next" (click)="waitForPhysicalInstallationTransmission()">{{ 'SITE_MANAGMENT.NEXT' | translate }}</button>
              @if (!isErrorToDisplay) {
                <div>
                  <button mat-button (click)="completeInstallation()" class="complete-installation-button">{{ 'SITE_MANAGMENT.COMPLETE_INS' | translate }}</button>
                </div>
              }
            </div>
          </div>
        </mat-step>
        <mat-step [completed]="false">
          <ng-template matStepLabel>
            <span class="lang_dir">{{ 'SITE_MANAGMENT.INS_REPORT' | translate }}</span>
          </ng-template>
          @if (!isTranssmitedFromInsideTheBin) {
            <div>
              <p class="text-center">{{ 'SITE_MANAGMENT.WAITING_NOTE' | translate }}...</p>
              @if (isDisplayProgressBar) {
                <div><mat-progress-bar mode="indeterminate" color="ThemePalette"></mat-progress-bar></div>
              }
            </div>
          } @else {
            <p class="text-center">{{ 'SITE_MANAGMENT.REVIEW_TRANS' | translate }}</p>
            <table cdk-table [dataSource]="dataSource" class="mat-elevation-z8" width="100%">
              <ng-container matColumnDef="time">
                <th cdk-header-cell *cdkHeaderCellDef>{{ 'SITE_MANAGMENT.TIME' | translate }}</th>
                <td cdk-cell *cdkCellDef="let element"><b> {{ element.time }} </b></td>
              </ng-container>
              <ng-container matColumnDef="capacity">
                <th cdk-header-cell *cdkHeaderCellDef>{{ 'SITE_MANAGMENT.CAPACITY' | translate }}</th>
                <td cdk-cell *cdkCellDef="let element"> {{element.capacity}}cm ({{element.capacityPrecent}}%) </td>
              </ng-container>
              <ng-container matColumnDef="temperature">
                <th cdk-header-cell *cdkHeaderCellDef>{{ 'SITE_MANAGMENT.TEMPRATURE' | translate }}</th>
                <td cdk-cell *cdkCellDef="let element"> {{element.temperature}}&#176; </td>
              </ng-container>
              <ng-container matColumnDef="humidity">
                <th cdk-header-cell *cdkHeaderCellDef>{{ 'SITE_MANAGMENT.HUMIDITY' | translate }}</th>
                <td cdk-cell *cdkCellDef="let element"> {{element.humidity}}% </td>
              </ng-container>
              <ng-container matColumnDef="signalQuality">
                <th cdk-header-cell *cdkHeaderCellDef>{{ 'SITE_MANAGMENT.SIGNAL_QUAL' | translate }}</th>
                <td cdk-cell *cdkCellDef="let element" [ngClass]="{'warning' : element.signalQuality < 10}"> {{element.signalQuality}} </td>
              </ng-container>
              <ng-container matColumnDef="battery">
                <th cdk-header-cell *cdkHeaderCellDef>{{ 'SITE_MANAGMENT.BATTERY' | translate }}</th>
                <td cdk-cell *cdkCellDef="let element" [ngClass]="{'warning' : element.battery != 100}"> {{element.battery}}% </td>
              </ng-container>
              <ng-container matColumnDef="version">
                <th cdk-header-cell *cdkHeaderCellDef>{{ 'SITE_MANAGMENT.VERSION' | translate }}</th>
                <td cdk-cell *cdkCellDef="let element"> {{element.version}} </td>
              </ng-container>
              <tr cdk-header-row *cdkHeaderRowDef="finalReportColumns"></tr>
              <tr cdk-row *cdkRowDef="let row; columns: finalReportColumns;"></tr>
            </table>
            @if (isErrorToDisplay) {
              <div>
                <p class="text-center red">{{ 'SITE_MANAGMENT.ERRORS' | translate }}</p>
                <ul>
                  @for (element of activationErrorsToDisplay; track element) {
                    <li>- {{element}}</li>
                  }
                </ul>
                <p class="text-center">{{ 'SITE_MANAGMENT.COMPLETE_INSTALL' | translate }}</p>
                <p class="text-center">{{ 'SITE_MANAGMENT.OTHER_CANCEL' | translate }}</p>
              </div>
            }
          }
          <br>
            <div>
              <button mat-button (click)="openCancelInstallationDialog()">{{ 'SITE_MANAGMENT.CANCEL' | translate }}</button>
              <button mat-button matStepperPrevious id="backRep" (click)="onInstallationReviewClickBack()">{{ 'SITE_MANAGMENT.BACK' | translate }}</button>
              @if (isTranssmitedFromInsideTheBin) {
                <button mat-button id="complete" (click)="completeInstallation()" class="complete-installation-button">{{ 'SITE_MANAGMENT.COMPLETE_INS' | translate }}</button>
              }
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  }



  @if (isMobile) {
    <div>
      @if (!isSwap) {
        <div class="mobile-top-container">
          <div class="mobile-management-header">
            <span class="mobile-left-title">{{ 'TITLES.INSTALL_NEW_BIN' | translate }}</span>
          </div>
          <div class="mobile-separate">
            <mat-divider class="mobile-line"></mat-divider>
          </div>
        </div>
      } @else {
        <div class="mobile-top-container">
          <div class="mobile-management-header">
            @if (!isFastInstallation) {
              <div>
                <span class="mobile-left-title">{{ 'TITLES.SWAP_DEVICE' | translate }}</span>
              </div>
            } @else {
              <span class="mobile-left-title">{{ 'TITLES.FAST_INSTALLATION' | translate }}</span>
            }
          </div>
          <div class="mobile-separate">
            <mat-divider class="mobile-line"></mat-divider>
          </div>
        </div>
      }
      <div class="install-new-bin-page mobile-new-bin">
        <mat-vertical-stepper [linear]="isLinear" #stepper>
          @if (!isSwap) {
            <div>
              <mat-step [stepControl]="firstFormGroup" [completed]="false">
                <form [formGroup]="firstFormGroup" #formDirective="ngForm">
                  <ng-template matStepLabel>
                    <span class="lang_dir">{{ 'SITE_MANAGMENT.BASIC_BIN_INFO_INSTALL' | translate }}</span>
                  </ng-template>
                  <mat-form-field #qrFormField>
                    <input type="text" pattern="[0-9]*" #deviceId matInput placeholder="{{ 'SITE_MANAGMENT.DEVICE_ID' | translate }}" formControlName="deviceId" class="form-control" required>
                    <button mat-button type="button" class="qr-icon" (click)="onClickQr()"><img src="../../assets/images/siteManagment/qr-code-scan.png" class="qr-image"></button>
                    @if (isFirstFormGroupSubmitted && f1.deviceId.errors) {
                      <div class="invalid-red-feedback">
                        @if (f1.deviceId.errors.required) {
                          <div>*{{ 'SITE_MANAGMENT.DEVICE_ID_REQ' | translate }}*</div>
                        }
                      </div>
                    }
                    <div class="invalid-red-feedback">
                      @switch (currentDeviceState) {
                        @case (1) {
                          <div>{{ 'SITE_MANAGMENT.ASSIGNED_ID' | translate }}</div>
                        }
                        @case (2) {
                          <div>{{ 'SITE_MANAGMENT.NOT_REGISTERED' | translate }}</div>
                        }
                        @case (3) {
                          <div>{{ 'SITE_MANAGMENT.INVALID_DEVICE_ID' | translate }}</div>
                        }
                        @default {
                          <div></div>
                        }
                      }
                    </div>
                    @if (show) {
                      <app-ngx-qr-reader (messageEvent)="receiveMessage($event)"></app-ngx-qr-reader>
                    }
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput placeholder="{{ 'SITE_MANAGMENT.BIN_NAME' | translate }}" formControlName="binName" required>
                    @if (isFirstFormGroupSubmitted && f1.binName.errors) {
                      <div class="invalid-red-feedback">
                        @if (f1.binName.errors.required) {
                          <div>*{{ 'SITE_MANAGMENT.NAME_REQ' | translate }}*</div>
                        }
                      </div>
                    }
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput placeholder="{{ 'SITE_MANAGMENT.BIN_DESCRIPTION' | translate }}" formControlName="binDescription">
                  </mat-form-field>
                  <mat-form-field>
                    <input type="number" matInput placeholder="{{ 'SITE_MANAGMENT.BIN_MAX_CAP' | translate }}" formControlName="binMaxCapacity" required>
                    @if (isFirstFormGroupSubmitted && f1.binMaxCapacity.errors) {
                      <div class="invalid-red-feedback">
                        @if (f1.binMaxCapacity.errors.required) {
                          <div>*{{ 'SITE_MANAGMENT.MAX_REQ' | translate }}*</div>
                        }
                      </div>
                    }
                  </mat-form-field>
                  <mat-form-field>
                    <input type="number" matInput placeholder="{{ 'SITE_MANAGMENT.BIN_MIN_CAP' | translate }}" formControlName="binMinCapacity" required>
                    @if (isFirstFormGroupSubmitted && f1.binMinCapacity.errors) {
                      <div class="invalid-red-feedback">
                        @if (f1.binMinCapacity.errors.required) {
                          <div>*{{ 'SITE_MANAGMENT.MIN_REQ' | translate }}*</div>
                        }
                      </div>
                    }
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{ 'SITE_MANAGMENT.SELECT_MATERIAL_TYPE' | translate }}</mat-label>
                    <mat-select formControlName="wasteTypeListCtrl" (change)="onSubmit($event)">
                      @for (wasteType of lstWasteTypes; track wasteType) {
                        <mat-option [value]="wasteType.waste_type_id">
                          <div>
                            {{wasteType.waste_type_name}}
                          </div>
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>{{ 'SITE_MANAGMENT.SELECT_ASSOCIATED_BIN' | translate }}</mat-label>
                    <mat-select formControlName="binClusterId">
                      <mat-option class="auto-filter" rtlDiv>
                        <ngx-mat-select-search rtlDiv class="select-filter" [formControl]="binFilterCtrl" [placeholderLabel]="translationsObj ? translationsObj.SITE_MANAGMENT.SEARCH_BIN_INSTALL_SWAP : ''" [noEntriesFoundLabel]="'no matching bin found'"></ngx-mat-select-search>
                      </mat-option>
                      @for (cluster of clustersToDisplay; track cluster) {
                        @if (cluster.bin_id !== 0) {
                          <mat-option [value]="cluster" [disabled]="cluster.numberOfBinsInCluster >= 5">
                            @if (cluster.numberOfBinsInCluster < 5) {
                              <div class="option-direction" rtlDiv>
                                <b>{{ 'SITE_MANAGMENT.BIN_NI' | translate }}: </b>{{cluster.bin_name}} ({{cluster.bin_id}}), <b>{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}: </b>{{cluster.bin_address}}
                              </div>
                            } @else {
                              <div style="color: gray" rtlDiv>
                                <b>{{ 'SITE_MANAGMENT.BIN_NI' | translate }}: </b>{{cluster.bin_name}} ({{cluster.bin_id}}), <b>{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}: </b>{{cluster.bin_address}} - Fully Occupied
                              </div>
                            }
                          </mat-option>
                        } @else {
                          <mat-option [value]="cluster">{{ 'SITE_MANAGMENT.NEW_CLUSTER' | translate }}</mat-option>
                        }
                      }
                    </mat-select>
                  </mat-form-field>
                  @if (isShowAdvanced) {
                    <div>
                      <div><br><b>{{ 'SITE_MANAGMENT.ADVANCED_SET' | translate }}</b></div>
                      <mat-form-field>
                        <input type="number" matInput placeholder="{{ 'SITE_MANAGMENT.BIN_WIDTH' | translate }}" formControlName="binWidthCtrl">
                      </mat-form-field>
                      <mat-form-field>
                        <input type="number" matInput placeholder="{{ 'SITE_MANAGMENT.BIN_HEIGHT' | translate }}" formControlName="binHeighthCtrl">
                      </mat-form-field>
                      <mat-form-field>
                        <input type="number" matInput placeholder="{{ 'SITE_MANAGMENT.BIN_MAX_CAP_L' | translate }}" formControlName="binMaxLitersCapacityCtrl">
                      </mat-form-field>
                    </div>
                  }
                  <div>
                    @if (!isShowAdvanced) {
                      <div>
                        <button mat-button color="primary" (click)="onAdvancedOptionClicked()">{{ 'SITE_MANAGMENT.SHOW_ADVANCED_SETTINGS' | translate }}</button>
                      </div>
                    } @else {
                      <button mat-button color="primary" (click)="onHideAdvancedOptionClicked()">{{ 'SITE_MANAGMENT.HIDE_ADVANCED_SET' | translate }}</button>
                    }
                    <div id="onFirst">
                      <button mat-button matStepperNext class="mat-stepper-next" [disabled]="firstFormGroup.invalid || isInitForms" (click)="onFirstFormGroupSubmit()">{{ 'SITE_MANAGMENT.NEXT' | translate }}</button>
                    </div>
                  </div>
                </form>
              </mat-step>
            </div>
          } @else {
            <mat-step [stepControl]="swapFormGroup" [completed]="false">
              <form [formGroup]="swapFormGroup" #swapFormDir="ngForm" (ngSubmit)="onSwapFormGroupSubmit()">
                <ng-template matStepLabel>
                  @if (!isFastInstallation) {
                    <div>
                      <span class="lang_dir">{{ 'SITE_MANAGMENT.SWAP_INFO' | translate }}</span>
                    </div>
                  } @else {
                    <span class="lang_dir">{{ 'SITE_MANAGMENT.FAST_INSTALLATION_INFO' | translate }}</span>
                  }
                </ng-template>
                <mat-form-field #qrFormField>
                  <input type="text" pattern="[0-9]*" #deviceId matInput placeholder="{{ 'SITE_MANAGMENT.NEW_DEVICE_ID' | translate }}" formControlName="newDeviceId" class="form-control" required>
                  <button type="button" mat-button class="qr-icon" (click)="onClickQr()"><img src="../../assets/images/siteManagment/qr-code-scan.png" class="qr-image"></button>
                  @if (isSwapFormGroupSubmitted && fSwap.newDeviceId.errors) {
                    <div class="invalid-red-feedback">
                      @if (fSwap.newDeviceId.errors.required) {
                        <div>*{{ 'SITE_MANAGMENT.DEVICE_ID_REQ' | translate }}*</div>
                      }
                    </div>
                  }
                  <div class="invalid-red-feedback">
                    @switch (currentDeviceState) {
                      @case (1) {
                        <div>{{ 'SITE_MANAGMENT.ASSIGNED_ID' | translate }}</div>
                      }
                      @case (2) {
                        <div>{{ 'SITE_MANAGMENT.NOT_REGISTERED' | translate }}</div>
                      }
                      @case (3) {
                        <div>{{ 'SITE_MANAGMENT.INVALID_DEVICE_ID' | translate }}</div>
                      }
                      @default {
                        <div></div>
                      }
                    }
                  </div>
                  @if (show) {
                    <app-ngx-qr-reader (messageEvent)="receiveMessage($event)"></app-ngx-qr-reader>
                  }
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{ 'SITE_MANAGMENT.SELECT_ASSOCIATED_BIN' | translate }}</mat-label>
                  <mat-select formControlName="oldBinId">
                    <mat-option class="auto-filter" rtlDiv>
                      <ngx-mat-select-search rtlDiv class="select-filter" [formControl]="binFilterCtrl" [placeholderLabel]="translationsObj ? translationsObj.SITE_MANAGMENT.SEARCH_BIN_INSTALL_SWAP : ''" [noEntriesFoundLabel]="'no matching bin found'"></ngx-mat-select-search>
                    </mat-option>
                    @for (cluster of clustersToDisplay; track cluster) {
                      @if (cluster.bin_id !== 0) {
                        <mat-option [value]="cluster">
                          <div class="option-direction" rtlDiv>
                            <b>{{ 'SITE_MANAGMENT.BIN_NI' | translate }}: </b>{{cluster.bin_name}} ({{cluster.bin_id}}), <b>{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}: </b>{{cluster.bin_address}}
                          </div>
                        </mat-option>
                      } @else {
                        <mat-option [value]="cluster">{{ 'SITE_MANAGMENT.NEW_CLUSTER' | translate }}</mat-option>
                      }
                    }
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{ 'SITE_MANAGMENT.INS_ACC' | translate }}</mat-label>
                  <mat-select formControlName="binInsAcc">
                    @for (binInsAcc of installationAccessories; track binInsAcc) {
                      <mat-option [value]="binInsAcc.installation_accessory_types_id">
                        <b>{{ currentLang != 'iw' ? binInsAcc.installation_accessory_types_name : binInsAcc.installation_accessory_types_name_hebrew }}</b>
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                <div id="swapFormstep">
                  <button mat-button matStepperNext [disabled]="swapFormGroup.invalid" class="mat-stepper-next">{{ 'SITE_MANAGMENT.NEXT' | translate }}</button>
                </div>
              </form>
            </mat-step>
          }
          @if (!isSwap) {
            <mat-step [stepControl]="secondFormGroup" [completed]="false">
              <form [formGroup]="secondFormGroup" (ngSubmit)="onSecondFormGroupSubmit()">
                <ng-template matStepLabel>
                  <span class="lang_dir">{{ 'SITE_MANAGMENT.BIN_LOCATION' | translate }}</span>
                </ng-template>
                <mat-form-field>
                  <input matInput placeholder="{{ 'SITE_MANAGMENT.ADDRESS' | translate }}" formControlName="binAddress" required>
                  @if (isSecondFormGroupSubmitted && f2.binAddress.errors) {
                    <div class="invalid-red-feedback">
                      @if (f2.binAddress.errors.required) {
                        <div>*{{ 'SITE_MANAGMENT.ADDRESS_REQ' | translate }}*</div>
                      }
                    </div>
                  }
                </mat-form-field>
                <!-- <app-map (messageEvent)="recieveLocationMsg($event)" (address)="recieveAddress($event)" [showFields]="true"></app-map> -->
                <div id="secondForm">
                  <button id="secondFormBack" mat-button matStepperPrevious>{{ 'SITE_MANAGMENT.BACK' | translate }}</button>
                  <button mat-button matStepperNext [disabled]="secondFormGroup.invalid" class="mat-stepper-next">{{ 'SITE_MANAGMENT.NEXT' | translate }}</button>
                </div>
              </form>
            </mat-step>
          }
          <mat-step [stepControl]="thirdFormGroup" [completed]="false">
            <form [formGroup]="thirdFormGroup">
              <ng-template matStepLabel>
                <span class="lang_dir">{{ 'SITE_MANAGMENT.DEVICE_ACTIVATION' | translate }}</span>
              </ng-template>
              @if (isNewBinAssigned) {
                <div>
                  @if (assignedBinId > 0) {
                    <div><p class="text-center green">{{ 'SITE_MANAGMENT.BIN_ID' | translate }}: {{assignedBinId}} {{ 'SITE_MANAGMENT.ASSIGN' | translate }}</p></div>
                  } @else {
                    <p class="text-center red">Error while trying to create new bin (see console for more info)!</p>
                  }
                </div>
              }
              @if (isDisplayProgressBar) {
                <div><mat-progress-bar mode="indeterminate" color="ThemePalette"></mat-progress-bar></div>
              }
              <p class="text-center">{{ 'SITE_MANAGMENT.DEVICE_ACTIVATION_FINGER' | translate }}</p>
              <p [@sensorDivState]="sensorDivState"><img src="../../assets/images/siteManagment/sensor.png"  style="position: relative;width: 132px ;top:10px; margin-right: calc(50% - 132px/2); height:100px;margin-left: calc(50% - 132px/2);"></p>
              <p [@fingerDivState]="fingerDivState" (@fingerDivState.start)="fingerDivStateStart($event)" (@fingerDivState.done)="fingerDivStateEnd($event)"><img src="../../assets/images/siteManagment/finger.png" style="position: relative; left: calc(50% - 41px/2 - 20px); bottom: 40px; height:80px;width: 41px;right: calc(50% - 41px/2 + 20px);"></p>
              <div [hidden]="!showTimer" class="timer">{{ timeLeft }}</div>
              <div>
                <button mat-button (click)="openCancelInstallationDialog()">{{ 'SITE_MANAGMENT.CANCEL' | translate }}</button>
              </div>
            </form>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>
              <span class="lang_dir">{{ 'SITE_MANAGMENT.PHISICAL_INS' | translate }}</span>
            </ng-template>
            @if (!isErrorToDisplay) {
              <div>
                <p class="text-center">{{ 'SITE_MANAGMENT.SUCCESSFULLY_ACT' | translate }}!</p>
                <p class="text-center">{{ 'SITE_MANAGMENT.INSTALLED_NEXT' | translate }}</p>
                <p class="text-center early-complete-text"> {{ 'SITE_MANAGMENT.OR_COMPLETE_INSTALLATION' | translate }} </p>
              </div>
            } @else {
              <p class="text-center red">{{ 'SITE_MANAGMENT.DEVICE_ERRORS' | translate }} </p>
              <ul>
                @for (element of activationErrorsToDisplay; track element) {
                  <li>- {{element}}</li>
                }
              </ul>
              <p class="text-center">{{ 'SITE_MANAGMENT.PLACE_DEV' | translate }}</p>
              <p class="text-center">{{ 'SITE_MANAGMENT.OTHER_CANCEL' | translate }}</p>
            }
            <div class="buttons-wrapper">
              <button mat-button (click)="openCancelInstallationDialog()">{{ 'SITE_MANAGMENT.CANCEL' | translate }}</button>
              <div class="complete-next-wrapper">
                <button mat-button id="physicalNext" matStepperNext class="mat-stepper-next" (click)="waitForPhysicalInstallationTransmission()">{{ 'SITE_MANAGMENT.NEXT' | translate }}</button>
                @if (!isErrorToDisplay) {
                  <div>
                    <button mat-button (click)="completeInstallation()" class="complete-installation-button">{{ 'SITE_MANAGMENT.COMPLETE_INS' | translate }}</button>
                  </div>
                }
              </div>
            </div>
          </mat-step>
          <mat-step [completed]="false">
            <ng-template matStepLabel>
              <span class="lang_dir">{{ 'SITE_MANAGMENT.INS_REPORT' | translate }}</span>
            </ng-template>
            @if (!isTranssmitedFromInsideTheBin) {
              <div>
                <p class="text-center">{{ 'SITE_MANAGMENT.WAITING_NOTE' | translate }}...</p>
                @if (isDisplayProgressBar) {
                  <div><mat-progress-bar mode="indeterminate" color="ThemePalette"></mat-progress-bar></div>
                }
              </div>
            } @else {
              <p class="text-center">{{ 'SITE_MANAGMENT.REVIEW_TRANS' | translate }}</p>
              <div [ngClass]="{'hide':spinnerExist,'showSpinner':!spinnerExist}" class="spinner-border mobile-spinner" role="status">
                <span class="sr-only"></span>
              </div>
              <table class="mobile-table mat-elevation-z8" [dataSource]="dataSource"
                rtlDiv #table mat-table multiTemplateDataRows
                matSort matSortStart="asc" matSortDisableClear matSortDirection="desc"
                matSortActive="time" [ngClass]="{'show':spinnerExist,'hide':!spinnerExist}" >
                <ng-container matColumnDef="time">
                  <th class="mobile-th" style="height: 52px;" mat-sort-header="time" mat-header-cell *matHeaderCellDef>
                    {{ 'SITE_MANAGMENT.TIME' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element"> <span title="{{element ? element.time : ''}}"> {{element ? element.time : ''}}</span> </td>
                </ng-container>
                <ng-container matColumnDef="capacity">
                  <th mat-sort-header="capacity" class="mobile-th mobile-status-pos" rtlDiv style="height: 52px;"  mat-header-cell *matHeaderCellDef>
                  {{ 'SITE_MANAGMENT.CAPACITY' | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    <span class="space-arrow"> {{element.capacity}}cm ({{element.capacityPrecent}}%)</span>
                    @if (expandedElement !== element) {
                      <img class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                    }
                    @if (expandedElement === element) {
                      <img class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                  <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" rtlDiv>
                    <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                      <div class="mobile-element-diagram" rtlDiv>
                        <div class="dFlex">
                          <div class="mobile-id-details-title">{{ 'SITE_MANAGMENT.TEMPRATURE' | translate }}</div>
                          <div class="mobile-id-details-value"> {{element.temperature}}&#176; </div>
                        </div>
                        <div class="dFlex">
                          <div class="mobile-address-details-title">{{ 'SITE_MANAGMENT.HUMIDITY' | translate }}</div>
                          <div class="mobile-address-details-value"> {{element.humidity}}% </div>
                        </div>
                        <div class="dFlex">
                          <div class="mobile-description-details-title">{{ 'SITE_MANAGMENT.SIGNAL_QUAL' | translate }}</div>
                          <div class="mobile-description-details-value" [ngClass]="{'warning' : element.signalQuality < 10}"> {{element.signalQuality}} </div>
                        </div>
                        <div class="dFlex">
                          <div class="mobile-collection-details-title">{{ 'SITE_MANAGMENT.BATTERY' | translate }}</div>
                          <div class="mobile-collection-details-value" [ngClass]="{'warning' : element.battery != 100}"> {{element.battery}}% </div>
                        </div>
                        <div class="dFlex">
                          <div class="mobile-update-details-title">{{ 'SITE_MANAGMENT.VERSION' | translate }}</div>
                          <div class="mobile-update-details-value">{{element.version}}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr style="display: flex;width: 100%;" mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                  (click)="expandedElement = expandedElement === element ? null : element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
              </table>
              @if (isErrorToDisplay) {
                <div>
                  <p class="text-center red">{{ 'SITE_MANAGMENT.ERRORS' | translate }}</p>
                  <ul>
                    @for (element of activationErrorsToDisplay; track element) {
                      <li>- {{element}}</li>
                    }
                  </ul>
                  <p class="text-center">{{ 'SITE_MANAGMENT.COMPLETE_INSTALL' | translate }}</p>
                  <p class="text-center">{{ 'SITE_MANAGMENT.OTHER_CANCEL' | translate }}</p>
                </div>
              }
            }
            <br>
              <div>
                <button mat-button (click)="openCancelInstallationDialog()">{{ 'SITE_MANAGMENT.CANCEL' | translate }}</button>
                <button mat-button matStepperPrevious id="backRep" (click)="onInstallationReviewClickBack()">{{ 'SITE_MANAGMENT.BACK' | translate }}</button>
                @if (isTranssmitedFromInsideTheBin) {
                  <button mat-button id="complete" (click)="completeInstallation()" class="complete-installation-button">{{ 'SITE_MANAGMENT.COMPLETE_INS' | translate }}</button>
                }
              </div>
            </mat-step>
          </mat-vertical-stepper>
        </div>
      </div>
    }

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewRef } from '@angular/core';
import _ from 'lodash';
import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslatorService } from '../../../services/translator_service';
import { Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-truck-details',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,CommonModule,MatCardModule,MatDividerModule],
  templateUrl: './truck-details.component.html',
  styleUrl: './truck-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TruckDetailsComponent {
  @Input() distance: number;
	@Input() totalTime: string;
	@Input() truckDriverName: string;
	@Input() tabletTeamsAllocationId: string;
	@Input() loginException: object;
	@Input() chosenTruck:object;
	@Input() chosenSite:object;
	isMobile: Boolean;
	binToCollect: number = 0;
	collectedBins: number = 0;
	spinnerActive : boolean = true;
	actualTime:string = '';
	planTime:string = '';
	groups:any[]=[];
	notIncluded:any[]=[];
	translate;
	currentLang;
	translationsObj;
	estimatedTravelTime = '';
	estimatedTravelDistance:number = 0;
	isHours:boolean = false;
	isHoursOfActualTime:boolean = false;
	prep_team_plan_start_time:string = '';
	return_back_team_plan_start_time:string = '';
	private readonly destroy$ = new Subject();

	constructor(private responsiveService: ResponsiveService,private apiQuery:ApiQuery,
		private translator: TranslatorService,private cd: ChangeDetectorRef) {
		this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
			this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			this.currentLang = value;
		})
	 }

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.spinnerActive = true;

		this.apiQuery.CollectionsStatsForWorkplans$.pipe(takeUntil(this.destroy$)).subscribe((data:any) => {
			if (!_.isEmpty(data[0])){
				this.groups = [];
				this.notIncluded = [];
				_.each(data, (site) => {
				  if(site.site_bins_collections != undefined){
					_.each(site.site_bins_collections["0_25"], (item) => {    
					  this.groups.push(item);
					});    
					_.each(site.site_bins_collections["26_50"], (item) => {   
					  this.groups.push(item);
					});
					_.each(site.site_bins_collections["51_75"], (item) => {     
					  this.groups.push(item);
					});
					_.each(site.site_bins_collections["76_85"], (item) => {          
					  this.groups.push(item);
					});
					_.each(site.site_bins_collections["86_100"], (item) => {         
					  this.groups.push(item);
					});
				  }
				});

				_.each(this.groups, (bin) => {
					if(bin.collection_cause_id == 0){
						this.notIncluded.push(bin);
					}
				});	
			}else{
				this.groups = [];
				this.notIncluded = [];
			}
			this.cd.detectChanges();
		})
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

	ngOnDestroy(){
		this.destroy$.next(true);
		this.destroy$.complete();
		if (!this.cd['destroyed'] || !(this.cd as ViewRef).destroyed) {			
			this.cd.detectChanges();
		}
	}

	ngOnChanges(){
		this.actualTime = '';
		this.planTime = '';
		let totalRelevantBins = []; //exclude iscollected = 2 with cause = 6
		if(this.chosenTruck && this.chosenTruck["Bins_collection_info"]){
			_.each(this.chosenTruck["Bins_collection_info"], (bin) => {
				totalRelevantBins.push(bin);
			});
			
			const estimatedSeconds = Number(this.chosenTruck["expected_travel_time"]);
			const secs = estimatedSeconds % 60;
			const mins = ((estimatedSeconds - secs) / 60) % 60;
			const hrs = (((estimatedSeconds - secs) / 60 - mins) / 60).toString().padStart(2, "0");	  
			const minutesConverted = mins.toString().padStart(2, "0");
			const total = hrs+":"+minutesConverted;
			if(Number(total.split(":")[0]) > 0){
				this.estimatedTravelTime = total;
				this.isHours = true;
			}else{
				this.estimatedTravelTime = total.split(":")[1];
				this.isHours = false;
			}

			if(this.totalTime && Number(this.totalTime.split(":")[0]) > 0){
				this.isHoursOfActualTime = true;
			}else{
				this.isHoursOfActualTime = false;
			}
			
			this.estimatedTravelDistance = Number((Number(this.chosenTruck["expected_travel_distance"])/1000).toFixed());
			this.binToCollect = totalRelevantBins.length;
			this.collectedBins = this.getCollectedBinCount(totalRelevantBins);
			this.prep_team_plan_start_time = moment(this.chosenTruck["prep_team_plan_start_time"]).format('HH:mm');
			this.return_back_team_plan_start_time = moment(this.chosenTruck["return_back_team_plan_start_time"]).format('HH:mm');
		}
		if(this.chosenSite && this.chosenSite["actual_plan_start_time"]!=null){				
			this.actualTime = moment(this.chosenSite["actual_plan_start_time"]).format('HH:mm');
		}		
		if(this.chosenSite && this.chosenSite["plan_start_time"]!=null){				
			this.planTime = moment(this.chosenSite["plan_start_time"]).format('HH:mm');
		}
	}

	printDistance() {
		return parseFloat(this.distance.toString()).toFixed(2);
	}

	printStartTime(){					
		if(this.actualTime != '' && this.actualTime != null)
		{
			return this.actualTime;
		}
		else{
			return this.planTime;
		}
	}

	displayStartTime(){
		if(this.actualTime != '' && this.actualTime != null)
		{
			return false;
		}
		else{
			return true;
		}
	}

	getCollectedBinCount = (data) => {
		this.spinnerActive = false;
		return data.filter(n => n.is_collected == 1).length;
	}

}

<div class="bin-sampling-history-report">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title class="title">{{'WIDGETS.MONITORING_REPORT' | translate}} {{dateDiff}}</mat-card-title>
    </mat-card-header>
    <mat-divider class="top-divider"></mat-divider>
    <div class='spinner-container' [hidden]="!spinnerActive">
      <div class="spinner"></div>
    </div>
    <div [ngClass]="{'showMe':!spinnerActive,'hideMe':spinnerActive}">
      <mat-card-content>
        <div class="date-selector">
          <span class="lang-dropdown" rtlDiv mat-icon-button [matMenuTriggerFor]="appMenu">
            <span class="lang-txt" rtlDiv>{{chosenType}}</span>
            <img class="arrowDown" rtlDiv src="assets/images/topmenu/arrow-down.svg" alt="" />
          </span>
          <mat-menu #appMenu="matMenu">
            <button class="outLine" [ngClass]="{'selected': dataType=='Capacity'}" (click)="changeDataType('Capacity')" mat-menu-item>{{'SAMPLING_HISTORY_REPORT.CAPACITY' | translate }}</button>
            <button class="outLine" [ngClass]="{'selected': dataType=='Temperature'}" (click)="changeDataType('Temperature')" mat-menu-item>{{'SAMPLING_HISTORY_REPORT.TEMPERATURE' | translate }}</button>
            <button class="outLine" [ngClass]="{'selected': dataType=='Humidity'}" (click)="changeDataType('Humidity')" mat-menu-item>{{'SAMPLING_HISTORY_REPORT.HUMIDITY' | translate }}</button>
            <button class="outLine" [ngClass]="{'selected': dataType=='Battery Status'}" (click)="changeDataType('Battery Status')" mat-menu-item>{{'TITLES.BATTERY_STATUS' | translate }}</button>
            <button class="outLine" [ngClass]="{'selected': dataType=='Signal Quality'}" (click)="changeDataType('Signal Quality')" mat-menu-item>{{'SITE_MANAGMENT.SIGNAL_QUAL' | translate }}</button>
          </mat-menu>
          <div class='range-type-container' rtlDiv>
            <div class='range-type-btn' (click)="changeCollectionReportRange('day')" [ngClass]="{'selected_text':selected_time === 'day'}">{{
            'SAMPLING_HISTORY_REPORT.DAY' | translate }}</div>
            <div class='range-type-btn' (click)="changeCollectionReportRange('week')" [ngClass]="{'selected_text':selected_time === 'week'}">{{
            'SAMPLING_HISTORY_REPORT.WEEK' | translate }}</div>
            <div class='range-type-btn-last' rtlDiv (click)="changeCollectionReportRange('month')" [ngClass]="{'selected_text':selected_time === 'month'}">{{
            'SAMPLING_HISTORY_REPORT.MONTH' | translate }}</div>
          </div>
        </div>
        <div class='datepicker-wrapper' rtlDiv>
          <div class='selected-dates' id="openBinsTable">
            <div class='dates-range' rtlDiv (click)='showHideBinListDropdown()'>
              @if (selection && selection.selected && selection.selected.length == 1) {
                <span class="selectedLbl" rtlDiv>
                  @if (usageType == 1) {
                    <span>{{'LIVE_INFO.BIN_ID' | translate}}</span>
                  }
                  @if (usageType != 1) {
                    <span>{{'LIVE_INFO.TANK_ID' | translate}}</span>
                  }
                  {{selectedBins}}
                </span>
              }
              @if (selection && selection.selected && selection.selected.length > 1) {
                <span class="selectedLbl" rtlDiv>{{'LIVE_INFO.MULTI_SELECTION' | translate}}</span>
              }
              <div class="dropdown-container">
                <img class='dropdown-icon' src="assets/images/dashboard/dropdown_arrow.svg" alt="dropdown_arrow" rtlDiv>
              </div>
            </div>
            @if (selection.selected.length > 0) {
              <div rtlDiv class="tags-chosen">
                @for (item of selection.selected; track item) {
                  <div class="tag-frame" rtlDiv>
                    <div class="alert-info-frame" rtlDiv>
                      <button class="tag" [style.background]="item['color']" rtlDiv>
                        <span>{{item['bin_id']}} | {{item['bin_name']}} </span>
                        <img class="close-btn" (click)="removeBinFromChart(item)" src="assets/images/reports/close.svg" alt="close" rtlDiv>
                      </button>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
          <div class="drop-down-list-container add-remove-bins" id="bins-list" [ngClass]="{'showMe':showBinsListDropDownList,'hideMe':!showBinsListDropDownList}" rtlDiv>
            <div class='bins-list-table'>
              <div class="search-bin-container">
                <input class="search-input" placeholder="{{usageType == 1 ? (translationsObj ? translationsObj.SAMPLING_HISTORY_REPORT.CHANGE_BIN_SEARCH : '') : (translationsObj ? translationsObj.SAMPLING_HISTORY_REPORT.CHANGE_TANK_SEARCH : '')}}"
                  (input)="searchBin($event.target)" />
                  <img class='search-icon' src="assets/images/reports/dropdown_search_icon.svg" alt="" rtlDiv />
                </div>
                <cdk-virtual-scroll-viewport tvsItemSize="48" headerHeight="56" style="height: 400px;">
                  <table class="table" mat-table #table [dataSource]="getTableData()" matSort matSortStart="asc" matSortActive="bin_name"
                    matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef class="space" rtlDiv>
                      </th>
                      <td class="space" mat-cell *matCellDef="let row" rtlDiv>
                        <mat-checkbox (click)="$event.stopPropagation()" [disabled]="(selection.selected.length == 1 && selection.isSelected(row)) || (selection.selected.length == 3 && !selection.isSelected(row))" (change)="$event ? selection.toggle(row) : null;selectBin()"
                          [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                      </td>
                    </ng-container>
  
                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                      <th class="space" mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" rtlDiv> {{
                      'LIST_COLUMNS.NAME' | translate }} </th>
                      <td class="space" mat-cell *matCellDef="let element" rtlDiv>
                        {{element ? element.bin_name :
                      ''}} </td>
                    </ng-container>
  
                    <!-- id Column -->
                    <ng-container matColumnDef="address">
                      <th class="space" mat-header-cell *matHeaderCellDef mat-sort-header="bin_address"> {{ 'LIST_COLUMNS.ADDRESS' | translate }}
                      </th>
                      <td mat-cell class="space" *matCellDef="let element">{{element ? element.bin_address: ''}}</td>
                    </ng-container>
  
                    <!-- neighborhood Column -->
                    <ng-container matColumnDef="neighborhood">
                      <th [hidden]="usageType == 2" class="space" mat-header-cell *matHeaderCellDef mat-sort-header="bin_neighborhood"> {{'LIST_COLUMNS.NEIGHBORHOOD' | translate}}
                      </th>
                      <td [hidden]="usageType == 2" class="space" mat-cell *matCellDef="let element"> {{element ? element.bin_neighborhood: ''}} </td>
                    </ng-container>
  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </cdk-virtual-scroll-viewport>
              </div>
            </div>
          </div>
  
          <div class='graph-wrapper'>
            <div class="collection-chart" [hidden]="!chart">
              <div class='graph-title-container'>
                <div class='graph-title'>{{getGraphTitle()}}</div>
                <div class='next-prev-btns-wrapper'>
                  <div class='time-range' rtlDiv>{{getDateTimeToShow()}}</div>
                  <img class='icon-img prev' [ngClass]="{'disable': paginationIndex === 1}" (click)='moveGraphPrev()' src="../assets/images/reports/prev_icon.svg"
                    alt="prev_icon" rtlDiv>
                    <img class='icon-img next' [ngClass]="{'disable': isNextBtnDisable()}" (click)='moveGraphNext()' src="../assets/images/reports/next_icon.svg"
                      alt="next_icon" rtlDiv>
                </div>
                </div>
                <div class='graph-data'>
                    <canvas id="binData"></canvas>
                </div>
            </div>
          </div>
      </mat-card-content>
    </div>
  </mat-card>
</div>
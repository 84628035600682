@if (!isMobile) {
  <div class="collection-report">
    @if (showReportPieTabs) {
      <mat-card class="widget-div">
        <mat-card-header>
          <mat-card-title class="title">{{'TITLES.COLLECTION_REPORT' | translate}}</mat-card-title>
          <div class="close-btn" rtlDiv (click)="closeCMP()" [hidden]="!showReportPieBtn">
            <img class="close-img" src="../../../assets/images/dashboard/closeX.svg">
          </div>
        </mat-card-header>
        <mat-divider class="top-divider"></mat-divider>
        <div class='spinner-container' [hidden]="!spinnerActive">
          <div class="spinner"></div>
        </div>
        @if (!spinnerActive) {
          <mat-tab-group mat-stretch-tabs="false">
            <mat-tab>
              <ng-template mat-tab-label>
                {{'WORKPLAN.TODAY' | translate}}
              </ng-template>
              <mat-card-content>
                <div style="margin: 0 auto;height: 350px;" [chart]="donutChartToday"></div>
              </mat-card-content>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                {{'WIDGETS.MONTH_AGO' | translate}}
              </ng-template>
              <mat-card-content>
                <div style="margin: 0 auto;height: 350px;" [chart]="donutChart"></div>
              </mat-card-content>
            </mat-tab>
          </mat-tab-group>
        }
      </mat-card>
    }
    @if (!showReportPieTabs) {
      <mat-card>
        <mat-card-header>
          <mat-card-title class="title">{{'WIDGETS.COLLECTION_BY_CAP' | translate}} {{datesDiff}}</mat-card-title>
          <div class="close-btn" rtlDiv (click)="closeCMP()" [hidden]="!showReportPieBtn">
            <img class="close-img" src="../../../assets/images/dashboard/closeX.svg">
          </div>
        </mat-card-header>
        <mat-divider class="top-divider"></mat-divider>
        <div class='spinner-container' [hidden]="!spinnerActive">
          <div class="spinner"></div>
        </div>
        <mat-card-content [hidden]="spinnerActive">
          <div style="margin: 0 auto;height: 350px;" [chart]="donutChart"></div>
        </mat-card-content>
      </mat-card>
    }
  </div>
}


@if (isMobile) {
  <div>
    <div class="accordion md-accordion" role="tablist" aria-multiselectable="true">
      @if (showReportPieTabs) {
        <div class="card mobile-card" >
          <div class="card-header mobile-header">
            <div class="mobile-flex">
              <span class="mobile-title">{{'TITLES.COLLECTION_REPORT' | translate}}</span>
            </div>
            <div class="mobile-close-btn" rtlDiv (click)="closeCMP()" [hidden]="!showReportPieBtn">
              <img class="mobile-close-img" src="../../../assets/images/dashboard/closeX.svg">
            </div>
          </div>
          <div class="collapse show" role="tabpanel">
            <div class="card-body mobile-kpi">
              <div [hidden]="!spinnerActive" class="spinner-border mobile-spinner" role="status">
                <span class="sr-only"></span>
              </div>
              @if (!spinnerActive) {
                <mat-tab-group mat-stretch-tabs="false">
                  <mat-tab>
                    <ng-template mat-tab-label>
                      {{'WORKPLAN.TODAY' | translate}}
                    </ng-template>
                    <mat-card-content>
                      <div style="margin: 0 auto;height: 350px;" [chart]="donutChartToday"></div>
                    </mat-card-content>
                  </mat-tab>
                  <mat-tab>
                    <ng-template mat-tab-label>
                      {{'WIDGETS.MONTH_AGO' | translate}}
                    </ng-template>
                    <mat-card-content>
                      <div style="margin: 0 auto;height: 350px;" [chart]="donutChart"></div>
                    </mat-card-content>
                  </mat-tab>
                </mat-tab-group>
              }
            </div>
          </div>
        </div>
      }
      @if (!showReportPieTabs) {
        <div class="card mobile-card" >
          <div class="card-header mobile-header">
            <div class="mobile-flex">
              <span class="mobile-title">{{'WIDGETS.COLLECTION_BY_CAP' | translate}} {{datesDiff}}</span>
            </div>
            <div class="mobile-close-btn" rtlDiv (click)="closeCMP()" [hidden]="!showReportPieBtn">
              <img class="mobile-close-img" src="../../../assets/images/dashboard/closeX.svg">
            </div>
          </div>
          <div class="collapse show" role="tabpanel">
            <div class="card-body mobile-kpi">
              <div [hidden]="!spinnerActive" class="spinner-border mobile-spinner" role="status">
                <span class="sr-only"></span>
              </div>
              <div [hidden]="spinnerActive" style="margin: 0 auto;height: 350px;" [chart]="donutChart"></div>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
}
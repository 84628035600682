@if (!isMobile) {
  <div>
    <div class="workplan-map truck-map">
      <mat-card>
        <mat-card-header>
          <mat-card-title class="title">
            {{ 'WIDGETS.MAP_VIEW' | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-divider class="top-divider"></mat-divider>
        <mat-card-content id="truck-map-container">

          <div class="filter-container">
            <div class='search-input-container' rtlDiv>
              <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
              <input class='search-input' type="text" (keyup)="applyMainFilter($event.target['value'])" placeholder="{{'OTHER.SEARCH' | translate}}"
                id="main_filter" rtlDiv>
            </div>
            <div class='drop-down-main-wrapperr show-all-bins' rtlDiv>
              <div class="drop-down-list-container" rtlDiv>
                <div class="menu-Cmp dropdown">
                  <div class="dropdown-toggle exception-frame" data-bs-toggle="dropdown">
                    <img [ngClass]="{'hide' : exceptions == null || exceptions.length == 0}" class="exc-details" src="assets/images/workplans-page/exceptions icon.svg" alt="" rtlDiv />
                  </div>
                  <div class="dropdown-menu list-items" rtlDiv>
                    @for (item of exceptionsTypes; track item) {
                      <div class="menuItem">
                        <div class="dflex">
                          <span class="item-txt">{{ currentLang != 'iw' ? item['exception_id_name'] : item['exception_id_name_hebrew'] }}</span>
                          <img rtlDiv class="selectedItem" alt="" [src]="item['icon']">
                        </div>
                      </div>
                    }
                  </div>
                </div>
                @for (item of items; track item) {
                  <div class="drop-down-list-item" [ngClass]="{'selected-item' : !item.selected}" [ngStyle]="{background: item.color}" (click)="selectUnselectItem(item)" rtlDiv>
                    <mat-checkbox [checked]="item.selected" (click)="selectUnselectItem(item);$event.stopPropagation();false"></mat-checkbox>
                    <span class="colorTxt">{{item.displayName | translate}}</span>
                  </div>
                }
              </div>
            </div>
          </div>

          <div class='truck-location-container'>
            <google-map id="map"
              (mapClick)="mapClick()"
              [options]="mapOptionsPc"
              (mapInitialized)="onMapReady($event)">
              @if (moreThanMax) {
                <map-marker-clusterer [ignoreHidden]="true" [minimumClusterSize]= "5" [styles]="mapOptions.styles" [calculator]="mapOptions.calculator">
                  @for (bin of markersArray; track bin; let i = $index) {
                    @if(isNumber(bin.lat) && isNumber(bin.lng)){
                      <map-marker #marker="mapMarker"
                        (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
                        [position]="{lat: bin.lat, lng: bin.lng}"
                        [visible]="bin.visible"
                        [options]="{ icon: { url: bin.icon }, draggable : bin.draggable }"
                        [label]="bin.label"
                        [title]="bin.title">
                      </map-marker>
                      <map-info-window #window="mapInfoWindow">
                        <div id="iw-container">
                          <div id="binInfoWindow_multiSites"></div>
                        </div>
                      </map-info-window>
                    }
                  }
                </map-marker-clusterer>
              }
              @if (!moreThanMax) {
                @for (bin of markersArray; track bin; let i = $index) {
                  @if(isNumber(bin.lat) && isNumber(bin.lng)){
                    <map-marker #marker="mapMarker"
                      (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
                      [position]="{lat: bin.lat, lng: bin.lng}"
                      [visible]="bin.visible"
                      [options]="{ icon: { url: bin.icon }, draggable : bin.draggable }"
                      [label]="bin.label"
                      [title]="bin.title">
                    </map-marker>
                    <map-info-window #window="mapInfoWindow">
                      <div id="iw-container">
                        <div id="binInfoWindow_singleSite"></div>
                      </div>
                    </map-info-window>
                  }
                }
              }
              <map-polyline [options]="polylineOptions" [path]="polylinePath"></map-polyline>
            </google-map>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
}

@if (isMobile) {
  <div>
    <div class="md-accordion workplan-map mobile-truck-map-container" id="activeMapAc" role="tablist" aria-multiselectable="true">
      <div class="card mobile-map-location">
        <div class="card-header mobile-header">
          <div class="mobile-flex">
            <span class="mobile-title"> {{ 'WIDGETS.MAP_VIEW' | translate }}</span>
          </div>
        </div>
        <div class="collapse show" role="tabpanel" data-parent="#activeMapAc">
          <div class="card-body"  id="truck-map-container">
            <div class='mobile-search-input-container' rtlDiv>
              <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
              <input class='search-input' type="text" (keyup)="applyMainFilter($event.target['value'])" placeholder="{{'OTHER.SEARCH' | translate}}"
                id="mobile-truck-map" rtlDiv>
            </div>
            <div class='mobile-drop-down-main-wrapperr show-all-bins' rtlDiv>
              <div class="menu-Cmp dropdown">
                <div class="dropdown-toggle" data-bs-toggle="dropdown">
                  <img [ngClass]="{'hide' : exceptions == null || exceptions.length == 0}" class="mobile-exc-details" src="assets/images/workplans-page/exceptions icon.svg" alt="" rtlDiv />
                </div>
                <div class="dropdown-menu list-items" rtlDiv>
                  @for (item of exceptionsTypes; track item) {
                    <div class="menuItem">
                      <div class="dflex">
                        <span class="item-txt">{{ currentLang != 'iw' ? item['exception_id_name'] : item['exception_id_name_hebrew'] }}</span>
                        <img rtlDiv class="selectedItem" alt="" [src]="item['icon']">
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div class="drop-down-list-container">
                @for (item of items; track item) {
                  <div class="drop-down-list-item" [ngClass]="{'selected-item' : !item.selected}" [ngStyle]="{background: item.color}" (click)="selectUnselectItem(item)" rtlDiv>
                    <mat-checkbox [checked]="item.selected" (click)="selectUnselectItem(item);$event.stopPropagation();false"></mat-checkbox>
                    <span class="colorTxt">{{item.displayName | translate}}</span>
                  </div>
                }
              </div>
            </div>
            <div class="mobile-map" rtlDiv>
              <google-map id="map"
                (mapClick)="mapClick()"
                [options]="mobileMapOptions"
                (mapInitialized)="onMapReady($event)">
                @if (moreThanMax) {
                  <map-marker-clusterer [ignoreHidden]="true" [minimumClusterSize]= "5" [styles]="mapOptions.styles" [calculator]="mapOptions.calculator">
                    @for (bin of markersArray; track bin; let i = $index) {
                      @if(isNumber(bin.lat) && isNumber(bin.lng)){
                        <map-marker #marker="mapMarker"
                        (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
                        [position]="{lat: bin.lat, lng: bin.lng}"
                        [visible]="bin.visible"
                        [options]="{ icon: { url: bin.icon }, draggable : bin.draggable }"
                        [label]="bin.label"
                        [title]="bin.title">
                      </map-marker>
                      <map-info-window #window="mapInfoWindow">
                        <div id="iw-container">
                          <div id="binInfoWindow_multiSites"></div>
                        </div>
                      </map-info-window>
                      }
                    }
                  </map-marker-clusterer>
                }
                @if (!moreThanMax) {
                  @for (bin of markersArray; track bin; let i = $index) {
                    @if(isNumber(bin.lat) && isNumber(bin.lng)){
                      <map-marker #marker="mapMarker"
                      (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
                      [position]="{lat: bin.lat, lng: bin.lng}"
                      [visible]="bin.visible"
                      [options]="{ icon: { url: bin.icon }, draggable : bin.draggable }"
                      [label]="bin.label"
                      [title]="bin.title">
                    </map-marker>
                    <map-info-window #window="mapInfoWindow">
                      <div id="iw-container">
                        <div id="binInfoWindow_singleSite"></div>
                      </div>
                    </map-info-window>
                    }
                  }
                }
                <map-polyline [options]="polylineOptions" [path]="polylinePath"></map-polyline>
              </google-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
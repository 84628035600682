@if(!mobileFlag){
  <div id="super-container" class="databin-app-container" rtlDiv>
    @if(logged_In){
      <app-sidebar></app-sidebar>
      <div class="main-content" rtlDiv>
        <app-header></app-header>
        <router-outlet></router-outlet>
      </div>
    }@else{
      <div>
        <app-login></app-login>
      </div>
    }
  </div>
}

@if(mobileFlag){
  <div rtlDiv>
    @if(logged_In){
      <div>
        <app-header></app-header>
        <router-outlet></router-outlet>
      </div>
    }@else{
      <div>
        <app-login></app-login>
      </div>
    }
  </div>
}



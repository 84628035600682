import { Component, OnInit } from '@angular/core';
import { TranslatorService } from '../../services/translator_service';
import * as _ from "lodash";
import {ResponsiveService} from "../../services/responsive.service";
import { ApiService } from '../../web-services/api/api.service';
import { ApiStore } from '../../web-services/api/api.store';
import moment from 'moment';
import { ApiQuery } from '../../web-services/api/api.query';
import { SitesMenuComponent } from './sites-menu/sites-menu.component';
import { LanguagesMenuComponent } from './languages-menu/languages-menu.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [SitesMenuComponent,LanguagesMenuComponent,SidebarComponent,MatToolbarModule,RTLDivDirectiveDirective,CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  currnetLang:any;
	translationsObj:any;
	isMobile: boolean = false;
	translate;
	usageType = 1;
	is_view_unexecuted_work_plans:any = 0;
	isAdmin: boolean = false;
	user_role_id = 0;

	constructor(
		private translator: TranslatorService,private responsiveService: ResponsiveService,
		private apiService:ApiService,private apiStore:ApiStore,private apiQuery:ApiQuery) {
			this.translate = this.translator;
			this.translator.currentLangEmitter$
			.subscribe(async value=>{						
				this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
				this.currnetLang = value;
			})
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.apiQuery.user$.subscribe((user : any) => {
			if (!user) return;

			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
			this.user_role_id = user["user_role_id"];
			if ((user["user_role_id"] == 2) || (user["user_role_id"] == 1) || (user["user_role_id"] == 5) || (user["user_role_id"] == 6)) {
				this.isAdmin = true;
			}
			this.is_view_unexecuted_work_plans = user["is_view_unexecuted_work_plans"];			
		});
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

	refreshWebSite(){
		let threeMonthsAgo = {
			site_id : 0,
			start_date : moment().subtract(3, 'months').format('YYYY-MM-DD 00:00:01'),
			end_date : moment().format('YYYY-MM-DD 23:23:59')
		  }
		let fillingRequest = {
			start_date : sessionStorage.getItem("fillingsMinDate"),
			end_date : sessionStorage.getItem("fillingsMaxDate")
		}
		let ticketManagementRequest = {
			start_time : sessionStorage.getItem("ticketManagementMinDate"),
			end_time : sessionStorage.getItem("ticketManagementMaxDate")
		}
		let request = {
			start_date : sessionStorage.getItem("collectionsMinDate"),
			end_date : sessionStorage.getItem("collectionsMaxDate")
		}
		let userGeneratedWorkplans = {
			site_id:0
		}	
		let dailyWorkplan = {
			start_date : moment().subtract(2, 'months').format('YYYY-MM-DD 00:00:01'),
			end_date : moment().format('YYYY-MM-DD 23:59:59')
		}
		let requestVolume = {
			site_id : 0,
			start_date : sessionStorage.getItem("collectionsMinDate"),
			end_date : sessionStorage.getItem("collectionsMaxDate")
		}

		let requestFillingsVolume = {
			site_id : 0,
			start_date : sessionStorage.getItem("fillingsMinDate"),
			end_date : sessionStorage.getItem("fillingsMaxDate")
		}

		let requestReports = {
			site_id : 0,
			start_time : sessionStorage.getItem("reportsMinDate"),
			end_time : sessionStorage.getItem("reportsMaxDate")
		}

		let collectionsStatsRequest = {
			site_id : 0,
			start_date : moment().startOf('month').format('YYYY-MM-DD 00:00:01'),
			end_date : moment().format('YYYY-MM-DD 23:59:59')
		}

		let fillingsStatsRequest = {
			site_id : 0,
			start_date : moment().subtract(1, 'months').format('YYYY-MM-DD 00:00:01'),
			end_date : moment().format('YYYY-MM-DD 23:59:59')
		}

		let ticketManagementRequestByDate = {
			status_id : -1
		}

		let collectionsStatsTodayRequest = {
			site_id : 0,
			start_date : moment().format('YYYY-MM-DD 00:00:01'),
			end_date : moment().format('YYYY-MM-DD 23:59:59')
		}

		let reportsRequest = {
			site_id : 0,
			start_time : moment().subtract(1, 'months').format('YYYY-MM-DD 00:00:01'),
			end_time : moment().format('YYYY-MM-DD 23:59:59')
		}
		
		let alertsByDate = {
			site_id : 0,
			start_time : moment().format('YYYY-MM-DD 00:00:01'),
			end_time : moment().format('YYYY-MM-DD 23:59:59')
		}

		let exceptionReport = {
			start_time : sessionStorage.getItem("reportsMinDate"),
			end_time : sessionStorage.getItem("reportsMaxDate")
		}

		if(this.usageType == 2){
			if(sessionStorage.getItem("fillingsMinDate")!=null && sessionStorage.getItem("fillingsMaxDate")!=null){
				this.apiStore.update({ allRefillsKpis: [] });
				this.apiStore.update({ refillsKpis: [] });
				this.apiService.getfilteredSiteRefillsKpis(fillingRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
				this.apiStore.update({ allRefillsStats: [] });
				this.apiStore.update({ refillsStats: [] });
				this.apiService.getfilteredBinsRefillsStats(requestFillingsVolume,JSON.parse(sessionStorage.getItem("chosenSites")));
			}else{
				this.apiStore.update({ allRefillsKpis: [] });
				this.apiStore.update({ refillsKpis: [] });
				this.apiService.getfilteredSiteRefillsKpis(fillingsStatsRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
				this.apiStore.update({ allRefillsStats: [] });
				this.apiStore.update({ refillsStats: [] });
				this.apiService.getfilteredBinsRefillsStats(fillingsStatsRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
			}
		}

		if(this.usageType == 1 && this.isAdmin){
			if(sessionStorage.getItem("ticketManagementMinDate")!=null && sessionStorage.getItem("ticketManagementMaxDate")!=null && 
			sessionStorage.getItem("ticketManagementMinDate")!='' && sessionStorage.getItem("ticketManagementMaxDate")!=''){
				this.apiStore.update({ allCRTicketsList: [] });
				this.apiStore.update({ cRTicketsList: [] });
				this.apiService.getCRTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
		
				this.apiStore.update({ allInsTicketsList: [] });
				this.apiStore.update({ insTicketsList: [] });
				this.apiService.getInsTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
		
				this.apiStore.update({ allRmTicketsList: [] });
				this.apiStore.update({ rmTicketsList: [] });
				this.apiService.getRmTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
		
			}else{
				this.apiStore.update({ allCRTicketsList: [] });
				this.apiStore.update({ cRTicketsList: [] });
				this.apiService.getCRTicketsList(ticketManagementRequestByDate,JSON.parse(sessionStorage.getItem("chosenSites")));
		
				this.apiStore.update({ allInsTicketsList: [] });
				this.apiStore.update({ insTicketsList: [] });
				this.apiService.getInsTicketsList(ticketManagementRequestByDate,JSON.parse(sessionStorage.getItem("chosenSites")));
		
				this.apiStore.update({ allRmTicketsList: [] });
				this.apiStore.update({ rmTicketsList: [] });
				this.apiService.getRmTicketsList(ticketManagementRequestByDate,JSON.parse(sessionStorage.getItem("chosenSites")));
			}			
		}
		this.apiStore.update({ allCollectionsStatsForWorkplanProgres: [] });                
        this.apiStore.update({ CollectionsStatsForWorkplanProgres: [] }); 
		this.apiService.getfilteredBinsCollectionsStats(collectionsStatsTodayRequest,3,JSON.parse(sessionStorage.getItem("chosenSites")));
		this.apiService.getfilteredBinsCollectionsStats(threeMonthsAgo,0,JSON.parse(sessionStorage.getItem("chosenSites")));
		if(sessionStorage.getItem("collectionsMinDate")!=null && sessionStorage.getItem("collectionsMaxDate")!=null){
			this.apiStore.update({ allCollectionsKpis: [] });
			this.apiStore.update({ collectionsKpis: [] });
			this.apiService.getfilteredSiteCollectionsKpis(request,JSON.parse(sessionStorage.getItem("chosenSites")));
			this.apiStore.update({ allCollectionsStats: [] });
			this.apiStore.update({ collectionsStats: [] });
			this.apiStore.update({ collectionsStatsInCollections: [] });
			this.apiService.getfilteredBinsCollectionsStats(requestVolume,5,JSON.parse(sessionStorage.getItem("chosenSites")));
		}else{
			this.apiStore.update({ allCollectionsKpis: [] });
			this.apiStore.update({ collectionsKpis: [] });
			this.apiService.getfilteredSiteCollectionsKpis(collectionsStatsRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
			this.apiStore.update({ allCollectionsStats: [] });
			this.apiStore.update({ collectionsStats: [] });
			this.apiStore.update({ collectionsStatsInCollections: [] });
			this.apiService.getfilteredBinsCollectionsStats(collectionsStatsRequest,1,JSON.parse(sessionStorage.getItem("chosenSites")));
			this.apiStore.update({ allCollectionsStatsToday: [] });
			this.apiStore.update({ collectionsStatsToday: [] });
			this.apiService.getfilteredBinsCollectionsStatsToday(collectionsStatsTodayRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
		}
		if(sessionStorage.getItem("reportsMinDate")!=null && sessionStorage.getItem("reportsMinDate")!=null){
			this.apiStore.update({ exceptionsReports: [] });
			this.apiService.getfilteredExceptionsReports(requestReports,JSON.parse(sessionStorage.getItem("chosenSites")));
			this.apiService.getfilteredUserEventsByType(requestReports,JSON.parse(sessionStorage.getItem("chosenSites")));
			this.apiService.getfilteredDriverExceptionsReports(exceptionReport.start_time,exceptionReport.end_time,JSON.parse(sessionStorage.getItem("chosenSites")));
		}else{
			this.apiStore.update({ exceptionsReports: [] });
			this.apiService.getfilteredExceptionsReports(reportsRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
			this.apiService.getfilteredUserEventsByType(alertsByDate,JSON.parse(sessionStorage.getItem("chosenSites")));
		}
		this.apiStore.update({ allSitesListData: [] });   
		this.apiStore.update({ sitesListData: [] });
		this.apiService.getSitesList(JSON.parse(sessionStorage.getItem("chosenSites")));
		this.apiService.getTicketsList(JSON.parse(sessionStorage.getItem("chosenSites")));
		this.apiService.getInstallationAccessoryTypes();
		this.apiService.getWorkPlanDistrebutionMethods();
		this.apiService.getUnitIssuesType();
		this.apiService.getCustomerWorkPlanCollectionschedulePolicy();
		this.apiStore.update({ allSitesClustersList: [] });
		this.apiStore.update({ sitesClustersList: [] });
		this.apiService.getfilteredSitesClustersList(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));
		this.apiStore.update({ allNeighborhoodsList: [] });
		this.apiStore.update({ neighborhoodsList: [] });
		this.apiService.getNeighborhoodsList(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));
		this.apiStore.update({ filteredTrucks: [] });	
		this.apiService.getTrucksTabletsInfo(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));	
		this.apiStore.update({ filteredUsers: [] });	
		this.apiService.getfilteredUsersInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
		this.apiStore.update({ allFilteredBins: [] });                    
		this.apiStore.update({ filteredBins: [] });	
		this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
		this.apiStore.update({ allFilteredBasicBins: [] });                    
		this.apiStore.update({ filteredBasicBins: [] }); 
		this.apiService.getfilteredBasicBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
		this.apiStore.update({ allFilteredDailyWorkPlan: [] });
		this.apiStore.update({ filteredDailyWorkPlan: [] });
		this.apiService.getfilteredActivePlans(JSON.parse(sessionStorage.getItem("chosenSites")));
		this.apiStore.update({ allWorkPlanScheduler: [] });
		this.apiStore.update({ workPlanScheduler: [] });
		this.apiService.getfilteredWorkPlanScheduler(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));		
		this.apiStore.update({ allDailyWorkPlanCalanderData: [] });
		this.apiStore.update({ dailyWorkPlanCalanderData: [] });
		this.apiService.getDailyWorkPlanCalanderData(dailyWorkplan.start_date,dailyWorkplan.end_date,this.is_view_unexecuted_work_plans,JSON.parse(sessionStorage.getItem("chosenSites")));
		this.apiService.getRemovedBinFromExistingWorkPlanReasonType();
		this.apiService.getTicketEventTypes();
		this.apiService.getTicketEventCause();
		this.apiService.getTicketAnalysisTypes();
		this.apiService.getTicketRemoveOrReplaceTypes();	
		this.apiService.getNavigationOptimizationTypes();
		this.apiService.getClusterTypes();
		this.apiService.getUserRoles();
		this.apiService.getUsageTypes();
		this.apiService.getCapacityDisplayTypes();
		this.apiService.getUserAccounts();
	}
}

@if (!isMobile) {
  <div class="bin-status">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="title">{{datesDiffTxt}}</mat-card-title>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <div class='spinner-container' [hidden]="!spinnerActive">
        <div class="spinner"></div>
      </div>
      <mat-card-content [hidden]="spinnerActive">
        <div class="content">
          <div class='progress-bar-left'>
            <img src="assets/images/fillings/overall_amount.svg" alt="" />
            <div class="data">
              <div class='text-number' rtlDiv>
                {{(amountOfRefill/1000).toFixed(2)}} {{ 'FILLINGS.CUBIC_METER' | translate }}
              </div>
              <div class='text-title' rtlDiv>{{ 'FILLINGS.OVERALL_AMOUNT_REFILL' | translate }}</div>
            </div>
          </div>
          <div class="middle-divider"></div>
          <div class='progress-bar-left'>
            <img src="assets/images/fillings/filled_bins.svg" alt="" />
            <div class="data">
              <div class='text-number' rtlDiv>
                {{totalBins}} / {{filledBins}}
              </div>
              @if (usageType == 1) {
                <div class='text-title' rtlDiv>{{ 'FILLINGS.FILLED_BINS' | translate }}</div>
              }
              @if (usageType != 1) {
                <div class='text-title' rtlDiv>{{ 'FILLINGS.FILLED_TANKS' | translate }}</div>
              }
            </div>
          </div>
          <div class="middle-divider"></div>
          <div class='progress-bar-left'>
            <img src="assets/images/fillings/avg_refill.svg" alt="" />
            <div class="data">
              <div class='text-number' rtlDiv>
                {{(avg.toFixed()/1000).toFixed(2)}} {{ 'FILLINGS.CUBIC_METER' | translate }}
              </div>
              <div class='text-title' rtlDiv>{{ 'FILLINGS.AVG_REFILL' | translate }}</div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
  </div>
}
import { Component, Input } from '@angular/core';
import { Chart, ChartModule } from 'angular-highcharts';
import _ from 'lodash';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';


@Component({
  selector: 'app-driver-report-distribution',
  standalone: true,
  imports: [TranslateModule, MatCardModule, RTLDivDirectiveDirective, MatDividerModule, ChartModule],
  templateUrl: './driver-report-distribution.component.html',
  styleUrl: './driver-report-distribution.component.scss'
})
export class DriverReportDistributionComponent {
  translate;
	translationsObj;
	currentLang;
  @Input() datesDiff;
  spinnerActive:boolean = true;
  datesDiffTxt:string;
	donutChart: any;
  allExceptions:any[] = [];
  total:any;

  constructor(private translator: TranslatorService,private apiQuery:ApiQuery) {
    this.translate = this.translator;
  }

  ngOnInit() {
    this.spinnerActive = true;	
    this.apiQuery.driverExceptionsReports$.subscribe((data:any) => {
		if(this.currentLang != 'iw'){
			this.datesDiffTxt = `Drivers Report Distribution`;
		}else{
			this.datesDiffTxt = `הפצת דיווחי נהגים`;
		}  
      this.datesDiffTxt += " " + this.datesDiff
      this.spinnerActive = true;
      if(data == null || data.length == 0){return;}
      this.spinnerActive = false;

      this.allExceptions = [];

      _.each(data, (item) => {
        if(item.exception_id == 9 || item.exception_id == 11 || item.exception_id == 12){
          this.allExceptions.push(item);
        }
      });      
    let allExceptionsGrouped=[]; 	 
	  this.total = 0;
	  let bin_location_issue = 0;
	  let unusable_bin_issue = 0;
	  let bin_sticker_issue = 0;


    let res = _.each(_.groupBy(this.allExceptions, "exception_id"), (item) => {        
		let group = {
			exception_data : [],
			exception_name : '',
			exception_name_hebrew : '',
			exception_num_of_bins : 0,
			exception_id : null
		  };        
      _.each((item), (collection) => {          
          group["exception_data"].push(collection);             
          group["exception_name"] = collection["exception_id_name"].replace(/_/g, ' ');
          group["exception_name_hebrew"] = collection["exception_id_name_hebrew"];
          group["exception_num_of_bins"] += 1;
          group["exception_id"] = collection["exception_id"];	   
      });  
      if(group["exception_id"] != null){
        allExceptionsGrouped.push(group);
      }
    });
			   
    _.each((allExceptionsGrouped), (group) => {  
		  if(group.exception_id == 9){
        bin_location_issue = group.exception_data.length;			
		  }else if(group.exception_id == 11){
			  unusable_bin_issue = group.exception_data.length;
		  }else if(group.exception_id == 12){
			  bin_sticker_issue = group.exception_data.length;
		  }
      });  

      this.total = bin_location_issue + unusable_bin_issue + bin_sticker_issue;
	  
      this.allExceptions = allExceptionsGrouped;         	  
      setTimeout(function(){ this.initDonut(this.total,bin_location_issue,unusable_bin_issue,bin_sticker_issue); }.bind(this), 2500);
    })
  }

  initDonut(total,bin_location_issue,unusable_bin_issue,bin_sticker_issue) {
	this.translator.currentLangEmitter$
    .subscribe(async value=>{						
      this.translationsObj = await this.translator.getTranslation(value).toPromise();			 
      this.currentLang = value;	
      if(this.currentLang != 'iw'){
        this.datesDiffTxt = `Drivers Report Distribution`;
      }else{
        this.datesDiffTxt = `הפצת דיווחי נהגים`;
      }  
	  const donut = new Chart({
		chart: {
		marginLeft:50,
			marginRight:50,
			marginBottom:100,
			marginTop:0,
			plotBackgroundColor: null,
			plotBorderWidth: 0,
			plotShadow: false
		},
		legend: {
			align: 'left',
			layout: 'vertical',	
			itemMarginBottom:5,	
			itemStyle: {
				fontFamily:'Open Sans',
				fontSize:'14px',
				color:'#3A4743',
				fontWeight: 'normal'				
			},	
		},
		title:{
			text: '<strong>' + total +'<br>' + this.translationsObj.COLLECTION_REPORT.TOTAL_EVENTS + '</strong>',
			align: 'center',
	style:{
	  fontFamily:'Open Sans, sans-serif',
				fontSize:'14px',
	  fontWeight: '600'				
	},
			verticalAlign: 'middle',
			y: -30
		},
		tooltip: {	
			borderWidth:0,
			backgroundColor:null,
			useHTML: true,	
			formatter: function(){
				return `<div style='flex-direction: row;align-items: center;padding: 4px 8px;width: max-content;height: max-content;top: 85px;background: #F5F6FA;box-shadow: 0px 2px 8px rgba(93, 120, 112, 0.2);border-radius: 3px;display: flex;'><div><div style='position: static;width: max-content;height: max-content;font-family: Open Sans;font-style: normal;font-weight: normal;font-size: 14px;line-height: 19px;text-transform: capitalize;color: #3A4743;flex: none;order: 0;flex-grow: 0;margin-left: 8px;margin-right: 8px;'>${this.point.name}</div><div style='position: static;width: max-content;height: max-content;font-family: Open Sans;font-style: normal;font-weight: 600;font-size: 14px;line-height: 19px;text-transform: capitalize;color: #3A4743;flex: none;order: 1;flex-grow: 0;margin-left: 8px;margin-right: 8px;'>${this.point.y} (${parseInt(this.point.percentage.toString())}%)</div></div></div>`;										
			  }
		},
		credits: {
		  enabled: false
		},
		plotOptions: {
		  pie: {
			allowPointSelect: true,
			cursor: "pointer",
			dataLabels: {
			  enabled: false,
			  distance: -50,
			},
			center: ["50%", "50%"],
			size: "100%",
			showInLegend: true
		  }
		},
		series: [
		  {
			name: "",
			point: {
				events: {
					legendItemClick: function() {
						return false;
					}
				}
			},
			data: [
			  {
				name: this.currentLang != 'iw' ? "Bin Location Issue" : "בעיה במיקום המיכל",
				color: '#254831',
				y: bin_location_issue
			  },
			  {
				name: this.currentLang != 'iw' ? "Unusable Bin Issue" : "מיכל לא ניתן לשימוש",
				color: '#99D7B0',
				y: unusable_bin_issue
			  },
			  {
				name: this.currentLang != 'iw' ? "Bin Sticker Issue" : "מיכל עם בעיה במדבקה",
				color: '#D8D72E',
				y: bin_sticker_issue
			  }
			],
			type: "pie",
			innerSize: "50%"
		  }
		]
	  });
	  this.donutChart = donut;
    });	
  }

}

@if (!isMobile) {
  <div class="manage-modal">
    <div class="modal-title">
      <span class="title-txt">{{ 'TITLES.MANAGE_PANEL' | translate }}</span>
      <button [ngClass]="{'clear-btn' : !disableClearBtn, 'clearBtn-disable' : disableClearBtn}" (click)="clearSelection()">{{ 'LIVE_INFO.CLEAR_ALL' | translate }}</button>
    </div>
    <div [ngClass]="{'modal-data' : !preventManage, 'modal-data-with-permission' : preventManage}">
      <div rtlDiv class="exception-wrapper padding-section devider" [ngClass]="{'disableDiv': isDisabled}">
        <div class="info-clusters" rtlDiv>
          <img src="assets/images/dashboard/nearby.svg" alt="" />
        </div>
        <div class="exception-title" rtlDiv>{{ 'TITLES.NEARBY_BIN' | translate }}</div>
        @if (showClusters) {
          <img class="exception-button" (click)="hideClustersbtn()" src="assets/images/workplans-page/exception toggle on.svg" alt="" />
        }
        @if (!showClusters) {
          <img class="exception-button" (click)="showClustersbtn()" src="assets/images/workplans-page/exeptions toggle off.svg" alt="" />
        }
      </div>
      <div class="devider">
        <mat-accordion>
          <mat-expansion-panel (opened)="neighborhoodsPanelOpenState = true" (closed)="neighborhoodsPanelOpenState = false" class="box-shadow">
            <mat-expansion-panel-header class="padding-unset acc-hover">
              <mat-panel-title>
                <div class="acc-title">
                  <div class="info-clusters" rtlDiv>
                    <img src="assets/images/dashboard/neighborhoods.svg" alt="" />
                  </div>
                  <span class="title-section">
                    <span>{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</span>
                    <span>({{neighborhoodsSelection['selectedOptions'].selected.length}})</span>
                  </span>
                </div>
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="filter-frame">
              <input class='search-input' type="text" [(ngModel)]="neighborhoodFilter" (input)="applyFilterNeighborhoods($event.target['value'])" placeholder="{{
                'OTHER.SEARCH' | translate }}" id="neighborhoods_search">
                <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
              </div>
              <mat-selection-list class="selection-list" #neighborhoodsSelection (selectionChange)="changeNeighborhood($event)">
                @for (neighborhood of neighborhoods; track neighborhood) {
                  <mat-list-option [value]="neighborhood">
                    <div class="neighborhood-option">
                      <span>{{neighborhood['nbrh_name']}}</span>
                      <span>({{neighborhood['num_of_bins_in_nbrh']}})</span>
                    </div>
                  </mat-list-option>
                }
              </mat-selection-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="devider">
          <mat-accordion>
            <mat-expansion-panel (opened)="fullCollectionPanelOpenState = true" (closed)="fullCollectionPanelOpenState = false" class="box-shadow">
              <mat-expansion-panel-header class="padding-unset acc-hover">
                <mat-panel-title>
                  <div class="acc-title">
                    <div class="info-clusters" rtlDiv>
                      <img src="assets/images/dashboard/fullCollection.svg" alt="" />
                    </div>
                    <span class="title-section">
                      <span>{{ 'LIST_COLUMNS.FULL_COLLECTION' | translate }}</span>
                      <span>({{fullCollectionSelection.selectedOptions.selected.length}})</span>
                    </span>
                  </div>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="filter-frame">
              <input class='search-input' [(ngModel)]="fullCollectionFilter" type="text" (input)="applyFilterFullCollection($event.target['value'])" placeholder="{{
                'OTHER.SEARCH' | translate }}" id="collections_search">
                  <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                </div>
                <mat-selection-list class="selection-list" #fullCollectionSelection (selectionChange)="changeFullCluster($event)">
                  @for (cluster of fullClusterCollection; track cluster) {
                    <mat-list-option [value]="cluster">
                      <div class="cluster-opt">
                        <span class="cut-txt" title="{{cluster['cluster_name']}}">{{cluster['cluster_name']}}</span>
                        @if (isAdmin) {
                          <button class="manage-cluster" (click)="manageCluster(cluster)">{{ 'TITLES.MANAGE' | translate }} ({{cluster['num_of_bins_in_cluster']}})</button>
                        }
                      </div>
                    </mat-list-option>
                  }
                </mat-selection-list>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div>
            <mat-accordion>
              <mat-expansion-panel (opened)="semiCollectionPanelOpenState = true" (closed)="semiCollectionPanelOpenState = false" class="box-shadow">
                <mat-expansion-panel-header class="padding-unset acc-hover">
                  <mat-panel-title>
                    <div class="acc-title">
                      <div class="info-clusters" rtlDiv>
                        <img src="assets/images/dashboard/semiCollection.svg" alt="" />
                      </div>
                      <span class="title-section">
                        <span>{{ 'LIST_COLUMNS.SEMI_COLLECTION' | translate }}</span>
                        <span>({{semiCollectionSelection.selectedOptions.selected.length}})</span>
                      </span>
                    </div>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="filter-frame">
              <input class='search-input' [(ngModel)]="semiCollectionFilter" type="text" (input)="applyFilterSemiCollection($event.target['value'])" placeholder="{{
                'OTHER.SEARCH' | translate }}" id="semiCollection_search">
                    <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                  </div>
                  <mat-selection-list class="selection-list" #semiCollectionSelection (selectionChange)="changeSemiCluster($event)">
                    @for (cluster of semiClusterCollection; track cluster) {
                      <mat-list-option [value]="cluster">
                        <div class="cluster-opt">
                          <span class="cut-txt" title="{{cluster['cluster_name']}}">{{cluster['cluster_name']}}</span>
                          @if (isAdmin) {
                            <button class="manage-cluster" (click)="manageCluster(cluster)">{{ 'TITLES.MANAGE' | translate }} ({{cluster['num_of_bins_in_cluster']}})</button>
                          }
                        </div>
                      </mat-list-option>
                    }
                  </mat-selection-list>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          @if (!preventManage) {
            <div class="modal-bottom">
              <div class="manage-container">
                <button [ngClass]="{'manage-btn' : !disableSubmitBtn, 'manageBtn-disable' : disableSubmitBtn}" (click)="manageBins()">{{ 'MANAGE_BIN.MANAGE_BINS' | translate }}</button>
              </div>
            </div>
          }
        </div>
      }

      @if (isMobile) {
        <div>
        </div>
      }
@if (!isMobile) {
  <div>
    <div class="date-page" rtlDiv>
      <div class='header-bold' rtlDiv>
        {{ 'TITLES.WORKPLAN' | translate }} @if (dateSelected) {
        <span>&nbsp;|&nbsp;</span>
        } {{dateSelected}}
      </div>
    </div>
    <mat-divider class="top-divider"></mat-divider>
  </div>
}


@if (isMobile) {
  <div>
    <div class="date-page" rtlDiv>
      <div class='header-bold' rtlDiv>
        {{ 'TITLES.WORKPLAN' | translate }} | {{dateSelected}}
      </div>
    </div>
    <mat-divider class="top-divider"></mat-divider>
  </div>
}
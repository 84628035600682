@if (!isInstallationCompleted) {
  <div>
    @if (!isSwap) {
      <div>
        <h5 mat-dialog-title class="text-center">{{ 'SITE_MANAGMENT.REV_BIN_DETAILS' | translate }}</h5>
      </div>
    } @else {
      <h5 mat-dialog-title class="text-center">{{ 'SITE_MANAGMENT.BEFORE_SWAP' | translate }}</h5>
    }
  </div>
} @else {
  @if (isSwap && !isFastInstallation) {
    <div>
      <h5 mat-dialog-title class="text-center" style="color:green"> {{ 'SITE_MANAGMENT.BIN_ID' | translate }} {{ binId }} {{ 'SITE_MANAGMENT.HAS_BEEN_SWAPED' | translate }} </h5>
    </div>
  } @else {
    <h5 mat-dialog-title class="text-center" style="color:green"> {{ 'SITE_MANAGMENT.BIN_ID' | translate }} {{ binId }} {{ 'SITE_MANAGMENT.HAS_BEEN_CREATED' | translate }} </h5>
  }
}

<mat-dialog-content>
  <table align="center" rtlDiv mat-table [dataSource]="dataSource" class="mat-elevation-z8" width="100%">

    <!--- Note that these columns can be defined in any order.
    The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="item">
      <th mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.ITEM' | translate }}</th>
      <td class="space-r" mat-cell *matCellDef="let element"> <b>{{element.item}}</b></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>{{ 'SITE_MANAGMENT.VALUE' | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.value}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-dialog-content>


@if (!isInstallationCompleted) {
  <div>
    @if (!isSwap) {
      <div>
        <mat-dialog-actions align="end">
          <button mat-button color="warn" mat-dialog-close>{{ 'SITE_MANAGMENT.CANCEL' | translate }}</button>
          <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{ 'SITE_MANAGMENT.CREATE' | translate }}</button>
        </mat-dialog-actions>
      </div>
    } @else {
      <mat-dialog-actions>
        <button mat-button [ngClass]="{'right' : currentLang == 'iw','left': currentLang != 'iw'}" color="warn" mat-dialog-close>{{ 'SITE_MANAGMENT.CANCEL' | translate }}</button>
        <button mat-button [ngClass]="{'left' : currentLang == 'iw','right': currentLang != 'iw'}" [mat-dialog-close]="true" cdkFocusInitial>{{ 'SITE_MANAGMENT.SWAP' | translate }}</button>
      </mat-dialog-actions>
    }
  </div>
} @else {
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ 'SITE_MANAGMENT.DONE' | translate }}</button>
  </mat-dialog-actions>
}

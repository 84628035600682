import { Component } from '@angular/core';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslatorService } from '../../../services/translator_service';
import { ResponsiveService } from '../../../services/responsive.service';
import _ from 'lodash';
import { MatCardModule } from '@angular/material/card';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-black-status-duration',
  standalone: true,
  imports: [MatCardModule, RTLDivDirectiveDirective,TranslateModule,MatDividerModule,CommonModule],
  templateUrl: './black-status-duration.component.html',
  styleUrl: './black-status-duration.component.scss'
})
export class BlackStatusDurationComponent {
  isMobile: Boolean;
	currentLang;
	translationsObj;
	spinnerActive:boolean = true;
  event_num_of_day:number = 0;
  site_total_num_of_over_flow_dates:number = 0;
  duration:number = 0;

  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,
    private apiQuery:ApiQuery) { 
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
  }

  ngOnInit() {
    this.onResize();
	  this.responsiveService.checkWidth();

    this.apiQuery.binCapacityAnalysis$.subscribe((data:any) => {
      this.event_num_of_day = 0;
      this.site_total_num_of_over_flow_dates = 0;
      this.duration = 0;
      this.spinnerActive = true;		
      if(data.length == 0) return;
      this.spinnerActive = false;	
      _.each(data, (item:any) => {
        this.site_total_num_of_over_flow_dates += item.site_total_num_of_over_flow_dates;
        _.each(item.binsWorkPlanCapacityAnalysis, (binWorkPlanCapacityAnalysis) => {
          _.each(binWorkPlanCapacityAnalysis.over_flow_dates, (overFlowDates) => {
            this.event_num_of_day += overFlowDates.event_num_of_day;
          });
        });
      });
      this.duration = this.event_num_of_day / this.site_total_num_of_over_flow_dates;
    });
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

}

import { Component, Inject, ViewChild } from '@angular/core';
import { Observable, map, startWith, tap } from 'rxjs';
import _ from 'lodash';
import moment from 'moment';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TranslatorService } from '../../../services/translator_service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilService } from '../../../services/util.service';
import { Router } from '@angular/router';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ResponsiveService } from '../../../services/responsive.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiStore } from '../../../web-services/api/api.store';
import { GoogleMapsModalComponent } from '../../site-managment/google-maps-modal/google-maps-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

interface Coordinates {
  address: string;
  latitude: number;
  longitude: number;
}
@Component({
  selector: 'app-resolve-ticket-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,MatAutocompleteModule,MatInputModule,MatDatepickerModule,MatRadioModule,MatIconModule
    ,MatNativeDateModule,MatSelectModule ],
  templateUrl: './resolve-ticket-modal.component.html',
  styleUrl: './resolve-ticket-modal.component.scss'
})
export class ResolveTicketModalComponent {
	currentLang;
	translationsObj;
  isMobile: Boolean;
	usageType = 1;
  ElForm: FormGroup;
  removeOrReplaceTypes:Array<object> = [];
  analysisTypes:Array<object> = [];
  ticketEventsCause:Array<object> = [];
  priority;
	sitesListFilter: any[] = [];
  binsListFilter: any[] = [];
  filteredItems: Observable<any[]>;
  filteredBins: Observable<any[]>;
  chosenSite;
  chosenBin;
  chosenNeighborhood;
  @ViewChild(MatAutocompleteTrigger,{static: false}) autoSite: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger,{static: false}) autoBin: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger,{static: false}) autobinsNeighborhood: MatAutocompleteTrigger;
  coordinates: Coordinates;
  binLocation: string
  lat:any;
  lon:any;
  neighborhoods : string[] = [];
  filteredNeighborhoods: Observable<any[]>;
  file_store: File[];
  minDate;
  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,private fb: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: any,
  private apiQuery:ApiQuery,private dialogRef: MatDialogRef<ResolveTicketModalComponent>,private modalService: NgbModal,private apiService:ApiService,private apiStore:ApiStore,
  private router: Router,public utilService: UtilService,private _adapter: DateAdapter<any>) {
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		});

    this.coordinates = {} as Coordinates;

    this.ElForm = this.fb.group({
      description: new FormControl(''),
      eventCause: new FormControl('', Validators.required),
      eventCheckOnField: this.data.chosenTicket.ticket_event_type_id == 1 ? new FormControl('', Validators.required) : new FormControl(''),
      removeOrReplaceType: new FormControl(''),
      site:new FormControl('', Validators.required),
      binsNeighborhood:new FormControl(''),
      binAddress:new FormControl('', Validators.required),
      unitReplaced: this.data.chosenTicket.ticket_event_type_id == 1 ? new FormControl('', Validators.required) : new FormControl(''),
      unitInstalled: this.data.chosenTicket.ticket_event_type_id == 3 ? new FormControl('', Validators.required) : new FormControl(''),
      removedFromBin: this.data.chosenTicket.ticket_event_type_id == 4 ? new FormControl('', Validators.required) : new FormControl(''),
      removedFromSite: this.data.chosenTicket.ticket_event_type_id == 4 ? new FormControl('', Validators.required) : new FormControl(''),
      binName: new FormControl({value: '', disabled: true}),
      resolvedDescription: new FormControl(''),
      installedBinName: new FormControl(''),
      binImage: new FormControl(''),
      arrival_date:new FormControl({value: '', disabled: true}),
      arrival_date_displayed:new FormControl({value: '', disabled: true})
    });
   }

  ngOnInit() {
    this.onResize();
	  this.responsiveService.checkWidth();
    this.minDate = moment().utc()["_d"];
    this.ElForm.controls.eventCause.disable();
    this.ElForm.controls.site.disable();
    this.ElForm.controls.binAddress.disable();
    this.ElForm.controls.binName.disable();
    this.ElForm.controls.description.disable();
    this.ElForm.controls.binsNeighborhood.disable();
    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}		
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
		});   
    
    if(this.data.chosenTicket.ticket_event_type_id == 1){
      this.lat = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][0].bin_lat;
      this.lon = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][0].bin_lon;
    }else if(this.data.chosenTicket.ticket_event_type_id == 3){
      this.lat = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][0].installed_lat;
      this.lon = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][0].installed_lon;
      this.ElForm.controls.installedBinName.setValue(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"] ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"].length - 1].installed_bin_name : '');
    }else if(this.data.chosenTicket.ticket_event_type_id == 4){
      this.lat = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][0].bin_lat;
      this.lon = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][0].bin_lon;
    }
    
    this.ElForm.controls.description.setValue(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"] ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"].length - 1].comment : '');
    this.choosePriority(this.data.chosenTicket.priority_id);    
    if(this.data.chosenTicket.expected_arrival_date == '0000-00-00 00:00:00'){
      if(this.data.chosenTicket.priority_id == 3){
        this.ElForm.controls.arrival_date.setValue(moment(moment().add(1,'weeks')).utc()["_d"]); 
      }else if(this.data.chosenTicket.priority_id == 1 || this.data.chosenTicket.priority_id == 2){
        this.ElForm.controls.arrival_date.setValue(moment(moment().add(2,'weeks')).utc()["_d"]);    
      }  
    }else{
      this.ElForm.controls.arrival_date.setValue(moment(moment(this.data.chosenTicket.expected_arrival_date)).utc()["_d"]); 
    }

    if(this._adapter["locale"] == 'he' || this._adapter["locale"] == 'iw'){
      this.ElForm.controls.arrival_date_displayed.setValue(moment(this.ElForm.getRawValue()["arrival_date"]).format('DD/MM/YYYY'));    
    }else{
      this.ElForm.controls.arrival_date_displayed.setValue(moment(this.ElForm.getRawValue()["arrival_date"]).format('MM/DD/YYYY'));    
    }  
    this.apiQuery.ticketEventsType$.subscribe((events) => {
      if(events.length == 0){return;}      
      this.changeType(Number(this.data.chosenTicket.ticket_event_type_id));          
      if(this.data.chosenTicket.ticket_event_type_id == 3){
        this.binLocation = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"].length - 1].installed_lat + "," + _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"].length - 1].installed_lon;
        this.ElForm.controls.binAddress.setValue(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"] ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][0].installed_bin_address : '');
      }  
    });

    this.apiQuery.sitesListData$.subscribe((sitesList) => {		            			
      if(sitesList.length == 0) return;
      this.sitesListFilter = sitesList;	 
      this.sitesListFilter.sort(function(a, b){
        if(a.site_name < b.site_name) { return -1; }
        if(a.site_name > b.site_name) { return 1; }
        return 0;
      });      
      this.ElForm.controls.site.setValue(this.data.chosenTicket.site_name);
      this.optionSelected(this.data.chosenTicket.site_name);            
      this.filteredItems = this.ElForm.controls.site.valueChanges
      .pipe(
      tap((val:any) => {
        if(val == ''){
          this.ElForm.controls.binName.disable();
          this.ElForm.controls.binsNeighborhood.disable();
        }        
      }),
      startWith(''),
      map(item => item ? this.filterItems(item) : this.sitesListFilter.slice())
      );  
    });

    this.apiQuery.analysisTypes$.subscribe((types) => {
      if(types.length == 0){return;}      
      this.analysisTypes = types;
    });

    this.apiQuery.removeOrReplaceTypes$.subscribe((types) => {
      if(types.length == 0){return;}      
      const response = types;       
      const relevantResponse = _.filter(response, (res) => {      
        return res["ticket_event_type_id"] == this.data.chosenTicket.ticket_event_type_id;
      });  
      this.removeOrReplaceTypes = relevantResponse.length > 0 ? relevantResponse[0]["TicketReplaceRemoveInfoObj"] : [];      
    });
    
  }
  
  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  handleFileInputChange(l): void {
    if (l.length) {
      const f = l[0];
      this.file_store = l;          
      this.ElForm.controls.binImage.patchValue(`${f.name}`);
    } else {
      this.ElForm.controls.binImage.patchValue('');
    }
  }

  changeType(type){    
    let eventType = type;
    this.apiQuery.ticketEventsCause$.subscribe((cause) => {
      if(cause.length == 0){return;}
      const response = cause;
      const relevantResponse = _.filter(response, (res) => {      
        return res["ticket_event_type_id"] == eventType;
      });          
      this.ticketEventsCause = relevantResponse.length > 0 ? relevantResponse[0]["TicketEventCausesObj"] : [];  
      this.ElForm.controls.eventCause.setValue(Number(this.data.chosenTicket.ticket_event_cause_id));                
    });
  }

  goToInstallation(){    
    this.closeModal();      
    this.router.navigate([`/newinstallationwizard/${this.chosenSite.site_id}/${this.data.chosenTicket.ticket_id}`]);
    this.apiStore.update({ selectedTicket: this.data.chosenTicket });
  }

  goToSwapUnit(){    
    this.closeModal();      
    this.router.navigate([`/swapUnit/${this.chosenSite.site_id}/${this.data.chosenTicket.ticket_id}`]);
    this.apiStore.update({ selectedTicket: this.data.chosenTicket });
  }

  unitReplacedChange(ev){
    if(ev.value == 1){
      this.ElForm.controls.removeOrReplaceType.setValidators(this.setRequired());
    }else{
      this.ElForm.controls.removeOrReplaceType.clearValidators();
      this.ElForm.controls.removeOrReplaceType.setValue('');
    }   
  }

  removedFromSiteChange(ev){
    if(ev.value == 1){
      this.ElForm.controls.removeOrReplaceType.setValidators(this.setRequired());
    }else{
      this.ElForm.controls.removeOrReplaceType.clearValidators();
      this.ElForm.controls.removeOrReplaceType.setValue('');
    }
  }

  getClassForFormControl(){
    if(this.ElForm.valid){
      return 'apply-btn';
    }else{
      return 'not-apply-btn';
    }
  }

  setRequired() {
		return [Validators.required];
	}

  openGoogelMapsModal() {
    const modalRef = this.modalService.open(GoogleMapsModalComponent,
      {
        scrollable: true,
        windowClass: 'modal-map'
      });
      modalRef.componentInstance.statusColor = "GREEN";

      modalRef.componentInstance.lon = this.data.chosenTicket ? this.binLocation.split(",")[1].trim() : 0.00000000;
      modalRef.componentInstance.lat = this.data.chosenTicket ? this.binLocation.split(",")[0].trim() : 0.00000000;

      if(this.usageType == 1){
        modalRef.componentInstance.usageType = 1;
        modalRef.componentInstance.titleStr = this.translationsObj.SITE_MANAGMENT.BIN_LOCATION;
      }else{
        modalRef.componentInstance.usageType = 2;
        modalRef.componentInstance.titleStr = this.translationsObj.SITE_MANAGMENT.TANK_LOCATION;
      }
      modalRef.result.then((result) => {
        if(result.longitude != undefined && result.latitude != undefined){
          this.coordinates = result;
          let point = result.latitude.toFixed(8)+','+result.longitude.toFixed(8);
          this.binLocation = point;
        }
      }, (reason) => {    
      });
  }

  filterItems(name: string) {      
    let results = this.sitesListFilter.filter(item =>
      item.site_name.toLowerCase().indexOf(name.toLowerCase()) === 0);      
    return results;
  }

  filterBins(name: string) {      
    let results = this.binsListFilter.filter(item =>
      item.bin_name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    return results;
  }

  filterBinsNeighborhoodList(name: string) {          
    let results = this.neighborhoods.filter(item =>
      item.toLowerCase().indexOf(name.toLowerCase()) === 0);
    return results;
  }

  optionSelected(option) {    
    const site = _.filter(this.sitesListFilter, (site) => {
			return site.site_name == option;
		});
    this.chosenSite = site[0];   

    this.apiQuery.filteredBins$.subscribe((bins) => {		   
      if(bins.length == 0) return;
      const binsAccordingToSite = _.filter(bins, (bin) => {
        return bin["site_id"] == this.chosenSite.site_id;
      });

      this.binsListFilter = binsAccordingToSite;	
      this.ElForm.controls.binName.setValue(this.data.chosenTicket.ticketInfo[0].bin_name);
      this.optionSelectedBinName(this.data.chosenTicket.ticketInfo[0].bin_name);  
      this.filteredBins = this.ElForm.controls.binName.valueChanges
      .pipe(
        tap((val:any) => {
          if(val == ''){
            this.ElForm.controls.binAddress.setValue('');    
          }        
        }),
      startWith(''),
      map(item => item ? this.filterBins(item) : this.binsListFilter.slice())
      );  
    });

    let BinsNeighborhoodList = {
      'site_id': this.chosenSite.site_id
    }
    this.apiService.getBinsNeighborhoodList(BinsNeighborhoodList).subscribe((v:any) => {	
      let ngh = [];
      _.each(v.NBRHoodList, (site) => {
        _.each(site.site_NBRH_list, (neighborhood) => {
          ngh.push(neighborhood.nbrh_name);
        });
      });
      this.neighborhoods = ngh;    
      if(this.data.chosenTicket.ticket_event_type_id == 3){
        this.ElForm.controls.binsNeighborhood.setValue(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status] ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].installed_bin_neighborhood : '');       
        this.optionSelectedBinsNeighborhood(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status] ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].installed_bin_neighborhood : '');  
      }
      this.filteredNeighborhoods = this.ElForm.controls.binsNeighborhood.valueChanges
      .pipe(
      startWith(''),
      map(item => item ? this.filterBinsNeighborhoodList(item) : this.neighborhoods.slice())
      );  
    });
  }

  optionSelectedBinName(option){    
    const bin = _.filter(this.binsListFilter, (bin) => {      
			return bin.bin_name == option;
		});    
    this.chosenBin = bin[0];
    if(this.chosenBin){
      this.ElForm.controls.binAddress.setValue(this.chosenBin.Bin_location.bin_address);    
    }
  }

  
  optionSelectedBinsNeighborhood(option){    
    const allNeighborhood = _.filter(this.neighborhoods, (neighborhood) => {      
      return option.value ? neighborhood.trim() == option.value.trim() : neighborhood.trim() == option.trim();
		});    
    this.chosenNeighborhood = allNeighborhood[0] ? allNeighborhood[0].trim() : '';      
  }

  removeOrReplaceTypeFunc(ev){
    this.ElForm.controls.binImage.setValue('');
  }

  closeModal(){
    this.apiStore.update({ selectedTicket: [] });
    this.dialogRef.close();
  }

  choosePriority(priority){
    if(this.priority){
      return;
    }
    this.priority = priority; 
  }

  completeResolve(){
    let ticketManagementRequest = {};   
		if(sessionStorage.getItem("ticketManagementMinDate") == '' || sessionStorage.getItem("ticketManagementMaxDate") == '' || 
		sessionStorage.getItem("ticketManagementMinDate") == null || sessionStorage.getItem("ticketManagementMaxDate") == null){
			ticketManagementRequest = {
				status_id : -1
			  }
		}else{
			ticketManagementRequest = {
				start_time : sessionStorage.getItem("ticketManagementMinDate"),
				end_time : sessionStorage.getItem("ticketManagementMaxDate")
			  }
		}				

		this.apiStore.update({ allCRTicketsList: [] });
		this.apiStore.update({ cRTicketsList: [] });
		this.apiService.getCRTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allInsTicketsList: [] });
		this.apiStore.update({ insTicketsList: [] });
		this.apiService.getInsTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allRmTicketsList: [] });
		this.apiStore.update({ rmTicketsList: [] });
		this.apiService.getRmTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

    this.apiStore.update({ allTicketsList: [] });
    this.apiStore.update({ ticketsList: [] });
    this.apiService.getTicketsList(JSON.parse(sessionStorage.getItem("chosenSites")));

    this.apiStore.update({ allFilteredBins: [] });                    
    this.apiStore.update({ filteredBins: [] });
    this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));

    this.apiStore.update({ allFilteredBasicBins: [] });                    
    this.apiStore.update({ filteredBasicBins: [] }); 
    this.apiService.getfilteredBasicBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));

    this.closeModal();  
  }

  resolveTicket(){    
    if(!this.ElForm.valid){
      return;
    }      
    const request = {   
      "event_cause_id":this.ElForm.getRawValue()["eventCause"],
      "event_type_id":this.data.selectedTab == 0 ? 1 : this.data.selectedTab == 1 ? 3 : 4,
      "priority_id":this.priority,
      "ticket_id":this.data.chosenTicket.ticket_id,
      "comment":this.ElForm.getRawValue()["resolvedDescription"],
      "status_id":4,
      "site_id":this.chosenSite.site_id,
      "expected_arrival_date":moment(this.ElForm.getRawValue()["arrival_date"]).format('YYYY-MM-DD HH:mm:ss')
    }

    if(this.data.selectedTab == 0){
      request["bin_id"] = this.chosenBin.bin_id;
      request["analysis_id"] = this.ElForm.getRawValue()["eventCheckOnField"];
      request["ticket_remove_replace_cause_id"] = this.ElForm.getRawValue()["removeOrReplaceType"] == '' ? 0 : this.ElForm.getRawValue()["removeOrReplaceType"];
      request["is_replaced"] = this.ElForm.getRawValue()["unitReplaced"];
    }else if(this.data.selectedTab == 1){
      request["is_installed"] = this.ElForm.getRawValue()["unitInstalled"];
      request["bin_install_address"] = this.ElForm.getRawValue()["binAddress"];
      request["lat"] = this.lat;
      request["lon"] = this.lon;
      request["installation_type_id"] = 0;
      request["bin_name"] = this.ElForm.getRawValue()["installedBinName"];
      request["installed_bin_neighborhood"] = this.chosenNeighborhood ? this.chosenNeighborhood : '';
    }else if(this.data.selectedTab == 2){
      request["bin_id"] = this.chosenBin.bin_id;
      request["is_removed_from_bin"] = this.ElForm.getRawValue()["removedFromBin"];
      request["is_removed_from_site"] = this.ElForm.getRawValue()["removedFromSite"];
      request["ticket_remove_replace_cause_id"] = this.ElForm.getRawValue()["removeOrReplaceType"] == '' ? 0 : this.ElForm.getRawValue()["removeOrReplaceType"];
    }
    this.apiService.insertNewTicket(request).subscribe((v:any) => {  
      if(this.ElForm.getRawValue()["binImage"] == ''){
        this.completeResolve();
      }else{
        this.apiService.uploadPictureToAmazonBucket(this.file_store,this.data.chosenTicket.ticket_id,2).subscribe((v:any) => {  
          if(v.upload_url.length > 0){
            let dataToRegister = {};
            dataToRegister["ticket_id"] = this.data.chosenTicket.ticket_id;
            dataToRegister["picture_url"] = v.upload_url;
            dataToRegister["picture_url_time"] = moment().format('YYYY-MM-DD HH:mm:ss');     
            dataToRegister["item_type_id"] = 2;               
            this.apiService.registerPicture(dataToRegister).subscribe((v:any) => {    
              this.completeResolve();                		                      
            });   
          }
        }); 
      }      
    });
  }
}

export namespace API {

	export interface Bin {
		bin_id: number;
		sim_id: string;
		bin_name: string;
		bin_description: string;
		Bin_location: BinLocation;
		bin_type_id: number;
		is_active: number;
		cluster_id: string;
		Bin_dimensions: BinDimensions;
		Active_features: ActiveFeatures;
		Bin_thresholds: BinThresholds;
		Bin_live_Data: BinLiveData;
	}

	interface BinLocation {
		bin_latitude: number;
		bin_longitude: number;
		bin_address: string;
	}

	interface BinDimensions {
		bin_height: number;
		bin_width: number;
	}


	interface ActiveFeatures {
		is_fire_alarm_on: number;
		is_washing_alarm_on: number;
		is_bin_upgrade_on: number;
	}

	interface BinThresholds {
		bin_max_fill_level: number;
		bin_max_fill_level_ratio: number;
		bin_max_volume_in_liters: number;
		bin_max_temperature: number;
		red_status_fill_level_in_percent: number;
		yellow_status_fill_level_in_percent: number;
		green_status_fill_level_in_percent: number;

	}

	interface BinLiveData {
		fill_level: number;
		fill_level_percent: number;
		fill_level_time: string;
		color_status_id: number;
		color_status_name: string;
		temperature: number;
		humidity: number;
		last_collection_time: string;
		battery_level: number;
		Transmission_data: TransmissionData;
	}

	interface TransmissionData {
		signal_quality: number;
		last_transmission_time: string;
		expected_transmission_time: string;
	}

	export interface DailyWorkPlan {
		plan_id: number;
		account_id: number;
		num_of_bins_to_collect: number;
		num_of_collected_bins: number;
		num_of_sites: number;
		num_of_active_trucks: number;
		Sites_Daily_work_plan: any;
	}

	interface BinCollectionData {
		bin_collection_time: string;
		bin_fill_level_before_collection: number;
		collection_status: BinCollectionType;
		is_washed: number;
	}
	export interface BinsCollectionsData {
		bin_id: number;
		bin_collections: BinCollectionData[];
	}
	export interface marker {
		lat: number;
		lng: number;
		label?: string;
		draggable: boolean;
	};
	
	export interface IgetBinsBySite {
	  bin_id: string;
	  bin_name: string;
	  bin_address: string;
	  bin_lat: string;
	  bin_lon: string;
	};

	export interface ITimOutObj {
		activationTimeOutAttempt: number,
		installationPhase: InstallationPhase
	};

	export interface IInstallationCancellationObj {
		installationPhase: InstallationPhase,
		
	}

	export interface IPreInstallationXlsxObj {
		num_of_bins: number,
		original_index: number,
		bin_letter: string,
		bin_number: string,
		bin_address: string,
		isAddressValid: boolean,
		comments: string,
		bin_lat: number,
		bin_lng: number,
		distance_from_start_point: number,
		waze_link: string
	}


	export enum BinCollectionType {
		'COLLECTED_TOO_LATE',
		'COLLECTED_TOO_SOON',
		'COLLECTED_ON_TIME'
	}


	export enum E_Error_Tracer_Zones {
		E_ERROR_TRACER_ZONE_SENSORS,
		E_ERROR_TRACER_ZONE_MODEM,
		E_ERROR_TRACER_ZONE_OTHER,
	  }
	  
	  export enum E_Error_Tracer_Sensors_Zone {
		E_ERROR_TRACER_ZONE_SENSORS_US_COM,
		E_ERROR_TRACER_ZONE_SENSORS_US_MEASURE,
		E_ERROR_TRACER_ZONE_SENSORS_ACC_COM,
		E_ERROR_TRACER_ZONE_SENSORS_ACC_FALSE_DETECTION,
		E_ERROR_TRACER_ZONE_SENSORS_TEMP_COM,
		E_ERROR_TRACER_ZONE_SENSORS_TEMP_ERROR
	  }
	  
	  export enum InstallationPhase {
		'PENDING',
		'PENDING_REVIEW',
		'REVIEW_APPROVED',
		'PENDING_SWAP_ACTIVATION',
		'PENDING_ACTIVATION',
		'NEW_PENDING_ACTIVATION',
		'ACTIVATED',
		'PENDING_PHYSICAL_INSTALLATION',
		'PHYSICALLY_INSTALLED',
		'INSTALLATION_COMPLETE'
	  
	  }

	  export enum E_EXCEPTION_HANDLER_EXCEPTION_ID {
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_DUMMY',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_ROUTE_CALCULATION',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_LOG_IN',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_LOG_OUT',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_PLAN_FINISHED',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_CURRENT_LOCATION',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_UNEXPECTED_COLLECTION_ORDER',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_ARRIVED_BUT_NO_COLLECTION',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_WASTE_DUMP_REROUTE',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_BIN_LOCATION_ISSUE',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_TABLET_NO_INTERNET_CONNECTION',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_UNUSABLE_BIN_ISSUE',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_BIN_STICKER_ISSUE',
		'E_EXCEPTION_HANDLER_EXCEPTION_ID_DRIVER_ARRIVED_TO_SITE',
		'E_EXCEPTION_HANDLER_EXCEPTION_GENERATOR_LAST'
	  };
	   
	  export enum E_EXCEPTION_HANDLER_REROUTE_STATUS_ID {
		'E_EXCEPTION_HANDLER_REROUTE_STATUS_ID_DUMMY',
		'E_EXCEPTION_HANDLER_REROUTE_STATUS_ID_GOING',
		'E_EXCEPTION_HANDLER_REROUTE_STATUS_ID_ARRIVED',
		'E_EXCEPTION_HANDLER_REROUTE_STATUS_ID_LEAVE'
	  }

	  export enum E_EXCEPTION_HANDLER_EXCEPTION_GENERATOR {
		'E_EXCEPTION_HANDLER_EXCEPTION_GENERATOR_DUMMY',
		'E_EXCEPTION_HANDLER_EXCEPTION_GENERATOR_DRIVER',
		'E_EXCEPTION_HANDLER_EXCEPTION_GENERATOR_APPLICATION',
		'E_EXCEPTION_HANDLER_EXCEPTION_GENERATOR_LAST'
	  };
}

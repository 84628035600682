import { ChangeDetectorRef, Component, EventEmitter, Injectable, Output } from '@angular/core';
import _ from 'lodash';
import moment from 'moment';
import { NgbDatepickerI18n, NgbCalendar, NgbModule, NgbDatepickerModule, NgbDateStruct, NgbDateAdapter, NgbDate, NgbDatepicker, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ApiQuery } from '../../web-services/api/api.query';
import { ResponsiveService } from '../../services/responsive.service';
import { Router } from '@angular/router';
import { TranslatorService } from '../../services/translator_service';
import { CommonModule, TranslationWidth } from '@angular/common';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
	one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
	!one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
		? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
	!one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
		? false : one.day > two.day : one.month > two.month : one.year > two.year;

const I18N_VALUES = {
  'iw': {
    weekdays: ['שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת', 'ראשון'],
    months: ['ינו', 'פבר', 'מרץ', 'אפר', 'מאי', 'יונ', 'יול', 'אוג', 'ספט', 'אוק', 'נוב', 'דצמ'],
  },
  'en': {
	weekdays: ['Mon', 'The', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  'pl': {
	weekdays: ['Mon', 'The', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  }
};
@Injectable()
export class I18n {
  language = localStorage.getItem('language');
  translationsObj;
  constructor (private translator: TranslatorService) {
	this.translator.currentLangEmitter$
	.subscribe(async value=>{						
		this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		this.language = value.toString();				 
	})	
  }
}
@Component({
  selector: 'app-datepicker-range',
  standalone: true,
  imports: [TranslateModule,RTLDivDirectiveDirective,NgbModule,CommonModule,
	NgbDatepickerModule,FormsModule,ReactiveFormsModule],
  templateUrl: './datepicker-range.component.html',
  styleUrl: './datepicker-range.component.scss',
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: DatepickerRangeComponent}]
})
export class DatepickerRangeComponent extends NgbDatepickerI18n {
	override getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
		return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
	}
	getMonthShortName(month: number): string {
		return I18N_VALUES[this._i18n.language].months[month - 1];
	}
	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}
	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
	@Output() selectDates: EventEmitter<any> = new EventEmitter<any>();

	groups:any[]=[];
	hoveredDate: NgbDateStruct;
	startDate: NgbDateStruct;
	endDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	disabled = true;
	firstDayOfWeek = 1;
	currentLang : string;
	translate;
	translationsObj;
	monthsToDisplay = 2;
	isMobile: Boolean;
	showResetBtn:boolean = false;
	constructor(private calendar: NgbCalendar, private _i18n: I18n, private translator: TranslatorService,
		private router: Router,private responsiveService: ResponsiveService,private apiQuery:ApiQuery,
		private cd: ChangeDetectorRef) {
		super()
		this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value.toString();	
		  if (this.currentLang == 'iw') {
			this.firstDayOfWeek = 7;
		   }else{
			this.firstDayOfWeek = 1;
		   }
	       this.cd.detectChanges();
		})
	}

	private selectDatesEmitter = () => {
		const selectedDates = {
			fromDate: this.fromDate ? `${this.fromDate.day}.${this.fromDate.month}.${this.fromDate.year}` : '',
			toDate: this.toDate ? `${this.toDate.day}.${this.toDate.month}.${this.toDate.year}` : ''
		};
		this.selectDates.emit(selectedDates);
	}

	onDateSelection(date: NgbDateStruct) {
		if (this.isDisabled(date)) return;

		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && equals(date, this.fromDate)) {
			this.toDate = this.fromDate;
		} else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
		if (this.fromDate && this.toDate) {
			this.selectDatesEmitter();
		}
	}

	isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate) && !after(date, this.calendar.getToday());
	isInside = date => after(date, this.fromDate) && before(date, this.toDate);
	isFrom = date => equals(date, this.fromDate);
	isTo = date => equals(date, this.toDate);
	isDisabled = date => after(date, this.calendar.getToday());
	hasCollection = date => {
		let newMoment = moment();
		newMoment.month(date.month-1);
		newMoment.date(date.day);
		newMoment.year(date.year);
		if(this.router.url.includes('collections')){
			if(this.groups.length > 0){
				if(this.groups.includes(moment(newMoment.format("YYYY-MM-DD 00:00:00")).valueOf())){
					return true;
				}
			}
		}
		return false;
	}

	ngOnInit() {		
		this.onResize();
		this.responsiveService.checkWidth();
		this.showResetBtn = false;
		if(this.isMobile){
			this.monthsToDisplay = 1;			
		}else{
			this.monthsToDisplay = 2;			
		}
		this.startDate = this.calendar.getPrev(this.calendar.getToday(), 'y', 5);
		this.endDate = this.calendar.getToday();
		
		if(this.router.url.includes('ticketManagement')){
			this.showResetBtn = true;
			if(sessionStorage.getItem("ticketManagementStartDate") != "Invalid date" && sessionStorage.getItem("ticketManagementEndDate") != "Invalid date"
			&& sessionStorage.getItem("ticketManagementStartDate")!= null && sessionStorage.getItem("ticketManagementEndDate") != null){	
				const from = new Date(moment(sessionStorage.getItem("ticketManagementStartDate")).format("YYYY-MM-DD 23:59:59"));
				const ngbDateStructFrom = { day: from.getUTCDate(), month: from.getUTCMonth() + 1 , year: from.getUTCFullYear()};
				
				const to = new Date(moment(sessionStorage.getItem("ticketManagementEndDate")).format("YYYY-MM-DD 23:59:59"));
				const ngbDateStructTo = { day: to.getUTCDate(), month: to.getUTCMonth() + 1 , year: to.getUTCFullYear()};
		
				this.fromDate = ngbDateStructFrom;
				this.toDate = ngbDateStructTo;
			}else{
				this.fromDate = null;
				this.toDate = null;
			}
		}
		else if(this.router.url.includes('fillings')) {
			if(sessionStorage.getItem("fillingsStartDate") != "Invalid date" && sessionStorage.getItem("fillingsEndDate") != "Invalid date"
			&& sessionStorage.getItem("fillingsStartDate")!= null && sessionStorage.getItem("fillingsEndDate") != null){			
				const from = new Date(moment(sessionStorage.getItem("fillingsStartDate")).format("YYYY-MM-DD 23:59:59"));
				const ngbDateStructFrom = { day: from.getUTCDate(), month: from.getUTCMonth() + 1 , year: from.getUTCFullYear()};
				
				const to = new Date(moment(sessionStorage.getItem("fillingsEndDate")).format("YYYY-MM-DD 23:59:59"));
				const ngbDateStructTo = { day: to.getUTCDate(), month: to.getUTCMonth() + 1 , year: to.getUTCFullYear()};
		
				this.fromDate = ngbDateStructFrom;
				this.toDate = ngbDateStructTo;
			}else{
				this.fromDate = this.calendar.getToday();
				this.toDate = this.calendar.getToday();
			}
		}else if(this.router.url.includes('collections')) {
			if(sessionStorage.getItem("collectionsStartDate") != "Invalid date" && sessionStorage.getItem("collectionsEndDate") != "Invalid date"
			&& sessionStorage.getItem("collectionsStartDate")!= null && sessionStorage.getItem("collectionsEndDate") != null){			
				const from = new Date(moment(sessionStorage.getItem("collectionsStartDate")).format("YYYY-MM-DD 23:59:59"));
				const ngbDateStructFrom = { day: from.getUTCDate(), month: from.getUTCMonth() + 1 , year: from.getUTCFullYear()};
				
				const to = new Date(moment(sessionStorage.getItem("collectionsEndDate")).format("YYYY-MM-DD 23:59:59"));
				const ngbDateStructTo = { day: to.getUTCDate(), month: to.getUTCMonth() + 1 , year: to.getUTCFullYear()};
		
				this.fromDate = ngbDateStructFrom;
				this.toDate = ngbDateStructTo;
			}else{
				this.fromDate = this.calendar.getToday();
				this.toDate = this.calendar.getToday();
			}
			this.apiQuery.collectionsStatsToHighlightPicker$.subscribe((data:any) => {
				this.groups = [];
				_.each(data, (site) => {
					if(site.site_bins_collections != undefined){
					  _.each(site.site_bins_collections["0_25"], (item) => {    
						this.groups.push(moment(moment(item.bin_collection_time).format("YYYY-MM-DD 00:00:00")).valueOf());
					  });    
					  _.each(site.site_bins_collections["26_50"], (item) => {   
						this.groups.push(moment(moment(item.bin_collection_time).format("YYYY-MM-DD 00:00:00")).valueOf());
					  });
					  _.each(site.site_bins_collections["51_75"], (item) => {     
						this.groups.push(moment(moment(item.bin_collection_time).format("YYYY-MM-DD 00:00:00")).valueOf());
					  });
					  _.each(site.site_bins_collections["76_85"], (item) => {          
						this.groups.push(moment(moment(item.bin_collection_time).format("YYYY-MM-DD 00:00:00")).valueOf());
					  });
					  _.each(site.site_bins_collections["86_100"], (item) => {         
						this.groups.push(moment(moment(item.bin_collection_time).format("YYYY-MM-DD 00:00:00")).valueOf());
					  });
					}
				});
			});
		}else if(this.router.url.includes('reports')){
			if(sessionStorage.getItem("reportsStartDate") != "Invalid date" && sessionStorage.getItem("reportsEndDate") != "Invalid date"
			&& sessionStorage.getItem("reportsStartDate")!= null && sessionStorage.getItem("reportsEndDate") != null){			
				const from = new Date(moment(sessionStorage.getItem("reportsStartDate")).format("YYYY-MM-DD 23:59:59"));
				const ngbDateStructFrom = { day: from.getUTCDate(), month: from.getUTCMonth() + 1 , year: from.getUTCFullYear()};
				
				const to = new Date(moment(sessionStorage.getItem("reportsEndDate")).format("YYYY-MM-DD 23:59:59"));
				const ngbDateStructTo = { day: to.getUTCDate(), month: to.getUTCMonth() + 1 , year: to.getUTCFullYear()};
		
				this.fromDate = ngbDateStructFrom;
				this.toDate = ngbDateStructTo;
			}else{
				this.fromDate = this.calendar.getToday();
				this.toDate = this.calendar.getToday();
			}
		}else if(this.router.url.includes('insights')){
			if(sessionStorage.getItem("insightsStartDate") != "Invalid date" && sessionStorage.getItem("insightsEndDate") != "Invalid date"
			&& sessionStorage.getItem("insightsStartDate")!= null && sessionStorage.getItem("insightsEndDate") != null){			
				const from = new Date(moment(sessionStorage.getItem("insightsStartDate")).format("YYYY-MM-DD 23:59:59"));
				const ngbDateStructFrom = { day: from.getUTCDate(), month: from.getUTCMonth() + 1 , year: from.getUTCFullYear()};
				
				const to = new Date(moment(sessionStorage.getItem("insightsEndDate")).format("YYYY-MM-DD 23:59:59"));
				const ngbDateStructTo = { day: to.getUTCDate(), month: to.getUTCMonth() + 1 , year: to.getUTCFullYear()};
		
				this.fromDate = ngbDateStructFrom;
				this.toDate = ngbDateStructTo;
			}else{
				this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 14);
				this.toDate = this.calendar.getToday();
			}
		}else{
			this.fromDate = this.calendar.getToday();
			this.toDate = this.calendar.getToday();
		}		
		this.selectDatesEmitter();
	}
	
	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

	selectToday() {
		this.fromDate = this.calendar.getToday();
		this.toDate = this.calendar.getToday();
		this.selectDatesEmitter();
	}

	resetToDefault(){
		this.fromDate = null;
		this.toDate = null;
		this.selectDatesEmitter();
	}

}

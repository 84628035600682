import { ChangeDetectionStrategy, Component } from '@angular/core';
import moment from 'moment';
import { TranslatorService } from '../../../services/translator_service';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiStore } from '../../../web-services/api/api.store';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { DatepickerRangeComponent } from '../../../widgets/datepicker-range/datepicker-range.component';
import { MatDividerModule } from '@angular/material/divider';

import { BinSamplingHistoryReportComponent } from '../bin-sampling-history-report/bin-sampling-history-report.component';
import { AlertsDateRangeComponent } from '../../alerts-date-range/alerts-date-range.component';
import { ExceptionsDistributionComponent } from '../exceptions-distribution/exceptions-distribution.component';
import { ExceptionListComponent } from '../exception-list/exception-list.component';
import { DriverReportDistributionComponent } from '../driver-report-distribution/driver-report-distribution.component';
import { DriverReportListComponent } from '../driver-report-list/driver-report-list.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [TranslateModule, MatCardModule, RTLDivDirectiveDirective, 
	DatepickerRangeComponent, MatDividerModule, BinSamplingHistoryReportComponent, AlertsDateRangeComponent, 
	ExceptionsDistributionComponent, ExceptionListComponent, DriverReportDistributionComponent, 
	DriverReportListComponent,CommonModule],
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss'
})
export class ReportsComponent {
  showDatePicker: Boolean = false;
	selectedDates: any = {
		from: '',
		to: ''
	};
  	minDate = '';
	datesDiff : any;
	maxDate = '';
	currentLang;
	timeDateFormat = 'DD.MM.YY';
	lastMaxDate:any;
	lastMinDate:any;
	user:object;
	usageType = 1;
	translationsObj;

  constructor(private translator: TranslatorService,private apiService:ApiService,private apiStore:ApiStore,private apiQuery:ApiQuery) {
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
			this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			this.currentLang = value;			
			if (this.currentLang !== 'iw') {
				this.timeDateFormat = 'MM.DD.YY';
			}
		})
	}

  ngOnInit() {
  }

  
	public showHideDatePicker = () => {
		this.showDatePicker = !this.showDatePicker;
	}

  	public onSelectDates = (selectDates: any) => {
		setTimeout(() => {
			this.showDatePicker = false;
			this.selectedDates.from = selectDates.fromDate;
			this.selectedDates.to = selectDates.toDate;		
			this.minDate = moment(this.selectedDates.from, 'DD.MM.YYYY').format('YYYY-MM-DD 00:00:01');
			this.maxDate = moment(this.selectedDates.to, 'DD.MM.YYYY').format('YYYY-MM-DD 23:59:59');
			sessionStorage.setItem("reportsMinDate",this.minDate);
			sessionStorage.setItem("reportsMaxDate",this.maxDate);

			sessionStorage.setItem("reportsStartDate",this.minDate);
			sessionStorage.setItem("reportsEndDate",this.maxDate);

			this.datesDiff = selectDates.fromDate + " - " + selectDates.toDate;	
			if(this.maxDate != this.lastMaxDate || this.minDate != this.lastMinDate){
				
				let request = {
					site_id : 0,
					start_time : this.minDate,
					end_time : this.maxDate
				}
				this.apiStore.update({ exceptionsReports: [] });
				this.apiService.getfilteredExceptionsReports(request,JSON.parse(sessionStorage.getItem("chosenSites")));
				this.apiService.getfilteredDriverExceptionsReports(this.minDate,this.maxDate,JSON.parse(sessionStorage.getItem("chosenSites")));
				this.apiService.getfilteredUserEventsByType(request,JSON.parse(sessionStorage.getItem("chosenSites")));
				this.lastMinDate = this.minDate;
				this.lastMaxDate = this.maxDate;
				this.apiQuery.user$.subscribe(user => {
					if (!user) {
						return;
					}					
					this.user = user;			
					if(this.user["usage_type_id"] != 1){
						this.usageType = this.user["usage_type_id"];
					}			
				});
			}
		}, 0);		
	}

	
	ngOnDestroy() {
		sessionStorage.removeItem('reportsMinDate');
		sessionStorage.removeItem('reportsMaxDate');
	}
}

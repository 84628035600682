@if (!isMobile) {
  <div>
    <div class="manage-bin-modal" rtlDiv>
      @if (usageType == 1) {
        <div class="manage-bin-header">{{ 'TITLES.MANAGE_BIN' | translate }}</div>
      }
      @if (usageType != 1) {
        <div class="manage-bin-header">{{ 'TITLES.MANAGE_TANK' | translate }}</div>
      }
      <div class="manage-bit-body">
        @if (data.properties.isAll || data.properties.bin_Name) {
          <div class="bin-name-container">
            @if (data && data.chosenBins && usageType == 1) {
              <div class="sub-title">{{ 'MANAGE_BIN.BIN_NAME' | translate }}</div>
            }
            @if (data && data.chosenBins && usageType != 1) {
              <div class="sub-title">{{ 'MANAGE_BIN.TANK_NAME' | translate }}</div>
            }
            @if (data && data.chosenBins) {
              <div class="bin-name-input truck-name-field">
                @if (data) {
                  <input [disabled]="data.properties.isAll" (keyup)="applySearch($event.target)" type="text" [(ngModel)]="binName" [ngClass]="{'input-bin-name-grayed' : data.properties.isAll, 'input-bin-name' : !data.properties.isAll}" name="binName" />
                }
              </div>
            }
            @if (binExists) {
              <div class="error-flag">{{ 'SITE_MANAGMENT.INVALID_BIN_NAME' | translate }}</div>
            }
            @if (nameFlag) {
              <div class="error-flag">{{ 'MANAGE_BIN.REQUIRED' | translate }}</div>
            }
          </div>
        }
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" (opened)="locationSettingsOpenState = true" (closed)="locationSettingsOpenState = false" class="panel-accordion">
            <mat-expansion-panel-header class="panel-accordion-header">
              <mat-panel-title class="panel-accordion-title">
                {{ 'MANAGE_BIN.LOCATION_SETTINGS' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div [ngClass]="{'hide-panel' : !locationSettingsOpenState}">
              <div class="flex-between">
                @if (data.properties.isAll || data.properties.bin_address) {
                  <div class="bin-name-container">
                    @if (usageType == 1) {
                      <div class="sub-title">{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}</div>
                    }
                    @if (usageType != 1) {
                      <div class="sub-title">{{ 'SITE_MANAGMENT.TANK_ADDRESS' | translate }}</div>
                    }
                    <div class="bin-name-input truck-name-field">
                      @if (data) {
                        <input type="text" [(ngModel)]="binAddress" class="input-bin-name" name="binAddress" />
                      }
                    </div>
                  </div>
                }
                @if (data && data.chosenBins && usageType == 1 && (data.properties.isAll || data.properties.bin_neighborhood)) {
                  <div class="days-between-container">
                    <div class="sub-title">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</div>
                    <form [formGroup]="ElForm" class="neighborhoods-form">
                      <mat-form-field class="neighborhoods-full-width">
                        <input matInput aria-label="Item" [matAutocomplete]="auto" formControlName="itemCtrl" (keyup.enter)="addOption()" class="mr-align">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option)">
                          @for (item of filteredItems | async; track item) {
                            <mat-option [value]="item" class="neighborhood-item" rtlDiv>
                              <span>{{ item }}</span>
                            </mat-option>
                          }
                        </mat-autocomplete>
                        @if (showAddButton && ElForm.getRawValue()['itemCtrl']) {
                          <button matSuffix mat-button mat-icon-button (click)="addOption()" [ngClass]="{'ltrBtn' : currentLang == 'iw', 'rtlBtn' : currentLang != 'iw'}">
                            <img src="assets/images/inventory/plus.svg" alt=""/>
                            <span class="btn-opt">{{ 'SITE_MANAGMENT.ADD' | translate }}</span>
                          </button>
                        }
                        @if (approveAdd) {
                          <button matSuffix mat-button mat-icon-button [ngClass]="{'ltrBtn' : currentLang == 'iw', 'rtlBtn' : currentLang != 'iw'}">
                            <img src="assets/images/inventory/checkmark.svg" alt=""/>
                            <span class="btn-opt">{{ 'SITE_MANAGMENT.ADDED' | translate }}</span>
                          </button>
                        }
                      </mat-form-field>
                    </form>
                  </div>
                }
              </div>
              @if (data.properties.isAll || data.properties.bin_description) {
                <div class="bin-name-container">
                  @if (usageType == 1) {
                    <div class="sub-title">{{ 'SITE_MANAGMENT.BIN_DESCRIPTION' | translate }}</div>
                  }
                  @if (usageType != 1) {
                    <div class="sub-title">{{ 'SITE_MANAGMENT.TANK_DESCRIPTION' | translate }}</div>
                  }
                  <div class="bin-name-input truck-name-field">
                    @if (data) {
                      <input type="text" [(ngModel)]="binDesc" class="input-bin-name" name="binDesc"/>
                    }
                  </div>
                </div>
              }
              @if (data && data.chosenBins && data.properties.isAll) {
                <div class="bin-name-container">
                  @if (usageType == 1) {
                    <div class="sub-title">{{ 'SITE_MANAGMENT.BIN_LOCATION' | translate }}</div>
                  }
                  @if (usageType != 1) {
                    <div class="sub-title">{{ 'SITE_MANAGMENT.TANK_LOCATION' | translate }}</div>
                  }
                  <div class="margin-gap">
                    <form [formGroup]="ElLocationForm" class="top-fields">
                      <div class="bin-name-container">
                        <div class="form-control-latLon">
                          <input (onAddressChange)="handleAddressChange($event)" ngx-gp-autocomplete #placesRef="ngx-places" (keyup.enter)="changeLoc()" type="text" class="latLon-input" formControlName="lonLat"  placeholder="{{'OTHER.SEARCH' | translate}}" id="latLon-input"/>                            
                          <img (click)="changeLoc()" class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                        </div>
                      </div>
                    </form>
                    <google-map
                    [options]="mapOptions"
                    (mapInitialized)="loadAPIWrapper($event)"
                    [center]="center"                  
                    class="map-manage-modal">
                    <map-marker [position]="{lat: latitude, lng: longitude}" [options]="{ icon: { url: markerIcon } , draggable : true }"
                    (mapDragend)="markerDragEnd($event)"></map-marker>
                  </google-map>
                  <div>
                    <h5>{{address}}</h5>
                  </div>
                </div>
              </div>
            }
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" (opened)="advancedSettingsOpenState = true" (closed)="advancedSettingsOpenState = false" class="panel-accordion">
          <mat-expansion-panel-header class="panel-accordion-header">
            <mat-panel-title class="panel-accordion-title">
              {{ 'SITE_MANAGMENT.ADVANCED_SET' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div [ngClass]="{'hide-panel' : !advancedSettingsOpenState}">
            @if (data && data.chosenBins && (data.properties.isAll || data.properties.bin_Site)) {
              <div class="days-between-container">
                <div class="sub-title">{{ 'TICKET_MANAGEMENT.SITE' | translate }}</div>
                <form [formGroup]="ElFormSites" class="neighborhoods-form">
                  <mat-form-field class="neighborhoods-full-width">
                    <mat-select formControlName="binSite" class="select-option" (selectionChange)="changeSite($event)">
                      @for (site of binSitesToDisplay; track site) {
                        <mat-option rtlDiv [value]="site.site_id" class="option-dropDown list-item">
                          <span rtlDiv>{{ site.site_name }}</span>
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            }
            @if (data && data.chosenBins && (data.properties.isAll || data.properties.bin_Type)) {
              <div class="days-between-container">
                <div class="sub-title">{{ 'SITE_MANAGMENT.SELECT_TYPE' | translate }}</div>
                <form [formGroup]="ElFormTypes" class="neighborhoods-form">
                  <mat-form-field class="neighborhoods-full-width">
                    <mat-select formControlName="binType" class="select-option" (selectionChange)="changeType($event)">
                      @for (type of binTypesToDisplay; track type) {
                        <mat-option rtlDiv [value]="type.site_bin_type_id" class="option-dropDown list-item">
                          <span rtlDiv>{{ type.bin_type_name }}</span>
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            }
            @if (data.properties.isAll) {
              <div class="days-between-container flex-between">
                @if (usageType == 1) {
                  <div>
                    <div class="sub-title">{{ 'MANAGE_BIN.MAX_DAYS' | translate }}</div>
                    <div class="days-between-input">
                      @if (data) {
                        <input type="number" id="max-days" [ngClass]="{'disable-input-number': PartialManagement,'input-number': !PartialManagement}" [(ngModel)]="maxDaysValue" min="0" [disabled]="PartialManagement"/>
                      }
                      @if (isAdmin) {
                        <div class="arrows-container">
                          <div class="up-container">
                            <div class='up-arrow' (click)="incrementMaxCollection()"></div>
                          </div>
                          <div class="down-container">
                            <div class='down-arrow' (click)="decrementMaxCollection()"></div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }
                <div>
                  @if (usageType == 1) {
                    <div class="sub-title">{{ 'SITE_MANAGMENT.BIN_MAX_CAP_L' | translate }}</div>
                  }
                  @if (usageType != 1) {
                    <div class="sub-title">{{ 'SITE_MANAGMENT.TANK_MAX_CAP_L' | translate }}</div>
                  }
                  <div class="days-between-input">
                    @if (data) {
                      <input id="max-cap" type="number" class="disable-input-number" [(ngModel)]="maxCapValue" min="0" [disabled]="true"/>
                    }
                    <!-- <div class="arrows-container" *ngIf="isAdmin">
                    <div class="up-container">
                      <div class='up-arrow' (click)="incrementMaxCap()"></div>
                    </div>
                    <div class="down-container">
                      <div class='down-arrow' (click)="decrementMaxCap()"></div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          }
          @if (data.properties.isAll) {
            <div class="bin-name-container">
              <div class="sub-title">{{ 'SITE_MANAGMENT.CLUSTER' | translate }}</div>
              <div class="bin-name-input truck-name-field cluster-opt">
                <mat-form-field>
                  <mat-select [disabled]="PartialManagement" panelClass="clustersPane" (openedChange)="openedChange($event)" #singleSelect placeholder="" (valueChange)="changeValue($event)" [(value)]="selectedCluster">
                    <mat-option>
                      <ngx-mat-select-search #searchngx class="ngx-search"
                      placeholderLabel="{{translationsObj ? translationsObj.OTHER.SEARCH : ''}}"
                      [formControl]="clusterFilterCtrl"
                      noEntriesFoundLabel="{{translationsObj ? translationsObj.TITLES.NO_RES : ''}}">
                      </ngx-mat-select-search>
                    </mat-option>
                    <div class="clusters-container">
                      @for (item of filteredList | async; track item) {
                        <mat-option [value]="item.cluster_id" class="list-item">
                          @for (bin of item.cluster_bins; track bin) {
                            <div rtlDiv>
                              @if (usageType == 1 && item.cluster_id != -1) {
                                <b>{{ 'SITE_MANAGMENT.BIN_NAME' | translate }}: </b>
                              }
                              @if (usageType != 1 && item.cluster_id != -1) {
                                <b>{{ 'SITE_MANAGMENT.TANK_NAME' | translate }}: </b>
                              }
                              {{bin.bin_name}}@if (item.cluster_id != -1) {
                              <span>,</span>
                            }
                            @if (usageType == 1 && item.cluster_id != -1) {
                              <b>{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}: </b>
                            }
                            @if (usageType != 1 && item.cluster_id != -1) {
                              <b>{{ 'SITE_MANAGMENT.TANK_ADDRESS' | translate }}: </b>
                            }
                            {{item.cluster_address}}
                          </div>
                        }
                      </mat-option>
                    }
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        }
        @if (data.properties.isAll) {
          <div class="threshold-level-container">
            <div class="threshold-sub-title">{{ 'MANAGE_BIN.THRESHOLD_LEVEL' |
            translate }}</div>
            <div class=" threshold-sub-title">
              <nouislider rtlDiv #slider [connect]="true" [min]="0" [max]="100" [tooltips]="thresholdTooltips" [(ngModel)]="thresholdRange"
                (ngModelChange)="onThresholdChange($event)" [disabled]="PartialManagement">
              </nouislider>
              <div class="legend-container">
                <span class="left-legend">0%</span>
                <span class="right-legend">100%</span>
              </div>
            </div>
          </div>
        }
        @if (data.properties.isAll) {
          <div class="max-capacity-container">
            @if (usageType == 1) {
              <div class="sub-title">{{ 'MANAGE_BIN.MAX_CAPACITY' | translate }}</div>
            }
            @if (usageType == 2) {
              <div class="sub-title">{{ 'MANAGE_BIN.MIN_CAPACITY_D' | translate }}</div>
            }
            <div class="threshold-bar">
              <nouislider rtlDiv #maxslider [connect]="true" [min]="0" [max]="100" [tooltips]="maxTooltip" [(ngModel)]="maxRange"
              (ngModelChange)="onMaxChange($event)" [disabled]="PartialManagement"></nouislider>
              <div class="legend-container">
                <span class="left-legend">0%</span>
                <span class="right-legend">100%</span>
              </div>
            </div>
          </div>
        }
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-accordion [ngClass]="{'hide-panel' : !data.properties.isAll}">
    <mat-expansion-panel [expanded]="true" (opened)="workPlanOpenState = true" (closed)="workPlanOpenState = false" class="panel-accordion">
      <mat-expansion-panel-header class="panel-accordion-header">
        <mat-panel-title class="panel-accordion-title">
          {{ 'MANAGE_BIN.WORKPLAN' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div [ngClass]="{'hide-panel' : !workPlanOpenState}">
        @if (data.properties.isAll && usageType == 1) {
          <div class="restriction-container">
            <div class="sub-title">{{ 'MANAGE_BIN.BIN_RESTRICTION_TIMES' |
            translate }}</div>
            @if (!restrictionOpen) {
              <div class="restriction-input-closed" (click)="toggleRestriction()">
                <div class="restriction-input-container">
                  <div class="restriction-input-title">{{ 'MANAGE_BIN.BIN_RESTRICTION_CAPTION' |
                  translate }}</div>
                  <div class="chevron-down"></div>
                </div>
              </div>
            }
            @if (restrictionOpen) {
              <div class="restriction-input-open">
                <div class="title-container">
                  <div class="restriction-input-title" rtlDiv>{{ 'MANAGE_BIN.BIN_RESTRICTION_CAPTION' |
                  translate }}</div>
                  <div class="chevron-up" (click)="toggleRestriction()"></div>
                </div>
                @for (restriction of restrictions; track restriction; let i = $index) {
                  <div class="restriction-time-container">
                    <div class="dflex">
                      <mat-checkbox class="restriction-checkbox" [checked]="restrictions[i].checked" (change)="resetRestriction(i,$event)" rtlDiv> </mat-checkbox>
                      <div class="time-container" [ngClass]="{'disabled-restriction': !restrictions[i].checked}">
                        <div class="time-title">{{ 'MANAGE_BIN.START_TIME' |
                        translate }}</div>
                        <div class="flex-container" rtlDiv>
                          <div class='time-btn left' (click)="openDropDownList(restriction,'startTimeHour')">
                            {{selectedManageBinTime[i].startHour}}
                            <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                            <div [hidden]="!restriction.showHideDropdown.startTimeHour" class='time-dropdown-container'>
                              @for (item of manageBinTime[i].hours; track item) {
                                <div class="drop-down-list-item time-list" (click)="selectManageBinTime(item, 'startHour' , i)">
                                {{item}} </div>
                              }
                            </div>
                          </div>
                          <div class="colon">:</div>
                          <div class='time-btn right' (click)="openDropDownList(restriction, 'startTimeMinute')">
                            {{selectedManageBinTime[i].startminute}}
                            <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                            <div [hidden]="!restriction.showHideDropdown.startTimeMinute" class='time-dropdown-container'>
                              @for (item of manageBinTime[i].minutes; track item) {
                                <div class="drop-down-list-item time-list" (click)="selectManageBinTime(item, 'startminute', i)">
                                  {{item}}
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="arrow-container">
                        <div class="arrow " [ngClass]="{'transform-arrow': isRtl()}"></div>
                      </div>
                      <div class="time-container" [ngClass]="{'disabled-restriction': !restrictions[i].checked}">
                        <div class="time-title">{{ 'MANAGE_BIN.END_TIME' |
                          translate }}
                        </div>
                        <div class="flex-container" rtlDiv>
                          <div class='time-btn left' (click)="openDropDownList(restriction,'endTimeHour')">
                            {{selectedManageBinTime[i].endHour}}
                            <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                            <div [hidden]='!restrictions[i].showHideDropdown.endTimeHour' class='time-dropdown-container'>
                              @for (item of manageBinTime[i].hours; track item) {
                                <div class="drop-down-list-item time-list" (click)="selectManageBinTime(item, 'endHour', i)">
                                  {{item}}
                                </div>
                              }
                            </div>
                          </div>
                          <div class="colon">:</div>
                          <div class='time-btn right' (click)="openDropDownList(restriction,'endTimeMinute')">
                            {{selectedManageBinTime[i].endMinute}}
                            <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                            <div [hidden]='!restrictions[i].showHideDropdown.endTimeMinute' class='time-dropdown-container'>
                              @for (item of manageBinTime[i].minutes; track item) {
                                <div class="drop-down-list-item time-list" (click)="selectManageBinTime(item, 'endMinute', i)">
                                  {{item}}
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    @if (i == 0 && endTimeMustBeAfterStartTimeFirst) {
                      <div class="error-msg">{{ 'MANAGE_BIN.END_TIME_AFTER_START_TIME' | translate }}</div>
                    }
                    @if (i == 1 && endTimeMustBeAfterStartTimeSecond) {
                      <div class="error-msg">{{ 'MANAGE_BIN.END_TIME_AFTER_START_TIME' | translate }}</div>
                    }
                    @if (i == 2 && endTimeMustBeAfterStartTimeThird) {
                      <div class="error-msg">{{ 'MANAGE_BIN.END_TIME_AFTER_START_TIME' | translate }}</div>
                    }
                    @if (i == 1 && startTimeMustBeAfterEndTimeOfTheFirstOption) {
                      <div class="error-msg">{{ 'MANAGE_BIN.START_TIME_AFTER_END_TIME_OF_FIRST' | translate }}</div>
                    }
                    @if (i == 2 && startTimeMustBeAfterEndTimeOfTheSecondOption) {
                      <div class="error-msg">{{ 'MANAGE_BIN.START_TIME_AFTER_END_TIME_OF_SECOND' | translate }}</div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
        @if (data.properties.isAll) {
          <div class="days-between-container">
            <div class="sub-title"></div>
            <div class="dflex">
              <label class="container-extra" rtlDiv>
                {{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}
                <input id="bin-included" [(ngModel)]="isWorkplanIncluded" [checked]="isWorkplanIncluded" type="checkbox" (change)="toggleEditableInclude(isWorkplanIncluded)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
            </div>
          </div>
        }
        @if (!isWorkplanIncluded && data.properties.isAll) {
          <div class="days-between-container">
            <div class="neighborhoods-form">
              <mat-form-field class="neighborhoods-full-width" [floatLabel]="options.value.floatLabel">
                <mat-select placeholder="{{ 'MANAGE_BIN.CHOOSE_REASON' | translate }}" (valueChange)="changeReasonType($event)" [(value)]="selectedReasonType" class="mat-options">
                  @for (item of reasonTypes; track item) {
                    <mat-option [value]="item['removedBinReason_id']" class="neighborhood-item list-item" rtlDiv>
                      <span>{{ currentLang == 'iw' ? item['removedBinReason_name_hebrew'] : item['removedBinReason_name'] }}</span>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        }
        @if (data.properties.isAll) {
          <div class="days-between-container">
            @if (usageType == 1) {
              <div class="sub-title">{{ 'MANAGE_BIN.BIN_COLLECTION_DAYS' | translate }}</div>
            }
            @if (usageType == 2) {
              <div class="sub-title">{{ 'MANAGE_BIN.TANK_FILLING_DAYS' | translate }}</div>
            }
            <div class="dflex">
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.SUN' | translate }}
                <input id="sun-chx" [(ngModel)]="isSundayChecked" [checked]="isSundayChecked" type="checkbox" (change)="toggleEditableSunday(isSundayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.MON' | translate }}
                <input id="mon-chx" [(ngModel)]="isMondayChecked" [checked]="isMondayChecked" type="checkbox" (change)="toggleEditableMonday(isMondayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.TUE' | translate }}
                <input id="tue-chx" [(ngModel)]="isTuesdayChecked" [checked]="isTuesdayChecked" type="checkbox" (change)="toggleEditableTuesday(isTuesdayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.WED' | translate }}
                <input id="wed-chx" [(ngModel)]="isWednesdayChecked" [checked]="isWednesdayChecked" type="checkbox" (change)="toggleEditableWednesday(isWednesdayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.THU' | translate }}
                <input id="thu-chx" [(ngModel)]="isThursdayChecked" [checked]="isThursdayChecked" type="checkbox" (change)="toggleEditableThursday(isThursdayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.FRI' | translate }}
                <input id="fri-chx" [(ngModel)]="isFridayChecked" [checked]="isFridayChecked" type="checkbox" (change)="toggleEditableFriday(isFridayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.SAT' | translate }}
                <input id="sat-chx" [(ngModel)]="isSaturdayChecked" [checked]="isSaturdayChecked" type="checkbox" (change)="toggleEditableSaturday(isSaturdayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
            </div>
          </div>
        }
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div class='main-btns-wrapper'>
  <div class='btns-container' rtlDiv>
    <div class='cancel-btn' (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' |
      translate }}
    </div>
    <button (click)='saveWorkplan()'
      [ngClass]="{'not-apply-btn' : (!isWorkplanIncluded && (!reasonType && !selectedReasonType)) || binAddress == '' || submitClicked || !ElForm.valid || !ElLocationForm.valid || !ElFormSites.valid || !ElFormTypes.valid || binExists || selectedCluster == '' , 'apply-btn' : ((!isWorkplanIncluded && (reasonType || selectedReasonType)) || isWorkplanIncluded) && binAddress != '' && !submitClicked && ElForm.valid && ElLocationForm.valid && ElFormSites.valid && ElFormTypes.valid && !binExists && selectedCluster != ''}">
      {{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}
    </button>
  </div>
</div>
</div>
</div>
}
@if (isMobile) {
  <div>
    <div class="manage-bin-modal" rtlDiv>
      <div class="mobile-modal-title" rtlDiv>
        <div class="dflex" (click)="closeModal()">
          <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
        </div>
        <div>
          @if (usageType == 1) {
            <span class="main-title">{{ 'TITLES.MANAGE_BIN' | translate }}</span>
          }
          @if (usageType != 1) {
            <span class="main-title">{{ 'TITLES.MANAGE_TANK' | translate }}</span>
          }
        </div>
      </div>
      <div class="mobile-manage-bit-body">
        @if (data.properties.isAll || data.properties.bin_Name) {
          <div class="bin-name-container">
            @if (data && data.chosenBins && usageType == 1) {
              <div class="sub-title">{{ 'MANAGE_BIN.BIN_NAME' | translate }}</div>
            }
            @if (data && data.chosenBins && usageType != 1) {
              <div class="sub-title">{{ 'MANAGE_BIN.TANK_NAME' | translate }}</div>
            }
            @if (data && data.chosenBins) {
              <div class="bin-name-input truck-name-field">
                @if (data) {
                  <input [disabled]="data.properties.isAll" (keyup)="applySearch($event.target)" type="text" [(ngModel)]="binName" [ngClass]="{'input-bin-name-grayed' : data.properties.isAll, 'input-bin-name' : !data.properties.isAll}" name="binName" />
                }
              </div>
            }
            @if (binExists) {
              <div class="error-flag">{{ 'SITE_MANAGMENT.INVALID_BIN_NAME' | translate }}</div>
            }
            @if (nameFlag) {
              <div class="error-flag">{{ 'MANAGE_BIN.REQUIRED' | translate }}</div>
            }
          </div>
        }
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" (opened)="locationSettingsOpenState = true" (closed)="locationSettingsOpenState = false" class="panel-accordion">
            <mat-expansion-panel-header class="panel-accordion-header">
              <mat-panel-title class="panel-accordion-title">
                {{ 'MANAGE_BIN.LOCATION_SETTINGS' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div [ngClass]="{'hide-panel' : !locationSettingsOpenState}">
              <div class="mobile-flex-between">
                @if (data.properties.isAll || data.properties.bin_address) {
                  <div class="bin-name-container">
                    @if (usageType == 1) {
                      <div class="sub-title">{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}</div>
                    }
                    @if (usageType != 1) {
                      <div class="sub-title">{{ 'SITE_MANAGMENT.TANK_ADDRESS' | translate }}</div>
                    }
                    <div class="bin-name-input truck-name-field">
                      @if (data) {
                        <input type="text" [(ngModel)]="binAddress" class="input-bin-name" name="binAddress" />
                      }
                    </div>
                  </div>
                }
                @if (data && data.chosenBins && usageType == 1 && (data.properties.isAll || data.properties.bin_neighborhood)) {
                  <div class="days-between-container">
                    <div class="sub-title">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</div>
                    <form [formGroup]="ElForm" class="neighborhoods-form">
                      <mat-form-field class="neighborhoods-full-width">
                        <input matInput aria-label="Item" [matAutocomplete]="auto" formControlName="itemCtrl" (keyup.enter)="addOption()" class="mr-align">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option)">
                          @for (item of filteredItems | async; track item) {
                            <mat-option [value]="item" class="neighborhood-item" rtlDiv>
                              <span>{{ item }}</span>
                            </mat-option>
                          }
                        </mat-autocomplete>
                        @if (showAddButton && ElForm.getRawValue()['itemCtrl']) {
                          <button matSuffix mat-button mat-icon-button (click)="addOption()" [ngClass]="{'ltrBtn' : currentLang == 'iw', 'rtlBtn' : currentLang != 'iw'}">
                            <img src="assets/images/inventory/plus.svg" alt=""/>
                            <span class="btn-opt">{{ 'SITE_MANAGMENT.ADD' | translate }}</span>
                          </button>
                        }
                        @if (approveAdd) {
                          <button matSuffix mat-button mat-icon-button [ngClass]="{'ltrBtn' : currentLang == 'iw', 'rtlBtn' : currentLang != 'iw'}">
                            <img src="assets/images/inventory/checkmark.svg" alt=""/>
                            <span class="btn-opt">{{ 'SITE_MANAGMENT.ADDED' | translate }}</span>
                          </button>
                        }
                      </mat-form-field>
                    </form>
                  </div>
                }
              </div>
              @if (data.properties.isAll || data.properties.bin_description) {
                <div class="bin-name-container">
                  @if (usageType == 1) {
                    <div class="sub-title">{{ 'SITE_MANAGMENT.BIN_DESCRIPTION' | translate }}</div>
                  }
                  @if (usageType != 1) {
                    <div class="sub-title">{{ 'SITE_MANAGMENT.TANK_DESCRIPTION' | translate }}</div>
                  }
                  <div class="bin-name-input truck-name-field">
                    @if (data) {
                      <input type="text" [(ngModel)]="binDesc" class="input-bin-name" name="binDesc"/>
                    }
                  </div>
                </div>
              }
              @if (data && data.chosenBins && data.properties.isAll) {
                <div class="bin-name-container">
                  @if (usageType == 1) {
                    <div class="sub-title">{{ 'SITE_MANAGMENT.BIN_LOCATION' | translate }}</div>
                  }
                  @if (usageType != 1) {
                    <div class="sub-title">{{ 'SITE_MANAGMENT.TANK_LOCATION' | translate }}</div>
                  }
                  <div class="margin-gap">
                    <form [formGroup]="ElLocationForm" class="top-fields">
                      <div class="bin-name-container">
                        <div class="form-control-latLon">
                          <input (onAddressChange)="handleAddressChange($event)" ngx-gp-autocomplete #placesRef="ngx-places" (keyup.enter)="changeLoc()" type="text" class="latLon-input" formControlName="lonLat"  placeholder="{{'OTHER.SEARCH' | translate}}" id="latLon-input"/>                            
                          <img (click)="changeLoc()" class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                        </div>
                      </div>
                    </form>
                    <google-map
                    [options]="mapOptions"
                    (mapInitialized)="loadAPIWrapper($event)"
                    [center]="center"                  
                    class="map-manage-modal">
                    <map-marker [position]="{lat: latitude, lng: longitude}" [options]="{ icon: { url: markerIcon } , draggable : true }"
                    (mapDragend)="markerDragEnd($event)"></map-marker>
                  </google-map>
                  <div>
                    <h5>{{address}}</h5>
                  </div>
                </div>
              </div>
            }
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel [expanded]="true" (opened)="advancedSettingsOpenState = true" (closed)="advancedSettingsOpenState = false" class="panel-accordion">
          <mat-expansion-panel-header class="panel-accordion-header">
            <mat-panel-title class="panel-accordion-title">
              {{ 'SITE_MANAGMENT.ADVANCED_SET' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div [ngClass]="{'hide-panel' : !advancedSettingsOpenState}">
            @if (data && data.chosenBins && (data.properties.isAll || data.properties.bin_Site)) {
              <div class="days-between-container">
                <div class="sub-title">{{ 'TICKET_MANAGEMENT.SITE' | translate }}</div>
                <form [formGroup]="ElFormSites" class="neighborhoods-form">
                  <mat-form-field class="neighborhoods-full-width">
                    <mat-select formControlName="binSite" class="select-option" (selectionChange)="changeSite($event)">
                      @for (site of binSitesToDisplay; track site) {
                        <mat-option rtlDiv [value]="site.site_id" class="option-dropDown">
                          <span rtlDiv>{{ site.site_name }}</span>
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            }
            @if (data && data.chosenBins && (data.properties.isAll || data.properties.bin_Type)) {
              <div class="days-between-container">
                <div class="sub-title">{{ 'SITE_MANAGMENT.SELECT_TYPE' | translate }}</div>
                <form [formGroup]="ElFormTypes" class="neighborhoods-form">
                  <mat-form-field class="neighborhoods-full-width">
                    <mat-select formControlName="binType" class="select-option" (selectionChange)="changeType($event)">
                      @for (type of binTypesToDisplay; track type) {
                        <mat-option rtlDiv [value]="type.site_bin_type_id" class="option-dropDown list-item">
                          <span rtlDiv>{{ type.bin_type_name }}</span>
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            }
            @if (data.properties.isAll) {
              <div class="days-between-container flex-between">
                @if (usageType == 1) {
                  <div>
                    <div class="sub-title">{{ 'MANAGE_BIN.MAX_DAYS' | translate }}</div>
                    <div class="days-between-input">
                      @if (data) {
                        <input type="number" id="max-days" [ngClass]="{'disable-input-number': PartialManagement,'input-number': !PartialManagement}" [(ngModel)]="maxDaysValue" min="0" [disabled]="PartialManagement"/>
                      }
                      @if (isAdmin) {
                        <div class="arrows-container">
                          <div class="up-container">
                            <div class='up-arrow' (click)="incrementMaxCollection()"></div>
                          </div>
                          <div class="down-container">
                            <div class='down-arrow' (click)="decrementMaxCollection()"></div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }
                <div>
                  @if (usageType == 1) {
                    <div class="sub-title">{{ 'SITE_MANAGMENT.BIN_MAX_CAP_L' | translate }}</div>
                  }
                  @if (usageType != 1) {
                    <div class="sub-title">{{ 'SITE_MANAGMENT.TANK_MAX_CAP_L' | translate }}</div>
                  }
                  <div class="days-between-input">
                    @if (data) {
                      <input id="max-cap" type="number" class="disable-input-number" [(ngModel)]="maxCapValue" min="0" [disabled]="true"/>
                    }
                    <!-- <div class="arrows-container" *ngIf="isAdmin">
                    <div class="up-container">
                      <div class='up-arrow' (click)="incrementMaxCap()"></div>
                    </div>
                    <div class="down-container">
                      <div class='down-arrow' (click)="decrementMaxCap()"></div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          }
          @if (data.properties.isAll) {
            <div class="bin-name-container">
              <div class="sub-title">{{ 'SITE_MANAGMENT.CLUSTER' | translate }}</div>
              <div class="bin-name-input truck-name-field cluster-opt">
                <mat-form-field>
                  <mat-select [disabled]="PartialManagement" panelClass="clustersPane" (openedChange)="openedChange($event)" #singleSelect placeholder="" (valueChange)="changeValue($event)" [(value)]="selectedCluster">
                    <ngx-mat-select-search #searchngx
                      placeholderLabel="{{translationsObj ? translationsObj.OTHER.SEARCH : ''}}"
                      [formControl]="clusterFilterCtrl"
                      noEntriesFoundLabel="{{translationsObj ? translationsObj.TITLES.NO_RES : ''}}">
                    </ngx-mat-select-search>
                    <div class="clusters-container">
                      @for (item of filteredList | async; track item) {
                        <mat-option [value]="item.cluster_id">
                          @for (bin of item.cluster_bins; track bin) {
                            <div rtlDiv>
                              @if (usageType == 1 && item.cluster_id != -1) {
                                <b>{{ 'SITE_MANAGMENT.BIN_NAME' | translate }}: </b>
                              }
                              @if (usageType != 1 && item.cluster_id != -1) {
                                <b>{{ 'SITE_MANAGMENT.TANK_NAME' | translate }}: </b>
                              }
                              {{bin.bin_name}}@if (item.cluster_id != -1) {
                              <span>,</span>
                            }
                            @if (usageType == 1 && item.cluster_id != -1) {
                              <b>{{ 'SITE_MANAGMENT.BIN_ADDRESS' | translate }}: </b>
                            }
                            @if (usageType != 1 && item.cluster_id != -1) {
                              <b>{{ 'SITE_MANAGMENT.TANK_ADDRESS' | translate }}: </b>
                            }
                            {{item.cluster_address}}
                          </div>
                        }
                      </mat-option>
                    }
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        }
        @if (data.properties.isAll) {
          <div class="threshold-level-container">
            <div class="threshold-sub-title">{{ 'MANAGE_BIN.THRESHOLD_LEVEL' |
            translate }}</div>
            <div class=" threshold-sub-title">
              <nouislider rtlDiv #slider [connect]="true" [min]="0" [max]="100" [tooltips]="thresholdTooltips" [(ngModel)]="thresholdRange"
                (ngModelChange)="onThresholdChange($event)" [disabled]="PartialManagement">
              </nouislider>
              <div class="legend-container">
                <span class="left-legend">0%</span>
                <span class="right-legend">100%</span>
              </div>
            </div>
          </div>
        }
        @if (data.properties.isAll) {
          <div class="max-capacity-container">
            @if (usageType == 1) {
              <div class="sub-title">{{ 'MANAGE_BIN.MAX_CAPACITY' | translate }}</div>
            }
            @if (usageType == 2) {
              <div class="sub-title">{{ 'MANAGE_BIN.MIN_CAPACITY_D' | translate }}</div>
            }
            <div class="threshold-bar">
              <nouislider rtlDiv #maxslider [connect]="true" [min]="0" [max]="100" [tooltips]="maxTooltip" [(ngModel)]="maxRange"
              (ngModelChange)="onMaxChange($event)" [disabled]="PartialManagement"></nouislider>
              <div class="legend-container">
                <span class="left-legend">0%</span>
                <span class="right-legend">100%</span>
              </div>
            </div>
          </div>
        }
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-accordion [ngClass]="{'hide-panel' : !data.properties.isAll}">
    <mat-expansion-panel [expanded]="true" (opened)="workPlanOpenState = true" (closed)="workPlanOpenState = false" class="panel-accordion">
      <mat-expansion-panel-header class="panel-accordion-header">
        <mat-panel-title class="panel-accordion-title">
          {{ 'MANAGE_BIN.WORKPLAN' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div [ngClass]="{'hide-panel' : !workPlanOpenState}">
        @if (data.properties.isAll && usageType == 1) {
          <div class="restriction-container">
            <div class="sub-title">{{ 'MANAGE_BIN.BIN_RESTRICTION_TIMES' |
            translate }}</div>
            @if (!restrictionOpen) {
              <div class="restriction-input-closed" (click)="toggleRestriction()">
                <div class="restriction-input-container">
                  <div class="restriction-input-title">{{ 'MANAGE_BIN.BIN_RESTRICTION_CAPTION' |
                  translate }}</div>
                  <div class="chevron-down"></div>
                </div>
              </div>
            }
            @if (restrictionOpen) {
              <div class="restriction-input-open">
                <div class="title-container">
                  <div class="restriction-input-title" rtlDiv>{{ 'MANAGE_BIN.BIN_RESTRICTION_CAPTION' |
                  translate }}</div>
                  <div class="chevron-up" (click)="toggleRestriction()"></div>
                </div>
                @for (restriction of restrictions; track restriction; let i = $index) {
                  <div class="restriction-time-container">
                    <div class="dflex">
                      <mat-checkbox class="restriction-checkbox" [checked]="restrictions[i].checked" (change)="resetRestriction(i,$event)" rtlDiv> </mat-checkbox>
                      <div class="time-container" [ngClass]="{'disabled-restriction': !restrictions[i].checked}">
                        <div class="time-title">{{ 'MANAGE_BIN.START_TIME' |
                        translate }}</div>
                        <div class="flex-container" rtlDiv>
                          <div class='time-btn left' (click)="openDropDownList(restriction,'startTimeHour')">
                            {{selectedManageBinTime[i].startHour}}
                            <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                            <div [hidden]="!restriction.showHideDropdown.startTimeHour" class='time-dropdown-container'>
                              @for (item of manageBinTime[i].hours; track item) {
                                <div class="drop-down-list-item time-list" (click)="selectManageBinTime(item, 'startHour' , i)">
                                {{item}} </div>
                              }
                            </div>
                          </div>
                          <div class="colon">:</div>
                          <div class='time-btn right' (click)="openDropDownList(restriction, 'startTimeMinute')">
                            {{selectedManageBinTime[i].startminute}}
                            <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                            <div [hidden]="!restriction.showHideDropdown.startTimeMinute" class='time-dropdown-container'>
                              @for (item of manageBinTime[i].minutes; track item) {
                                <div class="drop-down-list-item time-list" (click)="selectManageBinTime(item, 'startminute', i)">
                                  {{item}}
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="arrow-container">
                        <div class="arrow " [ngClass]="{'transform-arrow': isRtl()}"></div>
                      </div>
                      <div class="time-container" [ngClass]="{'disabled-restriction': !restrictions[i].checked}">
                        <div class="time-title">{{ 'MANAGE_BIN.END_TIME' |
                          translate }}
                        </div>
                        <div class="flex-container" rtlDiv>
                          <div class='time-btn left' (click)="openDropDownList(restriction,'endTimeHour')">
                            {{selectedManageBinTime[i].endHour}}
                            <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                            <div [hidden]='!restrictions[i].showHideDropdown.endTimeHour' class='time-dropdown-container'>
                              @for (item of manageBinTime[i].hours; track item) {
                                <div class="drop-down-list-item time-list" (click)="selectManageBinTime(item, 'endHour', i)">
                                  {{item}}
                                </div>
                              }
                            </div>
                          </div>
                          <div class="colon">:</div>
                          <div class='time-btn right' (click)="openDropDownList(restriction,'endTimeMinute')">
                            {{selectedManageBinTime[i].endMinute}}
                            <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                            <div [hidden]='!restrictions[i].showHideDropdown.endTimeMinute' class='time-dropdown-container'>
                              @for (item of manageBinTime[i].minutes; track item) {
                                <div class="drop-down-list-item time-list" (click)="selectManageBinTime(item, 'endMinute', i)">
                                  {{item}}
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    @if (i == 0 && endTimeMustBeAfterStartTimeFirst) {
                      <div class="error-msg">{{ 'MANAGE_BIN.END_TIME_AFTER_START_TIME' | translate }}</div>
                    }
                    @if (i == 1 && endTimeMustBeAfterStartTimeSecond) {
                      <div class="error-msg">{{ 'MANAGE_BIN.END_TIME_AFTER_START_TIME' | translate }}</div>
                    }
                    @if (i == 2 && endTimeMustBeAfterStartTimeThird) {
                      <div class="error-msg">{{ 'MANAGE_BIN.END_TIME_AFTER_START_TIME' | translate }}</div>
                    }
                    @if (i == 1 && startTimeMustBeAfterEndTimeOfTheFirstOption) {
                      <div class="error-msg">{{ 'MANAGE_BIN.START_TIME_AFTER_END_TIME_OF_FIRST' | translate }}</div>
                    }
                    @if (i == 2 && startTimeMustBeAfterEndTimeOfTheSecondOption) {
                      <div class="error-msg">{{ 'MANAGE_BIN.START_TIME_AFTER_END_TIME_OF_SECOND' | translate }}</div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
        @if (data.properties.isAll) {
          <div class="days-between-container">
            <div class="sub-title"></div>
            <div class="dflex">
              <label class="container-extra" rtlDiv>
                {{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}
                <input id="bin-included" [(ngModel)]="isWorkplanIncluded" [checked]="isWorkplanIncluded" type="checkbox" (change)="toggleEditableInclude(isWorkplanIncluded)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
            </div>
          </div>
        }
        @if (!isWorkplanIncluded && data.properties.isAll) {
          <div class="days-between-container">
            <div class="neighborhoods-form">
              <mat-form-field class="neighborhoods-full-width" [floatLabel]="options.value.floatLabel">
                <mat-label class="trigger-txt" rtlDiv>{{ 'MANAGE_BIN.CHOOSE_REASON' | translate }}</mat-label>
                <mat-select placeholder="" (valueChange)="changeReasonType($event)" [(value)]="selectedReasonType" class="mat-options">
                  @for (item of reasonTypes; track item) {
                    <mat-option [value]="item['removedBinReason_id']" class="neighborhood-item" rtlDiv>
                      <span>{{ currentLang == 'iw' ? item['removedBinReason_name_hebrew'] : item['removedBinReason_name'] }}</span>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        }
        @if (data.properties.isAll) {
          <div class="days-between-container">
            @if (usageType == 1) {
              <div class="sub-title">{{ 'MANAGE_BIN.BIN_COLLECTION_DAYS' | translate }}</div>
            }
            @if (usageType == 2) {
              <div class="sub-title">{{ 'MANAGE_BIN.TANK_FILLING_DAYS' | translate }}</div>
            }
            <div class="dflex">
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.SUN' | translate }}
                <input id="sun-chx" [(ngModel)]="isSundayChecked" [checked]="isSundayChecked" type="checkbox" (change)="toggleEditableSunday(isSundayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.MON' | translate }}
                <input id="mon-chx" [(ngModel)]="isMondayChecked" [checked]="isMondayChecked" type="checkbox" (change)="toggleEditableMonday(isMondayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.TUE' | translate }}
                <input id="tue-chx" [(ngModel)]="isTuesdayChecked" [checked]="isTuesdayChecked" type="checkbox" (change)="toggleEditableTuesday(isTuesdayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.WED' | translate }}
                <input id="wed-chx" [(ngModel)]="isWednesdayChecked" [checked]="isWednesdayChecked" type="checkbox" (change)="toggleEditableWednesday(isWednesdayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.THU' | translate }}
                <input id="thu-chx" [(ngModel)]="isThursdayChecked" [checked]="isThursdayChecked" type="checkbox" (change)="toggleEditableThursday(isThursdayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.FRI' | translate }}
                <input id="fri-chx" [(ngModel)]="isFridayChecked" [checked]="isFridayChecked" type="checkbox" (change)="toggleEditableFriday(isFridayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
              <label class="container-extra" rtlDiv>
                {{ 'NEW_WORKPLAN.SAT' | translate }}
                <input id="sat-chx" [(ngModel)]="isSaturdayChecked" [checked]="isSaturdayChecked" type="checkbox" (change)="toggleEditableSaturday(isSaturdayChecked)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
            </div>
          </div>
        }
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div class='mobile-main-btns-wrapper' rtlDiv>
  <div class='btns-container' rtlDiv>
    <button class="mobile-cancel_btn" (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' | translate }}</button>
    <button (click)='saveWorkplan()' [ngClass]="{'mobile-not-apply-btn' : (!isWorkplanIncluded && (!reasonType && !selectedReasonType)) || binAddress == '' || submitClicked || !ElForm.valid || !ElLocationForm.valid || !ElFormSites.valid || !ElFormTypes.valid || binExists || selectedCluster == '' , 'mobile-apply-btn' : ((!isWorkplanIncluded && (reasonType || selectedReasonType)) || isWorkplanIncluded) && binAddress != '' && !submitClicked && ElForm.valid && ElLocationForm.valid && ElFormSites.valid && ElFormTypes.valid && !binExists && selectedCluster != ''}">{{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}</button>
  </div>
</div>
</div>
</div>
}
import { Component, Input, ViewChild } from '@angular/core';
import { TableUtil } from '../../../tableUtil';
import _ from 'lodash';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { TranslatorService } from '../../../services/translator_service';
import { Papa } from 'ngx-papaparse';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from '../../pagination/pagination.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-exception-list',
  standalone: true,
  imports: [TranslateModule,MatCardModule,RTLDivDirectiveDirective,MatDividerModule,MatTableModule,MatSortModule,CommonModule,
    PaginationComponent,RouterModule],
  templateUrl: './exception-list.component.html',
  styleUrl: './exception-list.component.scss'
})
export class ExceptionListComponent {
  @Input() datesDiff;
  datesDiffTxt: string;
  translationsObj;
  currentLang;
  translate;
  spinnerActive: boolean = true;
  allExceptions: Array<object> = [];
  exceptionsData;
  dataTableLength = 0;
  usageType = 1;
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  tempFilter = "";
  startIndexTableData = 0;
  endIndexTableData = 6;
  displayedColumns = ["binName","siteName","address","time","name","information"];
  filteredData: Array<object> = [];

  constructor(private translator: TranslatorService,private apiQuery: ApiQuery,private papa: Papa) {
    this.translate = this.translator;
    this.translator.currentLangEmitter$.subscribe(async (value) => {
      this.translationsObj = await this.translator.getTranslation(value).toPromise();
      this.currentLang = value;
      if (this.currentLang != "iw") {
        this.datesDiffTxt = `Exception List`;
      } else {
        this.datesDiffTxt = `רשימת חריגים`;
      }
    });
  }

  ngOnInit() {
    this.spinnerActive = true;
    this.apiQuery.user$.subscribe((user) => {
      if (!user) return;
      if (user["usage_type_id"] != 1) {
        this.usageType = user["usage_type_id"];
      }
      this.apiQuery.exceptionsReports$.subscribe((data: any) => {
        let binsFiltered = [];
        if (this.currentLang != "iw") {
          this.datesDiffTxt = `Exception List`;
        } else {
          this.datesDiffTxt = `רשימת חריגים`;
        }
        this.datesDiffTxt += " " + this.datesDiff;
        this.spinnerActive = true;
        if (data.length == 0) {
          return;
        }
        this.spinnerActive = false;
        this.allExceptions = [];
        _.each(data, (site) => {
          if (site["user_message_data"] != null && site["user_message_data"]["length"] > 0) {
            _.each(site["user_message_data"], (data) => {
              if (data["message_data"] != null && data["message_data"]["length"] > 0) {
                _.each(data["message_data"], (msg) => {
                  msg["site"] = site["site_name"];
                  msg["bin_name"] = msg["bin_name"];
                  msg["id"] = site["site_id"];
                  msg["name"] = data["message_name"];
                  msg["name_hebrew"] = data["message_name_hebrew"];
                  msg["bin_address"] = msg["bin_address"];
                  this.allExceptions.push(msg);
                });
              }
            });
          }
        });

        if (this.usageType == 1) {
          binsFiltered = _.map(this.allExceptions, (b) => {
            return {
              bin_name: b["bin_name"],
              site_name: b["site"],
              bin_address: b["bin_address"],
              event_time: b["message_time"],
              event_name: b["name"],
              event_body: b["message_body"],
              bin_id: b["bin_id"],
              site_id: b["id"],
              event_name_he: b["name_hebrew"],
              event_body_he: b["message_body_hebrew"]
            };
          });
        } else {
          binsFiltered = _.map(this.allExceptions, (b) => {
            return {
              bin_name: b["bin_name"],
              bin_address: b["bin_address"],
              event_time: b["message_time"],
              event_name: b["name"],
              event_body: b["message_body"],
              bin_id: b["bin_id"],
              site_id: b["id"],
              event_name_he: b["name_hebrew"],
              event_body_he: b["message_body_hebrew"]
            };
          });
        }
        this.exceptionsData = binsFiltered;
        this.dataTableLength = this.exceptionsData.length;
        this.dataSource = new MatTableDataSource<any>(binsFiltered);
        this.dataSource.sort = this.sort;
        this.sortData({ direction: "desc", active: "bin_name" });
      });
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortData(sort: Sort) {
    const data = this.exceptionsData.slice();
    if (!sort.active || sort.direction === "") {
      this.exceptionsData = data;
      return;
    }
    this.exceptionsData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
    });
  }

  getTableData = () => {
    const tableData = [];
    const tempSearch = new MatTableDataSource<any>(this.exceptionsData);
    tempSearch.filter = this.tempFilter;
    this.filteredData = tempSearch.filteredData;
    for (let index = this.startIndexTableData;index <= this.endIndexTableData;index++) {
      if (tempSearch.filteredData[index]) {
        tableData.push(tempSearch.filteredData[index]);
      }
    }
    this.dataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);
    return this.dataSource;
  }

  applyFilter = (filterValue: any) => {
    filterValue = filterValue.value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.tempFilter = filterValue;
    this.dataSource.filter = filterValue;
  }

  onPageChange = (dataTableIndexes: any) => {
    this.startIndexTableData = dataTableIndexes.startIndex;
    this.endIndexTableData = dataTableIndexes.endIndex;
  }

  exportToCsv() {
    let dataToExport = null;
    dataToExport = _.cloneDeep(this.exceptionsData);
    _.each(dataToExport, (item) => {
      item.status += "%";
      if(this.currentLang == 'iw'){
				item.event_name = item.event_name_he;
        item.event_body = item.event_body_he;
			}
    });

    const header =
      this.usageType == 1
        ? {
            "0": this.translationsObj.SITE_MANAGMENT.BIN_NAME,
            "1": this.translationsObj.TRUCK_MAP.SITE,
            "2": this.translationsObj.LIST_COLUMNS.ADDRESS,
            "3": this.translationsObj.LIST_COLUMNS.EVENT_TIME,
            "4": this.translationsObj.LIST_COLUMNS.EVENT_NAME,
            "5": this.translationsObj.LIST_COLUMNS.EVENT_INFO,
          }
        : {
            "0": this.translationsObj.SITE_MANAGMENT.BIN_NAME,
            "1": this.translationsObj.LIST_COLUMNS.ADDRESS,
            "2": this.translationsObj.LIST_COLUMNS.EVENT_TIME,
            "3": this.translationsObj.LIST_COLUMNS.EVENT_NAME,
            "4": this.translationsObj.LIST_COLUMNS.EVENT_INFO,
          };

    const flatData = dataToExport.reduce((b, a) => {
      let res = _.slice(_.values(a), 0, Object.keys(header).length);
      b.push(res);
      return b;
    }, []);

    let source = {
      flatData,
      header,
    };

    let result = source.flatData.map((details) =>
      Object.keys(details).reduce(
        (a, b) => ((a[source.header[b]] = details[b]), a),
        {}
      )
    );
    if (this.currentLang != "iw") {
      TableUtil.exportArrayToExcel(result, "exceptions");
    } else {
      TableUtil.exportArrayToExcel(result, "חריגים");
    }
  }
}

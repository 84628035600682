@if (!isMobile) {
  <div class="workplanprogress">
    <mat-card>
      <div class='header-wrapper'>
        @if (usageType == 1) {
          <div class="title">{{ 'TITLES.BIN_EVENTS' | translate }}</div>
        }
        @if (usageType != 1) {
          <div class="title">{{ 'TITLES.TANK_EVENTS' | translate }}</div>
        }
        @if (showClear) {
          <div class="clear-all" (click)="clearAllBinEvents()" rtlDiv>
            {{ 'LIVE_INFO.CLEAR_ALL' | translate }}
          </div>
        }
      </div>
      <mat-divider class="top-divider"></mat-divider>
      <mat-card-content>
        @for (binEvent of binEvents; track binEvent; let i = $index) {
          <div class='bin-event-container'>
            <div class="flex-container">
              @if (currentLang != 'iw') {
                <div class='bin-event-text'>{{binEvent.event_body}}</div>
              }
              @if (currentLang == 'iw') {
                <div class='bin-event-text'>{{binEvent.event_body_hebrew}}</div>
              }
              <img class="bin-event-remove-btn" (click)="removeBinEvent(i)" src="assets/images/binInfoPage/remove_bin_event.svg"
                alt="remove_bin_event" rtlDiv/>
              </div>
              <div class="bin-event-date">{{binEvent.event_time | date : 'dd.MM.yy
              (EEEE)'}} , {{binEvent.event_time | date : 'shortTime'}}</div>
              <!-- <div class="bin-event-date" *ngIf="!moreThenTwoDaysAgo(binEvent)">{{relativeDay}}, {{binEvent.event_time | date
            : 'shortTime'}}</div> -->
          </div>
        }
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
    <mat-accordion class="example-headers-align mobile-card" multi>
      <mat-expansion-panel class="mat-expansion-panel-mobile">
        <mat-expansion-panel-header class="card-header mobile-header">
          <div class="my-panel mobile-flex">
            @if (usageType == 1) {
              <span class="mobile-title"> {{ 'TITLES.BIN_EVENTS' | translate }}</span>
            }
            @if (usageType != 1) {
              <span class="mobile-title"> {{ 'TITLES.TANK_EVENTS' | translate }}</span>
            }
          </div>
        </mat-expansion-panel-header>
        <mat-accordion class="card-body">
          @for (binEvent of binEvents; track binEvent; let i = $index) {
            <div class='mobile-bin-event-container' >
              <div class="mobile-text">
                @if (currentLang != 'iw') {
                  <div class='mobile-bin-event-text'>{{binEvent.event_body}}</div>
                }
                @if (currentLang == 'iw') {
                  <div class='mobile-bin-event-text'>{{binEvent.event_body_hebrew}}</div>
                }
                <div class="mobile-bin-event-date"><span class="mobile-bin-event-date-text">{{binEvent.event_time | date : 'dd.MM.yy'}}, {{binEvent.event_time | date : 'shortTime'}}</span></div>
              </div>
              <div class="mobile-close-container">
                <div class="mobile-close-frame">
                  <img class="mobile-close-img" (click)="removeBinEvent(i)" src="assets/images/binInfoPage/remove_bin_event.svg" alt="remove_bin_event" rtlDiv/>
                </div>
              </div>
              @if (binEvents.indexOf(binEvent) < binEvents.length-1) {
                <div class="mobile-devider"></div>
              }
            </div>
          }
        </mat-accordion>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
}
import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../../directives/rtldiv-directive.directive';

@Component({
  selector: 'app-cancel-swap-error-dialog',
  standalone: true,
  imports: [TranslateModule,MatDialogModule,RTLDivDirectiveDirective],
  templateUrl: './cancel-swap-error-dialog.component.html',
  styleUrl: './cancel-swap-error-dialog.component.scss'
})
export class CancelSwapErrorDialogComponent {
  constructor() { }

  ngOnInit() {
  }
}

@if (!isMobile) {
  <div class="padded dashboard">
    <div class="dashboard-header">
      <div class='dashboard-header-left' rtlDiv>
        <!-- <div class='header-navigator' (click)='navigateTo(lastPage)'>{{ lastPageTitle | translate }}</div>
        <img class="header-arrow" [ngClass]="{'transform-arrow': isRtl()}" src="assets/images/binInfoPage/breadcrumbs_arrow.svg"
          alt="arrow" /> -->
          @if (bin && usageType == 1) {
            <div class='header-bold' rtlDiv>
              <span [ngClass]="{'cut-txt' : bin.bin_name.length > 30}" title="{{bin.bin_name}}">{{bin.bin_name}}</span>
              <span class='separator-bold'>&nbsp;|&nbsp;</span>{{'SITE_MANAGMENT.BIN_ID' | translate }} {{bin.bin_id}}
            </div>
          }
          @if (bin && usageType == 2) {
            <div class='header-bold' rtlDiv>
              <span [ngClass]="{'cut-txt' : bin.bin_name.length > 30}" title="{{bin.bin_name}}">{{bin.bin_name}}</span>
              <span class='separator-bold'>&nbsp;|&nbsp;</span>
              <span [ngClass]="{'cut-txt' : bin.bin_description.length > 30}" title="{{bin.bin_description}}">{{bin.bin_description}}</span>
              <span class='separator-bold'>&nbsp;|&nbsp;</span>
              <span> {{bin.plot_size}} {{'LIST_COLUMNS.DUNAM' | translate}}</span>
            </div>
          }
        </div>
      </div>
      <mat-divider class="top-divider"></mat-divider>
      @if (bin) {
        <div class='last-time-update-container'>
          <div class='last-time-update' rtlDiv>
            <img class="header-arrow refresh" src="assets/images/binInfoPage/last_time_update_icon.svg" alt="last_time_updated" />
            {{'LIVE_INFO.LIVE_LAST_UPDATE' | translate }} {{calcDiff(bin.Bin_live_Data.Transmission_data.last_transmission_time)}}
          </div>
        </div>
      }
      <div class="bin-info-container">
        <div class="first-row">
          <app-bin-live-status [binLiveInfo_Bin]="bin"></app-bin-live-status>
        </div>
        <div class="second-row">
          <app-bin-location-description [binLiveInfo_Bin]="bin" rtlDiv></app-bin-location-description>
          <app-bin-events rtlDiv [bin_id]="binId"></app-bin-events>
        </div>
        <div class="third-row">
          <app-bin-capacity [binLiveInfo_Bin]="bin"></app-bin-capacity>
        </div>
        <div class="forth-row">
          <app-bin-collection-history rtlDiv [binLiveInfo_Bin]="bin"></app-bin-collection-history>          
          @if (usageType == 1) {
            <app-bin-collection-pie [binLiveInfo_Bin]="bin" rtlDiv></app-bin-collection-pie>
          }
        </div>
        <div class="fifth-row">
          <app-bin-info [binLiveInfo_Bin]="bin" rtlDiv></app-bin-info>
        </div>
        <div class="sixth-row">
          <app-bin-tickets-list></app-bin-tickets-list>
        </div>
      </div>
    </div>
  }

  @if (isMobile) {
    <div>
      <div class="mobile-top-container" [hidden]="!landscape">
        <div class="mobile-binlive-header">
          @if (bin && usageType == 1) {
            <div class='mobile-header-right-title' rtlDiv>
              <span class="mobile-binName" [ngClass]="{'cut-txt' : bin.bin_name.length > 30}" title="{{bin.binName}}">{{bin.bin_name}} </span>
              <span class='mobile-saperate'> <img class="mobile-saperate-img" alt="" src="assets/images/binInfoPage/saperate.svg"> </span>
              <span class="mobile-binId"> {{'SITE_MANAGMENT.BIN_ID' | translate }} {{binId}} </span>
            </div>
          }
          @if (bin && usageType == 2) {
            <div class='mobile-header-right-title' rtlDiv>
              <span class="mobile-binName" [ngClass]="{'cut-txt' : bin.bin_name.length > 30}" title="{{bin.binName}}">{{bin.bin_name}} </span>
              <span class='mobile-saperate'>
                <img class="mobile-saperate-img" alt="" src="assets/images/binInfoPage/saperate.svg">
              </span>
              <span class="mobile-binName" [ngClass]="{'cut-txt' : bin.bin_description.length > 30}" title="{{bin.bin_description}}">{{bin.bin_description}}</span>
              <span class='mobile-saperate'>
                <img class="mobile-saperate-img" alt="" src="assets/images/binInfoPage/saperate.svg">
              </span>
              <span class="mobile-binName"> {{bin.plot_size}} {{'LIST_COLUMNS.DUNAM' | translate}}</span>
            </div>
          }
        </div>
        <div class="mobile-separate">
          <mat-divider class="mobile-line"></mat-divider>
        </div>
      </div>
      @if (bin) {
        <div class='mobile-last-time-update-container'>
          <div class='last-time-update' rtlDiv>
            <img class="header-arrow refresh" src="assets/images/binInfoPage/last_time_update_icon.svg" alt="last_time_updated" />
            {{'LIVE_INFO.LIVE_LAST_UPDATE' | translate }} {{calcDiff(bin.Bin_live_Data.Transmission_data.last_transmission_time)}}
          </div>
        </div>
      }
      <app-bin-live-status [binLiveInfo_Bin]="bin" [ngClass]="{'hide':!landscape,'show':landscape}" [orientation]="landscape"></app-bin-live-status>
      <app-bin-location-description [binLiveInfo_Bin]="bin" [ngClass]="{'hide':!landscape,'show':landscape}" [orientation]="landscape"></app-bin-location-description>
      <app-bin-capacity (rangeBtnEvent)="rangeBtnEventHander($event)" [binLiveInfo_Bin]="bin"></app-bin-capacity>
      @if (bin) {
        <app-bin-collection-history [ngClass]="{'hide':!landscape,'show':landscape}" [showEvents]="showEvents" [showLocation]="showLocation" [orientation]="landscape" [binLiveInfo_Bin]="bin"></app-bin-collection-history>
      }
      @if (usageType == 1) {
        <app-bin-collection-pie [binLiveInfo_Bin]="bin" [ngClass]="{'hide':!landscape,'show':landscape}" rtlDiv></app-bin-collection-pie>
      }
      <app-bin-info [binLiveInfo_Bin]="bin" [ngClass]="{'hide':!landscape,'show':landscape}" [orientation]="landscape"></app-bin-info>
      <app-bin-events [ngClass]="{'hide':!landscape,'show':landscape}" [orientation]="landscape" (showListEvent)="showEventHandler($event)" [showLocation]="showLocation" [bin_id]="binId"></app-bin-events>
    </div>
  }
import { Component } from '@angular/core';
import { ResponsiveService } from '../../services/responsive.service';
import { ApiQuery } from '../../web-services/api/api.query';
import { Router } from '@angular/router';
import { ApiService } from '../../web-services/api/api.service';
import { TranslatorService } from '../../services/translator_service';
import * as _ from 'lodash';

import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';

@Component({
  selector: 'app-driver-plans',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective],
  templateUrl: './driver-plans.component.html',
  styleUrl: './driver-plans.component.scss'
})
export class DriverPlansComponent {
  sitesListFilter: any[] = [];
	isMobile: boolean;
	currentLang;
	translationsObj;
	user_role_id = 0;

  constructor(private apiService:ApiService,private apiQuery: ApiQuery,private responsiveService: ResponsiveService,
    private translator: TranslatorService,private router: Router) {
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
  }

  ngOnInit() {
    this.onResize();
		this.responsiveService.checkWidth();
    this.apiService.getSitesList(null);

    this.apiQuery.user$.subscribe(user => {
      if (!user) {
        return;
      }		      
      this.user_role_id = user["user_role_id"];
      this.apiQuery.filteredDailyWorkPlan$.subscribe(workPlans => {	
        if(workPlans.length == 0)return;			
        let plan = [];
        let vehicles = [];
        _.each(workPlans, (item) => {
            plan.push(item);			
        });
        plan.sort(function(a, b){
          if(a.site_name < b.site_name) { return -1; }
          if(a.site_name > b.site_name) { return 1; }
          return 0;
        });
        _.each(plan, (item) => {
          let bins_left_to_collect = 0;
          _.each(item.Vehicle_info, (vehicle) => {
            vehicle["site_name"] = item.site_name;
            vehicle["site_id"] = item.site_id;
            _.each(vehicle.Bins_collection_info, (bin) => {
              if(bin.is_collected == 0){
                bins_left_to_collect ++;
              }
            });
            vehicle["bins_left_to_collect"] = bins_left_to_collect;
            vehicles.push(vehicle);
          });
        });
        this.sitesListFilter = vehicles;
        if(this.user_role_id == 7){
          if(this.sitesListFilter && this.sitesListFilter.length == 1){
            this.router.navigate(['/driverplans/'+this.sitesListFilter[0].site_id+'/'+this.sitesListFilter[0].tablet_id]);
          }else{
            this.router.navigate(['/driverplans']);
          }
        }
      });
    });   
  }

    
	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  goToPlan(site){
    this.router.navigate([`/driverplans/${site.site_id}/${site.tablet_id}`]);
  }
}

@if (!isMobile) {
  <div class="bin-collection-history">
    <mat-card>
      <mat-card-header>
        @if (usageType == 1) {
          <mat-card-title class="title">{{ 'TITLES.COLLECTION_HISTORY' | translate }}</mat-card-title>
        }
        @if (usageType == 2) {
          <mat-card-title class="title">{{ 'TITLES.FILLINGS_HISTORY' | translate }}</mat-card-title>
        }
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <mat-card-content>
        <div class='table-wrapper'>
          <div class='search-input-container'>
            @if (binInfo) {
              <div class='bin-info'>
                <span [ngClass]="{'cut-txt' : binInfo.bin_name.length > 30}" title="{{binInfo.bin_name}}"> {{binInfo.bin_name}} </span>
                @if (usageType == 1) {
                  <span>&nbsp;- {{ 'LIVE_INFO.BIN_ID' | translate }} {{binInfo.bin_id}}</span>
                }
                @if (usageType != 1) {
                  <span>&nbsp;- {{ 'LIVE_INFO.TANK_ID' | translate }} {{binInfo.bin_id}}</span>
                }
              </div>
            }
            <div class='table-export-container' rtlDiv>
              <div class='table-export-btns-wrapper' (click)="exportToCsv()">
                <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
              </div>
            </div>
          </div>
          <div class='table-container'>
            <table class="table" mat-table #table [dataSource]="getTableData()" matSort matSortStart="desc" matSortActive="collection_date"
              matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
              <!-- Checkbox Column -->
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" class="list-checkbox" rtlDiv>
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="collection_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="collection_date">
                  @if (usageType == 1) {
                    <span>{{'LIVE_INFO.LIST_COLLECTION_DATE' | translate }}</span>
                  }
                  @if (usageType == 2) {
                    <span>{{'LIVE_INFO.LIST_FILLING_DATE' | translate }}</span>
                  }
                </th>
                <td mat-cell *matCellDef="let element"> {{element ? element.collection_date : ''}} </td>
              </ng-container>
              <!-- id Column -->
              <ng-container matColumnDef="collection_time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="collection_time">
                  @if (usageType == 1) {
                    <span>{{'LIVE_INFO.LIST_COLLECTION_TIME' | translate }} </span>
                  }
                  @if (usageType == 2) {
                    <span>{{'LIVE_INFO.LIST_FILLING_TIME' | translate }} </span>
                  }
                </th>
                <td mat-cell *matCellDef="let element" class="td-value"> {{element ? element.collection_time : ''}} </td>
              </ng-container>>
              <!-- status Column -->
              <ng-container matColumnDef="capacity_at_pick_up">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="capacity_at_pick_up">
                  @if (usageType == 1) {
                    <span>{{'LIVE_INFO.LIST_PICKUP_CAPACITY' | translate }}</span>
                  }
                  @if (usageType == 2) {
                    <span>{{'FILLINGS.REFILL_AMOUNT' | translate }}</span>
                  }
                </th>
                <td mat-cell *matCellDef="let element">
                  @if (usageType == 1) {
                    <div class="table-status-progress-bar-wrapper" [ngStyle]="getProgressBarStyleWrapper(element)">
                      <div class="table-status-progress-line" [ngStyle]="getProgressBarStyleInternal(element)"></div>
                    </div>
                  }
                  <div class='table-status-progress-bar-title' rtlDiv>
                    <span>{{element ? element.capacity_at_pick_up: ''}}</span>
                    @if (usageType == 1) {
                      <span>%</span>
                    }
                    @if (usageType == 2 && currentLang == 'iw') {
                      <span> ל</span>
                    }
                    @if (usageType == 2 && currentLang != 'iw') {
                      <span> L</span>
                    }
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
            </table>
          </div>
          <div class='collection-data-wrapper'>
            @if (getLastCollection() != '') {
              <div class='last-time-collection-container'>
                <img class="info-icon" src="../assets/images/binInfoPage/alert.svg" rtlDiv />
                <span class="spacelang" rtlDiv>
                  @if (usageType == 1) {
                    <span>{{ 'LIVE_INFO.LAST_COLLECTION' | translate }}</span>
                  }
                  @if (usageType == 2) {
                    <span>{{ 'LIVE_INFO.LAST_FILLING' | translate }}</span>
                  }
                  <span class='collection-last-collection'>{{getLastCollection()}}</span>
                </span>
              </div>
            }
            <div class='total-collection-last-week-container'>
              <img class="info-icon" src="../assets/images/binInfoPage/alert.svg" rtlDiv />
              @if (usageType == 1) {
                <span class="spacelang" rtlDiv>{{ 'LIVE_INFO.TOTAL_COLLECTIONS' | translate }}
                  <span class='collection-last-week-count'>{{getCollectionLastWeek()}}</span>
                </span>
              }
              @if (usageType == 2) {
                <span class="spacelang" rtlDiv>{{ 'LIVE_INFO.TOTAL_FILLINGS' | translate }}
                  <span class='collection-last-week-count'>{{getCollectionLastWeek()}}</span>
                </span>
              }
            </div>
          </div>
          <div class="spaceUp" [hidden]="dataTableLength === 0">
            <app-pagination [chosenBins]="binCollectionData" (pageChange)='onPageChange($event)' [filesPerPage]="6"></app-pagination>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
    <div class="accordion md-accordion" id="binCollectionHistoryAc" role="tablist" aria-multiselectable="true">
      <div class="card mobile-card">
        <div class="card-header mobile-header" id="binCapacity">
          <div class="mobile-flex">
            @if (usageType == 1) {
              <span class="mobile-title"> {{ 'TITLES.COLLECTION_HISTORY' | translate }}</span>
            }
            @if (usageType == 2) {
              <span class="mobile-title"> {{ 'TITLES.FILLINGS_HISTORY' | translate }}</span>
            }
          </div>
        </div>
        <div class="collapse show" role="tabpanel" data-parent="#binCollectionHistoryAc">
          <div class="card-body">
            <div>
              <div class='mobile-search-container'>
                <div class="mobile-flex">
                  @if (binInfo) {
                    <div class='mobile-bin-info'><span [ngClass]="{'cut-txt' : binInfo.bin_name.length > 30}" title="{{binInfo.bin_name}}">{{binInfo.bin_name}}</span>
                    @if (usageType == 1) {
                      <span>&nbsp;- {{ 'LIVE_INFO.BIN_ID' | translate }} {{binInfo.bin_id}}</span>
                    }
                    @if (usageType != 1) {
                      <span>&nbsp;- {{ 'LIVE_INFO.TANK_ID' | translate }} {{binInfo.bin_id}}</span>
                    }
                  </div>
                }
                <div class='table-export-container' rtlDiv>
                  <div class='table-export-btns-wrapper' (click)="exportToCsv()">
                    <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                    <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class='mobile-table-wrapper'>
              <table mat-table #table [dataSource]="getTableData()" matSort matSortStart="desc" matSortActive="collection_date" class="mobile-table"
                matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc" rtlDiv>
                <ng-container matColumnDef="collection_date">
                  <th class="mobile-table-th" mat-header-cell *matHeaderCellDef mat-sort-header="collection_date">
                    @if (usageType == 1) {
                      <span>{{'LIVE_INFO.LIST_COLLECTION_DATE' | translate }}</span>
                    }
                    @if (usageType == 2) {
                      <span>{{'LIVE_INFO.LIST_FILLING_DATE' | translate }}</span>
                    }
                  </th>
                  <td class="mobile-value-td" mat-cell *matCellDef="let element"> {{element ? element.collection_date : ''}} </td>
                </ng-container>
                <ng-container matColumnDef="collection_time">
                  <th class="mobile-table-th" mat-header-cell *matHeaderCellDef mat-sort-header="collection_time">
                    @if (usageType == 1) {
                      <span>{{'LIVE_INFO.LIST_COLLECTION_TIME' | translate }} </span>
                    }
                    @if (usageType == 2) {
                      <span>{{'LIVE_INFO.LIST_FILLING_TIME' | translate }} </span>
                    }
                  </th>
                  <td class="mobile-value-td" mat-cell *matCellDef="let element"> {{element ? element.collection_time : ''}} </td>
                </ng-container>>
                <ng-container matColumnDef="capacity_at_pick_up">
                  <th class="mobile-table-th" mat-header-cell *matHeaderCellDef mat-sort-header="capacity_at_pick_up">
                    @if (usageType == 1) {
                      <span>{{'LIVE_INFO.LIST_PICKUP_CAPACITY' | translate }}</span>
                    }
                    @if (usageType == 2) {
                      <span>{{'FILLINGS.REFILL_AMOUNT' | translate }}</span>
                    }
                  </th>
                  <td class="mobile-status-value mobile-spacing-td" mat-cell *matCellDef="let element">
                    <div class='mobile-table-status-progress-bar-title' [ngStyle]="getProgressBarStyleInternalMobile(element)" rtlDiv>
                      <span>{{element ? element.capacity_at_pick_up: ''}}</span>
                      @if (usageType == 1) {
                        <span>%</span>
                      }
                      @if (usageType == 2 && currentLang == 'iw') {
                        <span> ל</span>
                      }
                      @if (usageType == 2 && currentLang != 'iw') {
                        <span> L</span>
                      }
                    </div>
                  </td>
                </ng-container>
                <tr style="display: flex;width: 100%" mat-header-row *matHeaderRowDef="mobiledisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: mobiledisplayedColumns;" (click)="selection.toggle(row)"></tr>
              </table>
              <div class='collection-data-wrapper mobile-wrapper' rtlDiv>
                <div class='mobile-last-time-collection-container'>
                  <img class="mobile-info-icon" src="../assets/images/binInfoPage/alert.svg" rtlDiv />
                  @if (usageType == 1) {
                    <span class="mobile-last-collection-txt">{{ 'LIVE_INFO.LAST_COLLECTION' | translate }}</span>
                  }
                  @if (usageType == 2) {
                    <span class="mobile-last-collection-txt">{{ 'LIVE_INFO.LAST_FILLING' | translate }}</span>
                  }
                  <span class='mobile-last-collection-txt'>{{getLastCollection()}}</span>
                </div>
                @if (usageType == 1) {
                  <div class='mobile-total-collection-last-week-container'>
                    <img class="mobile-info-icon" src="../assets/images/binInfoPage/alert.svg" rtlDiv />
                    <span class="mobile-last-collection-txt">{{ 'LIVE_INFO.TOTAL_COLLECTIONS' | translate }} </span> <span class='mobile-last-collection-txt'>{{getCollectionLastWeek()}}</span>
                  </div>
                }
                @if (usageType == 2) {
                  <div class='mobile-total-collection-last-week-container'>
                    <img class="mobile-info-icon" src="../assets/images/binInfoPage/alert.svg" rtlDiv />
                    <span class="mobile-last-collection-txt">{{ 'LIVE_INFO.TOTAL_FILLINGS' | translate }} </span> <span class='mobile-last-collection-txt'>{{getCollectionLastWeek()}}</span>
                  </div>
                }
              </div>
              <div [hidden]="dataTableLength === 0">
                <app-pagination [chosenBins]="binCollectionData" (pageChange)='onPageChange($event)' [filesPerPage]="6"></app-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
}
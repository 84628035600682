<ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [minDate]="{year: minYear, month: minMonth, day: minDay}" [(ngModel)]="date"  [footerTemplate]="footerTemplate" [dayTemplate]="t" [firstDayOfWeek]="firstDayOfWeek" (navigate)="date = $event.next"></ngb-datepicker>

<ng-template #t let-date="date">
  <span class="custom-day">
    {{ date.day }}
  </span>
</ng-template>

<ng-template #footerTemplate>
  <button class="btn btn-sm btn-outline-primary mr-2 todayBtn" (click)="selectToday()">{{ 'WORKPLAN.TODAY' | translate }}</button>
</ng-template>
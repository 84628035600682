<div>
  @switch (timeOutObj.installationPhase) {
    @case (installationPhase.PENDING) {
      <div>PENDING</div>
    }
    @case (installationPhase.PENDING_REVIEW) {
      <div>PENDING_REVIEW</div>
    }
    @case (installationPhase.REVIEW_APPROVED) {
      <div>REVIEW_APPROVED</div>
    }
    @case (installationPhase.PENDING_SWAP_ACTIVATION) {
      <div>PENDING_SWAP_ACTIVATION</div>
    }
    @case (installationPhase.PENDING_ACTIVATION) {
      <div>
        <h5 mat-dialog-title class="text-center">{{ 'SITE_MANAGMENT.ACTIVATION_OUT' | translate }}</h5>
        <mat-dialog-content>
          <p class="text-center">{{ 'SITE_MANAGMENT.DEVICE_ISSUE' | translate }}</p>
        </mat-dialog-content>
        @if (timeOutObj.activationTimeOutAttempt == 1) {
          <div>
            <mat-dialog-actions>
              <button mat-button [ngClass]="{'right' : currentLang == 'iw','left': currentLang != 'iw'}" color="warn" mat-dialog-close>{{ 'SITE_MANAGMENT.CANCEL_ACTIVATION' | translate }}</button>
              <button mat-button [ngClass]="{'left' : currentLang == 'iw','right': currentLang != 'iw'}" [mat-dialog-close]="true" cdkFocusInitial>{{ 'SITE_MANAGMENT.CONTINUE_WAIT' | translate }}</button>
            </mat-dialog-actions>
          </div>
        } @else {
          <mat-dialog-actions align="end">
            <button mat-button color="warn" mat-dialog-close>{{ 'SITE_MANAGMENT.CANCEL_ACTIVATION' | translate }}</button>
          </mat-dialog-actions>
        }
      </div>
    }
    @case (installationPhase.NEW_PENDING_ACTIVATION) {
      <div>
        <h5 mat-dialog-title class="text-center">{{ 'SITE_MANAGMENT.DEVICE_ISSUE' | translate }}</h5>
        <mat-dialog-content>
          <p class="text-center">{{ 'SITE_MANAGMENT.INSERT_DEVICEID' | translate }}</p>
        </mat-dialog-content>
        <form [formGroup]="ElForm">
          <div class="input-container">
            <input formControlName="deviceId" type="text" name="deviceId" />
          </div>
        </form>
        <div>
          <mat-dialog-actions>
            <button mat-button [ngClass]="{'right' : currentLang == 'iw','left': currentLang != 'iw'}" [mat-dialog-close]="false" color="warn" mat-dialog-close>{{ 'SITE_MANAGMENT.CANCEL_ACTIVATION' | translate }}</button>
            <button mat-button [ngClass]="{'left' : currentLang == 'iw','right': currentLang != 'iw'}" [mat-dialog-close]="ElForm.controls.deviceId.value" cdkFocusInitial>{{ 'SITE_MANAGMENT.CONTINUE' | translate }}</button>
          </mat-dialog-actions>
        </div>
      </div>
    }
    <!-- <div *ngSwitchCase="installationPhase.ACTIVATED">ACTIVATED</div> -->
    @case (installationPhase.PENDING_PHYSICAL_INSTALLATION) {
      <div>
        <h5 mat-dialog-title class="text-center">Transmission Time Out</h5>
        <mat-dialog-content>
          <p class="text-center">It looks like there is an issue with this device transmission</p>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-button color="warn" mat-dialog-close>Cancel Installation</button>
        </mat-dialog-actions>
      </div>
    }
    <!-- <div *ngSwitchCase="installationPhase.PHYSICALLY_INSTALLED">PHYSICALLY_INSTALLED</div> -->
    <!-- <div *ngSwitchCase="installationPhase.INSTALLATION_COMPLETE">INSTALLATION_COMPLETE</div> -->
    @default {
      <div>Default</div>
    }
  }
</div>




import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { Observable, ReplaySubject, Subject, map, startWith } from 'rxjs';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiStore } from '../../../web-services/api/api.store';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiService } from '../../../web-services/api/api.service';
import moment from 'moment';
import _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import wNumb from 'wnumb';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NouisliderModule } from 'ng2-nouislider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GoogleMapsModule } from '@angular/google-maps';
import { Loader } from '@googlemaps/js-api-loader';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";

interface Coordinates {
  address: string;
  latitude: number;
  longitude: number;
}
@Component({
  selector: 'app-manage-bin-modal',
  standalone: true,
  imports: [TranslateModule,RTLDivDirectiveDirective,CommonModule,FormsModule,ReactiveFormsModule,NgbModule,
    MatCardModule,MatExpansionModule,MatFormFieldModule,MatInputModule,MatAutocompleteModule,MatSelectModule,
    NgxMatSelectSearchModule,NouisliderModule,MatCheckboxModule,GoogleMapsModule,NgxGpAutocompleteModule
  ],
  providers: [
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyB0UcoR0JNhlJkETb9LkDYfnjoiTW77GTc',
        libraries: ['places']
      })
    }
  ],
  templateUrl: './manage-bin-modal.component.html',
  styleUrl: './manage-bin-modal.component.scss'
})
export class ManageBinModalComponent {
  @ViewChild('slider', { static: false, read: ElementRef }) set slider(s: ElementRef) {
    if (s) {      
      const origin = s.nativeElement.querySelectorAll('.noUi-origin');
      if(origin.length > 0){
        origin[0].setAttribute('disabled', true);
        origin[0].style.display = 'none';
        origin[3].setAttribute('disabled', true);
        origin[3].style.display = 'none';
      }

      const connect = s.nativeElement.querySelectorAll('.noUi-connect');
      const classes = this.usageType == 1 ? ['c-1-color', 'c-2-color', 'c-3-color'] : ['c-3-color', 'c-2-color', 'c-1-color'];
      for (let i = 0; i < connect.length; i++) {
        connect[i].classList.add(classes[i]);
      }
    }
  }

  @ViewChild('maxslider', { static: false, read: ElementRef }) set maxslider(m: ElementRef) {
    if (m) {
      const maxOrigin = m.nativeElement.querySelectorAll('.noUi-origin');
      if(maxOrigin.length > 0){
        maxOrigin[0].setAttribute('disabled', true);
        maxOrigin[0].style.display = 'none';
      }

      const connect = m.nativeElement.querySelectorAll('.noUi-connect');
      if(connect.length > 0){
        connect[0].classList.add('max-color');
      }
    }
  }

  @ViewChild('searchngx', {static: false, read: ElementRef }) searchngx: ElementRef;
  ElLocationForm: FormGroup;
  latitude: number;
  longitude: number;
  statusColor: string;
  markerIcon: string = '../../../../assets/images/bins/all/greenNotOrdered.svg';
  greenMarkerIcon : string = '../../../../assets/images/bins/all/greenNotOrdered.svg';
  yellowMarkerIcon : string = '../../../../assets/images/bins/all/yellowNotOrdered.svg';
  redMarkerIcon : string = '../../../../assets/images/bins/all/redNotOrdered.svg';
  greenMarkerIcon2 : string = '../../../../assets/images/bins/all/greenNotOrdered2.svg';
  yellowMarkerIcon2 : string = '../../../../assets/images/bins/all/yellowNotOrdered2.svg';
  redMarkerIcon2 : string = '../../../../assets/images/bins/all/redNotOrdered2.svg';
  chosenAddress: any;
  address: string;
  map: any = null;
  private geoCoder;
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
  approveAdd : boolean = false;
  binName: string;
  binAddress: string;
  binLocation: string
  binDesc: string;
  nameFlag: boolean;
  binExists:boolean;
  currentLang;
  restriction_1_start_time: any;
  center: google.maps.LatLngLiteral = {lat: 0, lng: 0};
  mapOptions: google.maps.MapOptions = {
    streetViewControl : false,
  };
  maxCapacity: number;
  maxDaysValue: any;
  maxCapValue:any;
  restrictionOpen: boolean = false;
  restrictions: any[] = [
    {
      showHideDropdown: {
        startTimeHour: false,
        startTimeMinute: false,
        endTimeHour: false,
        endTimeMinute: false,
        checked: false
      }
    },
    {
      showHideDropdown: {
        startTimeHour: false,
        startTimeMinute: false,
        endTimeHour: false,
        endTimeMinute: false,
        checked: false
      }
    },
    {
      showHideDropdown: {
        startTimeHour: false,
        startTimeMinute: false,
        endTimeHour: false,
        endTimeMinute: false,
        checked: false
      }
    },

  ];
  
  thresholdRange: [number, number, number, number];
  maxRange: [number, number];
  thresholdConnect: [boolean, boolean, boolean, boolean];
  maxConnect: [boolean, boolean];

  thresholdTooltips: [any, any, any, any];
  maxTooltip: [any, any];

  greenThresholdValue: number;
  yellowThresholdValue: number;
  redThresholdValue: number;
  binLimitPercent: number;

  classes = ['c-1-color', 'c-2-color', 'c-3-color'];
  selectedManageBinTime = [
    {
      startHour: '08',
      startminute: '08',
      endHour: '08',
      endMinute: '00'
    },
    {
      startHour: '08',
      startminute: '00',
      endHour: '08',
      endMinute: '00'
    },
    {
      startHour: '08',
      startminute: '00',
      endHour: '08',
      endMinute: '00'
    }]
  showHideDropdownLists: any = {
    startTimeHour: false,
    startTimeMinute: false,
    endTimeHour: false,
    endTimeMinute: false,
  };
  manageBinTime = [
    {
      hours: [],
      minutes: []
    },
    {
      hours: [],
      minutes: []
    },
    {
      hours: [],
      minutes: []
    }
  ];
	usageType = 1;
  isWorkplanIncluded : boolean = false;
  isSundayChecked : boolean = false;
  isMondayChecked : boolean = false;
  isTuesdayChecked : boolean = false;
  isWednesdayChecked : boolean = false;
  isThursdayChecked : boolean = false;
  isFridayChecked : boolean = false;
  isSaturdayChecked : boolean = false;
  translate;
  translationsObj;
  neighborhoods : string[] = [];
  filteredItems: Observable<any[]>;
  showAddButton: boolean = false;
  prompt = '';
  ElForm: FormGroup;
  ElFormTypes: FormGroup;
  ElFormSites: FormGroup;
  selectedCluster : any = '';
  variables = [];
  coordinates: Coordinates;
  reasonTypes : Array<object> = [];
  reasonType : any;
  selectedReasonType: any;
  clusterFilterCtrl: FormControl = new FormControl();
  filteredList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelect', {static: false, read: MatSelect }) singleSelect: MatSelect;
  _onDestroy = new Subject<void>();
  newCluster:any = {
    cluster_id : -1,
    cluster_address : "",
    num_of_bins_in_cluster : 1,
    cluster_bins:[{
      bin_name : '',
    }]
  };
  binTypesToDisplay:any =[];
  binSitesToDisplay:any =[];
  endTimeMustBeAfterStartTimeFirst : boolean = false;
  endTimeMustBeAfterStartTimeSecond : boolean = false;
  endTimeMustBeAfterStartTimeThird : boolean = false;
  startTimeMustBeAfterEndTimeOfTheFirstOption : boolean = false;
  startTimeMustBeAfterEndTimeOfTheSecondOption : boolean = false;
	PartialManagement: boolean = false;
  isMobile: boolean;
	isAdmin: boolean = false;
  locationSettingsOpenState: boolean = false;
  advancedSettingsOpenState: boolean = false;
  workPlanOpenState: boolean = false;
  binsSite = [];
  preMappingBins = [];
  siteChangedManually: boolean = false;
  submitClicked : boolean = false;
  options: FormGroup;

	constructor(private translator: TranslatorService,
    public fb: FormBuilder,private modalService: NgbModal,private responsiveService: ResponsiveService,
    private apiService:ApiService,private apiStore:ApiStore, private apiQuery:ApiQuery,
    @Inject(MAT_DIALOG_DATA) public data: any,private dialogRef: MatDialogRef<ManageBinModalComponent>) {
      this.dialogRef.disableClose = true;

      this.translate = this.translator;
      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
        this.currentLang = value;	
        this.newCluster["cluster_bins"][0]["bin_name"] = this.translationsObj.SITE_MANAGMENT.NEW_CLUSTER;
      }); 
      this.coordinates = {} as Coordinates;

      this.ElForm = this.fb.group({
        itemCtrl: '',
      });
      
      this.ElFormTypes = this.fb.group({
        binType: new FormControl('', Validators.required)
      });

      this.ElFormSites = this.fb.group({
        binSite: new FormControl('', Validators.required)
      });

      this.ElLocationForm = this.fb.group({
        lonLat: new FormControl('', Validators.required)
      });

      this.options = fb.group({
        floatLabel: 'never'
      });

      this.filteredItems = this.ElForm.controls.itemCtrl.valueChanges
        .pipe(
        startWith(''),
        map(item => item ? this.filterItems(item) : this.neighborhoods.slice())
      );
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.maxDaysValue = 7;
    this.maxCapValue = 2000;  
    this.submitClicked = false;
    this.siteChangedManually = false;
    if(this.data && this.data.chosenBins){
      if(this.data.properties){
        if(!this.data.properties.isAll){
          this.ElLocationForm.controls.lonLat.clearValidators();
          this.ElLocationForm.controls.lonLat.updateValueAndValidity();

          this.ElFormSites.controls.binSite.clearValidators();
          this.ElFormSites.controls.binSite.updateValueAndValidity();
        }
      }
      if(this.data.chosenBins.Bin_Restrictions && this.data.chosenBins.Bin_Restrictions.collection_mode == 2){
        this.isWorkplanIncluded = true;
      }else{
        this.isWorkplanIncluded = false;
      }
      this.apiService.getSiteClustersList(this.data.chosenBins.site_id);

      this.apiQuery.siteClustersList$.subscribe((siteClustersList) => {
        if (!siteClustersList) return;
        this.variables = siteClustersList.ClusterBySiteInfoObj[0].clusters_info;
        let check = this.variables.filter(obj => {
					return obj.cluster_id == -1;
				});     
        if(check.length == 0){
          this.variables.unshift(this.newCluster);
        }   
        if(!this.siteChangedManually){
          this.selectedCluster = Number(this.data.chosenBins.cluster_id);              
        }else{
          this.selectedCluster = '';
        }
        this.filteredList.next(this.variables.slice());
        this.clusterFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterClusters();
        });
      });
      this.apiService.GetBinsWeeklyCollectionSchedule(this.data.chosenBins.bin_id).subscribe((v:any) => {  	        		
        if(v.SitesWeeklyCollectionSchedule!=null){
          _.each(v.SitesWeeklyCollectionSchedule, (site) => {
            _.each(site.bins_weekly_collections_list, (bin) => {
              _.each(bin, (item) => {                
                item.sunday == 1 ? this.isSundayChecked = true : this.isSundayChecked = false;
                item.monday == 1 ? this.isMondayChecked = true : this.isMondayChecked = false;
                item.tuesday == 1 ? this.isTuesdayChecked = true : this.isTuesdayChecked = false;
                item.wednesday == 1 ? this.isWednesdayChecked = true : this.isWednesdayChecked = false;
                item.thursday == 1 ? this.isThursdayChecked = true : this.isThursdayChecked = false;
                item.friday == 1 ? this.isFridayChecked = true : this.isFridayChecked = false;
                item.saturday == 1 ? this.isSaturdayChecked = true : this.isSaturdayChecked = false;
              }); 
            });    
          });
        }else{
          this.isSundayChecked = false;
          this.isMondayChecked = false;
          this.isTuesdayChecked = false;
          this.isWednesdayChecked = false;
          this.isThursdayChecked = false;
          this.isFridayChecked = false;
          this.isSaturdayChecked = false;
        }			        
      });
      this.binLocation = this.data.chosenBins.Bin_location.bin_latitude.toFixed(8) + "," + this.data.chosenBins.Bin_location.bin_longitude.toFixed(8);
      this.binName = this.data.chosenBins.bin_name;
      this.binAddress = this.data.chosenBins.Bin_location.bin_address;
      this.binDesc = this.data.chosenBins.bin_description;
      this.nameFlag = false;
      this.binExists = false;
      this.maxDaysValue = Number(this.data.chosenBins.Bin_thresholds.max_days_between_collections);

      let BinsNeighborhoodList = {
        'site_id': this.data.chosenBins.site_id
      }

      this.apiService.getBinsNeighborhoodList(BinsNeighborhoodList).subscribe((v:any) => {	
        let ngh = [];
        _.each(v.NBRHoodList, (site) => {
          _.each(site.site_NBRH_list, (neighborhood) => {
            ngh.push(neighborhood.nbrh_name);
          });
        });
        this.neighborhoods = ngh;     
        if(this.neighborhoods.length > 0){
          this.ElForm.controls.itemCtrl.setValidators(this.setRequired());
          this.ElForm.controls.itemCtrl.updateValueAndValidity();
        }else{
          this.ElForm.controls.itemCtrl.clearValidators();
          this.ElForm.controls.itemCtrl.updateValueAndValidity();
        }   
        this.ElForm.controls["itemCtrl"].setValue(this.data.chosenBins.Bin_location.bin_neighbourhood);
     });

     this.apiService.GetSiteBinTypes({"site_id":this.data.chosenBins.site_id});
     this.apiQuery.binTypes$.subscribe((v:any) => {	   
        if(v.length == 0 || v.siteBinsTypeInfoObj == null) {return;}    
        this.binTypesToDisplay = v.siteBinsTypeInfoObj; 
        if(!this.siteChangedManually){
          this.ElFormTypes.controls.binType.setValue(this.data.chosenBins.bin_types_sites_id ? this.data.chosenBins.bin_types_sites_id : '');       
        }else{
          this.ElFormTypes.controls.binType.setValue('');
        }
        _.each(this.binTypesToDisplay, (binType) => {
          if(binType.site_bin_type_id == this.data.chosenBins.bin_types_sites_id){
            this.maxCapValue = binType.bin_max_capacity_in_liters;
          }
        });
     }); 

     this.apiQuery.sitesListData$.subscribe((sitesList) => {
			if(sitesList.length == 0) return;
			this.binSitesToDisplay = sitesList; 
      this.ElFormSites.controls.binSite.setValue(Number(this.data.chosenBins.site_id ? this.data.chosenBins.site_id : ''));       
     });

     
     this.apiQuery.reasonTypes$.subscribe((data:any) => {
      this.reasonTypes = data;      
      if(this.data.chosenBins.Bin_Restrictions.collection_mode == 1){
        if(this.data.chosenBins.Bin_Restrictions.removed_bin_reason_type_id){
          this.selectedReasonType = Number(this.data.chosenBins.Bin_Restrictions.removed_bin_reason_type_id);
        }
      }
     });
     this.ElLocationForm.controls["lonLat"].setValue("");   
    }else{
      this.binName = "";
      this.binAddress = "";
      this.binDesc = "";
      this.binLocation = "";
      setTimeout(() => {
        this.isWorkplanIncluded = true;
        this.isSundayChecked = true;
        this.isMondayChecked = true;
        this.isTuesdayChecked = true;
        this.isWednesdayChecked = true;
        this.isThursdayChecked = true;
        this.isFridayChecked = true;
        this.isSaturdayChecked = true;
      }, 0);
    }

    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}
			
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
      if ((user["user_role_id"] == 2) || (user["user_role_id"] == 1) || (user["user_role_id"] == 5) || (user["user_role_id"] == 6)) {
				this.isAdmin = true;
			}
      if (user["user_role_id"] == 4) {
				this.PartialManagement = true;
			}
      this.initializeColorPickers();
		});

    this.buildManageBinTime();
    this.getRestriction();
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  setRequired() {
		return [Validators.required];
	}
  
  changeType(option){
    _.each(this.binTypesToDisplay, (binType) => {
      if(binType.site_bin_type_id == option.value){
        this.maxCapValue = binType.bin_max_capacity_in_liters;
      }
    });
  }

  changeSite(option){
    this.siteChangedManually = true;
    this.apiService.GetSiteBinTypes({"site_id":option.value});
    this.apiService.getSiteClustersList(option.value);

    let BinsNeighborhoodList = {
      'site_id': option.value
    }

    this.apiService.getBinsNeighborhoodList(BinsNeighborhoodList).subscribe((v:any) => {	
      let ngh = [];
      _.each(v.NBRHoodList, (site) => {
        _.each(site.site_NBRH_list, (neighborhood) => {
          ngh.push(neighborhood.nbrh_name);
        });
      });
      this.neighborhoods = ngh;  
      if(this.neighborhoods.length > 0){
        this.ElForm.controls.itemCtrl.setValidators(this.setRequired());
        this.ElForm.controls.itemCtrl.updateValueAndValidity();
      }else{
        this.ElForm.controls.itemCtrl.clearValidators();
        this.ElForm.controls.itemCtrl.updateValueAndValidity();
      }         
   });
  }

  loadAPIWrapper(map: any) {
    this.map = map;
    this.addYourLocationButton(this.map);
    const lon = this.data && this.data.chosenBins ? this.binLocation.split(",")[1].trim() : 0.00000000;
    const lat = this.data && this.data.chosenBins ? this.binLocation.split(",")[0].trim() : 0.00000000;

    this.setCurrentLocation(lon,lat);
    this.statusColor = this.data && this.data.chosenBins ? this.data.chosenBins.Bin_live_Data.color_status_name : "GREEN"
    if(this.statusColor == 'RED'){
      this.usageType == 1 ? this.markerIcon = this.redMarkerIcon : this.markerIcon = this.redMarkerIcon2;
    }else if(this.statusColor == 'YELLOW'){
      this.usageType == 1 ? this.markerIcon = this.yellowMarkerIcon : this.markerIcon = this.yellowMarkerIcon2;
    }else{
      this.usageType == 1 ? this.markerIcon = this.greenMarkerIcon : this.markerIcon = this.greenMarkerIcon2;
    }
  }

  setCurrentLocation(lon,lat) {
    this.latitude = Number(lat);
    this.longitude = Number(lon);
    const lonLat = Number(this.latitude).toFixed(8) + "," + Number(this.longitude).toFixed(8);
    this.ElLocationForm.controls["lonLat"].setValue(lonLat);    
    this.getAddress(this.latitude, this.longitude);
  }

  markerDragEnd($event: any) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    const lonLat = this.latitude.toFixed(8) + "," + this.longitude.toFixed(8);
    this.ElLocationForm.controls["lonLat"].setValue(lonLat);   
    this.getAddress(this.latitude, this.longitude); 
  }

  handleAddressChange(place: google.maps.places.PlaceResult) {
    this.chosenAddress = place;
    this.changeLoc();
  }
  
  changeLoc(){
    if(this.ElLocationForm.getRawValue()['lonLat'].length == 0){
      return;
    }
    const lonLat = this.ElLocationForm.getRawValue()['lonLat'].split(",");
    if(isNaN(lonLat[0]) || isNaN(lonLat[1])){
      if(this.chosenAddress.geometry){
        this.latitude = Number(this.chosenAddress.geometry.location.lat().toFixed(8));
        this.longitude = Number(this.chosenAddress.geometry.location.lng().toFixed(8));
      }
    }else{
      this.latitude = Number(lonLat[0]);
      this.longitude = Number(lonLat[1]);
    }
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder = new google.maps.Geocoder;
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
        }
      }
    });
    this.center = {lat: latitude, lng: longitude};
  }

  openedChange(opened: boolean){
    if(opened){
      let a = this.searchngx.nativeElement;
      let b = a.parentNode;      
      b.style.marginTop = "0px";             
      b.style.maxHeight = "256px"+" "+"!important";
     }
  }

  filterClusters() {
    if (!this.variables) {
      return;
    }
    let search = this.clusterFilterCtrl.value;
    if (!search) {
      this.filteredList.next(this.variables.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredList.next(
      this.variables.filter(cluster => cluster.cluster_address.toLowerCase().indexOf(search) > -1 ||
      cluster.cluster_bins.some(item => item.bin_name.toLowerCase().indexOf(search) > -1))
    );
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  changeValue(value){
    this.selectedCluster = value;
  }

  changeReasonType(value){
    this.reasonType = value;    
  }

  isRtl = () => {
    return this.currentLang === 'iw';
  }

  initializeColorPickers() {
    if(this.data && this.data.chosenBins){
      if(this.usageType == 1){
        this.thresholdRange = [
          0,
          this.data.chosenBins.Bin_thresholds.green_status_fill_level_in_percent,
          this.data.chosenBins.Bin_thresholds.yellow_status_fill_level_in_percent,
          100
        ];
      }else{
        this.thresholdRange = [
          this.data.chosenBins.Bin_thresholds.red_status_fill_level_in_percent,
          this.data.chosenBins.Bin_thresholds.yellow_status_fill_level_in_percent,
          this.data.chosenBins.Bin_thresholds.green_status_fill_level_in_percent,
          100
        ];
      }
      if(this.usageType == 1){
        this.maxRange = [
          0,
          this.data.chosenBins.Bin_thresholds.bin_max_allowed_fill_level_in_percent
        ]; 
      }else{
        this.maxRange = [
          0,
          this.data.chosenBins.Bin_thresholds.bin_min_allowed_fill_level_in_percent
        ]; 
      }

    }else{
      this.thresholdRange = [
        0,
        45,
        75,
        100
      ];
      this.maxRange = [
        0,
        100
      ]; 
    }

    if(this.usageType == 1){
      this.greenThresholdValue = this.thresholdRange[1]; 
      this.yellowThresholdValue = this.thresholdRange[2];
      this.redThresholdValue = this.thresholdRange[3];
    }else{
      this.greenThresholdValue = this.thresholdRange[3]; 
      this.yellowThresholdValue = this.thresholdRange[2];
      this.redThresholdValue = this.thresholdRange[1];
    }

    this.binLimitPercent = this.maxRange[1];
    this.thresholdConnect = [true, true, true, true];
    this.maxConnect = [true, true];

    this.thresholdTooltips = [wNumb({ decimals: 0 }), wNumb({ decimals: 0 }), wNumb({ decimals: 0 }), wNumb({ decimals: 0 })];
    this.maxTooltip = [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })];

  }

  onThresholdChange(value) {
    if(this.usageType == 1){
      this.greenThresholdValue = Math.round(value[1]); 
      this.yellowThresholdValue = Math.round(value[2]);
      this.redThresholdValue = Math.round(value[3]);
    }else{
      this.greenThresholdValue = Math.round(value[2]); 
      this.yellowThresholdValue = Math.round(value[1]);
      this.redThresholdValue = 0;
    }

  }
  onMaxChange(value) {
    this.binLimitPercent = Math.round(value[1]);
  }

  private getRestriction = () => {
    if(this.data && this.data.chosenBins && this.data.chosenBins.Bin_Restrictions){
      this.selectedManageBinTime[0].startHour = this.data.chosenBins.Bin_Restrictions.restriction_1_start_time.substring(0, 2);
      this.selectedManageBinTime[0].startminute = this.data.chosenBins.Bin_Restrictions.restriction_1_start_time.substring(3, 5);
      this.selectedManageBinTime[1].startHour = this.data.chosenBins.Bin_Restrictions.restriction_2_start_time.substring(0, 2);
      this.selectedManageBinTime[1].startminute = this.data.chosenBins.Bin_Restrictions.restriction_2_start_time.substring(3, 5);
      this.selectedManageBinTime[2].startHour = this.data.chosenBins.Bin_Restrictions.restriction_3_start_time.substring(0, 2);
      this.selectedManageBinTime[2].startminute = this.data.chosenBins.Bin_Restrictions.restriction_3_start_time.substring(3, 5);
  
      const firstEndTime = moment(this.data.chosenBins.Bin_Restrictions.restriction_1_start_time, "HH:mm:ss").add(parseInt(this.data.chosenBins.Bin_Restrictions.restriction_1_duration),'minutes').format('HH:mm:ss');
      const secondEndTime = moment(this.data.chosenBins.Bin_Restrictions.restriction_2_start_time, "HH:mm:ss").add(parseInt(this.data.chosenBins.Bin_Restrictions.restriction_2_duration),'minutes').format('HH:mm:ss');
      const thirdEndTime = moment(this.data.chosenBins.Bin_Restrictions.restriction_3_start_time, "HH:mm:ss").add(parseInt(this.data.chosenBins.Bin_Restrictions.restriction_3_duration),'minutes').format('HH:mm:ss');
      
      this.selectedManageBinTime[0].endHour = firstEndTime.split(":")[0];
      this.selectedManageBinTime[1].endHour = secondEndTime.split(":")[0];
      this.selectedManageBinTime[2].endHour = thirdEndTime.split(":")[0];

      this.selectedManageBinTime[0].endMinute = firstEndTime.split(":")[1];
      this.selectedManageBinTime[1].endMinute = secondEndTime.split(":")[1];
      this.selectedManageBinTime[2].endMinute = thirdEndTime.split(":")[1];
    }
    this.markChecked();
  }

  private buildManageBinTime = () => {
    for(let j = 0; j < this.manageBinTime.length; j++){
      for (let i = 0; i <= 59; i++) {
        if (i >= 0 && i <= 9) {
          this.manageBinTime[j].hours.push(`0${i}`);
          this.manageBinTime[j].minutes.push(`0${i}`);
        } else {
          if (i < 24) {
            this.manageBinTime[j].hours.push(`${i}`);
          }
          this.manageBinTime[j].minutes.push(`${i}`);
        }
      }
    }
  }

  public openDropDownList = (restriction, list_name: string) => {
    _.each(restriction.showHideDropdown, (value, key) => {

      if (key === list_name) {
        restriction.showHideDropdown[list_name] = !value;
      } else {
        restriction.showHideDropdown[key] = false;
      }
    });

  }
  public selectManageBinTime = (time, type, index) => {    
    this.selectedManageBinTime[index][type] = time;
    this.validateRestrictions(index);
  }

  private validateRestrictions(index){
    let firstStartTime = this.selectedManageBinTime[0].startHour + ":" + this.selectedManageBinTime[0].startminute + ":00";
    let firstEndTime = this.selectedManageBinTime[0].endHour + ":" + this.selectedManageBinTime[0].endMinute + ":00";
    let secondStartTime = this.selectedManageBinTime[1].startHour + ":" + this.selectedManageBinTime[1].startminute + ":00";
    let secondEndTime = this.selectedManageBinTime[1].endHour + ":" + this.selectedManageBinTime[1].endMinute + ":00";
    let thirdStartTime = this.selectedManageBinTime[2].startHour + ":" + this.selectedManageBinTime[2].startminute + ":00";
    let thirdEndTime = this.selectedManageBinTime[2].endHour + ":" + this.selectedManageBinTime[2].endMinute + ":00";   
     
    if(index == 0){      
      if(this.restrictions[0].checked){
        if(!(moment(firstEndTime, "HH:mm:ss").isAfter(moment(firstStartTime, "HH:mm:ss")))){
          this.endTimeMustBeAfterStartTimeFirst = true;
        }else{
          this.endTimeMustBeAfterStartTimeFirst = false;
        }
        if(this.restrictions[1].checked){
          if(!(moment(secondStartTime, "HH:mm:ss").isAfter(moment(firstEndTime, "HH:mm:ss")))){
            this.startTimeMustBeAfterEndTimeOfTheFirstOption = true;
          }else{
            this.startTimeMustBeAfterEndTimeOfTheFirstOption = false;
          } 
        }     
      }else{
        if(this.restrictions[1].checked){
          this.startTimeMustBeAfterEndTimeOfTheFirstOption = false;
        }  
        this.endTimeMustBeAfterStartTimeFirst = false;
      } 

      if(this.restrictions[0].checked && this.restrictions[2].checked){
        if(!(moment(thirdStartTime, "HH:mm:ss").isAfter(moment(firstEndTime, "HH:mm:ss")))){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = true;
        }else{
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        } 
      }else{
        this.startTimeMustBeAfterEndTimeOfTheSecondOption = false
      }
    }else if(index == 1){
      if(this.restrictions[1].checked){
        if(!(moment(secondEndTime, "HH:mm:ss").isAfter(moment(secondStartTime, "HH:mm:ss")))){
          this.endTimeMustBeAfterStartTimeSecond = true;
        }else{
          this.endTimeMustBeAfterStartTimeSecond = false;
        } 
      }else{
        this.endTimeMustBeAfterStartTimeSecond = false;
      }
  
      if(this.restrictions[0].checked && this.restrictions[1].checked){
        if(!(moment(secondStartTime, "HH:mm:ss").isAfter(moment(firstEndTime, "HH:mm:ss")))){
          this.startTimeMustBeAfterEndTimeOfTheFirstOption = true;
        }else{
          this.startTimeMustBeAfterEndTimeOfTheFirstOption = false;
        } 
      }else{
        this.startTimeMustBeAfterEndTimeOfTheFirstOption = false;
      }

      if(this.restrictions[1].checked && this.restrictions[2].checked){
        if(!(moment(thirdStartTime, "HH:mm:ss").isAfter(moment(secondEndTime, "HH:mm:ss")))){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = true;
        }else{
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        } 
      }else{
        this.startTimeMustBeAfterEndTimeOfTheSecondOption = false
      }

      if(this.restrictions[0].checked && this.restrictions[2].checked){
        if(!(moment(thirdStartTime, "HH:mm:ss").isAfter(moment(firstEndTime, "HH:mm:ss")))){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = true;
        }else{
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        } 
      }else{
        if(!this.restrictions[1].checked){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        }  
      }
    }else if(index == 2){           
      if(this.restrictions[2].checked){        
        if(!(moment(thirdEndTime, "HH:mm:ss").isAfter(moment(thirdStartTime, "HH:mm:ss")))){
          this.endTimeMustBeAfterStartTimeThird = true;
        }else{
          this.endTimeMustBeAfterStartTimeThird = false;
        }
      }else{
        this.endTimeMustBeAfterStartTimeThird = false;
      }

      if(this.restrictions[1].checked && this.restrictions[2].checked){        
        if(!(moment(thirdStartTime, "HH:mm:ss").isAfter(moment(secondEndTime, "HH:mm:ss")))){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = true;
        }else{          
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        } 
      }else{
        if(!this.restrictions[0].checked){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        }        
      }

      if(this.restrictions[0].checked && this.restrictions[2].checked){
        if(!(moment(thirdStartTime, "HH:mm:ss").isAfter(moment(firstEndTime, "HH:mm:ss")))){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = true;
        }else{
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        } 
      }else{
        if(!this.restrictions[1].checked){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        }  
      }
    }
  }

  addYourLocationButton(map) {
		let controlDiv = document.createElement('div');
		let firstChild = document.createElement('button');
		firstChild.type = 'button';
		firstChild.style.backgroundColor = '#fff';
		firstChild.style.border = 'none';
		firstChild.style.outline = 'none';
		firstChild.style.width = '40px';
		firstChild.style.height = '40px';
		firstChild.style.borderRadius = '2px';
		firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
		firstChild.style.cursor = 'pointer';
		firstChild.style.marginRight = '10px';
		firstChild.style.padding = '0px';
		firstChild.title = 'Your Location';
		controlDiv.appendChild(firstChild);
		
		let secondChild = document.createElement('div');
		secondChild.style.margin = 'calc(50% - 18px / 2)';
		secondChild.style.width = '18px';
		secondChild.style.height = '18px';
		secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
		secondChild.style.backgroundSize = '180px 18px';
		secondChild.style.backgroundPosition = '0px 0px';
		secondChild.style.backgroundRepeat = 'no-repeat';
		secondChild.id = 'center-managebin';
		firstChild.appendChild(secondChild);
		
		google.maps.event.addListener(map, 'dragend', function() {
			document.getElementById('center-managebin').style.backgroundPosition = '0px 0px';
		});

		firstChild.addEventListener('click', function() {
			var imgX = '0';
			var animationInterval = setInterval(function(){
				if(imgX == '-18') imgX = '0';
				else imgX = '-18';
				document.getElementById('center-managebin').style.backgroundPosition = imgX+'px 0px';
			}, 500);
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition( pos => {        
				map.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude});
        map.setZoom(15);
				clearInterval(animationInterval);
				document.getElementById('center-managebin').style.backgroundPosition = '-144px 0px';
				}, error => {
				},{maximumAge:10000, timeout:5000, enableHighAccuracy: true});
			}else{
				clearInterval(animationInterval);
				document.getElementById('center-managebin').style.backgroundPosition = '0px 0px';
			}
		});
		map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
	}

  closeModal = () => {
    this.dialogRef.close();
  }

  saveWorkplan = () => {
    if(this.binAddress == '' || !this.ElForm.valid || !this.ElLocationForm.valid
      || !this.ElFormSites.valid || !this.ElFormTypes.valid || this.submitClicked
      || this.selectedCluster == ''){
      return;
    }

    if(this.endTimeMustBeAfterStartTimeFirst || this.endTimeMustBeAfterStartTimeSecond || this.endTimeMustBeAfterStartTimeThird ||
      this.startTimeMustBeAfterEndTimeOfTheFirstOption || this.startTimeMustBeAfterEndTimeOfTheSecondOption){
      return;
    }

    this.submitClicked = true;

    let binIDs = [];
    binIDs.push([Number(this.data.chosenBins.bin_id), this.selectedCluster]);

    if(this.data && this.data.chosenBins){
      if (this.binName.length == 0 || this.binName.length < 4) {
        this.nameFlag = true;
        return;
      }
    }

    if(this.binExists){
      return;
    }

    const firstStartTime = this.selectedManageBinTime[0].startHour + ":" + this.selectedManageBinTime[0].startminute + ":00";
    const secondStartTime = this.selectedManageBinTime[1].startHour + ":" + this.selectedManageBinTime[1].startminute + ":00";
    const thirdStartTime = this.selectedManageBinTime[2].startHour + ":" + this.selectedManageBinTime[2].startminute + ":00";

    const firstEndTime = this.selectedManageBinTime[0].endHour + ":" + this.selectedManageBinTime[0].endMinute + ":00";
    const secondEndTime = this.selectedManageBinTime[1].endHour + ":" + this.selectedManageBinTime[1].endMinute + ":00";
    const thirdEndTime = this.selectedManageBinTime[2].endHour + ":" + this.selectedManageBinTime[2].endMinute + ":00";

    const duration1 = parseInt((moment.utc(moment(firstEndTime, "HH:mm:ss").diff(moment(firstStartTime, "HH:mm:ss"))).format("HH mm")).split(" ")[0])*60 + parseInt((moment.utc(moment(firstEndTime, "HH:mm:ss").diff(moment(firstStartTime, "HH:mm:ss"))).format("HH mm")).split(" ")[1]);
    const duration2 = parseInt((moment.utc(moment(secondEndTime, "HH:mm:ss").diff(moment(secondStartTime, "HH:mm:ss"))).format("HH mm")).split(" ")[0])*60 + parseInt((moment.utc(moment(secondEndTime, "HH:mm:ss").diff(moment(secondStartTime, "HH:mm:ss"))).format("HH mm")).split(" ")[1]);
    const duration3 = parseInt((moment.utc(moment(thirdEndTime, "HH:mm:ss").diff(moment(thirdStartTime, "HH:mm:ss"))).format("HH mm")).split(" ")[0])*60 + parseInt((moment.utc(moment(thirdEndTime, "HH:mm:ss").diff(moment(thirdStartTime, "HH:mm:ss"))).format("HH mm")).split(" ")[1]);

    const dataToSend = {
      "bin_id_list": binIDs,
      "bin_name": this.binName,
      "address": this.binAddress,
      "bin_lon":this.longitude,
      "bin_lat":this.latitude,
      "bin_types_sites_id": this.ElFormTypes.getRawValue()["binType"] ? this.ElFormTypes.getRawValue()["binType"] : '',
      "new_site_id": this.ElFormSites.getRawValue()["binSite"] ? this.ElFormSites.getRawValue()["binSite"] : '',
      "bin_description": this.binDesc,
      "neighborhood": this.ElForm.getRawValue()["itemCtrl"] ? this.ElForm.getRawValue()["itemCtrl"] : '',
      "green_status_value": this.greenThresholdValue,
      "yellow_status_value": this.yellowThresholdValue,
      "red_status_value": this.redThresholdValue,
      "max_allowed_caps_in_percents": this.binLimitPercent,
      "bin_max_capacity_in_liters":this.maxCapValue,
      "bin_max_collections_days_gap": this.usageType == 1 ? this.maxDaysValue : 0,
      "restriction_1_duration": this.usageType == 1 ? duration1 : 0,
      "restriction_1_start_time": this.usageType == 1 ? firstStartTime : 0,
      "restriction_2_duration": this.usageType == 1 ? duration2 : 0,
      "restriction_2_start_time": this.usageType == 1 ? secondStartTime : 0,
      "restriction_3_duration": this.usageType == 1 ? duration3 : 0,
      "restriction_3_start_time": this.usageType == 1 ? thirdStartTime : 0,
      "collection_mode" : this.isWorkplanIncluded ? 2 : 1,
      "sunday": this.isSundayChecked ? 1 : 0,
      "monday": this.isMondayChecked ? 1 : 0,
      "tuesday": this.isTuesdayChecked ? 1 : 0,
      "wednesday": this.isWednesdayChecked ? 1 : 0,
      "thursday": this.isThursdayChecked ? 1 : 0,
      "friday": this.isFridayChecked ? 1 : 0,
      "saturday": this.isSaturdayChecked ? 1 : 0
    }  
    if(this.data.chosenBins.collection_days){
      if(this.data.chosenBins.collection_days[0].sunday != dataToSend["sunday"] ||
      this.data.chosenBins.collection_days[0].monday != dataToSend["monday"] ||
      this.data.chosenBins.collection_days[0].tuesday != dataToSend["tuesday"] ||
      this.data.chosenBins.collection_days[0].wednesday != dataToSend["wednesday"] ||
      this.data.chosenBins.collection_days[0].thursday != dataToSend["thursday"] ||
      this.data.chosenBins.collection_days[0].friday != dataToSend["friday"] ||
      this.data.chosenBins.collection_days[0].saturday != dataToSend["saturday"]){
        dataToSend["is_update_collection_days"] = 1;
      }
    }  

    if(!this.isWorkplanIncluded){
      if(this.reasonType != null){
        dataToSend["removed_bin_reason_types_id"] = this.reasonType; 
      }else if(this.selectedReasonType != null){
        dataToSend["removed_bin_reason_types_id"] = this.selectedReasonType; 
      }else{
        return;
      }
    }
    this.closeModal();
    this.apiService.setBinThresholdsAndRestrictions(dataToSend).subscribe((v:any) => {
      this.apiStore.update({ allFilteredBins: [] });                    
      this.apiStore.update({ filteredBins: [] });
      this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
      this.apiStore.update({ allFilteredBasicBins: [] });                    
      this.apiStore.update({ filteredBasicBins: [] }); 
      this.apiService.getfilteredBasicBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
    });
  }
  
  public incrementMaxCollection = () => {
    Number(this.maxDaysValue++);
  }
  public decrementMaxCollection = () => {
    if(this.maxDaysValue == 0){return;}
    Number(this.maxDaysValue--);
  }

  public incrementMaxCap = () => {
    Number(this.maxCapValue++);
  }
  public decrementMaxCap = () => {
    if(this.maxCapValue == 0){return;}
    Number(this.maxCapValue--);
  }

  applySearch = (val) => {
    if(!val){return;}
    val = val.value;
    if(val.includes(" ")){
      this.binName = '';
    }
    this.apiQuery.filteredBasicBins$.subscribe(bins => {	
      this.apiQuery.preMappingInfo$.subscribe(preMappingInfo => {
        if(bins.length == 0 || !bins){return;}      
        if(this.data.chosenBins.site_id == 28 || this.data.chosenBins.site_id == 44 || this.data.chosenBins.site_id == 58){
          this.binsSite = bins.filter(bin => bin["site_id"] == 28 || bin["site_id"] == 44 || bin["site_id"] == 58);     
        }else if(this.data.chosenBins.site_id == 62 || this.data.chosenBins.site_id == 63 || this.data.chosenBins.site_id == 69){            
          this.binsSite = bins.filter(bin => bin["site_id"] == 62 || bin["site_id"] == 63 || bin["site_id"] == 69);     
        }else{
          this.binsSite = bins.filter(bin => bin["site_id"] == this.data.chosenBins.site_id);   
          this.preMappingBins = preMappingInfo.filter(bin => bin["site_id"] == this.data.chosenBins.site_id);   
        }
        const binsFiltered = this.binsSite.filter(bin => bin.bin_name.toLowerCase() == val.toLowerCase());           
        const binsMappingFiltered = this.preMappingBins.filter(bin => bin.bin_letter.toLowerCase()+""+bin.bin_number == val.toLowerCase());           
        this.nameFlag = false;
        if(val == this.data.chosenBins.bin_name){
          this.binExists = false;         
        }else if(binsFiltered.length > 0 || binsMappingFiltered.length > 0){
          this.binExists = true;     
        }else{        
          this.binExists = false;         
        } 
      });       
    });
  }

  markChecked() {
    if(this.data.chosenBins && this.data.chosenBins.Bin_Restrictions){
      const firstDuration = this.data.chosenBins.Bin_Restrictions.restriction_1_duration;
      const secondDuration = this.data.chosenBins.Bin_Restrictions.restriction_2_duration;
      const thirdDuration = this.data.chosenBins.Bin_Restrictions.restriction_3_duration;
      if(firstDuration != 0){
        this.restrictions[0].checked = true;
      }else{
        this.restrictions[0].checked = false;
      }
      if(secondDuration != 0){
        this.restrictions[1].checked = true;
      }else{
        this.restrictions[1].checked = false;
      }
      if(thirdDuration != 0){
        this.restrictions[2].checked = true;
      }else{
        this.restrictions[2].checked = false;
      }
    }
  }

	resetRestriction(index,event) {    
    this.restrictions[index].checked = event.checked;
		if (!this.restrictions[index].checked) {
			this.selectedManageBinTime[index].startHour = "00";
			this.selectedManageBinTime[index].endHour = "00";
			this.selectedManageBinTime[index].startminute = "00";
			this.selectedManageBinTime[index].endMinute = "00";
		}
    this.validateRestrictions(index);    
	}

  public toggleRestriction = () => {
    if(this.PartialManagement){
      return;
    }
    this.restrictionOpen = !this.restrictionOpen;
  }

  toggleEditableInclude(value){
    this.isWorkplanIncluded = value;
  }

  toggleEditableSunday(value) {				
		this.isSundayChecked = value;
  }

  toggleEditableMonday(value){
    this.isMondayChecked = value;
  }

  toggleEditableTuesday(value){
    this.isTuesdayChecked = value;
  }

  toggleEditableWednesday(value){
    this.isWednesdayChecked = value;
  }

  toggleEditableThursday(value){
    this.isThursdayChecked = value;
  }

  toggleEditableFriday(value){
    this.isFridayChecked = value;
  }

  toggleEditableSaturday(value){
    this.isSaturdayChecked = value;
  }

  
  filterItems(name: string) {
    this.approveAdd = false;
    let results = this.neighborhoods.filter(item =>
      item.toString().toLowerCase().indexOf(name.toLowerCase()) === 0);
    this.showAddButton = results.length === 0;
    return results;
  }

  optionSelected(option) {
    if (option.value.indexOf(this.prompt) === 0) {
      this.addOption();
    }
  }

  addOption() {
    let option = this.removePromptFromOption(this.ElForm.getRawValue()["itemCtrl"]);
    if (!this.neighborhoods.some(entry => entry === option)) {
      const index = this.neighborhoods.push(option) - 1;
      this.ElForm.controls["itemCtrl"].setValue(this.neighborhoods[index]);
      this.approveAdd = true;
    }
  }

  removePromptFromOption(option) {
    if (option.startsWith(this.prompt)) {
      option = option.substring(this.prompt.length, option.length);
    }
    return option;
  }
}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  apiKey: 'AIzaSyB0UcoR0JNhlJkETb9LkDYfnjoiTW77GTc',
  apiVersion: 'quarterly',
  version: '3.40'
};

export const SERVER_REMOTE_URL : any = [
	{ name: 'dev', is_selected: true, url: 'https://api-dev.data-bins.com/ServerApi/v1' },
	{ name: 'prod', is_selected: false, url: 'https://api-prod.data-bins.com/ServerApi/v1' },
  { name: 'rc', is_selected: false, url: 'https://api-rc.data-bins.com/ServerApi/v1' }
];

export const JSREPORT_URL : any = [
	{ name: 'dev', is_selected: true, url: 'https://jsreport-dev.data-bins.com/api/report' },
	{ name: 'prod', is_selected: false, url: 'https://jsreport.data-bins.com/api/report' },
  { name: 'rc', is_selected: false, url: 'https://jsreport.data-bins.com/api/report' }
];

export const DRIVERLIST_URL = [
	{ name: 'dev', is_selected: true, url: 'http://driver-list-dev.data-bins.com/jsreport_redirect.php' },
	{ name: 'prod', is_selected: false, url: 'http://driver-list.data-bins.com/jsreport_redirect.php' },
  { name: 'rc', is_selected: false, url: 'http://driver-list.data-bins.com/jsreport_redirect.php' }
];


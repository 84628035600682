
const LOCAL_STORAGE_PREFIX = 'databin_dashboard_';


// export const RED_BIN_COLOR_THRESHOLD = '#FBEFED';
// export const YELLOW_BIN_COLOR_THRESHOLD = '#FCFCF1';
export const RED_BIN_COLOR_THRESHOLD = 'red';
export const YELLOW_BIN_COLOR_THRESHOLD = 'yellow';
export const REPORT_DISPLAY_DAYS = 14;
export const LOCAL_STORAGE_KEYS = {
	AUTH_TOKEN: LOCAL_STORAGE_PREFIX + 'auth_token',
	USER: LOCAL_STORAGE_PREFIX + 'user'
};
export const VERSION_NUMBER = 'Version 2.0.25';
import { Component, Inject } from '@angular/core';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslatorService } from '../../../services/translator_service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiStore } from '../../../web-services/api/api.store';
import { ApiService } from '../../../web-services/api/api.service';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-delete-user-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,CommonModule,MatFormFieldModule
    ,MatSelectModule,MatInputModule],
  templateUrl: './delete-user-modal.component.html',
  styleUrl: './delete-user-modal.component.scss'
})
export class DeleteUserModalComponent {
  currentLang;
	translationsObj;
  isMobile: Boolean;

  constructor(private responsiveService: ResponsiveService,private translator: TranslatorService,
    private dialogRef: MatDialogRef<DeleteUserModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
    private apiService:ApiService,private apiStore:ApiStore) {
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
   }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  applyChanges(){
    let request = {};
    request["user_id"] = this.data.chosenUsers[0].user_id; 
    request["is_delete"] = 1;

    this.apiService.updateUserParams(request).subscribe((v:any) => { 
      this.apiStore.update({ filteredUsers: [] });	
      this.apiService.getfilteredUsersInfo(JSON.parse(sessionStorage.getItem("chosenSites")));                 
      this.closeModal();  
    });
	}
  	
	closeModal(){
		this.dialogRef.close();
	}

}

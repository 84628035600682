
@if (!isMobile) {
  <div>
    <div class="dashboard-header">
      <div class='dashboard-header-left' rtlDiv>
        @if (usageType == 1) {
          <div class='header-bold'>{{ 'SIDEBAR.FILLABLEBINS' | translate }}</div>
        }
        @if (usageType != 1) {
          <div class='header-bold'>{{ 'SIDEBAR.FILLABLETANKS' | translate }}</div>
        }
      </div>
    </div>
    <mat-divider class="top-divider"></mat-divider>
    <div class="fillable-page-content">
      <div class="unallocated-bins">
        <app-unallocated-bins></app-unallocated-bins>
      </div>
      <div class="allocated-bins">
        <app-allocated-bins></app-allocated-bins>
      </div>
    </div>
  </div>
}


@if (isMobile) {
  <div>
  </div>
}
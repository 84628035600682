import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslatorService } from '../../../services/translator_service';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ApiStore } from '../../../web-services/api/api.store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../web-services/api/api.service';
import _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-manage-clusters-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,MatSelectModule,NgbModule,MatInputModule],
  templateUrl: './manage-clusters-modal.component.html',
  styleUrl: './manage-clusters-modal.component.scss'
})
export class ManageClustersModalComponent {
  ElForm: FormGroup;
	currentLang;
	translationsObj;
  isMobile: Boolean;
	types : any[] = [];
  errorMsg : boolean = false;
  constructor(private dialogRef: MatDialogRef<ManageClustersModalComponent>,private translator: TranslatorService,private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,private apiQuery:ApiQuery,private responsiveService: ResponsiveService,
    private apiService:ApiService,private apiStore:ApiStore) { 
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})

    
    this.ElForm = this.fb.group({
      clusterType: new FormControl(''),
      minAllowedCap:new FormControl(''),
      maxAllowedCap:new FormControl('')
    });
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();

    this.apiQuery.clusterTypes$.subscribe((type) => {
      if(type.length == 0){return;}
      this.types = type;
      const allIsFull = this.data.chosenClusters.every( (val, i, arr) => val.cluster_type_id == 1);
      const allIsDifferential = this.data.chosenClusters.every( (val, i, arr) => val.cluster_type_id == 2);
      if(allIsFull){
        this.ElForm.controls['clusterType'].setValue(1);
        this.changeType(1);
      }else if(allIsDifferential){
        this.ElForm.controls['clusterType'].setValue(2);
        this.changeType(2);
      }else{
        this.ElForm.controls['clusterType'].disable();
        this.errorMsg = true;
        this.changeType('');
      }
    });
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  applyChanges(){
		if(!this.ElForm.valid){
			return;
		}
    this.saveChanges();
	}
  	
	closeModal(){
		this.dialogRef.close();
	}

  setRequired() {
		return [Validators.required];
	}

  changeType(type){
		if(type == 1){
      this.ElForm.controls['clusterType'].setValidators(this.setRequired());
      this.ElForm.controls['clusterType'].updateValueAndValidity();
      this.ElForm.controls['maxAllowedCap'].setValidators(this.setRequired());
      this.ElForm.controls['maxAllowedCap'].updateValueAndValidity();
      this.ElForm.controls['minAllowedCap'].setValidators(this.setRequired());
      this.ElForm.controls['minAllowedCap'].updateValueAndValidity();
		}else if(type == 2){
      this.ElForm.controls['clusterType'].setValidators(this.setRequired());
      this.ElForm.controls['clusterType'].updateValueAndValidity();
      this.ElForm.controls['minAllowedCap'].setValidators(this.setRequired());
      this.ElForm.controls['minAllowedCap'].updateValueAndValidity();
      this.ElForm.controls['maxAllowedCap'].clearValidators();
      this.ElForm.controls['maxAllowedCap'].updateValueAndValidity();
		}else{
      this.ElForm.controls['clusterType'].clearValidators();
      this.ElForm.controls['clusterType'].updateValueAndValidity();
    }
	}

  
	async saveChanges(){		
		let clusterParams = {};
		let siteClusterList = [];
    _.each(this.data.chosenClusters, (cluster) => {
      siteClusterList.push([cluster.cluster_id, cluster.site_id]);
    });
		clusterParams['site_cluster_list'] = siteClusterList;
		clusterParams['cluster_type_id'] = this.ElForm.getRawValue()["clusterType"] == '' ? -1 : this.ElForm.getRawValue()["clusterType"];
		clusterParams['min_required_capacity_for_collection'] = this.ElForm.getRawValue()["minAllowedCap"] == '' ? -1 : this.ElForm.getRawValue()["minAllowedCap"];
		clusterParams['cluster_max_allowed_capacity'] = this.ElForm.getRawValue()["maxAllowedCap"] == '' ? -1 : this.ElForm.getRawValue()["maxAllowedCap"];
    let response = await this.apiService.setSiteClusterParams(clusterParams);
		if(response){
      let sitesClusters = {
        site_id:0
      }	
      this.apiStore.update({ allSitesClustersList: [] });
      this.apiStore.update({ sitesClustersList: [] });
      this.apiService.getfilteredSitesClustersList(sitesClusters,JSON.parse(sessionStorage.getItem("chosenSites")));
		}
		this.closeModal();
	}
}

@if (!isMobile) {
  <div class="bin-status">
    <mat-card [ngClass]="{'widget-div' : showKPIBtn}">
      <mat-card-header>
        <mat-card-title class="title">{{'TITLES.COLLECTION_KPI_EDIT' | translate}} {{datesDiff}}</mat-card-title>
        <div class="close-btn" rtlDiv (click)="closeCMP()" [hidden]="!showKPIBtn">
          <img class="close-img" src="../../../assets/images/dashboard/closeX.svg">
        </div>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <div class='spinner-container' [hidden]="!spinnerActive">
        <div class="spinner"></div>
      </div>
      <mat-card-content [hidden]="spinnerActive">
        <div class="content">
          <div class='progress-bar-left'>
            @if (average.toFixed() <= 20) {
              <img src="assets/images/reports/binAverage20.svg" alt="" />
            }
            @if (average.toFixed() > 20 && average.toFixed() <= 40) {
              <img src="assets/images/reports/binAverage40.svg" alt="" />
            }
            @if (average.toFixed() > 40 && average.toFixed() <= 60) {
              <img src="assets/images/reports/binAverage60.svg" alt="" />
            }
            @if (average.toFixed() > 60 && average.toFixed() <= 80) {
              <img src="assets/images/reports/binAverage80.svg" alt="" />
            }
            @if (average.toFixed() > 80) {
              <img src="assets/images/reports/binAverage100.svg" alt="" />
            }
            <div class="data">
              <div class='text-number' rtlDiv>
                {{average == 0 ? 0 : average.toFixed()}}%
              </div>
              <div class='text-title' rtlDiv>{{ 'TITLES.AVERAGE_CAP_AT_PICK' | translate }}</div>
            </div>
          </div>
          <div class="middle-divider"></div>
          <div class='progress-bar-left'>
            <img src="assets/images/dashboard/ActiveBins.svg" alt="" />
            <div class="data">
              <div class='text-number' rtlDiv>
                {{activeBins}} / {{totalBins}}
              </div>
              <div class='text-title' rtlDiv>{{ 'TITLES.ACTIVE_BINS' | translate }}</div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
    <div class="accordion md-accordion" role="tablist" aria-multiselectable="true">
      <div class="card mobile-card">
        <div class="card-header mobile-header">
          <div class="mobile-flex">
            <span class="mobile-title">{{'TITLES.COLLECTION_KPI_EDIT' | translate}} {{datesDiff}}</span>
          </div>
          <div class="mobile-close-btn" rtlDiv (click)="closeCMP()" [hidden]="!showKPIBtn">
            <img class="mobile-close-img" src="../../../assets/images/dashboard/closeX.svg">
          </div>
        </div>
        <div class="collapse show" role="tabpanel">
          <div class="card-body mobile-kpi">
            <div [hidden]="!spinnerActive" class="spinner-border mobile-spinner" role="status">
              <span class="sr-only"></span>
            </div>
            <div class="mobile-content" [hidden]="spinnerActive">
              <div class="mobile-flex-left">
                <div class='progress-bar-left'>
                  @if (average.toFixed() <= 20) {
                    <img src="assets/images/reports/binAverage20.svg" alt="" />
                  }
                  @if (average.toFixed() > 20 && average.toFixed() <= 40) {
                    <img src="assets/images/reports/binAverage40.svg" alt="" />
                  }
                  @if (average.toFixed() > 40 && average.toFixed() <= 60) {
                    <img src="assets/images/reports/binAverage60.svg" alt="" />
                  }
                  @if (average.toFixed() > 60 && average.toFixed() <= 80) {
                    <img src="assets/images/reports/binAverage80.svg" alt="" />
                  }
                  @if (average.toFixed() > 80) {
                    <img src="assets/images/reports/binAverage100.svg" alt="" />
                  }
                  <div class="data">
                    <div class='text-number' rtlDiv>
                      {{average.toFixed()}}%
                    </div>
                    <div class='text-title' rtlDiv>{{ 'TITLES.AVERAGE_CAP_AT_PICK' | translate }}</div>
                  </div>
                </div>
              </div>
              <div class="mobile-flex-left">
                <div class='progress-bar-left'>
                  <img src="assets/images/dashboard/ActiveBins.svg" alt="" />
                  <div class="data">
                    <div class='text-number' rtlDiv>
                      {{activeBins}}/{{totalBins}}
                    </div>
                    <div class='text-title' rtlDiv>{{ 'TITLES.ACTIVE_BINS' | translate }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
@if (!isMobile) {
  <div>
    <div class="modal-title">
      <span>{{'TITLES.DELETE' | translate }} {{ 'TITLES.USERS' | translate }} ({{data.chosenUsers.length}})</span>
    </div>
    <div class="content-body" rtlDiv>
      @if (data.chosenUsers.length == 1) {
        <span>{{'TITLES.DELETE_MSG' | translate }} {{data.chosenUsers[0].first_name + " " + data.chosenUsers[0].last_name}}</span>
      }
      <span>?</span>
    </div>
    <div [ngClass]="{'rtldir': currentLang != 'iw','ltrdir':currentLang == 'iw'}">
      <button class="apply-btn" (click)="applyChanges()">{{'TITLES.DELETE' | translate }}</button>
      <button class="cancel_btn" (click)="closeModal()">{{ 'TRUCK_MAP.CANCEL' | translate }}</button>
    </div>
  </div>
}

@if (isMobile) {
  <div>
  </div>
}

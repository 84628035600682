@if (!isMobile) {
  <div class="map-live-view">
    <mat-card>
      <mat-card-header>
        <mat-card-title [ngClass]="'top-header'" class="title">
          <div>{{ 'WIDGETS.MAP_VIEW' | translate }}</div>
        </mat-card-title>
        <div class="close-btn" rtlDiv (click)="closeCMP()">
          <img class="close-img" src="../../../assets/images/dashboard/closeX.svg">
        </div>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <div class='spinner-container' [hidden]="!spinnerActive">
        <div class="spinner"></div>
      </div>
      <mat-card-content>
        <div class="filter-container">
          <div class='search-input-container' rtlDiv>
            <input class='search-input' type="text" (keyup)="applyFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
              id="map_search_input" rtlDiv>
            <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
          </div>
          <div class="drop-down-main-wrapper" rtlDiv>
            <div class = "drop-down-list-container">
              @for (item of items; track item) {
                <div class="drop-down-list-item" [ngClass]="{'selected-item' : !item.selected}" [ngStyle]="{background: item.color}" (click)="selectUnselectItem(item)">
                  <mat-checkbox [checked]="item.selected" (click)="selectUnselectItem(item);$event.stopPropagation();false"></mat-checkbox>
                  <span class="colorTxt">{{item.displayName | translate}}</span>
                </div>
              }
            </div>
          </div>
        </div>
        <div class="map-container" [ngClass]="{'hide-div' : spinnerActive}">
          <app-manage-panel-modal [showClusters]=showClusters [isDisabled]="isDisabled" (resetClicked)="handleResetClicked($event)" (clusterClicked)="handleClusterClicked($event)" (neighborhoodsSelected)="handleNeighborhoodsSelected($event)" (fullClusterSelection)="handleFullClusterSelection($event)" (semiClusterSelection)="handleSemiClusterSelection($event)" class="hide-div manage-panel" id="managePanelClicked"></app-manage-panel-modal>
          <google-map id="map"
            (mapClick)="mapClick()"
            [options]="mapOptionsPc"
            (mapInitialized)="loadAPIWrapper($event)">
              @if ((sitesCosenLength > 1) || (sitesCosenLength == 1 && moreThanMax)) {
                  @for (cluster of binsHashClustersArr; track cluster) {
                    <map-marker-clusterer [ignoreHidden]="true" [minimumClusterSize]= "5" [styles]="mapOptions.styles" [calculator]="mapOptions.calculator">
                      @for (bin of cluster; track bin; let i = $index) {
                            @if(isNumber(bin.lat) && isNumber(bin.lng)){
                                <map-marker #marker="mapMarker"
                                (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
                                [position]="{lat: bin.lat, lng: bin.lng}"
                                [visible]="bin.visible"
                                [options]="{ icon: { url: bin.icon }, draggable : bin.draggable }"
                                [label]="bin.label">
                                </map-marker>
                                <map-info-window #window="mapInfoWindow">
                                  <div id="iw-container">
                                    <div id="binInfoWindow_multiSites"></div>
                                  </div>                      
                                </map-info-window>
                            }      
                        }
                      </map-marker-clusterer>
                  }
              }
            @if (sitesCosenLength == 1 && !moreThanMax) {
              @for (bin of markersArray; track bin; let i = $index) {
                <map-marker #marker="mapMarker"
                  (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
                  [position]="{lat: bin.lat, lng: bin.lng}"
                  [visible]="bin.visible"
                  [options]="{ icon: { url: bin.icon }, draggable : bin.draggable }"
                  [label]="bin.label"
                  >
                </map-marker>
                <map-info-window #window="mapInfoWindow">
                  <div id="iw-container">
                    <div id="binInfoWindow_singleSite"></div>
                  </div>                                    
                </map-info-window>
              }
            }
          </google-map>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div class="mobile-map-live-view">
    <div class="accordion md-accordion" id="mapLiveAc" role="tablist" aria-multiselectable="true">
      <div class="card mobile-card">
        <div class="card-header mobile-header">
          <div class="mobile-flex">
            <span class="mobile-title"> {{ 'WIDGETS.MAP_VIEW' | translate }}</span>
          </div>
          <div class="mobile-close-btn" rtlDiv (click)="closeCMP()">
            <img class="mobile-close-img" src="../../../assets/images/dashboard/closeX.svg">
          </div>
        </div>
        <div class="collapse show" role="tabpanel" data-parent="#mapLiveAc">
          <div class="card-body">
            <div [hidden]="!spinnerActive" class="spinner-border mobile-spinner" role="status">
              <span class="sr-only"></span>
            </div>
            <div class='mobile-search-input-container' rtlDiv>
              <input class='search-input' type="text" (keyup)="applyFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
                id="mobile-map-view" rtlDiv>
              <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
            </div>
            <div class="mobile-drop-down" rtlDiv>
              <div class = "drop-down-list-container">
                @for (item of items; track item) {
                  <div class="drop-down-list-item" rtlDiv [ngClass]="{'selected-item' : !item.selected}" [ngStyle]="{background: item.color}" (click)="selectUnselectItem(item)">
                    <mat-checkbox [checked]="item.selected" (click)="selectUnselectItem(item);$event.stopPropagation();false"></mat-checkbox>
                    <span class="colorTxt"></span>
                  </div>
                }
              </div>
            </div>
            @if (markersArray && markersArray.length > 0) {
              <div class="mobile-exception-wrapper" [ngClass]="{'disableDiv': isDisabled}">
                @if (showClusters) {
                  <img class="exception-button" (click)="hideClustersbtn()"
                    src="assets/images/workplans-page/exception toggle on.svg" alt="" />
                }
                @if (!showClusters) {
                  <img class="exception-button" (click)="showClustersbtn()"
                    src="assets/images/workplans-page/exeptions toggle off.svg" alt="" />
                }
                <div class="exception-title">{{ 'TITLES.CLUSTERS' | translate }}</div>
              </div>
            }
            <div class="mobile-map" rtlDiv>
              <google-map id="map" class="mobile-agm"
                (mapClick)="mapClick()"
                [options]="mobileMapOptions"
                (mapInitialized)="loadAPIWrapper($event)">
                @if ((sitesCosenLength > 1) || (sitesCosenLength == 1 && moreThanMax)) {
                  @for (cluster of binsHashClustersArr; track cluster) {
                    <map-marker-clusterer [ignoreHidden]="true" [minimumClusterSize]= "5" [styles]="mapOptions.styles" [calculator]="mapOptions.calculator">
                      @for (bin of cluster; track bin; let i = $index) {
                        <map-marker #marker="mapMarker"
                          (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
                          [position]="{lat: bin.lat, lng: bin.lng}"
                          [visible]="bin.visible"
                          [options]="{ icon: { url: bin.icon }, draggable : bin.draggable }"
                          [label]="bin.label">
                        </map-marker>
                        <map-info-window #window="mapInfoWindow">
                          <div id="iw-container">
                            <div id="binInfoWindow_multiSites"></div>
                          </div>                      
                        </map-info-window>
                      }
                    </map-marker-clusterer>
                  }
                }
                @if (sitesCosenLength == 1 && !moreThanMax) {
                  @for (bin of markersArray; track bin; let i = $index) {
                    <map-marker #marker="mapMarker"
                      (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
                      [position]="{lat: bin.lat, lng: bin.lng}"
                      [visible]="bin.visible"
                      [options]="{ icon: { url: bin.icon }, draggable : bin.draggable }"
                      [label]="bin.label">
                    </map-marker>
                    <map-info-window #window="mapInfoWindow">
                      <div id="iw-container">
                        <div id="binInfoWindow_singleSite"></div>
                      </div>                                    
                    </map-info-window>
                  }
                }
              </google-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
@if (!isMobile) {
  <div>
    <div class="inventory-view">
      <div class='spinner-container' [hidden]="!spinnerActive">
        <div class="spinner"></div>
      </div>
      <mat-card [hidden]="spinnerActive">
        <mat-card-header>
          <mat-card-title class="title">{{ 'FILLINGS.ALL_FILLINGS' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-divider class="top-divider"></mat-divider>
        <mat-card-content>
          <div class='search-input-container' rtlDiv>
            <input class='search-input' type="text" (input)="applyFilter($event.target)" placeholder="{{
              'OTHER.SEARCH' | translate }}" name="">
              <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
            </div>
            <table class="table" multiTemplateDataRows mat-table #table [dataSource]="getTableData()" matSort matSortStart="asc"
              matSortActive="bin_desc" matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc" rtlDiv>
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_desc">
                  {{ usageType == 1 ? (translationsObj ? translationsObj.FILLINGS.BIN_DESC : '') : (translationsObj ? translationsObj.FILLINGS.TANK_DESC : '')}}
                </th>
                <td mat-cell *matCellDef="let element" class="space">
                  <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'fillings',binId:element.bin_id}" title="{{element ? element.bin_desc : ''}}" class="cut-txt binNameHref"> {{element ? element.bin_desc : ''}}</a>
                </td>
              </ng-container>
              <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address">
                {{ 'FILLINGS.ADDRESS' | translate }} </th>
                <td mat-cell *matCellDef="let element"> <span title="{{element ? element.bin_address : ''}}" class="cut-txt"> {{element ? element.bin_address : ''}}</span> </td>
              </ng-container>
              <ng-container matColumnDef="lotSize">
                <th class="space" mat-header-cell *matHeaderCellDef mat-sort-header="lot_size">
                {{ 'FILLINGS.LOT_SIZE' | translate }}</th>
                <td class="space" mat-cell *matCellDef="let element"> {{element ? element.lot_size: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="totalRefills">
                <th mat-header-cell *matHeaderCellDef class="space" mat-sort-header="total_refills"> {{ 'FILLINGS.TOTAL_REFILLS' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="space"> {{element ? element.total_refills: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="avgRefillAmount">
                <th mat-header-cell *matHeaderCellDef class="space" mat-sort-header="avg_refill_amount"> {{ 'FILLINGS.REFILL_CAP' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="space">
                  <!-- <div class="table-status-progress-bar-wrapper" [ngStyle]="getProgressBarStyleWrapper(element)">
                  <div class="table-status-progress-line" [ngStyle]="getProgressBarStyleInternal(element)"></div>
                </div> -->
                <div rtlDiv>{{element ? ((element.avg_refill_amount)/1000).toFixed(2): ''}} {{ 'FILLINGS.CUBIC_METER' | translate }}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="avgDailyUsageRate">
              <th mat-header-cell *matHeaderCellDef class="space" mat-sort-header="bin_avg_fill_rate_in_liters">
                {{ 'FILLINGS.AVG_DAILY_RATE' | translate }}
              </th>
              <td mat-cell *matCellDef="let element" class="space">
                {{element ? ((element.bin_avg_fill_rate_in_liters)/1000).toFixed(2): ''}} {{ 'FILLINGS.CUBIC_METER' | translate }}
              </td>
            </ng-container>
            <ng-container matColumnDef="arrow">
              <th mat-header-cell *matHeaderCellDef class="space"></th>
              <td mat-cell *matCellDef="let element" class="space">
                @if (expandedElement !== element) {
                  <img class="pointer" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                }
                @if (expandedElement === element) {
                  <img class="pointer" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
              <td class="details-td" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" rtlDiv>
                <div class="row-details" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <table class="table" mat-table #table [dataSource]="getTableFillings(expandedElement)" rtlDiv>
                    <ng-container matColumnDef="driverName">
                      <th mat-header-cell *matHeaderCellDef class="space"> {{ 'FILLINGS.DRIVER_NAME' | translate }} </th>
                      <td mat-cell *matCellDef="let element">
                        @if (currentLang == 'iw') {
                          <span class="cut-txt"> {{element !=undefined && element.collected_driver_first_name != undefined && element.collected_driver_last_name != undefined ? element.collected_driver_first_name + ' ' + element.collected_driver_last_name : 'ללא'}}</span>
                        }
                        @if (currentLang != 'iw') {
                          <span class="cut-txt"> {{element !=undefined && element.collected_driver_first_name != undefined && element.collected_driver_last_name != undefined ? element.collected_driver_first_name + ' ' + element.collected_driver_last_name : 'NA'}}</span>
                        }
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="refillAmount">
                      <th mat-header-cell *matHeaderCellDef class="space"> {{ 'FILLINGS.REFILL_AMOUNT' | translate }} </th>
                      <td mat-cell *matCellDef="let element" class="space">
                        <!-- <div class="table-status-progress-bar-wrapper" [ngStyle]="getProgressBarStyleWrapperFillings(element)">
                        <div class="table-status-progress-line" [ngStyle]="getProgressBarStyleInternalFillings(element)"></div>
                      </div> -->
                      <div rtlDiv>{{element ? ((element.bin_refill_amount_in_liters)/1000).toFixed(2): ''}} {{ 'FILLINGS.CUBIC_METER' | translate }}</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="refillTime">
                    <th mat-header-cell *matHeaderCellDef class="space"> {{ 'FILLINGS.REFILL_TIME' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space"> {{element ? element.bin_refill_time: ''}} </td>
                  </ng-container>
                  <ng-container matColumnDef="fertilizerType">
                    <th mat-header-cell *matHeaderCellDef class="space"> {{ 'FILLINGS.FERTILIZER_TYPE' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space">
                      <span>{{element ? element.type_of_fertilizer: ''}}</span>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedFillingsColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedFillingsColumns;"></tr>
                </table>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="expandedElement = expandedElement === element ? null : element"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
      </mat-card-content>
      @if (groupsWithFillings != undefined && groupsWithFillings.length > 0) {
        <div>
          <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="10"></app-pagination>
        </div>
      }
    </mat-card>
  </div>
</div>
}

@if (isMobile) {
  <div></div>
}
@if (!isMobile) {
  <div>
    <div class="cluster-info-header" rtlDiv>
      <div class="cluster-info-title" rtlDiv>
        <div class="cluster-name">{{chosenCluster['cluster_name']}}</div>
        <mat-divider [vertical]="true" class="top-divider"></mat-divider>
        <div class="cluster-type">{{currentLang != 'iw' ? chosenCluster['cluster_type_name'] : chosenCluster['cluster_type_name_hebrew']}}</div>
      </div>
      <img (click)="closeModal()" class="close-img" src="assets/images/reports/close.svg" alt="" rtlDiv>
    </div>
    <div class="cluster-additional-info" rtlDiv>
      <div>
        <div class="info-title">{{'INSIGHTS.CLUSTER_AVG' | translate}}</div>
        <div class="info-value">
          <img src="assets/images/binInfoPage/blackStatusDuration.svg" alt="" rtlDiv>
          {{chosenCluster['cluster_avg'].toFixed(0)}}{{usageType == 1 ? '%' : 'L'}}                 
        </div>
      </div>
      <div>
        <div class="info-title">{{'INSIGHTS.COLLECTIONS_FREQ' | translate}}</div>
        <div class="info-value">
          <img src="assets/images/binInfoPage/avgCollectionRate.svg" alt="" rtlDiv>
          {{chosenCluster['avg_collection_rate'].toFixed(0)}} {{'COLLECTION_REPORT.DAYS' | translate }}
        </div>
      </div>
      <div>
        <div class="info-title">{{'TITLES.TOTAL_BINS' | translate}}</div>
        <div class="info-value">
          <img src="assets/images/binInfoPage/totalBins.svg" alt="" rtlDiv>
          {{chosenCluster['number_of_bins_in_cluster']}}
        </div>
      </div>
    </div>
    <div class="bins-container" rtlDiv>
      <div class="bins-container-header" rtlDiv>
        <div class='search-input-container' rtlDiv>
          <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
          <input class='search-input' type="text" (input)="applyFilter($event.target['value'])" placeholder="{{
            'OTHER.SEARCH' | translate }}" name="">
          </div>
          @if (!allDataExpanded) {
            <img (click)="allExpandState = true;expandAllData()" class="expand-all" src="assets/images/topmenu/expand-all.svg" rtlDiv>
          }
          @if (allDataExpanded) {
            <img (click)="allExpandState = false;expandAllData()" class="expand-all" src="assets/images/topmenu/collapse-all.svg" rtlDiv>
          }
        </div>
        <div class="bins-list">
          <cdk-virtual-scroll-viewport itemSize="45" style="height:100%">
            <div *cdkVirtualFor="let item of data.item.bins; index as i" class="item-content">
              <mat-accordion #accordion="matAccordion" [multi]="true">
                <mat-expansion-panel (opened)="item.expand = true" (closed)="item.expand = false" [expanded]="allExpandState" hideToggle="true" class="box-shadow expansion-accord">
                  <mat-expansion-panel-header class="padding-unset acc-hover">
                    <mat-panel-title>
                      <div class="row-title">{{item.bin_name}}</div>
                    </mat-panel-title>
                    <mat-panel-description class="panel-desc">
                      @if (!item.expand) {
                        <img [ngClass]="{'rotate-lang':currentLang == 'iw'}" src="assets/images/dashboard/collapse-data.svg" alt="" rtlDiv>
                      }
                      @if (item.expand) {
                        <img src="assets/images/dashboard/down-chevron.svg" alt="" rtlDiv>
                      }
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="collapse-item" rtlDiv>
                    <div class="collapse-item-body">
                      <span>{{'LIST_COLUMNS.BIN_TYPE' | translate}}</span>
                      <span>{{item.bin_type_name}}</span>
                    </div>
                    <div class="collapse-item-body">
                      <span>{{'LIST_COLUMNS.STATUS' | translate}}</span>
                      <span>{{item.Bin_live_Data.fill_level_percent}}%</span>
                    </div>
                    <div class="collapse-item-body">
                      <span>{{'LIST_COLUMNS.LAST_COLLECTION' | translate}}</span>
                      <span>{{item.calcDiff_lastCollection}}</span>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
    </div>
  }

  @if (isMobile) {
    <div class="mobile-dialog">
      <div class="cluster-info-header" rtlDiv>
        <div class="dflex" (click)="closeModal()">
          <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
        </div>
        <div class="cluster-info-title" rtlDiv>
          <div class="cluster-name">{{chosenCluster['cluster_name']}}</div>
          <mat-divider [vertical]="true" class="top-divider"></mat-divider>
          <div class="cluster-type">{{currentLang != 'iw' ? chosenCluster['cluster_type_name'] : chosenCluster['cluster_type_name_hebrew']}}</div>
        </div>
      </div>
      <div class="cluster-additional-info" rtlDiv>
        <div>
          <div class="info-title">{{'INSIGHTS.CLUSTER_AVG' | translate}}</div>
          <div class="info-value">
            <img src="assets/images/binInfoPage/blackStatusDuration.svg" alt="" rtlDiv>
            {{chosenCluster['cluster_avg'].toFixed(0)}}{{usageType == 1 ? '%' : 'L'}}                 
          </div>
        </div>
        <div>
          <div class="info-title">{{'INSIGHTS.COLLECTIONS_FREQ' | translate}}</div>
          <div class="info-value">
            <img src="assets/images/binInfoPage/avgCollectionRate.svg" alt="" rtlDiv>
            {{chosenCluster['avg_collection_rate'].toFixed(0)}} {{'COLLECTION_REPORT.DAYS' | translate }}
          </div>
        </div>
        <div>
          <div class="info-title">{{'TITLES.TOTAL_BINS' | translate}}</div>
          <div class="info-value">
            <img src="assets/images/binInfoPage/totalBins.svg" alt="" rtlDiv>
            {{chosenCluster['number_of_bins_in_cluster']}}
          </div>
        </div>
      </div>
      <div class="bins-container" rtlDiv>
        <div class="bins-container-header" rtlDiv>
          <div class='search-input-container' rtlDiv>
            <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
          <input class='search-input' type="text" (input)="applyFilter($event.target['value'])" placeholder="{{
            'OTHER.SEARCH' | translate }}" name="">
            </div>
            @if (!allDataExpanded) {
              <img (click)="allExpandState = true;expandAllData()" class="expand-all" src="assets/images/topmenu/expand-all.svg" rtlDiv>
            }
            @if (allDataExpanded) {
              <img (click)="allExpandState = false;expandAllData()" class="expand-all" src="assets/images/topmenu/collapse-all.svg" rtlDiv>
            }
          </div>
          <div class="bins-list">
            <cdk-virtual-scroll-viewport itemSize="45" style="height:100%">
              <div *cdkVirtualFor="let item of data.item.bins; index as i" class="item-content">
                <mat-accordion #accordion="matAccordion" [multi]="true">
                  <mat-expansion-panel (opened)="item.expand = true" (closed)="item.expand = false" [expanded]="allExpandState" hideToggle="true" class="box-shadow expansion-accord">
                    <mat-expansion-panel-header class="padding-unset acc-hover">
                      <mat-panel-title>
                        <div class="row-title">{{item.bin_name}}</div>
                      </mat-panel-title>
                      <mat-panel-description class="panel-desc">
                        @if (!item.expand) {
                          <img [ngClass]="{'rotate-lang':currentLang == 'iw'}" src="assets/images/dashboard/collapse-data.svg" alt="" rtlDiv>
                        }
                        @if (item.expand) {
                          <img src="assets/images/dashboard/down-chevron.svg" alt="" rtlDiv>
                        }
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="collapse-item" rtlDiv>
                      <div class="collapse-item-body">
                        <span>{{'LIST_COLUMNS.BIN_TYPE' | translate}}</span>
                        <span>{{item.bin_type_name}}</span>
                      </div>
                      <div class="collapse-item-body">
                        <span>{{'LIST_COLUMNS.STATUS' | translate}}</span>
                        <span>{{item.Bin_live_Data.fill_level_percent}}%</span>
                      </div>
                      <div class="collapse-item-body">
                        <span>{{'LIST_COLUMNS.LAST_COLLECTION' | translate}}</span>
                        <span>{{item.calcDiff_lastCollection}}</span>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </div>
    }

@if (!isMobile) {
  <div>
    <div class="bin-list-view">
      <mat-card>
        <div class='spinner-container' [hidden]="!spinnerActive">
          <div class="spinner"></div>
        </div>
        <mat-card-content [hidden]="spinnerActive">
          <div class='search-input-container'>
            <div class="disp-gap">
              <div class="flex-data">
                <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                <input class='search-input' type="text" (keyup)="applyFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
                  name="" rtlDiv>
              </div>
                @if (filterTableAccordigToStatus.text != '') {
                  <div class="filteredValue">
                    <svg height="8" width="8">
                      <ellipse cx="4" cy="4" rx="4" ry="4" [ngStyle]="{fill: filterTableAccordigToStatus.value}"/>
                    </svg>
                    <span>{{filterTableAccordigToStatus.text}}</span>
                    <img (click)="removeFilter()" class="removeFilter" src="assets/images/reports/removeFilter.svg" alt=""/>
                  </div>
                }
            </div>
            <div class='table-export-container' rtlDiv>
              <div class='table-export-btns-wrapper' (click)="exportToCsv()">
                <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
              </div>
            </div>
          </div>
            <table multiTemplateDataRows class="table" mat-table #table [dataSource]="getTableData()" matSort matSortStart="asc" matSortActive="bin_name"
              matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
              <ng-container matColumnDef="binName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="th-title">
                  {{ 'INSIGHTS.BIN_NAME' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="td-value">
                  <div class="flex-th-value">
                    <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'insights',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="binNameHref"> {{element ? element.bin_name : ''}}</a>
                    <button (click)="changeSubmitClickedStatus(element)" class="tooltip-btn" type="button">
                      @if (element.number_of_bins_in_cluster > 1 && element.chosenCluster) {
                        <img src="assets/images/binInfoPage/clusterIndication.svg" alt="" />
                      }
                    </button>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="siteName">
                <th [hidden]="!not_same_linked_sites_id" mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="th-title space">
                {{ 'TITLES.SITE_NAME' | translate }}</th>
                <td [hidden]="!not_same_linked_sites_id" mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.site_name: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="numOfBlackStatusEvents">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="num_of_black_status_events" class="th-title space">
                {{ 'INSIGHTS.NUM_BLACK_STATUS_EV' | translate }}</th>
                <td mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.num_of_black_status_events: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="eventOpen">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="event_start_date" class="th-title space"> 
                {{ 'INSIGHTS.EVENT_START' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="td-value space">
                  {{currentLang == 'iw' && element ? element.event_start_date_he : element.event_start_date}}
                </td>
              </ng-container>
              <ng-container matColumnDef="blackStatusDuration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="black_status_duration" class="th-title space">
                  {{ 'INSIGHTS.BLACK_STATUS_DURATION' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="td-value space">
                  <span>{{element ? (element.black_status_duration | number: '1.0-0'): ''}} {{'COLLECTION_REPORT.DAYS' | translate }}</span>
                </td>
              </ng-container>              
              <ng-container matColumnDef="numOfCollections">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="num_of_collections" class="th-title space">
                  {{ 'INSIGHTS.NUM_OF_COLLECTIONS' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.num_of_collections: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="avgCollectionVolume">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="avg_volume" class="th-title space">
                  {{ 'INSIGHTS.AVG_COLLECTIONS_VOL' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="td-value space">
                  <span>{{element?.avg_volume?.toFixed(0)}}{{usageType == 1 ? '%' : 'L'}}</span>                  
                </td>
              </ng-container>
              <ng-container matColumnDef="clusterAvg">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="cluster_average" class="th-title">
                  {{ 'INSIGHTS.CLUSTER_AVG' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="td-value"> 
                  @if(element.number_of_bins_in_cluster > 1 && element.chosenCluster){
                    {{(element?.cluster_average | number: '1.0-0')+'%'}} 
                  }
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
            </table>
            @if (dataTableLength > 0) {
              <div>
                <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="7"></app-pagination>
              </div>
            }
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  }

  @if (isMobile) {
    <div>
    </div>
  }
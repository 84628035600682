@if (!isMobile) {
  <div class="full-content">
    @if (selectedTabIndx == 0) {
      <google-map class="map-frame"
        (mapClick)="mapClick()"
        [options]="mapOptions"
        (mapInitialized)="loadCheckOrReplacementMap($event)"
        (fullscreenchange)="fullCheckOrReplacementScreenchange()">
        @for (bin of markersCheckOrReplacementArray; track bin; let i = $index) {
          @if(isNumber(bin.lat) && isNumber(bin.lng)){
            <map-marker #marker="mapMarker"
            (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)"
            [position]="{lat: bin.lat, lng: bin.lng}"
            [visible]="bin.visible"
            [options]="{ icon: { url: bin.icon } }">
          </map-marker>
          <map-info-window #infoWindow>
            <div id="iw-event-container">
              <div id="singleBinInfo"></div>
            </div>
          </map-info-window>
          }
        }
      </google-map>
    }
    @if (selectedTabIndx == 1) {
      <google-map class="map-frame"
        (mapClick)="mapClick()"
        [options]="mapOptions"
        (mapInitialized)="loadInstallationMap($event)"
        (fullscreenchange)="fullInstallationScreenchange()">
        @for (bin of markersInstallationArray; track bin; let i = $index) {
          @if(isNumber(bin.lat) && isNumber(bin.lng)){
            <map-marker #marker="mapMarker"
            [position]="{lat: bin.lat, lng: bin.lng}"
            [visible]="bin.visible"
            [options]="{ icon: { url: bin.icon } }"
            (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)">
          </map-marker>
          <map-info-window #infoWindow>
            <div id="iw-event-container">
              <div id="singleBinInfo"></div>
            </div>
          </map-info-window>
          }
        }
      </google-map>
    }
    @if (selectedTabIndx == 2) {
      <google-map class="map-frame"
        (mapClick)="mapClick()"
        [options]="mapOptions"
        (mapInitialized)="loadCancellationMap($event)"
        (fullscreenchange)="fullCancellationScreenchange()">
        @for (bin of markersCancellationArray; track bin; let i = $index) {
          @if(isNumber(bin.lat) && isNumber(bin.lng)){
            <map-marker #marker="mapMarker"
            [position]="{lat: bin.lat, lng: bin.lng}"
            [visible]="bin.visible"
            [options]="{ icon: { url: bin.icon } }"
            (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)">
          </map-marker>
          <map-info-window #infoWindow>
            <div id="iw-event-container">
              <div id="singleBinInfo"></div>
            </div>
          </map-info-window>
          }
        }
      </google-map>
    }
  </div>
}

@if (isMobile) {
  <div class="full-content">
    @if (selectedTabIndx == 0) {
      <google-map class="map-frame"
        (mapClick)="mapClick()"
        [options]="mobileMapOptions"
        (mapInitialized)="loadCheckOrReplacementMap($event)"
        (fullscreenchange)="fullCheckOrReplacementScreenchange()">
        @for (bin of markersCheckOrReplacementArray; track bin; let i = $index) {
          @if(isNumber(bin.lat) && isNumber(bin.lng)){
            <map-marker #marker="mapMarker"
            [position]="{lat: bin.lat, lng: bin.lng}"
            [visible]="bin.visible"
            [options]="{ icon: { url: bin.icon } }"
            (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)">
          </map-marker>
          <map-info-window #infoWindow>
            <div id="iw-event-container">
              <div id="singleBinInfo"></div>
            </div>
          </map-info-window>
          }
        }
      </google-map>
    }
    @if (selectedTabIndx == 1) {
      <google-map class="map-frame"
        (mapClick)="mapClick()"
        [options]="mobileMapOptions"
        (mapInitialized)="loadInstallationMap($event)"
        (fullscreenchange)="fullInstallationScreenchange()">
        @for (bin of markersInstallationArray; track bin; let i = $index) {
          @if(isNumber(bin.lat) && isNumber(bin.lng)){
            <map-marker #marker="mapMarker"
            [position]="{lat: bin.lat, lng: bin.lng}"
            [visible]="bin.visible"
            [options]="{ icon: { url: bin.icon } }"
            (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)">
          </map-marker>
          <map-info-window #infoWindow>
            <div id="iw-event-container">
              <div id="singleBinInfo"></div>
            </div>
          </map-info-window>
          }
        }
      </google-map>
    }
    @if (selectedTabIndx == 2) {
      <google-map class="map-frame"
        (mapClick)="mapClick()"
        [options]="mobileMapOptions"
        (mapInitialized)="loadCancellationMap($event)"
        (fullscreenchange)="fullCancellationScreenchange()">
        @for (bin of markersCancellationArray; track bin; let i = $index) {
          @if(isNumber(bin.lat) && isNumber(bin.lng)){
            <map-marker #marker="mapMarker"
            [position]="{lat: bin.lat, lng: bin.lng}"
            [visible]="bin.visible"
            [options]="{ icon: { url: bin.icon } }"
            (mapClick)="openMarkerInfoWindow(marker, i);clickedMarker(bin)">
          </map-marker>
          <map-info-window #infoWindow>
            <div id="iw-event-container">
              <div id="singleBinInfo"></div>
            </div>
          </map-info-window>
          }
        }
      </google-map>
    }
  </div>
}

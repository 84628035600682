<div class="modal-title">
  @if (usageType == 1) {
    <span>{{ 'MANAGE_BIN.MANAGE_BINS' | translate }} </span>
  }
  @if (usageType != 1) {
    <span>{{ 'MANAGE_BIN.MANAGE_TANKS' | translate }} </span>
  }
  @if (usageType == 1) {
    <span>({{data.chosenBins.length}} {{ 'SITE_MANAGMENT.BINS' | translate }})</span>
  }
  @if (usageType != 1) {
    <span>({{data.chosenBins.length}} {{ 'SITE_MANAGMENT.TANKS' | translate }})</span>
  }
</div>
<div>
  <div class="opt-title" rtlDiv>{{ 'MANAGE_BIN.SELECT_PROPERTIES' | translate }}</div>
  <div class="prop-frame">
    <div class="container-chbx" rtlDiv>
      <label class="container-extra" rtlDiv>
        <input id="chbx_all" [(ngModel)]="options.isAll" [checked]="options.isAll" type="checkbox" (change)="toggleValue('isAll',options.isAll)" rtlDiv>
        <span class="checkmark checkmark-all" rtlDiv></span>
        <span class="label-chbx" rtlDiv>{{ 'NEW_WORKPLAN.CHOOSE_ALL' | translate }} ({{PartialManagement ? (3) : (sameBinsSite && usageType == 1 ? 9 : 7)}})</span>
      </label>
    </div>
    @if (sameBinsSite && usageType == 1) {
      <div class="container-chbx" rtlDiv>
        <label class="container-extra" rtlDiv [ngClass]="{'greenBorderColor' : options.isSite,'defaultBorderColor' : !options.isSite}">
          <input id="chbx_site" [(ngModel)]="options.isSite" [checked]="options.isSite" type="checkbox" (change)="toggleValue('isSite',options.isSite)" rtlDiv>
          <span class="checkmark" rtlDiv></span>
          <span class="label-chbx" [ngClass]="{'greenTextColor' : options.isSite,'defaultTextColor' : !options.isSite}" rtlDiv>{{ 'TICKET_MANAGEMENT.SITE' | translate }}</span>
        </label>
      </div>
    }
    @if (sameBinsSite && usageType == 1) {
      <div class="container-chbx" rtlDiv>
        <label class="container-extra" rtlDiv [ngClass]="{'greenBorderColor' : options.isNeighborhood,'defaultBorderColor' : !options.isNeighborhood}">
          <input id="chbx_neighborhood" [(ngModel)]="options.isNeighborhood" [checked]="options.isNeighborhood" type="checkbox" (change)="toggleValue('isNeighborhood',options.isNeighborhood)" rtlDiv>
          <span class="checkmark" rtlDiv></span>
          <span class="label-chbx" [ngClass]="{'greenTextColor' : options.isNeighborhood,'defaultTextColor' : !options.isNeighborhood}" rtlDiv>{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</span>
        </label>
      </div>
    }
    @if (isAdmin) {
      <div class="container-chbx" rtlDiv>
        <label class="container-extra" rtlDiv [ngClass]="{'greenBorderColor' : options.isMaxDays,'defaultBorderColor' : !options.isMaxDays}">
          <input id="chbx_maxDays" [(ngModel)]="options.isMaxDays" [checked]="options.isMaxDays" type="checkbox" (change)="toggleValue('isMaxDays',options.isMaxDays)" rtlDiv>
          <span class="checkmark" rtlDiv></span>
          <span class="label-chbx" [ngClass]="{'greenTextColor' : options.isMaxDays,'defaultTextColor' : !options.isMaxDays}" rtlDiv>{{ 'MANAGE_BIN.MAX_DAYS' | translate }}</span>
        </label>
      </div>
    }
    @if (isAdmin) {
      <div class="container-chbx" rtlDiv>
        <label class="container-extra" rtlDiv [ngClass]="{'greenBorderColor' : options.isBinMaxCapLiter,'defaultBorderColor' : !options.isBinMaxCapLiter}">
          <input id="chbx_binmaxcapLiter" [(ngModel)]="options.isBinMaxCapLiter" [checked]="options.isBinMaxCapLiter" type="checkbox" (change)="toggleValue('isBinMaxCapLiter',options.isBinMaxCapLiter)" rtlDiv>
          <span class="checkmark" rtlDiv></span>
          <span class="label-chbx" [ngClass]="{'greenTextColor' : options.isBinMaxCapLiter,'defaultTextColor' : !options.isBinMaxCapLiter}" rtlDiv>
            @if (usageType == 1) {
              <span>{{ 'SITE_MANAGMENT.BIN_MAX_CAP_L' | translate }}</span>
            }
            @if (usageType != 1) {
              <span>{{ 'SITE_MANAGMENT.TANK_MAX_CAP_L' | translate }}</span>
            }
          </span>
        </label>
      </div>
    }
    @if (isAdmin) {
      <div class="container-chbx" rtlDiv>
        <label class="container-extra" rtlDiv [ngClass]="{'greenBorderColor' : options.isThresholdLevel,'defaultBorderColor' : !options.isThresholdLevel}">
          <input id="chbx_thresholdlevel" [(ngModel)]="options.isThresholdLevel" [checked]="options.isThresholdLevel" type="checkbox" (change)="toggleValue('isThresholdLevel',options.isThresholdLevel)" rtlDiv>
          <span class="checkmark" rtlDiv></span>
          <span class="label-chbx" [ngClass]="{'greenTextColor' : options.isThresholdLevel,'defaultTextColor' : !options.isThresholdLevel}" rtlDiv>{{ 'MANAGE_BIN.THRESHOLD_LEVEL' | translate }}</span>
        </label>
      </div>
    }
    @if (isAdmin) {
      <div class="container-chbx" rtlDiv>
        <label class="container-extra" rtlDiv [ngClass]="{'greenBorderColor' : options.isMaxCapacity,'defaultBorderColor' : !options.isMaxCapacity}">
          <input id="chbx_maxcapacity" [(ngModel)]="options.isMaxCapacity" [checked]="options.isMaxCapacity" type="checkbox" (change)="toggleValue('isMaxCapacity',options.isMaxCapacity)" rtlDiv>
          <span class="checkmark" rtlDiv></span>
          <span class="label-chbx" [ngClass]="{'greenTextColor' : options.isMaxCapacity,'defaultTextColor' : !options.isMaxCapacity}" rtlDiv>
            @if (usageType == 1) {
              <span>{{ 'MANAGE_BIN.MAX_CAPACITY' | translate }}</span>
            }
            @if (usageType != 1) {
              <span>{{ 'MANAGE_BIN.MAX_CAPACITY_D' | translate }}</span>
            }
          </span>
        </label>
      </div>
    }
    @if (usageType == 1 && isAdmin) {
      <div class="container-chbx" rtlDiv>
        <label class="container-extra" rtlDiv [ngClass]="{'greenBorderColor' : options.isBinRestrictionsTimes,'defaultBorderColor' : !options.isBinRestrictionsTimes}">
          <input id="chbx_binrestrictions" [(ngModel)]="options.isBinRestrictionsTimes" [checked]="options.isBinRestrictionsTimes" type="checkbox" (change)="toggleValue('isBinRestrictionsTimes',options.isBinRestrictionsTimes)" rtlDiv>
          <span class="checkmark" rtlDiv></span>
          <span class="label-chbx" [ngClass]="{'greenTextColor' : options.isBinRestrictionsTimes,'defaultTextColor' : !options.isBinRestrictionsTimes}" rtlDiv>{{ 'MANAGE_BIN.BIN_RESTRICTION_TIMES' | translate }}</span>
        </label>
      </div>
    }
    <div class="container-chbx" rtlDiv>
      <label class="container-extra" rtlDiv [ngClass]="{'greenBorderColor' : options.isIncludeInWorkplan,'defaultBorderColor' : !options.isIncludeInWorkplan}">
        <input id="chbx_includeinworkplan" [(ngModel)]="options.isIncludeInWorkplan" [checked]="options.isIncludeInWorkplan" type="checkbox" (change)="toggleValue('isIncludeInWorkplan',options.isIncludeInWorkplan)" rtlDiv>
        <span class="checkmark" rtlDiv></span>
        <span class="label-chbx" [ngClass]="{'greenTextColor' : options.isIncludeInWorkplan,'defaultTextColor' : !options.isIncludeInWorkplan}" rtlDiv>{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</span>
      </label>
    </div>
    <div class="container-chbx" rtlDiv>
      <label class="container-extra" rtlDiv [ngClass]="{'greenBorderColor' : options.isBinRestrictionDays,'defaultBorderColor' : !options.isBinRestrictionDays}">
        <input id="chbx_binrestrictiondays" [(ngModel)]="options.isBinRestrictionDays" [checked]="options.isBinRestrictionDays" type="checkbox" (change)="toggleValue('isBinRestrictionDays',options.isBinRestrictionDays)" rtlDiv>
        <span class="checkmark" rtlDiv></span>
        <span class="label-chbx" [ngClass]="{'greenTextColor' : options.isBinRestrictionDays,'defaultTextColor' : !options.isBinRestrictionDays}" rtlDiv>
          @if (usageType == 1) {
            <span>{{ 'MANAGE_BIN.BIN_COLLECTION_DAYS' | translate }}</span>
          }
          @if (usageType != 1) {
            <span>{{ 'MANAGE_BIN.TANK_COLLECTION_DAYS' | translate }}</span>
          }
        </span>
      </label>
    </div>
  </div>
</div>
<div class="actions-btns" rtlDiv>
  <button class="cancel-btn" (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' | translate }}</button>
  <button class="next-btn" (click)="goNext()">{{ 'SITE_MANAGMENT.NEXT' | translate }}</button>
</div>
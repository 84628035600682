@if (!isMobile) {
  <div class="bin-info">
    <mat-card class="card-info">
      <mat-card-content>
        <div class='progress-bar-container' [hidden]="!bin">
          <div class="flex-data margin-btm">
            @if (bin && usageType == 1) {
              <div class='progress-bar-left item-responsive'>
                <img src="assets/images/binInfoPage/max_days.svg" alt="" />
                <div class="data">
                  @if (bin.Bin_thresholds.max_days_between_collections != 0) {
                    <div class='text-number' rtlDiv>{{bin.Bin_thresholds.max_days_between_collections}} {{'COLLECTION_REPORT.DAYS' | translate }}</div>
                  }
                  @if (bin.Bin_thresholds.max_days_between_collections == 0) {
                    <div class='text-number' rtlDiv>{{ 'LIVE_INFO.UNLIMITED' | translate }}</div>
                  }
                  <div class='text-title' rtlDiv>{{ 'MANAGE_BIN.MAX_DAYS' | translate }}</div>
                </div>
              </div>
            }
            @if (bin && usageType == 1) {
              <div class="middle-divider"></div>
            }
            @if (bin && usageType == 1) {
              <div class='progress-bar-left item-responsive'>
                @if (usageType == 1) {
                  <img src="assets/images/binInfoPage/max_allowed.svg" alt="" />
                }
                @else {
                  <img class="max-allowed-2" src="assets/images/binInfoPage/max_allowed2.svg" alt="" />
                }
                <div class="data">
                  @if (usageType == 1) {
                    <div class='text-number' rtlDiv>{{bin.Bin_thresholds.bin_max_allowed_fill_level_in_percent}}%</div>
                  }
                  @else {
                    <div class='text-number' rtlDiv>{{bin.Bin_thresholds.bin_min_allowed_fill_level_in_percent}}%</div>
                  }
                  @if (usageType == 1) {
                    <div class='text-title' rtlDiv>{{ 'MANAGE_BIN.MAX_CAPACITY' | translate }}</div>
                  }
                  @else {
                    <div class='text-title' rtlDiv>{{ 'MANAGE_BIN.MIN_CAPACITY' | translate }}</div>
                  }
                </div>
              </div>
            }
            @if (bin && usageType == 1) {
              <div class="middle-divider"></div>
            }
            @if (bin) {
              <div class='progress-bar-left item-responsive'>
                <img src="assets/images/binInfoPage/next_excepted.svg" alt="" />
                <div class="data">
                  <div class='text-number' rtlDiv>{{bin.Bin_live_Data.Transmission_data.expected_transmission_time}}</div>
                  <div class='text-title' rtlDiv>{{ 'LIVE_INFO.NEXT_EXPECTED_TRANS' | translate }}</div>
                </div>
              </div>
            }
            @if (bin && usageType == 2) {
              <div class="middle-divider"></div>
            }
            @if (bin && usageType == 2) {
              <div class='progress-bar-left item-responsive'>
                <img class="max-allowed-2" src="assets/images/binInfoPage/AvgDailyEmptyRateDunam.svg" alt="" />
                <div class="data">
                  <div class='text-title' rtlDiv>{{ 'LIVE_INFO.AVG_DAILY_EMPTYING_RATE_DUNAM' | translate }}</div>
                  @if (currentLang == 'iw') {
                    <div class='text-number' rtlDiv>{{bin.Bin_live_Data.average_daily_fill_rate_in_liters_per_dunam}} ל/ד</div>
                  }
                  @if (currentLang != 'iw') {
                    <div class='text-number' rtlDiv>{{bin.Bin_live_Data.average_daily_fill_rate_in_liters_per_dunam}} L/D</div>
                  }
                </div>
              </div>
            }
            @if (bin && usageType == 2) {
              <div class="middle-divider"></div>
            }
            @if (bin && usageType == 2) {
              <div class='progress-bar-left item-responsive'>
                <img class="max-allowed-2" src="assets/images/binInfoPage/PlanDailyEmptyRate.svg" alt="" />
                <div class="data">
                  <div class='text-title' rtlDiv>{{ 'LIVE_INFO.EXPECTED_EMPTYING_RATE' | translate }}</div>
                  @if (currentLang == 'iw') {
                    <div class='text-number' rtlDiv>{{bin.fertilizer_expected_usage_rate_in_liters_per_Dunam}} ל/ד</div>
                  }
                  @if (currentLang != 'iw') {
                    <div class='text-number' rtlDiv>{{bin.fertilizer_expected_usage_rate_in_liters_per_Dunam}} L/D</div>
                  }
                </div>
              </div>
            }
          </div>
          <div class="flex-data">
            @if (bin && usageType == 1) {
              <div class='progress-bar-left item-responsive'>
                <img src="assets/images/binInfoPage/collection_frequency.svg" alt="" />
                <div class="data">
                  <div class='text-number' rtlDiv>{{bin.Bin_live_Data.bin_collection_or_refill_rate.toFixed(0)}}</div>
                  <div class='text-title' rtlDiv>{{ 'MANAGE_BIN.MONTHLY_COLLECTION_FREQ' | translate }}</div>
                </div>
              </div>
            }
            @if (bin && usageType == 1) {
              <div class="middle-divider"></div>
            }
            <div class='progress-bar-left item-responsive'>
              <div class="circle">
                <svg class="desktop-progress" width="64" height="64" viewBox="0 0 120 120">
                  <circle class="progress__meter" cx="60" cy="60" r="55" stroke-width="10" />
                  <circle class="progress__value averageDaily" cx="60" cy="60" r="55" stroke-width="10" />
                </svg>
              </div>
              <div class="data">
                @if (usageType == 1 && bin) {
                  <div class='text-number' rtlDiv>{{bin.Bin_live_Data.average_daily_usage_rate_in_percent | number:'1.0-2'}}%</div>
                }
                @if (usageType == 2 && bin && currentLang != 'iw') {
                  <div class='text-number' rtlDiv>{{bin.Bin_live_Data.average_daily_fill_rate_in_liters | number:'2.1-2'}} L</div>
                }
                @if (usageType == 2 && bin && currentLang == 'iw') {
                  <div class='text-number' rtlDiv>{{bin.Bin_live_Data.average_daily_fill_rate_in_liters | number:'2.1-2'}} ל</div>
                }
                @if (usageType == 1) {
                  <div class='text-title' rtlDiv>{{ 'LIVE_INFO.AVG_RATE' | translate }}</div>
                }
                @if (usageType == 2) {
                  <div class='text-title' rtlDiv>{{ 'LIVE_INFO.EMPTY_RATE' | translate }}</div>
                }
              </div>
            </div>
            @if (bin && usageType == 1) {
              <div class="middle-divider"></div>
            }
            @if (bin && usageType == 1) {
              <div class='progress-bar-left item-responsive'>
                <div class="circle">
                  <svg class="desktop-progress" width="64" height="64" viewBox="0 0 120 120">
                    <circle class="progress__meter" cx="60" cy="60" r="55" stroke-width="10" />
                    <circle class="progress__value averageCapacity" cx="60" cy="60" r="55" stroke-width="10" />
                  </svg>
                </div>
                <div class="data">
                  @if (bin) {
                    <div class='text-number' rtlDiv>{{bin.Bin_live_Data.average_collections_capacity_in_percent.toFixed()}}%</div>
                  }
                  <div class='text-title' rtlDiv>{{ 'LIVE_INFO.AVG_COL_CAP' | translate }}</div>
                </div>
              </div>
            }
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
    <div class="accordion md-accordion" id="binEventsAc" role="tablist" aria-multiselectable="true">
      <div class="card mobile-card">
        <div class="collapse show" role="tabpanel" data-parent="#binEventsAc">
          <div class="card-body">
            <div>
              <div class='mobile-progress-bar-container' [hidden]="!bin" rtlDiv>
                @if (bin && usageType == 1) {
                  <div class='progress-bar-left'>
                    <img src="assets/images/binInfoPage/max_days.svg" alt="" />
                    <div class="data">
                      @if (bin.Bin_thresholds.max_days_between_collections != 0) {
                        <div class='text-number' rtlDiv>{{bin.Bin_thresholds.max_days_between_collections}} {{'COLLECTION_REPORT.DAYS' | translate }}</div>
                      }
                      @if (bin.Bin_thresholds.max_days_between_collections == 0) {
                        <div class='text-number' rtlDiv>{{ 'LIVE_INFO.UNLIMITED' | translate }}</div>
                      }
                      <div class='text-title' rtlDiv>{{ 'MANAGE_BIN.MAX_DAYS' | translate }}</div>
                    </div>
                  </div>
                }
                @if (bin && usageType == 1) {
                  <mat-divider class="mobile-middle-divider"></mat-divider>
                }
                @if (bin && usageType == 1) {
                  <div class='progress-bar-left'>
                    @if (usageType == 1) {
                      <img src="assets/images/binInfoPage/max_allowed.svg" alt="" />
                    }
                    @else {
                      <img class="max-allowed-2" src="assets/images/binInfoPage/max_allowed2.svg" alt="" />
                    }
                    <div class="data">
                      @if (usageType == 1) {
                        <div class='text-number' rtlDiv>{{bin.Bin_thresholds.bin_max_allowed_fill_level_in_percent}}%</div>
                      }
                      @else {
                        <div class='text-number' rtlDiv>{{bin.Bin_thresholds.bin_min_allowed_fill_level_in_percent}}%</div>
                      }
                      @if (usageType == 1) {
                        <div class='text-title' rtlDiv>{{ 'MANAGE_BIN.MAX_CAPACITY' | translate }}</div>
                      }
                      @else{
                        <div class='text-title' rtlDiv>{{ 'MANAGE_BIN.MIN_CAPACITY' | translate }}</div>
                      }
                    </div>
                  </div>
                }
                @if (bin && usageType == 1) {
                  <mat-divider class="mobile-middle-divider"></mat-divider>
                }
                @if (bin) {
                  <div class='progress-bar-left'>
                    <img src="assets/images/binInfoPage/next_excepted.svg" alt="" />
                    <div class="data">
                      <div class='text-number' rtlDiv>{{bin.Bin_live_Data.Transmission_data.expected_transmission_time}}</div>
                      <div class='text-title' rtlDiv> {{ 'LIVE_INFO.NEXT_EXPECTED_TRANS' | translate }}</div>
                    </div>
                  </div>
                }
                <mat-divider class="mobile-middle-divider"></mat-divider>
                <div class='progress-bar-left'>
                  <div class="circle">
                    <svg class="desktop-progress" width="64" height="64" viewBox="0 0 120 120">
                      <circle class="progress__meter" cx="60" cy="60" r="55" stroke-width="10" />
                      <circle class="progress__value averageDaily" cx="60" cy="60" r="55" stroke-width="10" />
                    </svg>
                  </div>
                  <div class="data">
                    @if (usageType == 1 && bin) {
                      <div class='text-number' rtlDiv>{{bin.Bin_live_Data.average_daily_usage_rate_in_percent.toFixed()}}%</div>
                    }
                    @if (usageType == 2 && bin && currentLang != 'iw') {
                      <div class='text-number' rtlDiv>{{bin.Bin_live_Data.average_daily_fill_rate_in_liters.toFixed()}} L</div>
                    }
                    @if (usageType == 2 && bin && currentLang == 'iw') {
                      <div class='text-number' rtlDiv>{{bin.Bin_live_Data.average_daily_fill_rate_in_liters.toFixed()}} ל</div>
                    }
                    @if (usageType == 1) {
                      <div class='text-title' rtlDiv>{{ 'LIVE_INFO.AVG_RATE' | translate }}</div>
                    }
                    @if (usageType == 2) {
                      <div class='text-title' rtlDiv>{{ 'LIVE_INFO.EMPTY_RATE' | translate }}</div>
                    }
                  </div>
                </div>
                @if (bin && usageType == 2) {
                  <mat-divider class="mobile-middle-divider"></mat-divider>
                }
                @if (bin && usageType == 2) {
                  <div class='progress-bar-left'>
                    <img class="max-allowed-2" src="assets/images/binInfoPage/AvgDailyEmptyRateDunam.svg" alt="" />
                    <div class="data">
                      <div class='text-title' rtlDiv>{{ 'LIVE_INFO.AVG_DAILY_EMPTYING_RATE_DUNAM' | translate }}</div>
                      @if (currentLang == 'iw') {
                        <div class='text-number' rtlDiv>{{bin.Bin_live_Data.average_daily_fill_rate_in_liters_per_dunam}} ל/ד</div>
                      }
                      @if (currentLang != 'iw') {
                        <div class='text-number' rtlDiv>{{bin.Bin_live_Data.average_daily_fill_rate_in_liters_per_dunam}} L/D</div>
                      }
                    </div>
                  </div>
                }
                @if (bin && usageType == 2) {
                  <mat-divider class="mobile-middle-divider"></mat-divider>
                }
                @if (bin && usageType == 2) {
                  <div class='progress-bar-left'>
                    <img class="max-allowed-2" src="assets/images/binInfoPage/PlanDailyEmptyRate.svg" alt="" />
                    <div class="data">
                      <div class='text-title' rtlDiv>{{ 'LIVE_INFO.EXPECTED_EMPTYING_RATE' | translate }}</div>
                      @if (currentLang == 'iw') {
                        <div class='text-number' rtlDiv>{{bin.fertilizer_expected_usage_rate_in_liters_per_Dunam}} ל/ד</div>
                      }
                      @if (currentLang != 'iw') {
                        <div class='text-number' rtlDiv>{{bin.fertilizer_expected_usage_rate_in_liters_per_Dunam}} L/D</div>
                      }
                    </div>
                  </div>
                }
                @if (bin && usageType == 1) {
                  <mat-divider class="mobile-middle-divider"></mat-divider>
                }
                @if (bin && usageType == 1) {
                  <div class='progress-bar-left'>
                    <div class="circle">
                      <svg class="desktop-progress" width="64" height="64" viewBox="0 0 120 120">
                        <circle class="progress__meter" cx="60" cy="60" r="55" stroke-width="10" />
                        <circle class="progress__value averageCapacity" cx="60" cy="60" r="55" stroke-width="10" />
                      </svg>
                    </div>
                    <div class="data">
                      @if (bin) {
                        <div class='text-number' rtlDiv>{{bin.Bin_live_Data.average_collections_capacity_in_percent.toFixed()}}%</div>
                      }
                      <div class='text-title' rtlDiv> {{ 'LIVE_INFO.AVG_COL_CAP' | translate }}</div>
                    </div>
                  </div>
                }
                @if (bin && usageType == 1) {
                  <mat-divider class="mobile-middle-divider"></mat-divider>
                }
                @if (bin && usageType == 1) {
                  <div class='progress-bar-left'>
                    <img src="assets/images/binInfoPage/collection_frequency.svg" alt="" />
                    <div class="data">
                      <div class='text-number' rtlDiv>{{bin.Bin_live_Data.bin_collection_or_refill_rate.toFixed(0)}}</div>
                      <div class='text-title' rtlDiv> {{ 'MANAGE_BIN.MONTHLY_COLLECTION_FREQ' | translate }}</div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}


import { Component, Input } from '@angular/core';
import { ResponsiveService } from '../../services/responsive.service';
import { CommonModule } from '@angular/common';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-progress-bar-color',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,CommonModule],
  templateUrl: './progress-bar-color.component.html',
  styleUrl: './progress-bar-color.component.scss'
})
export class ProgressBarColorComponent {
  isMobile: Boolean;

	@Input() progressWidth: string;
	@Input() progressHeight: string;
	@Input() backgroundColor: string;
	@Input() barColor: string;
	@Input() rangeLow: number;
	@Input() rangeHigh: number;
	@Input() percentage: string;

	constructor(private responsiveService: ResponsiveService) { }

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }


	getWrapperStyle = () => {
		if (!this.progressHeight || !this.progressWidth) { return; }
		return { 'width': this.progressWidth, 'height': this.progressHeight };
	}
	getProgressBarStyleWrapper = () => {
		if (!this.backgroundColor) { return; }
		return { 'background-color': this.backgroundColor };
	}
	getProgressBarStyleInternal = () => {
		if (!this.barColor || !this.percentage) { return; }
		return { 'background-color': this.barColor, 'width': this.percentage + '%' };
	}

}

{
  "name": "angular-databin",
  "version": "2.4.328",
  "scripts": {
    "ng": "ng",
    "start": "node server.js",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "build-dev": "ng build --configuration dev",
    "build-prod": "ng build --configuration production",
    "build-rc": "ng build --configuration rc",
    "echo-test": "echo test",
    "build-serve": "ng build && ng serve"
  },
  "private": true,
  "dependencies": {
    "@angular-magic/ngx-gp-autocomplete": "^2.0.2",
    "@angular/animations": "^17.2.0",
    "@angular/cdk": "^17.2.0",
    "@angular/common": "^17.2.0",
    "@angular/compiler": "^17.2.0",
    "@angular/core": "^17.2.0",
    "@angular/forms": "^17.2.0",
    "@angular/google-maps": "^17.2.1",
    "@angular/material": "^17.2.1",
    "@angular/platform-browser": "^17.2.0",
    "@angular/platform-browser-dynamic": "^17.2.0",
    "@angular/router": "^17.2.0",
    "@capacitor-community/background-geolocation": "^1.2.17",
    "@capacitor/android": "^6.0.0",
    "@capacitor/app": "^6.0.0",
    "@capacitor/core": "^6.0.0",
    "@capacitor/ios": "^6.0.0",
    "@capacitor/local-notifications": "^6.0.0",
    "@datorama/akita": "^7.1.1",
    "@googlemaps/js-api-loader": "^1.16.6",
    "@ionic/angular": "^7.8.3",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.11.8",
    "@types/google.maps": "^3.55.9",
    "@zxing/ngx-scanner": "^17.0.1",
    "angular-calendar": "^0.31.1",
    "angular-highcharts": "^17.0.1",
    "bootstrap": "^5.3.3",
    "chart.js": "^2.7.2",
    "chartjs-plugin-annotation": "^0.5.7",
    "crypto-js": "^4.2.0",
    "express": "^4.19.2",
    "highcharts": "^11.3.0",
    "ionic": "^5.4.16",
    "jquery": "^3.7.1",
    "lodash": "^4.17.21",
    "lottie-web": "^5.12.2",
    "material-design-icons": "^3.0.1",
    "moment": "^2.30.1",
    "ng-circle-progress": "^1.7.1",
    "ng-table-virtual-scroll": "^1.6.0",
    "ng2-nouislider": "^2.0.0",
    "ngx-lottie": "^11.0.1",
    "ngx-mat-select-search": "^7.0.5",
    "ngx-material-timepicker": "^13.1.1",
    "ngx-otp-input": "^0.11.4",
    "ngx-papaparse": "^8.0.0",
    "normalize.css": "^8.0.1",
    "nouislider": "^15.7.1",
    "pako": "^2.0.4",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "wnumb": "^1.1.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.2.2",
    "@angular/cli": "^17.2.2",
    "@angular/compiler-cli": "^17.2.0",
    "@capacitor/cli": "^6.0.0",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.14.202",
    "@types/node": "^20.11.22",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.3.2"
  }
}

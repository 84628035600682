import { Component, EventEmitter, Output } from '@angular/core';
import { TranslatorService } from '../../../services/translator_service';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';


@Component({
  selector: 'app-workplans-popup-approval',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective],
  templateUrl: './workplans-popup-approval.component.html',
  styleUrl: './workplans-popup-approval.component.scss'
})
export class WorkplansPopupApprovalComponent {
  @Output() approveCancelChangesEmitter = new EventEmitter<any>();
	currentLang;
  	translationsObj;
	translate;

	constructor(private translator: TranslatorService) {
		this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
	 }

	ngOnInit() {
	}

	public approveCancelChanges = (approve: Boolean) => {
		this.approveCancelChangesEmitter.emit(approve);
	}
}

import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { RouterModule, provideRouter } from '@angular/router';
import { TranslatorService } from './services/translator_service';
import { routes } from './app.routes';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, provideHttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from './services/auth.service';
import { ResponsiveService } from './services/responsive.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { UtilService } from './services/util.service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { PagerService } from './services/pager.service';
import {CalendarA11y, CalendarDateFormatter, CalendarModule, CalendarMomentDateFormatter,	DateAdapter} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import moment from 'moment';
import { CommonModule } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeHe from '@angular/common/locales/he';
import { GoogleMapsLoaderService } from './services/google-maps-loader.service';
registerLocaleData(localeHe);



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function playerFactory() {
	return player;
}  

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeApp(googleMapsLoaderService: GoogleMapsLoaderService): () => Promise<void> {
  return (): Promise<void> => googleMapsLoaderService.loadGoogleMaps();
}

export const provideTranslation = () => ({
  loader: {
    provide: TranslateLoader,
    useFactory: (createTranslateLoader),
    deps: [HttpClient]
  }
});
export function momentAdapterFactory() {
	return adapterFactory(moment);
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes), 
    // provideClientHydration(),
    provideHttpClient(),
    provideAnimations(),
    ResponsiveService,
    GoogleMapsLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [GoogleMapsLoaderService],
      multi: true
    },
    UtilService,
    AuthService,
    BrowserModule,
    TranslatorService,
    PagerService,
    CommonModule,
    HttpClient,
    importProvidersFrom([
      HttpClientModule, 
      TranslateModule.forRoot(provideTranslation())
    ]),
    provideLottieOptions({
      player: () => player,
    }),
    CalendarModule.forRoot(
			{
			  provide: DateAdapter,
			  useFactory: momentAdapterFactory,
			},
			{
			  dateFormatter: {
				provide: CalendarDateFormatter,
				useClass: CalendarMomentDateFormatter,
			  },
			}
		).providers!,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300
    }).providers!
  ]
};


<div class="dialog-forgot" mat-dialog-content>
  <img class="v-icon" src="assets/images/ic_check_circle.png" />
  <div class="text-forgot" >
    @if (!pass_sent) {
      <form class="form" name="form" (ngSubmit)="onCreatePass()" novalidate>
        <div class="form-group">
          <input type="text" class="form-control" name="username" [(ngModel)]="model.username"
            required placeholder="USERNAME_PLACEHOLDER"/>
            <button [disabled]="false" class="login-btn">login confirm</button>
          </div>
        </form>
      }
      @if (pass_sent) {
        <div class="text">
          request recived
        </div>
      }
    </div>
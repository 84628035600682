@if (!isMobile) {
  <div class="truck-workplans-list-view">
    <mat-card>
      <div class='spinner-container' [hidden]="!spinnerActive">
        <div class="spinner"></div>
      </div>
      <mat-card-header>
        <mat-card-title class="title">
          @if (usageType == 1) {
            <span>{{ 'FILLABLE_BINS.UNALLOCATED_BINS' | translate }}</span>
          }
          @if (usageType != 1) {
            <span>{{ 'FILLABLE_BINS.UNALLOCATED_TANKS' | translate }}</span>
          }
        </mat-card-title>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <mat-card-content>
        <div class='search-input-container'>
          <input class='search-input' type="text" (keyup)="applyFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
            name="" rtlDiv>
            <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
            <div class='table-export-container' rtlDiv>
              <div class='table-export-btns-wrapper' (click)="exportToCsv()">
                <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
              </div>
            </div>
          </div>
          <table class="table" mat-table #table multiTemplateDataRows [dataSource]="getTableData()" matSort matSortStart="asc" matSortActive="bin_name"
            matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row" rtlDiv>
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="th-title">{{ 'LIST_COLUMNS.NAME' | translate }} </th>
              <td mat-cell *matCellDef="let element">
                <a class="binNameHref" [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'workplans',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" [ngClass]="{'cut-txt' : element && element.bin_name.length > 11}"> {{element ? element.bin_name : ''}}</a>
              </td>
            </ng-container>
            <!-- Address Column -->
            <ng-container matColumnDef="address">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="address" class="th-title"> {{ 'LIST_COLUMNS.ADDRESS' | translate }}</th>
              <td mat-cell *matCellDef="let element" class="td-value"> {{element ? element.address: ''}} </td>
            </ng-container>
            <!-- id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_id" class="th-title"> {{ 'LIST_COLUMNS.ID' | translate }} </th>
              <td mat-cell *matCellDef="let element" class="td-value"> {{element ? element.bin_id: ''}} </td>
            </ng-container>
            <!-- current status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="current_status" class="th-title"> {{ 'LIST_COLUMNS.STATUS' | translate }} </th>
              <td mat-cell *matCellDef="let element" class="td-value">
                <div class="table-status-progress-bar-wrapper" [ngStyle]="getProgressBarStyleWrapper(element)">
                  <div class="table-status-progress-line" [ngStyle]="getProgressBarStyleInternal(element)"></div>
                </div>
                <div class='table-status-progress-bar-title' rtlDiv>{{element ? element.current_status: ''}}L</div>
              </td>
            </ng-container>
            <!-- size point Column -->
            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="size" class="th-title"> {{ 'LIST_COLUMNS.QUARTER_ACRE' | translate }} </th>
              <td mat-cell *matCellDef="let element" class="td-value"> {{element ? element.size : ''}} </td>
            </ng-container>
            <!-- last fill Column -->
            <ng-container matColumnDef="lastFill">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="last_fill" class="th-title"> {{ 'FILLABLE_BINS.LAST_FILL' | translate }} </th>
              <td mat-cell *matCellDef="let element" class="td-value"> {{element ? element.last_fill: ''}} </td>
            </ng-container>
            <!-- last update Column -->
            <ng-container matColumnDef="lastUpdate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="last_update" class="th-title"> {{ 'LIST_COLUMNS.LAST_UPDATE' | translate }} </th>
              <td mat-cell *matCellDef="let element" class="td-value"> {{element ? element.last_update: ''}} </td>
            </ng-container>
            <!-- empty rate Column -->
            <ng-container matColumnDef="emptyRate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="empty_rate" class="th-title"> {{ 'FILLINGS.AVG_DAILY_RATE' | translate }} </th>
              <td mat-cell *matCellDef="let element" class="td-value"> {{element.empty_rate | number:'2.1-2'}}L </td>
            </ng-container>
            <!-- filling amount Column -->
            <ng-container matColumnDef="fillingAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="Filling_amount" class="th-title"> {{ 'FILLABLE_BINS.FILLING_AMOUNT' | translate }} </th>
              <td mat-cell *matCellDef="let element" class="td-value"> {{element ? element.Filling_amount: ''}}L </td>
            </ng-container>
            <!-- manage-bin button Column -->
            <ng-container matColumnDef="manage">
              <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="manage"> </th>
              <td mat-cell *matCellDef="let element" class="manage-bins">
                <div class="manage-bin-button hide-manage-bin" (click)="allocateBin(element)" rtlDiv>
                  @if (usageType == 1) {
                    <span>{{'FILLABLE_BINS.ALLOCATED_BIN' | translate }}</span>
                  }
                  @if (usageType != 1) {
                    <span>{{'FILLABLE_BINS.ALLOCATED_TANK' | translate }}</span>
                  }
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr class="bins-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </mat-card-content>
        <div [hidden]="dataTableLength === 0">
          <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="6"></app-pagination>
        </div>
      </mat-card>
    </div>
  }


  @if (isMobile) {
    <div>
    </div>
  }
import { Component, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-google-maps-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, FormsModule, ReactiveFormsModule, GoogleMapsModule],
  templateUrl: './google-maps-modal.component.html',
  styleUrl: './google-maps-modal.component.scss'
})
export class GoogleMapsModalComponent {
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  markerIcon: string = '../../../../assets/images/bins/all/greenNotOrdered.svg';
  greenMarkerIcon : string = '../../../../assets/images/bins/all/greenNotOrdered.svg';
  yellowMarkerIcon : string = '../../../../assets/images/bins/all/yellowNotOrdered.svg';
  redMarkerIcon : string = '../../../../assets/images/bins/all/redNotOrdered.svg';
  greenMarkerIcon2 : string = '../../../../assets/images/bins/all/greenNotOrdered2.svg';
  yellowMarkerIcon2 : string = '../../../../assets/images/bins/all/yellowNotOrdered2.svg';
  redMarkerIcon2 : string = '../../../../assets/images/bins/all/redNotOrdered2.svg';
  
  center: google.maps.LatLngLiteral = {lat: 0, lng: 0};

  private geoCoder;
  ElForm: FormGroup;
  @Input() statusColor
  @Input() titleStr;
  @Input() usageType;
  @Input() lon;
  @Input() lat;
  public searchElementRef: ElementRef; 
 
  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder
  ) { 
    this.ElForm = this.fb.group({
      lat:'',
      lon: ''
    });
  }
 
 
  ngOnInit() {
    this.ElForm.controls["lat"].setValue("");    
    this.ElForm.controls["lon"].setValue("");   
  }

  loadAPIWrapper(map: any){
    this.setCurrentLocation(this.lon,this.lat);
    if(this.statusColor == 'RED'){
      this.usageType == 1 ? this.markerIcon = this.redMarkerIcon : this.markerIcon = this.redMarkerIcon2;
    }else if(this.statusColor == 'YELLOW'){
      this.usageType == 1 ? this.markerIcon = this.yellowMarkerIcon : this.markerIcon = this.yellowMarkerIcon2;
    }else{
      this.usageType == 1 ? this.markerIcon = this.greenMarkerIcon : this.markerIcon = this.greenMarkerIcon2;
    }
  }

  changeLoc(){
    this.latitude = Number(this.ElForm.getRawValue()['lat']);
    this.longitude = Number(this.ElForm.getRawValue()['lon']);
    this.getAddress(this.latitude, this.longitude);
  }
 
  private setCurrentLocation(lon,lat) {
      this.latitude = Number(lat);
      this.longitude = Number(lon);
      this.ElForm.controls["lat"].setValue(Number(this.latitude).toFixed(8));    
      this.ElForm.controls["lon"].setValue(Number(this.longitude).toFixed(8));  
      this.zoom = 8;
      this.getAddress(this.latitude, this.longitude);
  }
 
 
  markerDragEnd($event: any) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.ElForm.controls["lat"].setValue(this.latitude.toFixed(8));    
    this.ElForm.controls["lon"].setValue(this.longitude.toFixed(8));  
    this.getAddress(this.latitude, this.longitude);
  }
 
  getAddress(latitude, longitude) {
    this.geoCoder = new google.maps.Geocoder;
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        }
      }
    });
    this.center = {lat: latitude, lng: longitude};
  }

  closeModal(sendData) { 
    this.activeModal.close(sendData); 
  }

  saveLocation(){
    const data = {
      address: this.address,
      latitude: this.latitude,
      longitude: this.longitude
    }
    this.activeModal.close(data);
  }
}

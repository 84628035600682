@if (!isMobile) {
  <div class="app-bin-capacity">
    <div class='spinner-container' [ngClass]="{'hideGraph': !spinnerActive}">
      <div class="spinner"></div>
    </div>
    <mat-card>
      <mat-card-header>
        <mat-card-title class="title">
          <span>{{ 'TITLES.LIVE_CAPACITY' | translate }}</span>
        </mat-card-title>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <div class="tranmission-chx">
        <div class="dflex">
          <label class="container-extra" rtlDiv>
            <img class="transmission-img" src="assets/images/binInfoPage/transmission-black.svg" alt=""/>
            {{ 'LIVE_INFO.TRANSMISSION' | translate }}
            <input id="isTransmission_chc" [(ngModel)]="isTransmission" [checked]="isTransmission" type="checkbox" (change)="transmissionBtn(isTransmission)" rtlDiv>
            <span class="checkmark" rtlDiv></span>
          </label>
        </div>
      </div>
      <div class="transmission-hover-txt">
        {{ 'LIVE_INFO.TRANSMISSION_TXT' | translate }}
      </div>
      <div class="capacityData-container">
        @if (showDefaultCapacityData()) {
          <div class="default-data">
            <div class="default-img">
              <img src="assets/images/binInfoPage/emptyStateLiveInfo.svg" alt=""/>
            </div>
            <div class="empty-txt">
              {{ 'LIVE_INFO.DEFAULT_TXT_FOR_HOVER' | translate }}
            </div>
          </div>
        }
      </div>
      <mat-card-content id="capacity-container">
        <div style="height: 300px;" id="canvas-frame" [ngClass]="{'hideGraph': !seChart || spinnerActive}">
          <canvas id="canvas"></canvas>
        </div>
        <div class='chart-btns-wrapper' [ngClass]="{'hideGraph': !seChart || spinnerActive}">
          <div class="chart-btns-container">
            <button class='chart-btn prev' [ngClass]="{'disabled-btn': isTherePreviousWeek()}" [disabled]="isTherePreviousWeek()"
            (click)=goToPreviousWeek()>
            <img class='prev-img' src="assets/images/binInfoPage/preview_arrow.svg" alt="" />
            <div class="prev-title">{{ 'LIVE_INFO.PREVIOUS_WEEK' | translate }}</div>
          </button>
          <button class='chart-btn next' [ngClass]="{'disabled-btn': isThereNextWeek()}" [disabled]="isThereNextWeek()"
            (click)=goToNextWeek()>
            <div class="pre-title">{{ 'LIVE_INFO.NEXT_WEEK' | translate }}</div>
            <img class='next-img' src="assets/images/binInfoPage/next_arrow.svg" alt="" />
          </button>
          </div>
        </div>
        <div class="txt-font" [ngClass]="{'hideGraph' : seChart || spinnerActive}">{{ 'LIVE_INFO.NO_CAPACITY' | translate }}</div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
    <div class="accordion md-accordion" id="binCapacityAc" role="tablist" aria-multiselectable="true">
      <div class="card" [ngClass]="{'mobile-landscape':displayWeeks,'mobile-card':!displayWeeks}">
        <div class="card-header mobile-header" id="binCapacity">
          <div class="mobile-flex">
            <span class="mobile-title"> {{ 'TITLES.Mobile_LIVE_CAPACITY' | translate }}</span>
          </div>
        </div>
        <div class="collapse show" role="tabpanel" data-parent="#binCapacityAc">
          <div class="mobile-tranmission-chx">
            <div class="dflex">
              <label class="container-extra" rtlDiv>
                <img class="transmission-img" src="assets/images/binInfoPage/transmission-black.svg" alt=""/>
                {{ 'LIVE_INFO.TRANSMISSION' | translate }}
                <input [(ngModel)]="isTransmission" [checked]="isTransmission" type="checkbox" (change)="transmissionBtn(isTransmission)" rtlDiv>
                <span class="checkmark" rtlDiv></span>
              </label>
            </div>
          </div>
          <div class="capacityData-container">
            @if (showDefaultCapacityData()) {
              <div class="default-data">
                <div class="default-img">
                  <img src="assets/images/binInfoPage/emptyStateLiveInfo.svg" alt=""/>
                </div>
                <div class="empty-txt">
                  {{ 'LIVE_INFO.DEFAULT_TXT_FOR_CLICK' | translate }}
                </div>
              </div>
            }
          </div>
          <div class="card-body" id="capacity-container">
            @if (spinnerActive) {
              <div class="spinner-border mobile-spinner" role="status">
                <span class="sr-only"></span>
              </div>
            }
            <div style="height: 180px;" class="mobile-graph">
              <canvas class="mobile-canvas" id="canvas"></canvas>
            </div>
            <div (click)="goToWeekView('week')" class="mobile-btn" rtlDiv [hidden]="displayWeeks" [ngClass]="{'hideGraph' : !seChart || spinnerActive}">
              <img class="mobile-rotate" src="assets/images/dashboard/rotate.svg" alt=""/>
              <span class="btn-text">{{'WIDGETS.MOBILE_THIS_WEEK'| translate }} </span>
            </div>
            <div class="row-btns" [ngClass]="{'hideGraph' : !seChart || spinnerActive}">
              <button class='col-md-3 col-sm-3 col-lg-3 mobile-prev' [hidden]="!displayWeeks" [ngClass]="{'mobile-disable-btn': isTherePreviousWeek()}" [disabled]="isTherePreviousWeek()" (click)=goToPreviousWeek()>
                <img class='mobile-prev-arrow' src="assets/images/binInfoPage/preview_arrow.svg" alt="" />
                <span class="mobile-prev-txt">{{ 'LIVE_INFO.PREVIOUS_WEEK' | translate }}</span>
              </button>
              <button class='col-md-3 col-sm-3 col-lg-3 mobile-next' [hidden]="!displayWeeks" [ngClass]="{'mobile-disable-btn': isThereNextWeek()}" [disabled]="isThereNextWeek()" (click)=goToNextWeek()>
                <span class="mobile-next-txt">{{ 'LIVE_INFO.NEXT_WEEK' | translate }}</span>
                <img class='mobile-next-arrow' src="assets/images/binInfoPage/next_arrow.svg" alt="" />
              </button>
              <button (click)="goToWeekView('day')" class="col-md-3 col-sm-3 col-lg-3 mobile-daily" rtlDiv [hidden]="!displayWeeks">
                <img class="mobile-rotate" src="assets/images/dashboard/rotate.svg" alt=""/>
                <span class="mobile-daily-txt">{{'WIDGETS.MOBILE_THIS_DAY'| translate }} </span>
              </button>
            </div>
            <div class="txt-font" [ngClass]="{'hideGraph' : seChart || spinnerActive}">{{ 'LIVE_INFO.NO_CAPACITY' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Chart } from 'chart.js';
import moment from 'moment';
import { ApiService } from '../../../web-services/api/api.service';
import { TranslatorService } from '../../../services/translator_service';
import { ResponsiveService } from '../../../services/responsive.service';
import { UtilService } from '../../../services/util.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import _ from 'lodash';
import { RED_BIN_COLOR_THRESHOLD, YELLOW_BIN_COLOR_THRESHOLD } from '../../../constants';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import 'chartjs-plugin-annotation';

@Component({
  selector: 'app-bin-capacity',
  standalone: true,
  imports: [CommonModule,TranslateModule,RTLDivDirectiveDirective,MatDividerModule,MatCardModule,FormsModule],
  templateUrl: './bin-capacity.component.html',
  styleUrl: './bin-capacity.component.scss'
})
export class BinCapacityComponent {
  binInfo;
	capacity_lines = {};

	@Input()
	set binLiveInfo_Bin(bin_info:any) {
		if(bin_info != null){
			this.binInfo = bin_info;
			this.apiQuery.user$.subscribe((user) => {
				if (!user) return;
	
				this.capacityDisplayType = user["user_default_capacity_type_id"];

				if (this.capacityDisplayType != 0) {
					this.capacity_lines = {
						'redLine_fill': (this.binInfo.Bin_thresholds.bin_max_capacity_in_liters) * (this.binInfo.Bin_thresholds.yellow_status_fill_level_in_percent / 100) ,
						'yellowLine_fill': (this.binInfo.Bin_thresholds.bin_max_capacity_in_liters) * (this.binInfo.Bin_thresholds.green_status_fill_level_in_percent / 100)
					}
				}
				else {
					this.capacity_lines = {
						'redLine_fill': this.binInfo.Bin_thresholds.yellow_status_fill_level_in_percent,
						'yellowLine_fill': this.binInfo.Bin_thresholds.green_status_fill_level_in_percent
					}	
				}

				if(!this.isMobile){
					this.end_date = moment().startOf('day').format('YYYY-MM-DD 23:59:59');
					this.start_date = moment().startOf('day').subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss');
				}else{
					this.end_date = moment().format('YYYY-MM-DD 23:59:59');
					this.start_date = moment().subtract(1,'days').format('YYYY-MM-DD HH:mm:ss');
	
					this.rangeBtnEvent.emit(this.rangeBtn);
				}
					setTimeout(() => {
						this.apiService.binsCapacityData(this.binInfo["bin_id"], this.start_date, this.end_date);
					}, 0);
	
					this.apiQuery.capacityData$.subscribe(binCapacityData => {		
						if (!binCapacityData || binCapacityData.length == 0) return;
						this.parseBinCapacityData(binCapacityData);
					});
					this.numOfStepsAllowed = 4;
					this.currentStep = 1;	
			});
		}
	}
	@Output() rangeBtnEvent = new EventEmitter<boolean>();
	
	weekView:boolean = false;
	dayView:boolean = true;

	rangeBtn:boolean = true;
	isMobile: Boolean;
	daily_Start:string;
	daily_End:string;
	displayWeeks:boolean = false;
	currentStep: number;
	chart: Chart = null;
	fillLines = [];
	fromDate;
	toDate;
	maxToDate;
	maxFromDate;
	daysObj;
	daysObjTransmission;
	numOfStepsAllowed: any;
	start_date: string;
	end_date: string;
	currentLang;
	translationsObj;
	translateSub;
	spinnerActive: boolean;
	tooltipIndex: number = 0;
	tooltip;
	capacityDisplayType: number = 0;
	seChart : boolean;
	capacityData: Array<object> = [];
	capacityDataTransmission: Array<object> = [];
	isTransmission:boolean = true;

	constructor(private apiService: ApiService, private translator: TranslatorService,
		private responsiveService: ResponsiveService,private utilService: UtilService,
		private apiQuery: ApiQuery) {
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.spinnerActive = true;
		this.seChart = false;
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	  goToWeekView(range: string){
		this.displayWeeks = !this.displayWeeks;
		if(range === 'week'){
			if(this.isMobile){
				this.rangeBtn = false;
				document.getElementById('Mheader').style.display = 'none';
			}
			this.end_date = moment().startOf('day').format('YYYY-MM-DD 23:59:59');
			this.start_date = moment().startOf('day').subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss');

		}else if(range === 'day'){	
			if(this.isMobile){
				this.rangeBtn = true;
				document.getElementById('Mheader').style.display = 'flex';	
				setTimeout(function(){ 
					var el = document.getElementById('binCapacity');
					el.scrollIntoView({behavior: "smooth"});
				}, 100);
			}	
			this.end_date = moment().format('YYYY-MM-DD HH:mm:ss');
			this.start_date = moment().subtract(1,'days').format('YYYY-MM-DD HH:mm:ss');
		}
		this.rangeBtnEvent.emit(this.rangeBtn);
		setTimeout(() => {
			this.apiService.binsCapacityData(this.binInfo["bin_id"], this.start_date, this.end_date);
		}, 0);
		this.apiQuery.capacityData$.subscribe(binCapacityData => {		
			if (!binCapacityData || binCapacityData.length == 0) return;
			this.parseBinCapacityData(binCapacityData);
		});
		this.numOfStepsAllowed = 4;
		this.currentStep = 1;	

	  }

	ngOnDestroy() {
		if(this.chart){
			this.chart.destroy();
		}
	}

	public goToPreviousWeek = () => {
		if (this.currentStep < this.numOfStepsAllowed) {
			if (this.fromDate <= this.maxFromDate && this.maxFromDate <= this.toDate) return;
			this.toDate = this.toDate.endOf('day').subtract(7, 'days');
			this.fromDate = this.toDate.clone().endOf('day').subtract(6, 'days');
			this.initChart();
			this.currentStep++;
		}
	}

	public goToNextWeek = () => {
		const toDate = this.toDate.clone().add(7, 'days');
		const fromDate = toDate.clone().subtract(6, 'days');
		this.toDate = toDate;
		this.fromDate = fromDate;
		this.initChart();
		this.currentStep--;
	}

	private parseBinCapacityData = (binCapacityData) => {
		this.daysObj = this.resetDaysObject();
		this.daysObjTransmission = this.resetDaysObjectTransmission();
		this.capacityData = [];
		this.capacityDataTransmission = [];
		if (!_.isEmpty(binCapacityData[0])){
			_.each(binCapacityData, (item) => {
				this.capacityDataTransmission.push(item);
				_.each(item.transactions, (trans) => {
					trans.transmission_time = item.transmission_time;
					this.capacityData.push(trans);
				});
			});
		}

		this.maxToDate = moment();
		this.maxFromDate = this.maxToDate.clone().subtract(1, 'month');

		this.toDate = moment().endOf('day');
		this.fromDate = this.toDate.clone().subtract(6, 'days');
		this.fillLines = [
			{
				type: 'line',
				mode: 'horizontal',
				scaleID: 'y-axis-0',
				value: this.capacity_lines["redLine_fill"],
				borderColor: RED_BIN_COLOR_THRESHOLD,
				borderWidth: 1
			},
			{
				type: 'line',
				mode: 'horizontal',
				scaleID: 'y-axis-0',
				value: this.capacity_lines["yellowLine_fill"],
				borderColor: YELLOW_BIN_COLOR_THRESHOLD,
				borderWidth: 1
			}
		];
		if (this.capacityData.length > 0) {
			this.initChart();
			this.spinnerActive = false;
			this.seChart = true;
		}else{
			if(this.capacityData.length == 0){
				this.spinnerActive = false;
				this.seChart = false;
			}	
		}
	}

	private resetDaysObject = () => {
		return {
			days: [],
			daysHash: [],
			minMaxTime: [],
			daysArr: []
		};
	}

	private resetDaysObjectTransmission = () => {
		return {
			days: [],
			daysHash: [],
			minMaxTime: [],
			daysArr: []
		};
	}

	private getCapacityDataByRange = (): {} => {
		this.fromDate = this.fromDate.startOf('day');
		this.toDate = this.toDate.endOf('day');
		const binData = _.filter(this.capacityData, (bin) => {
			const binMeasurementTime = moment(bin["measurement_time"]);
			return binMeasurementTime >= this.fromDate && binMeasurementTime <= this.toDate;
		});
		return binData;
	}

	private getCapacityDataByRangeTransmission = (): {} => {
		this.fromDate = this.fromDate.startOf('day');
		this.toDate = this.toDate.endOf('day');
		const binData = _.filter(this.capacityDataTransmission, (bin) => {
			const binTransmissionTime = moment(bin["transmission_time"]);
			return binTransmissionTime >= this.fromDate && binTransmissionTime <= this.toDate;
		});
		return binData;
	}

	private getWeekMinMaxDayTime = (minWeekDate, maxWeekDate, binData) => {
		const minTime = `${moment(minWeekDate, 'DD.MM.YY').format('YYYY-MM-DD')} 00:00`;
		const maxTime = `${moment(maxWeekDate, 'DD.MM.YY').format('YYYY-MM-DD')} 23:59`;
		this.daysObj.minMaxTime = {
			minTime: minTime,
			maxTime: maxTime
		};
		if (_.isEmpty(binData)) {
			this.daysObj.daysArr = [minTime, maxTime];
			return;
		}
		this.daysObj.daysArr = _.map(binData, (data) => {
			return data.measurement_time;
		});
	}

	private getWeekMinMaxDayTimeTransmission = (minWeekDate, maxWeekDate, binData) => {
		const minTime = `${moment(minWeekDate, 'DD.MM.YY').format('YYYY-MM-DD')} 00:00`;
		const maxTime = `${moment(maxWeekDate, 'DD.MM.YY').format('YYYY-MM-DD')} 23:59`;
		this.daysObjTransmission.minMaxTime = {
			minTime: minTime,
			maxTime: maxTime
		};
		if (_.isEmpty(binData)) {
			this.daysObjTransmission.daysArr = [minTime, maxTime];
			return;
		}
		this.daysObjTransmission.daysArr = _.map(binData, (data) => {
			return data.transmission_time;
		});
	}

	private resetDayData = () => {
		this.daysObj.daysHash = [];
	}

	private resetDayDataTransmission = () => {
		this.daysObjTransmission.daysHash = [];
	}

	private getDayArrData = (binData: any) => {
		if (_.isEmpty(binData)) return;
		this.daysObj.daysHash = _.map(binData, (data) => {
			if (this.capacityDisplayType == 0) {
				return data.fill_level_in_precent;
			}
			else {
				return (parseInt(this.binInfo["Bin_thresholds"].bin_max_capacity_in_liters) * parseInt(data.fill_level_in_precent) / 100);
			}
		});
		this.daysObj.temperatures = _.map(binData, (data) => {
			return data.bin_temperature;
		});
		this.daysObj.humidity = _.map(binData, (data) => {
			return data.bin_humidity_level;
		});
		this.daysObj.transmissionTime = _.map(binData, (data) => {
			return data.transmission_time;
		});
	}

	private getDayArrDataTransmission = (binData: any) => {
		if (_.isEmpty(binData)) return;		
		this.daysObjTransmission.daysHash = _.map(binData, (data) => {
			return data.signal_quality == 31 ? 100 : ((data.signal_quality/30)*100).toFixed();
		});
		this.daysObjTransmission.numOfElements = _.map(binData, (data) => {
			return data.num_of_elements;
		});
		this.daysObjTransmission.transmissionTime = _.map(binData, (data) => {
			return data.transmission_time;
		});
		this.daysObjTransmission.transmissionCapacity = _.map(binData, (data) => {
			return data.transactions[data.transactions.length - 1].fill_level_in_precent;
		});
	}

	private initializeGraphDataTransmission = (): { daysLabel: string[], typeData: number[], num_of_elements: Number[],transmissionTimeData:any[],transmissionCapacity:any[] } => {
		this.resetDayDataTransmission();
		const capacityDataPerDate = this.getCapacityDataByRangeTransmission();
		this.getWeekMinMaxDayTimeTransmission(this.fromDate, this.toDate, capacityDataPerDate);
		this.getDayArrDataTransmission(capacityDataPerDate);
		return {
			daysLabel: this.daysObjTransmission.daysArr,
			typeData: this.daysObjTransmission.daysHash,
			num_of_elements: this.daysObjTransmission.numOfElements || [],
			transmissionTimeData: this.daysObjTransmission.transmissionTime || [],
			transmissionCapacity: this.daysObjTransmission.transmissionCapacity || []
		};
	}

	private initializeGraphData = (): { daysLabel: string[], typeData: number[], temperatureData: Number[], humidityData: Number[],transmissionTimeData:any[] } => {
		this.resetDayData();
		const capacityDataPerDate = this.getCapacityDataByRange();
		this.getWeekMinMaxDayTime(this.fromDate, this.toDate, capacityDataPerDate);
		this.getDayArrData(capacityDataPerDate);
		return {
			daysLabel: this.daysObj.daysArr,
			typeData: this.daysObj.daysHash,
			temperatureData: this.daysObj.temperatures || [],
			humidityData: this.daysObj.humidity || [],
			transmissionTimeData: this.daysObj.transmissionTime || []
		};
	}

	showDefaultCapacityData(){
		if(document.getElementById('chartjs-tooltip')){
			return false;
		}else{
			return true;
		}
	}

	private initChart = () => {
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
			this.translationsObj = await this.translator.getTranslation(value).toPromise();		 
			this.currentLang = value;
			if (this.chart) {
				this.chart.destroy();
			}
			if(document.getElementById("canvas") && !this.isMobile){
				document.getElementById("canvas").remove();
				let canvasDiv = document.createElement('canvas');
				canvasDiv.id = 'canvas';
				document.getElementById('canvas-frame').append(canvasDiv);
			}
			let suggestedMax = 100;
			let stepSize = 25;
			let capacityDisplaySymbol = '%';
			
			if (this.capacityDisplayType == 1 && this.binInfo != null) {
				suggestedMax 			= parseInt(this.binInfo["Bin_thresholds"].bin_max_capacity_in_liters);
				stepSize				= suggestedMax / 4;
				capacityDisplaySymbol	= 'L';
			}
			const graphData = this.initializeGraphData();
			const graphDataTransmission = this.initializeGraphDataTransmission();	
			var canvas: any = document.getElementById("canvas");
			const pointImage = new Image(16,16);
			pointImage.src = "assets/images/binInfoPage/transmission.svg";
			var sortedArr = (graphData.daysLabel).concat(graphDataTransmission.daysLabel);
			sortedArr.sort(function(a,b){
				return moment(moment(b).format("YYYY-MM-DD HH:mm:ss")).valueOf() - moment(moment(a).format("YYYY-MM-DD HH:mm:ss")).valueOf();
			});
			const graphDataArr = [];
			const graphDataTransmissionArr = [];
			for(let i=0,j=0;i<graphData.typeData.length,j<graphData.daysLabel.length;i++,j++){
				graphDataArr.push({x:graphData.daysLabel[j],y:graphData.typeData[i]});	
			}
			for(let i=0,j=0;i<graphDataTransmission.typeData.length,j<graphDataTransmission.daysLabel.length;i++,j++){
				graphDataTransmissionArr.push({x:graphDataTransmission.daysLabel[j],y:graphDataTransmission.typeData[i]});	
			}			
			let arrRadius = [];
			for(let i=0 ; i < graphDataArr.length ; i ++){
				arrRadius.push(2);
			}	
			let colors = [];
			for(let i=0 ; i < graphDataArr.length ; i ++){
				colors.push('black');
			}		
			if(canvas != undefined){
				var ctx = canvas.getContext('2d');
				this.chart = new Chart(ctx, {
					type: 'line',
					data: {
						labels: sortedArr,
						datasets: [{
							data: graphDataArr,
							borderColor: 'rgb(59, 73 , 69)',
							fill: false,
							pointBackgroundColor: colors,
							pointBorderColor: colors,
							borderWidth: 1,
							lineTension: 0,
							pointRadius: arrRadius,
							pointHitRadius: 4,
							pointHoverRadius: 4,
							temperatures: graphData.temperatureData,
							humidities: graphData.humidityData,
							transmissionTimes: graphData.transmissionTimeData
						},{
							data: graphDataTransmissionArr,
							borderColor: 'rgb(59, 73 , 69)',
							fill: false,
							showLine: false,
							pointBackgroundColor: 'blue',
							borderWidth: 1,
							lineTension: 0,
							pointRadius: 2,
							pointHitRadius: 4,							
							pointHoverRadius: 4,
							pointStyle: pointImage,		
							numOfElements: graphDataTransmission.num_of_elements,
							transmissionTimes: graphDataTransmission.transmissionTimeData,
							transmissionCapacity: graphDataTransmission.transmissionCapacity
						}]
					},
					options: {
						onHover: function(evt) {														
							var item = this.chart.getElementAtEvent(evt);
							if (item.length) {
								const firstPoint = item[0];
								if(firstPoint._options.backgroundColor == "blue"){
									for(let i=0 ; i <this.chart.data.datasets[0].transmissionTimes.length ; i++){
										if(this.chart.data.datasets[0].transmissionTimes[i] == firstPoint._chart.data.datasets[1].data[firstPoint._index].x){
											this.chart.data.datasets[0]['pointRadius'][i] = 4;
											this.chart.data.datasets[0]['pointBackgroundColor'][i] = '#00AEEF';
											this.chart.data.datasets[0]['pointBorderColor'][i] = '#00AEEF';
										}
									}
									this.chart.update();
								}														
							}else{
								const radius = _.filter(this.chart.data.datasets[0]['pointRadius'], (radius) => {									
									return radius == 4;
								});
								//if(radius.length > 0 && document.getElementById('chartjs-tooltip')){
									for(let i=0 ; i <this.chart.data.datasets[0]['pointRadius'].length ; i++){
										this.chart.data.datasets[0]['pointRadius'][i] = 2;	
										this.chart.data.datasets[0]['pointBackgroundColor'][i] = 'black';
										this.chart.data.datasets[0]['pointBorderColor'][i] = 'black';
									}
									if(document.getElementById('chartjs-tooltip')){
										document.getElementById('chartjs-tooltip').remove();
									}
									this.chart.update();
								//}								
							}
						  },
						hover: {
							mode: 'nearest'
						},
						maintainAspectRatio: false,
						legend: {
							display: false,
						},
						animation: false,
						scales: {
							yAxes: [{
								display: true,
								ticks: {
									stepSize: stepSize,
									suggestedMin: 0,
									suggestedMax: suggestedMax,
									callback: (value, index, values) => {
										if (capacityDisplaySymbol === '%') {
											if(this.currentLang == 'en' || this.currentLang == 'pl'){								
												return value.toString() + ' ' + capacityDisplaySymbol + ' ';
											}else{
												return capacityDisplaySymbol + ' ' + value.toString() + ' ';
											}
										}
										else {
											return value.toString() + ' ' + capacityDisplaySymbol + ' ';
										}
									},
								},
								radius: 25
							}],
							xAxes: [{
								gridLines: {
									display: false
								},
								scaleLabel: {
									display: true,
									labelString: this.isMobile ? "" : this.translationsObj.LIVE_INFO.CAPACITY_X_AXIS,
									fontColor: 'black',
									fontSize: 13,
									fontStyle: 'bold',
								},
								display: true,
								type: 'time',
								time: {							
									min: this.isMobile && this.rangeBtn ? new Date(moment().subtract(1,'days').format('YYYY-MM-DD HH:mm:ss')) :  new Date(this.daysObj.minMaxTime.minTime),
									max: new Date(this.daysObj.minMaxTime.maxTime),
									unit: 'day',
									unitStepSize: 1,
									displayFormats: { 'day': 'DD.MM.YY' }
								}
							}]
						},
						annotation: {
							annotations: this.fillLines,
							drawTime: "afterDatasetsDraw" 
						},
						tooltips: {							
							yAlign: 'bottom',
							enabled: false,
							custom: (tooltip) => {								
								tooltip.intersect = false;
								tooltip.datasets = [{
									data: graphData.typeData,		
									temperatures: graphData.temperatureData,
									humidities: graphData.humidityData,
									transmissionTimes: graphData.transmissionTimeData
								},{
									data: graphDataTransmission.typeData,
									numOfElements: graphDataTransmission.num_of_elements,
									transmissionTimes: graphDataTransmission.transmissionTimeData,
									transmissionCapacity: graphDataTransmission.transmissionCapacity
								}];
								tooltip.dataPoints = [];
		
								var tooltipEl = document.getElementById('chartjs-tooltip');
		
								if (!tooltipEl) {
									tooltipEl = document.createElement('div');
									tooltipEl.id = 'chartjs-tooltip';
									tooltipEl.style.width = "318px";
									tooltipEl.style.height = "132px";
									tooltipEl.style.border = "1px solid #D9D9D9";
									tooltipEl.style.borderRadius = "12px";
									tooltipEl.innerHTML = "<div></div>";
									document.getElementsByClassName('capacityData-container')[0].appendChild(tooltipEl);
								}
								
								if (tooltip.opacity == 0) {
									tooltipEl.style.display = 'none';
									document.getElementById('chartjs-tooltip').remove();
									return;
								}else{
								}
		
								tooltipEl.classList.remove('above', 'below', 'no-transform');
								if (tooltip.yAlign) {
									tooltipEl.classList.add(tooltip.yAlign);
								} else {
									tooltipEl.classList.add('no-transform');
								}
		
								function getBody(bodyItem) {									
									let value;
									if(bodyItem.lines.length == 0){
										value = 0;
									}else{
										value = bodyItem.lines;
									}	
									if(tooltip.labelColors[0].backgroundColor == "black" || tooltip.labelColors[0].backgroundColor == "#00AEEF"){
										return '<div style="display:flex;justify-content: space-between;font-weight:bold;font-size:20px;"><span style="color: #2A3338; font-family: Open Sans, sans-serif;	font-size: 16px; font-weight: 700;	line-height: 16px; align: left;display:flex;align-items:center;width:30%;position:relative;justify-content:space-evenly">' + '<img src="assets/images/binInfoPage/volumeLiveInfo.svg" alt=""/>' + tooltip.datasets[0].data[value] + capacityDisplaySymbol + '</span><div style="height: 42px;width: 1px;border-right: 1px solid #979797;opacity: 0.3;top:10px;position:relative"></div><span style="color: #2A3338;font-family: Open Sans, sans-serif;	font-size: 16px;	font-weight: 700;	line-height: 16px;display:flex;align-items:center;width:30%;position:relative;justify-content:space-evenly">' + '<img src="assets/images/binInfoPage/temperatureLiveInfo.svg" alt=""/>' + tooltip.datasets[0].temperatures[value]
										+ '°</span><div style="height: 42px;width: 1px;border-right: 1px solid #979797;opacity: 0.3;top:10px;position:relative"></div><span style="color: #2A3338; font-family: Open Sans, sans-serif;	font-size: 16px; font-weight: 700;	line-height: 16px;display:flex;align-items:center;width:30%;position:relative;justify-content:space-evenly">' + '<img src="assets/images/binInfoPage/humidityLiveInfo.svg" alt=""/>' + tooltip.datasets[0].humidities[value] + '%</span></div>';
									}else{
										return '<div style="display:flex;justify-content: space-between;font-weight:bold;font-size:20px;"><span style="color: #2A3338; font-family: Open Sans, sans-serif;	font-size: 16px; font-weight: 700;	line-height: 16px; align: left;display:flex;align-items:center;width:30%;position:relative;justify-content:space-evenly">' + '<img src="assets/images/binInfoPage/volumeLiveInfo.svg" alt=""/>' + tooltip.datasets[1].transmissionCapacity[value] + capacityDisplaySymbol + '</span><div style="height: 42px;width: 1px;border-right: 1px solid #979797;opacity: 0.3;top:10px;position:relative"></div><span style="color: #2A3338;font-family: Open Sans, sans-serif;	font-size: 16px;	font-weight: 700;	line-height: 16px;display:flex;align-items:center;width:30%;position:relative;justify-content:space-evenly">' + '<img src="assets/images/binInfoPage/signalQuality.svg" alt=""/>' + tooltip.datasets[1].data[value] + capacityDisplaySymbol
										+ '</span><div style="height: 42px;width: 1px;border-right: 1px solid #979797;opacity: 0.3;top:10px;position:relative"></div><span style="color: #2A3338; font-family: Open Sans, sans-serif;	font-size: 16px; font-weight: 700;	line-height: 16px;display:flex;align-items:center;width:30%;position:relative;justify-content:space-evenly">' + '<img src="assets/images/binInfoPage/numOfSamples.svg" alt=""/>' + tooltip.datasets[1].numOfElements[value] + '</span></div>';
									}																																													
								}

								function getTransmissions(bodyItem) {
									let value;
									if(bodyItem.lines.length == 0){
										value = 0;
									}else{
										value = bodyItem.lines;
									}		
									if(tooltip.labelColors[0].backgroundColor == "black" || tooltip.labelColors[0].backgroundColor == "#00AEEF"){
										return tooltip.datasets[0].transmissionTimes[value];
									}else{
										return tooltip.datasets[1].transmissionTimes[value];
									}																										
								}
		
								if (tooltip.body) {									
									var titleLines = tooltip.title || [];
									var bodyLines = tooltip.body.map(getBody);									
									var transmissions = tooltip.body.map(getTransmissions);
									var innerHtml = '';

									if(tooltip.labelColors[0].backgroundColor == "black" || tooltip.labelColors[0].backgroundColor == "#00AEEF"){
										innerHtml = '<div style="padding:13px 26px">';
									}else{
										innerHtml = '<div style="padding:21px 26px">';
									}
									
									if(tooltip.labelColors[0].backgroundColor == "black" || tooltip.labelColors[0].backgroundColor == "#00AEEF"){
										titleLines.forEach((title) => {
											const date = moment(title);
											const dateStr = this.currentLang == 'iw' ? this.utilService.convertDateHebrewWithoutTime(date) : this.utilService.convertDateEnglishWithoutTime(date);									
											const timeStr = this.currentLang != 'iw' ? date.format('LT') : date.format('HH:mm');
											this.currentLang == 'iw' ? innerHtml += '<div style="direction:rtl;color: #485760;font-family: Open Sans, sans-serif;	font-size: 12px;font-weight: 400;padding:10px 0px 5px 0px">' 
											+ '' + dateStr + ' ' + timeStr + '</div>' : innerHtml += '<div style="direction:ltr;color: #485760;font-family: Open Sans, sans-serif;	font-size: 12px;font-weight: 400;padding:10px 0px 5px 0px">' 
											+ '' + dateStr + ' ' + timeStr + '</div>';
											
										});
									}
		
									innerHtml += '<div>' + bodyLines[0] + '</div>';
									if(tooltip.labelColors[0].backgroundColor == "black" || tooltip.labelColors[0].backgroundColor == "#00AEEF"){
										innerHtml += '<div style="display:flex;justify-content: space-between;	color: #485760;	font-family: Open Sans, sans-serif;	font-size: 12px;line-height: 11px;"><div style="width:30%;justify-content:center;display:flex;font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600;font-size: 12px;color: #485760;opacity: 0.8;text-align: center">' + this.translationsObj.LIVE_INFO.CAPACITY + '</div><div style="width:30%;justify-content:center;display:flex;font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600;font-size: 12px;color: #485760;opacity: 0.8;text-align: center">' + this.translationsObj.LIVE_INFO.TEMPERATURE + '</div><div style="width:30%;justify-content:center;display:flex;font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600;font-size: 12px;color: #485760;opacity: 0.8;text-align: center">' + this.translationsObj.LIVE_INFO.HUMIDITY + '</div></div>';
									}else{
										innerHtml += '<div style="display:flex;justify-content: space-between;	color: #485760;	font-family: Open Sans, sans-serif;	font-size: 12px;line-height: 11px;"><div style="width:30%;justify-content:center;display:flex;font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600;font-size: 12px;color: #485760;opacity: 0.8;text-align: center">' + this.translationsObj.LIVE_INFO.CAPACITY + '</div><div style="width:30%;justify-content:center;display:flex;font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600;font-size: 12px;color: #485760;opacity: 0.8;text-align: center">' + this.translationsObj.SITE_MANAGMENT.SIGNAL_QUAL + '</div><div style="width:30%;justify-content:center;display:flex;font-family: Open Sans, sans-serif;font-style: normal;font-weight: 600;font-size: 12px;color: #485760;opacity: 0.8;text-align: center">' + this.translationsObj.SITE_MANAGMENT.NUM_OF_SAMPLES + '</div></div>';
									}

									const date = moment(transmissions[0]);
									const dateStr = this.currentLang == 'iw' ? this.utilService.convertDateHebrewWithoutTime(date) : this.utilService.convertDateEnglishWithoutTime(date);									
									const timeStr = date.format('HH:mm:ss');	
									if(tooltip.labelColors[0].backgroundColor == "black" || tooltip.labelColors[0].backgroundColor == "#00AEEF"){
										this.currentLang == 'iw' ? innerHtml += '<div style="direction:rtl;display:flex; color: #485760;font-family: Open Sans, sans-serif;	font-size: 10px;font-weight: 400;padding:14px 0px 0px 0px"><div>' + this.translationsObj.LIVE_INFO.TRANSMISSION_TIME + `<span style="margin-left:3px;">:</span>` +'</div><div>' + ' ' + dateStr + ' ' + timeStr + '</div></div>' :	innerHtml += '<div style="direction:ltr;display:flex; color: #485760;font-family: Open Sans, sans-serif;	font-size: 10px;font-weight: 400;padding:14px 0px 0px 0px"><div>' + this.translationsObj.LIVE_INFO.TRANSMISSION_TIME + `<span style="margin-right:3px;">:</span>` +'</div><div>' + '' + dateStr + ' ' + timeStr + '</div></div>';																
									}else{
										this.currentLang == 'iw' ? innerHtml += '<div style="direction:rtl;display:flex; color: #485760;font-family: Open Sans, sans-serif;	font-size: 10px;font-weight: 400;padding:14px 0px 0px 0px"><div>' + this.translationsObj.LIVE_INFO.TRANSMISSION_TIME + `<span style="margin-left:3px;">:</span>` +'</div><div>' + ' ' + dateStr + ' ' + timeStr + '</div></div>' :	innerHtml += '<div style="direction:ltr;display:flex; color: #485760;font-family: Open Sans, sans-serif;	font-size: 10px;font-weight: 400;padding:14px 0px 0px 0px"><div>' + this.translationsObj.LIVE_INFO.TRANSMISSION_TIME + `<span style="margin-right:3px;">:</span>` +'</div><div>' + '' + dateStr + ' ' + timeStr + '</div></div>';																
									}

									innerHtml += '</div>';
									var tableRoot = document.getElementById('chartjs-tooltip');
									tableRoot.innerHTML = innerHtml;
								}
		
								tooltipEl.style.zIndex = '99999999999';
								tooltipEl.style.direction = 'initial';
								tooltipEl.style.display = 'block';
								tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
								tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
								tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
							},
							callbacks: {
								label: function(tooltipItem, data) {
									return tooltipItem.index;
								}
							}
						}
					}
				});
			}
		});
	}

	transmissionBtn(value){
		this.chart.data.datasets[1].hidden = !this.chart.data.datasets[1].hidden;
		// this.chart.data.datasets.forEach(function(ds) {
		// 	if(ds.pointBackgroundColor == "blue"){
		// 		ds.hidden = !ds.hidden;
		// 	}
		// });
		this.chart.update();
	}


	public isThereNextWeek = () => {
		return this.currentStep === 1;
	}
	public isTherePreviousWeek = () => {
		return this.currentStep === this.numOfStepsAllowed;
	}
}

import { Component, Inject } from '@angular/core';
import { BulkEditModalComponent } from '../bulk-edit-modal/bulk-edit-modal.component';
import { ApiQuery } from '../../../web-services/api/api.query';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-manage-bins-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,MatSelectModule,NgbModule,MatInputModule],
  templateUrl: './manage-bins-modal.component.html',
  styleUrl: './manage-bins-modal.component.scss'
})
export class ManageBinsModalComponent {
  PartialManagement: boolean = false;
	isAdmin: boolean = false;
  sameBinsSite:boolean = true;
	usageType = 1;
  options = {
    isAll : false,
    isSite : false,
    isNeighborhood : false,
    isMaxDays : false,
    isBinMaxCapLiter : false,
    isThresholdLevel : false,
    isMaxCapacity : false,
    isBinRestrictionsTimes:false,
    isIncludeInWorkplan : false,
    isBinRestrictionDays : false
  }

  constructor(private dialogRef: MatDialogRef<ManageBinsModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
  private dialog: MatDialog,private apiQuery:ApiQuery) { 
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {    
    if(this.data.chosenBins && this.data.chosenBins.length > 0){
      let checkItem = this.data.chosenBins[0];
      _.each(this.data.chosenBins, (item) => {
				if(item.site_id != checkItem.site_id){
          this.sameBinsSite = false;
				}
			});
    }    

    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}
			
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
      if ((user["user_role_id"] == 2) || (user["user_role_id"] == 1) || (user["user_role_id"] == 5) || (user["user_role_id"] == 6)) {
				this.isAdmin = true;
			}
      if (user["user_role_id"] == 4) {
				this.PartialManagement = true;
			}
		});
  }

  toggleValue(str,value) {	
    if(str == 'isAll' && value){
      if(this.PartialManagement){
        this.options["isAll"] = true;
        this.options["isSite"] = true;
        this.options["isNeighborhood"] = true;
        this.options["isIncludeInWorkplan"] = true;
        this.options["isBinRestrictionDays"] = true;
      }else{
        Object.keys(this.options).forEach(key => {
          this.options[key] = true;
        });
      }
    }else if(str == 'isAll' && !value){
      Object.keys(this.options).forEach(key => {
        this.options[key] = false;
      });
    }else if(str != 'isAll' && !this.allTrueInsteadOfAll()){
      this.options["isAll"] = false;
      this.options[str] = value;
    }else{
      this.options["isAll"] = true;
      this.options[str] = value;
    }
    if(!this.sameBinsSite || this.usageType != 1){
      this.options["isSite"] = false;
      this.options["isNeighborhood"] = false;
    }
  }

  allTrueInsteadOfAll(){
    if(!this.PartialManagement){
      for(var key in this.options)
      {
        if(!this.options[key] && key != 'isAll') return false;
      }
    }else{
      if(!this.options["isSite"] && key != 'isAll') return false;
      if(!this.options["isNeighborhood"] && key != 'isAll') return false;
      if(!this.options["isIncludeInWorkplan"] && key != 'isAll') return false;
      if(!this.options["isBinRestrictionDays"] && key != 'isAll') return false;
    }
    return true;
  }

  closeModal(){
    this.dialogRef.close();
  }

  goNext(){
		this.dialog.open(BulkEditModalComponent, { panelClass: 'bulkBins-container' , data: {chosenBins : this.data.chosenBins, properties : this.options, parent: this} });
  }

}

import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface apiState {
  userAccounts: Array<object>;
  capacityDisplayTypes: Array<object>;
  usageTypes: Array<object>;
  clusterTypes: Array<object>;
  ticketEventsCause: Array<object>;
  ticketEventsType: Array<object>;
  userEvents: Array<object>;
  optimizationTypes: Array<object>;
  installationAccessoryTypes:Array<object>;
  userMessages:Array<object>;
  preInstallationMappingInfo: Array<object>;
  preMappingInfo: Array<object>;
  binsClusterInfoBySiteId: Array<object>;
  binTypes:Array<object>;
  filteredUsers :Array<object>;
  filteredBins :Array<object>;
  allFilteredBins :Array<object>;
  filteredBasicBins:Array<object>;
  allFilteredBasicBins:Array<object>;
  filteredTrucks:Array<object>;
  allBinCapacityAnalysis:Array<object>;
  binCapacityAnalysis:Array<object>;
  filteredDailyWorkPlan :Array<object>;
  allFilteredDailyWorkPlan :Array<object>;
  allSitesClustersList: Array<object>;
  sitesClustersList: Array<object>;
  allNeighborhoodsList: Array<object>;
  neighborhoodsList: Array<object>;
  allCRTicketsList:Array<object>;
  cRTicketsList:Array<object>;
  allInsTicketsList:Array<object>;
  insTicketsList:Array<object>;
  allRmTicketsList:Array<object>;
  rmTicketsList:Array<object>;
  allTicketsList:Array<object>;
  ticketsList:Array<object>;
  allRefillsStats:Array<object>;
  refillsStats:Array<object>;
  allCollectionsStatsForWorkplanProgres:Array<object>;
  CollectionsStatsForWorkplanProgres:Array<object>;
  CollectionsStatsForWorkplans:Array<object>;
  CollectionsStatsForInsights:Array<object>;
  collectionsStatsInCollections:Array<object>;
  collectionsStats:Array<object>;
  allCollectionsStats:Array<object>;
  collectionsStatsToHighlightPicker:Array<object>;
  collectionsStatsToday:Array<object>;
  allCollectionsStatsToday:Array<object>;
  exceptionsReports:Array<object>;
  driverExceptionsReports:Array<object>;
  collectionVolume:Array<object>;
  collectionsKpis:Array<object>;
  allCollectionsKpis:Array<object>;
  allRefillsKpis:Array<object>;
  refillsKpis:Array<object>;
  userEventsByType:Array<object>;
  userMessagesByType:Array<object>;
  userAlertsByType:Array<object>;
  dailyWorkPlanCalanderData:Array<object>;
  allDailyWorkPlanCalanderData:Array<object>;
  workPlanInfo:Array<object>;
  sitesListData :Array<object>;
  allSitesListData :Array<object>;
  workPlanScheduler:Array<object>;
  allWorkPlanScheduler:Array<object>;
  binsCapacityData:Array<object>;
  capacityData:Array<object>;
  workPlanDistrebutionMethods:Array<object>;
  pendingStatus:Array<object>;
  customerWorkPlanCollectionschedulePolicy:Array<object>;
  filteredBinManagmentInfo:Array<object>;
  accountsList:Array<object>;
  customerAccounts:Array<object>;
  reasonTypes:Array<object>;
  unAssignBins:Array<object>;
  analysisTypes:Array<object>;
  removeOrReplaceTypes:Array<object>;
  selectedWorkplan:object;
  createdWorkplan:Array<object>;
  selectedTicket:object;
  selectedStatusPieChart:object;
  selectedReportPieChart:object;
  selectedInsightsPieChart:object;
  selectedInsightsSamplingChart:object;
  userRoles:Array<object>;
  navigationInfo:object;
  preMappingTableFilteredValue:string;
  binEvents: Array<object>;
  siteInfo: object;
  updatedPassword: number;
  validation: number;
  nextClusterIdInDb: number;
  putNewBinClusterIdResponse: any;
  wasteTypesList: Array<object>;
  deviceIdState: boolean;
  binLastTransId: any;
  latLngExistsResponse: any;
  gmtTimeZone: any;
  siteClustersList: any;
  installationStatusResponse: any;
  transErrorTrace: any;
  performRestoreSwapDevices: any;
  deviceIdStatus: any;
  binResetResponse: any;
  binInfo: any;
  user: object;
  binCollectionData: Array<object>;
  binCollection:any;
}

export const initialapiState: apiState = {
  userAccounts: [],
  capacityDisplayTypes: [],
  usageTypes: [],
  clusterTypes: [],
  ticketEventsCause:[],
  ticketEventsType:[],
  userEvents:[{}],
  optimizationTypes:[],
  installationAccessoryTypes:[],
  userMessages:[{}],
  preInstallationMappingInfo: [{}],
  preMappingInfo: [{}],
  binsClusterInfoBySiteId:[{}],
  binTypes:[{}],
  filteredBins:[],
  filteredUsers:[],
  allFilteredBins:[],
  filteredBasicBins:[],
  allFilteredBasicBins:[],
  filteredTrucks:[],
  allBinCapacityAnalysis:[],
  binCapacityAnalysis:[],
  filteredDailyWorkPlan:[],
  allFilteredDailyWorkPlan:[],
  allSitesClustersList: [], 
  sitesClustersList: [],
  allNeighborhoodsList: [], 
  neighborhoodsList: [], 
  allCRTicketsList:[],
  cRTicketsList:[],
  allInsTicketsList:[],
  insTicketsList:[],
  allRmTicketsList:[],
  rmTicketsList:[],
  allTicketsList:[],
  ticketsList:[],
  allRefillsStats:[],
  refillsStats:[],
  allCollectionsStatsForWorkplanProgres:[],
  CollectionsStatsForWorkplanProgres:[],
  CollectionsStatsForWorkplans:[],
  CollectionsStatsForInsights:[],
  collectionsStatsInCollections:[],
  collectionsStats:[],
  allCollectionsStats:[],
  collectionsStatsToHighlightPicker:[],
  collectionsStatsToday:[],
  allCollectionsStatsToday:[],
  exceptionsReports:[],
  driverExceptionsReports:[],
  collectionVolume:[],
  collectionsKpis:[],
  allCollectionsKpis:[],
  allRefillsKpis:[],
  refillsKpis:[],
  userEventsByType:[],
  userMessagesByType:[{}],
  userAlertsByType:[{}],
  dailyWorkPlanCalanderData:[],
  allDailyWorkPlanCalanderData:[],
  workPlanInfo:[],
  sitesListData:[],
  allSitesListData:[],
  workPlanScheduler:[],
  allWorkPlanScheduler:[],
  binsCapacityData:[],
  capacityData:[],
  workPlanDistrebutionMethods:[],
  pendingStatus:[],
  customerWorkPlanCollectionschedulePolicy:[],
  filteredBinManagmentInfo:[],
  accountsList:[],
  customerAccounts:[],
  reasonTypes:[],
  unAssignBins:[],
  analysisTypes:[],
  removeOrReplaceTypes:[],
  selectedWorkplan:{},
  createdWorkplan:[],
  selectedTicket:{},
  selectedStatusPieChart:{},
  selectedReportPieChart:{},
  selectedInsightsPieChart:{},
  selectedInsightsSamplingChart:{},
  userRoles:[],
  navigationInfo:{},
  preMappingTableFilteredValue:'',
  binEvents: [],
  siteInfo: {},
  updatedPassword: 0,
  validation: 0,
  nextClusterIdInDb: 0,
  putNewBinClusterIdResponse: 0,
  wasteTypesList: [],
  deviceIdState: false,
  binLastTransId: null,
  latLngExistsResponse: null,
  gmtTimeZone: null,
  siteClustersList: null,
  installationStatusResponse: null,
  transErrorTrace: null,
  performRestoreSwapDevices: null,
  deviceIdStatus: null,
  binResetResponse: null,
  binInfo: null,
  user: {},
  binCollectionData:[],
  binCollection:null
};

@Injectable({
  providedIn: 'root'
})
@StoreConfig({name: 'api'})
export class ApiStore extends Store<apiState> {

  constructor() {
    super(initialapiState);

  }
}

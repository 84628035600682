@if (!isMobile) {
  <div>
    <div class="click-zone"></div>
    <div class="header-container padded">
      <mat-toolbar>
        <mat-toolbar-row class="mat-toolbar-row-header" rtlDiv>
          <app-sites-menu [ngClass]="{'hide-DropDown':user_role_id==7}"></app-sites-menu>
          <div class="secondary-header">
            <span class="notification-icon notify" (click)="refreshWebSite()" [ngClass]="{'hide-DropDown':user_role_id==7}">
              <img src="assets/images/topmenu/refresh.svg" alt="" class="bell-img" />
            </span>
            <app-languages-menu></app-languages-menu>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  </div>
}

@if (isMobile) {
  <div rtlDiv class="mobile-header" id="Mheader">
    <span class="mobile-img-frame" rtlDiv [ngClass]="{'hide-DropDown':user_role_id==7}">
      <app-sites-menu></app-sites-menu>
    </span>
    <app-sidebar class="sidebar-item"></app-sidebar>
  </div>
}
import { Component, Input } from '@angular/core';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';


@Component({
  selector: 'app-workplan-date',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, MatDividerModule],
  templateUrl: './workplan-date.component.html',
  styleUrl: './workplan-date.component.scss'
})
export class WorkplanDateComponent {
  isMobile: Boolean;
  displayedDate:string;
  @Input() dateSelected;

  constructor(private responsiveService: ResponsiveService) { }

  ngOnInit() {
    this.onResize();
		this.responsiveService.checkWidth();
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}
}

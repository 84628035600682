import { Component, Input, ViewChild } from '@angular/core';
import { API } from '../../../types.api';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ResponsiveService } from '../../../services/responsive.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps'

@Component({
  selector: 'app-bin-location-description',
  standalone: true,
  imports: [CommonModule,TranslateModule,RTLDivDirectiveDirective,MatCardModule,MatDividerModule,
    GoogleMapsModule],
  templateUrl: './bin-location-description.component.html',
  styleUrl: './bin-location-description.component.scss'
})
export class BinLocationDescriptionComponent {
  isMobile: boolean = false;
	@Input() orientation: boolean;
	binInfo;
	@Input()
	set binLiveInfo_Bin(bin_info:any) {	
		if(bin_info != null){
			this.binInfo = bin_info;
			this.subscribeBinInfo(this.binInfo);
		}
	}
	binData: API.Bin;
	map: any = null;
	binName;
	binId;
	marker;
	openedInfoWindow = [];
	currentLang;
	translationsObj;
	translate;
	usageType = 1;
  mapOptions: google.maps.MapOptions = {
    clickableIcons: false,
    streetViewControl : false,
    fullscreenControl : true,
    mapTypeControl : true
  };
  mobileMapOptions: google.maps.MapOptions = {
    streetViewControl: true,
    mapTypeControl : false,
    clickableIcons : false,
    fullscreenControl : true
  };


	constructor(private translator: TranslatorService,private apiQuery:ApiQuery,
		private responsiveService: ResponsiveService) {
			this.translate = this.translator;
			this.translator.currentLangEmitter$
			.subscribe(async value=>{						
			  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			  this.currentLang = value;	
			})
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.binName = '';
		this.binId = '';
		this.binData = null;
		this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}						
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
		});
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	private subscribeBinInfo = (bin: API.Bin) => {
		if (!bin) return;

		this.binData = bin;

		const location = {
			lat: this.binData.Bin_location.bin_latitude,
			lng: this.binData.Bin_location.bin_longitude
		};
		this.binName = this.binData.bin_name;
		this.binId = this.binData.bin_id;
		this.loadAPIWrapperInternal(this.map, location);
	}

	public loadAPIWrapper(map: any) {
		this.map = map;
		if(!this.isMobile){
			this.addYourLocationButton(this.map);
		}
		this.loadAPIWrapperInternal(this.map, null);
	}

	addYourLocationButton(map) {
		let controlDiv = document.createElement('div');
		let firstChild = document.createElement('button');
		firstChild.type = 'button';
		firstChild.style.backgroundColor = '#fff';
		firstChild.style.border = 'none';
		firstChild.style.outline = 'none';
		firstChild.style.width = '40px';
		firstChild.style.height = '40px';
		firstChild.style.borderRadius = '2px';
		firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
		firstChild.style.cursor = 'pointer';
		firstChild.style.marginRight = '10px';
		firstChild.style.padding = '0px';
		firstChild.title = 'Your Location';
		controlDiv.appendChild(firstChild);
		
		let secondChild = document.createElement('div');
		secondChild.style.margin = 'calc(50% - 18px / 2)';
		secondChild.style.width = '18px';
		secondChild.style.height = '18px';
		secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
		secondChild.style.backgroundSize = '180px 18px';
		secondChild.style.backgroundPosition = '0px 0px';
		secondChild.style.backgroundRepeat = 'no-repeat';
		secondChild.id = 'center-bininfo';
		firstChild.appendChild(secondChild);
		
		google.maps.event.addListener(map, 'dragend', function() {
			document.getElementById('center-bininfo').style.backgroundPosition = '0px 0px';
		});

		firstChild.addEventListener('click', function() {
			var imgX = '0';
			var animationInterval = setInterval(function(){
				if(imgX == '-18') imgX = '0';
				else imgX = '-18';
				document.getElementById('center-bininfo').style.backgroundPosition = imgX+'px 0px';
			}, 500);
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition( pos => {        
				map.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude});
				map.setZoom(15);
				clearInterval(animationInterval);
				document.getElementById('center-bininfo').style.backgroundPosition = '-144px 0px';
				}, error => {
				},{maximumAge:10000, timeout:5000, enableHighAccuracy: true});
			}else{
				clearInterval(animationInterval);
				document.getElementById('center-bininfo').style.backgroundPosition = '0px 0px';
			}
		});
		map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
	}

	private loadAPIWrapperInternal(map: any, customLocation: any) {
		if (!map || !this.binData || !this.binData.Bin_location) { return; }
		const userPosition = {
			lat: 32.085300,
			lng: 34.781768
		};
		if (customLocation) {
			userPosition.lat = customLocation.lat;
			userPosition.lng = customLocation.lng;
		}
		this.map.panTo(userPosition);
		this.map.setZoom(12);
		this.addMarker(map);
	}

	private addMarker = (map: any) => {
		this.clearMarkers();
		const icon = this.BinToIcon(this.binData.Bin_live_Data.color_status_id);
		this.marker = new google.maps.Marker({
			position: {
				lat: this.binData.Bin_location.bin_latitude,
				lng: this.binData.Bin_location.bin_longitude
			},
			map: map,
			icon: icon
		});
	}

	private clearMarkers = () => {
		if (!this.marker) { return; }
		this.marker.setMap(null);
	}

	private BinToIcon(status: number) {
		let icon = '';
		if(this.usageType == 1){
			if(status == 0){
				icon = `../../assets/images/bins/all/greenNotOrdered.svg`;
			}else if(status == 1){
				icon = `../../assets/images/bins/all/yellowNotOrdered.svg`;
			}else if(status == 2){
				icon = `../../assets/images/bins/all/redNotOrdered.svg`;
			}
			return icon;
		}else{
			if(status == 0){
				icon = `../../assets/images/bins/all/greenNotOrdered2.svg`;
			}else if(status == 1){
				icon = `../../assets/images/bins/all/yellowNotOrdered2.svg`;
			}else if(status == 2){
				icon = `../../assets/images/bins/all/redNotOrdered2.svg`;
			}
			return icon;
		}
	}
}

@if (!isMobile) {
  <div class="pagination" rtlDiv>
    <div class="pagination-borders desktop-gap">
      <div class="first-btn" (click)='setPage(1)' [class.disabled]="pager.currentPage === 1">
        <img src='assets/images/pagination/next-prev-all.svg' [ngClass]="{'transform-arrow': !isRtl()}" alt="prev-all">
      </div>
      <div class="prev-btn" (click)="setPage(pager.currentPage - 1)" [class.disabled]="pager.currentPage === 1">
        <img src='assets/images/pagination/next-prev.svg' [ngClass]="{'transform-arrow': !isRtl()}" alt="prev">
      </div>
      @if (pager.startPage > 1) {
        <div class="next-btn" (click)="setPreviosPagesBlock(pager.currentPage - 5)">
          <img class="dots" src='assets/images/pagination/dots.svg' alt="dots">
        </div>
      }
      @for (page of pager.pages; track page) {
        <div class="page-box" [class.selected]='pager.currentPage === page' (click)="setPage(page)">
          {{page}}
        </div>
      }
      @if (pager.totalPages != pager.endPage) {
        <div class="next-btn" (click)="setNextPagesBlock(pager.currentPage + 5)">
          <img class="dots" src='assets/images/pagination/dots.svg' alt="dots">
        </div>
      }
      <div class="next-btn" (click)="setPage(pager.currentPage + 1)" [class.disabled]="pager.currentPage === pager.totalPages">
        <img src='assets/images/pagination/next-prev.svg' [ngClass]="{'transform-arrow': isRtl()}" alt="next">
      </div>
      <div class="last-btn" (click)='setPage(pager.totalPages)' [class.disabled]="pager.currentPage === pager.totalPages">
        <img src='assets/images/pagination/next-prev-all.svg' [ngClass]="{'transform-arrow': isRtl()}" alt="next-all">
      </div>
    </div>
    @if (pageIndexes && dataLength!=0) {
      <div class="data-count">
        <span>{{pageIndexes.startIndex + 1}}</span>
        <span>-</span>
        <span>{{pageIndexes.endIndex + 1}}</span>
        <span>{{ 'TITLES.OUT_OF' | translate }}</span>
        <span>{{dataLength}}</span>
      </div>
    }
  </div>
}

@if (isMobile) {
  <div class="pagination" rtlDiv>
    <div>
      <div class="pagination-borders mobile-gap">
        <div class="mobile-first-btn" (click)='setPage(1)' [class.disabled]="pager.currentPage === 1">
          <img src='assets/images/pagination/next-prev-all.svg' [ngClass]="{'transform-arrow': !isRtl()}" alt="prev-all">
        </div>
        <div class="mobile-prev-btn" (click)="setPage(pager.currentPage - 1)" [class.disabled]="pager.currentPage === 1">
          <img src='assets/images/pagination/next-prev.svg' [ngClass]="{'transform-arrow': !isRtl()}" alt='prev'>
        </div>
        @if (pager.startPage > 1) {
          <div class="next-btn" (click)="setPreviosPagesBlock(pager.currentPage - 5)">
            <img class="dots" src='assets/images/pagination/dots.svg' alt="dots">
          </div>
        }
        @for (page of pager.pages; track page) {
          <div class="mobile-page" [class.mobile-page-box]='pager.currentPage === page' (click)="setPage(page)">
            {{page}}
          </div>
        }
        @if (pager.totalPages != pager.endPage) {
          <div class="next-btn" (click)="setNextPagesBlock(pager.currentPage + 5)">
            <img class="dots" src='assets/images/pagination/dots.svg' alt="dots">
          </div>
        }
        <div class="mobile-next-btn" (click)="setPage(pager.currentPage + 1)" [class.disabled]="pager.currentPage === pager.totalPages">
          <img src='assets/images/pagination/next-prev.svg' [ngClass]="{'transform-arrow': isRtl()}" alt='next'>
        </div>
        <div class="mobile-last-btn" (click)='setPage(pager.totalPages)' [class.disabled]="pager.currentPage === pager.totalPages">
          <img src='assets/images/pagination/next-prev-all.svg' [ngClass]="{'transform-arrow': isRtl()}" alt="next-all">
        </div>
      </div>
      @if (pageIndexes && dataLength!=0) {
        <div class="mobile-data-count">
          <span>{{pageIndexes.startIndex + 1}}</span>
          <span>-</span>
          <span>{{pageIndexes.endIndex + 1}}</span>
          <span>{{ 'TITLES.OUT_OF' | translate }}</span>
          <span>{{dataLength}}</span>
        </div>
      }
    </div>
  </div>
}
import { Component } from '@angular/core';
import moment from 'moment';
import { ResponsiveService } from '../../services/responsive.service';
import { ApiStore } from '../../web-services/api/api.store';
import { TranslatorService } from '../../services/translator_service';
import { ApiService } from '../../web-services/api/api.service';

import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { DatepickerRangeComponent } from '../../widgets/datepicker-range/datepicker-range.component';
import { RefillKpiComponent } from './refill-kpi/refill-kpi.component';
import { RefillsTableComponent } from './refills-table/refills-table.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-fillings',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, MatDividerModule, MatCardModule, DatepickerRangeComponent, 
	RefillKpiComponent, RefillsTableComponent,CommonModule],
  templateUrl: './fillings.component.html',
  styleUrl: './fillings.component.scss'
})
export class FillingsComponent {
  translate;
	isMobile: Boolean;
  translationsObj;
	currentLang;
  showDatePicker: Boolean = false;
	selectedDates: any = {
		from: '',
		to: ''
	};
  minDate = '';
  maxDate = '';
	lastMaxDate:any;
	lastMinDate:any;
	datesDiff : any;
	selected_time = 'day';
	timeDateFormat = 'DD.MM.YY';
	lastMonthDay: any = moment(this.minDate).format(this.timeDateFormat);
  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,
    private apiService:ApiService,private apiStore:ApiStore) { 
    this.translate = this.translator;
    this.translator.currentLangEmitter$
    .subscribe(async value=>{						
      this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
      this.currentLang = value;
      if (this.currentLang !== 'iw') {
        this.timeDateFormat = 'MM.DD.YY';
      }
    })
  }

  	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
    }

	ngOnDestroy() {
		sessionStorage.removeItem('fillingsMinDate');
		sessionStorage.removeItem('fillingsMaxDate');
	}

    onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

    showHideDatePicker = () => {
		this.showDatePicker = !this.showDatePicker;
	}

    onSelectDates = (selectDates: any) => {
		this.showDatePicker = false;
		this.selectedDates.from = selectDates.fromDate;
		this.selectedDates.to = selectDates.toDate;		
		this.minDate = moment(this.selectedDates.from, 'DD.MM.YYYY').format('YYYY-MM-DD 00:00:01');
		this.maxDate = moment(this.selectedDates.to, 'DD.MM.YYYY').format('YYYY-MM-DD 23:59:59');
		sessionStorage.setItem("fillingsMinDate",this.minDate);
		sessionStorage.setItem("fillingsMaxDate",this.maxDate);

		sessionStorage.setItem("fillingsStartDate",this.minDate);
		sessionStorage.setItem("fillingsEndDate",this.maxDate);

		this.datesDiff = selectDates.fromDate + " - " + selectDates.toDate;		
		if(this.maxDate != this.lastMaxDate || this.minDate != this.lastMinDate){
			let siteCollections = {
				start_date : this.minDate,
				end_date : this.maxDate
			}	  	
			this.apiStore.update({ allRefillsKpis: [] });
			this.apiStore.update({ refillsKpis: [] });
			this.apiService.getfilteredSiteRefillsKpis(siteCollections,JSON.parse(sessionStorage.getItem("chosenSites")));
			let request = {
				site_id : 0,
				start_date : this.minDate,
				end_date : this.maxDate
			}
			this.apiStore.update({ allRefillsStats: [] });
			this.apiStore.update({ refillsStats: [] });
			this.apiService.getfilteredBinsRefillsStats(request,JSON.parse(sessionStorage.getItem("chosenSites")));

			this.lastMinDate = this.minDate;
			this.lastMaxDate = this.maxDate;
		}
    	this.changeCollectionReportRange(this.selected_time);
	}

    changeCollectionReportRange = (time_range: string) => {
		this.selected_time = time_range;
		this.lastMonthDay = moment(this.minDate).format(this.timeDateFormat);
	}

}

@if (!isMobile) {
  <div class="resolve-ticket">
    <div class="modal-title">
      <span class="main-title">{{ 'TICKET_MANAGEMENT.CLOSE_EVENT' | translate }}</span>
      <span class="main-title"> - </span>
      @if (data.selectedTab == 0) {
        <span class="main-title">{{ 'TICKET_MANAGEMENT.CHECK_OR_REPLACEMENT_TAB' | translate }}</span>
      }
      @if (data.selectedTab == 1) {
        <span class="main-title">{{ 'TICKET_MANAGEMENT.INSTALLATION_TAB' | translate }}</span>
      }
      @if (data.selectedTab == 2) {
        <span class="main-title">{{ 'TICKET_MANAGEMENT.CANCELLATION_TAB' | translate }}</span>
      }
    </div>
    <div class="popup-container">
      <div class="prop-frame">
        <div class="days-between-container">
          <form [formGroup]="ElForm" class="add-ticket-form">
            <div class="events-type-container">
              <div>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.SITE' | translate }}</div>
                <mat-form-field class="event-type-input event-types">
                  <input tabindex="-1" class="input-site" matInput aria-label="Item" [matAutocomplete]="autoSite" formControlName="site" rtlDiv>
                  <mat-autocomplete #autoSite="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
                    @for (item of filteredItems | async; track item) {
                      <mat-option [value]="item.site_name" class="site-item" rtlDiv>
                        <span>{{ item.site_name }}</span>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div [ngClass]="{'flex-binInfo' : data.selectedTab != 1}" rtlDiv>
                @if (data.selectedTab != 1) {
                  <div class="margin-flex" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</div>
                    <mat-form-field class="event-type-input event-types">
                      <input tabindex="-1" class="input-site input-bin" matInput aria-label="Item" [matAutocomplete]="autoBin" formControlName="binName" rtlDiv>
                      <mat-autocomplete #autoBin="matAutocomplete" (optionSelected)="optionSelectedBinName($event.option.value)">
                        @for (item of filteredBins | async; track item) {
                          <mat-option [value]="item.bin_name" class="site-item" rtlDiv>
                            <span>{{ item.bin_name }}</span>
                          </mat-option>
                        }
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                }
                <div [ngClass]="{'max-width' : data.selectedTab != 1}" rtlDiv>
                  <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</div>
                  <mat-form-field class="event-type-input event-types">
                    <input tabindex="-1" type="text" class="input-site" matInput formControlName="binAddress" rtlDiv>
                  </mat-form-field>
                </div>
              </div>
              @if (data.selectedTab == 1) {
                <div>
                  <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_LOCATION' | translate }}</div>
                  <div class="event-type-input event-types">
                    <button (click)="openGoogelMapsModal()" class="btn-google-map" [disabled]="true" rtlDiv>{{binLocation}}</button>
                  </div>
                </div>
              }
              @if (data.selectedTab == 1) {
                <div rtlDiv>
                  <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.NEIGHBORHOOD' | translate }}</div>
                  <mat-form-field class="event-type-input event-types">
                    <input tabindex="-1" class="input-site input-bin" matInput aria-label="Item" [matAutocomplete]="autobinsNeighborhood" formControlName="binsNeighborhood" rtlDiv>
                    <mat-autocomplete #autobinsNeighborhood="matAutocomplete" (optionSelected)="optionSelectedBinsNeighborhood($event.option)">
                      @for (item of filteredNeighborhoods | async; track item) {
                        <mat-option [value]="item" class="site-item" rtlDiv>
                          <span>{{ item }}</span>
                        </mat-option>
                      }
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              }
              <div rtlDiv>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.GET_DIRECTIONS' | translate }}</div>
                <div class="waze-btn" rtlDiv>
                  <a class="wazeFrame" href='https://waze.com/ul?ll={{lat}},{{lon}}'>
                    <img src="assets/images/dashboard/waze.svg" alt="">
                    <span class="waze-txt">Waze</span>
                  </a>
                </div>
              </div>
              <div>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</div>
                <mat-form-field class="event-type-input event-types">
                  <mat-select rtlDiv formControlName="eventCause" name="eventCause">
                    @for (cause of ticketEventsCause; track cause) {
                      <mat-option [value]="cause['ticketEventCause_id']">
                        {{currentLang != 'iw' ? cause['ticketEventCause_name'] : cause['ticketEventCause_name_hebrew']}}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</div>
                <textarea id="description-area" class="description-area disabled-color" formControlName='description' rows="4" rtlDiv></textarea>
              </div>
              <div class="full-width" rtlDiv>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }}</div>
                <mat-form-field class="event-types date-field" appearance="fill">
                  <input matInput formControlName="arrival_date_displayed">
                  <input class="hiddenInput" [min]="minDate" matInput [matDatepicker]="dp" formControlName="arrival_date">
                  <mat-hint></mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                  <mat-datepicker #dp disabled="true"></mat-datepicker>
                </mat-form-field>
              </div>
              <div>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</div>
                <div class="dflex" rtlDiv>
                  <button class="priority-btn" (click)="choosePriority('1')" [ngClass]="{'low-priority' : priority == 1}">{{ 'TICKET_MANAGEMENT.LOW' | translate }}</button>
                  <button class="priority-btn middle-opt" (click)="choosePriority('2')" [ngClass]="{'medium-priority' : priority == 2}">{{ 'TICKET_MANAGEMENT.MEDIUM' | translate }}</button>
                  <button class="priority-btn" (click)="choosePriority('3')" [ngClass]="{'high-priority' : priority == 3}">{{ 'TICKET_MANAGEMENT.HIGH' | translate }}</button>
                </div>
              </div>
              <hr>
                @if (data.selectedTab == 0) {
                  <div>
                    <div class="installation-title" rtlDiv>
                      <div class="installation-btn" (click)='goToSwapUnit()' rtlDiv>
                        <img src="assets/images/dashboard/goTo.svg" alt=""/>
                        <span>{{ 'TICKET_MANAGEMENT.SWAP' | translate }}</span>
                      </div>
                    </div>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.EVENT_CHECK_ON_FIELD' | translate }}</div>
                    <mat-form-field class="event-type-input event-types">
                      <mat-select rtlDiv formControlName="eventCheckOnField" name="eventCheckOnField">
                        @for (type of analysisTypes; track type) {
                          <mat-option [value]="type['ticket_check_replace_analysis_type_id']" class="list-item">
                            <span rtlDiv class="type-opt">{{currentLang != 'iw' ? type['analysis_name'] : type['analysis_name_hebrew']}}</span>
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  </div>
                }
                @if (data.selectedTab == 0) {
                  <div>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_REPLACED' | translate }}</div>
                    <div rtlDiv>
                      <mat-radio-group
                        formControlName="unitReplaced">
                        <mat-radio-button (change)="unitReplacedChange($event)" class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.REPLACED' | translate }}</span></mat-radio-button>
                        <mat-radio-button (change)="unitReplacedChange($event)" class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.NOT_REPLACED' | translate }}</span></mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                }
                @if (data.selectedTab == 1) {
                  <div>
                    <div class="sub-title installation-title" rtlDiv>
                      <span>{{ 'TICKET_MANAGEMENT.UNIT_INSTALLED' | translate }}</span>
                      <div class="installation-btn" (click)='goToInstallation()' rtlDiv>
                        <img src="assets/images/dashboard/goTo.svg" alt=""/>
                        <span>{{ 'TICKET_MANAGEMENT.INSTALLATION' | translate }}</span>
                      </div>
                    </div>
                    <div rtlDiv>
                      <mat-radio-group
                        formControlName="unitInstalled">
                        <mat-radio-button class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.INSTALLED' | translate }}</span></mat-radio-button>
                        <mat-radio-button class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.NOT_INSTALLED' | translate }}</span></mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                }
                @if (data.selectedTab == 2) {
                  <div>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_IS_CANCELED' | translate }}</div>
                    <div rtlDiv>
                      <mat-radio-group
                        formControlName="removedFromBin">
                        <mat-radio-button class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'OTHER.YES' | translate }}</span></mat-radio-button>
                        <mat-radio-button class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'OTHER.NO' | translate }}</span></mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                }
                @if (data.selectedTab == 2) {
                  <div>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_REMOVED_FROM_SITE' | translate }}</div>
                    <div rtlDiv>
                      <mat-radio-group
                        formControlName="removedFromSite">
                        <mat-radio-button (change)="removedFromSiteChange($event)" class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'OTHER.YES' | translate }}</span></mat-radio-button>
                        <mat-radio-button (change)="removedFromSiteChange($event)" class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'OTHER.NO' | translate }}</span></mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                }
                @if ((data.selectedTab == 0 || data.selectedTab == 2) && (ElForm.controls.unitReplaced.value == 1 || ElForm.controls.removedFromSite.value == 1)) {
                  <div>
                    @if (data.selectedTab == 0) {
                      <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.REASON_OF_REPLACEMENT' | translate }}</div>
                    }
                    @if (data.selectedTab == 2) {
                      <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.REMOVED_REASON' | translate }}</div>
                    }
                    <mat-form-field class="event-type-input event-types">
                      <mat-select rtlDiv formControlName="removeOrReplaceType" name="removeOrReplaceType" (selectionChange)="removeOrReplaceTypeFunc($event)">
                        @for (type of removeOrReplaceTypes; track type) {
                          <mat-option [value]="type['ticket_remove_replace_cause_id']" class="list-item">
                            <span rtlDiv class="type-opt">{{currentLang != 'iw' ? type['ticket_remove_replace_cause_name'] : type['ticket_remove_replace_cause_name_hebrew']}}</span>
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  </div>
                }
                @if (data.selectedTab == 1 && ElForm.controls.unitInstalled.value == 1) {
                  <div rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</div>
                    <mat-form-field class="event-type-input event-types">
                      <input tabindex="-1" type="text" class="input-site" matInput formControlName="installedBinName" rtlDiv>
                    </mat-form-field>
                  </div>
                }
                <div>
                  <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</div>
                  <textarea class="description-area" formControlName='resolvedDescription' rows="4" rtlDiv></textarea>
                </div>
                @if (data.selectedTab == 0 && ElForm.controls.removeOrReplaceType.value == 4) {
                  <div rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.ADD_PIC' | translate }}</div>
                    <mat-form-field class="event-type-input event-types">
                      <input type="text" readonly matInput formControlName="binImage"/>
                      <input
                        type="file"
                        hidden
                        #f_input
                        (change)="handleFileInputChange(f_input.files)"
                        />
                      <button rtlDiv mat-button type="button" class="qr-icon" (click)="f_input.click()"><mat-icon>attach_file</mat-icon></button>
                    </mat-form-field>
                  </div>
                }
              </div>
              <div class='main-btns-wrapper'>
                <div class='btns-container' rtlDiv>
                  <div class='cancel-btn' (click)="closeModal()">{{ 'TICKET_MANAGEMENT.CANCEL' | translate }}</div>
                  <button class="{{ getClassForFormControl() }}" type="submit" [disabled]="!ElForm.valid" (click)='resolveTicket()'>{{ 'TICKET_MANAGEMENT.CLOSE' | translate }}</button>
                </div>
              </div>
              <hr class="hr-bottom">
              <div class="created-date" rtlDiv>
                {{ 'TICKET_MANAGEMENT.CREATED' | translate }} {{currentLang == 'iw' ? data.chosenTicket.created_convertDateHebrew : data.chosenTicket.created_convertDateEnglish}}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  }

  @if (isMobile) {
    <div>
      <div class="mobile-modal-title" rtlDiv>
        <div class="dflex" (click)="closeModal()">
          <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
        </div>
        <div>
          <span class="main-title">{{ 'TICKET_MANAGEMENT.CLOSE_EVENT' | translate }}</span>
          <span class="main-title"> - </span>
          @if (data.selectedTab == 0) {
            <span class="main-title">{{ 'TICKET_MANAGEMENT.CHECK_OR_REPLACEMENT_TAB' | translate }}</span>
          }
          @if (data.selectedTab == 1) {
            <span class="main-title">{{ 'TICKET_MANAGEMENT.INSTALLATION_TAB' | translate }}</span>
          }
          @if (data.selectedTab == 2) {
            <span class="main-title">{{ 'TICKET_MANAGEMENT.CANCELLATION_TAB' | translate }}</span>
          }
        </div>
      </div>
      <div class="mobile-popup-container">
        <div class="mobile-prop-frame">
          <div class="days-between-container">
            <form [formGroup]="ElForm" class="add-ticket-form">
              <div class="events-type-container">
                <div>
                  <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.SITE' | translate }}</div>
                  <mat-form-field class="event-type-input event-types">
                    <input tabindex="-1" class="input-site" matInput aria-label="Item" [matAutocomplete]="autoSite" formControlName="site" rtlDiv>
                    <mat-autocomplete #autoSite="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
                      @for (item of filteredItems | async; track item) {
                        <mat-option [value]="item.site_name" class="site-item" rtlDiv>
                          <span>{{ item.site_name }}</span>
                        </mat-option>
                      }
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                @if (data.selectedTab != 1) {
                  <div rtlDiv>
                    <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</div>
                    <mat-form-field class="event-type-input event-types">
                      <input tabindex="-1" class="input-site input-bin" matInput aria-label="Item" [matAutocomplete]="autoBin" formControlName="binName" rtlDiv>
                      <mat-autocomplete #autoBin="matAutocomplete" (optionSelected)="optionSelectedBinName($event.option.value)">
                        @for (item of filteredBins | async; track item) {
                          <mat-option [value]="item.bin_name" class="site-item" rtlDiv>
                            <span>{{ item.bin_name }}</span>
                          </mat-option>
                        }
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                }
                <div [ngClass]="{'max-width' : data.selectedTab != 1}" rtlDiv>
                  <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</div>
                  <mat-form-field class="event-type-input event-types">
                    <input tabindex="-1" type="text" class="input-site" matInput formControlName="binAddress" rtlDiv>
                  </mat-form-field>
                </div>
                @if (data.selectedTab == 1) {
                  <div>
                    <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_LOCATION' | translate }}</div>
                    <div class="event-type-input event-types">
                      <button (click)="openGoogelMapsModal()" class="btn-google-map" [disabled]="true" rtlDiv>{{binLocation}}</button>
                    </div>
                  </div>
                }
                @if (data.selectedTab == 1) {
                  <div rtlDiv>
                    <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.NEIGHBORHOOD' | translate }}</div>
                    <mat-form-field class="event-type-input event-types">
                      <input tabindex="-1" class="input-site input-bin" matInput aria-label="Item" [matAutocomplete]="autobinsNeighborhood" formControlName="binsNeighborhood" rtlDiv>
                      <mat-autocomplete #autobinsNeighborhood="matAutocomplete" (optionSelected)="optionSelectedBinsNeighborhood($event.option)">
                        @for (item of filteredNeighborhoods | async; track item) {
                          <mat-option [value]="item" class="site-item" rtlDiv>
                            <span>{{ item }}</span>
                          </mat-option>
                        }
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                }
                <div rtlDiv>
                  <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.GET_DIRECTIONS' | translate }}</div>
                  <div class="waze-btn" rtlDiv>
                    <a class="wazeFrame" href='https://waze.com/ul?ll={{lat}},{{lon}}'>
                      <img src="assets/images/dashboard/waze.svg" alt="">
                      <span class="waze-txt">Waze</span>
                    </a>
                  </div>
                </div>
                <div>
                  <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</div>
                  <mat-form-field class="event-type-input event-types">
                    <mat-select rtlDiv formControlName="eventCause" name="eventCause">
                      @for (cause of ticketEventsCause; track cause) {
                        <mat-option [value]="cause['ticketEventCause_id']">
                          {{currentLang != 'iw' ? cause['ticketEventCause_name'] : cause['ticketEventCause_name_hebrew']}}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</div>
                  <textarea id="description-area" class="description-area disabled-color" formControlName='description' rows="4" rtlDiv></textarea>
                </div>
                <div class="full-width" rtlDiv>
                  <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }}</div>
                  <mat-form-field class="event-types mobile-date-field" appearance="fill">
                    <input matInput formControlName="arrival_date_displayed">
                    <input class="hiddenInput" [min]="minDate" matInput [matDatepicker]="dp" formControlName="arrival_date">
                    <mat-hint></mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp disabled="true"></mat-datepicker>
                  </mat-form-field>
                </div>
                <div>
                  <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</div>
                  <div class="dflex" rtlDiv>
                    <button class="priority-btn" (click)="choosePriority('1')" [ngClass]="{'low-priority' : priority == 1}">{{ 'TICKET_MANAGEMENT.LOW' | translate }}</button>
                    <button class="priority-btn middle-opt" (click)="choosePriority('2')" [ngClass]="{'medium-priority' : priority == 2}">{{ 'TICKET_MANAGEMENT.MEDIUM' | translate }}</button>
                    <button class="priority-btn" (click)="choosePriority('3')" [ngClass]="{'high-priority' : priority == 3}">{{ 'TICKET_MANAGEMENT.HIGH' | translate }}</button>
                  </div>
                </div>
                <hr>
                  @if (data.selectedTab == 0) {
                    <div>
                      <div class="installation-title" rtlDiv>
                        <div class="installation-btn" (click)='goToSwapUnit()' rtlDiv>
                          <img src="assets/images/dashboard/goTo.svg" alt=""/>
                          <span>{{ 'TICKET_MANAGEMENT.SWAP' | translate }}</span>
                        </div>
                      </div>
                      <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.EVENT_CHECK_ON_FIELD' | translate }}</div>
                      <mat-form-field class="event-type-input event-types">
                        <mat-select rtlDiv formControlName="eventCheckOnField" name="eventCheckOnField">
                          @for (type of analysisTypes; track type) {
                            <mat-option class="list-item" [value]="type['ticket_check_replace_analysis_type_id']">
                              <span rtlDiv class="type-opt">{{currentLang != 'iw' ? type['analysis_name'] : type['analysis_name_hebrew']}}</span>
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                  }
                  @if (data.selectedTab == 0) {
                    <div>
                      <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_REPLACED' | translate }}</div>
                      <div rtlDiv>
                        <mat-radio-group
                          formControlName="unitReplaced">
                          <mat-radio-button (change)="unitReplacedChange($event)" class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.REPLACED' | translate }}</span></mat-radio-button>
                          <mat-radio-button (change)="unitReplacedChange($event)" class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.NOT_REPLACED' | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  }
                  @if (data.selectedTab == 1) {
                    <div>
                      <div class="mobile-sub-title installation-title" rtlDiv>
                        <span>{{ 'TICKET_MANAGEMENT.UNIT_INSTALLED' | translate }}</span>
                        <div class="installation-btn" (click)='goToInstallation()' rtlDiv>
                          <img src="assets/images/dashboard/goTo.svg" alt=""/>
                          <span>{{ 'TICKET_MANAGEMENT.INSTALLATION' | translate }}</span>
                        </div>
                      </div>
                      <div rtlDiv>
                        <mat-radio-group
                          formControlName="unitInstalled">
                          <mat-radio-button class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.INSTALLED' | translate }}</span></mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.NOT_INSTALLED' | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  }
                  @if (data.selectedTab == 2) {
                    <div>
                      <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_IS_CANCELED' | translate }}</div>
                      <div rtlDiv>
                        <mat-radio-group
                          formControlName="removedFromBin">
                          <mat-radio-button class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'OTHER.YES' | translate }}</span></mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'OTHER.NO' | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  }
                  @if (data.selectedTab == 2) {
                    <div>
                      <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_REMOVED_FROM_SITE' | translate }}</div>
                      <div rtlDiv>
                        <mat-radio-group
                          formControlName="removedFromSite">
                          <mat-radio-button (change)="removedFromSiteChange($event)" class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'OTHER.YES' | translate }}</span></mat-radio-button>
                          <mat-radio-button (change)="removedFromSiteChange($event)" class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'OTHER.NO' | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  }
                  @if ((data.selectedTab == 0 || data.selectedTab == 2) && (ElForm.controls.unitReplaced.value == 1 || ElForm.controls.removedFromSite.value == 1)) {
                    <div>
                      @if (data.selectedTab == 0) {
                        <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.REASON_OF_REPLACEMENT' | translate }}</div>
                      }
                      @if (data.selectedTab == 2) {
                        <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.REMOVED_REASON' | translate }}</div>
                      }
                      <mat-form-field class="event-type-input event-types">
                        <mat-select rtlDiv formControlName="removeOrReplaceType" name="removeOrReplaceType" (selectionChange)="removeOrReplaceTypeFunc($event)">
                          @for (type of removeOrReplaceTypes; track type) {
                            <mat-option class="list-item" [value]="type['ticket_remove_replace_cause_id']">
                              <span rtlDiv class="type-opt">{{currentLang != 'iw' ? type['ticket_remove_replace_cause_name'] : type['ticket_remove_replace_cause_name_hebrew']}}</span>
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                  }
                  @if (data.selectedTab == 1 && ElForm.controls.unitInstalled.value == 1) {
                    <div rtlDiv>
                      <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</div>
                      <mat-form-field class="event-type-input event-types">
                        <input tabindex="-1" type="text" class="input-site" matInput formControlName="installedBinName" rtlDiv>
                      </mat-form-field>
                    </div>
                  }
                  <div>
                    <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</div>
                    <textarea class="description-area" formControlName='resolvedDescription' rows="4" rtlDiv></textarea>
                  </div>
                  @if (data.selectedTab == 0 && ElForm.controls.removeOrReplaceType.value == 4) {
                    <div rtlDiv>
                      <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.ADD_PIC' | translate }}</div>
                      <mat-form-field class="event-type-input event-types">
                        <input type="text" readonly matInput formControlName="binImage"/>
                        <input
                          type="file"
                          hidden
                          #f_input
                          (change)="handleFileInputChange(f_input.files)"
                          />
                        <button rtlDiv mat-button type="button" class="qr-icon" (click)="f_input.click()"><mat-icon>attach_file</mat-icon></button>
                      </mat-form-field>
                    </div>
                  }
                </div>
                <div class='main-btns-wrapper'>
                  <div class='btns-container' rtlDiv>
                    <div class='cancel-btn' (click)="closeModal()">{{ 'TICKET_MANAGEMENT.CANCEL' | translate }}</div>
                    <button class="{{ getClassForFormControl() }}" type="submit" [disabled]="!ElForm.valid" (click)='resolveTicket()'>{{ 'TICKET_MANAGEMENT.CLOSE' | translate }}</button>
                  </div>
                </div>
                <hr class="hr-bottom">
                <div class="created-date" rtlDiv>
                  {{ 'TICKET_MANAGEMENT.CREATED' | translate }} {{currentLang == 'iw' ? data.chosenTicket.created_convertDateHebrew : data.chosenTicket.created_convertDateEnglish}}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    }
@if (!isMobile) {
  <div class="bin-status">
    <mat-card>
      <mat-card-content>
        @if (blurKPIs && (user_role_id !=5 && user_role_id != 6)) {
          <div class="broken-bin-container">
            <div class="inactive-txt">
              <img class="broken-bin-img" src="assets/images/binInfoPage/broken-bin.svg" alt="" />
              <div class="inactive-txt-header">{{'LIVE_INFO.ATTENTION' | translate }}</div>
              <div>{{'LIVE_INFO.INACTIVE_TXT' | translate }}</div>
            </div>
          </div>
        }
        <div [ngClass]="{'filter-blur':blurKPIs && (user_role_id !=5 && user_role_id != 6),'not-filter-blur':!blurKPIs || (user_role_id ==5 || user_role_id == 6)}">
          <div class="box left-box">
            <div class="circle-content" rtlDiv>
              <div class="content-number">
                <span>{{user_role_id == 5 || user_role_id == 6 ? capacityLevelUnfiltered : capacityLevel}}</span>
                @if (usageType == 1) {
                  <span>%</span>
                }
                @if (usageType == 2 && currentLang == 'iw') {
                  <span> ל</span>
                }
                @if (usageType == 2 && currentLang != 'iw') {
                  <span> L</span>
                }
              </div>
              <div class="content-text">{{'LIVE_INFO.CAPACITY' | translate }}</div>
            </div>
            <div class="circle">
              <svg class="desktop-progress" width="169" height="169" viewBox="0 0 120 120">
                <circle class="progress__meter" cx="60" cy="60" r="55" stroke-width="10" />
                <circle class="progress__value" cx="60" cy="60" r="55" stroke-width="10" />
              </svg>
            </div>
          </div>
          <div class="middle-divider"></div>
          <div class='progress-bar-container'>
            <div class='progress-bar-left'>
              <img src="assets/images/binInfoPage/temperature.svg" alt="" />
              <div class="data">
                <div class='text-number' rtlDiv>
                  {{binTemprature}}
                  <span class='circle-celcius' rtlDiv></span>
                </div>
                <div class='text-title' rtlDiv>{{'LIVE_INFO.TEMPERATURE' | translate }}</div>
              </div>
            </div>
            <div class='progress-bar-right'>
              <app-progress-bar-color [backgroundColor]="'rgba(204, 241, 221, 0.5)'" [barColor]="'#CCF1DD'" [progressWidth]="'177px'"
              [progressHeight]="'17px'" [percentage]="binTemprature" [rangeLow]="binTemperatureLow" [rangeHigh]="binTemperatureHigh"></app-progress-bar-color>
            </div>
          </div>
          <div class="middle-divider"></div>
          <div class='progress-bar-container'>
            <div class='progress-bar-left'>
              <img src="assets/images/binInfoPage/humidity.svg" alt="" />
              <div class="data">
                <div class='text-number' rtlDiv>{{binHumidity}}%</div>
                <div class='text-title' rtlDiv>{{'LIVE_INFO.HUMIDITY' | translate }}</div>
              </div>
            </div>
            <div class='progress-bar-right'>
              <app-progress-bar-color [backgroundColor]="'rgba(200, 225, 255, 0.5)'" [barColor]="'#C8E1FF'" [progressWidth]="'177px'"
              [progressHeight]="'17px'" [percentage]="binHumidity" [rangeLow]="binHumidityLow" [rangeHigh]="binHumidityHigh"></app-progress-bar-color>
            </div>
          </div>
          <div class="middle-divider"></div>
          <div class="progress-bar-container">
            <div class='progress-bar-left'>
              <img src="assets/images/binInfoPage/batteryStatus.svg" alt="" />
              <div class="data battery-status-wrapper">
                <div class='battery-status-text'>{{batteryLevelText}}</div>
                <div class='battery-status-title'>{{'TITLES.BATTERY_STATUS' | translate }}</div>
              </div>
            </div>
            <div class='progress-bar-right'>
              <app-progress-bar-color [backgroundColor]="'rgb(243, 243, 192,0.5)'" [barColor]="'#F3F3C0'" [progressWidth]="'177px'"
              [progressHeight]="'17px'" [percentage]="binBattery" [rangeLow]="binBatteryLow" [rangeHigh]="binBatteryHigh"></app-progress-bar-color>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
    <div class="accordion md-accordion" id="binLiveStatusFirstAc" role="tablist" aria-multiselectable="true">
      <div class="card mobile-capacity-card">
        @if (blurKPIs && (user_role_id !=5 && user_role_id != 6)) {
          <div class="broken-bin-container">
            <div class="inactive-txt">
              <img class="broken-bin-img" src="assets/images/binInfoPage/broken-bin.svg" alt="" />
              <div class="inactive-txt-header">{{'LIVE_INFO.ATTENTION' | translate }}</div>
              <div>{{'LIVE_INFO.INACTIVE_TXT' | translate }}</div>
            </div>
          </div>
        }
        <div [ngClass]="{'filter-blur':blurKPIs && (user_role_id !=5 && user_role_id != 6),'not-filter-blur':!blurKPIs || (user_role_id ==5 || user_role_id == 6)}">
          <div class="collapse show" role="tabpanel" data-parent="#binLiveStatusFirstAc">
            <div class="card-body ">
              <div class="mobile-progress-capacity">
                <div class="mobile-circle-content">
                  <div class="mobile-content-number">
                    <span>{{user_role_id == 5 || user_role_id == 6 ? capacityLevelUnfiltered : capacityLevel}}</span>
                    @if (usageType == 1) {
                      <span>%</span>
                    }
                    @if (usageType == 2 && currentLang == 'iw') {
                      <span> ל</span>
                    }
                    @if (usageType == 2 && currentLang != 'iw') {
                      <span> L</span>
                    }
                  </div>
                  <div class="mobile-content-text">{{'LIVE_INFO.CAPACITY' | translate }}</div>
                </div>
                <div class="mobile-circle">
                  <svg class="desktop-progress" width="169" height="169" viewBox="0 0 120 120">
                    <circle class="progress__meter" cx="60" cy="60" r="55" stroke-width="10" style="fill: transparent;stroke: #dec6f7;"/>
                    <circle class="progress__value mobile-progress-value" cx="60" cy="60" r="55" stroke-width="10" style="stroke: #b05dff;"/>
                  </svg>
                </div>
              </div>
              <div class="mobile-bin-info">
                <div class='mobile-progress-bar-container'>
                  <div class='progress-bar-left'>
                    <img src="assets/images/binInfoPage/temperature.svg" alt="" />
                    <div class="mobile-data">
                      <div class='text-number' rtlDiv>
                        {{binTemprature}}
                        <span class='circle-celcius' rtlDiv></span>
                      </div>
                      <div class='text-title' rtlDiv>{{'LIVE_INFO.TEMPERATURE' | translate }}</div>
                    </div>
                    <div class='progress-bar-right'>
                      <app-progress-bar-color [backgroundColor]="'rgba(204, 241, 221, 0.5)'" [barColor]="'#CCF1DD'" [progressWidth]="'177px'"
                      [progressHeight]="'17px'" [percentage]="binTemprature" [rangeLow]="binTemperatureLow" [rangeHigh]="binTemperatureHigh"></app-progress-bar-color>
                    </div>
                  </div>
                </div>
                <mat-divider class="mobile-middle-divider"></mat-divider>
                <div class='mobile-progress-bar-container'>
                  <div class='progress-bar-left'>
                    <img src="assets/images/binInfoPage/humidity.svg" alt="" />
                    <div class="mobile-data">
                      <div class='text-number' rtlDiv>{{binHumidity}}%</div>
                      <div class='text-title' rtlDiv>{{'LIVE_INFO.HUMIDITY' | translate }}</div>
                    </div>
                    <div class='progress-bar-right'>
                      <app-progress-bar-color [backgroundColor]="'rgba(200, 225, 255, 0.5)'" [barColor]="'#C8E1FF'" [progressWidth]="'177px'"
                      [progressHeight]="'17px'" [percentage]="binHumidity" [rangeLow]="binHumidityLow" [rangeHigh]="binHumidityHigh"></app-progress-bar-color>
                    </div>
                  </div>
                </div>
                <mat-divider class="mobile-middle-divider"></mat-divider>
                <div class="mobile-progress-bar-container">
                  <div class='progress-bar-left'>
                    <img src="assets/images/binInfoPage/batteryStatus.svg" alt="" />
                    <div class="mobile-data mobile-battery-status-wrapper">
                      <div class='battery-status-text'>{{batteryLevelText}}</div>
                      <div class='battery-status-title'>{{'TITLES.BATTERY_STATUS' | translate }}</div>
                    </div>
                    <div class='progress-bar-right'>
                      <app-progress-bar-color [backgroundColor]="'rgb(243, 243, 192,0.5)'" [barColor]="'#F3F3C0'" [progressWidth]="'177px'"
                      [progressHeight]="'17px'" [percentage]="binBattery" [rangeLow]="binBatteryLow" [rangeHigh]="binBatteryHigh"></app-progress-bar-color>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject} from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { API } from '../../../types.api';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { E_Error_Tracer_Sensors_Zone } from '../../../types';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import _ from 'lodash';
import moment from 'moment';
import { ActivationTimeOutDialogComponent } from '../install-new-bin/activation-time-out-dialog/activation-time-out-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslatorService } from '../../../services/translator_service';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { GenericConfirmDialogComponent } from '../../generic-confirm-dialog/generic-confirm-dialog.component';
import { DataSource } from '@angular/cdk/collections';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MapComponent } from '../map/map.component';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsModule } from '@angular/google-maps';

const finalReportData: IFinalReport[] = [];

const FIRST_ACTIVATION_TIMER_DURATION: number = 300; //300;
const SECOND_ACTIVATION_TIMER_DURATION: number = 300; //300;
const ACTIVATION_TRANSMISSION_TIMER_DURATION: number = 1000;
const PHYSICAL_TRANSMISSION_TIMER_DURATION: number = 1200; //220;

const MAX_ALLOWED_BINS_IN_CLUSTER: number = 5;



@Component({
  selector: 'app-pre-installation-mapping',
  standalone: true,
  imports: [TranslateModule,MatDividerModule,MatStepperModule,FormsModule,ReactiveFormsModule,CommonModule,GoogleMapsModule,
    MatFormFieldModule,MatSelectModule,NgxMatSelectSearchModule,MapComponent,RTLDivDirectiveDirective,MatInputModule,NgbModule],
  templateUrl: './pre-installation-mapping.component.html',
  styleUrl: './pre-installation-mapping.component.scss'
})
export class PreInstallationMappingComponent {
  associatedBinId: number;
  binSelectedCluster: BinCluster;
  binClusterId: number;
  binType:number;
  installationPhase: API.InstallationPhase;
  timeOutObj: API.ITimOutObj;
  binCreationDate: any;
  siteId$: number;
  isLinear = true;
  isInitForms: boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isFirstFormGroupSubmitted: boolean = false;
  isSecondFormGroupSubmitted: boolean = false;
  isInstallationCancled: boolean = false;
  isValidLocation: boolean = false;
  interval;
  firstActivationTimerTimeLeft: number = FIRST_ACTIVATION_TIMER_DURATION; // 5 min = 300 sec
  secondActivationTimerTimeLeft: number = SECOND_ACTIVATION_TIMER_DURATION; // 5 min = 300 sec
  waitForTransChangeTimerTimeLeft: number = ACTIVATION_TRANSMISSION_TIMER_DURATION;
  maxAllowedBinsInCluster: number = MAX_ALLOWED_BINS_IN_CLUSTER;
  firstActivationTimerInterval;
  secondActivationTimerInterval;
  waitForTransChangeTimerInterval;
  waitForInstallationCompleteInterval;
  waitForInstallComplete;
  activationTimeOutAttempt: number = 0;
  origTransId: number = 0;
  currentTransId: number = 0;
  isActive: boolean = false;
  isInstallationComplete: boolean = false;
  isIgnoreTrans: boolean = true;
  binLastTransIdPhysicalCounter: number = 0;
  lstClusters: any;
  clustersToDisplay: any = [];
  binTypesToDisplay:any =[];
  wasteTypesToDisplay: any = [];
  dialogRef;
  qrElement;
  err: E_Error_Tracer_Sensors_Zone = E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_ACC_COM;

  public binFilterCtrl: FormControl = new FormControl();
  public filteredBins: ReplaySubject<object> = new ReplaySubject<object>(1);

  @ViewChild('stepper',{static: false}) stepper: MatStepper;
  @ViewChild('formDirective',{static: false}) private formDirective: NgForm;
  isMobile: Boolean;
  currentLang: string;
  translateSub;
  translationsObj;
  spinnerExist:Boolean = false;
  latitude:number;
  longitude:number;
  routeParamsSub;
  siteId: any;
  displayedName : any;
  message:string;
  public show:boolean = false;
  binsSite = [];
  binExists:boolean = false;
  binInfoObject: DataBin;
  binGmtTimeZoneOffset: number;

  recieveLocationMsg($event) {
    this.isValidLocation = $event.is_valid_location;
    if (!this.isValidLocation) {
      this.secondFormGroup.controls.locationValidation.setErrors({invalid: true});
    }
    else {
      this.secondFormGroup.controls.locationValidation.setValue(1);
    } 
  }


  recieveAddress($event) {
    //this.secondFormGroup.controls.binAddress.setValue($event);
  }

  recieveLatLong($event){
    this.latitude = $event.split(",")[0];
    this.longitude = $event.split(",")[1];    
  }

  subscribeBinFilterCtrlChange() {
    this.binFilterCtrl.valueChanges.subscribe(val => {
      if (val !== "") {
        this.filterBins();
      }
      else {
        this.clustersToDisplay = this.lstClusters;        
      }
    });
  }

  onBinNameChanges(): void {
    this.firstFormGroup.controls.binName.valueChanges.subscribe(val => {
      if(!val){return;}
      this.apiQuery.filteredBasicBins$.subscribe(bins => {	
        if(bins.length == 0 || !bins){return;}      
        this.binsSite = bins.filter(bin => bin["site_id"] == this.siteId);      
        const binsFiltered = this.binsSite.filter(bin => bin.bin_name.toLowerCase() == val.toLowerCase());   
        if(val.length < 4 || val.length > 4){
          this.binExists = false;         
          this.firstFormGroup.controls.binName.setErrors({});                
        }         
        else if(binsFiltered.length > 0){
          this.binExists = true;     
          this.firstFormGroup.controls.binName.setErrors({});        
        }else{        
          this.binExists = false;         
          this.firstFormGroup.controls.binName.valid;
          this.firstFormGroup.controls.binName.setErrors(null);        
        }
      });
    });
  }

  protected filterBins() {
    if (!this.lstClusters) {
      return;
    }
    // get the search keyword
    let search = this.binFilterCtrl.value;
    this.searchBin(search);

  }
 
  
  public searchBin = (filterValue: string) => {
    let backupList = this.lstClusters;
    const filter = filterValue.trim().toLowerCase();
		if (filter === "") {
      this.clustersToDisplay = _.cloneDeep(this.lstClusters);
		}
		else {
      this.clustersToDisplay = [];

      for(let i=0 ; i<backupList.length; i++){
       for(let j=0 ; j<backupList[i].binsArr[0].length; j++){
        const nameItem = backupList[i].binsArr[0][j].bin_name.trim().toLowerCase();
        const addressItem = backupList[i].binsArr[0][j].bin_address.trim().toLowerCase();             
				if (_.includes(nameItem, filter) || _.includes(addressItem, filter)) {     
					this.clustersToDisplay.push(backupList[i]);
				}
       } 
      }
		}
  }

  onBinSelectionChangeSubscribe() {
    this.firstFormGroup.controls.binClusterId.valueChanges.subscribe(val => {
      this.binSelectedCluster = <BinCluster>val;
    }); 
  }


  onSecondFormGroupSubmit() {

    if (!this.secondFormGroup.invalid) {
      this.isSecondFormGroupSubmitted = true;
      this.openDialog(false);
    }
    else {
      return;
    }
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  pauseFirstActivationTimer(isOpenDialog: boolean) {
    clearInterval(this.firstActivationTimerInterval);
    this.firstActivationTimerTimeLeft = FIRST_ACTIVATION_TIMER_DURATION;
    this.activationTimeOutAttempt++;
    if (isOpenDialog) this.openActivationTimeOutDialog();
  }

  pauseSecondActivationTimer(isOpenDialog: boolean) {
    clearInterval(this.secondActivationTimerInterval);
    this.secondActivationTimerTimeLeft = SECOND_ACTIVATION_TIMER_DURATION;
    this.activationTimeOutAttempt++;
    if (isOpenDialog) this.openActivationTimeOutDialog();
  }

  goBack(){
    this.stepper.ngAfterViewInit();
    this.stepper.previous();
  }

  resetStepper() {
    this.stepper.reset();
  }

  resetForm(form: FormGroup) {

  form.reset();

  Object.keys(form.controls).forEach(key => {
    form.get(key).markAsUntouched();
    form.get(key).markAsPristine();
    form.get(key).setErrors(null);
  });

    form.markAsUntouched();
    form.markAsPristine();
  }

  lookForNewTranssmistion() {
    this.waitForInstallationCompleteInterval = setInterval(() => {
    
        this.pauseWaitForInstallationCompleteTimer();
        setTimeout(() => {
        }, 0);
      
    },3000)
  }

  clearFinalReport() {
    finalReportData.forEach(function (element, index, array) {
      finalReportData.pop();
    });

  }

  pauseWaitForNewBinTranssmissionTimer() {
    clearInterval(this.waitForTransChangeTimerInterval);
    this.waitForTransChangeTimerTimeLeft = ACTIVATION_TRANSMISSION_TIMER_DURATION;
    
  }

  pauseWaitForInstallationCompleteTimer() {
    clearInterval(this.waitForInstallationCompleteInterval);
  }

  initPage(isOnDestroy: boolean) {
    
    this.pauseWaitForNewBinTranssmissionTimer();
    this.pauseFirstActivationTimer(false);
    this.pauseSecondActivationTimer(false);
    this.pauseTimer();
    this.initParams();
    this.clearFinalReport();
  }


  initParams() {
    this.resetStepper();
    this.activationTimeOutAttempt = 0;
    this.isFirstFormGroupSubmitted = false;
    this.isSecondFormGroupSubmitted = false;
    this.isInstallationCancled = false;
    this.firstActivationTimerTimeLeft = FIRST_ACTIVATION_TIMER_DURATION; // 5 min = 300 sec
    this.secondActivationTimerTimeLeft = SECOND_ACTIVATION_TIMER_DURATION; // 5 min = 300 sec
    this.waitForTransChangeTimerTimeLeft = ACTIVATION_TRANSMISSION_TIMER_DURATION;
    this.activationTimeOutAttempt = 0;
    this.origTransId = 0;
    this.currentTransId = 0;
    this.isActive = false;
    this.isInstallationComplete = false;
    this.isIgnoreTrans = true;
    this.associatedBinId = null;
    this.clustersToDisplay = [];
    this.binTypesToDisplay = [];

    this.initForms();
  }

  initForms() {
    this.resetForm(this.firstFormGroup);
    this.firstFormGroup.controls.binClusterId.setValue(this.clustersToDisplay[0]);
    this.apiService.getSiteClustersList(this.siteId);

    this.resetForm(this.secondFormGroup);
    this.secondFormGroup.controls.binAddress.setValue("");
    
    this.isInitForms = true;
  }

  openDialog(isInstallationCompleted: boolean): void {
    let outSideClosedAllowed: boolean = false;

    if (isInstallationCompleted) outSideClosedAllowed = true;
  }

  openActivationTimeOutDialog(): void {
    if (this.dialogRef != undefined) {
      this.dialogRef.close();
    }

    let timeOutObj: API.ITimOutObj = {
      activationTimeOutAttempt: this.activationTimeOutAttempt,
      installationPhase: this.installationPhase
    }

    this.dialogRef = this.dialog.open(ActivationTimeOutDialogComponent, {
      data: timeOutObj,
      width: '600px',
      disableClose: true
    });

  }

  // convenience getter for easy access to form fields
  get f1() { 
    return this.firstFormGroup.controls; 
  }
  
  get f2() { 
    return this.secondFormGroup.controls; 
  }

  constructor(private _formBuilder: FormBuilder,
     private router: Router, private route: ActivatedRoute,
      private dialog: MatDialog,private responsiveService: ResponsiveService,
      private apiService:ApiService,private translator: TranslatorService,
      private apiQuery:ApiQuery) {
    this.routeParamsSub = this.route
			.queryParams
			.subscribe(params => {
        this.siteId = this.route.snapshot.paramMap.get("id"); 
      });
      this.currentLang = this.translator.getTranslationLanguage();
      this.translateSub = this.translator.getTranslation(this.currentLang).subscribe(translations => {
        this.translationsObj = translations;
      });
   }

   preInstallationMapping(){
    this.spinnerExist = true;

    if (this.firstFormGroup.invalid) return;

    this.binCreationDate = moment().format('YYYY-MM-DD HH:mm:ss');
    this.installationPhase = API.InstallationPhase.PENDING_REVIEW;
    this.isFirstFormGroupSubmitted = true;
    if (this.binSelectedCluster.cluster_id == -1) {
      this.apiService.getNextClusterIdInDb();

      this.apiQuery.nextClusterIdInDb$.subscribe((nextClusterIdInDb) => {
        if (!nextClusterIdInDb) return;

        this.apiService.putNewBinClusterId(this.binSelectedCluster.bin_id, nextClusterIdInDb);

        this.binClusterId = nextClusterIdInDb;

        this.lookForNewTranssmistion();
        
      });

    }
    else {
      this.binClusterId = this.binSelectedCluster.cluster_id;
      
      this.lookForNewTranssmistion();
    }

    let request = {
      site_id : Number(this.siteId),
      cluster_id : Number(this.firstFormGroup.getRawValue()["binClusterId"]["cluster_id"]),
      bin_lat : parseFloat(Number(this.latitude).toFixed(8)),
      bin_lon : parseFloat(Number(this.longitude).toFixed(8)),
      bin_name : this.firstFormGroup.getRawValue()["binName"],
      bin_description : this.firstFormGroup.getRawValue()["binDescription"],
      bin_address : this.secondFormGroup.getRawValue()["binAddress"],
      site_bin_type_id : Number(this.firstFormGroup.getRawValue()["binType"]["site_bin_type_id"])
    }
     this.apiService.preInstallationMappingAddNewBin(request).subscribe((v:any) => {  
       if(v.add_bin_insert_status){
        this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: this.translationsObj.SITE_MANAGMENT.SUCCESS_POPUP,func: 'goToReport'} });
       }else{
        this.dialog.open(GenericConfirmDialogComponent, { data: { parent: this, firstmsg: '', secondmsg: this.translationsObj.SITE_MANAGMENT.FAIL_POPUP,func: 'goToReport'} });
       }       
      this.spinnerExist = false;
     });   
   }

   goToReport(){
     this.router.navigate([`/preMapping/${this.siteId}`]);
   }

   confirm(funcunality) {
    switch (funcunality) {
      case 'goToReport':
        this.goToReport();
        break;
      default: break;
    }
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.installationPhase = API.InstallationPhase.PENDING;
    this.firstFormGroup = this._formBuilder.group({
      binName: new FormControl('', { validators: [Validators.required] }),
      binClusterId: new FormControl('', Validators.required),
      binType: new FormControl('', Validators.required),
      binDescription: new FormControl('', { validators: [Validators.maxLength(255)] })
    });
    
    this.secondFormGroup = this._formBuilder.group({
      binAddress: new FormControl('', { validators: [Validators.required, Validators.minLength(4)] }),
      locationValidation: new FormControl('', { validators: [Validators.required] })
    });

    this.firstFormGroup.controls.binClusterId.setValue("0"); // 0 means new cluster ID (default)

    this.firstFormGroup.statusChanges.subscribe((result) => {
    });

    this.firstFormGroup.controls.binClusterId.statusChanges.subscribe((result) => {
    });

    this.firstFormGroup.statusChanges.subscribe((result) => {
    });

    this.secondFormGroup.statusChanges.subscribe((result) => {
    });

    this.firstFormGroup.controls.binType.statusChanges.subscribe((result) => {
    });
  
    this.apiService.GetSiteBinTypes({"site_id":this.siteId});
    this.apiQuery.binTypes$.subscribe((v:any) => {		      
      if(v.length == 0 || v.siteBinsTypeInfoObj == null) {return;}    
      this.binTypesToDisplay = v.siteBinsTypeInfoObj;          
      this.firstFormGroup.controls.binType.setValue(this.binTypesToDisplay[0]);       
   }); 

   let request = {
    site_id : this.siteId
    }

    this.apiService.getBinsClusterInfoBySiteId(request);

    this.apiQuery.binsClusterInfoBySiteId$.subscribe((siteClustersList) => {   
      let newClusterItem: BinCluster = {
        binsArr:[[
          {
            bin_name: '',
            bin_address: '',
            cluster_id:0
          }
        ]],
        bin_id: 0,
        cluster_id: 0,
        count: 1
      };

      if (siteClustersList["ClustersoObj"] == undefined || siteClustersList["ClustersoObj"].length == 0){
        this.clustersToDisplay = [];
        this.lstClusters = siteClustersList["ClustersoObj"];     
        this.clustersToDisplay.unshift(newClusterItem);     
        this.firstFormGroup.controls.binClusterId.setValue(this.clustersToDisplay[0].binsArr[0][0]);      
        this.binSelectedCluster = newClusterItem;
        this.onBinSelectionChangeSubscribe();
      }else{
        this.clustersToDisplay = [];
        this.lstClusters = siteClustersList["ClustersoObj"];
        this.clustersToDisplay = siteClustersList["ClustersoObj"];       
        if(!this.clustersToDisplay.some(item => item.cluster_id == 0)){
          this.clustersToDisplay.unshift(newClusterItem);
        }
        this.firstFormGroup.controls.binClusterId.setValue(this.clustersToDisplay[0].binsArr[0][0]);       
        this.binSelectedCluster = newClusterItem;
        this.onBinSelectionChangeSubscribe();
      }
    });

    this.apiQuery.user$.subscribe(user => {
			if (!user) return;
    });

    this.subscribeBinFilterCtrlChange();
    this.onBinNameChanges();
  }

  ngAfterViewInit(){
    if (this.currentLang == 'iw') {
      document.getElementById('update').style.direction = 'ltr';
    }
    else{
      document.getElementById('update').style.direction = 'rtl';
    }

    setTimeout(() => {
      if(this.route.snapshot.paramMap.get("binName") != null){
        this.displayedName = " - " + this.route.snapshot.paramMap.get("binName");
        this.firstFormGroup.controls.binName.setValue(this.route.snapshot.paramMap.get("binName"));
        this.firstFormGroup.controls.binName.disable();

        this.apiService.getPendingPreMappingInfoBySiteId(this.siteId);
   
        this.apiQuery.preMappingInfo$.subscribe(preMappingInfo => {
          
          if (preMappingInfo.length > 0) {
            _.each(preMappingInfo, (item: any) => {         
              if ((item.bin_letter + item.bin_number).trim()  == this.route.snapshot.paramMap.get("binName")) {
                this.firstFormGroup.controls.binDescription.setValue(item.comments);           
                this.secondFormGroup.controls.binAddress.setValue(item.bin_address);
                return;     
              }
            });
          }
        });

      }else{
        this.displayedName = "";
        this.firstFormGroup.controls.binName.enable();
      }  
    }, 100);

  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  ngOnDestroy() {
    this.initPage(true);
  }


  refresh(): void {
    window.location.reload();
  }
}

export class ExampleDataSource extends DataSource<IFinalReport> {
  /** Stream of data that is provided to the table. */
  data = new BehaviorSubject<IFinalReport[]>(finalReportData);

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<IFinalReport[]> {
    return this.data;
  }

  disconnect() {
  }
}


export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface IBin {
  siteId: number;
  binName: string;
  binClusterId: number;
  binType:number;
  binAddress: string;
  binLat: number;
  binLon: number;
  binTimeZoneOffset: number;
  binTimeZoneNextDstChange: number;

}

export interface IFinalReport {
  time: string;
  capacity: number;
  capacityPrecent: number;
  temperature: number;
  humidity: number;
  signalQuality: number;
  battery: number;
  version: string;
}

export interface IGetBinsInfo {
  http_ref:     string;
  resource:     string;
  api_version:  number;
  count:        number;
  dataBins:     DataBin[];
  generated_on: Date;
}

export interface DataBin {
  bin_id:           number;
  sim_id:           string;
  bin_name:         string;
  bin_description:  string;
  cluster_id:       string;
  Bin_location:     BinLocation;
  bin_type_id:      number;
  is_active:        number;
  Bin_dimensions:   BinDimensions;
  Active_features:  ActiveFeatures;
  Bin_thresholds:   { [key: string]: number };
  Bin_live_Data:    BinLiveData;
  Bin_Restrictions: BinRestrictions;
  software_version: string;
}

export interface ActiveFeatures {
  is_fire_alarm_on:    number;
  is_washing_alarm_on: number;
  is_bin_upgrade_on:   number;
}

export interface BinRestrictions {
  restriction_1_start_time: string;
  restriction_1_duration:   number;
  restriction_2_start_time: string;
  restriction_2_duration:   number;
  restriction_3_start_time: string;
  restriction_3_duration:   number;
}

export interface BinDimensions {
  bin_height: number;
  bin_width:  number;
}

export interface BinLiveData {
  fill_level:           number;
  fill_level_unfiltered: number;
  fill_level_percent:   number;
  fill_level_percent_unfiltered:   number;
  fill_level_time:      string;
  color_status_id:      number;
  color_status_name:    string;
  temperature:          number;
  humidity:             number;
  last_collection_time: Date;
  battery_level:        number;
  Transmission_data:    TransmissionData;
}

export interface TransmissionData {
  last_transmission_id:       number;
  signal_quality:             number;
  last_transmission_time:     Date;
  expected_transmission_time: Date;
}

export interface BinLocation {
  bin_latitude:  number;
  bin_longitude: number;
  bin_address:   string;
  bin_gmt_time_zone: number;
}

export interface BinCluster {
  binsArr:Array<object>;
  bin_id: number;
  cluster_id: number;
  count:number;
}


export interface DialogData {
  binId: number;
  isInstallationCompleted: boolean;
  data: object;
}


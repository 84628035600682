import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subject, combineLatest, map, startWith, takeUntil, tap } from 'rxjs';
import { TranslatorService } from '../../../services/translator_service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiStore } from '../../../web-services/api/api.store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponsiveService } from '../../../services/responsive.service';
import _ from 'lodash';
import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { Loader } from '@googlemaps/js-api-loader';

interface Coordinates {
  address: string;
  latitude: number;
  longitude: number;
}
@Component({
  selector: 'app-add-ticket-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,MatAutocompleteModule,MatInputModule,MatDatepickerModule,GoogleMapsModule,MatSelectModule,
    MatNativeDateModule,NgxGpAutocompleteModule
  ],
  templateUrl: './add-ticket-modal.component.html',
  styleUrl: './add-ticket-modal.component.scss',
  providers: [
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyB0UcoR0JNhlJkETb9LkDYfnjoiTW77GTc',
        libraries: ['places']
      })
    }
  ],
})
export class AddTicketModalComponent {
  currentLang;
	translationsObj;
  isMobile: Boolean;
  ElForm: FormGroup;
  ticketEventsCause:Array<object> = [];
  priority;
	sitesListFilter: any[] = [];
  binsListFilter: any[] = [];
  filteredItems: Observable<any[]>;
  filteredBins: Observable<any[]>;
  filteredNeighborhoods: Observable<any[]>;
  @ViewChild(MatAutocompleteTrigger,{static: false}) autoSite: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger,{static: false}) autoBin: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger,{static: false}) autobinsNeighborhood: MatAutocompleteTrigger;
  chosenSite;
  chosenBin;
  chosenEventCause;
  chosenEventType;
  chosenNeighborhood;
  coordinates: Coordinates;
	usageType = 1;
  neighborhoods : string[] = [];
  minDate;
  partialEdit : boolean = false;
  displayErrorMsg : boolean = false;
  submitClicked : boolean = false;
	routeParamsSub:any;
  binIdUrl;
  binUrlInfo;
  chosenAddress: any;
  private geoCoder;
  address: string;
  latitude: number;
  longitude: number;
  zoom: number;
  ElLocationForm: FormGroup;
  map: any = null;
  statusColor: string;
  markerIcon: string = '../../../../assets/images/bins/all/greenNotOrdered.svg';
  greenMarkerIcon : string = '../../../../assets/images/bins/all/greenNotOrdered.svg';
  greenMarkerIcon2 : string = '../../../../assets/images/bins/all/greenNotOrdered2.svg';
	private readonly destroy$ = new Subject();
  center: google.maps.LatLngLiteral = {lat: 0, lng: 0};
  mapOptionsPc: google.maps.MapOptions = {
    streetViewControl: false
  };
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;

  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,private fb: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: any,
  private apiQuery:ApiQuery,private dialogRef: MatDialogRef<AddTicketModalComponent>,private modalService: NgbModal,private apiService:ApiService,private apiStore:ApiStore,
  private route: ActivatedRoute) {
    this.dialogRef.disableClose = true;

    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
    this.coordinates = {} as Coordinates;

    this.ElForm = this.fb.group({
      description: new FormControl(''),
      eventCause: new FormControl('', Validators.required),
      isWorkplanIncluded: new FormControl(false, Validators.required),
      site:new FormControl('', Validators.required),
      binAddress:new FormControl('', Validators.required),
      binName: new FormControl({value: '', disabled: true}),
      binsNeighborhood:new FormControl(''),
      arrival_date:new FormControl({value: '', disabled: true}),
      arrival_date_displayed:new FormControl({value: '', disabled: true})
    });

    this.ElLocationForm = this.fb.group({
      lonLat: new FormControl('')
    });
   }

  ngOnInit() {
    this.onResize();
	  this.responsiveService.checkWidth();
    this.submitClicked = false;
    this.minDate = moment().utc()["_d"];
    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}		
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
      if ((user["user_role_id"] == 3) || (user["user_role_id"] == 4)) {
        this.partialEdit = true;
      }else{
        this.partialEdit = false;
      }
		});

    if(this.data.selectedTab == 0){
      this.changeType(1);
      this.ElLocationForm.controls.lonLat.clearValidators();
      this.ElLocationForm.controls.lonLat.updateValueAndValidity();

      this.ElForm.controls.binName.setValidators(this.setRequired());
      this.ElForm.controls.binName.updateValueAndValidity();
    }else if(this.data.selectedTab == 1){
      this.changeType(3);
      this.ElLocationForm.controls.lonLat.setValidators(this.setRequired());
      this.ElLocationForm.controls.lonLat.updateValueAndValidity();

      this.ElForm.controls.binName.clearValidators();
      this.ElForm.controls.binName.updateValueAndValidity();
    }else if(this.data.selectedTab == 2){
      this.changeType(4);
      this.ElLocationForm.controls.lonLat.clearValidators();
      this.ElLocationForm.controls.lonLat.updateValueAndValidity();

      this.ElForm.controls.binName.setValidators(this.setRequired());
      this.ElForm.controls.binName.updateValueAndValidity();
    }

    this.apiQuery.sitesListData$.subscribe((sitesList) => {		            			
      if(sitesList.length == 0) return;
      this.sitesListFilter = sitesList;	 
      this.sitesListFilter.sort(function(a, b){
        if(a.site_name < b.site_name) { return -1; }
        if(a.site_name > b.site_name) { return 1; }
        return 0;
      });      
      if(this.sitesListFilter.length == 1){
        let option = {
          value : {
            site_id : this.sitesListFilter[0].site_id
          }
        }
        this.optionSelected(option);
        this.ElForm.controls.site.setValue(this.chosenSite);
        this.ElForm.controls.site.disable();
      }   
      this.filteredItems = this.ElForm.controls.site.valueChanges
      .pipe(
      tap((val:any) => {
        if(val == ''){
          this.ElForm.controls.binAddress.setValue('');    
          this.ElForm.controls.binName.setValue('');   
          this.ElForm.controls.binName.disable();
          this.ElForm.controls.binsNeighborhood.setValue('');   
          this.ElForm.controls.binsNeighborhood.disable();
        }        
      }),
      startWith(''),
      map(item => item ? this.filterItems(item) : this.sitesListFilter.slice())
      );  
    });
  }

  ngOnDestroy() {
    if(this.routeParamsSub){
      this.routeParamsSub.unsubscribe();
    }
    this.destroy$.next(true);
    this.destroy$.complete();
	}

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  changeCause(cause){
    this.chosenEventCause = cause.value;
    if(this.chosenEventCause == 2 || this.chosenEventCause == 3){
      this.choosePriority(3);
    }else if(this.chosenEventCause == 5 || this.chosenEventCause == 9 || this.chosenEventCause == 10){
      this.choosePriority(2);
    }else if(this.chosenEventCause == 6 || this.chosenEventCause == 7 || this.chosenEventCause == 8){
      this.choosePriority(1);
    }
    
    if(this.chosenEventCause == 5){
      this.ElForm.controls.arrival_date.setValue(moment(moment().add(1,'months')).utc()["_d"]); 
    }else if(this.chosenEventCause == 2 || this.chosenEventCause == 3 
      || this.chosenEventCause == 9 || this.chosenEventCause == 10 ||
      this.chosenEventCause == 6 || this.chosenEventCause == 7 || this.chosenEventCause == 8){
      this.ElForm.controls.arrival_date.setValue(moment(moment().add(2,'weeks')).utc()["_d"]);    
    }     

    if(this.currentLang == 'iw'){
      this.ElForm.controls.arrival_date_displayed.setValue(moment(this.ElForm.getRawValue()["arrival_date"]).format('DD/MM/YYYY'));    
    }else{
      this.ElForm.controls.arrival_date_displayed.setValue(moment(this.ElForm.getRawValue()["arrival_date"]).format('MM/DD/YYYY'));    
    } 
    this.validateTickets();
  }

  validateTickets(){
    combineLatest(
			this.apiQuery.cRTicketsList$,
			this.apiQuery.insTicketsList$,
			this.apiQuery.rmTicketsList$,
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(([cRTicketsList,insTicketsList,rmTicketsList])=>{	
        let existingTickets = [];	
        if(cRTicketsList.length == 0 && insTicketsList.length == 0 && rmTicketsList.length == 0){return;}

        _.each(cRTicketsList, (item) => {					
					if(item["TicketsList"]){
						if(item["TicketsList"]["open"]!=null){			
							_.each(item["TicketsList"]["open"], (ticket) => {	
                ticket.site_name = item["site_name"];
                ticket.site_id = item["site_id"];
                existingTickets.push(ticket);	   	
							});		
						}	
					}		
				});

				_.each(insTicketsList, (item) => {					
					if(item["TicketsList"]){
						if(item["TicketsList"]["open"]!=null){			
							_.each(item["TicketsList"]["open"], (ticket) => {	
                ticket.site_name = item["site_name"];
                ticket.site_id = item["site_id"];
                existingTickets.push(ticket);	 	
							});		
						}								
					}		
				});

				_.each(rmTicketsList, (item) => {					
					if(item["TicketsList"]){
						if(item["TicketsList"]["open"]!=null){			
							_.each(item["TicketsList"]["open"], (ticket) => {	
                ticket.site_name = item["site_name"];
                ticket.site_id = item["site_id"];
                existingTickets.push(ticket);	 	
							});		
						}								
					}		
				});
  
        if(this.chosenBin && this.chosenSite && this.chosenEventCause && this.chosenEventType == 1){
          if (existingTickets.some(ticket => ticket.ticket_id_info[ticket.ticket_id_info.length - 1].bin_name == this.chosenBin.bin_name
            && ticket.site_id == this.chosenSite.site_id 
            && ticket.ticketData.ticket_event_cause_id == this.chosenEventCause && 
            ticket.ticketData.ticket_event_type_id == this.chosenEventType)){
              this.displayErrorMsg = true;
          }else{
            this.displayErrorMsg = false;
          }
        }else if(this.chosenBin && this.chosenSite && this.chosenEventType == 4){
          if (existingTickets.some(ticket => ticket.ticket_id_info[ticket.ticket_id_info.length - 1].bin_name == this.chosenBin.bin_name
            && ticket.site_id == this.chosenSite.site_id && ticket.ticketData.ticket_event_type_id == this.chosenEventType)){
              this.displayErrorMsg = true;
          }else{
            this.displayErrorMsg = false;
          }
        }
      });
  }

  changeType(type){
    this.chosenEventType = type;
    this.ElForm.controls.site.setValue('');    
    this.ElForm.controls.description.setValue('');    
    this.ElForm.controls.eventCause.setValue('');    
    this.ElForm.controls.binAddress.setValue('');    
    this.ElForm.controls.binName.setValue('');    
    this.ElForm.controls.binsNeighborhood.setValue('');    
    this.priority = null;
    this.chosenBin = null;
    this.chosenSite = null;
    this.chosenEventCause = null;
    this.displayErrorMsg = false;
    this.apiQuery.ticketEventsCause$.subscribe((cause) => {
      if(cause.length == 0){return;}
      const response = cause;
      const relevantResponse = _.filter(response, (res) => {      
        return res["ticket_event_type_id"] == this.chosenEventType;
      });          
      this.ticketEventsCause = relevantResponse.length > 0 ? relevantResponse[0]["TicketEventCausesObj"] : [];      
    });
    this.routeParamsSub = this.route
    .queryParams
    .subscribe(params => {
      this.binIdUrl = params['binId'] || null;
      if(this.binIdUrl){
        this.apiQuery.binInfo$.subscribe((info) => {
          if (!info || parseInt(this.binIdUrl) != info.bin_id) {
            return;
          }
          this.binUrlInfo = info;
          let option = {
            value : {
              site_id : this.binUrlInfo.site_id
            }
          }
          this.optionSelected(option);
          this.ElForm.controls.site.setValue(this.chosenSite);
          this.ElForm.controls.site.disable();
          this.ElForm.controls.binAddress.disable();
        });
      }
    });
    if(this.chosenEventType == 1 || this.chosenEventType == 4){
      this.validateTickets();
    }
  }

  setRequired() {
		return [Validators.required];
	}

  filterItems(name: string) {    
    if(name["site_name"]){
      let results = this.sitesListFilter.filter(item =>
        item.site_name.toLowerCase().indexOf(name["site_name"].toLowerCase()) === 0);      
      return results;
    }else{
      let results = this.sitesListFilter.filter(item =>
        item.site_name.toLowerCase().indexOf(name.toLowerCase()) === 0);      
      return results;
    }
  }

  filterBins(name: string) {      
    let results = this.binsListFilter.filter(item =>
      item.bin_name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    return results;
  }

  filterBinsNeighborhoodList(name: string) {          
    let results = this.neighborhoods.filter(item =>
      item.toLowerCase().indexOf(name.toLowerCase()) === 0);
    return results;
  }

  displayProperty(value) {
    if (value) {
      return value.site_name;
    }
  }

  optionSelected(option) {
    this.apiQuery.sitesListData$.subscribe((sitesList) => {		            			
      if(sitesList.length == 0) return;
      this.sitesListFilter = sitesList;	 
      this.sitesListFilter.sort(function(a, b){
        if(a.site_name < b.site_name) { return -1; }
        if(a.site_name > b.site_name) { return 1; }
        return 0;
      });
      const site = _.filter(this.sitesListFilter, (site) => {
        return site.site_id == option.value.site_id;
      });
      this.chosenSite = site[0];   
      if(this.map){
        this.loadAPIWrapper(this.map,0);
      }
      this.apiQuery.filteredBins$.subscribe((bins) => {		   
        if(bins.length == 0) return;
        const binsAccordingToSite = _.filter(bins, (bin) => {
          return bin["site_id"] == this.chosenSite.site_id;
        });
  
        this.binsListFilter = binsAccordingToSite;	
        this.ElForm.controls.binName.enable();       
        this.filteredBins = this.ElForm.controls.binName.valueChanges
        .pipe(
          tap((val:any) => {
            if(val == ''){
              this.ElForm.controls.binAddress.setValue(''); 
              this.displayErrorMsg = false;
            }else{
              this.optionSelectedBinName(val);     
            }        
          }),
        startWith(''),
        map(item => item ? this.filterBins(item) : this.binsListFilter.slice())
        );  
        if(this.binIdUrl){
          this.optionSelectedBinName(this.binUrlInfo.bin_name);
          this.ElForm.controls.binName.setValue(this.binUrlInfo.bin_name);
          this.ElForm.controls.binName.disable();
        }
      });
  
      let BinsNeighborhoodList = {
        'site_id': this.chosenSite.site_id
      }
  
      this.apiService.getBinsNeighborhoodList(BinsNeighborhoodList).subscribe((v:any) => {	
        let ngh = [];
        _.each(v.NBRHoodList, (site) => {
          _.each(site.site_NBRH_list, (neighborhood) => {
            ngh.push(neighborhood.nbrh_name);
          });
        });
        this.neighborhoods = ngh;   
        if(this.data.selectedTab == 1){
          if(this.neighborhoods.length > 0){
            this.ElForm.controls.binsNeighborhood.setValidators(this.setRequired());
          }else{
            this.removeNeighborhoodValidation();
          }  
        }else{
          this.removeNeighborhoodValidation();
        }           
        this.ElForm.controls.binsNeighborhood.enable();       
        this.filteredNeighborhoods = this.ElForm.controls.binsNeighborhood.valueChanges
        .pipe(
        startWith(''),
        map(item => item ? this.filterBinsNeighborhoodList(item) : this.neighborhoods.slice())
        );  
      });
      this.validateTickets();
    }); 
  }

  optionSelectedBinName(option){    
    const bin = _.filter(this.binsListFilter, (bin) => {      
			return bin.bin_name == option;
		});   
    if(bin.length > 0){
      this.chosenBin = bin[0];
      this.ElForm.controls.binAddress.setValue(this.chosenBin.Bin_location.bin_address);   
      this.validateTickets(); 
    }
  }

  optionSelectedBinsNeighborhood(option){    
    const allNeighborhood = _.filter(this.neighborhoods, (neighborhood) => {      
      return option.value ? neighborhood.trim() == option.value.trim() : neighborhood.trim() == option.trim();
		});    
    this.chosenNeighborhood = allNeighborhood[0] ? allNeighborhood[0].trim() : '';        
  }

  removeNeighborhoodValidation(){
    this.ElForm.controls.binsNeighborhood.clearValidators();
    this.ElForm.controls.binsNeighborhood.updateValueAndValidity();
    this.ElForm.controls.binsNeighborhood.setValue('');
  }

  addTicket(){
    if(!this.ElLocationForm.valid || !this.ElForm.valid || !this.priority || this.displayErrorMsg || this.submitClicked){
      return;
    }
    this.submitClicked = true;
    const request = {   
      "event_cause_id":this.ElForm.getRawValue()["eventCause"],
      "event_type_id":this.data.selectedTab == 0 ? 1 : this.data.selectedTab == 1 ? 3 : 4,
      "priority_id":this.priority,
      "ticket_id":0,
      "comment":this.ElForm.getRawValue()["description"],
      "status_id":1,
      "site_id":this.chosenSite.site_id,
    }

    if(this.chosenEventCause == 5){
      request["expected_arrival_date"] = moment(moment().add(1,'months')).format('YYYY-MM-DD HH:mm:ss');
    }else if(this.chosenEventCause == 2 || this.chosenEventCause == 3 
      || this.chosenEventCause == 9 || this.chosenEventCause == 10 ||
      this.chosenEventCause == 6 || this.chosenEventCause == 7 || this.chosenEventCause == 8){
      request["expected_arrival_date"] = moment(moment().add(2,'weeks')).format('YYYY-MM-DD HH:mm:ss');
    } 

    if(this.data.selectedTab == 0){
      request["bin_id"] = this.chosenBin.bin_id;
      request["analysis_id"] = 0;
      request["ticket_remove_replace_cause_id"] = 0;
      request["is_replaced"] = 0;
      if(this.ElForm.getRawValue()["eventCause"] == 5){
        request["is_remove_from_work_plans"] = 0;
      }else if(this.ElForm.getRawValue()["isWorkplanIncluded"]){
        request["is_remove_from_work_plans"] = 0;
      }else{
        request["is_remove_from_work_plans"] = 1;
      }
    }else if(this.data.selectedTab == 1){
      request["is_installed"] = 0;
      request["bin_install_address"] = this.ElForm.getRawValue()["binAddress"];
      request["lat"] = this.latitude;
      request["lon"] = this.longitude;
      request["installation_type_id"] = 0;
      request["installed_bin_neighborhood"] = this.chosenNeighborhood ? this.chosenNeighborhood : '';
    }else if(this.data.selectedTab == 2){
      request["bin_id"] = this.chosenBin.bin_id;
      request["is_removed_from_bin"] = 0;
      request["is_removed_from_site"] = 0;
      request["ticket_remove_replace_cause_id"] = 0;
    }
    
    this.apiService.insertNewTicket(request).subscribe((v:any) => {         
      let ticketManagementRequest = {};   
      if(sessionStorage.getItem("ticketManagementMinDate") == '' || sessionStorage.getItem("ticketManagementMaxDate") == '' || 
      sessionStorage.getItem("ticketManagementMinDate") == null || sessionStorage.getItem("ticketManagementMaxDate") == null){
        ticketManagementRequest = {
          status_id : -1
          }
      }else{
        ticketManagementRequest = {
          start_time : sessionStorage.getItem("ticketManagementMinDate"),
          end_time : sessionStorage.getItem("ticketManagementMaxDate")
          }
      }				
  
      this.apiStore.update({ allCRTicketsList: [] });
      this.apiStore.update({ cRTicketsList: [] });
      this.apiService.getCRTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
  
      this.apiStore.update({ allInsTicketsList: [] });
      this.apiStore.update({ insTicketsList: [] });
      this.apiService.getInsTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
  
      this.apiStore.update({ allRmTicketsList: [] });
      this.apiStore.update({ rmTicketsList: [] });
      this.apiService.getRmTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

      this.apiStore.update({ allTicketsList: [] });
      this.apiStore.update({ ticketsList: [] });
      this.apiService.getTicketsList(JSON.parse(sessionStorage.getItem("chosenSites")));

      this.apiStore.update({ allFilteredBins: [] });                    
      this.apiStore.update({ filteredBins: [] });
      this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));

      this.apiStore.update({ allFilteredBasicBins: [] });                    
      this.apiStore.update({ filteredBasicBins: [] }); 
      this.apiService.getfilteredBasicBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));

      if(this.data.selectedTab == 0 || this.data.selectedTab == 2){
        this.apiService.getBinInfo(this.chosenBin.bin_id);
      }
      let data = {
        status: 1,
      }
      this.dialogRef.close(data);
    });
  }

  closeModal(){
    let data = {
      status: 0,
    }
    this.dialogRef.close(data);
  }

  handleAddressChange(place: google.maps.places.PlaceResult) {
    this.chosenAddress = place;
    this.changeLoc();
  }

  changeLoc(){
    if(this.ElLocationForm.getRawValue()['lonLat'].length == 0){
      return;
    }
    const lonLat = this.ElLocationForm.getRawValue()['lonLat'].split(",");
    if(isNaN(lonLat[0]) || isNaN(lonLat[1])){
      if(this.chosenAddress.geometry){
        this.latitude = Number(this.chosenAddress.geometry.location.lat().toFixed(8));
        this.longitude = Number(this.chosenAddress.geometry.location.lng().toFixed(8));
      }
    }else{
      this.latitude = Number(lonLat[0]);
      this.longitude = Number(lonLat[1]);
    }
    this.getAddress(this.latitude, this.longitude);
  }

  markerDragEnd($event: any) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    const lonLat = this.latitude.toFixed(8) + "," + this.longitude.toFixed(8);
    this.ElLocationForm.controls["lonLat"].setValue(lonLat);   
    this.getAddress(this.latitude, this.longitude); 
  }

  getAddress(latitude, longitude) {
    this.geoCoder = new google.maps.Geocoder;
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        }
      }
    });
    this.center = {lat: latitude, lng: longitude};
  }

  loadAPIWrapper(map: any,flag: number) {
    this.map = map;
    if(!document.getElementById('center-managebin')){
      this.addYourLocationButton(this.map);
    }
    const lon = this.chosenSite ? this.chosenSite.nav_start_point_lon : 0.00000000;
    const lat = this.chosenSite ? this.chosenSite.nav_start_point_lat : 0.00000000;

    this.setCurrentLocation(lon,lat,flag);
    this.statusColor = "GREEN";
    this.usageType == 1 ? this.markerIcon = this.greenMarkerIcon : this.markerIcon = this.greenMarkerIcon2;
  }

  setCurrentLocation(lon,lat,flag) {
    this.latitude = Number(lat);
    this.longitude = Number(lon);
    if(flag == 1){
      const lonLat = Number(this.latitude).toFixed(8) + "," + Number(this.longitude).toFixed(8);
      this.ElLocationForm.controls["lonLat"].setValue(lonLat); 
    }
    this.zoom = 8;
    this.getAddress(this.latitude, this.longitude);
  }

  addYourLocationButton(map) {
		let controlDiv = document.createElement('div');
		let firstChild = document.createElement('button');
		firstChild.type = 'button';
		firstChild.style.backgroundColor = '#fff';
		firstChild.style.border = 'none';
		firstChild.style.outline = 'none';
		firstChild.style.width = '40px';
		firstChild.style.height = '40px';
		firstChild.style.borderRadius = '2px';
		firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
		firstChild.style.cursor = 'pointer';
		firstChild.style.marginRight = '10px';
		firstChild.style.padding = '0px';
		firstChild.title = 'Your Location';
		controlDiv.appendChild(firstChild);
		
		let secondChild = document.createElement('div');
		secondChild.style.margin = 'calc(50% - 18px / 2)';
		secondChild.style.width = '18px';
		secondChild.style.height = '18px';
		secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
		secondChild.style.backgroundSize = '180px 18px';
		secondChild.style.backgroundPosition = '0px 0px';
		secondChild.style.backgroundRepeat = 'no-repeat';
		secondChild.id = 'center-managebin';
		firstChild.appendChild(secondChild);
		
		google.maps.event.addListener(map, 'dragend', function() {
			document.getElementById('center-managebin').style.backgroundPosition = '0px 0px';
		});

		firstChild.addEventListener('click', () => {
			var imgX = '0';
			var animationInterval = setInterval(function(){
				if(imgX == '-18') imgX = '0';
				else imgX = '-18';
				document.getElementById('center-managebin').style.backgroundPosition = imgX+'px 0px';
			}, 500);
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition( pos => {        
				map.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude});

        this.latitude = pos.coords.latitude;
        this.longitude = pos.coords.longitude;
        const lonLat = this.latitude.toFixed(8) + "," + this.longitude.toFixed(8);
        this.ElLocationForm.controls["lonLat"].setValue(lonLat);   
        this.getAddress(this.latitude, this.longitude); 
        map.setZoom(15);
				clearInterval(animationInterval);
				document.getElementById('center-managebin').style.backgroundPosition = '-144px 0px';
				}, error => {
				},{maximumAge:10000, timeout:5000, enableHighAccuracy: true});
			}else{
				clearInterval(animationInterval);
				document.getElementById('center-managebin').style.backgroundPosition = '0px 0px';
			}
		});
		map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
	}

  choosePriority(priority){
    this.priority = priority; 
  }
}

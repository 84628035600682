import { Component, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../../web-services/api/api.service';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-reset-password-modal',
  standalone: true,
  imports: [TranslateModule,RTLDivDirectiveDirective,FormsModule,CommonModule],
  templateUrl: './reset-password-modal.component.html',
  styleUrl: './reset-password-modal.component.scss'
})
export class ResetPasswordModalComponent {
  isEmpty: boolean;
  emailValidation: boolean;
  email:any;
  resetEmail: string;
  submit: boolean = false;
  showResetApproval: boolean = false;
	@Output() showHideResetModal = new EventEmitter<any>();
	@Output() showHideSuccessModal = new EventEmitter<any>();

  constructor(private apiService:ApiService) { }

  ngOnInit() {
    this.resetEmail = "";
	}
	
	public closeModal = () => {
    this.resetEmail = "";
		if (this.showResetApproval) {
			this.showResetApproval = false;
			return;
		}
    this.submit = false;
		this.showHideResetModal.emit(false);
  }
  
  public sendEmail = (email: string) => {
    if (email.length === 0)
      this.isEmpty = true;
    this.submit = true;
    if (this.emailValidation){
      const emailToReset = {
        "email_address": email
      }
      this.apiService.forgotMyPassword(emailToReset);
      this.resetEmail = "";
      this.showHideResetModal.emit(false);
      this.showHideSuccessModal.emit(true);
      
    }
  }
  public validateEmail = (event) => {
    const patt = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/;
    this.emailValidation = patt.test(event.value);
  }
}

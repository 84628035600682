@if (!isMobile) {
  <div class="padded workplans-page">
    <div class="dashboard-header">
      <div class='header-bold'>{{ 'TITLES.WORKPLANS' | translate }}</div>
      <div class="header-btns">
        @if (isAdmin) {
          <div [ngClass]="{'disableBtn' : trucksListData.length == 0, 'enableBtn' : trucksListData.length > 0}" class='dashboard-header-right space-lang' (click)='openCreateNewWorkplanModal()' rtlDiv>
            <div class='add-workplan-icon'></div>
            {{ 'TITLES.NEW_WORKPLAN' | translate }}
          </div>
        }
        @if (isAdmin) {
          <div [ngClass]="{'disableBtn' : trucksListData.length == 0, 'enableBtn' : trucksListData.length > 0}" class='dashboard-header-right' (click)='openForceBinsToFutureWorkplanModal()' rtlDiv>
            <div class='add-workplan-icon'></div>
            {{ 'WORKPLAN.ADD_BIN_TO_FUTURE_WORKPLAN' | translate }}
          </div>
        }
      </div>
    </div>
    <mat-divider class="top-divider"></mat-divider>
    <app-workplan-schedule (dateClicked)="dateClickedHandler($event)" (dayEvents)="eventsHandler($event)" (eventSelected)="eventSelectedHandler($event)" (activeWorkplan)="activeWorkplanHandler($event)"></app-workplan-schedule>
    <div class="bin-info-container">
      <app-workplan-date [dateSelected]="dateSelected"></app-workplan-date>
      <app-workplan-manager [dayClicked]="dateClicked" [hidden]="(!recommendedWorkPlan || !isActiveWorkPlan || futureWorkplanExist || userGeneratedState) && !emptyTruck" [isAdmin]="isAdmin" (chosenSite)="chosenSiteHandler($event)" (chosenTruck)="chosenTruckHandler($event)" (tabletTeamsAllocationId)="tabletTeamsAllocationIdHandler($event)" (truckDriverName)="truckDriverNameHandler($event)" (userGeneratedState)="userGeneratedStateHandler($event)" (dateSelected)="dateSelectedHandler($event)" [eventSelected]="eventSelected" [dayEvents]="dayEvents"></app-workplan-manager>
    </div>
    <div [hidden]="!recommendedWorkPlan">
      <div [hidden]="(!isActiveWorkPlan || futureWorkplanExist || userGeneratedState)" class="bin-info-container">
        <div class="first-row">
          <app-truck-details [chosenSite]="chosenSite" [chosenTruck]="chosenTruck" [tabletTeamsAllocationId]="tabletTeamsAllocationId" [truckDriverName]="truckDriverName" [loginException]="loginException" [distance]="totalDistance" [totalTime]="totalTime"></app-truck-details>
        </div>
        <div class="second-row">
          <app-truck-map [eventSelectedManager]="chosenSite" [chosenTruck]="chosenTruck" (loginException)="loginExceptionObj($event)" (transferTime)="updateTime($event)" (transferDistance)="updateDistance($event)"></app-truck-map>
        </div>
        <div class="third-row">
          <app-truck-workplans-list-view [eventSelectedManager]="chosenSite" [chosenTruck]="chosenTruck"></app-truck-workplans-list-view>
        </div>
      </div>
    </div>
    <div [hidden]="isActiveWorkPlan">
      <div class="no-workplan-container">
        <div class="no-workplan-text-container" rtlDiv>
          <div class="no-workplan-img-container" rtlDiv>
            <div class="empty-state-img" rtlDiv></div>
          </div>
          <div class="no-workplans-text" rtlDiv>{{ 'TITLES.NO_WORKPLAN' | translate }}</div>
        </div>
        @if (isAdmin) {
          <div class="add-workplan-container">
            <div class='add-workplan-center' (click)='openCreateNewWorkplanModal()' rtlDiv>
              <div class='add-workplan-icon'></div>
              {{ 'TITLES.NEW_WORKPLAN' | translate }}
            </div>
          </div>
        }
      </div>
    </div>
    <div [hidden]="recommendedWorkPlan">
      <div class="no-workplan-container">
        <div class="no-workplan-text-container" rtlDiv>
          <div class="no-workplan-img-container" rtlDiv>
            <div class="empty-state-img" rtlDiv></div>
          </div>
          <div class="no-workplans-text" rtlDiv>{{ 'TITLES.NO_WORKPLAN' | translate }}</div>
        </div>
        <div class="add-workplan-container">
          <div class='add-workplan-center' [ngClass]="{'disableBtn' : spinnerActive , 'enableBtn' : !spinnerActive}" rtlDiv (click)="showRecommendedWorkPlan()">
            {{ 'WORKPLAN.RECOMMENDED_WORK' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="!futureWorkplanExist">
      <div class="no-workplan-container">
        <div class="no-workplan-text-container" rtlDiv>
          <div class="no-workplan-img-container" rtlDiv>
            <div class="future-events-state-img" rtlDiv>
              <img src="../../../../assets/images/workplans-page/futureWorkplanExist.svg" alt=""/>
            </div>
          </div>
          <div class="no-workplans-text" rtlDiv>{{ 'TITLES.YOU_HAVE' | translate }} {{dayEvents ? dayEvents.length : 0}} {{ 'TITLES.WORKPLANS_SCHEDULED' | translate }}</div>
        </div>
      </div>
    </div>
    <div [hidden]="!userGeneratedState">
      <div class="no-workplan-container">
        <div class="no-workplan-text-container" rtlDiv>
          <div class="no-workplan-img-container" rtlDiv>
            <div class="empty-state-img" rtlDiv></div>
          </div>
          <div class="no-workplans-text" rtlDiv>{{ 'TITLES.NO_WORKPLAN' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
}

@if (isMobile) {
  <div>
    <div class="mobile-top-container">
      <div class="mobile-workplan-header">
        <div class="mobile-left-title">{{ 'TITLES.WORKPLANS' | translate }}</div>
      </div>
    </div>
    <mat-card class="mobile-schedule-title">
      <div class="title">{{ 'WORKPLAN.TITLE' | translate }}</div>
      @if(isAdmin){
        <div class="mobile-share-btn" rtlDiv>
          <button class="mobile-share" data-bs-toggle="dropdown" rtlDiv>
            <img class="mobile-share-img" src="../assets/images/workplans-page/kebab.svg" alt="share_icon">
          </button>
          <div class="dropdown-menu mobile-list-items" rtlDiv>
            <div class="menuItem" rtlDiv (click)="openCreateNewWorkplanModal()">
              <div class="exist-item" rtlDiv>
                <img rtlDiv alt="" src="../../../assets/images/workplans-page/add_new.svg">
                <span class="item-txt">{{ 'TITLES.NEW_WORKPLAN' | translate }}</span>
              </div>
            </div>
            <div class="menuItem mobile-last-item" rtlDiv (click)="openForceBinsToFutureWorkplanModal()">
              <div class="exist-item" rtlDiv>
                <img rtlDiv alt="" src="../../../assets/images/workplans-page/add_bins.svg">
                <span class="item-txt">{{ 'WORKPLAN.ADD_BIN_TO_FUTURE_WORKPLAN' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      }
    </mat-card>
    <app-workplan-schedule (dateClicked)="dateClickedHandler($event)" (dayEvents)="eventsHandler($event)" (eventSelected)="eventSelectedHandler($event)" (activeWorkplan)="activeWorkplanHandler($event)"></app-workplan-schedule>
    <div [hidden]="!recommendedWorkPlan">
      <app-workplan-manager [dayClicked]="dateClicked" [hidden]="(!isActiveWorkPlan || futureWorkplanExist || userGeneratedState) && !emptyTruck" [isAdmin]="isAdmin" (chosenSite)="chosenSiteHandler($event)" (chosenTruck)="chosenTruckHandler($event)" (truckDriverName)="truckDriverNameHandler($event)" (tabletTeamsAllocationId)="tabletTeamsAllocationIdHandler($event)" (userGeneratedState)="userGeneratedStateHandler($event)" (dateSelected)="dateSelectedHandler($event)" [eventSelected]="eventSelected" [dayEvents]="dayEvents"></app-workplan-manager>
      <div [hidden]="(!isActiveWorkPlan || futureWorkplanExist || userGeneratedState)" class="bin-info-container">
        <app-truck-details [chosenSite]="chosenSite" [chosenTruck]="chosenTruck" [truckDriverName]="truckDriverName" [tabletTeamsAllocationId]="tabletTeamsAllocationId" [loginException]="loginException" [distance]="totalDistance" [totalTime]="totalTime"></app-truck-details>
        <app-truck-map [eventSelectedManager]="chosenSite" [chosenTruck]="chosenTruck" (loginException)="loginExceptionObj($event)" (transferTime)="updateTime($event)" (transferDistance)="updateDistance($event)"></app-truck-map>
        <app-truck-workplans-list-view [eventSelectedManager]="chosenSite" [chosenTruck]="chosenTruck"></app-truck-workplans-list-view>
      </div>
    </div>
    <div class="mobile-isActiveWorkplan" [hidden]="isActiveWorkPlan">
      <div class="mobile-workplan">
        <div class="no-workplan-container">
          <div class="no-workplan-text-container" rtlDiv>
            <div class="no-workplan-img-container" rtlDiv>
              <div class="empty-state-img" rtlDiv></div>
            </div>
            <div class="no-workplans-text" rtlDiv>{{ 'TITLES.NO_WORKPLAN' | translate }}</div>
            <div class="no-workplan-text-info" rtlDiv>{{ 'TITLES.NO_WORKPLAN_INFO' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-isActiveWorkplan" [hidden]="recommendedWorkPlan">
      <div class="mobile-workplan">
        <div class="no-workplan-container">
          <div class="no-workplan-text-container" rtlDiv>
            <div class="no-workplan-img-container" rtlDiv>
              <div class="empty-state-img" rtlDiv></div>
            </div>
            <div class="no-workplans-text" rtlDiv>{{ 'TITLES.NO_WORKPLAN' | translate }}</div>
          </div>
          <div class="add-workplan-container">
            <div class='add-workplan-center' [ngClass]="{'disableBtn' : spinnerActive , 'enableBtn' : !spinnerActive}" rtlDiv (click)="showRecommendedWorkPlan()">
              {{ 'WORKPLAN.RECOMMENDED_WORK' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-isActiveWorkplan" [hidden]="!futureWorkplanExist">
      <div class="mobile-workplan">
        <div class="no-workplan-container" rtlDiv>
          <div class="no-workplan-text-container" rtlDiv>
            <div class="no-workplan-img-container" rtlDiv>
              <div class="future-events-state-img" rtlDiv>
                <img src="../../../../assets/images/workplans-page/futureWorkplanExist.svg" alt=""/>
              </div>
            </div>
            <div class="no-workplans-text" rtlDiv>{{ 'TITLES.YOU_HAVE' | translate }} {{dayEvents ? dayEvents.length : 0}} {{ 'TITLES.WORKPLANS_SCHEDULED' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-isActiveWorkplan" [hidden]="!userGeneratedState">
      <div class="mobile-workplan">
        <div class="no-workplan-container">
          <div class="no-workplan-text-container" rtlDiv>
            <div class="no-workplan-img-container" rtlDiv>
              <div class="empty-state-img" rtlDiv></div>
            </div>
            <div class="no-workplans-text" rtlDiv>{{ 'TITLES.NO_WORKPLAN' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
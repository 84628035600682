@if (!isMobile) {
  <div>
  </div>
}

@if (isMobile) {
  <div>
    <div class="mobile-modal-title" rtlDiv>
      <div class="dflex" (click)="closeModal()">
        <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
      </div>
      <div>
        <span class="main-title">{{ 'TITLES.MANAGE_COL_TITLE' | translate }}</span>
      </div>
    </div>
    <div class="padding-sides">
      @if(data.page == 'inventory'){
        <mat-selection-list #inventoryGroup rtlDiv class="dynamic-options" (selectionChange)="onInventorySelectionChange()">
          <mat-list-option [value]="'isBinNameChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isBinNameChecked" [disabled]="(inventoryGroup.selectedOptions.selected.length == 2 && !isBinNameChecked.selected) || usageType == 1" #isBinNameChecked>{{ 'LIST_COLUMNS.NAME' | translate }}</mat-list-option>          
          <mat-list-option [value]="'isAddressChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isAddressChecked" #isAddressChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isAddressChecked.selected">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</mat-list-option>
          @if (usageType == 2) {
            <mat-list-option [value]="'isClusterIdChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isClusterIdChecked" #isClusterIdChecked [disabled]="(inventoryGroup.selectedOptions.selected.length == 2 && !isClusterIdChecked.selected) || usageType == 2">{{ 'LIST_COLUMNS.DESCRIPTION' | translate }}</mat-list-option>
          }
          @if (usageType == 1) {
            <mat-list-option [value]="'isBinDescChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isBinDescChecked" #isBinDescChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isBinDescChecked.selected">{{ 'LIST_COLUMNS.DESCRIPTION' | translate }}</mat-list-option>
          }
          @if (usageType == 2) {
            <mat-list-option [value]="'isQuarterAcreChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isQuarterAcreChecked" #isQuarterAcreChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isQuarterAcreChecked.selected">{{ 'LIST_COLUMNS.QUARTER_ACRE' | translate }}</mat-list-option>
          }
          @if (usageType == 1) {
            <mat-list-option [value]="'isLastCollectionChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isLastCollectionChecked" #isLastCollectionChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isLastCollectionChecked.selected">{{ 'LIST_COLUMNS.LAST_COLLECTION' | translate }}</mat-list-option>
          }
          @if (usageType == 2) {
            <mat-list-option [value]="'isLastRefillChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isLastRefillChecked" #isLastRefillChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isLastRefillChecked.selected">{{ 'LIST_COLUMNS.LAST_REFILL' | translate }}</mat-list-option>
          }
          <mat-list-option [value]="'isLastUpdateChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isLastUpdateChecked" #isLastUpdateChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isLastUpdateChecked.selected">{{ 'LIST_COLUMNS.LAST_UPDATE' | translate }}</mat-list-option>
          <mat-list-option [value]="'isStatusChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isStatusChecked" #isStatusChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isStatusChecked.selected">{{ 'LIST_COLUMNS.STATUS' | translate }}</mat-list-option>
          @if (usageType == 1) {
            <mat-list-option [value]="'isNeighborhoodChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isNeighborhoodChecked" #isNeighborhoodChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 &&!isNeighborhoodChecked.selected">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</mat-list-option>
          }
          <mat-list-option [value]="'isBinSiteColChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isBinSiteColChecked" #isBinSiteColChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isBinSiteColChecked.selected">{{ 'TRUCK_MAP.SITE' | translate }}</mat-list-option>
          <mat-list-option [value]="'isBinIdColChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isBinIdColChecked" #isBinIdColChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isBinIdColChecked.selected">{{ 'LIST_COLUMNS.ID' | translate }}</mat-list-option>
          <mat-list-option [value]="'isBinTypeColChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isBinTypeColChecked" #isBinTypeColChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isBinTypeColChecked.selected">
            @if (usageType == 1) {
              <span>{{ 'LIST_COLUMNS.BIN_TYPE' | translate }}</span>
            }
            @if (usageType == 2) {
              <span>{{ 'LIST_COLUMNS.TANK_TYPE' | translate }}</span>
            }
          </mat-list-option>
          <mat-list-option [value]="'isBinInstallDateColChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isBinInstallDateColChecked" #isBinInstallDateColChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isBinInstallDateColChecked.selected">{{ 'LIST_COLUMNS.INSTALLATION_DATE' | translate }}</mat-list-option>
          <mat-list-option [value]="'isBinAvgRateColChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isBinAvgRateColChecked" #isBinAvgRateColChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isBinAvgRateColChecked.selected">
            @if (usageType == 1) {
              <span>{{ 'LIVE_INFO.EMPTY_RATE' | translate }}</span>
            }
            @if (usageType == 2) {
              <span>{{ 'FILLINGS.AVG_DAILY_RATE' | translate }}</span>
            }
          </mat-list-option>
          @if (usageType == 1) {
            <mat-list-option [value]="'isBinAvgUsageRateChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isBinAvgUsageRateChecked" #isBinAvgUsageRateChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isBinAvgUsageRateChecked.selected">{{ 'LIVE_INFO.AVG_RATE' | translate }}</mat-list-option>
          }
          @if (usageType == 1) {
            <mat-list-option [value]="'isBinCollectionFrequencyChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isBinCollectionFrequencyChecked" #isBinCollectionFrequencyChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isBinCollectionFrequencyChecked.selected">{{ 'MANAGE_BIN.MONTHLY_COLLECTION_FREQ' | translate }}</mat-list-option>
          }
          @if (usageType == 2) {
            <mat-list-option [value]="'isAvgDailyRateDunamColChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isAvgDailyRateDunamColChecked" #isAvgDailyRateDunamColChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isAvgDailyRateDunamColChecked.selected">{{'LIVE_INFO.AVG_DAILY_EMPTYING_RATE_DUNAM' | translate }}</mat-list-option>
          }
          <mat-list-option [value]="'isBinPredictedTimeForMaxAllowedCapacityCol'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isBinPredictedTimeForMaxAllowedCapacityCol" #isBinPredictedTimeForMaxAllowedCapacityCol [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isBinPredictedTimeForMaxAllowedCapacityCol.selected">{{'LIST_COLUMNS.FULLNESS_FORECAST_DATE' | translate}}</mat-list-option>
          <mat-list-option [value]="'isBinCollectionModeChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isBinCollectionModeChecked" #isBinCollectionModeChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isBinCollectionModeChecked.selected">{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</mat-list-option>
          <mat-list-option [value]="'isBinDeviceIdColChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" [selected]="inventorySelectedValues.isBinDeviceIdColChecked" #isBinDeviceIdColChecked [disabled]="inventoryGroup.selectedOptions.selected.length == 2 && !isBinDeviceIdColChecked.selected">{{ 'SITE_MANAGMENT.DEVICE_ID' | translate }}</mat-list-option>
        </mat-selection-list>
      }
  
      @if(data.page == 'collections'){
        <mat-selection-list #collectionsGroup class="dynamic-options" rtlDiv (selectionChange)="onCollectionsSelectionChange()">
          <mat-list-option [selected]="collectionsSelectedValues.isBinNameChecked" [disabled]="true" [value]="'isBinNameChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBinNameChecked>{{ 'SITE_MANAGMENT.BIN_NAME' | translate }}</mat-list-option>          
          <mat-list-option [selected]="collectionsSelectedValues.isSiteNameChecked" [disabled]="collectionsGroup.selectedOptions.selected.length == 2 && !isSiteNameChecked.selected" [value]="'isSiteNameChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isSiteNameChecked>{{ 'TRUCK_MAP.SITE' | translate }}</mat-list-option>
          <mat-list-option [selected]="collectionsSelectedValues.isAddressChecked" [disabled]="collectionsGroup.selectedOptions.selected.length == 2 && !isAddressChecked.selected" [value]="'isAddressChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isAddressChecked>{{ 'LIST_COLUMNS.ADDRESS' | translate }}</mat-list-option>
          <mat-list-option [selected]="collectionsSelectedValues.isNeighborhoodChecked" [disabled]="collectionsGroup.selectedOptions.selected.length == 2 && !isNeighborhoodChecked.selected" [value]="'isNeighborhoodChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isNeighborhoodChecked>{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</mat-list-option>
          <mat-list-option [selected]="collectionsSelectedValues.isBinTypeColChecked" [disabled]="collectionsGroup.selectedOptions.selected.length == 2 && !isBinTypeColChecked.selected" [value]="'isBinTypeColChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBinTypeColChecked>{{ 'LIST_COLUMNS.BIN_TYPE' | translate }}</mat-list-option>        
          <mat-list-option [selected]="collectionsSelectedValues.isBinCollectionTime" [disabled]="collectionsGroup.selectedOptions.selected.length == 2 && !isBinCollectionTime.selected" [value]="'isBinCollectionTime'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBinCollectionTime>{{ 'LIVE_INFO.LIST_COLLECTION_TIME' | translate }}</mat-list-option>        
          <mat-list-option [selected]="collectionsSelectedValues.isBinFillLevelBeforeCollection" [disabled]="collectionsGroup.selectedOptions.selected.length == 2 && !isBinFillLevelBeforeCollection.selected" [value]="'isBinFillLevelBeforeCollection'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBinFillLevelBeforeCollection>{{ 'LIST_COLUMNS.Capacity_Pick_Up' | translate }}</mat-list-option>         
          <mat-list-option [selected]="collectionsSelectedValues.isCollectionCauseNameChecked" [disabled]="collectionsGroup.selectedOptions.selected.length == 2 && !isCollectionCauseNameChecked.selected" [value]="'isCollectionCauseNameChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isCollectionCauseNameChecked>{{ 'TITLES.COLLECTION_CAUSE' | translate }}</mat-list-option>          
          <mat-list-option [selected]="collectionsSelectedValues.isBinAvgFillRateChecked" [disabled]="collectionsGroup.selectedOptions.selected.length == 2 && !isBinAvgFillRateChecked.selected" [value]="'isBinAvgFillRateChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBinAvgFillRateChecked>{{ 'LIVE_INFO.AVG_RATE' | translate }}</mat-list-option>
          <mat-list-option [selected]="collectionsSelectedValues.isDriverName" [disabled]="collectionsGroup.selectedOptions.selected.length == 2 && !isDriverName.selected" [value]="'isDriverName'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isDriverName>{{ 'TITLES.DRIVER_NAME' | translate }}</mat-list-option>
          <mat-list-option [selected]="collectionsSelectedValues.isLicensePlate" [disabled]="collectionsGroup.selectedOptions.selected.length == 2 && !isLicensePlate.selected" [value]="'isLicensePlate'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isLicensePlate>{{ 'TITLES.LICENSE_PLATE' | translate }}</mat-list-option>
        </mat-selection-list>
      }
  
      @if(data.page == 'ticketManagementCheckOrReplacement'){
        <mat-selection-list #ticketManagementCheckOrReplacementGroup class="dynamic-options" rtlDiv (selectionChange)="onTicketManagementCheckOrReplacementSelectionChange()">
          <mat-list-option [disabled]="ticketManagementCheckOrReplacementGroup.selectedOptions.selected.length == 2 && !isTicketIdChecked.selected" [selected]="ticketManagementCheckOrReplacementSelectedValues.isTicketIdChecked" [value]="'isTicketIdChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketIdChecked>{{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }}</mat-list-option>
          <mat-list-option [disabled]="true" [selected]="ticketManagementCheckOrReplacementSelectedValues.isTicketStatusChecked" [value]="'isTicketStatusChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketStatusChecked>{{ 'TICKET_MANAGEMENT.STATUS' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCheckOrReplacementSelectedValues.isTicketCauseChecked" [disabled]="ticketManagementCheckOrReplacementGroup.selectedOptions.selected.length == 2 && !isTicketCauseChecked.selected" [value]="'isTicketCauseChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketCauseChecked>{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</mat-list-option>
          @if (user_role_id != 3 && user_role_id != 4) {
            <mat-list-option [selected]="ticketManagementCheckOrReplacementSelectedValues.isTicketPriorityChecked" [disabled]="ticketManagementCheckOrReplacementGroup.selectedOptions.selected.length == 2 && !isTicketPriorityChecked.selected" [value]="'isTicketPriorityChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketPriorityChecked>{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</mat-list-option>
          }
          <mat-list-option [selected]="ticketManagementCheckOrReplacementSelectedValues.isTicketSiteChecked" [disabled]="ticketManagementCheckOrReplacementGroup.selectedOptions.selected.length == 2 && !isTicketSiteChecked.selected" [value]="'isTicketSiteChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketSiteChecked>{{ 'TICKET_MANAGEMENT.SITE' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCheckOrReplacementSelectedValues.isBinNameChecked" [disabled]="ticketManagementCheckOrReplacementGroup.selectedOptions.selected.length == 2 && !isBinNameChecked.selected" [value]="'isBinNameChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBinNameChecked>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCheckOrReplacementSelectedValues.isTicketAddressChecked" [disabled]="ticketManagementCheckOrReplacementGroup.selectedOptions.selected.length == 2 && !isTicketAddressChecked.selected" [value]="'isTicketAddressChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketAddressChecked>{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCheckOrReplacementSelectedValues.isDescChecked" [disabled]="ticketManagementCheckOrReplacementGroup.selectedOptions.selected.length == 2 && !isDescChecked.selected" [value]="'isDescChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isDescChecked>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCheckOrReplacementSelectedValues.isReporterChecked" [disabled]="ticketManagementCheckOrReplacementGroup.selectedOptions.selected.length == 2 && !isReporterChecked.selected" [value]="'isReporterChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isReporterChecked>{{ 'TICKET_MANAGEMENT.REPORTER' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCheckOrReplacementSelectedValues.isTicketCreatedChecked" [disabled]="ticketManagementCheckOrReplacementGroup.selectedOptions.selected.length == 2 && !isTicketCreatedChecked.selected" [value]="'isTicketCreatedChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketCreatedChecked>{{ 'TICKET_MANAGEMENT.CREATED' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCheckOrReplacementSelectedValues.isArrivalDateChecked" [disabled]="ticketManagementCheckOrReplacementGroup.selectedOptions.selected.length == 2 && !isArrivalDateChecked.selected" [value]="'isArrivalDateChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isArrivalDateChecked>{{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCheckOrReplacementSelectedValues.isTicketClosedChecked" [disabled]="ticketManagementCheckOrReplacementGroup.selectedOptions.selected.length == 2 && !isTicketClosedChecked.selected" [value]="'isTicketClosedChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketClosedChecked>{{ 'TICKET_MANAGEMENT.CLOSED' | translate }}</mat-list-option>
        </mat-selection-list>
      }

      @if(data.page == 'ticketManagementInstallation'){
        <mat-selection-list #ticketManagementInstallationGroup class="dynamic-options" rtlDiv (selectionChange)="onTicketManagementInstallationSelectionChange()">
          <mat-list-option [disabled]="ticketManagementInstallationGroup.selectedOptions.selected.length == 2 && !isTicketIdChecked.selected" [selected]="ticketManagementInstallationSelectedValues.isTicketIdChecked" [value]="'isTicketIdChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketIdChecked>{{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }}</mat-list-option>
          <mat-list-option [disabled]="true" [selected]="ticketManagementInstallationSelectedValues.isTicketStatusChecked" [value]="'isTicketStatusChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketStatusChecked>{{ 'TICKET_MANAGEMENT.STATUS' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementInstallationSelectedValues.isTicketCauseChecked" [disabled]="ticketManagementInstallationGroup.selectedOptions.selected.length == 2 && !isTicketCauseChecked.selected" [value]="'isTicketCauseChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketCauseChecked>{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</mat-list-option>
          @if (user_role_id != 3 && user_role_id != 4) {
            <mat-list-option [selected]="ticketManagementInstallationSelectedValues.isTicketPriorityChecked" [disabled]="ticketManagementInstallationGroup.selectedOptions.selected.length == 2 && !isTicketPriorityChecked.selected" [value]="'isTicketPriorityChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketPriorityChecked>{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</mat-list-option>
          }
          <mat-list-option [selected]="ticketManagementInstallationSelectedValues.isTicketSiteChecked" [disabled]="ticketManagementInstallationGroup.selectedOptions.selected.length == 2 && !isTicketSiteChecked.selected" [value]="'isTicketSiteChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketSiteChecked>{{ 'TICKET_MANAGEMENT.SITE' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementInstallationSelectedValues.isBinNameChecked" [disabled]="ticketManagementInstallationGroup.selectedOptions.selected.length == 2 && !isBinNameChecked.selected" [value]="'isBinNameChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBinNameChecked>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementInstallationSelectedValues.isTicketAddressChecked" [disabled]="ticketManagementInstallationGroup.selectedOptions.selected.length == 2 && !isTicketAddressChecked.selected" [value]="'isTicketAddressChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketAddressChecked>{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementInstallationSelectedValues.isDescChecked" [disabled]="ticketManagementInstallationGroup.selectedOptions.selected.length == 2 && !isDescChecked.selected" [value]="'isDescChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isDescChecked>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementInstallationSelectedValues.isReporterChecked" [disabled]="ticketManagementInstallationGroup.selectedOptions.selected.length == 2 && !isReporterChecked.selected" [value]="'isReporterChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isReporterChecked>{{ 'TICKET_MANAGEMENT.REPORTER' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementInstallationSelectedValues.isTicketCreatedChecked" [disabled]="ticketManagementInstallationGroup.selectedOptions.selected.length == 2 && !isTicketCreatedChecked.selected" [value]="'isTicketCreatedChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketCreatedChecked>{{ 'TICKET_MANAGEMENT.CREATED' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementInstallationSelectedValues.isArrivalDateChecked" [disabled]="ticketManagementInstallationGroup.selectedOptions.selected.length == 2 && !isArrivalDateChecked.selected" [value]="'isArrivalDateChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isArrivalDateChecked>{{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementInstallationSelectedValues.isTicketClosedChecked" [disabled]="ticketManagementInstallationGroup.selectedOptions.selected.length == 2 && !isTicketClosedChecked.selected" [value]="'isTicketClosedChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketClosedChecked>{{ 'TICKET_MANAGEMENT.CLOSED' | translate }}</mat-list-option>
        </mat-selection-list>
      }

      @if(data.page == 'ticketManagementCancellation'){
        <mat-selection-list #ticketManagementCancellationGroup class="dynamic-options" rtlDiv (selectionChange)="onTicketManagementCancellationSelectionChange()">
          <mat-list-option [disabled]="ticketManagementCancellationGroup.selectedOptions.selected.length == 2 && !isTicketIdChecked.selected" [selected]="ticketManagementCancellationSelectedValues.isTicketIdChecked" [value]="'isTicketIdChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketIdChecked>{{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }}</mat-list-option>
          <mat-list-option [disabled]="true" [selected]="ticketManagementCancellationSelectedValues.isTicketStatusChecked" [value]="'isTicketStatusChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketStatusChecked>{{ 'TICKET_MANAGEMENT.STATUS' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCancellationSelectedValues.isTicketCauseChecked" [disabled]="ticketManagementCancellationGroup.selectedOptions.selected.length == 2 && !isTicketCauseChecked.selected" [value]="'isTicketCauseChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketCauseChecked>{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</mat-list-option>
          @if (user_role_id != 3 && user_role_id != 4) {
            <mat-list-option [selected]="ticketManagementCancellationSelectedValues.isTicketPriorityChecked" [disabled]="ticketManagementCancellationGroup.selectedOptions.selected.length == 2 && !isTicketPriorityChecked.selected" [value]="'isTicketPriorityChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketPriorityChecked>{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</mat-list-option>
          }
          <mat-list-option [selected]="ticketManagementCancellationSelectedValues.isTicketSiteChecked" [disabled]="ticketManagementCancellationGroup.selectedOptions.selected.length == 2 && !isTicketSiteChecked.selected" [value]="'isTicketSiteChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketSiteChecked>{{ 'TICKET_MANAGEMENT.SITE' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCancellationSelectedValues.isBinNameChecked" [disabled]="ticketManagementCancellationGroup.selectedOptions.selected.length == 2 && !isBinNameChecked.selected" [value]="'isBinNameChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBinNameChecked>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCancellationSelectedValues.isTicketAddressChecked" [disabled]="ticketManagementCancellationGroup.selectedOptions.selected.length == 2 && !isTicketAddressChecked.selected" [value]="'isTicketAddressChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketAddressChecked>{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCancellationSelectedValues.isDescChecked" [disabled]="ticketManagementCancellationGroup.selectedOptions.selected.length == 2 && !isDescChecked.selected" [value]="'isDescChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isDescChecked>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCancellationSelectedValues.isReporterChecked" [disabled]="ticketManagementCancellationGroup.selectedOptions.selected.length == 2 && !isReporterChecked.selected" [value]="'isReporterChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isReporterChecked>{{ 'TICKET_MANAGEMENT.REPORTER' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCancellationSelectedValues.isTicketCreatedChecked" [disabled]="ticketManagementCancellationGroup.selectedOptions.selected.length == 2 && !isTicketCreatedChecked.selected" [value]="'isTicketCreatedChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketCreatedChecked>{{ 'TICKET_MANAGEMENT.CREATED' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCancellationSelectedValues.isArrivalDateChecked" [disabled]="ticketManagementCancellationGroup.selectedOptions.selected.length == 2 && !isArrivalDateChecked.selected" [value]="'isArrivalDateChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isArrivalDateChecked>{{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCancellationSelectedValues.isTicketClosedChecked" [disabled]="ticketManagementCancellationGroup.selectedOptions.selected.length == 2 && !isTicketClosedChecked.selected" [value]="'isTicketClosedChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isTicketClosedChecked>{{ 'TICKET_MANAGEMENT.CLOSED' | translate }}</mat-list-option>
          <mat-list-option [selected]="ticketManagementCancellationSelectedValues.isRemovedReasonChecked" [disabled]="ticketManagementCancellationGroup.selectedOptions.selected.length == 2 && !isRemovedReasonChecked.selected" [value]="'isRemovedReasonChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isRemovedReasonChecked>{{ 'TICKET_MANAGEMENT.REMOVED_REASON' | translate }}</mat-list-option>
        </mat-selection-list>
      }

      @if(data.page == 'workplanList'){
        <mat-selection-list #workplanListGroup class="dynamic-options" rtlDiv (selectionChange)="onWorkplanListSelectionChange()">
          <mat-list-option [selected]="workplanListSelectedValues.isBinOrderColChecked" [disabled]="workplanListGroup.selectedOptions.selected.length == 2 && !isBinOrderColChecked.selected" [value]="'isBinOrderColChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBinOrderColChecked>{{ 'WORKPLAN.COLLECTION_ORDER' | translate }}</mat-list-option>
          <mat-list-option [selected]="workplanListSelectedValues.isBinNameChecked" [disabled]="true" [value]="'isBinNameChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBinNameChecked>{{ 'LIST_COLUMNS.NAME' | translate }}</mat-list-option>
          <mat-list-option [selected]="workplanListSelectedValues.isBinIdColChecked" [disabled]="workplanListGroup.selectedOptions.selected.length == 2 && !isBinIdColChecked.selected" [value]="'isBinIdColChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBinIdColChecked>{{ 'LIST_COLUMNS.ID' | translate }}</mat-list-option>
          <mat-list-option [selected]="workplanListSelectedValues.isBinTypeColChecked" [disabled]="workplanListGroup.selectedOptions.selected.length == 2 && !isBinTypeColChecked.selected" [value]="'isBinTypeColChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBinTypeColChecked>{{ 'LIST_COLUMNS.BIN_TYPE' | translate }}</mat-list-option>
          <mat-list-option [selected]="workplanListSelectedValues.isAddressChecked" [disabled]="workplanListGroup.selectedOptions.selected.length == 2 && !isAddressChecked.selected" [value]="'isAddressChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isAddressChecked>{{ 'LIST_COLUMNS.ADDRESS' | translate }}</mat-list-option>          
          <mat-list-option [selected]="workplanListSelectedValues.isNeighborhoodChecked" [disabled]="workplanListGroup.selectedOptions.selected.length == 2 && !isNeighborhoodChecked.selected" [value]="'isNeighborhoodChecked'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isNeighborhoodChecked>{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</mat-list-option>
          <mat-list-option [selected]="workplanListSelectedValues.isBeforeCollectionCapacityInPercent" [disabled]="workplanListGroup.selectedOptions.selected.length == 2 && !isBeforeCollectionCapacityInPercent.selected" [value]="'isBeforeCollectionCapacityInPercent'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isBeforeCollectionCapacityInPercent>{{ 'WORKPLAN.CAP_AT_PICKUP' | translate }}</mat-list-option>
          <mat-list-option [selected]="workplanListSelectedValues.isAfterCollectionCapacityInPercent" [disabled]="workplanListGroup.selectedOptions.selected.length == 2 && !isAfterCollectionCapacityInPercent.selected" [value]="'isAfterCollectionCapacityInPercent'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isAfterCollectionCapacityInPercent>{{ 'WORKPLAN.CAP_AFTER_PICKUP' | translate }}</mat-list-option>
          <mat-list-option [selected]="workplanListSelectedValues.isStatus" [disabled]="workplanListGroup.selectedOptions.selected.length == 2 && !isStatus.selected" [value]="'isStatus'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isStatus>{{ 'LIST_COLUMNS.STATUS' | translate }}</mat-list-option>
          <mat-list-option [selected]="workplanListSelectedValues.isCollectionTime" [disabled]="workplanListGroup.selectedOptions.selected.length == 2 && !isCollectionTime.selected" [value]="'isCollectionTime'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isCollectionTime>{{ 'LIVE_INFO.LIST_COLLECTION_TIME' | translate }}</mat-list-option>
          <mat-list-option [selected]="workplanListSelectedValues.isCollected" [disabled]="workplanListGroup.selectedOptions.selected.length == 2 && !isCollected.selected" [value]="'isCollected'" rtlDiv [ngClass]="{'ltr' : currentLang == 'iw' , 'rtl' : currentLang != 'iw'}" class="list-option" #isCollected>{{ 'LIST_COLUMNS.COLLECTED' | translate }}</mat-list-option>
        </mat-selection-list>
      }
    </div>
    <div class="bottom-page">
      <div class="info-row" rtlDiv>
        <img class="cursor-pointer" src="assets/images/topmenu/info.svg" alt=""/>
        <span>{{ 'TITLES.MANAGE_COL' | translate }}</span>
      </div>
      <div class='main-btns-wrapper' rtlDiv>
        <div class='btns-container' rtlDiv>
          <div class="mobile-cancel_btn" (click)="closeModal()">{{ 'TRUCK_MAP.CANCEL' | translate }}</div>
          @if(data.page == 'inventory'){
            <div class="mobile-apply-btn" [ngClass]="{'disabled_btn' : !inventorySelectedOptionsAndNotDisabled}" (click)="applyChanges()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</div>
          }@else if(data.page == 'collections'){
            <div class="mobile-apply-btn" [ngClass]="{'disabled_btn' : !collectionsSelectedOptionsAndNotDisabled}" (click)="applyChanges()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</div>
          }@else if(data.page == 'ticketManagementCheckOrReplacement'){
            <div class="mobile-apply-btn" [ngClass]="{'disabled_btn' : !ticketManagementCheckOrReplacementSelectedOptionsAndNotDisabled}" (click)="applyChanges()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</div>
          }@else if(data.page == 'ticketManagementInstallation'){
            <div class="mobile-apply-btn" [ngClass]="{'disabled_btn' : !ticketManagementInstallationSelectedOptionsAndNotDisabled}" (click)="applyChanges()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</div>
          }@else if(data.page == 'ticketManagementCancellation'){
            <div class="mobile-apply-btn" [ngClass]="{'disabled_btn' : !ticketManagementCancellationSelectedOptionsAndNotDisabled}" (click)="applyChanges()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</div>
          }@else if(data.page == 'workplanList'){
            <div class="mobile-apply-btn" [ngClass]="{'disabled_btn' : !workplanListSelectedOptionsAndNotDisabled}" (click)="applyChanges()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</div>
          }
        </div>
      </div>
    </div>
  </div>
}
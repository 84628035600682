import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NumberWithDescriptionComponent } from '../../components/number-with-description/number-with-description.component';
import { TranslatorService } from '../../services/translator_service';
import { ResponsiveService } from '../../services/responsive.service';
import { ApiQuery } from '../../web-services/api/api.query';
import _ from 'lodash';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-bin-status',
  standalone: true,
  imports: [NgCircleProgressModule,TranslateModule,NumberWithDescriptionComponent,MatCardModule,
	MatDividerModule,RTLDivDirectiveDirective,RouterModule],
  templateUrl: './binstatus.component.html',
  styleUrl: './binstatus.component.scss'
})
export class BinstatusComponent {
  @Input() orientation: boolean = false;
	@Output() showCMP = new EventEmitter<boolean>(true);
	translate;
	workPlanProgress = 0;
	activeTrucks = 0;
	collectedBins = 0;
	notIncluded:any[]=[];
	groups:any[]=[];
	totalActiveTrucks = 0;
	totalCollectedBins = 0;
	testProgress:any;
	isMobile: boolean = false;
	spinnerActive:boolean = true;
	constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,private apiQuery:ApiQuery) {
		this.translate = this.translator.translate;
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.spinnerActive = true;
		this.apiQuery.filteredDailyWorkPlan$.subscribe(workPlans => {	
			this.spinnerActive = true;					
			if(workPlans.length == 0)return;			
			this.spinnerActive = false;					
			let plan : any[] = [];
			_.each(workPlans, (item:any) => {
				if(item["actual_plan_start_time"] != null){
					plan.push(item);
				}
			});
			let totalNumOfBinsToCollect = 0;
			let totalNumOfCollectedBins = 0;
			let totalNumOfBinsToCollectForSite = 0;
			let totalNumOfCollectedBinsForSite = 0;
			let totalNumOfActiveTrucks = 0;
			let totalNumOfTrucks = 0;
			let totalWorkplanProgress = 0;
			_.each(plan, (item) => {
				_.each(item["Vehicle_info"], (vehicleInfo) => {						
					totalNumOfBinsToCollect += vehicleInfo["total_num_of_bins_to_collect"];
					totalNumOfBinsToCollectForSite += vehicleInfo["total_num_of_bins_to_collect"];
					_.each(vehicleInfo["Bins_collection_info"], (collectedInfo) => {
						if(collectedInfo.is_collected == 1){													
							totalNumOfCollectedBins += 1;
							totalNumOfCollectedBinsForSite += 1;
						}
					});
				});
				item["workPlanProgress"] = Math.floor(totalNumOfCollectedBinsForSite / totalNumOfBinsToCollectForSite * 100)
				totalNumOfBinsToCollectForSite = 0;
				totalNumOfCollectedBinsForSite = 0;
			});

			_.each(plan, (site) => {
				totalNumOfTrucks += site["num_of_vehicles"];
				totalNumOfActiveTrucks += site["num_of_transit_trucks"];
				totalWorkplanProgress += site["workPlanProgress"];
			});

			if (!plan || plan.length == 0 || totalNumOfBinsToCollect == 0){					
				let fraction = 0;				
				this.activeTrucks = 0;
				this.collectedBins = 0;
				this.totalActiveTrucks = 0;
				this.totalCollectedBins = 0;
				this.workPlanProgress = Math.floor(fraction * 100);
			}
			else{
				this.activeTrucks = totalNumOfActiveTrucks;
				this.collectedBins = totalNumOfCollectedBins;
				this.totalActiveTrucks = totalNumOfTrucks;
				this.totalCollectedBins = totalNumOfBinsToCollect;
				this.workPlanProgress = Math.floor(totalWorkplanProgress / plan.length);
			}	
		});

		this.apiQuery.CollectionsStatsForWorkplanProgres$.subscribe((data:any) => {
			this.groups = [];
			this.notIncluded = [];
			_.each(data, (site) => {
				if(site.site_bins_collections != undefined){
				_.each(site.site_bins_collections["0_25"], (item) => {    
					this.groups.push(item);
				});    
				_.each(site.site_bins_collections["26_50"], (item) => {   
					this.groups.push(item);
				});
				_.each(site.site_bins_collections["51_75"], (item) => {     
					this.groups.push(item);
				});
				_.each(site.site_bins_collections["76_85"], (item) => {          
					this.groups.push(item);
				});
				_.each(site.site_bins_collections["86_100"], (item) => {         
					this.groups.push(item);
				});
				}
			});
			_.each(this.groups, (bin) => {
				if(bin.collection_cause_id == 0){
					this.notIncluded.push(bin);
				}
			});						
		})
	}

	closeCMP(){
		this.showCMP.emit(false);
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild, ViewRef } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import moment from 'moment';
import _ from 'lodash';
import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { Chart } from 'chart.js';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslatorService } from '../../../services/translator_service';
import { ApiService } from '../../../web-services/api/api.service';
import { takeUntil } from 'rxjs/operators';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  host: {
		'(document:click)': 'handleClick($event)',
	},
  selector: 'app-bin-sampling-history-report',
  standalone: true,
  imports: [TranslateModule,MatCardModule,RTLDivDirectiveDirective,MatDividerModule,MatMenuModule,
    CommonModule,ScrollingModule,MatTableModule,MatSortModule,MatCheckboxModule,TableVirtualScrollModule],
  templateUrl: './bin-sampling-history-report.component.html',
  styleUrl: './bin-sampling-history-report.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BinSamplingHistoryReportComponent {
  dateDiff;
	@Input()
	set datesDiff(datesDiff: string) {		
		if(datesDiff != null){
			this.dateDiff = datesDiff;
			this.minDate = moment(datesDiff.split("-")[0].trim(), 'DD.MM.YYYY').format('YYYY-MM-DD 00:00:01');
			this.maxDate = moment(datesDiff.split("-")[1].trim(), 'DD.MM.YYYY').format('YYYY-MM-DD 23:59:59');
			if (this.selection.selected.length > 0) {
				if(this.selection.selected.length == 1){
					this.selectedBins = this.selection.selected[0]["bin_id"];
				}

				let ids = [];
				_.each(this.selection.selected, (item) => {
					ids.push(item["bin_id"]);
				});  
		
				let dataToSend = {};
				dataToSend["bin_id_list"] = ids;
				this.dataToSendSorting = ids;
				dataToSend["start_time"] = this.minDate;
				dataToSend["end_time"] = this.maxDate;
				this.apiService.GetBinsCapacityData(JSON.parse(sessionStorage.getItem("chosenSites")), dataToSend);
				this.changeCollectionReportRange(this.selected_time);
			}else{
				this.apiQuery.filteredBasicBins$.subscribe(bins => {
					this.selection = new SelectionModel<Element>(true, []);
					if (_.isEmpty(bins)) return;
					let binsFiltered = _.map(bins, b => {
						return {
							bin_name: b["bin_name"],
							bin_id: b["bin_id"],
							bin_neighborhood:b['Bin_location']['bin_neighbourhood'],
							bin_max_volume_in_liters: b["Bin_thresholds"].bin_max_capacity_in_liters,
							bin_address: b["Bin_location"]["bin_address"]						
						};
					});
					this.binsData = binsFiltered;
					this.dataSource = new TableVirtualScrollDataSource<any>(binsFiltered);
					this.dataSource.sort = this.sort;								
					if(!this.selection.isSelected(this.binsData[0])){
					this.selection.toggle(this.binsData[0]);
					}
					
					this.selectedBins = this.binsData[0].bin_id;

					let dataToSend = {};
					dataToSend["bin_id_list"] = [this.binsData[0].bin_id];
					this.dataToSendSorting = this.binsData[0].bin_id;
					dataToSend["start_time"] = this.minDate;
					dataToSend["end_time"] = this.maxDate;
					this.apiService.GetBinsCapacityData(JSON.parse(sessionStorage.getItem("chosenSites")), dataToSend);
					this.changeCollectionReportRange(this.selected_time);
				});
			}
		}		
	}
	
	@ViewChild(MatSort,{static: false}) sort: MatSort;
	chart: Chart = null;
	binCapacityData: any;	
	usageType = 1;
	displayedColumns = ['select','name', 'address','neighborhood'];
	selected_time = 'day';
	daysObj;
	paginationIndex = 1;
	minDate = '';
	maxDate = '';
	showDatePicker: Boolean = false;
	lastMonthDay: any = moment(this.minDate).format('DD.MM.YY');
	binsData;
	dataSource = new TableVirtualScrollDataSource<any>();
	showBinsListDropDownList: Boolean = false;
	dataType: String = 'Capacity';
	chosenType:any;
	currentLang;
	translationsObj;
	translate;
	spinnerActive:boolean = true;
	timeDateFormat = 'DD.MM.YY';
	capacityDisplayType: number = 0;
	capacityDisplaySymbol: string = '%';
	selection = new SelectionModel<Element>(true, []);
	tempFilter = '';
	selectedBins:any;
	private readonly destroy$ = new Subject();
	dataToSendSorting;

	constructor(private translator: TranslatorService,private apiQuery:ApiQuery,private cd: ChangeDetectorRef,private apiService:ApiService) {
		this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		  if(this.currentLang != 'iw'){
			this.timeDateFormat = 'MM.DD.YY';
			this.chosenType = 'Capacity';
		  }else{
			this.chosenType = 'נפח';
		  }    
		})
	}

	ngOnInit() {		
		this.spinnerActive = true;	
	}

	ngAfterViewInit(){
		this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
			this.capacityDisplayType = user["user_default_capacity_type_id"];
			if (this.capacityDisplayType != 0) this.capacityDisplaySymbol = 'L';

			this.apiQuery.binsCapacityData$.pipe(takeUntil(this.destroy$)).subscribe((binCapacityData:any) => {
				this.initCollectionReport();
				this.spinnerActive = true;
				if (!binCapacityData || binCapacityData.length == 0) {return;}									
				  
				binCapacityData = this.mapOrder(binCapacityData, this.dataToSendSorting, 'bin_id');
				binCapacityData = binCapacityData.reverse();
				
				this.spinnerActive = false;
				this.parseBinCapacityData(binCapacityData);
				this.cd.detectChanges();
			});
		});
	}

	mapOrder(array, order, key) {
		array.sort( function (a, b) {
		  var A = a[key], B = b[key];  
		  if (order.indexOf(A) > order.indexOf(B)) {
			return 1;
		  } else {
			return -1;
		  }  
		});	
		return array;
	  };

	ngOnDestroy(){
		this.destroy$.next(true);
		this.destroy$.complete();
		if (!this.cd['destroyed'] || !(this.cd as ViewRef).destroyed) {			
			this.cd.detectChanges();
		}
	}

	getTableData = () => {		
		let tempSearch = new TableVirtualScrollDataSource<any>(this.binsData);
		tempSearch.filter = this.tempFilter;
		this.dataSource = tempSearch.filteredData.length > 0 ?
			new TableVirtualScrollDataSource<any>(tempSearch.filteredData) : new TableVirtualScrollDataSource<any>([]);			
		return this.dataSource;
	}

	removeBinFromChart(item){
		//if(this.selection.selected.length == 1){return;}
		this.selection.toggle(item);
		this.selectBin();		
	}

	sortData(sort: Sort) {
		let data = this.binsData.slice();
		if (!sort.active || sort.direction === '') {
			this.binsData = data;
			return;
		}
		this.binsData = data.sort((a, b) => {
			let isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
	}

	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	private isGraphTypeIsCapacity = () => {
		return this.dataType == 'Capacity';
	}

	private isGraphTypeIsTemperature = () => {
		return this.dataType == 'Temperature';
	}

	private isGraphTypeIsHumidity = () => {
		return this.dataType == 'Humidity';
	}

	private isGraphTypeIsBatteryLevel = () => {
		return this.dataType == 'Battery Status';
	}

	private isGraphTypeIsSignalQualit = () => {
		return this.dataType == 'Signal Quality';
	}

	public getGraphTitle = () => {		
		if (!this.translationsObj) {
			return;
		}
		if (this.isGraphTypeIsCapacity()) {
			if (this.capacityDisplayType != 0) {
				return this.translationsObj.SAMPLING_HISTORY_REPORT.Y_AXIS_CAPACITY_LITTERS;	
			}
			else {
				return this.translationsObj.SAMPLING_HISTORY_REPORT.Y_AXIS_CAPACITY;
			}
		}
		if (this.isGraphTypeIsTemperature()) {
			return this.translationsObj.SAMPLING_HISTORY_REPORT.Y_AXIS_TEMPERATURE;
		}
		if (this.isGraphTypeIsHumidity()) {
			return this.translationsObj.SAMPLING_HISTORY_REPORT.Y_AXIS_HUMIDITY;
		}
		if (this.isGraphTypeIsBatteryLevel()) {
			return this.translationsObj.TITLES.BATTERY_STATUS + " % ";
		}
		if (this.isGraphTypeIsSignalQualit()) {
			return this.translationsObj.SITE_MANAGMENT.SIGNAL_QUAL + " % ";
		}
	}

	public changeDataType = (dataType: string) => {
		this.dataType = dataType;
		if(this.isGraphTypeIsCapacity()){
			this.chosenType = this.translationsObj.SAMPLING_HISTORY_REPORT.CAPACITY;
		}else if(this.isGraphTypeIsTemperature()){
			this.chosenType = this.translationsObj.SAMPLING_HISTORY_REPORT.TEMPERATURE;
		}else if(this.isGraphTypeIsHumidity()){
			this.chosenType = this.translationsObj.SAMPLING_HISTORY_REPORT.HUMIDITY;
		}else if(this.isGraphTypeIsBatteryLevel()){
			this.chosenType = this.translationsObj.TITLES.BATTERY_STATUS;
		}else if(this.isGraphTypeIsSignalQualit()){
			this.chosenType = this.translationsObj.SITE_MANAGMENT.SIGNAL_QUAL;
		}
		this.initChart();
	}

	public showHideBinListDropdown = () => {
		this.showBinsListDropDownList = !this.showBinsListDropDownList;
	}

	public selectBin = () => {
		if(document.getElementById('bins-list') != null){
			document.getElementById('bins-list').style.visibility = "hidden";
		}
		this.showBinsListDropDownList = false;
		let ids = [];
		_.each(this.selection.selected, (item) => {    
            ids.push(item["bin_id"]);
          });  
		  if(this.selection.selected.length == 0){
			if(this.currentLang == 'iw'){
				this.selectedBins = "בחר מיכל";
			}else{
				this.selectedBins = "Select Bin";
			}
		  }else if(this.selection.selected.length == 1){		  
			this.selectedBins = this.selection.selected[0]["bin_id"];
		  }

		let dataToSend = {};
		dataToSend["bin_id_list"] = ids;
		this.dataToSendSorting = ids;
		dataToSend["start_time"] = this.minDate;
		dataToSend["end_time"] = this.maxDate;		  
		this.apiService.GetBinsCapacityData(JSON.parse(sessionStorage.getItem("chosenSites")), dataToSend);
	}

	public showHideDatePicker = () => {
		this.showDatePicker = !this.showDatePicker;
	}

	public getDateTimeToShow = () => {
		if (this.isSelectedRangeIsDay()) {			
			return moment(this.lastMonthDay, 'DD.MM.YY').format(this.timeDateFormat);	
		}

		if (this.isSelectedRangeIsWeek() || this.isSelectedRangeIsMonth()) {
			if (this.currentLang !== 'iw') {
				return `${moment(this.daysObj.minMaxTime.minTime).format(this.timeDateFormat)} - ${moment(this.daysObj.minMaxTime.maxTime).format(this.timeDateFormat)}`;
			}
			else {
				return `${moment(this.daysObj.minMaxTime.maxTime).format(this.timeDateFormat)} - ${moment(this.daysObj.minMaxTime.minTime).format(this.timeDateFormat)}`;
			}
		}
	}

	public moveGraphPrev = () => {
		if (this.paginationIndex - 1 === 0) return;
		this.paginationIndex--;
		if (this.isSelectedRangeIsDay()) {
			this.lastMonthDay = moment(this.lastMonthDay, 'DD.MM.YY').subtract(1, 'day').format('DD.MM.YY');
		}
		this.initChart();
	}

	public moveGraphNext = () => {
		this.paginationIndex++;
		if (this.isSelectedRangeIsDay() && !this.isDaysPaginationValid()) {
			return;
		}
		if (this.isSelectedRangeIsWeek() && !this.isWeeksPaginationValid()) {
			return;
		}
		if (this.isSelectedRangeIsMonth() && !this.isWeeksPaginationValid()) {
			return;
		}
		if (this.isSelectedRangeIsDay()) {
			this.lastMonthDay = moment(this.lastMonthDay, 'DD.MM.YY').add(1, 'day').format('DD.MM.YY');
		}
		this.initChart();
	}

	private isDaysPaginationValid = () => {
		if (moment(_.cloneDeep(this.lastMonthDay), 'DD.MM.YY').add(1, 'day') > moment(this.maxDate)) {
			this.paginationIndex--;
			return false;
		}
		return true;
	}

	private isWeeksPaginationValid = () => {
		if (this.paginationIndex > this.daysObj.days.length) {
			this.paginationIndex--;
			return false;
		}
		return true;
	}

	public isNextBtnDisable = () => {
		if (this.isSelectedRangeIsDay()) {
			return (moment(_.cloneDeep(this.lastMonthDay), 'DD.MM.YY').add(1, 'day') > moment(this.maxDate));
		}
		if (this.isSelectedRangeIsMonth() || this.isSelectedRangeIsWeek()) {
			return (this.paginationIndex + 1) > this.daysObj.days.length;
		}
	}


	private parseBinCapacityData = (binCapacityData: any) => {
		this.binCapacityData = binCapacityData;		
		if (this.translationsObj) {
			this.buildChart();
		}
	}


	private initCollectionReport = () => {
		this.paginationIndex = 1;
		this.resetDaysObject();
		this.daysObj = this.getDatesObjPerRange();
	}

	public changeCollectionReportRange = (time_range: string) => {				
		this.selected_time = time_range;
		this.lastMonthDay = moment(this.minDate).format('DD.MM.YY');
		this.initCollectionReport();
		this.buildChart();
	}

	private resetDaysObject = () => {
		return {
			days: [],
			daysHashSampling: {
				'Capacity': 0,
				'Temperature': 0,
				'Humidity': 0,
				'Battery Status': 0,
				'Signal Quality': 0
			},
			minMaxTime: [],
			daysArr: []
		};
	}

	private isSelectedRangeIsDay = () => {
		return this.selected_time == 'day';
	}

	private isSelectedRangeIsWeek = () => {
		return this.selected_time == 'week';
	}

	private isSelectedRangeIsMonth = () => {
		return this.selected_time == 'month';
	}

	private getDiffBetweenDates = () => {
		if (this.isSelectedRangeIsDay()) {
			return moment(this.maxDate).diff(this.minDate, 'days') + 1;
		}
		if (this.isSelectedRangeIsWeek()) {
			return moment(this.maxDate).diff(this.minDate, 'weeks') + 1;
		}
	}

	private getMonthLabel = (date: string) => {
		let startDate = moment(date).format('DD.MM.YY');
		let endDate: any = moment(date).endOf('month');
		if (endDate >= moment(this.maxDate)) {
			endDate = moment(this.maxDate).format('DD.MM.YY');
		} else {
			endDate = moment(date).endOf('month').format('DD.MM.YY');
		}
		return {
			fullFormat: `${startDate} - ${endDate}`
		};
	}

	private getDatesRangeToRun = () => {
		let minDay = 1;
		let maxDay = this.getDiffBetweenDates();
		return {
			minDay: minDay,
			maxDay: maxDay,
			dateStart: moment(this.minDate),
			dateEnd: moment(this.maxDate),
		};
	}

	private checkIfDayIsNotSunday = (date: string) => {
		return moment(date).weekday() !== 7;
	}

	private checkIfEndDayInNextMonth = (date: string) => {
		let nextDays = moment(date, 'DD.MM.YY').add(6, 'days').month();
		return nextDays !== moment(date, 'DD.MM.YY').month();
	}

	private buildDaysObjByWeeks = (daysRange: any, daysObj: any) => {
		let dayFormatStart = moment(daysRange.dateStart).format('DD.MM.YY');
		let dayFormatEnd = moment(daysRange.dateStart).add(6, 'days').format('DD.MM.YY');

		if (this.checkIfDayIsNotSunday(daysRange.dateStart)) {
			dayFormatEnd = moment(daysRange.dateStart).day(6).format('DD.MM.YY'); 
			daysRange.dateStart = moment(daysRange.dateStart).day(7); 
		} else {
			daysRange.dateStart = moment(daysRange.dateStart).add(7, 'days');
		}

		if (this.checkIfEndDayInNextMonth(dayFormatEnd)) {
			dayFormatEnd = moment(dayFormatEnd, 'DD.MM.YY').endOf('month').format('DD.MM.YY');
			daysRange.dateStart = moment(dayFormatEnd, 'DD.MM.YY').endOf('month').add(1, 'day');
		}
		if (moment(dayFormatEnd, 'DD.MM.YY') >= moment(this.maxDate)) {
			dayFormatEnd = moment(this.maxDate).format('DD.MM.YY');
		}
		let label = `${dayFormatStart} - ${dayFormatEnd}`;
		daysObj.days.push(label);
	}

	private buildDaysObjByMonth = (daysRange: any, daysObj: any) => {
		let labelForObj = this.getMonthLabel(daysRange.dateStart);
		daysObj.days.push(labelForObj.fullFormat);
		daysRange.dateStart = moment(daysRange.dateStart).endOf('month').add(1, 'day');
	}

	private getDatesObjPerRange() {
		let daysRange = this.getDatesRangeToRun();
		let daysObj = this.resetDaysObject();
		// Iterate over the days and create object by days range
		if (this.isSelectedRangeIsWeek() || this.isSelectedRangeIsMonth()) {
			while (daysRange.dateStart <= daysRange.dateEnd) {
				if (this.isSelectedRangeIsWeek()) { this.buildDaysObjByWeeks(daysRange, daysObj); }
				if (this.isSelectedRangeIsMonth()) { this.buildDaysObjByMonth(daysRange, daysObj); }
			}
		}
		return daysObj;
	}

	private buildChart = () => {
		this.initChart();
	}

	private getDaysArrLabels = (binData: any) => {
		let minTime = `${moment(this.lastMonthDay, 'DD.MM.YY').format('YYYY-MM-DD')} 00:00`;
		let maxTime = `${moment(this.lastMonthDay, 'DD.MM.YY').add(1, 'day').format('YYYY-MM-DD')} 00:00`;
		this.daysObj.minMaxTime = {
			minTime: minTime,
			maxTime: maxTime
		};
		if (_.isEmpty(binData)) {
			this.daysObj.daysArr = [minTime, maxTime];
			return;
		}

		this.daysObj.daysArr = _.map(binData, (data) => {
			if(data.Fill_level_samples){
				return data.Fill_level_samples.map(function(key) { 
					return key.measurement_time;
				  });
			}	
		});
	}

	private resetDayData = () => {
		this.daysObj.daysHashSampling['Capacity'] = [];
		this.daysObj.daysHashSampling['Temperature'] = [];
		this.daysObj.daysHashSampling['Humidity'] = [];
		this.daysObj.daysHashSampling['Battery Status'] = [];
		this.daysObj.daysHashSampling['Signal Quality'] = [];
	}

	private getDayArrData = (binData: any) => {
		if (_.isEmpty(binData)) return;
		let capacityType = this.capacityDisplayType;		
		this.daysObj.daysHashSampling['Capacity'] = _.map(binData, (data) => {
			if(data.Fill_level_samples){
				return data.Fill_level_samples.map(function(key) { 
					return capacityType == 0 ? key.fill_level_in_precent : key.fill_level_in_liters;					
				  });
			}
		});

		this.daysObj.daysHashSampling['Temperature'] = _.map(binData, (data) => {
			if(data.Fill_level_samples){
				return data.Fill_level_samples.map(function(key) { 
					return key.bin_temperature;
				  });	
			}
		});
		this.daysObj.daysHashSampling['Humidity'] = _.map(binData, (data) => {
			if(data.Fill_level_samples){
				return data.Fill_level_samples.map(function(key) { 
					return key.bin_humidity_level;
				  });
			}
		});
		this.daysObj.daysHashSampling['Battery Status'] = _.map(binData, (data) => {
			if(data.Fill_level_samples){
				return data.Fill_level_samples.map(function(key) { 
					return key.bat_stat;
				  });
			}
		});
		this.daysObj.daysHashSampling['Signal Quality'] = _.map(binData, (data) => {
			if(data.Fill_level_samples){
				return data.Fill_level_samples.map(function(key) { 
					return key.signal_quality;
				  });
			}
		});
		
	}

	private getDaysData = () => {
		this.resetDayData();
		let binData = _.each(this.binCapacityData, (bin) => {
			_.each(bin.Fill_level_samples, (item) => {    
				return moment(item.measurement_time).format('DD.MM.YY') == this.lastMonthDay;
			}); 
		});
		this.getDayArrData(binData);
		this.getDaysArrLabels(binData);
		return {
			daysLabel: this.daysObj.daysArr,
			typeData: this.daysObj.daysHashSampling[`${this.dataType}`],
		};
	}

	private getWeekMinMaxDayTime = (minWeekDate, maxWeekDate, binData) => {
		let minTime = `${moment(minWeekDate, 'DD.MM.YY').format('YYYY-MM-DD')} 00:00`;
		let maxTime = `${moment(maxWeekDate, 'DD.MM.YY').format('YYYY-MM-DD')} 00:00`;

		this.daysObj.minMaxTime = {
			minTime: minTime,
			maxTime: maxTime
		};
		
		if (_.isEmpty(binData)) {
			this.daysObj.daysArr = [minTime, maxTime];
			return;
		}		
		
		this.daysObj.daysArr = _.map(binData, (data) => {
			if(data.Fill_level_samples){
				return data.Fill_level_samples.map(function(key) { 
					return key.measurement_time;
				  });
			}	
		});
	}

	private getWeekData = () => {
		this.resetDayData();
		let weekDateRange = this.daysObj.days[this.paginationIndex - 1];
		let minWeekDate;
		let maxWeekDate;
		if (weekDateRange) {
			minWeekDate = moment(weekDateRange.split(' ')[0], 'DD.MM.YY'); 
			maxWeekDate = moment(weekDateRange.split(' ')[2], 'DD.MM.YY').add(1, 'day');
		}
		
		let binData = _.each(this.binCapacityData, (bin) => {
			_.each(bin.Fill_level_samples, (item) => {    
				let binMeasurementTime = moment(item.measurement_time);
				return binMeasurementTime >= minWeekDate && binMeasurementTime <= maxWeekDate;
			}); 
		});

		this.getWeekMinMaxDayTime(minWeekDate, maxWeekDate, binData);
		this.getDayArrData(binData);
		return {
			daysLabel: this.daysObj.daysArr,
			typeData: this.daysObj.daysHashSampling[`${this.dataType}`],
		};
	}

	private getPaginationLabelsData = () => {
		if (this.isSelectedRangeIsDay()) {
			return this.getDaysData();
		}
		if (this.isSelectedRangeIsWeek() || this.isSelectedRangeIsMonth()) {
			return this.getWeekData();
		}
	}

	private isMinTimeEqualsToMaxTime = () => {
		return this.daysObj.minMaxTime.minTime === this.daysObj.minMaxTime.maxTime;
	}

	private buildTimeLineConfig = () => {
		if (this.isSelectedRangeIsDay() || this.isMinTimeEqualsToMaxTime()) {
			return {
				unit: 'hour',
				unitStepSize: 2,
				displayFormats: { 'hour': 'HH:mm' }
			};
		}
		if (this.isSelectedRangeIsWeek() || this.isSelectedRangeIsMonth()) {
			return {
				unit: 'day',
				unitStepSize: 1,
				displayFormats: { 'day': this.timeDateFormat }
			};
		}
	}

	private calcXAxisLabel = () => {
		let retval = this.translationsObj.SAMPLING_HISTORY_REPORT.X_AXIS_DATE;
		if (this.isSelectedRangeIsDay()) {
			retval = this.translationsObj.SAMPLING_HISTORY_REPORT.X_AXIS_TIME;
		}
		return retval;
	}

	private buildYAxesTicksConfig = () => {
		let maxVolume = 0;

		if(this.selection.selected.length != 0){
			_.each(this.selection.selected, (item) => {    
				if(item["bin_max_volume_in_liters"] > maxVolume){
					maxVolume = item["bin_max_volume_in_liters"];
				}
			}); 
		} 

		if (this.isGraphTypeIsTemperature()) {
			return {
				stepSize: 20,
				suggestedMax: 120,
				suggestedMin: -40
			};
		}
		else if (this.isGraphTypeIsCapacity()) {
			if (this.capacityDisplayType != 0 && this.selection.selected.length != 0) {
				return {
					stepSize: maxVolume / 4,
					suggestedMax: maxVolume,
					suggestedMin: 0
				};
			}
			else {
				return {
					stepSize: 25,
					suggestedMax: 100,
					suggestedMin: 0
				};
			}
		}
		return {
			stepSize: 25,
			suggestedMax: 100,
			suggestedMin: 0
		};
	}
	getBinById(id) {
		return this.binsData.filter(bin => {
			return bin.bin_id == id;
		})
	}

	private initChart = () => {		
		if (this.chart) {
			this.chart.destroy();
		}
		let capacityDisplaySymbol = this.capacityDisplaySymbol;

		if (this.dataType.toLowerCase() != 'capacity') {
			if (this.dataType.toLowerCase() == 'temperature') {
				capacityDisplaySymbol = '&#8451';
			}
			else {
				capacityDisplaySymbol = '%';
			}
		}
		
		let timeDateFormat = this.timeDateFormat;
		if (!this.translationsObj) {
			return;
		}
		let graphData = this.getPaginationLabelsData();		
		if(graphData.typeData.length == 0){
			return;
		}
		
		let barTimeConfig = this.buildTimeLineConfig();
		let yAxesTicksConfig = this.buildYAxesTicksConfig();

		let dataArr = [];
		let tooltipArr = [];
		let labelsArr = [];

		_.each(this.selection.selected, (item) => { 			
			if((this.selection.selected).indexOf(item) == 0){
				item["color"] = "#60A682";
			}else if((this.selection.selected).indexOf(item) == 1){
				item["color"] = "#FFD654";
			}else{
				item["color"] = "#7CDAF7";
			}	
		}); 


		let reOrderedArr = [];		
		for(let i = graphData.typeData.length-1 ; i >= 0 ; i--){
			reOrderedArr.push(graphData.typeData[i]);
		}
		
		graphData.typeData = reOrderedArr;
		_.each(graphData.typeData, (item) => {    
				dataArr.push({
					data: item,
					borderColor: function(){
						if((graphData.typeData).indexOf(item) == 0){
							return "#60A682";
						}else if((graphData.typeData).indexOf(item) == 1){
							return "#FFD654";
						}else{
							return "#7CDAF7";
						}						
					},
					pointBackgroundColor: function(){
						if((graphData.typeData).indexOf(item) == 0){
							return "#60A682";
						}else if((graphData.typeData).indexOf(item) == 1){
							return "#FFD654";
						}else{
							return "#7CDAF7";
						}						
					},
					pointBorderColor: function(){
						if((graphData.typeData).indexOf(item) == 0){
							return "#60A682";
						}else if((graphData.typeData).indexOf(item) == 1){
							return "#FFD654";
						}else{
							return "#7CDAF7";
						}						
					},
					borderWidth: 1,
					lineTension: 0,
					pointRadius: 2,
					pointHitRadius: 6,
					pointHoverRadius: 3,
					fill:false
				});
			});	

			_.each(graphData.daysLabel, (item) => {    
				_.each(item, (dateTime) => {    
					labelsArr.push(
						dateTime
					);
				});	
			});	

			_.each(graphData.typeData, (item) => {    
				tooltipArr.push({
					data: item,
					borderColor: function(){
						if((graphData.typeData).indexOf(item) == 0){
							return "#60A682";
						}else if((graphData.typeData).indexOf(item) == 1){
							return "#FFD654";
						}else{
							return "#7CDAF7";
						}						
					},
					lineTension: 0,
					pointRadius: 4,
					pointHitRadius: 6
				});
			}); 

		var canvas: any = document.getElementById("binData");
		if(canvas != undefined){
			var ctx = canvas.getContext('2d');
			this.chart = new Chart(ctx, {
				type: 'line',
				data: {
					datasets: dataArr,
					labels : labelsArr
				},
				options: {
					maintainAspectRatio: false,
					legend: {
						display: false
					},
					animation: false,
					scales: {
						yAxes: [{
							display: true,
							ticks: {
								stepSize: yAxesTicksConfig.stepSize,
								suggestedMin: yAxesTicksConfig.suggestedMin,
								suggestedMax: yAxesTicksConfig.suggestedMax
							},
							radius: 25
						}],
						xAxes: [{
							gridLines: {
								display: false
							},
							scaleLabel: {
								display: true,
								labelString: this.calcXAxisLabel(),
								fontColor: 'black',
								fontSize: 13,
								fontStyle: 'bold',
							},
							display: true,
							type: 'time',
							time: {
								min: new Date(this.daysObj.minMaxTime.minTime),
								max: new Date(this.daysObj.minMaxTime.maxTime),
								unit: barTimeConfig.unit,
								unitStepSize: barTimeConfig.unitStepSize,
								displayFormats: barTimeConfig.displayFormats
							}
						}]
					},
					tooltips: {
						enabled: false,
						custom: (tooltip) => {
							tooltip.intersect = true;
							tooltip.datasets = tooltipArr;
							
							tooltip.dataPoints = [];
	
							var tooltipEl = document.getElementById('chartjs-tooltip');
	
							if (!tooltipEl) {
								tooltipEl = document.createElement('div');
								tooltipEl.id = 'chartjs-tooltip';
								tooltipEl.innerHTML = "<table></table>";
								document.body.appendChild(tooltipEl);
							}
	
							if (tooltip.opacity === 0) {
								tooltipEl.style.opacity = '0';
								return;
							}
	
							tooltipEl.classList.remove('above', 'below', 'no-transform');
							if (tooltip.yAlign) {
								tooltipEl.classList.add(tooltip.yAlign);
							} else {
								tooltipEl.classList.add('no-transform');
							}
							let getBody = (bodyItem, index) => {
								return '<div style="display:flex;justify-content: space-around;"> <span style="color:purple">' + bodyItem.lines + capacityDisplaySymbol+ '</span> </div>';
							}
	
							if (tooltip.body) {
								var titleLines = tooltip.title || [];
								var bodyLines = tooltip.body.map(getBody);								
								var innerHtml = '<thead>';
								var date;

								titleLines.forEach((title) => {
									date = moment(title);
									innerHtml += '<tr><th>' + ' ' + date.format(timeDateFormat) + '</th></tr>';
								});
								innerHtml += '</thead><tbody>';
	
								bodyLines.forEach((body, i, title) => {
									var colors = tooltip.labelColors[i];
									var style = 'border-color:' + colors.borderColor;
									style += '; border-width: 2px';
									var span = '<span style="' + style + '"></span>';
									innerHtml += '<tr><td>' + span + body + '</td></tr>';
									innerHtml += '<tr style="display:flex;justify-content: center;"><td>' + date.format('HH:mm:ss') + '</td></tr>'
								});
								innerHtml += '</tbody>';
	
								var tableRoot = tooltipEl.querySelector('table');
								tableRoot.innerHTML = innerHtml;
							}
	
							var position = this.chart.canvas.getBoundingClientRect();
	
							tooltipEl.style.backgroundColor = 'white';
							tooltipEl.style.height = '45px';
							tooltipEl.style.width = '80px';
							tooltipEl.style.opacity = '1';
							tooltipEl.style.position = 'absolute';
							tooltipEl.style.left = position.left + tooltip.caretX + 'px';
							tooltipEl.style.top = position.top + tooltip.caretY + 'px';
							tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
							tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
							tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
							tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
							tooltipEl.style.boxShadow = '0 0 6px 0 rgba(58,71,67,0.4)';
							tooltipEl.style.textAlign = '-webkit-center';
						}
					},
	
					annotation: {
						drawTime: 'afterDatasetsDraw'
					}
				}
		
				});
				setTimeout(() => {
					if(this.chart.canvas != null){
						this.chart.canvas.parentNode.style.height = '240px';
						this.chart.canvas.parentNode.style.width = '100%';
					}
				}, 0);
				if(document.getElementById('bins-list') != null){
					document.getElementById('bins-list').style.visibility = "hidden";
				}
		}	
	}

	public searchBin = (filterValue) => {
    filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempFilter = filterValue;
		this.dataSource.filter = filterValue;
	}

	handleClick(event) {    
		var binsContainer = document.getElementById('bins-list');
		var bnsBtn = document.getElementById('openBinsTable');
		if(!bnsBtn.contains(event.target)){
			if(binsContainer != undefined && (binsContainer.contains(event.target))){
				document.getElementById('bins-list').style.visibility = "visible";
			}else if(document.getElementById('bins-list') != undefined){
				document.getElementById('bins-list').style.visibility = "hidden";
			} 
		}else{
			if(document.getElementById('bins-list').style.visibility == "visible"){
				document.getElementById('bins-list').style.visibility = "hidden";
			}else{
				document.getElementById('bins-list').style.visibility = "visible";
			}
		}

	  }
}

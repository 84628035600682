import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild, ViewRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import _ from 'lodash';
import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiStore } from '../../../web-services/api/api.store';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ResponsiveService } from '../../../services/responsive.service';
import { Subject } from 'rxjs';
import { MatStepperModule } from '@angular/material/stepper';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-manage-cluster-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,MatSelectModule,NgbModule,MatInputModule,MatStepperModule,ScrollingModule,
    MatTableModule,MatSortModule,MatCheckboxModule,MatTabsModule,TableVirtualScrollModule],
  templateUrl: './manage-cluster-modal.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
      ])
    ],
  styleUrl: './manage-cluster-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageClusterModalComponent {
  expandedElement: any;
  translate;
  translationsObj;
  currentLang;
  usageType = 1;
  isMobile: Boolean;
  binFullInfo: any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  allBinsToCollectBackUp:any;
  allBinsForChooseBackUp:any;
  allBinsToCollect:any;
  allBinsForChoose: any;
  selectionAddBins= new SelectionModel<Element>(true, []);
  selection = new SelectionModel<Element>(true, []);
  addBinsTable : Array<object> = [];
  binsDataAddBins;
  binsDataTable : Array<object> = [];
  dataSourceAddBins = new TableVirtualScrollDataSource<any>();
  binsData;
  dataSource = new TableVirtualScrollDataSource<any>();
  @ViewChild(MatSort,{static: false}) sort: MatSort;
  tempFilter = '';
  displayedColumns = ['select', 'name', 'address','neighborhood','status'];
  displayedColumnsMobile = ['select', 'name', 'address','arrowDown'];
  types : Array<object> = [];
  spinnerActive : boolean = true;
  private readonly destroy$ = new Subject();
  //disableMaxAllowedCap :  boolean = true;
  filteredData:Array<object> = [];
  filteredBinsInCluster:Array<object> = [];
  selectedTab:number = 0;

constructor(private dialogRef: MatDialogRef<ManageClusterModalComponent>,private translator: TranslatorService,
  @Inject(MAT_DIALOG_DATA) public data: any,private responsiveService: ResponsiveService,private apiQuery:ApiQuery,
  private fb: FormBuilder,private cd: ChangeDetectorRef,private apiStore:ApiStore,
  private apiService:ApiService) { 
  this.dialogRef.disableClose = true;
  this.translate = this.translator;
  this.translator.currentLangEmitter$
  .subscribe(async value=>{						
	this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
	this.currentLang = value;	
  }); 

  this.firstFormGroup = this.fb.group({
	  searchInput: new FormControl('', Validators.required)
  });
  this.secondFormGroup = this.fb.group({
	  clusterName: new FormControl('', Validators.required),
	  clusterType: new FormControl('', Validators.required),
	  minAllowedCap: new FormControl('', Validators.required),
	  maxAllowedCap: new FormControl('')
  });
}

ngOnInit() {
  this.onResize();
  this.responsiveService.checkWidth();
  //this.disableMaxAllowedCap = true;
  this.apiQuery.user$.subscribe(user => {
		  if (!user) {
			  return;
		  }	
		  if(user["usage_type_id"] != 1){
			  this.usageType = user["usage_type_id"];
		  }	
	  });

  this.apiQuery.filteredBins$.pipe(takeUntil(this.destroy$)).subscribe(bins => {
	let idividualBins = [];
	this.spinnerActive = true;
	if(bins.length == 0) return;
	this.spinnerActive = false;
	_.each(bins, (item) => {
	  if(item["cluster_type_id"] == 0){
		  idividualBins.push(item);
	  }
	});

	this.binFullInfo = idividualBins;
	const binData = _.filter(bins, (bin) => {
	  return bin["cluster_id"] == this.data.chosenCluster.cluster_id && bin["site_id"] == this.data.chosenCluster.site_id;
	});

	const allBinsWithoutClusterBins = _.filter(idividualBins, (bin) => {
	  return binData.some(data => data["cluster_id"] != bin["cluster_id"]) && bin["site_id"] == this.data.chosenCluster.site_id; 
	});

	  this.allBinsToCollectBackUp = binData;
	  this.allBinsForChooseBackUp = allBinsWithoutClusterBins;

	  this.allBinsToCollect = binData;
	  this.allBinsForChoose = allBinsWithoutClusterBins;
	  this.initializeAddBinsTable();
	  this.initializeBinsTable();	
	  this.selection.clear();
	  this.selectionAddBins.clear();
	  this.cd.detectChanges();
  });

  this.apiQuery.clusterTypes$.subscribe((type) => {
	  if(type.length == 0){return;}
	  this.types = type;
	  this.secondFormGroup.controls.clusterType.setValue(Number(this.data.chosenCluster.cluster_type_id));   
	  //this.changeType(this.data.chosenCluster.cluster_type_id);
	  this.secondFormGroup.controls.maxAllowedCap.setValue(this.data.chosenCluster.cluster_max_allowed_capacity);                                                            
  });

  this.secondFormGroup.controls.clusterName.setValue(this.data.chosenCluster.cluster_name);                        
  this.secondFormGroup.controls.minAllowedCap.setValue(this.data.chosenCluster.min_required_capacity_for_collection);         
}

ngOnDestroy(){
  this.destroy$.next(true);
  this.destroy$.complete();
  if (!this.cd['destroyed'] || !(this.cd as ViewRef).destroyed) {			
	  this.cd.detectChanges();
  }
}

onResize() {
	  this.responsiveService.getMobileStatus().subscribe(isMobile => {
		this.isMobile = isMobile;
	  });
  }

  // changeType(type){
  // 	if(type == 1){
  // 		this.secondFormGroup.controls.maxAllowedCap.enable();
  // 		this.disableMaxAllowedCap = false;
  // 	}else{
  // 		this.secondFormGroup.controls.maxAllowedCap.disable();
  // 		this.disableMaxAllowedCap = true;
  // 	}
  // }

private initializeAddBinsTable = () => {
	  this.addBinsTable = [];
	  const binsFiltered = _.map(this.allBinsForChoose, b => {
		  return {
			  bin_name: b.bin_name,
			  bin_id: b.bin_id,
			  bin_address : b.Bin_location ? (b.Bin_location.bin_address ? b.Bin_location.bin_address : '') : (b.bin_address ? b.bin_address : ''),								
			  bin_neighborhood : b.Bin_location ? (b.Bin_location.bin_neighbourhood ? b.Bin_location.bin_neighbourhood : '') : (b.bin_neighborhood ? b.bin_neighborhood : ''),				
			  bin_status : b.Bin_live_Data ? (b.Bin_live_Data.fill_level_percent ? b.Bin_live_Data.fill_level_percent : 0) : (b.bin_status ? b.bin_status : 0),
		  };
	  });
	  this.binsDataAddBins = binsFiltered;	

	  _.each(this.binsDataAddBins, (item) => {			
		  this.addBinsTable.push(item);			
	  });	

	  const tempSearch = new TableVirtualScrollDataSource<any>(this.addBinsTable);
	  tempSearch.filter = this.tempFilter;
	  this.filteredBinsInCluster = tempSearch.filteredData;

	  this.dataSourceAddBins = new TableVirtualScrollDataSource<any>(binsFiltered);
	  this.dataSourceAddBins.sort = this.sort;
	  this.sortAddBinsData({ direction: 'desc', active: 'bin_name' });
  }

  initializeBinsTable = () => {
	  this.binsDataTable = [];
	  const binsFiltered = _.map(this.allBinsToCollect, b => {
		  return {
			  bin_name: b.bin_name,
			  bin_id: b.bin_id,
			  bin_address : b.Bin_location ? (b.Bin_location.bin_address ? b.Bin_location.bin_address : '') : (b.bin_address ? b.bin_address : ''),								
			  bin_neighborhood : b.Bin_location ? (b.Bin_location.bin_neighbourhood ? b.Bin_location.bin_neighbourhood : '') : (b.bin_neighborhood ? b.bin_neighborhood : ''),				
			  bin_status : b.Bin_live_Data ? (b.Bin_live_Data.fill_level_percent ? b.Bin_live_Data.fill_level_percent : 0) : (b.bin_status ? b.bin_status : 0),
		  };
	  });
	  this.binsData = binsFiltered;	
	  
	  _.each(this.binsData, (item) => {			
		  this.binsDataTable.push(item);			
	  });			

	  const tempSearch = new TableVirtualScrollDataSource<any>(this.binsDataTable);
	  tempSearch.filter = this.tempFilter;
	  this.filteredData = tempSearch.filteredData;
	  
	  this.dataSource = new TableVirtualScrollDataSource<any>(binsFiltered);
	  this.dataSource.sort = this.sort;
	  this.sortData({ direction: 'desc', active: 'bin_name' });
  }

moveBinToOutside(){
	  if(this.selection.selected.length == 0){
		  return;
	  }else{		
		  _.each(this.selection.selected, (b) => {
			  this.allBinsForChoose = [...this.allBinsForChoose,b];
		  });
		  
		  let res = this.allBinsToCollect.filter(item1 => 
			  !this.selection.selected.some(item2 => (item2["bin_id"] == item1["bin_id"])))			
		  this.allBinsToCollect = res;

		  this.initializeAddBinsTable();
		  this.initializeBinsTable();	
		  this.selection.clear();
		  this.selectionAddBins.clear();
	  }
  }

  moveBinToInside(){
	  if(this.selectionAddBins.selected.length == 0){
		  return;
	  }else{
		  _.each(this.selectionAddBins.selected, (b) => {
			  this.allBinsToCollect = [...this.allBinsToCollect,b];	
		  });
		  let res = this.allBinsForChoose.filter(item1 => 
			  !this.selectionAddBins.selected.some(item2 => (item2["bin_id"] == item1["bin_id"])))			
			  this.allBinsForChoose = res;

		  this.initializeBinsTable();	
		  this.initializeAddBinsTable();
		  this.selection.clear();
		  this.selectionAddBins.clear();
	  }
  }

sortAddBinsData(sort: Sort) {
	  const data = this.addBinsTable.slice();
	  if (!sort.active || sort.direction === '') {
		  this.addBinsTable = data;
		  return;
	  }
	  this.addBinsTable = data.sort((a, b) => {
		  const isAsc = sort.direction === 'desc';
		  return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
	  });
  }

sortData(sort: Sort) {
	  const data = this.binsDataTable.slice();
	  if (!sort.active || sort.direction === '') {
		  this.binsDataTable = data;
		  return;
	  }
	  this.binsDataTable = data.sort((a, b) => {
		  const isAsc = sort.direction === 'desc';
		  return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
	  });
  }

compare(a, b, isAsc) {
	  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

getAllBinsTableData = () => {
	  const tempSearch = new TableVirtualScrollDataSource<any>(this.addBinsTable);
	  tempSearch.filter = this.tempFilter;
	  this.filteredBinsInCluster = tempSearch.filteredData;
	  this.dataSourceAddBins = tempSearch.filteredData.length > 0 ?
		  new TableVirtualScrollDataSource<any>(tempSearch.filteredData) : new TableVirtualScrollDataSource<any>([]);
	  return this.dataSourceAddBins;
  }

getTableData = () => {	 
	  const tempSearch = new TableVirtualScrollDataSource<any>(this.binsDataTable);
	  tempSearch.filter = this.tempFilter;
	  this.filteredData = tempSearch.filteredData;
	  this.dataSource = tempSearch.filteredData.length > 0 ?
		  new TableVirtualScrollDataSource<any>(tempSearch.filteredData) : new TableVirtualScrollDataSource<any>([]);
	  return this.dataSource;
  }

  myTabSelectedIndexChange(event){
	  this.selectedTab = event;
  }

  getDirection(){    
	  if(this.currentLang != 'iw'){
		return 'ltr';
	  }
	  else{
		  return 'rtl';
		}
	}

applyFilter = (filterValue: string) => {
	  filterValue = filterValue.trim();
	  filterValue = filterValue.toLowerCase();
	  this.tempFilter = filterValue;
	  this.dataSource.filter = filterValue;
  }

  
  closeModal(){
	  this.dialogRef.close();
  }

  applyChanges(){
	  if(!this.secondFormGroup.valid){
		  return;
	  }
	  this.saveChanges();
  }

  async saveChanges(){		
	  let binIdArr = [];
	  let allBins = this.allBinsToCollectBackUp.filter(item1 => 
		  !this.allBinsToCollect.some(item2 => (item2["bin_id"] == item1["bin_id"])))		
	  const binsRemovedFromCluster: any = allBins;
	  _.each(binsRemovedFromCluster, (item) => {
		  binIdArr.push([Number(item.bin_id),-1]);
	  });
	  let binsInCluster = this.allBinsForChooseBackUp.filter(item1 => 
		  !this.allBinsForChoose.some(item2 => (item2["bin_id"] == item1["bin_id"])));
	  const binsAddedToCluster: any = binsInCluster;
	  _.each(this.allBinsForChooseBackUp, (bin) => {  
		  if (binsAddedToCluster.some(binsAdded => binsAdded.cluster_id == bin["cluster_id"])) {	
			  binIdArr.push([Number(bin.bin_id),this.data.chosenCluster.cluster_id]);																				
		  }                  
	  }); 
	  let dataToSend = {};
	  dataToSend['bin_id_list'] = binIdArr;		
	  let clusterParams = {};
	  let siteClusterList = [];
	  siteClusterList.push([this.data.chosenCluster.cluster_id, this.data.chosenCluster.site_id]);
	  clusterParams['site_cluster_list'] = siteClusterList;
	  clusterParams['cluster_type_id'] = this.secondFormGroup.getRawValue()["clusterType"];
	  clusterParams['min_required_capacity_for_collection'] = this.secondFormGroup.getRawValue()["minAllowedCap"];
	  clusterParams['cluster_max_allowed_capacity'] = this.secondFormGroup.getRawValue()["maxAllowedCap"] == '' ? 0 : this.secondFormGroup.getRawValue()["maxAllowedCap"];
	  clusterParams['cluster_name'] = this.secondFormGroup.getRawValue()["clusterName"];
	  let response = await this.apiService.setSiteClusterParams(clusterParams);
	  if(response){
		  this.apiService.setBinThresholdsAndRestrictions(dataToSend).subscribe((res) => {
			  let sitesClusters = {
				  site_id:0
			  }	
			  this.apiStore.update({ allSitesClustersList: [] });
			  this.apiStore.update({ sitesClustersList: [] });
			  this.apiService.getfilteredSitesClustersList(sitesClusters,JSON.parse(sessionStorage.getItem("chosenSites")));
			  this.apiStore.update({ allFilteredBins: [] });                    
			  this.apiStore.update({ filteredBins: [] });
			  this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
			  this.apiStore.update({ allFilteredBasicBins: [] });                    
			  this.apiStore.update({ filteredBasicBins: [] }); 
			  this.apiService.getfilteredBasicBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
		  });
	  }
	  this.closeModal();
  }
}

@if (!isMobile) {
  <div>
    <div class="modal-title">
      <span class="main-title">{{ 'TICKET_MANAGEMENT.ADD_EVENT' | translate }}</span>
      <span class="main-title"> - </span>
      @if (data.selectedTab == 0) {
        <span class="main-title">{{ 'TICKET_MANAGEMENT.CHECK_OR_REPLACEMENT_TAB' | translate }}</span>
      }
      @if (data.selectedTab == 1) {
        <span class="main-title">{{ 'TICKET_MANAGEMENT.INSTALLATION_TAB' | translate }}</span>
      }
      @if (data.selectedTab == 2) {
        <span class="main-title">{{ 'TICKET_MANAGEMENT.CANCELLATION_TAB' | translate }}</span>
      }
    </div>
    <div class="popup-container">
      <div class="prop-frame">
        <div class="days-between-container">
          <form [formGroup]="ElForm" class="add-ticket-form">
            <div class="events-type-container">
              <div>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.SITE' | translate }}</div>
                <mat-form-field class="event-type-input event-types">
                  <input tabindex="-1" class="input-site" matInput aria-label="Item" [matAutocomplete]="autoSite" formControlName="site" rtlDiv>
                  <mat-autocomplete #autoSite="matAutocomplete" (optionSelected)="optionSelected($event.option)" [displayWith]="displayProperty">
                    @for (item of filteredItems | async; track item) {
                      <mat-option [value]="item" class="site-item" rtlDiv>
                        <span>{{ item.site_name }}</span>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div [ngClass]="{'flex-binInfo' : data.selectedTab != 1}" rtlDiv>
                @if (data.selectedTab != 1) {
                  <div class="margin-flex" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</div>
                    <mat-form-field class="event-type-input event-types">
                      <input tabindex="-1" class="input-site input-bin" matInput aria-label="Item" [matAutocomplete]="autoBin" formControlName="binName" rtlDiv>
                      <mat-autocomplete #autoBin="matAutocomplete" (optionSelected)="optionSelectedBinName($event.option.value)">
                        @for (item of filteredBins | async; track item) {
                          <mat-option [value]="item.bin_name" class="site-item" rtlDiv>
                            <span>{{ item.bin_name }}</span>
                          </mat-option>
                        }
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                }
                <div [ngClass]="{'max-width' : data.selectedTab != 1}" rtlDiv>
                  <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</div>
                  <mat-form-field class="event-type-input event-types">
                    <input tabindex="-1" type="text" class="input-site" matInput formControlName="binAddress" rtlDiv>
                  </mat-form-field>
                </div>
              </div>
              @if (data.selectedTab == 1) {
                <div>
                  <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_LOCATION' | translate }}</div>
                  <div class="margin-gap">
                    @if (map) {
                      <div>
                        <form [formGroup]="ElLocationForm" class="top-fields">
                          <div class="event-type-input-location event-types dflex">
                            <input (onAddressChange)="handleAddressChange($event)" ngx-gp-autocomplete #placesRef="ngx-places" (keyup.enter)="changeLoc()" type="text" class="latLon-input" formControlName="lonLat"  placeholder="{{'OTHER.SEARCH' | translate}}" id="latLon-input"/>                            
                            <img (click)="changeLoc()" class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                          </div>
                        </form>
                      </div>
                    }
                    <google-map
                      [options]="mapOptionsPc"
                      (mapInitialized)="loadAPIWrapper($event,0)"
                      [center]="center"                  
                      [zoom]="zoom"
                      class="map-manage-modal">
                      <map-marker
                        [position]="{lat: latitude, lng: longitude}"
                        [options]="{ icon: { url: markerIcon } , draggable : true }"
                        (mapDragend)="markerDragEnd($event)"></map-marker>
                    </google-map>
                  <div>
                    <h5>{{address}}</h5>
                  </div>
                </div>
              </div>
            }
            @if (data.selectedTab == 1) {
              <div rtlDiv>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.NEIGHBORHOOD' | translate }}</div>
                <mat-form-field class="event-type-input event-types">
                  <input tabindex="-1" class="input-site input-bin" matInput aria-label="Item" [matAutocomplete]="autobinsNeighborhood" formControlName="binsNeighborhood" rtlDiv>
                  <mat-autocomplete #autobinsNeighborhood="matAutocomplete" (optionSelected)="optionSelectedBinsNeighborhood($event.option)">
                    @for (item of filteredNeighborhoods | async; track item) {
                      <mat-option [value]="item" class="site-item" rtlDiv>
                        <span>{{ item }}</span>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            }
            <div class="select-input">
              <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</div>
              <mat-form-field class="event-type-input event-types">
                <mat-select disableOptionCentering rtlDiv formControlName="eventCause" name="eventCause" (selectionChange)="changeCause($event)">
                  @for (cause of ticketEventsCause; track cause) {
                    <mat-option [value]="cause['ticketEventCause_id']" class="list-item">
                      <span class="flex-binInfo" rtlDiv>{{currentLang != 'iw' ? cause['ticketEventCause_name'] : cause['ticketEventCause_name_hebrew']}}</span>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              @if (displayErrorMsg) {
                <div class="error_msg" rtlDiv>{{ 'TICKET_MANAGEMENT.OPENED_TICKET_EXISTS' | translate }}</div>
              }
            </div>
            @if (data.selectedTab == 0 && chosenEventCause == 3) {
              <div rtlDiv>
                <div class="sub-title"></div>
                <div class="dflex">
                  <label class="container-extra" rtlDiv>
                    <div class="sub-title-flex">{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</div>
                    <input formControlName="isWorkplanIncluded" type="checkbox" rtlDiv>
                    <span class="checkmark" rtlDiv></span>
                  </label>
                </div>
              </div>
            }
            <div>
              <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</div>
              <textarea id="description-area" class="description-area" formControlName='description' rows="4" rtlDiv></textarea>
            </div>
            @if (!partialEdit) {
              <div class="full-width" rtlDiv>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }}</div>
                <mat-form-field class="event-types date-field" appearance="fill">
                  <input matInput formControlName="arrival_date_displayed">
                  <input class="hiddenInput" [min]="minDate" matInput [matDatepicker]="dp" formControlName="arrival_date">
                  <mat-hint></mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                  <mat-datepicker #dp disabled="true"></mat-datepicker>
                </mat-form-field>
              </div>
            }
            @if (!partialEdit) {
              <div>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</div>
                <div class="dflex" rtlDiv>
                  <button class="priority-btn" (click)="choosePriority('1')" [ngClass]="{'low-priority' : priority == 1}">{{ 'TICKET_MANAGEMENT.LOW' | translate }}</button>
                  <button class="priority-btn middle-opt" (click)="choosePriority('2')" [ngClass]="{'medium-priority' : priority == 2}">{{ 'TICKET_MANAGEMENT.MEDIUM' | translate }}</button>
                  <button class="priority-btn" (click)="choosePriority('3')" [ngClass]="{'high-priority' : priority == 3}">{{ 'TICKET_MANAGEMENT.HIGH' | translate }}</button>
                </div>
              </div>
            }
          </div>
          <div class='main-btns-wrapper'>
            <div class='btns-container' rtlDiv>
              <div class='cancel-btn' (click)="closeModal()">{{ 'TICKET_MANAGEMENT.CANCEL' | translate }}</div>
              <button [ngClass]="{'apply-btn' : ElLocationForm.valid && ElForm.valid && priority && !displayErrorMsg && !submitClicked,'not-apply-btn' : !ElLocationForm.valid || !ElForm.valid || !priority || displayErrorMsg || submitClicked}" type="submit" [disabled]="!ElLocationForm.valid && !ElForm.valid && !priority && displayErrorMsg && !submitClicked" (click)='addTicket()'>{{ 'TICKET_MANAGEMENT.ADD' | translate }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
}

@if (isMobile) {
  <div>
    <div class="mobile-modal-title" rtlDiv>
      <div class="dflex" (click)="closeModal()">
        <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
      </div>
      <div>
        <span class="main-title">{{ 'TICKET_MANAGEMENT.ADD_EVENT' | translate }}</span>
        <span class="main-title"> - </span>
        @if (data.selectedTab == 0) {
          <span class="main-title">{{ 'TICKET_MANAGEMENT.CHECK_OR_REPLACEMENT_TAB' | translate }}</span>
        }
        @if (data.selectedTab == 1) {
          <span class="main-title">{{ 'TICKET_MANAGEMENT.INSTALLATION_TAB' | translate }}</span>
        }
        @if (data.selectedTab == 2) {
          <span class="main-title">{{ 'TICKET_MANAGEMENT.CANCELLATION_TAB' | translate }}</span>
        }
      </div>
    </div>
    <div class="mobile-popup-container">
      <div class="mobile-prop-frame">
        <div class="days-between-container">
          <form [formGroup]="ElForm" class="add-ticket-form">
            <div class="events-type-container">
              <div>
                <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.SITE' | translate }}</div>
                <mat-form-field class="event-type-input event-types">
                  <input tabindex="-1" class="input-site" matInput aria-label="Item" [matAutocomplete]="autoSite" formControlName="site" rtlDiv>
                  <mat-autocomplete #autoSite="matAutocomplete" (optionSelected)="optionSelected($event.option)" [displayWith]="displayProperty">
                    @for (item of filteredItems | async; track item) {
                      <mat-option [value]="item" class="site-item" rtlDiv>
                        <span>{{ item.site_name }}</span>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              @if (data.selectedTab != 1) {
                <div class="margin-flex" rtlDiv>
                  <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</div>
                  <mat-form-field class="event-type-input event-types">
                    <input tabindex="-1" class="input-site input-bin" matInput aria-label="Item" [matAutocomplete]="autoBin" formControlName="binName" rtlDiv>
                    <mat-autocomplete #autoBin="matAutocomplete" (optionSelected)="optionSelectedBinName($event.option.value)">
                      @for (item of filteredBins | async; track item) {
                        <mat-option [value]="item.bin_name" class="site-item" rtlDiv>
                          <span>{{ item.bin_name }}</span>
                        </mat-option>
                      }
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              }
              <div [ngClass]="{'max-width' : data.selectedTab != 1}" rtlDiv>
                <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</div>
                <mat-form-field class="event-type-input event-types">
                  <input tabindex="-1" type="text" class="input-site" matInput formControlName="binAddress" rtlDiv>
                </mat-form-field>
              </div>
              @if (data.selectedTab == 1) {
                <div>
                  <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_LOCATION' | translate }}</div>
                  <div class="margin-gap">
                    @if (map) {
                      <div>
                        <form [formGroup]="ElLocationForm" class="top-fields">
                          <div class="event-type-input-location event-types dflex">
                            <input (onAddressChange)="handleAddressChange($event)" ngx-gp-autocomplete #placesRef="ngx-places" (keyup.enter)="changeLoc()" type="text" class="latLon-input" formControlName="lonLat"  placeholder="{{'OTHER.SEARCH' | translate}}" id="latLon-input"/>                            
                            <img (click)="changeLoc()" class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                          </div>
                        </form>
                      </div>
                    }
                    <google-map
                      [options]="mapOptionsPc"
                      (mapInitialized)="loadAPIWrapper($event,0)"
                      [center]="center"                  
                      [zoom]="zoom"
                      class="map-manage-modal">
                      <map-marker
                        [position]="{lat: latitude, lng: longitude}"
                        [options]="{ icon: { url: markerIcon } , draggable : true }"
                        (mapDragend)="markerDragEnd($event)"></map-marker>
                    </google-map>
                  <div>
                    <h5>{{address}}</h5>
                  </div>
                </div>
              </div>
            }
            @if (data.selectedTab == 1) {
              <div rtlDiv>
                <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.NEIGHBORHOOD' | translate }}</div>
                <mat-form-field class="event-type-input event-types">
                  <input tabindex="-1" class="input-site input-bin" matInput aria-label="Item" [matAutocomplete]="autobinsNeighborhood" formControlName="binsNeighborhood" rtlDiv>
                  <mat-autocomplete #autobinsNeighborhood="matAutocomplete" (optionSelected)="optionSelectedBinsNeighborhood($event.option)">
                    @for (item of filteredNeighborhoods | async; track item) {
                      <mat-option [value]="item" class="site-item" rtlDiv>
                        <span>{{ item }}</span>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            }
            <div class="select-input">
              <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</div>
              <mat-form-field class="event-type-input event-types">
                <mat-select disableOptionCentering rtlDiv formControlName="eventCause" name="eventCause" (selectionChange)="changeCause($event)">
                  @for (cause of ticketEventsCause; track cause) {
                    <mat-option [value]="cause['ticketEventCause_id']">
                      <span class="flex-binInfo" rtlDiv>{{currentLang != 'iw' ? cause['ticketEventCause_name'] : cause['ticketEventCause_name_hebrew']}}</span>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              @if (displayErrorMsg) {
                <div class="error_msg" rtlDiv>{{ 'TICKET_MANAGEMENT.OPENED_TICKET_EXISTS' | translate }}</div>
              }
            </div>
            @if (data.selectedTab == 0 && chosenEventCause == 3) {
              <div rtlDiv>
                <div class="mobile-sub-title"></div>
                <div class="dflex">
                  <label class="container-extra" rtlDiv>
                    <div class="sub-title-flex">{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</div>
                    <input formControlName="isWorkplanIncluded" type="checkbox" rtlDiv>
                    <span class="checkmark" rtlDiv></span>
                  </label>
                </div>
              </div>
            }
            <div>
              <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</div>
              <textarea id="description-area" class="description-area" formControlName='description' rows="4" rtlDiv></textarea>
            </div>
            @if (!partialEdit) {
              <div class="full-width" rtlDiv>
                <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }}</div>
                <mat-form-field class="event-types mobile-date-field" appearance="fill">
                  <input matInput formControlName="arrival_date_displayed">
                  <input class="hiddenInput" [min]="minDate" matInput [matDatepicker]="dp" formControlName="arrival_date">
                  <mat-hint></mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                  <mat-datepicker #dp disabled="true"></mat-datepicker>
                </mat-form-field>
              </div>
            }
            @if (!partialEdit) {
              <div>
                <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</div>
                <div class="dflex" rtlDiv>
                  <button class="priority-btn" (click)="choosePriority('1')" [ngClass]="{'low-priority' : priority == 1}">{{ 'TICKET_MANAGEMENT.LOW' | translate }}</button>
                  <button class="priority-btn middle-opt" (click)="choosePriority('2')" [ngClass]="{'medium-priority' : priority == 2}">{{ 'TICKET_MANAGEMENT.MEDIUM' | translate }}</button>
                  <button class="priority-btn" (click)="choosePriority('3')" [ngClass]="{'high-priority' : priority == 3}">{{ 'TICKET_MANAGEMENT.HIGH' | translate }}</button>
                </div>
              </div>
            }
          </div>
          <div class='main-btns-wrapper'>
            <div class='btns-container' rtlDiv>
              <div class='cancel-btn' (click)="closeModal()">{{ 'TICKET_MANAGEMENT.CANCEL' | translate }}</div>
              <button [ngClass]="{'apply-btn' : ElLocationForm.valid && ElForm.valid && priority && !displayErrorMsg && !submitClicked,'not-apply-btn' : !ElLocationForm.valid || !ElForm.valid || !priority || displayErrorMsg || submitClicked}" type="submit" [disabled]="!ElLocationForm.valid && !ElForm.valid && !priority && displayErrorMsg && !submitClicked" (click)='addTicket()'>{{ 'TICKET_MANAGEMENT.ADD' | translate }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
}
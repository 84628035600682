import { Component, Input } from '@angular/core';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ResponsiveService } from '../../../services/responsive.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
const RADIUS = 54;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
@Component({
  selector: 'app-bin-info',
  standalone: true,
  imports: [CommonModule,TranslateModule,RTLDivDirectiveDirective,MatDividerModule,MatCardModule],
  templateUrl: './bin-info.component.html',
  styleUrl: './bin-info.component.scss'
})
export class BinInfoComponent {
	currentLang;
	progressValueDaily;
	progressValueCapacity;
	translateSub;
	translate;
	isMobile: Boolean;
	@Input() orientation: boolean;
	translationsObj;
	bin:any;
	usageType = 1;
	@Input()
	set binLiveInfo_Bin(bin_info:any) {
		if(bin_info != null && bin_info.Bin_live_Data != null){
			this.bin = bin_info;	
			this.progressValueCapacity = document.querySelector('.averageCapacity');
			if(this.progressValueCapacity != null){
				this.progressValueCapacity.style.strokeDasharray = CIRCUMFERENCE;
			}						
			this.progressCapacity(this.bin.Bin_live_Data["average_collections_capacity_in_percent"]);
			this.progressValueDaily = document.querySelector('.averageDaily');	
			if(this.progressValueDaily != null){
				this.progressValueDaily.style.strokeDasharray = CIRCUMFERENCE;
			}		
			if(this.usageType == 1){
				this.progressDaily(this.bin.Bin_live_Data["average_daily_usage_rate_in_percent"],100);		      
			}else{
				this.progressDaily(this.bin.Bin_live_Data["average_daily_fill_rate_in_liters"],this.bin.Bin_thresholds["bin_max_capacity_in_liters"]);		      
			}
		}
	}

  constructor(private translator: TranslatorService,private apiQuery:ApiQuery,			
		private responsiveService: ResponsiveService) {
			this.translate = this.translator;
			this.translator.currentLangEmitter$
			.subscribe(async value=>{						
			  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			  this.currentLang = value;	
			})
	}

  ngOnInit() {
    this.onResize();
	this.responsiveService.checkWidth();
	this.apiQuery.user$.subscribe((user) => {
		if (!user) return;
		if(user["usage_type_id"] != 1){
			this.usageType = user["usage_type_id"];
		}
	});
   }
	  
   progressCapacity(value: number) {	   	   
	if (value == 0 && this.progressValueCapacity != null) {
		this.progressValueCapacity.style.display = 'none';
	} else {
		const progress = value / 100;
		const dashoffset = CIRCUMFERENCE * (1 - progress);		
		if(this.progressValueCapacity != null){
			this.progressValueCapacity.style.strokeDashoffset = dashoffset;	
		}
	}
   }

   progressDaily(value: number,total) {	
	if (value == 0 && this.progressValueDaily != null) {
		this.progressValueDaily.style.display = 'none';
	} else {
		const progress = value / total;
		const dashoffset = CIRCUMFERENCE * (1 - progress);		
		if(this.progressValueDaily != null){
			this.progressValueDaily.style.strokeDashoffset = dashoffset;		
		}
	}
   }

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }
}

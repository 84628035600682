import { CommonModule, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ApiService } from '../../../web-services/api/api.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Loader } from '@googlemaps/js-api-loader';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule, ReactiveFormsModule, GoogleMapsModule,CommonModule,
    MatFormFieldModule,NgxGpAutocompleteModule
  ],
  providers: [
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyB0UcoR0JNhlJkETb9LkDYfnjoiTW77GTc',
        libraries: ['places']
      })
    }
  ],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent {

  mapFormGroup: FormGroup;
  binTimeZone;
  center: google.maps.LatLngLiteral = {lat: 51.678418, lng: 7.809007};
  zoom: number = 18;
  locationInfoObj: object = {
    is_valid_location: false,
    lat: 0,
    lng: 0,
    time_zone_offset: 0,
    time_zone_next_dst_change: 0
  }

  isMapReady: boolean = false;
  isCordFree: boolean = false;
  isLocationEnabled: boolean = false;
  isDirectionReady: boolean = false;

  locationErrorMsg: any;

  assignedBinId: number = null; 

  @Output() messageEvent = new EventEmitter<object>();

  directionsService: google.maps.DirectionsService;
  directionsRenderer: google.maps.DirectionsRenderer;

  origin = {
    lat: 35.03278678,
    lng: 32.78686874,
    opacity: 0.0
  }

  destination = {
    lat: 35.03278700,
    lng: 32.78686900
  }

  currentLoc = {
    lat: 35.03278700,
    lng: 32.78686900
  }

  siteObject: any;
  routeParamsSub: any;
  siteId: string;
  map: any;
  destinationMarker: google.maps.Marker;
	@Output() address = new EventEmitter<string>();
	@Output() latLong = new EventEmitter<string>();
	@Input() showFields;
  mapOptions: google.maps.MapOptions = {
    gestureHandling: 'greedy' // Choose 'cooperative', 'greedy', 'none', or 'auto'
  };

  constructor(private _formBuilder: FormBuilder,  
    private ngZone: NgZone,private route: ActivatedRoute,
    private apiService:ApiService,private apiQuery:ApiQuery) {
    this.routeParamsSub = this.route
			.queryParams
			.subscribe(params => {
        this.siteId = this.route.snapshot.paramMap.get("id");
        this.apiService.getSiteInfo(this.siteId);
      });

      if(navigator){
        navigator.geolocation.getCurrentPosition( pos => {
          this.currentLoc.lng = +pos.coords.longitude;
          this.currentLoc.lat = +pos.coords.latitude;
        });
      }
  }
  
  public updateNewPosition(lat, lng) {
    const geocoder = new google.maps.Geocoder();
    const latlng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng)
    };

    geocoder.geocode(
      { location: latlng },
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        if (status === "OK") {
          if (results[0]) {
            this.address.emit(results[0].formatted_address.toString());
          } 
        } 
      }
    );


    this.destination.lng = lng;
    this.destination.lat = lat;

    // Update map
    this.center.lng = lng;
    this.center.lat = lat;

    this.map.setCenter({lat: lat, lng: lng});
    this.map.setZoom(this.zoom);

    this.apiService.getLatLngExists(this.center.lat.toFixed(8), this.center.lng.toFixed(8));

    // Update lat/lng form control
    this.mapFormGroup.controls.latLngFormControl.setValue(lat.toFixed(8) + ', ' + lng.toFixed(8));
    this.latLong.emit(lat.toFixed(8) + ',' + lng.toFixed(8));

    this.locationInfoObj["lat"] = this.center.lat.toFixed(8);
    this.locationInfoObj["lng"] = this.center.lng.toFixed(8);

  }

  

  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;

  public handleAddressChange(place: google.maps.places.PlaceResult) {
    this.updateNewPosition(place.geometry.location.lat(), place.geometry.location.lng());
    this.createRoute();
  }

  ngOnInit() {
    this.mapFormGroup = this._formBuilder.group({
      latLngFormControl: new FormControl({value: '', disabled: true}, Validators.required),
      timeZoneFormControl: new FormControl({value: '', disabled: true}, Validators.required)
    });


    this.apiQuery.gmtTimeZone$.subscribe((gmtTimeZone) => {
      if (!gmtTimeZone) return;

      this.binTimeZone = gmtTimeZone;
      this.mapFormGroup.controls.timeZoneFormControl.setValue(this.binTimeZone.zoneName);
      this.locationInfoObj["time_zone_offset"] = (this.binTimeZone.gmtOffset / 3600);
      this.locationInfoObj["time_zone_next_dst_change"] = formatDate((this.binTimeZone.zoneEnd * 1000), 'yyyy-MM-dd hh:mm:ss', 'en-US');
      this.locationInfoObj["is_valid_location"] = this.isCordFree;
      this.messageEvent.emit(this.locationInfoObj);
      
    });

    this.apiQuery.latLngExistsResponse$.subscribe((latLngExistsResponse) => {
      if (latLngExistsResponse != 0 && latLngExistsResponse !== null) {
        this.isCordFree = false;
        this.assignedBinId = latLngExistsResponse;
        this.locationInfoObj["is_valid_location"] = this.isCordFree;
        this.messageEvent.emit(this.locationInfoObj);
      }
      else {
        // Get the time zone
        this.apiService.getGmtTimeZoneByLatLng(this.center.lat.toFixed(8), this.center.lng.toFixed(8));
        this.isCordFree = true;
      }

    });

    this.apiQuery.siteInfo$.subscribe((siteInfo) => {
      if ((Object.keys(siteInfo).length === 0) || (!siteInfo)) return;

      this.siteObject = siteInfo;
    });
  }


  createRoute() {
    this.displayRoute(this.origin,this.destination);

    if (!this.destinationMarker) {
      this.makeUserMarker(this.currentLoc, 'assets/images/workplans-page/blue_dot_location.png', this.map);
      this.makeMarker(this.origin, 'assets/images/workplans-page/site_start_point.png', this.map, false);
      this.makeMarker(this.destination, 'assets/images/bins/all/green1.png', this.map, true);
    }
  }

  addYourLocationButton(map, marker) 
{
	var controlDiv = document.createElement('div');
	
  var firstChild = document.createElement('button');
  firstChild.type = 'button';
	firstChild.style.backgroundColor = '#fff';
	firstChild.style.border = 'none';
	firstChild.style.outline = 'none';
	firstChild.style.width = '40px';
	firstChild.style.height = '40px';
	firstChild.style.borderRadius = '2px';
	firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
	firstChild.style.cursor = 'pointer';
	firstChild.style.marginRight = '10px';
	firstChild.style.padding = '0px';
	firstChild.title = 'Your Location';
	controlDiv.appendChild(firstChild);
	
	var secondChild = document.createElement('div');
	secondChild.style.margin = 'calc(50% - 18px / 2)';
	secondChild.style.width = '18px';
	secondChild.style.height = '18px';
	secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
	secondChild.style.backgroundSize = '180px 18px';
	secondChild.style.backgroundPosition = '0px 0px';
	secondChild.style.backgroundRepeat = 'no-repeat';
	secondChild.id = 'you_location_img';
	firstChild.appendChild(secondChild);
	
	google.maps.event.addListener(map, 'dragend', function() {
		document.getElementById('you_location_img').style.backgroundPosition = '0px 0px';
	});

	firstChild.addEventListener('click', function() {
		var imgX = '0';
		var animationInterval = setInterval(function(){
			if(imgX == '-18') imgX = '0';
      else imgX = '-18';
      document.getElementById('you_location_img').style.backgroundPosition = imgX+'px 0px';
    }, 500);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition( pos => {        
        map.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude});
        clearInterval(animationInterval);
        document.getElementById('you_location_img').style.backgroundPosition = '-144px 0px';
      }, error => {
      },{maximumAge:10000, timeout:5000, enableHighAccuracy: true});
    }
		else{
      clearInterval(animationInterval);
      document.getElementById('you_location_img').style.backgroundPosition = '0px 0px';
		}
	});
	map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
}


public loadAPIWrapper(map: any) {
  this.map = map;
  const centerControlDiv = document.createElement("div");
  this.addYourLocationButton(map, centerControlDiv);
  this.isMapReady = true;
  // Get user current location if possible
  if (navigator.geolocation) {
      const id = navigator.geolocation.watchPosition( pos => {
      this.isLocationEnabled = true;
      this.updateNewPosition(pos.coords.latitude,pos.coords.longitude);
      navigator.geolocation.clearWatch(id);
    }, error => {
      this.locationErrorMsg = error.message;
      this.updateNewPosition(this.center.lat, this.center.lng);
    });
  }
  else {
    this.isLocationEnabled = false;
    this.updateNewPosition(this.center.lat, this.center.lng);
  }

  if (this.isDirectionReady && this.isLocationEnabled) {
    this.createRoute();
  }
  else {
    this.isDirectionReady = true;
  }
}

  ngAfterViewInit() {
    setTimeout(() => {
      this.directionsService = new google.maps.DirectionsService();      
      let map = this.map;
      this.directionsRenderer = new google.maps.DirectionsRenderer({
        draggable: true,
        map,
        preserveViewport: true,
        suppressMarkers: true
      });

      this.origin.lat = Number(this.siteObject.start_nav_point_lat);
      this.origin.lng = Number(this.siteObject.start_nav_point_lng);

      if (this.isDirectionReady && this.isLocationEnabled) {
        this.createRoute();
      }
      else {
        this.isDirectionReady = true;
      }
    }, 3000);
  }

  public displayRoute(origin: any,destination: any) {
    if(this.directionsService != null){
      this.directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
          avoidTolls: true
        },
        (
          result: google.maps.DirectionsResult,
          status: google.maps.DirectionsStatus
        ) => {
          if (status === "OK") {
            this.directionsRenderer.setDirections(result);
            this.map.setZoom(this.zoom);
          }
        }
      );
    }
  }

  public makeUserMarker(position, icon, map) {
    this.destinationMarker = new google.maps.Marker({
          position: position,
          map: map,
          icon: icon,
          draggable: false
      });
  }

  public makeMarker(position, icon, map, isDestination) {
    this.destinationMarker = new google.maps.Marker({
          position: position,
          map: map,
          icon: icon,
          draggable: true
      });

      if (isDestination) {
        google.maps.event.addListener(this.destinationMarker, 'dragend', () => {
          this.updateNewPosition(this.destinationMarker.getPosition().lat(),this.destinationMarker.getPosition().lng());
          this.createRoute();
        });
      }
  }
}


interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}


@if(!isMobile){
    <div class="activebins">
        <mat-card>
          <mat-card-header>
            <mat-card-title class="title">
                @if(usageType == 1){
                    <span class="text">{{ 'WIDGETS.TOTAL_ACTIVE' | translate }} </span>
                }@if(usageType != 1){
                    <span class="text">{{ 'WIDGETS.TOTAL_ACTIVE_TANKS' | translate }} </span>
                }
              <span class="number" [hidden]="spinnerActive">&nbsp;| {{totalActive}}</span>
              <span class="sub-tite" [hidden]="spinnerActive">
                &nbsp;&nbsp;&nbsp;
                <img src="../../../assets/images/dashboard/broken-bin.svg">
                &nbsp;{{nonActiveBins}}&nbsp;
                @if(usageType == 1){
                    <span>{{ 'WIDGETS.NON_ACTIVE_BINS' | translate }}</span>
                }@if(usageType != 1){
                    <span>{{ 'WIDGETS.NON_ACTIVE_TANKS' | translate }}</span>
                }
              </span>
            </mat-card-title>
            <div class="close-btn" rtlDiv (click)="closeCMP()">
              <img class="close-img" src="../../../assets/images/dashboard/closeX.svg">
            </div>
          </mat-card-header>
          <mat-divider class="top-divider"></mat-divider>
          <mat-card-content>
            <div class='spinner-container' [hidden]="!spinnerActive">
              <div class="spinner"></div>
            </div>
            <div class="frame-content" [hidden]="spinnerActive">
              <div class="box">
                <img class="box-img" src="assets/images/activebins/red_status.svg" alt="" />
                <app-number-with-description [number]="totalRed" [description]="'WIDGETS.RED_BINS' | translate"></app-number-with-description>
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="box">
                <img class="box-img" src="assets/images/activebins/yellow_status.svg" alt="" />
                <app-number-with-description [number]="totalYellow" [description]="'WIDGETS.YELLOW_BINS' | translate"></app-number-with-description>
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="box">
                <img class="box-img" src="assets/images/activebins/green_status.svg" alt="" />
                <app-number-with-description [number]="totalGreen" [description]="'WIDGETS.GREEN_BINS' | translate"></app-number-with-description>
              </div>
            </div>      
          </mat-card-content>
        </mat-card>
    </div>
}

@if(isMobile){
    <div>
        <div class="accordion md-accordion" id="activeBinsAc" role="tablist" aria-multiselectable="true">
          <div class="card mobile-accordion">
            <div class="card-header mobile-header">
              <div class="flex">
                <span class="mobile-bins-number"> {{totalActive}} </span>
                @if(usageType == 1){
                    <span class="mobile-total-bins">{{ 'WIDGETS.TOTAL_ACTIVE' | translate }} &nbsp;| &nbsp;</span>
                }@if(usageType != 1){
                    <span class="mobile-total-bins">{{ 'WIDGETS.TOTAL_ACTIVE_TANKS' | translate }} &nbsp;| &nbsp;</span>
                }
              </div>
              <div class="mobile-close-btn" rtlDiv (click)="closeCMP()">
                <img class="mobile-close-img" src="../../../assets/images/dashboard/closeX.svg">
              </div>
            </div>
            <div class="collapse show" role="tabpanel" data-parent="#activeBinsAc">
              <span class="mobile-sub-tite" [hidden]="spinnerActive">          
                <img src="../../../assets/images/dashboard/broken-bin.svg">
                &nbsp;{{nonActiveBins}}&nbsp;
                @if(usageType == 1){
                    <span>{{ 'WIDGETS.NON_ACTIVE_BINS' | translate }}</span>
                }@if(usageType != 1){
                    <span>{{ 'WIDGETS.NON_ACTIVE_TANKS' | translate }}</span>
                }
              </span>
              <div class="card-body">
                <div [hidden]="!spinnerActive" class="spinner-border mobile-spinner" role="status">
                  <span class="sr-only"></span>
                </div>
                <div class="mobile-bins-body row" rtlDiv [hidden]="spinnerActive">
                  <div class="mobile-active col-md-4 col-sm-4 col-lg-4">
                    <div class="mobile-icon">
                        <img class="mobile-subtract" src="assets/images/activebins/mobile_red_status.svg" alt="" />
                    </div>
                    <app-number-with-description [number]="totalRed" [description]="'WIDGETS.RED_BINS' | translate"></app-number-with-description>
                  </div>
                  <div class="mobile-active col-md-4 col-sm-4 col-lg-4">
                    <div class="mobile-icon">
                      <img class="mobile-subtract" src="assets/images/activebins/mobile_yellow_status.svg" alt="" />
                    </div>
                    <app-number-with-description [number]="totalYellow" [description]="'WIDGETS.YELLOW_BINS' | translate"></app-number-with-description>
                </div>
                  <div class="mobile-active col-md-4 col-sm-4 col-lg-4">
                    <div class="mobile-icon">
                        <img class="mobile-subtract" src="assets/images/activebins/mobile_green_status.svg" alt="" />
                    </div>
                    <app-number-with-description [number]="totalGreen" [description]="'WIDGETS.GREEN_BINS' | translate"></app-number-with-description>
                  </div>
      
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
}
  

<div class="padded reports-page">
  <div class="dashboard-header">
    <div class='dashboard-header-left' rtlDiv>
      <div class='header-bold'>{{ 'SIDEBAR.REPORTS' | translate }}</div>
    </div>
    <div class='datepicker-wrapper' rtlDiv>
      <div class='selected-dates' (click)='showHideDatePicker()'>
        <img [ngClass]="{'transform-arrow' : currentLang == 'iw'}" src="assets/images/dashboard/Previous.svg" alt="Previous" rtlDiv>
        <div class="dates-range datepicker-rtl" rtlDiv>
          {{selectedDates.from}} - {{selectedDates.to}}
        </div>
        <img [ngClass]="{'transform-arrow' : currentLang == 'iw'}" src="assets/images/dashboard/Next.svg" alt="Next" rtlDiv>
      </div>
      <div class="datepicker-frame" rtlDiv>
        <app-datepicker-range [hidden]='!showDatePicker' (selectDates)='onSelectDates($event)'></app-datepicker-range>
      </div>
    </div>
  </div>
  <mat-divider class="top-divider"></mat-divider>
  <div class="bin-info-container">
    <div class="first-row">
      <app-bin-sampling-history-report [datesDiff]="datesDiff"></app-bin-sampling-history-report>
    </div>
    <div class="second-row">
      <app-alerts-date-range [datesDiff]="datesDiff"></app-alerts-date-range>
      @if (usageType == 1) {
        <app-exceptions-distribution [datesDiff]="datesDiff"></app-exceptions-distribution>
      }
    </div>
    <div class="third-row">
      <app-exception-list [datesDiff]="datesDiff"></app-exception-list>
    </div>
  </div>

  @if (usageType == 1) {
    <div>
      <div class="dashboard-header">
        <div class='dashboard-header-left' rtlDiv>
          <div class='header-bold'>{{ 'TITLES.DRIVERS_REPORT' | translate }}</div>
        </div>
      </div>
      <mat-divider class="top-divider"></mat-divider>
      <div class="bin-info-container">
        <div class="first-row">
          <app-driver-report-distribution [datesDiff]="datesDiff"></app-driver-report-distribution>
        </div>
        <div class="second-row">
          <app-driver-report-list [datesDiff]="datesDiff"></app-driver-report-list>
        </div>
      </div>
    </div>
  }
</div>
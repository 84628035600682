@if (!isMobile) {
  <div>
    <div class="modal-title">
      <span class="main-title">{{ 'TICKET_MANAGEMENT.EDIT_EVENT' | translate }}</span>
      <span class="main-title"> - </span>
      @if (data.selectedTab == 0) {
        <span class="main-title">{{ 'TICKET_MANAGEMENT.CHECK_OR_REPLACEMENT_TAB' | translate }}</span>
      }
      @if (data.selectedTab == 1) {
        <span class="main-title">{{ 'TICKET_MANAGEMENT.INSTALLATION_TAB' | translate }}</span>
      }
      @if (data.selectedTab == 2) {
        <span class="main-title">{{ 'TICKET_MANAGEMENT.CANCELLATION_TAB' | translate }}</span>
      }
    </div>
    <div class="popup-container">
      <div class="prop-frame">
        <div class="days-between-container">
          <form [formGroup]="ElForm" class="add-ticket-form">
            <div class="events-type-container">
              <div>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.SITE' | translate }}</div>
                <mat-form-field class="event-type-input event-types">
                  <input tabindex="-1" class="input-site" matInput aria-label="Item" [matAutocomplete]="autoSite" formControlName="site" rtlDiv>
                  <mat-autocomplete #autoSite="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
                    @for (item of filteredItems | async; track item) {
                      <mat-option [value]="item.site_name" class="site-item" rtlDiv>
                        <span>{{ item.site_name }}</span>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div [ngClass]="{'flex-binInfo' : data.selectedTab != 1}" rtlDiv>
                @if (data.selectedTab != 1) {
                  <div class="margin-flex" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</div>
                    <mat-form-field class="event-type-input event-types">
                      <input tabindex="-1" class="input-site input-bin" matInput aria-label="Item" [matAutocomplete]="autoBin" formControlName="binName" rtlDiv>
                      <mat-autocomplete #autoBin="matAutocomplete" (optionSelected)="optionSelectedBinName($event.option.value)">
                        @for (item of filteredBins | async; track item) {
                          <mat-option [value]="item.bin_name" class="site-item" rtlDiv>
                            <span>{{ item.bin_name }}</span>
                          </mat-option>
                        }
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                }
                <div [ngClass]="{'max-width' : data.selectedTab != 1}" rtlDiv>
                  <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</div>
                  <mat-form-field class="event-type-input event-types">
                    <input tabindex="-1" type="text" class="input-site" matInput formControlName="binAddress" rtlDiv>
                  </mat-form-field>
                </div>
              </div>
              @if (data.selectedTab == 1) {
                <div>
                  <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_LOCATION' | translate }}</div>
                  <div class="margin-gap">
                    @if (map) {
                      <div>
                        <form [formGroup]="ElLocationForm" class="top-fields">
                          <div class="event-type-input-location event-types dflex">
                            <input (onAddressChange)="handleAddressChange($event)" ngx-gp-autocomplete #placesRef="ngx-places" (keyup.enter)="changeLoc()" type="text" class="latLon-input" formControlName="lonLat"  placeholder="{{'OTHER.SEARCH' | translate}}" id="latLon-input"/>                            
                            <img (click)="changeLoc()" class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                          </div>
                        </form>
                      </div>
                    }
                    <google-map
                      [options]="mapOptionsPc"
                      (mapInitialized)="loadAPIWrapper($event)"
                      [center]="center"                  
                      [zoom]="zoom"
                      class="map-manage-modal">
                      <map-marker
                        [position]="{lat: latitude, lng: longitude}"
                        [options]="{ icon: { url: markerIcon } , draggable : true }"
                        (mapDragend)="markerDragEnd($event)"></map-marker>
                    </google-map>
                  <div>
                    <h5>{{address}}</h5>
                  </div>
                </div>
              </div>
            }
            @if (data.selectedTab == 1) {
              <div rtlDiv>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.NEIGHBORHOOD' | translate }}</div>
                <mat-form-field class="event-type-input event-types">
                  <input tabindex="-1" class="input-site input-bin" matInput aria-label="Item" [matAutocomplete]="autobinsNeighborhood" formControlName="binsNeighborhood" rtlDiv>
                  <mat-autocomplete #autobinsNeighborhood="matAutocomplete" (optionSelected)="optionSelectedBinsNeighborhood($event.option)">
                    @for (item of filteredNeighborhoods | async; track item) {
                      <mat-option [value]="item" class="site-item" rtlDiv>
                        <span>{{ item }}</span>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            }
            <div>
              <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</div>
              <mat-form-field class="event-type-input event-types">
                <mat-select rtlDiv formControlName="eventCause" name="eventCause">
                  @for (cause of ticketEventsCause; track cause) {
                    <mat-option [value]="cause['ticketEventCause_id']">
                      {{currentLang != 'iw' ? cause['ticketEventCause_name'] : cause['ticketEventCause_name_hebrew']}}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              @if (displayErrorMsg) {
                <div class="error_msg" rtlDiv>{{ 'TICKET_MANAGEMENT.OPENED_TICKET_EXISTS' | translate }}</div>
              }
            </div>
            @if (data.selectedTab == 0 && data.chosenTicket.ticket_event_cause_id == 3) {
            <div rtlDiv>
              <div class="sub-title"></div>
              <div class="dflex">
                <label class="container-extra" rtlDiv>
                  <div class="sub-title-flex">{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</div>
                  <input formControlName="isWorkplanIncluded" type="checkbox" rtlDiv>
                  <span class="checkmark" rtlDiv></span>
                </label> 
              </div>
            </div>
          }
            <div>
              <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</div>
              <textarea [ngClass]="{'disabled-color' : data.status != 1}" class="description-area" formControlName='description' rows="4" rtlDiv></textarea>
            </div>
            @if (!partialEdit) {
              <div class="full-width" rtlDiv>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }}</div>
                <mat-form-field class="event-types date-field" appearance="fill">
                  <input class="arrival_date" matInput formControlName="arrival_date_displayed">
                  <input class="hiddenInput" [min]="minDate" matInput [matDatepicker]="dp" formControlName="arrival_date" (dateInput)="addEvent($event)">
                  <mat-hint></mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                  <mat-datepicker #dp [disabled]="data.status != 1 ? true : false"></mat-datepicker>
                </mat-form-field>
              </div>
            }
            @if (!partialEdit) {
              <div>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</div>
                <div class="dflex" rtlDiv>
                  <button class="priority-btn" (click)="choosePriority('1')" [ngClass]="{'low-priority' : priority == 1 , 'disableClick' : data.status != 1}">{{ 'TICKET_MANAGEMENT.LOW' | translate }}</button>
                  <button class="priority-btn middle-opt" (click)="choosePriority('2')" [ngClass]="{'medium-priority' : priority == 2 , 'disableClick' : data.status != 1}">{{ 'TICKET_MANAGEMENT.MEDIUM' | translate }}</button>
                  <button class="priority-btn" (click)="choosePriority('3')" [ngClass]="{'high-priority' : priority == 3 , 'disableClick' : data.status != 1}">{{ 'TICKET_MANAGEMENT.HIGH' | translate }}</button>
                </div>
              </div>
            }
            @if (data.status != 1) {
              <div>
                <hr>
                  @if (data.selectedTab == 0) {
                    <div>
                      <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.EVENT_CHECK_ON_FIELD' | translate }}</div>
                      <mat-form-field class="event-type-input event-types">
                        <mat-select rtlDiv formControlName="eventCheckOnField" name="eventCheckOnField">
                          @for (type of analysisTypes; track type) {
                            <mat-option [value]="type['ticket_check_replace_analysis_type_id']">
                              <span rtlDiv class="type-opt">{{currentLang != 'iw' ? type['analysis_name'] : type['analysis_name_hebrew']}}</span>
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                  }
                  @if (data.selectedTab == 0) {
                    <div>
                      <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_REPLACED' | translate }}</div>
                      <div rtlDiv>
                        <mat-radio-group
                          formControlName="unitReplaced">
                          <mat-radio-button (change)="unitReplacedChange($event)" class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.REPLACED' | translate }}</span></mat-radio-button>
                          <mat-radio-button (change)="unitReplacedChange($event)" class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.NOT_REPLACED' | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  }
                  @if (data.selectedTab == 1) {
                    <div>
                      <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_INSTALLED' | translate }}</div>
                      <div rtlDiv>
                        <mat-radio-group
                          formControlName="unitInstalled">
                          <mat-radio-button class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.INSTALLED' | translate }}</span></mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.NOT_INSTALLED' | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  }
                  @if (data.selectedTab == 2) {
                    <div>
                      <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_IS_CANCELED' | translate }}</div>
                      <div rtlDiv>
                        <mat-radio-group
                          formControlName="removedFromBin">
                          <mat-radio-button class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'OTHER.YES' | translate }}</span></mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'OTHER.NO' | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  }
                  @if (data.selectedTab == 2) {
                    <div>
                      <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_REMOVED_FROM_SITE' | translate }}</div>
                      <div rtlDiv>
                        <mat-radio-group
                          formControlName="removedFromSite">
                          <mat-radio-button (change)="removedFromSiteChange($event)" class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'OTHER.YES' | translate }}</span></mat-radio-button>
                          <mat-radio-button (change)="removedFromSiteChange($event)" class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'OTHER.NO' | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  }
                  @if ((data.selectedTab == 0 || data.selectedTab == 2) && (ElForm.controls.unitReplaced.value == 1 || ElForm.controls.removedFromSite.value == 1)) {
                    <div>
                      @if (data.selectedTab == 0) {
                        <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.REASON_OF_REPLACEMENT' | translate }}</div>
                      }
                      @if (data.selectedTab == 2) {
                        <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.REMOVED_REASON' | translate }}</div>
                      }
                      <mat-form-field class="event-type-input event-types">
                        <mat-select rtlDiv formControlName="removeOrReplaceType" name="removeOrReplaceType">
                          @for (type of removeOrReplaceTypes; track type) {
                            <mat-option [value]="type['ticket_remove_replace_cause_id']">
                              <span rtlDiv class="type-opt">{{currentLang != 'iw' ? type['ticket_remove_replace_cause_name'] : type['ticket_remove_replace_cause_name_hebrew']}}</span>
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                  }
                  <div>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</div>
                    <textarea id="description-area" class="description-area" formControlName='resolvedDescription' rows="4" rtlDiv></textarea>
                  </div>
                  @if (data.chosenTicket.pic_url != '' && data.chosenTicket.pic_url) {
                    <div class="flex-row" rtlDiv>
                      <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.PICTURE' | translate }} &nbsp;</div>
                      <img [src]="data.chosenTicket.pic_url" alt="" class="empty-img" />
                    </div>
                  }
                </div>
              }
            </div>
            <div class='main-btns-wrapper'>
              <div class='btns-container' rtlDiv>
                <div class='cancel-btn' (click)="closeModal()">{{ 'TICKET_MANAGEMENT.CANCEL' | translate }}</div>
                <div class='apply-btn' class="{{ getClassForFormControl() }}" (click)='editTicket()'>{{ 'TICKET_MANAGEMENT.EDIT' | translate }}</div>
              </div>
            </div>
            <hr class="hr-bottom">
            @if (data.chosenTicket.created && data.chosenTicket.created != '') {
              <div [ngClass]="{'margin-end' : ((!data.chosenTicket.resolved || data.chosenTicket.resolved == '') && (!data.chosenTicket.closed || data.chosenTicket.closed == '')), 'margin-not-end' : ((data.chosenTicket.resolved && data.chosenTicket.resolved != '') || (data.chosenTicket.closed && data.chosenTicket.closed != ''))}" class="created-date" rtlDiv>{{ 'TICKET_MANAGEMENT.CREATED' | translate }} {{(currentLang == 'iw' ? data.chosenTicket.created_convertDateHebrew : data.chosenTicket.created_convertDateEnglish)}}</div>
            }
            @if (data.chosenTicket.resolved && data.chosenTicket.resolved != '') {
              <div [ngClass]="{'margin-end' : (!data.chosenTicket.closed || data.chosenTicket.closed == ''), 'margin-not-end' : (data.chosenTicket.closed && data.chosenTicket.closed != '')}" class="created-date" rtlDiv>{{ 'TICKET_MANAGEMENT.RESOLVED' | translate }} {{(currentLang == 'iw' ? utilService.convertDateHebrew(data.chosenTicket.resolved) : utilService.convertDateEnglish(data.chosenTicket.resolved))}}</div>
            }
            @if (data.chosenTicket.closed && data.chosenTicket.closed != '') {
              <div class="created-date margin-end" rtlDiv>{{ 'TICKET_MANAGEMENT.CLOSED' | translate }} {{(currentLang == 'iw' ? utilService.convertDateHebrew(data.chosenTicket.closed) : utilService.convertDateEnglish(data.chosenTicket.closed))}}</div>
            }
          </form>
        </div>
      </div>
    </div>
  </div>
}

@if (isMobile) {
  <div>
    <div class="mobile-modal-title" rtlDiv>
      <div class="dflex" (click)="closeModal()">
        <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
      </div>
      <div>
        <span class="main-title">{{ 'TICKET_MANAGEMENT.EDIT_EVENT' | translate }}</span>
        <span class="main-title"> - </span>
        @if (data.selectedTab == 0) {
          <span class="main-title">{{ 'TICKET_MANAGEMENT.CHECK_OR_REPLACEMENT_TAB' | translate }}</span>
        }
        @if (data.selectedTab == 1) {
          <span class="main-title">{{ 'TICKET_MANAGEMENT.INSTALLATION_TAB' | translate }}</span>
        }
        @if (data.selectedTab == 2) {
          <span class="main-title">{{ 'TICKET_MANAGEMENT.CANCELLATION_TAB' | translate }}</span>
        }
      </div>
    </div>
    <div class="mobile-popup-container">
      <div class="mobile-prop-frame">
        <div class="days-between-container">
          <form [formGroup]="ElForm" class="add-ticket-form">
            <div class="events-type-container">
              <div>
                <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.SITE' | translate }}</div>
                <mat-form-field class="event-type-input event-types">
                  <input tabindex="-1" class="input-site" matInput aria-label="Item" [matAutocomplete]="autoSite" formControlName="site" rtlDiv>
                  <mat-autocomplete #autoSite="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
                    @for (item of filteredItems | async; track item) {
                      <mat-option [value]="item.site_name" class="site-item" rtlDiv>
                        <span>{{ item.site_name }}</span>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              @if (data.selectedTab != 1) {
                <div rtlDiv>
                  <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }}</div>
                  <mat-form-field class="event-type-input event-types">
                    <input tabindex="-1" class="input-site input-bin" matInput aria-label="Item" [matAutocomplete]="autoBin" formControlName="binName" rtlDiv>
                    <mat-autocomplete #autoBin="matAutocomplete" (optionSelected)="optionSelectedBinName($event.option.value)">
                      @for (item of filteredBins | async; track item) {
                        <mat-option [value]="item.bin_name" class="site-item" rtlDiv>
                          <span>{{ item.bin_name }}</span>
                        </mat-option>
                      }
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              }
              <div [ngClass]="{'max-width' : data.selectedTab != 1}" rtlDiv>
                <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }}</div>
                <mat-form-field class="event-type-input event-types">
                  <input tabindex="-1" type="text" class="input-site" matInput formControlName="binAddress" rtlDiv>
                </mat-form-field>
              </div>
              @if (data.selectedTab == 1) {
                <div>
                  <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_LOCATION' | translate }}</div>
                  <div class="margin-gap">
                    @if (map) {
                      <div>
                        <form [formGroup]="ElLocationForm" class="top-fields">
                          <div class="event-type-input-location event-types dflex">
                            <input (onAddressChange)="handleAddressChange($event)" ngx-gp-autocomplete #placesRef="ngx-places" (keyup.enter)="changeLoc()" type="text" class="latLon-input" formControlName="lonLat"  placeholder="{{'OTHER.SEARCH' | translate}}" id="latLon-input"/>                            
                            <img (click)="changeLoc()" class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                          </div>
                        </form>
                      </div>
                    }
                    <google-map
                      [options]="mapOptionsPc"
                      (mapInitialized)="loadAPIWrapper($event)"
                      [center]="center"                  
                      [zoom]="zoom"
                      class="map-manage-modal">
                      <map-marker
                        [position]="{lat: latitude, lng: longitude}"
                        [options]="{ icon: { url: markerIcon } , draggable : true }"
                        (mapDragend)="markerDragEnd($event)"></map-marker>
                    </google-map>
                  <div>
                    <h5>{{address}}</h5>
                  </div>
                </div>
              </div>
            }
            @if (data.selectedTab == 1) {
              <div rtlDiv>
                <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.NEIGHBORHOOD' | translate }}</div>
                <mat-form-field class="event-type-input event-types">
                  <input tabindex="-1" class="input-site input-bin" matInput aria-label="Item" [matAutocomplete]="autobinsNeighborhood" formControlName="binsNeighborhood" rtlDiv>
                  <mat-autocomplete #autobinsNeighborhood="matAutocomplete" (optionSelected)="optionSelectedBinsNeighborhood($event.option)">
                    @for (item of filteredNeighborhoods | async; track item) {
                      <mat-option [value]="item" class="site-item" rtlDiv>
                        <span>{{ item }}</span>
                      </mat-option>
                    }
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            }
            <div>
              <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }}</div>
              <mat-form-field class="event-type-input event-types">
                <mat-select rtlDiv formControlName="eventCause" name="eventCause">
                  @for (cause of ticketEventsCause; track cause) {
                    <mat-option [value]="cause['ticketEventCause_id']">
                      {{currentLang != 'iw' ? cause['ticketEventCause_name'] : cause['ticketEventCause_name_hebrew']}}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              @if (displayErrorMsg) {
                <div class="error_msg" rtlDiv>{{ 'TICKET_MANAGEMENT.OPENED_TICKET_EXISTS' | translate }}</div>
              }
            </div>
            @if (data.selectedTab == 0 && data.chosenTicket.ticket_event_cause_id == 3) {
            <div rtlDiv>
              <div class="mobile-sub-title"></div>
              <div class="dflex">
                <label class="container-extra" rtlDiv>
                  <div class="sub-title-flex">{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</div>
                  <input formControlName="isWorkplanIncluded" type="checkbox" rtlDiv>
                  <span class="checkmark" rtlDiv></span>
                </label> 
              </div>
            </div>
          }
            <div>
              <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</div>
              <textarea [ngClass]="{'disabled-color' : data.status != 1}" class="description-area" formControlName='description' rows="4" rtlDiv></textarea>
            </div>
            @if (!partialEdit) {
              <div class="full-width" rtlDiv>
                <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }}</div>
                <mat-form-field class="event-types mobile-date-field" appearance="fill">
                  <input class="arrival_date" matInput formControlName="arrival_date_displayed">
                  <input class="hiddenInput" [min]="minDate" matInput [matDatepicker]="dp" formControlName="arrival_date" (dateInput)="addEvent($event)">
                  <mat-hint></mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                  <mat-datepicker #dp [disabled]="data.status != 1 ? true : false"></mat-datepicker>
                </mat-form-field>
              </div>
            }
            @if (!partialEdit) {
              <div>
                <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }}</div>
                <div class="dflex" rtlDiv>
                  <button class="priority-btn" (click)="choosePriority('1')" [ngClass]="{'low-priority' : priority == 1 , 'disableClick' : data.status != 1}">{{ 'TICKET_MANAGEMENT.LOW' | translate }}</button>
                  <button class="priority-btn middle-opt" (click)="choosePriority('2')" [ngClass]="{'medium-priority' : priority == 2 , 'disableClick' : data.status != 1}">{{ 'TICKET_MANAGEMENT.MEDIUM' | translate }}</button>
                  <button class="priority-btn" (click)="choosePriority('3')" [ngClass]="{'high-priority' : priority == 3 , 'disableClick' : data.status != 1}">{{ 'TICKET_MANAGEMENT.HIGH' | translate }}</button>
                </div>
              </div>
            }
            @if (data.status != 1) {
              <div>
                <hr>
                  @if (data.selectedTab == 0) {
                    <div>
                      <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.EVENT_CHECK_ON_FIELD' | translate }}</div>
                      <mat-form-field class="event-type-input event-types">
                        <mat-select rtlDiv formControlName="eventCheckOnField" name="eventCheckOnField">
                          @for (type of analysisTypes; track type) {
                            <mat-option [value]="type['ticket_check_replace_analysis_type_id']">
                              <span rtlDiv class="type-opt">{{currentLang != 'iw' ? type['analysis_name'] : type['analysis_name_hebrew']}}</span>
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                  }
                  @if (data.selectedTab == 0) {
                    <div>
                      <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_REPLACED' | translate }}</div>
                      <div rtlDiv>
                        <mat-radio-group
                          formControlName="unitReplaced">
                          <mat-radio-button (change)="unitReplacedChange($event)" class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.REPLACED' | translate }}</span></mat-radio-button>
                          <mat-radio-button (change)="unitReplacedChange($event)" class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.NOT_REPLACED' | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  }
                  @if (data.selectedTab == 1) {
                    <div>
                      <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_INSTALLED' | translate }}</div>
                      <div rtlDiv>
                        <mat-radio-group
                          formControlName="unitInstalled">
                          <mat-radio-button class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.INSTALLED' | translate }}</span></mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'TICKET_MANAGEMENT.NOT_INSTALLED' | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  }
                  @if (data.selectedTab == 2) {
                    <div>
                      <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_IS_CANCELED' | translate }}</div>
                      <div rtlDiv>
                        <mat-radio-group
                          formControlName="removedFromBin">
                          <mat-radio-button class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'OTHER.YES' | translate }}</span></mat-radio-button>
                          <mat-radio-button class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'OTHER.NO' | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  }
                  @if (data.selectedTab == 2) {
                    <div>
                      <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_REMOVED_FROM_SITE' | translate }}</div>
                      <div rtlDiv>
                        <mat-radio-group
                          formControlName="removedFromSite">
                          <mat-radio-button (change)="removedFromSiteChange($event)" class="example-radio-button" [value]="1"><span class="radio-txt" rtlDiv>{{ 'OTHER.YES' | translate }}</span></mat-radio-button>
                          <mat-radio-button (change)="removedFromSiteChange($event)" class="example-radio-button" [value]="0"><span class="radio-txt" rtlDiv>{{ 'OTHER.NO' | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  }
                  @if ((data.selectedTab == 0 || data.selectedTab == 2) && (ElForm.controls.unitReplaced.value == 1 || ElForm.controls.removedFromSite.value == 1)) {
                    <div>
                      @if (data.selectedTab == 0) {
                        <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.REASON_OF_REPLACEMENT' | translate }}</div>
                      }
                      @if (data.selectedTab == 2) {
                        <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.REMOVED_REASON' | translate }}</div>
                      }
                      <mat-form-field class="event-type-input event-types">
                        <mat-select rtlDiv formControlName="removeOrReplaceType" name="removeOrReplaceType">
                          @for (type of removeOrReplaceTypes; track type) {
                            <mat-option [value]="type['ticket_remove_replace_cause_id']">
                              <span rtlDiv class="type-opt">{{currentLang != 'iw' ? type['ticket_remove_replace_cause_name'] : type['ticket_remove_replace_cause_name_hebrew']}}</span>
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </div>
                  }
                  <div>
                    <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }}</div>
                    <textarea id="description-area" class="description-area" formControlName='resolvedDescription' rows="4" rtlDiv></textarea>
                  </div>
                  @if (data.chosenTicket.pic_url != '' && data.chosenTicket.pic_url) {
                    <div class="flex-row" rtlDiv>
                      <div class="mobile-sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.PICTURE' | translate }} &nbsp;</div>
                      <img [src]="data.chosenTicket.pic_url" alt="" class="empty-img" />
                    </div>
                  }
                </div>
              }
            </div>
            <div class='main-btns-wrapper'>
              <div class='btns-container' rtlDiv>
                <div class='cancel-btn' (click)="closeModal()">{{ 'TICKET_MANAGEMENT.CANCEL' | translate }}</div>
                <div class='apply-btn' class="{{ getClassForFormControl() }}" (click)='editTicket()'>{{ 'TICKET_MANAGEMENT.EDIT' | translate }}</div>
              </div>
            </div>
            <hr class="hr-bottom">
            @if (data.chosenTicket.created && data.chosenTicket.created != '') {
              <div [ngClass]="{'margin-end' : ((!data.chosenTicket.resolved || data.chosenTicket.resolved == '') && (!data.chosenTicket.closed || data.chosenTicket.closed == '')), 'margin-not-end' : ((data.chosenTicket.resolved && data.chosenTicket.resolved != '') || (data.chosenTicket.closed && data.chosenTicket.closed != ''))}" class="created-date" rtlDiv>{{ 'TICKET_MANAGEMENT.CREATED' | translate }} {{(currentLang == 'iw' ? data.chosenTicket.created_convertDateHebrew : data.chosenTicket.created_convertDateEnglish)}}</div>
            }
            @if (data.chosenTicket.resolved && data.chosenTicket.resolved != '') {
              <div [ngClass]="{'margin-end' : (!data.chosenTicket.closed || data.chosenTicket.closed == ''), 'margin-not-end' : (data.chosenTicket.closed && data.chosenTicket.closed != '')}" class="created-date" rtlDiv>{{ 'TICKET_MANAGEMENT.RESOLVED' | translate }} {{(currentLang == 'iw' ? utilService.convertDateHebrew(data.chosenTicket.resolved) : utilService.convertDateEnglish(data.chosenTicket.resolved))}}</div>
            }
            @if (data.chosenTicket.closed && data.chosenTicket.closed != '') {
              <div class="created-date margin-end" rtlDiv>{{ 'TICKET_MANAGEMENT.CLOSED' | translate }} {{(currentLang == 'iw' ? utilService.convertDateHebrew(data.chosenTicket.closed) : utilService.convertDateEnglish(data.chosenTicket.closed))}}</div>
            }
          </form>
        </div>
      </div>
    </div>
  </div>
}
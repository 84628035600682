import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../../web-services/api/api.service';
import { TranslatorService } from '../../../../services/translator_service';
import { ApiQuery } from '../../../../web-services/api/api.query';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../../directives/rtldiv-directive.directive';


@Component({
  selector: 'app-activation-validation-dialog',
  standalone: true,
  imports: [TranslateModule, FormsModule, RTLDivDirectiveDirective, MatDialogModule, ReactiveFormsModule],
  templateUrl: './activation-validation-dialog.component.html',
  styleUrl: './activation-validation-dialog.component.scss'
})
export class ActivationValidationDialogComponent {

  deviceValidationFormGroup: FormGroup;
  currentDeviceState: boolean;
  currentLang: string;
  translateSub;
  translationsObj;

  constructor(
    public dialogRef: MatDialogRef<ActivationValidationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public binId: any,
    private _formBuilder: FormBuilder,
    private apiQuery:ApiQuery,private apiService:ApiService,
    private translator: TranslatorService) { 
      this.currentLang = this.translator.getTranslationLanguage();      
      this.translateSub = this.translator.getTranslation(this.currentLang).subscribe(translations => {
        this.translationsObj = translations;
      });
    }

  ngOnInit() {

    this.deviceValidationFormGroup = this._formBuilder.group({
      deviceId: new FormControl('', { validators: [Validators.required, Validators.minLength(10), Validators.min(0), this.validateDeviceId.bind(this)]}),
    });

    this.apiQuery.deviceIdState$.subscribe((deviceIdState) => {
      if (deviceIdState === null) return;

      this.currentDeviceState = deviceIdState;
      if (this.currentDeviceState) {
        this.deviceValidationFormGroup.controls['deviceId'].updateValueAndValidity();
      }
    });

    this.deviceValidationFormGroup.controls['deviceId'].valueChanges.subscribe(val => {
      if (!val) return;

      this.apiService.validateDeviceId(this.binId, val);
    });
  }


  public validateDeviceId(c: FormControl) {
    if (c.pristine) return null;

    return this.currentDeviceState === true ? null : {
      validateDeviceId : {
        valid: false
      }
    };

  }

  get fValidationGroup() {
    return this.deviceValidationFormGroup.controls;
  }

}

@if (!isMobile) {
  <div>
  </div>
}

@if (isMobile) {
  <div class="mobile-logout-page">
    <div class="mobile-profile-ico">
      <img alt="" class="mobile-ico" src="assets/images/sidemenu/profileUser.svg" />
    </div>
    <input id="mobile-email" disabled class="mobile-email-logout" type="text" placeholder="User: {{userEmail}}" />
    <input id="mobile-phone" disabled class="mobile-phone-logout" type="text" placeholder="Phone: {{phone}}" />
    <div class="mobile-btn" (click)="logout()">
      <span class="btn-text">Logout </span>
      <img class="mobile-rotate" src="assets/images/sidemenu/backIcon.svg" alt=""/>
    </div>
    <div class="version-details">
      <div class="version-number">{{version_number}}</div>
    </div>
  </div>
}


@if (!isMobile) {
  <div>
    <div class="modal-header" rtlDiv>
      <h3 class="modal-title">{{ 'SITE_MANAGMENT.BIN_LOCATION' | translate }}</h3>
      <button type="button" data-dismiss="modal" (click)="closeModal()" class="close-btn">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="ElForm" class="top-fields">
        <div class="bin-name-container">
          <div class="dflex" rtlDiv>
            <div class="row-space" rtlDiv>
              <input type="text" class="form-control time-input" formControlName="lon" placeholder="{{ 'LIST_COLUMNS.LON' | translate }}">
            </div>
            <div class="row-space" rtlDiv>
              <input type="text" class="form-control time-input" formControlName="lat" placeholder="{{ 'LIST_COLUMNS.LAT' | translate }}">
            </div>
            <button class="row-space search_btn" (click)="changeLoc()" rtlDiv>
              {{ 'MANAGE_BIN.CHANGE_LOCATION' | translate }}
            </button>
            <span (click)="dropMarkerInMyLocation()"><img src="../../assets/images/siteManagment/my_location.png" class="location_size"></span>
          </div>
        </div>
      </form>
      <google-map [options]="mapOptions" (mapInitialized)="loadAPIWrapper($event)" [zoom]="zoom" [center]="center" class="map-modal">
        <map-marker [position]="{lat: latitude, lng: longitude}"
        (mapDragend)="markerDragEnd($event)" [options]="{ icon: { url: markerIcon } , draggable : true }"></map-marker>
        <map-marker [position]="{lat: currentLat, lng: currentLon}" [options]="{ icon: { url: userIcon } }"></map-marker>
        <map-marker [position]="{lat: siteLat, lng: siteLon}" [options]="{ icon: { url: siteIcon } }"></map-marker>
      </google-map>
      <div>
        <h5>{{address}}</h5>
      </div>
    </div>
    <div class="modal-footer" rtlDiv>
      <button type="button" class='cancel-btn' data-dismiss="modal" (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' | translate }}</button>
      <button type="button" class='apply-btn' (click)="saveLocation()">{{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}</button>
    </div>
  </div>
}

@if (isMobile) {
  <div class="scrollable-container">
    <div class="modal-header" rtlDiv>
      <h3 class="modal-title">{{ 'SITE_MANAGMENT.BIN_LOCATION' | translate }}</h3>
      <button type="button" data-dismiss="modal" (click)="closeModal()" class="close-btn">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="mobile-body">
      <form [formGroup]="ElForm" class="top-fields">
        <div class="bin-name-container">
          <div class="dflex-mobile" rtlDiv>
            <div class="row-space" rtlDiv>
              <input type="text" class="form-control time-input-mobile" formControlName="lon" placeholder="{{ 'LIST_COLUMNS.LON' | translate }}">
            </div>
            <div class="row-space" rtlDiv>
              <input type="text" class="form-control time-input-mobile" formControlName="lat" placeholder="{{ 'LIST_COLUMNS.LAT' | translate }}">
            </div>
            <button class="row-space search_btn" (click)="changeLoc()" rtlDiv>
              {{ 'MANAGE_BIN.CHANGE_LOCATION' | translate }}
            </button>
            <span (click)="dropMarkerInMyLocation()"><img src="../../assets/images/siteManagment/my_location.png" class="location_size"></span>
          </div>
        </div>
      </form>
      <google-map [options]="mapOptions" (mapInitialized)="loadAPIWrapper($event)" [zoom]="zoom" [center]="center" class="map-modal">
        <map-marker [position]="{lat: latitude, lng: longitude}" [draggable]="true"
        (dragend)="markerDragEnd($event)" [options]="{ icon: { url: markerIcon } , draggable : true }"></map-marker>
        <map-marker [position]="{lat: currentLat, lng: currentLon}" [options]="{ icon: { url: userIcon } }"></map-marker>
        <map-marker [position]="{lat: siteLat, lng: siteLon}" [options]="{ icon: { url: siteIcon } }"></map-marker>
      </google-map>
      <div>
        <h5>{{address}}</h5>
      </div>
    </div>
    <div class="modal-footer" rtlDiv>
      <button type="button" class='cancel-btn' data-dismiss="modal" (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' | translate }}</button>
      <button type="button" class='apply-btn' (click)="saveLocation()">{{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}</button>
    </div>
  </div>
}
import { Component, Inject } from '@angular/core';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslatorService } from '../../../services/translator_service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiStore } from '../../../web-services/api/api.store';
import { ApiService } from '../../../web-services/api/api.service';
import _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-delete-clusters-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,CommonModule,MatFormFieldModule
    ,MatSelectModule,MatInputModule],
  templateUrl: './delete-clusters-modal.component.html',
  styleUrl: './delete-clusters-modal.component.scss'
})
export class DeleteClustersModalComponent {
  currentLang;
	translationsObj;
  isMobile: Boolean;
  submitClicked : boolean = false;

  constructor(private responsiveService: ResponsiveService,private translator: TranslatorService,
    private dialogRef: MatDialogRef<DeleteClustersModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
    private apiService:ApiService,private apiStore:ApiStore) {
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
   }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.submitClicked = false;
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  applyChanges(){
    this.saveChanges();
	}
  	
	closeModal(){
		this.dialogRef.close();
	}

  async saveChanges(){		
    if(this.submitClicked){
      return;
    }
    this.submitClicked = true;
		let clusterParams = {};
		let siteClusterList = [];
    _.each(this.data.chosenClusters, (cluster) => {
      siteClusterList.push([cluster.cluster_id, cluster.site_id]);
    });
		clusterParams['site_cluster_list'] = siteClusterList;
		clusterParams['cluster_type_id'] = -1;
		clusterParams['min_required_capacity_for_collection'] = -1;
		clusterParams['cluster_max_allowed_capacity'] = -1;
    clusterParams['is_delete'] = 1;
    let response = await this.apiService.setSiteClusterParams(clusterParams);
		if(response){
      let sitesClusters = {
        site_id:0
      }	
      this.apiStore.update({ allSitesClustersList: [] });
      this.apiStore.update({ sitesClustersList: [] });
      this.apiService.getfilteredSitesClustersList(sitesClusters,JSON.parse(sessionStorage.getItem("chosenSites")));
      this.apiStore.update({ allFilteredBins: [] });                    
      this.apiStore.update({ filteredBins: [] });
      this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
      this.apiStore.update({ allFilteredBasicBins: [] });                    
      this.apiStore.update({ filteredBasicBins: [] }); 
      this.apiService.getfilteredBasicBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
      this.closeModal();
		}
	}
}

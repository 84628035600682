@if (!isMobile) {
  <div>
    <div class="modal-title" rtlDiv>
      <span class="main-title">
        <span>{{ 'TICKET_MANAGEMENT.VIEW_TICKET' | translate }}</span>
        <span> - </span>
        @if (data.selectedTab == 0) {
          <span>{{ 'TICKET_MANAGEMENT.CHECK_OR_REPLACEMENT_TAB' | translate }}</span>
        }
        @if (data.selectedTab == 1) {
          <span>{{ 'TICKET_MANAGEMENT.INSTALLATION_TAB' | translate }}</span>
        }
        @if (data.selectedTab == 2) {
          <span>{{ 'TICKET_MANAGEMENT.CANCELLATION_TAB' | translate }}</span>
        }
      </span>
      <span class="close-btn" rtlDiv>
        <img (click)="closeModal()" class="close-img" src="assets/images/reports/close.svg" rtlDiv>
      </span>
    </div>
    <div class="popup-container">
      <div class="prop-frame">
        <div class="days-between-container">
          <div class="add-ticket-form">
            <div class="events-type-container">
              <div class="flex-row" [hidden]="data.chosenTicket.ticket_event_type_id == ''" rtlDiv>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.SITE' | translate }} &nbsp;</div>
                <span class="row-value">{{data.chosenTicket.site_name}}</span>
              </div>
              <div [ngClass]="{'flex-binInfo' : data.chosenTicket.ticket_event_type_id != 3}" [hidden]="data.chosenTicket.ticket_event_type_id == ''" rtlDiv>
                @if (data.chosenTicket.ticket_event_type_id != 3) {
                  <div class="flex-row" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }} &nbsp;</div>
                    <span class="row-value">{{binName}}</span>
                  </div>
                }
                @if (data.chosenTicket.ticket_event_type_id != '') {
                  <div class="flex-row" [ngClass]="{'max-width' : data.chosenTicket.ticket_event_type_id != 3}" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }} &nbsp;</div>
                    <span class="row-value">{{binAddress}}</span>
                  </div>
                }
              </div>
              @if (data.chosenTicket.ticket_event_type_id == 3) {
                <div class="flex-row" rtlDiv>
                  <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_LOCATION' | translate }} &nbsp;</div>
                  <span class="row-value">{{bin_location}}</span>
                </div>
              }
              @if (data.chosenTicket.ticket_event_type_id == 3) {
                <div class="flex-row" rtlDiv>
                  <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.NEIGHBORHOOD' | translate }} &nbsp;</div>
                  <span class="row-value">{{binNeighborhood}}</span>
                </div>
              }
              @if (data.chosenTicket.ticket_event_type_id != '') {
                <div class="flex-row" rtlDiv>
                  <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }} &nbsp;</div>
                  <span class="row-value">{{currentLang != 'iw' ? data.chosenTicket.eventCauseEn : data.chosenTicket.eventCauseHe}}</span>
                </div>
              }
              <div class="flex-row" rtlDiv>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }} &nbsp;</div>
                <span class="row-value">{{description}}</span>
              </div>
              <div class="flex-row" rtlDiv>
                <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }} &nbsp;</div>
                <span class="row-value">{{(currentLang == 'iw' ? data.chosenTicket.expected_arrival_date_he : data.chosenTicket.expected_arrival_date)}}</span>
              </div>
              @if (!partialEdit) {
                <div class="flex-row" rtlDiv>
                  <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.PRIORITY' | translate }} &nbsp;</div>
                  <div class="dflex" rtlDiv>
                    @if (data.chosenTicket.priority_id == 1) {
                      <button class="priority-btn" [ngClass]="{'low-priority' : data.chosenTicket.priority_id == 1}">{{ 'TICKET_MANAGEMENT.LOW' | translate }}</button>
                    }
                    @if (data.chosenTicket.priority_id == 2) {
                      <button class="priority-btn" [ngClass]="{'medium-priority' : data.chosenTicket.priority_id == 2}">{{ 'TICKET_MANAGEMENT.MEDIUM' | translate }}</button>
                    }
                    @if (data.chosenTicket.priority_id == 3) {
                      <button class="priority-btn" [ngClass]="{'high-priority' : data.chosenTicket.priority_id == 3}">{{ 'TICKET_MANAGEMENT.HIGH' | translate }}</button>
                    }
                  </div>
                </div>
              }
              @if (data.status != 1) {
                <hr>
                }
                @if (data.chosenTicket.ticket_event_type_id == 1 && data.status != 1) {
                  <div class="flex-row" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.EVENT_CHECK_ON_FIELD' | translate }} &nbsp;</div>
                    @if (currentLang == 'iw') {
                      <span class="row-value">{{analysisNameHe}}</span>
                    }
                    @if (currentLang != 'iw') {
                      <span class="row-value">{{analysisNameEn}}</span>
                    }
                  </div>
                }
                @if (data.chosenTicket.ticket_event_type_id == 1 && data.status != 1) {
                  <div class="flex-row" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_REPLACED' | translate }} &nbsp;</div>
                    @if (is_replaced == 1) {
                      <span class="row-value">{{'TICKET_MANAGEMENT.REPLACED' | translate}}</span>
                    }
                    @if (is_replaced == 0) {
                      <span class="row-value">{{'TICKET_MANAGEMENT.NOT_REPLACED' | translate}}</span>
                    }
                  </div>
                }
                @if (data.chosenTicket.ticket_event_type_id == 3 && data.status != 1) {
                  <div class="flex-row" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_INSTALLED' | translate }} &nbsp;</div>
                    @if (is_installed == 1) {
                      <span class="row-value">{{'TICKET_MANAGEMENT.INSTALLED' | translate}}</span>
                    }
                    @if (is_installed == 0) {
                      <span class="row-value">{{'TICKET_MANAGEMENT.NOT_INSTALLED' | translate}}</span>
                    }
                  </div>
                }
                @if (data.chosenTicket.ticket_event_type_id == 4 && data.status != 1) {
                  <div class="flex-row" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_IS_CANCELED' | translate }} &nbsp;</div>
                    @if (is_removed_from_bin == 1) {
                      <span class="row-value">{{ 'OTHER.YES' | translate }}</span>
                    }
                    @if (is_removed_from_bin == 0) {
                      <span class="row-value">{{ 'OTHER.NO' | translate }}</span>
                    }
                  </div>
                }
                @if (data.chosenTicket.ticket_event_type_id == 4 && data.status != 1) {
                  <div class="flex-row" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.UNIT_REMOVED_FROM_SITE' | translate }} &nbsp;</div>
                    @if (is_removed_from_site == 1) {
                      <span class="row-value">{{ 'OTHER.YES' | translate }}</span>
                    }
                    @if (is_removed_from_site == 0) {
                      <span class="row-value">{{ 'OTHER.NO' | translate }}</span>
                    }
                  </div>
                }
                @if (data.status != 1 && (data.chosenTicket.ticket_event_type_id == 1 || data.chosenTicket.ticket_event_type_id == 4) && (is_replaced == 1 || is_removed_from_site == 1)) {
                  <div class="flex-row" rtlDiv>
                    @if (data.chosenTicket.ticket_event_type_id == 1) {
                      <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.REASON_OF_REPLACEMENT' | translate }} &nbsp;</div>
                    }
                    @if (data.chosenTicket.ticket_event_type_id == 4) {
                      <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.REMOVED_REASON' | translate }} &nbsp;</div>
                    }
                    @if (currentLang == 'iw') {
                      <span class="row-value">{{reasonOfReplacementHe}}</span>
                    }
                    @if (currentLang != 'iw') {
                      <span class="row-value">{{reasonOfReplacementEn}}</span>
                    }
                  </div>
                }
                @if (data.chosenTicket.ticket_event_type_id == 3 && data.status != 1 && installedBinName && installedBinName != '') {
                  <div class="flex-row" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }} &nbsp;</div>
                    <span class="row-value">{{ installedBinName }}</span>
                  </div>
                }
                @if (data.status != 1) {
                  <div class="flex-row" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }} &nbsp;</div>
                    <span class="row-value">{{resolvedDescription}}</span>
                  </div>
                }
                @if (data.status != 1 && data.chosenTicket.ticket_event_type_id == 1 && reasonOfReplacementID == 4) {
                  <div class="flex-row" rtlDiv>
                    <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.PICTURE' | translate }} &nbsp;</div>
                    <a href="{{data.chosenTicket.pic_url}}" target="_blank"><img [src]="data.chosenTicket.pic_url" alt="" class="empty-img" /></a>
                  </div>
                }
              </div>
              <hr>
                @if (data.chosenTicket.created && data.chosenTicket.created != '') {
                  <div [ngClass]="{'margin-end' : ((!data.chosenTicket.resolved || data.chosenTicket.resolved == '') && (!data.chosenTicket.closed || data.chosenTicket.closed == '')), 'margin-not-end' : ((data.chosenTicket.resolved && data.chosenTicket.resolved != '') || (data.chosenTicket.closed && data.chosenTicket.closed != ''))}" class="created-date" rtlDiv>{{ 'TICKET_MANAGEMENT.CREATED' | translate }} {{(currentLang == 'iw' ? data.chosenTicket.created_convertDateHebrew : data.chosenTicket.created_convertDateEnglish)}}</div>
                }
                @if (data.chosenTicket.resolved && data.chosenTicket.resolved != '') {
                  <div [ngClass]="{'margin-end' : (!data.chosenTicket.closed || data.chosenTicket.closed == ''), 'margin-not-end' : (data.chosenTicket.closed && data.chosenTicket.closed != '')}" class="created-date" rtlDiv>{{ 'TICKET_MANAGEMENT.RESOLVED' | translate }} {{(currentLang == 'iw' ? utilService.convertDateHebrew(data.chosenTicket.resolved) : utilService.convertDateEnglish(data.chosenTicket.resolved))}}</div>
                }
                @if (data.chosenTicket.closed && data.chosenTicket.closed != '') {
                  <div class="created-date margin-end" rtlDiv>{{ 'TICKET_MANAGEMENT.CLOSED' | translate }} {{(currentLang == 'iw' ? utilService.convertDateHebrew(data.chosenTicket.closed) : utilService.convertDateEnglish(data.chosenTicket.closed))}}</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    }

    @if (isMobile) {
      <div>
      </div>
    }
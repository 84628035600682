import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren, ViewRef } from '@angular/core';
import { UtilService } from '../../../services/util.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ApiService } from '../../../web-services/api/api.service';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslatorService } from '../../../services/translator_service';
import _ from 'lodash';
import moment from 'moment';
import { API } from '../../../types.api';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { GoogleMap, GoogleMapsModule,MapInfoWindow,MapMarker,MapMarkerClusterer } from '@angular/google-maps';

@Component({
  selector: 'app-truck-map',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,CommonModule,MatCardModule,MatCheckboxModule,MatDividerModule,
	MapMarker,GoogleMapsModule,MapMarkerClusterer,GoogleMap,MapInfoWindow
  ],
  templateUrl: './truck-map.component.html',
  styleUrl: './truck-map.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TruckMapComponent {
	@ViewChildren(MapInfoWindow) infoWindows!: QueryList<MapInfoWindow>;
  	isMobile: Boolean;
	binsHash: {};
	exceptionMarker: any[] = [];
	exceptions: any = [];
	clickLoger: any[] = [];
	closeDropDown: boolean = true;
	@Output() transferDistance = new EventEmitter<string>(true);
	@Output() transferTime = new EventEmitter<string>(true);
	@Input() chosenTruck:object;
	map: any = null;
	mapBounds: any = null;
	currentTruckPosition: any;
	directionsResponseArray = [];
	infobin;
	truckBins: any;
	backUptruckBins:any;
	siteNavPoints: any = {};
	markersArray = [];
	exceptionsArray = [];
	openedInfoWindow = [];
	binTypeList=[];
	siteId: number;
	origin: object = { lat: 0, lng: 0 };
	destination: object = { lat: 0, lng: 0 };
	waypoints: object[] = [];
	renderOptions: object = {};
	totalRouteDistance: number = 0;
	totalActualRouteDistance: number = 0;
	isNewTruckSelected: boolean = false;
	currentTabletId: number = -1; 
	public elementRef;
	exceptionsTimer: any;
	isExceptionsTimerShouldRun: boolean;
	truckRoutePoints: any[] = [];
	clustersArrDic = {};
	markersArrayDic = {};
	translate;
	currentLang;
	translationsObj;
	exceptionsSub: any;
	@Input() eventSelectedManager : any;
	eventSelectedSub:any;
	@Output() loginException = new EventEmitter<object>(true);
	capacityDisplayType: number = 0;
	items:any;
	types = [];
	drawDirection:boolean = true;
	currentSitePlanId: number;
	dailyWorkplanDetails: any;
	usageType = 1;
	exceptionsTypes:Array<object> = [];
	moreThanMax:boolean = false;
	user_role_id = 0;
	mobileMapOptions: google.maps.MapOptions = {
		streetViewControl: true,
		mapTypeControl : true,
		fullscreenControl : true,
		clickableIcons : false
	};
	mapOptionsPc: google.maps.MapOptions = {
		streetViewControl: true,
		mapTypeControl : true,
		disableDefaultUI:false,
		fullscreenControl : true,
		clickableIcons : false
	};
	polylineOptions = {
		strokeColor: '#00AEEF',
		strokeWeight: 4
	};
	polylinePath: google.maps.LatLngLiteral[] = [];	
	mapOptions = {
		styles: [{
			height: 53,
			url: "../../assets/images/bins/all/cluster_icon.png",
			width: 52
		},
		{
			height: 53,
			url: "",
			width: 52
		}],
		calculator: (markers) => {
		  let count = 0;
		  let count1 = 0;
		  let count2 = 0;
		  let count3 = 0;
		  let count4 = 0;

		  for (let i = 0; i < markers.length; i++) {
			if(markers[i].visible){
				if(markers[i].icon.toString().includes("Cluster1")){
					count1++;				  
				}else if(markers[i].icon.toString().includes("Cluster2")){
					count2++;
				}else if(markers[i].icon.toString().includes("Cluster3")){
					count3++;
				}else if(markers[i].icon.toString().includes("Cluster4")){
					count4+= Number(markers[i].title);
				}else{
					count++;
				}
			}
		  }
		  if(count1*2 + count2*3 + count3*4 + count4 + count > 0){
			return {
				text: (count1*2 + count2*3 + count3*4 + count4 + count).toString(),
				index:1,
				title: (count1*2 + count2*3 + count3*4 + count4 + count).toString()
			  };
		  }else{
			return {
				text: "",
				index:2,
				title: ""
			  }; 
		  }
		}
	  };

	constructor(
		private utilService: UtilService,
		myElement: ElementRef,
		private apiQuery:ApiQuery,
		private cd: ChangeDetectorRef,
		private apiService:ApiService,
		private responsiveService: ResponsiveService,
		private translator: TranslatorService
	) {
		this.elementRef = myElement;

		this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
			this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			this.currentLang = value;	
		})
	}
	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.items = [
			{ displayName: 'WIDGETS.RED_BINS', name: 'Red Bins', selected: true,color:"#EF8378" },
			{ displayName: 'WIDGETS.YELLOW_BINS', name: 'Yellow Bins', selected: true ,color:"#E6E67E" },
			{ displayName: 'WIDGETS.GREEN_BINS', name: 'Green Bins', selected: true ,color:"#66D699"}
		];

		this.apiQuery.user$.subscribe(user => {
			if (!user) return;
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
			this.capacityDisplayType = user["user_default_capacity_type_id"];
			this.user_role_id = user["user_role_id"];
		});

		_.each(this.binTypeList, (item) => {
			item.selected = true;
		});
	}

	ngOnChanges(){		
		this.getExceptions();
	}

	async getExceptions(){
		let totalRelevantBins = [];
		this.transferDistance.emit("0");
		this.transferTime.emit("00:00");
		this.dailyWorkplanDetails = null;
		if(this.chosenTruck && this.eventSelectedManager){
			if(this.eventSelectedManager && this.eventSelectedManager.Vehicle_info){
				if(this.eventSelectedManager.Vehicle_info.some(vehicle => vehicle.tablet_id == this.chosenTruck["tablet_id"] && vehicle.time_of_planned_departure == this.chosenTruck["time_of_planned_departure"])){
					this.loginException.emit(null);
		
					if(this.eventSelectedManager["plan"] != undefined){
						this.eventSelectedSub = this.eventSelectedManager["plan"];				
					}else{
						this.eventSelectedSub = this.eventSelectedManager;
					}
					this.dailyWorkplanDetails = this.eventSelectedSub;
					this.currentTabletId = this.chosenTruck["tablet_id"];
					this.isNewTruckSelected = true;
					this.isExceptionsTimerShouldRun = true;
		
					_.each(this.chosenTruck["Bins_collection_info"], (bin) => {
						totalRelevantBins.push(bin);
					});
		
					this.backUptruckBins = totalRelevantBins;
					this.truckBins = totalRelevantBins;	
					this.siteNavPoints = this.getSiteNavPoint(this.currentTabletId);
					this.currentSitePlanId = this.getSitePlanByTabletId(this.currentTabletId);
					this.exceptionsSub = await this.apiService.exceptionHandler(this.currentSitePlanId,this.currentTabletId);
					this.exceptions = this.exceptionsSub.exceptionInfo;	
					_.each(this.exceptions, (exception) => {
						exception.icon = this.exceptionToIcon(exception);
						
						const uniqueValuesSet = new Set();
						const filteredArr = this.exceptions.filter((obj) => {
						const isPresentInSet = uniqueValuesSet.has(obj.exception_id);
						uniqueValuesSet.add(obj.exception_id);
						return !isPresentInSet;
						});
						
						this.exceptionsTypes = filteredArr;
		
						if(exception.exception_id == 2){
							this.loginException.emit(exception);						
						}
					});
												
					let exc = _.find(this.exceptions, function (item) {
						return (item.exception_id == 2)
					});	
					
					if(exc && this.currentTruckPosition){
						this.currentTruckPosition.setVisible(true);
					}else{
						if(this.currentTruckPosition != null){
							this.currentTruckPosition.setVisible(false);
						}
					}			
					if (this.map && this.truckBins && this.truckBins.length > 0) {	
						if(this.exceptions.length > 0){
							this.initializeBinsMap();
							this.setExceptions(this.map);
						}else{
							this.initializeBinsMap();
							this.hideExcMarkers();
							this.exceptionMarker = [];
						}
						this.loadAPIWrapper(this.map);		
					}
		
					if (this.currentSitePlanId != -1) {
						this.getActualRouteFromAPI(this.currentSitePlanId);
						this.drawDirection = true;
					}else{
						this.drawDirection = false;
						if(document.getElementById('googleMapCenter')!=null){
							document.getElementById('googleMapCenter').remove();
						}
					}				
					this.cd.detectChanges();	
				}
			}				
		}
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	ngOnDestroy() {
		this.isExceptionsTimerShouldRun = false;
		if (!this.cd['destroyed'] || !(this.cd as ViewRef).destroyed) {			
			this.cd.detectChanges();
		}
	}

	isEmptyObject(obj) {
		for (let key in obj) {
			if (obj.hasOwnProperty(key))
				return false;
		}
		return true;
	}

	getSitePlanByTabletId(tabletId) {
		let res = -1;

		if (this.dailyWorkplanDetails != undefined) {
			_.each(this.dailyWorkplanDetails.Vehicle_info, (vehicle) => {
				if (vehicle.tablet_id == tabletId) {
					res = this.dailyWorkplanDetails.Site_plan_id;
				}
			});
		}

		return res;
	}

	getSiteNavPoint(tabletId) {
		let res = {
			navStartPointLat: 31.79435235,
			navStartPointLng: 35.14584453,
			navStopPointLat: 31.79435235,
			navStopPointLng: 35.14584453
		};

		if (this.dailyWorkplanDetails != undefined) {
			_.each(this.dailyWorkplanDetails.Vehicle_info, (vehicle) => {
				if (vehicle.tablet_id == tabletId) {
					res.navStartPointLat = this.dailyWorkplanDetails.navigation_start_point_lat;
					res.navStartPointLng = this.dailyWorkplanDetails.navigation_start_point_lon;
					res.navStopPointLat = this.dailyWorkplanDetails.navigation_stop_point_lat;
					res.navStopPointLng = this.dailyWorkplanDetails.navigation_stop_point_lon;
				}
			});
		}

		return res;
	}

	public selectUnselectItem = (item: any) => {
		this.mapClick();
		item.selected = !item.selected;
		let items = [];
		items = this.items.filter(item => item.selected).map((obj) => {return obj.name});
		this.types = items;
		this.filterClustersAndColors();	
	}

	private subscribeTruckWorkplanInUserFilter = (truckWorkPlan: any) => {
		this.truckBins = truckWorkPlan;

		if (this.map) {
			const location = {
				lat: Number(this.truckBins[0].Bin_location.bin_latitude),
				lng: Number(this.truckBins[0].Bin_location.bin_longitude)
			};
	
			this.loadAPIWrapperFilter(this.map, location, this.truckBins);
		}
	}

	addYourLocationButton(map,pos){
		if(document.getElementById('googleMapCenter')!=null){
			document.getElementById('googleMapCenter').remove();
		}
		
		var controlDiv = document.createElement('div');
		var firstChild = document.createElement('button');
		firstChild.type = 'button';
		firstChild.id = "googleMapCenter";
		firstChild.style.backgroundColor = '#fff';
		firstChild.style.border = 'none';
		firstChild.style.outline = 'none';
		firstChild.style.width = '40px';
		firstChild.style.height = '40px';
		firstChild.style.borderRadius = '2px';
		firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
		firstChild.style.cursor = 'pointer';
		firstChild.style.marginRight = '10px';
		firstChild.style.padding = '0px';
		firstChild.title = 'Truck Location';
		controlDiv.appendChild(firstChild);
		
		var secondChild = document.createElement('div');
		secondChild.style.margin = 'calc(50% - 18px / 2)';
		secondChild.style.width = '18px';
		secondChild.style.height = '18px';
		secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
		secondChild.style.backgroundSize = '180px 18px';
		secondChild.style.backgroundPosition = '0px 0px';
		secondChild.style.backgroundRepeat = 'no-repeat';
		secondChild.id = 'you_location_img';
		firstChild.appendChild(secondChild);
		
		google.maps.event.addListener(map, 'dragend', function() {
			if(document.getElementById('you_location_img')){
				document.getElementById('you_location_img').style.backgroundPosition = '0px 0px';
			}
		});

		firstChild.addEventListener('click', function() {
			var imgX = '0';
			var animationInterval = setInterval(function(){
				if(imgX == '-18') imgX = '0';
		else imgX = '-18';
		document.getElementById('you_location_img').style.backgroundPosition = imgX+'px 0px';
		}, 500);
		if (navigator.geolocation) {
			map.fitBounds(map.getBounds());
			map.setCenter({lat: Number(pos.lat()), lng: Number(pos.lng())});
			map.setZoom(15);
			clearInterval(animationInterval);
			document.getElementById('you_location_img').style.backgroundPosition = '-144px 0px';	
		}else{
		clearInterval(animationInterval);
		document.getElementById('you_location_img').style.backgroundPosition = '0px 0px';
			}
		});
		map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
	}

	private initializeBinsMap = () => {
		this.directionsResponseArray = [];
		this.totalRouteDistance = 0;
		
		if (this.map && this.truckBins && this.truckBins.length > 0) {
			this.resetMarkers();			
			this.setMarkersByStatus(this.map, this.truckBins);
		}
	}

	public onMapReady(event: any) {
		this.map = event;
		this.getExceptions();	
	}

	public applyMainFilter = (filterValue: string) => {		
		let filteredArr = [];
		if(this.backUptruckBins != undefined){
			filterValue = filterValue.trim(); 
			if (filterValue == "") {				
				this.subscribeTruckWorkplanInUserFilter(this.backUptruckBins);
			}
			else{
				_.each(this.backUptruckBins, (item) => {															
					if((item["bin_id"].toString().includes(filterValue)) || (item["bin_name"].toString().includes(filterValue)) || ((item["Bin_location"]["bin_neighbourhood"] != undefined) && (item["Bin_location"]["bin_neighbourhood"].toString().includes(filterValue))) || (item["Bin_location"]["bin_address"].toString().includes(filterValue))){
						filteredArr.push(item);	
					}			
				});
				if(filteredArr.length > 0){
					this.subscribeTruckWorkplanInUserFilter(filteredArr);
				}else{
					this.subscribeTruckWorkplanInUserFilter(this.backUptruckBins);
				}
			}

		}
	}

	
	infoWindowClose(infoWindow) {
	}

	getActualRouteFromAPI(sitePlanId) {
		let isNeedToDraw: boolean = false;
		this.apiService.getTruckActualRoute(sitePlanId,this.currentTabletId).subscribe(truckRouteResponse =>{	
			if (!_.isEmpty(this.truckRoutePoints) && (truckRouteResponse["Truck_Location_Info"].length > 0)) {				
				const firstLocationReportedTime = truckRouteResponse["Truck_Location_Info"][0].location_reported_time;
				const lastLocationReportedTime = truckRouteResponse["Truck_Location_Info"][truckRouteResponse["Truck_Location_Info"].length - 1].location_reported_time;
				const estimatedSeconds = moment.duration(moment(lastLocationReportedTime).diff(moment(firstLocationReportedTime))).asSeconds();
				const secs = estimatedSeconds % 60;
				const mins = ((estimatedSeconds - secs) / 60) % 60;
				const hrs = (((estimatedSeconds - secs) / 60 - mins) / 60).toString().padStart(2, "0");	  
				const minutesConverted = mins.toString().padStart(2, "0");
				const total = hrs+":"+minutesConverted;
				this.transferTime.emit(total);

				if ((truckRouteResponse["Truck_Location_Info"][truckRouteResponse["Truck_Location_Info"].length - 1].location_lat != this.truckRoutePoints[this.truckRoutePoints.length - 1].latitude) || (truckRouteResponse["Truck_Location_Info"][truckRouteResponse["Truck_Location_Info"].length - 1].location_lon != this.truckRoutePoints[this.truckRoutePoints.length - 1].longitude)) {
					isNeedToDraw = true;
				}
			}
			else {
				isNeedToDraw = true;
			}

			if (isNeedToDraw && this.map) {
				this.truckRoutePoints = [];
				truckRouteResponse["Truck_Location_Info"].map(route => {
					let point = new google.maps.LatLng(route.location_lat, route.location_lon);
					this.truckRoutePoints.push(point);
				});
				this.polylinePath = this.truckRoutePoints.map(point => {return {lat: point.lat(), lng: point.lng()}});
				this.cd.detectChanges();
				if (this.truckRoutePoints.length > 0) {
					this.totalActualRouteDistance = parseFloat((google.maps.geometry.spherical.computeLength(this.truckRoutePoints)).toFixed(2));
					this.transferDistance.emit(this.totalActualRouteDistance.toFixed(2));
					let lastKnownPosition = this.truckRoutePoints[this.truckRoutePoints.length - 1];
					this.addYourLocationButton(this.map,this.truckRoutePoints[this.truckRoutePoints.length - 1]);
					if (this.currentTruckPosition !== undefined) {
						if (this.isNewTruckSelected) {
							this.currentTruckPosition.setPosition(lastKnownPosition);
						}
						else {
							this.currentTruckPosition.setPosition(lastKnownPosition);
						}
					}
					else {
						this.addTruckPositionMarkerToMap(this.truckRoutePoints[this.truckRoutePoints.length - 1], this.map);
					}
				}else{
					if(document.getElementById('googleMapCenter')!=null){
						document.getElementById('googleMapCenter').remove();
					}
				}

				this.isNewTruckSelected = false;
			}
		});
	}


	public addTruckPositionMarkerToMap(pos, map) {
		let icon = '../../assets/images/workplans-page/currentTruck.svg';
		this.currentTruckPosition = new google.maps.Marker({
			position: {
				lat: Number(pos.lat()),
				lng: Number(pos.lng())
			},
			icon: icon,
			map: map
		});
	}

	fliterMarkers() {
		let categories = ['Red Bins', 'Green Bins', 'Yellow Bins'];		
		categories = this.types;		
		_.each(this.markersArray, (marker) => {			
			if (categories.includes(marker["category"])) {
				marker.visible = true;
			} else {
				marker.visible = false;
			}
		});
	}

	public filterClustersAndColors(){	
		this.fliterMarkers();
		let prevZoom = this.map.getZoom();
		this.map.setZoom(-12);
		this.map.setZoom(prevZoom);
	}

	public loadAPIWrapper(map: any) {
		if (this.map) this.map = map;
		this.map.setZoom(-12);

		if (this.map && this.truckBins) {
			const userPosition = {
				lat: Number("32.085300"),
				lng: Number("34.781768")
			};
			if (this.truckBins && this.truckBins.length > 0) {
				const bounds = new google.maps.LatLngBounds();				
				this.truckBins.map(bin => {
					bounds.extend({ lat: Number(bin.Bin_location.bin_latitude), lng: Number(bin.Bin_location.bin_longitude) });
				});
				this.map.fitBounds(bounds);
			} else {
				this.map.panTo(userPosition);
				this.map.setZoom(12);
			}
		}
	}

	
	public loadAPIWrapperFilter(map: any, customLocation: any, bins:any) {
		if (!map) return;
		const userPosition = {
			lat: 32.085300,
			lng: 34.781768
		};
		if (customLocation) {
			userPosition.lat = Number(customLocation.lat);
			userPosition.lng = Number(customLocation.lng);
		}
		
		if (bins!=undefined && bins.length > 0) {
			const bounds = new google.maps.LatLngBounds();
			bins.map(bin => {				
				bounds.extend({ lat: bin.Bin_location.bin_latitude, lng: bin.Bin_location.bin_longitude });
			});
			map.fitBounds(bounds);
		} else {
			map.panTo(userPosition);
			map.setZoom(12);
		}
	}

	private mapBinsByClusterUsingSystem(bins: API.Bin[]) {
		const binsHashUsingSystem = {};
		_.each(bins, b => {
			if (binsHashUsingSystem[b.cluster_id+b["site_id"]] !== undefined) {
				binsHashUsingSystem[b.cluster_id+b["site_id"]].push(b);
			} else {
				binsHashUsingSystem[b.cluster_id+b["site_id"]] = [b];
			}
		});
		return binsHashUsingSystem;
	}

	private BinToType(status: number): string {
		let icon = '';
		switch (status) {
			case 0:
				icon = 'Green Bins';
				break;
			case 1:
				icon = 'Yellow Bins';
				break;
			case 2:
				icon = 'Red Bins';
				break;
			default:
				icon = 'Red Bins';
		}
		return icon;
	}

	resetMarkers() {
		this.markersArray = [];
		this.clustersArrDic = {};
		this.markersArrayDic = {};
	}

	openMarkerInfoWindow(marker: MapMarker, markerIndex: number) {
		this.infoWindows.forEach((infoWindow: MapInfoWindow, index: number ) => {
		  if (index === markerIndex) {
			infoWindow.open(marker);
		  } else {
			infoWindow.close();
		  }
		});
	}

	private mapBinsByCoordinates(bins: API.Bin[]) {
		const binsHash = {};
		_.each(bins, b => {
			if (binsHash[b["Bin_location"]["bin_latitude"]+","+b["Bin_location"]["bin_longitude"]] !== undefined) {
				binsHash[b["Bin_location"]["bin_latitude"]+","+b["Bin_location"]["bin_longitude"]].push(b);
			} else {
				binsHash[b["Bin_location"]["bin_latitude"]+","+b["Bin_location"]["bin_longitude"]] = [b];
			}
		});
		return binsHash;
	}

	private setMarkersByStatus(map, bins: API.Bin[]): void {
		this.binsHash = this.mapBinsByCoordinates(bins);		
		_.forOwn(this.binsHash, (value :any, key) => {
			if(value.length > 1){
				let isPartOfCluster = false;
				const icon = this.clusterToIcon(value);	
				const checkIfCluster = 	this.mapBinsByClusterUsingSystem(value);	
				_.forOwn(checkIfCluster, (value :any, key) => {
					if(value.length > 1){
						isPartOfCluster = true;
					}					
				})
				const label = '';								
				const marker = {
					icon: icon,
					lat: Number(value[0].Bin_location.bin_latitude),
					lng: Number(value[0].Bin_location.bin_longitude),
					label:label,
					bin_name : value[0].bin_name,
					bin_id : value[0].bin_id,
					category : this.BinToType(value[0]["bin_status_id"]),
					cluster_id: value[0].cluster_id,
					cluster : true,
					title : value.length.toString(),
					item:value,
					fill_level_percent : value[0]["Last_updated_fill_level_sample"].fill_level_in_precent,
					fill_level_litters : value[0]["Last_updated_fill_level_sample"].fill_level_in_litters,
					isInfoWindowOpen : false,
					visible: true,
					site_id: value[0].site_id,
					bin_address : value[0].Bin_location.bin_address,
					bin_neighbourhood : value[0].Bin_location["bin_neighbourhood"],
					last_collection_time : value[0]["last_collection_time"],
					color_status_name : value[0]["bin_status_id_color"],
					draggable : false,
					partOfCluster : isPartOfCluster
				};

				if(!this.clustersArrDic[value[0].Bin_location.bin_latitude+","+value[0].Bin_location.bin_longitude]){			
					this.clustersArrDic[value[0].Bin_location.bin_latitude+","+value[0].Bin_location.bin_longitude] = true;
					this.markersArray.push(marker);
				}else{
					this.markersArray.forEach(m=>{
						value.forEach(bin=>{
							if(m.lat == Number(bin.Bin_location.bin_latitude) && m.lng == Number(bin.Bin_location.bin_longitude)){
								m.filtered = true;
							}
						})					
					  })
				}  
			}else{
				value.forEach((element, index, array) => {
					const b = element;					
					const icon = this.BinToIcon(b,this.exceptions);	
					const label = this.labelOption(b,this.exceptions,icon);				
					const marker = {
						icon : icon,
						lat :  Number(b.Bin_location.bin_latitude),
						lng : Number(b.Bin_location.bin_longitude),	
						label:label,
						bin_name : b.bin_name,
						bin_id : b.bin_id,
						category : this.BinToType(b["bin_status_id"]),
						cluster_id: element.cluster_id,
						cluster : false,
						title : "1",
						fill_level_percent : element["Last_updated_fill_level_sample"].fill_level_in_precent,
						fill_level_litters : element["Last_updated_fill_level_sample"].fill_level_in_litters,
						isInfoWindowOpen : false,
						visible : true,
						site_id : b["site_id"],
						bin_address : b.Bin_location.bin_address,
						bin_neighbourhood : b.Bin_location["bin_neighbourhood"],
						last_collection_time : b["last_collection_time"],
						color_status_name : b["bin_status_id_color"],
						draggable : false
					}	
					if(!this.markersArrayDic[marker.bin_id]){
						this.markersArrayDic[marker.bin_id] = true;
						this.markersArray.push(marker);
					}else{
						this.markersArray.forEach(bin=>{
							if(bin.bin_id == element.bin_id){
								bin.filtered = true;
							}
						})
					}			
				});
			}
		});

		const startMarker = new google.maps.Marker({
			position: {
				lat: Number(this.siteNavPoints.navStartPointLat),
				lng: Number(this.siteNavPoints.navStartPointLng)
			},
			icon: '../../assets/images/workplans-page/site_start_point.png',
			map: map
		});

		this.markersArray.push(startMarker);

		const endMarker = new google.maps.Marker({
			position: {
				lat: Number(this.siteNavPoints.navStopPointLat),
				lng: Number(this.siteNavPoints.navStopPointLng)
			},
			icon: '../../assets/images/workplans-page/site_stop_point.png',
			map: map
		});

		this.markersArray.push(endMarker);
		
		if(bins.length > 100){
			this.moreThanMax = true;
		}else{
			this.moreThanMax = false;
		}
		
	}

	private clusterToIcon(cluster){				
		let icon = '';		
		let redCluster = _.find(cluster, function (item) {
			return (item.bin_status_id_color == "RED")
		});	
	
		let yellowCluster = _.find(cluster, function (item) {
			return (item.bin_status_id_color == "YELLOW")
		});	

		let greenCluster = _.find(cluster, function (item) {
			return (item.bin_status_id_color == "GREEN")
		});	
		
		if(redCluster != null){
			if(cluster.length == 2){
				icon = `../../assets/images/bins/all/defaultRedCluster1.svg`;
			}else if(cluster.length == 3){
				icon = `../../assets/images/bins/all/defaultRedCluster2.svg`;
			}else if(cluster.length == 4){
				icon = `../../assets/images/bins/all/defaultRedCluster3.svg`;
			}else{
				icon = `../../assets/images/bins/all/defaultRedCluster4.svg`;
			}			
			return icon;
		}else if(redCluster == null && yellowCluster != null){
			if(cluster.length == 2){
				icon = `../../assets/images/bins/all/defaultYellowCluster1.svg`;
			}else if(cluster.length == 3){
				icon = `../../assets/images/bins/all/defaultYellowCluster2.svg`;
			}else if(cluster.length == 4){
				icon = `../../assets/images/bins/all/defaultYellowCluster3.svg`;
			}else{
				icon = `../../assets/images/bins/all/defaultYellowCluster4.svg`;
			}			
			return icon;
		}else if(redCluster == null && yellowCluster == null && greenCluster != null){
			if(cluster.length == 2){
				icon = `../../assets/images/bins/all/defaultGreenCluster1.svg`;
			}else if(cluster.length == 3){
				icon = `../../assets/images/bins/all/defaultGreenCluster2.svg`;
			}else if(cluster.length == 4){
				icon = `../../assets/images/bins/all/defaultGreenCluster3.svg`;
			}else{
				icon = `../../assets/images/bins/all/defaultGreenCluster4.svg`;
			}			
			return icon;
		}
	}

	mapClick() {	
		this.infoWindows.forEach((infoWindow: MapInfoWindow ) => {
			infoWindow.close();
		});
	}

	clickedMarker(bin) {			  		  
		let content = '';								
		if(!bin.cluster){
			content = `<div class="iw-content" style="padding-left:unset;padding-right:unset;">
			<div class="tooltip-id">
			<div class="tooltip-value">
			${this.user_role_id != 8 ? `<a href="/binLiveInfoPage?pageName=dashboard&binId=${bin.bin_id}" class="binNameHref-truckMap tooltip-value bin-name">${bin.bin_name}</a>` : `<span class="tooltip-value">${bin.bin_name}</span>`}
			<span> | </span>
			<span>${bin.bin_id}</span>
			</div>
			<div class="tooltip-id border-bottom">
			<div class="tooltip-address" title="${bin.bin_address}">${bin.bin_address}</div>
			<div class="tooltip-address" title="${bin.bin_neighbourhood == null ? '' : bin.bin_neighbourhood}">${bin.bin_neighbourhood == null ? '' : bin.bin_neighbourhood}</div>
			</div>
			</div>
			</div>
			<div class="tooltip-id" style="padding-bottom: 9px;">
			<div class="tooltip-text">${this.usageType == 1 ? this.translationsObj.MAP_MARKER.VOLUME : this.translationsObj.MAP_MARKER.VOLUME_D}:</div>
			</div>          
			<div class="tooltip-marker" style="${this.getTooltipStyle(bin.fill_level_percent)}">
			${this.usageType == 1 ? bin.fill_level_percent : bin.bin_max_capacity_in_liters * bin.fill_level_percent / 100}${this.capacityDisplayType !=0 ? 'L' : '%'}
			<div class="tooltip-arrow-marker"></div>
			</div>
			
			<div class="progress_bar" id="progressbar" style="background: ${this.getProgressBarStyleWrapper(bin.color_status_name)};border-radius: 10px;margin-bottom: 5px;display:flex;position: relative;">
			<div class="progress_bar_internal" style="width: ${this.getProgressBarWidthInternal(bin.fill_level_percent)};
			height: 14px;background: ${this.getProgressBarStyleInternal(bin.color_status_name)};border-radius: 10px; line-height: 15px;">
			</div>
			</div>
	
			<div class="last">
			<div class="tooltip-id">
			<div class="tooltip-text-collection">${this.usageType == 1 ? this.translationsObj.LIST_COLUMNS.LAST_COLLECTION : this.translationsObj.LIST_COLUMNS.LAST_REFILL}:&nbsp;</div>		
			<div class="tooltip-text-collection">${(this.currentLang == 'iw' && bin.last_collection_time!='' && bin.last_collection_time!=undefined ? this.utilService.convertDateHebrew(bin.last_collection_time) : this.utilService.convertDateEnglish(bin.last_collection_time))}</div>
			<div class="tooltip-text-collection">${(bin.last_collection_time=='' || bin.last_collection_time==undefined) && this.usageType == 1 ? this.translationsObj.TITLES.LAST_COL : ((bin.last_collection_time=='' || bin.last_collection_time==undefined) && this.usageType != 1 ? this.translationsObj.TITLES.LAST_REFILL : '')}</div>
			</div>
			</div>`;	
		}else{
			let binTooltips = '';
			for(let i=0; i < bin.item.length; i++){
				let capacityVolume = bin.item[i].Last_updated_fill_level_sample.fill_level_in_precent;
				let capacityDataSymbol = '%';
				if (this.capacityDisplayType != 0) {
					capacityDataSymbol = 'L';
					capacityVolume = bin.item[i].Last_updated_fill_level_sample.fill_level_in_litters;
				}

				binTooltips += `
				<div class="carousel-item ${i == 0 ? "active" : ""}">
				<div class="iw-content" style="padding-left:unset;padding-right:unset;">
				<div class="tooltip-id">
				<div class="tooltip-value">
				${this.user_role_id != 8 ? `<a class="binNameHref-truckMap tooltip-value bin-name" href="/binLiveInfoPage?pageName=workplans&binId=${bin.item[i].bin_id}" id="clickableItem">${bin.item[i].bin_name}</a>` : `<span class="tooltip-value" id="clickableItem">${bin.item[i].bin_name}</span>`}				
				<span> | </span>
				<span>${bin.item[i].bin_id}</span>
				</div>
				<div class="tooltip-id border-bottom">
				<div class="tooltip-address" title="${bin.item[i].Bin_location.bin_address}">${bin.item[i].Bin_location.bin_address}</div>
				<div class="tooltip-address" title="${bin.item[i].Bin_location.bin_neighbourhood == null ? "" : bin.item[i].Bin_location.bin_neighbourhood}">${bin.item[i].Bin_location.bin_neighbourhood == null ? "" : bin.item[i].Bin_location.bin_neighbourhood}</div>
				</div>
				</div>
				</div>
				<div class="tooltip-id" style="padding-bottom: 9px;">
				<div class="tooltip-text">${this.translationsObj.MAP_MARKER.VOLUME}:
				</div>
				</div>
				<div class="tooltip-marker" style="${this.getTooltipStyle(bin.item[i].Last_updated_fill_level_sample.fill_level_in_precent)}">
				${this.capacityDisplayType != 0 ? bin.item[i].Last_updated_fill_level_sample.fill_level_in_litters : bin.item[i].Last_updated_fill_level_sample.fill_level_in_precent}${this.capacityDisplayType !=0 ? 'L' : '%'}
				<div class="tooltip-arrow-marker"></div>
				</div>    	
				<div id="progressbar" class="progress_bar" style="background: ${this.getProgressBarStyleWrapper(bin.item[i].bin_status_id_color)};border-radius: 10px;margin-bottom: 5px;display:flex;position: relative;">
				<div class="progress_bar_internal" style="background: ${this.getProgressBarStyleInternal(bin.item[i].bin_status_id_color)};width: ${this.getProgressBarWidthInternal(bin.item[i].Last_updated_fill_level_sample.fill_level_in_precent)};height: 14px;border-radius: 10px; line-height: 15px;">
				</div>
				</div>
				<div class="last">
				<div class="tooltip-id">
				<div class="tooltip-text-collection">
				${this.usageType == 1 ? this.translationsObj.LIST_COLUMNS.LAST_COLLECTION : ''}:
				</div>
				<div class="tooltip-text-collection">${this.usageType == 1 ? (this.currentLang == 'iw' && bin.item[i].last_collection_time!='' && bin.item[i].last_collection_time!=undefined ? this.utilService.convertDateHebrew(bin.item[i].last_collection_time) : this.utilService.convertDateEnglish(bin.item[i].last_collection_time)) : ''}</div>
				<div class="tooltip-text-collection">${(bin.item[i].last_collection_time=='' || bin.item[i].last_collection_time==undefined) && this.usageType == 1 ? this.translationsObj.TITLES.LAST_COL : ''}</div>				
				</div>
				</div>
				</div>
				`;
			}
			content = `<button class="carousel-control" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" class="prevNextArrow">
			<img src="assets/images/dashboard/pagination_arrow.png" alt="" class="${this.currentLang != 'iw' ? "rotation" : ""}">
			</button>
			<span style="position:relative;bottom:3px;padding: 0px 8px;">|</span>
			<button class="carousel-control" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" class="prevNextArrow">
			<img src="assets/images/dashboard/pagination_arrow.png" alt="" class="${this.currentLang == 'iw' ? "rotation" : ""}">
			</button>
			</div>
			<div id="carouselExampleControls" style="top:9px" class="carousel slide carousel-fade" data-ride="carousel">
			<div id="carousel-inner-frags" class="carousel-inner" style="overflow:unset">			
				${binTooltips}
			</div>`;
		}

		let node;
		node = document.createElement("div");
		node.id = "infoWindow-popup";
		node.className = 'infoWindow-class';
		node.innerHTML = content;
		
		if(this.moreThanMax){
			setTimeout(() => {					
				this.checkIfInfoWindowExist();
				document.getElementById("binInfoWindow_multiSites").append(node);
			}, 100);
		}else{
			setTimeout(() => {
				this.checkIfInfoWindowExist();
				document.getElementById("binInfoWindow_singleSite").append(node);
			}, 100);
		}	
	}

	checkIfInfoWindowExist(){
		if(document.getElementById('infoWindow-popup')){
			document.getElementById('infoWindow-popup').remove();
		}
	}

	isNumber(val): boolean { return typeof val === 'number'; }

	convertColotToInt = (color) => {
		switch (color) {
			case 'GREEN':
				return "#245B3E";
			case 'RED':
				return "#E01B09";
			case 'YELLOW':
				return "#FAB300";
		}
	}

	private createException(map, b, exc) {
		const exceptionName = this.currentLang != 'iw' ? b.msg.replace(/_/g, ' ') : b.msg_hebrew.replace(/_/g, ' ');
		const icon = this.exceptionToIcon(exc);
		const infowindow = new google.maps.InfoWindow({
			content: this.exceptionToHtml(exceptionName,b)
		});
		this.openedInfoWindow.push(infowindow);
		const exception = new google.maps.Marker({
			position: {
				lng: Number(b.location.lon),
				lat: Number(b.location.lat)
			},
			map: map,
			icon: icon,
		});
		this.exceptionMarker.push(exception);

		exception.addListener('click', () => {
			infowindow.open(map, exception);
			const iwOuter = document.querySelectorAll('.gm-style-iw');
			Array.from(document.querySelectorAll('.gm-style-iw')).forEach((element, index) => {
				const iwBackground = element.previousSibling;
				if (iwBackground instanceof HTMLElement) {
					iwBackground.style.display = 'none';
				}
			});
		});
	}

	private setExceptions = map => {
		this.hideExcMarkers();
		this.exceptionMarker = [];
		const collectedExceptions = [];
		if(this.exceptions != null && this.exceptions.length > 0){	
			_.each(this.exceptions, (exception) => {
				if(exception.exception_id == 6){					
					_.each(this.backUptruckBins, (bin) => {
						if(exception.expected_to_collect_bin_id == 0){
							const exp = {
								location: {
									lat: Number(exception.location_lat),
									lon: Number(exception.location_lon)
								},
								time:exception.exception_time,
								msg: exception.exception_id_name,
								msg_hebrew: exception.exception_id_name_hebrew,
								bin_name: '',
								reason_name: exception.route_calculation_reason_name ? exception.route_calculation_reason_name : '',
								reason_name_hebew: exception.route_calculation_reason_name_hebrew ? exception.route_calculation_reason_name_hebrew : ''
							}
							this.createException(map, exp, exception);	
						}else if(bin["bin_id"] == exception.expected_to_collect_bin_id){
							const exp = {
								location: {
									lat: Number(exception.location_lat),
									lon: Number(exception.location_lon)
								},
								time:exception.exception_time,
								msg: exception.exception_id_name,
								msg_hebrew: exception.exception_id_name_hebrew,
								bin_name: "Expected to collect bin " + bin.bin_name,
								reason_name: exception.route_calculation_reason_name ? exception.route_calculation_reason_name : '',
								reason_name_hebew: exception.route_calculation_reason_name_hebrew ? exception.route_calculation_reason_name_hebrew : ''
							}
							this.createException(map, exp, exception);							
						}
					});
				}else if(exception.exception_id == 7){
					_.each(this.backUptruckBins, (bin) => {
						if(bin["bin_id"] == exception.expected_to_collect_bin_id){
							if(bin.is_collected != 1){														
								const exp = {
									location: {
										lat: Number(exception.location_lat),
										lon: Number(exception.location_lon)
									},
									time:exception.exception_time,
									msg: exception.exception_id_name,
									msg_hebrew: exception.exception_id_name_hebrew,
									bin_name: "Expected to collect bin " + bin.bin_name,
									reason_name: exception.route_calculation_reason_name ? exception.route_calculation_reason_name : '',
									reason_name_hebew: exception.route_calculation_reason_name_hebrew ? exception.route_calculation_reason_name_hebrew : ''
								}
								this.createException(map, exp, exception);
							}
						}					
					});
				}else if(exception.exception_id == 9 || exception.exception_id == 11 || exception.exception_id == 12){
					_.each(this.backUptruckBins, (bin) => {
						if(bin["bin_id"] == exception.bin_id){
							const exp = {
								location: {
									lat: Number(exception.location_lat),
									lon: Number(exception.location_lon)
								},
								time:exception.exception_time,
								msg: exception.exception_id_name,
								msg_hebrew: exception.exception_id_name_hebrew,
								bin_name: this.translationsObj.MAP_MARKER.NAME + ": " + bin.bin_name,
								reason_name: exception.route_calculation_reason_name ? exception.route_calculation_reason_name : '',
								reason_name_hebew: exception.route_calculation_reason_name_hebrew ? exception.route_calculation_reason_name_hebrew : ''
							}
							this.createException(map, exp, exception);							
						}					
					});
				}else{				
					const exp = {
						location: {
							lat: Number(exception.location_lat),
							lon: Number(exception.location_lon)
						},
						time:exception.exception_time,
						msg: exception.exception_id_name,
						msg_hebrew: exception.exception_id_name_hebrew,
						bin_name:'',
						reason_name: exception.route_calculation_reason_name ? exception.route_calculation_reason_name : '',
						reason_name_hebew: exception.route_calculation_reason_name_hebrew ? exception.route_calculation_reason_name_hebrew : ''
					}
					this.createException(map, exp, exception);
				}
			});
		}
	}

	private labelOption(bin,exceptions,icon){		
		let label = {
			text: bin.bin_collection_order_index.toString(),
			className: icon.toString().includes("arrived") || icon.toString().includes("collected") ? "short-marker-label" : "marker-label",
			color: this.convertColotToInt(bin.bin_status_id_color),
			fontSize: "16px",
			fontWeight: "bold"
		}
		
		if(bin.is_collected == 2){
			return null;
		}
		
		if(exceptions != null && exceptions.length > 0){
			_.each(exceptions, (exc) => {
				if(exc.expected_to_collect_bin_id == bin.bin_id && exc.exception_id == 7){	
					return null;
				}else{
					return bin.is_collected == 0 ? label : null;
				}
			});
		}

		if(bin.bin_collection_order_index == 0){
			return null;
		}else{
			return label;
		}
	}

	private BinToIcon(bin,exceptions) {	
		let icon = '';		
		if(bin.is_collected == 2){
			if(bin.bin_status_id == 0){				
				icon = `../../assets/images/bins/all/out_green.svg`;
			}else if(bin.bin_status_id == 1){
				icon = `../../assets/images/bins/all/out_yellow.svg`;
			}else if(bin.bin_status_id == 2){
				icon = `../../assets/images/bins/all/out_red.svg`;
			}
			return icon;
		}

		let binChosen = _.find(exceptions, function (exc) {
			return (exc.expected_to_collect_bin_id == bin.bin_id && exc.exception_id == 7 && bin.is_collected == 0)
		});	

		if(binChosen != null){
			if(bin.bin_collection_order_index != 0){				
				if(bin.bin_status_id == 0){				
					icon = `../../assets/images/bins/all/arrived-green.svg`;
				}else if(bin.bin_status_id == 1){
					icon = `../../assets/images/bins/all/arrived-yellow.svg`;
				}else if(bin.bin_status_id == 2){
					icon = `../../assets/images/bins/all/arrived-red.svg`;
				}	
			}else{
				if(bin.bin_status_id == 0){				
					icon = `../../assets/images/bins/all/default-arrived-green.svg`;
				}else if(bin.bin_status_id == 1){
					icon = `../../assets/images/bins/all/default-arrived-yellow.svg`;
				}else if(bin.bin_status_id == 2){
					icon = `../../assets/images/bins/all/default-arrived-red.svg`;
				}
			}
			return icon;
		}	

		if(bin.bin_collection_order_index == 0 && bin.is_collected == 0){
			if(this.usageType == 1){
				if(bin.bin_status_id == 0){
					icon = `../../assets/images/bins/all/greenNotOrdered.svg`;
				}else if(bin.bin_status_id == 1){
					icon = `../../assets/images/bins/all/yellowNotOrdered.svg`;
				}else if(bin.bin_status_id == 2){
					icon = `../../assets/images/bins/all/redNotOrdered.svg`;
				}
				return icon;
			}else{
				if(bin.bin_status_id == 0){
					icon = `../../assets/images/bins/all/greenNotOrdered2.svg`;
				}else if(bin.bin_status_id == 1){
					icon = `../../assets/images/bins/all/yellowNotOrdered2.svg`;
				}else if(bin.bin_status_id == 2){
					icon = `../../assets/images/bins/all/redNotOrdered2.svg`;
				}
				return icon;
			}

		}

		if(bin.is_collected == 0){
			if(bin.bin_status_id == 0){
				icon = `../../assets/images/bins/all/green1.svg`;
			}else if(bin.bin_status_id == 1){
				icon = `../../assets/images/bins/all/yellow1.svg`;
			}else if(bin.bin_status_id == 2){
				icon = `../../assets/images/bins/all/red1.svg`;
			}
			return icon;

		}else{
			if(bin.bin_collection_order_index != 0){
				if(bin.bin_status_id == 0){
					icon = `../../assets/images/bins/all/collected-green.svg`;
				}else if(bin.bin_status_id == 1){
					icon = `../../assets/images/bins/all/collected-yellow.svg`;
				}else if(bin.bin_status_id == 2){
					icon = `../../assets/images/bins/all/collected-red.svg`;
				}
			}else{
				if(bin.bin_status_id == 0){
					icon = `../../assets/images/bins/all/default-collected-green.svg`;
				}else if(bin.bin_status_id == 1){
					icon = `../../assets/images/bins/all/default-collected-yellow.svg`;
				}else if(bin.bin_status_id == 2){
					icon = `../../assets/images/bins/all/default-collected-red.svg`;
				}
			}
			return icon;

		}
			
	}

	getProgressBarStyleInternal = (binInfo: any) => {
		if (!binInfo) { return ''; }
		if (binInfo == "GREEN") {
			return '#34D08D';
		} else if (binInfo == "YELLOW") {
			return '#E2E055';
		}
		return '#D7594C';
	}

	getProgressBarWidthInternal = (binInfo: any) => {
		if (!binInfo) { return ''; }
		return `${binInfo}%`;
	  }

	getTooltipStyle = (binInfo: any) => {		
		if(this.currentLang == 'iw'){
			return `margin-right: ${binInfo-7}%`;
		}else{
			return `margin-left: ${binInfo-7}%`;
		}
	}

	getProgressBarStyleWrapper = (binInfo: any) => {		
		if (!binInfo) { return ''; }
		if (binInfo == "GREEN") {
			return 'rgba(52, 208, 141, 0.15)';
		} else if (binInfo == "YELLOW") {
			return 'rgba(226, 224, 85, 0.15)';
		}
		return 'rgba(215, 89, 76, 0.15)';
  	}

	private exceptionToIcon(exc): string {		
		let icon = '';
		if(exc.exception_id == 7){
			icon = `../../assets/images/workplans-page/arrived-but-no-collection.svg`;
		}else if(exc.exception_id == 6){
			icon = `../../assets/images/workplans-page/unexpected-collection-order.svg`;
		}else if(exc.exception_id == 2){			
			icon = `../../assets/images/workplans-page/login-exception.svg`
		}else if(exc.exception_id == 3){			
			icon = `../../assets/images/workplans-page/logout-exception.svg`
		}else if(exc.exception_id == 9){			
			icon = `../../assets/images/workplans-page/bin_location_issue_reported.svg`
		}else if(exc.exception_id == 4){			
			icon = `../../assets/images/workplans-page/plan_finished.svg`
		}else if(exc.exception_id == 1){			
			icon = `../../assets/images/workplans-page/user_leave_route.svg`
		}else if(exc.exception_id == 11){			
			icon = `../../assets/images/workplans-page/unusable_bin_issue.svg`
		}else if(exc.exception_id == 12){			
			icon = `../../assets/images/workplans-page/bin_sticker_issue.svg`
		}else{
			icon = `../../assets/images/workplans-page/exceptions icon.svg`
		}
		return icon;
	}

	private exceptionToHtml(exceptionName,exp): string {
		if (!exceptionName) { return ''; }
		const content = `
		<div id="iw-container" style="margin-bottom:0;font-family: 'Open Sans', sans-serif;">
			<div class="iw-content" >
					<div class="exc-tooltip-value">${exceptionName}</div>
					<div class="exc-tooltip-value">${exp.bin_name != null ? exp.bin_name : '' }</div>
					<div class="exc-tooltip-value">${this.currentLang != 'iw' ? exp.reason_name : exp.reason_name_hebew}</div>
					<div class="exc-tooltip-value">${exp.time}</div>
			</div>
		</div>`;
		return content;
	}

	public hideExcMarkers = () => {
		this.exceptionMarker.map(marker => {
			marker.setVisible(false);
		})
	}

	public showExcMarkers = () => {
		this.exceptionMarker.map(marker => {
			marker.setVisible(true);
		})

	}
}

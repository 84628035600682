import { Component, OnInit, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ResponsiveService } from '../../../services/responsive.service';
import { LogoutValidationComponent } from '../logout-validation/logout-validation.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sidebar-item',
  standalone: true,
  imports: [CommonModule,RouterModule],
  templateUrl: './sidebar-item.component.html',
  styleUrl: './sidebar-item.component.scss'
})
export class SidebarItemComponent {
  @Input() link: string= '';
  @Input() icon: string= '';
  @Input() iconChosen: string= '';
  @Input() label: string = '';
  isMobile: boolean = false;
  clickedItem : Boolean = false;
  dialogRef: any;

	constructor(private responsiveService: ResponsiveService,private router: Router,
		private dialog: MatDialog) {
		router.events.subscribe((val:any) => {
			if(val["url"]){
				if(val["url"].includes('newinstallationwizard')){
					this.clickedItem = true;
				}else{
					this.clickedItem = false;	
				}			
			}		
		})
	}

	ngOnInit(){		
		this.onResize();
		this.responsiveService.checkWidth();
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

	onReqToLink(link:any){		
		this.dialogRef = this.dialog.open(LogoutValidationComponent, {
			width: '600px',
			disableClose: true
			});

			this.dialogRef.afterClosed().subscribe((result:any) => {
			if (result == true) {
				this.router.navigate([link]);
			}
		});		
	}
}

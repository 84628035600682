<div>
  <div class="modal-title">
    @if (usageType == 1) {
      <span class="main-title">{{ 'MANAGE_BIN.MANAGE_BINS' | translate }}</span>
    }
    @if (usageType != 1) {
      <span class="main-title">{{ 'MANAGE_BIN.MANAGE_TANKS' | translate }}</span>
    }
    <span class="back-btn" rtlDiv (click)="closeModal()"> < {{ 'SITE_MANAGMENT.BACK' | translate }}</span>
  </div>
  <div class="bulk-bins-container">
    <div class="opt-title" rtlDiv>{{ 'MANAGE_BIN.BULK_EDIT' | translate }} {{data.chosenBins.length}}
      @if (usageType == 1) {
        <span>{{ 'SITE_MANAGMENT.BINS' | translate }}</span>
      }
      @if (usageType != 1) {
        <span>{{ 'SITE_MANAGMENT.TANKS' | translate }}</span>
      }
    </div>
    <div class="prop-frame">
      @if (data.properties.isSite) {
        <div class="days-between-container">
          <div class="sub-title" rtlDiv>{{ 'TICKET_MANAGEMENT.SITE' | translate }}</div>
          <form [formGroup]="ElFormSites" class="neighborhoods-form">
            <mat-form-field class="neighborhoods-full-width">
              <input tabindex="-1" class="input-neighborhood" matInput aria-label="Item" [matAutocomplete]="auto" formControlName="binSite" (keyup.enter)="addOption()" rtlDiv>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeSite($event.option)">
                @for (site of filteredbinSitesToDisplay | async; track site) {
                  <mat-option [value]="site" class="neighborhood-item" rtlDiv>
                    <span>{{ site }}</span>
                  </mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </div>
      }

      @if (data.properties.isNeighborhood) {
        <div class="days-between-container">
          <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</div>
          <form [formGroup]="ElForm" class="neighborhoods-form">
            <mat-form-field class="neighborhoods-full-width">
              <input tabindex="-1" class="input-neighborhood" matInput aria-label="Item" [matAutocomplete]="auto" formControlName="itemCtrl" (keyup.enter)="addOption()" rtlDiv>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option)">
                @for (item of filteredItems | async; track item) {
                  <mat-option [value]="item" class="neighborhood-item" rtlDiv>
                    <span>{{ item }}</span>
                  </mat-option>
                }
              </mat-autocomplete>
              @if (showAddButton && ElForm.getRawValue()['itemCtrl']) {
                <button matSuffix mat-button mat-icon-button (click)="addOption()" [ngClass]="{'ltrBtn' : currentLang == 'iw', 'rtlBtn' : currentLang != 'iw'}">
                  <img src="assets/images/inventory/plus.svg" alt=""/>
                  <span class="btn-opt">{{ 'SITE_MANAGMENT.ADD' | translate }}</span>
                </button>
              }
              @if (approveAdd) {
                <button matSuffix mat-button mat-icon-button [ngClass]="{'ltrBtn' : currentLang == 'iw', 'rtlBtn' : currentLang != 'iw'}">
                  <img src="assets/images/inventory/checkmark.svg" alt=""/>
                  <span class="btn-opt">{{ 'SITE_MANAGMENT.ADDED' | translate }}</span>
                </button>
              }
            </mat-form-field>
          </form>
        </div>
      }
      @if (usageType == 1 && data.properties.isMaxDays) {
        <div class="days-between-container" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'MANAGE_BIN.MAX_DAYS' | translate }}</div>
          <div class="days-between-input">
            <input id="max_days" type="number" class="input-number" [(ngModel)]="maxDaysValue" min="0" rtlDiv/>
            <div class="arrows-container">
              <div class="up-container">
                <div class='up-arrow' (click)="incrementMaxCollection()"></div>
              </div>
              <div class="down-container">
                <div class='down-arrow' (click)="decrementMaxCollection()"></div>
              </div>
            </div>
          </div>
        </div>
      }
      @if (data.properties.isBinMaxCapLiter) {
        <div class="days-between-container" rtlDiv>
          @if (usageType == 1) {
            <div class="sub-title" rtlDiv>{{ 'SITE_MANAGMENT.BIN_MAX_CAP_L' | translate }}</div>
          }
          @if (usageType != 1) {
            <div class="sub-title" rtlDiv>{{ 'SITE_MANAGMENT.TANK_MAX_CAP_L' | translate }}</div>
          }
          <div class="days-between-input">
            <input id="max_cap" type="number" class="input-number" [(ngModel)]="maxCapValue" min="0" rtlDiv/>
            <div class="arrows-container">
              <div class="up-container">
                <div class='up-arrow' (click)="incrementMaxCap()"></div>
              </div>
              <div class="down-container">
                <div class='down-arrow' (click)="decrementMaxCap()"></div>
              </div>
            </div>
          </div>
        </div>
      }
      @if (data.properties.isThresholdLevel) {
        <div class="threshold-level-container">
          <div class="threshold-sub-title" rtlDiv>{{ 'MANAGE_BIN.THRESHOLD_LEVEL' | translate }}</div>
          <div class=" threshold-sub-title">
            <nouislider #slider [connect]="true" [min]="0" [max]="100" [tooltips]="thresholdTooltips" [(ngModel)]="thresholdRange"
            (ngModelChange)="onThresholdChange($event)"></nouislider>
            <div class="legend-container">
              <span class="left-legend">0%</span>
              <span class="right-legend">100%</span>
            </div>
          </div>
        </div>
      }
      @if (data.properties.isMaxCapacity) {
        <div class="max-capacity-container">
          @if (usageType == 1) {
            <div class="sub-title" rtlDiv>{{ 'MANAGE_BIN.MAX_CAPACITY' | translate }}</div>
          }
          @if (usageType == 2) {
            <div class="sub-title" rtlDiv>{{ 'MANAGE_BIN.MIN_CAPACITY_D' | translate }}</div>
          }
          <div class="threshold-bar">
            <nouislider #maxslider [connect]="true" [min]="0" [max]="100" [tooltips]="maxTooltip" [(ngModel)]="maxRange"
            (ngModelChange)="onMaxChange($event)"></nouislider>
            <div class="legend-container">
              <span class="left-legend">0%</span>
              <span class="right-legend">100%</span>
            </div>
          </div>
        </div>
      }

      @if (data.properties.isBinRestrictionsTimes) {
        <div rtlDiv class="restriction-container">
          <div class="sub-title">{{ 'MANAGE_BIN.BIN_RESTRICTION_TIMES' |
          translate }}</div>
          @if (!restrictionOpen) {
            <div class="restriction-input-closed" (click)="toggleRestriction()">
              <div class="restriction-input-container">
                <div class="restriction-input-title">{{ 'MANAGE_BIN.BIN_RESTRICTION_CAPTION' |
                translate }}</div>
                <div class="chevron-down"></div>
              </div>
            </div>
          }
          @if (restrictionOpen) {
            <div class="restriction-input-open">
              <div class="title-container">
                <div class="restriction-input-title" rtlDiv>{{ 'MANAGE_BIN.BIN_RESTRICTION_CAPTION' |
                translate }}</div>
                <div class="chevron-up" (click)="toggleRestriction()"></div>
              </div>
              @for (restriction of restrictions; track restriction; let i = $index) {
                <div class="restriction-time-container">
                  <div class="dflex">
                    <mat-checkbox class="restriction-checkbox" [checked]="restrictions[i].checked" (change)="resetRestriction(i,$event)" rtlDiv> </mat-checkbox>
                    <div class="time-container" [ngClass]="{'disabled-restriction': !restrictions[i].checked}">
                      <div class="time-title">{{ 'MANAGE_BIN.START_TIME' |
                      translate }}</div>
                      <div class="flex-container" rtlDiv>
                        <div class='time-btn left' (click)="openDropDownList(restriction,'startTimeHour')">
                          {{selectedManageBinTime[i].startHour}}
                          <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                          <div [hidden]="!restriction.showHideDropdown.startTimeHour" class='time-dropdown-container'>
                            @for (item of manageBinTime[i].hours; track item) {
                              <div class="drop-down-list-item time-list" (click)="selectManageBinTime(item, 'startHour' , i)">
                              {{item}} </div>
                            }
                          </div>
                        </div>
                        <div class="colon">:</div>
                        <div class='time-btn right' (click)="openDropDownList(restriction, 'startTimeMinute')">
                          {{selectedManageBinTime[i].startminute}}
                          <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                          <div [hidden]="!restriction.showHideDropdown.startTimeMinute" class='time-dropdown-container'>
                            @for (item of manageBinTime[i].minutes; track item) {
                              <div class="drop-down-list-item time-list" (click)="selectManageBinTime(item, 'startminute', i)">
                              {{item}} </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="arrow-container">
                      <div class="arrow " [ngClass]="{'transform-arrow': isRtl()}"></div>
                    </div>
                    <div class="time-container" [ngClass]="{'disabled-restriction': !restrictions[i].checked}">
                      <div class="time-title">{{ 'MANAGE_BIN.END_TIME' |
                      translate }}</div>
                      <div class="flex-container" rtlDiv>
                        <div class='time-btn left' (click)="openDropDownList(restriction,'endTimeHour')">
                          {{selectedManageBinTime[i].endHour}}
                          <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                          <div [hidden]='!restrictions[i].showHideDropdown.endTimeHour' class='time-dropdown-container'>
                            @for (item of manageBinTime[i].hours; track item) {
                              <div class="drop-down-list-item time-list" (click)="selectManageBinTime(item, 'endHour', i)">
                              {{item}} </div>
                            }
                          </div>
                        </div>
                        <div class="colon">:</div>
                        <div class='time-btn right' (click)="openDropDownList(restriction,'endTimeMinute')">
                          {{selectedManageBinTime[i].endMinute}}
                          <img class='time-down-arrow-img' src="assets/images/workplans-page/down_arrow_icon.svg" alt="arrow">
                          <div [hidden]='!restrictions[i].showHideDropdown.endTimeMinute' class='time-dropdown-container'>
                            @for (item of manageBinTime[i].minutes; track item) {
                              <div class="drop-down-list-item time-list" (click)="selectManageBinTime(item, 'endMinute', i)">
                              {{item}} </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  @if (i == 0 && endTimeMustBeAfterStartTimeFirst) {
                    <div class="error-msg">{{ 'MANAGE_BIN.END_TIME_AFTER_START_TIME' | translate }}</div>
                  }
                  @if (i == 1 && endTimeMustBeAfterStartTimeSecond) {
                    <div class="error-msg">{{ 'MANAGE_BIN.END_TIME_AFTER_START_TIME' | translate }}</div>
                  }
                  @if (i == 2 && endTimeMustBeAfterStartTimeThird) {
                    <div class="error-msg">{{ 'MANAGE_BIN.END_TIME_AFTER_START_TIME' | translate }}</div>
                  }
                  @if (i == 1 && startTimeMustBeAfterEndTimeOfTheFirstOption) {
                    <div class="error-msg">{{ 'MANAGE_BIN.START_TIME_AFTER_END_TIME_OF_FIRST' | translate }}</div>
                  }
                  @if (i == 2 && startTimeMustBeAfterEndTimeOfTheSecondOption) {
                    <div class="error-msg">{{ 'MANAGE_BIN.START_TIME_AFTER_END_TIME_OF_SECOND' | translate }}</div>
                  }
                </div>
              }
            </div>
          }
        </div>
      }

      @if (data.properties.isIncludeInWorkplan) {
        <div class="days-between-container">
          <div class="sub-title" rtlDiv></div>
          <div class="dflex" rtlDiv>
            <label class="container-extra" rtlDiv>
              {{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}
              <input id="chbx_workplanincluded" [(ngModel)]="isWorkplanIncluded" [checked]="isWorkplanIncluded" type="checkbox" (change)="toggleEditableInclude(isWorkplanIncluded)" rtlDiv>
              <span class="checkmark" rtlDiv></span>
            </label>
          </div>
        </div>
      }
      @if (!isWorkplanIncluded) {
        <div class="days-between-container">
          <div class="neighborhoods-form">
            <mat-form-field class="neighborhoods-full-width">
              <mat-label class="trigger-txt" rtlDiv>{{ 'MANAGE_BIN.CHOOSE_REASON' | translate }}</mat-label>
              <mat-select placeholder="" (valueChange)="changeReasonType($event)" [(value)]="selectedReasonType" class="mat-options" rtlDiv>
                @for (item of reasonTypes; track item) {
                  <mat-option [value]="item.removedBinReason_id" class="neighborhood-item" rtlDiv>
                    <span>{{ currentLang == 'iw' ? item.removedBinReason_name_hebrew : item.removedBinReason_name }}</span>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      }
      @if (data.properties.isBinRestrictionDays) {
        <div class="days-between-container">
          @if (usageType == 1) {
            <div class="sub-title" rtlDiv>{{ 'MANAGE_BIN.BIN_COLLECTION_DAYS' | translate }}</div>
          }
          @if (usageType == 2) {
            <div class="sub-title" rtlDiv>{{ 'MANAGE_BIN.TANK_FILLING_DAYS' | translate }}</div>
          }
          <div class="dflex" rtlDiv>
            <label class="container-extra" rtlDiv>
              {{ 'NEW_WORKPLAN.SUN' | translate }}
              <input id="sunday" [(ngModel)]="isSundayChecked" [checked]="isSundayChecked" type="checkbox" (change)="toggleEditableSunday(isSundayChecked)" rtlDiv>
              <span class="checkmark" rtlDiv></span>
            </label>
            <label class="container-extra" rtlDiv>
              {{ 'NEW_WORKPLAN.MON' | translate }}
              <input id="monday" [(ngModel)]="isMondayChecked" [checked]="isMondayChecked" type="checkbox" (change)="toggleEditableMonday(isMondayChecked)" rtlDiv>
              <span class="checkmark" rtlDiv></span>
            </label>
            <label class="container-extra" rtlDiv>
              {{ 'NEW_WORKPLAN.TUE' | translate }}
              <input id="tuesday" [(ngModel)]="isTuesdayChecked" [checked]="isTuesdayChecked" type="checkbox" (change)="toggleEditableTuesday(isTuesdayChecked)" rtlDiv>
              <span class="checkmark" rtlDiv></span>
            </label>
            <label class="container-extra" rtlDiv>
              {{ 'NEW_WORKPLAN.WED' | translate }}
              <input id="wednesday" [(ngModel)]="isWednesdayChecked" [checked]="isWednesdayChecked" type="checkbox" (change)="toggleEditableWednesday(isWednesdayChecked)" rtlDiv>
              <span class="checkmark" rtlDiv></span>
            </label>
            <label class="container-extra" rtlDiv>
              {{ 'NEW_WORKPLAN.THU' | translate }}
              <input id="thursday" [(ngModel)]="isThursdayChecked" [checked]="isThursdayChecked" type="checkbox" (change)="toggleEditableThursday(isThursdayChecked)" rtlDiv>
              <span class="checkmark" rtlDiv></span>
            </label>
            <label class="container-extra" rtlDiv>
              {{ 'NEW_WORKPLAN.FRI' | translate }}
              <input id="friday" [(ngModel)]="isFridayChecked" [checked]="isFridayChecked" type="checkbox" (change)="toggleEditableFriday(isFridayChecked)" rtlDiv>
              <span class="checkmark" rtlDiv></span>
            </label>
            <label class="container-extra" rtlDiv>
              {{ 'NEW_WORKPLAN.SAT' | translate }}
              <input id="saturday" [(ngModel)]="isSaturdayChecked" [checked]="isSaturdayChecked" type="checkbox" (change)="toggleEditableSaturday(isSaturdayChecked)" rtlDiv>
              <span class="checkmark" rtlDiv></span>
            </label>
          </div>
        </div>
      }
    </div>
  </div>
  <div class='main-btns-wrapper'>
    <div class='btns-container' rtlDiv>
      <div class='cancel-btn' (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' | translate }}</div>
      <div class='apply-btn' (click)='saveWorkplan()' [ngClass]="{'disableBtn' : !isWorkplanIncluded && !reasonType , 'enableBtn' : (!isWorkplanIncluded && reasonType) || isWorkplanIncluded}">{{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}</div>
    </div>
  </div>
</div>
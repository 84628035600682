<div class='gray-background' (click)="closeModal()"></div>
@if (!isMobile) {
  <div class="manage-bin-modal" rtlDiv>
    <div class="manage-bin-header"> {{'SITE_MANAGMENT.ACTIONS' | translate }}</div>
    <div class="manage-bit-body">
      <form [formGroup]="ElForm">
        <div class="bin-name-container">
          @if (translationsObj && currentStatus) {
            <div class="sub-title">{{ currentStatus == 1 ? translationsObj.LIST_COLUMNS.STATUS_DESC : translationsObj.SITE_MANAGMENT.CHANGE_STATUS }}</div>
          }
          <select class="bin-name-input" formControlName="status">
            @for (method of status; track method) {
              <option [value]="method['unit_reported_issues_type_id']">
                {{currentLang != 'iw' ? method['unit_reported_issues_type_name'] : method['unit_reported_issues_type_name_hebrew']}}
              </option>
            }
          </select>
        </div>
      </form>
      <div class='main-btns-wrapper'>
        <div class='btns-container' rtlDiv>
          <div class='cancel-btn' (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' | translate }}</div>
          <div class='apply-btn' (click)='saveWorkplan()'>{{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
}

@if (isMobile) {
  <div class="mobile-manage-bin-modal" rtlDiv>
    <div class="manage-bin-header"> {{'SITE_MANAGMENT.ACTIONS' | translate }}</div>
    <div class="manage-bit-body">
      <form [formGroup]="ElForm">
        <div class="bin-name-container">
          @if (translationsObj && currentStatus) {
            <div class="sub-title">{{ currentStatus == 1 ? translationsObj.LIST_COLUMNS.STATUS_DESC : translationsObj.SITE_MANAGMENT.CHANGE_STATUS }}</div>
          }
          <select class="bin-name-input" formControlName="status">
            @for (method of status; track method) {
              <option [value]="method['unit_reported_issues_type_id']">
                {{currentLang != 'iw' ? method['unit_reported_issues_type_name'] : method['unit_reported_issues_type_name_hebrew']}}
              </option>
            }
          </select>
        </div>
      </form>
      <div class='main-btns-wrapper'>
        <div class='btns-container' rtlDiv>
          <div class='cancel-btn' (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' | translate }}</div>
          <div class='apply-btn' (click)='saveWorkplan()'>{{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
}
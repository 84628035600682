@if (!isMobile) {
  <div class='truck-details-wrapper'>
    <mat-card>
      <div class='spinner-container' [ngClass]="{'hidden': !spinnerActive}">
        <div class="spinner"></div>
      </div>
      <mat-card-content id="truck-details-container" [ngClass]="{'hidden': spinnerActive}">
        <div class='box truck-driver'>
          <div class='box-img-wrapper'>
            <img class='box-img' src="../assets/images/workplans-page/truck_driver_icon.svg" alt="truck_driver_icon">
            <div class='subtitle'>{{ 'WORKPLAN.TRUCK_DRIVER' | translate }}</div>
          </div>
          @if (truckDriverName == '-1') {
            <div class='title'>{{ 'WORKPLAN.TRUCK_DRIVER_NAME' | translate }}</div>
          }
          @if (truckDriverName != '-1') {
            <div class='title'>{{truckDriverName}}</div>
          }
        </div>
        <div class="middle-divider"></div>
        <div class='box'>
          <div class='box-img-wrapper'>
            <img class='box-img' src="../assets/images/workplans-page/bins_collected_icon.svg" alt="start_time_icon">
            <div class='subtitle'>{{ 'WORKPLAN.BINS_COLLECTED' | translate }}</div>
          </div>
          <div class="collected_bins_txt">
            <div class='title collected-total'>
              {{collectedBins}}
              <span class='bin-collected' rtlDiv>/ {{binToCollect}}</span>
            </div>
            @if(notIncluded.length > 0){
              <div class="collections-label">
                <img class="alert-collections" src="../assets/images/workplans-page/alert.svg" alt="alert">
                <span class="collected_txt">{{notIncluded.length}} {{ 'WORKPLAN.NOT_INCLUDED_PLANS' | translate }}</span>
              </div>
            }
          </div>
        </div>
        <div class="middle-divider"></div>
        <div class='box'>
          <div class='box-img-wrapper'>
            <img class='box-img' src="../assets/images/workplans-page/start_time_icon.svg" alt="bins_collected_icon">
            <div>
              @if (displayStartTime()) {
                <div>
                  <div class='subtitle'>{{ 'WORKPLAN.START_TIME' | translate }}</div>
                </div>
              } @else {
                <div class='subtitle'>{{ 'WORKPLAN.ACTUAL_START_TIME' | translate }}</div>
              }
            </div>
          </div>
          <div class='title'>{{printStartTime()}}</div>
          @if (tabletTeamsAllocationId == '1') {
            <div class="additional-data">
              <div class='planned-time-lbls'>{{ 'WORKPLAN.PREPERATION_TEAM' | translate }} {{prep_team_plan_start_time}}</div>
              <div class='planned-time-lbls'>{{ 'WORKPLAN.BRINGING_IN_TEAM' | translate }} {{return_back_team_plan_start_time}}</div>
            </div>
          }
        </div>
        @if (displayStartTime()) {
          <div class="middle-divider"></div>
        }
        @if (displayStartTime()) {
          <div class='box'>
            <div class='box-img-wrapper'>
              <img class='box-img' src="../assets/images/workplans-page/estimated_start_time_icon.svg" alt="bins_collected_icon">
              <div class='subtitle'>{{ 'WORKPLAN.ESTIMATED_TRAVEL_TIME' | translate }}</div>
            </div>
            <div class='title'>
              {{estimatedTravelTime}}
              @if (isHours) {
                <span class='kilometers'>{{ 'COLLECTION_REPORT.HOURS' | translate }}</span>
              }
              @if (!isHours) {
                <span class='kilometers'>{{ 'COLLECTION_REPORT.MINUTES' | translate }}</span>
              }
            </div>
          </div>
        }
        @if (displayStartTime()) {
          <div class="middle-divider"></div>
        }
        @if (displayStartTime()) {
          <div class='box'>
            <div class='box-img-wrapper'>
              <img class='box-img' src="../assets/images/workplans-page/distance_icon.svg" alt="distance_icon">
              <div class='subtitle'>{{ 'WORKPLAN.ESTIMATED_TRAVEL_DISTANCE' | translate }}</div>
            </div>
            <div class='title'>
              {{estimatedTravelDistance}}
              <span class='kilometers'>{{ 'WORKPLAN.KM' | translate }}</span>
            </div>
          </div>
        }
        @if (!displayStartTime()) {
          <div class="middle-divider"></div>
        }
        @if (!displayStartTime()) {
          <div class='box'>
            <div class='box-img-wrapper'>
              <img class='box-img' src="../assets/images/workplans-page/estimated_start_time_icon.svg" alt="distance_icon">
              <div class='subtitle'>{{ 'WORKPLAN.TOTAL_ACTUAL_TIME' | translate }}</div>
            </div>
            <div class='title'>
              {{totalTime}}
              @if (isHoursOfActualTime) {
                <span class='kilometers'>{{ 'COLLECTION_REPORT.HOURS' | translate }}</span>
              }
              @if (!isHoursOfActualTime) {
                <span class='kilometers'>{{ 'COLLECTION_REPORT.MINUTES' | translate }}</span>
              }
            </div>
          </div>
        }
        @if (!displayStartTime()) {
          <div class="middle-divider"></div>
        }
        @if (!displayStartTime()) {
          <div class='box'>
            <div class='box-img-wrapper'>
              <img class='box-img' src="../assets/images/workplans-page/distance_icon.svg" alt="distance_icon">
              <div class='subtitle'>{{ 'WORKPLAN.TOTAL_ACTUAL_DISTANCE' | translate }}</div>
            </div>
            <div class='title'>
              {{printDistance()}}
              <span class='kilometers'>{{ 'WORKPLAN.KM' | translate }}</span>
            </div>
          </div>
        }
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div class='mobile-truck-details-wrapper'>
    <mat-card>
      <div [ngClass]="{'hidden': !spinnerActive}" class="spinner-border mobile-spinner" role="status">
        <span class="sr-only"></span>
      </div>
      <mat-card-content id="truck-details-container" [ngClass]="{'hidden': spinnerActive}">
        <div class='box truck-driver'>
          <div class='box-img-wrapper'>
            <img class='box-img' rtlDiv src="../assets/images/workplans-page/truck_driver_icon.svg" alt="truck_driver_icon">
          </div>
          <div class="mobile-frame-value">
            @if (truckDriverName == '-1') {
              <div class='title'>{{ 'WORKPLAN.TRUCK_DRIVER_NAME' | translate }}</div>
            }
            @if (truckDriverName != '-1') {
              <div class='title'>{{truckDriverName}}</div>
            }
            <div class='subtitle'> {{ 'WORKPLAN.TRUCK_DRIVER' | translate }}</div>
          </div>
        </div>
        <mat-divider class="mobile-top-divider"></mat-divider>
        <div class='box'>
          <div class='box-img-wrapper'>
            <img class='box-img' rtlDiv src="../assets/images/workplans-page/bins_collected_icon.svg" alt="start_time_icon">
          </div>
          <div class="mobile-frame-value">
            <div class='title collected-total'>
              {{collectedBins}}
              <span rtlDiv>/ {{binToCollect}} </span>
            </div>
            <div class='subtitle'>{{ 'WORKPLAN.BINS_COLLECTED' | translate }}</div>
          </div>
        </div>
        @if(notIncluded.length > 0){
          <div class="mobile-collections-label">
            <img class="alert-collections" src="../assets/images/workplans-page/alert.svg" alt="alert">
            <span class="collected_txt">{{notIncluded.length}} {{ 'WORKPLAN.NOT_INCLUDED_PLANS' | translate }}</span>
          </div>
        }
        <mat-divider class="mobile-top-divider"></mat-divider>
        <div class='box'>
          <div class='box-img-wrapper'>
            <img class='box-img' rtlDiv src="../assets/images/workplans-page/start_time_icon.svg" alt="bins_collected_icon">
          </div>
          <div class="mobile-frame-value">
            <div class='title'>{{printStartTime()}}</div>
            @if (displayStartTime()) {
              <div>
                <div class='subtitle'>{{ 'WORKPLAN.START_TIME' | translate }}</div>
              </div>
            } @else {
              <div class='subtitle'>{{ 'WORKPLAN.ACTUAL_START_TIME' | translate }}</div>
            }
          </div>
        </div>
        <mat-divider class="mobile-top-divider"></mat-divider>
        <div class='box'>
          <div class='box-img-wrapper'>
            <img class='box-img' rtlDiv src="../assets/images/workplans-page/distance_icon.svg" alt="distance_icon">
          </div>
          <div class="mobile-frame-value">
            <div class='title'>
              {{printDistance()}}
              <span>{{ 'WORKPLAN.KM' | translate }}</span>
            </div>
            <div><!--*ngIf="!displayStartTime(); else plannedDistance"-->
              <div class='subtitle'> {{ 'WORKPLAN.TOTAL_ACTUAL_DISTANCE' | translate }}</div>
            </div>
            <!-- <ng-template #plannedDistance>
            <div class='subtitle'> {{ 'WORKPLAN.TOTAL_DISTANCE' | translate }}</div>
          </ng-template> -->
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
}
@if (!isMobile) {
    <div class="disclosure_container">
        <div class="disclosure_msg">
            {{ 'DRIVER_PLAN.DISCLOSURE_MSG' | translate }}
        </div>
        <div class="disclosure_btns">
            <button class="submit_btn" (click)="onAgree()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</button>
            <button class="cancel_btn" (click)="onClose()">{{ 'TRUCK_MAP.CANCEL' | translate }}</button>
        </div>
    </div>
}

@if (isMobile) {
    <div class="disclosure_container">
        <div class="disclosure_msg">
            {{ 'DRIVER_PLAN.DISCLOSURE_MSG' | translate }}
        </div>
        <div class="disclosure_btns">
            <button class="cancel_btn" (click)="onClose()">{{ 'TRUCK_MAP.CANCEL' | translate }}</button>
            <button class="submit_btn" (click)="onAgree()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</button>
        </div>
    </div>
}
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE_KEYS } from '../constants';

/**
 * This service acts as a middleware for router.
 * Responsible for
 *
 * 1) Guarding protected routes, which only authenticated users can access.
 * It uses the 'canActivate' property on routes. See routes.ts file
 *
 * 2) Authenticating jwt token
 */
@Injectable()
export class AuthService {

	constructor(public router: Router) { }

	canActivate(): boolean {
		if (!this.isTokenExists()) {
			this.router.navigate(['/login']);
			return false;
		}
		return true;
	}

	isTokenExists = (): boolean => {
		return this.getToken() ? true : false;
	}

	getToken = () => {
		return localStorage.getItem(LOCAL_STORAGE_KEYS?.AUTH_TOKEN);
	}
}

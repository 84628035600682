<div cdkDrag class="popup-window" style="position: inherit">
  <!-- <button type="button" class="close outLine" aria-label="Close" (click)="onNoClick()">
  <span aria-hidden="true">&times;</span>
</button> -->
<form>
  <div class="content" mat-dialog-content style="margin:0 0;width: max-content;padding: 24px;">
    <div [ngClass]="(firstmsg=='') ? 'hide':'show'" class="top-space row" style="justify-content: center; display: none;">
      <h4>{{firstmsg}}</h4>
    </div>
    <div [ngClass]="(secondmsg=='') ? 'hide':'show'" class="top-space row" style="justify-content: center;width: max-content;">
      {{secondmsg}}
    </div>
    <div [ngClass]="isCanceldAllowed ? 'two-buttons':'one-button'">
      <div>
        <button (click)="confirm()" type="button" class="confirm-button btn btn-outline-info btn-rounded waves-effect">{{ 'SITE_MANAGMENT.OK' | translate }}</button>
      </div>
      @if (isCanceldAllowed) {
        <div>
          <button (click)="cancel()" type="button" class="confirm-button btn btn-outline-info btn-rounded waves-effect">{{ 'NEW_WORKPLAN.CANCEL' | translate }}</button>
        </div>
      }
    </div>
  </div>
</form>
</div>
import { Component } from '@angular/core';
import { TranslatorService } from '../../services/translator_service';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';


@Component({
  selector: 'app-forgot-password-modal',
  standalone: true,
  imports: [TranslateModule, FormsModule, RTLDivDirectiveDirective,MatDialogModule],
  templateUrl: './forgot-password-modal.component.html',
  styleUrl: './forgot-password-modal.component.scss'
})
export class ForgotPasswordModalComponent {
  translate;
	translationsObj;
	currentLang;
	model: any = {};
	pass_sent = false;

	constructor(
		private translator: TranslatorService,
		public dialogRef: MatDialogRef<ForgotPasswordModalComponent>){
		this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
	}

	ngOnInit() {
	}

	onCreatePass() {
		if (this.model.username) {
			this.pass_sent = true;
		}
	}

}

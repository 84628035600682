<div class='workplan-popup-approve-wrapper'>
    <div class='info-wrapper'>
      <img class='workplan-popup-img' src="assets/images/workplans-page/validate_icon.svg" alt="arrow">
      <div class='popup-text'>
        {{ 'NEW_WORKPLAN.CONFIRMATION' | translate }}
      </div>
      <div class='popup-btns-container'>
        <div class='popup-btn no' (click)='approveCancelChanges(false)' rtlDiv>{{ 'OTHER.NO' | translate }}</div>
        <div class='popup-btn yes' (click)='approveCancelChanges(true)' rtlDiv>{{ 'OTHER.YES' | translate }}</div>
      </div>
    </div>
  </div>
import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { API } from '../../../types.api';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { E_Error_Tracer_Sensors_Zone, E_Error_Tracer_Zones } from '../../../types';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Observable, BehaviorSubject, ReplaySubject, combineLatest, Subject} from 'rxjs';
import _ from 'lodash';
import { CancelInstallationDialogComponent } from './cancel-installation-dialog/cancel-installation-dialog.component';
import { ActivationTimeOutDialogComponent } from './activation-time-out-dialog/activation-time-out-dialog.component';
import { InstallNewBinDialogComponent } from './install-new-bin-dialog/install-new-bin-dialog.component';
import moment from 'moment';
import { ActivationValidationDialogComponent } from './activation-validation-dialog/activation-validation-dialog.component';
import { CancelSwapErrorDialogComponent } from './cancel-swap-error-dialog/cancel-swap-error-dialog.component';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ApiStore } from '../../../web-services/api/api.store';
import { ApiService } from '../../../web-services/api/api.service';
import { ResponsiveService } from '../../../services/responsive.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslatorService } from '../../../services/translator_service';
import { takeUntil } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/collections';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxQrReaderComponent } from '../ngx-qr-reader/ngx-qr-reader.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MapComponent } from '../map/map.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import {CdkTableModule} from '@angular/cdk/table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatInputModule } from '@angular/material/input';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';

const finalReportData: IFinalReport[] = [];

const FIRST_ACTIVATION_TIMER_DURATION: number = 420;
const SECOND_ACTIVATION_TIMER_DURATION: number = 420;
const ACTIVATION_TRANSMISSION_TIMER_DURATION: number = 1000;
const PHYSICAL_TRANSMISSION_TIMER_DURATION: number = 1200;

const MAX_ALLOWED_BINS_IN_CLUSTER: number = 5;
@Component({
  selector: 'app-install-new-bin',
  standalone: true,
  imports: [TranslateModule,MatDividerModule,MatStepperModule,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,NgxQrReaderComponent,MatSelectModule,NgxMatSelectSearchModule,MapComponent,
    MatProgressBarModule,MatTableModule,MatSortModule,CdkTableModule,NgbModule,MatInputModule,RTLDivDirectiveDirective],
  templateUrl: './install-new-bin.component.html',
  styleUrl: './install-new-bin.component.scss',
  animations: [
    trigger('fingerDivState', [
      state('normal', style({
        transform: 'translateY(50px)',
        opacity: 0
      }),
      ),
      state('done', style({
        transform: 'translateY(0)',
        opacity: 1
      })),
      transition('*=>done', animate('1s 4s'))
    ]),
    trigger('detailExpand', [
		  state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
		  state('expanded', style({height: '*'})),
		  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
    trigger('sensorDivState', [
      state('normal', style({
        transform: 'scale(1)',
      }),
      ),
      state('done', style({
        transform: 'scale(1)'
      })),
      transition('*<=>*', animate('1s 2s',
      keyframes([
        style({transform: 'translate3d(-2px, -2px, -8px)', offset: 0.1}),
        style({transform: 'translate3d(2px, 2px, 8px)', offset: 0.2}),
        style({transform: 'translate3d(-4px, -4px, 0)', offset: 0.3}),
        style({transform: 'translate3d(4px, 4px, 0)', offset: 0.4}),
        style({transform: 'translate3d(-4px, -4px, 0)', offset: 0.5}),
        style({transform: 'translate3d(4px, 4px, 0)', offset: 0.6}),
        style({transform: 'translate3d(-4px, -4px, 0)', offset: 0.7}),
        style({transform: 'translate3d(2px, 2px, 0)', offset: 0.8}),
        style({transform: 'translate3d(-2px, -2px, 0)', offset: 0.9}),
      ])
      ))
    ])
  ]
})
export class InstallNewBinComponent {
	expandedElement: any;

  @Input() isSwap: any;
  @Input() isFastInstallation: boolean;
  @Input() fastInstallationBinId: number;

  associatedBinId: number;
  binSelectedCluster: BinCluster;
  binClusterId: number;
  installationPhase: API.InstallationPhase;
  timeOutObj: API.ITimOutObj;
  binCreationDate: any;
  siteId$: number;
  isLinear = true;
  fingerDivState = 'normal';
  sensorDivState = 'normal';
  isInitForms: boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  swapFormGroup: FormGroup;
  isFirstFormGroupSubmitted: boolean = false;
  isSecondFormGroupSubmitted: boolean = false;
  isThirdFormGroupSubmitted: boolean = false;
  isSwapFormGroupSubmitted: boolean = false;
  isInstallationCancled: boolean = false;
  isShowAdvanced: boolean = false;
  isValidLocation: boolean = false;
  isSwapError: boolean = false;
  swapErrorResponse: string;
  timeLeft: number = 5;
  interval;
  showTimer: boolean = false;
  firstActivationTimerTimeLeft: number = FIRST_ACTIVATION_TIMER_DURATION; // 7 min = 420 sec
  secondActivationTimerTimeLeft: number = SECOND_ACTIVATION_TIMER_DURATION; // 7 min = 420 sec
  waitForTransChangeTimerTimeLeft: number = ACTIVATION_TRANSMISSION_TIMER_DURATION;
  waitForPhysicalInstallationTimerTimeLeft: number = PHYSICAL_TRANSMISSION_TIMER_DURATION; // 11 min = 220 sec * 3 sec each interval
  maxAllowedBinsInCluster: number = MAX_ALLOWED_BINS_IN_CLUSTER;
  firstActivationTimerInterval;
  secondActivationTimerInterval;
  waitForTransChangeTimerInterval;
  waitForInstallationCompleteInterval;
  waitForPhysicalInstallationTimerInterval;
  waitForInstallComplete;
  activationTimeOutAttempt: number = 0;
  origTransId: number = 0;
  currentTransId: number = 0;
  isActive: boolean = false;
  isPhysicalInstalled: boolean = false;
  isTranssmitedFromInsideTheBin: boolean = false;
  isErrorToDisplay: boolean = false;
  isDisplayProgressBar: boolean = false;
  isInstallationComplete: boolean = false;
  isIgnoreTrans: boolean = true;
  binLastTransIdPhysicalCounter: number = 0;
  lstClusters: object;
  clustersToDisplay: any = [];
  lstWasteTypes: any;
  wasteTypesToDisplay: any = [];
  assignedBinId: number = 0;
  dialogRef;
  cancelInstallationDialogRef;
  cancelSwapErrorDialogRef;
  qrElement;
  finalReportColumns: string[] = ['time', 'capacity', 'temperature', 'humidity', 'signalQuality', 'battery', 'version'];
  columnsToDisplay = ['time', 'capacity'];
  activationErrorsToDisplay: string[] = [];
  dataSource;
  err: E_Error_Tracer_Sensors_Zone = E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_ACC_COM;
  binPostObj:object={
    site_id: 0,
    device_id: 1,
    bin_name: 'a',
    bin_description: 'a',
    bin_max_capacity: 0,
    bin_max_capacity_liters: 0,
    bin_min_capacity: 0,
    bin_width: 0,
    bin_height: 0,
    bin_waste_type: 'a',
    cluster_id: 1,
    bin_address: 'a',
    bin_lat: 1,
    bin_lon: 1,
    bin_time_zone_offset: 1,
    bin_time_zone_next_dst_change: 1,
    bin_religion_id: 0,
    bin_creation_date: 0
  };
  binInstallObj: object = { };

  binSwapObj: object = {
    new_device_id: 0,
    associated_bin: 0
  }

  binSwapPostObj : object = {};
  public binFilterCtrl: FormControl = new FormControl();
  public filteredBins: ReplaySubject<object> = new ReplaySubject<object>(1);

  isNewBinAssigned: boolean = false;
  @ViewChild('stepper',{static: false}) stepper: MatStepper;
  @ViewChild('formDirective',{static: false}) private formDirective: NgForm;
  @ViewChild('swapFormDir',{static: false}) private swapFormDir: NgForm;
  isMobile: Boolean;
  spinnerExist : boolean = false;
  sitesListData: any[];
  siteName:string = "";
  currentLang: string;
  translateSub;
  translationsObj;
  message:string;
  public show:boolean = false;
  routeParamsSub;
  siteId: any;
  ticketId: any;
  isTicketId:boolean = false;
  binInfoObject: DataBin;
  binGmtTimeZoneOffset: number;
  private readonly destroy$ = new Subject();
  currentDeviceState: number = 0;

  @ViewChild('qrFormField', {static: false, read: ElementRef }) qrFormField: ElementRef;
  installationAccessories;

  // QR Functions
  receiveMessage($event) {
    this.message = $event;
    this.show = false;
    if (!this.isSwap) {
      this.firstFormGroup.controls['deviceId'].setValue(this.message);
    }
    else {
      this.swapFormGroup.controls['newDeviceId'].setValue(this.message);
    }
  }

  recieveLocationMsg($event) {
    this.isValidLocation = $event.is_valid_location;
    if (!this.isValidLocation) {
      this.secondFormGroup.controls['locationValidation'].setErrors({invalid: true});
    }
    else {
      this.secondFormGroup.controls['locationValidation'].setValue(1);
    }
    this.binPostObj["bin_lat"] = $event.lat;
    this.binPostObj["bin_lon"] = $event.lng;
    this.binPostObj["bin_time_zone_offset"] = $event.time_zone_offset;
    this.binPostObj["bin_time_zone_next_dst_change"] = $event.time_zone_next_dst_change;
  }

  onClickQr() {
    this.show = !this.show;
  }

  
  recieveAddress($event) {
    this.secondFormGroup.controls['binAddress'].setValue($event);
  }

  onDeviceIdChanges(): void {
    this.subscribeDeviceIdChange();

    if (!this.isSwap) {
      this.firstFormGroup.controls['deviceId'].valueChanges.subscribe(val => {
        if (!val) return;

        this.isInitForms = false;
        this.apiService.getDeviceIdState(val);
      });
    }
    else {
      this.swapFormGroup.controls['newDeviceId'].valueChanges.subscribe(val => {
        if (!val) return;
  
        this.isInitForms = false;
        this.apiService.getDeviceIdState(val);
      });
    }
  }

  subscribeDeviceIdChange() {
    this.apiQuery.deviceIdStatus$.subscribe((deviceIdState) => {
      if (deviceIdState === null) return;

      this.currentDeviceState = deviceIdState;
      if (this.qrElement) {
        if (this.currentDeviceState === 0) {
          if (!this.isSwap) {
            this.firstFormGroup.controls['deviceId'].setErrors(null);
            this.firstFormGroup.updateValueAndValidity();
          }
          else {
            this.swapFormGroup.controls['newDeviceId'].setErrors(null);
            this.swapFormGroup.updateValueAndValidity();
          }
        }
        else if (!this.isSwap) {
          this.qrElement.style.display = 'block';
          this.firstFormGroup.controls['deviceId'].setErrors({deviceIdState: deviceIdState});

          this.firstFormGroup.updateValueAndValidity();
        }
        else {
          this.qrElement.style.display = 'block';
          this.swapFormGroup.controls['newDeviceId'].setErrors({deviceIdState: deviceIdState});
          this.swapFormGroup.updateValueAndValidity();
        }
      }
      if(this.currentDeviceState == 0) {
        if(this.isSwap){
          this.swapFormGroup.controls['newDeviceId'].setErrors(null);
          this.swapFormGroup.updateValueAndValidity();
        }
      }else{
        if(this.isSwap){
          this.swapFormGroup.controls['newDeviceId'].setErrors({deviceIdState: deviceIdState});
          this.swapFormGroup.updateValueAndValidity();
        }
      }
    });
  }

  subscribeBinFilterCtrlChange() {
    this.binFilterCtrl.valueChanges.subscribe(val => {
      if (val !== "") {
        this.filterBins();
      }
      else {
        this.clustersToDisplay = this.lstClusters;
      }
    });
  }

  protected filterBins() {
    if (!this.lstClusters) {
      return;
    }
    let search = this.binFilterCtrl.value;
    this.searchBin(search);

  }

  public validateDeviceId(c: FormControl) {
    if (c.pristine) return null;

    return this.currentDeviceState === 0 ? null : {
      validateDeviceId : {
        valid: false
      }
    };

  }

  
  public searchBin = (filterValue: string) => {
    let backupList = this.lstClusters;
    const filter = filterValue.trim().toLowerCase();
		if (filter === "") {
      this.clustersToDisplay = _.cloneDeep(this.lstClusters);
		}
		else {
      this.clustersToDisplay = [];
			_.each(backupList, (item: any) => {
        const str = (item.bin_id + item.bin_name + item.bin_address).toLowerCase();
				if (_.includes(str, filter)) {
					this.clustersToDisplay.push(item);
				}
      });
		}
  }

  onSwapFormChanges(): void {
    this.subscribeDeviceIdChange();
  }

  onBinSelectionChangeSubscribe() {
    this.firstFormGroup.controls['binClusterId'].valueChanges.subscribe(val => {
      this.binSelectedCluster = <BinCluster>val;
    }); 
  }



  onFirstFormGroupSubmit() {

    if (this.firstFormGroup.invalid) return;

    this.binCreationDate = moment().format('YYYY-MM-DD HH:mm:ss');
    this.installationPhase = API.InstallationPhase.PENDING_REVIEW;
    this.isFirstFormGroupSubmitted = true;

    if (!this.firstFormGroup.controls['binMaxLitersCapacityCtrl'].value) this.firstFormGroup.controls['binMaxLitersCapacityCtrl'].setValue(0);
    if (!this.firstFormGroup.controls['binWidthCtrl'].value) this.firstFormGroup.controls['binWidthCtrl'].setValue(0);
    if (!this.firstFormGroup.controls['binHeighthCtrl'].value) this.firstFormGroup.controls['binHeighthCtrl'].setValue(0);


    if (this.binSelectedCluster.cluster_id == -1) {
      this.apiService.getNextClusterIdInDb();

      this.apiQuery.nextClusterIdInDb$.subscribe((nextClusterIdInDb) => {
        if (!nextClusterIdInDb) return;

        this.apiService.putNewBinClusterId(this.binSelectedCluster.bin_id, nextClusterIdInDb);

        this.binClusterId = nextClusterIdInDb;

        this.updatebinInstallObj();
        this.lookForNewTranssmistion();
        
      });

    }
    else {
      this.binClusterId = this.binSelectedCluster.cluster_id;
      
      this.updatebinInstallObj();
      this.lookForNewTranssmistion();
    }

    

  }

  updatebinInstallObj() {
    this.binPostObj["site_id"]                 = this.siteId;
    this.binPostObj["device_id"]               = this.firstFormGroup.controls['deviceId'].value;
    this.binPostObj["bin_name"]                = this.firstFormGroup.controls['binName'].value;
    this.binPostObj["bin_description"]         = this.firstFormGroup.controls['binDescription'].value;
    this.binPostObj["bin_max_capacity"]        = this.firstFormGroup.controls['binMaxCapacity'].value;
    this.binPostObj["bin_min_capacity"]        = this.firstFormGroup.controls['binMinCapacity'].value;
    this.binPostObj["bin_waste_type"]          = this.firstFormGroup.controls['wasteTypeListCtrl'].value;
    this.binPostObj["cluster_id"]              = this.binClusterId;
    this.binPostObj["bin_creation_date"]       = this.binCreationDate;
    this.binPostObj["bin_width"]               = this.firstFormGroup.controls['binWidthCtrl'].value;
    this.binPostObj["bin_height"]              = this.firstFormGroup.controls['binHeighthCtrl'].value;
    this.binPostObj["bin_max_capacity_liters"] = this.firstFormGroup.controls['binMaxLitersCapacityCtrl'].value;

    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.SITE_NAME] = this.siteName;
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.DEVICE_ID] = this.firstFormGroup.controls['deviceId'].value;
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_NAME] = this.firstFormGroup.controls['binName'].value;
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_DESCRIPTION] = this.firstFormGroup.controls['binDescription'].value;
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_MAX_CAP] = this.firstFormGroup.controls['binMaxCapacity'].value;
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_MIN_CAP] = this.firstFormGroup.controls['binMinCapacity'].value;

    _.each(this.lstWasteTypes, (item: any) => {
      if(item.waste_type_id == this.firstFormGroup.controls['wasteTypeListCtrl'].value){
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_MATERIAL_TYPE] = item.waste_type_name;
      }
    });    
   
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.CLUSTER] = this.firstFormGroup.controls['binClusterId'].value.bin_name == '' ? 'New' : this.firstFormGroup.controls['binClusterId'].value.bin_name;
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_WIDTH] = this.firstFormGroup.controls['binWidthCtrl'].value;
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_HEIGHT] = this.firstFormGroup.controls['binHeighthCtrl'].value;
    this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_MAX_CAP_L] = this.firstFormGroup.controls['binMaxLitersCapacityCtrl'].value;
  }

  onSecondFormGroupSubmit() {

    if (!this.secondFormGroup.invalid) {
      this.isSecondFormGroupSubmitted = true;

      this.binPostObj["bin_address"] = this.secondFormGroup.controls['binAddress'].value;
      this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_ADDRESS] = this.secondFormGroup.controls['binAddress'].value;
      this.openDialog(false);
    }
    else {
      return;
    }
  }

  onSwapFormGroupSubmit() {
    this.isSwapFormGroupSubmitted = true;
    this.binCreationDate = moment().format('YYYY-MM-DD HH:mm:ss');
    this.installationPhase = API.InstallationPhase.PENDING_REVIEW;
    if (this.swapFormGroup.invalid) return;

    this.binSwapObj["new_device_id"] = this.swapFormGroup.controls['newDeviceId'].value;
    this.binSwapObj["associated_bin"] = this.swapFormGroup.controls['oldBinId'].value.bin_id;
    this.binSwapObj["new_device_installation_accessory_id"] = this.swapFormGroup.controls['binInsAcc'].value;
    this.binSwapPostObj[this.translationsObj.SITE_MANAGMENT.NEW_DEVICE_ID] = this.swapFormGroup.controls['newDeviceId'].value;
    this.binSwapPostObj[this.translationsObj.SITE_MANAGMENT.BIN_NAME] = this.swapFormGroup.controls['oldBinId'].value.bin_name;

    if (!this.isFastInstallation) {
      this.openActivationValidationDialog(this.binSwapObj["associated_bin"]);
    }
    else {
      this.approveFastInstallation();
      this.lookForNewTranssmistion();
    }
  }

  fingerDivStateStart(event) {
  }

  fingerDivStateEnd(event) {
    if (event.fromState != 'void') {
      if (event.toState == 'done') {
        this.showTimer = true;
        this.startTimer();
      }
      else {
        this.toggleAnimation();
      }
    }
  }

  toggleAnimation() {
    this.fingerDivState == 'normal' ? this.fingerDivState = 'done' : this.fingerDivState = 'normal';
    this.sensorDivState == 'normal' ? this.sensorDivState = 'done' : this.sensorDivState = 'normal';
  }

  resetAnimation() {
    if (this.fingerDivState != 'normal') { 
      this.fingerDivState == 'normal';
    }
    if (this.sensorDivState != 'normal') {
      this.sensorDivState = 'normal';
    }
  }

  onCountDownStart() {
  }

  onCountDownFinished() {
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.showTimer = false;
        this.pauseTimer();
        this.timeLeft = 5;
        setTimeout(() => {
          if(!this.isDisplayProgressBar) this.isDisplayProgressBar = true;
          this.toggleAnimation();
        }, 0);
      }
    },1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  startFirstActivationTimer() {
    this.firstActivationTimerInterval = setInterval(() => {
      if(this.firstActivationTimerTimeLeft > 0) {
        this.firstActivationTimerTimeLeft--;
      } else {
        this.pauseFirstActivationTimer(true);
        this.firstActivationTimerTimeLeft = 5;
        setTimeout(() => {
          this.startSecondActivationTimer();
        }, 0);
      }
    },1000)
  }

  startSecondActivationTimer() {
    this.secondActivationTimerInterval = setInterval(() => {
      if(this.secondActivationTimerTimeLeft > 0) {
        this.secondActivationTimerTimeLeft--;
      } else {
        this.pauseSecondActivationTimer(true);
        this.firstActivationTimerTimeLeft = 5;
        setTimeout(() => {
        }, 0);
      }
    },1000)
  }

  pauseFirstActivationTimer(isOpenDialog: boolean) {
    clearInterval(this.firstActivationTimerInterval);
    this.firstActivationTimerTimeLeft = FIRST_ACTIVATION_TIMER_DURATION;
    this.activationTimeOutAttempt++;
    if (isOpenDialog) this.openActivationTimeOutDialog();
  }

  pauseSecondActivationTimer(isOpenDialog: boolean) {
    clearInterval(this.secondActivationTimerInterval);
    this.secondActivationTimerTimeLeft = SECOND_ACTIVATION_TIMER_DURATION;
    this.activationTimeOutAttempt++;
    if (isOpenDialog) this.openActivationTimeOutDialog();
  }

  goBack(){
    this.stepper.ngAfterViewInit();
    this.stepper.previous();
  }

  resetStepper() {
    this.stepper.reset();
  }

  resetForm(form: FormGroup) {

  form.reset();

  Object.keys(form.controls).forEach(key => {
    form.get(key).markAsUntouched();
    form.get(key).markAsPristine();
    form.get(key).setErrors(null);
  });

    form.markAsUntouched();
    form.markAsPristine();
  }


  handleNewBinTransmisstion(binLastTransId) {
    let isRealNewTrans = false;

    if (binLastTransId > this.currentTransId) {
      this.origTransId = this.currentTransId;
      this.currentTransId = binLastTransId;
      isRealNewTrans = true;
    }

    if ((!this.isIgnoreTrans) && (isRealNewTrans)) {
      switch(this.installationPhase) {
        case API.InstallationPhase.PENDING:
          break;
  
        case API.InstallationPhase.PENDING_REVIEW:
          break;
  
        case API.InstallationPhase.REVIEW_APPROVED:
          break;
  
        case API.InstallationPhase.PENDING_SWAP_ACTIVATION:
          this.installationPhase = API.InstallationPhase.PENDING_ACTIVATION;
          this.clearFinalReport();
          break;
  
        case API.InstallationPhase.PENDING_ACTIVATION:
          this.handlePendingActivation();
          break;
  
        case API.InstallationPhase.ACTIVATED:
          this.clearFinalReport();
          break;
  
        case API.InstallationPhase.PENDING_PHYSICAL_INSTALLATION:
          this.clearFinalReport();
          this.handlePendingPhysicalTrans();
          break;
  
        case API.InstallationPhase.PHYSICALLY_INSTALLED:
          this.handleUpdateReportTrans();
          break;
  
        case API.InstallationPhase.INSTALLATION_COMPLETE:
          break;
  
        default:
      }
    }
  }

  handlePendingActivation() {
    if (this.origTransId !== this.currentTransId) {
      this.isIgnoreTrans = true;
      this.installationPhase = API.InstallationPhase.ACTIVATED;
      this.isActive = true;
      this.isDisplayProgressBar = false;
      this.pauseWaitForNewBinTranssmissionTimer();
      this.resetAnimation();
      this.stepper.next();
      this.pauseFirstActivationTimer(false);
      this.pauseSecondActivationTimer(false);
      this.getTransErrors();
    }
  }

  waitForNewBinTranssmission() {
    this.setInstallationStatusActive(true);
    if (!this.isSwap) {
      this.installationPhase = API.InstallationPhase.PENDING_ACTIVATION;
    }
    else {
      this.installationPhase = API.InstallationPhase.PENDING_ACTIVATION;
    }

    this.waitForTransChangeTimerInterval = setInterval(() => {
      if(this.waitForTransChangeTimerTimeLeft > 0) {
        this.waitForTransChangeTimerTimeLeft--;
        if (this.waitForTransChangeTimerTimeLeft === (ACTIVATION_TRANSMISSION_TIMER_DURATION - 2)) {
          this.isIgnoreTrans = false;
        }
      } else {
        this.pauseWaitForNewBinTranssmissionTimer();
        setTimeout(() => {
        }, 0);
      }
    },3000)
  }

  lookForNewTranssmistion() {
    this.waitForInstallationCompleteInterval = setInterval(() => {
      if(this.installationPhase !== API.InstallationPhase.INSTALLATION_COMPLETE) {
        this.apiService.getBinLastTransId(this.assignedBinId);
      } else {
        this.pauseWaitForInstallationCompleteTimer();
        setTimeout(() => {
        }, 0);
      }
    },3000)
  }

  getTransErrors() {
    this.activationErrorsToDisplay = [];
    this.isErrorToDisplay = false;

    this.apiService.getTransErrorTrace(this.assignedBinId, this.currentTransId);

    this.apiQuery.transErrorTrace$.subscribe((transErrorTrace) => {
      if (transErrorTrace === null) return;

      if (transErrorTrace != 0) {
        for (let key in transErrorTrace) {
          let errorZone: E_Error_Tracer_Zones = <E_Error_Tracer_Zones>transErrorTrace[key].err_row_index;
          let errorCode: E_Error_Tracer_Sensors_Zone = <E_Error_Tracer_Sensors_Zone>transErrorTrace[key].err_column_index;
          let errorValue = transErrorTrace[key].err_value;

          if (errorZone == E_Error_Tracer_Zones.E_ERROR_TRACER_ZONE_SENSORS) {
            if (errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_ACC_COM || errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_ACC_NO_RESPONSE) {
              if (this.activationErrorsToDisplay.length > 0) {
                if (_.includes(this.activationErrorsToDisplay, this.translationsObj.SITE_MANAGMENT.ACC_ERROR)) {
                  return;
                }
              }

              if (errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_ACC_NO_RESPONSE && errorValue <= 1) {
                return;
              }

              this.isErrorToDisplay = true;
              this.activationErrorsToDisplay.push(this.translationsObj.SITE_MANAGMENT.ACC_ERROR);
            }
            else if (errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_TEMP_COM) {
              if (this.activationErrorsToDisplay.length > 0) {
                if (_.includes(this.activationErrorsToDisplay, this.translationsObj.SITE_MANAGMENT.TH_ERROR)) {
                  return;
                }
              }

              if (errorValue == 1) {
                this.isErrorToDisplay = true;
                this.activationErrorsToDisplay.push(this.translationsObj.SITE_MANAGMENT.TH_ERROR);
              }
            }
            else if (errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_COM || errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_MEASURE || errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_NO_RES || errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_DISTANCE_CALC_SOURCE_PGA || errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_EEPROM_RESET) {
              if (this.activationErrorsToDisplay.length > 0) {
                if (_.includes(this.activationErrorsToDisplay, this.translationsObj.SITE_MANAGMENT.ULTRA_ERROR)) {
                  return;
                }
              }

              if (errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_MEASURE && this.installationPhase < API.InstallationPhase.PHYSICALLY_INSTALLED) {
                return;
              }

              if (errorCode == E_Error_Tracer_Sensors_Zone.E_ERROR_TRACER_ZONE_SENSORS_US_EEPROM_RESET && errorValue <= 1) {
                return;
              }

              this.isErrorToDisplay = true;
              this.activationErrorsToDisplay.push(this.translationsObj.SITE_MANAGMENT.ULTRA_ERROR);
            }
          }
        }
      }
    });
  }

  waitForPhysicalInstallationTransmission() {
    this.installationPhase = API.InstallationPhase.PENDING_PHYSICAL_INSTALLATION;
    this.isDisplayProgressBar = true;

    this.isIgnoreTrans = false;

    this.waitForPhysicalInstallationTimerInterval = setInterval(() => {
      if(this.waitForPhysicalInstallationTimerTimeLeft > 0) {
        this.waitForPhysicalInstallationTimerTimeLeft--;
      } else {
        this.pauseWaitForPhysicalInstallationTranssmissionTimer(true);
      }
    },1000)
  }

  onInstallationReviewClickBack() {
    this.installationPhase = API.InstallationPhase.ACTIVATED;
    this.isTranssmitedFromInsideTheBin = false;
    this.pauseWaitForPhysicalInstallationTranssmissionTimer(false);
    this.clearFinalReport();
  }

  onAdvancedOptionClicked() {
    this.isShowAdvanced = true;
  }

  onHideAdvancedOptionClicked() {
    this.isShowAdvanced = false;
  }

  clearFinalReport() {
    finalReportData.forEach(function (element, index, array) {
      finalReportData.pop();
    });

    this.dataSource = finalReportData;
    this.spinnerExist = true;
  }

  handlePendingPhysicalTrans() {
    if ((this.origTransId !== this.currentTransId) && (this.origTransId != 0)) {
      this.installationPhase = API.InstallationPhase.PHYSICALLY_INSTALLED;
      this.pauseWaitForNewBinTranssmissionTimer();
      this.isTranssmitedFromInsideTheBin = true;
      this.updateFinalReportData();
      this.apiService.getTransErrorTrace(this.assignedBinId, this.currentTransId);
      this.pauseWaitForPhysicalInstallationTranssmissionTimer(false);
    }
  }

  handleUpdateReportTrans() {
    if (this.origTransId !== this.currentTransId) {
      this.isActive = true, this.resetAnimation();
      this.stepper.next();
      this.pauseFirstActivationTimer(false);
      this.pauseSecondActivationTimer(false);
      this.apiService.getTransErrorTrace(this.assignedBinId, this.currentTransId);
      this.isDisplayProgressBar = false;
      this.handleNewTransmisstion();
    }
  }

  pauseWaitForNewBinTranssmissionTimer() {
    clearInterval(this.waitForTransChangeTimerInterval);
    this.waitForTransChangeTimerTimeLeft = ACTIVATION_TRANSMISSION_TIMER_DURATION;
    
  }

  pauseWaitForInstallationCompleteTimer() {
    clearInterval(this.waitForInstallationCompleteInterval);
  }

  pauseWaitForPhysicalInstallationTranssmissionTimer(isOpenDialog: boolean) {
    clearInterval(this.waitForPhysicalInstallationTimerInterval);
    this.waitForPhysicalInstallationTimerTimeLeft = PHYSICAL_TRANSMISSION_TIMER_DURATION;
    if (isOpenDialog) this.openActivationTimeOutDialog();
  }

  updateFinalReportData() {
    this.handleNewTransmisstion();

    this.waitForTransChangeTimerInterval = setInterval(() => {
      if(this.waitForTransChangeTimerTimeLeft > 0) {
        this.waitForTransChangeTimerTimeLeft--;
      } else {
        this.pauseWaitForNewBinTranssmissionTimer();
        setTimeout(() => {
          // Reset timer for addtional 5 min before canceling this installtion
        }, 0);
      }
    },3000)
}

  handleNewTransmisstion() {
    this.apiService.getBinInfo(this.assignedBinId);
    
    this.apiQuery.binInfo$.subscribe((binInfo) => {
      if (!binInfo) return;

      this.binInfoObject = <DataBin>binInfo;
      this.binGmtTimeZoneOffset = this.binInfoObject.Bin_location.bin_gmt_time_zone;


      if ((this.installationPhase === API.InstallationPhase.PHYSICALLY_INSTALLED) && (this.binInfoObject.bin_id == this.assignedBinId) && (this.binInfoObject.Bin_live_Data.Transmission_data.last_transmission_time !== null) && (this.binInfoObject.Bin_live_Data.Transmission_data.last_transmission_id === this.currentTransId)) {

        let newRow: IFinalReport = {
          time: this.binInfoObject.Bin_live_Data.fill_level_time,
          capacity: this.binInfoObject.Bin_live_Data.fill_level_unfiltered,
          capacityPrecent: this.binInfoObject.Bin_live_Data.fill_level_percent_unfiltered,
          temperature: this.binInfoObject.Bin_live_Data.temperature,
          humidity: this.binInfoObject.Bin_live_Data.humidity,
          signalQuality: this.binInfoObject.Bin_live_Data.Transmission_data.signal_quality,
          battery: this.binInfoObject.Bin_live_Data.battery_level,
          version: this.binInfoObject.software_version
        }

        finalReportData.push(newRow);
        this.dataSource = new ExampleDataSource();
        this.spinnerExist = true;
      }
    }); 
  }

  completeInstallation() {
    this.isIgnoreTrans = true;
    this.setInstallationStatusActive(false);
    this.installationPhase = API.InstallationPhase.INSTALLATION_COMPLETE;


    this.apiQuery.installationStatusResponse$.subscribe((installationStatusResponse) => {
      if (installationStatusResponse === undefined) return;
      if (installationStatusResponse != 0) {
        this.openDialog(true);

        let request = {
          "bin_id" : this.assignedBinId,
          "start_time" : this.isSwap && !this.isFastInstallation ? moment(this.binCreationDate).format('YYYY-MM-DD HH:mm:ss') : moment().startOf('day').subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
          "end_time" : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          "time_zone" : this.binGmtTimeZoneOffset == null ? 2 : this.binGmtTimeZoneOffset,
          "is_ignore_time_limits" : this.isSwap ? 0 : 1
        }

        this.apiService.cleanBinTrans(request).subscribe((clearBinTransResonse:any) => {
          if (!clearBinTransResonse) return;

          this.isInstallationComplete = true;
          if(!this.isTicketId){
            this.goToPendingInstallationBinsReport();
          }else{
            this.goToTicketManagement();
          }
        });

      }
    })
  }

  cancelInstallation() {
    this.isInstallationCancled = true;
    this.isTranssmitedFromInsideTheBin = false;

    if (!this.isSwap) {
      this.apiService.performBinReset(this.assignedBinId);

      this.apiQuery.binResetResponse$.subscribe((binResetResponse) => {
        if (!binResetResponse) return;

        this.installationPhase = API.InstallationPhase.PENDING;
      });
    }
    else {
      if (this.isFastInstallation) {
        this.apiService.performRestoreSwapDevices(this.assignedBinId, 1);  
      }
      else {
        this.apiService.performRestoreSwapDevices(this.assignedBinId, 0);
      }

      this.installationPhase = API.InstallationPhase.PENDING;
    }

    this.initPage(false);
  }

  approveFastInstallation() {
    this.installationPhase = API.InstallationPhase.REVIEW_APPROVED;

    this.assignedBinId = <number>this.swapFormGroup.controls['oldBinId'].value.bin_id;
    this.isNewBinAssigned = true;

    let request = {
      bin_id: this.binSwapObj["associated_bin"],
      new_device_id: this.binSwapObj["new_device_id"],
      is_fast_installation: 1,
      new_device_installation_accessory_id: this.binSwapObj["new_device_installation_accessory_id"]                       
    }
    this.apiService.swapBinDevices(request).subscribe((performSwapDevicesResponse:any) => {      
      if (!performSwapDevicesResponse) return;
  
      if (performSwapDevicesResponse !== 1) {
        this.openCancelSwapErrorDialog();
      }
    });

    this.apiService.getBinInfo(this.assignedBinId);

    this.toggleAnimation();
    this.waitForNewBinTranssmission();

    this.startFirstActivationTimer();
  }

  initPage(isOnDestroy: boolean) {
    
    this.pauseWaitForNewBinTranssmissionTimer();
    this.pauseFirstActivationTimer(false);
    this.pauseSecondActivationTimer(false);
    this.pauseTimer();
    this.initParams();
    this.clearFinalReport();
    this.isTranssmitedFromInsideTheBin = false;
  }


  initParams() {
    this.resetStepper();
    this.activationTimeOutAttempt = 0;
    this.isNewBinAssigned = false;
    this.isFirstFormGroupSubmitted = false;
    this.isSecondFormGroupSubmitted = false;
    this.isThirdFormGroupSubmitted = false;
    this.isSwapFormGroupSubmitted = false;
    this.isInstallationCancled = false;
    this.timeLeft = 5;
    this.showTimer = false;
    this.firstActivationTimerTimeLeft = FIRST_ACTIVATION_TIMER_DURATION; // 5 min = 300 sec
    this.secondActivationTimerTimeLeft = SECOND_ACTIVATION_TIMER_DURATION; // 5 min = 300 sec
    this.waitForTransChangeTimerTimeLeft = ACTIVATION_TRANSMISSION_TIMER_DURATION;
    this.waitForPhysicalInstallationTimerTimeLeft = PHYSICAL_TRANSMISSION_TIMER_DURATION // 11 min = 220 sec * 3 sec each interval
    this.activationTimeOutAttempt = 0;
    this.origTransId = 0;
    this.currentTransId = 0;
    this.isActive = false;
    this.isPhysicalInstalled = false;
    this.isTranssmitedFromInsideTheBin = false;
    this.isErrorToDisplay = false;
    this.isDisplayProgressBar = false;
    this.isInstallationComplete = false;
    this.isIgnoreTrans = true;
    this.associatedBinId = null;
    this.clustersToDisplay = [];

    this.initForms();
  }

  initForms() {
    if (!this.isSwap) {
      this.firstFormGroup.controls['deviceId'].setValidators([Validators.required, Validators.minLength(10), Validators.min(0), this.validateDeviceId.bind(this)]);
      this.resetForm(this.firstFormGroup);
      this.firstFormGroup.controls['binClusterId'].setValue(this.clustersToDisplay[0]);
      
      this.apiService.getSiteClustersList(this.siteId);
      this.apiService.getWasteTypesList();

      this.resetForm(this.secondFormGroup);
      this.secondFormGroup.controls['binAddress'].setValue("");
    }
    else {
      this.resetForm(this.swapFormGroup);
      this.swapFormGroup.controls['newDeviceId'].setValidators([Validators.required, Validators.minLength(10), Validators.min(0), this.validateDeviceId.bind(this)]);
      this.apiService.getSiteClustersList(this.siteId);

      this.swapFormDir.resetForm();
    }

    this.isInitForms = true;
  }
  
  setInstallationStatusActive(isActiveFast: boolean) {
    if (isActiveFast) {
      this.apiService.setInstallationStatus(this.assignedBinId, 0);
    }
    else {
      this.apiService.setInstallationStatus(this.assignedBinId, 1);
    }
  }
  



  openDialog(isInstallationCompleted: boolean): void {
    let dataObj = this.binInstallObj;
    let outSideClosedAllowed: boolean = false;

    if (this.isSwap) dataObj = this.binSwapPostObj;
    if (isInstallationCompleted) outSideClosedAllowed = true;

    let dialogData: DialogData = {
      data: dataObj,
      isSwap: this.isSwap,
      isFastInstallation: this.isFastInstallation,
      isInstallationCompleted: isInstallationCompleted,
      binId: this.assignedBinId
    };
    const dialogRef = this.dialog.open(InstallNewBinDialogComponent, {
      data: dialogData,
      width: '600px',
      disableClose: outSideClosedAllowed
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.installationPhase = API.InstallationPhase.REVIEW_APPROVED;

        if (!this.isSwap) {
          this.insertNewBin(this.binPostObj);
        }
        else {
          this.assignedBinId = <number>this.swapFormGroup.controls['oldBinId'].value.bin_id;
          this.isNewBinAssigned = true;
          let request = {
            bin_id: this.binSwapObj["associated_bin"],
            new_device_id: this.binSwapObj["new_device_id"],
            is_fast_installation: 0,
            new_device_installation_accessory_id: this.binSwapObj["new_device_installation_accessory_id"]                       
          }
          this.apiService.swapBinDevices(request).subscribe((performSwapDevicesResponse:any) => {            
            if (!performSwapDevicesResponse) return;
        
            if (performSwapDevicesResponse !== 1) {
              this.openCancelSwapErrorDialog();
            }
          });
          
          this.apiService.getBinInfo(this.assignedBinId);

          this.toggleAnimation();
          this.waitForNewBinTranssmission();
        }

        this.startFirstActivationTimer();
      }
      else {
        if (!isInstallationCompleted) {
          this.apiService.getSiteClustersList(this.siteId);
          this.goBack();
          if (this.isSwap) {
            this.swapFormGroup.controls['newDeviceId'].reset();
            this.swapFormGroup.controls['oldBinId'].reset();
            this.swapFormGroup.controls['binInsAcc'].reset();
          }
        }
      }
    });
  }

  async insertNewBin(obj){
    let response;
    response = await this.apiService.insertNewbin(obj);
    if(response){
      this.isNewBinAssigned = true;
      this.assignedBinId = Number(response.new_assigned_bin_id);
      this.toggleAnimation();
      this.waitForNewBinTranssmission();
    }
  }


  openActivationTimeOutDialog(): void {
    if (this.dialogRef != undefined) {
      this.dialogRef.close();
    }

    let timeOutObj: API.ITimOutObj = {
      activationTimeOutAttempt: this.activationTimeOutAttempt,
      installationPhase: this.installationPhase
    }

    this.dialogRef = this.dialog.open(ActivationTimeOutDialogComponent, {
      data: timeOutObj,
      width: '600px',
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result === false) {
        this.openCancelInstallationDialog();
      }
      else if (result === true) {
      }
      else if (result !== undefined) {
        this.cancelInstallation();
      }
    });
  }

  openCancelInstallationDialog(): void {
    if (this.cancelInstallationDialogRef != null) {
      this.cancelInstallationDialogRef.close();
    } 
      this.cancelInstallationDialogRef = this.dialog.open(CancelInstallationDialogComponent, { 
        width: '600px',
        disableClose: true
      });

    this.cancelInstallationDialogRef.afterClosed().subscribe(result => {
      if (result !== true) {
        this.cancelInstallation();
      }
    });
  }


  openCancelSwapErrorDialog(): void {

    this.cancelSwapErrorDialogRef = this.dialog.open(CancelSwapErrorDialogComponent, { 
      width: '600px',
      disableClose: true
    });

    this.cancelSwapErrorDialogRef.afterClosed().subscribe(result => {
      if (result !== true) {
        this.cancelInstallation();
      }
    });
  }

  openActivationValidationDialog(binId: any): void {
    const dialogRef = this.dialog.open(ActivationValidationDialogComponent, {
      data: binId,
      width: '600px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openDialog(false);
        this.lookForNewTranssmistion();
      }
      else {
        this.initPage(false);
      }
    });
  }

  // convenience getter for easy access to form fields
  get f1() { 
    return this.firstFormGroup.controls; 
  }
  
  get f2() { 
    return this.secondFormGroup.controls; 
  }

  get fSwap() {
    return this.swapFormGroup.controls;
  }

  constructor(private _formBuilder: FormBuilder,private apiQuery:ApiQuery,
      private router: Router, private route: ActivatedRoute,private apiService:ApiService,
      private dialog: MatDialog,private responsiveService: ResponsiveService,private apiStore:ApiStore,
      private translator: TranslatorService) {
      this.currentLang = this.translator.getTranslationLanguage();
      this.translateSub = this.translator.getTranslation(this.currentLang).subscribe(translations => {
        this.translationsObj = translations;
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.SITE_NAME] = "";
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.DEVICE_ID] = 1;
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_NAME] = 'a';
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_DESCRIPTION] = 'a';
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_MAX_CAP] = 0;
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_MAX_CAP_L] = 0;
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_MIN_CAP] = 0;
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_WIDTH] = 0;
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_HEIGHT] = 0;
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_MATERIAL_TYPE] = 'a';
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.CLUSTER] = "New";
        this.binInstallObj[this.translationsObj.SITE_MANAGMENT.BIN_ADDRESS] = 'a';
      });

      this.firstFormGroup = this._formBuilder.group({
        binName: new FormControl('', { validators: [Validators.required, Validators.maxLength(20)] }),
        deviceId: new FormControl('', { validators: [Validators.required, Validators.minLength(10), Validators.min(0), this.validateDeviceId.bind(this)]}),
        binDescription: new FormControl('', { validators: [Validators.minLength(4)] }),
        binMaxCapacity: new FormControl('', { validators: [Validators.required, Validators.min(0)] }),
        binMinCapacity: new FormControl('', { validators: [Validators.required, Validators.min(0)] }),
        binClusterId: new FormControl('', Validators.required),
        binWidthCtrl: new FormControl('', Validators.min(0)),
        binHeighthCtrl: new FormControl('', Validators.min(0)),
        binMaxLitersCapacityCtrl: new FormControl('', Validators.min(0)),
        wasteTypeListCtrl: new FormControl('', Validators.required)
      });
      
      this.secondFormGroup = this._formBuilder.group({
        binAddress: new FormControl('', { validators: [Validators.required, Validators.minLength(4)] }),
        locationValidation: new FormControl('', { validators: [Validators.required] })
      });
      
      this.thirdFormGroup = this._formBuilder.group({
        binAddress: new FormControl('', { validators: [Validators.minLength(4)] })
      });
  
      this.swapFormGroup = this._formBuilder.group({
        newDeviceId: new FormControl('', { validators: [Validators.required, Validators.minLength(10), Validators.min(0), this.validateDeviceId.bind(this)]}),
        oldBinId: new FormControl('', Validators.required),
        binInsAcc: new FormControl('', Validators.required)
      });
   }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();

    this.routeParamsSub = this.route.queryParams
    .subscribe(params => {
      this.siteId = this.route.snapshot.paramMap.get("id");
      this.ticketId = this.route.snapshot.paramMap.get("item");
      if(!isNaN(this.ticketId)){  
        this.isTicketId = true;     
        combineLatest(
          this.apiQuery.cRTicketsList$,
          this.apiQuery.insTicketsList$,
          this.apiQuery.rmTicketsList$,
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe(([cRTicketsList,insTicketsList,rmTicketsList])=>{	
            let allTickets = [];
            if(cRTicketsList.length == 0 && insTicketsList.length == 0 && rmTicketsList.length == 0){return;}

            _.each(cRTicketsList, (item) => {					
              if(item["TicketsList"]){
                if(item["TicketsList"]["open"]!=null){			
                  _.each(item["TicketsList"]["open"], (ticket) => {	
                    allTickets.push(ticket);                      	
                  });		
                }	
              }		
            });
    
            _.each(insTicketsList, (item) => {					
              if(item["TicketsList"]){
                if(item["TicketsList"]["open"]!=null){			
                  _.each(item["TicketsList"]["open"], (ticket) => {	
                    allTickets.push(ticket);                      
                  });		
                }								
              }		
            });
    
            _.each(rmTicketsList, (item) => {					
              if(item["TicketsList"]){
                if(item["TicketsList"]["open"]!=null){			
                  _.each(item["TicketsList"]["open"], (ticket) => {	
                    allTickets.push(ticket);                      	
                  });		
                }								
              }		
            });
            const relevantTicket = _.filter(allTickets, (ticket) => {
              return ticket.ticket_id == this.ticketId;
            });
            this.initPageAccordingToTicket(relevantTicket); 
          });
      }else{
        this.isTicketId = false;
        this.initPageAccordingToTicket();     
      }
    });
  }

  initPageAccordingToTicket(ticket?){
    this.apiQuery.sitesListData$.subscribe((sitesList) => {
      if (!sitesList) return;
        this.sitesListData = sitesList;
        _.each(this.sitesListData, (item: any) => {
          if (item.site_id == this.siteId) {
            this.siteName = item.site_name;            
          }
        });
      });

    this.installationPhase = API.InstallationPhase.PENDING;
    this.isSwap ? this.isSwap = true : this.isSwap = false;

    if (!this.isSwap) {

      this.firstFormGroup.controls['binClusterId'].setValue("0"); // 0 means new cluster ID (default)

      this.firstFormGroup.statusChanges.subscribe((result) => {
        if (this.qrElement) {
          if (this.firstFormGroup.controls['deviceId'].pristine || (!this.firstFormGroup.controls['deviceId'].invalid)) {
              this.qrElement.style.display = 'flex';
          }
          else {
            this.qrElement.style.display = 'block';
          }
        }
      });
    }
    else {
      this.swapFormGroup.statusChanges.subscribe((result) => {
        if (this.qrElement) {
          if (this.swapFormGroup.controls['newDeviceId'].pristine || (!this.swapFormGroup.controls['newDeviceId'].invalid)) {
            this.qrElement.style.display = 'flex';
          }
          else {
            this.qrElement.style.display = 'block';
          }
        }
      });
    }
  
    this.apiService.getSiteClustersList(this.siteId);

    this.apiQuery.siteClustersList$.subscribe((siteClustersList) => {
      let clusterBinsArr = [];
      if (!siteClustersList) return;
      if(siteClustersList.ClusterBySiteInfoObj){
        _.each(siteClustersList.ClusterBySiteInfoObj[0].clusters_info, (item: any) => {
          _.each(item.cluster_bins, (bin: any) => {
            bin.numberOfBinsInCluster = item.num_of_bins_in_cluster;
            bin.bin_address = item.cluster_address;
            bin.cluster_id = item.cluster_id;
            clusterBinsArr.push(bin);
          });
        });
      }
      siteClustersList = clusterBinsArr;
      this.clustersToDisplay = [];
      this.lstClusters = siteClustersList;
      this.clustersToDisplay = siteClustersList;
      let clusterForDefaultDisplay = this.clustersToDisplay[0];

      if (this.fastInstallationBinId !== undefined) {
        let that = this;
        let updatedClustersToDisplay = [];
        let fastInstallationBinIdIndexInClustersToDisplay = _.findIndex(that.clustersToDisplay, function(o: any) { return o.bin_id == that.fastInstallationBinId; });
        updatedClustersToDisplay[0] = this.clustersToDisplay[fastInstallationBinIdIndexInClustersToDisplay];
        this.clustersToDisplay = updatedClustersToDisplay;
        clusterForDefaultDisplay = this.clustersToDisplay[0];
      }
      
      if(ticket.length > 0){
        const relevantBin = this.clustersToDisplay.find(cluster => cluster.bin_id == _.groupBy(ticket[0].ticket_id_info, "status_id")["1"][0].bin_id);
        if (!_.isEmpty(relevantBin)){
          this.swapFormGroup.controls['oldBinId'].setValue(relevantBin);
        }else{
          this.swapFormGroup.controls['oldBinId'].setValue(clusterForDefaultDisplay);
        }
      }else{
        this.swapFormGroup.controls['oldBinId'].setValue(clusterForDefaultDisplay);
      }

      let newClusterItem: BinCluster = {
        bin_id: 0,
        bin_name: '',
        bin_address: '',
        cluster_id: 0
      };

      this.clustersToDisplay.unshift(newClusterItem);

      this.firstFormGroup.controls['binClusterId'].setValue(newClusterItem);

      this.binSelectedCluster = newClusterItem;

      this.onBinSelectionChangeSubscribe();
    });


    this.apiQuery.installationAccessoryTypes$.subscribe((v:any) => {	       
      if(v.length == 0 || v == null) {return;}    
      this.installationAccessories = v;          
      this.swapFormGroup.controls['binInsAcc'].setValue(2);
    }); 

    this.apiService.getWasteTypesList();
    this.apiQuery.wasteTypesList$.subscribe((wasteTypesList) => {
      if (wasteTypesList.length == 0) return;
      this.lstWasteTypes = wasteTypesList["WasteType"];
      this.firstFormGroup.controls['wasteTypeListCtrl'].setValue(2);
      
    })

    this.onDeviceIdChanges();

    this.apiQuery.user$.subscribe(user => {
			if (!user) return;
    });


    this.apiQuery.binLastTransId$.subscribe((binLastTransId) => {
      if (binLastTransId === null) return;
      
      if (this.assignedBinId > 0) {
        this.handleNewBinTransmisstion(binLastTransId);
      }
    });

    this.subscribeBinFilterCtrlChange();

  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
  }
  
  goToPendingInstallationBinsReport() {
    //this.router.navigate([`/pendingInstallationBins/${this.siteId}`]);
  }

  goToTicketManagement(){
    let ticketManagementRequest = {};   
		if(sessionStorage.getItem("ticketManagementMinDate") == '' || sessionStorage.getItem("ticketManagementMaxDate") == '' || 
		sessionStorage.getItem("ticketManagementMinDate") == null || sessionStorage.getItem("ticketManagementMaxDate") == null){
			ticketManagementRequest = {
				status_id : -1
			  }
		}else{
			ticketManagementRequest = {
				start_time : sessionStorage.getItem("ticketManagementMinDate"),
				end_time : sessionStorage.getItem("ticketManagementMaxDate")
			  }
		}				

		this.apiStore.update({ allCRTicketsList: [] });
		this.apiStore.update({ cRTicketsList: [] });
		this.apiService.getCRTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allInsTicketsList: [] });
		this.apiStore.update({ insTicketsList: [] });
		this.apiService.getInsTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

		this.apiStore.update({ allRmTicketsList: [] });
		this.apiStore.update({ rmTicketsList: [] });
		this.apiService.getRmTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
    this.router.navigate([`/ticketManagement`]);
  }

  ngAfterViewInit() {
      setTimeout(() => {
        if (this.currentLang == 'iw') {
          if(document.getElementById('onFirst')!=null){
            document.getElementById('onFirst').style.direction = 'ltr';
          }
          if(document.getElementById('swapFormstep')!=null){
            document.getElementById('swapFormstep').style.direction = 'ltr';
          }
          if(document.getElementById('secondForm')!=null){
            document.getElementById('secondForm').style.direction = 'ltr';
          }
          if(document.getElementById('secondFormBack')!=null){
            document.getElementById('secondFormBack').style.cssFloat = 'right';
          }
          if(document.getElementById('complete') != null){
            document.getElementById('complete').style.cssFloat = 'left';
          }
          if(document.getElementById('physicalNext') != null){
            document.getElementById('physicalNext').style.cssFloat = 'left';
          }
          if(document.getElementById('backRep') != null){
            document.getElementById('backRep').style.cssFloat = 'none';
          }
        }
        else{
          if(document.getElementById('onFirst')!=null){
            document.getElementById('onFirst').style.direction = 'rtl';
          }
          if(document.getElementById('swapFormstep')!=null){
            document.getElementById('swapFormstep').style.direction = 'rtl';
          }
          if(document.getElementById('secondForm')!=null){
            document.getElementById('secondForm').style.direction = 'rtl';
          }
          if(document.getElementById('secondFormBack')!=null){
            document.getElementById('secondFormBack').style.cssFloat = 'left';
          }
          if(document.getElementById('complete') != null){
            document.getElementById('complete').style.cssFloat = 'right';
          }
          if(document.getElementById('physicalNext') != null){
            document.getElementById('physicalNext').style.cssFloat = 'right';
          }
          if(document.getElementById('backRep') != null){
            document.getElementById('backRep').style.cssFloat = 'right';
          }
        }
        if (this.qrFormField.nativeElement.querySelector('.mat-form-field-infix')) {
          this.qrElement = this.qrFormField.nativeElement.querySelector('.mat-form-field-infix');
          this.qrElement.style.display = 'flex';
        }
      }, 500);   
    }

  ngOnDestroy() {
    this.initPage(true);
    this.destroy$.next(true);
    this.destroy$.complete();
  }


  refresh(): void {
    window.location.reload();
  }

  onSubmit(event) {
    
  }



}

export class ExampleDataSource extends DataSource<IFinalReport> {
  /** Stream of data that is provided to the table. */
  data = new BehaviorSubject<IFinalReport[]>(finalReportData);

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<IFinalReport[]> {
    return this.data;
  }

  disconnect() {
  }
}

export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface IBin {
  siteId: number;
  deviceId: string;
  binName: string;
  binDescription: string;
  binMaxCapacity: number;
  binMinCapacity: number;
  binClusterId: number;
  binAddress: string;
  binLat: number;
  binLon: number;
  binTimeZoneOffset: number;
  binTimeZoneNextDstChange: number;

}

export interface IFinalReport {
  time: string;
  capacity: number;
  capacityPrecent: number;
  temperature: number;
  humidity: number;
  signalQuality: number;
  battery: number;
  version: string;
}

export interface IGetBinsInfo {
  http_ref:     string;
  resource:     string;
  api_version:  number;
  count:        number;
  dataBins:     DataBin[];
  generated_on: Date;
}

export interface DataBin {
  bin_id:           number;
  sim_id:           string;
  bin_name:         string;
  bin_description:  string;
  cluster_id:       string;
  Bin_location:     BinLocation;
  bin_type_id:      number;
  is_active:        number;
  Bin_dimensions:   BinDimensions;
  Active_features:  ActiveFeatures;
  Bin_thresholds:   { [key: string]: number };
  Bin_live_Data:    BinLiveData;
  Bin_Restrictions: BinRestrictions;
  software_version: string;
}

export interface ActiveFeatures {
  is_fire_alarm_on:    number;
  is_washing_alarm_on: number;
  is_bin_upgrade_on:   number;
}

export interface BinRestrictions {
  restriction_1_start_time: string;
  restriction_1_duration:   number;
  restriction_2_start_time: string;
  restriction_2_duration:   number;
  restriction_3_start_time: string;
  restriction_3_duration:   number;
}

export interface BinDimensions {
  bin_height: number;
  bin_width:  number;
}

export interface BinLiveData {
  fill_level:           number;
  fill_level_unfiltered: number;
  fill_level_percent:   number;
  fill_level_percent_unfiltered:   number;
  fill_level_time:      string;
  color_status_id:      number;
  color_status_name:    string;
  temperature:          number;
  humidity:             number;
  last_collection_time: Date;
  battery_level:        number;
  Transmission_data:    TransmissionData;
}

export interface TransmissionData {
  last_transmission_id:       number;
  signal_quality:             number;
  last_transmission_time:     Date;
  expected_transmission_time: Date;
}

export interface BinLocation {
  bin_latitude:  number;
  bin_longitude: number;
  bin_address:   string;
  bin_gmt_time_zone: number;
}

export interface BinCluster {
  bin_id: number;
  bin_name: string;
  cluster_id: number;
  bin_address: string;
}


export interface DialogData {
  binId: number;
  isInstallationCompleted: boolean;
  isSwap: boolean;
  isFastInstallation: boolean;
  data: object;
}

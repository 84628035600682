@if(!isMobile){
    <div>
        <div class="login-page" rtlDiv>
          <app-languages-menu class="language-pos"></app-languages-menu>
          <div class="right-page">
            <img class='login-img' src="assets/images/login/Sign_in.jpg" alt="" /> 
          </div>
          <div class="left-page" rtlDiv>
            <div class="fields-container">
              <div class="logo">
                <img class="databin-logo" src="assets/images/topmenu/databinlogo.svg" alt=""/>
              </div>
              <div class="input-fields">
                <input type="text" id="user_name" class="email" placeholder="{{ 'LOGIN.EMAIL' | translate }}" [(ngModel)]="username" />
                <input type="password" id="user_password" class="password" placeholder="{{ 'LOGIN.PASSWORD' | translate }}" [(ngModel)]="password" />
                @if(showLoginError){
                    <div class="error-container">
                        <img class='error-icon' src="assets/images/login/error.png" alt="" />             
                        <div class="error" rtlDiv>{{ 'LOGIN.INVALID_EMAIL_PASS' | translate }}</div>
                    </div>
                }
              </div>
              <div class="btns-frame">
                <button (click)="login(username, password)" class="sign-in-btn">{{ 'LOGIN.SIGN_IN' | translate }}</button>
                <span (click)="forgotPassword()" class="forgot-btn">{{ 'LOGIN.FORGOT_PASS' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        @if(showForgotPasswordModal){
            <app-reset-password-modal (showHideResetModal)="openResetModal($event)" (showHideSuccessModal)="openSuccessModal($event)"></app-reset-password-modal>
        }
        @if(showSuccessModal){
            <app-success-modal (showHideSuccessModal)="openSuccessModal($event)" [message]="message"></app-success-modal>
        }
        @if(showConfirmPasswordModal){
            <app-confirm-password (openSuccessModal)="showUpdatePassModal($event)" (showHideConfirmModal)="openConfirmModal($event)" [token]="token" (showHideSuccessModal)="openSuccessModal($event)"></app-confirm-password>
        }
    </div>
}
  
@if(isMobile){
    <div class="mobile-login-page">
        <app-languages-menu></app-languages-menu>
        <div class="mobile-signin-logo">
          <img class="mobile-header-img" src="assets/images/login/mobile-bg.svg" alt="" /> 
        </div>
        <div class="mobile-all-page">
          <div class="mobile-left-page">
            <div class="mobile-fields-container">
              <div class="mobile-logo">
                <img class="mobile-databin-logo" src="assets/images/topmenu/databinlogo.svg" alt=""/>
              </div>
              <div class="mobile-input-fields">
                <input class="mobile-email-login" rtlDiv type="text" placeholder="{{ 'LOGIN.EMAIL' | translate }}" [(ngModel)]="username" />
                <input class="mobile-password-login" rtlDiv type="password" placeholder="{{ 'LOGIN.PASSWORD' | translate }}" [(ngModel)]="password" />
                @if(showLoginError){
                    <div class="mobile-error-container" rtlDiv>
                        <img class='mobile-error-icon' src="assets/images/login/error.png" alt="" /> 
                        <div class="mobile-error-txt" rtlDiv>{{ 'LOGIN.INVALID_EMAIL_PASS' | translate }}</div>
                    </div>
                }
              </div>
              <div class="mobile-btns-frame">
                <a (click)="login(username, password)" class="mobile-signin-button"><span class="signInBtnLbl">{{ 'LOGIN.SIGN_IN' | translate }}</span></a>
                <a (click)="forgotPassword()" class="mobile-signin-forgotPass">{{ 'LOGIN.FORGOT_PASS' | translate }}</a>
              </div>
              @if(showForgotPasswordModal){
                <app-reset-password-modal (showHideResetModal)="openResetModal($event)" (showHideSuccessModal)="openSuccessModal($event)"></app-reset-password-modal>
              }
              @if(showSuccessModal){
                <app-success-modal (showHideSuccessModal)="openSuccessModal($event)" [message]="message"></app-success-modal>
              }
              @if(showConfirmPasswordModal){
                <app-confirm-password (openSuccessModal)="showUpdatePassModal($event)" (showHideConfirmModal)="openConfirmModal($event)" [token]="token" (showHideSuccessModal)="openSuccessModal($event)"></app-confirm-password>    
              }
            </div>
          </div>
          <div class="mobile-markers-logo">
            <img class="mobile-markers-img" src="assets/images/login/mobile-markers-bg.svg" alt="" /> 
          </div>
        </div>
      </div>
}
  
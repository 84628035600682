<form form [formGroup]="mapFormGroup" class="lat-lng-form" [ngClass]="{'notShowFields' : !showFields}">
  <mat-form-field class="lat-lng-field">
    <input matInput placeholder="{{ 'SITE_MANAGMENT.LAT_LNG' | translate }}" formControlName="latLngFormControl">
  </mat-form-field>
  <mat-form-field class="lat-lng-field">
    <input matInput placeholder="{{ 'SITE_MANAGMENT.TIME_ZONE' | translate }}" formControlName="timeZoneFormControl">
  </mat-form-field>
</form>

<!-- this creates a google map on the page with the given lat/lng from -->
<!-- the component as the initial center of the map: -->
@if (isMapReady) {
  <div>
    <div class="map-btns">
      <div class="search-location-wrapper">
        <input ngx-gp-autocomplete placeholder="{{'SITE_MANAGMENT.SEARCH_LOCATION' | translate}}" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>                            
      </div>
    </div>
  </div>
}
<google-map (mapInitialized)="loadAPIWrapper($event)"
  [ngClass]="{'extraDiv' : !showFields}"
  [center]="center"
  [options]="mapOptions">
</google-map>
<div class="location-info-wrapper">
  @if (!isCordFree) {
    <div class="location-taken-error-wrapper">
      {{'SITE_MANAGMENT.ASSIGNED_BIN' | translate}} {{ assignedBinId }}
    </div>
  }
  @if (!isLocationEnabled) {
    <div class="location-error-wrapper">
      Location information is unavailable! - {{ locationErrorMsg }}
      <div class="location-error-small">
        Please drag the marker to correct bin location or use the search box
      </div>
    </div>
  }
</div>
import { Component, Input } from '@angular/core';
import { TranslatorService } from '../../../services/translator_service';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import _ from 'lodash';

import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-refill-kpi',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, MatDividerModule, MatCardModule],
  templateUrl: './refill-kpi.component.html',
  styleUrl: './refill-kpi.component.scss'
})
export class RefillKpiComponent {
  translate;
	isMobile: Boolean;
  translationsObj;
	currentLang;
  datesDiffTxt:string;
  @Input() datesDiff;
  spinnerActive:boolean = true;
  amountOfRefill : number = 0;
  filledBins:number;
  totalBins : number = 0;
  avg:any = 0;
	usageType = 1;
  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,
    private apiQuery:ApiQuery) { 
    this.translate = this.translator;
    this.translator.currentLangEmitter$
    .subscribe(async value=>{						
      this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
      this.currentLang = value;
      if(this.currentLang != 'iw'){
        this.datesDiffTxt = `Fillings KPI`;
      }else{
        this.datesDiffTxt = `מדדי מילויים`;
      }    
    })
  }

  ngOnInit() {
    this.onResize();
		this.responsiveService.checkWidth();
    this.spinnerActive = true;
		this.apiQuery.refillsKpis$.subscribe((data:any) => {         
      if(this.currentLang != 'iw'){
        this.datesDiffTxt = `Fillings KPI`;
      }else{
        this.datesDiffTxt = `מדדי מילויים`;
      }    
      this.datesDiffTxt += " " + this.datesDiff; 
      this.spinnerActive = true;		     
      if(data.length == 0) return;
      this.spinnerActive = false;
      let amountOfRefill = 0;
      let filledBins = 0;
      let totalBins = 0;
      let avg = 0;
      if (!_.isEmpty(data[0])){
        _.each(data, (item) => {
          amountOfRefill += item.total_amount_of_refill_in_liters;
          filledBins += item.site_refills_num_of_bins;
          totalBins += item.site_total_num_of_bins;
          avg += item.site_avg_refill_capacity_in_liters;
        });
        this.amountOfRefill = data.length == 0 ? 0 : amountOfRefill;      
        this.filledBins = filledBins;
        this.totalBins = totalBins;
        this.avg = data.length == 0 ? 0 : avg/data.length;
      }else{
        this.amountOfRefill = 0;    
        this.filledBins = 0;
        this.totalBins = 0;  
        this.avg = 0;
      }
		}); 

    this.apiQuery.user$.subscribe(user => {
      if (!user) {
        return;
      }				
      if(user["usage_type_id"] != 1){
        this.usageType = user["usage_type_id"];
      }
    });
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}
}

import { Component, ElementRef } from '@angular/core';
import _ from 'lodash';
import { SERVER_REMOTE_URL } from '../../../environments/environment';
import { TranslatorService } from '../../services/translator_service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '../../web-services/api/api.service';
import { ApiStore } from '../../web-services/api/api.store';
import { ApiQuery } from '../../web-services/api/api.query';
import { ResponsiveService } from '../../services/responsive.service';
import { ForgotPasswordModalComponent } from '../forgot-password-modal/forgot-password-modal.component';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { LanguagesMenuComponent } from '../header/languages-menu/languages-menu.component';
import { ResetPasswordModalComponent } from './reset-password-modal/reset-password-modal.component';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { ConfirmPasswordComponent } from './confirm-password/confirm-password.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { VerifyMfaModalComponent } from './verify-mfa-modal/verify-mfa-modal.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [LanguagesMenuComponent, ResetPasswordModalComponent, SuccessModalComponent, ConfirmPasswordComponent,
	 TranslateModule, FormsModule, RTLDivDirectiveDirective],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})

export class LoginComponent {
  	message: string = '';
	showSuccessUpdatePassModal: boolean = false;
	updatePasswordMessage: string = '';
	forgotPasswordMessage: string = '';
	token: string = '';
	showSuccessModal: boolean = false;
	showForgotPasswordModal: boolean = false;
	showConfirmPasswordModal: boolean = false;
	showLoginError: boolean = false;
	translate;
	username = '';
	password = '';
	isMobile: boolean = false;
	translationsObj :any;
	currnetLang:any;
	login_clicked: boolean = false;

	static getServerURL = () => {
		const url : any = _.find(SERVER_REMOTE_URL, (sru) => sru.is_selected);
		if (!url) {
			return '';
		}
		return url.url;
	}


	constructor(
    private elementRef: ElementRef,
		private translator: TranslatorService, 
		private http: HttpClient,
		public dialog: MatDialog, 
		private router: Router, 
		private apiService:ApiService,
		private apiStore:ApiStore,
		private apiQuery:ApiQuery,
		private responsiveService: ResponsiveService) {
			this.translate = this.translator;
			this.translator.currentLangEmitter$
			.subscribe(async value=>{						
				this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
				this.currnetLang = value;
			})
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.login_clicked = false;

		if(this.isMobile){
			if(document.getElementById('Mheader')!=null){
        		const element : HTMLElement | null  = document.getElementById('Mheader');
				element ? element.style.display = 'none' : null;
			}
		}
		this.showSuccessUpdatePassModal = false;
		this.showSuccessModal = false;
		this.showForgotPasswordModal = false;
		this.showConfirmPasswordModal = false;
		this.showLoginError = false;
		this.username ="";
		this.password = "";
		const parsedUrl = window.location.href.split('/');
		if (parsedUrl.length > 1){
			this.token = parsedUrl[parsedUrl.length-1];
			if (parsedUrl[parsedUrl.length-2] === "resetPassword")
				this.checkTokenValidation();
		}
		this.apiQuery.validation$.subscribe((validation) => {	
			if (!validation) return;			
			if (validation === 1 ){
				this.confirmPassword();
			}
		});
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	checkTokenValidation() {
		const dataToSend = {
		 "token": this.token
		}
		this.apiService.isChangePaaswordTokenValid(dataToSend);
	}


	onClickForgotPassword(event:any) {
		const dialogRef = this.dialog.open(ForgotPasswordModalComponent, {
			height: '438px',
			width: '585px',
			data: {}
		});
	}

	forgotPassword(){
		this.showForgotPasswordModal = true;
	}

	
	showUpdatePassModal(showHide:any){		
		if(this.currnetLang == 'iw'){
			this.message = "הסיסמה עודכנה בהצלחה";
		}else{
			this.message = "Password has updated successfully";
		}
		this.showSuccessModal = showHide;
	}
	confirmPassword(){
		this.showConfirmPasswordModal = true;
	}
	openConfirmModal = (showHide:any) => {
		this.showConfirmPasswordModal = showHide;
	}
	openResetModal = (showHide:any) => {
		this.showForgotPasswordModal = showHide;
	}

	openSuccessModal = (showHide:any) => {
		if(this.currnetLang == 'iw'){
			this.message = "שלחנו לך אימייל עם ההוראות";
		}else{
			this.message = "We’ve sent you an email with the instructions";
		}
		this.showSuccessModal = showHide;
	}
	openUpdateSuccessModal = (showHide:any) => {
		this.showSuccessUpdatePassModal = showHide;
	}

	userAuthentication(headers,baseUrl,authToken){
		this.http.get(`${baseUrl}/userAuthentication`, { headers: headers })
		.subscribe((response: any) => {
			const { User_Authentication_Info } = response;
			const { User_Preferences } = response;

			const user = {
				authToken,
				user_id: User_Authentication_Info.user_id,
				first_name: User_Authentication_Info.user_first_name,
				last_name: User_Authentication_Info.user_last_name,
				user_email_address: User_Authentication_Info.user_email_address,
				user_phone_number: User_Authentication_Info.user_phone_number,
				user_role_name: User_Authentication_Info.user_role_name,
				user_role_id: User_Authentication_Info.user_role_id,
				user_default_lang_id: User_Preferences.default_lang_id,
				user_default_capacity_type_id: User_Preferences.capacity_display_type_id,
				usage_type_id: User_Preferences.usage_type_id,
				is_view_unexecuted_work_plans: User_Preferences.is_view_unexecuted_work_plans
			};

			localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, authToken);
			localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
			this.apiStore.update({ user: user });
			
			if(user["user_default_lang_id"] == "1"){
				this.translator.init('en');
				localStorage.setItem('language', 'en');

			}else if(user["user_default_lang_id"] == "2"){
				this.translator.init('iw');
				localStorage.setItem('language', 'iw');

			}else if(user["user_default_lang_id"] == "3"){
				this.translator.init('pl');
				localStorage.setItem('language', 'pl');

			}else{
				this.translator.init('iw');
				localStorage.setItem('language', 'iw');
			}

			if(user["user_role_id"] != 7){
				document.location.href = '/dashboard';
				//this.router.navigate(['/dashboard']);
			}else{
				//this.router.navigate(['/driverplans']);
				document.location.href = '/driverplans';
			}
		},error => {
			//delete after implementing mfa
			this.showLoginError = true;
			this.login_clicked = false;
		});
	}

	login = (username: string, password: string) => {	
		if(this.login_clicked){
			return;
		}
		this.login_clicked = true;
		const baseUrl = LoginComponent.getServerURL();
		const authToken = btoa(`${username}:${password}`);
		const headers = { 'Authorization': `Basic ${authToken}` };
		this.showLoginError = false;
		this.userAuthentication(headers, baseUrl,authToken);//delete after implementing mfa

		//restore after implementing mfa
		// this.http.get(`${baseUrl}/isMfaVerified`, { headers: headers }).subscribe((response: any) => {
		// 	const { User_Authentication_Info } = response;
		// 	if(response.is_mfa_verified){
		// 		this.userAuthentication(headers, baseUrl,authToken);
		// 	}else{
		// 		if(!User_Authentication_Info.user_id){
		// 			this.showLoginError = true;
		// 			this.login_clicked = false;
		// 			return;
		// 		}
		// 		let class_name = '';
		// 		if(this.isMobile){
		// 			class_name = 'popup-mobile';
		// 		}else{
		// 			class_name = 'bulkBins-container';
		// 		}
		// 		const dialogRef = this.dialog.open(VerifyMfaModalComponent, { panelClass: class_name , data: {userInfo : User_Authentication_Info, username : username , password : password}  });				
		// 		dialogRef.afterClosed().subscribe(result => {
		// 			this.login_clicked = false;
		// 		});
		// 	}
		// },error => {});
	}
}

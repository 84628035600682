import { Component, Input, ViewChild } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { Router, RouterModule } from '@angular/router';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiService } from '../../../web-services/api/api.service';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { UtilService } from '../../../services/util.service';
import { ApiStore } from '../../../web-services/api/api.store';
import _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { PaginationComponent } from '../../pagination/pagination.component';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { CollectionClusterInfoModalComponent } from '../collection-cluster-info-modal/collection-cluster-info-modal.component';
import { GenericColumnsManagingModalComponent } from '../../generic-columns-managing-modal/generic-columns-managing-modal.component';

@Component({
  selector: 'app-collections-table',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, MatDividerModule, MatCardModule,CommonModule,
    MatTableModule,MatSortModule,MatMenuModule,FormsModule,PaginationComponent,RouterModule],
  templateUrl: './collections-table.component.html',
  animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		  ])
	  ],
  styleUrl: './collections-table.component.scss'
})
export class CollectionsTableComponent {
  expandedElement: any;
  isBinNameColChecked:any;
  isBinTypeColChecked:any;
  isSiteNameChecked:any;
  isNeighborhoodChecked:any;
  isMobile: Boolean;
  translationsObj;
  translate;
	currentLang;
  spinnerActive:boolean = true;
	neighborhoodAll:boolean = false;
	binTypeColAll:boolean = false;
  binNameColAll:boolean = false;
  siteNameColAll:boolean = false;
  groupsBackup:any[]=[];
  groups:any[]=[];
  groupsWithCollections:any;
  dataSource = new MatTableDataSource<any>();
  tempFilter = '';
  startIndexTableData = 0;
  endIndexTableData = 9;
  displayedColumns = ['name','siteName', 'address','neighborhood','type','clearing','volume','cause','rate','driver','license_plate','plus'];
  columnsToDisplay = ['name','siteName','address','neighborhood','type','clearing','volume','cause','rate','driver','license_plate','arrowDown'];
	@ViewChild(MatSort,{static: false}) sort: MatSort;
	capacityDisplaySymbol: string = '%';
  usageType = 1;
	@Input() datesDiff;
  filterTableAccordigToStatus : any = {
    text : '',
    value : ''
  };
  filteredData:Array<object> = [];
  binsBackUp: Array<any> = [];
  capacityDisplayType: number = 0;
  clusterBins: Array<any> = [];
	user_role_id = 0;
  collectionsSelectedValues = {
    isBinNameChecked: false,
    isSiteNameChecked: false,
    isAddressChecked: false,
    isNeighborhoodChecked: false,
    isBinTypeColChecked: false,
    isBinCollectionTime: false,
    isBinFillLevelBeforeCollection: false,
    isCollectionCauseNameChecked: false,
    isBinAvgFillRateChecked: false,
    isDriverName: false,
    isLicensePlate: false
  };

  constructor(private translator: TranslatorService, private router: Router,private dialog: MatDialog,
		private responsiveService: ResponsiveService,private utilService: UtilService,private apiStore:ApiStore,
		private apiQuery:ApiQuery,private apiService:ApiService) {
      this.translate = this.translator;
      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
        this.currentLang = value;	
      })
	 }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.spinnerActive = true;	

		this.apiQuery.user$.subscribe(user => {
			if (!user) return;
      this.capacityDisplayType = user["user_default_capacity_type_id"];
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
      this.user_role_id = user["user_role_id"];

      if(this.usageType == 1){
        this.collectionsSelectedValues.isBinNameChecked = true;
        this.collectionsSelectedValues.isBinFillLevelBeforeCollection = true;
      }else{
      }
		});

    this.apiQuery.selectedStatusPieChart$.subscribe(status => {	
      if (!_.isEmpty(status)){
        if(!status["point"].selected){
          this.filterTableAccordigToStatus["text"] = status["point"].name;
          this.filterTableAccordigToStatus["value"] = status["point"].color;
        }else{
          this.filterTableAccordigToStatus["text"] = '';
          this.filterTableAccordigToStatus["value"] = '';		
          this.groups = this.groupsBackup;	
          this.initTableData(this.groups);	
        }
        if(this.filterTableAccordigToStatus["text"] == this.translationsObj.TITLES.RED_BINS_COLLECTIONS){
          const tempGroups = _.filter(this.groupsBackup, (item) => {
            return item.bin_fill_level_before_collection > item.yellow_status_value;
          });
          this.groups = tempGroups;
          this.initTableData(this.groups);
        }else if(this.filterTableAccordigToStatus["text"] == this.translationsObj.TITLES.YELLOW_BINS_COLLECTIONS){
          const tempGroups = _.filter(this.groupsBackup, (item) => {
            return item.bin_fill_level_before_collection > item.green_status_value && item.bin_fill_level_before_collection <= item.yellow_status_value;
          });
          this.groups = tempGroups;
          this.initTableData(this.groups);
        }else if(this.filterTableAccordigToStatus["text"] == this.translationsObj.TITLES.GREEN_BINS_COLLECTIONS){
          const tempGroups = _.filter(this.groupsBackup, (item) => {
            return item.bin_fill_level_before_collection <= item.green_status_value;
          });
          this.groups = tempGroups;
          this.initTableData(this.groups);
        }
      }
    });

    this.apiQuery.selectedReportPieChart$.subscribe(status => {		
      if (!_.isEmpty(status)){
        if(!status["point"].selected){
          this.filterTableAccordigToStatus["text"] = status["point"].name;
          this.filterTableAccordigToStatus["value"] = status["point"].color;
          const tempFilter = this.filterTableAccordigToStatus["text"].replace(/%/g,'').split('-');
          const tempGroups = _.filter(this.groupsBackup, (item) => {
            return item.bin_fill_level_before_collection >= Number(tempFilter[0]) && item.bin_fill_level_before_collection <= Number(tempFilter[1]);
          });
          this.groups = tempGroups;
          this.initTableData(this.groups);
        }else{
          this.filterTableAccordigToStatus["text"] = '';
          this.filterTableAccordigToStatus["value"] = '';		
          this.groups = this.groupsBackup;	
          this.initTableData(this.groups);	
        }
      }
    });

    this.apiService.GetDynamicColumns().subscribe((column:any) => {  			
			if(column.DynamicColumns != null && column.DynamicColumns.length > 0){
				_.each(column.DynamicColumns, (item) => {	
					if(item.is_activate_for_current_user){
						this.showColumn(item["dynamic_column_id"].toString());
					}				
				});
			}				
		});

    combineLatest(
      this.apiQuery.collectionsStatsInCollections$,
      this.apiQuery.filteredBins$,
      this.apiQuery.sitesClustersList$)
      .pipe()
      .subscribe(([data,bins,clusters])=>{
        this.spinnerActive = true;		
        if(data.length==0) return;    
        this.removeFilter();
        this.groups = [];
        if (!_.isEmpty(data[0])){
          _.each(data, (site) => {
            if(site['site_bins_collections'] != undefined){
              _.each(site['site_bins_collections']["0_25"], (item) => {    
                item.site_name = site['site_name'];   
                item.site_id = site['site_id'];   
                this.groups.push(item);
              });    
              _.each(site['site_bins_collections']["26_50"], (item) => {   
                item.site_name = site['site_name'];
                item.site_id = site['site_id'];             
                this.groups.push(item);
              });
              _.each(site['site_bins_collections']["51_75"], (item) => {     
                item.site_name = site['site_name'];
                item.site_id = site['site_id'];           
                this.groups.push(item);
              });
              _.each(site['site_bins_collections']["76_85"], (item) => {          
                item.site_name = site['site_name'];
                item.site_id = site['site_id'];      
                this.groups.push(item);
              });
              _.each(site['site_bins_collections']["86_100"], (item) => {         
                item.site_name = site['site_name'];
                item.site_id = site['site_id'];       
                this.groups.push(item);
              });
            }
          });
          this.groupsBackup = this.groups;
        }

        if (bins.length == 0) return;
        this.spinnerActive = false;
        this.binsBackUp = bins;
        _.each(_.groupBy(this.binsBackUp,(item) => `${item.cluster_id},${item.site_id}`), (clusters) => {
            let cluster_avg = 0;
            _.each(clusters, (bin) => {
              if (this.capacityDisplayType != 0) {
                cluster_avg += (bin["Bin_live_Data"].fill_level_percent * bin["Bin_thresholds"].bin_max_capacity_in_liters) / 100;
              } else {
                cluster_avg += bin["Bin_live_Data"].fill_level_percent;
              }
            });
            _.each(clusters, (bin) => {
              bin["cluster_avg"] = cluster_avg / clusters.length;
              bin["number_of_bins_in_cluster"] = clusters.length;
            });
          }
        );
        _.each(this.groups, (item) => {
          let relevantBin = this.binsBackUp.filter(
            (bin) =>
              bin["site_id"] == item.site_id &&
              bin["cluster_id"] == item.cluster_id &&
              bin["bin_id"] == item.bin_id
          );
          let chosenCluster = clusters.filter(
            (cluster) =>
              cluster["site_id"] == item.site_id &&
              cluster["cluster_id"] == item.cluster_id
          );

          if (relevantBin.length > 0) {
            item["cluster_avg"] = relevantBin[0]["cluster_avg"];
            item["number_of_bins_in_cluster"] = relevantBin[0]["number_of_bins_in_cluster"];
          }

          if (chosenCluster.length > 0) {
            item["chosenCluster"] = chosenCluster[0];

            let binsInCluster = this.binsBackUp.filter(
              (bin) =>
                bin["site_id"] == chosenCluster[0]["site_id"] &&
                bin["cluster_id"] == chosenCluster[0]["cluster_id"]
            );
            let collection_rate = 0;
            let avg_collection_rate = 0;
            _.each(binsInCluster, (bin) => {
              collection_rate += bin["Bin_live_Data"].bin_collection_or_refill_rate;
            });
            avg_collection_rate = collection_rate / binsInCluster.length;
            item["chosenCluster"]["avg_collection_rate"] = avg_collection_rate;
          }
        });
        this.spinnerActive = false;		
        this.initTableData(this.groups);
      });
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
  }

  initTableData(groups){
    this.groupsWithCollections = this.getArrayOfCollections(groups);
    this.dataSource = new MatTableDataSource<any>(this.groupsWithCollections);      
    this.dataSource.sort = this.sort;
    this.sortData({ direction: 'desc', active: 'bin_name' });
  }

  parseDateFormat(dateStr){
		return this.currentLang == 'iw' ? this.utilService.convertDateHebrew(dateStr) : this.utilService.convertDateEnglish(dateStr);
	}

  removeFilter(){
    this.apiStore.update({ selectedReportPieChart: {} });     															
    this.apiStore.update({ selectedStatusPieChart: {} });  
    this.filterTableAccordigToStatus["text"] = '';
    this.filterTableAccordigToStatus["value"] = '';		
    this.groups = this.groupsBackup;	
    this.initTableData(this.groups);																				
  }

  getDirection(){    
    if(this.currentLang != 'iw'){
      return 'ltr';
    }
    else{
        return 'rtl';
      }
  }

  showColumn(value){
    if(value == 1){
      this.neighborhoodAll = true;
    }else if(value == 3){
      this.binTypeColAll = true;
    }else if(value == 6){
      this.binNameColAll = true;
    }else if(value == 10){
      this.siteNameColAll = true;
    }
	}

  setColumnForUser(id,command){
		let dataToSend = {};
		if(command){
			dataToSend["dynamic_column_id_list"] = [id];
			dataToSend["is_dynamic_columns_active"] = 1;
			this.apiService.SetUserDynamicColumns(dataToSend);
		}else{
			dataToSend["dynamic_column_id_list"] = [id];
			dataToSend["is_dynamic_columns_active"] = 0;
			this.apiService.SetUserDynamicColumns(dataToSend);
		}		
	}

  getArrayOfCollections(group:any){    
    const binsFiltered = _.map(group, b => {
      return {
        bin_name: b["bin_name"],
        site_name:b["site_name"],
        bin_address: b["bin_address"],
        neighborhood:b["bin_neighbourhood"],
        bin_type:b["bin_type_name"],
        bin_collection_time: this.utilService.convertDateEnglish(b["bin_collection_time"]),
        bin_fill_level_before_collection: b["bin_fill_level_before_collection"],
        collection_cause_name: b["collection_cause_name"],
        collection_cause_name_hebrew : b["collection_cause_name_hebrew"],
        bin_avg_fill_rate: b["bin_avg_fill_rate"],
        collected_driver_first_name: b["collected_driver_first_name"],
        collected_driver_last_name: b["collected_driver_last_name"],
        assigned_truck_license_plate: b["assigned_truck_license_plate"],
        bin_id: b["bin_id"],
        green_status_value: b["green_status_value"],
        yellow_status_value: b["yellow_status_value"],
        number_of_bins_in_cluster: b["number_of_bins_in_cluster"],
        chosenCluster: b["chosenCluster"],
        site_id: b["site_id"],
        cluster_id: b["cluster_id"],
        cluster_avg: b["cluster_avg"],
        bin_collection_time_he : this.utilService.convertDateHebrew(b["bin_collection_time"])
      };
    });
    return binsFiltered;
  }
  
  applyFilter = (filterValue: any) => {
    filterValue = filterValue.value;
    filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempFilter = filterValue;
		this.dataSource.filter = filterValue;
  }

  getTableData = () => {	
    const tableData = [];
    const tempSearch = new MatTableDataSource<any>(this.groupsWithCollections);
    tempSearch.filter = this.tempFilter;
    this.filteredData = tempSearch.filteredData;
    for (let index = this.startIndexTableData; index <= this.endIndexTableData; index++) {
      if (tempSearch.filteredData[index]) {
        tableData.push(tempSearch.filteredData[index]);
      }
    }
    this.dataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
    return this.dataSource;
  }

  sortData(sort: Sort) {    
    const data = this.groupsWithCollections.slice();    
		if (!sort.active || sort.direction === '') {
			this.groupsWithCollections = data;
			return;
		}
		this.groupsWithCollections = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
  }
  
  compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  getProgressBarStyleInternalCollections = (binInfo: any) => {
    let element = binInfo;    
		binInfo = binInfo.bin_fill_level_before_collection;
		if (binInfo <= element.green_status_value) {
			return { 'background-color': '#14C264', 'width': binInfo + '%','max-width':'100%' };
		} else if (binInfo > element.green_status_value && binInfo <= element.yellow_status_value) {
			return { 'background-color': '#FAB300', 'width': binInfo + '%','max-width':'100%' };
		}
		return { 'background-color': '#E01B09', 'width': binInfo + '%','max-width':'100%' };
  }

	goToBinLivePage(bin: any) {  
    if(this.user_role_id != 8){
      this.router.navigate(['/binLiveInfoPage'], { queryParams: { pageName: 'collections', binId: bin.bin_id } });
    }  
	}
  
  onPageChange = (dataTableIndexes: any) => {
		this.startIndexTableData = dataTableIndexes.startIndex;
		this.endIndexTableData = dataTableIndexes.endIndex;
  }

  toggleEditableAll(value) {
		this.neighborhoodAll = value;    
    this.setColumnForUser("1",this.neighborhoodAll);
  }

   toggleEditableTypeAll(value){
	  this.binTypeColAll = value;    
    this.setColumnForUser("3",this.binTypeColAll);
   }

   toggleEditableNameAll(value){
	  this.binNameColAll = value;    
    this.setColumnForUser("6",this.binNameColAll);
   }

   toggleEditableSiteAll(value){
	  this.siteNameColAll = value;    
    this.setColumnForUser("10",this.siteNameColAll);
   }
  
   changeSubmitClickedStatus(el) {
    this.openClusterInfo(el);
  }

  openClusterInfo(el) {
    this.clusterBins = [];
    let capacityAnalysisBins = this.groupsWithCollections.filter((bin) => bin["site_id"] == el.site_id && bin["cluster_id"] == el.cluster_id);
    let binsInfoSameCluster = this.binsBackUp.filter((bin) =>
        bin["site_id"] == el.site_id && bin["cluster_id"] == el.cluster_id
    );

    _.each(binsInfoSameCluster, (bin) => {
      if (!capacityAnalysisBins.some((item) => item.bin_id == bin["bin_id"])) {
        this.clusterBins.push(bin);
      }
    });
    this.clusterBins = this.clusterBins.concat(capacityAnalysisBins);
    this.openClusterInfoPopup();
  }

  openClusterInfoPopup() {
    let class_name = '';
		if(this.isMobile){
			class_name = 'popup-container-mobile';
		}else{
			class_name = this.currentLang != "iw" ? "clusterInfo-container-ltr" : "clusterInfo-container-rtl";
		}

    this.dialog.open(CollectionClusterInfoModalComponent, {
      panelClass: class_name,
      data: {
        items: this.clusterBins
      },
    });
  }

  openColumnManaging(){
    const dialogRef = this.dialog.open(GenericColumnsManagingModalComponent, { panelClass: 'popup-container-mobile' , data: {page : 'collections', displayedColumns: this.collectionsSelectedValues} });
		dialogRef.afterClosed().subscribe(result => {
			this.collectionsSelectedValues  = result.values;
			if (!_.isEmpty(this.collectionsSelectedValues)) {
				//init table with the new columns
        this.initTableData(this.groups)			
      }
		});
	}	
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslatorService } from '../../services/translator_service';
import { ResponsiveService } from '../../services/responsive.service';
import { PagerService } from '../../services/pager.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [TranslateModule,CommonModule,RTLDivDirectiveDirective],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent {
  isMobile: boolean = false;
	translationsObj : any;
	itemsSub: Subscription = Subscription.EMPTY;
	@Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
	pager: any = {};
	dataLength: number = 0;
	translate: any;
	currentLang: any;
	@Input() chosenBins: any;
	@Input() filesPerPage: any;
	currentDisplayed:Array<object> = [];
	pageIndexes: any;
	
	constructor(private pagerService: PagerService, private translator: TranslatorService,
		private responsiveService: ResponsiveService) {
			this.translator.currentLangEmitter$
			.subscribe(async value=>{						
				this.translationsObj = await this.translator.getTranslation(value).toPromise();							 
				this.currentLang = value;	
			})
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
	}

	ngOnChanges(){
		if(this.chosenBins){	
			if(!this.compareTwoArrayOfObjects(this.currentDisplayed,this.chosenBins)){
				this.currentDisplayed = this.chosenBins;
				this.dataLength = this.chosenBins.length;
				this.setPage(1);
			}
		}		
	}

	compareTwoArrayOfObjects(first_array_of_objects:any,second_array_of_objects:any) {
		return (
			first_array_of_objects.length === second_array_of_objects.length &&
			first_array_of_objects.every((element_1:any) =>
				second_array_of_objects.some(
					(element_2:any) =>
						element_1.bin_id == element_2.bin_id &&
						element_1.bin_name == element_2.bin_name
				)
			)
		);
	};

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

	isRtl = () => {
		return localStorage.getItem('language') === 'iw';
	}

	setPreviosPagesBlock = (page: number) => {
		if (this.pager.currentPage >= 4 && this.pager.currentPage <= 8) {
			this.setPage(3);
		} else if (this.pager.totalPages === this.pager.endPage) {
			this.setPage(this.pager.totalPages - 7);
		} else {
			this.setPage(page);
		}
	}

	setNextPagesBlock = (page: number) => {
		if (this.pager.endPage + 5 >= this.pager.totalPages) {
			this.setPage(this.pager.totalPages - 2);
		} else if (this.pager.currentPage >= 1 && this.pager.currentPage <= 3) {
			this.setPage(8);
		} else {
			this.setPage(page);
		}
	}
	setPage(page: number) {
		if (page < 1 || page > this.pager.totalPages) {
			return;
		}
		this.pager = this.pagerService.getPager(this.dataLength, page, this.filesPerPage);
		this.pageIndexes = {
			startIndex: this.pager.startIndex,
			endIndex: this.pager.endIndex
		};
		this.pageChange.emit(this.pageIndexes);
	}
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsLoaderService {
  private apiKey = 'AIzaSyB0UcoR0JNhlJkETb9LkDYfnjoiTW77GTc';
  private scriptLoaded = false;
  private currentLanguage: string | null = null;
  private loadingPromise: Promise<void> | null = null;

  // loadGoogleMaps(language: string): Promise<void> {
  //   if (this.scriptLoaded && language === this.currentLanguage) {
  //     return Promise.resolve();
  //   }

  //   return this.loadScript(language);
  // }

  // private loadScript(language: string): Promise<void> {
  //   if (this.loadingPromise) {
  //     return this.loadingPromise.then(() => this.removeGoogleMapsElements()).then(() => this.injectScript(language));
  //   }

  //   return this.injectScript(language);
  // }


  // private injectScript(language: string): Promise<void> {
  //   this.loadingPromise = new Promise((resolve, reject) => {
  //     this.removeGoogleMapsElements().then(() => {
  //       this.currentLanguage = language;

  //       const script = document.createElement('script');
  //       script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places,geometry,drawing&language=${language}`;
  //       script.async = true; // Add async attribute
  //       script.defer = true; // Add defer attribute
  //       script.onload = () => {
  //         this.scriptLoaded = true;
  //         resolve();
  //       };
  //       script.onerror = (error) => reject(error);

  //       document.head.appendChild(script);
  //     });
  //   });

  //   return this.loadingPromise;
  // }

  // removeGoogleMapsElements(): Promise<void> {
  //   return new Promise((resolve) => {
  //     const scripts = document.querySelectorAll('script[src*="maps.googleapis.com"], script[src*="googleapis.com"]');
  //     scripts.forEach(script => script.remove());

  //     const links = document.querySelectorAll('link[href*="googleapis"]');
  //     links.forEach(link => link.remove());

  //     // Remove Google Maps related global objects
  //     delete (window as any).google;

  //     this.scriptLoaded = false;
  //     this.currentLanguage = null;      
  //     setTimeout(() => resolve(), 0);
  //   });
  // }

  loadGoogleMaps(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.scriptLoaded) {
        resolve();
        return;
      }
      let language  = localStorage.getItem('language') || 'he';
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places,geometry,drawing&language=${language}`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.scriptLoaded = true;
        resolve();
      };
      script.onerror = (error) => reject(error);

      document.head.appendChild(script);
    });
  }
}

import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TranslatorService {
	public currentLangEmitter$ : BehaviorSubject<String> = new BehaviorSubject<String>('');

	constructor(public translate: TranslateService, private _ngZone: NgZone) {
	}

	async init(lang : any) {
		this.translate.setDefaultLang(lang);
		this.translate.use(lang);
		this.translate.getDefaultLang();
		this.currentLangEmitter$.next(lang);
	}

	changeLanguage(lang : any) {
		this.translate.setDefaultLang(lang);
		this.translate.use(lang);
		this._ngZone.run(() => {
			this.translate.setDefaultLang(lang);
			this.translate.use(lang);
		});
	}

	isRTL = () => {
		return this.translate.currentLang === 'iw';
	}

	selectedLang = () => {
		let rc = localStorage.getItem('language');
		
		switch (rc) {
			case 'iw':
				rc = 'עב';
				break;
			case 'en':
				rc = 'EN';
				break;
			case 'pl':
				rc = 'PL';
				break;
			default:
				rc = 'EN';
		}
		return rc;
	}

	getTranslationLanguage = () => {
		let rc = localStorage.getItem('language');

		switch (rc) {
			case 'iw':
				rc = 'iw';
				break;
			case 'en':
				rc = 'en';
				break;
			case 'pl':
				rc = 'pl';
				break;
			default:
				rc = 'en';
		}
		return rc;
	}

	getTranslation(lang : any) {
		return this.translate.getTranslation(lang);
	}

}

@if (!isMobile) {
  <div [ngStyle]="getWrapperStyle()">
    <div class="table-status-progress-bar-wrapper" [ngStyle]="getProgressBarStyleWrapper()">
      <div class="table-status-progress-line" [ngStyle]="getProgressBarStyleInternal()"></div>
    </div>
    <div class = 'range-wrapper'>
      <div class = 'low-range'>{{rangeLow}}</div>
      <div class = 'high-range'>{{rangeHigh}}</div>
    </div>
  </div>
}

@if (isMobile) {
  <div [ngStyle]="getWrapperStyle()" class="mobile-progress">
    <div class="table-status-progress-bar-wrapper" [ngStyle]="getProgressBarStyleWrapper()">
      <div class="table-status-progress-line" [ngStyle]="getProgressBarStyleInternal()"></div>
    </div>
    <div class = 'range-wrapper'>
      <div class = 'low-range'>{{rangeLow}}</div>
      <div class = 'high-range'>{{rangeHigh}}</div>
    </div>
  </div>
}
import { Component, ViewChild } from '@angular/core';
import { ResponsiveService } from '../../services/responsive.service';
import { TranslatorService } from '../../services/translator_service';
import { ApiQuery } from '../../web-services/api/api.query';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';

import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { UnallocatedBinsComponent } from './unallocated-bins/unallocated-bins.component';
import { AllocatedBinsComponent } from './allocated-bins/allocated-bins.component';

@Component({
  selector: 'app-fillable-bins',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, MatDividerModule, MatCardModule, UnallocatedBinsComponent, AllocatedBinsComponent],
  templateUrl: './fillable-bins.component.html',
  styleUrl: './fillable-bins.component.scss'
})
export class FillableBinsComponent {
  currentLang;
	translationsObj;
  	isMobile: Boolean;
  	translate;
	usageType = 1;
	displayedColumns = ['select', 'name','address', 'id'];
	binsData:Array<any> = [];
	spinnerActive:boolean = true;
	dataSource = new MatTableDataSource<any>();
	dataTableLength = 0;
	@ViewChild(MatSort,{static: false}) sort: MatSort;
	startIndexTableData = 0;
	endIndexTableData = 9;
	tempFilter = '';
	selection = new SelectionModel<Element>(true, []);


  constructor(private translator: TranslatorService,
	private apiQuery:ApiQuery,
		private responsiveService: ResponsiveService) {
		this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
	}

  ngOnInit() {
	this.onResize();
	this.responsiveService.checkWidth();
	this.apiQuery.user$.subscribe(user => {
		if (!user) return;

		if(user["usage_type_id"] != 1){
			this.usageType = user["usage_type_id"];
		}
	});
  }

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		this.isMobile = isMobile;
		});
	}
}

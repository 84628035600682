import { Component, ViewChild } from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import _ from 'lodash';
import moment from 'moment';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ApiService } from '../../../web-services/api/api.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslatorService } from '../../../services/translator_service';
import { ApiStore } from '../../../web-services/api/api.store';
import { MatDialogRef } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { PaginationComponent } from '../../pagination/pagination.component';
import { UtilService } from '../../../services/util.service';

@Component({
  selector: 'app-force-bin-collection-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,CommonModule,FormsModule,MatFormFieldModule,MatInputModule,
    NgbModule,ReactiveFormsModule,MatTableModule,MatSortModule,MatCheckboxModule,MatTabsModule,
    PaginationComponent],
  templateUrl: './force-bin-collection-modal.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
      ])
    ],
  styleUrl: './force-bin-collection-modal.component.scss'
})
export class ForceBinCollectionModalComponent {
	expandedElement: any;
	currentLang;
	translationsObj;
	isMobile: Boolean;
  	tempFilter = '';
	dataSource = new MatTableDataSource<any>();
	dataSourceAddBins = new MatTableDataSource<any>();
  	addBinsTable : Array<object> = [];
	binsDataTable : Array<object> = [];
	binsData;
	binsDataAddBins;
	displayedColumns = ['select', 'name', 'address','neighborhood','status','collection_mode','predicted_time_for_max_allowed_capacity'];
	displayedColumnsMobile = ['select', 'name', 'address', 'arrowDown'];
	selectionAddBins= new SelectionModel<Element>(true, []);
	selection = new SelectionModel<Element>(true, []);
	@ViewChild(MatSort,{static: false}) sort: MatSort;
	ElForm: FormGroup;
	siteList:Array<object>;
	allBinsForChoose: any;
	allBinsToCollect:any;
	allBinsForChooseBackUp:any;
	allBinsToCollectBackUp:any;
	is_view_unexecuted_work_plans:any = 0;
	usageType = 1;
	filteredData:Array<object> = [];
	filteredBinsToCollectData:Array<object> = [];
	startIndexTableDataAllBins = 0;
	endIndexTableDataAllBins = 7;
	startIndexTableData = 0;
	endIndexTableData = 7;
	private readonly destroy$ = new Subject();
	spinnerActive:boolean = false;
	selectedTab:number = 0;

  constructor(private responsiveService: ResponsiveService,public fb: FormBuilder,private apiService:ApiService,
    private apiStore:ApiStore,private translator: TranslatorService,private apiQuery:ApiQuery,
	private dialogRef: MatDialogRef<ForceBinCollectionModalComponent>,private utilService: UtilService) {
		this.dialogRef.disableClose = true;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		this.currentLang = this.translator.getTranslationLanguage();	   
		})
		this.ElForm = this.fb.group({
			siteName: '',
		  });
	}

  ngOnInit() {
    this.onResize();
	this.responsiveService.checkWidth();
	this.apiQuery.user$.subscribe(user => {
		if (!user) return;

		if(user["usage_type_id"] != 1){
			this.usageType = user["usage_type_id"];
		}
		this.is_view_unexecuted_work_plans = user["is_view_unexecuted_work_plans"];			
	});
	this.apiQuery.sitesListData$.subscribe((sitesList) => {
		if(sitesList == null || sitesList.length == 0){return;}
		this.siteList = sitesList;

		this.siteList.sort(function(a, b){
			if(a["site_name"] < b["site_name"]) { return -1; }
			if(a["site_name"] > b["site_name"]) { return 1; }
			return 0;
		});
		if(this.siteList.length == 1){
			this.ElForm.controls["siteName"].setValue(this.siteList[0]["site_id"]);
			this.getSiteChosen();
		}
	})
  }
  
  
  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

	getSiteChosen(){
		this.apiQuery.filteredBins$.subscribe(bins => {	
			this.spinnerActive = true;
			if(bins.length == 0) return;
			this.spinnerActive = false;					
			let allBinsToCollect = bins.filter(bin => {
				return (bin["site_id"] == this.ElForm.getRawValue()['siteName'] && bin["is_forced_to_work_plan"] == 1);
			});	
			let allBinsForChoose = bins.filter(bin => {
				return (bin["site_id"] == this.ElForm.getRawValue()['siteName'] && bin["is_forced_to_work_plan"] == 0);
			});	

			this.allBinsToCollectBackUp = allBinsToCollect;
			this.allBinsForChooseBackUp = allBinsForChoose;

			this.allBinsToCollect = allBinsToCollect;
			this.allBinsForChoose = allBinsForChoose;
			this.initializeAddBinsTable();
			this.initializeBinsTable();	
			this.selection.clear();
			this.selectionAddBins.clear();
		});
	}

	myTabSelectedIndexChange(event){
		this.selectedTab = event;
	}
  
	closeModal = () => {
		this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
		this.dialogRef.close();
	}

	onAllBinsTablePageChange = (dataTableIndexes: any) => {
		this.startIndexTableDataAllBins = dataTableIndexes.startIndex;
		this.endIndexTableDataAllBins = dataTableIndexes.endIndex;
		this.getAllBinsTableDataMobile();
	}

	onTablePageChange = (dataTableIndexes: any) => {
		this.startIndexTableData = dataTableIndexes.startIndex;
		this.endIndexTableData = dataTableIndexes.endIndex;
		this.getTableDataMobile();
	}

	masterToggle = () => {		
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource["AllData"].filteredData.map(row =>
				this.selection.select(row))
	}

	isAllSelected = (): boolean => {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource["AllData"].filteredData.length;
		return numSelected === numRows;
	}

	getAllBinsTableDataMobile = () => {
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.addBinsTable);
		tempSearch.filter = this.tempFilter;
		this.filteredData = tempSearch.filteredData;
		for (let index = this.startIndexTableDataAllBins; index <= this.endIndexTableDataAllBins; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
		this.dataSourceAddBins = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
		return this.dataSourceAddBins;
	}

	getAllBinsTableData = () => {
		const tempSearch = new MatTableDataSource<any>(this.addBinsTable);
		tempSearch.filter = this.tempFilter;
		this.filteredData = tempSearch.filteredData;
		this.dataSourceAddBins = tempSearch.filteredData.length > 0 ?
			new MatTableDataSource<any>(tempSearch.filteredData) : new MatTableDataSource<any>([]);
		return this.dataSourceAddBins;
	}

  getTableData = () => {	 
		const tempSearch = new MatTableDataSource<any>(this.binsDataTable);
		tempSearch.filter = this.tempFilter;
		this.filteredBinsToCollectData = tempSearch.filteredData;
		this.dataSource = tempSearch.filteredData.length > 0 ?
			new MatTableDataSource<any>(tempSearch.filteredData) : new MatTableDataSource<any>([]);
		this.dataSource["AllData"] = new MatTableDataSource<any>(tempSearch.filteredData);
		return this.dataSource;
	}

	getTableDataMobile = () => {
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.binsDataTable);
		tempSearch.filter = this.tempFilter;
		this.filteredBinsToCollectData = tempSearch.filteredData;
		for (let index = this.startIndexTableData; index <= this.endIndexTableData; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
		this.dataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
		this.dataSource["AllData"] = new MatTableDataSource<any>(tempSearch.filteredData);
		return this.dataSource;
	}

  applyFilter = (filterValue: string) => {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempFilter = filterValue;
		this.dataSource.filter = filterValue;
	}

  sortAddBinsData(sort: Sort) {
		const data = this.addBinsTable.slice();
		if (!sort.active || sort.direction === '') {
			this.addBinsTable = data;
			return;
		}
		this.addBinsTable = data.sort((a, b) => {
			const isAsc = sort.direction === 'desc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
	}

  sortData(sort: Sort) {
		const data = this.binsDataTable.slice();
		if (!sort.active || sort.direction === '') {
			this.binsDataTable = data;
			return;
		}
		this.binsDataTable = data.sort((a, b) => {
			const isAsc = sort.direction === 'desc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
	}

	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	getDirection(){    
		if(this.currentLang != 'iw'){
		  return 'ltr';
		}
		else{
			return 'rtl';
		  }
	  }

	moveBinToOutside(){
		if(this.selection.selected.length == 0){
			return;
		}else{		
			_.each(this.selection.selected, (b) => {
				this.allBinsForChoose = [...this.allBinsForChoose,b];
			});
			
			let res = this.allBinsToCollect.filter(item1 => 
				!this.selection.selected.some(item2 => (item2["bin_id"] == item1["bin_id"])))			
			this.allBinsToCollect = res;

			this.initializeAddBinsTable();
			this.initializeBinsTable();	
			this.selection.clear();
			this.selectionAddBins.clear();
		}
	}

	moveBinToInside(){
		if(this.selectionAddBins.selected.length == 0){
			return;
		}else{
			_.each(this.selectionAddBins.selected, (b) => {
				this.allBinsToCollect = [...this.allBinsToCollect,b];	
			});
			let res = this.allBinsForChoose.filter(item1 => 
				!this.selectionAddBins.selected.some(item2 => (item2["bin_id"] == item1["bin_id"])))			
				this.allBinsForChoose = res;

			this.initializeBinsTable();	
			this.initializeAddBinsTable();
			this.selection.clear();
			this.selectionAddBins.clear();
		}
	}

	private initializeAddBinsTable = () => {
		this.addBinsTable = [];
		const binsFiltered = _.map(this.allBinsForChoose, b => {
			return {
				bin_name: b.bin_name,
				bin_id: b.bin_id,
				bin_address : b.Bin_location ? (b.Bin_location.bin_address ? b.Bin_location.bin_address : '') : (b.bin_address ? b.bin_address : ''),								
				bin_neighborhood : b.Bin_location ? (b.Bin_location.bin_neighbourhood ? b.Bin_location.bin_neighbourhood : '') : (b.bin_neighborhood ? b.bin_neighborhood : ''),				
				bin_status : b.Bin_live_Data ? (b.Bin_live_Data.fill_level_percent ? b.Bin_live_Data.fill_level_percent : 0) : (b.bin_status ? b.bin_status : 0),
				collection_mode : b.Bin_Restrictions ? (b.Bin_Restrictions.collection_mode ? Number(b.Bin_Restrictions.collection_mode) : '') : (b.collection_mode ? Number(b.collection_mode) : 0),	
				predicted_time_for_max_allowed_capacity : b.Bin_live_Data ? (b.Bin_live_Data.predicted_time_for_max_allowed_capacity ? this.utilService.convertDateEnglishWithoutTime(b.Bin_live_Data.predicted_time_for_max_allowed_capacity) : '') : (b.predicted_time_for_max_allowed_capacity ? b.predicted_time_for_max_allowed_capacity : ''),								
				predicted_time_for_max_allowed_capacity_he : b.Bin_live_Data ? (b.Bin_live_Data.predicted_time_for_max_allowed_capacity ? this.utilService.convertDateHebrewWithoutTime(b.Bin_live_Data.predicted_time_for_max_allowed_capacity) : '') : (b.predicted_time_for_max_allowed_capacity_he ? b.predicted_time_for_max_allowed_capacity_he : ''),								
				predicted_time_for_max_allowed_capacity_value : moment(b.Bin_live_Data ? (b.Bin_live_Data.predicted_time_for_max_allowed_capacity ? this.utilService.convertDateEnglishWithoutTime(b.Bin_live_Data.predicted_time_for_max_allowed_capacity) : '') : (b.predicted_time_for_max_allowed_capacity ? b.predicted_time_for_max_allowed_capacity : '')).format("YYYY-MM-DD HH:mm:ss").valueOf(),
			};
		});
		this.binsDataAddBins = binsFiltered;	

		_.each(this.binsDataAddBins, (item) => {			
			this.addBinsTable.push(item);			
		});	
		this.dataSourceAddBins = new MatTableDataSource<any>(binsFiltered);
		this.dataSourceAddBins.sort = this.sort;
		this.sortAddBinsData({ direction: 'desc', active: 'bin_name' });
	}

	initializeBinsTable = () => {
		this.binsDataTable = [];
		const binsFiltered = _.map(this.allBinsToCollect, b => {
			return {
				bin_name: b.bin_name,
				bin_id: b.bin_id,
				bin_address : b.Bin_location ? (b.Bin_location.bin_address ? b.Bin_location.bin_address : '') : (b.bin_address ? b.bin_address : ''),								
				bin_neighborhood : b.Bin_location ? (b.Bin_location.bin_neighbourhood ? b.Bin_location.bin_neighbourhood : '') : (b.bin_neighborhood ? b.bin_neighborhood : ''),				
				bin_status : b.Bin_live_Data ? (b.Bin_live_Data.fill_level_percent ? b.Bin_live_Data.fill_level_percent : 0) : (b.bin_status ? b.bin_status : 0),
				collection_mode : b.Bin_Restrictions ? (b.Bin_Restrictions.collection_mode ? Number(b.Bin_Restrictions.collection_mode) : '') : (b.collection_mode ? Number(b.collection_mode) : 0),
				predicted_time_for_max_allowed_capacity : b.Bin_live_Data ? (b.Bin_live_Data.predicted_time_for_max_allowed_capacity ? this.utilService.convertDateEnglishWithoutTime(b.Bin_live_Data.predicted_time_for_max_allowed_capacity) : '') : (b.predicted_time_for_max_allowed_capacity ? b.predicted_time_for_max_allowed_capacity : ''),								
				predicted_time_for_max_allowed_capacity_he : b.Bin_live_Data ? (b.Bin_live_Data.predicted_time_for_max_allowed_capacity ? this.utilService.convertDateHebrewWithoutTime(b.Bin_live_Data.predicted_time_for_max_allowed_capacity) : '') : (b.predicted_time_for_max_allowed_capacity_he ? b.predicted_time_for_max_allowed_capacity_he : ''),								
				predicted_time_for_max_allowed_capacity_value : moment(b.Bin_live_Data ? (b.Bin_live_Data.predicted_time_for_max_allowed_capacity ? this.utilService.convertDateEnglishWithoutTime(b.Bin_live_Data.predicted_time_for_max_allowed_capacity) : '') : (b.predicted_time_for_max_allowed_capacity ? b.predicted_time_for_max_allowed_capacity : '')).format("YYYY-MM-DD HH:mm:ss").valueOf(),
			};
		});
		this.binsData = binsFiltered;	
		
		_.each(this.binsData, (item) => {			
			this.binsDataTable.push(item);			
		});			
		this.dataSource = new MatTableDataSource<any>(binsFiltered);
		this.dataSource.sort = this.sort;
		this.sortData({ direction: 'desc', active: 'bin_name' });
	}

	applyChanges(){
		if(this.ElForm.getRawValue()['siteName'] == ''){
			return;
		}
		this.removeBinFromWorkplan();
		this.addBinsToWorkplan();
		this.closeModal();
	}

	getImg(value:any){			
		if(value == 2){
			return "assets/images/dashboard/selected.svg";
		}else{
			return "assets/images/dashboard/notSelected.svg";
		}
	}

	public removeBinFromWorkplan = () => {			
		let includedBins = this.allBinsToCollectBackUp.filter(item1 => 
			!this.allBinsToCollect.some(item2 => (item2["bin_id"] == item1["bin_id"])))		

		const binsToAdd: any = includedBins;
		if (_.isEmpty(binsToAdd)) return;
		const binListId = _.map(binsToAdd, 'bin_id');		
		const dataToSend = {
			bin_id_list: binListId,
			site_id:includedBins[0].site_id
		};

		this.apiService.cancelForceBinsToWorkPlan(dataToSend).subscribe((v:any) => { 			
			let userGeneratedWorkplans = {
				site_id:0
			}	
			let start_date = moment().subtract(2, 'months').format('YYYY-MM-DD HH:MM:59');
			let end_date = moment().format('YYYY-MM-DD HH:MM:59');
			this.apiStore.update({ allFilteredDailyWorkPlan: [] });
			this.apiStore.update({ filteredDailyWorkPlan: [] });
			this.apiService.getfilteredActivePlans(JSON.parse(sessionStorage.getItem("chosenSites"))); 
			this.apiStore.update({ allWorkPlanScheduler: [] });
			this.apiStore.update({ workPlanScheduler: [] });
			this.apiService.getfilteredWorkPlanScheduler(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));
			this.apiStore.update({ allDailyWorkPlanCalanderData: [] });
			this.apiStore.update({ dailyWorkPlanCalanderData: [] });
			this.apiService.getDailyWorkPlanCalanderData(start_date,end_date,this.is_view_unexecuted_work_plans,JSON.parse(sessionStorage.getItem("chosenSites")));  	
			this.apiStore.update({ allFilteredBins: [] });                    
			this.apiStore.update({ filteredBins: [] });
			this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));		
		});   
	}

	public addBinsToWorkplan = () => {
		let includedBins = this.allBinsForChooseBackUp.filter(item1 => 
			!this.allBinsForChoose.some(item2 => (item2["bin_id"] == item1["bin_id"])));

		const binsToAdd: any = includedBins;
		if (_.isEmpty(binsToAdd)) return;
		const binListId = _.map(binsToAdd, 'bin_id');		
		const dataToSend = {
			bin_id_list: binListId,
			site_id:includedBins[0].site_id
		};
		this.apiService.forceBinsToWorkPlan(dataToSend).subscribe((v:any) => {     			
			let userGeneratedWorkplans = {
				site_id:0
			}	
			let start_date = moment().subtract(2, 'months').format('YYYY-MM-DD HH:MM:59');
			let end_date = moment().format('YYYY-MM-DD HH:MM:59');
			this.apiStore.update({ allFilteredDailyWorkPlan: [] });
			this.apiStore.update({ filteredDailyWorkPlan: [] });
			this.apiService.getfilteredActivePlans(JSON.parse(sessionStorage.getItem("chosenSites"))); 
			this.apiStore.update({ allWorkPlanScheduler: [] });
			this.apiStore.update({ workPlanScheduler: [] });
			this.apiService.getfilteredWorkPlanScheduler(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));
			this.apiStore.update({ allDailyWorkPlanCalanderData: [] });
			this.apiStore.update({ dailyWorkPlanCalanderData: [] });
			this.apiService.getDailyWorkPlanCalanderData(start_date,end_date,this.is_view_unexecuted_work_plans,JSON.parse(sessionStorage.getItem("chosenSites")));  								
			this.apiStore.update({ allFilteredBins: [] });                    
			this.apiStore.update({ filteredBins: [] });
			this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites"))); 			 		
		});   
	}
}

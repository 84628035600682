@if (!isMobile) {
  <div class="truck-workplans-list-view">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="title">{{ 'TITLES.TRUCK_WORKPLAN_LIST_VIEW' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <div class='spinner-container' [hidden]="!spinnerActive">
        <div class="spinner"></div>
      </div>
      <mat-card-content [hidden]="spinnerActive">
        <div class='search-input-container'>
          <div class="search-frame">
            <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
            <input class='search-input' type="text" (keyup)="applyFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}" id="search-input" rtlDiv>
          </div>
            <div class='table-export-container' rtlDiv>
              <div class='table-export-btns-wrapper' (click)="exportToCsv()">
                <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
              </div>
            </div>
          </div>
          <div class="dflex-days">
            <div class="width100">
              <table class="table" mat-table #table multiTemplateDataRows [dataSource]="getTableData()" matSort matSortStart="asc" matSortActive="bin_name"
              matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
                <!-- Collection Order Column -->
                <ng-container matColumnDef="CollectionOrder">
                  <th [hidden]="!binOrderCol" mat-header-cell *matHeaderCellDef mat-sort-header="bin_order" class="th-title">{{ 'WORKPLAN.COLLECTION_ORDER' | translate }} </th>
                  <td [hidden]="!binOrderCol" mat-cell *matCellDef="let element" class="td-value"> {{element ? element.bin_order: ''}} </td>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th [hidden]="!binNameCol && usageType == 2" mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="th-title">{{ 'LIST_COLUMNS.NAME' | translate }} </th>
                  <td [ngClass]="{'cursor-auto' : user_role_id == 8}" class="td-value" [hidden]="!binNameCol && usageType == 2" mat-cell *matCellDef="let element">
                    @if(user_role_id != 8){
                      <a class="binNameHref" [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'workplans',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" [ngClass]="{'cut-txt' : element && element.bin_name.length > 11}"> {{element ? element.bin_name : ''}}</a>
                    }@else{
                      <span title="{{element ? element.bin_name : ''}}" [ngClass]="{'cut-txt' : element && element.bin_name.length > 11}">{{element ? element.bin_name : ''}}</span>
                    }
                  </td>
                </ng-container>
                <!-- id Column -->
                <ng-container matColumnDef="id">
                  <th [hidden]="!binIdCol" mat-header-cell *matHeaderCellDef mat-sort-header="bin_id" class="th-title space"> {{ 'LIST_COLUMNS.ID' | translate }} </th>
                  <td [hidden]="!binIdCol" mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.bin_id: ''}} </td>
                </ng-container>
                <!-- type Column -->
                <ng-container matColumnDef="type">
                  <th [hidden]="!binTypeCol" mat-header-cell *matHeaderCellDef mat-sort-header="bin_type" class="list-title th-title space">{{ 'LIST_COLUMNS.BIN_TYPE' | translate }}</th>
                  <td [hidden]="!binTypeCol" mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.bin_type: ''}} </td>
                </ng-container>
                <!-- Address Column -->
                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="address" class="th-title space"> {{ 'LIST_COLUMNS.ADDRESS' | translate }}</th>
                  <td mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.address: ''}} </td>
                </ng-container>
                <!-- neighborhood Column -->
                <ng-container matColumnDef="neighborhood">
                  <th [hidden]="!neighborhood" mat-header-cell *matHeaderCellDef mat-sort-header="neighborhood" class="th-title space">{{'LIST_COLUMNS.NEIGHBORHOOD' | translate}}</th>
                  <td [hidden]="!neighborhood" mat-cell *matCellDef="let element" class="td-value space"> {{element ? element.neighborhood: ''}} </td>
                </ng-container>
                <ng-container matColumnDef="beforeCollectionCapacityInPercent">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="beforeCollectionCapacityInPercent" class="th-title space">{{'WORKPLAN.CAP_AT_PICKUP' | translate}}</th>
                  <td mat-cell *matCellDef="let element" class="td-value space"> {{element && element.beforeCollectionCapacityInPercent != '0' ? element.beforeCollectionCapacityInPercent: ''}}{{element && element.beforeCollectionCapacityInPercent != '0' ? capacitySymbol.replace(" ","") : ''}} </td>
                </ng-container>
                <ng-container matColumnDef="afterCollectionCapacityInPercent">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="afterCollectionCapacityInPercent" class="th-title space">{{'WORKPLAN.CAP_AFTER_PICKUP' | translate}}</th>
                  <td mat-cell *matCellDef="let element" class="td-value space"> {{element && element.beforeCollectionCapacityInPercent != '0' ? element.afterCollectionCapacityInPercent: ''}}{{element && element.beforeCollectionCapacityInPercent != '0' ? capacitySymbol.replace(" ","") : ''}}</td>
                </ng-container>
                <!-- status Column -->
                <ng-container matColumnDef="status">
                  <th [hidden]="isPast" mat-header-cell *matHeaderCellDef mat-sort-header="status" class="th-title space">{{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                  <td [hidden]="isPast" mat-cell *matCellDef="let element" class="space">
                    <div class="progress-flex">
                      <div class="table-status-progress-bar-wrapper progress">
                        <div class="table-status-progress-line progress-bar" [ngStyle]="getProgressBarStyleInternal(element)"></div>
                      </div>
                      <div class='table-status-progress-bar-title' rtlDiv>{{element ? element.bin_capacity_to_display: ''}}<span [hidden]=!element>{{capacitySymbol}}</span></div>
                    </div>
                  </td>
                </ng-container>
                <!-- last_collection Column -->
                <ng-container matColumnDef="last_collection">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="plan_collection_time" class="th-title space"> {{ 'LIVE_INFO.LIST_COLLECTION_TIME' | translate }} </th>
                  <td mat-cell *matCellDef="let element" class="td-value space"> 
                    {{currentLang == 'iw' && element ? element.plan_collection_time_he : element.plan_collection_time}}
                  </td>
                </ng-container>
                <!-- collection Column. Is there any way to sort this? -->
                <ng-container matColumnDef="collected">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="collected" class="th-title"> 
                    {{'LIST_COLUMNS.COLLECTED' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <img src="{{element ? getImg(element) : ''}}" alt="" />
                    @if (element.bin_collection_cause_id == 3) {
                      <img class='forced-ic' src="assets/images/dashboard/forced.svg" alt="" rtlDiv/>
                    }
                    @if (element.bin_collection_cause_id == 7) {
                      <img class='forced-ic' src="assets/images/workplans-page/binAdded.svg" alt="" rtlDiv/>
                    }
                    @if (element.collected == 2 && element.removed_bin_reason_type_id == 6) {
                      <img class='forced-ic' src="assets/images/workplans-page/binRemoved.svg" alt="" rtlDiv/>
                    }
                    @if (element.bin_collection_cause_id == 10) {
                      <img class='forced-ic' src="assets/images/workplans-page/system_added.svg" alt="" rtlDiv/>
                    }
                    @if (element.collected == 2 && element.removed_bin_reason_type_id == 15) {
                      <img class='forced-ic' src="assets/images/workplans-page/system_removed.svg" alt="" rtlDiv/>
                    }
                    @if (element && getImg(element) && (getImg(element).includes('missed') || getImg(element).includes('collected2'))) {
                      <img class="arrow_down" rtlDiv src="assets/images/workplans-page/arrow_down.svg" alt="" />
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" rtlDiv>
                    <div [@detailExpand]="element == expandedElement && getImg(element) && (getImg(element).includes('missed') || getImg(element).includes('collected2')) ? 'expanded' : 'collapsed'">
                      <div class="mobile-element-diagram" rtlDiv>
                        @for (data of element.exceptions; track data) {
                          <div>
                            <div class="dFlex">
                              @if (currentLang != 'iw' && getImg(element) && getImg(element).includes('missed')) {
                                <span rtlDiv class="mobile-id-details-value"><span class="collapsed-row-title">{{ 'WORKPLAN.REPORT_TYPE' | translate }}: </span> {{data ? getExcName(data.exception_id_name) : ''}}</span>
                              }
                              @if (currentLang == 'iw' && getImg(element) && getImg(element).includes('missed')) {
                                <span rtlDiv class="mobile-id-details-value"><span class="collapsed-row-title">{{ 'WORKPLAN.REPORT_TYPE' | translate }}: </span> {{data ? getExcName(data.exception_id_name_hebrew) : ''}}</span>
                              }
                            </div>
                            <div class="dFlex">
                              @if (currentLang != 'iw' && getImg(element) && getImg(element).includes('collected2')) {
                                <span rtlDiv class="mobile-id-details-value"><span class="collapsed-row-title">{{ 'WORKPLAN.REPORT_TYPE' | translate }}: </span> {{element ? (data.exception_id_name): ''}}</span>
                              }
                              @if (currentLang == 'iw' && getImg(element) && getImg(element).includes('collected2')) {
                                <span rtlDiv class="mobile-id-details-value"><span class="collapsed-row-title">{{ 'WORKPLAN.REPORT_TYPE' | translate }}: </span> {{element ? (data.exception_id_name_hebrew): ''}}</span>
                              }
                            </div>
                          </div>
                        }
                        @if (element.exceptions && element.exceptions.length == 0) {
                          <div class="dFlex">
                            @if (currentLang != 'iw' && getImg(element) && getImg(element).includes('collected2')) {
                              <span rtlDiv class="mobile-id-details-value"><span class="collapsed-row-title">{{ 'WORKPLAN.REPORT_TYPE' | translate }}: </span> {{element ? (element.removed_bin_reason_type_name != '' ? element.removed_bin_reason_type_name : element.exception_id_name): ''}}</span>
                            }
                            @if (currentLang == 'iw' && getImg(element) && getImg(element).includes('collected2')) {
                              <span rtlDiv class="mobile-id-details-value"><span class="collapsed-row-title">{{ 'WORKPLAN.REPORT_TYPE' | translate }}: </span> {{element ? (element.removed_bin_reason_type_name_hebrew != '' ? element.removed_bin_reason_type_name_hebrew : element.exception_id_name_hebrew): ''}}</span>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="expandedElement = expandedElement === element ? null : element"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
              </table>
              <div [hidden]="filteredData.length == 0">
                <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="6"></app-pagination>
              </div>
            </div>
            <div class="add-col">
              <div>
                <img rtlDiv class="dropdown-toggle addBtn" id="dropdownMenuButton" data-bs-toggle="dropdown" src="assets/images/dashboard/addCol.svg" alt=""/>
                <div class="dropdown-menu list-items" rtlDiv>
                  <div class="flexOnly" rtlDiv>
                    <input class="inputChx" rtlDiv [(ngModel)]="isNeighborhoodChecked" [checked]="neighborhood" (change)="toggleEditable(isNeighborhoodChecked)" type="checkbox" id="scalesNeighborhood" name="scalesNeighborhood">
                    <label for="scalesNeighborhood" class="txt">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</label>
                  </div>
                  <div class="flexOnly" rtlDiv [hidden]="usageType == 1">
                    <input class="inputChx" rtlDiv [(ngModel)]="isBinNameColChecked" [checked]="binNameCol" (change)="toggleEditableName(isBinNameColChecked)" type="checkbox" id="scalesName" name="scalesName">
                    <label for="scalesName" class="txt">{{ 'LIST_COLUMNS.NAME' | translate }}</label>
                  </div>
                  <div class="flexOnly" rtlDiv>
                    <input class="inputChx" rtlDiv [(ngModel)]="isBinIdColChecked" [checked]="binIdCol" (change)="toggleEditableId(isBinIdColChecked)" type="checkbox" id="scalesId" name="scalesId">
                    <label for="scalesId" class="txt">{{ 'LIST_COLUMNS.ID' | translate }}</label>
                  </div>
                  <div class="flexOnly" rtlDiv>
                    <input class="inputChx" rtlDiv [(ngModel)]="isBinTypeColChecked" [checked]="binTypeCol" (change)="toggleEditableType(isBinTypeColChecked)" type="checkbox" id="scalesType" name="scalesType">
                    <label for="scalesType" class="txt">{{ 'LIST_COLUMNS.BIN_TYPE' | translate }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  }

  @if (isMobile) {
    <div>
      <div class="accordion md-accordion" id="truckWorkplanAc" role="tablist" aria-multiselectable="true">
        <div class="card mobile-card">
          <div class="card-header mobile-header">
            <span class="mobile-title"> {{ 'TITLES.TRUCK_WORKPLAN_LIST_VIEW' | translate }}</span>
          </div>
          <div class="collapse show" role="tabpanel" data-parent="#truckWorkplanAc">
            <div class="card-body">
              <div>
                <div class="mobile-search-excel">
                  <div class='mobile-search-container'>
                    <img class='mobile-search-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                    <input class='mobile-input' type="text" (keyup)="applyFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
                      id="mobile-truck-list" rtlDiv>
                    </div>
                    <div class='mobile-table-export-container' rtlDiv>
                      <div class='mobile-table-export-btns-wrapper' (click)="exportToCsv()" rtlDiv>
                        <img class='mobile-table-export-img' src="assets/images/dashboard/Excel.svg" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="spinner-border mobile-spinner" role="status" [hidden]="!spinnerActive">
                    <span class="sr-only"></span>
                  </div>              
                  @if (dataSource.filteredData.length > 0) {
                    <table class="mobile-table" rtlDiv #table mat-table [dataSource]="getTableData()" multiTemplateDataRows matSort matSortStart="asc"
                      matSortDisableClear (matSortChange)="sortData($event)" matSortActive="bin_name" matSortDirection="desc">
                      <ng-container matColumnDef="CollectionOrder">
                        <th [hidden]="!workplanListSelectedValues.isBinOrderColChecked" class="mobile-th" mat-sort-header="bin_order" mat-header-cell *matHeaderCellDef>{{ 'WORKPLAN.COLLECTION_ORDER' | translate }} </th>
                        <td [hidden]="!workplanListSelectedValues.isBinOrderColChecked" mat-cell *matCellDef="let element">
                          <span> {{element ? element.bin_order: ''}}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="name">
                        <th [hidden]="!workplanListSelectedValues.isBinNameChecked" class="mobile-th" mat-sort-header="bin_name" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.NAME' | translate }} </th>
                        <td [hidden]="!workplanListSelectedValues.isBinNameChecked" mat-cell *matCellDef="let element" (click)="goToBinLivePage(element)">
                          <span title="{{element ? element.bin_name : ''}}" class="cut-txt"> {{element ? element.bin_name : ''}}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="collected">
                        <th [hidden]="!workplanListSelectedValues.isCollected" class="mobile-th" mat-sort-header="collected" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.COLLECTED' | translate }}</th>
                        <td [hidden]="!workplanListSelectedValues.isCollected" mat-cell *matCellDef="let element">
                          <div rtlDiv class="mobile-status-value">
                            <img src="{{element ? getImg(element) : ''}}" alt="" />
                            @if (element.bin_collection_cause_id == 3) {
                              <img class='forced-ic' src="assets/images/dashboard/forced.svg" alt="" rtlDiv/>
                            }
                            @if (element.bin_collection_cause_id == 7) {
                              <img class='forced-ic' src="assets/images/workplans-page/binAdded.svg" alt="" rtlDiv/>
                            }
                            @if (element.collected == 2 && element.removed_bin_reason_type_id == 6) {
                              <img class='forced-ic' src="assets/images/workplans-page/binRemoved.svg" alt="" rtlDiv/>
                            }
                            @if (element.bin_collection_cause_id == 10) {
                              <img class='forced-ic' src="assets/images/workplans-page/system_added.svg" alt="" rtlDiv/>
                            }
                            @if (element.collected == 2 && element.removed_bin_reason_type_id == 15) {
                              <img class='forced-ic' src="assets/images/workplans-page/system_removed.svg" alt="" rtlDiv/>
                            }
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="id">
                        <th [hidden]="!workplanListSelectedValues.isBinIdColChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_id" class="mobile-th"> {{ 'LIST_COLUMNS.ID' | translate }} </th>
                        <td [hidden]="!workplanListSelectedValues.isBinIdColChecked" mat-cell *matCellDef="let element"> {{element ? element.bin_id: ''}} </td>
                      </ng-container>
                      <ng-container matColumnDef="type">
                        <th [hidden]="!workplanListSelectedValues.isBinTypeColChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_type" class="mobile-th">{{ 'LIST_COLUMNS.BIN_TYPE' | translate }}</th>
                        <td [hidden]="!workplanListSelectedValues.isBinTypeColChecked" mat-cell *matCellDef="let element"> {{element ? element.bin_type: ''}} </td>
                      </ng-container>
                      <ng-container matColumnDef="address">
                        <th [hidden]="!workplanListSelectedValues.isAddressChecked" mat-header-cell *matHeaderCellDef mat-sort-header="address" class="mobile-th"> {{ 'LIST_COLUMNS.ADDRESS' | translate }}</th>
                        <td [hidden]="!workplanListSelectedValues.isAddressChecked" mat-cell *matCellDef="let element"> {{element ? element.address: ''}} </td>
                      </ng-container>
                      <ng-container matColumnDef="neighborhood">
                        <th [hidden]="!workplanListSelectedValues.isNeighborhoodChecked" mat-header-cell *matHeaderCellDef mat-sort-header="neighborhood" class="mobile-th">{{'LIST_COLUMNS.NEIGHBORHOOD' | translate}}</th>
                        <td [hidden]="!workplanListSelectedValues.isNeighborhoodChecked" mat-cell *matCellDef="let element"> {{element ? element.neighborhood: ''}} </td>
                      </ng-container>
                      <ng-container matColumnDef="beforeCollectionCapacityInPercent">
                        <th [hidden]="!workplanListSelectedValues.isBeforeCollectionCapacityInPercent" mat-header-cell *matHeaderCellDef mat-sort-header="beforeCollectionCapacityInPercent" class="mobile-th">{{'WORKPLAN.CAP_AT_PICKUP' | translate}}</th>
                        <td [hidden]="!workplanListSelectedValues.isBeforeCollectionCapacityInPercent" mat-cell *matCellDef="let element"> {{element && element.beforeCollectionCapacityInPercent != '0' ? element.beforeCollectionCapacityInPercent: ''}}{{element && element.beforeCollectionCapacityInPercent != '0' ? capacitySymbol.replace(" ","") : ''}} </td>
                      </ng-container>
                      <ng-container matColumnDef="afterCollectionCapacityInPercent">
                        <th [hidden]="!workplanListSelectedValues.isAfterCollectionCapacityInPercent" mat-header-cell *matHeaderCellDef mat-sort-header="afterCollectionCapacityInPercent" class="mobile-th">{{'WORKPLAN.CAP_AFTER_PICKUP' | translate}}</th>
                        <td [hidden]="!workplanListSelectedValues.isAfterCollectionCapacityInPercent" mat-cell *matCellDef="let element"> {{element && element.beforeCollectionCapacityInPercent != '0' ? element.afterCollectionCapacityInPercent: ''}}{{element && element.beforeCollectionCapacityInPercent != '0' ? capacitySymbol.replace(" ","") : ''}}</td>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <th [hidden]="!workplanListSelectedValues.isStatus" mat-header-cell *matHeaderCellDef mat-sort-header="status" class="mobile-th">{{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                        <td [hidden]="!workplanListSelectedValues.isStatus" mat-cell *matCellDef="let element">
                          <div class="table-status-progress-bar-wrapper">
                            <div class="table-status-progress-line" [ngStyle]="getProgressBarStyleInternal(element)"></div>
                          </div>
                          <div class='table-status-progress-bar-title' rtlDiv>{{element ? element.bin_capacity_to_display: ''}}<span [hidden]=!element>{{capacitySymbol}}</span></div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="last_collection">
                        <th [hidden]="!workplanListSelectedValues.isCollectionTime" mat-header-cell *matHeaderCellDef mat-sort-header="plan_collection_time" class="mobile-th"> {{ 'LIVE_INFO.LIST_COLLECTION_TIME' | translate }} </th>
                        <td [hidden]="!workplanListSelectedValues.isCollectionTime" mat-cell *matCellDef="let element"> 
                          {{currentLang == 'iw' && element ? element.plan_collection_time_he : element.plan_collection_time}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="arrowDown">
                        <th class="mobile-th" mat-header-cell *matHeaderCellDef>
                          <img (click)="openColumnManaging()" rtlDiv src="assets/images/dashboard/addCol.svg" alt=""/>
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div rtlDiv class="mobile-status-value">
                            @if (expandedElement !== element) {
                              <img class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                            }
                            @if (expandedElement === element) {
                              <img class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                            }
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" rtlDiv>
                          <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="mobile-element-diagram" rtlDiv>
                              @if(!workplanListSelectedValues.isBinOrderColChecked){
                                <div class="dFlex">
                                  <div class="mobile-id-details-title">{{ 'WORKPLAN.COLLECTION_ORDER' | translate }}</div>
                                  <div class="mobile-id-details-value"> {{element ? element.bin_order: ''}} </div>
                                </div>
                              }
                              @if(!workplanListSelectedValues.isBinIdColChecked){
                                <div class="dFlex">
                                  <div class="mobile-id-details-title">{{ 'LIST_COLUMNS.ID' | translate }}</div>
                                  <div class="mobile-id-details-value"> {{element ? element.bin_id : ''}} </div>
                                </div>
                              }
                              @if(!workplanListSelectedValues.isBinTypeColChecked){
                                <div class="dFlex">
                                  <div class="mobile-address-details-title">{{ 'LIST_COLUMNS.BIN_TYPE' | translate }}</div>
                                  <div class="mobile-address-details-value"> {{element ? element.bin_type : ''}} </div>
                                </div>
                              }
                              @if(!workplanListSelectedValues.isAddressChecked){
                                <div class="dFlex">
                                  <div class="mobile-address-details-title">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</div>
                                  <div class="mobile-address-details-value"> {{element ? element.address : ''}} </div>
                                </div>
                              }
                              @if(!workplanListSelectedValues.isNeighborhoodChecked){
                                @if (element && element.neighborhood && element.neighborhood != '') {
                                  <div class="dFlex">
                                    <div class="mobile-address-details-title">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</div>
                                    <div class="mobile-address-details-value"> {{element ? element.neighborhood : ''}} </div>
                                  </div>
                                }
                              }
                              @if(!workplanListSelectedValues.isAfterCollectionCapacityInPercent){
                                <div class="dFlex">
                                  <div class="mobile-address-details-title">{{ 'WORKPLAN.CAP_AFTER_PICKUP' | translate }}</div>
                                  <div class="mobile-address-details-value"> {{element && element.beforeCollectionCapacityInPercent != '0' ? element.afterCollectionCapacityInPercent: ''}}{{element && element.beforeCollectionCapacityInPercent != '0' ? capacitySymbol.replace(" ","") : ''}} </div>
                                </div>
                              } 
                              @if(!workplanListSelectedValues.isBeforeCollectionCapacityInPercent){
                                <div class="dFlex">
                                  <div class="mobile-address-details-title">{{ 'WORKPLAN.CAP_AT_PICKUP' | translate }}</div>
                                  <div class="mobile-description-details-value"> {{element && element.beforeCollectionCapacityInPercent != '0' ? element.beforeCollectionCapacityInPercent: ''}}{{element && element.beforeCollectionCapacityInPercent != '0' ? capacitySymbol.replace(" ","") : ''}} </div>
                                </div> 
                              }      
                              @if(!workplanListSelectedValues.isStatus){
                                <div class="dFlex">
                                  <div class="mobile-description-details-title">{{'LIST_COLUMNS.STATUS'|translate}}</div>
                                  <div class="mobile-description-details-value">{{element ? element.bin_capacity_to_display : ''}}{{capacitySymbol.replace(" ","")}}</div>
                                </div>
                              }    
                              @if(!workplanListSelectedValues.isCollectionTime){
                                <div class="dFlex">
                                  <div class="mobile-collection-details-title">{{ 'LIVE_INFO.LIST_COLLECTION_TIME' | translate }}</div>
                                  <div class="mobile-collection-details-value"> {{element ? element.plan_collection_time : ''}} </div>
                                </div>
                              }        
                              @if(!workplanListSelectedValues.isCollected){
                                <div class="dFlex">
                                  <div class="mobile-collection-details-title">{{ 'LIST_COLUMNS.COLLECTED' | translate }}</div>
                                  <div class="mobile-collection-details-value"> 
                                    <div rtlDiv class="mobile-status-value">
                                      <img src="{{element ? getImg(element) : ''}}" alt="" />
                                      @if (element.bin_collection_cause_id == 3) {
                                        <img class='forced-ic' src="assets/images/dashboard/forced.svg" alt="" rtlDiv/>
                                      }
                                      @if (element.bin_collection_cause_id == 7) {
                                        <img class='forced-ic' src="assets/images/workplans-page/binAdded.svg" alt="" rtlDiv/>
                                      }
                                      @if (element.collected == 2 && element.removed_bin_reason_type_id == 6) {
                                        <img class='forced-ic' src="assets/images/workplans-page/binRemoved.svg" alt="" rtlDiv/>
                                      }
                                      @if (element.bin_collection_cause_id == 10) {
                                        <img class='forced-ic' src="assets/images/workplans-page/system_added.svg" alt="" rtlDiv/>
                                      }
                                      @if (element.collected == 2 && element.removed_bin_reason_type_id == 15) {
                                        <img class='forced-ic' src="assets/images/workplans-page/system_removed.svg" alt="" rtlDiv/>
                                      }
                                    </div>
                                  </div>
                                </div>
                              }                                          
                              @for (data of element.exceptions; track data) {
                                <div>
                                  <div class="dFlex">
                                    @if (currentLang != 'iw' && getImg(element) && getImg(element).includes('missed')) {
                                      <span class="dFlex" rtlDiv><span class="mobile-collection-details-title">{{ 'WORKPLAN.REPORT_TYPE' | translate }}: </span> <span class="mobile-collection-details-value">{{data ? getExcName(data.exception_id_name) : ''}}</span></span>
                                    }
                                    @if (currentLang == 'iw' && getImg(element) && getImg(element).includes('missed')) {
                                      <span class="dFlex" rtlDiv><span class="mobile-collection-details-title">{{ 'WORKPLAN.REPORT_TYPE' | translate }}: </span> <span class="mobile-collection-details-value">{{data ? getExcName(data.exception_id_name_hebrew) : ''}}</span></span>
                                    }
                                  </div>
                                  <div class="dFlex">
                                    @if (currentLang != 'iw' && getImg(element) && getImg(element).includes('collected2')) {
                                      <span class="dFlex" rtlDiv><span class="mobile-collection-details-title">{{ 'WORKPLAN.REPORT_TYPE' | translate }}: </span> <span class="mobile-collection-details-value">{{element ? (data.exception_id_name): ''}}</span></span>
                                    }
                                    @if (currentLang == 'iw' && getImg(element) && getImg(element).includes('collected2')) {
                                      <span class="dFlex" rtlDiv><span class="mobile-collection-details-title">{{ 'WORKPLAN.REPORT_TYPE' | translate }}: </span> <span class="mobile-collection-details-value">{{element ? (data.exception_id_name_hebrew): ''}}</span></span>
                                    }
                                  </div>
                                </div>
                              }
                              @if (element.exceptions && element.exceptions.length == 0) {
                                <div class="dFlex">
                                  @if (currentLang != 'iw' && getImg(element) && getImg(element).includes('collected2')) {
                                    <span class="dFlex" rtlDiv><span class="mobile-collection-details-title">{{ 'WORKPLAN.REPORT_TYPE' | translate }}: </span> <span class="mobile-collection-details-value">{{element ? (element.removed_bin_reason_type_name != '' ? element.removed_bin_reason_type_name : element.exception_id_name): ''}}</span></span>
                                  }
                                  @if (currentLang == 'iw' && getImg(element) && getImg(element).includes('collected2')) {
                                    <span class="dFlex" rtlDiv><span class="mobile-collection-details-title">{{ 'WORKPLAN.REPORT_TYPE' | translate }}: </span> <span class="mobile-collection-details-value">{{element ? (element.removed_bin_reason_type_name_hebrew != '' ? element.removed_bin_reason_type_name_hebrew : element.exception_id_name_hebrew): ''}}</span></span>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" (click)="expandedElement = expandedElement === element ? null : element"></tr>
                      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                    </table>
                  }
                  <div [hidden]="filteredData.length == 0">
                    <app-pagination [chosenBins]="filteredData" (pageChange)='onPageChange($event)' [filesPerPage]="6"></app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslatorService } from '../../../services/translator_service';
import { ApiStore } from '../../../web-services/api/api.store';
import { ApiService } from '../../../web-services/api/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-add-user-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,CommonModule,
    MatSelectModule,MatInputModule],
  templateUrl: './add-user-modal.component.html',
  styleUrl: './add-user-modal.component.scss'
})
export class AddUserModalComponent {
  ElForm: FormGroup;
	currentLang;
	translationsObj;
  isMobile: Boolean;
  userRoles:any[] = [];
  sitesList:any[] = [];
  usageTypes:any[] = [];
  capacityDisplayTypes:any[] = [];
	userAccounts:any[] = [];
  options: FormGroup;
	submitClicked : boolean = false;
  password_error : boolean = false;
  email_error : boolean = false;
  user_name_error : boolean = false;
  
  constructor(private dialogRef: MatDialogRef<AddUserModalComponent>,private translator: TranslatorService,private fb: FormBuilder,
    private responsiveService: ResponsiveService,@Inject(MAT_DIALOG_DATA) public data: any,private apiQuery:ApiQuery,
    private apiService:ApiService,private apiStore:ApiStore) {
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
  
    this.options = fb.group({
      floatLabel: 'never'
    });

    this.ElForm = this.fb.group({
      userName:new FormControl('', Validators.required),
      email:new FormControl('',{ validators: [Validators.required, Validators.email] }),
      password:new FormControl('', Validators.required),
      firstName:new FormControl(''),
      lastName:new FormControl(''),
      phoneNumber:new FormControl('',Validators.required),
      company:new FormControl(''),
      permissions:new FormControl('', Validators.required),
      sitesList:new FormControl('', Validators.required),
      usageType:new FormControl('', Validators.required),
      capacityDisplayType:new FormControl('', Validators.required),
      userAccount:new FormControl('', Validators.required)
    });
   }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.submitClicked = false;

    this.apiQuery.userRoles$.subscribe((v:any) => {	       
      if(v.length == 0) {return;}    
      this.userRoles = v;  
    });

    this.apiQuery.sitesListData$.subscribe((sitesList) => {
			if(sitesList.length == 0) return;
			this.sitesList = sitesList; 
		});	

    this.apiQuery.usageTypes$.subscribe((type) => {
      if(type.length == 0){return;}  
      this.usageTypes = type;                                                 
    });

    this.apiQuery.capacityDisplayTypes$.subscribe((type) => {
      if(type.length == 0){return;}  
      this.capacityDisplayTypes = type;                                                    
    });

    this.apiQuery.userAccounts$.subscribe((accounts) => {
      if(accounts.length == 0){return;}  
      this.userAccounts = accounts;
      if(this.userAccounts.length == 1){
        this.ElForm.controls['userAccount'].setValue(Number(this.userAccounts[0]["account_id"]));
      }        
    });
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  checkLength(e){
    if(e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  }
  
  applyChanges(){
		if(!this.ElForm.valid || this.submitClicked){
			return;
		}
		this.submitClicked = true;
    let request = {};
    request["email_address"] = this.ElForm.getRawValue()["email"];
    request["user_password"] = this.ElForm.getRawValue()["password"];
    request["user_first_name"] = this.ElForm.getRawValue()["firstName"];
    request["user_last_name"] = this.ElForm.getRawValue()["lastName"]; 
    request["user_name"] = this.ElForm.getRawValue()["userName"]; 
    request["account_id"] = this.ElForm.getRawValue()["userAccount"]; 
    request["user_phone_number"] = this.ElForm.getRawValue()["phoneNumber"]; 
    request["user_role_id"] = this.ElForm.getRawValue()["permissions"];
    request["user_time_zone"] = '';
    request["sites_id_list"] = this.ElForm.getRawValue()["sitesList"];
    request["notification_id_list"] = [];
    request["is_web_notification"] = 0;
    request["is_email"] = 0;
    request["is_sms"] = 0;
    request["usage_type_id"] = this.ElForm.getRawValue()["usageType"];
    request["capacity_display_type_id"] = this.ElForm.getRawValue()["capacityDisplayType"];
    request["default_lang_id"] = 2;
    request["user_company"] = this.ElForm.getRawValue()["company"];
    
    this.apiService.insertNewUser(request).subscribe((v:any) => {   
      if(v.createUserStatus == 1){
        this.password_error = false;
        this.email_error = false;
        this.user_name_error = false;
        this.apiStore.update({ filteredUsers: [] });	
        this.apiService.getfilteredUsersInfo(JSON.parse(sessionStorage.getItem("chosenSites")));               
        this.closeModal(); 
      }else if(v.createUserStatus == 2){
        this.password_error = true;
        this.email_error = false;
        this.user_name_error = false;
        this.submitClicked = false;
        setTimeout(function(){ 
					var el = document.getElementById('error-msg');
					el.scrollIntoView({behavior: "smooth"});
				}, 100);
      }else if(v.createUserStatus == 3){
        this.password_error = false;
        this.email_error = false;
        this.user_name_error = true;
        this.submitClicked = false;        
        setTimeout(function(){ 
					var el = document.getElementById('error-msg');
					el.scrollIntoView({behavior: "smooth"});
				}, 100);     
      }else if(v.createUserStatus == 4){
        this.password_error = false;
        this.email_error = true;
        this.user_name_error = false;
        this.submitClicked = false;        
        setTimeout(function(){ 
					var el = document.getElementById('error-msg');
					el.scrollIntoView({behavior: "smooth"});
				}, 100);     
      }
    });
	}
  	
	closeModal(){
		this.dialogRef.close();
	}
}

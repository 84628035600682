import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InstallNewBinComponent } from '../install-new-bin/install-new-bin.component';

@Component({
  selector: 'app-fast-installation',
  standalone: true,
  imports: [InstallNewBinComponent],
  templateUrl: './fast-installation.component.html',
  styleUrl: './fast-installation.component.scss'
})
export class FastInstallationComponent {

  routeParamsSub;
  siteId: any;
  isSwap: boolean = true;
  isFastInstallation: boolean = true;
  binId: number = 0;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.routeParamsSub = this.route
			.queryParams
			.subscribe(params => {
        this.siteId = this.route.snapshot.paramMap.get("id");
        this.binId = Number(this.route.snapshot.paramMap.get("binId"));
      });
   }

  ngOnInit() {
  }
}

@if (!isMobile) {
  <div class="collection-report">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="title">
          @if (usageType == 1) {
            <span>{{ 'TITLES.BIN_LOCATION_DESCRIPTION' | translate }}</span>
          }
          @if (usageType != 1) {
            <span>{{ 'TITLES.TANK_LOCATION_DESCRIPTION' | translate }}</span>
          }
        </mat-card-title>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <mat-card-content>
        <div class='bin-location-container'>
          <div class='bin-info'>
            <span [ngClass]="{'cut-txt' : binName.length > 30}" title="{{binName}}">{{binName}}</span>
            @if (usageType == 1) {
              <span>&nbsp;| {{ 'LIVE_INFO.BIN_ID' | translate }} {{binId}}</span>
            }
            @if (usageType != 1) {
              <span>&nbsp;| {{ 'LIVE_INFO.TANK_ID' | translate }} {{binId}}</span>
            }
            @if (binData) {
              <span>&nbsp;| {{binData.Bin_location.bin_address}}</span>
            }
          </div>
          <div class='bin-info-description'>{{ binData && binData.bin_description ? binData.bin_description : ''}}</div>
          <google-map id="map" [options]="mapOptions" (mapInitialized)="loadAPIWrapper($event)"></google-map>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
    <div class="accordion md-accordion" id="binLocationDescAc" role="tablist" aria-multiselectable="true">
      <div class="card mobile-card">
        <div class="card-header mobile-header">
          <div class="mobile-flex">
            @if (usageType == 1) {
              <span class="mobile-title">{{ 'TITLES.BIN_LOCATION_DESCRIPTION' | translate }}</span>
            }
            @if (usageType != 1) {
              <span class="mobile-title">{{ 'TITLES.TANK_LOCATION_DESCRIPTION' | translate }}</span>
            }
          </div>
        </div>
        <div class="collapse show" role="tabpanel" data-parent="#binLocationDescAc">
          <div class="card-body">
            <div>
              <div class='mobile-header-right' rtlDiv>
                <span class="mobile-binName" [ngClass]="{'cut-txt' : binName.length > 30}" title="{{binName}}">{{binName}}</span>
                @if (usageType == 1) {
                  <span class="mobile-binId">&nbsp;| {{ 'LIVE_INFO.BIN_ID' | translate }} {{binId}}</span>
                }
                @if (usageType != 1) {
                  <span class="mobile-binId">&nbsp;| {{ 'LIVE_INFO.TANK_ID' | translate }} {{binId}}</span>
                }
                @if (binData) {
                  <span class="mobile-binId">&nbsp;| {{binData.Bin_location.bin_address}}</span>
                }
              </div>
              <div class='mobile-info-desc'>{{ binData && binData.bin_description ? binData.bin_description : ''}}</div>
              <google-map [options]="mobileMapOptions" (mapInitialized)="loadAPIWrapper($event)" class="mobile-map"></google-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
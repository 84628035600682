import { Component, ElementRef, Inject, NgModule, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, NgModel } from '@angular/forms';
import { ApiService } from '../../../web-services/api/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslatorService } from '../../../services/translator_service';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ApiStore } from '../../../web-services/api/api.store';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { WorkplansPopupApprovalComponent } from '../workplans-popup-approval/workplans-popup-approval.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCardModule } from '@angular/material/card';
import { DatepickerCustomComponent } from '../../../widgets/datepicker-custom/datepicker-custom.component';

@Component({
  selector: 'app-create-new-workplan-modal',
  host: {
		'(document:click)': 'handleClick($event)',
	},
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,CommonModule,WorkplansPopupApprovalComponent,MatInputModule,
    MatFormFieldModule,MatSelectModule,NgbModule,FormsModule,MatCardModule,DatepickerCustomComponent],
  templateUrl: './create-new-workplan-modal.component.html',
  styleUrl: './create-new-workplan-modal.component.scss'
})
export class CreateNewWorkplanModalComponent {
	closeHour: boolean;
	closeMinute: boolean;
	clickMinuteCounter: any;
	trucksErrorFlag: boolean;
	missingSiteSelection: boolean;
	missingTruckSelection: boolean;
	isTruckListShowAll: boolean;
	sitesErrorFlag: boolean;
	selectedTrucks: any[] = [];
	repeatNumber:any = 0;
	clickHourCounter: number;
	closeTimer: boolean;
	elementRef: ElementRef<any>;
	allSitesSelected: boolean;
	allTrucksSelected: boolean;
	sites = new FormControl();
	selectedSites: any[] = [];
	selectedhours: any[];
	sitesListData: any[];
	exist:boolean = false;
	showWorkplanPopupApproval: Boolean = false;
	showHideDropdownLists: any = {
		subSites: false,
		trucks: false,
		timeHour: false,
		timeMinute: false,
		collectionMethod: false
	};
	daySelected: any = {
		today: true,
		tomorrow: false
	};

	defaultSite = {
		site_name: 'Run on all Sites',
		site_id: null,
		isAll: true
	};

	defaultTruck = {
		tablet_id: 0,
		site_id: 0,
		isAll: true
	}

	selectedWorkplanTime = {
		hour: '08',
		minute: '00'
	};

	workplanTime = {
		hours: [],
		minutes: []
	};

	selectedCollectionMethod: any = {
		distrebution_method_name: ''
	};

	showTimeErrorText: Boolean = false;

	selectedSite: any = this.defaultSite;
	selectedTruck: any;

	trucksListData: any[];
	trucksListDataToShow: any[];

	dailyWorkplanPlanId: any = 0;
	translate;
	currentLang;
	translationsObj;
	showDatePicker: Boolean = false;
	selectedDates: any = {
		date: '',
		dateformat: ''
	};
	@ViewChild('siteMatSelect',{static: false}) siteMatSelect;
	@ViewChild('truckMatSelect',{static: false}) truckMatSelect;
	dateLbl:any = '';
	chosenPeriod:any;
	is_view_unexecuted_work_plans:any = 0;
	usageType = 1;
	isMobile: Boolean;
	weekDays : Array<any> = [];

	constructor(private apiService:ApiService, myElement: ElementRef, private translator: TranslatorService,private apiStore:ApiStore,
		private apiQuery:ApiQuery,@Inject(MAT_DIALOG_DATA) public data: any,private responsiveService: ResponsiveService,
		private dialogRef: MatDialogRef<CreateNewWorkplanModalComponent>,
		private fb: FormBuilder) {
		this.dialogRef.disableClose = true;
		this.elementRef = myElement;
		this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
			this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			this.currentLang = value;
			this.dateLbl = this.translationsObj.WORKPLAN.TODAY;				
			if(this.data.dayClicked == undefined){
				return;
			}
			else{
				if(new Date(moment().format("YYYY-MM-DD 00:00:00")).getTime() != new Date(this.data.dayClicked).getTime()){
					this.selectedDates.date = moment(this.data.dayClicked).format('DD/MM/YYYY');
					this.selectedDates.dateformat = moment(this.data.dayClicked).format('YYYY-MM-DD');	
					this.dateLbl = this.selectedDates.date;				
				}else{
					this.dateLbl = this.translationsObj.WORKPLAN.TODAY;				
				}
			}

			this.defaultSite = {
				site_name: this.translationsObj.NEW_WORKPLAN.RUN_ON_ALL_SITES,
				site_id: null,
				isAll: true
			};

			this.defaultTruck = {
				tablet_id: this.translationsObj.NEW_WORKPLAN.RUN_ON_ALL_SITES,
				site_id: 0,
				isAll: true
			}
		})
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.weekDays = [
			{ displayName: 'NEW_WORKPLAN.SUNDAY_COLLECTION', selected: false, color:"#ECECEC"},
			{ displayName: 'NEW_WORKPLAN.MONDAY_COLLECTION', selected: false, color:"#ECECEC" },
			{ displayName: 'NEW_WORKPLAN.TUESDAY_COLLECTION', selected: false, color:"#ECECEC" },
			{ displayName: 'NEW_WORKPLAN.WEDNESDAY_COLLECTION', selected: false, color:"#ECECEC" },
			{ displayName: 'NEW_WORKPLAN.THURSDAY_COLLECTION', selected: false, color:"#ECECEC" },
			{ displayName: 'NEW_WORKPLAN.FRIDAY_COLLECTION', selected: false, color:"#ECECEC" },
			{ displayName: 'NEW_WORKPLAN.SATURDAY_COLLECTION', selected: false, color:"#ECECEC" }
		];
		this.chosenPeriod = this.data.periods[1].value;
		this.apiQuery.user$.subscribe(user => {
			if (!user) return;

			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
			this.is_view_unexecuted_work_plans = user["is_view_unexecuted_work_plans"];			
		});

		this.buildWorkplanTime();
		this.allSitesSelected = false;
		this.clickHourCounter = 0;
		this.clickMinuteCounter = 0;
		this.resetSelectedTime();
	}

	ngAfterViewInit() {	
		combineLatest(
			this.apiQuery.filteredTrucks$,
			this.apiQuery.sitesListData$
		  )
		  .pipe()
		  .subscribe(([trucksList,sitesList])=>{	
			if (trucksList == null || trucksList.length == 0) return;
			if (sitesList == null || sitesList.length == 0) return;
			this.selectedTrucks = [];
			this.selectedSites = [];			
			this.subscribeTrucksList(trucksList);
			this.subscribeSitesList(sitesList);
		});
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

	selectUnselectItem(item) {
		item.selected = !item.selected;
	}

	changeValue(value){		
    	this.chosenPeriod = value;
  	}

	public showHideDatePicker = () => {
		this.showDatePicker = !this.showDatePicker;
	}

	public onSelectDates = (selectDates: any) => {
		this.selectedDates.date = selectDates.date;
		this.selectedDates.dateformat = selectDates.dateformat;	
		this.dateLbl = this.selectedDates.date;	
		this.buildWorkplanTime();
	}

	private subscribeTrucksList = (trucksList) => {
		this.trucksListData = trucksList;
		this.trucksListDataToShow = trucksList;
	}

	private resetSelectedTime = () => {
		const timeNowHour = moment().add(1, 'hours').format('HH');
		this.selectedWorkplanTime = {
			hour: timeNowHour,
			minute: '00'
		};
	}

	private subscribeSitesList = (sitesList: any[]) => {
		this.sitesListData = sitesList;
		_.each(this.sitesListData, item => {
			if (item.site_id == null) {
				this.exist = true;
			}			 
		});
		if(this.sitesListData.length > 1){
			if(!this.exist){
				this.sitesListData = [this.defaultSite,...this.sitesListData];
			}
			this.selectedSite = this.defaultSite;
		}else if(this.sitesListData.length == 1){
			this.selectAllSites(this.siteMatSelect.ngControl,this.sitesListData,this.sitesListData[0]);
		}
	}

	public closeModal = () => {
		if (this.showWorkplanPopupApproval) {
			this.showWorkplanPopupApproval = false;
			return;
		}
		this.resetSelectedTime();
		this.selectedSite = this.defaultSite;
		this.openDropDownList('');
		this.dialogRef.close();
		this.sitesErrorFlag = false;
		this.trucksErrorFlag = false;
		this.showTimeErrorText = false;
	}

	public openDropDownList = (list_name: string) => {
		_.each(this.showHideDropdownLists, (value, key) => {
			if (key === list_name) {
				this.showHideDropdownLists[list_name] = !value;
			} else {
				this.showHideDropdownLists[key] = false;
			}
		});
	}

	private buildWorkplanTime = () => {
		this.workplanTime.hours = [];
		this.workplanTime.minutes = [];
		if(new Date(moment().format("YYYY-MM-DD 00:00:00")).getTime() == new Date(moment(this.selectedDates.dateformat).format("YYYY-MM-DD 00:00:00")).getTime()){			
			const timeNowHour = parseInt(moment().add(1, 'hours').format('HH'));
			for (let i = 0; i <= 59; i++) {
				if ((i >= 0 && i <= 9) && (i >= timeNowHour)) {
					this.workplanTime.hours.push(`0${i}`);
					this.workplanTime.minutes.push(`0${i}`);
				} else {
					if (i < 24 && i >= timeNowHour) {
						this.workplanTime.hours.push(`${i}`);
					}else{
						if ((i >= 0 && i <= 9)){
							this.workplanTime.minutes.push(`0${i}`);
						}else{
							this.workplanTime.minutes.push(`${i}`);
						}
					}
				}
			}
		}else{			
			for (let i = 0; i <= 59; i++) {
				if ((i >= 0 && i <= 9)) {
					this.workplanTime.hours.push(`0${i}`);
					this.workplanTime.minutes.push(`0${i}`);
				} else {
					if (i < 24) {
						this.workplanTime.hours.push(`${i}`);
					}
					this.workplanTime.minutes.push(`${i}`);
				}
			}
		}
		if(this.workplanTime != null){
			this.selectWorkplanTime(this.workplanTime.hours[0],"hour");
			this.selectWorkplanTime(this.workplanTime.minutes[0],"minute");
		}
	}

	public selectWorkplanTime = (time, type) => {
		this.showTimeErrorText = false;
		this.selectedWorkplanTime[type] = time;
	}

	private isStartTimeValid = () => {
		const timeNowHour = moment().format('HH');
		return this.selectedWorkplanTime.hour >= timeNowHour;
	}

	public saveWorkplan = () => {
		if (this.selectedSites.length == 0) {
			this.sitesErrorFlag = true;
			if(!this.trucksErrorFlag){
				this.missingSiteSelection = true; 
			}
		}
		else {
			this.sitesErrorFlag = false;
			this.missingSiteSelection = false;
		}
		if(this.dateLbl == this.translationsObj.WORKPLAN.TODAY){
			if (this.selectedTrucks.length == 0 && this.selectedSites.length != 0) {
				this.trucksErrorFlag = true;
			}
			else {
				this.trucksErrorFlag = false;
			}
		}
		if(this.sitesErrorFlag || this.trucksErrorFlag){
			return;
		}
		if(new Date(moment().format("YYYY-MM-DD 00:00:00")).getTime() == new Date(moment(this.selectedDates.dateformat).format("YYYY-MM-DD 00:00:00")).getTime()){
			if (!this.isStartTimeValid()) {
				this.showTimeErrorText = true;
				return;
			}
		}
		if (!this.sitesErrorFlag && !this.trucksErrorFlag && this.dateLbl == this.translationsObj.WORKPLAN.TODAY) {
			this.showWorkplanPopupApproval = true;
		}else{
			this.approveCancelWorkplanChanges(true);
		}
	}

	public approveCancelWorkplanChanges = (ans: boolean) => {
		this.showWorkplanPopupApproval = false;

		if (!ans) {
			return;
		}
		//if Today
		if(moment().format("YYYY-MM-DD 00:00:00").valueOf() == moment(this.selectedDates.dateformat + " " + this.selectedWorkplanTime.hour + ":" + this.selectedWorkplanTime.minute + ":00").format("YYYY-MM-DD 00:00:00").valueOf()){  	
			const startTime = moment(`${this.selectedWorkplanTime.hour}:${this.selectedWorkplanTime.minute}`, 'HH:mm').format('YYYY/MM/DD HH:mm:ss');
			const dataToSend = {
				plan_start_time: startTime,
				sites_info: []
			};

			_.each(this.selectedSites, site => {
				if(site.site_id != null){
					let obj = {
						site_id: site.site_id,
						tabletIdList: []
					}
					let trucksPerSite = _.filter(this.selectedTrucks, (truck) => {
						return truck.site_id === site.site_id;
					});
					_.each(trucksPerSite, truck => {
						obj.tabletIdList.push(truck.tablet_id);
					});
					dataToSend.sites_info.push(obj);
				}
			});
			this.apiStore.update({ allDailyWorkPlanCalanderData: [] });
			this.apiStore.update({ dailyWorkPlanCalanderData: [] });
			this.apiStore.update({ allWorkPlanScheduler: [] });
			this.apiStore.update({ workPlanScheduler: [] });
			this.apiStore.update({ allFilteredDailyWorkPlan: [] });
			this.apiStore.update({ filteredDailyWorkPlan: [] });
			this.apiService.createWorkplan(dataToSend).subscribe((v:any) => {  
				let sitesIds = [];
				_.each(this.selectedSites, site => {
					sitesIds.push(site.site_id)
				});
				let request = {
					site_id_list : sitesIds,
					start_date : this.selectedDates.dateformat + " " + this.selectedWorkplanTime.hour + ":" + this.selectedWorkplanTime.minute + ":00",
					repeat_multiplex : this.repeatNumber,
					repeat_time_span : this.chosenPeriod,			
					}
				
				this.apiService.SetWorkPlanScheduler(request).subscribe((v:any) => {  
					if(v.insert_status){
						let userGeneratedWorkplans = {
							site_id:0
						}	
						let start_date = moment().subtract(2, 'months').format('YYYY-MM-DD HH:MM:59');
						let end_date = moment().format('YYYY-MM-DD HH:MM:59');
						this.apiService.getDailyWorkPlanCalanderData(start_date,end_date,this.is_view_unexecuted_work_plans,JSON.parse(sessionStorage.getItem("chosenSites")));
						this.apiService.getfilteredWorkPlanScheduler(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));
						this.apiService.getfilteredActivePlans(JSON.parse(sessionStorage.getItem("chosenSites")));
						this.apiStore.update({ createdWorkplan: dataToSend.sites_info });
					}			
				});  		
			});   		
		}else{
			let sitesIds = [];
			_.each(this.selectedSites, site => {
				sitesIds.push(site.site_id)
			});
			let request = {
				site_id_list : sitesIds,
				start_date : this.selectedDates.dateformat + " " + this.selectedWorkplanTime.hour + ":" + this.selectedWorkplanTime.minute + ":00",
				repeat_multiplex : this.repeatNumber,
				repeat_time_span : this.chosenPeriod,			
			}
			this.apiStore.update({ allWorkPlanScheduler: [] });
			this.apiStore.update({ workPlanScheduler: [] });
			this.apiService.SetWorkPlanScheduler(request).subscribe((v:any) => {  
				if(v.insert_status){
					let userGeneratedWorkplans = {
						site_id:0
					}		
					this.apiService.getfilteredWorkPlanScheduler(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));
				}			
			});   
		}
	
		this.closeModal();
	}

	selectAllSites(select: NgModel, values, item) {
		if(this.selectedSites.length > 0){
			this.sitesErrorFlag = false;
		}

		if(this.selectedTrucks.length > 0){
			this.selectedTrucks = [];
		}

		if (item.isAll) {
			select.update.emit(values);
		}
		else {
			let selectedSiteArray = [];
			selectedSiteArray.push(item);
			select.update.emit(selectedSiteArray);
		}

		this.missingSiteSelection = false;
		this.allSitesSelected = false;
		this.siteMatSelect.close();
		this.updateTruckListPerSiteSelection();
	}


	updateTruckListPerSiteSelection() {
		this.trucksListDataToShow = [];
		this.selectedSites.forEach((element, index, array) => {
			if (!element.isAll) {
				this.trucksListDataToShow = [...this.trucksListDataToShow, ..._.filter(this.trucksListData, (truck) => {
					return truck.site_id === element.site_id;
				})];
			}
		});
		if(this.trucksListDataToShow.length != 1){
			this.trucksListDataToShow.unshift(this.defaultTruck);
		}else{
			this.updateTruckFlag(this.truckMatSelect.ngControl,this.trucksListDataToShow,this.trucksListDataToShow[0]);
		}
	}

	updateTruckFlag(select: NgModel, values, item) {
		if (this.selectedTrucks.length > 0) {
			this.trucksErrorFlag = false;
		}

		if (this.trucksListDataToShow.length === 2) {
			if (this.missingTruckSelection === undefined) {
				select.update.emit(values);
				this.isTruckListShowAll = true;
			}
			else {
				if (this.isTruckListShowAll) {
					select.update.emit([]);
					this.isTruckListShowAll = false;
				}
				else {
					select.update.emit(values);
					this.isTruckListShowAll = true;
				}
			}
		}
		else {
			if (item.isAll) {
				this.allTrucksSelected = true;
			}
			else {
				if ((this.trucksListDataToShow.length - 1 === this.selectedTrucks.length) && (this.trucksListDataToShow.length !== 2)) {
					this.allTrucksSelected = true;
				}
			}
	
			if (this.allTrucksSelected) {
				if ((this.trucksListDataToShow.length - 1 === this.selectedTrucks.length)) {
					if (item.isAll) {
						select.update.emit([]);
					}
					else if(this.selectedTrucks[0] != undefined){
						if (this.selectedTrucks[0].isAll) {
							let selectedTruckArray = [];
							selectedTruckArray.push(item);
							select.update.emit(selectedTruckArray);
						}
					}
					else {
						select.update.emit(values);
					}
				}
				else if (!item.isAll) {
				}
				else {
					select.update.emit(values);
				}
	
			}
			else {
				if (item.isAll) {
					select.update.emit([]);
				}
				else {
				}
			}
		}
		this.missingTruckSelection = false;
	}

	itemIsChooseAll(item) {
		return item.isAll;
	}

	public opendropDown = (list_name: string) => {
		_.each(this.showHideDropdownLists, (value, key) => {
			if (key === 'trucks') {
				this.showHideDropdownLists['trucks'] = true;
			}
		});
	}

	handleClick(event) {
		let clickedComponent = event.target;
		let insideHour = false;
		let insideMinute = false;
		do {
			if (clickedComponent.className === "time-btn left") {
				insideHour = true;
				this.clickHourCounter++;
				break;
			}
			if (clickedComponent.className === "time-btn right") {
				insideMinute = true;
				this.clickMinuteCounter++;
				break;
			}
			if (clickedComponent.className === "")
				break;
			clickedComponent = clickedComponent.parentNode;
		} while (clickedComponent);

		if (insideHour) {
			this.closeMinute = true;
			this.closeHour = false;
			this.openDropDownList('timeHour');
		}
		if (insideMinute) {
			this.closeHour = true;
			this.closeMinute = false;
			this.openDropDownList('timeMinute');
		}
		if (!insideMinute && !insideHour) {
			this.closeHour = true;
			this.closeMinute = true;
		}
	}

	checkTruckValidity() {
		if (this.selectedSites.length === 0) {
			if(!this.trucksErrorFlag){
				this.missingSiteSelection = true;
			}
		}
		else {
			this.missingSiteSelection = false;
		}
	}
}

@if (!isMobile) {
  <div>
    <div class="inventory-page" rtlDiv>
      <div class="dflex-row">
        <div class="inventory-header" rtlDiv>
          {{'INSIGHTS.BLACK_STATUS' | translate }}
        </div>
        <div class="black-status-func-container" rtlDiv>
          <form class="blackStatus" [formGroup]="ElForm" rtlDiv>
            <div class="flex-edit">
              <div >{{ 'INSIGHTS.VOLUME' | translate }}</div>
              <span>-</span>
              @if (!editBlackStatusFlag) {
                <div class="balck-status-input-frame">
                  <input min="50" max="100" class="black-status-input" type="number" matInput formControlName="blackStatusBin" rtlDiv>
                  <img [ngClass]="{'noClick': ElForm.getRawValue()['blackStatusBin'] < 50 || ElForm.getRawValue()['blackStatusBin'] > 100,'enableBtn': ElForm.getRawValue()['blackStatusBin'] >= 50 && ElForm.getRawValue()['blackStatusBin'] <= 100}" (click)="submitBlackStatus()" src="assets/images/inventory/submit-V.svg" alt=""/>
                </div>
              }
              @if (editBlackStatusFlag) {
                <div class="black-status-value">
                  <div>{{blackStatusValue}}%</div>
                  <img class="cursor-pointer" (click)="editBlackStatus()" src="assets/images/inventory/edit.svg" alt=""/>
                </div>
              }
            </div>
          </form>
          <div class='datepicker-wrapper' rtlDiv>
            <div class='selected-dates' (click)='showHideDatePicker()'>
              <img [ngClass]="{'transform-arrow' : currentLang == 'iw'}" src="assets/images/dashboard/Previous.svg" alt="Previous" rtlDiv>
              <div class="dates-range datepicker-rtl" rtlDiv>
                {{selectedDates.from}} - {{selectedDates.to}}
              </div>
              <img [ngClass]="{'transform-arrow' : currentLang == 'iw'}" src="assets/images/dashboard/Next.svg" alt="Next" rtlDiv>
            </div>
            <div class="datepicker-frame" rtlDiv>
              <app-datepicker-range [hidden]='!showDatePicker' (selectDates)='onSelectDates($event)'></app-datepicker-range>
            </div>
          </div>
        </div>
      </div>
      <mat-divider class="top-divider"></mat-divider>
    </div>
    <div class="insights-content">
      <div class="first-row">
        <div class="sampling-info">
          <app-black-status-sampling></app-black-status-sampling>
        </div>
        <div class="status-info">
          <app-black-status-pie></app-black-status-pie>
          <app-black-status-duration></app-black-status-duration>
        </div>
      </div>
      <div class="second-row">
        <app-black-status-list-view></app-black-status-list-view>
      </div>
    </div>
  </div>
}

@if (isMobile) {
  <div>
  </div>
}
import { Component } from '@angular/core';
import { TranslatorService } from '../../../services/translator_service';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-logout-validation',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,MatDialogModule],
  templateUrl: './logout-validation.component.html',
  styleUrl: './logout-validation.component.scss'
})
export class LogoutValidationComponent {
  translationsObj;
	currentLang;
  constructor(private translator: TranslatorService) {
    this.translator.currentLangEmitter$
    .subscribe(async value=>{						
      this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
      this.currentLang = value;	 
    })
   }

  ngOnInit() {
  }
}

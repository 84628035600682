@if (!isMobile) {
  <div>
    <div class="driver-page" rtlDiv>
      <div class="title">
        {{'DRIVER_PLAN.CHOOSE_SITE' | translate}}
      </div>
      <div class="plans-list">
        @for (site of sitesListFilter; track site) {
          <div rtlDiv>
            <div class="list-item" rtlDiv>
              <div class="site-info">
                <div class="site_name">{{site.site_name}}</div>
                <div>
                  <div class="dflex">
                    <div class="row-title">{{'DRIVER_PLAN.TRUCK_NAME' | translate}}</div>
                    <div class="row-val">-</div>
                    <div class="row-val">{{site.tablet_id}}</div>
                  </div>
                  <div class="dflex">
                    <div class="row-title">{{'DRIVER_PLAN.BINS_TO_COLLECT' | translate}}</div>
                    <div class="row-val">-</div>
                    <div class="row-val">{{site.bins_left_to_collect}}</div>
                  </div>
                </div>
              </div>
              <div class="btn-frame">
                <div class="view-btn" (click)="goToPlan(site)">
                  {{'DRIVER_PLAN.VIEW_PLAN' | translate}}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}

@if (isMobile) {
  <div>
    <div class="mobile-driver-page" rtlDiv>
      <div class="mobile-title">
        {{'DRIVER_PLAN.CHOOSE_SITE' | translate}}
      </div>
      <div class="mobile-plans-list">
        @for (site of sitesListFilter; track site) {
          <div rtlDiv>
            <div class="list-item" rtlDiv>
              <div class="site-info">
                <div class="mobile-site_name">{{site.site_name}}</div>
                <div>
                  <div class="dflex">
                    <div class="mobile-row-title">{{'DRIVER_PLAN.TRUCK_NAME' | translate}}</div>
                    <div class="mobile-row-title">-</div>
                    <div class="mobile-row-title">{{site.tablet_id}}</div>
                  </div>
                  <div class="dflex">
                    <div class="mobile-row-title">{{'DRIVER_PLAN.BINS_TO_COLLECT' | translate}}</div>
                    <div class="mobile-row-title">-</div>
                    <div class="mobile-row-title">{{site.bins_left_to_collect}}</div>
                  </div>
                </div>
              </div>
              <div class="btn-frame">
                <div class="mobile-view-btn" (click)="goToPlan(site)">
                  {{'DRIVER_PLAN.VIEW_PLAN' | translate}}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}

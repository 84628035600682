import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-generic-confirm-dialog',
  standalone: true,
  imports: [TranslateModule,RTLDivDirectiveDirective,CommonModule],
  templateUrl: './generic-confirm-dialog.component.html',
  styleUrl: './generic-confirm-dialog.component.scss'
})
export class GenericConfirmDialogComponent {
  firstmsg = '';
  secondmsg = '';
  showModal = true;
  isCanceldAllowed: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<GenericConfirmDialogComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.firstmsg = this.data.firstmsg;
    this.secondmsg = this.data.secondmsg;
    this.isCanceldAllowed = this.data.isCanceldAllowed === undefined ? false : this.data.isCanceldAllowed;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.onNoClick();
    this.data.parent.confirm(this.data.func);
  }

  cancel(): void {
    this.onNoClick();
  }
}

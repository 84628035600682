import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { apiState, ApiStore } from './api.store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiQuery extends Query<apiState> {
  userAccounts$:Observable<Array<object>>;
  capacityDisplayTypes$:Observable<Array<object>>;
  usageTypes$:Observable<Array<object>>;
  clusterTypes$:Observable<Array<object>>;
  ticketEventsCause$:Observable<Array<object>>;
  ticketEventsType$:Observable<Array<object>>;
  userEvents$:Observable<Array<object>>;
  optimizationTypes$:Observable<Array<object>>;
  installationAccessoryTypes$:Observable<Array<object>>;
  userMessages$:Observable<Array<object>>;
  preInstallationMappingInfo$:Observable<Array<object>>;
  preMappingInfo$:Observable<Array<object>>;
  binsClusterInfoBySiteId$:Observable<Array<object>>;
  binTypes$:Observable<Array<object>>;
  filteredUsers$:Observable<Array<object>>;
  filteredBins$:Observable<Array<object>>;
  allFilteredBins$:Observable<Array<object>>;
  filteredBasicBins$:Observable<Array<object>>;
  allFilteredBasicBins$:Observable<Array<object>>;
  filteredTrucks$:Observable<Array<object>>;
  allBinCapacityAnalysis$:Observable<Array<object>>;
  binCapacityAnalysis$:Observable<Array<object>>;
  filteredDailyWorkPlan$:Observable<Array<object>>;
  allFilteredDailyWorkPlan$:Observable<Array<object>>;
  exceptionsReports$:Observable<Array<object>>;
  driverExceptionsReports$:Observable<Array<object>>;
  allSitesClustersList$:Observable<Array<object>>;
  sitesClustersList$:Observable<Array<object>>;
  allNeighborhoodsList$:Observable<Array<object>>;
  neighborhoodsList$:Observable<Array<object>>;
  allCRTicketsList$:Observable<Array<object>>;
  cRTicketsList$:Observable<Array<object>>;
  allInsTicketsList$:Observable<Array<object>>;
  insTicketsList$:Observable<Array<object>>;
  allRmTicketsList$:Observable<Array<object>>;
  rmTicketsList$:Observable<Array<object>>;
  allTicketsList$:Observable<Array<object>>;
  ticketsList$:Observable<Array<object>>;
  allRefillsStats$:Observable<Array<object>>;
  refillsStats$:Observable<Array<object>>;
  CollectionsStatsForWorkplans$:Observable<Array<object>>;
  CollectionsStatsForInsights$:Observable<Array<object>>;
  allCollectionsStatsForWorkplanProgres$:Observable<Array<object>>;
  CollectionsStatsForWorkplanProgres$:Observable<Array<object>>;
  collectionsStatsInCollections$:Observable<Array<object>>;
  collectionsStats$:Observable<Array<object>>;
  allCollectionsStats$:Observable<Array<object>>;
  collectionsStatsToHighlightPicker$:Observable<Array<object>>;
  collectionsStatsToday$:Observable<Array<object>>;
  allCollectionsStatsToday$:Observable<Array<object>>;
  collectionVolume$:Observable<Array<object>>;
  collectionsKpis$:Observable<Array<object>>;
  allCollectionsKpis$:Observable<Array<object>>;
  allRefillsKpis$:Observable<Array<object>>;
  refillsKpis$:Observable<Array<object>>;
  userEventsByType$:Observable<Array<object>>;
  userMessagesByType$:Observable<Array<object>>;
  userAlertsByType$:Observable<Array<object>>;
  dailyWorkPlanCalanderData$:Observable<Array<object>>;
  allDailyWorkPlanCalanderData$:Observable<Array<object>>;
  workPlanInfo$:Observable<Array<object>>;
  sitesListData$:Observable<Array<object>>;
  allSitesListData$:Observable<Array<object>>;
  workPlanScheduler$:Observable<Array<object>>;
  allWorkPlanScheduler$:Observable<Array<object>>;
  binsCapacityData$:Observable<Array<object>>;
  capacityData$:Observable<Array<object>>;
  workPlanDistrebutionMethods$:Observable<Array<object>>;
  pendingStatus$:Observable<Array<object>>;
  customerWorkPlanCollectionschedulePolicy$:Observable<Array<object>>;
  filteredBinManagmentInfo$:Observable<Array<object>>;
  accountsList$:Observable<Array<object>>;
  customerAccounts$:Observable<Array<object>>;
  reasonTypes$:Observable<Array<object>>;
  unAssignBins$:Observable<Array<object>>;
  analysisTypes$:Observable<Array<object>>;
  removeOrReplaceTypes$:Observable<Array<object>>;
  selectedStatusPieChart$:Observable<object>;
  selectedReportPieChart$:Observable<object>;
  selectedInsightsPieChart$:Observable<object>;
  selectedInsightsSamplingChart$:Observable<object>;
  userRoles$:Observable<Array<object>>;
  navigationInfo$:Observable<object>;
  binEvents$:Observable<Array<object>>;
  siteInfo$:Observable<object>;
  updatedPassword$:Observable<number>;
  validation$:Observable<number>;
  nextClusterIdInDb$:Observable<number>;
  putNewBinClusterIdResponse$:Observable<any>;
  wasteTypesList$:Observable<Array<object>>;
  deviceIdState$:Observable<any>;
  binLastTransId$:Observable<any>;
  latLngExistsResponse$:Observable<any>;
  gmtTimeZone$:Observable<any>;
  siteClustersList$:Observable<any>;
  installationStatusResponse$:Observable<any>;
  transErrorTrace$: Observable<any>;
  performRestoreSwapDevices$: Observable<any>;
  deviceIdStatus$: Observable<any>;
  binResetResponse$: Observable<any>;
  binInfo$: Observable<any>;
  user$: Observable<object>;
  binCollectionData$: Observable<Array<object>>;
  binCollection$: Observable<any>;

  constructor(protected override store: ApiStore) {
    super(store);
    this.userAccounts$ = this.select(state => state.userAccounts);
    this.capacityDisplayTypes$ = this.select(state => state.capacityDisplayTypes);
    this.usageTypes$ = this.select(state => state.usageTypes);
    this.clusterTypes$ = this.select(state => state.clusterTypes);
    this.ticketEventsCause$ = this.select(state => state.ticketEventsCause);
    this.ticketEventsType$ = this.select(state => state.ticketEventsType);
    this.userEvents$ = this.select(state => state.userEvents);
    this.optimizationTypes$ = this.select(state => state.optimizationTypes);
    this.installationAccessoryTypes$ = this.select(state => state.installationAccessoryTypes);
    this.userMessages$ = this.select(state => state.userMessages);
    this.preInstallationMappingInfo$ = this.select(state => state.preInstallationMappingInfo);
    this.preMappingInfo$ = this.select(state => state.preMappingInfo);
    this.binsClusterInfoBySiteId$ = this.select(state => state.binsClusterInfoBySiteId);
    this.binTypes$ = this.select(state => state.binTypes);
    this.filteredUsers$ = this.select(state => state.filteredUsers);
    this.filteredBins$ = this.select(state => state.filteredBins);
    this.allFilteredBins$ = this.select(state => state.allFilteredBins);
    this.filteredBasicBins$ = this.select(state => state.filteredBasicBins);
    this.allFilteredBasicBins$ = this.select(state => state.allFilteredBasicBins);
    this.filteredTrucks$ = this.select(state => state.filteredTrucks);
    this.allBinCapacityAnalysis$ = this.select(state => state.allBinCapacityAnalysis);
    this.binCapacityAnalysis$ = this.select(state => state.binCapacityAnalysis);
    this.filteredDailyWorkPlan$ = this.select(state => state.filteredDailyWorkPlan);
    this.allFilteredDailyWorkPlan$ = this.select(state => state.allFilteredDailyWorkPlan);
    this.allSitesClustersList$ = this.select(state => state.allSitesClustersList);
    this.sitesClustersList$ = this.select(state => state.sitesClustersList);
    this.allNeighborhoodsList$ = this.select(state => state.allNeighborhoodsList);
    this.neighborhoodsList$ = this.select(state => state.neighborhoodsList);
    this.allCRTicketsList$ = this.select(state => state.allCRTicketsList);
    this.cRTicketsList$ = this.select(state => state.cRTicketsList);
    this.allInsTicketsList$ = this.select(state => state.allInsTicketsList);
    this.insTicketsList$ = this.select(state => state.insTicketsList);
    this.allRmTicketsList$ = this.select(state => state.allRmTicketsList);
    this.rmTicketsList$ = this.select(state => state.rmTicketsList);
    this.allTicketsList$ = this.select(state => state.allTicketsList);
    this.ticketsList$ = this.select(state => state.ticketsList);
    this.allRefillsStats$ = this.select(state => state.allRefillsStats);
    this.refillsStats$ = this.select(state => state.refillsStats);
    this.CollectionsStatsForWorkplans$ = this.select(state => state.CollectionsStatsForWorkplans);
    this.CollectionsStatsForInsights$ = this.select(state => state.CollectionsStatsForInsights);
    this.allCollectionsStatsForWorkplanProgres$ = this.select(state => state.allCollectionsStatsForWorkplanProgres);
    this.CollectionsStatsForWorkplanProgres$ = this.select(state => state.CollectionsStatsForWorkplanProgres);
    this.collectionsStatsInCollections$ = this.select(state => state.collectionsStatsInCollections);
    this.collectionsStats$ = this.select(state => state.collectionsStats);
    this.allCollectionsStats$ = this.select(state => state.allCollectionsStats);
    this.collectionsStatsToHighlightPicker$ = this.select(state => state.collectionsStatsToHighlightPicker);
    this.collectionsStatsToday$ = this.select(state => state.collectionsStatsToday);
    this.allCollectionsStatsToday$ = this.select(state => state.allCollectionsStatsToday);
    this.exceptionsReports$ = this.select(state => state.exceptionsReports);
    this.driverExceptionsReports$ = this.select(state => state.driverExceptionsReports);
    this.collectionVolume$ = this.select(state => state.collectionVolume);
    this.collectionsKpis$ = this.select(state => state.collectionsKpis);
    this.allCollectionsKpis$ = this.select(state => state.allCollectionsKpis);
    this.allRefillsKpis$ = this.select(state => state.allRefillsKpis);
    this.refillsKpis$ = this.select(state => state.refillsKpis);
    this.userEventsByType$ = this.select(state => state.userEventsByType);
    this.userMessagesByType$ = this.select(state => state.userMessagesByType);
    this.userAlertsByType$ = this.select(state => state.userAlertsByType);
    this.dailyWorkPlanCalanderData$ = this.select(state => state.dailyWorkPlanCalanderData);
    this.allDailyWorkPlanCalanderData$ = this.select(state => state.allDailyWorkPlanCalanderData);
    this.workPlanInfo$ = this.select(state => state.workPlanInfo);   
    this.sitesListData$ = this.select(state => state.sitesListData);
    this.allSitesListData$ = this.select(state => state.allSitesListData);
    this.workPlanScheduler$ = this.select(state => state.workPlanScheduler);
    this.allWorkPlanScheduler$ = this.select(state => state.allWorkPlanScheduler);
    this.binsCapacityData$ = this.select(state => state.binsCapacityData);
    this.capacityData$ = this.select(state => state.capacityData);
    this.workPlanDistrebutionMethods$ = this.select(state => state.workPlanDistrebutionMethods);
    this.pendingStatus$ = this.select(state => state.pendingStatus);
    this.customerWorkPlanCollectionschedulePolicy$ = this.select(state => state.customerWorkPlanCollectionschedulePolicy);
    this.filteredBinManagmentInfo$ = this.select(state => state.filteredBinManagmentInfo);
    this.accountsList$ = this.select(state => state.accountsList);  
    this.customerAccounts$ = this.select(state => state.customerAccounts);
    this.reasonTypes$ = this.select(state => state.reasonTypes);  
    this.unAssignBins$ = this.select(state => state.unAssignBins);  
    this.analysisTypes$ = this.select(state => state.analysisTypes);  
    this.removeOrReplaceTypes$ = this.select(state => state.removeOrReplaceTypes);  
    this.selectedStatusPieChart$ = this.select(state => state.selectedStatusPieChart);  
    this.selectedReportPieChart$ = this.select(state => state.selectedReportPieChart);  
    this.selectedInsightsPieChart$ = this.select(state => state.selectedInsightsPieChart);
    this.selectedInsightsSamplingChart$ = this.select(state => state.selectedInsightsSamplingChart);
    this.userRoles$ = this.select(state => state.userRoles);
    this.navigationInfo$ = this.select(state => state.navigationInfo);
    this.binEvents$ = this.select(state => state.binEvents);
    this.siteInfo$ = this.select(state => state.siteInfo);
    this.updatedPassword$ = this.select(state => state.updatedPassword);
    this.validation$ = this.select(state => state.validation);
    this.nextClusterIdInDb$ = this.select(state => state.nextClusterIdInDb);
    this.putNewBinClusterIdResponse$ = this.select(state => state.putNewBinClusterIdResponse);
    this.wasteTypesList$ = this.select(state => state.wasteTypesList);
    this.deviceIdState$ = this.select(state => state.deviceIdState);
    this.binLastTransId$ = this.select(state => state.binLastTransId);
    this.latLngExistsResponse$ = this.select(state => state.latLngExistsResponse);
    this.gmtTimeZone$ = this.select(state => state.gmtTimeZone);
    this.siteClustersList$ = this.select(state => state.siteClustersList);
    this.installationStatusResponse$ = this.select(state => state.installationStatusResponse);
    this.transErrorTrace$ = this.select(state => state.transErrorTrace);
    this.performRestoreSwapDevices$ = this.select(state => state.performRestoreSwapDevices);
    this.deviceIdStatus$ = this.select(state => state.deviceIdStatus);
    this.binResetResponse$ = this.select(state => state.binResetResponse);
    this.binInfo$ = this.select(state => state.binInfo);
    this.user$ = this.select(state => state.user);
    this.binCollectionData$ = this.select(state => state.binCollectionData);
    this.binCollection$ = this.select(state => state.binCollection);
  }
}

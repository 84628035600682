@if (!isMobile) {
  <div class="padded reports-page">
    <div class="dashboard-header">
      <div class='dashboard-header-left' rtlDiv>
        <div class='header-bold'>{{ 'WIDGETS.COLLECTIONS' | translate }}</div>
      </div>
      <div class='datepicker-wrapper' rtlDiv>
        <div class='selected-dates' (click)='showHideDatePicker()'>
          <img [ngClass]="{'transform-arrow' : currentLang == 'iw'}" src="assets/images/dashboard/Previous.svg" alt="Previous" rtlDiv>
          <div class="dates-range datepicker-rtl" rtlDiv>
            {{selectedDates.from}} - {{selectedDates.to}}
          </div>
          <img [ngClass]="{'transform-arrow' : currentLang == 'iw'}" src="assets/images/dashboard/Next.svg" alt="Next" rtlDiv>
        </div>
        <div class="datepicker-frame" rtlDiv>
          <app-datepicker-range [hidden]='!showDatePicker' (selectDates)='onSelectDates($event)'></app-datepicker-range>
        </div>
      </div>
    </div>
    <mat-divider class="top-divider"></mat-divider>
    <div class="bin-info-container">
      <div class='range-type-container' rtlDiv>
        @if (showSpinner) {
          <div class="spinner-border mobile-spinner" role="status" rtlDiv>
            <span class="sr-only"></span>
          </div>
        }
        <button id="excelExp" [ngClass]="{'cursor_pointer':!excel_disabled,'cursor_noDrop':excel_disabled}" class='range-type-btn obvious excelBtn' (click)="exportToCsv()" rtlDiv>
          <img class='table-export-img excel' rtlDiv src="assets/images/dashboard/Excel.svg" alt="" />
          <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
        </button>
      </div>
      <div class="first-row">
        <app-collection-kpi [datesDiff]="datesDiff" [showKPIBtn]="showKPIBtn"></app-collection-kpi>
      </div>
      <div class="second-row">
        <!-- <app-average-tank [datesDiff]="datesDiff" rtlDiv></app-average-tank> -->
        <app-collection-status-pie [datesDiff]="datesDiff" rtlDiv></app-collection-status-pie>
        <app-collection-report-pie [datesDiff]="datesDiff" rtlDiv [showReportPieTabs]="showReportPieTabs" [showReportPieBtn]="showReportPieBtn"></app-collection-report-pie>
      </div>
      <div class="third-row">
        <app-collections-table [datesDiff]="datesDiff"></app-collections-table>
      </div>
    </div>
  </div>
}

@if (isMobile) {
  <div>
    <div class="mobile-top-container">
      <div class="mobile-inventory-header">
        <div class="mobile-left-title">{{ 'WIDGETS.COLLECTIONS' | translate }}</div>
      </div>
    </div>
    <div class="mobile-header-manager">
      <div class='mobile-datepicker-wrapper' rtlDiv>
        <div class='selected-dates' (click)='showHideDatePicker()'>
          <img class='calendar-icon' src="../assets/images/reports/calendar.svg" alt="calendar" rtlDiv>
          <div class='dates-range datepicker-rtl' rtlDiv>
            {{selectedDates.from}} - {{selectedDates.to}}
          </div>
          <img class='dropdown-icon' src="assets/images/dashboard/dropdown_arrow.svg" alt="dropdown_arrow" rtlDiv>
        </div>
        <div class="datepicker-rtl">
          <app-datepicker-range [hidden]='!showDatePicker' (selectDates)='onSelectDates($event)'></app-datepicker-range>
        </div>
      </div>
      <div class='mobile-table-export-container' rtlDiv>
        @if (showSpinner) {
          <div class="spinner-border mobile-spinner" role="status" rtlDiv>
            <span class="sr-only"></span>
          </div>
        }
        <div id="excelExp" class='mobile-table-export-btns-wrapper' (click)="exportToCsv()" rtlDiv>
          <img class='mobile-table-export-img' src="assets/images/dashboard/Excel.svg" alt="" />
        </div>
      </div>
    </div>
    <div>
      <app-collection-kpi [datesDiff]="datesDiff" [showKPIBtn]="showKPIBtn"></app-collection-kpi>
    </div>
    <div>
      <!-- <app-average-tank [datesDiff]="datesDiff" rtlDiv></app-average-tank> -->
      <app-collection-status-pie [datesDiff]="datesDiff" rtlDiv></app-collection-status-pie>
    </div>
    <div>
      <app-collection-report-pie [datesDiff]="datesDiff" rtlDiv [showReportPieTabs]="showReportPieTabs" [showReportPieBtn]="showReportPieBtn"></app-collection-report-pie>
    </div>
    <div>
      <app-collections-table [datesDiff]="datesDiff"></app-collections-table>
    </div>
  </div>
}
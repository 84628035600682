<h5 mat-dialog-title class="text-center">{{ 'SITE_MANAGMENT.ID_VALID' | translate }}</h5>

<mat-dialog-content>
  <form [formGroup]="deviceValidationFormGroup" rtlDiv>
    <input type="number" #deviceId matInput placeholder="{{ 'SITE_MANAGMENT.INSERT_ID' | translate }}" formControlName="deviceId" class="form-control" required>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="btns-frame" rtlDiv>
    <button class="cancel-btn" mat-button color="warn" [mat-dialog-close]="false">{{ 'SITE_MANAGMENT.CANCEL' | translate }}</button>
    <button class="ok-btn" mat-button [mat-dialog-close]="true" [disabled]="deviceValidationFormGroup.invalid">{{ 'SITE_MANAGMENT.CONTINUE' | translate }}</button>
  </div>
</mat-dialog-actions>
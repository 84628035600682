import { Directive, ElementRef } from '@angular/core';
import { TranslatorService } from '../services/translator_service';
import { LangChangeEvent } from '@ngx-translate/core';

@Directive({
  selector: '[rtlDiv]',
  standalone: true
})
export class RTLDivDirectiveDirective {

  
  isRTL: boolean;

  setDirection(element:any, isRTL:any): void {
    if (isRTL) {
      element.nativeElement.classList.add('rtl');
    }else{
      element.nativeElement.classList.remove('rtl');
    }
  }

  constructor(
    private translator: TranslatorService,
    el: ElementRef
  ) {
      this.isRTL = translator.isRTL();
      this.setDirection(el, this.isRTL);
      translator.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.setDirection(el, translator.isRTL());
      });
    }

}

@if (!isMobile) {
  <div class="manage-clusters-pc">
    <div class="modal-title">
      <span>{{ 'LIST_COLUMNS.MANAGE_CLUSTER' | translate }} ({{data.chosenClusters.length}} {{ 'TITLES.CLUSTERS' | translate }})</span>
    </div>
    <form [formGroup]="ElForm">
      <div class="form-margins" rtlDiv>
        <div class="full-width" rtlDiv>
          @if (errorMsg) {
            <div class="error-msg">
              <img src="assets/images/inventory/missed-info.svg" alt=""/>
              <span class="error-txt">{{ 'LIST_COLUMNS.ERROR_BULK_CLUSTERS' | translate }}</span>
            </div>
          }
          <div [ngClass]="{'grayed-out' : errorMsg}">
            <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.CLUSTER_TYPE' | translate }}</div>
            <mat-form-field class="event-type-input event-types">
              <mat-select class="full-height" rtlDiv formControlName="clusterType" name="clusterType" (selectionChange)="changeType($event.value)">
                @for (type of types; track type) {
                  <mat-option [value]="type.cluster_type_id">
                    <span class="flex-binInfo" rtlDiv>{{currentLang != 'iw' ? type.cluster_type_name : type.cluster_type_name_hebrew}}</span>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.MIN_ALLOWED_CAPACITY' | translate }}</div>
          <mat-form-field class="event-type-input event-types" rtlDiv>
            <input tabindex="-1" type="number" class="input-site" matInput formControlName="minAllowedCap" rtlDiv>
          </mat-form-field>
        </div>
        @if (ElForm.getRawValue()['clusterType'] == 1) {
          <div class="full-width" rtlDiv>
            <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.MAX_ALLOWED_CAPACITY' | translate }}</div>
            <mat-form-field class="event-type-input event-types" rtlDiv>
              <input tabindex="-1" type="number" class="input-site" matInput formControlName="maxAllowedCap" rtlDiv>
            </mat-form-field>
          </div>
        }
      </div>
    </form>
    <div [ngClass]="{'rtldir': currentLang != 'iw','ltrdir':currentLang == 'iw'}">
      <button [ngClass]="{'apply-btn' : ElForm.valid,'not-apply-btn' : !ElForm.valid}" [disabled]="!ElForm.valid" (click)="applyChanges()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</button>
      <button class="cancel_btn" (click)="closeModal()">{{ 'TRUCK_MAP.CANCEL' | translate }}</button>
    </div>
  </div>
}

@if (isMobile) {
  <div class="mobile-manage-clusters">
    <div class="mobile-modal-title" rtlDiv>
      <div class="dflex" (click)="closeModal()">
        <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
      </div>
      <div>
        <span class="main-title">{{ 'LIST_COLUMNS.MANAGE_CLUSTERS' | translate }} ({{data.chosenClusters.length}} {{ 'TITLES.CLUSTERS' | translate }})</span>
      </div>
    </div>
    <form [formGroup]="ElForm">
      <div class="mobile-form-margins" rtlDiv>
        <div class="full-width" rtlDiv>
          @if (errorMsg) {
            <div class="error-msg">
              <img src="assets/images/inventory/missed-info.svg" alt=""/>
              <span class="error-txt">{{ 'LIST_COLUMNS.ERROR_BULK_CLUSTERS' | translate }}</span>
            </div>
          }
          <div [ngClass]="{'grayed-out' : errorMsg}">
            <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.CLUSTER_TYPE' | translate }}</div>
            <mat-form-field class="event-type-input event-types">
              <mat-select class="full-height" rtlDiv formControlName="clusterType" name="clusterType" (selectionChange)="changeType($event.value)">
                @for (type of types; track type) {
                  <mat-option [value]="type.cluster_type_id">
                    <span class="flex-binInfo" rtlDiv>{{currentLang != 'iw' ? type.cluster_type_name : type.cluster_type_name_hebrew}}</span>
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.MIN_ALLOWED_CAPACITY' | translate }}</div>
          <mat-form-field class="event-type-input event-types" rtlDiv>
            <input tabindex="-1" type="number" class="input-site" matInput formControlName="minAllowedCap" rtlDiv>
          </mat-form-field>
        </div>
        @if (ElForm.getRawValue()['clusterType'] == 1) {
          <div class="full-width" rtlDiv>
            <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.MAX_ALLOWED_CAPACITY' | translate }}</div>
            <mat-form-field class="event-type-input event-types" rtlDiv>
              <input tabindex="-1" type="number" class="input-site" matInput formControlName="maxAllowedCap" rtlDiv>
            </mat-form-field>
          </div>
        }
      </div>
    </form>
    <div class='main-btns-wrapper' rtlDiv>
      <div class='btns-container' rtlDiv>
        <button class="mobile-cancel_btn" (click)="closeModal()">{{ 'TRUCK_MAP.CANCEL' | translate }}</button>
        <button [ngClass]="{'mobile-apply-btn' : ElForm.valid,'mobile-not-apply-btn' : !ElForm.valid}" [disabled]="!ElForm.valid" (click)="applyChanges()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</button>
      </div>
    </div>
  </div>
}

import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiStore } from '../../../web-services/api/api.store';
import { ApiService } from '../../../web-services/api/api.service';
import moment from 'moment';
import _ from 'lodash';
import wNumb from 'wnumb';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { NouisliderModule } from 'ng2-nouislider';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-bulk-edit-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,MatSelectModule,NgbModule,MatInputModule,MatAutocompleteModule,NouisliderModule,
    MatCheckboxModule],
  templateUrl: './bulk-edit-modal.component.html',
  styleUrl: './bulk-edit-modal.component.scss'
})
export class BulkEditModalComponent {
  @ViewChild('slider', { static: false, read: ElementRef }) set slider(s: ElementRef) {
    if (s) {      
      const origin = s.nativeElement.querySelectorAll('.noUi-origin');
      if(origin.length > 0){
        origin[0].setAttribute('disabled', true);
        origin[0].style.display = 'none';
        origin[3].setAttribute('disabled', true);
        origin[3].style.display = 'none';
      }

      const connect = s.nativeElement.querySelectorAll('.noUi-connect');
      const classes = this.usageType == 1 ? ['c-1-color', 'c-2-color', 'c-3-color'] : ['c-3-color', 'c-2-color', 'c-1-color'];
      for (let i = 0; i < connect.length; i++) {
        connect[i].classList.add(classes[i]);
      }
    }
  }

  @ViewChild('maxslider', { static: false, read: ElementRef }) set maxslider(m: ElementRef) {
    if (m) {
      const maxOrigin = m.nativeElement.querySelectorAll('.noUi-origin');
      if(maxOrigin.length > 0){
        maxOrigin[0].setAttribute('disabled', true);
        maxOrigin[0].style.display = 'none';
      }

      const connect = m.nativeElement.querySelectorAll('.noUi-connect');
      if(connect.length > 0){
        connect[0].classList.add('max-color');
      }
    }
  }

  approveAdd : boolean = false;
  binAddress: string;
  binDesc: string;
  currentLang;
  restriction_1_start_time: any;
  maxDaysValue: any;
  maxCapValue:any;
  restrictionOpen: boolean = false;
  
  thresholdRange: [number, number, number, number];
  maxRange: [number, number];
  thresholdConnect: [boolean, boolean, boolean, boolean];
  maxConnect: [boolean, boolean];

  thresholdTooltips: [any, any, any, any];
  maxTooltip: [any, any];
  greenThresholdValue: number;
  yellowThresholdValue: number;
  redThresholdValue: number;
  binLimitPercent: number;
  classes = ['c-1-color', 'c-2-color', 'c-3-color'];
  showTimeErrorText: Boolean = false;
	usageType = 1;
  isWorkplanIncluded : boolean = false;
  isSundayChecked : boolean = false;
  isMondayChecked : boolean = false;
  isTuesdayChecked : boolean = false;
  isWednesdayChecked : boolean = false;
  isThursdayChecked : boolean = false;
  isFridayChecked : boolean = false;
  isSaturdayChecked : boolean = false;
  translate;
  translationsObj;
  neighborhoods : string[] = [];
  filteredItems: Observable<any[]>;
  filteredbinSitesToDisplay: Observable<any>;
  showAddButton: boolean = false;
  prompt = '';
  ElForm: FormGroup;
  ElFormSites: FormGroup;
  selectedCluster : any;
  variables = [];
  filteredList : any;
  reasonTypes : any[] = [];
  binSitesToDisplay:Array<any> =[];
  reasonType : any;
  selectedReasonType: any;
  endTimeMustBeAfterStartTimeThird : boolean = false;
  endTimeMustBeAfterStartTimeFirst : boolean = false;
  startTimeMustBeAfterEndTimeOfTheFirstOption : boolean = false;
  startTimeMustBeAfterEndTimeOfTheSecondOption : boolean = false;
  endTimeMustBeAfterStartTimeSecond : boolean = false;
  chosenSite : object = {};
  sitesList : Array<object> = [];
  restrictions: any[] = [
    {
      showHideDropdown: {
        startTimeHour: false,
        startTimeMinute: false,
        endTimeHour: false,
        endTimeMinute: false,
        checked: false
      }
    },
    {
      showHideDropdown: {
        startTimeHour: false,
        startTimeMinute: false,
        endTimeHour: false,
        endTimeMinute: false,
        checked: false
      }
    },
    {
      showHideDropdown: {
        startTimeHour: false,
        startTimeMinute: false,
        endTimeHour: false,
        endTimeMinute: false,
        checked: false
      }
    },
  ];
  selectedManageBinTime = [
    {
      startHour: '08',
      startminute: '08',
      endHour: '08',
      endMinute: '00'
    },
    {
      startHour: '08',
      startminute: '00',
      endHour: '08',
      endMinute: '00'
    },
    {
      startHour: '08',
      startminute: '00',
      endHour: '08',
      endMinute: '00'
    }];
    manageBinTime = [
      {
        hours: [],
        minutes: []
      },
      {
        hours: [],
        minutes: []
      },
      {
        hours: [],
        minutes: []
      }
    ];
    
  constructor(private translator: TranslatorService,private apiQuery:ApiQuery,
		public fb: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: any,
    private apiService:ApiService,private apiStore:ApiStore,private dialogRef: MatDialogRef<BulkEditModalComponent>) {
      this.dialogRef.disableClose = true;

      this.translate = this.translator;
      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
        this.currentLang = value;	
      }); 

      this.ElForm = this.fb.group({
        itemCtrl: '',
      });

      this.ElFormSites = this.fb.group({
        binSite: ''
      });
  }

  ngOnInit() {    
    this.maxDaysValue = 7;
    this.maxCapValue = 2000;    
    this.binAddress = "";
    this.binDesc = "";
    setTimeout(() => {
      this.isWorkplanIncluded = true;
      this.isSundayChecked = true;
      this.isMondayChecked = true;
      this.isTuesdayChecked = true;
      this.isWednesdayChecked = true;
      this.isThursdayChecked = true;
      this.isFridayChecked = true;
      this.isSaturdayChecked = true;
    }, 0);
    
    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}	
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
      this.initializeColorPickers();
		});

    this.apiQuery.reasonTypes$.subscribe((data:any) => {
      this.reasonTypes = data;          
    });

    this.apiQuery.sitesListData$.subscribe((sitesList) => {
      let sites = [];
			if(sitesList.length == 0) return;
      _.each(sitesList, (site) => {
        sites.push(site["site_name"]);
      });
      this.sitesList = sitesList;
			this.binSitesToDisplay = sites; 
      this.ElFormSites.controls['binSite'].setValue(this.data.chosenBins[0].site_name ? this.data.chosenBins[0].site_name : '');  
      
      _.each(this.sitesList, (site) => {
        if(site["site_name"] == this.data.chosenBins[0].site_name){
          this.chosenSite = site;
        }
      });

      this.filteredbinSitesToDisplay = this.ElFormSites.controls['binSite'].valueChanges
      .pipe(
      startWith(''),
      map(item => item ? this.filterbinSitesToDisplay(item) : this.binSitesToDisplay.slice()));       
    });

    this.buildManageBinTime();
  }

  ngAfterViewInit() {
    let BinsNeighborhoodList = {
      'site_id': this.data.chosenBins[0].site_id
    }

    this.apiService.getBinsNeighborhoodList(BinsNeighborhoodList).subscribe((v:any) => {	
      let ngh = [];
      _.each(v.NBRHoodList, (site) => {
        _.each(site.site_NBRH_list, (neighborhood) => {
          ngh.push(neighborhood.nbrh_name);
        });
      });
      this.neighborhoods = ngh;        
      this.filteredItems = this.ElForm.controls['itemCtrl'].valueChanges
      .pipe(
      startWith(''),
      map(item => item ? this.filterItems(item) : this.neighborhoods.slice()));  
   });
  }

  changeSite(option){
    _.each(this.sitesList, (site) => {
      if(site["site_name"] == option.value){
        this.chosenSite = site;
      }
    });

    let BinsNeighborhoodList = {
      'site_id': this.chosenSite["site_id"]
    }

    this.apiService.getBinsNeighborhoodList(BinsNeighborhoodList).subscribe((v:any) => {	
      let ngh = [];
      _.each(v.NBRHoodList, (site) => {
        _.each(site.site_NBRH_list, (neighborhood) => {
          ngh.push(neighborhood.nbrh_name);
        });
      });
      this.neighborhoods = ngh;        
      this.ElForm.controls["itemCtrl"].setValue('');
   });
  }
  
  buildManageBinTime = () => {
    for(let j = 0; j < this.manageBinTime.length; j++){
      for (let i = 0; i <= 59; i++) {
        if (i >= 0 && i <= 9) {
          this.manageBinTime[j].hours.push(`0${i}`);
          this.manageBinTime[j].minutes.push(`0${i}`);
        } else {
          if (i < 24) {
            this.manageBinTime[j].hours.push(`${i}`);
          }
          this.manageBinTime[j].minutes.push(`${i}`);
        }
      }
    }
  }

  changeReasonType(value){
    this.reasonType = value;    
  }

  isRtl = () => {
    return this.currentLang === 'iw';
  }

  initializeColorPickers() {

    this.thresholdRange = [
      0,
      45,
      75,
      100
    ];
    this.maxRange = [
      0,
      100
    ]; 
    
    if(this.usageType == 1){
      this.greenThresholdValue = this.thresholdRange[1]; 
      this.yellowThresholdValue = this.thresholdRange[2];
      this.redThresholdValue = this.thresholdRange[3];
    }else{
      this.greenThresholdValue = this.thresholdRange[3]; 
      this.yellowThresholdValue = this.thresholdRange[2];
      this.redThresholdValue = this.thresholdRange[1];
    }

    this.binLimitPercent = this.maxRange[1];
    this.thresholdConnect = [true, true, true, true];
    this.maxConnect = [true, true];

    this.thresholdTooltips = [wNumb({ decimals: 0 }), wNumb({ decimals: 0 }), wNumb({ decimals: 0 }), wNumb({ decimals: 0 })];
    this.maxTooltip = [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })];

  }

  onThresholdChange(value) {
    if(this.usageType == 1){
      this.greenThresholdValue = Math.round(value[1]); 
      this.yellowThresholdValue = Math.round(value[2]);
      this.redThresholdValue = Math.round(value[3]);
    }else{
      this.greenThresholdValue = Math.round(value[3]); 
      this.yellowThresholdValue = Math.round(value[2]);
      this.redThresholdValue = Math.round(value[1]);
    }

  }
  onMaxChange(value) {
    this.binLimitPercent = Math.round(value[1]);
  }

  public openDropDownList = (restriction, list_name: string) => {
    _.each(restriction.showHideDropdown, (value, key) => {

      if (key === list_name) {
        restriction.showHideDropdown[list_name] = !value;
      } else {
        restriction.showHideDropdown[key] = false;
      }
    });

  }

  public selectManageBinTime = (time, type, index) => {    
    this.selectedManageBinTime[index][type] = time;
    this.validateRestrictions(index);
  }

  private validateRestrictions(index){
    let firstStartTime = this.selectedManageBinTime[0].startHour + ":" + this.selectedManageBinTime[0].startminute + ":00";
    let firstEndTime = this.selectedManageBinTime[0].endHour + ":" + this.selectedManageBinTime[0].endMinute + ":00";
    let secondStartTime = this.selectedManageBinTime[1].startHour + ":" + this.selectedManageBinTime[1].startminute + ":00";
    let secondEndTime = this.selectedManageBinTime[1].endHour + ":" + this.selectedManageBinTime[1].endMinute + ":00";
    let thirdStartTime = this.selectedManageBinTime[2].startHour + ":" + this.selectedManageBinTime[2].startminute + ":00";
    let thirdEndTime = this.selectedManageBinTime[2].endHour + ":" + this.selectedManageBinTime[2].endMinute + ":00";   
     
    if(index == 0){      
      if(this.restrictions[0].checked){
        if(!(moment(firstEndTime, "HH:mm:ss").isAfter(moment(firstStartTime, "HH:mm:ss")))){
          this.endTimeMustBeAfterStartTimeFirst = true;
        }else{
          this.endTimeMustBeAfterStartTimeFirst = false;
        }
        if(this.restrictions[1].checked){
          if(!(moment(secondStartTime, "HH:mm:ss").isAfter(moment(firstEndTime, "HH:mm:ss")))){
            this.startTimeMustBeAfterEndTimeOfTheFirstOption = true;
          }else{
            this.startTimeMustBeAfterEndTimeOfTheFirstOption = false;
          } 
        }     
      }else{
        if(this.restrictions[1].checked){
          this.startTimeMustBeAfterEndTimeOfTheFirstOption = false;
        }  
        this.endTimeMustBeAfterStartTimeFirst = false;
      } 

      if(this.restrictions[0].checked && this.restrictions[2].checked){
        if(!(moment(thirdStartTime, "HH:mm:ss").isAfter(moment(firstEndTime, "HH:mm:ss")))){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = true;
        }else{
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        } 
      }else{
        this.startTimeMustBeAfterEndTimeOfTheSecondOption = false
      }
    }else if(index == 1){
      if(this.restrictions[1].checked){
        if(!(moment(secondEndTime, "HH:mm:ss").isAfter(moment(secondStartTime, "HH:mm:ss")))){
          this.endTimeMustBeAfterStartTimeSecond = true;
        }else{
          this.endTimeMustBeAfterStartTimeSecond = false;
        } 
      }else{
        this.endTimeMustBeAfterStartTimeSecond = false;
      }
  
      if(this.restrictions[0].checked && this.restrictions[1].checked){
        if(!(moment(secondStartTime, "HH:mm:ss").isAfter(moment(firstEndTime, "HH:mm:ss")))){
          this.startTimeMustBeAfterEndTimeOfTheFirstOption = true;
        }else{
          this.startTimeMustBeAfterEndTimeOfTheFirstOption = false;
        } 
      }else{
        this.startTimeMustBeAfterEndTimeOfTheFirstOption = false;
      }

      if(this.restrictions[1].checked && this.restrictions[2].checked){
        if(!(moment(thirdStartTime, "HH:mm:ss").isAfter(moment(secondEndTime, "HH:mm:ss")))){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = true;
        }else{
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        } 
      }else{
        this.startTimeMustBeAfterEndTimeOfTheSecondOption = false
      }

      if(this.restrictions[0].checked && this.restrictions[2].checked){
        if(!(moment(thirdStartTime, "HH:mm:ss").isAfter(moment(firstEndTime, "HH:mm:ss")))){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = true;
        }else{
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        } 
      }else{
        if(!this.restrictions[1].checked){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        }  
      }
    }else if(index == 2){           
      if(this.restrictions[2].checked){        
        if(!(moment(thirdEndTime, "HH:mm:ss").isAfter(moment(thirdStartTime, "HH:mm:ss")))){
          this.endTimeMustBeAfterStartTimeThird = true;
        }else{
          this.endTimeMustBeAfterStartTimeThird = false;
        }
      }else{
        this.endTimeMustBeAfterStartTimeThird = false;
      }

      if(this.restrictions[1].checked && this.restrictions[2].checked){        
        if(!(moment(thirdStartTime, "HH:mm:ss").isAfter(moment(secondEndTime, "HH:mm:ss")))){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = true;
        }else{          
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        } 
      }else{
        if(!this.restrictions[0].checked){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        }        
      }

      if(this.restrictions[0].checked && this.restrictions[2].checked){
        if(!(moment(thirdStartTime, "HH:mm:ss").isAfter(moment(firstEndTime, "HH:mm:ss")))){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = true;
        }else{
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        } 
      }else{
        if(!this.restrictions[1].checked){
          this.startTimeMustBeAfterEndTimeOfTheSecondOption = false;
        }  
      }
    }
  }

  resetRestriction(index,event) {    
    this.restrictions[index].checked = event.checked;
		if (!this.restrictions[index].checked) {
			this.selectedManageBinTime[index].startHour = "00";
			this.selectedManageBinTime[index].endHour = "00";
			this.selectedManageBinTime[index].startminute = "00";
			this.selectedManageBinTime[index].endMinute = "00";
		}
    this.validateRestrictions(index);    
	}

  public closeModal = () => {
    this.dialogRef.close();
  }
  public saveWorkplan = () => {
    if(this.endTimeMustBeAfterStartTimeFirst || this.endTimeMustBeAfterStartTimeSecond || this.endTimeMustBeAfterStartTimeThird ||
      this.startTimeMustBeAfterEndTimeOfTheFirstOption || this.startTimeMustBeAfterEndTimeOfTheSecondOption){
      return;
    }
    let binIDs = [];
    _.each(this.data.chosenBins, (item) => {
      binIDs.push([Number(item.bin_id),0]);
    });  

    let dataToSend = {
      "bin_id_list": binIDs
    }
    
    if(this.data.properties.isSite){
      dataToSend["new_site_id"] = this.chosenSite["site_id"];
    }
    if(this.data.properties.isNeighborhood){
      dataToSend["neighborhood"] = this.ElForm.getRawValue()["itemCtrl"] ? this.ElForm.getRawValue()["itemCtrl"] : '';
    }
    if(this.data.properties.isMaxDays){
      dataToSend["bin_max_collections_days_gap"] = this.usageType == 1 ? this.maxDaysValue : 0;
    }
    if(this.data.properties.isBinMaxCapLiter){
      dataToSend["bin_max_capacity_in_liters"] = this.maxCapValue;
    }
    if(this.data.properties.isThresholdLevel){
      dataToSend["green_status_value"] = this.greenThresholdValue;
      dataToSend["yellow_status_value"] = this.yellowThresholdValue;
      dataToSend["red_status_value"] = this.redThresholdValue;
    }
    if(this.data.properties.isMaxCapacity){
      dataToSend["max_allowed_caps_in_percents"] = this.binLimitPercent;
    }
    if(this.data.properties.isIncludeInWorkplan){
      dataToSend["collection_mode"] = this.isWorkplanIncluded ? 2 : 1;
    }
    if(this.data.properties.isBinRestrictionDays){
      dataToSend["is_update_collection_days"] = 1;
      dataToSend["sunday"] = this.isSundayChecked ? 1 : 0;
      dataToSend["monday"] = this.isMondayChecked ? 1 : 0;
      dataToSend["tuesday"] = this.isTuesdayChecked ? 1 : 0;
      dataToSend["wednesday"] = this.isWednesdayChecked ? 1 : 0;
      dataToSend["thursday"] = this.isThursdayChecked ? 1 : 0;
      dataToSend["friday"] = this.isFridayChecked ? 1 : 0;
      dataToSend["saturday"] = this.isSaturdayChecked ? 1 : 0;
    }
    if(!this.isWorkplanIncluded){
      if(this.reasonType != null){
        dataToSend["removed_bin_reason_types_id"] = this.reasonType; 
      }else{
        return;
      }
    }

    if(this.data.properties.isBinRestrictionsTimes){
      const firstStartTime = this.selectedManageBinTime[0].startHour + ":" + this.selectedManageBinTime[0].startminute + ":00";
      const secondStartTime = this.selectedManageBinTime[1].startHour + ":" + this.selectedManageBinTime[1].startminute + ":00";
      const thirdStartTime = this.selectedManageBinTime[2].startHour + ":" + this.selectedManageBinTime[2].startminute + ":00";
  
      const firstEndTime = this.selectedManageBinTime[0].endHour + ":" + this.selectedManageBinTime[0].endMinute + ":00";
      const secondEndTime = this.selectedManageBinTime[1].endHour + ":" + this.selectedManageBinTime[1].endMinute + ":00";
      const thirdEndTime = this.selectedManageBinTime[2].endHour + ":" + this.selectedManageBinTime[2].endMinute + ":00";
  
      const duration1 = parseInt((moment.utc(moment(firstEndTime, "HH:mm:ss").diff(moment(firstStartTime, "HH:mm:ss"))).format("HH mm")).split(" ")[0])*60 + parseInt((moment.utc(moment(firstEndTime, "HH:mm:ss").diff(moment(firstStartTime, "HH:mm:ss"))).format("HH mm")).split(" ")[1]);
      const duration2 = parseInt((moment.utc(moment(secondEndTime, "HH:mm:ss").diff(moment(secondStartTime, "HH:mm:ss"))).format("HH mm")).split(" ")[0])*60 + parseInt((moment.utc(moment(secondEndTime, "HH:mm:ss").diff(moment(secondStartTime, "HH:mm:ss"))).format("HH mm")).split(" ")[1]);
      const duration3 = parseInt((moment.utc(moment(thirdEndTime, "HH:mm:ss").diff(moment(thirdStartTime, "HH:mm:ss"))).format("HH mm")).split(" ")[0])*60 + parseInt((moment.utc(moment(thirdEndTime, "HH:mm:ss").diff(moment(thirdStartTime, "HH:mm:ss"))).format("HH mm")).split(" ")[1]);
      
      dataToSend["restriction_1_duration"] = this.usageType == 1 ? duration1 : 0;
      dataToSend["restriction_1_start_time"] = this.usageType == 1 ? firstStartTime : 0;
      dataToSend["restriction_2_duration"] = this.usageType == 1 ? duration2 : 0;
      dataToSend["restriction_2_start_time"] = this.usageType == 1 ? secondStartTime : 0;
      dataToSend["restriction_3_duration"] = this.usageType == 1 ? duration3 : 0;
      dataToSend["restriction_3_start_time"] = this.usageType == 1 ? thirdStartTime : 0;
    }
    this.closeModal();
    this.data.parent.closeModal();
    this.apiService.setBinThresholdsAndRestrictions(dataToSend).subscribe((v:any) => {
      this.apiStore.update({ allFilteredBins: [] });                    
      this.apiStore.update({ filteredBins: [] });
      this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
      this.apiStore.update({ allFilteredBasicBins: [] });                    
      this.apiStore.update({ filteredBasicBins: [] }); 
      this.apiService.getfilteredBasicBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
		});

  }
  public incrementMaxCollection = () => {
    Number(this.maxDaysValue++);
  }
  public decrementMaxCollection = () => {
    if(this.maxDaysValue == 0){return;}
    Number(this.maxDaysValue--);
  }

  public incrementMaxCap = () => {
    Number(this.maxCapValue++);
  }
  public decrementMaxCap = () => {
    if(this.maxCapValue == 0){return;}
    Number(this.maxCapValue--);
  }

  public isRestricted = (index, restriction) => {
		return !restriction.checked
  }

  public toggleRestriction = () => {
    this.restrictionOpen = !this.restrictionOpen;
  }

  toggleEditableInclude(value){
    this.isWorkplanIncluded = value;
  }

  toggleEditableSunday(value) {				
		this.isSundayChecked = value;
  }

  toggleEditableMonday(value){
    this.isMondayChecked = value;
  }

  toggleEditableTuesday(value){
    this.isTuesdayChecked = value;
  }

  toggleEditableWednesday(value){
    this.isWednesdayChecked = value;
  }

  toggleEditableThursday(value){
    this.isThursdayChecked = value;
  }

  toggleEditableFriday(value){
    this.isFridayChecked = value;
  }

  toggleEditableSaturday(value){
    this.isSaturdayChecked = value;
  }

  
  filterItems(name: string) {
    this.approveAdd = false;
    let results = this.neighborhoods.filter(item =>
      item.toLowerCase().indexOf(name.toLowerCase()) === 0);
    this.showAddButton = results.length === 0;
    return results;
  }

  filterbinSitesToDisplay(name: string) {
    let results = this.binSitesToDisplay.filter(item =>
      item.toLowerCase().indexOf(name.toLowerCase()) === 0);
    return results;
  }

  optionSelected(option) {
    if (option.value.indexOf(this.prompt) === 0) {
      this.addOption();
    }
  }

  addOption() {
    let option = this.removePromptFromOption(this.ElForm.getRawValue()["itemCtrl"]);
    if (!this.neighborhoods.some(entry => entry === option)) {
      const index = this.neighborhoods.push(option) - 1;
      this.ElForm.controls["itemCtrl"].setValue(this.neighborhoods[index]);
      this.approveAdd = true;
    }
  }

  removePromptFromOption(option) {
    if (option.startsWith(this.prompt)) {
      option = option.substring(this.prompt.length, option.length);
    }
    return option;
  }
}

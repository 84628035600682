@if (!isMobile) {
  <div class="change-bin-collection" rtlDiv>
    <div class="drop-down-list-container add-remove-bins" rtlDiv>
      <div class="add-bins-title">{{'TRUCK_MAP.CHANGE_BIN' | translate}}</div>
      <div class="change-bin-to-collect">
        <div class="search-container">
          <div class="search-bin-container">
            <input class="search-input" [(ngModel)]="filterTables" placeholder="{{'OTHER.SEARCH' | translate }}"
              id="filter_tables" (input)="applyFilter($event.target)" />
              <img class='search-icon' src="assets/images/reports/dropdown_search_icon.svg" alt="" rtlDiv />
          </div>
        </div>
          <div class="popup-content" rtlDiv>
            <div class='bins-list-table activeBins' rtlDiv>
              <div class="change-bin-table-frame">
                <span class="change-bin-table-title">{{'TRUCK_MAP.BINS_OUTSIDE_WORKPLAN' | translate}}</span>
                @if(dataSourceAddBins){
                  <span class="total-bins">{{'TITLES.TOTAL_BINS' | translate}} - {{filteredAddBinsData.length}}</span>
                }
              </div>
              <div class='spinner-container-unassigned' [hidden]="!spinnerUnassignedBins">
                <div class="spinner"></div>
              </div>
              <div class="outside-table-border">
                <table class="table" mat-table #table [dataSource]="getAddBinsTableData()" matSort matSortStart="asc" matSortActive="bin_name"
                  matSortDisableClear (matSortChange)="sortAddBinsData($event)" matSortDirection="desc">
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef class="cut-change-bin-txt"></th>
                  <td mat-cell *matCellDef="let row" class="list-checkbox cut-change-bin-txt" rtlDiv>
                    <mat-checkbox class="direction" (click)="$event.stopPropagation()" (change)="$event ? selectionAddBins.toggle(row) : null" [checked]="selectionAddBins.isSelected(row)">
                    </mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="cut-change-bin-txt"> {{
                  'LIST_COLUMNS.NAME' | translate }} </th>
                  <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv> <span title="{{element ? element.bin_name : ''}}">{{element ? element.bin_name : ''}}</span></td>
                </ng-container>
                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="cut-change-bin-txt">{{ 'LIST_COLUMNS.ADDRESS'
                  | translate }}</th>
                  <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv><span title="{{element ? element.bin_address : ''}}">{{element ? element.bin_address: ''}}</span>  </td>
                </ng-container>
                <ng-container matColumnDef="neighborhood">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_neighborhood" class="cut-change-bin-txt" rtlDiv>
                  {{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</th>
                  <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv><span title="{{element ? element.bin_neighborhood : ''}}">{{element ? element.bin_neighborhood: ''}}</span>  </td>
                </ng-container>
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_status" class="cut-change-bin-txt">{{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                  <td mat-cell *matCellDef="let element" class="cut-change-bin-txt"> {{element ? element.bin_status: ''}}% </td>
                </ng-container>
                <ng-container matColumnDef="collectionMode">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="collection_mode" class="cut-change-bin-txtSts" rtlDiv>{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</th>
                  <td mat-cell *matCellDef="let element" class="cut-change-bin-txtSts" rtlDiv>
                    <img class='search-input-img' title="{{element ? getTitle(element) : ''}}" src="{{element ? getImg(element.collection_mode) : ''}}" alt="" />
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectionAddBins.toggle(row)"></tr>
              </table>
            </div>
          </div>
          <div class="add-remove-arrows" rtlDiv>
            @if (currentLang != 'iw') {
              <div>
                <img (click)="moveBinToInside()" class="right-arrow" rtlDiv src="../assets/images/workplans-page/right.svg" alt="right_icon">
              </div>
            }
            @if (currentLang != 'iw') {
              <div>
                <img (click)="moveBinToOutside()" class="left-arrow" rtlDiv src="../assets/images/workplans-page/left.svg" alt="left_icon">
              </div>
            }
            @if (currentLang == 'iw') {
              <div>
                <img (click)="moveBinToInside()" class="right-arrow" rtlDiv src="../assets/images/workplans-page/left.svg" alt="right_icon">
              </div>
            }
            @if (currentLang == 'iw') {
              <div >
                <img (click)="moveBinToOutside()" class="left-arrow" rtlDiv src="../assets/images/workplans-page/right.svg" alt="left_icon">
              </div>
            }
          </div>
          <div class='bins-list-table removedBins' rtlDiv>
            <div class="change-bin-table-frame">
              <span class="change-bin-table-title active-lbl">{{'TRUCK_MAP.BINS_INSIDE_WORKPLAN' | translate}}</span>
              @if(dataSource){
                <span class="total-bins">{{'TITLES.TOTAL_BINS' | translate}} - {{filteredData.length}}</span>
              }
            </div>
            <div class="inside-table-border">
              <table class="table" mat-table #table [dataSource]="getTableData()" matSort matSortStart="asc"
                matSortActive="bin_name" matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef class="cut-change-bin-txtChb" rtlDiv>
                    <mat-checkbox class="direction" (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                  </th>
                  <td class="cut-change-bin-txtChb list-checkbox" mat-cell *matCellDef="let row" rtlDiv>
                    <mat-checkbox class="direction" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="cut-change-bin-txt" rtlDiv>{{
                  'LIST_COLUMNS.NAME' | translate }}</th>
                  <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv> <span title="{{element ? element.bin_name : ''}}">{{element ? element.bin_name : ''}}</span></td>
                </ng-container>
                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="cut-change-bin-txt" rtlDiv>{{ 'LIST_COLUMNS.ADDRESS'
                  | translate }}</th>
                  <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv><span title="{{element ? element.bin_address : ''}}">{{element ? element.bin_address: ''}}</span>  </td>
                </ng-container>
                <ng-container matColumnDef="neighborhood">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_neighborhood" class="cut-change-bin-txt" rtlDiv>
                  {{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</th>
                  <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv><span title="{{element ? element.bin_neighborhood : ''}}">{{element ? element.bin_neighborhood: ''}}</span>  </td>
                </ng-container>
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_status" class="cut-change-bin-txtSts" rtlDiv>{{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                  <td mat-cell *matCellDef="let element" class="cut-change-bin-txtSts" rtlDiv> {{element ? element.bin_status: ''}}% </td>
                </ng-container>
                <ng-container matColumnDef="collectionMode">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="collection_mode" class="cut-change-bin-txtSts" rtlDiv>{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</th>
                  <td mat-cell *matCellDef="let element" class="cut-change-bin-txtSts" rtlDiv>
                    <img class='search-input-img' title="{{element ? getTitle(element) : ''}}" src="{{element ? getImg(element.collection_mode) : ''}}" alt="" />
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class='add-remove-bins-btns-container' rtlDiv>
        <div class='add-remove-bins-btn add' (click)='cancelModal()'>
          {{ 'TRUCK_MAP.CANCEL' | translate }}
        </div>
        <div class='add-remove-bins-btn apply' (click)='applyWorkplanChanges()'> {{ 'TRUCK_MAP.APPLY_CHANGES' |
        translate }}</div>
      </div>
    </div>
  </div>
}

@if (isMobile) {
  <div class="mobile-change-bin-collection" rtlDiv>
    <div class='drop-down-main-wrapper' rtlDiv>
      <div class="add-remove-bins" rtlDiv>
        <div class="mobile-modal-title" rtlDiv>
          <div class="dflex" (click)="cancelModal()">
            <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
          </div>
          <div>
            <span class="main-title">{{ 'TRUCK_MAP.CHANGE_BIN' | translate }}</span>
          </div>
        </div>
        <div class="mobile-change-bin-to-collect">
          <div  class="mobile-search-bin-container">
            <img class='search-icon' src="assets/images/reports/dropdown_search_icon.svg" alt="" rtlDiv />
            <input class="mobile-search-input" placeholder="{{'OTHER.SEARCH' | translate }}"
              (input)="applyFilter($event.target)" />
          </div>
          @if (selectedTab == 0 && selectionAddBins.selected.length > 0) {
            <div class="mobile-move-container">
              <div class="move-from">{{'WORKPLAN.MOVE' | translate}} {{selectionAddBins.selected.length}} {{'SITE_MANAGMENT.BINS' | translate}} {{'WORKPLAN.TO' | translate}} '{{'TRUCK_MAP.BINS_INSIDE_WORKPLAN' | translate}}'</div>
              <div class="move-btn" (click)="moveBinToInside()">
                <div>{{'WORKPLAN.MOVE' | translate}}</div>
              </div>
            </div>
          }
          @if (selectedTab == 1 && selection.selected.length > 0) {
            <div class="mobile-move-container">
              <div class="move-from">{{'WORKPLAN.MOVE' | translate}} {{selection.selected.length}} {{'SITE_MANAGMENT.BINS' | translate}} {{'WORKPLAN.TO' | translate}} '{{'TRUCK_MAP.BINS_OUTSIDE_WORKPLAN' | translate}}'</div>
              <div class="move-btn" (click)="moveBinToOutside()">
                <div>{{'WORKPLAN.MOVE' | translate}}</div>
              </div>
            </div>
          }
          <mat-tab-group mat-stretch-tabs="false" rtlDiv animationDuration="0ms" [selectedIndex]="1" (selectedIndexChange)="myTabSelectedIndexChange($event)">
            <mat-tab>
              <ng-template mat-tab-label>
                @if(dataSourceAddBins){
                  {{'TRUCK_MAP.BINS_OUTSIDE_WORKPLAN' | translate}} ({{filteredAddBinsData.length}})
                }
              </ng-template>
              <div>
                <div class="spinner-border mobile-spinner" [hidden]="!spinnerUnassignedBins">
                  <div class="spinner"></div>
                </div>
                <div class="card mobile-card" [hidden]="spinnerUnassignedBins">
                  <div class="card-body">
                    <div id="totalBins-All">
                      <table class="mobile-table" mat-table [dataSource]="getAddBinsTableDataMobile()" matSort matSortStart="asc"
                        matSortActive="bin_name" multiTemplateDataRows matSortDisableClear (matSortChange)="sortAddBinsData($event)" matSortDirection="desc">
                        <ng-container matColumnDef="select">
                          <th mat-header-cell *matHeaderCellDef class="mobile-th-title"></th>
                          <td mat-cell *matCellDef="let row" class="mobile-td-title" rtlDiv>
                            <mat-checkbox class="direction" (click)="$event.stopPropagation()" (change)="$event ? selectionAddBins.toggle(row) : null" [checked]="selectionAddBins.isSelected(row)">
                            </mat-checkbox>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="mobile-th-title"> {{'LIST_COLUMNS.NAME' | translate }} </th>
                          <td mat-cell *matCellDef="let element" class="mobile-td-title" rtlDiv>
                            <span class="mobile-td" title="{{element ? element.bin_name : ''}}">{{element ? element.bin_name : ''}}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_status" class="mobile-th-title">{{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                          <td mat-cell *matCellDef="let element" class="mobile-td-title" rtlDiv>
                            <span class="mobile-td" title="{{element ? element.bin_status : ''}}">{{element ? element.bin_status: ''}}%</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="arrowDown">
                          <th class="mobile-th-title" mat-sort-header="arrowDown" style="height: 52px;" rtlDiv mat-header-cell *matHeaderCellDef></th>
                          <td class="mobile-td-title" mat-cell *matCellDef="let element">
                            <div rtlDiv class="mobile-status-value">
                              @if (expandedElement !== element) {
                                <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                              }
                              @if (expandedElement === element) {
                                <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                              }
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="expandedDetail">
                          <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" rtlDiv>
                            <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                              <div class="mobile-element-diagram" rtlDiv>
                                <div class="mobile-item-details">
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</div>
                                    <div class="mobile-details-value"> {{element ? element.bin_neighborhood : ''}} </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</div>
                                    <div class="mobile-details-value"> {{element ? element.bin_address: ''}} </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</div>
                                    <div class="mobile-details-value"> 
                                      <img class='search-input-img' src="{{element ? getImg(element.collection_mode) : ''}}" alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumnsMobile;" (click)="expandedElement = expandedElement === element ? null : element"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                      </table>
                      <div>
                        <app-pagination [chosenBins]="filteredAddBinsData" (pageChange)='onAllBinsTablePageChange($event)' [filesPerPage]="8"></app-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                @if(dataSource){
                  {{'TRUCK_MAP.BINS_INSIDE_WORKPLAN' | translate}} ({{filteredData.length}})
                }
              </ng-template>
              <div>
                <div class="spinner-border mobile-spinner" [hidden]="!spinnerUnassignedBins">
                  <div class="spinner"></div>
                </div>
                <div class="card mobile-card" [hidden]="spinnerUnassignedBins">
                  <div class="card-body">
                    <div id="totalBins-All">
                      <table class="mobile-table" mat-table [dataSource]="getTableDataMobile()" matSort matSortStart="asc"
                        matSortActive="bin_name" multiTemplateDataRows matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
                        <ng-container matColumnDef="select">
                          <th mat-header-cell *matHeaderCellDef class="mobile-th-title" rtlDiv>
                            <mat-checkbox class="direction" (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()" rtlDiv>
                            </mat-checkbox>
                          </th>
                          <td mat-cell *matCellDef="let row" class="mobile-td-title" rtlDiv>
                            <mat-checkbox class="direction" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" rtlDiv>
                            </mat-checkbox>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="mobile-th-title"> {{'LIST_COLUMNS.NAME' | translate }} </th>
                          <td mat-cell *matCellDef="let element" class="mobile-td-title" rtlDiv>
                            <span class="mobile-td" title="{{element ? element.bin_name : ''}}">{{element ? element.bin_name : ''}}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_status" class="mobile-th-title">{{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                          <td mat-cell *matCellDef="let element" class="mobile-td-title" rtlDiv>
                            <span class="mobile-td" title="{{element ? element.bin_status : ''}}">{{element ? element.bin_status: ''}}%</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="arrowDown">
                          <th class="mobile-th-title" mat-sort-header="arrowDown" style="height: 52px;" rtlDiv mat-header-cell *matHeaderCellDef></th>
                          <td class="mobile-td-title" mat-cell *matCellDef="let element">
                            <div rtlDiv class="mobile-status-value">
                              @if (expandedElement !== element) {
                                <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                              }
                              @if (expandedElement === element) {
                                <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                              }
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="expandedDetail">
                          <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" rtlDiv>
                            <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                              <div class="mobile-element-diagram" rtlDiv>
                                <div class="mobile-item-details">
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</div>
                                    <div class="mobile-details-value"> {{element ? element.bin_neighborhood : ''}} </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</div>
                                    <div class="mobile-details-value"> {{element ? element.bin_address: ''}} </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</div>
                                    <div class="mobile-details-value"> 
                                      <img class='search-input-img' src="{{element ? getImg(element.collection_mode) : ''}}" alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumnsMobile;" (click)="expandedElement = expandedElement === element ? null : element"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                      </table>
                      <div>
                        <app-pagination [chosenBins]="filteredData" (pageChange)='onTablePageChange($event)' [filesPerPage]="8"></app-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class='main-btns-wrapper' rtlDiv>
          <div class='btns-container' rtlDiv>
            <div class='mobile-cancel_btn' (click)="cancelModal()" rtlDiv>{{ 'TRUCK_MAP.CANCEL' | translate }}</div>
            <div class='mobile-apply-btn' (click)='applyWorkplanChanges()' rtlDiv>{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
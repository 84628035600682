@if (!isMobile) {
    <div>
      <div class="modal-title">
        <span>{{'TITLES.DELETE' | translate }} {{ 'NEW_WORKPLAN.TRUCK' | translate }}</span>
      </div>
      <div class="content-body" rtlDiv>
        @if (data.chosenTrucks.length > 1) {
          <span>{{'TITLES.DELETE_MSG' | translate }} {{data.chosenTrucks.length}} {{ 'NEW_WORKPLAN.TRUCKS' | translate }}</span>
        }
        @if (data.chosenTrucks.length == 1) {
          <span>{{'TITLES.SINGLE_DELETE_MSG' | translate }} {{'NEW_WORKPLAN.TRUCK' | translate }} {{data.chosenTrucks[0].truck_id}}</span>
        }
        <span>?</span>
      </div>
      <div [ngClass]="{'rtldir': currentLang != 'iw','ltrdir':currentLang == 'iw'}">
        <button (click)='applyChanges()' [ngClass]="{'not-apply-btn' : submitClicked , 'apply-btn' : !submitClicked}">
          {{ 'TITLES.DELETE' | translate }}
        </button>
        <button class="cancel_btn" (click)="closeModal()">{{ 'TRUCK_MAP.CANCEL' | translate }}</button>
      </div>
    </div>
  }
  
  @if (isMobile) {
    <div>
      <div class="mobile-modal-title">
        <span>{{'TITLES.DELETE' | translate }} {{ 'NEW_WORKPLAN.TRUCK' | translate }}</span>
      </div>
      <div class="mobile-content-body" rtlDiv>
        @if (data.chosenTrucks.length > 1) {
          <span>{{'TITLES.DELETE_MSG' | translate }} {{data.chosenTrucks.length}} {{ 'NEW_WORKPLAN.TRUCKS' | translate }}</span>
        }
        @if (data.chosenTrucks.length == 1) {
          <span>{{'TITLES.SINGLE_DELETE_MSG' | translate }} {{'NEW_WORKPLAN.TRUCK' | translate }} {{data.chosenTrucks[0].truck_id}}</span>
        }
        <span>?</span>
      </div>
      <div [ngClass]="{'mobile-rtldir': currentLang != 'iw','mobile-ltrdir':currentLang == 'iw'}">
        <button (click)='applyChanges()' [ngClass]="{'not-apply-btn' : submitClicked , 'apply-btn' : !submitClicked}">
          {{ 'TITLES.DELETE' | translate }}
        </button>
        <button class="cancel_btn" (click)="closeModal()">{{ 'TRUCK_MAP.CANCEL' | translate }}</button>
      </div>
    </div>
  }
  
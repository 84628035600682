@if (!isMobile) {
  <div>
    <div class="inventory-page" rtlDiv>
      <div class="inventory-header" rtlDiv>
        {{'SIDEBAR.TICKET_MANAGEMENT' | translate }}
      </div>
      <mat-divider class="top-divider"></mat-divider>
    </div>
    <div class="ticket-view">
      <mat-tab-group (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false">
        <mat-tab label="{{'TICKET_MANAGEMENT.CHECK_OR_REPLACEMENT_TAB' | translate}}">
          <mat-card>
            <div class='spinner-container' [hidden]="!checkOrReplacementSpinnerActive">
              <div class="spinner"></div>
            </div>
            <mat-card-content [hidden]="checkOrReplacementSpinnerActive">
              <div class="tab-width">
                <div class='search-input-container' rtlDiv>
                  <div class="search-frame">
                    <input id="checkOrReplacementFilter" class='search-input' type="text" (input)="applyCheckOrReplacementFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate }}" name="">
                    <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                  </div>
                  <div class='table-export-container' rtlDiv>
                    <div class='table-export-btns-wrapper' (click)="exportToCsv('0')" rtlDiv>
                      <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                      <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                    </div>
                    @if (user_role_id == 5 || user_role_id == 2 || user_role_id == 4) {
                      <div class="manage-bins-btn-container" (click)="openAddTicket()" rtlDiv>
                        <a class="manage-bins-btn" rtlDiv>
                          <span>
                            <img class="arow_manage" src="assets/images/dashboard/Plus.svg" rtlDiv/>
                          </span>
                          <span class="btn-text">{{ 'TICKET_MANAGEMENT.ADD_EVENT' | translate }}</span>
                        </a>
                      </div>
                    }
                  </div>
                </div>
                <table class="table" mat-table [dataSource]="getCheckOrReplacementData()" matSort matSortStart="asc"
                  matSortActive="created" matSortDisableClear (matSortChange)="sortCheckOrReplacementTicketsData($event)" matSortDirection="desc">
                  <ng-container matColumnDef="ticket_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="ticket_id" class="th-title">
                    {{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="td-value"> {{element ? element.ticket_id : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="ticket_status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="status" class="th-title">
                    {{ 'TICKET_MANAGEMENT.STATUS' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <span [ngClass]="{'pc-opened-event' : element.status == 1, 'pc-closed-event' : element.status != 1}">{{element ? element.status_txt : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="event_cause">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="ticket_event_cause_id" class="th-title">
                    {{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      @if (currentLang != 'iw') {
                        <span class="td-value"> {{element ? element.eventCauseEn : ''}}</span>
                      }
                      @if (currentLang == 'iw') {
                        <span class="td-value"> {{element ? element.eventCauseHe : ''}}</span>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="priority">
                    <th [hidden]="user_role_id == 3 || user_role_id == 4" mat-header-cell *matHeaderCellDef mat-sort-header="priority_id" class="th-title">
                    {{ 'TICKET_MANAGEMENT.PRIORITY' | translate }} </th>
                    <td [hidden]="user_role_id == 3 || user_role_id == 4" mat-cell *matCellDef="let element">
                      <div class="dflex-center">
                        <span [ngClass]="{'low-priority-dot':element.priority_id == 1,'medium-priority-dot':element.priority_id == 2,'high-priority-dot':element.priority_id == 3}"></span>
                        @if (currentLang != 'iw') {
                          <span class="td-value"> {{ element ? element.priorityEn : ''}}</span>
                        }
                        @if (currentLang == 'iw') {
                          <span class="td-value"> {{element ? element.priorityHe : ''}}</span>
                        }
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="site_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="site_id" class="th-title">
                    {{ 'TICKET_MANAGEMENT.SITE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="td-value"> {{element ? element.site_name : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="th-title">
                    {{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      @if (element.bin_id == 0 || element.bin_id == '') {
                        <span class="td-value"> {{element ? element.bin_name : ''}}</span>
                      }
                      @if (element.bin_id != 0 && element.bin_id != '') {
                        <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'ticketManagement',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="td-value"> {{element ? element.bin_name : ''}}</a>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="th-title">
                    {{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="td-value"> {{element ? element.bin_address : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="comment">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="comment" class="th-title">
                    {{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="td-value"> {{element ? element.comment : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="reporter">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="reporter" class="th-title">
                    {{ 'TICKET_MANAGEMENT.REPORTER' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="td-value"> {{element ? (element.first_name == null || element.last_name == null ? '' : element.reporter) : ''}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="created">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="created" class="th-title">
                    {{ 'TICKET_MANAGEMENT.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="td-value">
                        {{(currentLang == 'iw' && element ? utilService.convertDateHebrewWithoutTime(element.created) : utilService.convertDateEnglishWithoutTime(element.created))}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="arrival_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="expected_arrival_date" class="th-title">
                    {{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="td-value">
                        {{(currentLang == 'iw' && element ? utilService.convertDateHebrewWithoutTime(element.expected_arrival_date) : utilService.convertDateEnglishWithoutTime(element.expected_arrival_date))}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="closed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="closed" class="th-title">
                    {{ 'TICKET_MANAGEMENT.CLOSED' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="td-value">
                        {{(currentLang == 'iw' && element ? utilService.convertDateHebrew(element.closed) : utilService.convertDateEnglish(element.closed))}}
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="manage">
                    <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="manage"> </th>
                    <td mat-cell *matCellDef="let element" class="manage-bins">
                      <div class="menu-Cmp" rtlDiv>
                        <div class="dropdown-toggle" data-bs-toggle="dropdown">
                          <img alt="" src="../../../assets/images/dashboard/threedots.svg">
                        </div>
                        <div class="dropdown-menu list-items" rtlDiv>
                          @if (user_role_id == 1 || user_role_id == 3 || user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) {
                            <div class="menuItem" (click)="viewAction(element,element.status,0)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.VIEW' | translate }}</span>
                            </div>
                          }
                          @if (user_role_id == 5 || user_role_id == 2 || deleteOwnEvent(element)) {
                            <div class="menuItem" (click)="deleteAction(element,element.status)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.DELETE' | translate }}</span>
                            </div>
                          }
                          @if ((user_role_id == 5 || user_role_id == 6) && element.status == 1) {
                            <div class="menuItem" (click)="resolveAction(element,element.status,0)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.CLOSE' | translate }}</span>
                            </div>
                          }
                          @if ((user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) && element.status == 1) {
                            <div class="menuItem" (click)="editAction(element,element.status)">
                              <span class="item-txt">{{ 'TICKET_MANAGEMENT.EDIT' | translate }}</span>
                            </div>
                          }
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedCheckOrReplacementColumns"></tr>
                  <tr class="ticket-row" mat-row *matRowDef="let row; columns: displayedCheckOrReplacementColumns;"></tr>
                </table>
                @if (checkOrReplacementTicketsTableLength > 0) {
                  <div>
                    <app-pagination [chosenBins]="filteredCheckOrReplacementData" (pageChange)='onCheckOrReplacementPageChange($event)' [filesPerPage]="10"></app-pagination>
                  </div>
                }
              </div>
            </mat-card-content>
          </mat-card>
        </mat-tab>
      <mat-tab label="{{'TICKET_MANAGEMENT.CANCELLATION_TAB' | translate}}">
        <mat-card>
          <div class='spinner-container' [hidden]="!cancellationSpinnerActive">
            <div class="spinner"></div>
          </div>
          <mat-card-content [hidden]="cancellationSpinnerActive">
            <div class="tab-width">
              <div class='search-input-container' rtlDiv>
                <div class="search-frame">
                  <input id="cancellationFilter" class='search-input' type="text" (input)="applyCancellationFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate }}" name="">
                  <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                </div>
                <div class='table-export-container' rtlDiv>
                  <div class='table-export-btns-wrapper' (click)="exportToCsv('2')" rtlDiv>
                    <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                    <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                  </div>
                  @if (user_role_id == 5 || user_role_id == 2 || user_role_id == 4) {
                    <div class="manage-bins-btn-container" (click)="openAddTicket()" rtlDiv>
                      <a class="manage-bins-btn" rtlDiv>
                        <span>
                          <img class="arow_manage" src="assets/images/dashboard/Plus.svg" rtlDiv/>
                        </span>
                        <span class="btn-text">{{ 'TICKET_MANAGEMENT.ADD_EVENT' | translate }}</span>
                      </a>
                    </div>
                  }
                </div>
              </div>
              <table class="table" mat-table [dataSource]="getCancellationData()" matSort matSortStart="asc"
                matSortActive="closed" matSortDisableClear (matSortChange)="sortCancellationTicketsData($event)" matSortDirection="desc">
                <ng-container matColumnDef="ticket_id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="ticket_id" class="th-title">
                  {{ 'TICKET_MANAGEMENT.EVENT_ID' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="td-value"> {{element ? element.ticket_id : ''}}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="ticket_status">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="status" class="th-title">
                  {{ 'TICKET_MANAGEMENT.STATUS' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    <span [ngClass]="{'pc-opened-event' : element.status == 1, 'pc-closed-event' : element.status != 1}">{{element ? element.status_txt : ''}}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="event_cause">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="ticket_event_cause_id" class="th-title">
                  {{ 'TICKET_MANAGEMENT.EVENT_CAUSE' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    @if (currentLang != 'iw') {
                      <span class="td-value"> {{element ? element.eventCauseEn : ''}}</span>
                    }
                    @if (currentLang == 'iw') {
                      <span class="td-value"> {{element ? element.eventCauseHe : ''}}</span>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="priority">
                  <th [hidden]="user_role_id == 3 || user_role_id == 4" mat-header-cell *matHeaderCellDef mat-sort-header="priority_id" class="th-title">
                  {{ 'TICKET_MANAGEMENT.PRIORITY' | translate }} </th>
                  <td [hidden]="user_role_id == 3 || user_role_id == 4" mat-cell *matCellDef="let element">
                    <div class="dflex-center">
                      <span [ngClass]="{'low-priority-dot':element.priority_id == 1,'medium-priority-dot':element.priority_id == 2,'high-priority-dot':element.priority_id == 3}"></span>
                      @if (currentLang != 'iw') {
                        <span class="td-value"> {{element ? element.priorityEn : ''}}</span>
                      }
                      @if (currentLang == 'iw') {
                        <span class="td-value"> {{element ? element.priorityHe : ''}}</span>
                      }
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="site_name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="site_id" class="th-title">
                  {{ 'TICKET_MANAGEMENT.SITE' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="td-value"> {{element ? element.site_name : ''}}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="bin_name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="th-title">
                  {{ 'TICKET_MANAGEMENT.BIN_NAME' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    @if (element.bin_id == 0 || element.bin_id == '') {
                      <span class="td-value"> {{element ? element.bin_name : ''}}</span>
                    }
                    @if (element.bin_id != 0 && element.bin_id != '') {
                      <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'ticketManagement',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="td-value"> {{element ? element.bin_name : ''}}</a>
                    }
                  </td>
                </ng-container>
                <ng-container matColumnDef="bin_address">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="th-title">
                  {{ 'TICKET_MANAGEMENT.BIN_ADDRESS' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="td-value"> {{element ? element.bin_address : ''}}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="comment">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="comment" class="th-title">
                  {{ 'TICKET_MANAGEMENT.DESCRIPTION' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="td-value"> {{element ? element.comment : ''}}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="reporter">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="reporter" class="th-title">
                  {{ 'TICKET_MANAGEMENT.REPORTER' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="td-value"> {{element ? (element.first_name == null || element.last_name == null ? '' : element.reporter) : ''}}</span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="created" class="th-title">
                  {{ 'TICKET_MANAGEMENT.CREATED' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="td-value">
                      {{(currentLang == 'iw' && element ? utilService.convertDateHebrewWithoutTime(element.created) : utilService.convertDateEnglishWithoutTime(element.created))}}
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="arrival_date">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="expected_arrival_date" class="th-title">
                  {{ 'TICKET_MANAGEMENT.ARRIVAL_DATE' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="td-value">
                      {{(currentLang == 'iw' && element ? utilService.convertDateHebrewWithoutTime(element.expected_arrival_date) : utilService.convertDateEnglishWithoutTime(element.expected_arrival_date))}}
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="closed">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="closed" class="th-title">
                  {{ 'TICKET_MANAGEMENT.CLOSED' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    <span class="td-value">
                      {{(currentLang == 'iw' && element ? utilService.convertDateHebrew(element.closed) : utilService.convertDateEnglish(element.closed))}}
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="manage">
                  <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="manage"> </th>
                  <td mat-cell *matCellDef="let element" class="manage-bins">
                    <div class="menu-Cmp" rtlDiv>
                      <div class="dropdown-toggle" data-bs-toggle="dropdown">
                        <img alt="" src="../../../assets/images/dashboard/threedots.svg">
                      </div>
                      <div class="dropdown-menu list-items" rtlDiv>
                        @if (user_role_id == 1 || user_role_id == 3 || user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) {
                          <div class="menuItem" (click)="viewAction(element,element.status,2)">
                            <span class="item-txt">{{ 'TICKET_MANAGEMENT.VIEW' | translate }}</span>
                          </div>
                        }
                        @if (user_role_id == 5 || user_role_id == 2 || deleteOwnEvent(element)) {
                          <div class="menuItem" (click)="deleteAction(element,element.status)">
                            <span class="item-txt">{{ 'TICKET_MANAGEMENT.DELETE' | translate }}</span>
                          </div>
                        }
                        @if ((user_role_id == 5 || user_role_id == 6) && element.status == 1) {
                          <div class="menuItem" (click)="resolveAction(element,element.status,2)">
                            <span class="item-txt">{{ 'TICKET_MANAGEMENT.CLOSE' | translate }}</span>
                          </div>
                        }
                        @if ((user_role_id == 5 || user_role_id == 6 || user_role_id == 2 || user_role_id == 4) && element.status == 1) {
                          <div class="menuItem" (click)="editAction(element,element.status)">
                            <span class="item-txt">{{ 'TICKET_MANAGEMENT.EDIT' | translate }}</span>
                          </div>
                        }
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedCancellationColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedCancellationColumns;"></tr>
              </table>
              @if (cancellationTicketsTableLength > 0) {
                <div>
                  <app-pagination [chosenBins]="filteredCancellationData" (pageChange)='onCancellationPageChange($event)' [filesPerPage]="10"></app-pagination>
                </div>
              }
            </div>
          </mat-card-content>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
}

@if (isMobile) {
  <div>
  </div>
}
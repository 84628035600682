@if(!isMobile){
  <div class="workplan-description">
    <div class="content-number">
      <span>{{number}}</span>
      @if(total != undefined){
        <span>&nbsp;/&nbsp;</span>
        <span class="total">{{total}}</span>
      }
    </div>
    <div class="content-text">{{description}}</div>
  </div>
}

@if(isMobile){
  <div>
    <div class="mobile-bin-status">
      <div class="mobile-bins-number">
        <span class="mobile-number">{{number}}</span>
        @if(total != undefined){
          <span>&nbsp;/&nbsp;</span>
          <span class="mobile-total total">{{total}}</span>
        }
      </div>
      <div class="mobile-bins-collected">{{description}}</div>
    </div>
  </div>
}
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslatorService } from '../../../services/translator_service';
import { MatDialog } from '@angular/material/dialog';
import { ApiStore } from '../../../web-services/api/api.store';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ResponsiveService } from '../../../services/responsive.service';
import { CreateNewWorkplanModalComponent } from '../create-new-workplan-modal/create-new-workplan-modal.component';
import { ForceBinCollectionModalComponent } from '../force-bin-collection-modal/force-bin-collection-modal.component';
import moment from 'moment';
import _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { WorkplanScheduleComponent } from '../workplan-schedule/workplan-schedule.component';
import { WorkplanDateComponent } from '../workplan-date/workplan-date.component';
import { WorkplanManagerComponent } from '../workplan-manager/workplan-manager.component';
import { TruckDetailsComponent } from '../truck-details/truck-details.component';
import { TruckWorkplansListViewComponent } from '../truck-workplans-list-view/truck-workplans-list-view.component';
import { MatCardModule } from '@angular/material/card';
import { MOMENT } from 'angular-calendar';
import { TruckMapComponent } from '../truck-map/truck-map.component';

@Component({
  selector: 'app-workplans',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,CommonModule,MatDividerModule,WorkplanScheduleComponent,
    WorkplanDateComponent,WorkplanManagerComponent,TruckDetailsComponent,TruckWorkplansListViewComponent,
    MatCardModule,TruckMapComponent],
  templateUrl: './workplans.component.html',
  styleUrl: './workplans.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkplansComponent {
  translate;
	isMobile: Boolean;
	loginException:object = null;
	showRecommendedWorkPlanClicked:boolean = false;
	isActiveWorkPlan: boolean = true;
	futureWorkplanExist:boolean = false;
	recommendedWorkPlan:boolean = false;
	userGeneratedState:boolean = false;
	emptyTruck:boolean = true;
	totalDistance: number = 0;
	totalTime = '';
	spinnerActive: boolean = true;
	chosenTruck:object;
	chosenSite:object;
	start_date:any;
	end_date:any;
	dayEvents:any;
	eventSelected:any;
	dateClicked:object;
	isAdmin: boolean = false;
	dateSelected:string;
	truckDriverName:string;
	tabletTeamsAllocationId:string;
	trucksListData: any[];
	currentLang;
  	translationsObj;
	periods = [];
	days = [];
	constructor(private translator: TranslatorService,private dialog: MatDialog,
		private responsiveService: ResponsiveService,private apiQuery:ApiQuery,private apiStore:ApiStore) {
			this.translate = this.translator;
			this.translator.currentLangEmitter$
			.subscribe(async value=>{						
			  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			  this.currentLang = value;	
			  this.periods = [
				{value: '1', viewValue: this.translationsObj.NEW_WORKPLAN.DAY},
				{value: '7', viewValue: this.translationsObj.NEW_WORKPLAN.WEEK},
				{value: '30', viewValue: this.translationsObj.NEW_WORKPLAN.MONTH}
			]
			
			this.days = [
				{value: '1', viewValue: this.translationsObj.NEW_WORKPLAN.SUNDAY},
				{value: '2', viewValue: this.translationsObj.NEW_WORKPLAN.MONDAY},
				{value: '3', viewValue: this.translationsObj.NEW_WORKPLAN.TUESDAY},
				{value: '4', viewValue: this.translationsObj.NEW_WORKPLAN.WEDNESDAY},
				{value: '5', viewValue: this.translationsObj.NEW_WORKPLAN.THURSDAY},
				{value: '6', viewValue: this.translationsObj.NEW_WORKPLAN.FRIDAY},
				{value: '6', viewValue: this.translationsObj.NEW_WORKPLAN.SATURDAY}
			]
			})
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.showRecommendedWorkPlanClicked = false;
		this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}
			if ((user["user_role_id"] == 2) || (user["user_role_id"] == 4) || (user["user_role_id"] == 1) || (user["user_role_id"] == 5) || (user["user_role_id"] == 6)) {
				this.isAdmin = true;
			}
		});


		this.apiQuery.filteredTrucks$.subscribe(trucksList => {
			if (!trucksList) return;
			this.subscribeTrucksList(trucksList);
		});
	}

	private subscribeTrucksList = (trucksList) => {
		this.trucksListData = trucksList;
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

	
	ngOnDestroy() {
		this.apiStore.update({ selectedWorkplan: {} });
		this.apiStore.update({ createdWorkplan: [] });
	}

	showRecommendedWorkPlan(){
		if(this.spinnerActive){
			return;
		}else{
			this.showRecommendedWorkPlanClicked = true;
			this.recommendedWorkPlan = true;
		}
	}

	public openCreateNewWorkplanModal = () => {		
		if(this.trucksListData.length == 0){
			return;
		}else{
			if(this.isMobile){
				this.dialog.open(CreateNewWorkplanModalComponent, { panelClass: 'popup-container-mobile', data: {dayClicked : this.dateClicked, periods : this.periods, days : this.days} });
			}else{
				this.dialog.open(CreateNewWorkplanModalComponent, { data: {dayClicked : this.dateClicked, periods : this.periods, days : this.days} });
			}
		}
	}

	public openForceBinsToFutureWorkplanModal = () => {		
		if(this.trucksListData.length == 0){
			return;
		}else{
			if(this.isMobile){
				this.dialog.open(ForceBinCollectionModalComponent, { panelClass: 'popup-container-mobile' });
			}else{
				this.dialog.open(ForceBinCollectionModalComponent);
			}
		}
	}

	updateDistance(dist) {
		this.totalDistance = dist / 1000;
	}

	updateTime(time){
		this.totalTime = time;
	}

	loginExceptionObj($event) {
		this.loginException = $event;				
	}

	activeWorkplanHandler($event) {
		this.isActiveWorkPlan = $event;						
	}

	eventSelectedHandler($event) {						
		this.eventSelected = $event;										
	}

	dateClickedHandler($event){
		this.dateClicked = $event;		
	}

	userGeneratedStateHandler($event) {						
		this.userGeneratedState = $event;			
		if(this.userGeneratedState){
			this.userGeneratedState = true;
			this.recommendedWorkPlan = true;
			this.futureWorkplanExist = false;
			this.isActiveWorkPlan = true;
			this.emptyTruck = false;
		}
	}

	eventsHandler($event) {				
		this.spinnerActive=false;
		this.dayEvents=$event;
		let userGenerated = [];	
		let active = []
		this.emptyTruck = false;
		if(this.dayEvents.length > 0){
			if(new Date(moment(this.dayEvents[0].start).format("YYYY-MM-DD 00:00:00")).getTime() > new Date(moment().format("YYYY-MM-DD 00:00:00")).getTime()){
				this.futureWorkplanExist = true;
				this.isActiveWorkPlan = true;
				this.recommendedWorkPlan = true;
				this.userGeneratedState = false;
			}else if(new Date(moment(this.dayEvents[0].start).format("YYYY-MM-DD 00:00:00")).getTime() == new Date(moment().format("YYYY-MM-DD 00:00:00")).getTime()){				
				userGenerated = this.dayEvents.filter(obj => {
					return obj.userGenerated == "userGenerated";
				});
				active = this.dayEvents.filter(obj => {
					return obj.userGenerated == "currentActive";
				});
				if(this.dayEvents.length == 1 && this.dayEvents[0].icon == "../../assets/images/workplans-page/Executed.svg"){
					this.futureWorkplanExist = false;
					this.recommendedWorkPlan = true;
					this.userGeneratedState = false;
				}
				else if(userGenerated.length == 0 && active.length !=0){
						_.each(this.dayEvents, (item) => {
							if(item.icon == '../../assets/images/workplans-page/userGenerated.svg'){
								this.futureWorkplanExist = false;
								this.isActiveWorkPlan = true;
								this.recommendedWorkPlan = true;
								this.userGeneratedState = false;
								return;
							}
						});	
					if(!this.showRecommendedWorkPlanClicked){
						this.futureWorkplanExist = false;
						this.isActiveWorkPlan = true;
						this.recommendedWorkPlan = false;
						this.userGeneratedState = false;	
					}else{
						this.futureWorkplanExist = false;
						this.isActiveWorkPlan = true;
						this.recommendedWorkPlan = true;
						this.userGeneratedState = false;
					}							
				}else{										
					this.futureWorkplanExist = false;
					this.recommendedWorkPlan = true;
					this.userGeneratedState = false;
				}
			}else if(new Date(moment(this.dayEvents[0].start).format("YYYY-MM-DD 00:00:00")).getTime() < new Date(moment().format("YYYY-MM-DD 00:00:00")).getTime()){
				for(let i=0 ; i<this.dayEvents.length ; i++){
					if(this.dayEvents[i]["userGenerated"] == "userGenerated"){
						this.futureWorkplanExist = true;
						this.isActiveWorkPlan = true;
						this.recommendedWorkPlan = true;
						this.userGeneratedState = false;
					}else{
						this.futureWorkplanExist = false;
						this.isActiveWorkPlan = true;
						this.recommendedWorkPlan = true;
						this.userGeneratedState = false;
					}
				}
			}
		}else{
			this.futureWorkplanExist = false;
			this.recommendedWorkPlan = true;
			this.userGeneratedState = false;
		}
	}

	chosenTruckHandler($event){
		this.chosenTruck = $event;
		let totalBins = [];
		
		if(this.chosenTruck["Bins_collection_info"] == null){
			this.userGeneratedState = true;
			this.recommendedWorkPlan = true;
			this.futureWorkplanExist = false;
			this.isActiveWorkPlan = true;
			this.emptyTruck = true;
			return;
		}else{
			if(this.showRecommendedWorkPlanClicked){
				this.futureWorkplanExist = false;
				this.isActiveWorkPlan = true;
				this.recommendedWorkPlan = true;
				this.userGeneratedState = false;
				this.emptyTruck = true;
			}
		}

		let collectedBins = this.getCollectedBinCount(this.chosenTruck["Bins_collection_info"]);
		if(collectedBins > 0){
			this.futureWorkplanExist = false;
			this.isActiveWorkPlan = true;
			this.recommendedWorkPlan = true;
			this.userGeneratedState = false;
			this.emptyTruck = false;
		}

		_.each(this.chosenTruck["Bins_collection_info"], (bin) => {
			totalBins.push(bin);
		});

		if(totalBins.length == 0){
			this.futureWorkplanExist = false;
			this.isActiveWorkPlan = true;
			this.recommendedWorkPlan = false;
			this.userGeneratedState = false;
			this.emptyTruck = false;
		}

	}

	chosenSiteHandler($event){
		this.chosenSite = $event;
	}

	private getCollectedBinCount = (data) => {
		return data.filter(n => n.is_collected).length;
	}

	tabletTeamsAllocationIdHandler($event){
		this.tabletTeamsAllocationId = $event;
	}

	truckDriverNameHandler($event){
		this.truckDriverName=$event;
	}

	dateSelectedHandler($event){
		this.dateSelected = $event;
	}
}

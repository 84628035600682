@if (!isMobile) {
  <div class="alerts-his">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="title">{{ 'TITLES.ALERTS' | translate }}</mat-card-title>
        <div class="close-btn" rtlDiv (click)="closeCMP()">
          <img class="close-img" src="../../../assets/images/dashboard/closeX.svg">
        </div>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <div class='spinner-container' [hidden]="!spinnerActive">
        <div class="spinner"></div>
      </div>
      <mat-card-content [hidden]="spinnerActive">
        @if (allAlerts.length == 0) {
          <div class="empty-frame">
            <img src="assets/images/topmenu/empty_alert_state.svg" alt="" class="empty-img" />
            <div><span class="empty-txt">{{ 'WIDGETS.NO_ALERTS' | translate }}</span></div>
          </div>
        }
        @if (allAlerts.length > 0) {
          <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
            @for (alert of allAlerts; track alert; let i = $index) {
              <div class="card dataAcc">
                <div class="card-header headerAcc" role="tab">
                  <a data-bs-toggle="collapse" href="#collapseOne{{i}}">
                    <h5 class="mb-0 titleAcc">
                      {{currentLang != 'iw' ? alert.alert_name : alert.alert_name_hebrew}} ({{alert.alert_data.length}})
                      <div class="frame-arrow">
                        <img src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                      </div>
                    </h5>
                  </a>
                </div>
                @if (alert) {
                  <div [ngClass]="{'more':alert.alert_data.length > 1,'less':alert.alert_data.length == 1}" id="collapseOne{{i}}" class="collapse" role="tabpanel">
                    <cdk-virtual-scroll-viewport itemSize="45" style="height:100%">
                      <ul class="card-body alertBody" *cdkVirtualFor="let alertData of alert.alert_data">
                        <li>
                          <div class="dflex">
                            <span class="bodyTxt idTxt">{{alertData.alert_id}} | </span>
                            <span class="bodyTxt">@if (usageType == 1) {
                              <a class="aColor" [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'dashboard',binId:alertData.bin_id}"> &nbsp;{{ alertData.bin_name }} &nbsp;</a>
                            }</span>
                            <span class="bodyTxt">@if (usageType == 2) {
                              <a class="aColor" [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'dashboard',binId:alertData.bin_id}"> &nbsp;{{ alertData.bin_description }} &nbsp;</a>
                            }</span>
                            <span> - &nbsp;</span>
                            <span class="bodyTxt">{{currentLang != 'iw' ? alertData.alert_body : alertData.alert_body_hebrew}}</span>
                          </div>
                          @if (currentLang == 'iw') {
                            <div class="dateTxt">{{validateDateIw(alertData.alert_time)}}</div>
                          }
                          @if (currentLang != 'iw') {
                            <div class="dateTxt">{{validateDateEn(alertData.alert_time)}}</div>
                          }
                        </li>
                      </ul>
                    </cdk-virtual-scroll-viewport>
                  </div>
                }
              </div>
            }
          </div>
        }
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
    <div class="accordion md-accordion" role="tablist" aria-multiselectable="true">
      <div class="card mobile-card">
        <div class="card-header mobile-header">
          <div class="mobile-flex">
            <span class="mobile-title">{{ 'TITLES.ALERTS' | translate }}</span>
          </div>
          <div class="mobile-close-btn" rtlDiv (click)="closeCMP()">
            <img class="mobile-close-img" src="../../../assets/images/dashboard/closeX.svg">
          </div>
        </div>
        <div class="collapse show" role="tabpanel">
          <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
            <div [hidden]="!spinnerActive" class="spinner-border mobile-spinner" role="status">
              <span class="sr-only"></span>
            </div>
            <div [hidden]="spinnerActive">
              @if (allAlerts.length == 0) {
                <div class="mobile-empty-frame">
                  <img src="assets/images/topmenu/empty_alert_state.svg" alt="" class="mobile-empty-img" />
                  <div><span class="empty-txt">{{ 'WIDGETS.NO_ALERTS' | translate }}</span></div>
                </div>
              }
              @if (allAlerts.length > 0) {
                @for (alert of allAlerts; track alert; let i = $index) {
                  <div class="card dataAcc">
                    <div class="card-header headerAcc" role="tab">
                      <a data-bs-toggle="collapse" href="#collapseOne{{i}}">
                        <h5 class="mb-0 titleAcc">
                          {{currentLang != 'iw' ? alert.alert_name : alert.alert_name_hebrew}} ({{alert.alert_data.length}})
                          <div class="frame-arrow">
                            <img src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                          </div>
                        </h5>
                      </a>
                    </div>
                    @if (alert) {
                      <div [ngClass]="{'more':alert.alert_data.length > 1,'less':alert.alert_data.length == 1}" id="collapseOne{{i}}" class="collapse" role="tabpanel">
                        <cdk-virtual-scroll-viewport itemSize="45" style="height:100%">
                          <ul class="card-body alertBody" *cdkVirtualFor="let alertData of alert.alert_data">
                            <li>
                              <div class="dflex">
                                <span class="bodyTxt idTxt">{{alertData.alert_id}} | </span>
                                <span class="bodyTxt noneFlex">@if (usageType == 1) {
                                  <a class="aColor" [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'dashboard',binId:alertData.bin_id}"> &nbsp;{{ alertData.bin_name }} &nbsp;</a>
                                }</span>
                                <span class="bodyTxt noneFlex">@if (usageType == 2) {
                                  <a class="aColor" [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'dashboard',binId:alertData.bin_id}"> &nbsp;{{ alertData.bin_description }} &nbsp;</a>
                                }</span>
                                <span> - &nbsp;</span>
                                <span class="bodyTxt">{{currentLang != 'iw' ? alertData.alert_body : alertData.alert_body_hebrew}}</span>
                              </div>
                              @if (currentLang == 'iw') {
                                <div class="dateTxt">{{validateDateIw(alertData.alert_time)}}</div>
                              }
                              @if (currentLang != 'iw') {
                                <div class="dateTxt">{{validateDateEn(alertData.alert_time)}}</div>
                              }
                            </li>
                          </ul>
                        </cdk-virtual-scroll-viewport>
                      </div>
                    }
                  </div>
                }
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
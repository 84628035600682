import { Component } from '@angular/core';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslatorService } from '../../../services/translator_service';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-disclosure-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,CommonModule],
  templateUrl: './disclosure-modal.component.html',
  styleUrl: './disclosure-modal.component.scss'
})
export class DisclosureModalComponent {
  isMobile: boolean;
  currentLang;
  translationsObj;

  constructor(private responsiveService: ResponsiveService,private translator: TranslatorService,
    private dialogRef: MatDialogRef<DisclosureModalComponent>
  ) {
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$.subscribe(async (value) => {
      this.translationsObj = await this.translator.getTranslation(value).toPromise();
      this.currentLang = value;
    });
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  onAgree(){
    let data = {
      status: 1,
    }
    this.dialogRef.close(data);
  }

  onClose(){
    let data = {
      status: 0,
    }
    this.dialogRef.close(data);
  }
}

import { Component, Inject } from '@angular/core';
import { TranslatorService } from '../../../services/translator_service';
import { ResponsiveService } from '../../../services/responsive.service';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import {MatMenuModule} from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';

@Component({
  selector: 'app-languages-menu',
  standalone: true,
  imports: [MatMenuModule,CommonModule,MatNativeDateModule,TranslateModule,RTLDivDirectiveDirective],
  templateUrl: './languages-menu.component.html',
  styleUrl: './languages-menu.component.scss'
})
export class LanguagesMenuComponent {

  translate:any;
	currnetLang:any;
	translationsObj:any;
  selectedLanguage: string = 'en';
	isMobile: boolean = false;

  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string) {
    this.translate = this.translator;
    this.translator.currentLangEmitter$
    .subscribe(async value=>{			   
      if(!value && !localStorage.getItem('language')){
        value = 'en';
      }     
      if(!localStorage.getItem('language') && value){
        localStorage.setItem('language', value.toString());
      }
      this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
      this.currnetLang = value;
      this.selectedLanguage = this.translator.selectedLang();			
    })
   }

  ngOnInit() {
    this.onResize();
		this.responsiveService.checkWidth();
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  changeLanguage(lang:any) {    
    this._locale = lang == 'iw' ? 'he' : lang;
    this._adapter.setLocale(this._locale);
		if(lang == this.currnetLang){
			return;
		}
		this.translator.init(lang);
		localStorage.setItem('language', lang);
		this.currnetLang = this.translator.getTranslationLanguage();
		this.selectedLanguage = this.translator.selectedLang();
    document.location.reload()
	}


}

@if(!isMobile){
  <div>
    <span class="lang-dropdown" rtlDiv mat-icon-button [matMenuTriggerFor]="appMenu">
      <span class="lang-txt" rtlDiv>{{selectedLanguage}}</span>
      <img class="arrowDown" rtlDiv src="assets/images/topmenu/arrow-down.svg" alt="" />
    </span>
    <mat-menu #appMenu="matMenu">
      <button class="outLine" [ngClass]="{'selected': currnetLang=='en'}" (click)="changeLanguage('en')" mat-menu-item>English</button>
      <button class="outLine" [ngClass]="{'selected': currnetLang=='iw'}" (click)="changeLanguage('iw')" mat-menu-item>עברית</button>
      <button class="outLine" [ngClass]="{'selected': currnetLang=='pl'}" (click)="changeLanguage('pl')" mat-menu-item>Polski</button>
    </mat-menu>
  </div>
}

@if(isMobile){
  <div class="mobile-lang" rtlDiv>
    <span class="mobile-lang" mat-icon-button [matMenuTriggerFor]="appMenu">
      <span class="mobile-language">{{selectedLanguage}}
        <span class="mobile-arrow">
          <img class="mobile-arrow-img" rtlDiv src="assets/images/topmenu/arrow-down.svg" alt="" />
        </span>
      </span>
    </span>
    <mat-menu #appMenu="matMenu">
      <div class="mat-menu-languages">
        <button class="mobile-justify" [ngClass]="{'selected': currnetLang=='en'}" (click)="changeLanguage('en')" mat-menu-item>English</button>
        <button class="mobile-justify" [ngClass]="{'selected': currnetLang=='iw'}" (click)="changeLanguage('iw')" mat-menu-item>עברית</button>
        <button class="mobile-justify" [ngClass]="{'selected': currnetLang=='pl'}" (click)="changeLanguage('pl')" mat-menu-item>Polski</button>
      </div>
    </mat-menu>
  </div>
}
  

  
  
  
  
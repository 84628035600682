@if (!isMobile) {
  <div class="collection-report">
    <mat-card>
      <div class='spinner-container' [hidden]="!spinnerActive">
        <div class="spinner"></div>
      </div>
      <mat-card-content [hidden]="spinnerActive">
        <div style="margin: 0 auto;height: 350px;" [chart]="donutChart"></div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div>
    <div class="accordion md-accordion" role="tablist" aria-multiselectable="true">
      <div class="card mobile-card">
        <div class="collapse show" role="tabpanel">
          <div class="card-body">
            <div [hidden]="!spinnerActive" class="spinner-border mobile-spinner" role="status">
              <span class="sr-only"></span>
            </div>
            <div [hidden]="spinnerActive" style="margin: 0 auto;height: 350px;" [chart]="donutChart"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

import { Component } from '@angular/core';
import { TranslatorService } from '../../../../services/translator_service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RTLDivDirectiveDirective } from '../../../../directives/rtldiv-directive.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-cancel-installation-dialog',
  standalone: true,
  imports: [TranslateModule,FormsModule, RTLDivDirectiveDirective,MatDialogModule,ReactiveFormsModule,
    CommonModule],
  templateUrl: './cancel-installation-dialog.component.html',
  styleUrl: './cancel-installation-dialog.component.scss'
})
export class CancelInstallationDialogComponent {
  currentLang: string;
  translateSub;
  translationsObj;

  constructor(private translator: TranslatorService) { 
    this.currentLang = this.translator.getTranslationLanguage();      
    this.translateSub = this.translator.getTranslation(this.currentLang).subscribe(translations => {
      this.translationsObj = translations;
    });
  }

  ngOnInit() {
  }
}

import { Component, Input, ViewChild } from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import _ from 'lodash';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { Papa } from 'ngx-papaparse';
import { TableUtil } from '../../../tableUtil';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginationComponent } from '../../pagination/pagination.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-driver-report-list',
  standalone: true,
  imports: [MatCardModule,TranslateModule,RTLDivDirectiveDirective,MatDividerModule,MatTableModule,MatSortModule,CommonModule,
    PaginationComponent,FormsModule,RouterModule],
  templateUrl: './driver-report-list.component.html',
  styleUrl: './driver-report-list.component.scss'
})
export class DriverReportListComponent {
  translate;
  translationsObj;
  currentLang;
  @Input() datesDiff;
  spinnerActive: boolean = true;
  allExceptions: Array<object> = [];
  exceptionsData;
  dataTableLength = 0;
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  startIndexTableData = 0;
  endIndexTableData = 6;
  tempFilter = "";
  datesDiffTxt: string;
  displayedColumns = ["binName", "siteName", "address", "time", "name"];
  filteredData: Array<object> = [];

  constructor(private translator: TranslatorService,private apiQuery: ApiQuery,private papa: Papa) {
    this.translate = this.translator;
    this.translator.currentLangEmitter$.subscribe(async (value) => {
      this.translationsObj = await this.translator.getTranslation(value).toPromise();
      this.currentLang = value;
      if (this.currentLang != "iw") {
        this.datesDiffTxt = `Drivers Report List`;
      } else {
        this.datesDiffTxt = `רשימת דיווחי נהגים`;
      }
    });
  }

  ngOnInit() {
    this.spinnerActive = true;
    this.apiQuery.driverExceptionsReports$.subscribe((data: any) => {
      if (this.currentLang != "iw") {
        this.datesDiffTxt = `Drivers Report List`;
      } else {
        this.datesDiffTxt = `רשימת דיווחי נהגים`;
      }
      this.datesDiffTxt += " " + this.datesDiff;
      this.spinnerActive = true;
      if (data == null || data.length == 0) {
        return;
      }
      this.spinnerActive = false;
      this.allExceptions = [];
      _.each(data, (item) => {
        if (item.exception_id == 9 || item.exception_id == 11 || item.exception_id == 12) {
          this.allExceptions.push(item);
        }
      });

      const binsFiltered = _.map(this.allExceptions, (b) => {
        return {
          bin_name: b["bin_name"],
          site_name: b["site_name"],
          bin_address: b["bin_address"],
          event_time: b["exception_time"],
          event_name: b["exception_id_name"].replace(/_/g, " "),
          bin_id: b["bin_id"],
          event_name_he: b["exception_id_name_hebrew"]
        };
      });

      this.exceptionsData = binsFiltered;
      this.dataTableLength = this.exceptionsData.length;
      this.dataSource = new MatTableDataSource<any>(binsFiltered);
      this.dataSource.sort = this.sort;
      this.sortData({ direction: "desc", active: "bin_name" });
    });
  }

  sortData(sort: Sort) {
    const data = this.exceptionsData.slice();
    if (!sort.active || sort.direction === "") {
      this.exceptionsData = data;
      return;
    }
    this.exceptionsData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onPageChange = (dataTableIndexes: any) => {
    this.startIndexTableData = dataTableIndexes.startIndex;
    this.endIndexTableData = dataTableIndexes.endIndex;
  }

  getTableData = () => {
    const tableData = [];
    const tempSearch = new MatTableDataSource<any>(this.exceptionsData);
    tempSearch.filter = this.tempFilter;
	this.filteredData = tempSearch.filteredData;
    for (let index = this.startIndexTableData;index <= this.endIndexTableData;index++) {
      if (tempSearch.filteredData[index]) {
        tableData.push(tempSearch.filteredData[index]);
      }
    }
    this.dataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);
    return this.dataSource;
  }

  applyFilter = (filterValue: any) => {
    filterValue = filterValue.value;
	filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.tempFilter = filterValue;
    this.dataSource.filter = filterValue;
  }

  exportToCsv() {
    let dataToExport = null;
    dataToExport = _.cloneDeep(this.exceptionsData);

    _.each(dataToExport, (item) => {
      item.status += "%";
      if(this.currentLang == 'iw'){
				item.event_name = item.event_name_he;
			}
    });

    const header = {
      "0": this.translationsObj.SITE_MANAGMENT.BIN_NAME,
      "1": this.translationsObj.TRUCK_MAP.SITE,
      "2": this.translationsObj.LIST_COLUMNS.ADDRESS,
      "3": this.translationsObj.LIST_COLUMNS.EVENT_TIME,
      "4": this.translationsObj.LIST_COLUMNS.EVENT_NAME,
    };

    const flatData = dataToExport.reduce((b, a) => {
      let res = _.slice(_.values(a), 0, Object.keys(header).length);
      b.push(res);
      return b;
    }, []);

    let source = {
      flatData,
      header,
    };

    let result = source.flatData.map((details) =>
      Object.keys(details).reduce(
        (a, b) => ((a[source.header[b]] = details[b]), a),
        {}
      )
    );
    if (this.currentLang != "iw") {
      TableUtil.exportArrayToExcel(result, "Drivers Report");
    } else {
      TableUtil.exportArrayToExcel(result, "דיווחי נהגים");
    }
  }
}

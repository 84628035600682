<div class="collection-report">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title class="title">{{datesDiffTxt}}</mat-card-title>
    </mat-card-header>
    <mat-divider class="top-divider"></mat-divider>
    <div class='spinner-container' [hidden]="!spinnerActive">
      <div class="spinner"></div>
    </div>
    <mat-card-content [hidden]="spinnerActive">
      <div class="pie-data" [chart]="donutChart"></div>
      <div class="data-txt" rtlDiv>
        <div class="alert-info-frame-total" rtlDiv>
          <span class="alert-number-total" rtlDiv>{{total}}</span>
          <span class="alert-name-total" rtlDiv>{{ 'COLLECTION_REPORT.TOTAL_EVENTS' | translate }}</span>
        </div>
        @for (item of allExceptions; track item) {
          <div rtlDiv>
            <div class="alert-info-frame" rtlDiv>
              <span class="alert-number" rtlDiv>{{item.exception_num_of_bins}}</span>
              @if (currentLang != 'iw') {
                <span class="alert-name" rtlDiv>{{item.exception_name}}</span>
              }
              @if (currentLang == 'iw') {
                <span class="alert-name" rtlDiv>{{item.exception_name_hebrew}}</span>
              }
            </div>
          </div>
        }
      </div>
    </mat-card-content>
  </mat-card>
</div>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslatorService } from '../../services/translator_service';
import { ResponsiveService } from '../../services/responsive.service';
import { ApiQuery } from '../../web-services/api/api.query';
import _ from 'lodash';
import { MatCardModule } from '@angular/material/card';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-collection-kpi',
  standalone: true,
  imports: [TranslateModule,MatCardModule,RTLDivDirectiveDirective,MatDividerModule,CommonModule],
  templateUrl: './collection-kpi.component.html',
  styleUrl: './collection-kpi.component.scss'
})
export class CollectionKpiComponent {
  isMobile: boolean = false;
  average : any = 0;
  activeBins:number = 0;
  efficiency : number = 0;
  levelOfService:number = 0;
  totalBins:number = 0;
  translate;
  translationsObj:any;
  spinnerActive:boolean = true;
	@Output() showCMP = new EventEmitter<boolean>(true);
  @Input() showKPIBtn : boolean = true;
  @Input() datesDiff : any;
	currentLang: any;
  constructor(private translator: TranslatorService,
		private responsiveService: ResponsiveService,
		private apiQuery:ApiQuery) {
      this.translate = this.translator;
			this.translator.currentLangEmitter$
			.subscribe(async value=>{						
				this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
				this.currentLang = value;
			})
	}


  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.spinnerActive = true;
		this.apiQuery.collectionsKpis$.subscribe((data:any) => {   
      this.spinnerActive = true;		     
      if(data.length == 0) return;
      this.spinnerActive = false;
      let avg = 0;
      let activeBins = 0;
      let efficiency = 0;
      let levelOfService = 0;
      let totalBins = 0;
      if (!_.isEmpty(data[0])){
        _.each(data, (item) => {
          avg += item.site_avg_collection_capacity_in_percent;
          activeBins += item.site_collections_num_of_bins;
          efficiency += item.site_efficiency_level_in_percent;
          levelOfService += item.site_service_level_in_percent;
          totalBins += item.site_total_num_of_bins;
        });
        this.average = data.length == 0 ? 0 : avg/data.length;      
        this.activeBins = activeBins;
        this.totalBins = totalBins;
        this.efficiency = data.length == 0 ? 0 : efficiency/data.length;
        this.levelOfService = data.length == 0 ? 0 : levelOfService/data.length;
      }else{
        this.average = 0;    
        this.activeBins = 0;
        this.totalBins = 0;  
      }

		}); 
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
  }
  
  closeCMP(){
		this.showCMP.emit(false);
	}
}

import { Component, EventEmitter, Injectable, Output } from '@angular/core';
import { TranslatorService } from '../../services/translator_service';
import { NgbCalendar, NgbDateStruct, NgbDatepickerI18n, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { TranslationWidth } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const I18N_VALUES = {
  'iw': {
    weekdays: ['שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת', 'ראשון'],
    months: ['ינו', 'פבר', 'מרץ', 'אפר', 'מאי', 'יונ', 'יול', 'אוג', 'ספט', 'אוק', 'נוב', 'דצמ'],
  },
  'en': {
	weekdays: ['Mon', 'The', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
  'pl': {
	weekdays: ['Mon', 'The', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  }
};
@Injectable()
export class I18n {
  language = 'iw';

  constructor (private translator: TranslatorService) {
	  if (this.translator.getTranslationLanguage() !== 'iw') {
		  this.language = 'en';
	  } 
  }
}

@Component({
  selector: 'app-datepicker-custom',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, NgbModule, NgbDatepickerModule, FormsModule, ReactiveFormsModule],
  templateUrl: './datepicker-custom.component.html',
  styleUrl: './datepicker-custom.component.scss',
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: DatepickerCustomComponent}]
})
export class DatepickerCustomComponent extends NgbDatepickerI18n {
	override getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
		return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
	}
	getMonthShortName(month: number): string {
		return I18N_VALUES[this._i18n.language].months[month - 1];
	}
	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}
	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
	@Output() selectDates: EventEmitter<any> = new EventEmitter<any>();

	hoveredDate: NgbDateStruct;

	date: any;
	disabled = true;
	firstDayOfWeek = 1;
	currentLang : string;
	rtlStr = 'ltr';
	minDay:any = 0;
	minMonth:any = 0;
	minYear:any = 0;

  constructor(private calendar: NgbCalendar, private _i18n: I18n, private translator: TranslatorService) {
		super()
		this.currentLang = this.translator.getTranslationLanguage();
		this.date = calendar.getToday();
	}

	private selectDatesEmitter = () => {		
		const selectedDates = {
			date: this.date ? `${this.date.day}/${this.date.month}/${this.date.year}` : '',
			dateformat : this.date ? `${this.date.year}-${this.date.month}-${this.date.day}` : '',
		};		
		this.selectDates.emit(selectedDates);
	}

	ngOnInit() {
		if (this.currentLang === 'iw') {
			this.firstDayOfWeek = 7;
			this.rtlStr = 'rtl';
		}
		this.selectDatesEmitter();
		this.minDay = new Date(moment().format("YYYY-MM-DD 00:00:00")).getDate();
		this.minMonth = new Date(moment().format("YYYY-MM-DD 00:00:00")).getMonth() + 1;
		this.minYear = new Date(moment().format("YYYY-MM-DD 00:00:00")).getFullYear();
	}

	onDateSelection(date: NgbDateStruct) {
		this.date = date;
		this.selectDatesEmitter();
	}


	selectToday() {
		this.date = this.calendar.getToday();
		this.selectDatesEmitter();
	  }
}

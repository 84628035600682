import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { ApiQuery } from '../../web-services/api/api.query';
import { TranslatorService } from '../../services/translator_service';
import { ResponsiveService } from '../../services/responsive.service';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { NumberWithDescriptionComponent } from '../../components/number-with-description/number-with-description.component';
import { MatDividerModule } from '@angular/material/divider';
@Component({
  selector: 'app-active-bins',
  standalone: true,
  imports: [MatCardModule,TranslateModule,RTLDivDirectiveDirective,NumberWithDescriptionComponent,MatDividerModule],
  templateUrl: './active-bins.component.html',
  styleUrl: './active-bins.component.scss'
})
export class ActiveBinsComponent {
  @Input() orientation: boolean = false;
	@Output() showCMP = new EventEmitter<boolean>(true);
	nonActiveBins = 0;
	totalActive = 0;
	totalGreen = 0;
	totalYellow = 0;
	totalRed = 0;
	translate;
	isMobile: boolean = false;
	spinnerActive:boolean = true;
	usageType = 1;
	
	constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,
		private apiQuery:ApiQuery) {
		this.translate = this.translator.translate;
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.spinnerActive = true;
		this.apiQuery.user$.subscribe((user:any) => {
			if (!user) return;
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
		});
		this.apiQuery.filteredBasicBins$.subscribe(bins => {				
			this.spinnerActive = true;	
			if(bins.length == 0)return;			
			this.spinnerActive = false;	
			this.getBinsStats(bins);										
		});
	}
	
	closeCMP(){
		this.showCMP.emit(false);
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	getBinsStats = (bins: any) => {				
		this.totalActive = bins.length;
		this.nonActiveBins = _.filter(bins, b => b.bin_collection_mode == "1").length;
		this.totalGreen = _.filter(bins, b => b.Bin_live_Data.color_status_id === 0 && b.bin_collection_mode == "2").length;
		this.totalYellow = _.filter(bins, b => b.Bin_live_Data.color_status_id === 1 && b.bin_collection_mode == "2").length;
		this.totalRed = _.filter(bins, b => b.Bin_live_Data.color_status_id === 2 && b.bin_collection_mode == "2").length;
	}

}

import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ResponsiveService } from '../../../services/responsive.service';
import { ActivatedRoute } from '@angular/router';
import { DateAdapter, MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { UtilService } from '../../../services/util.service';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiStore } from '../../../web-services/api/api.store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, combineLatest, map, startWith, takeUntil, tap } from 'rxjs';
import _ from 'lodash';
import moment from 'moment';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatSelectModule } from '@angular/material/select';
import { Loader } from '@googlemaps/js-api-loader';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";

interface Coordinates {
  address: string;
  latitude: number;
  longitude: number;
}
@Component({
  selector: 'app-edit-ticket-modal',
  standalone: true,
  providers: [
    provideNativeDateAdapter(),
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyB0UcoR0JNhlJkETb9LkDYfnjoiTW77GTc',
        libraries: ['places']
      })
    }
  ],
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,MatAutocompleteModule,MatInputModule,MatDatepickerModule,MatRadioModule,MatNativeDateModule,
    GoogleMapsModule,MatSelectModule,NgxGpAutocompleteModule
  ],
  templateUrl: './edit-ticket-modal.component.html',
  styleUrl: './edit-ticket-modal.component.scss'
})
export class EditTicketModalComponent {
  currentLang;
	translationsObj;
  isMobile: Boolean;
	usageType = 1;
  ElForm: FormGroup;
  removeOrReplaceTypes:Array<object> = [];
  analysisTypes:Array<object> = [];
  ticketEventsCause:Array<object> = [];
  priority;
	sitesListFilter: any[] = [];
  binsListFilter: any[] = [];
  filteredItems: Observable<any[]>;
  filteredBins: Observable<any[]>;
  filteredNeighborhoods: Observable<any[]>;
  chosenSite;
  chosenBin;
  chosenNeighborhood;
  @ViewChild(MatAutocompleteTrigger,{static: false}) autoSite: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger,{static: false}) autoBin: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger,{static: false}) autobinsNeighborhood: MatAutocompleteTrigger;
  coordinates: Coordinates;
  neighborhoods : string[] = [];
  minDate;
  partialEdit : boolean = false;
  displayErrorMsg : boolean = false;
	routeParamsSub:any;
  chosenAddress: any;
  private geoCoder;
  address: string;
  latitude: number;
  longitude: number;
  zoom: number;
  ElLocationForm: FormGroup;
  map: any = null;
  statusColor: string;
  markerIcon: string = '../../../../assets/images/bins/all/greenNotOrdered.svg';
  greenMarkerIcon : string = '../../../../assets/images/bins/all/greenNotOrdered.svg';
  greenMarkerIcon2 : string = '../../../../assets/images/bins/all/greenNotOrdered2.svg';
	private readonly destroy$ = new Subject();
  mapOptionsPc: google.maps.MapOptions = {
    streetViewControl: false
  };
  center: google.maps.LatLngLiteral = {lat: 0, lng: 0};
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;

  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,private fb: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: any,
  private apiQuery:ApiQuery,private dialogRef: MatDialogRef<EditTicketModalComponent>,private modalService: NgbModal,private apiService:ApiService,private apiStore:ApiStore,
  public utilService: UtilService,private _adapter: DateAdapter<any>,private route: ActivatedRoute) {
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
    this.coordinates = {} as Coordinates;

    this.ElForm = this.fb.group({
      description: new FormControl(''),
      eventCause: new FormControl('', Validators.required),
      isWorkplanIncluded: new FormControl(false, Validators.required),
      eventCheckOnField: this.data.status != 1 && this.data.chosenTicket.ticket_event_type_id == 1 ? new FormControl('', Validators.required) : new FormControl(''),
      removeOrReplaceType: new FormControl(''),
      site:new FormControl('', Validators.required),
      binsNeighborhood:new FormControl(''),
      binAddress:new FormControl('', Validators.required),
      unitReplaced: this.data.status != 1 && this.data.chosenTicket.ticket_event_type_id == 1 ? new FormControl('', Validators.required) : new FormControl(''),
      unitInstalled: this.data.status != 1 && this.data.chosenTicket.ticket_event_type_id == 3 ? new FormControl('', Validators.required) : new FormControl(''),
      removedFromBin: this.data.status != 1 && this.data.chosenTicket.ticket_event_type_id == 4 ? new FormControl('', Validators.required) : new FormControl(''),
      removedFromSite: this.data.status != 1 && this.data.chosenTicket.ticket_event_type_id == 4 ? new FormControl('', Validators.required) : new FormControl(''),
      binName: new FormControl(''),
      resolvedDescription : new FormControl(''),
      arrival_date:new FormControl({value: '', disabled: true}),
      arrival_date_displayed:new FormControl({value: '', disabled: true}),
    });

    this.ElLocationForm = this.fb.group({
      lonLat: new FormControl('')
    });
   }

  ngOnInit() {
    this.onResize();
	  this.responsiveService.checkWidth();
    this.minDate = moment().utc()["_d"];

    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}		
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
      if ((user["user_role_id"] == 3) || (user["user_role_id"] == 4)) {
        this.partialEdit = true;
      }else{
        this.partialEdit = false;
      }
		});
    
    if(this.data.selectedTab == 0 && this.data.chosenTicket.ticket_event_cause_id == 3){
      this.ElForm.controls.isWorkplanIncluded.setValue(this.data.chosenTicket.includedInWorkplan == "2" ? true : false);
    }
    
    this.apiQuery.ticketEventsType$.subscribe((events) => {
      if(events.length == 0){return;}      
      this.setEventType(Number(this.data.chosenTicket.ticket_event_type_id));          
    });
    this.ElForm.controls.eventCause.disable();
    this.ElForm.controls.site.disable();
    if(this.data.status != 1){
      this.ElForm.controls.binName.disable();
      this.ElForm.controls.binAddress.disable();
      this.ElForm.controls.binsNeighborhood.disable();
      this.ElForm.controls.description.disable();
    }
    this.routeParamsSub = this.route
    .queryParams
    .subscribe(params => {
      let binId = params['binId'] || null;
      if(binId){
        this.ElForm.controls.binName.disable();
        this.ElForm.controls.binAddress.disable();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
	}

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  getClassForFormControl(){
    if(!this.ElForm.valid || !this.priority || this.displayErrorMsg){
      return 'not-apply-btn';
    }else{
      return 'apply-btn';
    }
  }

  unitReplacedChange(ev){
    if(ev.value == 1){
      this.ElForm.controls.removeOrReplaceType.setValidators(this.setRequired());
    }else{
      this.ElForm.controls.removeOrReplaceType.clearValidators();
      this.ElForm.controls.removeOrReplaceType.setValue('');
    }   
  }

  removedFromSiteChange(ev){
    if(ev.value == 1){
      this.ElForm.controls.removeOrReplaceType.setValidators(this.setRequired());
    }else{
      this.ElForm.controls.removeOrReplaceType.clearValidators();
      this.ElForm.controls.removeOrReplaceType.setValue('');
    }
  }
  
  setRequired() {
		return [Validators.required];
	}

  setEventType(type){        
    let eventType = type;
    if(this.data.status != 1){
      if(this.data.chosenTicket.ticket_event_type_id == 3){
        this.ElForm.controls.unitInstalled.setValue(Number(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"].length -1].is_installed));
      }else if(this.data.chosenTicket.ticket_event_type_id == 4){
        this.ElForm.controls.removedFromBin.setValue(Number(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"].length -1].is_removed_from_bin));
        this.ElForm.controls.removedFromSite.setValue(Number(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"].length -1].is_removed_from_site));  
      }else if(this.data.chosenTicket.ticket_event_type_id == 1){
        this.ElForm.controls.unitReplaced.setValue(Number(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"].length -1].is_replaced));
      }
      this.ElForm.controls.resolvedDescription.setValue(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"] ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"].length - 1].comment : '');
    }
    this.ElForm.controls.description.setValue(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"] ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"].length - 1].comment : '');
    this.choosePriority(this.data.chosenTicket.priority_id);    

    if(this.data.chosenTicket.expected_arrival_date == '0000-00-00 00:00:00'){
      if(this.data.chosenTicket.priority_id == 3){
        this.ElForm.controls.arrival_date.setValue(moment(moment().add(1,'weeks')).utc()["_d"]); 
      }else if(this.data.chosenTicket.priority_id == 1 || this.data.chosenTicket.priority_id == 2){
        this.ElForm.controls.arrival_date.setValue(moment(moment().add(2,'weeks')).utc()["_d"]);    
      }  
    }else{
      this.ElForm.controls.arrival_date.setValue(moment(moment(this.data.chosenTicket.expected_arrival_date)).utc()["_d"]); 
    }

    if(this._adapter["locale"] == 'he' || this._adapter["locale"] == 'iw'){
      this.ElForm.controls.arrival_date_displayed.setValue(moment(this.ElForm.getRawValue()["arrival_date"]).format('DD/MM/YYYY'));    
    }else{
      this.ElForm.controls.arrival_date_displayed.setValue(moment(this.ElForm.getRawValue()["arrival_date"]).format('MM/DD/YYYY'));    
    }  

    this.apiQuery.ticketEventsCause$.subscribe((cause) => {
      if(cause.length == 0){return;}
      const response = cause;
      const relevantResponse = _.filter(response, (res) => {      
        return res["ticket_event_type_id"] == eventType;
      });          
      this.ticketEventsCause = relevantResponse.length > 0 ? relevantResponse[0]["TicketEventCausesObj"] : [];    
      this.ElForm.controls.eventCause.setValue(Number(this.data.chosenTicket.ticket_event_cause_id));                        
    });
    this.apiQuery.removeOrReplaceTypes$.subscribe((types) => {
      if(types.length == 0){return;}      
      const response = types;       
      const relevantResponse = _.filter(response, (res) => {      
        return res["ticket_event_type_id"] == this.data.chosenTicket.ticket_event_type_id;
      });  
      this.removeOrReplaceTypes = relevantResponse.length > 0 ? relevantResponse[0]["TicketReplaceRemoveInfoObj"] : [];     
      if(this.data.status != 1){
        if((this.data.chosenTicket.ticket_event_type_id == 4 || this.data.chosenTicket.ticket_event_type_id == 1)){
          this.ElForm.controls.removeOrReplaceType.setValue(Number(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"].length -1].replace_cause_id));       
        }
      }
    });
    if(this.data.chosenTicket.ticket_event_type_id == 3){      
      this.ElForm.controls.binAddress.setValue(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"] ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][0].installed_bin_address : '');
    }  
    this.apiQuery.sitesListData$.subscribe((sitesList) => {		            			
      if(sitesList.length == 0) return;
      this.sitesListFilter = sitesList;	 
      this.sitesListFilter.sort(function(a, b){
        if(a.site_name < b.site_name) { return -1; }
        if(a.site_name > b.site_name) { return 1; }
        return 0;
      });      
      this.ElForm.controls.site.setValue(this.data.chosenTicket.site_name);
      this.optionSelected(this.data.chosenTicket.site_name);            
      this.filteredItems = this.ElForm.controls.site.valueChanges
      .pipe(
      tap((val:any) => {   
        if(val == ''){
          this.ElForm.controls.binAddress.setValue('');    
          this.ElForm.controls.binName.setValue('');   
          this.ElForm.controls.binName.disable();
        }    
      }),
      startWith(''),
      map(item => item ? this.filterItems(item) : this.sitesListFilter.slice())
      );  
    });

    this.apiQuery.analysisTypes$.subscribe((types) => {
      if(types.length == 0){return;}      
      this.analysisTypes = types;
      if(this.data.status != 1){
        if(this.data.chosenTicket.ticket_event_type_id == 1){
          this.ElForm.controls.eventCheckOnField.setValue(Number(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"].length -1].analysis_id));       
        }
      }
    });
  }

  addEvent(event: MatDatepickerInputEvent<Date>) {
    if(this._adapter["locale"] == 'he' || this._adapter["locale"] == 'iw'){
      this.ElForm.controls.arrival_date_displayed.setValue(moment(event.value).format('DD/MM/YYYY'));    
    }else{
      this.ElForm.controls.arrival_date_displayed.setValue(moment(event.value).format('MM/DD/YYYY'));    
    }  
  }

  filterItems(name: string) {      
    let results = this.sitesListFilter.filter(item =>
      item.site_name.toLowerCase().indexOf(name.toLowerCase()) === 0);      
    return results;
  }

  filterBins(name: string) {      
    let results = this.binsListFilter.filter(item =>
      item.bin_name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    return results;
  }

  filterBinsNeighborhoodList(name: string) {          
    let results = this.neighborhoods.filter(item =>
      item.toLowerCase().indexOf(name.toLowerCase()) === 0);
    return results;
  }

  optionSelected(option) {    
    const site = _.filter(this.sitesListFilter, (site) => {
			return site.site_name == option;
		});    
    this.chosenSite = site[0];       
    if(this.map){
      this.loadAPIWrapper(this.map);
    }
    this.apiQuery.filteredBins$.subscribe((bins) => {		   
      if(bins.length == 0) return;
      const binsAccordingToSite = _.filter(bins, (bin) => {
        return bin["site_id"] == this.chosenSite.site_id;
      });      
      this.binsListFilter = binsAccordingToSite;	

      const binExistingInBins = _.filter(this.binsListFilter, (bin) => {
        return bin["bin_id"] == this.data.chosenTicket.ticketInfo[0].bin_id;
      });
      
      if(binExistingInBins.length > 0){
        this.ElForm.controls.binName.setValue(this.data.chosenTicket.ticketInfo[0].bin_name);
        this.optionSelectedBinName(this.data.chosenTicket.ticketInfo[0].bin_name); 
      }    
      if(this.binsListFilter.length > 0){
        if(this.data.status == 1){
          this.ElForm.controls.binName.enable();   
        }
      }       
      this.filteredBins = this.ElForm.controls.binName.valueChanges
      .pipe(
        tap((val:any) => {     
          if(val == ''){
            this.ElForm.controls.binAddress.setValue(''); 
            this.displayErrorMsg = false;   
          }else{
            this.optionSelectedBinName(val);     
          }      
        }),
      startWith(''),
      map(item => item ? this.filterBins(item) : this.binsListFilter.slice())
      );  
    });

    let BinsNeighborhoodList = {
      'site_id': this.chosenSite.site_id
    }

    this.apiService.getBinsNeighborhoodList(BinsNeighborhoodList).subscribe((v:any) => {	
      let ngh = [];
      _.each(v.NBRHoodList, (site) => {
        _.each(site.site_NBRH_list, (neighborhood) => {
          ngh.push(neighborhood.nbrh_name);
        });
      });
      this.neighborhoods = ngh;          
      const BinsNeighborhoodList = _.filter(this.neighborhoods, (neighborhood) => {
        return neighborhood == this.data.chosenTicket.ticketInfo[0].installed_bin_neighborhood;
      });
      
      if(BinsNeighborhoodList.length > 0){
        this.ElForm.controls.binsNeighborhood.setValue(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status] ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].installed_bin_neighborhood : '');
        this.optionSelectedBinsNeighborhood(_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status] ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].installed_bin_neighborhood : '');  
      }    
      if(this.neighborhoods.length > 0){
        if(this.data.status == 1){
          this.ElForm.controls.binsNeighborhood.enable();   
        }
      }     

      this.filteredNeighborhoods = this.ElForm.controls.binsNeighborhood.valueChanges
      .pipe(
      startWith(''),
      map(item => item ? this.filterBinsNeighborhoodList(item) : this.neighborhoods.slice())
      );  
    });
  }

  optionSelectedBinsNeighborhood(option){  
    const allNeighborhood = _.filter(this.neighborhoods, (neighborhood) => {      
			return option.value ? neighborhood.trim() == option.value.trim() : neighborhood.trim() == option.trim();
		});    
    this.chosenNeighborhood = allNeighborhood[0] ? allNeighborhood[0].trim() : '';        
  }

  optionSelectedBinName(option){    
    const bin = _.filter(this.binsListFilter, (bin) => {      
			return bin.bin_name == option;
		});    
    this.chosenBin = bin[0];
    if(this.chosenBin){
      this.ElForm.controls.binAddress.setValue(this.chosenBin.Bin_location.bin_address);    
      this.validateTickets(); 
    }
  }

  validateTickets(){
    combineLatest(
			this.apiQuery.cRTicketsList$,
			this.apiQuery.insTicketsList$,
			this.apiQuery.rmTicketsList$,
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(([cRTicketsList,insTicketsList,rmTicketsList])=>{	
        let existingTickets = [];	
        if(cRTicketsList.length == 0 && insTicketsList.length == 0 && rmTicketsList.length == 0){return;}

        _.each(cRTicketsList, (item) => {					
					if(item["TicketsList"]){
						if(item["TicketsList"]["open"]!=null){			
							_.each(item["TicketsList"]["open"], (ticket) => {	
                if(ticket.ticket_id != this.data.chosenTicket.ticket_id){
                  ticket.site_name = item["site_name"];
                  ticket.site_id = item["site_id"];
                  existingTickets.push(ticket);	
                } 	
							});		
						}	
					}		
				});

				_.each(insTicketsList, (item) => {					
					if(item["TicketsList"]){
						if(item["TicketsList"]["open"]!=null){			
							_.each(item["TicketsList"]["open"], (ticket) => {	
                if(ticket.ticket_id != this.data.chosenTicket.ticket_id){
                  ticket.site_name = item["site_name"];
                  ticket.site_id = item["site_id"];
                  existingTickets.push(ticket);	
                } 	
							});		
						}								
					}		
				});

				_.each(rmTicketsList, (item) => {					
					if(item["TicketsList"]){
						if(item["TicketsList"]["open"]!=null){			
							_.each(item["TicketsList"]["open"], (ticket) => {	
                if(ticket.ticket_id != this.data.chosenTicket.ticket_id){
                  ticket.site_name = item["site_name"];
                  ticket.site_id = item["site_id"];
                  existingTickets.push(ticket);	
                } 	
							});		
						}								
					}		
				});
  
        if(this.chosenBin && this.chosenSite && this.data.chosenTicket.ticket_event_cause_id && this.data.chosenTicket.ticket_event_type_id == 1){
          if (existingTickets.some((ticket) => {
            ticket.ticket_id_info.length > 0 &&
            ticket.ticket_id_info[ticket.ticket_id_info.length - 1].bin_name == this.chosenBin.bin_name
            && ticket.site_id == this.chosenSite.site_id 
            && ticket.ticketData.ticket_event_cause_id == this.data.chosenTicket.ticket_event_cause_id && 
            ticket.ticketData.ticket_event_type_id == this.data.chosenTicket.ticket_event_type_id
          })){
              this.displayErrorMsg = true;
          }else{
            this.displayErrorMsg = false;
          }
        }else if(this.chosenBin && this.chosenSite && this.data.chosenTicket.ticket_event_type_id == 4){
          if (existingTickets.some(ticket => ticket.ticket_id_info[ticket.ticket_id_info.length - 1].bin_name == this.chosenBin.bin_name
            && ticket.site_id == this.chosenSite.site_id && ticket.ticketData.ticket_event_type_id == this.data.chosenTicket.ticket_event_type_id)){
              this.displayErrorMsg = true;
          }else{
            this.displayErrorMsg = false;
          }
        }
      });
  }
   
  closeModal(){
    let data = {
      status: 0,
    }
    this.dialogRef.close(data);
  }

  handleAddressChange(place: google.maps.places.PlaceResult) {
    this.chosenAddress = place;
    this.changeLoc();
  }

  changeLoc(){
    if(this.ElLocationForm.getRawValue()['lonLat'].length == 0){
      return;
    }
    const lonLat = this.ElLocationForm.getRawValue()['lonLat'].split(",");
    if(isNaN(lonLat[0]) || isNaN(lonLat[1])){
      if(this.chosenAddress.geometry){
        this.latitude = Number(this.chosenAddress.geometry.location.lat().toFixed(8));
        this.longitude = Number(this.chosenAddress.geometry.location.lng().toFixed(8));
      }
    }else{
      this.latitude = Number(lonLat[0]);
      this.longitude = Number(lonLat[1]);
    }
    this.getAddress(this.latitude, this.longitude);
  }

  markerDragEnd($event: any) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    const lonLat = this.latitude.toFixed(8) + "," + this.longitude.toFixed(8);
    this.ElLocationForm.controls["lonLat"].setValue(lonLat);   
    this.getAddress(this.latitude, this.longitude); 
  }

  getAddress(latitude, longitude) {
    this.geoCoder = new google.maps.Geocoder;
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        }
      }
    });
    this.center = {lat: latitude, lng: longitude};
  }

  loadAPIWrapper(map: any) {
    this.map = map;
    if(!document.getElementById('center-managebin')){
      this.addYourLocationButton(this.map);
    }
    const lon = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"].length - 1].installed_lon;
    const lat = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"].length - 1].installed_lat;
    this.setCurrentLocation(lon,lat);
    this.statusColor = "GREEN"
    this.usageType == 1 ? this.markerIcon = this.greenMarkerIcon : this.markerIcon = this.greenMarkerIcon2;
  }

  setCurrentLocation(lon,lat) {
    this.latitude = Number(lat);
    this.longitude = Number(lon);
    const lonLat = Number(this.latitude).toFixed(8) + "," + Number(this.longitude).toFixed(8);
    this.ElLocationForm.controls["lonLat"].setValue(lonLat);    
    this.zoom = 8;
    this.getAddress(this.latitude, this.longitude);
  }

  addYourLocationButton(map) {
		let controlDiv = document.createElement('div');
		let firstChild = document.createElement('button');
		firstChild.type = 'button';
		firstChild.style.backgroundColor = '#fff';
		firstChild.style.border = 'none';
		firstChild.style.outline = 'none';
		firstChild.style.width = '40px';
		firstChild.style.height = '40px';
		firstChild.style.borderRadius = '2px';
		firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
		firstChild.style.cursor = 'pointer';
		firstChild.style.marginRight = '10px';
		firstChild.style.padding = '0px';
		firstChild.title = 'Your Location';
		controlDiv.appendChild(firstChild);
		
		let secondChild = document.createElement('div');
		secondChild.style.margin = 'calc(50% - 18px / 2)';
		secondChild.style.width = '18px';
		secondChild.style.height = '18px';
		secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
		secondChild.style.backgroundSize = '180px 18px';
		secondChild.style.backgroundPosition = '0px 0px';
		secondChild.style.backgroundRepeat = 'no-repeat';
		secondChild.id = 'center-managebin';
		firstChild.appendChild(secondChild);
		
		google.maps.event.addListener(map, 'dragend', function() {
			document.getElementById('center-managebin').style.backgroundPosition = '0px 0px';
		});

		firstChild.addEventListener('click', function() {
			var imgX = '0';
			var animationInterval = setInterval(function(){
				if(imgX == '-18') imgX = '0';
				else imgX = '-18';
				document.getElementById('center-managebin').style.backgroundPosition = imgX+'px 0px';
			}, 500);
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition( pos => {        
				map.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude});
        map.setZoom(15);
				clearInterval(animationInterval);
				document.getElementById('center-managebin').style.backgroundPosition = '-144px 0px';
				}, error => {
				},{maximumAge:10000, timeout:5000, enableHighAccuracy: true});
			}else{
				clearInterval(animationInterval);
				document.getElementById('center-managebin').style.backgroundPosition = '0px 0px';
			}
		});
		map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
	}

  choosePriority(priority){
    if(this.priority && this.data.status != 1){
      return;
    }
    this.priority = priority;
  }

  editTicket(){   
    if(!this.ElForm.valid || !this.priority || this.displayErrorMsg){
      return;
    }
    if(this.data.status == 1){
      const request = {   
        "event_cause_id":this.ElForm.getRawValue()["eventCause"],
        "event_type_id":this.data.selectedTab == 0 ? 1 : this.data.selectedTab == 1 ? 3 : 4,
        "priority_id":this.priority,
        "ticket_id":this.data.chosenTicket.ticket_id,
        "comment":this.ElForm.getRawValue()["description"],
        "status_id":1,
        "site_id":this.chosenSite.site_id,
        "expected_arrival_date": moment(this.ElForm.getRawValue()["arrival_date"]).format('YYYY-MM-DD HH:mm:ss')
      }
  
      if(this.data.selectedTab == 0){
        request["bin_id"] = this.chosenBin.bin_id;
        request["analysis_id"] = 0;
        request["ticket_remove_replace_cause_id"] = 0;
        request["is_replaced"] = 0;
        if(this.ElForm.getRawValue()["eventCause"] == 5){
          request["is_remove_from_work_plans"] = 0;
        }else if(this.ElForm.getRawValue()["isWorkplanIncluded"]){
          request["is_remove_from_work_plans"] = 0;
        }else{
          request["is_remove_from_work_plans"] = 1;
        }
      }else if(this.data.selectedTab == 1){
        request["is_installed"] = 0;
        request["bin_install_address"] = this.ElForm.getRawValue()["binAddress"];
        request["lat"] = this.latitude;
        request["lon"] = this.longitude;
        request["installation_type_id"] = 0;
        request["installed_bin_neighborhood"] = this.chosenNeighborhood ? this.chosenNeighborhood : '';
      }else if(this.data.selectedTab == 2){
        request["bin_id"] = this.chosenBin.bin_id;
        request["is_removed_from_bin"] = 0;
        request["is_removed_from_site"] = 0;
        request["ticket_remove_replace_cause_id"] = 0;
      }
      this.apiService.insertNewTicket(request).subscribe((v:any) => {         
        let ticketManagementRequest = {};   
        if(sessionStorage.getItem("ticketManagementMinDate") == '' || sessionStorage.getItem("ticketManagementMaxDate") == '' || 
        sessionStorage.getItem("ticketManagementMinDate") == null || sessionStorage.getItem("ticketManagementMaxDate") == null){
          ticketManagementRequest = {
            status_id : -1
            }
        }else{
          ticketManagementRequest = {
            start_time : sessionStorage.getItem("ticketManagementMinDate"),
            end_time : sessionStorage.getItem("ticketManagementMaxDate")
            }
        }				
    
        this.apiStore.update({ allCRTicketsList: [] });
        this.apiStore.update({ cRTicketsList: [] });
        this.apiService.getCRTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
    
        this.apiStore.update({ allInsTicketsList: [] });
        this.apiStore.update({ insTicketsList: [] });
        this.apiService.getInsTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
    
        this.apiStore.update({ allRmTicketsList: [] });
        this.apiStore.update({ rmTicketsList: [] });
        this.apiService.getRmTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

        this.apiStore.update({ allFilteredBins: [] });                    
        this.apiStore.update({ filteredBins: [] });
        this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));

        this.apiStore.update({ allFilteredBasicBins: [] });                    
        this.apiStore.update({ filteredBasicBins: [] }); 
        this.apiService.getfilteredBasicBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));

        let data = {
          status: 1,
        }
        this.dialogRef.close(data);  
      });
    }else if(this.data.status == 2){      
      const request = {   
        "event_cause_id":this.ElForm.getRawValue()["eventCause"],
        "event_type_id":this.data.selectedTab == 0 ? 1 : this.data.selectedTab == 1 ? 3 : 4,
        "priority_id":this.priority,
        "ticket_id":this.data.chosenTicket.ticket_id,
        "comment":this.ElForm.getRawValue()["resolvedDescription"],
        "status_id":2,
        "site_id":this.chosenSite.site_id,
        "expected_arrival_date": moment(this.ElForm.getRawValue()["arrival_date"]).format('YYYY-MM-DD HH:mm:ss')
      }
      if(this.data.selectedTab == 0){
        request["bin_id"] = this.chosenBin.bin_id;
        request["analysis_id"] = this.ElForm.getRawValue()["eventCheckOnField"];
        request["is_replaced"] = this.ElForm.getRawValue()["unitReplaced"];
        if(this.ElForm.getRawValue()["eventCause"] == 5){
          request["is_remove_from_work_plans"] = 0;
        }else if(this.ElForm.getRawValue()["isWorkplanIncluded"]){
          request["is_remove_from_work_plans"] = 0;
        }else{
          request["is_remove_from_work_plans"] = 1;
        }
        request["ticket_remove_replace_cause_id"] = this.ElForm.getRawValue()["unitReplaced"] == 1 ? this.ElForm.getRawValue()["removeOrReplaceType"] : 0;
      }else if(this.data.selectedTab == 1){
        request["is_installed"] = this.ElForm.getRawValue()["unitInstalled"];
        request["bin_install_address"] = this.ElForm.getRawValue()["binAddress"];
        request["lat"] = this.latitude;
        request["lon"] = this.longitude;
        request["installation_type_id"] = 0;
        request["installed_bin_neighborhood"] = this.chosenNeighborhood ? this.chosenNeighborhood : '';
      }else if(this.data.selectedTab == 2){
        request["bin_id"] = this.chosenBin.bin_id;
        request["is_removed_from_bin"] = this.ElForm.getRawValue()["removedFromBin"];
        request["is_removed_from_site"] = this.ElForm.getRawValue()["removedFromSite"];
        request["ticket_remove_replace_cause_id"] = this.ElForm.getRawValue()["removedFromBin"] == 1 ? this.ElForm.getRawValue()["removeOrReplaceType"] : 0;
      }
      this.apiService.insertNewTicket(request).subscribe((v:any) => {         
        let ticketManagementRequest = {};   
        if(sessionStorage.getItem("ticketManagementMinDate") == '' || sessionStorage.getItem("ticketManagementMaxDate") == '' || 
        sessionStorage.getItem("ticketManagementMinDate") == null || sessionStorage.getItem("ticketManagementMaxDate") == null){
          ticketManagementRequest = {
            status_id : -1
            }
        }else{
          ticketManagementRequest = {
            start_time : sessionStorage.getItem("ticketManagementMinDate"),
            end_time : sessionStorage.getItem("ticketManagementMaxDate")
            }
        }				
    
        this.apiStore.update({ allCRTicketsList: [] });
        this.apiStore.update({ cRTicketsList: [] });
        this.apiService.getCRTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
    
        this.apiStore.update({ allInsTicketsList: [] });
        this.apiStore.update({ insTicketsList: [] });
        this.apiService.getInsTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));
    
        this.apiStore.update({ allRmTicketsList: [] });
        this.apiStore.update({ rmTicketsList: [] });
        this.apiService.getRmTicketsList(ticketManagementRequest,JSON.parse(sessionStorage.getItem("chosenSites")));

        this.apiStore.update({ allFilteredBins: [] });                    
        this.apiStore.update({ filteredBins: [] });
        this.apiService.getfilteredBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));

        this.apiStore.update({ allFilteredBasicBins: [] });                    
        this.apiStore.update({ filteredBasicBins: [] }); 
        this.apiService.getfilteredBasicBinsInfo(JSON.parse(sessionStorage.getItem("chosenSites")));
        
        let data = {
          status: 1,
        }
        this.dialogRef.close(data); 
      });
    }
  }

}


<div class="modal-header" rtlDiv>
    <h3 class="modal-title">{{ titleStr }}</h3>
    <button type="button" data-dismiss="modal" (click)="closeModal('dismiss')" class="close-btn">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
  <form [formGroup]="ElForm" class="top-fields">
      <div class="bin-name-container">
          <div class="dflex" rtlDiv>
              <div class="row-space" rtlDiv>
                  <input type="text" class="form-control time-input" formControlName="lon" placeholder="{{ 'LIST_COLUMNS.LON' | translate }}">
              </div>
              <div class="row-space" rtlDiv>
                  <input type="text" class="form-control time-input" formControlName="lat" placeholder="{{ 'LIST_COLUMNS.LAT' | translate }}">
              </div>
              <button class="row-space search_btn" (click)="changeLoc()" rtlDiv>                    
                  {{ 'MANAGE_BIN.CHANGE_LOCATION' | translate }} 
                  <!-- <img src="assets/images/sidemenu/table_search_icon.png" alt="" /> -->
              </button>
          </div>
      </div>
   </form>

   <google-map (mapInitialized)="loadAPIWrapper($event)" [center]="center" [zoom]="zoom" class="map-modal hmap">
      <map-marker [position]="{lat: latitude, lng: longitude}"
      (mapDragend)="markerDragEnd($event)" [options]="{ icon: { url: markerIcon } , draggable : true }"></map-marker>
  </google-map>
    <div>
      <h5>{{address}}</h5>
    </div>
</div>
<div class="modal-footer" rtlDiv>
    <button type="button" class='cancel-btn' data-dismiss="modal" (click)="closeModal('close')">{{ 'MANAGE_BIN.CANCEL' | translate }}</button>
    <button type="button" class='apply-btn' (click)="saveLocation()">{{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}</button>
</div>
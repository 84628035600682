@if (!isMobile) {
  <div class="add-cluster-pc">
    <div class="modal-title">
      <span>{{ 'LIST_COLUMNS.ADD_CLUSTER' | translate }} </span>
    </div>
    <mat-vertical-stepper class="manage-cluster-stepper" #stepper rtlDiv>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>{{'LIST_COLUMNS.BINS_IN_CLUSTER' | translate}}</ng-template>
          <div class='drop-down-main-wrapper add-remove-bins' rtlDiv>
            <div class="bin-name-container">
              <div class="sub-title-sites">{{ 'SITE_MANAGMENT.SITE_NAME' | translate }}</div>
              <select class="bin-name-input distrebution-Methods" formControlName="siteName" (change)="getSiteChosen()">
                @for (site of siteList; track site) {
                  <option [value]="site['site_id']">
                    {{site['site_name']}}
                  </option>
                }
              </select>
            </div>
            <div class="drop-down-list-container add-remove-bins bin-container-rtl" rtlDiv>
              <div class="change-bin-to-collect">
                <div class="search-bin-container">
                  <input class="search-input" placeholder="{{'OTHER.SEARCH' | translate }}"
                    (input)="applyFilter($event.target['value'])" formControlName="searchInput"/>
                  <img class='search-icon' src="assets/images/reports/dropdown_search_icon.svg" alt="" rtlDiv />
                </div>
                <div class="popup-content" rtlDiv>
                  <div class='bins-list-table activeBins' rtlDiv>
                    <div class="change-bin-table-frame">
                      <span class="change-bin-table-title">{{'WIDGETS.ALL_BINS' | translate}}</span>
                      <span class="total-bins">{{'TITLES.TOTAL_BINS' | translate}} - {{filteredBinsInCluster.length}}</span>
                    </div>
                    @if (spinnerActive) {
                      <div class='spinner-container'>
                        <div class="spinner"></div>
                      </div>
                    }
                    <div class="outside-table-border">
                      <cdk-virtual-scroll-viewport tvsItemSize="48" headerHeight="56" style="height: 100%;">
                        <table class="table" mat-table #table [dataSource]="getAllBinsTableData()" matSort matSortStart="asc" matSortActive="bin_name"
                          matSortDisableClear (matSortChange)="sortAddBinsData($event)" matSortDirection="desc">
                          <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef class="cut-change-bin-txt">
                            </th>
                            <td mat-cell *matCellDef="let row" class="list-checkbox cut-change-bin-txt" rtlDiv>
                              <mat-checkbox class="direction" (click)="$event.stopPropagation()" (change)="$event ? selectionAddBins.toggle(row) : null" [checked]="selectionAddBins.isSelected(row)">
                              </mat-checkbox>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="cut-change-bin-txt"> {{
                            'LIST_COLUMNS.NAME' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv> <span title="{{element ? element.bin_name : ''}}">{{element ? element.bin_name : ''}}</span></td>
                          </ng-container>
                          <ng-container matColumnDef="address">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="cut-change-bin-txt">{{ 'LIST_COLUMNS.ADDRESS'
                            | translate }}</th>
                            <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv><span title="{{element ? element.bin_address : ''}}">{{element ? element.bin_address: ''}}</span>  </td>
                          </ng-container>
                          <ng-container matColumnDef="neighborhood">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_neighborhood" class="cut-change-bin-txt" rtlDiv>
                            {{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</th>
                            <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv><span title="{{element ? element.bin_neighborhood : ''}}">{{element ? element.bin_neighborhood: ''}}</span>  </td>
                          </ng-container>
                          <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_status" class="cut-change-bin-txt">{{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                            <td mat-cell *matCellDef="let element" class="cut-change-bin-txt"> {{element ? element.bin_status: ''}}% </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectionAddBins.toggle(row)"></tr>
                        </table>
                      </cdk-virtual-scroll-viewport>
                    </div>
                  </div>
                  <div class="add-remove-arrows" rtlDiv>
                    @if (currentLang != 'iw') {
                      <div>
                        <img (click)="moveBinToInside()" class="right-arrow" rtlDiv src="../assets/images/workplans-page/right.svg" alt="right_icon">
                      </div>
                    }
                    @if (currentLang != 'iw') {
                      <div>
                        <img (click)="moveBinToOutside()" class="left-arrow" rtlDiv src="../assets/images/workplans-page/left.svg" alt="left_icon">
                      </div>
                    }
                    @if (currentLang == 'iw') {
                      <div>
                        <img (click)="moveBinToInside()" class="right-arrow" rtlDiv src="../assets/images/workplans-page/left.svg" alt="right_icon">
                      </div>
                    }
                    @if (currentLang == 'iw') {
                      <div>
                        <img (click)="moveBinToOutside()" class="left-arrow" rtlDiv src="../assets/images/workplans-page/right.svg" alt="left_icon">
                      </div>
                    }
                  </div>
                  <div class='bins-list-table removedBins' rtlDiv>
                    <div class="change-bin-table-frame">
                      <span class="change-bin-table-title active-lbl">{{'LIST_COLUMNS.BINS_IN_CLUSTER' | translate}}</span>
                      <span class="total-bins">{{'TITLES.TOTAL_BINS' | translate}} - {{filteredData.length}}</span>
                    </div>
                    @if (spinnerActive) {
                      <div class='spinner-container'>
                        <div class="spinner"></div>
                      </div>
                    }
                    <div class="inside-table-border">
                      <cdk-virtual-scroll-viewport tvsItemSize="48" headerHeight="56" style="height: 100%;">
                        <table class="table" mat-table #table [dataSource]="getTableData()" matSort matSortStart="asc"
                          matSortActive="bin_name" matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
                          <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef class="cut-change-bin-txt" rtlDiv>
                            </th>
                            <td class="cut-change-bin-txt list-checkbox" mat-cell *matCellDef="let row" rtlDiv>
                              <mat-checkbox class="direction" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)">
                              </mat-checkbox>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="cut-change-bin-txt" rtlDiv>{{
                            'LIST_COLUMNS.NAME' | translate }}</th>
                            <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv> <span title="{{element ? element.bin_name : ''}}">{{element ? element.bin_name : ''}}</span></td>
                          </ng-container>
                          <ng-container matColumnDef="address">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="cut-change-bin-txt" rtlDiv>{{ 'LIST_COLUMNS.ADDRESS'
                            | translate }}</th>
                            <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv><span title="{{element ? element.bin_address : ''}}">{{element ? element.bin_address: ''}}</span>  </td>
                          </ng-container>
                          <ng-container matColumnDef="neighborhood">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_neighborhood" class="cut-change-bin-txt" rtlDiv>
                            {{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</th>
                            <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv><span title="{{element ? element.bin_neighborhood : ''}}">{{element ? element.bin_neighborhood: ''}}</span>  </td>
                          </ng-container>
                          <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_status" class="cut-change-bin-txt" rtlDiv>{{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                            <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv> {{element ? element.bin_status: ''}}% </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
                        </table>
                      </cdk-virtual-scroll-viewport>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>{{'LIST_COLUMNS.CLUSTER_INFO' | translate}}</ng-template>
          <div class="cluster-info" rtlDiv>
            <div class="full-width" rtlDiv>
              <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.CLUSTER_NAME' | translate }}</div>
              <mat-form-field class="event-type-input event-types" rtlDiv>
                <input tabindex="-1" type="text" class="input-site" matInput formControlName="clusterName" rtlDiv>
              </mat-form-field>
            </div>
            <div class="full-width" rtlDiv>
              <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.CLUSTER_TYPE' | translate }}</div>
              <mat-form-field class="event-type-input event-types">
                <mat-select class="full-height" rtlDiv formControlName="clusterType" name="clusterType">
                  @for (type of types; track type) {
                    <mat-option [value]="type['cluster_type_id']" class="list-item">
                      <span class="flex-binInfo" rtlDiv>{{currentLang != 'iw' ? type['cluster_type_name'] : type['cluster_type_name_hebrew']}}</span>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="full-width" rtlDiv>
              <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.MIN_ALLOWED_CAPACITY' | translate }}</div>
              <mat-form-field class="event-type-input event-types" rtlDiv>
                <input tabindex="-1" type="number" class="input-site" matInput formControlName="minAllowedCap" rtlDiv>
              </mat-form-field>
            </div>
            <div class="full-width" rtlDiv>
              <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.MAX_ALLOWED_CAPACITY' | translate }}</div>
              <mat-form-field class="event-type-input event-types" rtlDiv>
                <input tabindex="-1" type="number" class="input-site" matInput formControlName="maxAllowedCap" rtlDiv>
              </mat-form-field>
            </div>
          </div>
        </form>
      </mat-step>
    </mat-vertical-stepper>
    <div [ngClass]="{'rtldir': currentLang != 'iw','ltrdir':currentLang == 'iw'}">
      <button [ngClass]="{'apply-btn' : secondFormGroup.valid && !submitClicked,'not-apply-btn' : !secondFormGroup.valid || submitClicked}" [disabled]="!secondFormGroup.valid && !submitClicked" (click)="applyChanges()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</button>
      <button class="cancel_btn" (click)="closeModal()">{{ 'TRUCK_MAP.CANCEL' | translate }}</button>
    </div>
  </div>
}

@if (isMobile) {
  <div class="mobile-add-cluster-modal">
    <div class="mobile-modal-title" rtlDiv>
      <div class="dflex" (click)="closeModal()">
        <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
      </div>
      <div>
        <span class="main-title">{{ 'LIST_COLUMNS.ADD_CLUSTER' | translate }}</span>
      </div>
    </div>
    <mat-vertical-stepper class="mobile-manage-cluster-stepper" #stepper rtlDiv>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>{{'LIST_COLUMNS.BINS_IN_CLUSTER' | translate}}</ng-template>
          <div class='drop-down-main-wrapper' rtlDiv>
            <div class="mobile-container">
              <div class="sub-title-sites">{{ 'SITE_MANAGMENT.SITE_NAME' | translate }}</div>
              <select class="mobile-select-option" formControlName="siteName" (change)="getSiteChosen()">
                @for (site of siteList; track site) {
                  <option [value]="site['site_id']">
                    {{site['site_name']}}
                  </option>
                }
              </select>
            </div>
            <div class="drop-down-list-container bin-container-rtl" rtlDiv>
              <div class="mobile-change-bin-to-collect">
                <div class="mobile-search-bin-container">
                  <img class='search-icon' src="assets/images/reports/dropdown_search_icon.svg" alt="" rtlDiv />
                  <input class="search-input" placeholder="{{'OTHER.SEARCH' | translate }}"
                    (input)="applyFilter($event.target['value'])" formControlName="searchInput"/>
                </div>
                @if (selectedTab == 0 && selectionAddBins.selected.length > 0) {
                  <div class="mobile-move-container">
                    <div class="move-from">{{'WORKPLAN.MOVE' | translate}} {{selectionAddBins.selected.length}} {{'SITE_MANAGMENT.BINS' | translate}} {{'WORKPLAN.TO' | translate}} '{{'LIST_COLUMNS.BINS_IN_CLUSTER' | translate}}'</div>
                    <div class="move-btn" (click)="moveBinToInside()">
                      <div>{{'WORKPLAN.MOVE' | translate}}</div>
                    </div>
                  </div>
                }
                @if (selectedTab == 1 && selection.selected.length > 0) {
                  <div class="mobile-move-container">
                    <div class="move-from">{{'WORKPLAN.MOVE' | translate}} {{selection.selected.length}} {{'SITE_MANAGMENT.BINS' | translate}} {{'WORKPLAN.TO' | translate}} '{{'WIDGETS.ALL_BINS' | translate}}'</div>
                    <div class="move-btn" (click)="moveBinToOutside()">
                      <div>{{'WORKPLAN.MOVE' | translate}}</div>
                    </div>
                  </div>
                }
                <mat-tab-group mat-stretch-tabs="false" rtlDiv animationDuration="0ms" (selectedIndexChange)="myTabSelectedIndexChange($event)">
                  <mat-tab label="{{'WIDGETS.ALL_BINS' | translate}} ({{filteredBinsInCluster.length}})">
                    <ng-template matTabContent>
                      <div class="spinner-border mobile-spinner" [hidden]="!spinnerActive">
                        <div class="spinner"></div>
                      </div>
                      <div class="card mobile-card" [hidden]="spinnerActive">
                        <div class="card-body">
                          <div class="mobile-outside-table-border">
                            <cdk-virtual-scroll-viewport tvsItemSize="48" headerHeight="56" style="height: 100%;">
                              <table class="mobile-table" mat-table #table [dataSource]="getAllBinsTableData()" matSort matSortStart="asc" matSortActive="bin_name"
                                matSortDisableClear multiTemplateDataRows (matSortChange)="sortAddBinsData($event)" matSortDirection="desc">
                                <ng-container matColumnDef="select">
                                  <th mat-header-cell *matHeaderCellDef class="mobile-th-title"></th>
                                  <td mat-cell *matCellDef="let row" class="mobile-td-title" rtlDiv>
                                    <mat-checkbox class="direction" (click)="$event.stopPropagation()" (change)="$event ? selectionAddBins.toggle(row) : null" [checked]="selectionAddBins.isSelected(row)">
                                    </mat-checkbox>
                                  </td>
                                </ng-container>
                                <ng-container matColumnDef="name">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="mobile-th-title"> {{'LIST_COLUMNS.NAME' | translate }} </th>
                                  <td mat-cell *matCellDef="let element" class="mobile-td-title" rtlDiv>
                                    <span class="mobile-td">{{element ? element.bin_name : ''}}</span>
                                  </td>
                                </ng-container>
                                <ng-container matColumnDef="address">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="mobile-th-title">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</th>
                                  <td mat-cell *matCellDef="let element" class="mobile-td-title" rtlDiv>
                                    <span class="mobile-td">{{element ? element.bin_address: ''}}</span>
                                  </td>
                                </ng-container>
                                <ng-container matColumnDef="arrowDown">
                                  <th class="mobile-th-title" mat-sort-header="arrowDown" style="height: 52px;" rtlDiv mat-header-cell *matHeaderCellDef></th>
                                  <td class="mobile-td-title" mat-cell *matCellDef="let element">
                                    <div rtlDiv class="mobile-status-value">
                                      @if (expandedElement !== element) {
                                        <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                                      }
                                      @if (expandedElement === element) {
                                        <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                                      }
                                    </div>
                                  </td>
                                </ng-container>
                                <ng-container matColumnDef="expandedDetail">
                                  <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" rtlDiv>
                                    <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                      <div class="mobile-element-diagram" rtlDiv>
                                        <div class="mobile-item-details">
                                          <div class="dFlex">
                                            <div class="mobile-details-title">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</div>
                                            <div class="mobile-details-value"> {{element ? element.bin_neighborhood : ''}} </div>
                                          </div>
                                          <div class="dFlex">
                                            <div class="mobile-details-title">{{ 'LIST_COLUMNS.STATUS' | translate }}</div>
                                            <div class="mobile-details-value"> {{element ? element.bin_status: ''}}% </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
                                <tr mat-row *matRowDef="let element; columns: displayedColumnsMobile;" (click)="expandedElement = expandedElement === element ? null : element"></tr>
                                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                              </table>
                            </cdk-virtual-scroll-viewport>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </mat-tab>
                  <mat-tab label="{{'LIST_COLUMNS.BINS_IN_CLUSTER' | translate}} ({{filteredData.length}})">
                    <ng-template matTabContent>
                      <div class="spinner-border mobile-spinner" [hidden]="!spinnerActive">
                        <div class="spinner"></div>
                      </div>
                      <div class="card mobile-card" [hidden]="spinnerActive">
                        <div class="card-body">
                          <div class="mobile-inside-table-border">
                            <cdk-virtual-scroll-viewport tvsItemSize="48" headerHeight="56" style="height: 100%;">
                              <table class="mobile-table" mat-table #table [dataSource]="getTableData()" matSort matSortStart="asc"
                                matSortActive="bin_name" matSortDisableClear multiTemplateDataRows (matSortChange)="sortData($event)" matSortDirection="desc">
                                <ng-container matColumnDef="select">
                                  <th mat-header-cell *matHeaderCellDef class="mobile-th-title"></th>
                                  <td mat-cell *matCellDef="let row" class="mobile-td-title" rtlDiv>
                                    <mat-checkbox class="direction" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                                    </mat-checkbox>
                                  </td>
                                </ng-container>
                                <ng-container matColumnDef="name">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="mobile-th-title"> {{'LIST_COLUMNS.NAME' | translate }} </th>
                                  <td mat-cell *matCellDef="let element" class="mobile-td-title" rtlDiv>
                                    <span class="mobile-td">{{element ? element.bin_name : ''}}</span>
                                  </td>
                                </ng-container>
                                <ng-container matColumnDef="address">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="mobile-th-title">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</th>
                                  <td mat-cell *matCellDef="let element" class="mobile-td-title" rtlDiv>
                                    <span class="mobile-td">{{element ? element.bin_address: ''}}</span>
                                  </td>
                                </ng-container>
                                <ng-container matColumnDef="arrowDown">
                                  <th class="mobile-th-title" mat-sort-header="arrowDown" style="height: 52px;" rtlDiv mat-header-cell *matHeaderCellDef></th>
                                  <td class="mobile-td-title" mat-cell *matCellDef="let element">
                                    <div rtlDiv class="mobile-status-value">
                                      @if (expandedElement !== element) {
                                        <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                                      }
                                      @if (expandedElement === element) {
                                        <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                                      }
                                    </div>
                                  </td>
                                </ng-container>
                                <ng-container matColumnDef="expandedDetail">
                                  <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" rtlDiv>
                                    <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                      <div class="mobile-element-diagram" rtlDiv>
                                        <div class="mobile-item-details">
                                          <div class="dFlex">
                                            <div class="mobile-details-title">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</div>
                                            <div class="mobile-details-value"> {{element ? element.bin_neighborhood : ''}} </div>
                                          </div>
                                          <div class="dFlex">
                                            <div class="mobile-details-title">{{ 'LIST_COLUMNS.STATUS' | translate }}</div>
                                            <div class="mobile-details-value"> {{element ? element.bin_status: ''}}% </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
                                <tr mat-row *matRowDef="let element; columns: displayedColumnsMobile;" (click)="expandedElement = expandedElement === element ? null : element"></tr>
                                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                              </table>
                            </cdk-virtual-scroll-viewport>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>{{'LIST_COLUMNS.CLUSTER_INFO' | translate}}</ng-template>
          <div class="mobile-cluster-info" rtlDiv>
            <div class="full-width" rtlDiv>
              <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.CLUSTER_NAME' | translate }}</div>
              <mat-form-field class="event-type-input event-types" rtlDiv>
                <input tabindex="-1" type="text" class="input-site" matInput formControlName="clusterName" rtlDiv>
              </mat-form-field>
            </div>
            <div class="full-width" rtlDiv>
              <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.CLUSTER_TYPE' | translate }}</div>
              <mat-form-field class="event-type-input event-types">
                <mat-select class="full-height" rtlDiv formControlName="clusterType" name="clusterType">
                  @for (type of types; track type) {
                    <mat-option [value]="type['cluster_type_id']">
                      <span class="flex-binInfo" rtlDiv>{{currentLang != 'iw' ? type['cluster_type_name'] : type['cluster_type_name_hebrew']}}</span>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex-gap">
              <div class="full-width" rtlDiv>
                <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.MIN_ALLOWED_CAPACITY' | translate }}</div>
                <mat-form-field class="event-type-input event-types" rtlDiv>
                  <input tabindex="-1" type="number" class="input-site" matInput formControlName="minAllowedCap" rtlDiv>
                </mat-form-field>
              </div>
              <div class="full-width" rtlDiv>
                <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.MAX_ALLOWED_CAPACITY' | translate }}</div>
                <mat-form-field class="event-type-input event-types" rtlDiv>
                  <input tabindex="-1" type="number" class="input-site" matInput formControlName="maxAllowedCap" rtlDiv>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </mat-step>
    </mat-vertical-stepper>
    <div class='main-btns-wrapper' rtlDiv>
      <div class='btns-container' rtlDiv>
        <button class="mobile-cancel_btn" (click)="closeModal()">{{ 'TRUCK_MAP.CANCEL' | translate }}</button>
        <button [ngClass]="{'mobile-apply-btn' : secondFormGroup.valid && !submitClicked,'mobile-not-apply-btn' : !secondFormGroup.valid || submitClicked}" [disabled]="!secondFormGroup.valid && !submitClicked" (click)="applyChanges()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</button>
      </div>
    </div>
  </div>
}

<div class='gray-background' (click)="closeModal()"></div>
<div class='create-reset-password-modal-wrapper'>
  <div class='main-title'>{{ 'LOGIN.RESET_PASSWORD' | translate }}</div>
  <div class='data-wrapper'>
    <div class="instructions" rtlDiv>{{ 'LOGIN.LINK_PASS' | translate }}</div>
    <div>
      <input rtlDiv type="text" class="email-input" placeholder="{{ 'LOGIN.EMAIL_ADDRESS' | translate }}" name="email"
        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+" (keyup)="validateEmail($event.target)"
        [(ngModel)]="resetEmail" #email="ngModel"
        [ngClass]="{'error-input': (email['errors'] && email['errors']['pattern'] && submit) || isEmpty}" />
        @if ((email['errors'] && email['errors']['pattern'] && submit) || isEmpty) {
          <div class="error-flag" rtlDiv>
            <img class='error-icon' rtlDiv src="assets/images/login/error.png" alt="" />
            <div>{{ 'LOGIN.INVALID_EMAIL' | translate }}</div>
          </div>
        }
      </div>
    </div>
    <div class='btns-container' rtlDiv>
      <div class='cancel-btn' rtlDiv (click)="closeModal()">{{ 'LOGIN.CANCEL' | translate }}</div>
      <div class='apply-btn' (click)='sendEmail(resetEmail)'>{{ 'LOGIN.SEND' | translate }}</div>
    </div>
  </div>
import { Component, Input } from '@angular/core';
import { ResponsiveService } from '../../services/responsive.service';
import { TranslatorService } from '../../services/translator_service';
import { ActivatedRoute } from '@angular/router';
import { ApiQuery } from '../../web-services/api/api.query';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { ProgressBarColorComponent } from '../../widgets/progress-bar-color/progress-bar-color.component';
import { API } from '../../types.api';
const RADIUS = 54;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
@Component({
  selector: 'app-bin-live-status',
  standalone: true,
  imports: [CommonModule,TranslateModule,RTLDivDirectiveDirective,MatDividerModule,MatCardModule,ProgressBarColorComponent],
  templateUrl: './bin-live-status.component.html',
  styleUrl: './bin-live-status.component.scss'
})
export class BinLiveStatusComponent {
	isMobile: Boolean;
	@Input() orientation: boolean;
	binInfo;
	@Input()
	set binLiveInfo_Bin(bin_info:any) {	
		this.binInfo = bin_info;
		this.subscribeBinInfo(this.binInfo);
	}

	progressValue;
	capacityLevel = 0;
	capacityLevelUnfiltered = 0;
	batteryLevelText = '';
	binTemprature = '0';
	binTemperatureLow = -20;
	binTemperatureHigh = 90;
	binBattery = '0';
	binBatteryLow = 0;
	binBatteryHigh = 100;
	binHumidity = '0';
	binHumidityLow = 0;
	binHumidityHigh = 100;
	binBatteryLevel = 100;
	translationsObj;
	currentLang;
	currentBatteryStatus: string = '../assets/images/binInfoPage/battery_v3.svg';
	translate;
	capacityDisplayType: number = 0;
	usageType = 1;
	blurKPIs:boolean = false;
	routeParamsSub;
	user_role_id = 0;

	constructor(private translator: TranslatorService,private route: ActivatedRoute, 
		private responsiveService: ResponsiveService,private apiQuery:ApiQuery) {
		this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	ngOnDestroy() {
		this.routeParamsSub.unsubscribe();
	}

	subscribeBinInfo = (bin: API.Bin) => {
		this.apiQuery.user$.subscribe(user => {
			if (!user) return;
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
			this.user_role_id = user["user_role_id"];
			this.capacityDisplayType = user["user_default_capacity_type_id"];

			this.routeParamsSub = this.route
			.queryParams
			.subscribe(params => {
				let id = params['binId'] || null;		
				if (!bin || !bin.Bin_live_Data || id != bin.bin_id) { return; }
				if(bin["Bin_Restrictions"].collection_mode == 2){
					this.blurKPIs = false;
				}else{
					this.blurKPIs = true;
				}
				this.capacityLevelUnfiltered = bin.Bin_live_Data['fill_level_percent_unfiltered'];	
				this.capacityLevel = bin.Bin_live_Data.fill_level_percent;
				if (this.capacityDisplayType != 0) {
					this.capacityLevel = Math.round((bin.Bin_thresholds["bin_max_capacity_in_liters"]) * (bin.Bin_live_Data.fill_level_percent / 100));
				}		
				this.binTemprature = bin.Bin_live_Data.temperature.toString();
				this.binHumidity = bin.Bin_live_Data.humidity.toString();
				this.binBattery = bin.Bin_live_Data.battery_level.toString();
				this.binBatteryLevel = bin.Bin_live_Data.battery_level;
				this.progressValue = document.querySelector('.progress__value');
				this.progressValue.style.strokeDasharray = CIRCUMFERENCE;
				if(this.user_role_id == 5 || this.user_role_id == 6){
					this.progress(bin.Bin_live_Data['fill_level_percent_unfiltered']);
				}else{
					this.progress(bin.Bin_live_Data.fill_level_percent);
				}
				this.currentBatteryStatus = this.getBatteryLevelIcon();
			});
		});
	}

	progress(value: number) {
		if (value == 0) {
			this.progressValue.style.display = 'none';
		} else {
			const progress = value / 100;
			const dashoffset = CIRCUMFERENCE * (1 - progress);
			this.progressValue.style.strokeDashoffset = dashoffset;
		}
	}

	getBatteryLevelIcon = () => {
		if(this.translationsObj!=undefined){
			if (this.binBatteryLevel >= 0 && this.binBatteryLevel <= 25) {
				this.batteryLevelText = this.translationsObj.BATTERY_STATUS.CRITICAL;
				return '../assets/images/binInfoPage/battery_v1.svg';
			}
			if (this.binBatteryLevel >= 26 && this.binBatteryLevel <= 50) {
				this.batteryLevelText = this.translationsObj.BATTERY_STATUS.LOW;
				return '../assets/images/binInfoPage/battery_v2.svg';
			}
			if (this.binBatteryLevel >= 51 && this.binBatteryLevel <= 75) {
				this.batteryLevelText = this.translationsObj.BATTERY_STATUS.NORMAL;
				return '../assets/images/binInfoPage/battery_v3.svg';
			}
			this.batteryLevelText = this.translationsObj.BATTERY_STATUS.GOOD;
			return '../assets/images/binInfoPage/battery_v4.svg';
		}
	}
}

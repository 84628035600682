import { CommonModule } from '@angular/common';
import { Component, VERSION, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ZXingScannerComponent, ZXingScannerModule } from '@zxing/ngx-scanner';
import _ from 'lodash';
import { BarcodeFormat } from '@zxing/library';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';

@Component({
  selector: 'app-ngx-qr-reader',
  standalone: true,
  imports: [TranslateModule,CommonModule,ZXingScannerModule,MatInputModule,NgbModule,RTLDivDirectiveDirective],
  templateUrl: './ngx-qr-reader.component.html',
  styleUrl: './ngx-qr-reader.component.scss'
})
export class NgxQrReaderComponent {
  allowedFormats = [ BarcodeFormat.QR_CODE, BarcodeFormat.EAN_13, BarcodeFormat.CODE_128, BarcodeFormat.DATA_MATRIX];

  ngVersion = VERSION.full;
  @ViewChild('select',{static:false})
  selectField: ElementRef;

  @ViewChild('scanner',{static:false})
  scanner: ZXingScannerComponent;

  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;

  availableDevices: MediaDeviceInfo[];
  devicesToDisplay: MediaDeviceInfo[];
  deviceToDisplay: MediaDeviceInfo = null;
  currentDevice: MediaDeviceInfo = null;

  userSelectedDevice: MediaDeviceInfo = null;

  message: string = "121013999"

  @Output() messageEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }

  stateToEmoji(state: boolean): string {

    const states = {
      undefined: '❔',
      null: '⭕',
      true: '✔',
      false: '❌'
    };

    return states['' + state];
  }

  switchCamera() {
    this.availableDevices.forEach((device) => {
      if (!this.scanner.isCurrentDevice(device)) {
        this.currentDevice = device;
      }
    });
  }

  camerasFoundHandler(camerasFoundEvent) {
    if (camerasFoundEvent === undefined) return;
    if (camerasFoundEvent.length > 0) {
      this.currentDevice = camerasFoundEvent[0];
      this.hasDevices = true;
      this.availableDevices = camerasFoundEvent;
      this.devicesToDisplay = _.cloneDeep(this.availableDevices);
      this.deviceToDisplay = _.cloneDeep(this.currentDevice);
    }
  }

  scanSuccessHandler(scanSuccessEvent) {
    if (scanSuccessEvent === undefined) return;

    if (scanSuccessEvent.length === 10) {
      this.messageEvent.emit(scanSuccessEvent);
    }
  }

  scanErrorHandler(scanErrorEvent) {
    if (scanErrorEvent === undefined) return;
  }

  scanFailureHandler(scanFailureEvent) {
    if (scanFailureEvent === undefined) return;
  }

  scanCompleteHandler(scanCompleteEvent) {
    if (scanCompleteEvent === undefined) return;
  }
}


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiStore } from './api.store';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { tap } from 'rxjs/operators';
import { JSREPORT_URL, SERVER_REMOTE_URL } from '../../../environments/environment';
import * as _ from 'lodash';
import { Router } from '@angular/router';

const headers = new HttpHeaders({
    'Content-Type': 'application/octet-stream',
    'Authorization': 'Basic YWRtaW5AZGF0YS1iaW5zLmNvbTpxMzEwODgy',
    'Content-Encoding': 'gzip'
    });

const postHeadersFile = () => new HttpHeaders({
    'Authorization': `Basic ${localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)}`
});

const postHeaders = () => new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Basic ${localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)}`
});

const getHeaders = () =>new HttpHeaders({
    'Authorization': `Basic ${localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)}`
});

const url = _.find(SERVER_REMOTE_URL, sru => sru.is_selected).url;
const reportsUrl = _.find(JSREPORT_URL, sru => sru.is_selected).url;

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    isInternetConnected: boolean = true;
    binsArrivalArray: any[] = [];
    noInternetTimeInterval;
    noInternetTimeCounter: number = 0;
    offlineModeEvent: any = {
        is_enabled: false,
        is_triggered_by_user: false
    };
    internetTimeCounter: number = 0;
    internetTimeInterval;

    constructor(private http: HttpClient,private router: Router,
        private apiStore: ApiStore) {}


        getReport(data:any) {                   
            const body = data.buffer;
            return this.http.post(
                `${reportsUrl}`,
                body, 
                { headers, responseType: 'text'}
              );
        }

        SetUserWidgets(dataToSend : any){            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }     
            const body = dataToSend;                                                        
            this.http
            .post(`${url}/SetUserWidgets`,body,{headers:postHeaders()})
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );   
        }
        
        SetUserDynamicColumns(dataToSend : any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;                                                        
            this.http
            .post(`${url}/SetUserDynamicColumns`,body,{headers:postHeaders()})
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );  
        }
        
        removeUserMessages(dataToSend : any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;                        
            return this.http.post(`${url}/RemoveUserMessages`, body,{headers:postHeaders()});     
        }

        getUserEvents(){            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }    
            this.apiStore.update({ userEvents: [] });       
            this.http
            .get(`${url}/GetUserAlerts`,{headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ userEvents: data });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );  
        }
        
        getNavigationOptimizationTypes(){            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }    
            let filteredInfo = [];            
            this.apiStore.update({ optimizationTypes: [] });       
            this.http
            .get(`${url}/GetNavigationOptimizationTypes`,{headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    _.each(data.OptimizationTypesObj, (ot) => {									
                        filteredInfo.push(ot);                        
                    });
                    if(filteredInfo.length == 0){
                        filteredInfo.push({});
                    }
                    this.apiStore.update({ optimizationTypes: filteredInfo });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );  
        }

        binsCapacityData(id:any,start_date:any,end_date:any,){            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }    
            let filteredInfo = [];            
            this.apiStore.update({ capacityData: [] });       
            const params = `${id}/date/?${start_date}?${end_date}`;
            this.http
            .get(`${url}/binsCapacityData/${params}`,{headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    if(data.Bins_capacity_data.length > 0){
                        _.each(data.Bins_capacity_data, (ua) => {									
                            _.each(ua.Fill_level_samples, (sample) => {									
                                filteredInfo.push(sample);                        
                            });                    
                        });
                    }

                    if(filteredInfo.length == 0){
                        filteredInfo.push({});
                    }
                    this.apiStore.update({ capacityData: filteredInfo });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );  
        }

        getTicketEventTypes(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }    
            let filteredInfo = [];            
            this.apiStore.update({ ticketEventsType: [] });       
            this.http
            .get(`${url}/GetTicketEventTypes`,{headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    _.each(data.TicketEventTypesObj, (ua) => {									
                        filteredInfo.push(ua);                        
                    });
                    if(filteredInfo.length == 0){
                        filteredInfo.push({});
                    }
                    this.apiStore.update({ ticketEventsType: filteredInfo });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );  
        }

        getTicketEventCause(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }    
            let filteredInfo = [];            
            this.apiStore.update({ ticketEventsCause: [] });       
            this.http
            .get(`${url}/GetTicketEventCauses`,{headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    _.each(data.TicketEventCausesObjInfo, (ua) => {									
                        filteredInfo.push(ua);                        
                    });
                    if(filteredInfo.length == 0){
                        filteredInfo.push({});
                    }
                    this.apiStore.update({ ticketEventsCause: filteredInfo });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );  
        }
 
        getClusterTypes(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }    
            let filteredInfo = [];            
            this.apiStore.update({ clusterTypes: [] });       
            this.http
            .get(`${url}/GetClusterTypes`,{headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    _.each(data.binClusterTypesObj, (ua) => {									
                        filteredInfo.push(ua);                        
                    });
                    if(filteredInfo.length == 0){
                        filteredInfo.push({});
                    }
                    this.apiStore.update({ clusterTypes: filteredInfo });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );  
        }

        getUsageTypes(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }    
            let filteredInfo = [];            
            this.apiStore.update({ usageTypes: [] });       
            this.http
            .get(`${url}/GetUsageTypes`,{headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    _.each(data.UsageTypes, (ua) => {									
                        filteredInfo.push(ua);                        
                    });
                    if(filteredInfo.length == 0){
                        filteredInfo.push({});
                    }
                    this.apiStore.update({ usageTypes: filteredInfo });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );  
        }

        getUserAccounts(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }    
            let filteredInfo = [];            
            this.apiStore.update({ userAccounts: [] });       
            this.http
            .get(`${url}/GetUserAccounts`,{headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    _.each(data.AccountsList, (ua) => {									
                        filteredInfo.push(ua);                        
                    });
                    if(filteredInfo.length == 0){
                        filteredInfo.push({});
                    }
                    this.apiStore.update({ userAccounts: filteredInfo });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );  
        }

        getCapacityDisplayTypes(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }    
            let filteredInfo = [];            
            this.apiStore.update({ capacityDisplayTypes: [] });       
            this.http
            .get(`${url}/GetCapacityDisplayTypes`,{headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    _.each(data.DisplayTypes, (ua) => {									
                        filteredInfo.push(ua);                        
                    });
                    if(filteredInfo.length == 0){
                        filteredInfo.push({});
                    }
                    this.apiStore.update({ capacityDisplayTypes: filteredInfo });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );  
        }

        getInstallationAccessoryTypes(){     
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }  
            let filteredInfo = [];       
            this.apiStore.update({ installationAccessoryTypes: [] });       
            this.http
                .get(`${url}/GetInstallationAccessoryTypes`,{headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.InstallationAccessoryTypesObj, (item) => {									
                            filteredInfo.push(item);                        
                        });
                        this.apiStore.update({ installationAccessoryTypes: filteredInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );  
        }

        getWorkPlanDistrebutionMethods(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }  
            let filteredInfo = [];            
            this.apiStore.update({ workPlanDistrebutionMethods: [] });       
            this.http
                .get(`${url}/GetWorkPlanDistrebutionMethods`,{headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.distrebution_Method_Info, (item) => {									
                            filteredInfo.push(item);                        
                        });
                        this.apiStore.update({ workPlanDistrebutionMethods: filteredInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );  
        }

        getUnitIssuesType(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }  
            let filteredInfo = [];            
            this.apiStore.update({ pendingStatus: [] });       
            this.http
                .get(`${url}/GetUnitIssuesType`,{headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.issuesTypes, (item) => {									
                            filteredInfo.push(item);                        
                        });
                        this.apiStore.update({ pendingStatus: filteredInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );  
        }

        getCustomerWorkPlanCollectionschedulePolicy(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }  
            let filteredInfo = [];            
            this.apiStore.update({ customerWorkPlanCollectionschedulePolicy: [] });       
            this.http
                .get(`${url}/GetCustomerWorkPlanCollectionschedulePolicy`,{headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.PolicyTypes, (item) => {									
                            filteredInfo.push(item);                        
                        });
                        this.apiStore.update({ customerWorkPlanCollectionschedulePolicy: filteredInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                ); 
        }

        GetWidgets(){            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }        
            return this.http.get(`${url}/GetUserWidgets`,{headers:getHeaders()});
        }

        GetDynamicColumns(){            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }          
            return this.http.get(`${url}/GetUserDynamicColumns`,{headers:getHeaders()});
        }

        GetBinsCapacityData(sites:any,dataToSend:any) {            
            if(!sites || !localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }   
            const body = dataToSend;
            this.apiStore.update({ binsCapacityData: [] });
            let filteredInfo = [];            
            this.http
            .post(`${url}/GetBinsCapacityData`,body,{headers:postHeaders()})
            .pipe(
                tap((data:any) => {        
                    _.each(data.Bins_capacity_data, (ua) => {									
                        filteredInfo.push(ua);                        
                    });
                    if(filteredInfo.length == 0){
                        filteredInfo.push({});
                    }
                    this.apiStore.update({ binsCapacityData: filteredInfo });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );     
        }

        getfilteredUserEventsByType(dataToSend:any,sites?:any) {            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }   
            const body = dataToSend;
            this.apiStore.update({ userEventsByType: [] });
            let filteredInfo = [];            
            this.http
            .post(`${url}/GetUserAlertsByTypeAndDate`,body,{headers:postHeaders()})
            .pipe(
                tap((data:any) => {              
                    if(data.user_alerts[0]!=null){                        
                        _.each(data.user_alerts, (ua) => {	
                            if(sites){
                                if (sites.some(site => site.site_id == ua.site_id)) {																					
                                    filteredInfo.push(ua);
                                }
                            }else{
                                filteredInfo.push(ua);
                            }								
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ userEventsByType: filteredInfo });
                    }else{
                        this.apiStore.update({ userEventsByType: [{}] });
                    }                   
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );     
        }

        getUserMessagesByType(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }  
            this.apiStore.update({ userMessagesByType: [] });
            let filteredInfo = [];
            this.http
                .get(`${url}/GetUserMessagesByType`,{headers:getHeaders()})
                .pipe(
                    tap((data:any) => {                                                                  
                        _.each(data.user_messages, (ua) => {									
                            filteredInfo.push(ua);                            
                        });
                        this.apiStore.update({ userMessagesByType: filteredInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );  
        }

        getUserAlertsByType(sites?:any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }  
            this.apiStore.update({ userAlertsByType: [] });
            let filteredInfo = [];
            this.http
                .get(`${url}/GetUserAlertsByType`,{headers:getHeaders()})
                .pipe(
                    tap((data:any) => {                                          
                        _.each(data.user_alerts, (ua) => {	
                            if(sites){
                                if (sites.some(site => site.site_id == ua.site_id)) {																					
                                    filteredInfo.push(ua);
                                } 
                            }else{
                                filteredInfo.push(ua);
                            }				
                        });
                        if(filteredInfo.length == 0){
                            this.apiStore.update({ userAlertsByType: [{}] });
                        }else{
                            this.apiStore.update({ userAlertsByType: filteredInfo });
                        }
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );  
        }

        
        getfilteredExceptionsReports(dataToSend:any,sites?:any) {         
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }  
            const body = dataToSend;
            this.apiStore.update({ exceptionsReports: [] });
            let filteredInfo = [];
                this.http
                .post(`${url}/GetSiteExceptionsList`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {                                                
                        _.each(data.user_messages, (cs) => {	
                            if(sites){
                                if (sites.some(site => site.site_id == cs["site_id"])) {																					
                                    filteredInfo.push(cs);
                                }
                            }else{
                                filteredInfo.push(cs);
                            }								
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ exceptionsReports: filteredInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        getfilteredDriverExceptionsReports(start_date:any,end_date:any,sites?:any) {         
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }  
            const params = `?exception_time>="${start_date}"?exception_time<="${end_date}"`;     
            this.apiStore.update({ driverExceptionsReports: [] });            
            let filteredInfo = [];
                this.http
                .get(`${url}/exceptionHandler/${params}`,{headers:getHeaders()})
                .pipe(
                    tap((data:any) => {      
                        _.each(data.exceptionInfo, (cs) => {	
                            if(sites){
                                if (sites.some(site => site.site_id == cs["site_id"])) {																					
                                    filteredInfo.push(cs);
                                }
                            }else{
                                filteredInfo.push(cs);
                            }								
                        });                        
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }                      
                        this.apiStore.update({ driverExceptionsReports: filteredInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        getfilteredBinsCollectionsStats(dataToSend:any,flag,sites?:any) {         
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                      
            const body = dataToSend;                                    
            let filteredInfo = [];      
            let allfilteredInfo = [];      
            let filteredInfoToHighlightPicker  = [];             
            let filteredCollectionsForWorkplans = [];
            let filteredCollectionsForInsights = [];
            let allFilteredCollectionsForWorkplanProgress = [];
            let filteredCollectionsForWorkplanProgress = [];
            if(flag == 0){
                this.http
                .post(`${url}/binsCollectionsStats`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.Bins_collections_data, (cs) => {	
                            if(sites){
                                if (sites.some(site => site.site_id == cs["site_id"])) {																					
                                    filteredInfoToHighlightPicker.push(cs);
                                }
                            }else{
                                filteredInfoToHighlightPicker.push(cs);
                            }						
                        });
                        if(filteredInfoToHighlightPicker.length == 0){
                            filteredInfoToHighlightPicker.push({});
                        }
                        this.apiStore.update({ collectionsStatsToHighlightPicker: filteredInfoToHighlightPicker });      
                    }))   
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                ); 
            }else if(flag == 4){
                this.http
                .post(`${url}/binsCollectionsStats`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.Bins_collections_data, (cs) => {	
                            filteredCollectionsForInsights.push(cs);								
                        });
                        if(filteredCollectionsForInsights.length == 0){                        
                            filteredCollectionsForInsights.push({});
                        }                        
                        this.apiStore.update({ CollectionsStatsForInsights: filteredCollectionsForInsights });                      
                    }))   
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                ); 
            }else if(flag == 2){
                this.http
                .post(`${url}/binsCollectionsStats`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.Bins_collections_data, (cs) => {	
                            filteredCollectionsForWorkplans.push(cs);								
                        });
                        if(filteredCollectionsForWorkplans.length == 0){                        
                            filteredCollectionsForWorkplans.push({});
                        }                        
                        this.apiStore.update({ CollectionsStatsForWorkplans: filteredCollectionsForWorkplans });                      
                    }))   
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                ); 
            }else if(flag == 3){
                if(this.apiStore.getValue()["allCollectionsStatsForWorkplanProgres"].length == 0){
                    this.http
                    .post(`${url}/binsCollectionsStats`,body,{headers:postHeaders()})
                    .pipe(
                        tap((data:any) => {
                            _.each(data.Bins_collections_data, (cs) => {	
                                allFilteredCollectionsForWorkplanProgress.push(cs);		
                                if(sites){
                                    if (sites.some(site => site.site_id == cs["site_id"])) {																					
                                        filteredCollectionsForWorkplanProgress.push(cs);	
                                    }
                                }else{
                                    filteredCollectionsForWorkplanProgress.push(cs);	
                                }							
                            });
                            if(filteredCollectionsForWorkplanProgress.length == 0){                        
                                filteredCollectionsForWorkplanProgress.push({});
                            }        
                            this.apiStore.update({ allCollectionsStatsForWorkplanProgres: allFilteredCollectionsForWorkplanProgress });                
                            this.apiStore.update({ CollectionsStatsForWorkplanProgres: filteredCollectionsForWorkplanProgress });                      
                        }))   
                    .subscribe(
                        () => {},
                        err => {
                            if(err.error != null && err.error.toString().includes("expired")){
                                this.tokenExpired();
                            }
                        }
                    ); 
                }else{                
                    _.each(this.apiStore.getValue()["allCollectionsStatsForWorkplanProgres"], (cs) => {                      
                        if(sites){
                            if (sites.some(site => site.site_id == cs["site_id"])) {																					
                                filteredCollectionsForWorkplanProgress.push(cs);	
                            }
                        }else{
                            filteredCollectionsForWorkplanProgress.push(cs);	
                        }	
                    });              
                    if(filteredCollectionsForWorkplanProgress.length == 0){                        
                        filteredCollectionsForWorkplanProgress.push({});
                    }    
                    this.apiStore.update({ CollectionsStatsForWorkplanProgres: filteredCollectionsForWorkplanProgress });                                   
                } 
            }
            if(this.apiStore.getValue()["allCollectionsStats"].length == 0){
                this.http
                .post(`${url}/binsCollectionsStats`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.Bins_collections_data, (cs) => {	
                            allfilteredInfo.push(cs);		
                            if(sites){
                                if (sites.some(site => site.site_id == cs["site_id"])) {																					
                                    filteredInfo.push(cs);
                                }
                            }else{
                                filteredInfo.push(cs);
                            }						
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allCollectionsStats: allfilteredInfo });
                        if(flag == 1){
                            this.apiStore.update({ collectionsStats: filteredInfo });
                        }else if(flag == 5){
                            this.apiStore.update({ collectionsStatsInCollections: filteredInfo });
                        }
                    })                  
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                
                _.each(this.apiStore.getValue()["allCollectionsStats"], (cs) => {                      
                    if(sites){
                        if (sites.some(site => site.site_id == cs["site_id"])) {																					
                            filteredInfo.push(cs);
                        }
                    }else{
                        filteredInfo.push(cs);
                    }
                });              
                if(filteredInfo.length == 0){
                    filteredInfo.push({});
                } 
                if(flag == 1){
                    this.apiStore.update({ collectionsStats: filteredInfo });     
                }else if(flag == 5){
                    this.apiStore.update({ collectionsStatsInCollections: filteredInfo });
                }               
            } 
        }

        getfilteredBinsRefillsStats(dataToSend:any,sites?:any) {         
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                      
            const body = dataToSend;                                    
            let filteredInfo = [];      
            let allfilteredInfo = [];             
            if(this.apiStore.getValue()["allRefillsStats"].length == 0){
                this.http
                .post(`${url}/binsRefillsStats`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.Bins_refills_data, (cs) => {	
                            allfilteredInfo.push(cs);	
                            if(sites){
                                if (sites.some(site => site.site_id == cs["site_id"])) {																					
                                    filteredInfo.push(cs);
                                }
                            }else{
                                filteredInfo.push(cs);
                            }							
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allRefillsStats: allfilteredInfo });
                        this.apiStore.update({ refillsStats: filteredInfo });
                    })                  
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                
                _.each(this.apiStore.getValue()["allRefillsStats"], (cs) => {                      
                    if (sites.some(site => site.site_id == cs["site_id"])) {																					
                        filteredInfo.push(cs);
                    }
                });              
                this.apiStore.update({ refillsStats: filteredInfo });                    
            } 
        }

        getfilteredBinsCollectionsStatsToday(dataToSend:any,sites?:any) {      
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                      
            const body = dataToSend;                                    
            let filteredInfo = [];    
            let allfilteredInfo = [];                 
            if(this.apiStore.getValue()["allCollectionsStatsToday"].length == 0){
                this.http
                .post(`${url}/binsCollectionsStats`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.Bins_collections_data, (cs) => {		
                            allfilteredInfo.push(cs);	
                            if(sites){
                                if (sites.some(site => site.site_id == cs["site_id"])) {																					
                                    filteredInfo.push(cs);
                                }
                            }else{
                                filteredInfo.push(cs);
                            }						
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allCollectionsStatsToday: allfilteredInfo });
                        this.apiStore.update({ collectionsStatsToday: filteredInfo });
                    })                  
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                
                _.each(this.apiStore.getValue()["allCollectionsStatsToday"], (cs) => {                      
                    if (sites.some(site => site.site_id == cs["site_id"])) {																					
                        filteredInfo.push(cs);
                    }
                });              
                if(filteredInfo.length == 0){
                    filteredInfo.push({});
                }           
                this.apiStore.update({ collectionsStatsToday: filteredInfo });                    
            }                     
        }

        getfilteredSiteAvarageCollectionVolume(dataToSend,sites?:any){         
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            } 
            const body = dataToSend;                                                            
            this.apiStore.update({ collectionVolume: [] });
            let filteredInfo = [];
            this.http
            .post(`${url}/GetSiteAvarageCollectionVolume`,body,{headers:postHeaders()})
            .pipe(
                tap((data:any) => {    
                    _.each(data.BinsAvgCollectionVolumeObj, (ck) => {	
                        if(sites){
                            if (sites.some(site => site.site_id == ck["site_id"])) {																					
                                filteredInfo.push(ck);
                            }
                        }else{
                            filteredInfo.push(ck);
                        }								
                    });
                    if(filteredInfo.length == 0){
                        filteredInfo.push({});
                    }
                    this.apiStore.update({ collectionVolume: filteredInfo });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );              
        }        


        getfilteredSiteCollectionsKpis(dataToSend:any,sites?:any) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                      
            const body = dataToSend;                                    
            let filteredInfo = [];     
            let allfilteredInfo = [];                   
            if(this.apiStore.getValue()["allCollectionsKpis"].length == 0){
                this.http
                .post(`${url}/GetSiteCollectionsKpis`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.SiteCollectionKpiObj, (ck) => {	
                            allfilteredInfo.push(ck);
                            if(sites){
                                if (sites.some(site => site.site_id == ck["site_id"])) {																					
                                    filteredInfo.push(ck);
                                }
                            }else{
                                filteredInfo.push(ck);
                            }
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allCollectionsKpis: allfilteredInfo });
                        this.apiStore.update({ collectionsKpis: filteredInfo });
                    })                  
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                
                _.each(this.apiStore.getValue()["allCollectionsKpis"], (bin) => {  
                    if(sites){
                        if (sites.some(site => site.site_id == bin["site_id"])) {																					
                            filteredInfo.push(bin);
                        }
                    }else{
                        filteredInfo.push(bin);
                    }                    
                });              
                if(filteredInfo.length == 0){
                    filteredInfo.push({});
                }          
                this.apiStore.update({ collectionsKpis: filteredInfo });                    
            }                                   
        }

        getfilteredSiteRefillsKpis(dataToSend:any,sites?:any) {                        
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                                  
            const body = dataToSend;                                    
            let filteredInfo = [];     
            let allfilteredInfo = [];                   
            if(this.apiStore.getValue()["allRefillsKpis"].length == 0){
                this.http
                .post(`${url}/GetSiteRefillsKpis`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.SiteRefillsKpiObj, (ck) => {	
                            allfilteredInfo.push(ck);
                            if(sites){
                                if (sites.some(site => site.site_id == ck["site_id"])) {																					
                                    filteredInfo.push(ck);
                                }
                            }else{
                                filteredInfo.push(ck);
                            }
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allRefillsKpis: allfilteredInfo });
                        this.apiStore.update({ refillsKpis: filteredInfo });
                    })                  
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                                
                _.each(this.apiStore.getValue()["allRefillsKpis"], (bin) => {                      
                    if (sites.some(site => site.site_id == bin["site_id"])) {																					
                        filteredInfo.push(bin);
                    }
                });  
                if(filteredInfo.length == 0){
                    filteredInfo.push({});
                }            
                this.apiStore.update({ refillsKpis: filteredInfo });                    
            }                                   
        }

        getfilteredSitesClustersList(dataToSend:any,sites?:any) {                        
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                                  
            const body = dataToSend;                                    
            let filteredInfo = [];     
            let allfilteredInfo = [];       
            if(this.apiStore.getValue()["allSitesClustersList"].length == 0){
                this.http
                .post(`${url}/GetSitesClustersList`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.SitesClustersData, (site) => {	
                            _.each(site.SiteClusters, (cluster) => {	
                                cluster.site_id = site.site_id;
                                cluster.site_name = site.site_name;
                                allfilteredInfo.push(cluster);
                                if(sites){
                                    if (sites.some(site => site.site_id == cluster["site_id"])) {																					
                                        filteredInfo.push(cluster);
                                    }
                                }else{
                                    filteredInfo.push(cluster);
                                }
                            });
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allSitesClustersList: allfilteredInfo });
                        this.apiStore.update({ sitesClustersList: filteredInfo });
                    })                  
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                                
                _.each(this.apiStore.getValue()["allSitesClustersList"], (cluster) => {    
                    if(sites){
                        if (sites.some(site => site.site_id == cluster["site_id"])) {																					
                            filteredInfo.push(cluster);
                        }
                    }else{
                        filteredInfo.push(cluster);
                    }                  
                });  
                if(filteredInfo.length == 0){
                    filteredInfo.push({});
                }            
                this.apiStore.update({ sitesClustersList: filteredInfo });
            }                                   
        }

        getNeighborhoodsList(dataToSend : object = {},sites?:any) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                                  
            const body = dataToSend;                                    
            let filteredInfo = [];     
            let allfilteredInfo = [];       
            if(this.apiStore.getValue()["allNeighborhoodsList"].length == 0){
                this.http
                .post(`${url}/GetBinsNeighborhoodList`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.NBRHoodList, (site) => {
                            allfilteredInfo.push(site);
                            if(sites){
                                if (sites.some(site => site.site_id == site["site_id"])) {																					
                                    filteredInfo.push(site);
                                }
                            }else{
                                filteredInfo.push(site);
                            }
                          });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allNeighborhoodsList: allfilteredInfo });
                        this.apiStore.update({ neighborhoodsList: filteredInfo });
                    })                  
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                                
                _.each(this.apiStore.getValue()["allNeighborhoodsList"], (cluster) => {    
                    if(sites){
                        if (sites.some(site => site.site_id == cluster["site_id"])) {																					
                            filteredInfo.push(cluster);
                        }
                    }else{
                        filteredInfo.push(cluster);
                    }                  
                });  
                if(filteredInfo.length == 0){
                    filteredInfo.push({});
                }            
                this.apiStore.update({ neighborhoodsList: filteredInfo });
            }  
        }

        getCRTicketsList(dataToSend:any,sites?:any) {                        
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                                  
            const body = dataToSend;                                    
            let filteredInfo = [];     
            let allfilteredInfo = [];   
            if(this.apiStore.getValue()["allCRTicketsList"].length == 0){
                this.http
                .post(`${url}/GetCRTicketsList`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.ticketsInfoData, (ck) => {	
                            allfilteredInfo.push(ck);
                            if(sites){
                                if (sites.some(site => site.site_id == ck["site_id"])) {																					
                                    filteredInfo.push(ck);
                                }
                            }else{
                                filteredInfo.push(ck);
                            }
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allCRTicketsList: allfilteredInfo });
                        this.apiStore.update({ cRTicketsList: filteredInfo });
                    })                  
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                                
                _.each(this.apiStore.getValue()["allCRTicketsList"], (bin) => {    
                    if(sites){
                        if (sites.some(site => site.site_id == bin["site_id"])) {																					
                            filteredInfo.push(bin);
                        }
                    }else{
                        filteredInfo.push(bin);
                    }                  
                });  
                if(filteredInfo.length == 0){
                    filteredInfo.push({});
                }            
                this.apiStore.update({ cRTicketsList: filteredInfo });
            }                                   
        }

        getInsTicketsList(dataToSend:any,sites?:any) {                        
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                                  
            const body = dataToSend;                                    
            let filteredInfo = [];     
            let allfilteredInfo = [];       
            if(this.apiStore.getValue()["allInsTicketsList"].length == 0){
                this.http
                .post(`${url}/GetInsTicketsList`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.ticketsInfoData, (ck) => {	
                            allfilteredInfo.push(ck);
                            if(sites){
                                if (sites.some(site => site.site_id == ck["site_id"])) {																					
                                    filteredInfo.push(ck);
                                }
                            }else{
                                filteredInfo.push(ck);
                            }
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allInsTicketsList: allfilteredInfo });
                        this.apiStore.update({ insTicketsList: filteredInfo });
                    })                  
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                                
                _.each(this.apiStore.getValue()["allInsTicketsList"], (bin) => {    
                    if(sites){
                        if (sites.some(site => site.site_id == bin["site_id"])) {																					
                            filteredInfo.push(bin);
                        }
                    }else{
                        filteredInfo.push(bin);
                    }                  
                });  
                if(filteredInfo.length == 0){
                    filteredInfo.push({});
                }            
                this.apiStore.update({ insTicketsList: filteredInfo });
            }                                   
        }

        getRmTicketsList(dataToSend:any,sites?:any) {                        
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                                  
            const body = dataToSend;                                    
            let filteredInfo = [];     
            let allfilteredInfo = [];       
            if(this.apiStore.getValue()["allRmTicketsList"].length == 0){
                this.http
                .post(`${url}/GetRmTicketsList`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.ticketsInfoData, (ck) => {	
                            allfilteredInfo.push(ck);
                            if(sites){
                                if (sites.some(site => site.site_id == ck["site_id"])) {																					
                                    filteredInfo.push(ck);
                                }
                            }else{
                                filteredInfo.push(ck);
                            }
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allRmTicketsList: allfilteredInfo });
                        this.apiStore.update({ rmTicketsList: filteredInfo });
                    })                  
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                                
                _.each(this.apiStore.getValue()["allRmTicketsList"], (bin) => {    
                    if(sites){
                        if (sites.some(site => site.site_id == bin["site_id"])) {																					
                            filteredInfo.push(bin);
                        }
                    }else{
                        filteredInfo.push(bin);
                    }                  
                });  
                if(filteredInfo.length == 0){
                    filteredInfo.push({});
                }            
                this.apiStore.update({ rmTicketsList: filteredInfo });
            }                                   
        }

        getTicketsList(sites?:any) {                        
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                                  
            const body = {
                status_id:1
            };                                    
            let filteredInfo = [];     
            let allfilteredInfo = [];       
            if(this.apiStore.getValue()["allTicketsList"].length == 0){
                this.http
                .post(`${url}/GetTicketsList`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.ticketsInfoData, (ck) => {	
                            allfilteredInfo.push(ck);
                            if(sites){
                                if (sites.some(site => site.site_id == ck["site_id"])) {																					
                                    filteredInfo.push(ck);
                                }
                            }else{
                                filteredInfo.push(ck);
                            }
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allTicketsList: allfilteredInfo });
                        this.apiStore.update({ ticketsList: filteredInfo });
                    })                  
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                                
                _.each(this.apiStore.getValue()["allTicketsList"], (bin) => {    
                    if(sites){
                        if (sites.some(site => site.site_id == bin["site_id"])) {																					
                            filteredInfo.push(bin);
                        }
                    }else{
                        filteredInfo.push(bin);
                    }                  
                });  
                if(filteredInfo.length == 0){
                    filteredInfo.push({});
                }            
                this.apiStore.update({ ticketsList: filteredInfo });
            }                                   
        }

        getTicketAnalysisTypes(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let filteredInfo = [];   
            this.apiStore.update({ analysisTypes: [] });
            this.http
                .get(`${url}/GetTicketAnalysisTypes`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.AnalysisTypesObj, (un) => {	
                            filteredInfo.push(un);
                        });	

                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ analysisTypes: filteredInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        getTicketRemoveOrReplaceTypes(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let filteredInfo = [];   
            this.apiStore.update({ removeOrReplaceTypes: [] });
            this.http
                .get(`${url}/GetTicketRemoveOrReplaceTypes`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.RmoveOrReplaceTypesObj, (un) => {	
                            filteredInfo.push(un);
                        });	

                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ removeOrReplaceTypes: filteredInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        getfilteredWorkPlanScheduler(dataToSend:any,sites?:any) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let filteredInfo = [];   
            let allfilteredInfo = [];
            const body = dataToSend;                                        
            if(this.apiStore.getValue()["allWorkPlanScheduler"].length == 0){
                this.http
                .post(`${url}/GetWorkPlanScheduler`,body,{headers:postHeaders()})
                .pipe(
                    tap((data:any) => {
                        if(data.workPlanSchedulerObj != undefined){
                            _.each(data.workPlanSchedulerObj, (ws) => {		
                                allfilteredInfo.push(ws);	
                                if(sites){
                                    if (sites.some(site => site.site_id == ws["site_id"])) {																					
                                        filteredInfo.push(ws);
                                    }
                                }else{
                                    filteredInfo.push(ws);
                                }						
                            });
                        }

                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allWorkPlanScheduler: allfilteredInfo });
                        this.apiStore.update({ workPlanScheduler: filteredInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                                
                _.each(sites, (site) => {		
                    _.each(this.apiStore.getValue()["allWorkPlanScheduler"], (workPlanSite) => {		
                        if (workPlanSite["site_id"] == site.site_id) {	
                            filteredInfo.push(workPlanSite);
                        }
                    });
                });
                if(filteredInfo.length == 0){
                    filteredInfo.push({});
                }
                this.apiStore.update({ workPlanScheduler: filteredInfo });
            }                                                    
        }

        getUnAssignToWorkPlanBinsInfo(siteId){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let filteredInfo = [];   
            this.apiStore.update({ unAssignBins: [] });
            this.http
                .get(`${url}/getUnAssignToWorkPlanBinsInfo/${siteId}`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.BinsInfo, (un) => {	
                            filteredInfo.push(un);
                        });	

                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ unAssignBins: filteredInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        getUserMessages(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.apiStore.update({ userMessages: [] });
            this.http
                .get(`${url}/GetUserMessages`,{headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        this.apiStore.update({ userMessages: data });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );  
        }

        preInstallationMappingAddNewBin(dataToSend : object = {}){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;                        
            return this.http.post(`${url}/preInstallationMappingAddNewBin`, body,{headers:postHeaders()}); 
        }

        SetWorkPlanScheduler(dataToSend : object = {}){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;                        
            return this.http.post(`${url}/SetWorkPlanScheduler`, body,{headers:postHeaders()}); 
        }        

        getPreInstallationMappingInfoBySite(siteId: any) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.apiStore.update({ preInstallationMappingInfo: [] });
            this.http
                .get(`${url}/getPreInstallationMappingInfoBySite/${siteId}`,{headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        this.apiStore.update({ preInstallationMappingInfo: data });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        getWorkPlanTrucksInfo(planId){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            return this.http.get(`${url}/WorkPlanTrucksInfoBySitePlanId/${planId}`,{headers:getHeaders()});
        }

        GetBinsWeeklyCollectionSchedule(siteId){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            return this.http.get(`${url}/GetBinsWeeklyCollectionSchedule/${siteId}`,{headers:getHeaders()});
        }

        getPendingPreMappingInfoBySiteId(siteId: any) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.apiStore.update({ preMappingInfo: [] });
            this.http
                .get(`${url}/getPendingPreMappingInfo/${siteId}`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        this.apiStore.update({ preMappingInfo: data });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        getBinsClusterInfoBySiteId(dataToSend : object = {}){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;                        
            return ((this.http.post<Object>(
                `${url}/GetBinsClusterInfoBySiteId`,
                body,
                {headers:postHeaders()}))
                .pipe(tap((data: Array<object>) => {
                  this.apiStore.update({ binsClusterInfoBySiteId: data });
                }
                ))
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                )
              );
        }

        setSiteClusterParams(dataToSend : any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;                        
            return this.http.post(`${url}/SetSiteClusterParams`, body,{headers:postHeaders()}).toPromise(); 
        }

        getCurrentCapacityAnalysis(dataToSend : any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;                        
            return this.http.post(`${url}/GetCurrentCapacityAnalysis`, body,{headers:postHeaders()}); 
        }

        GetSiteBinTypes(dataToSend : object = {}){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.apiStore.update({ binTypes: [] });
            const body = dataToSend;                        
            return ((this.http.post<Object>(
                `${url}/GetSiteBinTypes`,
                body,
                {headers:postHeaders()}))
                .pipe(tap((data: Array<object>) => {
                  this.apiStore.update({ binTypes: data });
                }
                ))
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                )
              );
        }

        getBinCollection(param: any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/binsCollections/${param}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ binCollectionData: data.Bins_collections_data.length > 0 ? data.Bins_collections_data[0].bin_collections : [{}] });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        getBinCollectionData(param: any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/binsCollections/${param}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ binCollection: data });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        getTruckActualRoute(site_plan_id,tablet_id){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            return this.http.get(`${url}/getTruckActualRoute/${site_plan_id}/${tablet_id}`,{headers:getHeaders()});
        }

        removeBinEvents(dataToSend: any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/RemoveBinEvents`, body,{headers:postHeaders()});            
        }

        getBinEvents(bin_id: number){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let filteredInfo = [];
            this.http
            .get(`${url}/getBinEvents/${bin_id}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    _.each(data.bin_events_data, (info) => {
                        filteredInfo.push(info);                     	
                    });
                    this.apiStore.update({ binEvents: filteredInfo });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        getSiteInfo(site_id: any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let filteredInfo = {};
            this.http
            .get(`${url}/getSiteInfo/${site_id}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    filteredInfo = data;
                    if(_.isEmpty(filteredInfo)){
                        filteredInfo = {};
                    }
                    this.apiStore.update({ siteInfo: filteredInfo });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        setBinThresholdsAndRestrictions(dataToSend: any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/setBinThresholdsAndRestrictions`, body,{headers:postHeaders()});   
        }

        isChangePaaswordTokenValid(dataToSend: any){
            const body = dataToSend;
            this.http
            .post(`${url}/isChangePaaswordTokenValid`,body, {headers:postHeaders()})
            .pipe(
                tap((data:any) => {     
                    this.apiStore.update({ validation: data.token_validity_status_id });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            ); 
        }

        updateUserPassword(dataToSend: any){
            const body = dataToSend;
            this.http
            .post(`${url}/updateUserPassword`,body, {headers:postHeaders()})
            .pipe(
                tap((data:any) => {     
                    this.apiStore.update({ updatedPassword: data.password_update_status_id });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            ); 
        }

        forgotMyPassword(dataToSend : object = {}) {
            const body = dataToSend;                                                        
            this.http
            .post(`${url}/forgotMyPassword`,body,{headers:postHeaders()})
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        getNextClusterIdInDb(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }          
            this.http
            .get(`${url}/GetNextClusterId`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ nextClusterIdInDb: data });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );  
        }

        putNewBinClusterId(binId, newCluserId){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/PutNewBinClusterId/${binId}/${newCluserId}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ putNewBinClusterIdResponse: data });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        getWasteTypesList(){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/wasteTypes`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ wasteTypesList: (!_.isEmpty(data)) ? data : null });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        validateDeviceId(bin_id, device_id){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/validateDeviceId/${bin_id}/${device_id}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ deviceIdState: data });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        getBinLastTransId(bin_id: any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/getBinLastTransID/${bin_id}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ binLastTransId: data });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        getLatLngExists(lat: any, lng: any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/getLatLngExists/${lat}/${lng}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ latLngExistsResponse: data });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        getGmtTimeZoneByLatLng(lat: any, lng: any){            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/getGmtTimeZoneByLatLng/${lat}/${lng}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ gmtTimeZone: (!_.isEmpty(data)) ? data : null });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        setInstallationStatus(bin_id, new_installation_status){            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/setInstallationStatus/${bin_id}/${new_installation_status}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ installationStatusResponse: data });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        getTransErrorTrace(bin_id, trans_id){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/getTransErrorTrace/${bin_id}/${trans_id}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ transErrorTrace: data });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        performRestoreSwapDevices(binId: any, isFastInstallation: any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/restoreSwapDevices/${binId}/${isFastInstallation}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ performRestoreSwapDevices: data });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        getDeviceIdState(device_id: any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/CheckIfDeviceIdEx/${device_id}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ deviceIdStatus: data });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        performBinReset(bin_id: any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/ResetBin/${bin_id}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ binResetResponse: data });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        getBinInfo(bin_id: number){            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/getBinsInfo/${bin_id}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ binInfo: data.dataBins.length > 0 ? data.dataBins[0] : null });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        getSiteClustersList(site_id: any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.http
            .get(`${url}/GetClusterBySite/${site_id}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    this.apiStore.update({ siteClustersList: data });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        postPreMappingInfo(dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/postPreMappingInfo`, body,{headers:postHeaders()});
        }

        setBinIsNotHere(dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/setBinIsNotHere`, body,{headers:postHeaders()});
        }

        getBinsNeighborhoodList(dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/GetBinsNeighborhoodList`, body,{headers:postHeaders()});
        }

        getPreMappingBinsNeighborhoodList(dataToSend : object = {}){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/GetPreMappingBinsNeighborhoodList`, body,{headers:postHeaders()});
        }

        cleanBinTrans(dataToSend : object = {}){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/cleanBinTrans`, body,{headers:postHeaders()});
        }

        removeRecordFromPreMappingTable(binLetter: any, binNumber: any, siteId: number) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            return this.http.get(`${url}/removeRecordFromPreMappingTable/${binLetter}/${binNumber}/${siteId}`,{headers:getHeaders()});
        }

        getNextPreMappingSticker(siteId: number) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            return this.http.get(`${url}/getNextPreMappingSticker/${siteId}`,{headers:getHeaders()});
        }

        updatePreMappingBinComment(dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/updatePreMappingBinComment`, body,{headers:postHeaders()});
        }

        getTrucksTabletsInfo(dataToSend:any,sites?:any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let filteredInfo = [];
            const body = dataToSend;
            this.apiStore.update({ filteredTrucks: [] });	
            this.http
            .post(`${url}/TrucksTabletsInfo`,body, {headers:postHeaders()})
            .pipe(
                tap((data:any) => {                        
                    _.each(data.TruckTabletInfo, (truck) => {
                        if(sites){
                            if (sites.some(site => site.site_id == truck.site_id)) {
                                filteredInfo.push(truck);
                            }
                        }else{
                            filteredInfo.push(truck);
                        }	
                    });
                    if(filteredInfo.length == 0){
                        filteredInfo.push({});
                    }
                    this.apiStore.update({ filteredTrucks: filteredInfo });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );           
        }

        getWorkPlanAnalysis(dataToSend:any,sites?:any){            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let allfilteredInfo = [];                   
            let filteredInfo = [];
            const body = dataToSend;
            if(this.apiStore.getValue()["allBinCapacityAnalysis"].length == 0){
                this.http
                .post(`${url}/GetWorkPlanAnalysis`,body, {headers:postHeaders()})
                .pipe(
                    tap((data:any) => {           
                        _.each(data.WorkPlanAnalysisInfo, (info) => {
                            allfilteredInfo.push(info);
                            if(sites){
                                if (sites.some(site => site.site_id == info.site_id)) {
                                    filteredInfo.push(info);
                                }
                            }else{
                                filteredInfo.push(info);
                            }	
                        });
                        if(filteredInfo.length == 0){
                            filteredInfo.push({});
                        }
                        this.apiStore.update({ allBinCapacityAnalysis: allfilteredInfo });
                        this.apiStore.update({ binCapacityAnalysis: filteredInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                ); 
            }else{                
                _.each(this.apiStore.getValue()["allBinCapacityAnalysis"], (info) => {  
                    if(sites){
                        if (sites.some(site => site.site_id == info["site_id"])) {																					
                            filteredInfo.push(info);
                        }
                    }else{
                        filteredInfo.push(info);
                    }                    
                });              
                if(filteredInfo.length == 0){
                    filteredInfo.push({});
                }          
                this.apiStore.update({ binCapacityAnalysis: filteredInfo });                    
            }
        }

        getfilteredBinsInfo(sites?:any) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }          
            let filteredInfo = [];     
            let allfilteredInfo = [];                   
            if(this.apiStore.getValue()["allFilteredBins"].length == 0){
                this.http
                .get(`${url}/getBinsInfo`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.dataBins, (bin) => {	
                            allfilteredInfo.push(bin);
                            if(sites){
                                if (sites.some(site => site.site_id == bin.site_id)) {																					
                                    filteredInfo.push(bin);
                                } 
                            }else{
                                filteredInfo.push(bin);
                            }                          
                        });
                        this.apiStore.update({ allFilteredBins: allfilteredInfo });                    
                        this.apiStore.update({ filteredBins: filteredInfo });                    
                    })                    
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                
                _.each(this.apiStore.getValue()["allFilteredBins"], (bin) => {  
                    if(sites){
                        if (sites.some(site => site.site_id == bin["site_id"])) {																					
                            filteredInfo.push(bin);
                        } 
                    }else{
                        filteredInfo.push(bin);
                    }                    
  
                });              
                this.apiStore.update({ filteredBins: filteredInfo });                    
            }                                         
        }

        getfilteredUsersInfo(sites?:any) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }          
            let filteredInfo = [];     
            this.http
            .get(`${url}/usersInfo`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    _.each(data.usersData, (user) => {	
                        if(user["user_role_id"] != 5){                            
                            _.each(user.user_sites_Info, (siteInfo) => {	
                                if(sites){
                                    if (sites.some(site => site.site_id == siteInfo.site_id)) {	
                                        filteredInfo.push({...user});
                                        return false;
                                    }    
                                }else{
                                    let check = filteredInfo.filter(obj => {
                                        return obj.user_id == user.user_id;
                                    });
                                    if(check.length > 0){
                                        return false;
                                    }else{
                                        filteredInfo.push({...user});
                                    }
                                }                                                                                      
                            });                            
                        }                                      
                    });
                    if(filteredInfo.length == 0){
                        filteredInfo.push({});
                    }
                    this.apiStore.update({ filteredUsers: filteredInfo });                    
                })                    
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );                                            
        }

        GetBinManagmentInfo(siteId:any) {
            if(!siteId || !localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }          
            let filteredInfo = [];      
            this.apiStore.update({ filteredBinManagmentInfo: [] });
            this.http
            .get(`${url}/GetBinManagmentInfo/${siteId}`, {headers:getHeaders()})
            .pipe(
                tap((data:any) => {
                    if(data["binsParams"].length > 0){
                        _.each(data["binsParams"], (param) => {	
                            _.each(param.siteBins, (bin) => {	
                                filteredInfo.push(bin);
                            });
                        });
                    }
                    this.apiStore.update({ filteredBinManagmentInfo: filteredInfo });
                })
            )
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );  
        }

        getNextFreeStickers(siteId:any, numberOfStickers:any, binName?:any){         
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }      
            if(binName){
                return this.http.get(`${url}/getNextFreeStickers/${siteId}/${numberOfStickers}/${binName}`,{headers:getHeaders()});
            }else{
                return this.http.get(`${url}/getNextFreeStickers/${siteId}/${numberOfStickers}`,{headers:getHeaders()});
            }
        }

        getfilteredBasicBinsInfo(sites?:any) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                      
            let filteredInfo = [];
            let allInfo = [];                        
            if(this.apiStore.getValue()["allFilteredBasicBins"].length == 0){                
                this.http
                .get(`${url}/getBasicBinsInfo`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.dataBins, (bin) => {	
                            allInfo.push(bin);
                            if(sites){
                                if (sites.some(site => site.site_id == bin.site_id)) {																					
                                    filteredInfo.push(bin);
                                }  
                            }else{
                                filteredInfo.push(bin);
                            }                         
                        });
                        this.apiStore.update({ allFilteredBasicBins: allInfo });                    
                        this.apiStore.update({ filteredBasicBins: filteredInfo });                    
                    })                    
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                                
                _.each(this.apiStore.getValue()["allFilteredBasicBins"], (bin) => {   
                    if(sites){
                        if (sites.some(site => site.site_id == bin["site_id"])) {																					
                            filteredInfo.push(bin);
                        }   
                    }else{
                        filteredInfo.push(bin);
                    }   
                });              
                this.apiStore.update({ filteredBasicBins: filteredInfo });                    
            }               
        }

        sendBinArrival(dataToSend? : any){            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }   
            const body = dataToSend;                                                        
            this.http
            .post(`${url}/binArrival`,body,{headers:postHeaders()})
            .pipe(
                tap((data:any) => {        
                    console.log("internet connected")
                    this.isInternetConnected = true;
                    console.log("binsArrivalArray",this.binsArrivalArray);
                    if (this.binsArrivalArray.length > 0) {
                        this.binsArrivalArray.forEach(binArrival => {
                          this.sendBinArrival(binArrival);
                        });
                    }
                    this.binsArrivalArray = []; 
                })
            )
            .subscribe(
                () => {},
                err => {
                    console.log("No internet connection");
                    this.binsArrivalArray.push(dataToSend);
                    this.isInternetConnected = false;
                    this.noInternetWatcher();
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );
        }

        noInternetWatcher() {
            if (this.noInternetTimeInterval) return;
      
            if (!this.offlineModeEvent.is_enabled) {
              this.noInternetTimeInterval = setInterval(() => {
                if (this.isInternetConnected) {
                  this.clearNoInternetWatcher();
                }
                else {
                  this.noInternetTimeCounter++;
                }
      
                if (this.noInternetTimeCounter >= 60) {
                  this.offlineModeEvent.is_enabled = true;
                  this.offlineModeEvent.is_triggered_by_user = false;      
                  this.clearNoInternetWatcher();
                }
              }, 1000);
            }
        }

        clearNoInternetWatcher() {
            if (this.noInternetTimeInterval) {
              clearInterval(this.noInternetTimeInterval);
              this.noInternetTimeInterval = undefined;
            }
      
            this.noInternetTimeCounter = 0;
            this.internetTimeCounter = 0;
            this.isInternetConnected = true;
      
            if (this.internetTimeInterval) return;
      
            if (this.offlineModeEvent.is_enabled) {
              this.internetTimeInterval = setInterval(() => {
                if (!this.isInternetConnected) {
                  this.internetTimeCounter = 0;
                }
                else {
                  this.internetTimeCounter++;
                }
      
                if (this.internetTimeCounter >= 60) {
                  this.offlineModeEvent.is_enabled = false;
                  this.offlineModeEvent.is_triggered_by_user = false;
      
                  clearInterval(this.internetTimeInterval);
                  this.internetTimeInterval = undefined;
                }
              }, 1000);
            }
          }

        sendBinArrivalWithoutWorkPlan(dataToSend : any){            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }     
            const body = dataToSend;                                                        
            this.http
            .post(`${url}/binArrivalWithoutWorkPlan`,body,{headers:postHeaders()})
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            );   
        }

        sendExceptionCurrentLocation(dataToSend : any){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }     
            const body = dataToSend;                                                        
            this.http
            .post(`${url}/exceptionHandler`,body,{headers:postHeaders()})
            .subscribe(
                () => {},
                err => {
                    if(err.error != null && err.error.toString().includes("expired")){
                        this.tokenExpired();
                    }
                }
            ); 
        }

        createWorkplan(dataToSend : any) {            
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }       
            const body = dataToSend;
            return this.http.post(`${url}/dailyWorkPlan`, body,{headers:postHeaders()});
        }

        getDailyWorkPlansAccordingToDate(start_date: any,end_date:any,flag:any) {      
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const params = `date/?${start_date}?${end_date}/${flag}`;         
            return this.http.get(`${url}/dailyWorkPlan/${params}`,{headers:getHeaders()}).toPromise();                  
        }

        getDailyWorkPlanCalanderData(start_date: any,end_date:any,flag:any,sites?:any) {      
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let DailyWorkPlan = [];    
            let allDailyWorkPlan = [];             
            const params = `date/?${start_date}?${end_date}/${flag}`;                 
            if(this.apiStore.getValue()["allDailyWorkPlanCalanderData"].length == 0){
                this.http
                .get(`${url}/GetDailyWorkPlanCalanderData/${params}`,{headers:getHeaders()})
                .pipe(
                    tap((data:any) => {                        
                        _.each(data.Daily_work_plans_info, (workPlan) => {
                            allDailyWorkPlan.push(workPlan)		
                            if(sites){
                                if (sites.some(site => site.site_id == workPlan["site_id"])) {	
                                    DailyWorkPlan.push(workPlan);																				
                                }  
                            }else{
                                DailyWorkPlan.push(workPlan);																				
                            }                     
                        });

                        if(DailyWorkPlan.length == 0){
                            DailyWorkPlan.push({});
                        }

                        this.apiStore.update({ allDailyWorkPlanCalanderData: allDailyWorkPlan });
                        this.apiStore.update({ dailyWorkPlanCalanderData: DailyWorkPlan });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                                
                _.each(sites, (site) => {		
                    _.each(this.apiStore.getValue()["allDailyWorkPlanCalanderData"], (workPlanSite) => {		
                        if (workPlanSite["site_id"] == site.site_id) {	
                            DailyWorkPlan.push(workPlanSite);
                        }
                    });
                });

                
                if(DailyWorkPlan.length == 0){
                    DailyWorkPlan.push({});
                }

                this.apiStore.update({ dailyWorkPlanCalanderData: DailyWorkPlan });
            }                
        }

        dailyWorkPlanInfo(workplan: any) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let DailyWorkPlanInfo = [];    
            let id = workplan["plan"].Site_plan_id
            this.apiStore.update({ workPlanInfo: [] });
            this.http
                .get(`${url}/dailyWorkPlan/${id}`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.Daily_work_plans, (workPlan) => {		
                            _.each(workPlan.Sites_Daily_work_plan, (workPlanSite) => {
                                let obj = {
                                    dateToSort : workplan.dateToSort,
                                    finish : workplan.finish,
                                    icon : workplan.icon,
                                    plan : workPlanSite,
                                    scheduleTxt : workplan.scheduleTxt,
                                    start : workplan.start,
                                    title : workplan.title,
                                    userGenerated : workplan.userGenerated
                                }
                                DailyWorkPlanInfo.push(obj)		
                            });
                        });
                        if(DailyWorkPlanInfo.length == 0){
                            DailyWorkPlanInfo.push({});
                        }
                        this.apiStore.update({ workPlanInfo: DailyWorkPlanInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        removeBinFromExistingWorkPlan = (dataToSend: any) => {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/removeBinFromExistingWorkPlan `, body,{headers:postHeaders()});
        }

        swapBinDevices = (dataToSend: any) => {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/swapBinDevices `, body,{headers:postHeaders()});
        }

        addBinToExistingWorkPlan = (dataToSend: any) => {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/addBinToExistingWorkPlan`, body,{headers:postHeaders()});
        }

        uploadPictureToAmazonBucket = (files: File[],id : any,itemId : any) => {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }            
            const formData = new FormData();
            formData.append('file',files[0]);            
            return this.http.post(`${url}/uploadPictureToAmazonBucket/${id}/${itemId}`,formData,{headers:postHeadersFile()});
        }

        registerPicture = (dataToSend: any) => {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/registerPicture`, body,{headers:postHeaders()});
        }

        forceBinsToWorkPlan = (dataToSend: any) => {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/ForceBinsToWorkPlan `, body,{headers:postHeaders()});
        }

        cancelForceBinsToWorkPlan = (dataToSend: any) => {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/CancelForceBinsToWorkPlan `, body,{headers:postHeaders()});
        }

        getfilteredActivePlans(sites?:any) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let DailyWorkPlan = [];     
            let allDailyWorkPlan = [];       
            if(this.apiStore.getValue()["allFilteredDailyWorkPlan"].length == 0){
                this.http
                .get(`${url}/dailyWorkPlan/currentDayActivePlan/1`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {                              
                        if(data.Daily_work_plans.length > 0){
                            _.each(data.Daily_work_plans, (workPlan) => {		
                                _.each(workPlan.Sites_Daily_work_plan, (workPlanSite) => {
                                    allDailyWorkPlan.push(workPlanSite);	
                                    if(sites){
                                        if (sites.some(site => site.site_id == workPlanSite["site_id"])) {	
                                            workPlanSite.plan_id = workPlan.plan_id;																	
                                            DailyWorkPlan.push(workPlanSite);																				
                                        }  
                                    }else{
                                        workPlanSite.plan_id = workPlan.plan_id;																	
                                        DailyWorkPlan.push(workPlanSite);	
                                    }	                                                                       
                                });
                            });
                        }
                        else{
                            allDailyWorkPlan.push({});
                            DailyWorkPlan.push({});
                        }    
                        this.apiStore.update({ allFilteredDailyWorkPlan: allDailyWorkPlan });    
                        this.apiStore.update({ filteredDailyWorkPlan: DailyWorkPlan });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{                                
                _.each(this.apiStore.getValue()["allFilteredDailyWorkPlan"], (workPlan) => {                      
                    if(sites){
                        if (sites.some(site => site.site_id == workPlan["site_id"])) {																					
                            DailyWorkPlan.push(workPlan);
                        }  
                    }else{
                        DailyWorkPlan.push(workPlan);
                    }
                });     
                if(DailyWorkPlan.length == 0){
                    this.apiStore.update({ filteredDailyWorkPlan: [{}] });
                }else{
                    this.apiStore.update({ filteredDailyWorkPlan: DailyWorkPlan });
                } 
            }      
                                   
        }

        getSitesList(sites:any, siteManagementFlag?) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let sitesToDisplay = [];     
            let allSitesToDisplay = [];
            let fullUrl = "/getSitesList";
            if (siteManagementFlag) fullUrl += "/1";             
            if(this.apiStore.getValue()["allSitesListData"].length == 0 || siteManagementFlag === 1){                                
                this.http
                .get(`${url}` + fullUrl, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.SitesInfo, (siteData) => {
                            allSitesToDisplay.push(siteData);	
                            if(sites != null){
                                if (sites.some(site => site.site_id == siteData["site_id"])) {	
                                    sitesToDisplay.push(siteData);																				
                                } 
                            }else{
                                sitesToDisplay.push(siteData);																				
                            }
                        });
                        if(sites == null){
                            this.apiStore.update({ allSitesListData: allSitesToDisplay });   
                        }                                                        
                        this.apiStore.update({ sitesListData: sitesToDisplay });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
            }else{           
                _.each(this.apiStore.getValue()["allSitesListData"], (siteData) => {  
                    if(sites != null){
                        if (sites.some(site => site.site_id == siteData["site_id"])) {																					
                            sitesToDisplay.push(siteData);
                        }  
                    }else{
                        sitesToDisplay.push(siteData);
                    }    
                });      
                        
                this.apiStore.update({ sitesListData: sitesToDisplay });

            }              
        }

        
        SetBinFillStatus (dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/SetBinFillStatus `, body,{headers:postHeaders()});
        }

        UpdateTrucksTabletsInfo (dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/UpdateTrucksTabletsInfo `, body,{headers:postHeaders()});
        }

        setSiteParams (dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/SetSiteParams `, body,{headers:postHeaders()});
        }

        overwritePreMappingBin(dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/OverwritePreMappingBin `, body,{headers:postHeaders()});
        }

        SwapDevices(dataToSend : object = {}){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/SwapDevices `, body,{headers:postHeaders()});  
        }

        setNewLifeCycleAction (dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/SetNewLifeCycleAction `, body,{headers:postHeaders()}).toPromise();
        }

        setBinRegistrationStatus(dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/SetBinRegistrationStatus`, body,{headers:postHeaders()});
        }

        insertNewSite(dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/InsertNewSite`, body,{headers:postHeaders()});
        }

        insertNewbin(dataToSend : object = {}){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/insertbin`, body,{headers:postHeaders()}).toPromise();
        }

        insertNewTicket(dataToSend : object = {}){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/insertNewTicket`, body,{headers:postHeaders()});
        }

        insertNewUser(dataToSend : object = {}){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/CreateNewUser`, body,{headers:postHeaders()});
        }

        insertNewTruckTabletInfo(dataToSend : object = {}){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/InsertNewTruckTabletInfo`, body,{headers:postHeaders()});
        }

        updateUserParams(dataToSend : object = {}){
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/UpdateUserParams`, body,{headers:postHeaders()});
        }

        getUserRoles() {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let accounts = []; 
            this.apiStore.update({ userRoles: [] });
            this.http
                .get(`${url}/GetUserRoles`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.UserRoless, (account) => {
                            accounts.push(account);																				                                                                                                     
                        });                                                    
                        this.apiStore.update({ userRoles: accounts });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        getTabletNavigationInfo(tablet_id) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            this.apiStore.update({ navigationInfo: {} });
            this.http
                .get(`${url}/tabletNavigationInfo/${tablet_id}`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {                                               
                        this.apiStore.update({ navigationInfo: data.tabletNavInfo });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        getUserinfo() {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let accounts = []; 
            this.apiStore.update({ accountsList: [] });
            this.http
                .get(`${url}/userinfo`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.user.user_accounts_info, (account) => {
                            accounts.push(account);																				                                                                                                     
                        });                                                    
                        this.apiStore.update({ accountsList: accounts });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        getCustomerAccounts() {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let accounts = []; 
            this.apiStore.update({ customerAccounts: [] });
            this.http
                .get(`${url}/customerAccounts`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.Customer_account, (account) => {
                            accounts.push(account);																				                                                                                                     
                        });                                                    
                        this.apiStore.update({ customerAccounts: accounts });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        getRemovedBinFromExistingWorkPlanReasonType() {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            let types = []; 
            this.apiStore.update({ reasonTypes: [] });
            this.http
                .get(`${url}/GetRemovedBinFromExistingWorkPlanReasonType`, {headers:getHeaders()})
                .pipe(
                    tap((data:any) => {
                        _.each(data.ReasonTypesObj, (reason) => {
                            if(reason.removedBinReason_id == 1 || reason.removedBinReason_id == 3 || reason.removedBinReason_id == 7 || 
                                reason.removedBinReason_id == 8 || reason.removedBinReason_id == 9 || reason.removedBinReason_id == 10
                                || reason.removedBinReason_id == 14){
                                    types.push(reason);																				                                                                                                     
                                }
                        });                                                    
                        this.apiStore.update({ reasonTypes: types });
                    })
                )
                .subscribe(
                    () => {},
                    err => {
                        if(err.error != null && err.error.toString().includes("expired")){
                            this.tokenExpired();
                        }
                    }
                );
        }

        exceptionHandler(site_plan_id,tablet_id){                        
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }                      
            return this.http.get(`${url}/exceptionHandler/?site_plan_id=${site_plan_id}?tablet_id=${tablet_id}`,{headers:getHeaders()}).toPromise();
        }

        postGeolocationAddresses(dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/postGeolocationAddresses`, body,{headers:postHeaders()});
        }


        getGoogleAddressObjFromAddress(dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/getGoogleAddressObjFromAddress`,body, {headers:postHeaders()});
        }

        getGoogleDirectionRoute(dataToSend : object = {}) {
            if(!localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN)){
                return;
            }
            const body = dataToSend;
            return this.http.post(`${url}/getGoogleDirectionRoute`, body,{headers:postHeaders()}).toPromise();
        }

        tokenExpired() {
            //emptying bin-status component
            this.apiStore.update({ allFilteredDailyWorkPlan: [] });
            this.apiStore.update({ filteredDailyWorkPlan: [] });
    		this.apiStore.update({ allCollectionsStatsForWorkplanProgres: [] });                
            this.apiStore.update({ CollectionsStatsForWorkplanProgres: [] }); 
            
            //emptying active-bins component
            this.apiStore.update({ allFilteredBasicBins: [] });                    
            this.apiStore.update({ filteredBasicBins: [] }); 
    
            //emptying collection-kpi components
            this.apiStore.update({ allCollectionsKpis: [] });
            this.apiStore.update({ collectionsKpis: [] });
    
            //emptying collection-pie component
            this.apiStore.update({ collectionsStatsInCollections: [] });
            this.apiStore.update({ allCollectionsStats: [] });
            this.apiStore.update({ collectionsStats: [] });
            this.apiStore.update({ allCollectionsStatsToday: [] });
            this.apiStore.update({ collectionsStatsToday: [] });
    
            //emptying refill-kpi components
            this.apiStore.update({ allRefillsKpis: [] });
            this.apiStore.update({ refillsKpis: [] });

            //emptying refills-table components
            this.apiStore.update({ allRefillsStats: [] });
            this.apiStore.update({ refillsStats: [] });

            //emptying alert-history component
            this.apiStore.update({ userEventsByType: [] });
    
            //emptying inventory component 
            this.apiStore.update({ allFilteredBins: [] });                    
            this.apiStore.update({ filteredBins: [] });
    
            //emptying volume component           
            this.apiStore.update({ collectionVolume: [] });
    
            //emptying exception-list component
            this.apiStore.update({ exceptionsReports: [] });
    
            //emptying workPlanScheduler 
            this.apiStore.update({ allWorkPlanScheduler: [] });
            this.apiStore.update({ workPlanScheduler: [] });
    
            //document.location.href="/";
            this.router.navigate(['/login']);		
            
            localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.USER);
            
            localStorage.removeItem('language');
            sessionStorage.removeItem('fillingsMinDate');
            sessionStorage.removeItem('fillingsMaxDate');
            sessionStorage.removeItem('fillingsStartDate');
            sessionStorage.removeItem('fillingsEndDate');
        
            sessionStorage.removeItem('collectionsMinDate');
            sessionStorage.removeItem('collectionsMaxDate');
            sessionStorage.removeItem('collectionsStartDate');
            sessionStorage.removeItem('collectionsEndDate');
    
            sessionStorage.removeItem('reportsMinDate');
            sessionStorage.removeItem('reportsMaxDate');
            sessionStorage.removeItem('reportsStartDate');
            sessionStorage.removeItem('reportsEndDate');

            sessionStorage.removeItem('ticketManagementMinDate');
            sessionStorage.removeItem('ticketManagementMaxDate');
            sessionStorage.removeItem('ticketManagementStartDate');
            sessionStorage.removeItem('ticketManagementEndDate');

            sessionStorage.removeItem('insightsMinDate');
            sessionStorage.removeItem('insightsMaxDate');
            sessionStorage.removeItem('insightsStartDate');
            sessionStorage.removeItem('insightsEndDate');
            sessionStorage.removeItem('blackStatusBin');
        }
}
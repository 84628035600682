@if (!isMobile) {
  <div class="force-bin-collection">
    <div class='drop-down-main-wrapper add-remove-bins' rtlDiv>
      <div class="drop-down-list-container add-remove-bins bin-container-rtl" rtlDiv>
        <div class="add-bins-title">{{'WORKPLAN.ADD_BIN_TO_FUTURE_WORKPLAN' | translate}}</div>
        <div class="change-bin-to-collect">
          <form [formGroup]="ElForm">
            <div class="bin-name-container">
              <div class="sub-title">{{ 'SITE_MANAGMENT.SITE_NAME' | translate }}</div>
              <select class="bin-name-input distrebution-Methods" formControlName="siteName" (change)="getSiteChosen()">
                @for (site of siteList; track site) {
                  <option [value]="site['site_id']">
                    {{site['site_name']}}
                  </option>
                }
              </select>
            </div>
          </form>
          <div class="search-bin-container">
            <input class="search-input" placeholder="{{'OTHER.SEARCH' | translate }}"
              (input)="applyFilter($event.target['value'])" />
            <img class='search-icon' src="assets/images/reports/dropdown_search_icon.svg" alt="" rtlDiv />
          </div>
          <div class="popup-content" rtlDiv>
            <div class='bins-list-table activeBins' rtlDiv>
              <div class="change-bin-table-frame">
                <span class="change-bin-table-title">{{'WIDGETS.ALL_BINS' | translate}}</span>
                <span class="total-bins">{{'TITLES.TOTAL_BINS' | translate}} - {{filteredData.length}}</span>
              </div>
              <div class="outside-table-border">
                <table class="table" mat-table #table [dataSource]="getAllBinsTableData()" matSort matSortStart="asc" matSortActive="bin_name"
                  matSortDisableClear (matSortChange)="sortAddBinsData($event)" matSortDirection="desc">
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef class="cut-change-bin-txt">
                    </th>
                    <td mat-cell *matCellDef="let row" class="list-checkbox cut-change-bin-txt" rtlDiv>
                      <mat-checkbox class="direction" (click)="$event.stopPropagation()" (change)="$event ? selectionAddBins.toggle(row) : null" [checked]="selectionAddBins.isSelected(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="cut-change-bin-txt"> {{
                    'LIST_COLUMNS.NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv> <span title="{{element ? element.bin_name : ''}}">{{element ? element.bin_name : ''}}</span></td>
                  </ng-container>
                  <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="cut-change-bin-txt">{{ 'LIST_COLUMNS.ADDRESS'
                    | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv><span title="{{element ? element.bin_address : ''}}">{{element ? element.bin_address: ''}}</span>  </td>
                  </ng-container>
                  <ng-container matColumnDef="neighborhood">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_neighborhood" class="cut-change-bin-txt" rtlDiv>
                    {{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv><span title="{{element ? element.bin_neighborhood : ''}}">{{element ? element.bin_neighborhood: ''}}</span>  </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_status" class="cut-change-bin-txt">{{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="cut-change-bin-txt"> {{element ? element.bin_status: ''}}% </td>
                  </ng-container>
                  <ng-container matColumnDef="collection_mode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="collection_mode" class="cut-change-bin-txt"> {{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="cut-change-bin-txt">
                      <img class='search-input-img' src="{{element ? getImg(element.collection_mode) : ''}}" alt="" />
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="predicted_time_for_max_allowed_capacity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="predicted_time_for_max_allowed_capacity_value" class="cut-change-bin-txt">{{ 'LIST_COLUMNS.FULLNESS_FORECAST_DATE' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="cut-change-bin-txt"> 
                      {{currentLang == 'iw' ? element.predicted_time_for_max_allowed_capacity_he: element.predicted_time_for_max_allowed_capacity}} 
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectionAddBins.toggle(row)"></tr>
                </table>
              </div>
            </div>
            <div class="add-remove-arrows" rtlDiv>
              @if (currentLang != 'iw') {
                <div>
                  <img (click)="moveBinToInside()" class="right-arrow" rtlDiv src="../assets/images/workplans-page/right.svg" alt="right_icon">
                </div>
              }
              @if (currentLang != 'iw') {
                <div>
                  <img (click)="moveBinToOutside()" class="left-arrow" rtlDiv src="../assets/images/workplans-page/left.svg" alt="left_icon">
                </div>
              }
              @if (currentLang == 'iw') {
                <div>
                  <img (click)="moveBinToInside()" class="right-arrow" rtlDiv src="../assets/images/workplans-page/left.svg" alt="right_icon">
                </div>
              }
              @if (currentLang == 'iw') {
                <div>
                  <img (click)="moveBinToOutside()" class="left-arrow" rtlDiv src="../assets/images/workplans-page/right.svg" alt="left_icon">
                </div>
              }
            </div>
            <div class='bins-list-table removedBins' rtlDiv>
              <div class="change-bin-table-frame">
                <span class="change-bin-table-title active-lbl">{{'TITLES.BINS_TO_COLLECT' | translate}}</span>
                <span class="total-bins">{{'TITLES.TOTAL_BINS' | translate}} - {{filteredBinsToCollectData.length}}</span>
              </div>
              <div class="inside-table-border">
                <table class="table" mat-table #table [dataSource]="getTableData()" matSort matSortStart="asc"
                  matSortActive="bin_name" matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef class="cut-change-bin-txt" rtlDiv>
                      <mat-checkbox class="direction" (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" rtlDiv>
                      </mat-checkbox>
                    </th>
                    <td class="cut-change-bin-txt list-checkbox" mat-cell *matCellDef="let row" rtlDiv>
                      <mat-checkbox class="direction" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" rtlDiv>
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="cut-change-bin-txt" rtlDiv>{{
                    'LIST_COLUMNS.NAME' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv> <span title="{{element ? element.bin_name : ''}}">{{element ? element.bin_name : ''}}</span></td>
                  </ng-container>
                  <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="cut-change-bin-txt" rtlDiv>{{ 'LIST_COLUMNS.ADDRESS'
                    | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv><span title="{{element ? element.bin_address : ''}}">{{element ? element.bin_address: ''}}</span>  </td>
                  </ng-container>
                  <ng-container matColumnDef="neighborhood">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_neighborhood" class="cut-change-bin-txt" rtlDiv>
                    {{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv><span title="{{element ? element.bin_neighborhood : ''}}">{{element ? element.bin_neighborhood: ''}}</span>  </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_status" class="cut-change-bin-txt" rtlDiv>{{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="cut-change-bin-txt" rtlDiv> {{element ? element.bin_status: ''}}% </td>
                  </ng-container>
                  <ng-container matColumnDef="collection_mode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="collection_mode" class="cut-change-bin-txt"> {{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="cut-change-bin-txt">
                      <img class='search-input-img' src="{{element ? getImg(element.collection_mode) : ''}}" alt="" />
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="predicted_time_for_max_allowed_capacity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="predicted_time_for_max_allowed_capacity_value" class="cut-change-bin-txt">{{ 'LIST_COLUMNS.FULLNESS_FORECAST_DATE' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="cut-change-bin-txt"> 
                      {{currentLang == 'iw' ? element.predicted_time_for_max_allowed_capacity_he: element.predicted_time_for_max_allowed_capacity}} 
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class='add-remove-bins-btns-container' rtlDiv>
          <div class='add-remove-bins-btn add' (click)='closeModal()'>
            {{ 'TRUCK_MAP.CANCEL' | translate }}
          </div>
          <div class='add-remove-bins-btn apply' (click)='applyChanges()'> {{ 'TRUCK_MAP.APPLY_CHANGES' |
          translate }}</div>
        </div>
      </div>
    </div>
  </div>
}

@if (isMobile) {
  <div class="mobile-force-bin-collection" rtlDiv>
    <div class='drop-down-main-wrapper' rtlDiv>
      <div class="add-remove-bins" rtlDiv>
        <div class="mobile-modal-title" rtlDiv>
          <div class="dflex" (click)="closeModal()">
            <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
          </div>
          <div>
            <span class="main-title">{{ 'WORKPLAN.ADD_BIN_TO_FUTURE_WORKPLAN' | translate }}</span>
          </div>
        </div>
        <div class="mobile-change-bin-to-collect">
          <form [formGroup]="ElForm">
            <div class="mobile-bin-name-container">
              <div class="mobile-sub-title">{{ 'SITE_MANAGMENT.SITE_NAME' | translate }}</div>
              <select class="mobile-sites-drop-down" formControlName="siteName" (change)="getSiteChosen()">
                @for (site of siteList; track site) {
                  <option [value]="site['site_id']">
                    {{site['site_name']}}
                  </option>
                }
              </select>
            </div>
          </form>
          <div class="mobile-search-bin-container">
            <img class='search-icon' src="assets/images/reports/dropdown_search_icon.svg" alt="" rtlDiv />
            <input class="mobile-search-input" placeholder="{{'OTHER.SEARCH' | translate }}"
              (input)="applyFilter($event.target['value'])" />
          </div>
          @if (selectedTab == 0 && selectionAddBins.selected.length > 0) {
            <div class="mobile-move-container">
              <div class="move-from">{{'WORKPLAN.MOVE' | translate}} {{selectionAddBins.selected.length}} {{'SITE_MANAGMENT.BINS' | translate}} {{'WORKPLAN.TO' | translate}} '{{'TITLES.BINS_TO_COLLECT' | translate}}'</div>
              <div class="move-btn" (click)="moveBinToInside()">
                <div>{{'WORKPLAN.MOVE' | translate}}</div>
              </div>
            </div>
          }
          @if (selectedTab == 1 && selection.selected.length > 0) {
            <div class="mobile-move-container">
              <div class="move-from">{{'WORKPLAN.MOVE' | translate}} {{selection.selected.length}} {{'SITE_MANAGMENT.BINS' | translate}} {{'WORKPLAN.TO' | translate}} '{{'WIDGETS.ALL_BINS' | translate}}'</div>
              <div class="move-btn" (click)="moveBinToOutside()">
                <div>{{'WORKPLAN.MOVE' | translate}}</div>
              </div>
            </div>
          }
          <mat-tab-group mat-stretch-tabs="false" rtlDiv animationDuration="0ms" [selectedIndex]="1" (selectedIndexChange)="myTabSelectedIndexChange($event)">
            <mat-tab label="{{'WIDGETS.ALL_BINS' | translate}} ({{filteredData.length}})">
              <div>
                <div class="spinner-border mobile-spinner" [hidden]="!spinnerActive">
                  <div class="spinner"></div>
                </div>
                <div class="card mobile-card" [hidden]="spinnerActive">
                  <div class="card-body">
                    <div id="totalBins-All">
                      <table class="mobile-table" mat-table [dataSource]="getAllBinsTableDataMobile()" matSort matSortStart="asc"
                        matSortActive="bin_name" multiTemplateDataRows matSortDisableClear (matSortChange)="sortAddBinsData($event)" matSortDirection="desc">
                        <ng-container matColumnDef="select">
                          <th mat-header-cell *matHeaderCellDef class="mobile-th-title"></th>
                          <td mat-cell *matCellDef="let row" class="mobile-td-title" rtlDiv>
                            <mat-checkbox class="direction" (click)="$event.stopPropagation()" (change)="$event ? selectionAddBins.toggle(row) : null" [checked]="selectionAddBins.isSelected(row)">
                            </mat-checkbox>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="mobile-th-title"> {{'LIST_COLUMNS.NAME' | translate }} </th>
                          <td mat-cell *matCellDef="let element" class="mobile-td-title" rtlDiv>
                            <span class="mobile-td" title="{{element ? element.bin_name : ''}}">{{element ? element.bin_name : ''}}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="address">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="mobile-th-title">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</th>
                          <td mat-cell *matCellDef="let element" class="mobile-td-title" rtlDiv>
                            <span class="mobile-td" title="{{element ? element.bin_address : ''}}">{{element ? element.bin_address: ''}}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="arrowDown">
                          <th class="mobile-th-title" mat-sort-header="arrowDown" style="height: 52px;" rtlDiv mat-header-cell *matHeaderCellDef></th>
                          <td class="mobile-td-title" mat-cell *matCellDef="let element">
                            <div rtlDiv class="mobile-status-value">
                              @if (expandedElement !== element) {
                                <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                              }
                              @if (expandedElement === element) {
                                <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                              }
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="expandedDetail">
                          <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" rtlDiv>
                            <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                              <div class="mobile-element-diagram" rtlDiv>
                                <div class="mobile-item-details">
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</div>
                                    <div class="mobile-details-value"> {{element ? element.bin_neighborhood : ''}} </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'LIST_COLUMNS.STATUS' | translate }}</div>
                                    <div class="mobile-details-value"> {{element ? element.bin_status: ''}}% </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</div>
                                    <div class="mobile-details-value"> 
                                      <img class='search-input-img' src="{{element ? getImg(element.collection_mode) : ''}}" alt="" />
                                    </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'LIST_COLUMNS.FULLNESS_FORECAST_DATE' | translate }}</div>
                                    <div class="mobile-details-value"> {{currentLang == 'iw' ? element.predicted_time_for_max_allowed_capacity_he: element.predicted_time_for_max_allowed_capacity}} </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumnsMobile;" (click)="expandedElement = expandedElement === element ? null : element"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                      </table>
                      <div>
                        <app-pagination [chosenBins]="filteredData" (pageChange)='onAllBinsTablePageChange($event)' [filesPerPage]="8"></app-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="{{'TITLES.BINS_TO_COLLECT' | translate}} ({{filteredBinsToCollectData.length}})">
              <div>
                <div class="spinner-border mobile-spinner" [hidden]="!spinnerActive">
                  <div class="spinner"></div>
                </div>
                <div class="card mobile-card" [hidden]="spinnerActive">
                  <div class="card-body">
                    <div id="totalBins-All">
                      <table class="mobile-table" mat-table [dataSource]="getTableDataMobile()" matSort matSortStart="asc"
                        matSortActive="bin_name" multiTemplateDataRows matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
                        <ng-container matColumnDef="select">
                          <th mat-header-cell *matHeaderCellDef class="mobile-th-title" rtlDiv>
                            <mat-checkbox class="direction" (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()" rtlDiv>
                            </mat-checkbox>
                          </th>
                          <td mat-cell *matCellDef="let row" class="mobile-td-title" rtlDiv>
                            <mat-checkbox class="direction" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" rtlDiv>
                            </mat-checkbox>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="mobile-th-title"> {{'LIST_COLUMNS.NAME' | translate }} </th>
                          <td mat-cell *matCellDef="let element" class="mobile-td-title" rtlDiv>
                            <span class="mobile-td" title="{{element ? element.bin_name : ''}}">{{element ? element.bin_name : ''}}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="address">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="mobile-th-title">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</th>
                          <td mat-cell *matCellDef="let element" class="mobile-td-title" rtlDiv>
                            <span class="mobile-td" title="{{element ? element.bin_address : ''}}">{{element ? element.bin_address: ''}}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="arrowDown">
                          <th class="mobile-th-title" mat-sort-header="arrowDown" style="height: 52px;" rtlDiv mat-header-cell *matHeaderCellDef></th>
                          <td class="mobile-td-title" mat-cell *matCellDef="let element">
                            <div rtlDiv class="mobile-status-value">
                              @if (expandedElement !== element) {
                                <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                              }
                              @if (expandedElement === element) {
                                <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                              }
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="expandedDetail">
                          <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsMobile.length" rtlDiv>
                            <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                              <div class="mobile-element-diagram" rtlDiv>
                                <div class="mobile-item-details">
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</div>
                                    <div class="mobile-details-value"> {{element ? element.bin_neighborhood : ''}} </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'LIST_COLUMNS.STATUS' | translate }}</div>
                                    <div class="mobile-details-value"> {{element ? element.bin_status: ''}}% </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</div>
                                    <div class="mobile-details-value"> 
                                      <img class='search-input-img' src="{{element ? getImg(element.collection_mode) : ''}}" alt="" />
                                    </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-details-title">{{ 'LIST_COLUMNS.FULLNESS_FORECAST_DATE' | translate }}</div>
                                    <div class="mobile-details-value"> {{currentLang == 'iw' ? element.predicted_time_for_max_allowed_capacity_he: element.predicted_time_for_max_allowed_capacity}} </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumnsMobile;" (click)="expandedElement = expandedElement === element ? null : element"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                      </table>
                      <div>
                        <app-pagination [chosenBins]="filteredBinsToCollectData" (pageChange)='onTablePageChange($event)' [filesPerPage]="8"></app-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class='main-btns-wrapper' rtlDiv>
          <div class='btns-container' rtlDiv>
            <div class='mobile-cancel_btn' (click)="closeModal()" rtlDiv>{{ 'TRUCK_MAP.CANCEL' | translate }}</div>
            <div [ngClass]="{'mobile-apply-btn':ElForm.getRawValue()['siteName'] != '','mobile-not-apply-btn':ElForm.getRawValue()['siteName'] == ''}" (click)='applyChanges()' rtlDiv>{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
}




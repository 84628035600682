import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslatorService } from '../../../../services/translator_service';
import { API } from '../../../../types.api';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../../directives/rtldiv-directive.directive';

@Component({
  selector: 'app-activation-time-out-dialog',
  standalone: true,
  imports: [TranslateModule,CommonModule,RTLDivDirectiveDirective,MatDialogModule,FormsModule,ReactiveFormsModule],
  templateUrl: './activation-time-out-dialog.component.html',
  styleUrl: './activation-time-out-dialog.component.scss'
})
export class ActivationTimeOutDialogComponent {
  ElForm: FormGroup;
  public installationPhase = API.InstallationPhase;
  currentLang: string;
  translateSub;
  translationsObj;
  constructor(@Inject(MAT_DIALOG_DATA) public timeOutObj: API.ITimOutObj, public fb: FormBuilder,
  private dialogRef: MatDialogRef<ActivationTimeOutDialogComponent>,
  private translator: TranslatorService) {
    this.currentLang = this.translator.getTranslationLanguage();      
    this.translateSub = this.translator.getTranslation(this.currentLang).subscribe(translations => {
      this.translationsObj = translations;
    });
    this.ElForm = this.fb.group({
      deviceId: '',
    });
  }

  ngOnInit() {
    switch (this.timeOutObj.installationPhase) {
			case this.installationPhase.ACTIVATED:
				break;
      case this.installationPhase.INSTALLATION_COMPLETE:
        break;
      case this.installationPhase.PENDING:
        break;
      case this.installationPhase.PENDING_ACTIVATION:
        break;
      case this.installationPhase.NEW_PENDING_ACTIVATION:
        this.ElForm.controls["deviceId"].setValue("");    
        break;
      case this.installationPhase.PENDING_PHYSICAL_INSTALLATION:
        break;
      case this.installationPhase.PENDING_REVIEW:
        break;
      case this.installationPhase.PENDING_SWAP_ACTIVATION:
        break;
      case this.installationPhase.PHYSICALLY_INSTALLED:
        break;
      case this.installationPhase.REVIEW_APPROVED:
        break;
			default:
		}
  }
  
  close() {
    this.dialogRef.close();
  }
}

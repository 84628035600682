import { Component } from '@angular/core';
import moment from 'moment';
import { ApiService } from '../../web-services/api/api.service';
import { TranslatorService } from '../../services/translator_service';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResponsiveService } from '../../services/responsive.service';
import { ApiStore } from '../../web-services/api/api.store';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { BlackStatusPieComponent } from './black-status-pie/black-status-pie.component';
import { MatCardModule } from '@angular/material/card';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { BlackStatusSamplingComponent } from './black-status-sampling/black-status-sampling.component';
import { BlackStatusDurationComponent } from './black-status-duration/black-status-duration.component';
import { BlackStatusListViewComponent } from './black-status-list-view/black-status-list-view.component';
import { DatepickerRangeComponent } from '../../widgets/datepicker-range/datepicker-range.component';

@Component({
  selector: 'app-insights',
  standalone: true,
  imports: [TranslateModule,FormsModule,ReactiveFormsModule,CommonModule,MatDividerModule,BlackStatusPieComponent,
    MatCardModule, RTLDivDirectiveDirective,BlackStatusSamplingComponent,BlackStatusDurationComponent,BlackStatusListViewComponent,
	DatepickerRangeComponent],
  templateUrl: './insights.component.html',
  styleUrl: './insights.component.scss'
})
export class InsightsComponent {
  isMobile: Boolean;
	currentLang;
	translationsObj;
 	showDatePicker: Boolean = false;
	selectedDates: any = {
		from: '',
		to: ''
	};
	minDate = '';
	maxDate = '';
	lastMaxDate:any;
	lastMinDate:any;
	ElForm: FormGroup;
	blackStatusValue:number = 90;
	editBlackStatusFlag:boolean = true;

  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,
    private apiService:ApiService,private apiStore:ApiStore,private fb: FormBuilder) {
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
		this.ElForm = this.fb.group({
			blackStatusBin :new FormControl(90)
		});
   }

  ngOnInit() {
    this.onResize();
	this.responsiveService.checkWidth();
	sessionStorage.setItem("blackStatusBin","90");
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  submitBlackStatus(){
	if(this.ElForm.getRawValue()['blackStatusBin'] < 50 || this.ElForm.getRawValue()['blackStatusBin'] > 100){
		return;
	}else{
		this.blackStatusValue = this.ElForm.getRawValue()['blackStatusBin'];
		this.editBlackStatusFlag = true;
		sessionStorage.setItem("blackStatusBin",this.ElForm.getRawValue()['blackStatusBin']);
		this.getBinCapacityAnalysis(this.minDate,this.maxDate);		
	}
  }

  editBlackStatus(){
	this.editBlackStatusFlag = false;
  }

  showHideDatePicker = () => {
    this.showDatePicker = !this.showDatePicker;
  }

  onSelectDates = (selectDates: any) => {
	setTimeout(() => {
		this.showDatePicker = false;
		this.selectedDates.from = selectDates.fromDate;
		this.selectedDates.to = selectDates.toDate;		
		this.minDate = moment(this.selectedDates.from, 'DD.MM.YYYY').format('YYYY-MM-DD 00:00:01');
		this.maxDate = moment(this.selectedDates.to, 'DD.MM.YYYY').format('YYYY-MM-DD 23:59:59');
		sessionStorage.setItem("insightsMinDate",this.minDate);
		sessionStorage.setItem("insightsMaxDate",this.maxDate);

		sessionStorage.setItem("insightsStartDate",this.minDate);
		sessionStorage.setItem("insightsEndDate",this.maxDate);

		let collectionsStatsRequest = {
			site_id : 0,
			start_date : this.minDate,
			end_date : this.maxDate
		}

		if(this.maxDate != this.lastMaxDate || this.minDate != this.lastMinDate){
 			this.getBinCapacityAnalysis(this.minDate,this.maxDate);	
			this.apiStore.update({ CollectionsStatsForInsights: [] });	
			this.apiService.getfilteredBinsCollectionsStats(collectionsStatsRequest,4,JSON.parse(sessionStorage.getItem("chosenSites")))
		}
	}, 0);		
  }

  getBinCapacityAnalysis(start,end){
	let requestWorkPlanAnalysis = {
		sites_list : [],
		capacity_in_percent :this.ElForm.getRawValue()['blackStatusBin'],
		start_date : start,
		end_date : end
	}
	this.apiStore.update({ allBinCapacityAnalysis: [] });
	this.apiStore.update({ binCapacityAnalysis: [] });
	this.apiService.getWorkPlanAnalysis(requestWorkPlanAnalysis,JSON.parse(sessionStorage.getItem("chosenSites")));
	this.lastMinDate = start;
	this.lastMaxDate = end;
  }
}

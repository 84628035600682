@if (!isMobile) {
  <div class="padded reports-page">
    <div class="dashboard-header">
      <div class='dashboard-header-left' rtlDiv>
        <div class='header-bold'>{{ 'WIDGETS.FILLINGS' | translate }}</div>
      </div>
      <div class='datepicker-wrapper' rtlDiv>
        <div class='selected-dates' (click)='showHideDatePicker()'>
          <img [ngClass]="{'transform-arrow' : currentLang == 'iw'}" src="assets/images/dashboard/Previous.svg" alt="Previous" rtlDiv>
          <div class="dates-range datepicker-rtl" rtlDiv>
            {{selectedDates.from}} - {{selectedDates.to}}
          </div>
          <img [ngClass]="{'transform-arrow' : currentLang == 'iw'}" src="assets/images/dashboard/Next.svg" alt="Next" rtlDiv>
        </div>
        <div class="datepicker-frame" rtlDiv>
          <app-datepicker-range [hidden]='!showDatePicker' (selectDates)='onSelectDates($event)'></app-datepicker-range>
        </div>
      </div>
    </div>
    <mat-divider class="top-divider"></mat-divider>
    <div class="bin-info-container">
      <div class="first-row">
        <app-refill-kpi [datesDiff]="datesDiff"></app-refill-kpi>
      </div>
      <div class="second-row">
        <app-refills-table></app-refills-table>
      </div>
    </div>
  </div>
}

@if (isMobile) {
  <div>
  </div>
}
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ResponsiveService } from '../../services/responsive.service';
import { ApiQuery } from '../../web-services/api/api.query';
import { ApiStore } from '../../web-services/api/api.store';
import { environment } from '../../../environments/environment';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { FormsModule } from '@angular/forms';
import { App } from '@capacitor/app';


@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, FormsModule],
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss'
})
export class LogoutComponent {
  isMobile: Boolean;
	userEmail:string="";
	phone:string="";
	version_number: String;

  constructor(private responsiveService: ResponsiveService,
    private router: Router, private apiStore:ApiStore,private apiQuery:ApiQuery) {
		this.version_number = environment.appVersion;
	 }

  ngOnInit() {
    this.onResize();
	this.responsiveService.checkWidth();
	this.apiQuery.user$.subscribe(user => {
		if (!user) {
			return;
		}		
		this.phone = user["user_phone_number"];
		this.userEmail = user["user_email_address"];
	});

	App.addListener('backButton', data => {
		window.history.back();
	});
  }

  
	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
  }
  
  logout() {
	  	//emptying bin-status component
		this.apiStore.update({ allFilteredDailyWorkPlan: [] });
		this.apiStore.update({ filteredDailyWorkPlan: [] });
		this.apiStore.update({ allCollectionsStatsForWorkplanProgres: [] });                
        this.apiStore.update({ CollectionsStatsForWorkplanProgres: [] }); 
		
		//emptying active-bins component
		this.apiStore.update({ allFilteredBasicBins: [] });                    
		this.apiStore.update({ filteredBasicBins: [] }); 

		//emptying collection-kpi components
		this.apiStore.update({ allCollectionsKpis: [] });
		this.apiStore.update({ collectionsKpis: [] });

		//emptying collection-pie component
		this.apiStore.update({ collectionsStatsInCollections: [] });
		this.apiStore.update({ allCollectionsStats: [] });
		this.apiStore.update({ collectionsStats: [] });
		this.apiStore.update({ allCollectionsStatsToday: [] });
		this.apiStore.update({ collectionsStatsToday: [] });

		//emptying refill-kpi components
		this.apiStore.update({ allRefillsKpis: [] });
		this.apiStore.update({ refillsKpis: [] });

		//emptying refills-table components
		this.apiStore.update({ allRefillsStats: [] });
		this.apiStore.update({ refillsStats: [] });

		//emptying alert-history component
		this.apiStore.update({ userEventsByType: [] });

		//emptying inventory component 
		this.apiStore.update({ allFilteredBins: [] });                    
		this.apiStore.update({ filteredBins: [] });

		//emptying volume component           
		this.apiStore.update({ collectionVolume: [] });

		//emptying exception-list component
		this.apiStore.update({ exceptionsReports: [] });

		//emptying dailyworkplans calendar
		this.apiStore.update({ allDailyWorkPlanCalanderData: [] });
		this.apiStore.update({ dailyWorkPlanCalanderData: [] });
		
		//emptying workPlanScheduler 
		this.apiStore.update({ allWorkPlanScheduler: [] });
		this.apiStore.update({ workPlanScheduler: [] });

		//emptying sitesList
		this.apiStore.update({ allSitesListData: [] });   	                                                      
		this.apiStore.update({ sitesListData: [] });

		//document.location.href="/";
		this.router.navigate(['/login']);
		localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
		localStorage.removeItem(LOCAL_STORAGE_KEYS.USER);
	}
}

<ngb-datepicker class="datepicker-frame" rtlDiv #dp [startDate]="fromDate" [displayMonths]="monthsToDisplay" (dateSelect)="onDateSelection($event)" [footerTemplate]="footerTemplate" [dayTemplate]="t" [maxDate]="endDate" [minDate]="startDate" [firstDayOfWeek]="firstDayOfWeek">
</ngb-datepicker>
<ng-template #t let-date="date" let-focused="focused">
  <span class="custom-day" [class.focused]="focused" [class.range]="isFrom(date) || isTo(date) || isInside(date)"
    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null"
    [class.disabled]="isDisabled(date)">
    {{ date.day }}
    @if (hasCollection(date)) {
      <div [ngClass]="{'collected-dot':!isMobile,'mobile-collected-dot':isMobile}">
        <svg height="6" width="6">
          <ellipse cx="3" cy="3" rx="3" ry="3" style="fill:#5D7870"/>
        </svg>
      </div>
    }
  </span>
</ng-template>

<ng-template #footerTemplate>
  <button class="btn btn-sm btn-outline-primary mr-2 todayBtn" (click)="selectToday()">{{ 'WORKPLAN.TODAY' | translate }}</button>
  @if (showResetBtn) {
    <button class="btn btn-sm btn-outline-primary mr-2 todayBtn" (click)="resetToDefault()">{{ 'TICKET_MANAGEMENT.CLEAR' | translate }}</button>
  }
</ng-template>
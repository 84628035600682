import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewRef } from '@angular/core';
import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import * as _ from 'lodash';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { Subject, Subscription, fromEvent, merge, of } from "rxjs";
import { TranslatorService } from '../../services/translator_service';
import { ResponsiveService } from '../../services/responsive.service';
import { ApiQuery } from '../../web-services/api/api.query';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../web-services/api/api.service';
import { UtilService } from '../../services/util.service';
import { map, takeUntil } from "rxjs/operators";
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { MatTableModule } from '@angular/material/table';
import { PaginationComponent } from '../pagination/pagination.component';
import {Capacitor, registerPlugin} from "@capacitor/core";
import {BackgroundGeolocationPlugin} from "@capacitor-community/background-geolocation";
import { MatDialog } from '@angular/material/dialog';
import { DisclosureModalComponent } from './disclosure-modal/disclosure-modal.component';
import moment from 'moment';
import { API } from '../../types.api';

const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>("BackgroundGeolocation");
@Component({
  selector: 'app-driver-list',
  standalone: true,
  imports: [CommonModule,TranslateModule,RTLDivDirectiveDirective,MatDividerModule,ScrollingModule,
    MatTableModule,MatSortModule,PaginationComponent,TableVirtualScrollModule
  ],
  templateUrl: './driver-list.component.html',
  styleUrl: './driver-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverListComponent {
  isMobile: boolean;
  currentLang;
  translationsObj;
  routeParamsSub: any;
  capacityDisplayType: number = 0;
  usageType = 1;
  capacitySymbol: string = " %";
  binsDataTable;
  dataSource = new TableVirtualScrollDataSource<any>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  filteredData: Array<object> = [];
  tempFilter = "";
  displayedColumns = [
    "bin_collection_order_index",
    "cluster_size",
    "bin_name",
    "neighborhood",
    "address",
    "status",
  ];
  displayedColumnsMobile = [
    "bin_collection_order_index",
    "cluster_size",
    "bin_name",
    "neighborhood",
    "address",
    "status",
  ];
  lastUpdatedTime;
  binsNotCollected;
  chosenPlan;
  vehicles: any = [];
  includedBins: Array<object> = [];
  notIncludedBins: Array<object> = [];
  private readonly destroy$ = new Subject();
  private readonly destroySiteInfo$ = new Subject();
  watchPosGeoLocation;
  geolocationCapacitor;
  // Define a threshold for detecting a stop (in meters)
  STOP_THRESHOLD = 5;
  STOP_THRESHOLD2 = 5;
  // Define a time threshold for how long in a place to consider it a stop (in milliseconds)
  TIME_THRESHOLD = 10000;
  TIME_THRESHOLD2 = 10000;
  lastPosition = null;
  lastPosition2 = null;
  lastUpdateTime = Date.now();
  lastUpdateTime2 = Date.now();
  updateInterval = 1000; // 1 second in milliseconds
  stopTimer = null;
  stopTimer2 = null;
  interval;
  truckId;
  siteId;
  networkStatus: any;
  networkStatus$: Subscription = Subscription.EMPTY;
  responseCount : number;
  binsInfo:Array<object> = [];

  constructor(
    private translator: TranslatorService,
    private responsiveService: ResponsiveService,
    private apiQuery: ApiQuery,
    private route: ActivatedRoute,
    private apiService: ApiService,
    public utilService: UtilService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.translator.currentLangEmitter$.subscribe(async (value) => {
      this.translationsObj = await this.translator.getTranslation(value).toPromise();
      this.currentLang = value;
    });
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.checkNetworkStatus();
    this.responseCount = 0;

    this.apiQuery.user$.subscribe((user) => {
      if (!user) return;
      if (user["usage_type_id"] != 1) {
        this.usageType = user["usage_type_id"];
      }
      this.capacityDisplayType = user["user_default_capacity_type_id"];
      if (this.capacityDisplayType != 0) {
        this.capacitySymbol = "L";
      }
    });

    this.routeParamsSub = this.route.queryParams.subscribe((params) => {
      this.truckId = this.route.snapshot.paramMap.get("tabletid");
      this.siteId = this.route.snapshot.paramMap.get("siteid");
      this.apiQuery.filteredDailyWorkPlan$.pipe(takeUntil(this.destroySiteInfo$)).subscribe((workPlans) => {
        if (workPlans.length == 0) return;
        let plan = [];
        let vehicles = [];
        _.each(workPlans, (item) => {
          plan.push(item);
        });
        _.each(plan, (item) => {
          _.each(item.Vehicle_info, (vehicle) => {
            vehicle["site_name"] = item.site_name;
            vehicle["site_id"] = item.site_id;
			      vehicle["plan_id"] = item.plan_id;
            vehicle["site_plan_id"] = item.Site_plan_id;
            vehicles.push(vehicle);
          });
        });
        this.vehicles = vehicles;
        const relevantVehicle = _.filter(vehicles, (vehicle) => {
          return vehicle.site_id == this.siteId && vehicle.tablet_id == this.truckId;
        });
        if (relevantVehicle.length > 0) {
          this.chosenPlan = relevantVehicle[0];
        }
        this.cd.detectChanges();
      });
      this.apiService.getTabletNavigationInfo(this.truckId);
      this.apiQuery.navigationInfo$.pipe(takeUntil(this.destroy$)).subscribe((workPlans) => {
        let binsLeftoToCollect = [];
          if (_.isEmpty(workPlans)) {
            return;
          }
          _.each(workPlans['bins_cluster_info'], (item) => {
            _.each(item['bins_info'], (bin) => {
              if (bin["is_collected"] == 0) {
                binsLeftoToCollect.push(bin);
              }
            });
          });
          this.binsNotCollected = binsLeftoToCollect.length;
          this.lastUpdatedTime = workPlans["work_plan_last_update_time"];
          this.binsInfo = [];
          _.each(workPlans["bins_cluster_info"], (item) => {
            _.each(item["bins_info"], (bin,index:any) => {
              const isLast: boolean = index == item["bins_info"]['length'] - 1;
              const middleIndex = item["bins_info"]['length'] == 2 ? 1 : Math.floor(item["bins_info"]['length'] / 2);
              bin.isLast = isLast;
              if (index === middleIndex) {
                bin.isMiddle = true;
              }else{
                bin.isMiddle = false;
              }
              bin["num_of_bins_in_cluster"] = item["num_of_bins_in_cluster"];
              this.binsInfo.push(bin);
            });
          });
          this.binsDataTable = this.binsInfo;
          this.binsInfo.sort(function(a, b){
            if(a['bin_collection_order_index'] < b['bin_collection_order_index']) { return 1; }
            if(a['bin_collection_order_index'] > b['bin_collection_order_index']) { return -1; }
            return 0;
          });
          this.dataSource = new TableVirtualScrollDataSource<any>(
            this.binsInfo
          );
          this.dataSource.sort = this.sort;
          this.sortData({ direction: "asc", active: "bin_collection_order_index" });
          this.cd.detectChanges();
        });
    });

    if ("geolocation" in navigator) {
      this.watchPosGeoLocation = navigator.geolocation.watchPosition((pos) => {
        this.responseCount++;
        if(this.responseCount % 2 == 0 && this.chosenPlan){
          const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
          let request = {
            exception_id: API.E_EXCEPTION_HANDLER_EXCEPTION_ID.E_EXCEPTION_HANDLER_EXCEPTION_ID_CURRENT_LOCATION,
            exception_generator: API.E_EXCEPTION_HANDLER_EXCEPTION_GENERATOR.E_EXCEPTION_HANDLER_EXCEPTION_GENERATOR_APPLICATION,
            plan_id: this.chosenPlan.site_plan_id,
            info_filed_1: pos.coords.heading ? pos.coords.heading : 0,
            info_filed_2: pos.coords.accuracy,
            info_filed_3: currentDateTime,
            tablet_id: this.chosenPlan.tablet_id,
            location_lon: pos.coords.longitude,
            location_lat: pos.coords.latitude
          }
          this.apiService.sendExceptionCurrentLocation(request);
        }
        const { latitude, longitude } = pos.coords;
        const currentTime = Date.now();
        if (this.lastPosition) {
            const distanceMoved = this.distance(
                latitude, longitude,
                this.lastPosition.latitude, this.lastPosition.longitude,
                'M'
            );
            if (distanceMoved < this.STOP_THRESHOLD) {
                if (!this.stopTimer) {
                    // Start a timer if not already started
                    this.stopTimer = setTimeout(() => {
                        let notIncludedBins = [];
                        let includedBins = [];
                        this.apiQuery.filteredBins$.subscribe((bins) => {
                          if (bins.length == 0) return;
                          _.each(bins, (item) => {
                            const distance = this.distance(pos.coords.latitude,pos.coords.longitude,item["Bin_location"]["bin_latitude"],item["Bin_location"]["bin_longitude"],"M");
                            if (distance <= 30) {
                              notIncludedBins.push(item);
                            }                            
                          });
                          this.notIncludedBins = notIncludedBins;
                        });
                        if(this.chosenPlan){
                        _.each(this.chosenPlan.Bins_collection_info, (item) => {
                          const distance = this.distance(pos.coords.latitude,pos.coords.longitude,item["Bin_location"]["bin_latitude"],item["Bin_location"]["bin_longitude"],"M");
                          if (distance <= 30) {
                            includedBins.push(item);
                          }
                          });
                          this.includedBins = includedBins;
                          _.each(this.notIncludedBins, (item) => {
                          const dataToSend = {};
                          dataToSend["bin_id"] = item['bin_id'];
                          dataToSend["tablet_id"] = this.chosenPlan.tablet_id;
                          this.apiService.sendBinArrivalWithoutWorkPlan(dataToSend);
                          });
                    
                          _.each(this.includedBins, (item) => {
                          const dataToSend = {};
                          dataToSend["plan_id"] = this.chosenPlan.site_plan_id;
                          dataToSend["bin_id"] = item['bin_id'];
                          dataToSend["tablet_id"] = this.chosenPlan.tablet_id;
                          dataToSend["nav_lat"] = item["Bin_location"]["bin_latitude"];
                          dataToSend["nav_lon"] = item["Bin_location"]["bin_longitude"];
                          this.apiService.sendBinArrival(dataToSend);
                          });
                        }
                    }, this.TIME_THRESHOLD);
                }
            } else {
                clearTimeout(this.stopTimer);
                this.stopTimer = null;
            }
        }
    
        this.lastPosition = { latitude, longitude };
        this.lastUpdateTime = currentTime;
        },
        (error) => {},
        { enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 10000}
      );
    }else {
      console.log("Geolocation is not supported by this browser.");
    }

    this.interval = setInterval(() => {
      this.apiService.getTabletNavigationInfo(this.truckId);
    },30000);

    if(this.isMobile){
      const dialogRef = this.dialog.open(DisclosureModalComponent, { panelClass: 'popup-mobile' });
      dialogRef.afterClosed().subscribe(result => {
        if(result.status == 1){
          this.startBackgroundGeolocation();
        }
      });
    }      
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.networkStatus$.unsubscribe();
    if(this.isMobile){
      this.stopBackgroundGeolocation();
    }
    navigator.geolocation.clearWatch(this.watchPosGeoLocation);
    this.responseCount = 0;
    this.destroy$.next(true);
    this.destroy$.complete();

    this.destroySiteInfo$.next(true);
    this.destroySiteInfo$.complete();
    if (!this.cd["destroyed"] || !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  
  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        if(status){
          this.apiService.sendBinArrival();
        }
        this.networkStatus = status;
      });
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  goToDriverPlans() {
    this.router.navigate(["/driverplans"]);
  }

  sortData(sort: Sort) {
    const data = this.binsDataTable.slice();
    if (!sort.active || sort.direction === "") {
      this.binsDataTable = data;
      return;
    }
    this.binsDataTable = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      return sort.active
        ? this.compare(a[sort.active], b[sort.active], isAsc)
        : 0;
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getTableData = () => {
    const tempSearch = new TableVirtualScrollDataSource<any>(
      this.binsDataTable
    );
    tempSearch.filter = this.tempFilter;
    this.filteredData = tempSearch.filteredData;
    this.dataSource =
      tempSearch.filteredData.length > 0
        ? new TableVirtualScrollDataSource<any>(tempSearch.filteredData)
        : new TableVirtualScrollDataSource<any>([]);
    return this.dataSource;
  };

  getImg(elm) {
    if (elm == null) {
      return "";
    }
    if (elm) {
      if (elm.is_collected == 1) {
        return "assets/images/dashboard/selected.svg";
      }
    }
  }

  getProgressBarStyleWrapper = (binInfo: any) => {
    if (!binInfo) {
      return;
    }
    return { "background-color": "rgba(245, 246, 250, 1)" };
  };

  getProgressBarStyleInternal = (binInfo: any) => {
    if (!binInfo) {
      return;
    }
    let width =
      this.capacityDisplayType != 0
        ? binInfo["Last_updated_fill_level_sample"]["fill_level_in_litters"]
        : binInfo["Last_updated_fill_level_sample"]["fill_level_in_precent"];
    if (binInfo.bin_status_id_color === "GREEN") {
      return {
        "background-color": "#14C264",
        width: width + "%",
        "max-width": "100%",
      };
    } else if (binInfo.bin_status_id_color === "YELLOW") {
      return {
        "background-color": "#FAB300",
        width: width + "%",
        "max-width": "100%",
      };
    } else {
      return {
        "background-color": "#E01B09",
        width: width + "%",
        "max-width": "100%",
      };
    }
  };

  distance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var radlon1 = (Math.PI * lon1) / 180;
    var radlon2 = (Math.PI * lon2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "M") {
      dist = dist * 1609.34;
    }
    return dist;
  }

  startBackgroundGeolocation() {
    if (Capacitor.getPlatform() === 'web') {
      console.warn('BackgroundGeolocation is not available on the web platform');
      return;
    }

    this.geolocationCapacitor = BackgroundGeolocation.addWatcher(
      {
          backgroundMessage: "Background tracking is enabled",
          backgroundTitle: "Tracking You.",
          requestPermissions: true,
          stale: false,
          distanceFilter: 0
      },
      (location, error) => {
          if (error) {
              if (error.code === "NOT_AUTHORIZED") {
                  if (window.confirm(
                      "This app needs your location, " +
                      "but does not have permission.\n\n" +
                      "Open settings now?"
                  )) {
                      BackgroundGeolocation.openSettings();
                  }
              }
              return console.error(error);
          }
  
          this.responseCount++;
          if(this.responseCount % 2 == 0 && this.chosenPlan){
            const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
            let request = {
              exception_id: API.E_EXCEPTION_HANDLER_EXCEPTION_ID.E_EXCEPTION_HANDLER_EXCEPTION_ID_CURRENT_LOCATION,
              exception_generator: API.E_EXCEPTION_HANDLER_EXCEPTION_GENERATOR.E_EXCEPTION_HANDLER_EXCEPTION_GENERATOR_APPLICATION,
              plan_id: this.chosenPlan.site_plan_id,
              info_filed_1: location.bearing ? location.bearing : 0,
              info_filed_2: location.accuracy,
              info_filed_3: currentDateTime,
              tablet_id: this.chosenPlan.tablet_id,
              location_lon: location.longitude,
              location_lat: location.latitude
            }
            this.apiService.sendExceptionCurrentLocation(request);
          }
          console.log("current",location);
          console.log(this.lastPosition2,"lastPosition");

          const currentTime = Date.now();
          if (currentTime - this.lastUpdateTime2 >= this.updateInterval) {
              this.lastUpdateTime2 = currentTime;
              const { latitude, longitude } = location;
            if (this.lastPosition2) {
              const distanceMoved = this.distance(
                  latitude, longitude,
                  this.lastPosition2.latitude, this.lastPosition2.longitude,
                  'M'
              );
              console.log("distanceMoved",distanceMoved);
              console.log("STOP_THRESHOLD",this.STOP_THRESHOLD2);
              if (distanceMoved < this.STOP_THRESHOLD2) {
                console.log("STOPPED detected");
                  if (!this.stopTimer2) {
                    console.log("STARTED");
                      // Start a timer if not already started
                      this.stopTimer2 = setTimeout(() => {
                          let notIncludedBins = [];
                          let includedBins = [];
                          this.apiQuery.filteredBins$.subscribe((bins) => {
                            if (bins.length == 0) return;
                            _.each(bins, (item) => {
                              const distance = this.distance(location.latitude,location.longitude,item["Bin_location"]["bin_latitude"],item["Bin_location"]["bin_longitude"],"M");
                              if (distance <= 30) {
                                notIncludedBins.push(item);
                              }                            
                            });
                            this.notIncludedBins = notIncludedBins;
                          });
                          console.log("STOPPED");    
                          if(this.chosenPlan){
                          _.each(this.chosenPlan.Bins_collection_info, (item) => {
                            const distance = this.distance(location.latitude,location.longitude,item["Bin_location"]["bin_latitude"],item["Bin_location"]["bin_longitude"],"M");
                            if (distance <= 30) {
                              includedBins.push(item);
                            }
                            });
                            this.includedBins = includedBins;
                            console.log("includedBins",this.includedBins);
                            console.log("notIncludedBins",this.notIncludedBins);
                            _.each(this.notIncludedBins, (item) => {
                            const dataToSend = {};
                            dataToSend["bin_id"] = item['bin_id'];
                            dataToSend["tablet_id"] = this.chosenPlan.tablet_id;
                            this.apiService.sendBinArrivalWithoutWorkPlan(dataToSend);
                            });
                      
                            _.each(this.includedBins, (item) => {
                            const dataToSend = {};
                            dataToSend["plan_id"] = this.chosenPlan.site_plan_id;
                            dataToSend["bin_id"] = item['bin_id'];
                            dataToSend["tablet_id"] = this.chosenPlan.tablet_id;
                            dataToSend["nav_lat"] = item["Bin_location"]["bin_latitude"];
                            dataToSend["nav_lon"] = item["Bin_location"]["bin_longitude"];
                            this.apiService.sendBinArrival(dataToSend);
                            });
                          }
                      }, this.TIME_THRESHOLD2);
                  }
              } else {
                  clearTimeout(this.stopTimer2);
                  this.stopTimer2 = null;
              }
          }
      
          this.lastPosition2 = { latitude, longitude };
          this.lastUpdateTime2 = currentTime;
        }
      }
    )
  }

  stopBackgroundGeolocation() {
    if (Capacitor.getPlatform() === 'web') {
      console.warn('BackgroundGeolocation is not available on the web platform');
      return;
    }
    BackgroundGeolocation.removeWatcher({ id: this.geolocationCapacitor });
  }
}

import { Component, Input } from '@angular/core';
import { ResponsiveService } from '../../services/responsive.service';

@Component({
  selector: 'app-number-with-description',
  standalone: true,
  imports: [],
  templateUrl: './number-with-description.component.html',
  styleUrl: './number-with-description.component.scss'
})
export class NumberWithDescriptionComponent {
	isMobile: boolean = false;

	@Input() number = 0;
	@Input() total : any;
	@Input() description: string = '';

	constructor(private responsiveService: ResponsiveService) {	}

	ngOnInit(){
		this.onResize();
		this.responsiveService.checkWidth();
	}

	
	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }
}

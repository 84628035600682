@if (!isMobile) {
  <div>
    <div class="modal-title">
      <span>{{'TITLES.DELETE' | translate }} {{ 'TITLES.CLUSTERS' | translate }} ({{data.chosenClusters.length}})</span>
    </div>
    <div class="content-body" rtlDiv>
      @if (data.chosenClusters.length > 1) {
        <span>{{'TITLES.DELETE_MSG' | translate }} {{data.chosenClusters.length}} {{ 'TITLES.CLUSTERS' | translate }}</span>
      }
      @if (data.chosenClusters.length == 1) {
        <span>{{'TITLES.DELETE_MSG' | translate }} {{'TITLES.THE_CLUSTER' | translate }} {{data.chosenClusters[0].cluster_name}}</span>
      }
      <span>?</span>
    </div>
    <div [ngClass]="{'rtldir': currentLang != 'iw','ltrdir':currentLang == 'iw'}">
      <button (click)='applyChanges()' [ngClass]="{'not-apply-btn' : submitClicked , 'apply-btn' : !submitClicked}">
        {{ 'TITLES.DELETE' | translate }}
      </button>
      <button class="cancel_btn" (click)="closeModal()">{{ 'TRUCK_MAP.CANCEL' | translate }}</button>
    </div>
  </div>
}

@if (isMobile) {
  <div>
    <div class="mobile-modal-title">
      <span>{{'TITLES.DELETE' | translate }} {{ 'TITLES.CLUSTERS' | translate }} ({{data.chosenClusters.length}})</span>
    </div>
    <div class="mobile-content-body" rtlDiv>
      @if (data.chosenClusters.length > 1) {
        <span>{{'TITLES.DELETE_MSG' | translate }} {{data.chosenClusters.length}} {{ 'TITLES.CLUSTERS' | translate }}</span>
      }
      @if (data.chosenClusters.length == 1) {
        <span>{{'TITLES.DELETE_MSG' | translate }} {{'TITLES.THE_CLUSTER' | translate }} {{data.chosenClusters[0].cluster_name}}</span>
      }
      <span>?</span>
    </div>
    <div [ngClass]="{'mobile-rtldir': currentLang != 'iw','mobile-ltrdir':currentLang == 'iw'}">
      <button (click)='applyChanges()' [ngClass]="{'not-apply-btn' : submitClicked , 'apply-btn' : !submitClicked}">
        {{ 'TITLES.DELETE' | translate }}
      </button>
      <button class="cancel_btn" (click)="closeModal()">{{ 'TRUCK_MAP.CANCEL' | translate }}</button>
    </div>
  </div>
}

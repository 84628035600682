import { Component, Inject } from '@angular/core';
import _ from 'lodash';
import { TranslatorService } from '../../../services/translator_service';
import { ResponsiveService } from '../../../services/responsive.service';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiQuery } from '../../../web-services/api/api.query';
import { UtilService } from '../../../services/util.service';
import { ApiStore } from '../../../web-services/api/api.store';
import { ApiService } from '../../../web-services/api/api.service';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-view-ticket-modal',
  standalone: true,
  imports: [TranslateModule,RTLDivDirectiveDirective,CommonModule],
  templateUrl: './view-ticket-modal.component.html',
  styleUrl: './view-ticket-modal.component.scss'
})
export class ViewTicketModalComponent {
  currentLang;
	translationsObj;
  isMobile: Boolean;
	usageType = 1;
  binName;
  binAddress;
  description;
  binNeighborhood;
  resolvedDescription;
  installedBinName;
  bin_location;
  is_installed;
  is_replaced;
  is_removed_from_site;
  is_removed_from_bin;
  reasonOfReplacementID;
  reasonOfReplacementEn;
  reasonOfReplacementHe;
  analysisNameEn;
  analysisNameHe;
  partialEdit : boolean = false;

  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,private fb: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: any,
  private apiQuery:ApiQuery,private dialogRef: MatDialogRef<ViewTicketModalComponent>,private modalService: NgbModal,private apiService:ApiService,private apiStore:ApiStore,
  public utilService: UtilService) {
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
   }

  ngOnInit() {
    this.onResize();
	  this.responsiveService.checkWidth();

    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}		
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
      if ((user["user_role_id"] == 3) || (user["user_role_id"] == 4)) {
        this.partialEdit = true;
      }else{
        this.partialEdit = false;
      }
		});
    if(this.data.chosenTicket.ticket_event_type_id == 1 || this.data.chosenTicket.ticket_event_type_id == 4){
      this.analysisNameEn = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].analysis_name;
      this.analysisNameHe = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].analysis_name_hebrew;
      this.reasonOfReplacementID = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].ticket_remove_replace_cause_name ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].ticket_remove_replace_cause_name : _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].replace_cause_id;
      this.reasonOfReplacementEn = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].ticket_remove_replace_cause_name ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].ticket_remove_replace_cause_name : _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].replace_cause_name;
      this.reasonOfReplacementHe = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].ticket_remove_replace_cause_name_hebrew ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].ticket_remove_replace_cause_name_hebrew : _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].replace_cause_name_hebrew;
      this.is_replaced = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].is_replaced;
      this.is_removed_from_bin = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].is_removed_from_bin;
      this.is_removed_from_site = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].is_removed_from_site;
      this.binName = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][0].bin_name;
      this.binAddress = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][0].bin_address;
      this.description = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"].length - 1].comment;
    }else if(this.data.chosenTicket.ticket_event_type_id == 3){
      this.is_installed = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][0].is_installed;
      this.bin_location = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][0].installed_lat + "," + _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][0].installed_lon;
      this.binAddress = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][0].installed_bin_address;
      this.installedBinName = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"] ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["2"].length - 1].installed_bin_name : '';
      this.description = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["1"].length - 1].comment;
      this.binNeighborhood = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].installed_bin_neighborhood ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")[this.data.status].length - 1].installed_bin_neighborhood : '';
    }    
    this.resolvedDescription = _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["4"] ? _.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["4"][_.groupBy(this.data.chosenTicket["ticketInfo"], "status_id")["4"].length - 1].comment : '';
  }
  
  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  closeModal(){
    this.dialogRef.close();
  }
}

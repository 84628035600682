import { Component, ViewChild } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PaginationComponent } from '../../pagination/pagination.component';
import { TranslatorService } from '../../../services/translator_service';
import { ApiService } from '../../../web-services/api/api.service';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { UtilService } from '../../../services/util.service';
import { Papa } from 'ngx-papaparse';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
import { ChangeLocationModalComponent } from '../change-location-modal/change-location-modal.component';
import { BinsActionsModalComponent } from '../bins-actions-modal/bins-actions-modal.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-bins-monitoring',
  standalone: true,
  imports: [TranslateModule,RTLDivDirectiveDirective,CommonModule,FormsModule,ReactiveFormsModule,NgbModule,
    MatTableModule,MatSortModule,MatCheckboxModule,PaginationComponent,BinsActionsModalComponent,MatDividerModule,
    MatTabsModule,MatCardModule,RouterModule
  ],
  templateUrl: './bins-monitoring.component.html',
  animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		  ])
	  ],
  styleUrl: './bins-monitoring.component.scss'
})
export class BinsMonitoringComponent {
	binFullInfo: any;
	translate;
	@ViewChild(MatSort,{static: false}) sort: MatSort;
	selectionFirst = new SelectionModel<Element>(true, []);
	selectionSecond = new SelectionModel<Element>(true, []);
	dataSourceFirst = new MatTableDataSource<any>();
	dataSourceSecond = new MatTableDataSource<any>();
	binsFirstData:Array<any> = [];
	binsSecondData:Array<any> = [];
	displayedColumns = ['select', 'bin_name', 'bin_id','device_id','bin_address','bin_installation_date', 'bin_installer','bin_installation_user_reported_capacity_in_percent','bin_installation_capacity_in_percent','bin_capacity_gap','current_capacity_in_percent','production_chain_status_desc','binWazeLink','manage','location'];
	columnsToDisplay = ['bin_name', 'bin_id','arrowDown'];
	relevantFirstType:Array<any> = [];
	relevantSecondType:Array<any> = [];
	dataFirstTableLength = 0;
	dataSecondTableLength = 0;
	tempFirstFilter = '';
	tempSecondFilter = '';
	startFirstIndexTableData = 0;
	endFirstIndexTableData = 9;
	startSecondIndexTableData = 0;
	endSecondIndexTableData = 9;
	currentLang;
	translationsObj;
	isMobile: Boolean;
	spinnerFirstActive:boolean = true;
	spinnerSecondActive:boolean = true;
	routeParamsSub;
	siteId: any;
	selectedBin;
	tabStatus: any;
	showManageBinModal: Boolean = false;
	filteredFirstData:Array<object> = [];
	filteredSecondData:Array<object> = [];
	expandedElement: any;

	constructor(private translator: TranslatorService, private papa: Papa,
		private utilService: UtilService, private router: Router,private responsiveService: ResponsiveService,private dialog: MatDialog,
		private route: ActivatedRoute,private apiService:ApiService,private apiQuery:ApiQuery) {
		this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		});
		this.routeParamsSub = this.route
		.queryParams
		.subscribe(params => {
			this.siteId = this.route.snapshot.paramMap.get("id"); 
		});		
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.apiService.GetBinManagmentInfo(this.siteId);		
	}

	ngAfterViewInit() {
		this.apiQuery.filteredBinManagmentInfo$.subscribe(data => {	
			let binsFirstFiltered = [];
			this.relevantFirstType = [];
			this.spinnerFirstActive = true;		

			let binsSecondFiltered = [];
			this.relevantSecondType = [];
			this.spinnerSecondActive = true;		
			if(data.length == 0){return;}
			_.each(data, (item) => {
				if(item["production_chain_status_id"] == 3 || item["production_chain_status_id"] == 4 ||
					item["production_chain_status_id"] == 5){
					this.relevantFirstType.push(item);
				}
				if(item["production_chain_status_id"] == 4 || item["production_chain_status_id"] == 5){
					this.relevantSecondType.push(item);
				}
			});
			
			binsFirstFiltered = _.map(this.relevantFirstType, b => {
				return {
					bin_name: b["bin_name"],
					bin_id: b["bin_id"],
					device_id: b["device_id"],
					bin_address: b["bin_address"],
					bin_installation_date: b["bin_installation_date"],
					bin_installer: b["bin_installer"],
					bin_installation_user_reported_capacity_in_percent: b["bin_installation_user_reported_capacity_in_percent"],
					bin_installation_capacity_in_percent: b["bin_installation_capacity_in_percent"],
					bin_capacity_gap: b["user_reported_from_actual_diff_in_percent"],
					current_capacity_in_percent: b["current_capacity_in_percent"],
					production_chain_status_desc: b["production_chain_status_desc"],
					production_chain_status_desc_hebrew: b["production_chain_status_desc_hebrew"],
					lat: b["bin_lat"],
					lon: b["bin_lon"]
				};
			});

			binsSecondFiltered = _.map(this.relevantSecondType, b => {
				return {
					bin_name: b["bin_name"],
					bin_id: b["bin_id"],
					device_id: b["device_id"],
					bin_address: b["bin_address"],
					bin_installation_date: b["bin_installation_date"],
					bin_installer: b["bin_installer"],
					bin_installation_user_reported_capacity_in_percent: b["bin_installation_user_reported_capacity_in_percent"],
					bin_installation_capacity_in_percent: b["bin_installation_capacity_in_percent"],
					bin_capacity_gap: b["user_reported_from_actual_diff_in_percent"],
					current_capacity_in_percent: b["current_capacity_in_percent"],
					production_chain_status_desc: b["production_chain_status_desc"],
					production_chain_status_desc_hebrew: b["production_chain_status_desc_hebrew"],
					lat: b["bin_lat"],
					lon: b["bin_lon"]
				};
			});

			this.binsFirstData = binsFirstFiltered;			
			this.dataFirstTableLength = this.binsFirstData.length;
			this.dataSourceFirst = new MatTableDataSource<any>(binsFirstFiltered);

			this.binsSecondData = binsSecondFiltered;			
			this.dataSecondTableLength = this.binsSecondData.length;
			this.dataSourceSecond = new MatTableDataSource<any>(binsSecondFiltered);

			this.dataSourceFirst.sort = this.sort;
			this.sortFirstData({ direction: 'asc', active: 'bin_name' });	
			this.spinnerFirstActive = false;	
			this.dataSourceSecond.sort = this.sort;
			this.sortSecondData({ direction: 'asc', active: 'bin_name' });	
			this.spinnerSecondActive = false;	
		});
	}

	getFirstTableData = () => {
		const tableFirstData = [];
		const tempFirstSearch = new MatTableDataSource<any>(this.binsFirstData);
		tempFirstSearch.filter = this.tempFirstFilter;
		this.filteredFirstData = tempFirstSearch.filteredData;
		for (let index = this.startFirstIndexTableData; index <= this.endFirstIndexTableData; index++) {
			if (tempFirstSearch.filteredData[index]) {
				tableFirstData.push(tempFirstSearch.filteredData[index]);
			}
		}
		this.dataSourceFirst = tableFirstData.length > 0 ? new MatTableDataSource<any>(tableFirstData) : new MatTableDataSource<any>([]);		
		this.dataSourceFirst["AllData"] = new MatTableDataSource<any>(this.binsFirstData);
		return this.dataSourceFirst;
	}

	getSecondTableData = () => {
		const tableSecondData = [];
		const tempSecondSearch = new MatTableDataSource<any>(this.binsSecondData);
		tempSecondSearch.filter = this.tempSecondFilter;
		this.filteredSecondData = tempSecondSearch.filteredData;
		for (let index = this.startSecondIndexTableData; index <= this.endSecondIndexTableData; index++) {
			if (tempSecondSearch.filteredData[index]) {
				tableSecondData.push(tempSecondSearch.filteredData[index]);
			}
		}
		this.dataSourceSecond = tableSecondData.length > 0 ? new MatTableDataSource<any>(tableSecondData) : new MatTableDataSource<any>([]);		
		this.dataSourceSecond["AllData"] = new MatTableDataSource<any>(this.binsSecondData);
		return this.dataSourceSecond;
	}

	onFirstPageChange = (dataTableIndexes: any) => {
		this.startFirstIndexTableData = dataTableIndexes.startIndex;
		this.endFirstIndexTableData = dataTableIndexes.endIndex;
	}

	onSecondPageChange = (dataTableIndexes: any) => {
		this.startSecondIndexTableData = dataTableIndexes.startIndex;
		this.endSecondIndexTableData = dataTableIndexes.endIndex;
	}

	sortFirstData(sort: Sort) {
		const data = this.binsFirstData.slice();
		if (!sort.active || sort.direction === '') {
			this.binsFirstData = data;
			return;
		}
		this.binsFirstData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
	}

	sortSecondData(sort: Sort) {
		const data = this.binsSecondData.slice();
		if (!sort.active || sort.direction === '') {
			this.binsSecondData = data;
			return;
		}
		this.binsSecondData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
	}

	public openManageBinModal = (showHide: Boolean,bin?,status?) => {
		this.tabStatus = status;
		this.selectedBin = [];
		this.selectedBin.push(bin);
		this.showManageBinModal = showHide;
		this.selectionFirst.clear();
		this.selectionSecond.clear();
	}

	openchangeLocationModal(item){
		if(!this.isMobile){
			this.dialog.open(ChangeLocationModalComponent, { panelClass: 'bulkBins-container' , data: {chosenBin : item , chosenSite : this.siteId} });
		}else{
			this.dialog.open(ChangeLocationModalComponent, { panelClass: 'bulkBins-container-mobile' , data: {chosenBin : item , chosenSite : this.siteId} });	
		}
	}

	
	public openManageModalForMulti = (showHide: Boolean,status) => {		
		this.tabStatus = status;
		if(this.selectionFirst.selected.length == 0){
			return;
		}
		if (showHide) {		
			this.selectedBin = this.selectionFirst.selected;
		}		
		this.showManageBinModal = showHide;
		this.selectionFirst.clear();
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	goToBinLivePage(bin: any) {
		this.router.navigate(['/binLiveInfoPage'], { queryParams: { pageName: 'inventory', binId: bin.bin_id } });
	}

	isFirstAllSelected = (): boolean => {
		const numSelected = this.selectionFirst.selected.length;
		const numRows = this.dataSourceFirst["AllData"].filteredData.length;
		return numSelected === numRows;
	}

	isSecondAllSelected = (): boolean => {
		const numSelected = this.selectionSecond.selected.length;
		const numRows = this.dataSourceSecond["AllData"].filteredData.length;
		return numSelected === numRows;
	}

	applyFirstFilter = (filterValue) => {
    filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempFirstFilter = filterValue;
		this.dataSourceFirst.filter = filterValue;
	}

	applySecondFilter = (filterValue) => {
    filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempSecondFilter = filterValue;
		this.dataSourceSecond.filter = filterValue;
	}

	masterFirstToggle = () => {
		this.isFirstAllSelected() ?
			this.selectionFirst.clear() :
			this.dataSourceFirst["AllData"].filteredData.map(row =>
				this.selectionFirst.select(row))
	}

	masterSecondToggle = () => {
		this.isSecondAllSelected() ?
			this.selectionFirst.clear() :
			this.dataSourceSecond["AllData"].filteredData.map(row =>
				this.selectionFirst.select(row))
	}

	exportFirstToCsv = () => {
		let dataToExport = null;
		let csvData;
		if (this.selectionFirst.selected.length > 0) {
			dataToExport = _.cloneDeep(this.selectionFirst.selected);
		} else {
			dataToExport = _.cloneDeep(this.binsFirstData);
		}

		const flatData = dataToExport.reduce((b, a) => {
			b.push(_.values(a));
			return b;
		}, [])

		csvData = this.papa.unparse({
			fields: [
				this.translationsObj.LIST_COLUMNS.NAME,
				this.translationsObj.LIST_COLUMNS.ID,
				this.translationsObj.SITE_MANAGMENT.DEVICE_ID,
				this.translationsObj.LIST_COLUMNS.ADDRESS,
				this.translationsObj.SITE_MANAGMENT.INSTALLATION_TIME,
				this.translationsObj.SITE_MANAGMENT.INSTALLER_NAME,
				this.translationsObj.SITE_MANAGMENT.CAP_BY_USER,
				this.translationsObj.SITE_MANAGMENT.CAP_AT_INTALLATION,
				this.translationsObj.SITE_MANAGMENT.CAP_GAP,
				this.translationsObj.SITE_MANAGMENT.CURRENT_CAP,
				this.translationsObj.LIST_COLUMNS.STATUS_DESC
			], data: flatData
		});
		
		this.papa.parse(csvData, {
			complete: (results, file) => {
				const csv = `\uFEFF${this.papa.unparse(results.data)}`;
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
				const csvURL = window.URL.createObjectURL(blob);
				const tempLink = document.createElement('a');
				tempLink.href = csvURL;
				if(this.currentLang != 'iw'){
					tempLink.setAttribute('download', 'Installed Devices.csv');
				}else{
					tempLink.setAttribute('download', 'יחידות מותקנות.csv');
				}
				tempLink.click();
			}
		});
	}

	exportSecondToCsv = () => {
		let dataToExport = null;
		let csvData;
		if (this.selectionSecond.selected.length > 0) {
			dataToExport = _.cloneDeep(this.selectionSecond.selected);
		} else {
			dataToExport = _.cloneDeep(this.binsSecondData);
		}

		const flatData = dataToExport.reduce((b, a) => {
			b.push(_.values(a));
			return b;
		}, [])

		csvData = this.papa.unparse({
			fields: [
				this.translationsObj.LIST_COLUMNS.NAME,
				this.translationsObj.LIST_COLUMNS.ID,
				this.translationsObj.SITE_MANAGMENT.DEVICE_ID,
				this.translationsObj.LIST_COLUMNS.ADDRESS,
				this.translationsObj.SITE_MANAGMENT.INSTALLATION_TIME,
				this.translationsObj.SITE_MANAGMENT.INSTALLER_NAME,
				this.translationsObj.SITE_MANAGMENT.CAP_BY_USER,
				this.translationsObj.SITE_MANAGMENT.CAP_AT_INTALLATION,
				this.translationsObj.SITE_MANAGMENT.CAP_GAP,
				this.translationsObj.SITE_MANAGMENT.CURRENT_CAP,
				this.translationsObj.LIST_COLUMNS.STATUS_DESC
			], data: flatData
		});
		
		this.papa.parse(csvData, {
			complete: (results, file) => {
				const csv = `\uFEFF${this.papa.unparse(results.data)}`;
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
				const csvURL = window.URL.createObjectURL(blob);
				const tempLink = document.createElement('a');
				tempLink.href = csvURL;
				if(this.currentLang != 'iw'){
					tempLink.setAttribute('download', 'Malfunctioning Devices.csv');
				}else{
					tempLink.setAttribute('download', 'יחידות תקולות.csv');
				}
				tempLink.click();
			}
		});
	}
}

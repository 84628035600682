import { Component, EventEmitter, Input, Output } from '@angular/core';
import _ from 'lodash';
import { TranslatorService } from '../../../services/translator_service';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ResponsiveService } from '../../../services/responsive.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';

@Component({
  selector: 'app-bin-events',
  standalone: true,
  imports: [CommonModule,TranslateModule,RTLDivDirectiveDirective,MatDividerModule,MatCardModule,MatExpansionModule],
  templateUrl: './bin-events.component.html',
  styleUrl: './bin-events.component.scss'
})
export class BinEventsComponent {
  @Input() bin_id;
	@Input() orientation: boolean;
	isMobile: Boolean;
	showlist:boolean = false;
	@Output() showListEvent = new EventEmitter<boolean>();
	@Input() showLocation: boolean;
	errorMsg: any;
	translationsObj;
	currentLang;
    translate;
	binEvents: any;
	showClear: boolean = false;
	usageType = 1;

	constructor(private translator: TranslatorService,
		private responsiveService: ResponsiveService,private apiService:ApiService,private apiQuery: ApiQuery) {
			this.translate = this.translator;
			this.translator.currentLangEmitter$
			.subscribe(async value=>{						
			  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			  this.currentLang = value;
			})
		 }

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.showListEvent.emit(this.showlist);

		this.apiQuery.binEvents$.subscribe((binEvents) => {		
			this.binEvents = binEvents;
			if (this.binEvents.length > 0) {
				this.showClear = true;
			}
		});

		this.apiQuery.user$.subscribe((user) => {
			if (!user) return;

			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
		});
	}

	ngAfterViewInit() {          
		setTimeout(() => {
			this.apiService.getBinEvents(this.bin_id)
		}, 0);
	}
	
	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	  displayList(){		  
		this.showlist = !this.showlist;
		this.showListEvent.emit(this.showlist);
	  }

	public removeBinEvent = (index) => {
		var binToRemove = this.binEvents[index].event_id;
		this.binEvents.splice(index, 1);
		this.apiService.removeBinEvents({ 'event_id_list': [binToRemove] }).subscribe((v:any) => {});
	}

	public clearAllBinEvents = () => {
		const tempArray = _.map(this.binEvents, 'event_id');
		this.apiService.removeBinEvents({ 'event_id_list': tempArray }).subscribe((v:any) => {});
		this.binEvents = [];
	}
}

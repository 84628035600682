import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiService } from '../../../web-services/api/api.service';
import { TranslatorService } from '../../../services/translator_service';
import { MatDialog } from '@angular/material/dialog';
import { ApiQuery } from '../../../web-services/api/api.query';
import _ from 'lodash';
import { ManageBinsModalComponent } from '../../inventory/manage-bins-modal/manage-bins-modal.component';
import { ManageClusterModalComponent } from '../../inventory/manage-cluster-modal/manage-cluster-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatListModule} from '@angular/material/list';

@Component({
  selector: 'app-manage-panel-modal',
  standalone: true,
  imports: [TranslateModule,CommonModule,MatExpansionModule,RTLDivDirectiveDirective,MatInputModule,
    FormsModule,ReactiveFormsModule,MatListModule],
  templateUrl: './manage-panel-modal.component.html',
  styleUrl: './manage-panel-modal.component.scss'
})
export class ManagePanelModalComponent {
  translationsObj;
	currentLang;
  isMobile: Boolean;
  @Output() resetClicked = new EventEmitter<boolean>(true);
	@Output() clusterClicked = new EventEmitter<boolean>(true);
  @Output() neighborhoodsSelected = new EventEmitter<object>();  
  @Output() fullClusterSelection = new EventEmitter<object>();  
  @Output() semiClusterSelection = new EventEmitter<object>();  
  @Input() isDisabled;
  @Input() showClusters;
  neighborhoods:Array<string> = [];
  neighborhoodsBackUp:Array<string> = [];
  neighborhoodsPanelOpenState:Boolean = false;
  fullCollectionPanelOpenState:Boolean = false;
  semiCollectionPanelOpenState:Boolean = false;
	@ViewChild('neighborhoodsSelection',{static:false}) neighborhoodsSelection: MatSelect;
  @ViewChild('fullCollectionSelection',{static:false}) fullCollectionSelection: MatSelect;
  @ViewChild('semiCollectionSelection',{static:false}) semiCollectionSelection: MatSelect; 
  clustersFullInfo:Array<object> = [];
  fullClusterCollection:Array<object> = [];
  semiClusterCollection:Array<object> = [];
  fullClusterCollectionBackUp:Array<object> = [];
  semiClusterCollectionBackUp:Array<object> = [];
  disableClearBtn:Boolean = true;
  disableSubmitBtn:Boolean = true;
  allBinsBackUp:Array<object> = [];
  isAdmin: boolean = false;
  preventManage: boolean = false;
  user_role_id = 0;
  neighborhoodFilter:string = '';
  fullCollectionFilter:string = '';
  semiCollectionFilter:string = '';

  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,private apiService:ApiService,
    private apiQuery:ApiQuery,private dialog: MatDialog) {
    this.translator.currentLangEmitter$
    .subscribe(async value=>{						
      this.translationsObj = await this.translator.getTranslation(value).toPromise();							 
      this.currentLang = value;	
    })
   }

  ngOnInit() {
    this.onResize();
		this.responsiveService.checkWidth();
    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}
      if ((user["user_role_id"] == 2) || (user["user_role_id"] == 1) || (user["user_role_id"] == 5) || (user["user_role_id"] == 6)) {
				this.isAdmin = true;
			}
      if(user["user_role_id"] == 3){
        this.preventManage = true;
      }
      this.user_role_id = user["user_role_id"];
    });

    this.apiQuery.neighborhoodsList$.subscribe(v => {	
      let ngh = [];
      _.each(v, (site) => {
        _.each(site["site_NBRH_list"], (neighborhood) => {
          ngh.push(neighborhood);
        });
      });
      this.neighborhoodsBackUp = ngh;
      this.neighborhoods = ngh;        
      this.neighborhoodFilter = '';     
    });

    this.apiQuery.sitesClustersList$.subscribe(clusters => {	
			if(clusters.length == 0) return;
      this.clustersFullInfo = clusters;

      this.fullClusterCollection = this.clustersFullInfo.filter(cluster => {
        return cluster["cluster_type_id"] == 1; 
      });
      this.fullClusterCollectionBackUp = this.fullClusterCollection;
      this.semiClusterCollection = this.clustersFullInfo.filter(cluster => {
        return cluster["cluster_type_id"] == 2; 
      });
      this.semiClusterCollectionBackUp = this.semiClusterCollection;
      this.fullCollectionFilter = '';
      this.semiCollectionFilter = '';
		});

    this.apiQuery.filteredBasicBins$.subscribe(bins => {
      if(bins.length == 0) return;
      this.allBinsBackUp = bins;
    });
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  showClustersbtn(){
    if(this.isDisabled){return;}
    this.showClusters = true;	
    this.disableClearBtn = false;
    this.clusterClicked.emit(true);
  }

  hideClustersbtn(){
    if(this.isDisabled){return;}
    this.showClusters = false;	
    if(this.disableSubmitBtn){
      this.disableClearBtn = true;
    }
    this.clusterClicked.emit(false);
  }

  manageBins(){
    let chosenBins = [];
    if(this.disableSubmitBtn){
      return;
    }
    _.each(this.allBinsBackUp, (bin) => {
      if (this.neighborhoodsSelection["selectedOptions"].selected.some(neighborhood => neighborhood.value.nbrh_name == bin["Bin_location"].bin_neighbourhood)
      || this.fullCollectionSelection["selectedOptions"].selected.some(cluster => cluster.value.cluster_id == bin["cluster_id"])
      || this.semiCollectionSelection["selectedOptions"].selected.some(cluster => cluster.value.cluster_id == bin["cluster_id"])) {																					
        chosenBins.push(bin);
      }
    });
    this.dialog.open(ManageBinsModalComponent, { panelClass: 'bulkBins-container' , data: {chosenBins : chosenBins} });  
  }

  clearSelection(){
    if(this.disableClearBtn){
      return;
    }
    if(this.showClusters){
      this.hideClustersbtn();
    }
    this.neighborhoodsSelection["selectedOptions"].clear();
    this.fullCollectionSelection["selectedOptions"].clear();
    this.semiCollectionSelection["selectedOptions"].clear();
    this.disableClearBtn = true;
    this.disableSubmitBtn = true;
    this.resetClicked.emit(true);
  }

  applyFilterNeighborhoods(value){
    this.neighborhoods = this.neighborhoodsBackUp.filter(neighborhood => {
      return neighborhood["nbrh_name"].toString().includes(value); 
    });
  }

  applyFilterFullCollection(value){
    this.fullClusterCollection = this.fullClusterCollectionBackUp.filter(cluster => {
      return cluster["cluster_name"].toString().includes(value); 
    });
  }

  applyFilterSemiCollection(value){
    this.semiClusterCollection = this.semiClusterCollectionBackUp.filter(cluster => {
      return cluster["cluster_name"].toString().includes(value); 
    });
  }

  changeSemiCluster(type){
    let chosenChbx = {value : type.option.value, selected : type.option.selected}; 
    this.semiClusterSelection.emit(chosenChbx)
    if(this.semiCollectionSelection["selectedOptions"].selected.length > 0 ||
    this.fullCollectionSelection["selectedOptions"].selected.length > 0 ||
    this.neighborhoodsSelection["selectedOptions"].selected.length > 0){
      this.disableClearBtn = false;
      this.disableSubmitBtn = false;
    }else{
      if(!this.showClusters){
        this.disableClearBtn = true;
      }
      this.disableSubmitBtn = true;
    }
	}

  changeFullCluster(type){
    let chosenChbx = {value : type.option.value, selected : type.option.selected}; 
    this.fullClusterSelection.emit(chosenChbx)
    if(this.semiCollectionSelection["selectedOptions"].selected.length > 0 ||
    this.fullCollectionSelection["selectedOptions"].selected.length > 0 ||
    this.neighborhoodsSelection["selectedOptions"].selected.length > 0){
      this.disableClearBtn = false;
      this.disableSubmitBtn = false;
    }else{
      if(!this.showClusters){
        this.disableClearBtn = true;
      }
      this.disableSubmitBtn = true;
    }
  }

  changeNeighborhood(type){
    let chosenChbx = {value : type.options[0].value, selected : type.options[0].selected}; 
    this.neighborhoodsSelected.emit(chosenChbx)
    if(this.semiCollectionSelection["selectedOptions"].selected.length > 0 ||
    this.fullCollectionSelection["selectedOptions"].selected.length > 0 ||
    this.neighborhoodsSelection["selectedOptions"].selected.length > 0){
      this.disableClearBtn = false;
      this.disableSubmitBtn = false;
    }else{
      if(!this.showClusters){
        this.disableClearBtn = true;
      }
      this.disableSubmitBtn = true;
    }
  }

  manageCluster(cluster){
    this.dialog.open(ManageClusterModalComponent, { panelClass: 'bulkBins-container' , data: {chosenCluster : cluster} });
  }
}

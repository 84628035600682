@if (!isMobile) {
  <div class="popup-width">
    <div class="modal-title">
      <span>{{ 'LIST_COLUMNS.ADD_USER' | translate }}</span>
    </div>
    <form class="form-content" [formGroup]="ElForm">
      <div class="form-margins" rtlDiv>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'SITE_MANAGMENT.ACCOUNT_NAME' | translate }}</div>
          <mat-form-field class="event-type-input event-types" rtlDiv>
            <mat-select class="full-height" rtlDiv formControlName="userAccount" name="userAccount">
              @for (account of userAccounts; track account) {
                <mat-option [value]="account.account_id" class="list-item">
                  <span class="flex-binInfo" rtlDiv>{{account.account_name}}</span>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.USER_NAME' | translate }}</div>
          <mat-form-field class="event-type-input event-types" rtlDiv>
            <input type="text" class="input-site" matInput formControlName="userName" rtlDiv>
          </mat-form-field>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.EMAIL' | translate }}</div>
          <mat-form-field [floatLabel]="options.value.floatLabel" class="event-type-input event-types" rtlDiv>
            <input type="email" class="input-site" matInput formControlName="email" rtlDiv placeholder="example@email.com">
          </mat-form-field>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'LOGIN.PASSWORD' | translate }}</div>
          <mat-form-field class="event-type-input event-types" rtlDiv>
            <input type="text" class="input-site" matInput formControlName="password" rtlDiv>
          </mat-form-field>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.FIRST_NAME' | translate }}</div>
          <mat-form-field class="event-type-input event-types" rtlDiv>
            <input type="text" class="input-site" matInput formControlName="firstName" rtlDiv>
          </mat-form-field>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.LAST_NAME' | translate }}</div>
          <mat-form-field class="event-type-input event-types" rtlDiv>
            <input type="text" class="input-site" matInput formControlName="lastName" rtlDiv>
          </mat-form-field>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.PHONE_NUMBER' | translate }}</div>
          <mat-form-field [floatLabel]="options.value.floatLabel" class="event-type-input event-types" rtlDiv>
            <input type="number" [ngClass]="{'rtlPhone':currentLang != 'iw','ltrPhone':currentLang == 'iw'}" (input)="checkLength($event)" class="input-site" matInput formControlName="phoneNumber" maxLength="12" placeholder="(XXX) XXX-XXXXXX">
          </mat-form-field>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.COMPANY' | translate }}</div>
          <mat-form-field class="event-type-input event-types" rtlDiv>
            <input type="text" class="input-site" matInput formControlName="company" rtlDiv>
          </mat-form-field>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.PERMISSIONS' | translate }}</div>
          <mat-form-field class="event-type-input event-types" rtlDiv>
            <mat-select class="full-height" rtlDiv formControlName="permissions" name="permissions">
              @for (role of userRoles; track role) {
                <mat-option [value]="role.user_role_id" class="list-item">
                  <span class="flex-binInfo" rtlDiv>{{currentLang != 'iw' ? role.user_role_name : role.user_role_name_hebrew}}</span>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'NEW_WORKPLAN.SITES' | translate }}</div>
          <mat-form-field class="event-type-input event-types" rtlDiv>
            <mat-select class="full-height" rtlDiv formControlName="sitesList" name="sitesList" multiple>
              @for (site of sitesList; track site) {
                <mat-option class="list-item-sites" [value]="site.site_id" rtlDiv>
                  <span class="flex-binInfo" rtlDiv>{{site.site_name}}</span>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.USAGE_TYPE' | translate }}</div>
          <mat-form-field class="event-type-input event-types" rtlDiv>
            <mat-select class="full-height" rtlDiv formControlName="usageType" name="usageType">
              @for (type of usageTypes; track type) {
                <mat-option [value]="type.usage_type_id" class="list-item">
                  <span class="flex-binInfo" rtlDiv>{{currentLang != 'iw' ? type.usage_type_name : type.usage_type_name_hebrew}}</span>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="full-width" rtlDiv>
          <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.CAPACITY_DISPLAY' | translate }}</div>
          <mat-form-field class="event-type-input event-types" rtlDiv>
            <mat-select class="full-height" rtlDiv formControlName="capacityDisplayType" name="capacityDisplayType">
              @for (type of capacityDisplayTypes; track type) {
                <mat-option [value]="type.capacity_display_type_id" class="list-item">
                  <span class="flex-binInfo" rtlDiv>{{currentLang != 'iw' ? type.capacity_display_type_name : type.capacity_display_type_name_hebrew}}</span>
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div id="error-msg">
          @if(password_error){
            <div class="full-width password-error" rtlDiv>
              <div rtlDiv>{{ 'LIST_COLUMNS.INVALID_PASS' | translate }}</div>
              <div rtlDiv>{{ 'LIST_COLUMNS.INVALID_PASS_MSG' | translate }}</div>
            </div>
          }
          @if(email_error){
            <div class="full-width password-error" rtlDiv>
              <div rtlDiv>{{ 'LIST_COLUMNS.INVALID_EMAIL' | translate }}</div>
            </div>
          }
          @if(user_name_error){
            <div class="full-width password-error" rtlDiv>
              <div rtlDiv>{{ 'LIST_COLUMNS.INVALID_USER_NAME' | translate }}</div>
            </div>
          }
        </div>
      </div>
    </form>
    <div [ngClass]="{'rtldir': currentLang != 'iw','ltrdir':currentLang == 'iw'}">
      <button [ngClass]="{'apply-btn' : ElForm.valid && !submitClicked,'not-apply-btn' : !ElForm.valid || submitClicked}" [disabled]="!ElForm.valid && !submitClicked" (click)="applyChanges()">{{ 'TRUCK_MAP.APPLY_CHANGES' | translate }}</button>
      <button class="cancel_btn" (click)="closeModal()">{{ 'TRUCK_MAP.CANCEL' | translate }}</button>
    </div>
  </div>
}

@if (isMobile) {
  <div>
  </div>
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, QueryList, ViewChildren, ViewRef } from '@angular/core';
import _ from 'lodash';
import moment from 'moment';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { MatDialog } from '@angular/material/dialog';
import { TranslatorService } from '../../../services/translator_service';
import { ResolveTicketModalComponent } from '../resolve-ticket-modal/resolve-ticket-modal.component';
import { GoogleMap, GoogleMapsModule, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router, RouterModule } from '@angular/router';
@Component({
  selector: 'app-ticket-management-map',
  standalone: true,
  imports: [GoogleMapsModule,MapMarker,MapInfoWindow,GoogleMap,RouterModule],
  templateUrl: './ticket-management-map.component.html',
  styleUrl: './ticket-management-map.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketManagementMapComponent {
  @ViewChildren(MapInfoWindow) infoWindows!: QueryList<MapInfoWindow>;

  mapOptions: google.maps.MapOptions = {
    streetViewControl : false,
    disableDefaultUI : false,
    fullscreenControl : true,
    zoomControl : false
  };

  mobileMapOptions: google.maps.MapOptions = {
    streetViewControl: true,
    disableDefaultUI : false,
    fullscreenControl : true,
    zoomControl : false
  };

  currentLang;
	translationsObj;
  isMobile: Boolean;

  checkOrReplacementMap: any = null;
  markersCheckOrReplacementArray = [];
	checkOrReplacement:Array<object> = [];
  binDataCheckOrReplacement:Array<object> = [];
  checkOrReplacementFilterTxt:string = '';

  installationMap: any = null;
  markersInstallationArray = [];
	installation:Array<object> = [];
  binDataInstallation:Array<object> = [];
  installationFilterTxt:string = '';

  cancellationMap: any = null;
  markersCancellationArray = [];
	cancellation:Array<object> = [];
  binDataCancellation:Array<object> = [];
  cancellationFilterTxt:string = '';

  binsToDisplay:Array<object> = [];
  selectedTabIndx:any = 0;
	@Input() set selectedTab(selectedTab:any) {
    this.selectedTabIndx = selectedTab;
  }
  @Input() set filteredCheckOrReplacementValue(filteredCheckOrReplacementValue:any) {
    this.checkOrReplacementFilterTxt = filteredCheckOrReplacementValue;
    if(this.checkOrReplacementMap){
      if (filteredCheckOrReplacementValue == "") {
        _.each(this.markersCheckOrReplacementArray, (item) => {										
            item.visible = true;          
				});
        this.loadAPIWrapperInternal(this.checkOrReplacementMap,this.markersCheckOrReplacementArray.filter(item => item.visible == true));
      }else{
				_.each(this.markersCheckOrReplacementArray, (item) => {										
					if((item["ticket_id"] && item["ticket_id"].toString().includes(filteredCheckOrReplacementValue)) || (item["bin_name"] && item["bin_name"].toString().toLowerCase().includes(filteredCheckOrReplacementValue))){
						item.visible = true;	
					}else{
            item.visible = false;
          }		
				});
        this.loadAPIWrapperInternal(this.checkOrReplacementMap, this.markersCheckOrReplacementArray.filter(item => item.visible == true));
			}
    }
  }
  @Input() set filteredInstallationValue(filteredInstallationValue:any) {
    this.installationFilterTxt = filteredInstallationValue;
    if(this.installationMap){
      if (filteredInstallationValue == "") {
        _.each(this.markersInstallationArray, (item) => {										
          item.visible = true;          
        });
        this.loadAPIWrapperInternal(this.installationMap,this.markersInstallationArray.filter(item => item.visible == true));
      }else{
				_.each(this.markersInstallationArray, (item) => {										
					if((item["ticket_id"] && item["ticket_id"].toString().includes(filteredInstallationValue)) || (item["bin_name"] && item["bin_name"].toString().toLowerCase().includes(filteredInstallationValue))){
            item.visible = true;
          }else{
            item.visible = false;
          }			
				});
        this.loadAPIWrapperInternal(this.installationMap, this.markersInstallationArray.filter(item => item.visible == true));
			}
    }
  }
  @Input() set filteredCancellationValue(filteredCancellationValue:any) {
    this.cancellationFilterTxt = filteredCancellationValue;
    if(this.cancellationMap){
      if (filteredCancellationValue == "") {
        _.each(this.markersCancellationArray, (item) => {										
          item.visible = true;          
        });
        this.loadAPIWrapperInternal(this.cancellationMap,this.markersCancellationArray.filter(item => item.visible == true));
      }else{
				_.each(this.markersCancellationArray, (item) => {										
					if((item["ticket_id"] && item["ticket_id"].toString().includes(filteredCancellationValue)) || (item["bin_name"] && item["bin_name"].toString().toLowerCase().includes(filteredCancellationValue))){
            item.visible = true;
          }else{
            item.visible = false;
          }			
				});
        this.loadAPIWrapperInternal(this.cancellationMap, this.markersCancellationArray.filter(item => item.visible == true));
			}
    }
  }
	private readonly destroyCheckOrReplacement$ = new Subject();
	private readonly destroyInstallation$ = new Subject();
	private readonly destroyCancellation$ = new Subject();
  @Output() markerSelected = new EventEmitter<object>();  
	user_role_id = 0;

  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,private apiQuery:ApiQuery,
    private cd: ChangeDetectorRef,private dialog: MatDialog,private router: Router) { 
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();

    this.apiQuery.user$.subscribe(user => {
      if (!user) {
        return;
      }		
      this.user_role_id = user["user_role_id"];
    }); 
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  getRelevantCheckOrReplacementMarkers(){
    combineLatest(
      this.apiQuery.cRTicketsList$,
      this.apiQuery.filteredBasicBins$
      )
      .pipe(takeUntil(this.destroyCheckOrReplacement$))
      .subscribe(([cRTicketsList,bins])=>{	
        if(cRTicketsList.length == 0 || bins.length == 0){return;}
        let checkOrReplacement = [];	
        _.each(cRTicketsList, (item) => {					
          if(item["TicketsList"]){
            if(item["TicketsList"]["open"]!=null){			
              _.each(item["TicketsList"]["open"], (ticket) => {	
                ticket.site_name = item["site_name"];
                ticket.site_id = item["site_id"];
                this.getRelevantBin(bins,ticket,"1");
                checkOrReplacement.push(ticket);			
              });		
            }	
          }		
        });

        const checkOrReplacementFiltered = _.map(checkOrReplacement, ticket => {
          return {
            ticket_id : ticket["ticket_id"],
            eventTypeEn : ticket["ticketData"].ticket_event_type_name,
            eventCauseEn : ticket["ticketData"].ticket_event_cause_name,
            priorityEn : ticket["ticketData"].priority_name,
            site_name : ticket["site_name"],
            bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_name) : '',
            bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_address) : '',
            reporter : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name + " " + _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
            created : ticket["ticket_id_info"].length > 0 ? ticket["ticket_id_info"][0].action_time : '',
            expected_arrival_date : ticket["ticketData"].expected_arrival_date == '0000-00-00 00:00:00' ? (ticket["ticketData"].priority_id == 3 ? moment().add(1,'weeks').format('YYYY-MM-DD HH:mm:ss') : moment().add(2,'weeks').format('YYYY-MM-DD HH:mm:ss')) : moment(ticket["ticketData"].expected_arrival_date).format('YYYY-MM-DD HH:mm:ss'),
            eventCauseHe : ticket["ticketData"].ticket_event_cause_name_hebrew,
            priorityHe : ticket["ticketData"].priority_name_hebrew,
            eventTypeHe : ticket["ticketData"].ticket_event_type_name_hebrew,
            site_id : ticket["site_id"],
            ticket_event_cause_id : ticket["ticketData"].ticket_event_cause_id,
            priority_id : ticket["ticketData"].priority_id,
            ticket_event_type_id : ticket["ticketData"].ticket_event_type_id,
            ticketInfo : ticket["ticket_id_info"],
            pic_url : ticket["ticketData"].pic_url,
            first_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name : '',
            last_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
            user_id : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].user_id: '',
            bin_id : ticket["bin_id"],
            comment : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].comment: '',
            bin_lat : ticket["bin_lat"],
            bin_lon :  ticket["bin_lon"],
            bin_collection_mode : ticket["bin_collection_mode"]
          };
        });	
    
        this.checkOrReplacement = checkOrReplacementFiltered;			      
        this.binsToDisplay = this.checkOrReplacement;
        this.setCheckOrReplacementMarkersOnMap(this.binsToDisplay);
        this.binDataCheckOrReplacement = this.binsToDisplay;
        if(this.checkOrReplacementFilterTxt == ''){
          this.loadAPIWrapperInternal(this.checkOrReplacementMap,this.binDataCheckOrReplacement);
        }else{
          let filteredArr = [];
          _.each(this.binDataCheckOrReplacement, (item) => {										
            if((item["ticket_id"].toString().includes(this.checkOrReplacementFilterTxt)) || (item["bin_name"].toString().toLowerCase().includes(this.checkOrReplacementFilterTxt))){
              filteredArr.push(item);	
            }			
          });
          this.loadAPIWrapperInternal(this.checkOrReplacementMap, filteredArr);
        }
        this.cd.detectChanges();
      });
  }

  getRelevantInstallationMarkers(){
    combineLatest(
      this.apiQuery.insTicketsList$,
      this.apiQuery.filteredBasicBins$
      )
      .pipe(takeUntil(this.destroyInstallation$))
      .subscribe(([insTicketsList,bins])=>{	
        if(insTicketsList.length == 0 || bins.length == 0){return;}
        let installation = [];
        _.each(insTicketsList, (item) => {					
          if(item["TicketsList"]){
            if(item["TicketsList"]["open"]!=null){
              _.each(item["TicketsList"]["open"], (ticket) => {	
                ticket.site_name = item["site_name"];
                ticket.site_id = item["site_id"];
                this.getRelevantBin(bins,ticket,"1");
                installation.push(ticket);			
              });	
            }									
          }		
        });
        
        const installationFiltered = _.map(installation, ticket => {
          return {
            ticket_id : ticket["ticket_id"],
            eventTypeEn : ticket["ticketData"].ticket_event_type_name,
            eventCauseEn : ticket["ticketData"].ticket_event_cause_name,
            priorityEn : ticket["ticketData"].priority_name,
            site_name : ticket["site_name"],
            bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_name) : '',
            bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_address) : '',
            reporter : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name + " " + _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
            created : ticket["ticket_id_info"][0].action_time,
            closed :  _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : '',
            solution_time : moment(moment(_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : '').format('YYYY-MM-DD HH:mm:ss')).diff(moment(ticket["ticket_id_info"][0].action_time).format('YYYY-MM-DD HH:mm:ss'), 'days'),
            eventCauseHe : ticket["ticketData"].ticket_event_cause_name_hebrew,
            priorityHe : ticket["ticketData"].priority_name_hebrew,
            eventTypeHe : ticket["ticketData"].ticket_event_type_name_hebrew,
            site_id : ticket["site_id"],
            ticket_event_cause_id : ticket["ticketData"].ticket_event_cause_id,
            priority_id : ticket["ticketData"].priority_id,
            ticket_event_type_id : ticket["ticketData"].ticket_event_type_id,
            ticketInfo : ticket["ticket_id_info"],
            pic_url : ticket["ticketData"].pic_url,
            expected_arrival_date : ticket["ticketData"].expected_arrival_date,
            first_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name : '',
            last_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
            user_id : _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].user_id : '',
            bin_id : ticket["bin_id"],
            comment : _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].comment: '',
            bin_lat : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_lat : 0,
            bin_lon : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_lon : 0,
            bin_collection_mode : ticket["bin_collection_mode"]
          };
        });	
        this.installation = installationFiltered;			
        this.binsToDisplay = this.installation;
        this.setInstallationMarkersOnMap(this.binsToDisplay);
        this.binDataInstallation = this.binsToDisplay;
        if (this.installationFilterTxt == "") {
          this.loadAPIWrapperInternal(this.installationMap,this.binDataInstallation);
        }else{
          let filteredArr = [];
          _.each(this.binDataInstallation, (item) => {										
            if((item["ticket_id"].toString().includes(this.installationFilterTxt)) || (item["bin_name"].toString().toLowerCase().includes(this.installationFilterTxt))){
              filteredArr.push(item);	
            }			
          });
          this.loadAPIWrapperInternal(this.installationMap, filteredArr);
        }
        this.cd.detectChanges();
      });
  }

  getRelevantCancellationMarkers(){
    combineLatest(
      this.apiQuery.rmTicketsList$,
      this.apiQuery.filteredBasicBins$
      )
      .pipe(takeUntil(this.destroyCancellation$))
      .subscribe(([rmTicketsList,bins])=>{	
        if(rmTicketsList.length == 0 || bins.length == 0){return;}
        let cancellation = [];
        _.each(rmTicketsList, (item) => {					
          if(item["TicketsList"]){
            if(item["TicketsList"]["open"]!=null){
              _.each(item["TicketsList"]["open"], (ticket) => {	
                ticket.site_name = item["site_name"];
                ticket.site_id = item["site_id"];
                this.getRelevantBin(bins,ticket,"1");
                cancellation.push(ticket);			
              });	
            }									
          }		
        });

        const cancellationFiltered = _.map(cancellation, ticket => {
          return {
            ticket_id : ticket["ticket_id"],
            eventTypeEn : ticket["ticketData"].ticket_event_type_name,
            eventCauseEn : ticket["ticketData"].ticket_event_cause_name,
            priorityEn : ticket["ticketData"].priority_name,
            site_name : ticket["site_name"],
            bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_name) : '',
            bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? (ticket["ticketData"].ticket_event_type_id == 3 ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].installed_bin_address : _.groupBy(ticket["ticket_id_info"], "status_id")["1"][_.groupBy(ticket["ticket_id_info"], "status_id")["1"].length - 1].bin_address) : '',
            reporter : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name + " " + _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
            created : ticket["ticket_id_info"][0].action_time,
            closed :  _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : '',
            solution_time : moment(moment(_.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][0].action_time : '').format('YYYY-MM-DD HH:mm:ss')).diff(moment(ticket["ticket_id_info"][0].action_time).format('YYYY-MM-DD HH:mm:ss'), 'days'),
            eventCauseHe : ticket["ticketData"].ticket_event_cause_name_hebrew,
            priorityHe : ticket["ticketData"].priority_name_hebrew,
            eventTypeHe : ticket["ticketData"].ticket_event_type_name_hebrew,
            site_id : ticket["site_id"],
            ticket_event_cause_id : ticket["ticketData"].ticket_event_cause_id,
            priority_id : ticket["ticketData"].priority_id,
            ticket_event_type_id : ticket["ticketData"].ticket_event_type_id,
            ticketInfo : ticket["ticket_id_info"],
            pic_url : ticket["ticketData"].pic_url,
            expected_arrival_date : ticket["ticketData"].expected_arrival_date,
            first_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_first_name : '',
            last_name : _.groupBy(ticket["ticket_id_info"], "status_id")["1"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["1"][0].user_last_name: '',
            user_id : _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].user_id : '',
            bin_id : ticket["bin_id"],
            comment : _.groupBy(ticket["ticket_id_info"], "status_id")["4"] ? _.groupBy(ticket["ticket_id_info"], "status_id")["4"][_.groupBy(ticket["ticket_id_info"], "status_id")["4"].length - 1].comment: '',
            bin_lat : ticket["bin_lat"],
            bin_lon : ticket["bin_lon"],
            bin_collection_mode : ticket["bin_collection_mode"]
          };
        });	
    
        this.cancellation = cancellationFiltered;			
        this.binsToDisplay = this.cancellation;
        this.setCancellationMarkersOnMap(this.binsToDisplay);
        this.binDataCancellation = this.binsToDisplay;
        if (this.cancellationFilterTxt == "") {
          this.loadAPIWrapperInternal(this.cancellationMap,this.binDataCancellation);
        }else{
          let filteredArr = [];
          _.each(this.binDataCancellation, (item) => {										
            if((item["ticket_id"].toString().includes(this.cancellationFilterTxt)) || (item["bin_name"].toString().toLowerCase().includes(this.cancellationFilterTxt))){
              filteredArr.push(item);	
            }			
          });
          this.loadAPIWrapperInternal(this.cancellationMap, filteredArr);
        }
        this.cd.detectChanges();
      });
  }

  ngOnDestroy(){
		this.destroyCheckOrReplacement$.next(true);
		this.destroyCheckOrReplacement$.complete();

    this.destroyInstallation$.next(true);
		this.destroyInstallation$.complete();

    this.destroyCancellation$.next(true);
		this.destroyCancellation$.complete();
		if (!this.cd['destroyed'] || !(this.cd as ViewRef).destroyed) {			
			this.cd.detectChanges();
		}
	}

  fullCheckOrReplacementScreenchange(ev?){
    if (ev == 0 && document.fullscreenElement) {
      document.exitFullscreen();
    }
  }

  fullInstallationScreenchange(ev?){
    if (ev == 0 && document.fullscreenElement) {
      document.exitFullscreen();
    }
  }

  fullCancellationScreenchange(ev?){
    if (ev == 0 && document.fullscreenElement) {
      document.exitFullscreen();
    }
  }
  
  loadCheckOrReplacementMap(map: any){
		this.checkOrReplacementMap = map;	
    this.addYourLocationButton(this.checkOrReplacementMap,'center-checkOrReplacement')
    this.getRelevantCheckOrReplacementMarkers();
  }

  loadInstallationMap(map: any){
    this.installationMap = map;
    this.addYourLocationButton(this.installationMap,'center-installation')	
    this.getRelevantInstallationMarkers();
  }

  loadCancellationMap(map: any){
    this.cancellationMap = map;
    this.addYourLocationButton(this.cancellationMap,'center-cancellation')	
    this.getRelevantCancellationMarkers();
  }

  setCheckOrReplacementMarkersOnMap(bins){
    this.markersCheckOrReplacementArray = [];
    let binsHash = this.mapBinsByCoordinates(bins);		
		_.forOwn(binsHash, (value :any, key) => {	
			if(value.length > 1){
				const icon = this.clusterToIcon(value);	
				const marker = {
          item:value,
          icon: icon,
          lat: Number(value[0].bin_lat),
          lng: Number(value[0].bin_lon),		
          bin_lon: Number(value[0].bin_lon),
          bin_lat: Number(value[0].bin_lat),		
          visible: true,
          draggable: false,
          isInfoWindowOpen: false,
          bin_id: value[0].bin_id,
          bin_name:value[0].bin_name,
          bin_address: value[0].bin_address,
          ticket_event_type_id: value[0].ticket_event_type_id,
          eventTypeEn: value[0].eventTypeEn,
          eventTypeHe: value[0].eventTypeHe,
          partOfCluster: true
				};
        this.markersCheckOrReplacementArray.push(marker);
			}else{
				value.forEach((element, index, array) => {
					const b = element;						
					const icon = this.BinToIcon(b);
					const marker = {
						icon: icon,
						lat: Number(b.bin_lat),
						lng: Number(b.bin_lon),		
            bin_lon: Number(b.bin_lon),
            bin_lat: Number(b.bin_lat),			
						visible: true,
            draggable: false,
            isInfoWindowOpen: false,
            bin_id: b.bin_id,
            bin_name:b.bin_name,
            bin_address: b.bin_address,
            ticket_event_type_id: b.ticket_event_type_id,
            priority_id: b.priority_id,
            eventTypeEn: b.eventTypeEn,
            eventTypeHe: b.eventTypeHe,
            eventCauseEn: b.eventCauseEn,
            eventCauseHe: b.eventCauseHe,
            priorityEn: b.priorityEn,
            priorityHe: b.priorityHe,
            site_id: b.site_id,
            site_name: b.site_name,
            ticket_id: b.ticket_id,
            partOfCluster: false
					};	
          this.markersCheckOrReplacementArray.push(marker);
				});
			}
		});
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition( pos => {  
        const relevantMarker = _.filter(this.markersCheckOrReplacementArray, (res) => {      
          return res["id"] == "startMarkerCheckOrReplacement";
        });  
        if(relevantMarker.length == 0){
          const startMarker = new google.maps.Marker({
            position: {
              lat: Number(pos.coords.latitude),
              lng: Number(pos.coords.longitude)
            },
            icon: '../../../../assets/images/workplans-page/user-location.svg',
            map: this.checkOrReplacementMap
          });
          startMarker["id"] = "startMarkerCheckOrReplacement";
          this.markersCheckOrReplacementArray.push(startMarker);
        }
      }, error => {
      },{maximumAge:10000, timeout:5000, enableHighAccuracy: true});
    }
  }

  private clusterToIcon(cluster){				
		let icon = '';		

		if(cluster.length == 2){
			icon = `../../../../assets/images/bins/all/eventCluster1.svg`;
		}else if(cluster.length == 3){
			icon = `../../../../assets/images/bins/all/eventCluster2.svg`;
		}else if(cluster.length == 4){
			icon = `../../../../assets/images/bins/all/eventCluster3.svg`;
		}else{
			icon = `../../../../assets/images/bins/all/Cluster4.svg`;
		}			
		return icon;
		
	}

  mapClick() {	
		this.infoWindows.forEach((infoWindow: MapInfoWindow ) => {
			infoWindow.close();
		});
	}
  
	clickedMarker(bin) {	
    if(bin.ticket_event_type_id == 1){
      this.markersCheckOrReplacementArray.forEach((marker) => {
        if(marker.icon_secondary){
          marker.icon = marker.icon_secondary;
          delete marker.icon_secondary;
        }
      });
    }else if(bin.ticket_event_type_id == 3){
      this.markersInstallationArray.forEach((marker) => {
        if(marker.icon_secondary){
          marker.icon = marker.icon_secondary;
          delete marker.icon_secondary;
        }
      });
    }else if(bin.ticket_event_type_id == 4){
      this.markersCancellationArray.forEach((marker) => {
        if(marker.icon_secondary){
          marker.icon = marker.icon_secondary;
          delete marker.icon_secondary;
        }
      });
    }

    bin.icon_secondary = bin.icon;
    if(!bin.partOfCluster){
      bin.icon = this.BinToIconHighlighted(bin);
    }
    this.markerSelected.emit(bin);
            
    let content;
    if(!bin.partOfCluster){
      let closeEventPopUp = document.createElement('div');
      if(this.isMobile){
        closeEventPopUp.className = 'mobile-tooltip-action-btn';
      }else{
        closeEventPopUp.className = 'tooltip-action-btn';
      }
      closeEventPopUp.setAttribute("name",bin.ticket_id);
      closeEventPopUp.id = bin.ticket_id;
      closeEventPopUp.innerHTML = this.translationsObj.TICKET_MANAGEMENT.CLOSE;
  
      closeEventPopUp.addEventListener('click', ()=> {
        let binChosen = [];
        let class_name = '';
        if(this.isMobile){
          class_name = 'popup-container-mobile';
        }else{
          class_name = 'bulkBins-container';
        }
        if(this.selectedTabIndx == 0){
          binChosen = this.checkOrReplacement.filter(item => item["ticket_id"] == bin["ticket_id"]);
        }else if(this.selectedTabIndx == 1){
          binChosen = this.installation.filter(item => item["ticket_id"] == bin["ticket_id"]);
        }else if(this.selectedTabIndx == 2){
          binChosen = this.cancellation.filter(item => item["ticket_id"] == bin["ticket_id"]);
        }
        if(binChosen.length > 0){
          this.dialog.closeAll();
          if(binChosen[0]["ticket_event_type_id"] == 1){
            this.fullCheckOrReplacementScreenchange(0);
            this.dialog.open(ResolveTicketModalComponent, { panelClass: class_name , data: {chosenTicket : binChosen[0],status: 1,selectedTab:0} });
          }else if(binChosen[0]["ticket_event_type_id"] == 3){
            this.fullInstallationScreenchange(0);
            this.dialog.open(ResolveTicketModalComponent, { panelClass: class_name , data: {chosenTicket : binChosen[0],status: 1,selectedTab:1} });
          }else if(binChosen[0]["ticket_event_type_id"] == 4){
            this.fullCancellationScreenchange(0);
            this.dialog.open(ResolveTicketModalComponent, { panelClass: class_name , data: {chosenTicket : binChosen[0],status: 1,selectedTab:2} });
          }
        }
      });

      let wazeLink = document.createElement('a');
      wazeLink.className = 'tooltip-waze';
      wazeLink.setAttribute("href",`https://waze.com/ul?ll=${bin.lat},${bin.lng}`);
  
      let wazeImg = document.createElement('img');
      wazeImg.setAttribute("src","assets/images/dashboard/waze.svg");
      wazeLink.appendChild(wazeImg);
  
      content = document.createElement('div');
      content.className = 'iw-content';
      content.setAttribute("style","padding-left:unset;padding-right:unset;");
  
      let tooltipId = document.createElement('div');
      tooltipId.className = 'tooltip-id';
  
      let tooltipValue = document.createElement('div');
      tooltipValue.className = 'tooltip-value';
  
      let disp = document.createElement('div');
      disp.className = 'disp space-between';
  
      let dispGap = document.createElement('div');
      dispGap.className = 'disp gap';
  
      let dispHeaderTooltip = document.createElement('div');
      dispHeaderTooltip.className = 'disp header-tooltip';
  
      let span1 = document.createElement('span');
      span1.innerHTML = this.translationsObj.LIST_COLUMNS.ID;
  
      let span2 = document.createElement('span');
      span2.innerHTML = bin.ticket_id;
      dispHeaderTooltip.appendChild(span1);
      dispHeaderTooltip.appendChild(span2);
  
      let dispHeaderTooltip2 = document.createElement('div');
      dispHeaderTooltip2.className = 'disp header-tooltip';
  
      let span3 = document.createElement('span');
      span3.innerHTML = bin.ticket_event_type_id != 3 ? '|' : '';
      
      let buttonToBinLiveInfo = document.createElement('button');
      buttonToBinLiveInfo.className = 'binNameHref-truckMap tooltip-value bin-name binInfo_btn';
      buttonToBinLiveInfo.addEventListener("click", (e) => {
        this.router.navigate(['/binLiveInfoPage'], { queryParams: { pageName: 'dashboard', binId: bin.bin_id } });
      });
      buttonToBinLiveInfo.innerHTML = bin.ticket_event_type_id != 3 ? bin.bin_name : '';
      
      dispHeaderTooltip2.appendChild(span3);
      dispHeaderTooltip2.appendChild(buttonToBinLiveInfo);
      dispGap.appendChild(dispHeaderTooltip);
      dispGap.appendChild(dispHeaderTooltip2);
      disp.appendChild(dispGap);
      if(!this.isMobile && this.user_role_id != 2){
        disp.appendChild(closeEventPopUp);
      }
      tooltipValue.appendChild(disp);
      tooltipId.appendChild(tooltipValue);
      
      let tooltipId2 = document.createElement('div');
      tooltipId2.className = 'tooltip-id border-bottom';
      
      let tooltipAddress = document.createElement('div');
      tooltipAddress.className = 'tooltip-address address-waze gap';
      tooltipAddress.setAttribute("title",bin.bin_address);
      tooltipAddress.innerHTML = bin.bin_address + wazeLink.outerHTML;
      
      tooltipId2.appendChild(tooltipAddress);
      tooltipId.appendChild(tooltipId2);
      content.appendChild(tooltipId);
      
      let tooltipDividerMargin = document.createElement('div');
      tooltipDividerMargin.className = 'tooltip-divider-margin';
      
      let disp2 = document.createElement('div');
      disp2.className = 'disp space-between';
      
      let titleInfo = document.createElement('span');
      titleInfo.className = 'title-info';
      titleInfo.innerHTML = this.translationsObj.TICKET_MANAGEMENT.EVENT_CAUSE;
      
      let valueInfo = document.createElement('span');
      valueInfo.className = 'value-info';
      valueInfo.innerHTML = this.currentLang == 'iw' ? bin.eventCauseHe : bin.eventCauseEn;
      
      disp2.appendChild(titleInfo);
      disp2.appendChild(valueInfo);
      
      let disp3 = document.createElement('div');
      disp3.className = 'disp space-between';
      
      let titleInfo2 = document.createElement('span');
      titleInfo2.className = 'title-info';
      titleInfo2.innerHTML = this.translationsObj.TICKET_MANAGEMENT.PRIORITY;
      
      let valueInfo2 = document.createElement('span');
      valueInfo2.className = 'value-info';
      valueInfo2.innerHTML = this.currentLang == 'iw' ? bin.priorityHe : bin.priorityEn;
      
      disp3.appendChild(titleInfo2);
      disp3.appendChild(valueInfo2);
      
      tooltipDividerMargin.appendChild(disp2);
      tooltipDividerMargin.appendChild(disp3);
      if(this.isMobile && this.user_role_id != 2){
        tooltipDividerMargin.appendChild(closeEventPopUp);
      }
      content.appendChild(tooltipDividerMargin);
    }else{      
			let binTooltips = '';
      for(let i=0; i < bin.item.length; i++){
        let closeEventPopUp = document.createElement('div');
        if(this.isMobile){
          closeEventPopUp.className = 'mobile-tooltip-action-btn';
        }else{
          closeEventPopUp.className = 'tooltip-action-btn';
        }
        closeEventPopUp.setAttribute("name",bin.item[i].ticket_id);
        closeEventPopUp.id = bin.item[i].ticket_id;
        closeEventPopUp.innerHTML = this.translationsObj.TICKET_MANAGEMENT.CLOSE;

        document.addEventListener('click',(e)=>{
          if(e.target && e.target['className'] == 'tooltip-action-btn' || e.target['className'] == 'mobile-tooltip-action-btn'){
            let binChosen = [];
            let class_name = '';
            if(this.isMobile){
              class_name = 'popup-container-mobile';
            }else{
              class_name = 'bulkBins-container';
            }
            if(this.selectedTabIndx == 0){
              binChosen = this.checkOrReplacement.filter(item => item["ticket_id"] == e.target['id']);
            }else if(this.selectedTabIndx == 1){
              binChosen = this.installation.filter(item => item["ticket_id"] == e.target['id']);
            }else if(this.selectedTabIndx == 2){
              binChosen = this.cancellation.filter(item => item["ticket_id"] == e.target['id']);
            }
            if(binChosen.length > 0){
              this.dialog.closeAll();
              if(binChosen[0]["ticket_event_type_id"] == 1){
                this.fullCheckOrReplacementScreenchange(0);
                this.dialog.open(ResolveTicketModalComponent, { panelClass: class_name , data: {chosenTicket : binChosen[0],status: 1,selectedTab:0} });
              }else if(binChosen[0]["ticket_event_type_id"] == 3){
                this.fullInstallationScreenchange(0);
                this.dialog.open(ResolveTicketModalComponent, { panelClass: class_name , data: {chosenTicket : binChosen[0],status: 1,selectedTab:1} });
              }else if(binChosen[0]["ticket_event_type_id"] == 4){
                this.fullCancellationScreenchange(0);
                this.dialog.open(ResolveTicketModalComponent, { panelClass: class_name , data: {chosenTicket : binChosen[0],status: 1,selectedTab:2} });
              }
            }
          }
        });

        let wazeLink = document.createElement('a');
        wazeLink.className = 'tooltip-waze';
        wazeLink.setAttribute("href",`https://waze.com/ul?ll=${bin.lat},${bin.lng}`);
    
        let wazeImg = document.createElement('img');
        wazeImg.setAttribute("src","assets/images/dashboard/waze.svg");
        wazeLink.appendChild(wazeImg);
    
        let carouselItem = document.createElement('div');
        carouselItem.className = 'carousel-item';
        if(i == 0){
          carouselItem.className += ' active';
        }

        content = document.createElement('div');
        content.className = 'iw-content';
        content.setAttribute("style","padding-left:unset;padding-right:unset;");
    
        let tooltipId = document.createElement('div');
        tooltipId.className = 'tooltip-id';
    
        let tooltipValue = document.createElement('div');
        tooltipValue.className = 'tooltip-value';
    
        let disp = document.createElement('div');
        disp.className = 'disp space-between';
    
        let dispGap = document.createElement('div');
        dispGap.className = 'disp gap';
    
        let dispHeaderTooltip = document.createElement('div');
        dispHeaderTooltip.className = 'disp header-tooltip';
    
        let span1 = document.createElement('span');
        span1.innerHTML = this.translationsObj.LIST_COLUMNS.ID;
    
        let span2 = document.createElement('span');
        span2.innerHTML = bin.item[i].ticket_id;
        dispHeaderTooltip.appendChild(span1);
        dispHeaderTooltip.appendChild(span2);
    
        let dispHeaderTooltip2 = document.createElement('div');
        dispHeaderTooltip2.className = 'disp header-tooltip';
    
        let span3 = document.createElement('span');
        span3.innerHTML = bin.item[i].ticket_event_type_id != 3 ? '|' : '';
        
        let buttonToBinLiveInfo = document.createElement('a');
        buttonToBinLiveInfo.setAttribute('class','binNameHref-truckMap tooltip-value bin-name binInfo_btn');
        buttonToBinLiveInfo.setAttribute('href',`/binLiveInfoPage?pageName=dashboard&binId=${bin.item[i].bin_id}`);
        buttonToBinLiveInfo.innerHTML = bin.item[i].ticket_event_type_id != 3 ? bin.item[i].bin_name : '';
        
        dispHeaderTooltip2.appendChild(span3);
        dispHeaderTooltip2.appendChild(buttonToBinLiveInfo);
        dispGap.appendChild(dispHeaderTooltip);
        dispGap.appendChild(dispHeaderTooltip2);
        disp.appendChild(dispGap);
        if(!this.isMobile && this.user_role_id != 2){
          disp.appendChild(closeEventPopUp);
        }
        tooltipValue.appendChild(disp);
        tooltipId.appendChild(tooltipValue);
        
        let tooltipId2 = document.createElement('div');
        tooltipId2.className = 'tooltip-id border-bottom';
        
        let tooltipAddress = document.createElement('div');
        tooltipAddress.className = 'tooltip-address address-waze gap';
        tooltipAddress.setAttribute("title",bin.item[i].bin_address);
        tooltipAddress.innerHTML = bin.item[i].bin_address + wazeLink.outerHTML;
        
        tooltipId2.appendChild(tooltipAddress);
        tooltipId.appendChild(tooltipId2);
        content.appendChild(tooltipId);
        
        let tooltipDividerMargin = document.createElement('div');
        tooltipDividerMargin.className = 'tooltip-divider-margin';
        
        let disp2 = document.createElement('div');
        disp2.className = 'disp space-between';
        
        let titleInfo = document.createElement('span');
        titleInfo.className = 'title-info';
        titleInfo.innerHTML = this.translationsObj.TICKET_MANAGEMENT.EVENT_CAUSE;
        
        let valueInfo = document.createElement('span');
        valueInfo.className = 'value-info';
        valueInfo.innerHTML = this.currentLang == 'iw' ? bin.item[i].eventCauseHe : bin.item[i].eventCauseEn;
        
        disp2.appendChild(titleInfo);
        disp2.appendChild(valueInfo);
        
        let disp3 = document.createElement('div');
        disp3.className = 'disp space-between';
        
        let titleInfo2 = document.createElement('span');
        titleInfo2.className = 'title-info';
        titleInfo2.innerHTML = this.translationsObj.TICKET_MANAGEMENT.PRIORITY;
        
        let valueInfo2 = document.createElement('span');
        valueInfo2.className = 'value-info';
        valueInfo2.innerHTML = this.currentLang == 'iw' ? bin.item[i].priorityHe : bin.item[i].priorityEn;
        
        disp3.appendChild(titleInfo2);
        disp3.appendChild(valueInfo2);
        
        tooltipDividerMargin.appendChild(disp2);
        tooltipDividerMargin.appendChild(disp3);
        if(this.isMobile && this.user_role_id != 2){
          tooltipDividerMargin.appendChild(closeEventPopUp);
        }
        content.appendChild(tooltipDividerMargin);
        carouselItem.appendChild(content);
        binTooltips += carouselItem.outerHTML;
      }

      let prevNextArrow = document.createElement('button');
      prevNextArrow.setAttribute("data-bs-target","#carouselExampleFade");
      prevNextArrow.setAttribute("data-bs-slide","prev");
      prevNextArrow.className = 'prevNextArrow carousel-control';

      let imgPrev = document.createElement('img');
      imgPrev.src = "assets/images/dashboard/pagination_arrow.png";
      imgPrev.alt = "";
      imgPrev.className = this.currentLang != 'iw' ? "rotation" : "";

      let spanPrev = document.createElement('span');
      spanPrev.className = 'sr-only';
      spanPrev.innerHTML = '';

      prevNextArrow.appendChild(imgPrev);
      prevNextArrow.appendChild(spanPrev);

      let span = document.createElement('span');
      span.style.position = 'relative';
      span.style.bottom = '3px';
      span.style.padding = '0px 8px';
      span.innerHTML = '|';

      let nextArrow = document.createElement('button');
      nextArrow.setAttribute("data-bs-target","#carouselExampleFade");
      nextArrow.setAttribute("data-bs-slide","next");
      nextArrow.className = 'prevNextArrow carousel-control';

      let imgNext = document.createElement('img');
      imgNext.src = "assets/images/dashboard/pagination_arrow.png";
      imgNext.alt = "";
      imgNext.className = this.currentLang == 'iw' ? "rotation" : "";

      let spanNext = document.createElement('span');
      spanNext.className = 'sr-only';
      spanNext.innerHTML = '';

      nextArrow.appendChild(imgNext);
      nextArrow.appendChild(spanNext);

      let carousel = document.createElement('div');
      carousel.id = "carouselExampleFade";
      carousel.className = 'carousel slide carousel-fade';
      carousel.setAttribute("data-ride","carousel");
      carousel.style.top = '9px';

      let carouselInnerFrags = document.createElement('div');
      carouselInnerFrags.id = "carousel-inner-frags";
      carouselInnerFrags.className = 'carousel-inner';
      carouselInnerFrags.style.overflow = 'unset';

      carouselInnerFrags.innerHTML = binTooltips;
      carousel.appendChild(carouselInnerFrags);

      content = document.createElement('div');
      content.appendChild(prevNextArrow);
      content.appendChild(span);
      content.appendChild(nextArrow);
      content.appendChild(carousel);
    }
    
    let node;
    node = document.createElement("div");
    node.id = "infoWindow-popup";
    node.className = 'infoWindow-class';
    node.appendChild(content);
    
    setTimeout(() => {
      this.checkIfInfoWindowExist();
      document.getElementById("singleBinInfo").append(node);
    }, 100);

    if(this.isMobile){
      if(this.selectedTabIndx == 0){
        setTimeout(function(){ 
          var el = document.getElementById('checkOrReplacementTable');
          el.scrollIntoView({behavior: "smooth"});
        }, 100);
      }else if(this.selectedTabIndx == 1){
        setTimeout(function(){ 
          var el = document.getElementById('installationTable');
          el.scrollIntoView({behavior: "smooth"});
        }, 100);
      }else if(this.selectedTabIndx == 2){
        setTimeout(function(){ 
          var el = document.getElementById('cancellationTable');
          el.scrollIntoView({behavior: "smooth"});
        }, 100);
      }
    }
	}

  openMarkerInfoWindow(marker: MapMarker, markerIndex: number) {
		this.infoWindows.forEach((infoWindow: MapInfoWindow, index: number ) => {
		  if (index === markerIndex) {
			infoWindow.open(marker);
		  } else {
			infoWindow.close();
		  }
		});
	}

  isNumber(val): boolean { return typeof val === 'number'; }
  
	checkIfInfoWindowExist(){
		if(document.getElementById('infoWindow-popup')){
			document.getElementById('infoWindow-popup').remove();
		}
	}

  setInstallationMarkersOnMap(bins){
    this.markersInstallationArray = [];
    let binsHash = this.mapBinsByCoordinates(bins);		
		_.forOwn(binsHash, (value :any, key) => {	
			if(value.length > 1){

			}else{
				value.forEach((element, index, array) => {
					const b = element;						
					const icon = this.BinToIcon(b);
					
					const marker = {
						icon: icon,
						lat: Number(b.bin_lat),
						lng: Number(b.bin_lon),				
            bin_lon: Number(b.bin_lon),
            bin_lat: Number(b.bin_lat),	
						visible: true,
            draggable: false,
            isInfoWindowOpen : false,
            bin_id: b.bin_id,
            bin_name:b.bin_name,
            bin_address: b.bin_address,
            ticket_event_type_id: b.ticket_event_type_id,
            priority_id: b.priority_id,
            eventTypeEn: b.eventTypeEn,
            eventTypeHe: b.eventTypeHe,
            eventCauseEn: b.eventCauseEn,
            eventCauseHe: b.eventCauseHe,
            priorityEn: b.priorityEn,
            priorityHe: b.priorityHe,
            site_id: b.site_id,
            site_name: b.site_name,
            ticket_id: b.ticket_id
					};	
          this.markersInstallationArray.push(marker);
				});
			}
		});
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition( pos => {  
        const relevantMarker = _.filter(this.markersInstallationArray, (res) => {      
          return res["id"] == "startMarkerInstallation";
        });  
        if(relevantMarker.length == 0){
          const startMarker = new google.maps.Marker({
            position: {
              lat: Number(pos.coords.latitude),
              lng: Number(pos.coords.longitude)
            },
            icon: '../../../../assets/images/workplans-page/user-location.svg',
            map: this.installationMap
          });
          startMarker["id"] = "startMarkerInstallation";
          this.markersInstallationArray.push(startMarker);
        }
      }, error => {
      },{maximumAge:10000, timeout:5000, enableHighAccuracy: true});
    }
  }

  setCancellationMarkersOnMap(bins){
    this.markersCancellationArray = [];
    let binsHash = this.mapBinsByCoordinates(bins);		
		_.forOwn(binsHash, (value :any, key) => {	
			if(value.length > 1){

			}else{
				value.forEach((element, index, array) => {
					const b = element;						
					const icon = this.BinToIcon(b);
					
					const marker = {
						icon: icon,
						lat: Number(b.bin_lat),
						lng: Number(b.bin_lon),				
            bin_lon: Number(b.bin_lon),
            bin_lat: Number(b.bin_lat),	
						visible: true,
            draggable: false,
            isInfoWindowOpen : false,
            bin_id: b.bin_id,
            bin_name:b.bin_name,
            bin_address: b.bin_address,
            ticket_event_type_id: b.ticket_event_type_id,
            priority_id: b.priority_id,
            eventTypeEn: b.eventTypeEn,
            eventTypeHe: b.eventTypeHe,
            eventCauseEn: b.eventCauseEn,
            eventCauseHe: b.eventCauseHe,
            priorityEn: b.priorityEn,
            priorityHe: b.priorityHe,
            site_id: b.site_id,
            site_name: b.site_name,
            ticket_id: b.ticket_id
					};	
          this.markersCancellationArray.push(marker);
				});
			}
		});
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition( pos => {  
        const relevantMarker = _.filter(this.markersCancellationArray, (res) => {      
          return res["id"] == "startMarkerCancellation";
        });  
        if(relevantMarker.length == 0){
          const startMarker = new google.maps.Marker({
            position: {
              lat: Number(pos.coords.latitude),
              lng: Number(pos.coords.longitude)
            },
            icon: '../../../../assets/images/workplans-page/user-location.svg',
            map: this.cancellationMap
          });
          startMarker["id"] = "startMarkerCancellation";
          this.markersCancellationArray.push(startMarker);
        }
      }, error => {
      },{maximumAge:10000, timeout:5000, enableHighAccuracy: true});
    }
  }

  loadAPIWrapperInternal(map: any,bins) {
		if (!map) return;
		const userPosition = {
			lat: Number("32.085300"),
			lng: Number("34.781768")
		};
		if (bins.length > 0) {
			const bounds = new google.maps.LatLngBounds();
			bins.map(bin => {
				if(bin.bin_lat != 0 && bin.bin_lon != 0 && 
					bin.bin_lat != null && bin.bin_lon != null && 
					bin.bin_lat != '' && bin.bin_lon != ''){	
						bounds.extend({ lat: Number(bin.bin_lat), lng: Number(bin.bin_lon) });				
				}	
			});
			map.fitBounds(bounds);
		} else {
			map.panTo(userPosition);
		}
	}

  BinToIconHighlighted(val){
    let icon = '';
    if(val.ticket_event_type_id == 1){
      if(val.priority_id == 1){
				icon = `../../../../assets/images/bins/low-priority-inactive-highlighted.svg`;
      }else if(val.priority_id == 2){
        icon = `../../../../assets/images/bins/medium-priority-inactive-highlighted.svg`;
      }else{
        icon = `../../../../assets/images/bins/high-priority-inactive-highlighted.svg`;
      }
    }else{
      if(val.priority_id == 1){
				icon = `../../../../assets/images/bins/low-priority-event-highlighted.svg`;
      }else if(val.priority_id == 2){
        icon = `../../../../assets/images/bins/medium-priority-event-highlighted.svg`;
      }else{
        icon = `../../../../assets/images/bins/high-priority-event-highlighted.svg`;
      }
    }
    return icon;
  }

  BinToIcon(val){
    let icon = '';
    if(val.ticket_event_type_id == 1){
      if(val.priority_id == 1){
				icon = `../../../../assets/images/bins/low-priority-inactive.svg`;
      }else if(val.priority_id == 2){
        icon = `../../../../assets/images/bins/medium-priority-inactive.svg`;
      }else{
        icon = `../../../../assets/images/bins/high-priority-inactive.svg`;
      }
    }else{
      if(val.priority_id == 1){
				icon = `../../../../assets/images/bins/low-priority-event.svg`;
      }else if(val.priority_id == 2){
        icon = `../../../../assets/images/bins/medium-priority-event.svg`;
      }else{
        icon = `../../../../assets/images/bins/high-priority-event.svg`;
      }
    }
    return icon;
  }

  mapBinsByCoordinates(bins) {
		const binsHash = {};
		_.each(bins, b => {
      if (binsHash[b.bin_lat+","+b.bin_lon] !== undefined) {
        binsHash[b.bin_lat+","+b.bin_lon].push(b);
      } else {
        binsHash[b.bin_lat+","+b.bin_lon] = [b];
      }
		});
		return binsHash;
	}

  getRelevantBin(bins,ticket,status){
    if(_.groupBy(ticket["ticket_id_info"], "status_id")[status]){
      if(ticket["ticketData"].ticket_event_type_id == 3){
        if(_.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].installed_bin_name != ''){
          const relevant_bin = bins.filter(bin => bin["site_id"] == ticket.site_id && bin["bin_name"] == _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].installed_bin_name);
          if(relevant_bin.length > 0){
            ticket.bin_id = relevant_bin[0]["bin_id"];
            ticket.bin_lat = relevant_bin[0]["bin_latitude"];
            ticket.bin_lon = relevant_bin[0]["bin_longitude"];
            ticket.bin_collection_mode = relevant_bin[0]["bin_collection_mode"];
          }else{
            ticket.bin_id = '';
            ticket.bin_lat = 0.00000000;
            ticket.bin_lon = 0.00000000;
            ticket.bin_collection_mode = 0;
          }
        }else{
          ticket.bin_id = '';
          ticket.bin_lat = _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].installed_lat;
          ticket.bin_lon = _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].installed_lon;
          ticket.bin_collection_mode = 0;
        }
      }else{
        ticket.bin_id = _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].bin_id;
        ticket.bin_lat = _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].bin_lat;
        ticket.bin_lon = _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].bin_lon;
        ticket.bin_collection_mode = 0;
      }
    }
  }

  addYourLocationButton(map,id) {
		let controlDiv = document.createElement('div');
		let firstChild = document.createElement('button');
		firstChild.type = 'button';
		firstChild.style.backgroundColor = '#fff';
		firstChild.style.border = 'none';
		firstChild.style.outline = 'none';
		firstChild.style.width = '40px';
		firstChild.style.height = '40px';
		firstChild.style.borderRadius = '2px';
		firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
		firstChild.style.cursor = 'pointer';
		firstChild.style.marginRight = '10px';
		firstChild.style.padding = '0px';
		firstChild.title = 'Your Location';
		controlDiv.appendChild(firstChild);
		
		let secondChild = document.createElement('div');
		secondChild.style.margin = 'calc(50% - 18px / 2)';
		secondChild.style.width = '18px';
		secondChild.style.height = '18px';
		secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
		secondChild.style.backgroundSize = '180px 18px';
		secondChild.style.backgroundPosition = '0px 0px';
		secondChild.style.backgroundRepeat = 'no-repeat';
		secondChild.id = id;
		firstChild.appendChild(secondChild);
		
		google.maps.event.addListener(map, 'dragend', function() {
			document.getElementById(id).style.backgroundPosition = '0px 0px';
		});

		firstChild.addEventListener('click', ()=> {
			var imgX = '0';
			var animationInterval = setInterval(function(){
				if(imgX == '-18') imgX = '0';
				else imgX = '-18';
				document.getElementById(id).style.backgroundPosition = imgX+'px 0px';
			}, 500);
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition( pos => {        
				map.setCenter({lat: pos.coords.latitude, lng: pos.coords.longitude});
        map.setZoom(15);
				clearInterval(animationInterval);
				document.getElementById(id).style.backgroundPosition = '-144px 0px';
				}, error => {
				},{maximumAge:10000, timeout:5000, enableHighAccuracy: true});
			}else{
				clearInterval(animationInterval);
				document.getElementById(id).style.backgroundPosition = '0px 0px';
			}
		});
		map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
	}
}

import { Component } from '@angular/core';
import { Chart, ChartModule } from 'angular-highcharts';
import _ from 'lodash';
import { TranslatorService } from '../../../services/translator_service';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { MatCardModule } from '@angular/material/card';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { Router } from '@angular/router';
import { ApiStore } from '../../../web-services/api/api.store';

@Component({
  selector: 'app-black-status-pie',
  standalone: true,
  imports: [MatCardModule, RTLDivDirectiveDirective,TranslateModule,MatDividerModule,ChartModule],
  templateUrl: './black-status-pie.component.html',
  styleUrl: './black-status-pie.component.scss'
})
export class BlackStatusPieComponent {
  isMobile: Boolean;
	currentLang;
	translationsObj;
	spinnerActive:boolean = true;
	donutChart: any;

  constructor(private translator: TranslatorService,private responsiveService: ResponsiveService,
    private apiQuery:ApiQuery,private router: Router,private apiStore: ApiStore) {
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
   }

  ngOnInit() {
    this.onResize();
	this.responsiveService.checkWidth();

    this.apiQuery.binCapacityAnalysis$.subscribe((data:any) => {
      let total = 0;
      let firstPart = 0;
      let secondPart = 0;
      let thirdPart = 0;
	  let forthPart = 0;
      this.spinnerActive = true;		
      if(data.length == 0) return;
      this.spinnerActive = false;	
      _.each(data, (binCapacityAnalysis) => {
        _.each(binCapacityAnalysis.binsWorkPlanCapacityAnalysis, (binWorkPlanCapacityAnalysis) => {
          _.each(binWorkPlanCapacityAnalysis.over_flow_dates, (over_flow_date) => {
            if(over_flow_date.event_num_of_day >= 1 && over_flow_date.event_num_of_day <= 3){
              firstPart++;
            }else if(over_flow_date.event_num_of_day >= 4 && over_flow_date.event_num_of_day <= 7){
              secondPart++;
            }else if(over_flow_date.event_num_of_day >= 8 && over_flow_date.event_num_of_day <= 14){
              thirdPart++;
            }else{
			  forthPart++;
			}
          });
        });
      });
      total = firstPart + secondPart + thirdPart + forthPart;
      this.initDonut(total,firstPart,secondPart,thirdPart,forthPart);
    });
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  initDonut(total,firstPart,secondPart,thirdPart,forthPart){
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
			this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			this.currentLang = value;
			const donut = new Chart({
				chart: {
					plotBackgroundColor: null,
					plotBorderWidth: 0,
					plotShadow: false
				},
				legend: {
					rtl: this.currentLang != 'iw' ? false : true,
					align: this.currentLang != 'iw' ? 'right' : 'left',
					verticalAlign: 'top',
					layout: 'vertical',
					x: 0,
					y: 20,
					itemMarginBottom:16,	
					itemStyle: {
						fontFamily:'Open Sans, sans-serif',
						fontSize:'14px',
						color:'#3A4743',
						fontWeight: '400'
					},	
				},
				title:{
					text: '',
					align: 'center',
					verticalAlign: 'middle',
					y: -40,
					style:{
						fontFamily:'Open Sans, sans-serif',
						fontSize:'14px',
						fontWeight: '600'				
					}
				},
				tooltip: {	
					borderWidth:0,
					backgroundColor:null,
					useHTML: true,	
					formatter: function(){
						return `<div style="padding: 4px;background: var(--neutral-black-90, #2A3338);display: flex;border-radius: 3px;box-shadow: 0px 2px 8px 0px rgba(93, 120, 112, 0.20);">
						<div style="font-family: Open Sans, sans-serif;font-size: 10px;font-weight: 700;font-style: normal;color: var(--Neutral-White, var(--Schemes-On-Primary, #FFF));">${this.point.name} - ${this.point.y} (${parseInt(this.point.percentage.toString())}%)</div>
						</div>`
					  }
				},
				credits: {
				  enabled: false
				},
				plotOptions: {
				  pie: {
					allowPointSelect: true,
					cursor: "pointer",
					dataLabels: {
					  enabled: false,
					  distance: -50,
					},
					center: ["50%", "50%"],
					size: "100%",
					showInLegend: true
				  }
				},
				series: [
				  {
					name: "",
					point: {
						events: {
							legendItemClick: function() {
								return false;
							},
							click: (event: any) => {
								if (this.router.url.includes("insights")) {
								  this.apiStore.update({ selectedInsightsPieChart: event });
								}
							}
						}
					},
					data: [
						{
							name:  `15+`,
							color: '#023E8A',
							y: forthPart
						},
						{
							name: `8-14`,
							color: '#0077B6',
							y: thirdPart
						},
						{
							name: `4-7`,
							color: '#48CAE4', 
							y: secondPart
						},
						{
							name:  `1-3`,
							color: '#ADE8F4',
							y: firstPart
						}
					],
					type: "pie"
				  }
				]
			  });
			  this.donutChart = donut;
			  this.spinnerActive = false;
		})
	  }
}

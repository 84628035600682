@if (!isMobile) {
  <div class="workplan-schedule">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="title">{{ 'WORKPLAN.TITLE' | translate }}</mat-card-title>
        <div (click)="getToday()" class="btn btn-outline-secondary todayBtn" mwlCalendarToday [(viewDate)]="viewDate" [style.visibility]="!spinnerActive ? 'visible' : 'hidden'">
          <span class="todayTxt">{{ 'WORKPLAN.TODAY' | translate }}</span>
        </div>
      </mat-card-header>
      <mat-divider class="top-divider"></mat-divider>
      <div class='spinner-container' [style.visibility]="spinnerActive ? 'visible' : 'hidden'">
        <div class="spinner"></div>
      </div>
      <mat-card-content [style.visibility]="!spinnerActive ? 'visible' : 'hidden'">
        <div>
          <div class="prevNextCal">
            <div class='table-export-container' rtlDiv>
              @if (spinnerExcelActive) {
                <div class="spinner-border excel-spinner" role="status" rtlDiv>
                  <span class="sr-only"></span>
                </div>
              }
              <div class='table-export-btns-wrapper' (click)="exportToCsv()" rtlDiv>
                <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
              </div>
            </div>
            <div class="btn-group">
              <button
                class="btn"
                mwlCalendarPreviousView
                (click)="decrement()"
                [disabled]="prevBtnDisabled"
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()">
                @if (currentLang != 'iw') {
                  <img src="../../../../assets/images/workplans-page/backCalendar.svg"/>
                }
                @if (currentLang == 'iw') {
                  <img src="../../../../assets/images/workplans-page/nextCalendar.svg"/>
                }
              </button>
              <div class="btn monthTxt">{{ viewDate | calendarDate:(view + 'ViewTitle'): locale }}</div>
              <button
                class="btn"
                mwlCalendarNextView
                (click)="increment()"
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()">
                @if (currentLang != 'iw') {
                  <img alt="" src="../../../../assets/images/workplans-page/nextCalendar.svg"/>
                }
                @if (currentLang == 'iw') {
                  <img alt="" src="../../../../assets/images/workplans-page/backCalendar.svg"/>
                }
              </button>
            </div>
          </div>
          <div>
            <mwl-calendar-month-view
              [cellTemplate]="customCellTemplatePc"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              [activeDayIsOpen]="activeDayIsOpen"
              [locale]="locale"
              [weekStartsOn]="weekStartsOn">
            </mwl-calendar-month-view>
          </div>
          <ng-template #customCellTemplatePc let-day="day" let-locale="locale">
            <div class="cal-cell-top" style="height: 70%;" (click)="dayClicked(day)">
              <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            </div>
            @if (day.events.length > 0) {
              <div class="cell-totals">
                @for (event of day.events | slice:0:3; track event) {
                  <div class="menu-Cmp dropdown">
                    <img alt="" [src]="event['icon']"/>
                    @if (currentLang != 'iw') {
                      <span (click)="handleEvent(event,true)" class="dropdown-toggle cut-txt-schedule badge badge-{{ event['id'] }}">
                        {{validateDateEn(event['scheduleTxt'])}} {{event['title']}}
                      </span>
                    }
                    @if (currentLang == 'iw') {
                      <span (click)="handleEvent(event,true)" class="dropdown-toggle cut-txt-schedule badge badge-{{ event['id'] }}">
                        {{validateDateIw(event['scheduleTxt'])}} {{event['title']}}
                      </span>
                    }
                  </div>
                }
                @if (day.events.length > 3) {
                  <div class="menu-Cmp dropdown">
                    <span data-bs-toggle="dropdown" class="dropdown-toggle moreEv">
                      {{ 'WORKPLAN.SHOW_ALL' | translate }}
                      <div class="dropdown-menu list-items-show" rtlDiv>
                        <div class="day-top-frame">
                          <div class="cal-cell-top container-show" style="height: 70%;" (click)="dayClicked(day)">
                            <span class="cal-day-number-show">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                          </div>
                          <div class="close-btn" rtlDiv data-toggle="dropdown">
                            <img class="close-img" src="../../../assets/images/dashboard/closeX.svg">
                          </div>
                        </div>
                        @for (event of day.events; track event) {
                          <div class="menuItem badge" title="{{validateDateIw(event.scheduleTxt)}} {{event.title}}" (click)="handleEvent(event,true)" rtlDiv>
                            <img alt="" src={{event.icon}}/>
                            @if (currentLang != 'iw') {
                              <span class="cut-txt-schedule truckVal" rtlDiv>{{validateDateEn(event.scheduleTxt)}} {{event.title}}</span>
                            }
                            @if (currentLang == 'iw') {
                              <span class="cut-txt-schedule truckVal" rtlDiv>{{validateDateIw(event.scheduleTxt)}} {{event.title}}</span>
                            }
                          </div>
                        }
                      </div>
                    </span>
                  </div>
                }
              </div>
            }
          </ng-template>
        </div>
        <div class="menu-Cmp keys-icons" rtlDiv>
          <div class="key" rtlDiv>
            <img class="key-icon" alt="" src="../../../../assets/images/workplans-page/userGenerated.svg"/>
            <span rtlDiv class="key-desc">{{ 'WORKPLAN.PLANNED-KEY' | translate }}</span>
          </div>
          <div class="key" rtlDiv>
            <img class="key-icon" alt="" src="../../../../assets/images/workplans-page/Executed.svg"/>
            <span rtlDiv class="key-desc">{{ 'WORKPLAN.EXECUTED-KEY' | translate }}</span>
          </div>
          <div class="key" rtlDiv>
            <img class="key-icon" alt="" src="../../../../assets/images/workplans-page/recommended.svg"/>
            <span rtlDiv class="key-desc">{{ 'WORKPLAN.RECOMMENDED-KEY' | translate }}</span>
          </div>
          <div class="key" rtlDiv>
            <img class="key-icon" alt="" src="../../../../assets/images/workplans-page/schedule_automatic.svg"/>
            <span rtlDiv class="key-desc">{{ 'WORKPLAN.SCHEDULED-KEY' | translate }}</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

@if (isMobile) {
  <div class="mobile-workplan-schedule">
    <mat-card>
      <mat-divider class="top-divider"></mat-divider>
      <div class='spinner-container' [style.visibility]="spinnerActive ? 'visible' : 'hidden'">
        <div class="spinner"></div>
      </div>
      <mat-card-content [style.visibility]="!spinnerActive ? 'visible' : 'hidden'">
        <div>
          <div class="mobile-prevNextCal">
            <div class="mobile-calendar-func">
              <div class="mobile-search-excel">
                @if (spinnerExcelActive) {
                  <div class="spinner-border excel-spinner" role="status" rtlDiv>
                    <span class="sr-only"></span>
                  </div>
                }
                <div class='mobile-table-export-container' rtlDiv>
                  <div class='mobile-table-export-btns-wrapper' (click)="exportToCsv()" rtlDiv>
                    <img class='mobile-table-export-img' src="assets/images/dashboard/Excel.svg" alt="" />
                  </div>
                </div>
              </div>
              <div (click)="getToday()" class="btn btn-outline-secondary todayBtn" mwlCalendarToday [(viewDate)]="viewDate" [style.visibility]="!spinnerActive ? 'visible' : 'hidden'">
                <span class="todayTxt">{{ 'WORKPLAN.TODAY' | translate }}</span>
              </div>
            </div>
            <div class="btn-group">
              <div
                class="btn"
                mwlCalendarPreviousView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()">
                @if (currentLang != 'iw') {
                  <img src="../../../../assets/images/workplans-page/backCalendar.svg"/>
                }
                @if (currentLang == 'iw') {
                  <img src="../../../../assets/images/workplans-page/nextCalendar.svg"/>
                }
              </div>
              <div class="btn monthTxt">{{ viewDate | calendarDate:(view + 'ViewTitle'): locale }}</div>
              <div
                class="btn"
                mwlCalendarNextView
                [view]="view"
                [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()">
                @if (currentLang != 'iw') {
                  <img alt="" src="../../../../assets/images/workplans-page/nextCalendar.svg"/>
                }
                @if (currentLang == 'iw') {
                  <img alt="" src="../../../../assets/images/workplans-page/backCalendar.svg"/>
                }
              </div>
            </div>
          </div>
          <div>
            <mwl-calendar-month-view
              [cellTemplate]="customCellTemplateMobile"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              [activeDayIsOpen]="activeDayIsOpen"
              [locale]="locale"
              [weekStartsOn]="weekStartsOn">
            </mwl-calendar-month-view>
          </div>
          <ng-template #customCellTemplateMobile let-day="day" let-locale="locale">
            <div class="cal-cell-top" (click)="dayClicked(day)">
              <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            </div>
            @if (day.events.length > 0) {
              <div class="mobile-cell-totals" (click)="dayClicked(day)">
                <div class="menu-Cmp">
                  <img alt="" src="../../../../assets/images/workplans-page/event-mobile.svg"/>
                </div>
              </div>
            }
          </ng-template>
          @if (day!=undefined) {
            <div rtlDiv>
              <mat-divider class="top-divider"></mat-divider>
              <div class="cal-cell-top container-show">
                <span class="mobile-cal-day-number-show">{{ day.date | date: 'dd/MM/yyyy' }}</span>
              </div>
              @for (event of day.events; track event) {
                <div class="mobile-menuItem badge" [ngClass]="{'mobile-border-btm' : day.events.indexOf(event) < day.events.length-1}" title="{{validateDateIw(event.scheduleTxt)}} {{event.title}}" (click)="handleEvent(event,true)" rtlDiv>
                  <img alt="" src={{event.icon}}/>
                  @if (currentLang != 'iw') {
                    <span class="truckVal" rtlDiv>{{validateDateEn(event.scheduleTxt)}} {{event.title}}</span>
                  }
                  @if (currentLang == 'iw') {
                    <span class="truckVal" rtlDiv>{{validateDateIw(event.scheduleTxt)}} {{event.title}}</span>
                  }
                </div>
              }
            </div>
          }
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import _ from 'lodash';
import { TranslatorService } from '../../../services/translator_service';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ApiService } from '../../../web-services/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bins-actions-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, FormsModule, ReactiveFormsModule, NgbModule],
  templateUrl: './bins-actions-modal.component.html',
  styleUrl: './bins-actions-modal.component.scss'
})
export class BinsActionsModalComponent {
  binName: string;
  currentLang; 
  @Output() showHideModal = new EventEmitter<any>();
  @Input() selectedBin;  
  translate;
  translationsObj;
	isMobile: Boolean;
  status:Array<object> = [];
  ElForm: FormGroup;
  routeParamsSub;
	siteId: any;
  currentStatus:any;
	@Input()
	set tabStatus(tabStatus:any) {
    this.currentStatus =  tabStatus;
    this.apiQuery.pendingStatus$.subscribe((status) => {
      if(!status){return;}
      if(tabStatus == 1){
        this.status = _.filter(status, (st) => {
          return st["unit_reported_issues_type_id"] == 7 || st["unit_reported_issues_type_id"] ==8;
        });
      }else if(tabStatus == 2){
        this.status = _.filter(status, (st) => {
          return st["unit_reported_issues_type_id"] != 7 && st["unit_reported_issues_type_id"] !=8;
        });
      }
    });
  }

	constructor(private translator: TranslatorService,private apiQuery:ApiQuery,private apiService:ApiService,private route: ActivatedRoute,
		public fb: FormBuilder,private responsiveService: ResponsiveService) {
      this.translate = this.translator;
      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
        this.currentLang = value;	
      }); 

      this.routeParamsSub = this.route
      .queryParams
      .subscribe(params => {
        this.siteId = this.route.snapshot.paramMap.get("id"); 				
      });

      this.ElForm = this.fb.group({
        status:'',
      });
  }

  ngOnInit() {
    this.onResize();
		this.responsiveService.checkWidth();
    this.ElForm.controls["status"].setValue('');
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

  public closeModal = () => {
    this.showHideModal.emit(false);
  }

  public saveWorkplan = () => {
    this.LifeCycleAction();  
  }

  async LifeCycleAction(){
    let response;
    let deviceIds = [];
    _.each(this.selectedBin, (item) => {
      deviceIds.push(item.device_id);
    });
    const request = {   
      "device_id_list": deviceIds,
      "unit_reported_issues_type_id":this.ElForm.getRawValue()["status"],
      "comments":"",
    }
    const registrationRequest = {
      "device_id_list" : deviceIds,
      "new_registration_id" : this.currentStatus == 1 ? (this.ElForm.getRawValue()["status"] == 7 ? 4 : 5) : (this.ElForm.getRawValue()["status"] == 9 ? 3 : 6)
    }

    if(this.currentStatus == 2 && this.ElForm.getRawValue()["status"] == 9){      
      this.apiService.setBinRegistrationStatus(registrationRequest).subscribe((v:any) => {            
        this.apiService.GetBinManagmentInfo(this.siteId);
        this.closeModal();
      }); 
    }else{
      response = await this.apiService.setNewLifeCycleAction(request);
      if(response.set_status[0]){
        this.apiService.setBinRegistrationStatus(registrationRequest).subscribe((v:any) => {    
          this.apiService.GetBinManagmentInfo(this.siteId);
          this.closeModal();
        });
      }
    }
  }
}

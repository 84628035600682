import { Component } from '@angular/core';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ApiService } from '../../../web-services/api/api.service';
import moment from 'moment';
import { ApiStore } from '../../../web-services/api/api.store';
import _ from 'lodash';
import * as pako from 'pako';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { DatepickerRangeComponent } from '../../../widgets/datepicker-range/datepicker-range.component';
import { CollectionKpiComponent } from '../../collection-kpi/collection-kpi.component';
import { CollectionStatusPieComponent } from '../../collection-status-pie/collection-status-pie.component';
import { CollectionReportPieComponent } from '../../collection-report-pie/collection-report-pie.component';
import { CollectionsTableComponent } from '../collections-table/collections-table.component';
import { CommonModule } from '@angular/common';
import { combineLatest } from 'rxjs';
@Component({
  selector: 'app-collections',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, MatDividerModule, MatCardModule, DatepickerRangeComponent,
    CollectionKpiComponent,CollectionStatusPieComponent,CollectionReportPieComponent,CollectionsTableComponent,CommonModule],
  templateUrl: './collections.component.html',
  styleUrl: './collections.component.scss'
})
export class CollectionsComponent {
	isMobile: Boolean;
	excel_disabled: boolean = true;
	showSpinner:boolean=false;
	newUrl:string = "";
	reportBody:object={};
	userSite:any;
	selectedDates: any = {
		from: '',
		to: ''
	};
	showKPIBtn : boolean = false;
	showReportPieBtn : boolean = false;
	showReportPieTabs : boolean = false;
	showDatePicker: Boolean = false;
	minDate = '';
	maxDate = '';
	datesDiff : any;
	lastMaxDate:any;
	lastMinDate:any;
	user:object;
	currentLang;
	selected_time = 'day';
	translationsObj;
	timeDateFormat = 'DD.MM.YY';
	lastMonthDay: any = moment(this.minDate).format(this.timeDateFormat);
	constructor(private apiService:ApiService,private translator: TranslatorService,private apiQuery:ApiQuery,
		private apiStore:ApiStore,private responsiveService: ResponsiveService) {
			this.translator.currentLangEmitter$
			.subscribe(async value=>{						
				this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
				this.currentLang = value;			
				if (this.currentLang !== 'iw') {
					this.timeDateFormat = 'MM.DD.YY';
				}
			})
	}
	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();

		combineLatest(
			this.apiQuery.collectionsStatsInCollections$,
			this.apiQuery.collectionsKpis$,
			)
			.pipe()
			.subscribe(([collectionsStats,collectionsKpis])=>{	
				if(collectionsStats.length == 0 || collectionsKpis.length == 0){
					this.excel_disabled = true;
					return;
				}else{
					this.excel_disabled = false;
				}
			});
	}

	exportToCsv(){
		this.getExcelReport();
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	public showHideDatePicker = () => {
		this.showDatePicker = !this.showDatePicker;
	}

	ngOnDestroy() {
		sessionStorage.removeItem('collectionsMinDate');
		sessionStorage.removeItem('collectionsMaxDate');
	}

	public onSelectDates = (selectDates: any) => {
		setTimeout(() => {
			this.showDatePicker = false;
			this.selectedDates.from = selectDates.fromDate;
			this.selectedDates.to = selectDates.toDate;		
			this.minDate = moment(this.selectedDates.from, 'DD.MM.YYYY').format('YYYY-MM-DD 00:00:01');
			this.maxDate = moment(this.selectedDates.to, 'DD.MM.YYYY').format('YYYY-MM-DD 23:59:59');
			sessionStorage.setItem("collectionsMinDate",this.minDate);
			sessionStorage.setItem("collectionsMaxDate",this.maxDate);
	
			sessionStorage.setItem("collectionsStartDate",this.minDate);
			sessionStorage.setItem("collectionsEndDate",this.maxDate);
	
			this.datesDiff = selectDates.fromDate + " - " + selectDates.toDate;		
			if(this.maxDate != this.lastMaxDate || this.minDate != this.lastMinDate){
				let siteCollections = {
					start_date : this.minDate,
					end_date : this.maxDate
				}	  	
				this.apiStore.update({ allCollectionsKpis: [] });
				this.apiStore.update({ collectionsKpis: [] });
				this.apiService.getfilteredSiteCollectionsKpis(siteCollections,JSON.parse(sessionStorage.getItem("chosenSites")));
				let request = {
					site_id : 0,
					start_date : this.minDate,
					end_date : this.maxDate
				}
				this.apiStore.update({ allCollectionsStats: [] });
				this.apiStore.update({ collectionsStatsInCollections: [] });
				this.apiService.getfilteredBinsCollectionsStats(request,5,JSON.parse(sessionStorage.getItem("chosenSites")));
	
				this.lastMinDate = this.minDate;
				this.lastMaxDate = this.maxDate;
				this.apiQuery.user$.subscribe(user => {
					this.user = user;			
				});
			}
			this.changeCollectionReportRange(this.selected_time);
		}, 0);
	}

	public changeCollectionReportRange = (time_range: string) => {
		this.selected_time = time_range;
		this.lastMonthDay = moment(this.minDate).format(this.timeDateFormat);
	}

	getExcelReport(){	
		if(!this.excel_disabled){
			this.showSpinner = true;
			this.excel_disabled = true;
			//@ts-ignore
			document.getElementById("excelExp").disabled = true;
			let sites = [];
			if(JSON.parse(sessionStorage.getItem("chosenSites")) != undefined && 
			JSON.parse(sessionStorage.getItem("chosenSites")).length > 0){
				_.each(JSON.parse(sessionStorage.getItem("chosenSites")), (item) => {   
					if(item.site_id != undefined){
						sites.push(item.site_id.toString().trim())
					}  
				}); 
			}
			else if(sites.length == 0){
				sites.push(this.userSite.toString());
			}	
			const reportLanguage = localStorage.getItem('language') == 'iw' ? 'he' : localStorage.getItem('language');
			this.reportBody = 
			{
				template: {
					shortid: "HvRpdblgP1"
				},
				data: {
					report_configuration: {
						report_lang: reportLanguage,
						sites_list: sites,
						report_time_zone: "2",
						report_start_date: this.minDate,
						report_end_date: this.maxDate
					},
					report_data: {
						binsCollectionsStats: {
							Bins_collections_data: this.apiStore.getValue()["collectionsStatsInCollections"]
						},
						GetSiteCollectionsKpis:{
							SiteCollectionKpiObj: this.apiStore.getValue()["collectionsKpis"]
						}
					}
				},
				options: {
					localization: {
						language: reportLanguage
					},
					preview: true
				} 
			};

			this.apiService.getReport(pako.gzip(JSON.stringify(this.reportBody)))
			.subscribe((v:any) => {			
				this.newUrl = v.split('src=')[1].split(/[ >]/)[0].concat("src=").concat(v.split('src=')[2].split(/[ >]/)[0]);
				this.newUrl = this.newUrl.replace(/"/g,"");		
				if(this.showSpinner){
					this.showSpinner = false;
					this.excel_disabled = false;
					//@ts-ignore
					document.getElementById("excelExp").disabled = false;				
					window.open(`${this.newUrl}`);
				}
			});	
		}	
	}

}

import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslatorService } from '../../../services/translator_service';
import { ApiService } from '../../../web-services/api/api.service';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiStore } from '../../../web-services/api/api.store';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';

import { GoogleMapsModule } from '@angular/google-maps';

@Component({
  selector: 'app-change-location-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, FormsModule, ReactiveFormsModule, GoogleMapsModule],
  templateUrl: './change-location-modal.component.html',
  styleUrl: './change-location-modal.component.scss'
})
export class ChangeLocationModalComponent {
  isMobile: Boolean;
  ElForm: FormGroup;
  latitude: number = 0;
  longitude: number = 0;
  geoCoder;
  zoom: number = 18;
  address: string;
  siteLat : number  = 0;
  siteLon : number  = 0;
  currentLat : number = 0;
  currentLon : number = 0;
  markerIcon : string = '../../../../assets/images/bins/all/greenNotOrdered.svg';
  userIcon : string = '../../../../assets/images/workplans-page/blue_dot_location.png';
  siteIcon : string = '../../../../assets/images/workplans-page/site_start_point.png'
	translate;
	currentLang;
	translationsObj;
  siteObject: any;
  directionsService: google.maps.DirectionsService;
  directionsRenderer: google.maps.DirectionsRenderer;
	map: any = null;
  center: google.maps.LatLngLiteral = {lat: 0, lng: 0};
  mapOptions: google.maps.MapOptions = {
    gestureHandling: 'greedy' // Choose 'cooperative', 'greedy', 'none', or 'auto'
  };
  
  @HostListener('click',['$event']) onclick(event) {
    if(event.target.id == 'you_location_img' || event.target.id == 'googleMapCenter'){
      var imgX = '0';
      var animationInterval = setInterval(function(){
        if(imgX == '-18') imgX = '0';
        else imgX = '-18';
        if(document.getElementById('you_location_img')){
          document.getElementById('you_location_img').style.backgroundPosition = imgX+'px 0px';
        }
      }, 500);

      if (navigator.geolocation) {      
        navigator.geolocation.getCurrentPosition( pos => {                    
          this.currentLon = +pos.coords.longitude;
          this.currentLat = +pos.coords.latitude;
          this.map.setCenter({lat: +pos.coords.latitude, lng: +pos.coords.longitude});
          clearInterval(animationInterval);
          if(document.getElementById('you_location_img')){
            document.getElementById('you_location_img').style.backgroundPosition = '-144px 0px';
          }  
        });
      }
      else{
        clearInterval(animationInterval);
        if(document.getElementById('you_location_img')){
          document.getElementById('you_location_img').style.backgroundPosition = '0px 0px';
        }
      }
    }
  }

  constructor(private dialogRef: MatDialogRef<ChangeLocationModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private dialog: MatDialog,
  private responsiveService: ResponsiveService,private fb: FormBuilder,
  private apiStore:ApiStore,private apiService:ApiService,private translator: TranslatorService,private route: ActivatedRoute,
  private apiQuery:ApiQuery) {
    this.dialogRef.disableClose = true;
    this.translate = this.translator;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		});

    this.ElForm = this.fb.group({
      lat:'',
      lon: ''
    });
   }

  ngOnInit() {
    this.onResize();
		this.responsiveService.checkWidth();
    this.ElForm.controls["lat"].setValue(this.data.chosenBin.lat);    
    this.ElForm.controls["lon"].setValue(this.data.chosenBin.lon);   
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  changeLoc(){
    this.latitude = Number(this.ElForm.getRawValue()['lat']);
    this.longitude = Number(this.ElForm.getRawValue()['lon']);
    this.getAddress(this.latitude, this.longitude);
  }

  setCurrentLocation(lon,lat) {
    this.latitude = Number(lat); //lat
    this.longitude = Number(lon);
    this.ElForm.controls["lat"].setValue(Number(this.latitude).toFixed(8));    
    this.ElForm.controls["lon"].setValue(Number(this.longitude).toFixed(8));  
    
    this.getAddress(this.latitude, this.longitude);
  }

  markerDragEnd($event: any) {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.ElForm.controls["lat"].setValue(this.latitude.toFixed(8));    
    this.ElForm.controls["lon"].setValue(this.longitude.toFixed(8));  
    let destination = {
      lat: Number(this.data.chosenBin.lat),
      lng: Number(this.data.chosenBin.lon)
    }
    let origin = {
      lat: Number(this.latitude),
      lng: Number(this.longitude)
    }
    this.displayRoute(origin,destination);  
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder = new google.maps.Geocoder;
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
        }
      }
    });
    this.center = {lat: latitude, lng: longitude};
  }

  loadAPIWrapper(map: any){
    this.map = map;	
    var controlDiv = document.createElement('div');
    var firstChild = document.createElement('button');
    firstChild.type = 'button';
    firstChild.id = "googleMapCenter";
    firstChild.style.backgroundColor = '#fff';
    firstChild.style.border = 'none';
    firstChild.style.outline = 'none';
    firstChild.style.width = '40px';
    firstChild.style.height = '40px';
    firstChild.style.borderRadius = '2px';
    firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
    firstChild.style.cursor = 'pointer';
    firstChild.style.marginRight = '10px';
    firstChild.style.padding = '0px';
    firstChild.title = 'Your Location';
    controlDiv.appendChild(firstChild);
    
    var secondChild = document.createElement('div');
    secondChild.style.margin = 'calc(50% - 18px / 2)';
    secondChild.style.width = '18px';
    secondChild.style.height = '18px';
    secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
    secondChild.style.backgroundSize = '180px 18px';
    secondChild.style.backgroundPosition = '0px 0px';
    secondChild.style.backgroundRepeat = 'no-repeat';
    secondChild.id = 'you_location_img';
    firstChild.appendChild(secondChild);
    
    google.maps.event.addListener(map, 'dragend', function() {
      if(document.getElementById('you_location_img')){
        document.getElementById('you_location_img').style.backgroundPosition = '0px 0px';
      }
    });

    if(navigator){
      navigator.geolocation.getCurrentPosition( pos => {                    
        this.currentLon = +pos.coords.longitude;
        this.currentLat = +pos.coords.latitude;
      });
    }      
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);

    this.directionsService = new google.maps.DirectionsService();      
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      draggable: true,
      map,
      preserveViewport: true,
      suppressMarkers: true
    });
    let destination = {
      lat: Number(this.data.chosenBin.lat),
      lng: Number(this.data.chosenBin.lon)
    }
    this.setCurrentLocation(this.data.chosenBin.lon,this.data.chosenBin.lat);

    this.apiService.getSiteInfo(this.data.chosenSite);
    this.apiQuery.siteInfo$.subscribe((siteInfo) => {
      if ((Object.keys(siteInfo).length === 0) || (!siteInfo)) return;
      this.siteObject = siteInfo;
      this.siteLat = Number(this.siteObject.start_nav_point_lat);
      this.siteLon = Number(this.siteObject.start_nav_point_lng);    
      let origin = {
        lat: Number(this.siteLat),
        lng: Number(this.siteLon)
      }
      this.displayRoute(origin,destination);        
    });
  }

  displayRoute(origin: any,destination: any) {    
    if(this.directionsService != null){
      this.directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
          avoidTolls: true
        },
        (
          result: google.maps.DirectionsResult,
          status: google.maps.DirectionsStatus
        ) => {          
          if (status === "OK") {
            this.directionsRenderer.setDirections(result);
          }
        }
      );
    }
  }

  dropMarkerInMyLocation(){    
    this.ElForm.controls["lat"].setValue(Number(this.currentLat).toFixed(8));    
    this.ElForm.controls["lon"].setValue(Number(this.currentLon).toFixed(8));  
    this.changeLoc();
  }

  closeModal(){
    this.dialogRef.close();
  }

  saveLocation(){
    let dataToSend = {
      "bin_id_list": [[this.data.chosenBin.bin_id,0]],
      "bin_lon":Number(this.ElForm.getRawValue()["lon"]),
      "bin_lat":Number(this.ElForm.getRawValue()["lat"])
    }    
    this.apiService.setBinThresholdsAndRestrictions(dataToSend).subscribe((res) => {
      this.apiStore.update({ filteredBinManagmentInfo: [] });
      this.apiService.GetBinManagmentInfo(this.data.chosenSite);		
			this.closeModal();
		});
  }
}

import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { BinLiveInfoPageComponent } from './components/bin-live-info-page-main/bin-live-info-page/bin-live-info-page.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AuthService } from './services/auth.service';
import { WorkplansComponent } from './components/workplans-page/workplans/workplans.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { ReportsComponent } from './components/reports-page/reports/reports.component';
import { CollectionsComponent } from './components/reports-page/collections/collections.component';
import { SiteManagmentComponent } from './components/site-managment/site-managment.component';
import { SiteConfigComponent } from './components/site-managment/site-config/site-config.component';
import { SwapUnitComponent } from './components/site-managment/swap-unit/swap-unit.component';
import { InstallNewBinComponent } from './components/site-managment/install-new-bin/install-new-bin.component';
import { PreMappingComponent } from './components/site-managment/pre-mapping/pre-mapping.component';
import { PreInstallationMappingComponent } from './components/site-managment/pre-installation-mapping/pre-installation-mapping.component';
import { FastInstallationComponent } from './components/site-managment/fast-installation/fast-installation.component';
import { FillableBinsComponent } from './components/fillable-bins/fillable-bins.component'
import { NewInstallationWizardComponent } from './components/site-managment/new-installation-wizard/new-installation-wizard.component';
import { BinsMonitoringComponent } from './components/site-managment/bins-monitoring/bins-monitoring.component';
import { FillingsComponent } from './components/fillings/fillings.component';
import { TicketManagementComponent } from './components/ticket-management/ticket-management.component';
import { InsightsComponent } from './components/insights/insights.component';
import { DriverPlansComponent } from './components/driver-plans/driver-plans.component';
import { DriverListComponent } from './components/driver-list/driver-list.component';

export const routes: Routes = [
    { path: 'logout', component: LogoutComponent },
	{ path: 'login', component: LoginComponent },
	{ path: '', redirectTo: 'dashboard', pathMatch: 'full'},
	{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthService] },
	{ path: 'fillableBins', component: FillableBinsComponent, canActivate: [AuthService] },
	{ path: 'workplans', component: WorkplansComponent, canActivate: [AuthService] },
	{ path: 'inventory', component: InventoryComponent, canActivate: [AuthService] },
	{ path: 'insights', component: InsightsComponent, canActivate: [AuthService] },
	{ path: 'reports', component: ReportsComponent, canActivate: [AuthService] },
	{ path: 'collections', component: CollectionsComponent, canActivate: [AuthService] },
	{ path: 'fillings', component: FillingsComponent, canActivate: [AuthService] },
	{ path: 'siteMangment', component: SiteManagmentComponent, canActivate: [AuthService] },
	{ path: 'ticketManagement', component: TicketManagementComponent, canActivate: [AuthService] },
	{ path: 'ticketManagement/:id', component: TicketManagementComponent, canActivate: [AuthService] },
	{ path: 'driverplans', component: DriverPlansComponent, canActivate: [AuthService] },
	{ path: 'driverplans/:siteid/:tabletid', component: DriverListComponent, canActivate: [AuthService] },
	{ path: 'siteConfig/:id', component: SiteConfigComponent, canActivate: [AuthService] },
	{ path: 'swapUnit/:id', component: SwapUnitComponent, canActivate: [AuthService]},
	{ path: 'swapUnit/:id/:item', component: SwapUnitComponent, canActivate: [AuthService]},
	{ path: 'binsmonitoring/:id', component: BinsMonitoringComponent, canActivate: [AuthService] },
	{ path: 'fastInstallation/:id/:binId', component: FastInstallationComponent, canActivate: [AuthService]},
	{ path: 'installNewBin/:id', component: InstallNewBinComponent, canActivate: [AuthService] },
	{ path: 'preMapping/:id', component: PreMappingComponent, canActivate: [AuthService] },
	{ path: 'preInstallationMapping/:id', component: PreInstallationMappingComponent, canActivate: [AuthService] },
	{ path: 'preInstallationMapping/:id/:binName', component: PreInstallationMappingComponent, canActivate: [AuthService] },
	{ path: 'newinstallationwizard/:id/:binName', component: NewInstallationWizardComponent, canActivate: [AuthService]},
	{ path: 'binLiveInfoPage', component: BinLiveInfoPageComponent, canActivate: [AuthService] },
	{ path: 'resetPassword/:token', component: LoginComponent}
];

import { Component, ViewChild } from '@angular/core';
import { CreateSiteModalComponent } from './create-site-modal/create-site-modal.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { TableUtil } from '../../tableUtil';
import _ from 'lodash';
import { Papa } from 'ngx-papaparse';
import { Router, RouterModule } from '@angular/router';
import { ApiQuery } from '../../web-services/api/api.query';
import { MatDialog } from '@angular/material/dialog';
import { TranslatorService } from '../../services/translator_service';
import { ApiService } from '../../web-services/api/api.service';
import { ResponsiveService } from '../../services/responsive.service';
import { SelectionModel } from '@angular/cdk/collections';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { PaginationComponent } from '../pagination/pagination.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-site-managment',
  standalone: true,
  imports: [TranslateModule,MatCardModule,CommonModule,MatTabsModule,PaginationComponent,RTLDivDirectiveDirective,MatTableModule,
    RouterModule,MatDividerModule,MatCheckboxModule,MatSortModule],
  templateUrl: './site-managment.component.html',
  animations: [
	trigger('detailExpand', [
		state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
		state('expanded', style({ height: '*' })),
		transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
	  ])
  ],
  styleUrl: './site-managment.component.scss'
})
export class SiteManagmentComponent {
  expandedElement: any;
  @ViewChild(MatSort,{static: false}) sort: MatSort;
	sitesDataSource = new MatTableDataSource<any>();
	siteSelection = new SelectionModel<Element>(true, []);
	isMobile: Boolean;
	startIndexTableDataSites = 0;
	endIndexTableDataSites = 9;
	currentLang;
	translationsObj;
	translate;
	filteredExist:boolean;
	tempFilter = '';
	sitesData:Array<any> = [];
	displayedSitesColumns = ['select', 'site_name','site_id','site_num_of_installed_on_site','site_num_of_pending_swap','site_num_of_pending_verification','site_num_of_pending_installation'];
	columnsToDisplay = ['site_name', 'site_id','arrowDown'];
	spinnerSitesActive:boolean = true;
	sitesFullInfo:any;
	sitesTableLength = 0;
	spinnerExist : boolean = false;
	usageType = 1;
	isAdmin: boolean = false;
	filteredData:Array<object> = [];

	constructor(private papa: Papa,
			private router: Router,
			private apiQuery:ApiQuery,
			private dialog: MatDialog,
			private apiService:ApiService,
			private translator: TranslatorService,
			private responsiveService: ResponsiveService) {
				this.translate = this.translator;
				this.translator.currentLangEmitter$
				.subscribe(async value=>{						
				this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
				this.currentLang = value;	
				})
	}

	ngOnInit() {
		this.apiService.getSitesList(JSON.parse(sessionStorage.getItem("chosenSites")), 1);
		this.onResize();
		this.responsiveService.checkWidth();
		this.spinnerSitesActive = true;
		this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}
						
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
			if ((user["user_role_id"] == 2) || (user["user_role_id"] == 1) || (user["user_role_id"] == 5) || (user["user_role_id"] == 6)) {
				this.isAdmin = true;
			}
		});

		this.apiQuery.sitesListData$.subscribe((sitesList) => {
			this.spinnerSitesActive = true;		
			if(sitesList.length == 0) return;
			this.spinnerSitesActive = false;
			this.sitesFullInfo = sitesList; 

			const sitesFiltered = _.map(sitesList, b => {
				return {
					site_name: b["site_name"],
					site_id: b["site_id"],
					site_num_of_installed_on_site: b["site_num_of_installed_on_site"],
					site_num_of_pending_swap: b["site_num_of_pending_swap"],
					site_num_of_pending_verification: b["site_num_of_pending_verification"],
					site_num_of_pending_installation: b["site_num_of_pending_installation"]
				};
			});	

			this.sitesData = sitesFiltered;			
			this.sitesTableLength = this.sitesData.length;
			this.sitesDataSource = new MatTableDataSource<any>(sitesFiltered);

			this.sitesDataSource.sort = this.sort;
			if(this.isMobile){
				if(this.sitesDataSource.filteredData.length > 0){
					this.spinnerExist = true;
					this.filteredExist = true;
				}else{
					this.spinnerExist = false;			
					this.filteredExist = false;
				}
			}
			this.sortSitesData({ direction: 'desc', active: 'site_name' });
		});	
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
			this.isMobile = isMobile;
		});
	}

	applySitesFilter = (filterValue: any) => {
    filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempFilter = filterValue;
		this.sitesDataSource.filter = filterValue;
		setTimeout(() => {
			if(this.isMobile){
				if(this.sitesDataSource.data.length == 0){
					this.filteredExist = false;
				}else{
					this.filteredExist = true;
				}
			}
		}, 0)		
	}

	exportSitesToCsv = () => {
		let dataToExport = null;
		if (this.siteSelection.selected.length > 0) {
			dataToExport = _.cloneDeep(this.siteSelection.selected);
		} else {
			dataToExport = _.cloneDeep(this.sitesData);
		}

		const header = 
		{
			'0' : this.translationsObj.TITLES.SITE_NAME,
			'1' : this.translationsObj.LIST_COLUMNS.ID,
			'2' : this.translationsObj.SITE_MANAGMENT.INSTALLED_DEVICES,
			'3' : this.translationsObj.SITE_MANAGMENT.PENDING_SWAP_DEVICES,
			'4' : this.translationsObj.SITE_MANAGMENT.PENDING_VERIFICATION_DEVICES,
			'5' : this.translationsObj.SITE_MANAGMENT.PENDING_INSTALLATION_DEVICES
		};

		const flatData = dataToExport.reduce((b, a) => {	
			let res = _.slice(_.values(a), 0, Object.keys(header).length)									
			b.push(res);
			return b;
		}, [])
		
		let source = {
			flatData,
			header
		};	

		let result = source.flatData.map((details) => 
		Object.keys(details).reduce((a, b) => 
		(a[source.header[b]] = details[b], a), {}));			
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "Sites");
		}else{
			TableUtil.exportArrayToExcel(result, "אתרים");
		}
	}

	getSitesData = () => {
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.sitesData);
		tempSearch.filter = this.tempFilter;
		this.filteredData = tempSearch.filteredData;
		for (let index = this.startIndexTableDataSites; index <= this.endIndexTableDataSites; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
		this.sitesDataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
		return this.sitesDataSource;
	}

	sortSitesData(sort: Sort) {
		const data = this.sitesData.slice();
		if (!sort.active || sort.direction === '') {
			this.sitesData = data;
			return;
		}
		this.sitesData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
	}

	isSitesAllSelected = (): boolean => {
		const numSelected = this.siteSelection.selected.length;
		const numRows = this.sitesDataSource.data.length;
		return numSelected === numRows;
	}

	masterSitesToggle = () => {
		this.isSitesAllSelected() ?
			this.siteSelection.clear() :
			this.sitesDataSource.filteredData.map(row =>
				this.siteSelection.select(row))
	}

	onSitesPageChange = (dataTableIndexes: any) => {
		this.startIndexTableDataSites = dataTableIndexes.startIndex;
		this.endIndexTableDataSites = dataTableIndexes.endIndex;
	}
	
	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	openCreateSite = () => {
		this.siteSelection.clear();
		this.dialog.open(CreateSiteModalComponent, { panelClass: 'bulkBins-container', data: {chosenUsers : []} });	
	}
}

import { RouterOutlet } from '@angular/router';
import { Component } from '@angular/core';
import { Location, PopStateEvent } from "@angular/common";
import { Subscription, filter } from 'rxjs';
import { Router, NavigationEnd,NavigationStart } from '@angular/router';
import { TranslatorService } from './services/translator_service';
import { AuthService } from './services/auth.service';
import { LOCAL_STORAGE_KEYS } from './constants';
import {ResponsiveService} from './services/responsive.service';
import { ApiStore } from './web-services/api/api.store';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { RTLDivDirectiveDirective } from './directives/rtldiv-directive.directive';
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,SidebarComponent,HeaderComponent,LoginComponent,RTLDivDirectiveDirective],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  opened = true;
	fetchAlerts: any;
	mobileFlag : boolean = false;
	subscription: Subscription;
	lastPoppedUrl: any;
  	yScrollStack: any[] = [];
	logged_In : boolean = false;
	
	constructor(
		private router: Router,
		private location: Location,
		private translator: TranslatorService,
		private authSrv: AuthService,
		private apiStore: ApiStore,
		private responsiveService:ResponsiveService) { 
		  this.subscription = router.events.subscribe((event) => {
				if (event instanceof NavigationStart) {
					browserRefresh = !router.navigated;
					if(browserRefresh){
						sessionStorage.removeItem("chosenSites");
					}
					
				}
  			});
		}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		
		this.getUserFromLocalStorage();

		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		  ).subscribe((event: NavigationEnd) => {
			this.logged_In = this.isLoggedIn();
			if (event.urlAfterRedirects == '/login' && this.logged_In) {
				this.router.navigate(['/dashboard']);
			}
		});

		if(!this.mobileFlag){
			this.router.events.subscribe((evt) => {
				if (!(evt instanceof NavigationEnd)) {
					return;
				}
				const el = document.getElementById('super-container');
        if(el){
          el.scrollTop = 0;
        }
			});	
		}else{
			this.location.subscribe((ev:PopStateEvent) => {
				this.lastPoppedUrl = ev.url;
			});
			this.router.events.subscribe((ev:any) => {
				if (ev instanceof NavigationStart) {
					if (ev.url != this.lastPoppedUrl)
						this.yScrollStack.push(window.scrollY);
				} else if (ev instanceof NavigationEnd) {
					if (ev.url == this.lastPoppedUrl) {
						this.lastPoppedUrl = undefined;
						window.scrollTo(0, this.yScrollStack.pop());						
					} else
						window.scrollTo(0, 0);						
				}
			});
		}
		if(localStorage && localStorage.getItem('language')){
			let lan = localStorage.getItem('language');
			if(lan == null || lan == ''){
				lan = 'en';
			}					
			this.translator.init(lan);
		}
	}

	onResize(){
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
			this.mobileFlag = isMobile;
		});
	  }

	getUserFromLocalStorage = () => {
		if (this.authSrv.isTokenExists()) {
			const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
			this.apiStore.update({ user: user });
		}
	}

	isLoggedIn() {
		return this.authSrv.isTokenExists() && !this.isResetPassword();
	}

	isResetPassword() {
		const parsedUrl = window.location.href.split('/');
		if (parsedUrl.length > 1){
			const token = parsedUrl[parsedUrl.length-1];
			if (parsedUrl[parsedUrl.length-2] === "resetPassword") {
				localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
				localStorage.removeItem(LOCAL_STORAGE_KEYS.USER);
				return true;
			}
		}
		return false;
	}
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslatorService } from '../../../../services/translator_service';
import { DialogData } from '../install-new-bin.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RTLDivDirectiveDirective } from '../../../../directives/rtldiv-directive.directive';
import { MatTableModule } from '@angular/material/table';

export interface PeriodicElement {
  item: string;
  value: any;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-install-new-bin-dialog',
  standalone: true,
  imports: [TranslateModule,CommonModule,FormsModule, RTLDivDirectiveDirective,MatDialogModule,MatTableModule],
  templateUrl: './install-new-bin-dialog.component.html',
  styleUrl: './install-new-bin-dialog.component.scss'
})
export class InstallNewBinDialogComponent {

  displayedColumns: string[] = ['item', 'value'];

  dataSource = ELEMENT_DATA;

  isSwap: boolean;
  isFastInstallation: boolean;

  isInstallationCompleted: boolean;

  binId: number;
  currentLang: string;
  translateSub;
  translationsObj;

  constructor(
    public dialogRef: MatDialogRef<InstallNewBinDialogComponent>,private translator: TranslatorService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
      this.currentLang = this.translator.getTranslationLanguage();      
      this.translateSub = this.translator.getTranslation(this.currentLang).subscribe(translations => {
        this.translationsObj = translations;
      });
    }

  ngOnInit() {
    this.isSwap = this.data.isSwap;
    this.isFastInstallation = this.data.isFastInstallation;
    this.isInstallationCompleted = this.data.isInstallationCompleted;
    if (this.isInstallationCompleted) this.binId = this.data.binId;

    ELEMENT_DATA.length = 0;
    for (let key in this.data.data) {
      let newName = {
        item: key,
        value: this.data.data[key]
      };
      ELEMENT_DATA.push(newName);
    }
  }
}

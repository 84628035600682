import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { TableUtil } from '../../../tableUtil';
import _ from 'lodash';
import { Subject,combineLatest } from 'rxjs';
import {CalendarEvent,CalendarView,CalendarDateFormatter,DAYS_OF_WEEK, CalendarMomentDateFormatter, CalendarMonthModule, CalendarCommonModule, MOMENT, CalendarA11y} from 'angular-calendar';
import { TranslatorService } from '../../../services/translator_service';
import { Papa } from 'ngx-papaparse';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiStore } from "../../../web-services/api/api.store";
import { CustomDateFormatter } from './custom-date-formatter.provider';

@Component({
  selector: 'app-workplan-schedule',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,CommonModule,MatDividerModule,MatCardModule,
    FormsModule,ReactiveFormsModule,CalendarMonthModule,CalendarCommonModule,NgbModule],
  templateUrl: './workplan-schedule.component.html',
  styleUrl: './workplan-schedule.component.scss',
  providers: [
    {
      provide: CalendarA11y
     },
    {
      provide: MOMENT, useValue: moment
    },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    }
  ]
})
export class WorkplanScheduleComponent {
  locale: string;
  isMobile: Boolean;
	translate;
	currentLang;
  translationsObj;
  todayArr:any[]=[];
  weekStartsOn: number;
  spinnerActive = true;  
  spinnerExcelActive = false;
  events: CalendarEvent[] = [];
  eventsExcel: CalendarEvent[] = [];
  refresh: Subject<void> = new Subject<void>();
  activeDayIsOpen: boolean = false;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  day : any;
  eventSelectedBackUp;
  @Output() activeWorkplan = new EventEmitter<boolean>(true);
  @Output() eventSelected = new EventEmitter<any>();  
  @Output() dayEvents = new EventEmitter<Array<object>>();  
  @Output() dateClicked = new EventEmitter<object>();  
  userGeneratedWorkplans : any;
  dailyWorkplans : any;
  currentDayWorkplans : any;
  userGeneratedWorkplansExcel : any;
  dailyWorkplansExcel : any;
  currentDayWorkplansExcel : any;
  prevBtnDisabled:boolean = false;
  multiTrucksPerPlan:boolean = false;
  excelClicked:boolean = false;
	usageType = 1;
	is_view_unexecuted_work_plans:any = 0;
  last_plan:object = {
    plan : {
      Site_plan_id : 0
    }
  };

  constructor(private translator: TranslatorService,private papa: Papa,private apiService:ApiService,
    private responsiveService: ResponsiveService,private apiQuery:ApiQuery,private apiStore:ApiStore) {
      this.translate = this.translator;
      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
        this.currentLang = value;	
        this.currentLang != 'iw' ? this.locale = 'en' : this.locale = 'he';
        this.currentLang != 'iw' ? this.weekStartsOn = DAYS_OF_WEEK.MONDAY : this.weekStartsOn = DAYS_OF_WEEK.SUNDAY;
      })
  }

  ngOnInit() {    
    this.onResize();
		this.responsiveService.checkWidth();
    this.spinnerActive = true;

    this.apiQuery.user$.subscribe(user => {
			if (!user) return;

			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
			this.is_view_unexecuted_work_plans = user["is_view_unexecuted_work_plans"];			
		});

    combineLatest(
      this.apiQuery.dailyWorkPlanCalanderData$,
      this.apiQuery.workPlanScheduler$,
      this.apiQuery.filteredDailyWorkPlan$,
    )
    .pipe()
    .subscribe(([dailyWorkPlans,workPlanScheduler,currentActive])=>{
      this.spinnerActive = true;
      if((!dailyWorkPlans || dailyWorkPlans.length == 0) ||
      (!workPlanScheduler || workPlanScheduler.length == 0) ||
      (!currentActive || currentActive.length == 0)){return;}
      this.last_plan = {
        plan : {
          Site_plan_id : 0
        }
      };
      this.day = null;
      this.todayArr = [];     
      let dailyWorkplansEvents = [];
      let userGeneratedWorkplansEvents = [];
      let currentDayWorkplansEvents = [];
      this.events = [];
      this.dailyWorkplans = dailyWorkPlans;
      this.userGeneratedWorkplans = workPlanScheduler;    
      this.currentDayWorkplans = currentActive;      
      
      if(!_.isEmpty(this.dailyWorkplans[0])){
        _.each(this.dailyWorkplans, (site) => {
          let sitePlan= {
            start : site.actual_plan_start_time != null && site.actual_plan_start_time != "0000-00-00 00:00:00" ? moment(site.actual_plan_start_time).toDate() : moment(site.plan_start_time).toDate(),
            finish :moment(site.plan_defined_finish_time).toDate(),
            scheduleTxt : site.actual_plan_start_time != null && site.actual_plan_start_time != "0000-00-00 00:00:00" ? site.actual_plan_start_time : site.plan_start_time,
            title : site.site_name,
            dateToSort : moment(site.actual_plan_start_time != null && site.actual_plan_start_time != "0000-00-00 00:00:00" ? site.actual_plan_start_time : site.plan_start_time).toDate(),
            plan : {
              Site_plan_id : site.Site_plan_id,
              site_id : site.site_id,
              site_name : site.site_name,
              plan_start_time : site.plan_start_time,
              actual_plan_start_time : site.actual_plan_start_time
            },
            userGenerated : 'daily',
          };
          dailyWorkplansEvents.push(sitePlan);
        });
      }

      if(!_.isEmpty(this.userGeneratedWorkplans[0])){
        _.each(this.userGeneratedWorkplans, (plan) => {
          _.each(plan.SiteworkPlanSchedulerObj, (site) => {
            let sitePlan= {
              start : moment(site.work_plan_schedule_time).toDate(),
              scheduleTxt : site.work_plan_schedule_time,
              title : plan.site_name,
              dateToSort : moment(site.work_plan_schedule_time).toDate(),
              plan : site,
              userGenerated : 'userGenerated',
            };
            userGeneratedWorkplansEvents.push(sitePlan);
          });
        });
      }

      if(!_.isEmpty(this.currentDayWorkplans[0])){
        _.each(this.currentDayWorkplans, (site) => {
          let sitePlan= {
            start : moment(site.plan_start_time).toDate(),
            finish :moment(site.plan_defined_finish_time).toDate(),
            scheduleTxt: site.actual_plan_start_time != null && site.actual_plan_start_time != "0000-00-00 00:00:00" ?  site.actual_plan_start_time : site.plan_start_time,
            title : site.site_name,
            dateToSort : moment(site.actual_plan_start_time != null && site.actual_plan_start_time != "0000-00-00 00:00:00" ?  site.actual_plan_start_time : site.plan_start_time).toDate(),
            plan : site,
            userGenerated : 'currentActive',
          };
          currentDayWorkplansEvents.push(sitePlan);
        });
      }

      if(this.dailyWorkplans != undefined && this.userGeneratedWorkplans != undefined && this.currentDayWorkplans != undefined){
        if(currentDayWorkplansEvents.length > 0 && dailyWorkplansEvents.length > 0){
          for(let i=0;i<currentDayWorkplansEvents.length;i++){
            for(let j=0;j<dailyWorkplansEvents.length;j++){
              if(moment(currentDayWorkplansEvents[i].start).format("YYYY-MM-DD 00:00:00").valueOf() == moment(dailyWorkplansEvents[j].start).format("YYYY-MM-DD 00:00:00").valueOf() &&
              currentDayWorkplansEvents[i].title.trim() == dailyWorkplansEvents[j].title.trim() && currentDayWorkplansEvents[i].plan.Site_plan_id == dailyWorkplansEvents[j].plan.Site_plan_id){                                
                dailyWorkplansEvents[j].icon = "-1";
              }            
            }
          }
        }


        for(let i=0;i<currentDayWorkplansEvents.length;i++){
          if(moment().format("YYYY-MM-DD 00:00:00").valueOf() == moment(currentDayWorkplansEvents[i].start).format("YYYY-MM-DD 00:00:00").valueOf()){
            this.todayArr.push(currentDayWorkplansEvents[i]);          
          } 
        } 
        for(let i=0;i<dailyWorkplansEvents.length;i++){
          if(moment().format("YYYY-MM-DD 00:00:00").valueOf() == moment(dailyWorkplansEvents[i].start).format("YYYY-MM-DD 00:00:00").valueOf()
          && dailyWorkplansEvents[i].icon != '-1'){
            this.todayArr.push(dailyWorkplansEvents[i]);
          } 
        } 
  
        if(this.todayArr.length != 0){
          this.eventSelectedBackUp = this.todayArr[0];
          this.eventSelected.emit(this.todayArr[0]);
          this.dayEvents.emit(this.todayArr);      
        }


        if(userGeneratedWorkplansEvents.length > 0){
          for(let i=0;i<userGeneratedWorkplansEvents.length;i++){
            if(userGeneratedWorkplansEvents[i].plan.scheduled_by_user_id == 0 && moment().format("YYYY-MM-DD 00:00:00").valueOf() < moment(userGeneratedWorkplansEvents[i].start).format("YYYY-MM-DD 00:00:00").valueOf()){
              if(userGeneratedWorkplansEvents[i].icon == undefined){
                userGeneratedWorkplansEvents[i].icon = '../../assets/images/workplans-page/schedule_automatic.svg';
                this.events.push(userGeneratedWorkplansEvents[i]);
              }
            } 
          }
        }

        if(userGeneratedWorkplansEvents.length > 0 && dailyWorkplansEvents.length > 0){
          for(let i=0;i<userGeneratedWorkplansEvents.length;i++){
            for(let j=0;j<dailyWorkplansEvents.length;j++){
              if(moment(userGeneratedWorkplansEvents[i].start).format("YYYY-MM-DD HH:mm:ss").valueOf() == moment(dailyWorkplansEvents[j].start).format("YYYY-MM-DD HH:mm:ss").valueOf() && 
              userGeneratedWorkplansEvents[i].title == dailyWorkplansEvents[j].title){
                if(dailyWorkplansEvents[j].icon == undefined){
                  dailyWorkplansEvents[j].icon = '../../assets/images/workplans-page/Executed.svg';
                  this.events.push(dailyWorkplansEvents[j]);
                }
              }
            }
          }
        }

        if(userGeneratedWorkplansEvents.length > 0 && currentDayWorkplansEvents.length > 0){
          for(let i=0;i<userGeneratedWorkplansEvents.length;i++){
            for(let j=0;j<currentDayWorkplansEvents.length;j++){
              if(moment(userGeneratedWorkplansEvents[i].start).format("YYYY-MM-DD 00:00:00").valueOf() == moment(currentDayWorkplansEvents[j].start).format("YYYY-MM-DD 00:00:00").valueOf() && (userGeneratedWorkplansEvents[i].title.trim() == currentDayWorkplansEvents[j].title.trim())
              && currentDayWorkplansEvents[j].plan.is_site_plan_execution_start){
                if(currentDayWorkplansEvents[j].icon == undefined){
                  currentDayWorkplansEvents[j].icon = '../../assets/images/workplans-page/Executed.svg';
                  userGeneratedWorkplansEvents[i].icon = "-1";
                  this.events.push(currentDayWorkplansEvents[j]);
                } 
              }
              else if(moment(userGeneratedWorkplansEvents[i].start).format("YYYY-MM-DD 00:00:00").valueOf() == moment(currentDayWorkplansEvents[j].start).format("YYYY-MM-DD 00:00:00").valueOf() && (userGeneratedWorkplansEvents[i].title.trim() == currentDayWorkplansEvents[j].title.trim())
              && !currentDayWorkplansEvents[j].plan.is_site_plan_execution_start){
                if(currentDayWorkplansEvents[j].icon == undefined){
                  currentDayWorkplansEvents[j].icon = '../../assets/images/workplans-page/userGenerated.svg';
                  userGeneratedWorkplansEvents[i].icon = "-1";
                  this.events.push(currentDayWorkplansEvents[j]);
                }
              }
            }
          }
        }

        if(userGeneratedWorkplansEvents.length > 0 && this.events.length > 0){
          for(let i=0;i<userGeneratedWorkplansEvents.length;i++){
            for(let j=0;j<this.events.length;j++){
              if(moment(userGeneratedWorkplansEvents[i].start).format("YYYY-MM-DD 00:00:00").valueOf() == moment(this.events[j].start).format("YYYY-MM-DD 00:00:00").valueOf() &&
              userGeneratedWorkplansEvents[i].title.trim() == this.events[j].title.trim() && moment().format("YYYY-MM-DD 00:00:00").valueOf() > moment(userGeneratedWorkplansEvents[i].start).format("YYYY-MM-DD 00:00:00").valueOf()){                
                  userGeneratedWorkplansEvents[i].icon = "-1";
              }            
            }
          }
        }
   
        if(userGeneratedWorkplansEvents.length > 0){
          for(let i=0;i<userGeneratedWorkplansEvents.length;i++){
            if(userGeneratedWorkplansEvents[i].icon == undefined && moment().format("YYYY-MM-DD 00:00:00").valueOf() != moment(userGeneratedWorkplansEvents[i].start).format("YYYY-MM-DD 00:00:00").valueOf()){
              userGeneratedWorkplansEvents[i].icon = '../../assets/images/workplans-page/userGenerated.svg';
              this.events.push(userGeneratedWorkplansEvents[i]);
            } 
          }
        }

        if(currentDayWorkplansEvents.length > 0){
          for(let i=0;i<currentDayWorkplansEvents.length;i++){
            if(currentDayWorkplansEvents[i].icon == undefined){
              currentDayWorkplansEvents[i].icon = '../../assets/images/workplans-page/recommended.svg';
              this.events.push(currentDayWorkplansEvents[i]);
            } 
          }
        }

        if(dailyWorkplansEvents.length > 0){
          for(let i=0;i<dailyWorkplansEvents.length;i++){
            if(dailyWorkplansEvents[i].icon == undefined){
              dailyWorkplansEvents[i].icon = '../../assets/images/workplans-page/recommended.svg';
              this.events.push(dailyWorkplansEvents[i]);
            } 
          }
        }

        this.events.sort(function(a,b){ 
          return a["dateToSort"] - b["dateToSort"];
        });
        this.spinnerActive = false;  

        if(this.apiStore.getValue()["createdWorkplan"].length > 0){
          _.each(this.todayArr, (item) => {
            if(item.plan != null){
              if(item.plan.site_id == this.apiStore.getValue()["createdWorkplan"][0]['site_id']){
                this.handleEvent(item,true);                
              }              
            }
          });
        }else{
          if(_.isEmpty(this.apiStore.getValue()["selectedWorkplan"])){
            _.each(this.todayArr, (item) => {
              if(item.plan != null){
                if(item.plan.Site_plan_id > this.last_plan['plan'].Site_plan_id){
                  this.last_plan = item;
                }              
              }
            });
            this.handleEvent(this.last_plan,true);  
          }else if(!_.isEmpty(this.apiStore.getValue()["selectedWorkplan"])){
            _.each(this.todayArr, (item) => {
              if(item.plan != null && this.apiStore.getValue()["selectedWorkplan"]['plan'] !=null){
                if(item.plan.Site_plan_id == this.apiStore.getValue()["selectedWorkplan"]['plan'].Site_plan_id){
                  this.handleEvent(item,true);                
                }
              }
            });
          }  
        }      
      }
      this.refresh.next();
    })
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if(this.isMobile){
      this.day = { date, events };      
    }
    if(moment(date).format("YYYY-MM-DD 00:00:00").valueOf() < moment().format("YYYY-MM-DD 00:00:00").valueOf() && events.length == 0){
      return;
    } 
    this.dayEvents.emit(events);
    this.dateClicked.emit(date);
    if(moment(date).format("YYYY-MM-DD 00:00:00").valueOf() > moment().format("YYYY-MM-DD 00:00:00").valueOf() && events.length == 0){
      this.activeWorkplan.emit(false);
    }
    if(events.length > 0){
      for(let i=0 ; i<events.length ; i++){
        if(events[i]["userGenerated"] != "userGenerated"){
          this.handleEvent(events[i],false);
          break;
        }
      } 
    }  
  }

  decrement(){
    const filteredEvents = this.events.filter(event => moment(event.start).format("YYYY-MM-DD 00:00:00").valueOf() < moment(this.viewDate).format("YYYY-MM-DD 00:00:00").valueOf());            
    if(filteredEvents.length > 0){
      this.prevBtnDisabled = false;
    }else{
      this.prevBtnDisabled = true;
    }

  }

  increment(){
    const filteredEvents = this.events.filter(event => event.start.valueOf() < this.viewDate.valueOf());    
    if(filteredEvents.length > 0){
      this.prevBtnDisabled = false;
    }else{
      this.prevBtnDisabled = true;
    }
  }

  async getWorkplansFromAPI(){
    let dataToExport = null;
    let binsFiltered= [];
    let bins = [];
    let binsToExcel = [];
    let start_time;  
    let end_time;
    let DailyWorkPlan = [];
    if(moment(this.viewDate).format("M") == moment().format("M")){//this month
      start_time = moment(this.viewDate).startOf('month').format('YYYY-MM-DD 00:00:01');
      end_time = moment().format('YYYY-MM-DD 23:59:59');
    }else if(moment(this.viewDate).format("M") == moment().subtract(1, 'month').format("M")){//1 month ago
      start_time = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD 00:00:01');
      end_time = moment(this.viewDate).endOf('month').format('YYYY-MM-DD 23:59:59');
    }else if(moment(this.viewDate).format("M") == moment().subtract(2, 'month').format("M")){//2 months ago
      start_time = moment().subtract(2, 'month').format('YYYY-MM-DD 00:00:01');
      end_time = moment(this.viewDate).endOf('month').format('YYYY-MM-DD 23:59:59');
    }

    let response = await this.apiService.getDailyWorkPlansAccordingToDate(start_time,end_time,this.is_view_unexecuted_work_plans);
    if(response){
      _.each(response["Daily_work_plans"], (workPlan) => {		
        _.each(workPlan.Sites_Daily_work_plan, (workPlanSite) => {
          if (JSON.parse(sessionStorage.getItem("chosenSites")).some(site => site.site_id == workPlanSite["site_id"])) {	
            workPlanSite.plan_id = workPlan.plan_id;																	
            DailyWorkPlan.push(workPlanSite);																				
          }  
        });
      });
    }
    combineLatest(
      this.apiQuery.workPlanScheduler$,
      this.apiQuery.filteredDailyWorkPlan$,
    )
    .pipe()
    .subscribe(([workPlanScheduler,currentActive])=>{      
      if((!workPlanScheduler || workPlanScheduler.length == 0) ||
      (!currentActive || currentActive.length == 0)){return;}      
      this.spinnerExcelActive = false;
      let dailyWorkplansEventsExcel = [];
      let userGeneratedWorkplansEventsExcel = [];
      let currentDayWorkplansEventsExcel = [];
      this.eventsExcel = [];
      this.dailyWorkplansExcel = DailyWorkPlan;
      this.userGeneratedWorkplansExcel = workPlanScheduler;    
      this.currentDayWorkplansExcel = currentActive;      

      if(!_.isEmpty(this.dailyWorkplansExcel[0])){
        _.each(this.dailyWorkplansExcel, (site) => {
          let sitePlan= {
            start : site.actual_plan_start_time != null && site.actual_plan_start_time != "0000-00-00 00:00:00" ? moment(site.actual_plan_start_time).toDate() : moment(site.plan_start_time).toDate(),
            finish :moment(site.plan_defined_finish_time).toDate(),
            scheduleTxt : site.actual_plan_start_time != null && site.actual_plan_start_time != "0000-00-00 00:00:00" ? site.actual_plan_start_time : site.plan_start_time,
            title : site.site_name,
            dateToSort : moment(site.actual_plan_start_time != null && site.actual_plan_start_time != "0000-00-00 00:00:00" ? site.actual_plan_start_time : site.plan_start_time).toDate(),
            plan : site,
            userGenerated : 'daily',
          };
          dailyWorkplansEventsExcel.push(sitePlan);
        });
      }

      if(!_.isEmpty(this.userGeneratedWorkplansExcel[0])){
        _.each(this.userGeneratedWorkplansExcel, (plan) => {
          _.each(plan.SiteworkPlanSchedulerObj, (site) => {
            let sitePlan= {
              start : moment(site.work_plan_schedule_time).toDate(),
              scheduleTxt : site.work_plan_schedule_time,
              title : plan.site_name,
              dateToSort : moment(site.work_plan_schedule_time).toDate(),
              plan : site,
              userGenerated : 'userGenerated',
            };
            userGeneratedWorkplansEventsExcel.push(sitePlan);
          });
        });
      }

      if(!_.isEmpty(this.currentDayWorkplansExcel[0])){
        _.each(this.currentDayWorkplansExcel, (site) => {
          let sitePlan= {
            start : moment(site.plan_start_time).toDate(),
            finish :moment(site.plan_defined_finish_time).toDate(),
            scheduleTxt: site.actual_plan_start_time != null && site.actual_plan_start_time != "0000-00-00 00:00:00" ?  site.actual_plan_start_time : site.plan_start_time,
            title : site.site_name,
            dateToSort : moment(site.actual_plan_start_time != null && site.actual_plan_start_time != "0000-00-00 00:00:00" ?  site.actual_plan_start_time : site.plan_start_time).toDate(),
            plan : site,
            userGenerated : 'currentActive',
          };
          currentDayWorkplansEventsExcel.push(sitePlan);
        });
      }

      if(this.dailyWorkplansExcel != undefined && this.userGeneratedWorkplansExcel != undefined && this.currentDayWorkplansExcel != undefined){
        if(currentDayWorkplansEventsExcel.length > 0 && dailyWorkplansEventsExcel.length > 0){
          for(let i=0;i<currentDayWorkplansEventsExcel.length;i++){
            for(let j=0;j<dailyWorkplansEventsExcel.length;j++){
              if(moment(currentDayWorkplansEventsExcel[i].start).format("YYYY-MM-DD 00:00:00").valueOf() == moment(dailyWorkplansEventsExcel[j].start).format("YYYY-MM-DD 00:00:00").valueOf() &&
              currentDayWorkplansEventsExcel[i].title.trim() == dailyWorkplansEventsExcel[j].title.trim() && currentDayWorkplansEventsExcel[i].plan.Site_plan_id == dailyWorkplansEventsExcel[j].plan.Site_plan_id){                                
                dailyWorkplansEventsExcel[j].icon = "-1";
              }            
            }
          }
        }

        if(userGeneratedWorkplansEventsExcel.length > 0){
          for(let i=0;i<userGeneratedWorkplansEventsExcel.length;i++){
            if(userGeneratedWorkplansEventsExcel[i].plan.scheduled_by_user_id == 0 && moment().format("YYYY-MM-DD 00:00:00").valueOf() < moment(userGeneratedWorkplansEventsExcel[i].start).format("YYYY-MM-DD 00:00:00").valueOf()){
              if(userGeneratedWorkplansEventsExcel[i].icon == undefined){
                userGeneratedWorkplansEventsExcel[i].icon = '../../assets/images/workplans-page/schedule_automatic.svg';
                this.eventsExcel.push(userGeneratedWorkplansEventsExcel[i]);
              }
            } 
          }
        }

        if(userGeneratedWorkplansEventsExcel.length > 0 && dailyWorkplansEventsExcel.length > 0){
          for(let i=0;i<userGeneratedWorkplansEventsExcel.length;i++){
            for(let j=0;j<dailyWorkplansEventsExcel.length;j++){
              if(moment(userGeneratedWorkplansEventsExcel[i].start).format("YYYY-MM-DD HH:mm:ss").valueOf() == moment(dailyWorkplansEventsExcel[j].start).format("YYYY-MM-DD HH:mm:ss").valueOf() && 
              userGeneratedWorkplansEventsExcel[i].title == dailyWorkplansEventsExcel[j].title){
                if(dailyWorkplansEventsExcel[j].icon == undefined){
                  dailyWorkplansEventsExcel[j].icon = '../../assets/images/workplans-page/Executed.svg';
                  this.eventsExcel.push(dailyWorkplansEventsExcel[j]);
                }
              }
            }
          }
        }

        if(userGeneratedWorkplansEventsExcel.length > 0 && currentDayWorkplansEventsExcel.length > 0){
          for(let i=0;i<userGeneratedWorkplansEventsExcel.length;i++){
            for(let j=0;j<currentDayWorkplansEventsExcel.length;j++){
              if(moment(userGeneratedWorkplansEventsExcel[i].start).format("YYYY-MM-DD 00:00:00").valueOf() == moment(currentDayWorkplansEventsExcel[j].start).format("YYYY-MM-DD 00:00:00").valueOf() && (userGeneratedWorkplansEventsExcel[i].title.trim() == currentDayWorkplansEventsExcel[j].title.trim())
              && currentDayWorkplansEventsExcel[j].plan.is_site_plan_execution_start){
                if(currentDayWorkplansEventsExcel[j].icon == undefined){
                  currentDayWorkplansEventsExcel[j].icon = '../../assets/images/workplans-page/Executed.svg';
                  userGeneratedWorkplansEventsExcel[i].icon = "-1";
                  this.eventsExcel.push(currentDayWorkplansEventsExcel[j]);
                } 
              }
              else if(moment(userGeneratedWorkplansEventsExcel[i].start).format("YYYY-MM-DD 00:00:00").valueOf() == moment(currentDayWorkplansEventsExcel[j].start).format("YYYY-MM-DD 00:00:00").valueOf() && (userGeneratedWorkplansEventsExcel[i].title.trim() == currentDayWorkplansEventsExcel[j].title.trim())
              && !currentDayWorkplansEventsExcel[j].plan.is_site_plan_execution_start){
                if(currentDayWorkplansEventsExcel[j].icon == undefined){
                  currentDayWorkplansEventsExcel[j].icon = '../../assets/images/workplans-page/userGenerated.svg';
                  userGeneratedWorkplansEventsExcel[i].icon = "-1";
                  this.eventsExcel.push(currentDayWorkplansEventsExcel[j]);
                }
              }
            }
          }
        }

        if(userGeneratedWorkplansEventsExcel.length > 0 && this.eventsExcel.length > 0){
          for(let i=0;i<userGeneratedWorkplansEventsExcel.length;i++){
            for(let j=0;j<this.eventsExcel.length;j++){
              if(moment(userGeneratedWorkplansEventsExcel[i].start).format("YYYY-MM-DD 00:00:00").valueOf() == moment(this.eventsExcel[j].start).format("YYYY-MM-DD 00:00:00").valueOf() &&
              userGeneratedWorkplansEventsExcel[i].title.trim() == this.eventsExcel[j].title.trim() && moment().format("YYYY-MM-DD 00:00:00").valueOf() > moment(userGeneratedWorkplansEventsExcel[i].start).format("YYYY-MM-DD 00:00:00").valueOf()){                
                  userGeneratedWorkplansEventsExcel[i].icon = "-1";
              }            
            }
          }
        }
   
        if(userGeneratedWorkplansEventsExcel.length > 0){
          for(let i=0;i<userGeneratedWorkplansEventsExcel.length;i++){
            if(userGeneratedWorkplansEventsExcel[i].icon == undefined && moment().format("YYYY-MM-DD 00:00:00").valueOf() != moment(userGeneratedWorkplansEventsExcel[i].start).format("YYYY-MM-DD 00:00:00").valueOf()){
              userGeneratedWorkplansEventsExcel[i].icon = '../../assets/images/workplans-page/userGenerated.svg';
              this.eventsExcel.push(userGeneratedWorkplansEventsExcel[i]);
            } 
          }
        }

        if(currentDayWorkplansEventsExcel.length > 0){
          for(let i=0;i<currentDayWorkplansEventsExcel.length;i++){
            if(currentDayWorkplansEventsExcel[i].icon == undefined){
              currentDayWorkplansEventsExcel[i].icon = '../../assets/images/workplans-page/recommended.svg';
              this.eventsExcel.push(currentDayWorkplansEventsExcel[i]);
            } 
          }
        }

        if(dailyWorkplansEventsExcel.length > 0){
          for(let i=0;i<dailyWorkplansEventsExcel.length;i++){
            if(dailyWorkplansEventsExcel[i].icon == undefined){
              dailyWorkplansEventsExcel[i].icon = '../../assets/images/workplans-page/recommended.svg';
              this.eventsExcel.push(dailyWorkplansEventsExcel[i]);
            } 
          }
        }
        
        this.eventsExcel.sort(function(a,b){ 
          return a["dateToSort"] - b["dateToSort"];
        });
      }

      if(this.excelClicked){
        let plans = this.eventsExcel.filter(obj => {
          return (moment(obj.start).format("M") == moment(this.viewDate).format("M")) && (obj["icon"].includes("Executed") || obj["icon"].includes("recommended") || obj["icon"].includes("userGenerated"));
        });      
        _.each(plans, (plan) => {
          if(plan["plan"].Vehicle_info){
            if(plan["plan"].Vehicle_info.length > 1){
              this.multiTrucksPerPlan = true;
            }
            _.each(plan["plan"].Vehicle_info, (truck) => {
              _.each(truck.Bins_collection_info, (bin) => {
                bin.site_name = plan["plan"].site_name;
                bin.tablet_id = truck.tablet_id;
                bin.scheduleTxt = plan["scheduleTxt"].split(" ")[0];
                bins.push(bin);
              });
            });
          }
        });
        binsFiltered = _.map(bins, b => {
          if(!this.multiTrucksPerPlan){
            return {
              site_name : b.site_name,
              bin_name: b.bin_name,
              bin_id: b.bin_id,
              bin_type: b.bin_type_name,
              address: b.Bin_location.bin_address,
              neighborhood:b.Bin_location.bin_neighbourhood,
              beforeCollectionCapacityInPercent:b.beforeCollectionCapacityInPercent,
              afterCollectionCapacityInPercent:b.afterCollectionCapacityInPercent,
              status: b.Last_updated_fill_level_sample.fill_level_in_precent,
              last_collection: b.last_collection_time,
              collected: b.is_collected,
              scheduleTxt: b.scheduleTxt
            };
          }else{
            return {
              tablet_id : b.tablet_id,
              site_name : b.site_name,
              bin_name: b.bin_name,
              bin_id: b.bin_id,
              bin_type: b.bin_type_name,
              address: b.Bin_location.bin_address,
              neighborhood:b.Bin_location.bin_neighbourhood,
              beforeCollectionCapacityInPercent:b.beforeCollectionCapacityInPercent,
              afterCollectionCapacityInPercent:b.afterCollectionCapacityInPercent,
              status: b.Last_updated_fill_level_sample.fill_level_in_precent,
              last_collection: b.last_collection_time,
              collected: b.is_collected,
              scheduleTxt: b.scheduleTxt
            };
          }
        });
        binsToExcel = binsFiltered;
        dataToExport = _.cloneDeep(binsToExcel);
        _.each(dataToExport, (item) => {	
            if (item.collected == 1) {
              item.collected = this.translationsObj.WORKPLAN.COLLECTED;
            }else{
              item.collected = this.translationsObj.WORKPLAN.NOT_COLLECTED;
            }
        });
        const header = this.multiTrucksPerPlan ? 
        {
          '0' : this.translationsObj.TITLES.TRUCK_ID,
          '1' : this.translationsObj.TRUCK_MAP.SITE,
          '2' : this.translationsObj.LIST_COLUMNS.NAME, 
          '3' : this.translationsObj.LIST_COLUMNS.ID,
          '4' : this.translationsObj.LIST_COLUMNS.BIN_TYPE, 
          '5' : this.translationsObj.LIST_COLUMNS.ADDRESS,
          '6' : this.translationsObj.LIST_COLUMNS.NEIGHBORHOOD,
          '7' : this.translationsObj.WORKPLAN.CAP_AT_PICKUP,
          '8' : this.translationsObj.WORKPLAN.CAP_AFTER_PICKUP,
          '9' : this.translationsObj.LIST_COLUMNS.STATUS,
          '10' : this.translationsObj.LIST_COLUMNS.LAST_COLLECTION, 
          '11' : this.translationsObj.LIST_COLUMNS.COLLECTED,
          '12' : this.translationsObj.NEW_WORKPLAN.DATE
        } :
        {
          '0' : this.translationsObj.TRUCK_MAP.SITE,
          '1' : this.translationsObj.LIST_COLUMNS.NAME, 
          '2' : this.translationsObj.LIST_COLUMNS.ID,
          '3' : this.translationsObj.LIST_COLUMNS.BIN_TYPE, 
          '4' : this.translationsObj.LIST_COLUMNS.ADDRESS,
          '5' : this.translationsObj.LIST_COLUMNS.NEIGHBORHOOD,
          '6' : this.translationsObj.WORKPLAN.CAP_AT_PICKUP,
          '7' : this.translationsObj.WORKPLAN.CAP_AFTER_PICKUP,
          '8' : this.translationsObj.LIST_COLUMNS.STATUS,
          '9' : this.translationsObj.LIST_COLUMNS.LAST_COLLECTION, 
          '10' : this.translationsObj.LIST_COLUMNS.COLLECTED,
          '11' : this.translationsObj.NEW_WORKPLAN.DATE
        };
 
        const flatData = dataToExport.reduce((b, a) => {	
          let res = _.slice(_.values(a), 0, Object.keys(header).length)									
          b.push(res);
          return b;
        }, [])
        
        let source = {
          flatData,
          header
        };	

        let result = source.flatData.map((details) => 
        Object.keys(details).reduce((a, b) => 
        (a[source.header[b]] = details[b], a), {}));			
        if(this.currentLang != 'iw'){
          TableUtil.exportArrayToExcel(result, "Monthly Workplan");
        }else{
          TableUtil.exportArrayToExcel(result, "תוכנית עבודה חודשית");
        }
      }
      this.excelClicked = false;
    })
  }

  exportToCsv(){  
    this.spinnerExcelActive = true;
    this.excelClicked = true;
    this.getWorkplansFromAPI();
  }

  validateDateEn(param){
    return moment(param).format('hh:mm A');
  }

  validateDateIw(param){
    return moment(param).format('HH:mm');
  }

  handleEvent(event: any,flag :boolean) {
    if(event["userGenerated"] == "daily"){
      this.apiService.dailyWorkPlanInfo(event);
      this.apiQuery.workPlanInfo$.subscribe((info:any) => {    
        if(info.length == 0 || _.isEmpty(info[0])){
          return;
        }
        if(this.eventSelectedBackUp && this.eventSelectedBackUp["plan"] && this.eventSelectedBackUp["plan"].Site_plan_id == info[0]["plan"].Site_plan_id){
          return;
        }else{
          this.eventSelectedBackUp = info[0];
          this.eventSelected.emit(info[0]);
        }
      });
    }else{
      this.eventSelectedBackUp = event;
      this.eventSelected.emit(event);
    }
    if(flag){
      this.dayEvents.emit([event]);
    }
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  getToday(){
    this.eventSelectedBackUp = this.todayArr[0];
    this.eventSelected.emit(this.todayArr[0]);
    this.dayEvents.emit(this.todayArr);  
    if(this.isMobile){
      this.day["date"] = moment();
      this.day["events"] = this.todayArr;
    }
  }
}

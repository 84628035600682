<div class="manage-bin-modal" rtlDiv>
  <div class="manage-bin-header">{{ 'TITLES.MANAGE_SITES' |  translate }}</div>
  <div class="manage-bit-body">
    <form [formGroup]="ElForm">
      <div class="bin-name-container">
        @if (data.chosenSites) {
          <div class="sub-title">{{ 'TITLES.SITE_NAME' | translate }}</div>
        }
        @if (data.chosenSites) {
          <div class="bin-name-input">
            @if (data.chosenSites) {
              <input id="site-name" formControlName="siteName" type="text" class="input-bin-name"/>
            }
          </div>
        }
      </div>

      <mat-accordion>
        <mat-expansion-panel [expanded]="true" (opened)="workPlanOpenState = true" (closed)="workPlanOpenState = false" class="panel-accordion">
          <mat-expansion-panel-header class="panel-accordion-header">
            <mat-panel-title class="panel-accordion-title">
              {{ 'MANAGE_BIN.WORKPLAN' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div [ngClass]="{'hide-panel' : !workPlanOpenState}">
            <div class="bin-name-container">
              <div class="sub-title">{{ 'LIST_COLUMNS.DISTRIBUTION_METHOD' | translate }}</div>
              <select class="bin-name-input distrebution-Methods" formControlName="WorkPlanDistrebutionMethods" id="distrebution-methods">
                @for (method of workPlanDistrebutionMethods; track method) {
                  <option [value]="method['distrebution_method_id']" class="method-option">
                    {{currentLang != 'iw' ? method['distrebution_method_name'] : method['distrebution_method_name_hebrew']}}
                  </option>
                }
              </select>
            </div>

            <div class="bin-name-container">
              <div class="sub-title">
                <span> {{ 'MANAGE_SITE.SHIFTS' | translate }} </span>
                @if (filtersFormArray.controls.length > 0) {
                  <span> ({{filtersFormArray.controls.length}}) </span>
                }
              </div>
              <div class="shifts-manager" rtlDiv>
                <div class="shifts-names" rtlDiv>
                  {{getShiftsNames()}}
                </div>
                <div class="add-shift" (click)="addFilterToFiltersFormArray()" rtlDiv>
                  <img src="assets/images/inventory/green-plus.svg" alt=""/>
                  {{ 'MANAGE_SITE.ADD_SHIFTS' | translate }}
                </div>
              </div>
              <form [formGroup]="dynamicForm">
                <div formArrayName="shifts">
                  @if (filtersFormArray.controls.length > 0) {
                    <div class="shifts-container">
                      <div class="shift-row-title">
                        <div class="medium-width">{{ 'MANAGE_SITE.SHIFT_NAME' | translate }}</div>
                        <div class="small-width">{{ 'MANAGE_SITE.START' | translate }}</div>
                        <div class="small-width">{{ 'MANAGE_SITE.DURATION' | translate }}</div>
                      </div>
                      @for (filter of filtersFormArray.controls; track filter; let i = $index) {
                        <div>
                          <div [formGroupName]="i" class="shift-row">
                            <mat-form-field class="field-form medium-width" rtlDiv>
                              <input class="input-field" type="text" matInput formControlName="name" rtlDiv>
                            </mat-form-field>
                            <mat-form-field class="field-form small-width" rtlDiv>
                              <input class="input-field cursor-point" [ngxTimepicker]="start_time" [format]="24" matInput formControlName="start_time">
                              <ngx-material-timepicker [theme]="staticTheme" [confirmBtnTmpl]="confirmBtn" [cancelBtnTmpl]="cancelBtn" #start_time></ngx-material-timepicker>
                              <ng-template #cancelBtn>
                                <div class="manage-bins-btn-container" rtlDiv>
                                  <a class="manage-bins-btn" rtlDiv>{{ 'MANAGE_BIN.CANCEL' | translate }}</a>
                                </div>
                              </ng-template>
                              <ng-template #confirmBtn>
                                <div class="manage-bins-btn-container" rtlDiv>
                                  <a class="manage-bins-btn" rtlDiv>{{ 'SITE_MANAGMENT.OK' | translate }}</a>
                                </div>
                              </ng-template>
                            </mat-form-field>
                            <mat-form-field class="field-form small-width" rtlDiv>
                              <input class="input-field" type="text" matInput formControlName="duration" rtlDiv>
                            </mat-form-field>
                            <div class="cursor-point xsmall-width" (click)="removeFilterFromFiltersFormArray(i)">
                              <img src="assets/images/dashboard/closeX.svg" alt=""/>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
              </form>
            </div>
            <div class="bin-name-container">
              <div class="sub-title">{{ 'MANAGE_SITE.SET_FIXED_DAYS_TO_COLLECT' | translate }}</div>
              <select id="fixed-days-to-collect" class="bin-name-input distrebution-Methods" formControlName="customerWorkPlanCollectionschedulePolicy">
                @for (policy of customerWorkPlanCollectionschedulePolicy; track policy) {
                  <option [value]="policy['customer_work_plan_collection_schedule_policy_id']" class="method-option">
                    {{currentLang != 'iw' ? policy['customer_work_plan_collection_schedule_policy_name'] : policy['customer_work_plan_collection_schedule_policy_name_hebrew']}}
                  </option>
                }
              </select>
            </div>
            <div class="map-points">
              <div class="bin-name-container">
                <div class="sub-title"></div>
                <mat-tab-group [(selectedIndex)]="selectedTab" (selectedTabChange)="tabChanged($event)">
                  <mat-tab>
                    <ng-template mat-tab-label>
                      @if (selectedTab == 0) {
                        <img src="assets/images/inventory/start-point-active.svg" alt=""/>
                      }
                      @if (selectedTab != 0) {
                        <img src="assets/images/inventory/start-point-tab.svg" alt=""/>
                      }
                      {{ 'MANAGE_SITE.START' | translate }}
                    </ng-template>
                    <div class="tab-content">
                      <div class="search-bar">
                        <div class="search-input-frame">
                          <img class="search-img" src="assets/images/sidemenu/table_search_icon.png" alt="" />
                          <input (onAddressChange)="handleStartAddressChange($event)" ngx-gp-autocomplete #placesRef="ngx-places" (keyup.enter)="applyFilter()" type="text" class="search-input" formControlName="startLonLat"  placeholder="{{'OTHER.SEARCH' | translate}}"/>                            
                        </div>
                        <div class="apply-btn" (click)="applyFilter()" rtlDiv>
                          {{ 'TITLES.APPLY' | translate }}
                        </div>
                      </div>                      
                      <div class="toggle-container">
                        <div class="edit-toggle-txt">{{ 'TITLES.EDIT' | translate }}</div>
                        @if (showDefaultIconsInStartPointMap) {
                          <img class="cursor-point" (click)="toggleDefaultIconsInStartPointMap()" src="assets/images/workplans-page/exception toggle on.svg" alt="" />
                        }
                        @if (!showDefaultIconsInStartPointMap) {
                          <img class="cursor-point" (click)="toggleDefaultIconsInStartPointMap()" src="assets/images/workplans-page/exeptions toggle off.svg" alt="" />
                        }
                      </div>
                      <google-map
                        [options]="mapOptionsPc"
                        (mapInitialized)="loadAPIWrapperForStartPoint($event)"
                        class="map-manage-modal">
                        @for (bin of markersArrayStartPoint; track bin; let i = $index) {
                          <map-marker
                            [position]="{lat: bin.lat, lng: bin.lng}"
                            [visible]="bin.visible"
                            [options]="{ icon: { url: bin.icon } , draggable : bin.draggable }"
                          (mapDragend)="markerStartPointDragEnd($event)"></map-marker>
                        }
                      </google-map>
                    </div>
                  </mat-tab>

                  <mat-tab>
                    <ng-template mat-tab-label>
                      @if (selectedTab == 1) {
                        <img src="assets/images/inventory/finish-point-active.svg" alt=""/>
                      }
                      @if (selectedTab != 1) {
                        <img src="assets/images/inventory/finish-point-tab.svg" alt=""/>
                      }
                      {{ 'MANAGE_SITE.FINISH' | translate }}
                    </ng-template>
                    <div class="tab-content">
                      <div class="search-bar">
                        <div class="search-input-frame">
                          <img class="search-img" src="assets/images/sidemenu/table_search_icon.png" alt="" />
                          <input (onAddressChange)="handleFinishAddressChange($event)" ngx-gp-autocomplete #placesRef="ngx-places" (keyup.enter)="applyFilter()" type="text" class="search-input" formControlName="finishLonLat"  placeholder="{{'OTHER.SEARCH' | translate}}"/>                            
                        </div>
                        <div class="apply-btn" (click)="applyFilter()" rtlDiv>
                          {{ 'TITLES.APPLY' | translate }}
                        </div>
                      </div>                      
                      <div class="toggle-container">
                        <div class="edit-toggle-txt">{{ 'TITLES.EDIT' | translate }}</div>
                        @if (showDefaultIconsInFinishPointMap) {
                          <img class="cursor-point" (click)="toggleDefaultIconsInFinishPointMap()" src="assets/images/workplans-page/exception toggle on.svg" alt="" />
                        }
                        @if (!showDefaultIconsInFinishPointMap) {
                          <img class="cursor-point" (click)="toggleDefaultIconsInFinishPointMap()" src="assets/images/workplans-page/exeptions toggle off.svg" alt="" />
                        }
                      </div>
                      <google-map
                        [options]="mapOptionsPc"
                        (mapInitialized)="loadAPIWrapperForFinishPoint($event)"
                        class="map-manage-modal">
                        @for (bin of markersArrayFinishPoint; track bin; let i = $index) {
                          <map-marker
                            [position]="{lat: bin.lat, lng: bin.lng}"
                            [visible]="bin.visible"
                            [options]="{ icon: { url: bin.icon } , draggable : bin.draggable }"
                          (mapDragend)="markerFinishPointDragEnd($event)"></map-marker>
                        }
                      </google-map>
                    </div>
                  </mat-tab>

                  <mat-tab>
                    <ng-template mat-tab-label>
                      @if (selectedTab == 2) {
                        <img src="assets/images/inventory/unloading-point-active.svg" alt=""/>
                      }
                      @if (selectedTab != 2) {
                        <img src="assets/images/inventory/unloading-point-tab.svg" alt=""/>
                      }
                      {{ 'MANAGE_SITE.UNLOADING' | translate }}
                    </ng-template>
                    <div class="tab-content">
                      <div class="search-bar">
                        <div class="search-input-frame">
                          <img class="search-img" src="assets/images/sidemenu/table_search_icon.png" alt="" />
                          <input (onAddressChange)="handleDumpAddressChange($event)" ngx-gp-autocomplete #placesRef="ngx-places" (keyup.enter)="applyFilter()" type="text" class="search-input" formControlName="dumpLonLat"  placeholder="{{'OTHER.SEARCH' | translate}}"/>                            
                        </div>
                        <div class="apply-btn" (click)="applyFilter()" rtlDiv>
                          {{ 'TITLES.APPLY' | translate }}
                        </div>
                      </div>                      
                      <div class="toggle-container">
                        <div class="edit-toggle-txt">{{ 'TITLES.EDIT' | translate }}</div>
                        @if (showDefaultIconsInUnloadingPointMap) {
                          <img class="cursor-point" (click)="toggleDefaultIconsInUnloadingPointMap()" src="assets/images/workplans-page/exception toggle on.svg" alt="" />
                        }
                        @if (!showDefaultIconsInUnloadingPointMap) {
                          <img class="cursor-point" (click)="toggleDefaultIconsInUnloadingPointMap()" src="assets/images/workplans-page/exeptions toggle off.svg" alt="" />
                        }
                      </div>
                      <google-map
                        [options]="mapOptionsPc"
                        (mapInitialized)="loadAPIWrapperForUnloadingPoint($event)"
                        class="map-manage-modal">
                        @for (bin of markersArrayDumpPoint; track bin; let i = $index) {
                          <map-marker
                            [position]="{lat: bin.lat, lng: bin.lng}"
                            [visible]="bin.visible"
                            [options]="{ icon: { url: bin.icon } , draggable : bin.draggable }"
                          (mapDragend)="markerDumpPointDragEnd($event)"></map-marker>
                        }
                      </google-map>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion>
        <mat-expansion-panel [expanded]="true" (opened)="advancedSettingsOpenState = true" (closed)="advancedSettingsOpenState = false" class="panel-accordion">
          <mat-expansion-panel-header class="panel-accordion-header">
            <mat-panel-title class="panel-accordion-title">
              {{ 'SITE_MANAGMENT.ADVANCED_SET' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div [ngClass]="{'hide-panel' : !advancedSettingsOpenState}">
            <div class="bin-name-container">
              <div class="sub-title">{{ 'MANAGE_SITE.SET_MAX_VOLUME' | translate }}</div>
              <select id="set-max-volume" class="bin-name-input distrebution-Methods" formControlName="useSiteMaxAllowedCapacity" (change)="setVolumeType()">
                <option [value]="0" class="method-option">
                  {{ 'MANAGE_SITE.MAX_VOLUME_BIN' | translate }}
                </option>
                <option [value]="1" class="method-option">
                  {{ 'MANAGE_SITE.MAX_VOLUME_SITE' | translate }}
                </option>
              </select>
            </div>

            @if (showVolume) {
              <div class="bin-name-container">
                <div class="sub-title">{{ 'MANAGE_SITE.MAXIMUM_VOLUME' | translate }}</div>
                <div class="bin-name-input">
                  @if (data.chosenSites) {
                    <input id="max-volume" type="text" class="input-bin-name" formControlName="capacity"/>
                  }
                </div>
              </div>
            }
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion>
        <mat-expansion-panel [expanded]="true" (opened)="alertsOpenState = true" (closed)="alertsOpenState = false" class="panel-accordion">
          <mat-expansion-panel-header class="panel-accordion-header">
            <mat-panel-title class="panel-accordion-title">
              {{ 'MANAGE_SITE.ALERTS_SETTINGS' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div [ngClass]="{'hide-panel' : !alertsOpenState}">
            <div class="bin-name-container">
              <div class="sub-title">{{ 'MANAGE_SITE.UNCOLLECTED_BIN_ALERT_NUM_OF_DAYS' | translate }}</div>
              <div class="bin-name-input">
                @if (data.chosenSites) {
                  <input id="uncollected-alerts" type="text" class="input-bin-name" formControlName="daysGapForNoCollections"/>
                }
              </div>
            </div>

            <div class="bin-name-container">
              <div class="sub-title">{{ 'MANAGE_SITE.AUTOMATIC_WORKPLAN' | translate }}</div>
              <div class="dflex gap-points">
                <div class="half-frame" rtlDiv>
                  <div class="sub-subTitle">{{ 'MANAGE_SITE.MIN_AVG_VOLUME_REQUIRED' | translate }}</div>
                  @if (data.chosenSites) {
                    <input id="min-avg-volume" type="text" class="time-input" formControlName="minAverageVolume"/>
                  }
                </div>
                <div class="half-frame">
                  <div class="sub-subTitle">{{ 'MANAGE_SITE.MAX_NUM_OF_DAYS_FORECAST' | translate }}</div>
                  @if (data.chosenSites) {
                    <input id="max-forecast" type="text" class="time-input" formControlName="maxNumberDaysForecast"/>
                  }
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </form>
  </div>
  <div class='main-btns-wrapper'>
    <div class='btns-container' rtlDiv>
      <div class='cancel-btn' (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' | translate }}</div>
      <div class='apply-btn' (click)='saveWorkplan()'>{{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}</div>
    </div>
  </div>
</div>
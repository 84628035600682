import { Component, Input } from '@angular/core';
import { Chart, ChartModule } from 'angular-highcharts';
import _ from 'lodash';
import { TranslatorService } from '../../services/translator_service';
import { ResponsiveService } from '../../services/responsive.service';
import { ApiQuery } from '../../web-services/api/api.query';
import { ApiStore } from '../../web-services/api/api.store';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';


@Component({
  selector: 'app-collection-status-pie',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, MatDividerModule, MatCardModule, ChartModule],
  templateUrl: './collection-status-pie.component.html',
  styleUrl: './collection-status-pie.component.scss'
})
export class CollectionStatusPieComponent {
  spinnerActive: boolean = true;
  @Input() datesDiff;
  isMobile: Boolean;
  translationsObj;
  currentLang;
  donutChart: any;

  constructor(
    private translator: TranslatorService,
    private responsiveService: ResponsiveService,
    private apiQuery: ApiQuery,
    private apiStore: ApiStore
  ) {
    this.translator.currentLangEmitter$.subscribe(async (value) => {
      this.translationsObj = await this.translator
        .getTranslation(value)
        .toPromise();
      this.currentLang = value;
      this.updateChart();
    });
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.apiQuery.selectedReportPieChart$.subscribe((status) => {
      this.updateChart();
    });
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  updateChart() {
	if(this.translationsObj){
		this.spinnerActive = true;
		this.apiQuery.collectionsStatsInCollections$.subscribe((data: any) => {
		  this.spinnerActive = true;
		  if (data.length == 0) return;
		  let total = 0;
		  let redCount = 0;
		  let yellowCount = 0;
		  let greenCount = 0;
	
		  if (!_.isEmpty(data[0])) {
			_.each(data, (site) => {
			  _.each(site.site_bins_collections, (range) => {
				_.each(range, (item) => {
				  if (item.bin_fill_level_before_collection <= item.green_status_value) {
					greenCount++;
				  } else if (item.bin_fill_level_before_collection > item.green_status_value && item.bin_fill_level_before_collection <= item.yellow_status_value) {
					yellowCount++;
				  } else {
					redCount++;
				  }
				});
			  });
			});
			total = greenCount + yellowCount + redCount;
		  }
		  this.initDonut(total, redCount, yellowCount, greenCount);
		});
	}
  }

  initDonut(total, redCount, yellowCount, greenCount) {
    const donut = new Chart({
      chart: {
        marginLeft: 50,
        marginRight: 50,
        marginBottom: 100,
        marginTop: -10,
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      legend: {
        align: this.currentLang != "iw" ? "left" : "right",
        layout: "vertical",
        itemMarginBottom: 5,
        itemStyle: {
          fontFamily: "Open Sans",
          fontSize: "14px",
          color: "#3A4743",
          fontWeight: "normal",
        },
      },
      title: {
        text: "<strong>" + total + "<br>" + this.translationsObj.WIDGETS.COLLECTIONS + "</strong>",
        align: "center",
        verticalAlign: "middle",
        y: -40,
        style: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: "14px",
          fontWeight: "600",
        },
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: null,
        useHTML: true,
        formatter: function () {
          return `<div style='flex-direction: row;align-items: center;padding: 4px 8px;width: max-content;height: max-content;top: 85px;background: #F5F6FA;box-shadow: 0px 2px 8px rgba(93, 120, 112, 0.2);border-radius: 3px;display: flex;'><img src='assets/images/dashboard/bin.svg' alt=''/><div><div style='position: static;width: max-content;height: max-content;font-family: Open Sans;font-style: normal;font-weight: normal;font-size: 14px;line-height: 19px;text-transform: capitalize;color: #3A4743;flex: none;order: 0;flex-grow: 0;margin-left: 8px;margin-right: 8px;'>${
            this.point.name
          }</div><div style='position: static;width: max-content;height: max-content;font-family: Open Sans;font-style: normal;font-weight: 600;font-size: 14px;line-height: 19px;text-transform: capitalize;color: #3A4743;flex: none;order: 1;flex-grow: 0;margin-left: 8px;margin-right: 8px;'>${
            this.point.y
          } (${parseInt(this.point.percentage.toString())}%)</div></div></div>`;
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
            distance: -50,
          },
          center: ["50%", "50%"],
          size: "90%",
          showInLegend: true,
        },
      },
      series: [
        {
          name: "",
          point: {
            events: {
              legendItemClick: function () {
                return false;
              },
              click: (event) => {
                this.apiStore.update({ selectedStatusPieChart: event });
              },
            },
          },
          data: [
            {
              name: this.translationsObj.TITLES.GREEN_BINS_COLLECTIONS,
              color: "#36CF79",
              y: greenCount,
            },
            {
              name: this.translationsObj.TITLES.YELLOW_BINS_COLLECTIONS,
              color: "#FFD654",
              y: yellowCount,
            },
            {
              name: this.translationsObj.TITLES.RED_BINS_COLLECTIONS,
              color: "#FF786B",
              y: redCount,
            },
          ],
          type: "pie",
          innerSize: "50%",
        },
      ],
    });
    this.donutChart = donut;
    this.spinnerActive = false;
  }
}

import { Component, Input } from '@angular/core';
import { Chart, ChartModule } from 'angular-highcharts';
import _ from 'lodash';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';


@Component({
  selector: 'app-exceptions-distribution',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective, MatDividerModule, MatCardModule, ChartModule],
  templateUrl: './exceptions-distribution.component.html',
  styleUrl: './exceptions-distribution.component.scss'
})
export class ExceptionsDistributionComponent {
  @Input() datesDiff;
  datesDiffTxt:string;
	translationsObj;
	currentLang;
  translate;
  spinnerActive:boolean = true;
  allAlerts:any[] = [];
	donutChart: any;
  total:any;

  constructor(private translator: TranslatorService,private apiQuery:ApiQuery) {
	this.translate = this.translator;
   }

  ngOnInit() {
    this.spinnerActive = true;	
	this.apiQuery.exceptionsReports$.subscribe((data:any) => {   
		if(this.currentLang != 'iw'){
			this.datesDiffTxt = `Exception Distribution`;
		}else{
			this.datesDiffTxt = `הפצת חריגים`;
		}  
		this.datesDiffTxt += " " + this.datesDiff
		this.spinnerActive = true;
		if (_.isEmpty(data)) return;
		this.spinnerActive = false;
		if(data.length==0) {
			return;
		}
      
      this.allAlerts = [];

	  _.each(data, (item) => {
        _.each(item.user_message_data, (msg) => {
			if(msg.message_id != 2){
				this.allAlerts.push(msg);
			}
        });
	  });

      let allAretsGrouped=[]; 	 
	  this.total = 0;
	  let uncollectedBin_bins = 0;
	  let binOverFlow_bins = 0;
	  let collectionWithoutWorkPlan_bins = 0;
	  let offHoursCollection_bins = 0;
	  let emptyBin_bins = 0;
	  let RemoveBinFromWorkplan = 0;
	let periodicCollection_bins = 0;
      let res = _.each(_.groupBy(this.allAlerts, "message_id"), (item) => {  
		let group : any = {
			alert_data : [],
			alert_name : '',
			alert_name_hebrew : '',
			alert_num_of_bins : 0,
			message_id : null
		  };  	
        _.each((item), (collection) => {  
			if(collection["message_id"] == 5 || collection["message_id"] == 6 ||
			collection["message_id"] == 9 || collection["message_id"] == 10 ||
			collection["message_id"] == 14 || collection["message_id"] == 18
			|| collection["message_id"] == 19){
				_.each((collection["message_data"]), (alert) => {
					group["alert_data"].push(alert);
					}); 
					group["alert_name"] = collection["message_name"];
					group["alert_name_hebrew"] = collection["message_name_hebrew"];
					group["alert_num_of_bins"] += collection["message_data"] != null ? collection["message_data"].length : 0;
					group["message_id"] = collection["message_id"];	
			}
        });  
		if(group["message_id"] != null){
			allAretsGrouped.push(group);
		}
      });
			   
      _.each((allAretsGrouped), (group) => {  
		  if(group.message_id == 5){
            uncollectedBin_bins = group.alert_data.length;			
		  }else if(group.message_id == 6){
			binOverFlow_bins = group.alert_data.length;
		  }else if(group.message_id == 9){
			collectionWithoutWorkPlan_bins = group.alert_data.length;
		  }else if(group.message_id == 10){
			offHoursCollection_bins = group.alert_data.length;
		  }else if(group.message_id == 14){
			periodicCollection_bins = group.alert_data.length;
		  }else if(group.message_id == 18){
			emptyBin_bins = group.alert_data.length;      
		  }else if(group.message_id == 19){
			RemoveBinFromWorkplan = group.alert_data.length;      
		  }
      });  

      this.total = uncollectedBin_bins + binOverFlow_bins + collectionWithoutWorkPlan_bins + offHoursCollection_bins + emptyBin_bins + periodicCollection_bins + RemoveBinFromWorkplan;
	  
      this.allAlerts = allAretsGrouped;         	  
      setTimeout(function(){ this.initDonut(this.total,uncollectedBin_bins,binOverFlow_bins,collectionWithoutWorkPlan_bins,offHoursCollection_bins,emptyBin_bins,periodicCollection_bins,RemoveBinFromWorkplan); }.bind(this), 1500);
	});
  }


  initDonut(total,uncollectedBin_bins,binOverFlow_bins,collectionWithoutWorkPlan_bins,offHoursCollection_bins,emptyBin_bins,periodicCollection_bins,RemoveBinFromWorkplan) {
	this.translator.currentLangEmitter$
	.subscribe(async value=>{						
	  this.translationsObj = await this.translator.getTranslation(value).toPromise();			 
	  this.currentLang = value;	
	  if(this.currentLang != 'iw'){
        this.datesDiffTxt = `Exception Distribution`;
      }else{
        this.datesDiffTxt = `הפצת חריגים`;
      }
	  const donut = new Chart({
		chart: {
		marginLeft:50,
			marginRight:50,
			marginBottom:100,
			marginTop:0,
			plotBackgroundColor: null,
			plotBorderWidth: 0,
			plotShadow: false
		},
		legend: {
			align: 'left',
			layout: 'vertical',	
			itemMarginBottom:5,	
			itemStyle: {
				fontFamily:'Open Sans',
				fontSize:'14px',
				color:'#3A4743',
				fontWeight: 'normal'				
			},	
		},
		title:{
			text: '<strong>' + total +'<br>' + this.translationsObj.COLLECTION_REPORT.TOTAL_EVENTS + '</strong>',
			align: 'center',
	style:{
	  fontFamily:'Open Sans, sans-serif',
				fontSize:'14px',
	  fontWeight: '600'				
	},
			verticalAlign: 'middle',
			y: -30
		},
		tooltip: {	
			borderWidth:0,
			backgroundColor:null,
			useHTML: true,	
			formatter: function(){
				return `<div style='flex-direction: row;align-items: center;padding: 4px 8px;width: max-content;height: max-content;top: 85px;background: #F5F6FA;box-shadow: 0px 2px 8px rgba(93, 120, 112, 0.2);border-radius: 3px;display: flex;'><div><div style='position: static;width: max-content;height: max-content;font-family: Open Sans;font-style: normal;font-weight: normal;font-size: 14px;line-height: 19px;text-transform: capitalize;color: #3A4743;flex: none;order: 0;flex-grow: 0;margin-left: 8px;margin-right: 8px;'>${this.point.name}</div><div style='position: static;width: max-content;height: max-content;font-family: Open Sans;font-style: normal;font-weight: 600;font-size: 14px;line-height: 19px;text-transform: capitalize;color: #3A4743;flex: none;order: 1;flex-grow: 0;margin-left: 8px;margin-right: 8px;'>${this.point.y} (${parseInt(this.point.percentage.toString())}%)</div></div></div>`;										
			  }
		},
		credits: {
		  enabled: false
		},
		plotOptions: {
		  pie: {
			allowPointSelect: true,
			cursor: "pointer",
			dataLabels: {
			  enabled: false,
			  distance: -50,
			},
			center: ["50%", "50%"],
			size: "100%",
			showInLegend: true
		  }
		},
		series: [
		  {
			name: "",
			point: {
				events: {
					legendItemClick: function() {
						return false;
					}
				}
			},
			data: [
			  {
				name: this.currentLang != 'iw' ? "Uncollected Bin" : "איסוף שלא בוצע",
				color: '#254831',
				y: uncollectedBin_bins
			  },
			  {
				name: this.currentLang != 'iw' ? "Bin Over Flow" : "הצפה במיכל",
				color: '#72A284',
				y: binOverFlow_bins
			  },
			  {
				name: this.currentLang != 'iw' ? "Collection Without A Work Plan" : "איסוף מיכל ללא תכנית עבודה",
				color: '#99D7B0',
				y: collectionWithoutWorkPlan_bins
			  },
			  {
				name: this.currentLang != 'iw' ? "Off Hours Collection" : "איסוף מיכל מחוץ לשעות העבודה",
				color: '#D8D72E',
				y: offHoursCollection_bins
			  },
			  {
				name: this.currentLang != 'iw' ? "Non Periodic Collection" : "אי פינוי מחזורי",
				color: 'burlywood',
				y: periodicCollection_bins
			  },
			  {
				name: this.currentLang != 'iw' ? "Empty Bin Collection" : "פינוי מיכל ריק",
				color: '#89DFFF',
				y: emptyBin_bins
			  },
			  {
				name: this.currentLang != 'iw' ? "Remove Bin From Workplan" : "הסרת מיכל מתוכנית העבודה",
				color: 'rgb(89, 120, 250)',
				y: RemoveBinFromWorkplan
			  }
			],
			type: "pie",
			innerSize: "50%"
		  }
		]
	  });
		this.donutChart = donut;  
	});
  }
}

import { Component, Inject } from '@angular/core';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslatorService } from '../../../services/translator_service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiStore } from '../../../web-services/api/api.store';
import { ApiService } from '../../../web-services/api/api.service';
import _ from 'lodash';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-delete-trucks-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,CommonModule,MatFormFieldModule
    ,MatSelectModule,MatInputModule],
  templateUrl: './delete-trucks-modal.component.html',
  styleUrl: './delete-trucks-modal.component.scss'
})
export class DeleteTrucksModalComponent {
  currentLang;
	translationsObj;
  isMobile: Boolean;
  submitClicked : boolean = false;

  constructor(private responsiveService: ResponsiveService,private translator: TranslatorService,
    private dialogRef: MatDialogRef<DeleteTrucksModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
    private apiService:ApiService,private apiStore:ApiStore) {
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$
		.subscribe(async value=>{						
		  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
		  this.currentLang = value;	
		})
  }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();
    this.submitClicked = false;
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  applyChanges(){
    this.saveChanges();
	}

  closeModal(){
		this.dialogRef.close();
	}

  saveChanges(){		
    if(this.submitClicked){
      return;
    }
    this.submitClicked = true;
		let request = {};
		let trucksList = [];
    _.each(this.data.chosenTrucks, (truck) => {
      trucksList.push(truck.truck_id);
    });
    request["tablet_id_list"] = trucksList;
    request['is_delete'] = 1;

    this.apiService.UpdateTrucksTabletsInfo(request).subscribe((v:any) => {    
      if(v.updateStatus){
        let userGeneratedWorkplans = {
          site_id:0
        }
        this.apiService.getTrucksTabletsInfo(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));		
        this.closeModal();
      }
   });  
	}

}

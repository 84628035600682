import { Component, Input, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { UtilService } from '../../../services/util.service';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslatorService } from '../../../services/translator_service';
import { Papa } from 'ngx-papaparse';
import moment from 'moment';
import _ from 'lodash';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PaginationComponent } from '../../pagination/pagination.component';

@Component({
  selector: 'app-bin-collection-history',
  standalone: true,
  imports: [CommonModule,TranslateModule,RTLDivDirectiveDirective,MatDividerModule,
	MatCardModule,FormsModule,MatTableModule,MatSortModule,MatCheckboxModule,PaginationComponent],
  templateUrl: './bin-collection-history.component.html',
  styleUrl: './bin-collection-history.component.scss'
})
export class BinCollectionHistoryComponent {
  @Input() orientation: boolean;
	tableData: any[];
	displayedColumns = ['select', 'collection_date', 'collection_time', 'capacity_at_pick_up'];
	mobiledisplayedColumns = ['collection_date', 'collection_time', 'capacity_at_pick_up'];
	selection = new SelectionModel<Element>(true, []);
	isMobile: Boolean;
	showlist:boolean = false;
	@Input() showLocation: boolean;
	@Input() showEvents: boolean;

	@ViewChild(MatSort,{static: false}) sort: MatSort;

	binInfo;
	@Input()
	set binLiveInfo_Bin(bin_info:any) {	
		if(bin_info != null){
			this.binInfo = bin_info;
			setTimeout(() => {
				this.apiService.getBinCollection(this.binInfo.bin_id);
			}, 0);
		}
	}

	startIndexTableData = 0;
	endIndexTableData = 5;
	dataTableLength = 0;
	dataSource = new MatTableDataSource<any>();
	binCollectionData;
	binCollectionNotFormattedData;
	currentLang;
	translationsObj;
	isRTL: boolean;
	spinnerActive: boolean;
	translate;
	capacityDisplayType: number = 0;
	usageType = 1;

	constructor(private papa: Papa,
		private translator: TranslatorService,
		private apiQuery: ApiQuery,
		private apiService: ApiService,
		private responsiveService: ResponsiveService,
		private utilService: UtilService) {
			this.translate = this.translator;
			this.translator.currentLangEmitter$
			.subscribe(async value=>{						
			  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
			  this.currentLang = value;
			  this.isRTL =  this.translator.isRTL();
			})
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.spinnerActive = true;

		this.dataSource = null;
		this.binCollectionData = null;

		this.apiQuery.binCollectionData$.subscribe((binCollection) => {
			if(binCollection.length==0) return;
			this.subscribeBinCollection(binCollection);
			this.spinnerActive = false;
		});

		this.apiQuery.user$.subscribe((user) => {
			if (!user) return;

			this.capacityDisplayType = user["user_default_capacity_type_id"];
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
		});
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	  displayList(){		  
		this.showlist = !this.showlist;
	  }

	ngOnDestroy() {
		this.dataSource = null;
		this.binCollectionData = null;
	}

	isSpinnerActive(){
		return this.spinnerActive;
	}

	sortData(sort: Sort) {
		const data = this.binCollectionData.slice();
		if (!sort.active || sort.direction === '') {
			this.binCollectionData = data;
			return;
		}
		this.binCollectionData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			if(sort.active == "capacity_at_pick_up"){
				return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
			}else{
				return sort.active ? this.compareDates(a[sort.active], b[sort.active], isAsc) : 0;
			}
		});
	}

	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	compareDates(a, b, isAsc) {
		if (this.currentLang !== 'iw') {
			a = moment(a, "MM.DD.YYYY");
			b = moment(b, "MM.DD.YYYY");
		}
		else {
			a = moment(a, "DD.MM.YYYY");
			b = moment(b, "DD.MM.YYYY");
		}

		return (a < b ? -1 : 1) * (isAsc === 'asc' ? 1 : -1);
	}

	public onPageChange = (dataTableIndexes: any) => {
		this.startIndexTableData = dataTableIndexes.startIndex;
		this.endIndexTableData = dataTableIndexes.endIndex;
	}

	private subscribeBinCollection = (binCollection) => {
		this.binCollectionNotFormattedData = binCollection;
		let collection_date;
		let collection_time;
		let binsFiltered = [];
		binsFiltered = _.map(binCollection, b => {	
			if (this.currentLang !== 'iw') {
				collection_date = moment(b.bin_collection_time).format('MM.DD.YYYY');
				collection_time = moment(b.bin_collection_time).format('hh:mm');
			}
			else {
				collection_date = moment(b.bin_collection_time).format('DD.MM.YYYY');
				collection_time = moment(b.bin_collection_time).format('HH:mm');
			}
			return {
				collection_date: collection_date,
				collection_time: collection_time,
				capacity_at_pick_up: this.capacityDisplayType != 0 ? b["bin_refill_amount_in_liters"] : b["bin_fill_level_before_collection"],
				status_bar_color_name: b["status_bar_color_name"]
			};
		});

		this.binCollectionData = !_.isEmpty(binCollection[0]) ? binsFiltered : [];
		this.dataTableLength = this.binCollectionData.length;
		this.dataSource = new MatTableDataSource<any>(binsFiltered);
		this.dataSource.sort = this.sort;
		this.sortData({ direction: 'desc', active: 'collection_date' });
	}

	public getTableData = () => {
		if (!this.dataSource || this.binCollectionData.length === 0) return [];
		this.tableData = [];
		for (let index = this.startIndexTableData; index <= this.endIndexTableData; index++) {
			this.tableData.push(this.binCollectionData[index]);
		}
		this.dataSource = new MatTableDataSource<any>(this.tableData);		
		this.dataSource["AllData"] = new MatTableDataSource<any>(this.binCollectionData);
		return this.dataSource;
	}
	public exportToCsv = () => {
		let dataToExport = null;
		if (this.selection.selected.length > 0) {
			dataToExport = _.cloneDeep(this.selection.selected);
		} else {
			dataToExport = _.cloneDeep(this.binCollectionData);
		}
		_.each(dataToExport, (item) => {
			item.capacity_at_pick_up += '%';
		});
		const flatData = dataToExport.reduce((b, a) => {
			b.push(_.values(a));
			return b;
		}, [])
		const csvData = this.papa.unparse({ fields: [this.translationsObj.LIVE_INFO.LIST_COLLECTION_DATE, this.translationsObj.LIVE_INFO.LIST_COLLECTION_TIME, this.translationsObj.LIVE_INFO.LIST_PICKUP_CAPACITY], data: flatData });

		this.papa.parse(csvData, {
			complete: (results, file) => {
				const csv = `\uFEFF${this.papa.unparse(results.data)}`;
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
				const csvURL = window.URL.createObjectURL(blob);
				const tempLink = document.createElement('a');
				tempLink.href = csvURL;
				if(this.currentLang != 'iw'){
					tempLink.setAttribute('download', `${this.binInfo.bin_name} - Collections.csv`);
				}else{
					tempLink.setAttribute('download', `${this.binInfo.bin_name} - פינויים.csv`);
				}
				tempLink.click();
			}
		});
	}

	public getProgressBarStyleWrapper = (element: any) => {
		if(element){
			if(element.status_bar_color_name){
				if (element.status_bar_color_name.toLowerCase() == "green") {
					return { 'background-color': 'rgba(52, 208, 141, 0.15)' };
				} else if (element.status_bar_color_name.toLowerCase() == "yellow") {
					return { 'background-color': 'rgba(226, 224, 85, 0.15)' };
				}
				return { 'background-color': 'rgba(215, 89, 76, 0.15)' };
			}
		}
	}

	public getProgressBarStyleInternalMobile = (element: any) => {
		if(element){
			if(element.status_bar_color_name){
				if (element.status_bar_color_name.toLowerCase() == "green") {
					return { 'color': '#34D08D' };
				} else if (element.status_bar_color_name.toLowerCase() == "yellow") {
					return { 'color': '#E2E055'};
				}
				return { 'color': '#D7594C'};
			}
		}
	}

	public getProgressBarStyleInternal = (element: any) => {
		if(element){
			let progressBarFillLevelValue = element.capacity_at_pick_up;
			if (this.capacityDisplayType != 0) progressBarFillLevelValue = progressBarFillLevelValue / 100;
			if(element.status_bar_color_name){
				if (element.status_bar_color_name.toLowerCase() == "green") {
					return { 'background-color': '#34D08D', 'width': progressBarFillLevelValue + '%','max-width':'100%' };
				} else if (element.status_bar_color_name.toLowerCase() == "yellow") {
					return { 'background-color': '#E2E055', 'width': progressBarFillLevelValue + '%','max-width':'100%' };
				}
				return { 'background-color': '#D7594C', 'width': progressBarFillLevelValue + '%','max-width':'100%' };
			}	
		}
	}

	public getLastCollection = () => {
		if ((!this.binCollectionNotFormattedData) || (this.binCollectionNotFormattedData.length === 0)) return '';
		const last_time = this.calcDiff(this.binCollectionNotFormattedData[0].bin_collection_time);
		return last_time;
	}

	public getCollectionLastWeek = () => {
		const now = moment();
		const fromDate = now.startOf('week');
		const toDate = moment();

		let countCollectionLastWeek = 0;
		_.each(this.binCollectionNotFormattedData, (item) => {
			const binCollectionTimeElement = moment(item['bin_collection_time']);
			if ((binCollectionTimeElement >= fromDate) && (binCollectionTimeElement < toDate)) {
				countCollectionLastWeek++;
			}
		});
		return countCollectionLastWeek;
	}
	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected = (): boolean => {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource["AllData"].filteredData.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle = () => {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource["AllData"].filteredData.forEach(row => this.selection.select(row));
	}

	calcDiff = (date: string) => {
		if (this.translationsObj != undefined) {
			let diff_in_string = '';
			const original_date = moment(date);
			const date_time_now = moment();
			const diff_in_object: any = this.utilService.preciseDiffBetweenDates(original_date, date_time_now, true);
			if (diff_in_object.months > 0) {
				diff_in_string = diff_in_string + diff_in_object.months + ' ';
				if (diff_in_object.months === 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MONTH + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MONTHS + ' ';
				}
			}
			if (diff_in_object.days > 0) {
				diff_in_string = diff_in_string + diff_in_object.days + ' ';
				if (diff_in_object.days === 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.DAY + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.DAYS + ' ';
				}
			}
			if (diff_in_object.hours > 0) {
				if (diff_in_object.days > 0) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.AND + ' ' + diff_in_object.hours + ' ';
				} else {
					diff_in_string += diff_in_object.hours + ' ';
				}
				if (diff_in_object.hours === 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.HOUR + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.HOURS + ' ';
				}
			}
			
			if (diff_in_object.minutes > 0) {
				if (diff_in_object.hours > 0) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.AND + ' ' + diff_in_object.minutes + ' ';
				} else {
					diff_in_string += diff_in_object.minutes + ' ';
				}
				if (diff_in_object.minutes === 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MINUTE + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MINUTES + ' ';
				}
			}
			else {
				diff_in_string += this.translationsObj.COLLECTION_REPORT.MINUTE + ' ';
			}

			if (this.currentLang !== 'iw') {
				diff_in_string += this.translationsObj.COLLECTION_REPORT.AGO;
			}
			else {
				diff_in_string = ' ' + diff_in_string;
			}
			
			return diff_in_string;
		}
		
	}
}

import { Component, Inject, ViewChild } from '@angular/core';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import _ from 'lodash';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../web-services/api/api.service';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-add-truck-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,MatSelectModule,MatInputModule,NgbModule,MatExpansionModule],
  templateUrl: './add-truck-modal.component.html',
  styleUrl: './add-truck-modal.component.scss'
})
export class AddTruckModalComponent {
  sundayVal:string;
  mondayVal:string;
  tuesdayVal:string;
  wednesdayVal:string;
  thursdayVal:string;
  fridayVal:string;
  saturdayVal:string;
  currentLang;
  truckCapacity:any;
  truckMaxBins:string;
  truckLicensePlate:string;
	translationsObj;
  translateSub;
  routeListData: any[] = [];
  selectedRoute: any;
	sitesListData: any[] = [];
	selectedSites: any;
	@ViewChild('siteMatSelect',{static: false}) siteMatSelect;
  @ViewChild('routeTypeMatSelect',{static: false}) routeTypeMatSelect;
	exist:boolean = false;
  selectedSite: any;
	allSitesSelected: boolean;
	usageType = 1;
  PartialManagement: boolean = false;
	isAdmin: boolean = false;
  isMobile: Boolean;
  panelOpenState: boolean = false;

  constructor(private apiService:ApiService, private translator: TranslatorService,private apiQuery:ApiQuery,
    private dialogRef: MatDialogRef<AddTruckModalComponent>, private responsiveService: ResponsiveService) {
      this.dialogRef.disableClose = true;
      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
        this.currentLang = value;	
      })
  }

  ngOnInit() {   
    this.onResize();
	  this.responsiveService.checkWidth();
    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}
			
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
      if ((user["user_role_id"] == 2) || (user["user_role_id"] == 1) || (user["user_role_id"] == 5) || (user["user_role_id"] == 6)) {
				this.isAdmin = true;
			}
      if (user["user_role_id"] == 4) {
				this.PartialManagement = true;
			}
		});    

    this.apiQuery.sitesListData$.subscribe((sitesList) => {
      if (sitesList.length > 0){
        this.subscribeSitesList(sitesList);
        this.allSitesSelected = false;
        if(sitesList.length == 1){
          this.selectedSites = sitesList[0];
          this.allSitesSelected = false;
        }
      }
    });

    this.apiQuery.optimizationTypes$.subscribe((optimizationTypes) => {
      if (optimizationTypes.length == 0){return;}      
      this.routeListData = optimizationTypes;   
    });
   }

   onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
   }

   selectAllSites(select: NgModel, values, item) {
    this.selectedSites = item;
    select.update.emit(item);
 
		this.allSitesSelected = false;
		this.siteMatSelect.close();
	}

  selectRouteType(select: NgModel, values, item) {
    this.selectedRoute = item;
    select.update.emit(item);
		this.routeTypeMatSelect.close();    
	}

  validateCapacity(value){
    let max = 1000000;

    if (parseInt(value) > max) {
        this.truckCapacity = max; 
    }
  }
   
	subscribeSitesList = (sitesList: any[]) => {
    this.sitesListData = sitesList;   
  }

  closeModal = () => {
    this.dialogRef.close();
  }

  saveWorkplan = () => {
    let request = {};    
    request["max_capacity_in_liters"] = Number(this.truckCapacity);
    if (this.selectedSites != undefined) {
      request["site_id"] = this.selectedSites["site_id"];
    }
    request["sim_id"] = '';
    request["max_capacity_in_bins"] = Number(this.truckMaxBins);
    request["assigned_truck_license_plate"] = this.truckLicensePlate;
    if (this.selectedRoute != undefined) {
      request["route_optimization_type_id"] = this.selectedRoute["optimization_type_id"];
    }

    request["tablet_teams_allocation_id"] = 1;
    request["is_default_tablet"] = 1;
    request["tablet_name"] = '';
    request["driver_rest_time_in_min"] = 0;
    request["driver_rest_cycle_in_min"] = 0;

    request["tablet_daily_capacity_list"] = [];
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.sundayVal,max_capacity_in_liters:this.truckCapacity});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.mondayVal,max_capacity_in_liters:this.truckCapacity});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.tuesdayVal,max_capacity_in_liters:this.truckCapacity});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.wednesdayVal,max_capacity_in_liters:this.truckCapacity});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.thursdayVal,max_capacity_in_liters:this.truckCapacity});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.fridayVal,max_capacity_in_liters:this.truckCapacity});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.saturdayVal,max_capacity_in_liters:this.truckCapacity});
    
    this.apiService.insertNewTruckTabletInfo(request).subscribe((v:any) => {     
      if(v.updateStatus){
        let userGeneratedWorkplans = {
          site_id:0
        }
        this.apiService.getTrucksTabletsInfo(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));		
        this.closeModal();
      }
   });    
  }
}

@if (!isMobile) {
  <div>
    <div class="padded inventory-page" rtlDiv>
      <div class="inventory-header" rtlDiv>
        <div class='inventory-header-left'>
          <div class='header-bold' rtlDiv>{{ 'WIDGETS.MANAGE_BINS' | translate }}</div>
        </div>
        @if (showManageBinModal) {
          <app-bins-actions-modal (showHideModal)="openManageBinModal($event)" [selectedBin]="selectedBin" [tabStatus]="tabStatus"></app-bins-actions-modal>
        }
      </div>
      <mat-divider class="top-divider"></mat-divider>
    </div>
    <div class="inventory-view">
      <mat-tab-group mat-stretch-tabs="false" rtlDiv animationDuration="0ms">
        <mat-tab label="{{'SITE_MANAGMENT.INSTALLED_DEVICES' | translate}}">
          <mat-card>
            <div class='spinner-container' [hidden]="!spinnerFirstActive">
              <div class="spinner"></div>
            </div>
            <mat-card-content [hidden]="spinnerFirstActive">
              <div class='search-input-container' rtlDiv>
                <div class="search-frame">
                  <input class='search-input' type="text" (input)="applyFirstFilter($event.target)" placeholder="{{
                    'OTHER.SEARCH' | translate }}" name="">                    
                    <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                </div>
                  <div class='table-export-container' rtlDiv>
                    <div class='table-export-btns-wrapper' (click)="exportFirstToCsv()" rtlDiv>
                      <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                      <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                    </div>
                    <div class="manage-bins-btn-container" [ngClass]="{'disableBtn' : selectionFirst.selected.length == 0 , 'enableBtn' : selectionFirst.selected.length > 0}" (click)="openManageModalForMulti(true,1)" [hidden]="spinnerFirstActive" rtlDiv>
                      <a class="manage-bins-btn" rtlDiv>{{ 'SITE_MANAGMENT.ACTIONS' | translate }} <span><img class="arow_manage" src="assets/images/dashboard/Arrow_right.svg" rtlDiv/></span></a>
                    </div>
                  </div>
                </div>
                <table class="table" mat-table [dataSource]="getFirstTableData()" matSort
                  (matSortChange)="sortFirstData($event)">
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox id="inventory-header-checkbox" (change)="$event ? masterFirstToggle() : null"
                        [checked]="selectionFirst.hasValue() && isFirstAllSelected()"
                        [indeterminate]="selectionFirst.hasValue() && !isFirstAllSelected()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row" class="list-checkbox chb-space" rtlDiv>
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionFirst.toggle(row) : null"
                        [checked]="selectionFirst.isSelected(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="th-title"> {{ 'LIST_COLUMNS.NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                      <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'inventory',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="cut-txt binNameHref"> {{element ? element.bin_name : ''}}</a>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_id" class="space th-title"> {{ 'LIST_COLUMNS.ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.bin_id: ''}} </td>
                  </ng-container>
                  <ng-container matColumnDef="device_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="device_id" class="space th-title"> {{ 'SITE_MANAGMENT.DEVICE_ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.device_id: ''}} </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="space th-title"> {{ 'LIST_COLUMNS.ADDRESS' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.bin_address: ''}} </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_installation_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_installation_date" class="space th-title"> {{ 'SITE_MANAGMENT.INSTALLATION_TIME' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.bin_installation_date: ''}} </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_installer">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_installer" class="space th-title"> {{ 'SITE_MANAGMENT.INSTALLER_NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.bin_installer: ''}} </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_installation_user_reported_capacity_in_percent">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_installation_user_reported_capacity_in_percent" class="space th-title">{{'SITE_MANAGMENT.CAP_BY_USER' | translate}}</th>
                    <td class="space td-value" mat-cell *matCellDef="let element"> {{element ? element.bin_installation_user_reported_capacity_in_percent: ''}}% </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_installation_capacity_in_percent">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_installation_capacity_in_percent" class="space th-title"> {{
                      'SITE_MANAGMENT.CAP_AT_INTALLATION' | translate
                    }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.bin_installation_capacity_in_percent: ''}}% </td>
                  </ng-container>
                  <ng-container matColumnDef="bin_capacity_gap">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_capacity_gap" class="space th-title"> {{
                      'SITE_MANAGMENT.CAP_GAP' | translate
                    }} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.bin_capacity_gap: ''}}% </td>
                  </ng-container>
                  <ng-container matColumnDef="current_capacity_in_percent">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="current_capacity_in_percent" class="space th-title"> {{'SITE_MANAGMENT.CURRENT_CAP' | translate}} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.current_capacity_in_percent: ''}}% </td>
                  </ng-container>
                  <ng-container matColumnDef="production_chain_status_desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="production_chain_status_desc" class="space th-title"> {{'LIST_COLUMNS.STATUS_DESC' | translate}} </th>
                    <td mat-cell *matCellDef="let element" class="space td-value">
                      @if (element && currentLang != 'iw') {
                        <span>{{element.production_chain_status_desc}}</span>
                      }
                      @if (element && currentLang == 'iw') {
                        <span>{{element.production_chain_status_desc_hebrew}}</span>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="binWazeLink">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="binWazeLink" class="space th-title">{{ 'SITE_MANAGMENT.WAZE_LINK' | translate }}</th>
                    <td mat-cell *matCellDef="let element" class="space td-value">
                      <span class="flexIcons">
                        <span>
                          <a href='https://waze.com/ul?ll={{element.lat}},{{element.lon}}'>
                            <img class="wazeIcon" src="assets/images/dashboard/waze.svg" alt="">
                          </a>
                        </span>
                        <span>
                          <a href='https://www.google.com/maps/dir/?api=1&destination={{element.lat}},{{element.lon}}&travelmode=walking'>
                            <img class="wazeIcon google-maps-icon" src="assets/images/dashboard/google-maps.svg" alt="">
                          </a>
                        </span>
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="manage">
                    <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="manage"></th>
                    <td mat-cell *matCellDef="let element" class="manage-bins">
                      <div class="manage-bin-button hide-manage-bin" (click)="openManageBinModal(true, element,1)" rtlDiv>
                      {{'SITE_MANAGMENT.ACTIONS' | translate }}</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="location"></th>
                    <td mat-cell *matCellDef="let element" class="manage-bins">
                      <div class="manage-bin-button hide-manage-bin" (click)="openchangeLocationModal(element)" rtlDiv>
                      {{'SITE_MANAGMENT.UPDATE_LOCATION' | translate }}</div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr class="bins-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </mat-card-content>
              <div [hidden]="spinnerFirstActive || dataFirstTableLength == 0">
                <app-pagination [chosenBins]="filteredFirstData" (pageChange)='onFirstPageChange($event)' [filesPerPage]="10"></app-pagination>
              </div>
            </mat-card>
          </mat-tab>
          <mat-tab label="{{'SITE_MANAGMENT.MALFUNCTUNIMG_DEVICES' | translate}}">
            <mat-card>
              <div class='spinner-container' [hidden]="!spinnerSecondActive">
                <div class="spinner"></div>
              </div>
              <mat-card-content [hidden]="spinnerSecondActive">
                <div class='search-input-container' rtlDiv>
                  <div class="search-frame">
                    <input class='search-input' type="text" (input)="applySecondFilter($event.target)" placeholder="{{
                      'OTHER.SEARCH' | translate }}" name="">
                        <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                  </div>
                    <div class='table-export-container' rtlDiv>
                      <div class='table-export-btns-wrapper' (click)="exportSecondToCsv()" rtlDiv>
                        <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                        <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                      </div>
                    </div>
                  </div>
                  <table class="table" mat-table [dataSource]="getSecondTableData()" matSort
                    (matSortChange)="sortSecondData($event)">
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox id="inventory-header-checkbox" (change)="$event ? masterSecondToggle() : null"
                          [checked]="selectionSecond.hasValue() && isSecondAllSelected()"
                          [indeterminate]="selectionSecond.hasValue() && !isSecondAllSelected()">
                        </mat-checkbox>
                      </th>
                      <td mat-cell *matCellDef="let row" class="list-checkbox chb-space" rtlDiv>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionSecond.toggle(row) : null"
                          [checked]="selectionSecond.isSelected(row)">
                        </mat-checkbox>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_name">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="th-title"> {{ 'LIST_COLUMNS.NAME' | translate }} </th>
                      <td mat-cell *matCellDef="let element">
                        <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'inventory',binId:element.bin_id}" title="{{element ? element.bin_name : ''}}" class="cut-txt binNameHref"> {{element ? element.bin_name : ''}}</a>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_id">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_id" class="space th-title"> {{ 'LIST_COLUMNS.ID' | translate }} </th>
                      <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.bin_id: ''}} </td>
                    </ng-container>
                    <ng-container matColumnDef="device_id">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="device_id" class="space th-title"> {{ 'SITE_MANAGMENT.DEVICE_ID' | translate }} </th>
                      <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.device_id: ''}} </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_address">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_address" class="space th-title"> {{ 'LIST_COLUMNS.ADDRESS' | translate }} </th>
                      <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.bin_address: ''}} </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_installation_date">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_installation_date" class="space th-title"> {{ 'SITE_MANAGMENT.INSTALLATION_TIME' | translate }} </th>
                      <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.bin_installation_date: ''}} </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_installer">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_installer" class="space th-title"> {{ 'SITE_MANAGMENT.INSTALLER_NAME' | translate }} </th>
                      <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.bin_installer: ''}} </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_installation_user_reported_capacity_in_percent">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_installation_user_reported_capacity_in_percent" class="space th-title">{{'SITE_MANAGMENT.CAP_BY_USER' | translate}}</th>
                      <td class="space td-value" mat-cell *matCellDef="let element"> {{element ? element.bin_installation_user_reported_capacity_in_percent: ''}}% </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_installation_capacity_in_percent">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_installation_capacity_in_percent" class="space th-title"> {{'SITE_MANAGMENT.CAP_AT_INTALLATION' | translate}} </th>
                      <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.bin_installation_capacity_in_percent: ''}}% </td>
                    </ng-container>
                    <ng-container matColumnDef="bin_capacity_gap">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="bin_capacity_gap" class="space th-title"> {{'SITE_MANAGMENT.CAP_GAP' | translate}} </th>
                      <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.bin_capacity_gap: ''}}% </td>
                    </ng-container>
                    <ng-container matColumnDef="current_capacity_in_percent">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="current_capacity_in_percent" class="space th-title"> {{'SITE_MANAGMENT.CURRENT_CAP' | translate}} </th>
                      <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.current_capacity_in_percent: ''}}% </td>
                    </ng-container>
                    <ng-container matColumnDef="production_chain_status_desc">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="production_chain_status_desc" class="space th-title"> {{'LIST_COLUMNS.STATUS_DESC' | translate}} </th>
                      <td mat-cell *matCellDef="let element" class="space td-value">
                        @if (element && currentLang != 'iw') {
                          <span>{{element.production_chain_status_desc}}</span>
                        }
                        @if (element && currentLang == 'iw') {
                          <span>{{element.production_chain_status_desc_hebrew}}</span>
                        }
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="binWazeLink">
                      <th mat-header-cell *matHeaderCellDef class="space th-title">{{ 'SITE_MANAGMENT.WAZE_LINK' | translate }}</th>
                      <td mat-cell *matCellDef="let element" class="space td-value">
                        <span class="flexIcons">
                          <span>
                            <a href='https://waze.com/ul?ll={{element.lat}},{{element.lon}}'>
                              <img class="wazeIcon" src="assets/images/dashboard/waze.svg" alt="">
                            </a>
                          </span>
                          <span>
                            <a href='https://www.google.com/maps/dir/?api=1&destination={{element.lat}},{{element.lon}}&travelmode=walking'>
                              <img class="wazeIcon google-maps-icon" src="assets/images/dashboard/google-maps.svg" alt="">
                            </a>
                          </span>
                        </span>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="manage">
                      <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="manage"></th>
                      <td mat-cell *matCellDef="let element" class="manage-bins">
                        <div class="manage-bin-button hide-manage-bin" (click)="openManageBinModal(true, element,2)" rtlDiv>
                          {{'SITE_MANAGMENT.ACTIONS' | translate }}
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="location">
                      <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="location"></th>
                      <td mat-cell *matCellDef="let element" class="manage-bins">
                        <div class="manage-bin-button hide-manage-bin" (click)="openchangeLocationModal(element)" rtlDiv>
                        {{'SITE_MANAGMENT.UPDATE_LOCATION' | translate }}</div>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr class="bins-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </mat-card-content>
                <div [hidden]="spinnerSecondActive || dataSecondTableLength == 0">
                  <app-pagination [chosenBins]="filteredSecondData" (pageChange)='onSecondPageChange($event)' [filesPerPage]="10"></app-pagination>
                </div>
              </mat-card>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    }


    @if (isMobile) {
      <div>
        <div class="mobile-top-container">
          <div class="mobile-inventory-header">
            <div class="mobile-left-title">{{ 'WIDGETS.MANAGE_BINS' | translate }}</div>
          </div>
          @if (showManageBinModal) {
            <app-bins-actions-modal (showHideModal)="openManageBinModal($event)" [selectedBin]="selectedBin" [tabStatus]="tabStatus"></app-bins-actions-modal>
          }
          <div class="mobile-separate">
            <mat-divider class="mobile-line"></mat-divider>
          </div>
        </div>
        <div class="mobile-group-tabs">
          <mat-tab-group>
            <mat-tab label="{{'SITE_MANAGMENT.INSTALLED_DEVICES' | translate}}">
              <ng-template matTabContent>
                <div class="card mobile-card">
                  <div class="card-body">
                    <div class="mobile-search-excel">
                      <div class='mobile-search-container'>
                        <input class='mobile-input' type="text" (keyup)="applyFirstFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
                          name="" rtlDiv>
                          <img class='mobile-search-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                        </div>
                        <div class='mobile-table-export-container' rtlDiv>
                          <div class='mobile-table-export-btns-wrapper' (click)="exportFirstToCsv()" rtlDiv>
                            <img class='mobile-table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                          </div>
                        </div>
                      </div>
                      <div class="spinner-border mobile-spinner" [hidden]="!spinnerFirstActive">
                        <div class="spinner"></div>
                      </div>
                      <table rtlDiv class="mobile-table" mat-table [dataSource]="getFirstTableData()" multiTemplateDataRows
                        [hidden]="spinnerFirstActive"
                        matSort (matSortChange)="sortFirstData($event)">
                        <ng-container matColumnDef="bin_name">
                          <th class="mobile-th mobile-details-td" style="height: 52px;" mat-sort-header="bin_name" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.NAME' | translate }} </th>
                          <td class="mobile-status-td mobile-details-td" mat-cell *matCellDef="let element" (click)="goToBinLivePage(element)"> <span title="{{element ? element.bin_name : ''}}" class="cut-txt"> {{element ? element.bin_name : ''}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="bin_id">
                          <th class="mobile-th mobile-details-td" style="height: 52px;" mat-sort-header="bin_id" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.ID' | translate }} </th>
                          <td class="mobile-status-td mobile-details-td" mat-cell *matCellDef="let element" (click)="goToBinLivePage(element)"> <span title="{{element ? element.bin_id : ''}}" class="cut-txt"> {{element ? element.bin_id : ''}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="arrowDown">
                          <th class="mobile-th" mat-sort-header="arrowDown" style="height: 52px;" rtlDiv mat-header-cell *matHeaderCellDef></th>
                          <td mat-cell *matCellDef="let element">
                            <div rtlDiv class="mobile-status-value">
                              @if (expandedElement !== element) {
                                <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                              }
                              @if (expandedElement === element) {
                                <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                              }
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="expandedDetail">
                          <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" rtlDiv>
                            <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                              <div class="mobile-element-diagram" rtlDiv>
                                <div class="dFlex">
                                  <div class="mobile-id-details-title">{{ 'SITE_MANAGMENT.DEVICE_ID' | translate }}</div>
                                  <div class="mobile-id-details-value"> {{element.device_id}} </div>
                                </div>
                                <div class="dFlex">
                                  <div class="mobile-description-details-title">{{'LIST_COLUMNS.ADDRESS' | translate}}</div>
                                  <div class="mobile-description-details-value">
                                    <span>{{element ? element.bin_address: ''}}</span>
                                    <a class="wazeFrame" href='https://waze.com/ul?ll={{element.lat}},{{element.lon}}'><img class="wazeIcon" src="assets/images/dashboard/waze.svg" alt=""></a>
                                    <a class="wazeFrame" href='https://www.google.com/maps/dir/?api=1&destination={{element.lat}},{{element.lon}}&travelmode=walking'><img class="wazeIcon google-maps-icon" src="assets/images/dashboard/google-maps.svg" alt=""></a>
                                  </div>
                                </div>
                                <div class="dFlex">
                                  <div class="mobile-id-details-title">{{ 'SITE_MANAGMENT.INSTALLATION_TIME' | translate }}</div>
                                  <div class="mobile-id-details-value"> {{element.bin_installation_date}} </div>
                                </div>
                                <div class="dFlex">
                                  <div class="mobile-description-details-title">{{'SITE_MANAGMENT.INSTALLER_NAME' | translate}}</div>
                                  <div class="mobile-description-details-value"> {{element ? element.bin_installer: ''}} </div>
                                </div>
                                <div class="dFlex">
                                  <div class="mobile-description-details-title">{{'SITE_MANAGMENT.CAP_BY_USER' | translate}}</div>
                                  <div class="mobile-description-details-value"> {{element.bin_installation_user_reported_capacity_in_percent}}% </div>
                                </div>
                                <div class="dFlex">
                                  <div class="mobile-description-details-title">{{'SITE_MANAGMENT.CAP_AT_INTALLATION' | translate}}</div>
                                  <div class="mobile-description-details-value"> {{element.bin_installation_capacity_in_percent}}% </div>
                                </div>
                                <div class="dFlex">
                                  <div class="mobile-description-details-title">{{'SITE_MANAGMENT.CAP_GAP' | translate}}</div>
                                  <div class="mobile-description-details-value"> {{element.bin_capacity_gap}}% </div>
                                </div>
                                <div class="dFlex">
                                  <div class="mobile-acre-details-title">{{'SITE_MANAGMENT.CURRENT_CAP' | translate}}</div>
                                  <div class="mobile-acre-details-value"> {{element.current_capacity_in_percent}}% </div>
                                </div>
                                <div class="dFlex">
                                  <div class="mobile-acre-details-title">{{'LIST_COLUMNS.STATUS_DESC' | translate}}</div>
                                  <div class="mobile-acre-details-value">
                                    @if (element && currentLang != 'iw') {
                                      <span>{{element.production_chain_status_desc}}</span>
                                    }
                                    @if (element && currentLang == 'iw') {
                                      <span>{{element.production_chain_status_desc_hebrew}}</span>
                                    }
                                  </div>
                                </div>
                                <div class="mobile-manage-row">
                                  <div class="mobile-manage-bin-button" (click)="openManageBinModal(true, element,1)" rtlDiv>
                                    {{'SITE_MANAGMENT.ACTIONS' | translate }}
                                  </div>
                                  <div class="mobile-manage-bin-button" (click)="openchangeLocationModal(element)" rtlDiv>
                                    {{'SITE_MANAGMENT.UPDATE_LOCATION' | translate }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                          (click)="expandedElement = expandedElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                      </table>
                      @if (dataFirstTableLength > 0 && !spinnerFirstActive) {
                        <div class="mobile-pagination">
                          <app-pagination [chosenBins]="filteredFirstData" (pageChange)='onFirstPageChange($event)' [filesPerPage]="10"></app-pagination>
                        </div>
                      }
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab label="{{'SITE_MANAGMENT.MALFUNCTUNIMG_DEVICES' | translate}}">
                <ng-template matTabContent>
                  <div class="card mobile-card">
                    <div class="card-body">
                      <div class="mobile-search-excel">
                        <div class='mobile-search-container'>
                          <input class='mobile-input' type="text" (keyup)="applySecondFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
                            name="" rtlDiv>
                            <img class='mobile-search-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                          </div>
                          <div class='mobile-table-export-container' rtlDiv>
                            <div class='mobile-table-export-btns-wrapper' (click)="exportSecondToCsv()" rtlDiv>
                              <img class='mobile-table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="spinner-border mobile-spinner" [hidden]="!spinnerSecondActive">
                          <div class="spinner"></div>
                        </div>
                        <table rtlDiv class="mobile-table" mat-table [dataSource]="getSecondTableData()" multiTemplateDataRows
                          [hidden]="spinnerSecondActive"
                          matSort (matSortChange)="sortSecondData($event)">
                          <ng-container matColumnDef="bin_name">
                            <th class="mobile-th mobile-details-td" style="height: 52px;" mat-sort-header="bin_name" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.NAME' | translate }} </th>
                            <td class="mobile-status-td mobile-details-td" mat-cell *matCellDef="let element" (click)="goToBinLivePage(element)"> <span title="{{element ? element.bin_name : ''}}" class="cut-txt"> {{element ? element.bin_name : ''}}</span> </td>
                          </ng-container>
                          <ng-container matColumnDef="bin_id">
                            <th class="mobile-th mobile-details-td" style="height: 52px;" mat-sort-header="bin_id" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.ID' | translate }} </th>
                            <td class="mobile-status-td mobile-details-td" mat-cell *matCellDef="let element" (click)="goToBinLivePage(element)"> <span title="{{element ? element.bin_id : ''}}" class="cut-txt"> {{element ? element.bin_id : ''}}</span> </td>
                          </ng-container>
                          <ng-container matColumnDef="arrowDown">
                            <th class="mobile-th" mat-sort-header="arrowDown" style="height: 52px;" rtlDiv mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                              <div rtlDiv class="mobile-status-value">
                                @if (expandedElement !== element) {
                                  <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                                }
                                @if (expandedElement === element) {
                                  <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                                }
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="expandedDetail">
                            <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" rtlDiv>
                              <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                <div class="mobile-element-diagram" rtlDiv>
                                  <div class="dFlex">
                                    <div class="mobile-id-details-title">{{ 'SITE_MANAGMENT.DEVICE_ID' | translate }}</div>
                                    <div class="mobile-id-details-value"> {{element.device_id}} </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-description-details-title">{{'LIST_COLUMNS.ADDRESS' | translate}}</div>
                                    <div class="mobile-description-details-value">
                                      <span>{{element ? element.bin_address: ''}}</span>
                                      <a class="wazeFrame" href='https://waze.com/ul?ll={{element.lat}},{{element.lon}}'><img class="wazeIcon" src="assets/images/dashboard/waze.svg" alt=""></a>
                                      <a class="wazeFrame" href='https://www.google.com/maps/dir/?api=1&destination={{element.lat}},{{element.lon}}&travelmode=walking'><img class="wazeIcon google-maps-icon" src="assets/images/dashboard/google-maps.svg" alt=""></a>
                                    </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-id-details-title">{{ 'SITE_MANAGMENT.INSTALLATION_TIME' | translate }}</div>
                                    <div class="mobile-id-details-value"> {{element.bin_installation_date}} </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-description-details-title">{{'SITE_MANAGMENT.INSTALLER_NAME' | translate}}</div>
                                    <div class="mobile-description-details-value"> {{element ? element.bin_installer: ''}} </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-description-details-title">{{'SITE_MANAGMENT.CAP_BY_USER' | translate}}</div>
                                    <div class="mobile-description-details-value"> {{element.bin_installation_user_reported_capacity_in_percent}}% </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-description-details-title">{{'SITE_MANAGMENT.CAP_AT_INTALLATION' | translate}}</div>
                                    <div class="mobile-description-details-value"> {{element.bin_installation_capacity_in_percent}}% </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-description-details-title">{{'SITE_MANAGMENT.CAP_GAP' | translate}}</div>
                                    <div class="mobile-description-details-value"> {{element.bin_capacity_gap}}% </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-acre-details-title">{{'SITE_MANAGMENT.CURRENT_CAP' | translate}}</div>
                                    <div class="mobile-acre-details-value"> {{element.current_capacity_in_percent}}% </div>
                                  </div>
                                  <div class="dFlex">
                                    <div class="mobile-acre-details-title">{{'LIST_COLUMNS.STATUS_DESC' | translate}}</div>
                                    <div class="mobile-acre-details-value">
                                      @if (element && currentLang != 'iw') {
                                        <span>{{element.production_chain_status_desc}}</span>
                                      }
                                      @if (element && currentLang == 'iw') {
                                        <span>{{element.production_chain_status_desc_hebrew}}</span>
                                      }
                                    </div>
                                  </div>
                                  <div class="mobile-manage-row">
                                    <div class="mobile-manage-bin-button" (click)="openManageBinModal(true, element,2)" rtlDiv>
                                      {{'SITE_MANAGMENT.ACTIONS' | translate }}
                                    </div>
                                    <div class="mobile-manage-bin-button" (click)="openchangeLocationModal(element)" rtlDiv>
                                      {{'SITE_MANAGMENT.UPDATE_LOCATION' | translate }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                            (click)="expandedElement = expandedElement === element ? null : element">
                          </tr>
                          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                        </table>
                        @if (dataSecondTableLength > 0 && !spinnerSecondActive) {
                          <div class="mobile-pagination">
                            <app-pagination [chosenBins]="filteredSecondData" (pageChange)='onSecondPageChange($event)' [filesPerPage]="10"></app-pagination>
                          </div>
                        }
                      </div>
                    </div>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        }
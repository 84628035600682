<div class="qr-page-wrapper">
  <div class="scanner-shell" [hidden]="!hasDevices">
    @if (availableDevices) {
      <div class="switch-camera-icon-wrapper">
        <img class="switch-camera-icon" [ngClass]="{'hide': availableDevices.length < 2}" src="assets/images/siteManagment/switch_camera-24px.svg" alt="" (click)="switchCamera()">
      </div>
    }
    <zxing-scanner #scanner [(device)]="currentDevice" (camerasFound)="camerasFoundHandler($event)" (scanSuccess)="scanSuccessHandler($event)" (scanError)="scanErrorHandler($event)" (scanFailure)="scanFailureHandler($event)" (scanComplete)="scanCompleteHandler($event)" [formats]="allowedFormats"></zxing-scanner>
    @if (qrResultString) {
      <section class="results">
        <small>Result</small>
        <strong>{{ qrResultString }}</strong>
      </section>
    }
  </div>
</div>